import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

const Dashboard3NumbersWidget = ({ title, description, items }) => (
    <Card className="mb-2">
        <CardHeader className="darkCardHeader">
            <h5>{title}</h5>
            <span>{description}</span>
        </CardHeader>
        <CardBody>
            <Row>
                <Col>
                    {items.map((item, i) => (
                        <p key={i} className="name">
                            <span className="number-label-warning">
                                {item.value}
                            </span>{' '}
                            {item.label}
                        </p>
                    ))}
                </Col>
            </Row>
        </CardBody>
    </Card>
);

Dashboard3NumbersWidget.propTypes = {
    title: PropTypes.string.isRequired,
};

export default Dashboard3NumbersWidget;
