import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment, { max } from 'moment';
import DialogModal from 'cyder/modals/DialogModal';
import { Row, Col, Form, Button, Input } from 'reactstrap';
import Select from 'react-select';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { getParameterByName } from '../../../../js/util';
import { search, getRenewalStatus, sendRenewalEmail, exportRenewals } from 'actions/staff/membership/renewals/action.js';

import { reorderArray } from 'js/util';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.sendRenewalEmail = this.sendRenewalEmail.bind(this);
        this.downloadCSV = this.downloadCSV.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            exportlist: [],
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            keyword: '',
            navObj: [],
            statuses: [],
            status: {
                label: `Show all status`,
                value: 0,
            },
            years: [],
            year: {
                label: 'Show all years',
                value: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'alert',
            },
            tableInitialState: {
                page: 2,
            },
        };
    }
    onSelectChange(e, id) {
        switch (id) {
            case 'year':
                this.setState({ year: e });
                break;
            case 'status':
                this.setState({ status: e });
                break;
        }
    }
    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        } else if (this.state.modal.type === 'alertrefresh') {
            this.modalToggle();
            this.table.fireFetchData();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalAction = this.modalAction;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuffs() {
        try {
            const res = await this.props.search();
            const years = this.getFilterByYearOptions(res.data);
            const statusesRes = await this.props.getRenewalStatus();
            const statuses = statusesRes.data.map(status => ({
                value: status.id,
                label: status.status,
            }));

            // Remove irrelevant data
            statuses.unshift({
                value: 0,
                label: `Show all status`,
            });

            // Updated by Fernando on 4 Oct 2019. The sorted will be done at the API
            // Reorder statuses
            // const reorderedStatuses = reorderArray(
            //     statuses,
            //     ['Decision Unknown', 'Uncontactable', 'In Progress', 'Discontinued', 'Renewed'],
            //     'label',
            // );

            if (this._isMounted) {
                const selectedStatus = statusesRes.data.filter(status => status.id == getParameterByName('status'));
                // console.log("param status:", getParameterByName("status"))
                // console.log("selectedStatus:", selectedStatus[0])
                const selectedStatusValue =
                    selectedStatus.length > 0
                        ? {
                              label: selectedStatus[0].status,
                              value: getParameterByName('status'),
                          }
                        : {
                              label: `Show all status`,
                              value: 0,
                          };
                // Added by Fernando on 26 Mar 2020, to keep the last visited page number
                this.setState({
                    years,
                    statuses: statuses,
                    status: selectedStatusValue,
                    year: getParameterByName('year')
                        ? {
                              label: getParameterByName('year'),
                              value: getParameterByName('year'),
                          }
                        : {
                              label: `Show all years`,
                              value: '',
                          },
                    keyword: getParameterByName('keyword') ? getParameterByName('keyword') : '',
                });

                if (getParameterByName('page') && getParameterByName('page') != '') {
                    this.table.onPageChange(getParameterByName('page'));
                }
            }
        } catch (error) {
            throw error;
        }
    }
    getMinimalAndMaximalYear(data) {
        const mapDateToYear = date => moment(date, 'YYYY-MM-DD').format('YYYY');
        let minYear;
        let maxYear;
        // Get minimal year
        data.forEach(application => {
            const year = parseInt(mapDateToYear(application.expiryDate));
            // Min year
            minYear = minYear || year;
            minYear = year < minYear ? year : minYear;
            // Max year
            maxYear = maxYear || year;
            maxYear = year > maxYear ? year : maxYear;
        });
        // Updated by Fernando
        // https://cydersg.atlassian.net/browse/SUP-25
        // MinYear is always 2015
        minYear = 2015;
        return [minYear, maxYear];
    }
    getFilterByYearOptions(data) {
        const yearLimit = this.getMinimalAndMaximalYear(data);
        let obj = [{ value: '', label: 'Show all years' }];
        for (let i = yearLimit[0]; i <= yearLimit[1]; i++) {
            obj.push({
                value: i,
                label: i,
            });
        }
        return obj;
    }
    sendRenewalEmail(voterId) {
        this.props
            .sendRenewalEmail(voterId)
            .then(res => {
                this.componentDidMount();
                this.showModal('Confirmation', `Renewal emails successfully sent.`, 'alertrefresh', false, true);
            })
            .catch(error => {
                this.showModal('Error', `An error occured during sending of renewal email`, 'alert', false, true);
                throw error;
            });
    }
    async search(page, pageSize, sorted, filtered) {
        this.showLoading(true);
        try {
            const res = await this.props.search(
                this.state.keyword,
                this.state.status.value,
                this.state.year.value,
                page,
                pageSize,
                sorted,
                filtered,
            );
            this.setState({
                list: res.data,
                loading: false,
                pageCount: res.totalNoOfPages,
                totalNum: res.totalNoOfItems,
                page,
            });
        } catch (error) {
            this.showLoading(false);
            throw error;
        }
    }
    async exportAllRenewals() {
        this.showLoading(true);
        try {
            const res = await this.props.exportRenewals(this.state.keyword, this.state.status.value, this.state.year.value);
            this.setState({
                exportlist: res.data,
                loading: false,
            });
            this.downloadCSV(res.data);
        } catch (error) {
            this.showLoading(false);
            throw error;
        }
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    yesNoUnknownIcons(val) {
        switch (val) {
            case 'Successfully Renewed':
                return <i className="material-icons align-text-bottom text-success">check_circle</i>;
            case 'Do not wish to renew':
                return <i className="material-icons align-text-bottom text-danger">remove_circle</i>;
            default:
                return <i className="fa fa-icon text-muted fa-question-circle" />;
        }
    }

    downloadCSV(data) {
        //Filter columns
        let filteredData = data.map(x => ({
            company_name: x.companyName,
            renewal_status: x.status,
            omr_name: `${x.omrFirstName} ${x.omrLastName}`,
            omr_email: x.omrEmail,
            corporate_admin_name: `${x.corporateFirstName} ${x.corporateLastName}`,
            corporate_admin_email: x.corporateEmail,
        }));

        const papa = require('papaparse');
        var csv = papa.unparse(filteredData);

        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `membership_renewal_${moment().format('DD-MM-YYYY_HH-MM-SS')}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }

        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `membership_renewal_${moment().format('DD-MM-YYYY_HH-MM-SS')}.csv`);
        tempLink.click();
    }

    render() {
        const columns = [
            {
                Header: 'Company',
                accessor: 'companyName',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 400,
            },
            {
                Header: 'Expiry Date',
                accessor: 'expiryDate',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <div>
                            {moment(props.value, 'YYYY-MM-DD').format('DD/MM/YYYY') == 'Invalid date'
                                ? '-'
                                : moment(props.value).format('DD/MM/YYYY')}
                        </div>
                    );
                },
                maxWidth: 130,
                minWidth: 100,
            },
            {
                Header: 'Status',
                accessor: 'status',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 200,
                minWidth: 100,
            },
            {
                Header: 'Renewal Email Timestamp',
                accessor: 'renewalEmailTimestamp',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                maxWidth: 180,
                Cell: props => {
                    if (props.value == null) {
                        return (
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Not yet sent
                            </span>
                        );
                    } else {
                        return moment(props.value).format('DD/MM/YYYY HH:mm');
                    }
                },
            },
            {
                Header: 'Resend Renewal',
                accessor: 'renewalId',
                sortable: false,
                maxWidth: 125,
                headerStyle: { whiteSpace: 'unset' },
                sortable: false,
                Cell: props => {
                    return (
                        <Button className="primary-btn-style primary-btn-xs" onClick={e => this.sendRenewalEmail(props.value)}>
                            <i className="mr-1 material-icons align-text-bottom ">send</i> Send
                        </Button>
                    );
                },
            },
            {
                Header: '',
                accessor: 'renewalId',
                sortable: false,
                sortable: false,
                maxWidth: 125,
                Cell: props => {
                    return (
                        <Link
                            to={`/staff/membership/membership-renewal/${props.value}?keyword=${this.state.keyword}&page=${this.state.page}&status=${this.state.status.value}&year=${this.state.year.value}`}
                        >
                            <Button className="primary-btn-style primary-btn-xs">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i> Edit
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Row className="mb-2">
                    <Col xs={10}>
                        <div className="form-inline">
                            <Input
                                xs={2}
                                style={{
                                    width: '200px',
                                    float: 'left',
                                    marginRight: '10px',
                                }}
                                onKeyDown={e => {
                                    if (e.keyCode == 13) {
                                        // this.table.onPageChange(10);
                                        this.table.fireFetchData();
                                    }
                                }}
                                placeholder={`Keyword`}
                                value={this.state.keyword}
                                onChange={this.onKeywordChange}
                            />
                            <Col xs={4}>
                                <Select
                                    name="selected-state"
                                    autosize={false}
                                    clearable={true}
                                    isMulti={false}
                                    disabled={false}
                                    searchable={true}
                                    value={this.state.status}
                                    options={this.state.statuses}
                                    onChange={e => this.onSelectChange(e, 'status')}
                                />
                            </Col>
                            <Col xs={3}>
                                <Select
                                    name="selected-state"
                                    autosize={false}
                                    clearable={true}
                                    isMulti={false}
                                    disabled={false}
                                    searchable={true}
                                    placeholder="Filter by year ..."
                                    value={this.state.year}
                                    options={this.state.years}
                                    onChange={e => this.onSelectChange(e, 'year')}
                                />
                            </Col>
                            <Button
                                style={{ float: 'left' }}
                                className="ml-1 mr-1 align-middle primary-btn-style"
                                onClick={() => this.table.fireFetchData()}
                            >
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <Button
                                style={{ float: 'left' }}
                                className="ml-1 mr-1 align-middle primary-btn-style"
                                onClick={() => this.exportAllRenewals()}
                                disabled={this.state.loading}
                            >
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Export
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            // PaginationComponent={CyderTablePagination}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, status, year, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, status, year, page, pageSize, sorted, filtered));
        },
        exportRenewals: (keyword, status, year) => {
            return dispatch(exportRenewals(keyword, status, year));
        },
        getRenewalStatus: () => {
            return dispatch(getRenewalStatus());
        },
        sendRenewalEmail: renewalId => {
            return dispatch(sendRenewalEmail(renewalId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
