import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Table, Button } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { membershipRenewalDetailAnalytics } from 'actions/analytics/memberAnalyticsAction';
import { HorizontalBar, Chart } from 'react-chartjs-2';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import { getAllEvents } from 'actions/admin/misc/resources/action.js';

class EventList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load(this.props.year).then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                this.props.selectEvent(props.value);
                            }}
                        >
                            {props.value}
                        </span>
                    );
                },
            },
            {
                Header: 'Summary',
                accessor: 'summary',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['summary'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                                this.props.selectEvent(props.original.name);
                            }}
                        >
                            {props.value}
                        </span>
                    );
                },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        defaultPageSize={5}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: year => {
            return dispatch(getAllEvents(year));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventList));
