/**
 * Service Application view page
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import ReactTable from 'react-table';

import matchSorter from 'match-sorter';
import { Row, Col, FormGroup, Label, Input, Container, Button } from 'reactstrap';
import Select from 'react-select';
import BigForm from 'cyder/forms/BigForm';
import DialogModal from 'cyder/modals/DialogModal';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';
import CyderApplicationStatusPanel from 'cyder/panels/CyderApplicationStatusPanel';
import { ServiceDetails } from './components/ServiceDetailsPanel';
import { checkDisabledCompleteAssessment } from '../product/ApplicationViewPage';
import { getAssessmentScoresheet } from 'actions/staff/certification/staffCertAssessmentAction';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import {
    loadPaymentMode,
    getApplicationInfo,
    unlinkInvoice,
    getCompanyInfo,
    getApplicationStatusInfo,
    getServiceCategoryInfo,
    getServiceAssessmentTemplatesByServiceCategory,
    getProductAssessmentTemplatesByProductType,
    acceptApplication,
    discontinueApplication,
    startAssessment,
    updateRemarks,
    updateStatus,
    completeAssessment,
    getAllStaffs,
    getAllCertManagers,
    getAllSuperAdmin,
    sendForApproval,
    approveApplication,
    rejectApplication,
    startFeedback,
    startPreparation,
    completeApplication,
    markAsPaid,
    getInvoiceInfo,
    authorizingInvoice,
    fetchXeroInventoryCodes,
    savePaymentInfo,
    generateCertNumber,
    fetchNumberOfTicks,
    takeOverCase,
    returnMua,
    getCertificatesByApplicationId,
    closeCase,
    getXeroInvoiceInfo,
    findApproverInfo,
    getPaymentDetails,
    createReceiptInS3ForCertificate,
    sendReceipt,
    getPaymentInfo,
    sendWithdrawalEmail,
    checkCertNumberDuplication,
    updateNumberSequence,
    getAllCertificateList,
    printCertificationApplication,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import {
    setTakeUpCase,
    setAssignApprover,
    setAssignNextApprover,
} from 'actions/member/certification/applications/memberServiceApplicationAction';
import { printCertByCertNumber, handleRegenerate, downloadCertificate } from 'actions/shared/certificationAction';
import { b64toBlob, pad, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

import modalMessageDict from 'pages/staff/certification/applications/product/data/modalMessageDict';
import { actionExecutor } from 'js/util';

const ApplicationStatus = ({ visible, status, step }) => {
    if (visible) {
        return (
            <div>
                <Row>
                    <Col>
                        <CyderApplicationStatusPanel status={status} step={step} />
                    </Col>
                </Row>
            </div>
        );
    } else {
        return null;
    }
};

const CertListModal = props => {
    const { loading, certificates } = props;
    const columns = [
        {
            Header: 'Running Cert No.',
            accessor: 'certNumber',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certNumber'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Status',
            accessor: 'status',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Company',
            accessor: 'companyName',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
            filterAll: true,
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: '',
            accessor: '',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            minWidth: 70,
            Cell: ({ value }) => {
                const url = `/staff/certification/applications/viewproduct/${value.certAppId}`;
                return (
                    <Button className="ml-2 align-middle primary-btn-style primary-btn-xs" onClick={() => history.push(url)}>
                        <i className="fas fa-folder-open mr-1" />
                        Open
                    </Button>
                );
            },
        },
    ];
    if (!certificates && !loading) return 'Failed to load certificate list.';
    return (
        <ReactTable
            columns={columns}
            filterable={true}
            defaultPageSize={10}
            className="-striped -highlight"
            data={certificates}
            defaultSorted={[
                {
                    id: 'certNumber',
                    desc: true,
                },
            ]}
        />
        // <CyderReactTable
        //     manual
        //     minRows={0}
        //     filterable={false}
        //     columns={columns}
        //     className="-highlight mb-2"
        //     showPagination={false}
        //     defaultPageSize={10}
        //     loading={loading}
        //     data={certificates}
        //     totalNum={certificates && certificates.length}
        // />
    );
};

class DialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.paymentDateOnChange = this.paymentDateOnChange.bind(this);
        this.state = {
            amountReceived: 0,
            bank: '',
            referenceNumber: '',
            remarks: '',
            datePaymentReceived: moment().format('YYYY-MM-DD'),
            selectedPaymentMode: '',
        };
    }
    componentDidMount() {
        var {
            amountReceived,
            bank,
            referenceNumber,
            remarks,
            datePaymentReceived,
            selectedPaymentMode,
            modeOfPaymentId,
        } = this.props.paymentInfo;
        if (selectedPaymentMode == null) {
            selectedPaymentMode = modeOfPaymentId;
        }
        this.props.paymentModes.forEach(element => {
            if (element.value == selectedPaymentMode) {
                selectedPaymentMode = element;
            }
        });
        if (datePaymentReceived == null) {
            datePaymentReceived = moment().format('YYYY-MM-DD');
        }
        this.setState({
            amountReceived,
            bank,
            referenceNumber,
            remarks,
            datePaymentReceived,
            selectedPaymentMode,
        });
    }
    setPaymentMode(e) {
        var paymentInfo = this.state;
        paymentInfo.modeOfPaymentId = e.value;
        // var modal = this.state.modal;
        // modal.refresh = new Date().getTime();
        this.setState({
            selectedPaymentMode: e,
            paymentInfo,
        });
        this.props.setPaymentMode(e);
    }
    onTextChanged(e) {
        var paymentInfo = this.state;
        paymentInfo[e.target.id] = e.target.id === 'amountReceived' ? parseFloat(e.target.value) : e.target.value;
        // var modal = this.state.modal;
        // modal.refresh = new Date().getTime();
        this.setState(paymentInfo);
        this.props.onTextChanged(e);
    }
    paymentDateOnChange(e) {
        var paymentInfo = Object.assign({}, this.state);
        paymentInfo.datePaymentReceived = e.format('YYYY-MM-DD');
        // var modal = this.state.modal;
        // modal.refresh = new Date().getTime();
        // console.log("paymentInfo.datePaymentReceived: ", paymentInfo.datePaymentReceived);
        this.setState(paymentInfo);
        this.props.paymentDateOnChange(e);
    }
    render() {
        let { amountReceived, bank, referenceNumber, remarks, datePaymentReceived, selectedPaymentMode } = this.state;
        // console.log("datePaymentReceived: ",datePaymentReceived)
        // console.log("this.props.paymentModes: ", this.props.paymentModes)
        if (!datePaymentReceived) {
            datePaymentReceived = moment().format('YYYY-MM-DD');
        }
        // console.log("amountReceived: ",amountReceived)
        return (
            <Col xs={12} className="update-payment">
                <span>* Mandatory fill </span>
                <FormGroup>
                    <Label>
                        Mode of payment
                        <MandatoryStar />
                    </Label>
                    <Select
                        type="select"
                        options={this.props.paymentModes}
                        value={selectedPaymentMode}
                        onChange={this.setPaymentMode}
                        name="selectedPaymentMode"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Amount Received
                        <MandatoryStar />
                    </Label>
                    <Input type="number" step="any" id="amountReceived" value={amountReceived} onChange={this.onTextChanged} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Bank
                        <MandatoryStar />
                    </Label>
                    <Input type="text" id="bank" onChange={this.onTextChanged} value={bank} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Reference No / Cheque No
                        <MandatoryStar />
                    </Label>
                    <Input type="text" id="referenceNumber" value={referenceNumber} onChange={this.onTextChanged} />
                </FormGroup>
                <FormGroup>
                    <Label>Remarks</Label>
                    <Input type="text" id="remarks" onChange={this.onTextChanged} value={remarks} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        Date Received
                        <MandatoryStar />
                    </Label>
                    <CyderDatePicker
                        placeholder=""
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect={true}
                        onChange={this.paymentDateOnChange}
                        value={datePaymentReceived || moment(datePaymentReceived).format('YYYY-MM-DD')}
                        width="100%"
                    />
                </FormGroup>
            </Col>
        );
    }
}

class ApplicationViewPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.unlinkInvoice = this.unlinkInvoice.bind(this);
        this.viewCertificates = this.viewCertificates.bind(this);
        this.sendWithdrawalEmail = this.sendWithdrawalEmail.bind(this);
        this.getPaymentDetails = this.getPaymentDetails.bind(this);
        this.paymentDateOnChange = this.paymentDateOnChange.bind(this);
        this.authorizeInvoice = this.authorizeInvoice.bind(this);
        this.viewCatalogue = this.viewCatalogue.bind(this);
        this.viewPicture = this.viewPicture.bind(this);
        this.onTemplateChange = this.onTemplateChange.bind(this);
        this.previewTemplate = this.previewTemplate.bind(this);
        this.takeUpConfirm = this.takeUpConfirm.bind(this);
        this.takeUpCase = this.takeUpCase.bind(this);
        this.addRemarks = this.addRemarks.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onRemarksChange = this.onRemarksChange.bind(this);
        this.discontinueApplication = this.discontinueApplication.bind(this);
        this.startAssessment = this.startAssessment.bind(this);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.startAssigningApprover = this.startAssigningApprover.bind(this);
        this.startAssigningNextApprover = this.startAssigningNextApprover.bind(this);
        this.sendForApproval = this.sendForApproval.bind(this);
        this.approveApplication = this.approveApplication.bind(this);
        this.rejectApplication = this.rejectApplication.bind(this);
        this.startFeedback = this.startFeedback.bind(this);
        this.startPreparation = this.startPreparation.bind(this);
        this.completeApplication = this.completeApplication.bind(this);
        this.markAsPaid = this.markAsPaid.bind(this);
        this.printCertificate = this.printCertificate.bind(this);
        this.updateServiceWithCertificate = this.updateServiceWithCertificate.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.sendToCaseOfficer = this.sendToCaseOfficer.bind(this);
        this.onSelectedOfficerChange = this.onSelectedOfficerChange.bind(this);
        this.onSelectedPeerChange = this.onSelectedPeerChange.bind(this);
        this.onSelectedManagerChange = this.onSelectedManagerChange.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onOverseasTransactionChargesChange = this.onOverseasTransactionChargesChange.bind(this);
        this.onDisableInvoiceChange = this.onDisableInvoiceChange.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.updateMarkUserAgreementStates = this.updateMarkUserAgreementStates.bind(this);
        this.editApplication = this.editApplication.bind(this);
        this.viewSurveyResult = this.viewSurveyResult.bind(this);
        this.returnMua = this.returnMua.bind(this);
        this.takeOverCase = this.takeOverCase.bind(this);
        this.generateCertNumber = this.generateCertNumber.bind(this);
        this.handleRegenerate = this.handleRegenerate.bind(this);
        this.printCert = this.printCert.bind(this);
        this.closeCase = this.closeCase.bind(this);
        this.getXeroInvoiceInfo = this.getXeroInvoiceInfo.bind(this);

        this.viewPreviousCertificate = this.viewPreviousCertificate.bind(this);
        this.viewPreviousAssessment = this.viewPreviousAssessment.bind(this);
        this.viewPreviousApplication = this.viewPreviousApplication.bind(this);
        this.createAndSendReceipt = this.createAndSendReceipt.bind(this);
        this.sendReceipt = this.sendReceipt.bind(this);
        this.downloadReceipt = this.downloadReceipt.bind(this);
        this.getPaymentInfo = this.getPaymentInfo.bind(this);
        this.showsLoading = this.showsLoading.bind(this);
        this.printMUA = this.printMUA.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            newTemplateChoosen: false,
            enableCloseCaseBtn: true,
            otCharges: 0,
            disableInvoice: 0,
            openCertificatesModal: false,
            completeAssessmentDisabled: true,
            loading: false,
            applicationDetails: {},
            companyDetails: {},
            applicationStatus: {},
            selectedTemplate: {
                label: '',
                value: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: false,
                negativeButtonHide: true,
                type: 'save',
                action: '',
            },
            paymentInfo: {
                amountReceived: '',
                bank: '',
                referenceNumber: '',
                remarks: '',
                modeOfPaymentId: '',
                datePaymentReceivedCtrl: '',
                datePaymentReceived: '',
            },
            paymentModes: '',
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            generatedCertNumber: '',
            certificationNumber: '',
            itemCode: this.props.itemCode ? this.props.itemCode[0] : '',
            numberOfTicks: '',
            muaNameError: '',
            muaContactNumberError: '',
            muaDesignationError: '',
            muaEmailError: '',
            otCharges: 0,
            enablePrintCert: false,
            sendReceiptPressed: false,
            isApplicantDetailsOpen: false,
            sendForApprovalClicked: false,
        };
    }
    async componentDidMount(oldApplicationId) {
        this.props.takeUpCase(false);
        const {
            fetchXeroInventoryCodes,
            getApplicationInfo,
            getCompanyInfo,
            getPaymentModes,
            getApplicationStatusInfo,
            getServiceCategoryInfo,
            getServiceAssessmentTemplatesByServiceCategory,
            getCertificatesByApplicationId,
        } = this.props;
        const id = oldApplicationId || this.props.match.params.id;
        this.props.fetchNumberOfTicks(id);

        try {
            this.showLoading(true);
            // GET - application info
            const applicationDetails = await getApplicationInfo(id);
            const { companyId, templateId, serviceCategoryId, certificateApplicationStatusId, assessmentId } = applicationDetails.data;

            // GET - others
            const xeroCodes = await fetchXeroInventoryCodes();
            const companyDetails = await getCompanyInfo(companyId);
            const applicationStatus = await getApplicationStatusInfo(certificateApplicationStatusId);
            const serviceCategory = await getServiceCategoryInfo(serviceCategoryId);

            if (applicationDetails.data.certificateToRenewOrAmendId != null) {
                this.props.setUsePrevAssessmentTemplaTe();
            }

            await this.getXeroInvoiceInfo(applicationDetails);
            await this.getApproverId(applicationDetails);

            this.props.resetAssigningApprover();

            // GET - payment mode options
            const response = await getPaymentModes();
            var paymentModes = [];
            response.data.map(({ id, mode }) => {
                if (id !== 4) {
                    paymentModes.push({
                        value: parseInt(id),
                        label: mode,
                    });
                }
            });
            if (applicationDetails.data.paymentId !== null) {
                await this.getPaymentDetails(applicationDetails.data.paymentId, paymentModes);
            }

            // GET - selected template
            const templates = await getServiceAssessmentTemplatesByServiceCategory(serviceCategoryId);
            const selectedTemplate = this.getChoosenTemplate(templates.data, templateId, serviceCategoryId);

            const certificates = await getCertificatesByApplicationId(id);

            const scoresheetInfo = await this.props.getAssessmentScoresheet(assessmentId);
            const disabledCompleteAssessment = checkDisabledCompleteAssessment(scoresheetInfo, 'service');

            // SET STATE
            if (this._isMounted) {
                let newTemplateChoosen = false;
                if (applicationDetails.data.certificateToRenewOrAmendId == null) {
                    newTemplateChoosen = true;
                }

                this.setState({
                    companyDetails,
                    applicationStatus,
                    certificateApplicationStatusId,
                    templates,
                    selectedTemplate,
                    serviceCategory,
                    xeroCodes,
                    paymentModes,
                    newTemplateChoosen,
                    applicationDetails: {
                        data: sanitizeStringAndObjectNullsToEmptyStrings(applicationDetails.data),
                    },
                    certificates,
                    completeAssessmentDisabled: disabledCompleteAssessment,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    // ACTION HANDLER
    async getPaymentDetails(id, paymentModes) {
        try {
            this.showLoading(true);
            let paymentDetails = null;
            paymentDetails = id === null ? null : await this.props.getPaymentDetails(id);
            paymentDetails = id === null ? null : paymentDetails.data;
            var selectedPaymentMode = {
                label: '',
                value: '',
            };

            if (paymentDetails !== null) {
                paymentModes.forEach(each => {
                    if (paymentDetails.modeOfPaymentId === each.value) {
                        selectedPaymentMode.label = each.label;
                        selectedPaymentMode.value = each.value;
                    }
                });
            }

            const { amountReceived, bank, referenceNumber, modeOfPaymentId, datePaymentReceived } = paymentDetails;

            var markAsPaidDisabled = true;
            if (!amountReceived || !bank || !referenceNumber || !modeOfPaymentId || !datePaymentReceived) {
                markAsPaidDisabled = true;
            } else {
                markAsPaidDisabled = false;
            }

            var paymentInfo = {
                amountReceived: paymentDetails === null ? '' : paymentDetails.amountReceived,
                bank: paymentDetails === null ? '' : paymentDetails.bank,
                referenceNumber: paymentDetails === null ? '' : paymentDetails.referenceNumber,
                remarks: paymentDetails === null ? '' : paymentDetails.remarks,
                modeOfPaymentId: paymentDetails === null ? '' : selectedPaymentMode.value,
                datePaymentReceived: paymentDetails === null ? '' : paymentDetails.datePaymentReceived,
            };

            this.setState({
                paymentInfo,
                selectedPaymentMode: selectedPaymentMode,
                markAsPaidDisabled,
            });
        } catch (error) {
            // throw error;
        } finally {
            this.showLoading(false);
        }
    }
    sendReceipt(certificatelink, id) {
        this.props.sendReceipt(certificatelink, id);
    }

    async printMUA() {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printCertificationApplication(this.props.match.params.id, 'service', true);
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        } catch (error) {
            this.showModal('Error', 'Application cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }

    async createAndSendReceipt() {
        let paymentData = await this.props.getPaymentInfo(this.state.applicationDetails.data.paymentId);
        if (paymentData != null && paymentData.data != null && paymentData.data.datePaymentReceived != null) {
            let data = await this.props.downloadReceipt(this.state.applicationDetails.data.paymentId);
            const hasError = this.showModalIf(
                'Error',
                modalMessageDict['downloadReceiptFailure'],
                !data || !this.state.applicationDetails.data.paymentId,
            );
            if (hasError) return;
            this.props.sendReceipt(data.file, this.props.match.params.id);
            this.setState({
                sendReceiptPressed: true,
            });
            this.showModal('Confirmation', 'Receipt successfully sent to Applicant.', 'Alert', null, true);
        } else {
            const hasError = this.showModalIf('Error', modalMessageDict['downloadReceiptFailureNoPaymentDone'], !paymentData.paymentStatus);
            if (hasError) return;
        }
        //on success modalDict(certReceiptSent)
    }
    async getPaymentInfo() {
        const formatDate = date => moment(date).format('YYYY-MM-DD');
        try {
            this.showLoading(true);
            let { data } = await this.props.getPaymentInfo(this.state.applicationDetails.data.paymentId);
            if (!data) {
                this.showModalIf('Error', modalMessageDict['getPaymentInfoFailure'], true);
                return;
            }

            // SET PAYMENT INFO
            const { amountReceived, bank, referenceNumber, remarks, modeOfPaymentId, receivedTimestamp } = data;
            const paymentInfo = {
                amountReceived,
                bank,
                referenceNumber,
                remarks,
                modeOfPaymentId,
                datePaymentReceived: moment(receivedTimestamp),
            };

            // SET SELECTED PAYMENT MODE
            const mode = this.state.paymentModes.find(x => x.value === modeOfPaymentId);
            const selectedPaymentMode = {
                value: mode ? mode.value : '',
                label: mode ? mode.label : '',
            };
            this.setState({ paymentInfo, selectedPaymentMode });
        } catch (error) {
            this.showLoading(false);
            this.showModalIf('Error', modalMessageDict['getPaymentInfoFailure'], true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async downloadReceipt() {
        try {
            this.showLoading(true);
            let paymentData = await this.props.getPaymentInfo(this.state.applicationDetails.data.paymentId);
            if (paymentData.data.paymentStatus == 1) {
                let data = await this.props.downloadReceipt(this.state.applicationDetails.data.paymentId);
                const hasError = this.showModalIf(
                    'Error',
                    modalMessageDict['downloadReceiptFailure'],
                    !data || !this.state.applicationDetails.data.paymentId,
                );
                if (hasError) return;
                window.open(data.file, '_blank');
            } else {
                const hasError = this.showModalIf(
                    'Error',
                    modalMessageDict['downloadReceiptFailureNoPaymentDone'],
                    !paymentData.paymentStatus,
                );
                if (hasError) return;
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async printCertificate() {
        const { service } = this.state.applicationDetails.data;
        const { printCert } = this.props;
        try {
            this.showLoading(true);
            const { certificationNumber } = JSON.parse(service);
            const { data } = await printCert(certificationNumber, 'service');
            var blob = b64toBlob(data, 'application/pdf');
            var blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        } catch (error) {
            this.showModalIf('Error', modalMessageDict['printCertificateFailure'], error);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async authorizeInvoice() {
        const { id } = this.state.applicationDetails.data;
        const { authorizingInvoice } = this.props;
        try {
            this.showLoading(true);
            const res = await authorizingInvoice(id);
            const falseCondition = res
                .toString()
                .toLowerCase()
                .includes('error');
            this.showModalIf('Error', modalMessageDict['authorizeInvoiceFailure'], falseCondition);
            this.showModalIf('Confirmation', modalMessageDict['authorizeInvoiceSuccess'], !falseCondition);
            this.componentDidMount();
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getApproverId(applicationDetails) {
        try {
            if (applicationDetails != null && applicationDetails.data != null) {
                let caseOfficer = null;
                caseOfficer = await this.props.findApproverInfo(applicationDetails.data.caseOfficerId);
                let peerOfficer = null;
                peerOfficer = await this.props.findApproverInfo(applicationDetails.data.peerReviewId);
                let managerOfficer = null;
                managerOfficer = await this.props.findApproverInfo(applicationDetails.data.managerId);

                this.setState({
                    caseOfficer,
                    peerOfficer,
                    managerOfficer,
                });
            }
        } catch (error) {
            throw error;
        } finally {
        }
    }
    async getXeroInvoiceInfo(applicationDetails) {
        try {
            if (applicationDetails != null && applicationDetails.data != null && applicationDetails.data.invoiceId != null) {
                const { data } = await this.props.getInvoiceInfo(applicationDetails.data.invoiceId);
                let xeroInvoice = await this.props.getXeroInvoiceInfo(data.invoiceNumber);
                this.setState({
                    xeroInvoice,
                });
            }
        } catch (error) {
            throw error;
        } finally {
        }
    }
    updateMarkUserAgreementStates(newStates) {
        this.setState(newStates);
    }
    async downloadInvoice() {
        const { getInvoiceInfo } = this.props;
        const { invoiceId } = this.state.applicationDetails.data;
        try {
            this.showLoading(true);
            let { data } = await getInvoiceInfo(invoiceId);
            const hasError = this.showModalIf('Error', modalMessageDict['downloadInvoiceFailure'], !data || !data.invoiceNumber);
            if (hasError) return;
            window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_pte_ltd&invoiceno=${data.invoiceNumber}`;
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async markAsPaid() {
        const { markAsPaid } = this.props;
        const { id } = this.state.applicationDetails.data;
        try {
            this.showLoading(true);
            const { ok } = await markAsPaid(id);
            this.showModalIf('Error', modalMessageDict['markAsPaidFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['markAsPaidSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startFeedback() {
        const { startFeedback } = this.props;
        const { id } = this.state.applicationDetails.data;
        try {
            let { ok } = await startFeedback(id);
            this.showModalIf('Error', modalMessageDict['startFeedbackFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['startFeedbackSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startPreparation() {
        let res = await this.props.startPreparation(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    viewPreviousCertificate(oldCertId) {
        history.push(`/staff/certification/certificates/service/certdetails/${oldCertId}`);
        // this.componentDidMount(oldCertId);
    }
    viewPreviousApplication(oldApplicationId) {
        window.location.href = `/staff/certification/applications/viewservice/${oldApplicationId}`;
        // this.componentDidMount(oldCertId);
    }
    viewPreviousAssessment(oldApplicationId, oldAssessmentId, oldAssessmentTemplateId) {
        this.setState({
            newTemplateChoosen: false,
        });
        history.push(
            `/staff/certification/applications/viewservicetemplate/${oldApplicationId}/${oldAssessmentTemplateId}/${oldAssessmentId}/${this.state.applicationDetails.data.id}`,
        );
        // this.componentDidMount(oldCertId);
    }
    async closeCase() {
        try {
            this.showLoading(true);
            let { data } = await this.props.closeCase(this.props.match.params.id);
            this.showModalIf('Confirmation', modalMessageDict['closeCaseConfimation'], data);
            this.showModalIf('Error', modalMessageDict['closeCaseFailure'], !data);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async completeApplication() {
        this.showLoading(true);
        const { id } = this.state.applicationDetails.data;
        const { completeApplication } = this.props;
        var service = JSON.parse(this.state.applicationDetails.data.service);
        service.issued_on = moment().format('YYYY-MM-DD');
        service.expiry_date = moment()
            .add(2, 'years')
            .add(-1, 'days')
            .format('YYYY-MM-DD');
        var serviceJson = JSON.stringify(service);

        try {
            let { ok, data } = await completeApplication(id, serviceJson, 'service', null);
            this.showModalIf('Error', modalMessageDict['completeApplicationFailure'], data.errno || !ok);
            this.showModalIf('Confirmation', modalMessageDict['completeApplicationSuccess'], data && !data.errno);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async printCert() {
        try {
            this.showLoading(true);
            await this.props.downloadCertificate(this.state.certificates.data[0].id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async handleRegenerate() {
        try {
            this.showLoading(true);
            const { data } = await this.props.handleRegenerate(this.state.certificates.data[0].id, 'service');
            this.showModalIf('Confirmation', modalMessageDict['handleRegenerateConfirm'], data);
            this.setState({ enableCloseCaseBtn: false, enablePrintCert: true });
            // alert('Certificate has been generated');
            // var blob = b64toBlob(data, 'application/pdf');
            // var blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async generateCertNumber() {
        let res = await this.props.generateCertNumber();
        var certNumber = res.data.certNumber;
        certNumber = 'SGBS ' + pad(certNumber, 4);
        this.setState({
            generatedCertNumber: certNumber,
        });
    }
    async addRemarks() {
        const { id, remarks } = this.state.applicationDetails.data;
        const { updateRemarks } = this.props;
        try {
            this.showLoading(true);
            const { ok } = await updateRemarks(id, remarks);
            this.showModalIf('Error', modalMessageDict['addRemarksFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['addRemarksSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async updateStatus() {
        const { id } = this.state.applicationDetails.data;
        const statusId = this.state.statusId.value;
        const { updateStatus } = this.props;
        try {
            this.showLoading(true);
            const { ok } = await updateStatus(id, statusId);
            this.showModalIf('Error', modalMessageDict['updateStatusFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['updateStatusSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async checkCertNumberDuplication(certNumber, applicationDetails) {
        // Reset Dialog
        this.setState({ openCertificatesModal: false, loading: false });
        try {
            this.showLoading(true);
            if (certNumber.length >= 1) {
                var result;
                var isFound = false;
                var service = JSON.parse(applicationDetails.data.service);
                var certNumberChanged = service.certificationNumber !== certNumber ? true : false;

                if (certNumberChanged) {
                    result = await this.props.checkCertNumberDuplication(certNumber);
                    isFound = result.data;
                }

                if (isFound) {
                    this.showModalIf('Error', modalMessageDict['certNumberDuplication'], isFound);
                }
            } else {
                this.showModalIf('Error', modalMessageDict['certNumberEmpty'], true);
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            return isFound;
        }
    }

    async sendToCaseOfficer() {
        const { applicationDetails, selectedOfficer, certificationNumber, numberOfTicks, itemCode } = this.state;
        const { ticks } = this.props;
        const codeProps = this.props.itemCode;
        var finalCertNumber = 'SGBS ' + pad(certificationNumber, 4);
        const isCertDuplicated = await this.checkCertNumberDuplication(finalCertNumber, applicationDetails);
        // //CHECK UNDEFINED
        if (!isCertDuplicated) {
            const hasError = this.showModalIf('Error', modalMessageDict['sendToCaseOfficerValid'], !selectedOfficer);
            if (hasError) return;
            this.showLoading(true);

            let details = Object.assign({}, applicationDetails);
            let service = details && details.data.service && JSON.parse(details.data.service);
            if (service.greenRemarks === null || service.greenRemarks === '') {
                service.greenRemarks = details.data.greenRemarks;
            }
            let serviceJson = JSON.stringify(service);

            const data = {
                certificationNumber: finalCertNumber,
                itemCode: itemCode ? itemCode : codeProps[0],
                ticks: numberOfTicks && numberOfTicks !== '' ? numberOfTicks : ticks,
                disableInvoice: this.state.disableInvoice == true ? 1 : 0,
            };

            try {
                if (Object.values(data)) {
                    const res = await this.props.saveCompleteAssessment(applicationDetails.data.id, selectedOfficer.value, data);
                    this.showModalIf('Error', modalMessageDict['sendToCaseOfficerFailure'], !res.ok);
                    if (res.ok) this.showModal('Confimation', modalMessageDict['sendToCaseOfficerConfirm'], 'pushToCertList', false, true);
                    // const res1 = await this.props.updateNumberSequence(1, 'service');
                }
            } catch (error) {
                throw error;
            } finally {
                this.setState({ numberOfTicks: '' });
                this.showLoading(false);
                this.componentDidMount();
            }
        }
    }
    async sendForApproval() {
        try {
            const { ok } = await this.props.sendForApproval(
                this.state.applicationDetails.data.id,
                this.state.selectedPeer.value,
                this.state.selectedManager.value,
                this.state.applicationDetails.data.service,
            );
            this.showModalIf('Error', modalMessageDict['sendForApprovalFailure'], !ok);
            if (ok) this.showModal('Confirmation', modalMessageDict['sendForApprovalSuccess'], 'pushToCertList', false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startAssigningNextApprover() {
        let sendForApprovalClicked = this.state.sendForApprovalClicked;
        sendForApprovalClicked = true;
        // let staffsRes = await this.props.getAllStaffs();
        // make staff to be same as managers
        // Refer to https://cydersg.atlassian.net/browse/SGBCCRM-1584
        let staffsRes = await this.props.getAllCertManagers();
        // change managers to be only super admin
        // https://cydersg.atlassian.net/browse/SUP-19
        let managersRes = await this.props.getAllSuperAdmin();
        let staffs = staffsRes.map((staff, i) => {
            return {
                label: staff.first_name + ' ' + staff.last_name,
                value: staff.id,
            };
        });
        let managers = managersRes.map((manager, i) => {
            return {
                label: manager.first_name + ' ' + manager.last_name,
                value: manager.id,
            };
        });
        this.setState({
            peers: staffs,
            managers,
            sendForApprovalClicked,
        });
        this.props.startAssigningNextApprover();
    }
    async unlinkInvoice() {
        this.props.unlinkInvoice(this.state.applicationDetails.data.id);
    }
    async startAssigningApprover() {
        let res = await this.props.getAllStaffs();
        this.generateCertNumber();
        let officers = res.map((officer, i) => {
            return {
                label: officer.first_name + ' ' + officer.last_name,
                value: officer.id,
            };
        });
        this.setState({
            officers,
        });
        this.props.startAssigningApprover();
    }
    async takeOverCase() {
        let res = await this.props.takeOverCase(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    async sendWithdrawalEmail() {
        try {
            this.showLoading(true);
            await this.props.sendWithdrawalEmail(this.state.applicationDetails.data.id);
            this.showModal('Confirmation', modalMessageDict['sendWithdrawalSuccess'], null, false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async takeUpConfirm() {
        const { selectedTemplate, applicationDetails, newTemplateChoosen } = this.state;
        const { acceptApplication } = this.props;
        try {
            this.showLoading(true);
            const { data } = await acceptApplication(
                applicationDetails.data.id,
                selectedTemplate.value,
                new Number(this.props.usePreviousAssessmentTemplate.value).valueOf(),
            );
            const hasError = this.showModalIf('Error', modalMessageDict['takeUpConfirmFailure'], data);
            if (hasError) this.props.takeUpCase(false);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async discontinueApplication() {
        const { discontinueApplication } = this.props;
        const { id } = this.state.applicationDetails.data;
        try {
            this.showLoading(true);
            const res = await discontinueApplication(id);
            this.showModalIf('Error', modalMessageDict['discontinueApplicationFailure'], !res.ok);
            this.showModalIf('Confirmation', 'Application (ID: ' + id + ') successfully discontinued.', res.ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startAssessment() {
        let res = await this.props.startAssessment(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    async approveApplication() {
        const { id, peerReviewStatus } = this.state.applicationDetails.data;
        const { approveApplication } = this.props;
        const approvaLevel = peerReviewStatus === 'Pending' ? 'peer' : 'manager';
        try {
            this.showLoading(true);
            const { ok } = await approveApplication(id, approvaLevel);
            this.showModalIf('Error', modalMessageDict['approveApplicationFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['approveApplicationSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async rejectApplication() {
        const remarks = 'rejected';
        const approvaLevel = this.state.applicationDetails.data.peerReviewStatus === 'Pending' ? 'peer' : 'manager';
        try {
            this.showLoading(false);
            const { ok } = await this.props.rejectApplication(this.state.applicationDetails.data.id, approvaLevel, remarks);
            this.showModalIf('Error', modalMessageDict['rejectApplicationFailure'], !ok);
            if (ok) this.showModal('Confirmation', modalMessageDict['rejectApplicationSuccess'], 'pushToCertList', false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    updateServiceWithCertificate(serviceObj, otCharges) {
        var applicationDetails = Object.assign({}, this.state.applicationDetails);
        var service = applicationDetails.data.service;
        try {
            service = JSON.parse(applicationDetails.data.service);
        } catch (e) {}

        if (!service) service = {};
        Object.assign(service, serviceObj);
        applicationDetails.data.service = JSON.stringify(service);

        this.setState({
            otCharges: otCharges || this.state.otCharges,
            applicationDetails,
        });
    }
    takeUpCase(take) {
        const { selectedTemplate, applicationDetails } = this.state;
        const hasError = this.showModalIf(
            'Error',
            modalMessageDict['takeUpCaseValid'],
            !selectedTemplate.value && !applicationDetails.data.templateId,
        );
        if (hasError) return;
        this.props.takeUpCase(take);
    }
    returnMua() {
        this.props.returnMua(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    editApplication() {
        history.push(`/staff/certification/edit-application-on-behalf/service/${this.state.applicationDetails.data.id}`);
    }
    viewSurveyResult() {
        this.props.setPostSurveyResultViewUrl(`/staff/certification/applications/viewservice/${this.state.applicationDetails.data.id}`);
        history.push(`/staff/certification/applications/survey/${this.state.applicationDetails.data.id}`);
    }
    getChoosenTemplate(templates, choosenTemplateId, serviceCategoryId) {
        var foundTemplate = {
            label: '',
            value: '',
        };
        templates.forEach(template => {
            if (template.id === choosenTemplateId) {
                foundTemplate = { label: template.title, value: template.id };
            }
        });
        // Recommend the template
        if (foundTemplate == null || foundTemplate.value == null || foundTemplate.value == '') {
            templates.forEach(template => {
                if (template.serviceCategoryId === serviceCategoryId) {
                    foundTemplate = { label: template.title, value: template.id };
                }
            });
        }
        return foundTemplate;
    }
    viewAssessment() {
        const { id, templateId, assessmentId } = this.state.applicationDetails.data;
        history.push(`/staff/certification/applications/viewservicetemplate/${id}/${templateId}/${assessmentId}/${id}`);
    }
    previewTemplate() {
        const { selectedTemplate, applicationDetails, newTemplateChoosen } = this.state;
        const hasError = this.showModalIf('Error', 'Please select an assessment template to preview', !newTemplateChoosen);
        if (hasError) return;
        this.props.takeUpCase(false);
        history.push(
            `/staff/certification/applications/viewservicetemplate/${applicationDetails.data.id}/${selectedTemplate.value}/0/${applicationDetails.data.id}`,
        );
    }
    viewPicture() {
        window.open(`${SGBC_S3_ROOT}${this.state.applicationDetails.data.productPicture}`);
    }
    viewCatalogue() {
        window.open(`${SGBC_S3_ROOT}${this.state.applicationDetails.data.productCatalogue}`);
    }
    // ONCAHNGE HANDLER
    handleChange(e) {
        const name = e.target.name;

        // Updated by Fernando on 20 June 2020
        // CRMSD-290, customer requested to remove this validation

        // if (e.target.name === 'certificationNumber') {
        //     var regex = '^$|^(([1-9]{1}[0-9]{0,3}){1})$';
        //     regex = new RegExp(regex);
        //     regex.test(e.target.value) && this.setState({ [name]: e.target.value });
        //     return;
        // } else {
        //     this.setState({ [name]: e.target.value });
        // }

        this.setState({ [name]: e.target.value });
    }
    onOverseasTransactionChargesChange(e) {
        this.setState({
            otCharges: new Number(e.target.checked).valueOf(),
        });
    }
    onDisableInvoiceChange(e) {
        this.setState({
            disableInvoice: new Number(e.target.checked).valueOf(),
        });
    }
    onTemplateChange(e) {
        this.setState({
            newTemplateChoosen: true,
            selectedTemplate: e,
        });
    }
    onSelectedOfficerChange(e) {
        this.setState({
            selectedOfficer: e,
        });
    }
    onSelectedPeerChange(e) {
        this.setState({
            selectedPeer: e,
        });
    }
    onSelectedManagerChange(e) {
        this.setState({
            selectedManager: e,
        });
    }
    onRemarksChange(e) {
        var applicationDetails = this.state.applicationDetails;
        applicationDetails.data.remarks = e.target.value;
        this.setState({
            applicationDetails,
        });
    }
    onStatusChange(e) {
        this.setState({
            statusId: e,
        });
    }
    // CONFIRMATION DIALOG
    markAsPaidConfirmation = () => {
        this.showModal('Confirmation', modalMessageDict['markAsPaidConfirmation'], 'markAsPaid');
    };
    // DIALOG MODAL
    modalAction() {
        if (this.state.modal.type === 'addRemarks') this.saveRemarks();
        if (this.state.modal.type === 'updatePayment') this.savePaymentInfo();
        if (this.state.modal.type === 'markAsPaid') this.markAsPaid();
        if (this.state.modal.type === 'authorizeInvoice') this.authorizeInvoice();
        if (this.state.modal.type === 'discontinue') this.discontinueApplication();
        if (this.state.modal.type === 'feedback') this.startFeedback();
        if (this.state.modal.type === 'finalisation') this.startFeedback();
        if (this.state.modal.type === 'pushToCertList') history.push(`/staff/certification/applications`);
        this.modalToggle();
    }

    async savePaymentInfo() {
        try {
            var paymentInfo = this.state.paymentInfo;
            const data = { ...paymentInfo };
            this.showLoading(true);
            const { error } = await this.props.savePaymentInfo(data, this.state.applicationDetails.data.id);
            this.showModalIf('Error', modalMessageDict['savePaymentInfoFailure'], error);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }

    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    onTextChanged(e) {
        var paymentInfo = this.state.paymentInfo;
        paymentInfo[e.target.id] = e.target.id === 'amountReceived' ? parseFloat(e.target.value) : e.target.value;
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            paymentInfo,
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, null, null, true);
        return evaluation;
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    showsLoading = loading => {
        this.setState({ loading });
    };
    viewCertificates() {
        this.setState({ openCertificatesModal: true });
        const callback = async () => {
            const { data } = await this.props.getAllCertificateList('service');
            this.setState({ certificatesList: data });
            this.showModal(
                'Certificates',
                <CertListModal loading={this.state.loading} certificates={this.state.certificatesList} />,
                null,
                true,
                true,
            );
        };
        actionExecutor(this.showsLoading, callback);
    }
    setPaymentMode(e) {
        var paymentInfo = this.state.paymentInfo;
        paymentInfo.modeOfPaymentId = e.value;
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            selectedPaymentMode: e,
            modal,
            paymentInfo,
        });
    }
    paymentDateOnChange(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.datePaymentReceived = e.format('YYYY-MM-DD');
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            paymentInfo,
            modal,
        });
    }
    updatePayment() {
        this.setState({ openCertificatesModal: false });
        this.showModal(
            'Update Payment Information',
            <DialogComponent
                paymentInfo={this.state.paymentInfo}
                onTextChanged={this.onTextChanged}
                paymentModes={this.state.paymentModes}
                selectedPaymentMode={this.state.selectedPaymentMode}
                setPaymentMode={this.setPaymentMode}
                paymentDateOnChange={this.paymentDateOnChange}
            />,
            'updatePayment',
            false,
            false,
        );
    }
    toggleApplicantDetailsOpen = () => {
        this.setState({ isApplicantDetailsOpen: !this.state.isApplicantDetailsOpen });
    };
    // RENDER
    render() {
        const {
            certificationNumber,
            paymentInfo,
            itemCode,
            numberOfTicks,
            muaEmailError,
            muaNameError,
            muaDesignationError,
            muaContactNumberError,
        } = this.state;
        let appStatus = '';
        const { modeOfPaymentId, ...restPaymentInfo } = paymentInfo;
        const stillEmpty = Object.values(restPaymentInfo).some(item => item === '');
        if (this.state.applicationStatus.data != null && this.state.applicationDetails.data != null) {
            appStatus = this.state.applicationStatus.data.status;
            if (appStatus === 'Approval') {
                if (this.state.applicationDetails.data.peerReviewStatus === 'Rejected') {
                    appStatus = 'Rejected by Peer';
                } else if (this.state.applicationDetails.data.peerReviewStatus === 'Pending') {
                    appStatus = 'Peer Approval';
                } else if (this.state.applicationDetails.data.managerStatus === 'Rejected') {
                    appStatus = 'Rejected by Manager';
                } else if (this.state.applicationDetails.data.managerStatus === 'Pending') {
                    appStatus = 'Manager Approval';
                }
            }
        }
        // REASSIGN STEP
        let applicationStep = (this.state.applicationStatus.data && this.state.applicationStatus.data.id) || 0;
        switch (applicationStep) {
            case 11:
                applicationStep = 0;
                break;
        }

        const muaPanel =
            this.state.certificateApplicationStatusId != null &&
            this.state.certificateApplicationStatusId >= 8 &&
            this.state.certificateApplicationStatusId <= 10 ? (
                <MarkUserAgreementPanel
                    applicationId={this.props.match.params.id}
                    readonly
                    updateMarkUserAgreementStates={this.updateMarkUserAgreementStates}
                    states={this.state}
                    applicationSource={`Certification`}
                    errors={{ muaEmailError, muaNameError, muaDesignationError, muaContactNumberError }}
                />
            ) : (
                ''
            );

        return (
            <div className="page-widget">
                <DialogModal
                    size={this.state.openCertificatesModal && 'lg'}
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                    refresh={this.state.modal.refresh}
                />
                {this.state.loading && <LoadingSpinner center />}
                {!this.state.loading && this.state.applicationDetails.data && this.state.applicationStatus.data && (
                    <BigForm
                        title={
                            this.state.applicationDetails.data.applicationTypeId !== 2
                                ? 'Services Certification Application'
                                : 'Services Renewal Certification Application'
                        }
                        description=""
                    >
                        <div>
                            <ApplicationStatus visible={true} step={applicationStep} />
                            <ServiceDetails
                                printMUA={this.printMUA}
                                visible={true}
                                selectState={this.state}
                                certificateDetails={{
                                    certificationNumber,
                                    itemCode,
                                    numberOfTicks,
                                }}
                                viewCertificates={this.viewCertificates}
                                sendWithdrawalEmail={this.sendWithdrawalEmail}
                                paymentInfo={this.state.paymentInfo}
                                takeUpCase={this.takeUpCase}
                                applicationId={this.props.applicationId}
                                takeUpConfirm={this.takeUpConfirm}
                                previewTemplate={this.previewTemplate}
                                prevAssessmentId={this.state.applicationDetails.data.assessmentId}
                                previewOldTemplate={this.viewAssessment}
                                applicationTypeOldRenew={this.state.applicationDetails.data.applicationTypeId}
                                selectedServiceCategory={
                                    this.state.serviceCategory && this.state.serviceCategory.data
                                        ? this.state.serviceCategory.data.category
                                        : 'None'
                                }
                                addRemarks={this.addRemarks}
                                statusId={this.state.statusId}
                                updateStatus={this.updateStatus}
                                greenRemarks={this.state.applicationDetails.data.greenRemarks}
                                onRemarksChange={this.onRemarksChange}
                                onStatusChange={this.onStatusChange}
                                onHandleChange={this.handleChange}
                                remarks={this.state.applicationDetails.data.remarks}
                                completeAssessment={this.startAssigningApprover}
                                unlinkInvoice={this.unlinkInvoice}
                                completeAssessmentDisabled={this.state.completeAssessmentDisabled}
                                sendForApproval={this.sendForApproval}
                                sendToCaseOfficer={this.sendToCaseOfficer}
                                officers={this.state.officers}
                                selectedOfficer={this.state.selectedOfficer}
                                onSelectedOfficerChange={this.onSelectedOfficerChange}
                                sendForNextApproval={this.startAssigningNextApprover}
                                templates={this.state.templates}
                                selectedTemplate={this.state.selectedTemplate}
                                onTemplateChange={this.onTemplateChange}
                                newTemplateChoosen={this.state.newTemplateChoosen}
                                discontinue={this.discontinueApplication}
                                startAssessment={this.startAssessment}
                                viewAssessment={this.viewAssessment}
                                updatePayment={this.updatePayment}
                                downloadReceipt={this.downloadReceipt}
                                viewCatalogue={this.viewCatalogue}
                                viewPicture={this.viewPicture}
                                takeUpCaseStarted={this.props.application.view.takeUpCaseStarted}
                                assignApproverStarted={this.props.application.view.assignApproverStarted}
                                assignNextApproverStarted={this.props.application.view.assignNextApproverStarted}
                                productBrands={this.props.application.new.productBrands}
                                status={appStatus}
                                isSelfFinancingCapable={this.state.applicationDetails.data.isSelfFinancingCapable}
                                submittedOn={
                                    this.state.applicationDetails.data.applicationFirstSubmittedTimestamp != null &&
                                    this.state.applicationDetails.data.applicationFirstSubmittedTimestamp != ''
                                        ? moment(this.state.applicationDetails.data.applicationFirstSubmittedTimestamp).format('DD/MM/YYYY')
                                        : '-'
                                }
                                title={this.state.companyDetails.data.name}
                                selectedPeer={this.state.selectedPeer}
                                peers={this.state.peers}
                                peerReviewStatus={this.state.applicationDetails.data.peerReviewStatus}
                                onSelectedPeerChange={this.onSelectedPeerChange}
                                selectedManager={this.state.selectedManager}
                                managers={this.state.managers}
                                managerStatus={this.state.applicationDetails.data.managerStatus}
                                peerReviewRemarks={this.state.applicationDetails.data.peerReviewRemarks}
                                managerRemarks={this.state.applicationDetails.data.managerRemarks}
                                editApplication={this.editApplication}
                                onSelectedManagerChange={this.onSelectedManagerChange}
                                approve={this.approveApplication}
                                reject={this.rejectApplication}
                                startFeedback={this.startFeedback}
                                startPreparation={this.startPreparation}
                                completeApplication={this.completeApplication}
                                markAsPaid={this.markAsPaidConfirmation}
                                type={this.state.applicationDetails.data.isProductCertificateApplication === 1 ? 'product' : 'service'}
                                otCharges={this.state.otCharges}
                                disableInvoice={this.state.disableInvoice}
                                updateServiceWithCertificate={this.updateServiceWithCertificate}
                                downloadInvoice={this.downloadInvoice}
                                applicationDetails={this.state.applicationDetails.data}
                                authorizeInvoice={this.authorizeInvoice}
                                itemCode={this.props.itemCode}
                                viewSurveyResult={this.viewSurveyResult}
                                printCertificate={this.printCertificate}
                                generatedCertNumber={this.state.generatedCertNumber}
                                manufacturerContactPersonName={this.state.applicationDetails.data.manufacturerContactPersonName}
                                manufacturerTelephone={this.state.applicationDetails.data.manufacturerTelephone}
                                otCharges={this.state.otCharges}
                                onOverseasTransactionChargesChange={this.onOverseasTransactionChargesChange}
                                onDisableInvoiceChange={this.onDisableInvoiceChange}
                                takeOverCase={this.takeOverCase}
                                returnMua={this.returnMua}
                                certificates={this.state.certificates}
                                printCert={this.printCert}
                                handleRegenerate={this.handleRegenerate}
                                closeCase={this.closeCase}
                                xeroInvoice={this.state.xeroInvoice}
                                profile={this.props.profile}
                                approvers={{
                                    caseOfficer: this.state.caseOfficer,
                                    peerOfficer: this.state.peerOfficer,
                                    managerOfficer: this.state.managerOfficer,
                                }}
                                viewPreviousCertificate={this.viewPreviousCertificate}
                                viewPreviousApplication={this.viewPreviousApplication}
                                viewPreviousAssessment={this.viewPreviousAssessment}
                                createAndSendReceipt={this.createAndSendReceipt}
                                toggleApplicantDetailsOpen={this.toggleApplicantDetailsOpen}
                                isApplicantDetailsOpen={this.state.isApplicantDetailsOpen}
                                muaPanel={muaPanel}
                                markAsPaidDisabled={this.state.markAsPaidDisabled}
                                sendForApprovalClicked={this.state.sendForApprovalClicked}
                            />
                        </div>
                    </BigForm>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const xero = state.staffCertificationApplicationReducer && state.staffCertificationApplicationReducer.xeroCodes;
    const ticks = state.staffCertificationApplicationReducer && state.staffCertificationApplicationReducer.ticks;
    const itemCode = xero && xero.map(item => item.itemCode);
    const applicationId = Number(ownProps.match.params.id);
    const profile = state.profile;

    return {
        ownProps,
        application: state.memberServiceApplication,
        assessment: state.memberServiceAssessment,
        certifications: state.memberCertifications,
        xeroCodes: state.staffCertificationApplicationReducer.xeroCodes,
        itemCode,
        applicationId,
        ticks,
        profile,
        usePreviousAssessmentTemplate: state.staffCertificationApplicationReducer.usePreviousAssessmentTemplate,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        takeUpCase: take => {
            dispatch(setTakeUpCase(take));
        },
        resetAssigningApprover: () => {
            dispatch(setAssignApprover(false));
            dispatch(setAssignNextApprover(false));
        },
        startAssigningApprover: () => {
            dispatch(setAssignApprover(true));
        },
        sendForApproval: (id, selectedPeerId, selectedManagerId, service) => {
            return dispatch(sendForApproval(id, selectedPeerId, selectedManagerId, null, service));
        },
        startAssigningNextApprover: () => {
            dispatch(setAssignNextApprover(true));
        },
        getApplicationInfo: id => {
            return dispatch(getApplicationInfo(id));
        },
        getCompanyInfo: id => {
            return dispatch(getCompanyInfo(id));
        },
        getApplicationStatusInfo: id => {
            return dispatch(getApplicationStatusInfo(id));
        },
        getServiceAssessmentTemplatesByServiceCategory: id => {
            return dispatch(getServiceAssessmentTemplatesByServiceCategory(id));
        },
        acceptApplication: (id, templateId, newTemplateChoosen) => {
            return dispatch(acceptApplication(id, templateId, newTemplateChoosen));
        },
        discontinueApplication: id => {
            return dispatch(discontinueApplication(id));
        },
        startAssessment: id => {
            return dispatch(startAssessment(id));
        },
        saveCompleteAssessment: (id, selectedOfficerId, service) => {
            return dispatch(completeAssessment(id, selectedOfficerId, null, service));
        },
        unlinkInvoice: id => {
            return dispatch(unlinkInvoice(id));
        },
        updateRemarks: (id, remarks) => {
            return dispatch(updateRemarks(id, remarks));
        },
        updateStatus: (id, statusId) => {
            return dispatch(updateStatus(id, statusId));
        },
        getAllStaffs: () => {
            return dispatch(getAllStaffs());
        },
        getAllCertManagers: () => {
            return dispatch(getAllCertManagers());
        },
        getAllSuperAdmin: () => {
            return dispatch(getAllSuperAdmin());
        },
        approveApplication: (id, approvalLevel) => {
            return dispatch(approveApplication(id, approvalLevel));
        },
        rejectApplication: (id, approvalLevel, remarks) => {
            return dispatch(rejectApplication(id, approvalLevel, remarks));
        },
        startFeedback: id => {
            return dispatch(startFeedback(id));
        },
        startPreparation: id => {
            return dispatch(startPreparation(id));
        },
        completeApplication: (id, service, type) => {
            return dispatch(completeApplication(id, null, type, service));
        },
        markAsPaid: id => {
            return dispatch(markAsPaid(id));
        },
        getInvoiceInfo: id => {
            return dispatch(getInvoiceInfo(id));
        },
        getProductAssessmentTemplatesByProductType: id => {
            return dispatch(getProductAssessmentTemplatesByProductType(id));
        },
        getServiceCategoryInfo: id => {
            return dispatch(getServiceCategoryInfo(id));
        },
        authorizingInvoice(id) {
            return dispatch(authorizingInvoice(id));
        },
        printCert: certNumber => {
            return dispatch(printCertByCertNumber(certNumber, 'service'));
        },
        setPostSurveyResultViewUrl: postSurveyResultViewUrl => {
            return dispatch({
                type: 'SHARED_SURVEY_SET_POST_SURVEY_RESULT_VIEW_URL',
                postSurveyResultViewUrl,
            });
        },
        fetchXeroInventoryCodes: () => dispatch(fetchXeroInventoryCodes()),
        fetchNumberOfTicks: id => dispatch(fetchNumberOfTicks(id)),
        generateCertNumber: () => {
            return dispatch(generateCertNumber('SGBS'));
        },
        getPaymentModes: () => {
            return dispatch(loadPaymentMode());
        },
        getPaymentDetails: id => {
            return dispatch(getPaymentDetails(id));
        },
        takeOverCase: id => {
            return dispatch(takeOverCase(id));
        },
        savePaymentInfo: (paymentInfo, id) => {
            return dispatch(savePaymentInfo(paymentInfo, id));
        },
        returnMua: id => {
            return dispatch(returnMua(id));
        },
        closeCase: id => {
            return dispatch(closeCase(id));
        },
        getCertificatesByApplicationId: id => {
            return dispatch(getCertificatesByApplicationId(id));
        },
        handleRegenerate: (certificateId, type) => {
            return dispatch(handleRegenerate(certificateId, type));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
        getXeroInvoiceInfo: certificationNumber => {
            return dispatch(getXeroInvoiceInfo(certificationNumber));
        },
        findApproverInfo: approverId => {
            return dispatch(findApproverInfo(approverId));
        },
        sendReceipt: (certificatelink, id) => {
            return dispatch(sendReceipt(certificatelink, id));
        },
        downloadReceipt: paymentId => {
            return dispatch(createReceiptInS3ForCertificate(paymentId));
        },
        getPaymentInfo: paymentId => {
            return dispatch(getPaymentInfo(paymentId));
        },
        getAssessmentScoresheet: id => {
            return dispatch(getAssessmentScoresheet(id));
        },
        sendWithdrawalEmail: id => {
            return dispatch(sendWithdrawalEmail(id));
        },
        checkCertNumberDuplication: certNumber => {
            return dispatch(checkCertNumberDuplication(certNumber));
        },
        setUsePrevAssessmentTemplaTe: () => {
            return dispatch({
                type: 'STAFF_CERT_APPLICATION_USE_PREV_TEMPLATE',
            });
        },
        updateNumberSequence: (value, type) => {
            return dispatch(updateNumberSequence(value, type));
        },
        getAllCertificateList: type => {
            return dispatch(getAllCertificateList(type));
        },
        printCertificationApplication: (id, type, muaLocked) => {
            return dispatch(printCertificationApplication(id, type, muaLocked));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationViewPage),
);
