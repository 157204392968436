/**
 * Service details panel
 *
 * Author: Fernando Karnagi
 */
import React from 'react';

import { Row, Col, Input, Button, CardBody, Collapse } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import TakeUpCasePanel from 'pages/staff/certification/applications/case/TakeUpCasePanel';
import AssignApproverPanel from 'pages/staff/certification/applications/approval/AssignApproverPanel';
import AssignOfficerPanel from 'pages/staff/certification/applications/approval/AssignOfficerPanel';
import CreateCertificateDetailsPanel from 'pages/staff/certification/applications/approval/CreateCertificateDetailsPanel';
import ProductTaggingPanel from 'pages/staff/certification/applications/product/components/ProductTaggingPanel';
import ScoresheetViewPanel from 'pages/staff/certification/assessment/components/ScoresheetViewPanel';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';
import UpdateStatusPopup from 'pages/staff/certification/applications/update/UpdateStatusPopup';

const buttonStyle = {
    display: 'flex',
    flex: 1,
    float: 'left',
};

export const ProductDetailsButton = ({ width, btnProps, onClick, icon, label, color, className, disabled }) => {
    return (
        <ButtonIcon
            borderStyle={{ marginRight: '10px', marginBottom: '10px' }}
            width={width === 'auto' ? '' : width || '210px'}
            icon={icon}
            label={label}
            onClick={onClick}
            buttonColor={color}
            disabled={disabled}
            className={className + ' text-capitalize text-center'}
            fontStyle={{ justifyContent: 'center' }}
            {...btnProps}
        />
    );
};

export const ProductCertDetails = ({ brands, viewProductCertificate, printCert, buttonStyle }) => {
    if (brands == null || brands.length <= 0) {
        return '';
    }
    var ret = brands[0].models.map((model, i) => {
        return (
            <React.Fragment key={i}>
                <ProductDetailsButton
                    colStyle={buttonStyle}
                    onClick={e => printCert(model.certificationNumber)}
                    icon="payment"
                    label={`Download ${model.certificationNumber}`}
                />
                <ProductDetailsButton
                    colStyle={buttonStyle}
                    icon="file_copy"
                    onClick={e => viewProductCertificate(model.certificationNumber)}
                    icon="payment"
                    label={`View ${model.certificationNumber} Details`}
                />
            </React.Fragment>
        );
    });
    return ret;
};

export class StatusLabel extends React.Component {
    getStatusRemark() {
        const {
            additionalInfo,
            muaLocked,
            caseClosed,
            xeroInvoice,
            approvers,
            status,
            applicationTypeId,
            muaPanel,
            withInvoice,
        } = this.props;
        const val = approvers && approvers.peerOfficer && approvers.peerOfficer.data ? approvers.peerOfficer.data.first_name : `-`;
        const applicationType =
            applicationTypeId === 1 ? 'New application' : applicationTypeId == 2 ? 'Renewal application' : 'Amendment of scope application';
        switch (status) {
            case 'Draft':
                return 'This application is in draft, has not been submitted.';
            case 'Submitted':
                return (
                    <label className="text-bold">
                        {applicationType} submitted on ({additionalInfo})
                        <br />
                        Please scroll down and review the details of application. Click 'Next' at the buttom of the page to proceed.
                    </label>
                );
            case 'Accepted':
                return 'Application Accepted. Pending Applicant submission(s).';
            case 'Assessment':
                return 'Assessment in progress.';
            case 'Approval':
                return 'Assessment pending approval.' + (withInvoice == 0 ? ' This application does not have invoice.' : '');
            case 'Peer Approval':
                return 'Assessment pending Officer Verification.' + (withInvoice == 0 ? ' This application does not have invoice.' : '');
            case 'Manager Approval':
                if (withInvoice == 0) {
                    return 'Assessment pending Management Approval. This application does not have invoice.';
                } else if (xeroInvoice && xeroInvoice.data && xeroInvoice.data.length > 0 && xeroInvoice.data[0].Status == 'AUTHORISED') {
                    return 'Assessment pending Management Approval. Invoice is authorised.';
                } else if (xeroInvoice && xeroInvoice.data && xeroInvoice.data.length > 0 && xeroInvoice.data[0].Status == 'PAID') {
                    return 'Assessment pending Management Approval. Invoice is paid.';
                } else {
                    return 'Assessment pending Management Approval. Invoice is not authorised yet.';
                }
            case 'Invoice':
                return (
                    <div>
                        Invoice processed and sent to Applicant. <br />
                        Click on <b>Update Payment Info</b> to verify payment before proceeding with <b>Mark As Paid</b>.
                    </div>
                );
            case 'Paid':
                return 'Payment Successful.';
            case 'Finalisation':
                if (muaLocked == 0)
                    return (
                        'Pending Mark User Agreement and application details confirmation.' +
                        (withInvoice == 0 ? ' This application does not have invoice.' : '')
                    );
                return (
                    <div>
                        Mark User Agreement submitted. <br />
                        Click on <b>View MUA</b> to verify Authorised Personnel Details and certification information before proceeding with
                        certification generation.
                    </div>
                );
            case 'Issued':
                if (caseClosed == 0) {
                    return (
                        <label>
                            Finalisation completed.
                            <br />
                            <br />
                            Click on <b>Generate Certificate PDF</b> to proceed with certificate(s) generation. <br />
                            Click on <b>Close The Case</b> to complete assessment.
                        </label>
                    );
                }
                return 'Assessment complete.' + (withInvoice == 0 ? ' This application does not have invoice.' : '');
            case 'Preparation':
                return 'Preparation is in progress.' + (withInvoice == 0 ? ' This application does not have invoice.' : '');
            case 'Discontinued':
                return 'Application has been discontinued.';
        }
    }
    render() {
        const { status, peerReviewStatus, managerStatus, peerReviewRemarks, managerRemarks } = this.props;
        return (
            <div className="form-group">
                <div style={{ fontSize: '17px', marginBottom: '10px' }}>{this.getStatusRemark()}</div>
                {peerReviewStatus === 'Rejected' && (
                    <div className="field-label">Rejected by peer officer with this comment: {peerReviewRemarks}</div>
                )}
                {managerStatus === 'Rejected' && <div className="field-label">Rejected by manager with this comment: {managerRemarks}</div>}
                <br />
                {/* {status != 'Submitted' && status != 'Draft' && status != 'Issued' && <label>Please choose the action below</label>} */}
            </div>
        );
    }
}

export const ProductDetailsPanel = ({
    applicationDetails,
    visible,
    title,
    status,
    selectState,
    remarks,
    greenRemarks,
    updateStatus,
    onStatusChange,
    prevAssessmentId,
    previewOldTemplate,
    applicationTypeOldRenew,
    manufacturerDetails,
    statusId,
    newTemplateChoosen,
    isManufacturer,
    selectedProductType,
    submittedOn,
    viewCatalogue,
    viewPicture,
    onRemarksChange,
    addRemarks,
    startAssessment,
    assessmentStarted,
    viewAssessment,
    viewAssessmentStarted,
    takeUpCase,
    takeUpCaseStarted,
    takeUpConfirm,
    templates,
    selectedTemplate,
    onTemplateChange,
    previewTemplate,
    completeAssessment,
    unlinkInvoice,
    completeAssessmentDisabled,
    assignApproverStarted,
    sendForApproval,
    sendToCaseOfficer,
    selectedOfficer,
    officers,
    onSelectedOfficerChange,
    assignNextApproverStarted,
    sendForNextApproval,
    updatePayment,
    downloadReceipt,
    discontinue,
    certificateDetails,
    selectedPeer,
    peers,
    onSelectedPeerChange,
    peerReviewStatus,
    selectedManager,
    managers,
    onSelectedManagerChange,
    managerStatus,
    approve,
    reject,
    startFeedback,
    startPreparation,
    completeApplication,
    markAsPaid,
    type,
    downloadInvoice,
    authorizeInvoiceDialog,
    discontinueDialog,
    startFeedbackDialog,
    productBrands,
    updateProductBrandsWithCertificate,
    unassignedBrandFromCertificate,
    printCertificate,
    peerReviewRemarks,
    managerRemarks,
    onHandleChange,
    taggingPanelVisible,
    applicationId,
    itemCode,
    editApplication,
    viewSurveyResult,
    onOverseasTransactionChargesChange,
    onDisableInvoiceChange,
    otCharges,
    disableInvoice,
    takeOverCase,
    returnMua,
    handleRegenerate,
    printCertByCertNumber,
    viewProductCertificateByCertNumber,
    closeCase,
    xeroInvoice,
    profile,
    approvers,
    viewPreviousAssessment,
    viewPreviousApplication,
    viewPreviousCertificate,
    sendWithdrawalEmail,
    checkCertNumberDuplication,
    attachPreviousTemplate,
    createAndSendReceipt,
    createAndSendReceiptConfirmation,
    toggleApplicantDetailsOpen,
    isApplicantDetailsOpen,
    muaPanel,
    markAsPaidDisabled,
    sendForApprovalClicked,
    paymentInfo,
    viewCertificates,
    handleBlur,
    printMUA,
    removeInvoice,
}) => {
    const {
        manufacturerFactoryAddress,
        manufacturerNameOfCompanyPlant,
        manufacturerNameOfCompany,
        manufacturerFactoryAddressPlant,
        manufacturerCountryOfOrigin,
        certificateToRenewOrAmendId,
        oldCertificateApplicationId,
        oldAssessmentId,
        oldAssessmentTemplateId,
        manufacturerContactPersonName,
        manufacturerTelephone,
        muaLocked,
        tagging,
    } = applicationDetails;

    let brands = [];
    try {
        if (productBrands != null) brands = JSON.parse(productBrands);
    } catch (e) {}

    if (!visible) return '';
    return (
        <NoShadowPanel title={title} borderSize={1} flat hasNolineBreak titleStyle={{ color: '#317446', fontSize: '3.25em' }}>
            <div>
                {status != 'Draft' && (
                    <Row>
                        <Col className="align-items-center text-bold" style={{ textAlign: 'right' }}>
                            {profile.profile.is_superuser === 1 && (
                                <UpdateStatusPopup
                                    updateStatus={updateStatus}
                                    onStatusChange={onStatusChange}
                                    type="button"
                                    statusId={statusId}
                                />
                            )}
                            <AuditListPopup contentTypeId={49} withIcon type="button" objectId={applicationDetails.id} />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs="12">
                        <StatusLabel
                            status={status}
                            peerReviewStatus={peerReviewStatus}
                            managerStatus={managerStatus}
                            peerReviewRemarks={peerReviewRemarks}
                            managerRemarks={managerRemarks}
                            additionalInfo={submittedOn}
                            muaLocked={muaLocked}
                            caseClosed={applicationDetails.caseClosed}
                            withInvoice={applicationDetails.withInvoice}
                            xeroInvoice={xeroInvoice}
                            approvers={approvers}
                            applicationTypeId={applicationDetails.applicationTypeId}
                            muaPanel={muaPanel}
                        />

                        {status === 'Submitted' && (
                            <React.Fragment>
                                <TakeUpCasePanel
                                    templates={templates}
                                    takeUpConfirm={takeUpConfirm}
                                    previewTemplate={previewTemplate}
                                    onTemplateChange={onTemplateChange}
                                    selectedTemplate={selectedTemplate}
                                    takeUpCaseStarted={takeUpCaseStarted}
                                    previewOldTemplate={previewOldTemplate}
                                    newTemplateChoosen={newTemplateChoosen}
                                    applicationTypeNewRenew={applicationTypeOldRenew}
                                    prevAssessmentId={prevAssessmentId}
                                    applicationDetails={applicationDetails}
                                />
                            </React.Fragment>
                        )}
                        <div className="form-group">
                            {status === 'Assessment' && !assessmentStarted && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}
                            {(status === 'Peer Approval' ||
                                status === 'Manager Approval' ||
                                status === 'Rejected by Peer' ||
                                status === 'Rejected by Manager') && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}
                            {status === 'Approval' && !viewAssessmentStarted && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}

                            {status !== 'Draft' &&
                                status !== 'Submitted' &&
                                status !== 'Finalisation' &&
                                status !== 'Discontinued' &&
                                status !== 'Assessment' &&
                                status !== 'Issued' &&
                                applicationDetails.withInvoice == 1 &&
                                status !== 'Accepted' && (
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={downloadInvoice}
                                        icon="receipt"
                                        label="Download Invoice"
                                    />
                                )}
                            {status === 'Paid' && applicationDetails.withInvoice == 1 && (
                                <React.Fragment>
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={updatePayment}
                                        icon="payment"
                                        label="Update Payment Info"
                                    />
                                </React.Fragment>
                            )}
                            {(status === 'Paid' || status === 'Finalisation' || status === 'Issued') &&
                                applicationDetails.withInvoice == 1 && (
                                    <React.Fragment>
                                        <ProductDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={downloadReceipt}
                                            icon="receipt"
                                            label="Download Receipt"
                                        />
                                    </React.Fragment>
                                )}
                            {status === 'Finalisation' && (
                                <React.Fragment>
                                    {muaLocked == 1 && (
                                        <ProductDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={returnMua}
                                            icon="keyboard_return"
                                            label="Return MUA"
                                        />
                                    )}
                                </React.Fragment>
                            )}
                            {(status === 'Issued' || status === 'Finalisation') && (
                                <React.Fragment>
                                    {muaLocked == 1 && (
                                        <ProductDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={printMUA}
                                            icon="remove_red_eye"
                                            label="View MUA"
                                        />
                                    )}

                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={viewSurveyResult}
                                        icon="feedback"
                                        label="View Survey Submission"
                                    />
                                </React.Fragment>
                            )}
                            {(status === 'Submitted' ||
                                status === 'Accepted' ||
                                status === 'Assessment' ||
                                status === 'Approval' ||
                                status === 'Rejected by Peer' ||
                                status === 'Rejected by Manager') &&
                                !assessmentStarted && (
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        icon="edit"
                                        label="Edit Application"
                                        onClick={editApplication}
                                    />
                                )}
                            {(status === 'Submitted' || status === 'Accepted' || status === 'Assessment' || status === 'Approval') &&
                                certificateToRenewOrAmendId && (
                                    <React.Fragment>
                                        <ProductDetailsButton
                                            colStyle={buttonStyle}
                                            icon="file_copy"
                                            label="View Previous Certificate"
                                            onClick={() => viewPreviousCertificate(certificateToRenewOrAmendId)}
                                        />
                                        {oldAssessmentId && oldAssessmentTemplateId && (
                                            <ProductDetailsButton
                                                colStyle={buttonStyle}
                                                icon="format_list_bulleted"
                                                label="View Previous Assessment"
                                                onClick={() =>
                                                    viewPreviousAssessment(
                                                        oldCertificateApplicationId,
                                                        oldAssessmentId,
                                                        oldAssessmentTemplateId,
                                                    )
                                                }
                                            />
                                        )}

                                        {oldCertificateApplicationId && (
                                            <ProductDetailsButton
                                                colStyle={buttonStyle}
                                                icon="format_list_bulleted"
                                                label="View Previous Application"
                                                onClick={() => viewPreviousApplication(oldCertificateApplicationId)}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                            {(status === 'Assessment' || status === 'Accepted') && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={discontinueDialog}
                                    icon="not_interested"
                                    label="Discontinue Application"
                                    btnProps={{ flat: true }}
                                />
                            )}

                            {status === 'Issued' && (
                                <ProductCertDetails
                                    brands={brands}
                                    viewProductCertificate={viewProductCertificateByCertNumber}
                                    printCert={printCertByCertNumber}
                                    buttonStyle={buttonStyle}
                                />
                            )}
                            {status !== 'Draft' && status !== 'Submitted' && status !== 'Issued' && status !== 'Discontinued' && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    icon="cached"
                                    label="Take Over Case"
                                    onClick={() => takeOverCase()}
                                />
                            )}
                            {status === 'Discontinued' && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    icon="cached"
                                    label="Send Withdrawal Email"
                                    onClick={() => sendWithdrawalEmail()}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {status === 'Accepted' && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                onClick={startAssessment}
                                icon="format_list_bulleted"
                                label="Start Assessment"
                            />
                        )}
                        {status === 'Assessment' && (
                            <React.Fragment>
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    color={`#e1ab4c`}
                                    onClick={completeAssessment}
                                    disabled={completeAssessmentDisabled}
                                    icon="done"
                                    label="Complete Assessment"
                                />
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    color={`#e1ab4c`}
                                    onClick={unlinkInvoice}
                                    icon="remove_circle_outline"
                                    label="Remove Invoice"
                                />
                            </React.Fragment>
                        )}
                        {(status === 'Approval' || status === 'Rejected by Peer' || status === 'Rejected by Manager') &&
                            (peerReviewStatus === null ||
                                peerReviewStatus === '' ||
                                peerReviewStatus === 'Rejected' ||
                                managerStatus === 'Rejected') && (
                                <React.Fragment>
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        color={`#e1ab4c`}
                                        onClick={sendForNextApproval}
                                        icon="description"
                                        label="Send for Approval"
                                        disabled={sendForApprovalClicked}
                                    />
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={reject}
                                        icon="thumb_down"
                                        color={`#e1ab4c`}
                                        label="Send for Re-evaluation"
                                        btnProps={{ flat: true }}
                                    />
                                </React.Fragment>
                            )}

                        {status !== 'Submitted' &&
                            status !== 'Assessment' &&
                            status !== 'Accepted' &&
                            status === 'Manager Approval' &&
                            applicationDetails.withInvoice == 1 &&
                            profile.user.id == applicationDetails.managerId && (
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    disabled={
                                        xeroInvoice &&
                                        xeroInvoice.data &&
                                        xeroInvoice.data.length > 0 &&
                                        (xeroInvoice.data[0].Status == 'AUTHORISED' || xeroInvoice.data[0].Status == 'PAID')
                                    }
                                    onClick={authorizeInvoiceDialog}
                                    color={`#e1ab4c`}
                                    icon="receipt"
                                    label="Authorise Invoice"
                                />
                            )}
                        {status === 'Peer Approval' && profile.user.id == applicationDetails.peerReviewId && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                onClick={approve}
                                icon="thumb_up"
                                label="Approve"
                            />
                        )}
                        {status === 'Manager Approval' && profile.user.id == applicationDetails.managerId && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                disabled={
                                    xeroInvoice &&
                                    xeroInvoice.data &&
                                    xeroInvoice.data.length > 0 &&
                                    xeroInvoice.data[0].Status != 'AUTHORISED' &&
                                    xeroInvoice.data[0].Status != 'PAID'
                                }
                                onClick={approve}
                                icon="thumb_up"
                                label="Approve"
                            />
                        )}
                        {status === 'Manager Approval' && profile.user.id == applicationDetails.managerId && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                disabled={
                                    !(
                                        xeroInvoice &&
                                        xeroInvoice.data &&
                                        xeroInvoice.data.length > 0 &&
                                        xeroInvoice.data[0].Status != 'AUTHORISED' &&
                                        xeroInvoice.data[0].Status != 'PAID'
                                    )
                                }
                                onClick={removeInvoice}
                                icon="remove_circle_outline"
                                label="Remove Invoice"
                            />
                        )}
                        {((status === 'Peer Approval' && profile.user.id == applicationDetails.peerReviewId) ||
                            (status === 'Manager Approval' && profile.user.id == applicationDetails.managerId)) && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                onClick={reject}
                                disabled={
                                    xeroInvoice != null &&
                                    xeroInvoice.data != null &&
                                    xeroInvoice.data.length > 0 &&
                                    (xeroInvoice.data[0].Status == 'AUTHORISED' || xeroInvoice.data[0].Status == 'PAID')
                                }
                                label="Reject"
                                color={`#e1ab4c`}
                                icon="thumb_down"
                                btnProps={{ flat: true }}
                            />
                        )}
                        {status === 'Invoice' && (
                            <React.Fragment>
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    color={`#e1ab4c`}
                                    onClick={updatePayment}
                                    icon="payment"
                                    label="Update Payment Info"
                                />
                                <ProductDetailsButton
                                    color={`#e1ab4c`}
                                    colStyle={buttonStyle}
                                    onClick={markAsPaid}
                                    icon="payment"
                                    label="Mark as Paid"
                                    disabled={markAsPaidDisabled}
                                />
                            </React.Fragment>
                        )}
                        {status === 'Finalisation' && (
                            <ProductDetailsButton
                                colStyle={buttonStyle}
                                onClick={completeApplication}
                                icon="done"
                                color={`#e1ab4c`}
                                disabled={muaLocked == 0}
                                label="Proceed to Generate Cert"
                            />
                        )}
                        {status === 'Paid' && (
                            <React.Fragment>
                                {applicationDetails.withInvoice == 1 && (
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={createAndSendReceiptConfirmation}
                                        icon="done"
                                        color={`#e1ab4c`}
                                        label="Send Receipt"
                                    />
                                )}
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={startFeedbackDialog}
                                    icon="done"
                                    color={`#e1ab4c`}
                                    label="Start Finalisation"
                                    disabled={applicationDetails.withInvoice == 1 && !selectState.sendReceiptPressed}
                                />
                            </React.Fragment>
                        )}
                        {status === 'Issued' && applicationDetails.caseClosed == 0 && (
                            <React.Fragment>
                                {applicationDetails.caseClosed == 0 && (
                                    <ProductDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={handleRegenerate}
                                        icon="payment"
                                        color={`#e1ab4c`}
                                        label="Generate All Certificate PDF"
                                    />
                                )}
                                <ProductDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={closeCase}
                                    disabled={selectState.enableCloseCaseBtn}
                                    icon="done"
                                    color={`#e1ab4c`}
                                    label="Close the case"
                                />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
                {status != 'Draft' && (
                    <Row>
                        <React.Fragment>
                            <Col className="align-items-center text-bold" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <a
                                    href="#"
                                    onClick={viewCertificates}
                                    style={{ color: '#317446', display: 'flex', justifyContent: 'flex-end' }}
                                >
                                    <u>View List of Certificate Numbers</u>
                                </a>
                                {/* <div style={{ marginLeft: 5 }}>
                                    <AuditListPopup contentTypeId={49} type="link" objectId={applicationDetails.id} />
                                </div> */}
                            </Col>
                        </React.Fragment>
                    </Row>
                )}
                <hr />
                {status === 'Assessment' && assignApproverStarted && (
                    <React.Fragment>
                        <AssignOfficerPanel
                            sendToCaseOfficer={sendToCaseOfficer}
                            selectedOfficer={selectedOfficer}
                            officers={officers}
                            onSelectedOfficerChange={onSelectedOfficerChange}
                            type={type}
                            onHandleChange={onHandleChange}
                            certificateDetails={certificateDetails}
                            applicationId={applicationId}
                            productBrands={productBrands}
                            checkCertNumberDuplication={checkCertNumberDuplication}
                            updateProductBrandsWithCertificate={updateProductBrandsWithCertificate}
                            unassignedBrandFromCertificate={unassignedBrandFromCertificate}
                            onOverseasTransactionChargesChange={onOverseasTransactionChargesChange}
                            otCharges={otCharges}
                            onDisableInvoiceChange={onDisableInvoiceChange}
                            disableInvoice={disableInvoice}
                        />
                        <hr />
                    </React.Fragment>
                )}
                {status === 'Approval' && assignNextApproverStarted && (
                    <React.Fragment>
                        <AssignApproverPanel
                            sendForApproval={sendForApproval}
                            selectedPeer={selectedPeer}
                            peers={peers}
                            onSelectedPeerChange={onSelectedPeerChange}
                            selectedManager={selectedManager}
                            managers={managers}
                            onSelectedManagerChange={onSelectedManagerChange}
                        />
                        <hr />
                    </React.Fragment>
                )}
                {taggingPanelVisible && (
                    <Wrapper>
                        <ProductTaggingPanel applicationId={applicationId} applicationTagging={tagging} />
                    </Wrapper>
                )}
                <Row style={{ marginBottom: '1.5em' }}>
                    <Col xs="12" md={4}>
                        <div className="form-group">
                            <h5 className="text-bold">Product Category</h5>
                            <div className="field-label">{selectedProductType.category || 'Others'}</div>
                        </div>
                    </Col>
                    <Col xs="12" md={4}>
                        <div className="form-group">
                            <h5 className="text-bold">Product Subcategory</h5>
                            <div className="field-label">{selectedProductType.subcategory || 'Others'}</div>
                        </div>
                    </Col>
                    <Col xs="12" md={4}>
                        <div className="form-group">
                            <h5 className="text-bold">Product Type</h5>
                            <div className="field-label">{selectedProductType.type || 'Others'}</div>
                        </div>
                    </Col>
                </Row>

                {(status === 'Peer Approval' || status === 'Manager Approval') && applicationDetails != null && (
                    <React.Fragment>
                        <hr />
                        <h4 class="text-bold">Assessment</h4>
                        {applicationDetails && applicationDetails.assessmentId && (
                            <ScoresheetViewPanel
                                hidePrint
                                reviewMode
                                staff
                                scoresheetId={applicationDetails.assessmentId}
                                type={type}
                                templateId={applicationDetails.templateId}
                                applicationId={applicationDetails.id}
                            />
                        )}
                    </React.Fragment>
                )}

                {(status === 'Approval' || status === 'Rejected by Peer' || status === 'Rejected by Manager') &&
                    (peerReviewStatus === null ||
                        peerReviewStatus === '' ||
                        peerReviewStatus === 'Rejected' ||
                        managerStatus === 'Rejected') && (
                        <React.Fragment>
                            <hr />
                            <h4 className="text-bold">Assessment</h4>
                            {applicationDetails && applicationDetails.assessmentId && (
                                <ScoresheetViewPanel
                                    hidePrint
                                    reviewMode
                                    staff
                                    scoresheetId={applicationDetails.assessmentId}
                                    type={type}
                                    templateId={applicationDetails.templateId}
                                    applicationId={applicationDetails.id}
                                />
                            )}
                        </React.Fragment>
                    )}

                {type === 'product' && !assignApproverStarted && (
                    <React.Fragment>
                        <CardBody className="mb-4" style={{ padding: '0px' }}>
                            <Wrapper xs="12">
                                <div className="form-group page-widget">
                                    {status === 'Finalisation' || status === 'Issued' ? (
                                        <React.Fragment>
                                            <br />
                                            <Col xs="12" md={12}>
                                                <div className="form-group">
                                                    <h5 className="text-bold">Green Remarks</h5>
                                                    <div className="field-label">
                                                        {greenRemarks === '' ? 'No green remarks provided' : greenRemarks}
                                                    </div>
                                                </div>
                                            </Col>
                                            <br />
                                        </React.Fragment>
                                    ) : null}
                                    <Col>
                                        <h5 className="text-bold">Brands and Models</h5>
                                        <br />
                                        <div className="field-label">
                                            <CreateCertificateDetailsPanel
                                                checkCertNumberDuplication={checkCertNumberDuplication}
                                                type={type}
                                                itemCode={itemCode}
                                                productBrands={productBrands}
                                                certificateDetails={certificateDetails}
                                                onHandleChange={onHandleChange}
                                                applicationId={applicationId}
                                                unassignedBrandFromCertificate={unassignedBrandFromCertificate}
                                                updateProductBrandsWithCertificate={updateProductBrandsWithCertificate}
                                                onOverseasTransactionChargesChange={onOverseasTransactionChargesChange}
                                                otCharges={otCharges}
                                                printCertificate={printCertificate}
                                                status={status}
                                                readonly={
                                                    status != 'Approval' && status != 'Rejected by Peer' && status != 'Rejected by Manager'
                                                }
                                            />
                                        </div>
                                    </Col>
                                </div>
                            </Wrapper>
                        </CardBody>
                    </React.Fragment>
                )}

                {status != 'Finalisation' && status != 'Issued' && (
                    <Wrapper>
                        <ProductDetailsButton width={160} onClick={viewCatalogue} icon="chrome_reader_mode" label="Product Catalogue" />
                        <ProductDetailsButton width={160} onClick={viewPicture} icon="chrome_reader_mode" label="Product Picture" />
                    </Wrapper>
                )}

                {/* {(status == 'Finalisation' || status == 'Issued') && (
                    <Wrapper>
                        <ProductDetailsButton
                            colStyle={buttonStyle}
                            icon="remove_red_eye"
                            label="Show Applicant Details"
                            onClick={() => {
                                const element = document.getElementById('applicantDetails');
                                if (!element.style.display) {
                                    element.style.display = 'none';
                                    return;
                                }
                                element.style.display = '';
                            }}
                        />
                    </Wrapper>
                )} */}

                <div id="applicantDetails">
                    <hr />
                    <Row onClick={toggleApplicantDetailsOpen} style={{ marginBottom: '1.5em', cursor: 'pointer' }}>
                        <Col>
                            <label className="field-header">
                                <h4 className="text-bold">Applicants Details</h4>
                            </label>
                        </Col>
                        <Col className="text-right">
                            <i
                                className="material-icons"
                                style={{
                                    color: 'black',
                                    fontSize: '20px',
                                }}
                            >
                                {isApplicantDetailsOpen ? 'close' : 'filter_list'}
                            </i>
                        </Col>
                    </Row>
                    <Collapse isOpen={isApplicantDetailsOpen}>
                        <React.Fragment>
                            <Row style={{ marginBottom: '1.5em' }}>
                                <Col md={4}>
                                    <div className="form-group">
                                        <h5 className="text-bold">Main Contact Person </h5>
                                        <div className="field-label">
                                            {manufacturerContactPersonName ? manufacturerContactPersonName : '-'}
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="form-group">
                                        <h5 className="text-bold">Main Contact Phone</h5>
                                        <div className="field-label">{manufacturerTelephone ? manufacturerTelephone : '-'}</div>
                                    </div>
                                </Col>
                            </Row>

                            <hr />
                            <Wrapper rowStyle={{ marginBottom: '1.5em' }}>
                                <Wrapper xs={12}>
                                    {isManufacturer === 1 ? (
                                        <div className="form-group">
                                            <label className="field-header">Company is a Distributor/Reseller/Agent</label>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label className="field-header">Company is not Distributor/Reseller/Agent</label>
                                        </div>
                                    )}
                                </Wrapper>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Name Of Manufacturer</h5>
                                            <div className="field-label">{manufacturerNameOfCompany}</div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Name Of Manufacturing Plant</h5>
                                            <div className="field-label">{manufacturerNameOfCompanyPlant}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Address of Manufacturer</h5>
                                            <div className="field-label">{manufacturerFactoryAddress}</div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Address of Manufacturing Plant</h5>
                                            <div className="field-label">{manufacturerFactoryAddressPlant}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="form-group">
                                            <h5 className="text-bold">Country of Origin</h5>
                                            <div className="field-label">{manufacturerCountryOfOrigin}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Wrapper>
                            <Wrapper xs="7" className="pb-4">
                                <div className="form-group">
                                    <h5 className="text-bold">
                                        Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy
                                        saving properties, recyclability, usage of recycled content, etc.).
                                    </h5>

                                    {!takeUpCaseStarted ? (
                                        <React.Fragment>
                                            <div className="field-label">
                                                <Input type="textarea" value={greenRemarks} disabled={true} />
                                            </div>
                                        </React.Fragment>
                                    ) : (
                                        <div className="field-label">
                                            {greenRemarks === '' ? 'No green remarks provided' : greenRemarks}
                                        </div>
                                    )}
                                </div>
                            </Wrapper>
                        </React.Fragment>
                    </Collapse>
                    <hr />
                    <Wrapper xs="7">
                        <div className="form-group">
                            <h5 className="text-bold">Remarks</h5>
                            {!takeUpCaseStarted ? (
                                <React.Fragment>
                                    <div className="field-label">
                                        <Input type="textarea" value={remarks} onChange={onRemarksChange} />
                                    </div>
                                    <Button className="ml-auto primary-btn-style" onClick={addRemarks}>
                                        <i className="mr-1 material-icons align-text-bottom ">save</i>
                                        Update Remarks
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <div className="field-label">{remarks === '' ? 'No remarks provided' : remarks}</div>
                            )}
                        </div>
                    </Wrapper>
                </div>
                <hr />
                <br />
                <Wrapper xs="12" className="text-right">
                    {status === 'Submitted' && takeUpCaseStarted && (
                        <React.Fragment>
                            <ButtonIcon className="mr-2 primary-btn-style-outline" onClick={() => takeUpCase(false)} label="Back" />
                            <ButtonIcon onClick={takeUpConfirm} icon="check" label="Accept Application" />
                        </React.Fragment>
                    )}
                    {status === 'Submitted' && !takeUpCaseStarted && <ButtonIcon onClick={() => takeUpCase(true)} label="Next" />}
                </Wrapper>
                {/* {status == 'Issued' && (
                <Wrapper>
                    <ServiceCertificationPanel
                        certificate={certificate}
                        certificateStatus={certificateStatus}
                        serviceCategoryShow={false}
                        downloadShow={true}
                    />
                </Wrapper>
            )} */}
            </div>
        </NoShadowPanel>
    );
};

const Wrapper = ({ children, xs, className, rowStyle, rowOnClick }) => {
    return (
        <Row style={rowStyle} onClick={rowOnClick}>
            <Col xs={xs} className={className}>
                {children}
            </Col>
        </Row>
    );
};
