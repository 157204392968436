import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const DetailsPageFormOthers = ({ questions, qr }) => {
    if (!qr) return null;
    return (
        <RowCardHOC>
            <h5>Section H: Others</h5>
            <br />
            <div>
                <Row>
                    {qr.map((questionResponse, i) => (
                        <QuestionResponseMap
                            key={i}
                            questionResponse={questionResponse}
                            question={questions.find(q => q.question.id === questionResponse.questionId)}
                        />
                    ))}
                </Row>
            </div>
        </RowCardHOC>
    );
};

const QuestionResponseMap = ({ questionResponse, question }) => {
    return (
        <Col xs={12} className="mb-2">
            <LabelItem
                noColon
                label={question.question.name}
                content={
                    <div>
                        {questionResponse.answerIds.map((answerId, i) => {
                            const content = question.answer.find(e => e.id === answerId);
                            return <div key={i}>{content && content.name}</div>;
                        })}
                        {questionResponse.others && (
                            <p>
                                <i>{`"${questionResponse.others}"`}</i>
                            </p>
                        )}
                    </div>
                }
            />
        </Col>
    );
};

export default DetailsPageFormOthers;
