import React from 'react';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Row, Col, Input, FormGroup, Label, Button, Form } from 'reactstrap';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

const StaffCertificationApplicationProductProducts = props => {
    const { productDetails } = props.state;
    return (
        <Col>
            <Row className="page-widget">
                <Col>
                    <h5 className="text-bold">Brands and Models</h5>
                    <Col className="p-4">
                        <ReactTable
                            manual
                            className="-highlight mb-2"
                            minRows={0}
                            defaultPageSize={20}
                            showPagination={false}
                            filterable={false}
                            data={productDetails}
                            columns={props.tableColumns}
                        />
                    </Col>
                </Col>
            </Row>
            {props.productInEditting && props.productInEditting.productBrand && (
                <div className="mt-4">
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="iso">Brand</Label>
                                <Input
                                    type="text"
                                    name="brand"
                                    id="brand"
                                    value={props.productInEditting.productBrand}
                                    placeholder="brand"
                                    onChange={props.productBrandChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="name">Models</Label>
                                <Input
                                    type="textarea"
                                    rows={4}
                                    name="model"
                                    id="model"
                                    value={props.productInEditting.productModel}
                                    placeholder="model"
                                    onChange={props.productModelChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={props.saveEditting}>
                                Save
                            </Button>
                        </FlexCol>
                    </FlexRow>
                </div>
            )}
        </Col>
    );
};

export default StaffCertificationApplicationProductProducts;
