import React from 'react';
import { Link } from 'react-router-dom';

import { Col } from 'reactstrap';

const EventConfirmationPanel = ({ staff, eventState, proceedPayment }) => {
    const { mode, event, isRegistrationInWaitingList, isRegistrationConfirmed } = eventState;
    const isStaffMode = mode === 'staff';

    // DERTERMINE REDIRECTION
    var redirection = `/member/event/${event.id}/registration`;
    if (mode === 'public') redirection = `/public/event/rsvp/${event.id}`;
    if (mode === 'staff') redirection = `/staff/events/${event.id}/main`;

    return (
        <Col style={{ textAlign: 'center' }}>
            <h3 className="text-center text-bold">Registration Complete</h3>
            <hr />
            {isStaffMode && (
                <div>
                    <h5>
                        Please click
                        <HereRefreshingPage to={redirection} />
                        to continue.
                    </h5>
                </div>
            )}
            <br />
            {isRegistrationInWaitingList && !isStaffMode && (
                <div>
                    <h5>
                        Thank you for your interest in our event. Due to overwhelmed registration, your registration is currently in waiting
                        list. We will notify you when your registration is successful.
                    </h5>
                </div>
            )}
            {isRegistrationConfirmed && !isRegistrationInWaitingList && !isStaffMode && (
                <div>
                    <h5>
                        Thank you for your interest in our event. Once your registration has been processed, you will receive a confirmation
                        email with further details.
                        <br />
                        <br />
                        Click
                        <HereRefreshingPage to={redirection} />
                        to return to the Event’s main page.
                    </h5>
                    <br />
                </div>
            )}
            {!isRegistrationConfirmed && !isRegistrationInWaitingList && !isStaffMode && (
                <div>
                    <h5>
                        Thank you for you interest on this event. This event registration requires SGBC verification. We will notify you
                        when your registration is successful.
                    </h5>
                </div>
            )}
        </Col>
    );
};

const Here = ({ to }) => (
    <Link to={to}>
        &nbsp;<u>here</u>&nbsp;
    </Link>
);

const HereRefreshingPage = ({ to }) => (
    <a href={to}>
        &nbsp;<u>here</u>&nbsp;
    </a>
);

export default EventConfirmationPanel;
