import {
    MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE,
    MEMBER_HOME_SET_LOADING_STATE,
    MEMBER_HOME_SET_LOADING_EVENT_STATE,
    MEMBER_HOME_SET_LOADING_NEWS_STATE,
    MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE,
    MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE,
    MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE,
    MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE,
} from '../../constants/actionTypesMemberHome';

import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

export function memberHomeSetLoadingState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}

export function memberHomeSetLoadingMembershipInfoState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}

export function memberHomeSetLoadingMembershipApplicationInfoState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}

export function memberHomeSetLoadingCertificateState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}

export function memberHomeSetLoadingResourceLibraryState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function memberHomeSetLoadingEventState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_EVENT_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function memberHomeSetLoadingNewsState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_HOME_SET_LOADING_NEWS_STATE,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function memberHomePanelCertGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getMemberHome',
        });
        dispatch({ type: MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE,
        );
    };
}

export function memberHomePanelMembershipApplicationGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE,
        );
    };
}
export function memberHomePanelMembershipGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE,
        );
    };
}
export function memberHomePanelEventGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE,
        );
    };
}

export function publicEventPageEventGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getPublicHomeAllUpcomingEventsMultipleDates',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function publicHomePanelEventGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getPublicHome',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE,
        );
    };
}
export function memberHomePanelResourcesGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/resource`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST,
            MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS,
            MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE,
        );
    };
}
