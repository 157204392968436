import {
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST,
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE,
    EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE,
    EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE,
    EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT,
    EVENT_ATTENDEE_DETAILS_SET_MODA_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsAttendee';
import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';
import { sanitizeObjectNullsToEmptyStrings } from 'js/util';

// Default state
const defaultState = {
    loading: false,
    dropdown: {
        professionList: [],
        ticketTypesList: [],
    },
    attendeeInfo: {
        id: 0,
        name: '',
        nric: '',
        email: '',
        companyName: '',
        professionalNumber: '',
        attended: 0,
        signature: '',
        foodPreferenceId: 0,
        trackId: 0,
        attendeeProfessionId: 0,
        companyId: 0,
        ticketTypeId: 0,
        designation: '',
        handphone: '',
    },
    ...defaultModalKeys,
};

export function eventsAttendeeDetailsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Get - profession list
        case EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS:
            newState.dropdown.professionList = action.response.data;
            return newState;
        case EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE:
            return newState;
        // Get - ticket types list
        case EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS:
            newState.dropdown.ticketTypesList = action.response.data;
            return newState;
        case EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE:
            return newState;
        // Get - attendee details
        case EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS:
            // console.log("action.response: ", action.response)
            let attendeeInfo;
            try {
                attendeeInfo = action.response.data[0];
            } catch (e) {
                attendeeInfo = action.response[0];
            }
            newState.attendeeInfo = sanitizeObjectNullsToEmptyStrings(attendeeInfo);
            newState.loading = false;
            return newState;
        case EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE:
            newState.loading = false;
            return newState;
        // Check in
        case EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = '../attendees';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Attendee successfully checked in';
            return newState;
        case EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to check in attendee.';
            return newState;
        // Delete
        case EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = '../attendees';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Attendee successfully deleted.';
            return newState;
        case EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to delete attendee.';
        // Update
        case EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = '../attendees';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Attendee’s details successfully updated.';
            return newState;
        case EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to update attendee.';
            return newState;
        // Others
        case EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE:
            const target = action.event.target;
            newState.attendeeInfo = Object.assign({}, state.attendeeInfo);
            newState.attendeeInfo[target.id] = target.value;
            return newState;
        case EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        case EVENT_ATTENDEE_DETAILS_SET_MODA_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return newState;
    }
}
