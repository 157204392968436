import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Row, Col, Form, Label, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputField, { ButtonComponent } from 'pages/admin/companies/shared/InputField';
import Select from 'react-select';

import { save, update, remove, load, getOrganisationalGroups } from 'actions/admin/companies/organisational-category/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.onGroupChange = this.onGroupChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            orgGroups: [],
            data: {
                groupId: '',
                name: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/companies/organisational-category');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(loading) {
        if (this._isMounted) {
            this.setState({
                loading,
            });
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    componentDidMount() {
        const tasks = [this.getRelevantStuff(), this.getOrgGroups()];
        this.showLoading(true);
        Promise.all(tasks).then(() => this.showLoading(false));
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getOrgGroups() {
        try {
            this.showLoading(true);
            const { data } = await this.props.getOrgGroups();
            if (this._isMounted) {
                this.setState({
                    orgGroups: data,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getRelevantStuff() {
        try {
            const { id } = this.props.match.params;
            if (!id || id === '0') return;
            const { data, error, message } = await this.props.load(id);
            const evaluation = error || !data || !data.id;
            const hasError = this.showModalIf('Error', message, evaluation);
            if (hasError) return;
            if (this._isMounted) {
                this.setState({
                    data,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    async save() {
        try {
            this.showLoading(true);
            const { save, update } = this.props;
            const data = this.prepareDataBeforeSave(this.state.data);
            const isSave = !data.id || data.id === 0;
            const { error, message } = isSave ? await save(data) : await update(data);
            const hasError = this.showModalIf('Error', message, error);
            if (hasError) return;
            history.replace('/admin/companies/organisational-category');
        } catch (error) {
            this.showModalIf('Error', error.message, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/companies/organisational-category');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }
    onGroupChange(value) {
        this.setState({
            data: {
                ...this.state.data,
                groupId: value.id,
            },
        });
    }
    nameChange(e) {
        var data = this.state.data;
        data.name = e.target.value;
        this.setState({ data });
    }
    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <InputField label="Group">
                    <div style={{ width: '600px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            options={this.state.orgGroups}
                            onChange={this.onGroupChange}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.group}
                            value={this.state.orgGroups.find(x => x.id === this.state.data.groupId)}
                        />
                    </div>
                    <Label>
                        <small>select group</small>
                    </Label>
                </InputField>
                <InputField
                    label="Name"
                    instruction="requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        id: 'name',
                        style: { width: '600px' },
                        value: this.state.data.name,
                        onChange: this.nameChange,
                    }}
                />
                <hr />
                <FlexRow between="xs">
                    <ButtonComponent className="primary-btn-style" onClick={this.save} label="Save" />
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <ButtonComponent
                                className="primary-btn-style-outline"
                                colClassName="d-flex justify-content-end"
                                onClick={this.removeConfirm}
                                label="Delete"
                            />
                            <AuditListPopup contentTypeId={14} objectId={this.state.data.id} />
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getOrgGroups: () => {
            return dispatch(getOrganisationalGroups());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
