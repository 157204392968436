import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Row, Col, Container } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { membershipRenewalRetentionInfoAnalytics } from 'actions/analytics/memberAnalyticsAction';

class MembershipRetentionRate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate() {
        if (this.state.refreshState != this.props.membership.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.membership.refresh,
            });
        }
    }
    load() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }
    render() {
        // console.log("membership: ", this.props.membership)
        const paddingTopBottom = '20px';
        const currentYear = moment().format('YYYY');
        const selectedYear = moment(this.props.membership.endDate, 'DD/MM/YYYY').format('YYYY');

        const retentionCount = this.state.data == null ? 0 : this.state.data.validMember - this.state.data.notRenewing;
        return (
            <AnalyticsCard>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row>
                            <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>Renewal Rate</span>
                        </Row>
                        <Row style={{ paddingTop: paddingTopBottom, paddingBottom: paddingTopBottom }}>
                            <Col xs="3">
                                {' '}
                                <AnalyticsBigText text={`${Math.round(this.state.data.renewalRate)}%`} textColor={`#78BE20`} />
                            </Col>

                            <Col xs="9">
                                {' '}
                                <AnalyticsNormalText
                                    text={`${this.state.data.successfulCount} Renewed in ${parseInt(selectedYear) + 1} / ${
                                        this.state.data.totalCount
                                    } Expired in ${selectedYear}`}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>Retention Rate</span>
                        </Row>
                        <Row style={{ paddingTop: paddingTopBottom, paddingBottom: paddingTopBottom }}>
                            <Col xs="3">
                                {' '}
                                <AnalyticsBigText
                                    text={`${Math.round((retentionCount * 100) / this.state.data.validMember)}%`}
                                    textColor={`#f8ac59`}
                                />
                            </Col>

                            <Col xs="9">
                                {' '}
                                <AnalyticsNormalText
                                    text={`${retentionCount} Retained in ${selectedYear} / ${
                                        this.state.data.validMember
                                    } Active Member in ${selectedYear - 1} `}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        membership: state.membershipRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(membershipRenewalRetentionInfoAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipRetentionRate));
