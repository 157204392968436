import {
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE,
} from '../../../actions/constants/actionTypesMemberCertification';

const defaultState = {
    loading: false,
    tablePages: -1,
    ongoingapplications: [],
    pastapplications: [],
};

export default function memberCertificationsOverviewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS:
            if (action.response.data[0].tablestatus === 'ongoing') {
                newState.ongoingapplications = action.response.data;
            } else if (action.response.data[0].tablestatus === 'past') {
                newState.pastapplications = action.response.data;
            }
            newState.tablePages = action.response.totalNoOfPages;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING:
            newState.ongoingapplications = action.response.data;
            newState.tablePages = action.response.totalNoOfPages;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST:
            newState.pastapplications = action.response.data;
            newState.tablePages = action.response.totalNoOfPages;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        default:
            return newState;
    }
}
