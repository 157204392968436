import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Input } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';

import { getProductCategories, getProductSubCategories } from 'actions/admin/product/product-sub-category/action';

import { search } from 'actions/admin/product/product-type/action.js';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.state = {
            list: [],
            filterCategory: '',
            filterSubCategory: '',
            productCategories: [],
            productSubCategories: [],
            selectLoading: false,
            loading: false,
            keyword: '',
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    componentDidMount() {
        this.getProductCategories();
        this.getProductSubCategories();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(loading) {
        this.setState({ loading });
    }
    showSelectLoading(selectLoading) {
        this.setState({ selectLoading });
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    onSelectChange(value, key) {
        let state = Object.assign({}, this.state);
        state[key] = value ? value.label : '';
        this.setState(state, () => this.getProductSubCategories());
    }
    async getProductCategories() {
        try {
            this.showSelectLoading(true);
            const { data } = await this.props.getProductCategories();
            // PROCESS DATA
            const productCategories = data.map(({ id, category }) => {
                return {
                    value: id,
                    label: category,
                };
            });
            // SET STATE
            if (this._isMounted && data)
                this.setState({
                    productCategories,
                });
        } catch (error) {
            throw error;
        } finally {
            this.showSelectLoading(false);
        }
    }
    async getProductSubCategories() {
        try {
            this.showSelectLoading(true);
            const { data } = await this.props.getProductSubCategories(this.state.filterCategory);
            // PROCESS DATA
            const productSubCategories = data.map(({ id, subcategory }) => {
                return {
                    value: id,
                    label: subcategory,
                };
            });
            // SET STATE
            if (this._isMounted && data)
                this.setState({
                    productSubCategories,
                });
        } catch (error) {
            throw error;
        } finally {
            this.showSelectLoading(false);
        }
    }
    async search(page, pageSize, sorted, filtered) {
        const { keyword, filterCategory, filterSubCategory } = this.state;
        try {
            this.showLoading(true);
            const { data, totalNoOfPages, totalNoOfItems } = await this.props.search(
                keyword,
                page,
                pageSize,
                sorted,
                filtered,
                filterCategory,
                filterSubCategory,
            );
            if (this._isMounted)
                this.setState({
                    list: data,
                    loading: false,
                    pageCount: totalNoOfPages,
                    totalNum: totalNoOfItems,
                });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    render() {
        const columns = [
            { Header: 'ID', accessor: 'id', style: { whiteSpace: 'unset' }, maxWidth: 100 },
            {
                Header: 'Type',
                accessor: 'type',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Category Name',
                accessor: 'categoryName',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Sub Category Name',
                accessor: 'subCategoryName',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Status',
                accessor: 'status',
                maxWidth: 100,
                Cell: props => {
                    // console.log("props.value: ", props)
                    return props.original.active == 1 ? 'Active' : 'Inactive';
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={'/admin/product/product-type/' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Row className="form-inline">
                            <Col>
                                <Input
                                    style={{ float: 'left', marginRight: '5px' }}
                                    type="text"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.table.fireFetchData();
                                    }}
                                    onChange={this.onKeywordChange}
                                    value={this.state.keyword}
                                />
                                <div style={{ float: 'left', width: '200px', marginRight: '5px' }}>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder="Product Category"
                                        isClearable={true}
                                        isLoading={this.state.selectLoading}
                                        options={this.state.productCategories}
                                        onChange={value => this.onSelectChange(value, 'filterCategory')}
                                    />
                                </div>
                                <div style={{ float: 'left', width: '200px', marginRight: '5px' }}>
                                    <Select
                                        classNamePrefix="select"
                                        placeholder="Product Sub Category"
                                        isClearable={true}
                                        isLoading={this.state.selectLoading}
                                        options={this.state.productSubCategories}
                                        onChange={value => this.onSelectChange(value, 'filterSubCategory')}
                                    />
                                </div>
                                <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                    <i className="material-icons align-text-bottom">search</i>
                                    &nbsp;Search
                                </Button>
                            </Col>
                            <div className="ml-auto">
                                <Link to="/admin/product/product-type/0">
                                    <Button className="ml-1 mr-1 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            minRows={0}
                            columns={columns}
                            filterable={false}
                            className="-highlight mb-2"
                            data={this.state.list}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, page, pageSize, sorted, filtered, productCat, productSubCat) => {
            return dispatch(search(keyword, page, pageSize, sorted, filtered, productCat, productSubCat));
        },
        getProductCategories: () => {
            return dispatch(getProductCategories());
        },
        getProductSubCategories: productCategories => {
            return dispatch(getProductSubCategories(productCategories));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
