/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch } from 'react-router';

// PrivateRoute
import PrivateRoute from './cyder/common/PrivateRoute';

// Error
import ErrorPage from './pages/error-page';

// Public
import PublicCompanyDetailsPage from './pages/public/PublicCompanyDetailsPage';
import DirectoryPage from './pages/public/DirectoryPage';
import MembershipDirectoryPage from './pages/member/membership/directory/MembershipDirectoryPage';
import MemberDetailPage from './pages/member/membership/directory/MemberDetailPage';
import DirectoryProductPage from './pages/public/DirectoryProductPage';
import DirectoryCategoryPage from './pages/public/DirectoryCategoryPage';
import DirectorySubCategoryPage from './pages/public/DirectorySubCategoryPage';
import MembershipPage from './pages/public/MembershipPage';
import CertificationsPage from './pages/public/CertificationsPage';
import ElectionsVoterPage from './pages/staff/elections/ElectionsVoterPage';
import EventsRsvpPage from './pages/events/EventsRsvpPage';
import EventPaymentPage from './pages/events/payment/EventPaymentPage';
import EventPayNowPage from './pages/events/payment/EventPayNowPage';
import PublicLoginStaff from './pages/public/PublicStaffLogin';
import PublicLoginMember from './pages/public/PublicMemberLogin';
import ProductContainer from './pages/public/product/index';
import ServiceContainer from './pages/public/service';
import SurveysDetailPage from './pages/surveys/SurveysDetailPage';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import ResetPassword from './pages/public/ResetPassword';
import EventDirectoryPage from './pages/public/event-directory/EventDirectoryPage';
import EventReceiptPage from './pages/events/payment/EventReceiptPage';

const Routes = () => (
    <Switch>
        <PrivateRoute exact path="/public/forgotpassword" component={ForgotPassword} />
        <PrivateRoute exact path="/public/company/:companyid" component={PublicCompanyDetailsPage} />
        <PrivateRoute exact path="/public/membership" component={MembershipPage} />
        <PrivateRoute exact path="/public/certifications" component={CertificationsPage} />
        <PrivateRoute exact path="/public/directory/:dtype/:cat/:subcat" component={DirectorySubCategoryPage} />
        <PrivateRoute exact path="/public/directory/:dtype/:cat" component={DirectoryCategoryPage} />
        <PrivateRoute exact path="/public/directory/:dtype" component={DirectoryProductPage} />
        <PrivateRoute exact path="/public/directory" component={DirectoryPage} />
        <PrivateRoute exact path="/public/memberdirectory" component={MembershipDirectoryPage} />
        <PrivateRoute exact path="/public/memberdirectory/:companyid" component={MemberDetailPage} />
        <PrivateRoute exact path="/public/elections/view/:electionid/vote" component={ElectionsVoterPage} />
        {/* Public events */}
        <PrivateRoute exact path="/public/eventdirectory" component={EventDirectoryPage} />
        <PrivateRoute exact path="/public/event/rsvp/:eventid" component={EventsRsvpPage} />
        <PrivateRoute exact path="/public/event/rsvp/:eventid/tix" component={EventsRsvpPage} />
        <PrivateRoute exact path="/public/event/rsvp/:eventid/form" component={EventsRsvpPage} />
        <PrivateRoute exact path="/public/event/rsvp/:eventid/confirmation" component={EventsRsvpPage} />
        <PrivateRoute exact path="/public/event/registration/:registrationid/payment" component={EventPaymentPage} />
        <PrivateRoute exact path="/public/event/payment-receipt/:paymentid" component={EventReceiptPage} />
        <PrivateRoute exact path="/public/event/registration/:registrationid/paynow" component={EventPayNowPage} />
        <PrivateRoute exact path="/public/login/staff" component={PublicLoginStaff} />
        <PrivateRoute exact path="/public/login/member" component={PublicLoginMember} />
        <PrivateRoute exact path="/public/login/member/:page" component={PublicLoginMember} />
        <PrivateRoute exact path="/public/createaccount" component={PublicLoginMember} />
        <PrivateRoute exact path="/public/resetpassword/:encrypted" component={ResetPassword} />
        <PrivateRoute path="/public/product" component={ProductContainer} />
        <PrivateRoute path="/public/service" component={ServiceContainer} />

        {/* Public survey */}
        <PrivateRoute exact path="/public/survey/:surveyid" component={SurveysDetailPage} postSurveyUrl={'/'} />

        {/* Error */}
        <PrivateRoute path="/" component={ErrorPage} />
    </Switch>
);

export default Routes;
