import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Row, Col } from 'reactstrap';

import { setConfig } from '../../actions/config';

class Footer extends React.Component {
    state = {
        windowSize: 0,
    };
    clearPersist = () => {
        this.props.persistor.purge();
    };
    componentDidMount() {
        const pathname = window.location.pathname;
        if (pathname.toLowerCase().includes('staff') && !pathname.toLowerCase().includes('public')) {
            window.addEventListener('resize', this.setWindowSize);
            this.setWindowSize();
        }
    }
    componentDidUpdate(prevProps) {
        // Using config.layout prop to determine add listener when switch between public to staff
        if (prevProps.config.layout !== this.props.config.layout) {
            window.addEventListener('resize', this.setWindowSize);
            this.setWindowSize();
        }
    }
    componentWillUnmount() {
        if (window.location.pathname.toLowerCase().includes('staff')) window.removeEventListener('resize', this.setWindowSize);
    }
    setWindowSize = () => {
        this.setState({
            windowSize: window.innerWidth,
        });
    };
    render() {
        const { windowSize } = this.state;
        const { layout } = this.props.config;
        const breakpoint = windowSize > 1200;
        const pathname = window.location.pathname.toLowerCase();
        const product = pathname.includes('public/product');
        const service = pathname.includes('public/service');
        const mode = pathname.includes('staff') || pathname.includes('admin') ? 'staff' : 'member';
        return (
            <footer className={'mt-4 ' + (product || service ? 'main footer public' : 'main footer')}>
                <Row className="content">
                    <Col
                        className="footer-left"
                        style={
                            !product && breakpoint && mode === 'staff'
                                ? {
                                      paddingLeft: layout === 'default-sidebar-1' ? '240px' : '100px',
                                  }
                                : null
                        }
                    >
                        Singapore Green Building Council © 2019-2020 &nbsp;UEN: T09SS0112H
                    </Col>
                    <Col className="footer-right">
                        <p className="mb-1">Copyright © 2019-2020 SGBC</p>
                        Powered by{' '}
                        <a href="http://www.cyder.com.sg/" target="_blank">
                            Cyder SG
                        </a>
                    </Col>
                </Row>
            </footer>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        profile: state.profile.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setConfig: (key, value) => dispatch(setConfig(key, value)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Footer);
