import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as notificationsReducer } from 'reapop';
import { config } from './config';
import { colors, backgroundColors } from './config/colors';
import { pagedata } from './config/pagedata';

// modal
import dialogModalReducer from './modal/dialogModalReducer';

// SGBC menu / navigation
import { usermenu } from './config/usermenu';
import { navigationStaff } from './config/navigationStaff';
import { navigationStaffAsAdmin } from './config/navigationStaffAsAdmin';
import { navigationAdmin } from './config/navigationAdmin';
import { navigationPublic } from './config/navigationPublic';
import { navigationMember } from './config/navigationMember';

// SGBC authentication
import cyderLoginReducer from './security/cyderLoginReducer';
import { forgotPasswordReducer } from './security/forgotPasswordReducer';
import { cyderChangePasswordReducer } from './security/cyderChangePasswordReducer';
import { createAccountReducer } from './account/createAccountReducer';

// SGBC profile
import profileReducer from './profile/profileReducer';
import personalProfileReducer from './profile/personalProfileReducer';
import memberCompanyReducer from './member/company/memberCompanyReducer';
import { memberReducer } from './profile/memberReducer';
import { staffReducer } from './profile/staffReducer';

// SGBC Staff
import staffHomeReducer from './staff/staffHomeReducer';
import staffMembershipOverviewReducer from './staff/membership/staffMembershipOverviewReducer';
import { staffMembershipStatisticsReducer } from './staff/membership/statisticsReducer';
import { staffMembershipApplicationsReducer } from './staff/membership/applications/staffMembershipApplicationsReducer';
import { staffMembershipApplicationsDetailsReducer } from './staff/membership/applications/staffMembershipApplicationsDetailsReducer';
import { staffMembershipRenewalsReducer } from './staff/membership/renewals/staffMembershipRenewalsReducer';
import { staffMembershipMembersReducer } from './staff/membership/members/staffMembershipMembersReducer';
import { staffMembershipRenewalsDetailsReducer } from './staff/membership/renewals/staffMembershipRenewalsDetailsReducer';
import { staffCompanyDetailsOverviewReducer } from './staff/company/staffCompanyDetailsOverviewReducer';
import { staffCompanyDetailsMembershipReducer } from './staff/company/staffCompanyDetailsMembershipReducer';
import { staffCompanyDetailsEventsReducer } from './staff/company/staffCompanyDetailsEventsReducer';
import { staffCompanyDetailsTeamManagementReducer } from './staff/company/staffCompanyDetailsTeamManagementReducer';
import { staffCompanyDetailsLoggingActivityReducer } from './staff/company/staffCompanyDetailsLoggingActivityReducer';
import staffCertificationApplicationReducer from './staff/certification/staffCertificationApplicationReducer';
import { staffAssessmentCriteriaReducer } from './staff/certification/staffAssessmentCriteriaReducer';
import staffCertificationAssessmentCriteriaAddReducer from './staff/certification/assessment/staffCertificationAssessmentCriteriaAddReducer';
import staffCertificationAssessmentCriteriaListReducer from './staff/certification/assessment/staffCertificationAssessmentCriteriaListReducer';
import staffCertificationSTemplateReducer from './staff/certification/assessment/staffCertificationSTemplateReducer';

// SGBC Events
import { eventsAllReducer } from './staff/events/events/eventsAllReducer';
import { eventsAttendeeReducer } from './staff/events/events/eventsAttendeeReducer';
import { eventsAttendeeDetailsReducer } from './staff/events/events/eventsAttendeeDetailsReducer';
import { eventsCreationReducer } from './staff/events/events/eventsCreationReducer';
import { eventsRegistrationReducer } from './staff/events/events/eventsRegistrationReducer';
import { eventsDateReducer } from './staff/events/events/eventsDateReducer';
import { eventsTrackReducer } from './staff/events/events/eventsTrackReducer';
import { eventsTicketReducer } from './staff/events/events/eventsTicketReducer';
import { eventsMaterialReducer } from './staff/events/events/eventsMaterialReducer';
import { eventsChecklistReducer } from './staff/events/events/eventsChecklistReducer';
import { eventsExpenditureReducer } from './staff/events/events/eventsExpenditureReducer';
import { promoCodesReducer } from './staff/events/promocodes/promoCodesReducer';
import { dinnerEventsAllEventsReducer } from './staff/dinner-events/dinnerEventsAllEventsReducer';
import { eventsDinnerAllReducer } from './staff/dinner/events/eventsAllReducer';

// SGBC Member
import memberNewMembershipApplicationReducer from './member/membership/applications/memberNewMembershipApplicationReducer';
import memberQuestionnairesReducer from './member/membership/questionnaires/memberQuestionnairesReducer';
import { memberProductApplicationReducer } from './member/certification/memberProductApplicationReducer';
import { memberProductReviewReducer } from './member/certification/memberProductReviewReducer';
import { memberProductRenewReducer } from './member/certification/memberProductRenewReducer';
import { memberProductAssessmentReducer } from './member/certification/memberProductAssessmentReducer';
import { memberServiceApplicationReducer } from './member/certification/memberServiceApplicationReducer';
import { memberServiceReviewReducer } from './member/certification/memberServiceReviewReducer';
import { memberServiceRenewReducer } from './member/certification/memberServiceRenewReducer';
import { memberServiceAssessmentReducer } from './member/certification/memberServiceAssessmentReducer';
import { memberCertificationsReducer } from './member/certification/memberCertificationsReducer';
import { memberDirectoryReducer } from './member/directory/memberDirectoryReducer';

// ivan 27 aug onwards
import memberCertificationsOverviewReducer from './member/certification/memberCertificationsOverviewReducer';
import memberCertificationsApplicationListReducer from './member/certification/memberCertificationsApplicationListReducer';
import memberCertificationsListReducer from './member/certification/memberCertificationsListReducer';

// SGBC Survey
import staffSurveyReducer from './staff/survey/surveyReducer';
import sharedSurveyReducer from './survey/sharedSurveyReducer';

// SGBC Admin
import { groupReducer } from './admin/auth/groupReducer';
import { groupAddReducer } from './admin/auth/groupAddReducer';
import { userReducer } from './admin/auth/userReducer';
import { userAddReducer } from './admin/auth/userAddReducer';
import { userDetailReducer } from './admin/auth/userDetailReducer';
import { countryReducer } from './admin/misc/countryReducer';
import { countryAddReducer } from './admin/misc/countryAddReducer';
import { userManualReducer } from './admin/misc/userManualReducer';
import { userManualAddReducer } from './admin/misc/userManualAddReducer';

import teamManagementReducer from './shared/teamManagementReducer';
import teamMembersDetailsReducer from './shared/teamMembersDetailsReducer';
import publicReducer from './public';
import memberHomeReducer from './member/memberHomeReducer';

// SGBC Analytics
import eventsAnalyticsReducer from './analytics/eventsAnalyticsReducer';
import certificationIssuanceAnalyticsReducer from './analytics/certificationIssuanceAnalyticsReducer';
import certificationRenewalAnalyticsReducer from './analytics/certificationRenewalAnalyticsReducer';
import membershipIssuanceAnalyticsReducer from './analytics/membershipIssuanceAnalyticsReducer';
import membershipRenewalAnalyticsReducer from './analytics/membershipRenewalAnalyticsReducer';

const rootReducer = combineReducers({
    config,
    colors,
    usermenu,
    notifications: notificationsReducer(),
    pagedata,
    backgroundColors,
    navigationStaff,
    navigationStaffAsAdmin,
    navigationPublic,
    navigationMember,
    navigationAdmin,
    router: routerReducer,
    cyderLoginReducer,
    cyderChangePasswordReducer,
    forgotPasswordReducer,
    createAccountReducer,
    profile: profileReducer,
    personalProfileReducer,
    memberCompanyReducer,
    member: memberReducer,
    memberHomeReducer,
    memberNewMembershipApplicationReducer,
    memberQuestionnairesReducer,
    memberProductApplication: memberProductApplicationReducer,
    memberProductReview: memberProductReviewReducer,
    memberProductAssessment: memberProductAssessmentReducer,
    memberProductRenew: memberProductRenewReducer,
    memberServiceApplication: memberServiceApplicationReducer,
    memberServiceReview: memberServiceReviewReducer,
    memberServiceAssessment: memberServiceAssessmentReducer,
    memberServiceRenew: memberServiceRenewReducer,
    memberCertifications: memberCertificationsReducer,
    memberCertificationsOverviewReducer,
    memberCertificationsApplicationListReducer,
    memberCertificationsListReducer,
    memberDirectory: memberDirectoryReducer,
    staffHome: staffHomeReducer,
    staff: staffReducer,
    staffMembershipOverviewReducer,
    staffMembershipStatistics: staffMembershipStatisticsReducer,
    staffMembershipApplicationsReducer,
    staffMembershipRenewalsReducer,
    staffMembershipApplicationsDetailsReducer,
    staffMembershipRenewalsDetailsReducer,
    staffMembershipMembersReducer,
    staffCertificationApplicationReducer,
    staffCertificationAssessmentCriteriaAddReducer,
    staffCertificationAssessmentCriteriaListReducer,
    staffCertificationAssessmentCriteria: staffAssessmentCriteriaReducer,
    staffSurvey: staffSurveyReducer,
    survey: sharedSurveyReducer,
    // directory
    staffCompanyDetailsOverviewReducer,
    staffCompanyDetailsMembershipReducer,
    staffCompanyDetailsEventsReducer,
    staffCompanyDetailsTeamManagementReducer,
    staffCompanyDetailsLoggingActivityReducer,
    // event
    eventsAllReducer,
    eventsAttendeeReducer,
    eventsAttendeeDetailsReducer,
    eventsCreationReducer,
    eventsRegistrationReducer,
    eventsDateReducer,
    eventsTrackReducer,
    eventsTicketReducer,
    eventsMaterialReducer,
    eventsChecklistReducer,
    eventsExpenditureReducer,
    promoCodes: promoCodesReducer,
    public: publicReducer, // Public Reducer
    //dinner event
    dinnerEventsAllEventsReducer,
    eventsDinnerAllReducer,
    // admin
    groupReducer,
    groupAddReducer,
    userReducer,
    userAddReducer,
    userDetailReducer,
    countryReducer,
    countryAddReducer,
    userManualReducer,
    userManualAddReducer,
    dialogModalReducer,
    teamManagementReducer,
    teamMembersDetailsReducer,
    staffCertificationSTemplateReducer,
    // Analytics
    eventsAnalyticsReducer,
    certificationIssuanceAnalyticsReducer,
    certificationRenewalAnalyticsReducer,
    membershipIssuanceAnalyticsReducer,
    membershipRenewalAnalyticsReducer,
});
export default rootReducer;
