import withQuery from 'with-query';

import {
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST,
    MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE,
} from '../../constants/actionTypesMemberCertification';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

export function memberCertApplicationListGetInfo(status, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'memberApplicationListByType',
        });
        const body = {
            type,
            status,
        };
        let reducerActionForSuccess =
            status == 'ongoing'
                ? MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING
                : MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST,
            reducerActionForSuccess,
            MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE,
        );
    };
}
