import React from 'react';
import PropTypes from 'prop-types';

import '../../css/cyder/table/dashboard-table.css';

const TableWidgeEventsListWidget = ({ items }) => (
    <div data-dashboard-table="event-list">
        <div className="dashboard-table">
            <table className="table table-unbordered table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Company</th>
                    </tr>
                </thead>
                <tbody>
                    {items &&
                        items.map((item, i) => (
                            <tr key={i}>
                                <td className="ticker">{item.date}</td>
                                <td className="ticker">{item.name}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <button className={`btn btn-primary btn-circle btn-xs btn-flat`}>
                <span>more</span>
            </button>
        </div>
    </div>
);

export default TableWidgeEventsListWidget;
