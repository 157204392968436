const defaultState = {
    questions: [],
};

export default function memberQuestionnairesReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        default:
            return newState;
    }
}
