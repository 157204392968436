import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import history from '../../../../../history';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'reactstrap';

import { getAnswersByApplicationId } from 'actions/staff/survey/surveyAction.js';

import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

const ButtonComponent = props => {
    const { color, style, label, icon, className, onClick } = props;
    return (
        <Button color={color} className={className} style={style} onClick={onClick}>
            <i className="material-icons align-text-bottom">{icon}</i>
            &nbsp; {label}
        </Button>
    );
};

class CertApplicationSurveyResultPanel extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.back = this.back.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: false,
            pageSize: 50,
            pageCount: 0,
        };
    }
    componentDidMount() {}
    search() {
        this.setState({
            loading: true,
        });
        this.props
            .getAnswersByApplicationId(this.props.match.params.id)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: 1,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    back() {
        history.replace(this.props.survey.postSurveyResultViewUrl);
    }
    render() {
        console.log('List', this.state.totalNum);
        const columns = [
            { Header: 'Question', accessor: 'questionText', style: { whiteSpace: 'unset' } },
            {
                Header: 'Answer',
                accessor: 'id',
                Cell: props => {
                    return props.original.answerText != null ? props.original.answerText : props.original.answerOption;
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12} className="d-flex">
                        <ButtonComponent onClick={this.back} className="primary-btn-style-outline" icon="chevron_left" label="Back" />

                        {/* <i onClick={this.back} style={{ cursor: 'pointer' }} className={`fa fa-2x fa-angle-left`} />
                        <div
                            style={{
                                fontSize: '20px',
                                marginLeft: '20px',
                                cursor: 'pointer',
                            }}
                            onClick={this.back}
                        >
                            back
                        </div> */}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            hideTotalNum={true}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.list.length}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search();
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        survey: state.survey,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAnswersByApplicationId: applicationId => {
            return dispatch(getAnswersByApplicationId(applicationId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertApplicationSurveyResultPanel));
