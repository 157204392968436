/**
 * Event RSVP action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_PRINT_ROOT } from 'config';

// memberEvents - member
export function loadEventInfo(eventId, mode) {
    return (dispatch, getState) => {
        const urlLocation = mode === 'member' ? 'event' : 'public-event';
        const action = mode === 'member' ? 'findByEventId' : 'findEventByEventId';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadEventDatesInfo(eventId, mode) {
    return (dispatch, getState) => {
        const urlLocation = mode === 'member' ? 'event-date' : 'public-event';
        const action = mode === 'member' ? 'findByEventId' : 'findEventDateById';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadEventMultipleDatesInfo(eventId, mode) {
    return (dispatch, getState) => {
        const urlLocation = mode === 'member' ? 'event-date' : 'public-event';
        const action = mode === 'member' ? 'find-multiple-by-eventid' : 'find-multiple-event-dates-by-id';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadEventTickets(id, mode) {
    return (dispatch, getState) => {
        const urlLocation = 'public-event';
        const action = 'findEventTicketById';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadAllEventTicketsForStaff(id, mode) {
    return (dispatch, getState) => {
        const urlLocation = 'public-event';
        const action = 'findEventTicketByEventIForStaff';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadRegistrations(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getRegistrationByEventAndCompanyId',
            eventId: id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadRegistrationInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'findbyid',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadAttendants(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getAllAttendeeByRegistrationId',
            registrationId: id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadEventTracks(eventId, mode) {
    return (dispatch, getState) => {
        const urlLocation = mode === 'member' ? 'event-track' : 'public-event';
        const action = mode === 'member' ? 'findByEventId' : 'findTrackByEventId';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/${urlLocation}`, {
            action,
            eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function loadEventMaterials(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-material`, {
            action: 'get-by-eventid',
            id: id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function submitRegistration(data, mode) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: mode === 'member' ? 'createRegistration' : 'createRegistrationFromPublic-v2',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function eventLoadEventPaymentModes(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-payment-mode`, {
            action: 'get-by-registrationid',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
// -- default template
export function getByEventId(eventId, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'get-by-eventid',
            id: eventId,
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}
export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'update',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}
// membershipApplicationDetailsPage - staff
export function applicationLoadEventPaymentModes(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment-mode`, {
            action: 'getFinancePaymentMode',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
// API needs confirmation
export function applicationSumbitMembershipApplicationPayment(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'savePaymentInfoByStaff',
        });
        const body = {
            applicationId,
            amountReceived: 0,
            invoiceNumber: 0,
            bank: '',
            remarks: '',
            companyId: 0,
            invoiceId: 0,
            referenceNumber: '',
            paymentMode: 0,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
export function updatePaymentStatus(registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'update-xero-payment',
        });
        const body = {
            id: registrationId,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
export function validatePromoCode(code, eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'promoCodeValidation',
        });
        const data = {
            promoCode: code,
            eventId,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function printReceipt(paymentId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-event-receipt',
            id: paymentId,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url);
    };
}

export function sendReceipt(paymentId, registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'sendReceiptEmail',
        });
        const body = {
            paymentId,
            registrationId,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
