import React from 'react';
import { connect } from 'react-redux';
import { b64toBlob } from 'js/util';

import { NavItem, NavLink, Container, Row, Col, Button } from 'reactstrap';
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import OverviewPanel from './MemberOverviewPanel';
import DialogModal from 'cyder/modals/DialogModal';
import history from '../../../../history';

// Overview
import {
    staffCompanyDetailsGetMemberInfo,
    staffCompanyDetailsGetCompanyInfo,
    staffCompanyDetailsGetCertificationInfo,
    staffCompanyDetailsGetEventInfo,
    staffCompanyDetailsOverviewHandleInputChange,
    staffCompanyDetailsOverviewResetToDefault,
} from 'actions/staff/company/staffCompanyDetailsOverviewAction';
// Membership
import {
    staffCompanyDetailsGetCertificationPDF,
    staffCompanyDetailsGetMembershipLetter,
    staffCompanyDetailsGetMemberApplication,
    staffCompanyDetailsUpdateMembershipTier,
    staffCompanyDetailsPrintCertByCompanyId,
    staffCompanyDetailsMembershipResetToDefault,
    staffCompanyDetailsMembershipSetEventModalToggle,
} from 'actions/staff/company/staffCompanyDetailsMembershipAction';
// Events
import {
    staffCompanyDetailsGetEventAttended,
    staffCompanyDetailsHandleInputChange,
} from 'actions/staff/company/staffCompanyDetailsEventsAction';
//Team Management
import { staffCompanyDetailsGetTeamMemberList } from 'actions/staff/company/staffCompanyDetailsTeamManagementAction';
// Logging activities
import {
    staffCompanyDetailsLoggingResetToDefault,
    staffCompanyDetailsLoggingGetLoggingActivities,
} from 'actions/staff/company/staffCompanyDetailsLoggingActivityAction';
// Others
import {
    memeberMembershipApplicationGetBusinessActivity,
    memberMembershipApplicationGetCategories,
} from 'actions/member/company/memberCompanyAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

class MemberDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.membershipTierOnChange = this.membershipTierOnChange.bind(this);
        this.updateMembershipTier = this.updateMembershipTier.bind(this);
        this.printCert = this.printCert.bind(this);
        this.state = {
            activeTab: 'overview',
            companyId: 0,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ companyId: this.props.match.params.companyid });
        this.getRelevantStuffs(this.props.match.params.companyid);
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    getRelevantStuffs(companyId) {
        this.props.overviewResetToDefault().then(() => {
            this.props.overviewGetBusinessActivities();
            this.props.overviewGetOrganisationMainCategory();
            this.props.overviewGetMemberInfo(companyId);
            this.props.overviewGetCertificationInfo(companyId);
            this.props.overviewGetEventInfo(companyId);
            this.props.overviewGetCompanyInfo(companyId);
        });
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => this.getRelevantStuffs(this.state.companyId),
            );
        }
    }
    renderNavItem(navItems) {
        return navItems.map(navItem => (
            <NavItem key={navItem.key}>
                <NavLink
                    className={this.state.activeTab === navItem.key ? 'active' : null}
                    onClick={() => {
                        this.toggleTab(navItem.key);
                    }}
                >
                    <i className={'fa fa-fw ' + navItem.icon} />
                    {' ' + navItem.text}
                </NavLink>
            </NavItem>
        ));
    }
    // Overview Tab
    getBusinessActivityName(businessActivities, mainCategoryId) {
        let businessActivity = '';
        businessActivities.forEach(activity => {
            if (activity.id === mainCategoryId) {
                businessActivity = activity.name;
            }
        });
        return businessActivity;
    }
    getMainCategoryName(categories, mainCategoryId) {
        let mainCategory = '';
        categories.forEach(category => {
            if (category.mainCategory.id === mainCategoryId) {
                mainCategory = category.mainCategory.name;
            }
        });
        return mainCategory;
    }
    // Membership
    updateMembershipTier() {
        this.props.dialogModalToggle();
        this.props.membershipSetEventModalData({
            modalAction: () =>
                this.props.membershipUpdateMembershipTier(
                    this.props.match.params.companyid,
                    this.props.overview.selectedMembershipTier.value,
                ),
            modalBody: 'Are you sure you want to update the membership tier',
        });
    }
    printCert() {
        this.props
            .memebershipPrintCertByCompanyId(this.props.match.params.companyid)
            .then(res => {
                var blob = b64toBlob(res.data, 'application/pdf');
                var blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl);
            })
            .catch(error => {
                throw error;
            });
    }
    membershipTierOnChange(e) {
        this.props.overviewHandleInputChange(e);
    }
    render() {
        const activeTab = this.state.activeTab;
        return (
            <div>
                <DialogModal
                    modalAction={this.props[activeTab].modalAction}
                    modalHeader={this.props[activeTab].modalHeader}
                    modalBody={this.props[activeTab].modalBody}
                    loading={this.props[activeTab].modalLoading}
                    buttonDisabler={this.props[activeTab].modalLoading}
                    boundForceUpdate={this.boundForceUpdate}
                    modalOnlyOneButton={this.props[activeTab].modalOnlyOneButton}
                />
                {this.props.overview.loading ? (
                    <LoadingSpinner />
                ) : (
                    <Container>
                        <Row style={{ marginTop: '30px' }}>
                            <Col xs={12} className="d-flex">
                                <h3>
                                    {/* <img
                                        onClick={e => {
                                            history.push(`../memberdirectory`);
                                        }}
                                        src="/assets/icons/icon_mbr_home_membership1.png"
                                        style={{ marginBottom: '5px' }}
                                        width="48"
                                        height="48"
                                    /> */}
                                    &nbsp;
                                    <strong
                                        onClick={e => {
                                            history.push(`../memberdirectory`);
                                        }}
                                    >
                                        MEMBERS DIRECTORY
                                    </strong>
                                </h3>
                                <Button
                                    color="warning"
                                    className="ml-auto d-inline-flex mt-2 primary-btn-style"
                                    style={{
                                        height: '40px',
                                    }}
                                    onClick={e => {
                                        history.push(`../memberdirectory`);
                                    }}
                                >
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <OverviewPanel
                            count={this.props.overview.count}
                            companyId={this.state.companyId}
                            memberInfo={this.props.overview.memberInfo}
                            companyInfo={this.props.overview.companyInfo}
                            subCategory={this.props.overview.subCategory}
                            businessActivities={this.props.member.form.businessActivities}
                            getBusinessActivityName={this.getBusinessActivityName}
                            mainCategories={this.props.member.form.categories}
                            getMainCategoryName={this.getMainCategoryName}
                        />
                    </Container>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        member: state.memberCompanyReducer,
        overview: state.staffCompanyDetailsOverviewReducer,
        membership: state.staffCompanyDetailsMembershipReducer,
        certification: {},
        events: state.staffCompanyDetailsEventsReducer,
        team: state.staffCompanyDetailsTeamManagementReducer,
        logging: state.staffCompanyDetailsLoggingActivityReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // Overview
        overviewGetCompanyInfo: companyId => {
            dispatch(staffCompanyDetailsGetCompanyInfo(companyId));
        },
        overviewGetBusinessActivities: () => {
            dispatch(memeberMembershipApplicationGetBusinessActivity());
        },
        overviewGetOrganisationMainCategory: () => {
            dispatch(memberMembershipApplicationGetCategories());
        },
        overviewGetMemberInfo: companyId => {
            return dispatch(staffCompanyDetailsGetMemberInfo(companyId));
        },
        overviewGetCertificationInfo: companyId => {
            dispatch(staffCompanyDetailsGetCertificationInfo(companyId));
        },
        overviewGetEventInfo: companyId => {
            dispatch(staffCompanyDetailsGetEventInfo(companyId));
        },
        overviewHandleInputChange: event => {
            dispatch(staffCompanyDetailsOverviewHandleInputChange(event));
        },
        overviewResetToDefault: () => {
            return dispatch(staffCompanyDetailsOverviewResetToDefault());
        },
        // Membership
        membershipGetMemberApplication: companyId => {
            dispatch(staffCompanyDetailsGetMemberApplication(companyId));
        },
        membershipGetCertificatonPDF: companyId => {
            dispatch(staffCompanyDetailsGetCertificationPDF(companyId));
        },
        membershipGetMembershipLetter: companyId => {
            dispatch(staffCompanyDetailsGetMembershipLetter(companyId));
        },
        memebershipPrintCertByCompanyId: companyId => {
            return dispatch(staffCompanyDetailsPrintCertByCompanyId(companyId));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        membershipSetEventModalData: data => {
            dispatch(staffCompanyDetailsMembershipSetEventModalToggle(data));
        },
        membershipUpdateMembershipTier: (companyId, membershipTier) => {
            return dispatch(staffCompanyDetailsUpdateMembershipTier(companyId, membershipTier));
        },
        membershipResetToDefault: () => {
            return dispatch(staffCompanyDetailsMembershipResetToDefault());
        },
        // Event
        eventsGetEventAttended: (companyId, keyword, page, pageSize, sorted, filtered) => {
            dispatch(staffCompanyDetailsGetEventAttended(companyId, keyword, page, pageSize, sorted, filtered));
        },
        eventsHandleInputChange: (event, companyId) => {
            const value = event.target.value;
            if (value.length === 0) {
                dispatch(staffCompanyDetailsGetEventAttended(companyId, value));
            }
            dispatch(staffCompanyDetailsHandleInputChange(value));
        },
        // Team Management
        teamGetMemberList: (a, b, c, d, companyId) => {
            dispatch(staffCompanyDetailsGetTeamMemberList(a, b, c, d, companyId));
        },
        // Logging Activities
        loggingResetToDefault: () => {
            return dispatch(staffCompanyDetailsLoggingResetToDefault());
        },
        loggingGetLoggingActivitiesList: companyId => {
            dispatch(staffCompanyDetailsLoggingGetLoggingActivities(companyId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MemberDetailPage));
