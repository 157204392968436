/**
 * Create certificate details panel
 *
 * Author: Fernando
 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from 'history.js';
import matchSorter from 'match-sorter';
import { Row, Col, Input, FormGroup, Button } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import ButtonIcon from 'cyder/button/ButtonIcon';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import {
    fetchXeroInventoryCodes,
    fetchNumberOfTicks,
    fetchNumberOfTicksBySubId,
    getAllCertificateList,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { SGBC_S3_ROOT } from 'config';
import { pad, actionExecutor } from 'js/util';

const tickOptions = [
    { key: 1, value: 1, label: 1 },
    { key: 2, value: 2, label: 2 },
    { key: 3, value: 3, label: 3 },
    { key: 4, value: 4, label: 4 },
];
const tickOptionsService = [
    { key: 1, value: 5, label: 1 },
    { key: 2, value: 6, label: 2 },
    { key: 3, value: 7, label: 3 },
    { key: 4, value: 8, label: 4 },
    { key: 5, value: null, label: 'No Rating' },
];

class CreateCertificateDetailsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.onAddClicked = this.onAddClicked.bind(this);
        this.onTicksChange = this.onTicksChange.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.handleChangeItemCode = this.handleChangeItemCode.bind(this);
        this.state = {
            add: false,
            loading: false,
            certificationNumber: '',
            price: '',
            testValue: '',
            selectedBrandModel: [],
            itemCode: '',
            openCertificatesModal: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: false,
                negativeButtonHide: true,
                type: 'save',
                action: '',
                certificates: [],
            },
        };
    }
    componentDidMount() {
        const { type, applicationId, fetchNumberOfTicksBySubId, fetchXeroInventoryCodes } = this.props;
        const arg = type === 'service' ? 'CS' : 'CP';
        fetchXeroInventoryCodes(arg);
        fetchNumberOfTicksBySubId(applicationId);
    }
    showLoading = loading => {
        this.setState({ loading });
    };
    onAddClicked() {
        const { certificationNumber, itemCodes, itemCode, onHandleChange } = this.props;
        this.setState({
            certificationNumber: certificationNumber,
            add: true,
        });

        let defaultItemCode = itemCodes && itemCodes.length > 0 ? itemCodes[2].key : null;
        let defaultTick = 2;
        let fakeEvent = {
            target: {
                name: 'itemCode',
                value: itemCode ? itemCode.value : defaultItemCode,
            },
        };
        onHandleChange(fakeEvent);
    }
    onCheckBoxChange(checked, brandModel) {
        let { selectedBrandModel } = this.state;
        if (checked) {
            selectedBrandModel.push(brandModel);
        } else {
            selectedBrandModel = selectedBrandModel.filter(element => element !== brandModel);
        }
        this.setState({
            selectedBrandModel,
        });
    }
    onTicksChange(e) {
        const obj = {
            ticks: e.target.value,
        };
        this.setState(obj);
        if (this.props.type === 'service') this.props.updateServiceWithCertificate(obj);
    }
    handleChangeItemCode(e) {
        var stringTemp = e.target.value;
        var temp = stringTemp.split(' | ');
        const obj = {
            itemCode: temp[0],
        };
        this.setState(obj);
        if (this.props.type === 'service') this.props.updateServiceWithCertificate(obj);
    }
    viewCatalogueOrPicture(id) {
        window.open(`${SGBC_S3_ROOT}${id}`);
    }
    viewCertificates = () => {
        this.showModalIf('Certificates', null, true);
        this.setState({ openCertificatesModal: true });
        const callback = async () => {
            const { data } = await this.props.getAllCertificateList();
            this.setState({ certificates: data });
        };
        actionExecutor(this.showLoading, callback);
    };
    modalAction = () => {
        const { modal } = this.state;
        this.modalToggle();
    };
    modalToggle = () => {
        const { modal } = this.state;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    render() {
        const { itemCodes, ticks, status } = this.props;
        // console.log("ticks: ", ticks)

        const { numberOfTicks, certificationNumber, itemCode, testValue } = this.props.certificateDetails;
        const completeColumns = [
            {
                Header: '',
                accessor: '',
                minWidth: 20,
                Cell: props => {
                    const { certificationNumber } = props.value;
                    const { brand, model } = props.original;
                    if (!this.props.readonly && !certificationNumber) {
                        return (
                            <Input
                                type="checkbox"
                                className="custom-checkbox"
                                onChange={e => {
                                    const { checked } = e.target;
                                    this.onCheckBoxChange(checked, `${brand}|${model}`);
                                }}
                            />
                        );
                    }
                    return;
                },
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                minWidth: 150,
            },
            {
                Header: 'Model',
                accessor: 'model',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 170,
            },
            {
                Header: 'Cert Number',
                accessor: 'certificationNumber',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: '',
                accessor: '',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                sortable: false,
                minWidth: 40,
                Cell: props => {
                    const { certificationNumber } = props.original;
                    const { printCertificate } = this.props;
                    const { status } = this.props;
                    if (!certificationNumber) return;
                    if (status === 'Issued') {
                        return (
                            <i
                                className="mr-1 material-icons align-text-bottom"
                                style={{ color: '#6ba17d', cursor: 'pointer' }}
                                onClick={e => printCertificate(certificationNumber)}
                            >
                                cloud_download
                            </i>
                        );
                    }
                },
            },
            {
                Header: 'Item Code',
                accessor: 'itemCode',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Rating',
                accessor: 'ticks',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Test Value',
                accessor: 'testValue',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: '',
                sortable: false,
                accessor: '',
                maxWidth: 40,
                Cell: props => {
                    if (this.props.readonly || !props.value.certificationNumber) return;
                    return (
                        <i
                            className="mr-1 material-icons align-text-bottom"
                            style={{ color: 'red' }}
                            onClick={e => {
                                this.props.unassignedBrandFromCertificate(`${props.original.brand}|${props.original.model}`);
                            }}
                        >
                            delete
                        </i>
                    );
                },
            },
        ];
        const productCatalogueAndPic = [
            {
                Header: 'Product Picture',
                sortable: false,
                accessor: 'productPicture',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                maxWidth: 110,
                Cell: props => {
                    if (!props.value) return;
                    return <ViewButton onClick={() => this.viewCatalogueOrPicture(props.value)} icon="chrome_reader_mode" />;
                },
            },
            {
                Header: 'Product Catalogue',
                sortable: false,
                accessor: 'productCatalogue',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                maxWidth: 110,
                Cell: props => {
                    if (!props.value) return;
                    return <ViewButton onClick={() => this.viewCatalogueOrPicture(props.value)} icon="chrome_reader_mode" />;
                },
            },
        ];

        // FILTER PRODUCT BRANDS DATA
        var brands = this.props.productBrands;
        var brandsTable = [];
        if (this.props.type === 'product' && brands) {
            var brands = JSON.parse(this.props.productBrands);
            brands.forEach(brand => {
                brand.models.forEach(model => {
                    brandsTable.push({
                        brand: brand.name,
                        model: model.name,
                        certificationNumber: model.certificationNumber,
                        itemCode: model.itemCode,
                        testValue: model.testValue,
                        ticks: model.ticks,
                        productCatalogue: model.productCatalogue,
                        productPicture: model.productPicture,
                    });
                });
            });
        }

        // CONDITIONALLLY MANIPULATE COLULMNS
        var columns = [];
        switch (status) {
            case 'Draft':
            case 'Submitted':
            case 'Accepted':
            case 'Assessment':
                columns = completeColumns.slice(1, 3);
                break;
            case 'Issued':
            case 'Finalisation':
                productCatalogueAndPic.forEach(obj => completeColumns.splice(7, 0, obj));
                columns = completeColumns;
                break;
            default:
                columns = completeColumns;
                break;
        }

        const certNumber = this.props.certificateDetails.certificationNumber;
        const unassignedCertBrand = brandsTable.filter(element => !element.certificationNumber);

        // console.log("brandsTable: ", brandsTable)

        const customDialog = <CertListModal loading={this.state.loading} certificates={this.state.certificates} />;
        return (
            <Fragment>
                <DialogModal
                    size="lg"
                    modalAction={this.modalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.openCertificatesModal ? customDialog : this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <div>
                    {this.props.type === 'product' && (
                        <React.Fragment>
                            {!this.props.readonly && (
                                <RowWrapper>
                                    {unassignedCertBrand.length > 0
                                        ? `Select the respective Brand and Model for each Certificate. Repeat for each Certificate to be issued.`
                                        : `Please delete any of the below certificate to assign new certificate number`}
                                </RowWrapper>
                            )}
                            <RowWrapper>
                                {brandsTable != null && brandsTable.length > 0 && (
                                    <ReactTable
                                        className="-highlight mb-2"
                                        data={brandsTable}
                                        columns={columns}
                                        defaultPageSize={20}
                                        showPagination={false}
                                        filterable={false}
                                        minRows={0}
                                        manual
                                    />
                                )}
                            </RowWrapper>
                        </React.Fragment>
                    )}

                    {!this.props.readonly && this.props.type === 'product' && !this.state.add && unassignedCertBrand.length > 0 && (
                        <RowWrapper>
                            <ButtonIcon color="info" icon="check" label="Add Certificate" onClick={this.onAddClicked} />
                        </RowWrapper>
                    )}
                    <FormGroup className="pt-4 pb-4">
                        {(this.props.type === 'service' || this.state.add) && (
                            <React.Fragment>
                                <RowWrapper
                                    xs="3"
                                    label={this.props.type === 'product' ? 'Certification Number: SGBP' : 'Certification Number: SGBS'}
                                >
                                    <input
                                        style={{ width: '400px' }}
                                        type="text"
                                        maxlength="7" // Updated by Fernando on 20 Jun 2020, CRMSD-290
                                        name="certificationNumber"
                                        className="form-control"
                                        placeholder="Please enter certification number"
                                        value={certNumber}
                                        onChange={this.props.onHandleChange}
                                    />
                                    <span style={{ fontSize: '10px' }}>
                                        <i>Maximum of 7 characters.</i>
                                    </span>
                                </RowWrapper>
                                <RowWrapper xs="3" style={{ marginTop: '10px' }} label="Certificate Item Code">
                                    <select
                                        style={{ width: '400px' }}
                                        name="itemCode"
                                        className="form-control"
                                        placeholder="Please select item code"
                                        value={itemCode}
                                        onChange={this.props.onHandleChange}
                                    >
                                        {itemCodes &&
                                            itemCodes.map((item, i) => {
                                                const selected = itemCode && itemCode != null ? item.value == itemCode : ``;
                                                return (
                                                    <option selected={selected} value={item.value} key={i}>
                                                        {item.text}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </RowWrapper>
                                <RowWrapper xs="3" label="Rating" style={{ marginTop: '10px' }}>
                                    <select
                                        style={{ width: '400px' }}
                                        name="numberOfTicks"
                                        id="numberOfTicks"
                                        className="form-control"
                                        onChange={this.props.onHandleChange}
                                    >
                                        {(this.props.type === 'service' &&
                                            tickOptionsService.map((item, i) => {
                                                const selected = ticks && ticks !== null ? item.value === ticks : tickOptions[0].value;
                                                const label = item.value == null ? item.label : 'L' + item.label;

                                                return (
                                                    <option id="selectedTick" selected={selected} key={i} value={item.value}>
                                                        {label}
                                                    </option>
                                                );
                                            })) ||
                                            (this.props.type === 'product' &&
                                                tickOptions.map(item => {
                                                    let suggestedTick = 1;
                                                    try {
                                                        if (
                                                            this.state.selectedBrandModel != null &&
                                                            this.state.selectedBrandModel.length > 0 &&
                                                            ticks != null &&
                                                            ticks.length > 0
                                                        ) {
                                                            for (var i = 0; i < this.state.selectedBrandModel.length; i++) {
                                                                let selectedModel = this.state.selectedBrandModel[i].split('|')[1];
                                                                for (var j = 0; j < ticks.length; j++) {
                                                                    if (selectedModel == ticks[i].id) {
                                                                        suggestedTick = ticks[i].finalRating;
                                                                        // console.log(
                                                                        //     'suggested tick for model [',
                                                                        //     selectedModel,
                                                                        //     '] is ',
                                                                        //     suggestedTick,
                                                                        // );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } catch (e) {
                                                        console.log('fallback to suggestedTick = 1');
                                                    }

                                                    const selected = item.value == suggestedTick;

                                                    return (
                                                        <option id="selectedTick" selected={selected} key={item.key} value={item.value}>
                                                            {item.label}
                                                        </option>
                                                    );
                                                }))}
                                    </select>
                                </RowWrapper>
                                {this.props.type === 'product' && (
                                    <RowWrapper xs="3" label="Test Value (if applicable)" style={{ marginTop: '10px' }}>
                                        <input
                                            style={{ width: '400px' }}
                                            type="text"
                                            name="testValue"
                                            className="form-control"
                                            placeholder="Please enter test value"
                                            value={this.props.testValue}
                                            onChange={this.props.onHandleChange}
                                        />
                                        <span style={{ fontSize: '10px' }}>
                                            <i>(this is only applicable for VOC Emission, VOC Content, or Formaldehyde)</i>
                                        </span>
                                    </RowWrapper>
                                )}
                            </React.Fragment>
                        )}

                        {this.props.type === 'product' && this.state.add && (
                            <RowWrapper xs="3" style={{ marginTop: '10px' }}>
                                <ButtonIcon
                                    icon="check"
                                    label="Save Certificate"
                                    onClick={e => {
                                        const certNumber = pad(certificationNumber, 4);

                                        // Updated by Fernando on 10 Sep 2019, to get the recommended value of tick
                                        // const numOfTickFromUI = document.getElementById('selectedTick').value;
                                        const numOfTickFromObj = document.getElementById('numberOfTicks');
                                        const numOfTickFromUI = numOfTickFromObj.options[numOfTickFromObj.selectedIndex].value;
                                        // console.log("numberOfTicks ", numberOfTicks, ", numOfTickFromUI: ", numOfTickFromUI)
                                        const tickNumber = numberOfTicks || numOfTickFromUI || ticks;
                                        const brandModel = this.state.selectedBrandModel;
                                        var finalCertNumber = '';
                                        if (this.props.type == 'product') {
                                            finalCertNumber = 'SGBP ' + certNumber;
                                        } else if (this.props.type == 'service') {
                                            finalCertNumber = 'SGBS ' + certNumber;
                                        }
                                        // Validation checking here
                                        this.props.checkCertNumberDuplication(finalCertNumber);
                                        this.props.updateProductBrandsWithCertificate(
                                            finalCertNumber,
                                            itemCode,
                                            tickNumber,
                                            brandModel,
                                            testValue,
                                        );
                                        // console.log("ticks: ",ticks, ", tickNumber: ", tickNumber)
                                        this.setState({
                                            add: false,
                                            certificationNumber: '',
                                            itemCode: '',
                                            ticks: '',
                                            testValue: '',
                                            selectedBrandModel: [],
                                        });
                                    }}
                                />
                            </RowWrapper>
                        )}
                    </FormGroup>
                </div>
            </Fragment>
        );
    }
}

const ViewButton = ({ onClick, icon }) => {
    return (
        <Button className="primary-btn-style" onClick={onClick}>
            <i className="material-icons align-text-bottom">{icon}</i>
        </Button>
    );
};

const RowWrapper = props => {
    const { xs, label, children, style, className } = props;
    return (
        <Row style={style} className={className}>
            {label && <Col xs={xs}>{label}</Col>}
            <Col>{children}</Col>
        </Row>
    );
};

const CertListModal = props => {
    const { loading, certificates } = props;
    const columns = [
        {
            Header: 'Running Cert No.',
            accessor: 'certNumber',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certNumber'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Status',
            accessor: 'status',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Company',
            accessor: 'companyName',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
            filterAll: true,
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: '',
            accessor: '',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            minWidth: 70,
            Cell: ({ value }) => {
                const url = `/staff/certification/applications/viewproduct/${value.certAppId}`;
                return (
                    <Button className="ml-2 align-middle primary-btn-style primary-btn-xs" onClick={() => history.push(url)}>
                        <i className="fas fa-folder-open mr-1" />
                        Open
                    </Button>
                );
            },
        },
    ];
    if (!certificates && !loading) return 'Failed to load certificate list.';
    return (
        <ReactTable columns={columns} filterable={true} defaultPageSize={10} className="-striped -highlight" data={certificates} />
        // <CyderReactTable
        //     manual
        //     minRows={0}
        //     filterable={false}
        //     columns={columns}
        //     className="-highlight mb-2"
        //     showPagination={false}
        //     defaultPageSize={10}
        //     loading={loading}
        //     data={certificates}
        //     totalNum={certificates && certificates.length}
        // />
    );
};

const mapStateToProps = ({ staffCertificationApplicationReducer }) => {
    const xero = staffCertificationApplicationReducer && staffCertificationApplicationReducer.xeroCodes;
    const itemCodes =
        xero &&
        xero.map(item => ({
            key: item.itemCode,
            value: item.itemCode,
            text: item.itemCode + ' | ' + item.itemName,
        }));
    const ticks = staffCertificationApplicationReducer && staffCertificationApplicationReducer.ticks;
    return { itemCodes, ticks };
};

const actions = { fetchXeroInventoryCodes, fetchNumberOfTicks, fetchNumberOfTicksBySubId, getAllCertificateList };

// prettier-ignore
export default connect(mapStateToProps, actions)(CreateCertificateDetailsPanel);
