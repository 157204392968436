export const COUNTRY_SEARCH_REQUEST = 'COUNTRY_SEARCH_REQUEST';
export const COUNTRY_SEARCH_SUCCESS = 'COUNTRY_SEARCH_SUCCESS';
export const COUNTRY_SEARCH_FAILURE = 'COUNTRY_SEARCH_FAILURE';
export const COUNTRY_KEYWORD_SEARCH_REQUEST = 'COUNTRY_KEYWORD_SEARCH_REQUEST';
export const COUNTRY_KEYWORD_SEARCH_SUCCESS = 'COUNTRY_KEYWORD_SEARCH_SUCCESS';
export const COUNTRY_KEYWORD_SEARCH_FAILURE = 'COUNTRY_KEYWORD_SEARCH_FAILURE';
export const COUNTRY_TOGGLE_CHECKBOX = 'COUNTRY_TOGGLE_CHECKBOX';
export const COUNTRY_TOGGLE_CHECKBOX_ALL = 'COUNTRY_TOGGLE_CHECKBOX_ALL';

export const COUNTRY_CHANGE_INPUT_KEYWORD_VALUE = 'COUNTRY_CHANGE_INPUT_KEYWORD_VALUE ';

// Country Details / Country Add
export const COUNTRY_ADD_GET_DETAIL_REQUEST = 'COUNTRY_ADD_GET_DETAIL_REQUEST';
export const COUNTRY_ADD_GET_DETAIL_SUCCESS = 'COUNTRY_ADD_GET_DETAIL_SUCCESS';
export const COUNTRY_ADD_GET_DETAIL_FAILURE = 'COUNTRY_ADD_GET_DETAIL_FAILURE';

export const COUNTRY_ADD_SAVE_COUNTRY_REQUEST = 'COUNTRY_ADD_SAVE_COUNTRY_REQUEST';
export const COUNTRY_ADD_SAVE_COUNTRY_SUCCESS = 'COUNTRY_ADD_SAVE_COUNTRY_SUCCESS';
export const COUNTRY_ADD_SAVE_COUNTRY_FAILURE = 'COUNTRY_ADD_SAVE_COUNTRY_FAILURE';

export const COUNTRY_ADD_DELETE_COUNTRY_REQUEST = 'COUNTRY_ADD_DELETE_COUNTRY_REQUEST';
export const COUNTRY_ADD_DELETE_COUNTRY_SUCCESS = 'COUNTRY_ADD_DELETE_COUNTRY_SUCCESS';
export const COUNTRY_ADD_DELETE_COUNTRY_FAILURE = 'COUNTRY_ADD_DELETE_COUNTRY_FAILURE';

export const COUNTRY_ADD_SET_FIELDS = 'COUNTRY_ADD_SET_FIELDS';

export const COUNTRY_ADD_CHANGE_FIELD_VALUE = 'COUNTRY_ADD_CHANGE_FIELD_VALUE ';

export const COUNTRY_ADD_MODAL_TOGGLE = 'COUNTRY_ADD_MODAL_TOGGLE';
