import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';

import { Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';

import { save, update, remove, load } from 'actions/staff/dinner/registration/action.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);

        this.eventIdChange = this.eventIdChange.bind(this);
        this.back = this.back.bind(this);
        this.registrationTypeChange = this.registrationTypeChange.bind(this);
        this.mealTypeChange = this.mealTypeChange.bind(this);
        this.mealTypeAmountChange = this.mealTypeAmountChange.bind(this);
        this.tableTypeChange = this.tableTypeChange.bind(this);
        this.companyAddressChange = this.companyAddressChange.bind(this);
        this.companyNameChange = this.companyNameChange.bind(this);
        this.primaryContactDesignationChange = this.primaryContactDesignationChange.bind(this);
        this.primaryContactEmailChange = this.primaryContactEmailChange.bind(this);
        this.primaryContactPhoneChange = this.primaryContactPhoneChange.bind(this);
        this.primaryContactNameChange = this.primaryContactNameChange.bind(this);
        this.secondaryContactDesignationChange = this.secondaryContactDesignationChange.bind(this);
        this.secondaryContactEmailChange = this.secondaryContactEmailChange.bind(this);
        this.secondaryContactPhoneChange = this.secondaryContactPhoneChange.bind(this);
        this.secondaryContactNameChange = this.secondaryContactNameChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.prepareDataBeforeSave = this.prepareDataBeforeSave.bind(this);

        this.state = {
            loading: false,
            data: {
                eventId: '',

                standardMealAmount: 10,

                vegetarianMealAmount: 0,

                halalMealAmount: 0,

                companyAddress: '',

                companyName: '',

                contactEmail: '',

                contactPhone: '',

                contactName: '',

                contactDesignation: '',

                secondaryContactEmail: '',

                secondaryContactPhone: '',

                secondaryContactName: '',

                secondaryContactDesignation: '',

                id: '',
            },
            registrationType: { value: 'Individual', label: 'Individual' },
            mealType: [{ value: 'Standard', label: 'Standard' }],
            tableType: { value: 'Standard', label: 'Standard' },
            mealTypes: [
                { value: 'Standard', label: 'Standard' },
                { value: 'Vegetarian', label: 'Vegetarian' },
                { value: 'Halal', label: 'Halal' },
            ],
            tableTypes: [
                { value: 'VIP', label: 'VIP' },
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Gold', label: 'Gold' },
                { value: 'Standard', label: 'Standard' },
            ],
            registrationTypes: [{ value: 'Individual', label: 'Individual' }, { value: 'Company', label: 'Company' }],
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace(`/staff/galadinner/${this.props.match.params.eventid}/registration`);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        var mealType = [];
                        if (res.data.standardMealAmount !== 0) {
                            mealType.push({ value: 'Standard', label: 'Standard' });
                        }
                        if (res.data.vegetarianMealAmount !== 0) {
                            mealType.push({ value: 'Vegetarian', label: 'Vegetarian' });
                        }
                        if (res.data.halalMealAmount !== 0) {
                            mealType.push({ value: 'Halal', label: 'Halal' });
                        }
                        this.setState({
                            data: res.data,
                            tableType: {
                                value: res.data.tableType,
                                label: res.data.tableType,
                            },
                            mealType: mealType,
                            registrationType: {
                                value: res.data.registrationType,
                                label: res.data.registrationType,
                            },
                        });
                    }
                    this.showLoading(false);
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    eventId: this.props.match.params.eventid,

                    standardMealAmount: 10,

                    vegetarianMealAmount: 0,

                    halalMealAmount: 0,

                    companyAddress: '',

                    companyName: '',

                    contactEmail: '',

                    contactPhone: '',

                    contactName: '',

                    contactDesignation: '',

                    secondaryContactEmail: '',

                    secondaryContactPhone: '',

                    secondaryContactName: '',

                    secondaryContactDesignation: '',

                    id: '',
                },
                registrationType: { value: 'Individual', label: 'Individual' },
                mealType: [{ value: 'Standard', label: 'Standard' }],
                tableType: { value: 'Standard', label: 'Standard' },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        var tempHalal, tempStd, tempVege;
        tempHalal = 0;
        tempStd = 0;
        tempVege = 0;
        if (this.state.registrationType.value === 'Individual') {
            if (this.state.mealType[0].value === 'Standard') {
                newData.vegetarianMealAmount = 0;
                newData.halalMealAmount = 0;
                newData.standardMealAmount = 1;
            }
            if (this.state.mealType[0].value === 'Halal') {
                newData.halalMealAmount = 1;
                newData.standardMealAmount = 0;
                newData.vegetarianMealAmount = 0;
            }
            if (this.state.mealType[0].value === 'Vegetarian') {
                newData.vegetarianMealAmount = 1;
                newData.halalMealAmount = 0;
                newData.standardMealAmount = 0;
            }
        } else {
            this.state.mealType.map((element, index) => {
                if (element.value === 'Standard') tempStd = newData.standardMealAmount;
                if (element.value === 'Vegetarian') tempVege = newData.vegetarianMealAmount;
                if (element.value === 'Halal') tempHalal = newData.halalMealAmount;
            });
            newData.standardMealAmount = tempStd;
            newData.halalMealAmount = tempHalal;
            newData.vegetarianMealAmount = tempVege;
        }
        newData.registrationType = this.state.registrationType.value;
        newData.tableType = this.state.tableType.value;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace(`/staff/galadinner/${this.props.match.params.eventid}/registration`);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace(`/staff/galadinner/${this.props.match.params.eventid}/registration`, 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace(`/staff/galadinner/${this.props.match.params.eventid}/registration`);
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    eventIdChange(e) {
        var data = this.state.data;
        data.eventId = e.target.value;
        this.setState({ data });
    }

    companyAddressChange(e) {
        var data = this.state.data;
        data.companyAddress = e.target.value;
        this.setState({ data });
    }

    companyNameChange(e) {
        var data = this.state.data;
        data.companyName = e.target.value;
        this.setState({ data });
    }

    primaryContactDesignationChange(e) {
        var data = this.state.data;
        data.contactDesignation = e.target.value;
        this.setState({ data });
    }

    primaryContactEmailChange(e) {
        var data = this.state.data;
        data.contactEmail = e.target.value;
        this.setState({ data });
    }

    primaryContactPhoneChange(e) {
        var data = this.state.data;
        data.contactPhone = e.target.value;
        this.setState({ data });
    }

    primaryContactNameChange(e) {
        var data = this.state.data;
        data.contactName = e.target.value;
        this.setState({ data });
    }

    secondaryContactDesignationChange(e) {
        var data = this.state.data;
        data.secondaryContactDesignation = e.target.value;
        this.setState({ data });
    }

    secondaryContactEmailChange(e) {
        var data = this.state.data;
        data.secondaryContactEmail = e.target.value;
        this.setState({ data });
    }

    secondaryContactPhoneChange(e) {
        var data = this.state.data;
        data.secondaryContactPhone = e.target.value;
        this.setState({ data });
    }

    secondaryContactNameChange(e) {
        var data = this.state.data;
        data.secondaryContactName = e.target.value;
        this.setState({ data });
    }

    tableTypeChange(e) {
        var state = this.state;
        state.tableType = e;
        this.setState({ state });
    }

    mealTypeChange(event) {
        var state = this.state;
        if (state.registrationType.value === 'Individual') {
            state.mealType = [];
            state.mealType.push(event);
        } else {
            state.mealType = event;
        }
        this.setState({ state });
    }

    registrationTypeChange(event) {
        var state = this.state;
        var data = this.state.data;
        data.vegetarianMealAmount = 0;
        data.halalMealAmount = 0;
        data.standardMealAmount = 10;
        state.registrationType = event;
        state.mealType = [{ value: 'Standard', label: 'Standard' }];
        this.setState({ state, data });
    }

    mealTypeAmountChange(label, event) {
        var data = this.state.data;
        data.standardMealAmount = 10;
        if (label === 'Vegetarian') data.vegetarianMealAmount = event.target.value;
        if (label === 'Halal') data.halalMealAmount = event.target.value;
        if (label === 'Standard') data.standardMealAmount = event.target.value;
        this.setState({ data });
    }

    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col className="page-widget mb-4">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i> Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                {/* <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="id">ID</Label>
                                <Input type="text" name="id" id="id" style={{width: '100px'}} disabled value={this.state.data.id} placeholder="id" />
                            </FormGroup>
                        </Col>
                    </Row> */}
                <InputField label="Table Type">
                    <Select
                        autosize={false}
                        options={this.state.tableTypes}
                        value={this.state.tableType}
                        clearable={true}
                        onChange={this.tableTypeChange}
                        name="selected-state"
                        disabled={false}
                    />
                </InputField>
                <InputField label="Individual or Company Registration">
                    <Select
                        autosize={false}
                        options={this.state.registrationTypes}
                        value={this.state.registrationType}
                        clearable={true}
                        onChange={this.registrationTypeChange}
                        name="selected-state"
                        disabled={false}
                    />
                </InputField>
                {this.state.registrationType.value === 'Individual' ? (
                    <InputField label="Meal Type">
                        <Select
                            autosize={false}
                            options={this.state.mealTypes}
                            value={this.state.mealType}
                            clearable={true}
                            onChange={e => this.mealTypeChange(e)}
                            name="selected-state"
                            disabled={false}
                        />
                    </InputField>
                ) : (
                    <InputField label="Additional Meal Type">
                        <Select
                            autosize={false}
                            options={this.state.mealTypes}
                            value={this.state.mealType}
                            clearable={true}
                            isMulti={true}
                            onChange={e => this.mealTypeChange(e)}
                            name="selected-state"
                            disabled={false}
                        />
                    </InputField>
                )}
                {this.state.registrationType.value === 'Company' &&
                    this.state.mealType.length > 0 &&
                    this.state.mealType.map((meal, index) => {
                        var disabled = false;
                        if (meal.label === 'Vegetarian') {
                            var mealValue = this.state.data.vegetarianMealAmount;
                            disabled = false;
                        }
                        if (meal.label === 'Halal') {
                            var mealValue = this.state.data.halalMealAmount;
                            disabled = false;
                        }
                        if (meal.label === 'Standard') {
                            var mealValue = this.state.data.standardMealAmount;
                            disabled = true;
                        }
                        return (
                            <InputField
                                key={index}
                                label={`Number of ${meal.label} meal`}
                                inputProps={{
                                    disabled,
                                    type: 'number',
                                    value: mealValue,
                                    placeholder:
                                        meal.label === 'Standard' ? 'Company registration always includes Standard meal by default' : '0',
                                    onChange: e => this.mealTypeAmountChange(meal.label, e),
                                }}
                            />
                        );
                    })}
                <InputField
                    label="Primary Contact Name"
                    inputProps={{
                        type: 'text',
                        name: 'primaryContactName',
                        id: 'primaryContactName',
                        value: this.state.data.contactName,
                        onChange: this.primaryContactNameChange,
                    }}
                />
                <InputField
                    label="Primary Contact Phone"
                    inputProps={{
                        type: 'text',
                        name: 'primaryContactPhone',
                        id: 'primaryContactPhone',
                        value: this.state.data.contactPhone,
                        onChange: this.primaryContactPhoneChange,
                    }}
                />
                <InputField
                    label="Primary Contact Email"
                    inputProps={{
                        type: 'text',
                        name: 'primaryContactEmail',
                        id: 'primaryContactEmail',
                        value: this.state.data.contactEmail,
                        onChange: this.primaryContactEmailChange,
                    }}
                />
                <InputField
                    label="Primary Contact Designation"
                    inputProps={{
                        type: 'text',
                        name: 'primaryContactDesignation',
                        id: 'primaryContactDesignation',
                        value: this.state.data.contactDesignation,
                        onChange: this.primaryContactDesignationChange,
                    }}
                />
                <InputField
                    label="Secondary Contact Name"
                    inputProps={{
                        type: 'text',
                        name: 'secondaryContactName',
                        id: 'secondaryContactName',
                        value: this.state.data.secondaryContactName,
                        onChange: this.secondaryContactNameChange,
                    }}
                />
                <InputField
                    label="Secondary Contact Phone"
                    inputProps={{
                        type: 'text',
                        name: 'secondaryContactPhone',
                        id: 'secondaryContactPhone',
                        value: this.state.data.secondaryContactPhone,
                        onChange: this.secondaryContactPhoneChange,
                    }}
                />
                <InputField
                    label="Secondary Contact Email"
                    inputProps={{
                        type: 'text',
                        name: 'secondaryContactEmail',
                        id: 'secondaryContactEmail',
                        value: this.state.data.secondaryContactEmail,
                        onChange: this.secondaryContactEmailChange,
                    }}
                />
                <InputField
                    label="Secondary Contact Designation"
                    inputProps={{
                        type: 'text',
                        name: 'secondaryContactDesignation',
                        id: 'secondaryContactDesignation',
                        value: this.state.data.secondaryContactDesignation,
                        onChange: this.secondaryContactDesignationChange,
                    }}
                />
                <InputField
                    label="Company Name"
                    inputProps={{
                        type: 'text',
                        name: 'companyName',
                        id: 'companyName',
                        value: this.state.data.companyName,
                        onChange: this.companyNameChange,
                    }}
                />
                <InputField
                    label="Company Address"
                    inputProps={{
                        type: 'text',
                        name: 'companyAddress',
                        id: 'companyAddress',
                        value: this.state.data.companyAddress,
                        onChange: this.companyAddressChange,
                    }}
                />
                {/* <InputField
                    label="eventId"
                    inputProps={{
                        type: 'text',
                        name: 'eventId',
                        id: 'eventId',
                        value: this.state.data.eventId,
                        onChange: this.eventIdChange,
                    }}
                /> */}
                <hr />
                <FlexRow between="xs" className="pb-4">
                    <FlexCol xs={2}>
                        <Button className="primary-btn-style" onClick={this.save}>
                            Save
                        </Button>
                    </FlexCol>
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <Button className="text-bold primary-btn-style-outline" onClick={this.removeConfirm}>
                                Delete
                            </Button>
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
