import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Col, Form, Button } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { getTotal, getByEventId, generateRecords, eventSetModalData } from 'actions/staff/events/event-expenditure/action.js';
import { dialogModalToggle } from '../../../../actions/modal/dialogModalAction';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
        this.getRelevantStuff = this.getRelevantStuff.bind(this);
        this.generate = this.generate.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageCount: 0,
            total: 0,
            totalIncome: 0,
            totalExpenditure: 0,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.getRelevantStuff();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    generate() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.generateRecords(this.props.event.eventid),
            modalBody: 'Are you sure you want to generate records? Note: This will delete all existing records.',
        });
    }
    async getRelevantStuff(page, pageSize) {
        this.showLoading(true);
        let list = [];
        let total = 0;
        let totalIncome = 0;
        let totalExpenditure = 0;
        let pageCount = 0;
        let totalNum = 0;
        try {
            const res = await this.props.search(this.props.event.eventid, page, pageSize);
            list = res.data;
            const res1 = await this.props.getExpenditureTotal(this.props.event.eventid);
            total = res1.data[0].finalAmount;
            totalIncome = res1.data[0].totalIncome;
            totalExpenditure = res1.data[0].totalExpenditure;
            pageCount = res.totalNoOfPages;
            totalNum = res.totalNoOfItems;
        } catch (error) {
            throw error;
        } finally {
            if (this._isMounted) {
                // Set state
                this.setState({
                    list,
                    total,
                    totalIncome,
                    totalExpenditure,
                    pageCount,
                    totalNum,
                    loading: false,
                });
            }
        }
    }
    async download() {
        try {
            this.setState({
                exportLoading: true,
            });
            const { data } = await this.props.search(this.props.event.eventid, 0, 999);
            var convert = [];
            data.forEach(x => {
                var obj = {
                    transactionDate: x.transactionDate,
                    transactionType: x.transactionType,
                    amount: x.amount,
                    description: x.description,
                };
                convert.push(obj);
            });
            const papa = require('papaparse');
            const parseConfig = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };
            var csvPayload = papa.unparse(convert, parseConfig);
            var csv = 'data:text/csv;charset=utf-8,' + csvPayload;
            var res = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', res);
            link.setAttribute('download', 'income_expenditure.csv');
            link.click();
        } catch (error) {
            throw error;
        }
    }
    render() {
        const formatter = new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: 'SGD',
            minimumFractionDigits: 2,
        });
        const columns = [
            {
                Header: 'Trasaction Date',
                accessor: 'transactionDate',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Transaction Type',
                accessor: 'transactionType',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.original.transactionType === 'Expenditure') {
                        return <span style={{ color: 'red' }}>{formatter.format(props.value)}</span>;
                    } else {
                        return <span style={{ color: 'green' }}>{formatter.format(props.value)}</span>;
                    }
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Button
                            className="primary-btn-style"
                            onClick={e => {
                                this.props.setMode('add', props.value);
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col>
                        <Form inline>
                            <Row>
                                <InfoText semicolon label="Total Expenditure" value={formatter.format(this.state.totalExpenditure)} />
                                <InfoText semicolon label="Total Income" value={formatter.format(this.state.totalIncome)} />
                                <InfoText
                                    label={this.state.total < 0 ? 'Deficit' : 'Surplus'}
                                    color={this.state.total < 0 ? 'red' : 'green'}
                                    value={formatter.format(this.state.total)}
                                />
                            </Row>
                            <div className="ml-auto">
                                <Button
                                    className="ml-1 mr-1 align-middle primary-btn-style"
                                    onClick={e => {
                                        this.props.setMode('add', 0);
                                    }}
                                >
                                    <i className="material-icons align-text-bottom">add</i>
                                    &nbsp;Add
                                </Button>
                                <Button className="ml-1 mr-1 align-middle primary-btn-style" onClick={this.download}>
                                    <i className="material-icons align-text-bottom">file_download</i>
                                    &nbsp;Export
                                </Button>
                                {/* 18th Feb 2019 : Automatically generate during event creation, generate button hidden  */}
                                <Button
                                    hidden
                                    className="ml-1 mr-1 align-middle primary-btn-style"
                                    onClick={e => {
                                        this.generate();
                                    }}
                                >
                                    <i className="material-icons align-text-bottom">replay</i>
                                    &nbsp;Generate
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            sortable={false}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={10}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={state => {
                                this.getRelevantStuff(state.page, state.pageSize);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const InfoText = ({ label, value, color, semicolon }) => {
    return (
        <h5 className="mr-4 text-bold" style={color && { color }}>
            {label} {semicolon ? ':' : ' '} {value}
        </h5>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (eventId, page, pageSize) => {
            return dispatch(getByEventId(eventId, page, pageSize));
        },
        getExpenditureTotal: eventId => {
            return dispatch(getTotal(eventId));
        },
        generateRecords: eventId => {
            dispatch(generateRecords(eventId));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        setEventModalData: data => {
            dispatch(eventSetModalData(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPage);
