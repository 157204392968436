import {
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST,
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS,
    STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE,
} from '../../../actions/constants/actionTypesStaffMembership';

import util from 'js/util';

const defaultState = {
    loading: false,
    statistics: {
        activeMembersCount: 0,
        expiringIn6Months: 0,
        totalMembers: 0,
    },
};

export default function memberCertificationsOverviewReducer(
    state = defaultState,
    action,
) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS:
            newState.statistics = util.flattenArrayToKeys(action.response.data);
            newState.loading = false;
            return newState;
        case STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        default:
            return newState;
    }
}
