import { MODAL_DIALOG_TOGGLE, MODAL_DIALOG_SET_TOGGLE } from '../../actions/constants/actionTypesModal';

// defaults
const defaultState = {
    modalOpen: false,
};

export default (state = defaultState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MODAL_DIALOG_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            return newState;
        // BACKDOOR METHOD FOR QUICK FIX ONLY
        case MODAL_DIALOG_SET_TOGGLE:
            newState.modalOpen = action.show;
            return newState;
        case MODAL_DIALOG_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            return newState;
        default:
            return state;
    }
};
