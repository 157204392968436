/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import ScoresheetViewPanel from './components/ScoresheetViewPanel';

class ScoresheetViewPage extends React.PureComponent {
    render() {
        return (
            <div className="page-widget">
                <ScoresheetViewPanel
                    scoresheetId={this.props.match.params.scoresheetid}
                    type={this.props.type}
                    preview={this.props.preview}
                    showInstruction={true}
                    applicationId={this.props.match.params.originapplicationid}
                    templateId={this.props.match.params.templateid}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => ({});

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ScoresheetViewPage),
);
