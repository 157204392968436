/**
 * Staff Application List page
 *
 * Author: Fernando Karnagi
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Link } from 'react-router-dom';
import moment from 'moment';

import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import { FormGroup, Input, Row, Col, Form, Label, Button } from 'reactstrap';
import Select from 'react-select';
import { SGBC_DATA_CUTOVER_DATE } from 'config';
import CyderReactTable from 'cyder/table/CyderReactTable';
import IconWidget6 from 'icon-widgets/IconWidget6';

import {
    getApplicationOverviewStats,
    staffCertListGetStatus,
    fetchApplicationList,
    fetchReviewIdByStaff,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { actionExecutor } from 'js/util';

import 'css/forms/default-forms.css';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

const typeOptions = [
    { key: 'product', label: 'Product', value: 'product', selected: true },
    { key: 'service', label: 'Service', value: 'service' },
    { key: 'all', label: 'All', value: 'all' },
];

export const BrandDetailComponent = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((brand, index) => {
                return (
                    <div key={index} style={{ fontWeight: 'normal' }}>
                        <div>{brand.name != null ? brand.name.toUpperCase() : ''}</div>
                        {brand.models != null &&
                            brand.models.map((model, index) => {
                                return <div key={index}>{model.name}</div>; //no description now
                            })}
                        <br />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

class ApplicationList extends Component {
    constructor(props) {
        super(props);
        this.getApplicationStat = this.getApplicationStat.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.handleChangeType = this.handleChangeType.bind(this);
        this.toggleMyCase = this.toggleMyCase.bind(this);
        this.getReviewList = this.getReviewList.bind(this);
        this.search = this.search.bind(this);

        this.state = {
            // Table and Pagination
            pageSize: 10,
            pageCount: 0,
            pagination: true,
            tableList: [],
            // Controls
            applicationList: {},
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            applicationStatusOption: [],
            applicationStatus: {
                label: 'All',
                value: 0,
            },
            // Filter Controls
            keyword: '',
            loading: false,
            screenLoading: true,
            type: 'all',
            myCaseFlag: false,
            checkedtype: 'all',
            reviewList: [],
        };
    }
    componentWillMount() {
        const { state } = this.props.location;
        if (state && state.certificateStatusSubmitted == 2) {
            this.setState({
                applicationStatus: {
                    label: 'Submitted',
                    value: 2,
                },
            });
            return;
        }
    }
    async componentDidMount() {
        this.setState({
            screenLoading: true,
        });
        await this.determineMode();
        await this.getStatus();
        await this.getApplicationStat();
        await this.props.retrieveApplicationOverviewStats();
        await this.getReviewList();
        await this.props.resetSelectedTemplate();
        this.setState({
            screenLoading: false,
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.url != this.props.match.url) {
            this.componentDidMount();
            this.setState({
                loading: true,
                reviewList: [],
            });
            this.props.fetchApplicationList({
                itemsPerPage: 20,
                page: 1,
                sorted: null,
                status: this.state.applicationStatus.label !== 'All' ? this.state.applicationStatus.label : '',
                type: this.state.type,
                keyword: this.state.keyword,
                mycaseonly: this.props.mode === 'mycase',
            });
            this.setState({
                loading: false,
            });
        }
    }
    async getReviewList() {
        const callback = async () => {
            const { data } = await this.props.fetchMyReviewList();
            try {
                var splitData = data.certificateId.split(',');
                var reviewList = splitData.map(function(x) {
                    return parseInt(x);
                });
                this.setState({ reviewList });
            } catch (e) {
                this.setState({ reviewList: [] });
            }
        };
        actionExecutor(this.showLoading, callback);
    }
    showLoading = loading => {
        this.setState({
            loading,
        });
    };
    determineMode = () => {
        const myCaseFlag = this.props.mode === 'mycase';
        this.setState({
            myCaseFlag,
        });
    };
    async getApplicationStat() {
        const callback = async () => {
            const { data } = await this.props.retrieveApplicationOverviewStats();
            this.setState({
                applicationList: data,
            });
        };
        actionExecutor(this.showLoading, callback);
    }
    getStatus = () => {
        let { applicationStatusOption } = this.state;
        const callback = async () => {
            const { data } = await this.props.getStatus();
            if (!data) return;
            const newData = data.map(x => ({
                label: x.status,
                value: x.id,
            }));
            this.setState({
                applicationStatusOption: [
                    {
                        label: 'All',
                        value: 0,
                    },
                    ...newData,
                ],
            });
        };
        actionExecutor(() => {}, callback);
    };
    // KEYWORD AND FILTERS
    onKeywordChange(e) {
        var state = this.state;
        state.keyword = e.target.value;
        this.setState({ state });
    }
    onSelectChange(status) {
        var state = this.state;
        state.applicationStatus = status;
        this.setState({ state });
    }
    handleChangeType(type) {
        var state = this.state;
        state.type = type;
        state.checkedtype = type;
        this.setState({ state });
    }
    async search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });

        let res = await this.props.fetchApplicationList({
            itemsPerPage: pageSize,
            page: page + 1 || 1,
            sorted: sorted,
            status: this.state.applicationStatus.label !== 'All' ? this.state.applicationStatus.label : '',
            type: this.state.type,
            keyword: this.state.keyword,
            mycaseonly: this.state.myCaseFlag,
            filtered: filtered,
        });
        this.setState({
            loading: false,
        });
    }
    toggleMyCase(e) {
        var state = this.state;
        state.myCaseFlag = e.target.checked;
        this.setState({ state });
    }
    render() {
        const height = 70;
        const columns = [
            {
                Header: 'Type',
                accessor: 'type',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 70,
                Cell: props =>
                    props.original.assessmentNeedVerification ? (
                        <div style={{ color: '#8c0000', fontWeight: 'bold' }}>{props.value}</div>
                    ) : (
                        props.value
                    ),
            },
            {
                Header: 'Company',
                accessor: 'company',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 70,
                Cell: props =>
                    props.original.assessmentNeedVerification ? (
                        <div style={{ color: '#8c0000', fontWeight: 'bold' }}>{props.value}</div>
                    ) : (
                        props.value
                    ),
            },
            {
                Header: 'Description',
                accessor: 'category',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 200,
                Cell: props => {
                    const products = props.original.brands || [];
                    if (props.original.type == 'service') {
                        return props.original.assessmentNeedVerification ? (
                            <span style={{ color: '#8c0000' }}>
                                <b>{props.value}</b>
                            </span>
                        ) : (
                            <b>{props.value}</b>
                        );
                    }
                    var styles = props.original.assessmentNeedVerification ? { color: '#8c0000', fontWeight: 'bold' } : {};
                    try {
                        return (
                            <React.Fragment>
                                <div style={styles}>
                                    <b>{props.value}</b>
                                    {products.constructor === Array && products.length > 0 ? (
                                        <React.Fragment>
                                            <BrandDetailComponent items={products} />
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </React.Fragment>
                        );
                    } catch (e) {
                        return props.value;
                    }
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 80,
                Cell: props =>
                    props.original.assessmentNeedVerification ? (
                        <div style={{ color: '#8c0000', fontWeight: 'bold' }}>
                            {props.original.status == 'Accepted' ? 'Application Accepted' : props.original.status}
                        </div>
                    ) : props.original.status == 'Accepted' ? (
                        'Application Accepted'
                    ) : (
                        props.original.status
                    ),
            },
            {
                Header: 'Submitted On',
                accessor: 'submittedOn',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 80,
                Cell: props =>
                    props.original.assessmentNeedVerification ? (
                        <div style={{ color: '#8c0000', fontWeight: 'bold' }}>
                            {moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
                        </div>
                    ) : (
                        moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
                    ),
            },
            {
                Header: 'Case Officer',
                accessor: 'officerInCharge',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 60,
                Cell: props =>
                    props.original.assessmentNeedVerification ? (
                        <div style={{ color: '#8c0000', fontWeight: 'bold' }}>{props.value}</div>
                    ) : (
                        props.value
                    ),
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                width: 100,
                Cell: props => {
                    const type = props.original.clazz === 'Service' ? 'viewservice' : 'viewproduct';
                    const url = `./applications/${type}/${props.original.id}`;
                    return (
                        <Link to={url}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="fas fa-folder-open mr-1" />
                                Open
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        const applicationStatuses = [
            {
                color: 'white',
                text: 'Submitted',
                number: this.state.applicationList && this.state.applicationList.total_new,
                remarks: '',
                bgStyle: {
                    backgroundColor: '#428156',
                },
                height,
            },
            {
                color: 'white',
                text: 'In-progress',
                number: this.state.applicationList && this.state.applicationList.total_assessment,
                remarks: '',
                bgStyle: {
                    backgroundColor: '#89aa49',
                },
                height,
            },
            {
                color: 'white',
                text: 'Invoice',
                number: this.state.applicationList && this.state.applicationList.total_invoice,
                remarks: '',
                bgStyle: {
                    backgroundColor: '#858687',
                },
                height,
            },
            {
                color: 'white',
                text: 'Paid & Finalisation',
                number: this.state.applicationList && this.state.applicationList.total_paid_and_finalisation,
                remarks: '',
                bgStyle: {
                    backgroundColor: '#646565',
                },
                height,
            },
        ];

        // console.log("this.props.applications.totalNoOfItems: ", this.props.applications.totalNoOfItems, " -> ", this.state.screenLoading)
        return (
            <div className="page-widget">
                <NoShadowPanel title={this.props.mode !== 'mycase' ? 'All Applications' : 'My Cases'} borderSize={2} flat />
                <Row>
                    {applicationStatuses.map((widget, i) => (
                        <Col key={i}>
                            <IconWidget6 {...widget} />
                        </Col>
                    ))}
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Col xs={12}>
                        <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.table.fireFetchData();
                                    }}
                                    onChange={this.onKeywordChange}
                                />
                                <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                    <i className="material-icons align-text-bottom">search</i>
                                    &nbsp;Search
                                </Button>
                                &nbsp;
                                <Select
                                    clearable={true}
                                    autosize={false}
                                    searchable={true}
                                    className="w-200 ml-2"
                                    name="selected-state"
                                    value={this.state.applicationStatus}
                                    options={this.state.applicationStatusOption}
                                    onChange={e => {
                                        this.onSelectChange(e);
                                        this.table.fireFetchData();
                                    }}
                                />
                            </FormGroup>
                            {typeOptions.map((item, index) => (
                                <Label check key={index}>
                                    <Input
                                        key={item.key}
                                        name="type"
                                        type="radio"
                                        value={item.value}
                                        checked={item.value === this.state.type}
                                        onChange={() => {
                                            this.handleChangeType(item.value);
                                            this.table.fireFetchData();
                                        }}
                                    />
                                    {item.label}
                                </Label>
                            ))}
                            &nbsp;
                            <label>
                                <Input
                                    type="checkbox"
                                    checked={this.state.myCaseFlag}
                                    onChange={e => {
                                        this.toggleMyCase(e);
                                        this.table.fireFetchData();
                                    }}
                                />
                                My Case Only
                            </label>
                        </Form>
                    </Col>
                </Row>
                {this.state.screenLoading ? (
                    <LoadingSpinner />
                ) : (
                    <Row>
                        <Col xs={12}>
                            <CyderReactTable
                                minRows={0}
                                columns={columns}
                                reviewList={this.state.reviewList}
                                filterable={false}
                                className="-highlight mb-2"
                                showPagination={this.state.pagination}
                                defaultPageSize={this.state.pageSize}
                                manual
                                data={this.props.applicationList}
                                pages={this.props.applications.totalNoOfPages}
                                totalNum={this.props.applications.totalNoOfItems}
                                loading={this.state.loading}
                                ref={instance => {
                                    if (instance != null) this.table = instance.table;
                                }}
                                onFetchData={(state, instance) => {
                                    this.search(state.page, state.pageSize, state.sorted, state.filtered);
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        login: state.profile,
        applications: state.staffCertificationApplicationReducer,
        applicationList: state.staffCertificationApplicationReducer.applications,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getStatus: () => {
            return dispatch(staffCertListGetStatus());
        },
        retrieveApplicationOverviewStats: () => dispatch(getApplicationOverviewStats()),
        fetchApplicationList: data => {
            return dispatch(fetchApplicationList(data));
        },
        resetSelectedTemplate: () => {
            return dispatch({
                type: 'STAFF_CERT_APPLICATION_STORE_SELECTED_TEMPLATE',
                template: {},
            });
        },
        fetchMyReviewList: () => {
            return dispatch(fetchReviewIdByStaff());
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationList),
);
