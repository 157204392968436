import React from 'react';
import { PreviewPanelWrapper } from './PreviewPanel1';
import { Col } from 'reactstrap';

const PreviewPanel1 = ({ textColor, onGoingEvents }) => (
    <PreviewPanelWrapper textColor={textColor} icon="fa-calendar-alt">
        <br />
        <Col>
            <h4 className="pt-3">
                On-going <br />
                Events
            </h4>
        </Col>
        <Col className="text-right">
            <h1 className="display-4 text-bold">{onGoingEvents}</h1>
        </Col>
    </PreviewPanelWrapper>
);
export default PreviewPanel1;
