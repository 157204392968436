import React from 'react';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';

import '../../css/elements/jumbotron-1.css';

class Jumbotron1 extends React.Component {
    generateLink = linkIndex => {
        const linkBlackList = ['/admin', '/admin/misc'];
        const thridLevelMenuList = ['product', 'service', 'requirement', 'criteria'];
        const linkString = '/' + this.props.parts.slice(0, linkIndex + 1).join('/');
        // For breadcrumb index 0 to 2
        switch (linkIndex) {
            case 0:
                return '/staff/home';
            case 1:
                return '#';
            case 2:
                const part = this.props.parts[linkIndex];
                if (thridLevelMenuList.indexOf(part) === -1) return '#';
                break;
        }
        // For breadcrumb not in blacklist
        if (linkBlackList.indexOf(linkString) === -1) return linkString;
        return '#';
    };
    render() {
        const dict = {
            sgbc_settings: 'SGBC Settings',
            'member-directory': 'Member Directory',
            attendance: 'Attendance',
            allocate: 'Allocate',
            all: 'All',
            create: 'Create',
            founding: 'Founding',
            dashboard: 'Dashboard',
            corporate: 'Corporate',
            myaccount: 'My Account',
            questionnaires: 'Questionnaires',
            issuedanalysisbymonth: 'Issued Analysis',
            issuedanalysisbyyear: 'Issued Analysis',
            previewapplication: 'Preview',
            payment: 'Payment',
            expirymanagementforecasting: 'Expiry Management Forecasting',
            newdashboard: 'Dashboard',
            overview: 'Overview',
            newapplication: 'New Application',
            newassessment: 'New Assessment',
            viewapplication: 'View Application',
            newreview: 'Review',
            viewassessment: 'View Assessment',
            reviewassessment: 'Documents Update',
            renew: 'Renew',
            ongoing: 'On-going',
            caseclosed: 'Case closed',
            criteria: 'Criteria',
            scoresheettemplate: 'Criteria',
            viewproduct: 'View Product',
            editproductscoresheet: 'Update',
            scoresheet: 'Score Sheet',
            tix: 'Tickets',
            viewproductscoresheet: 'View',
            editservicescoresheet: 'View',
            certificates: 'Certificates',
            product: 'Product',
            service: 'Services',
            applications: 'Applications',
            viewservice: 'View Services',
            myreview: 'Review',
            PeerApproval: 'Approval',
            surveys: 'Survey',
            ManagerApproval: 'Approval',
            result: 'Result',
            promocodes: 'Promo Codes',
            dinnerevents: 'Dinner Events',
            edit: 'Edit',
            home: 'Home',
            companyprofile: 'Company Profile',
            confirmation: 'Confirmation',
            rsvp: 'RSVP',
            profile: 'Profile',
            regpayment: 'Payment',
            attendees: 'Attendees',
            members: 'Members',
            accounts: 'Accounts',
            memberdetail: 'Member Details',
            resources: 'Resouces',
            requirement: 'Requirement',
            renewal: 'Renewals',
            scrutineers: 'Scrutineers',
            nominee: 'Nominees',
            seating: 'Seating Plan',
            registration: 'Registration',
            galadinner: 'Gala Dinner',
            voters: 'Voters',
            viewproducttemplate: 'Assessment',
            viewservicetemplate: 'Assessment',
            'membership-renewal': 'Renewals',
            'organisation-main-category': 'Main Category',
            certdetails: 'Details',
            'scoresheet-criteria': 'Criteria',
            'product-sub-category': 'Product Subcategory',
            'product-type': 'Product Type',
            users: 'Users',
            'organisation-sub-category': 'Sub Category',
            'organisational-category-group': 'Category Group',
            'organisational-category': 'Category',
            salutations: 'Salutations',
            profession: 'Professions',
            'membership-application-status': 'Application Sltatus',
            'membership-renewal-status': 'Renewal Status',
            'product-category': 'Category',
            'product-sub-category': 'Sub Category',
            'product-type': 'Type',
            'product-listing-renewal-status': 'Renewal Status',
            'service-category': 'Category',
            'bca-data-option': 'BCA Categories',
            'other-data-option': 'Green Attributes',
            'building-type-option': 'Green Credentials',
            'event-type': 'Event Type',
            'food-preference': 'Food Preferences',
            'survey-category': 'Category',
            'survey-question-type': 'Question Type',
            'payment-mode': 'Payment Mode',
            country: 'Country',
        };
        const viewDict = {
            Directory: 'Members',
            Renewals: 'Membership Renewals',
            Applications: 'Membership Applications',
        };
        // PRIORITY FOR LINK TEXT:
        // viewDict[this.props.view] -> this.props.view -> jumbotronText -> item (url segment)
        const view = viewDict[this.props.view] || this.props.view;
        return (
            <div className="jumbotron jumbotron-1">
                <div className="container-fluid jumbotron-container-fluid">
                    <div className="row justify-content-between">
                        <div className="col jumbotron-container-col">
                            {/* <ol className="breadcrumb icon-home icon-angle-right">
                                {this.props.parts &&
                                    this.props.parts.map((item, i) => {
                                        return (
                                            <Breadcrumb
                                                key={item.toString() + i}
                                                index={i}
                                                view={view}
                                                dict={dict}
                                                item={item}
                                                parts={this.props.parts}
                                                jumbotronText={this.props.jumbotronText}
                                                generateLinkAction={this.generateLink}
                                            />
                                        );
                                    })}
                            </ol> */}
                            {/* <h4 className="title">{this.props.view ? this.props.view : this.props.jumbotronText}</h4> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const Breadcrumb = props => {
    let { item } = props;
    const { parts, jumbotronText, dict, view, index, generateLinkAction } = props;
    const evaluation = parts.length !== 1 && index === parts.length - 1;
    if (evaluation) item = view || (jumbotronText || item);
    return (
        <li>
            <Link to={generateLinkAction(index)}>{dict[item] ? dict[item] : evaluation ? item : startCase(item)}</Link>
        </li>
    );
};

export default Jumbotron1;
