import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Button,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';

import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class CompanyPage extends React.Component {
    componentDidMount() {
        this.props.setJumbotronTitle('Add Company');
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={10}>
                        <h2>Migration</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Form>
                                    <FormGroup row>
                                        <Label xs={2}>Organisation Primary Business Activity:</Label>
                                        <Col xs={10}>
                                            <FormGroup className="mb-0" tag="fieldset">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> None
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Others
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Testing Laborataries
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Energy Services Specialists
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Government Agencies
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Product Companies
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Research and Tertiary Institutions
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Professional Bodies, Trade Associations and
                                                        Chambers
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Property/Facility Management and Real Estate
                                                        Services
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Real Estate Owners and Developers
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Builders and Contractors
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" /> Professional Building and Environmental
                                                        Consultancies
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                            <Button className="pr-1 mr-0" color="link">
                                                <i className="material-icons align-text-bottom">edit</i>
                                            </Button>
                                            <Button className="pl-1 ml-0" color="link">
                                                <i className="material-icons align-text-bottom">add</i>
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label xs={2}>Services offered by organisation:</Label>
                                        <Col xs={5}>
                                            <h6>Available Services offered by organisation</h6>
                                            <Input
                                                type="select"
                                                multiple
                                                multiple="multiple"
                                                className="selectfilter"
                                                id="id_organisation_sub_category"
                                                name="organisation_sub_category"
                                            >
                                                <option value="3">Architecture</option>
                                                <option value="4">Environmental Sustainability Design</option>
                                                <option value="5">Façade Design</option>
                                                <option value="6">Interior Design</option>
                                                <option value="7">Landscape Design</option>
                                                <option value="8">Lighting Design</option>
                                                <option value="9">M&amp;E Engineering</option>
                                                <option value="10">C&amp;S Engineering</option>
                                                <option value="11">Project Management</option>
                                                <option value="12">Quantity Surveying</option>
                                                <option value="14">Building / Civil Works</option>
                                                <option value="15">M&amp;E Works</option>
                                                <option value="16">Interior Fit-out</option>
                                                <option value="18">Developer</option>
                                                <option value="19">Property Owner</option>
                                                <option value="20">REIT/ Investment Company</option>
                                                <option value="21">Facility Management</option>
                                                <option value="22">Real Estate Services</option>
                                                <option value="23">Trade Associations &amp; Chambers</option>
                                                <option value="24">Professional Bodies</option>
                                                <option value="27">Government Agency</option>
                                                <option value="33">Testing Laboratary</option>
                                                <option value="34">Others</option>
                                                <option value="1">Manufacturer / Distributor / Reseller</option>
                                                <option value="13">Other Consultancies</option>
                                                <option value="17">Other Builders or Contractors</option>
                                                <option value="25">Research &amp; Tertiary Institution</option>
                                                <option value="29">SGBC Certified Energy Performance Contracting (EPC)</option>
                                                <option value="30">NEA Accredited Energy Service Company (ESCO)</option>
                                                <option value="31">Energy Efficiency Solution Provider</option>
                                                <option value="32">Renewable Energy Integrator</option>
                                                <option value="35">Energy Retailer</option>
                                                <option value="36">IT infrastructure network</option>
                                                <option value="37">Energy Modelling</option>
                                            </Input>
                                        </Col>
                                        <Col xs={5}>
                                            <h6>Chosen Services offered by organisation</h6>
                                            <Input
                                                type="select"
                                                multiple
                                                multiple="multiple"
                                                className="selectfilter"
                                                id="id_organisation_sub_category"
                                                name="organisation_sub_category"
                                            >
                                                <option value="3">Architecture</option>
                                                <option value="4">Environmental Sustainability Design</option>
                                                <option value="5">Façade Design</option>
                                                <option value="6">Interior Design</option>
                                                <option value="7">Landscape Design</option>
                                                <option value="8">Lighting Design</option>
                                                <option value="9">M&amp;E Engineering</option>
                                                <option value="10">C&amp;S Engineering</option>
                                                <option value="11">Project Management</option>
                                                <option value="12">Quantity Surveying</option>
                                                <option value="14">Building / Civil Works</option>
                                                <option value="15">M&amp;E Works</option>
                                                <option value="16">Interior Fit-out</option>
                                                <option value="18">Developer</option>
                                                <option value="19">Property Owner</option>
                                                <option value="20">REIT/ Investment Company</option>
                                                <option value="21">Facility Management</option>
                                                <option value="22">Real Estate Services</option>
                                                <option value="23">Trade Associations &amp; Chambers</option>
                                                <option value="24">Professional Bodies</option>
                                                <option value="27">Government Agency</option>
                                                <option value="33">Testing Laboratary</option>
                                                <option value="34">Others</option>
                                                <option value="1">Manufacturer / Distributor / Reseller</option>
                                                <option value="13">Other Consultancies</option>
                                                <option value="17">Other Builders or Contractors</option>
                                                <option value="25">Research &amp; Tertiary Institution</option>
                                                <option value="29">SGBC Certified Energy Performance Contracting (EPC)</option>
                                                <option value="30">NEA Accredited Energy Service Company (ESCO)</option>
                                                <option value="31">Energy Efficiency Solution Provider</option>
                                                <option value="32">Renewable Energy Integrator</option>
                                                <option value="35">Energy Retailer</option>
                                                <option value="36">IT infrastructure network</option>
                                                <option value="37">Energy Modelling</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                        <h2>Key Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label xs={4}>ID:</Label>
                                                <Col xs={8}>
                                                    <Input />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>User:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Name of Organization:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Country:</Label>
                                                <Col xs={8}>
                                                    <Input type="select">
                                                        <option>---------</option>
                                                        <option>Singapore</option>
                                                        <option>Malaysia</option>
                                                        <option>United States</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>UEN / Company Registration Number: *</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Key membership account</Label>
                                                <Col xs={8}>
                                                    <Label className="align-middle" check>
                                                        <Input type="checkbox" />
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Organisational category:</Label>
                                                <Col xs={8}>
                                                    <Input type="select">
                                                        <option>---------</option>
                                                        <option>Others</option>
                                                        <option>Developer</option>
                                                        <option>Architects</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Other organisational category:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Product Services Description</Label>
                                                <Col xs={8}>
                                                    <Input type="textarea" rows={5} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Green building movement involvement:</Label>
                                                <Col xs={8}>
                                                    <Input type="textarea" rows={5} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Annual turnover:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Number of employees:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Have minimum 30% local shareholding</Label>
                                                <Col xs={8}>
                                                    <Label className="align-middle" check>
                                                        <Input type="checkbox" />
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Is SME:</Label>
                                                <Col xs={8}>
                                                    <Input type="select">
                                                        <option>Unknown</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Legacy username:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col sm={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                    xs={4}
                                                >
                                                    ID:
                                                </Label>
                                                <Col xs={8}>
                                                    <Input
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        type="text"
                                                        placeholder="hi"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                    xs={4}
                                                >
                                                    User:
                                                </Label>
                                                <Col xs={8}>
                                                    <Input
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        type="text"
                                                        placeholder="hi"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                    xs={4}
                                                >
                                                    Name of Organization:
                                                </Label>
                                                <Col xs={8}>
                                                    <Input
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        type="text"
                                                        placeholder="hi"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Button className="pr-1 mr-0" color="link">
                                                    <i className="material-icons align-text-bottom">edit</i>
                                                </Button>
                                                <Button className="pl-1 ml-0" color="link">
                                                    <i className="material-icons align-text-bottom">add</i>
                                                </Button>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                    style={{
                                                        visibility: 'hidden',
                                                    }}
                                                    xs={4}
                                                >
                                                    UEN / Company Registration Number:
                                                </Label>
                                                <Col xs={8}>
                                                    <Input
                                                        style={{
                                                            visibility: 'hidden',
                                                        }}
                                                        type="text"
                                                        placeholder="hi"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup style={{ visibility: 'hidden' }} row>
                                                <Label xs={4}>Key membership account</Label>
                                                <Col xs={8}>
                                                    <Label className="align-middle" check>
                                                        <Input type="checkbox" />
                                                    </Label>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Button className="pr-1 mr-0" color="link">
                                                    <i className="material-icons align-text-bottom">edit</i>
                                                </Button>
                                                <Button className="pl-1 ml-0" color="link">
                                                    <i className="material-icons align-text-bottom">add</i>
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Categorisation Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label xs={4}>Other Professional Building & Environmental Consultancies:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Other Builders & Contractors:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Other Others:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Contact Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label xs={4}>Main phone number:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            {/* <FormGroup row>
                                                <Label xs={4}>Facsimile:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup> */}
                                            <FormGroup row>
                                                <Label xs={4}>Website:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>General email:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Contact Persons Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label xs={4}>Official management representative:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Contact person from finance:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Additional representative 1:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Additional representative 2:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Additional representative 3:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Hr admin training rep:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Certification rep:</Label>
                                                <Col xs={6}>
                                                    <InputGroup>
                                                        <Input type="text" />
                                                        <InputGroupAddon addonType="append" className="align-middle">
                                                            <Button color="link">
                                                                <i className="material-icons align-bottom">search</i>
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Certification contact person:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Certification contact person email:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Address Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label xs={4}>Business address line 1:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Business address line 2:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Business address line 3:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Business postal code:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Mailing address line 1:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Mailing address line 2:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Mailing address line 3:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label xs={4}>Mailing postal code:</Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Misc. Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Row>
                                            <Col xs={4}>Logo thumb:</Col>
                                            <Col xs={8}>
                                                <div />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Logo width:</Col>
                                            <Col xs={8}>
                                                <div>(None)</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Logo height:</Col>
                                            <Col xs={8}>
                                                <div>(None)</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Company Logo:</Col>
                                            <Col xs={8}>
                                                <div>
                                                    <Input type="file" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Created on:</Col>
                                            <Col xs={8}>
                                                <div>{moment().format('MMMM D YYYY, HH:mm a')}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Updated on:</Col>
                                            <Col xs={8}>
                                                <div>{moment().format('MMMM D YYYY, HH:mm a')}</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>Updated by:</Col>
                                            <Col xs={8}>
                                                <div>fkarnagi</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={2}>
                        <h2 style={{ visibility: 'hidden' }}>placeholder</h2>
                        <div className="sticky-top">
                            <Card>
                                <CardBody>
                                    <Button color="info" block>
                                        Save
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and continue editing</span>
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and add another</span>
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderExpenseMyReceiptsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CompanyPage));
