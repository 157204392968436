import React from 'react';
import ReactTable from 'react-table';
import { Row, Col, Form, Button, Input } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

class CyderReactTable extends React.Component {
    render() {
        let totalNum = this.props.totalNum != null ? this.props.totalNum : 0;
        let tableProps = Object.assign({}, this.props);
        let reviewList = tableProps.reviewList;
        if (totalNum <= 0) {
            tableProps.showPagination = false;
            tableProps.columns = [];
        }

        return (
            <div>
                {!this.props.hideTotalNum && totalNum > 0 ? (
                    <Row>
                        <Col xs="12">
                            <b>
                                {totalNum} record{totalNum > 1 ? 's' : ''} found
                            </b>
                            <hr />
                        </Col>
                    </Row>
                ) : (
                    ``
                )}
                <Row>
                    <Col xs="12">
                        {!this.props.loading && totalNum <= 0 && (
                            <div>
                                No record found
                                <br />
                                <br />
                            </div>
                        )}
                        <ReactTable
                            style={this.props.loading || totalNum <= 0 ? { display: 'none' } : null}
                            {...tableProps}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance;
                                }
                            }}
                            getTrProps={(state, rowInfo, column) => {
                                return {
                                    style: {
                                        color: '#63666A',
                                    },
                                };
                            }}
                            LoadingComponent={() => <div>{this.props.loading && <LoadingSpinner />}</div>}
                            NoDataComponent={() => ``}
                        />
                        {this.props.loading && <LoadingSpinner />}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CyderReactTable;
