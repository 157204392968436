import React from 'react';
import Select from 'react-select';

const YEAR = [
    {
        value: '2018',
        label: '2018',
    },
    {
        value: '2017',
        label: '2017',
    },
    {
        value: '2016',
        label: '2016',
    },
    {
        value: '2015',
        label: '2015',
    },
];

const YearSelector = ({ onChangeFrom, onChangeTo, year }) => (
    <form>
        <div className="form-row">
            <div className="col">
                <div className="form-group">
                    <label className="field-header">From:</label>
                    <Select
                        autosize={false}
                        placeholder=""
                        autofocus
                        options={YEAR}
                        simpleValue
                        name="selected-state"
                    />
                    <small className="form-text text-muted">
                        Please pick a start year
                    </small>
                </div>
            </div>

            <div className="col">
                <div className="form-group">
                    <label className="field-header">To:</label>
                    <Select
                        autosize={false}
                        placeholder=""
                        autofocus
                        options={YEAR}
                        simpleValue
                        name="selected-state"
                    />
                    <small className="form-text text-muted">
                        Please pick an end year
                    </small>
                </div>
            </div>
        </div>
    </form>
);

export default YearSelector;
