/**
 * Service certificate renew page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

// COMPONENTS
import { Container } from 'reactstrap';
import BigForm from '../../../../../cyder/forms/BigForm';
import DialogModal from 'cyder/modals/DialogModal';
import TopNavigationLayout from '../../../../../layouts/TopNavigationLayout';
import { ServiceCertificationRenewPanel } from './components/ServiceCertificationRenewPanel';

// ACTIONS
import { saveRenewCertApplication, getCertificateInfoById } from 'actions/member/certification/renew/memberProductRenewAction';
import { setUpdateAssessment } from '../../../../../actions/member/certification/renew/memberServiceRenewAction';
import { getRenewCertApplFromCertID } from 'actions/member/certification/applications/memberCertNewApplAction';

import '../../../../../css/ui-elements/buttons.css';
import '../../../../../css/icons/material-design-icons.css';
import '../../../../../css/cyder/form/cyder-icons.css';
import '../../../../../css/cyder/form/common-form.css';
import '../../../../../css/cyder/tooltip/menucool.css';
import '../../../../../css/ui-elements/buttons.css';
import '../../../../../css/cyder/table/dashboard-table.css';

class ServiceRenewPage extends React.Component {
    constructor(props) {
        super(props);
        this.showLoading = this.showLoading.bind(this);
        this.submitCertRenewAppl = this.submitCertRenewAppl.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.getStuffs = this.getStuffs.bind(this);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            activeTab: 0,
            selectValue: '',
            clearable: true,
            certificateInfo: {},
            loading: true,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getStuffs();
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    viewAssessment() {
        const { templateId, assessmentId, type } = this.props.renew;
        const { certId } = this.props.match.params;
        history.push(`/member/certification/${type}/viewassessment/renew/${certId}/${templateId}/${assessmentId}`);
    }
    async getStuffs() {
        try {
            this.showLoading(true);
            const certId = this.props.match.params.certId;
            const certificateInfo = await this.props.getCertificateInfoById(certId);
            this.props.getRenewData(certId);
            if (this._isMounted) {
                this.setState({
                    certificateInfo: certificateInfo.data,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    constructBody() {
        return {
            isSelfFinancingCapable: this.props.renew.isSelfFinancingCapable,
            productCatalogueFilename: this.props.renew.productCatalogueFilename,
            service: this.props.renew.service,
            manufacturerContactPersonName: this.props.renew.manufacturerContactPersonName,
            manufacturerTelephone: this.props.renew.manufacturerTelephone,
            serviceCategoryId: this.props.renew.serviceCategoryId,
            productCatalogue: this.props.renew.productCatalogue,
            certificateNumberToRenewOrAmend: this.props.renew.certificateNumber,
            productPicture: this.props.renew.productPicture,
            productPictureFilename: this.props.renew.productPictureFilename,
            certId: this.props.match.params.certId,
            templateId: this.props.renew.templateId,
            assessmentId: this.props.renew.assessmentId,
            manufacturerCountryId: '',
            manufacturerCountryName: '',
            manufacturerFactoryAddress: '',
            manufacturerNameOfCompany: '',
            manufacturerPostalCode: '',
            remarks: '',
            greenRemarks: this.props.renew.greenRemarks,
        };
    }
    async submitCertRenewAppl() {
        const type = this.props.renew.type; //'product  or service'
        const idObj = {};
        const body = {
            ...idObj,
            ...this.constructBody(),
        };
        try {
            const res = await this.props.saveRenewCertApplication(type, body);
            if (res.data.id) {
                history.push(`/member/certification/${type}/renew/application/` + res.data.id);
                return;
            }
            this.showModal('error', 'Failed to start certification renewal', '', false, true);
        } catch (error) {
            throw error;
        }
    }
    // MODAL
    modalAction = () => {
        this.modalToggle();
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    render() {
        const { modal } = this.state;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <Container>
                    <br />
                    <BigForm title="Services Certificate Renewal">
                        <ServiceCertificationRenewPanel
                            certificate={this.state.certificateInfo}
                            downloadShow={false}
                            submitRenewal={this.submitCertRenewAppl}
                            updateAssessment={this.props.updateAssessment}
                            assessmentBeingUpdated={this.props.renew.updateAssessment}
                            submitAssessment={this.props.submitAssessment}
                            viewAssessment={this.viewAssessment}
                            shouldSubmitAssessmentDisabled={!this.props.renew.templateId && !this.props.renew.assessmentId}
                            toggle={this.toggle}
                            tabState={this.state}
                            assessments={this.props.renew.assessments}
                        />
                    </BigForm>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        assessment: state.memberServiceAssessment,
        application: state.memberServiceApplication,
        review: state.memberServiceReview,
        certifications: state.memberCertifications,
        renew: state.memberProductRenew,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificateInfoById: certId => {
            return dispatch(getCertificateInfoById(certId));
        },
        getRenewData: certId => {
            return dispatch(getRenewCertApplFromCertID(certId));
        },
        saveRenewCertApplication: (type, body) => {
            return dispatch(saveRenewCertApplication(type, body, false));
        },
        updateAssessment: () => {
            dispatch(setUpdateAssessment(true));
        },
        submitAssessment: () => {
            dispatch(setUpdateAssessment(false));
        },
        submitRenewal: () => {},
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ServiceRenewPage),
);
