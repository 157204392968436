import React from 'react';
import { connect } from 'react-redux';
import { b64toBlob } from '../../../../js/util';

import { Container, Col, Row } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import DashboardWidget from 'cyder/widget/DashboardWidget';
import Resources from '../../home/components/resources/Resources';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import MembershipDashboardWidget from 'pages/member/home/components/MembershipDashboardWidget';

import { memberHomePanelResourcesGetInfo, memberHomeSetLoadingResourceLibraryState } from 'actions/member/home/memberHomeAction';
import { staffCompanyDetailsPrintCertByCompanyId } from 'actions/staff/company/staffCompanyDetailsMembershipAction';

class MemberHomeOld extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.state = {
            isOpenMembershipInformation: true,
            isOpenAccountInformation: true,
            isOpenCertificates: true,
            isOpenResourceLibrary: true,
            isOpenUpcomingEvents: true,
            companyInfo: {},
        };
        this.showLoadingPrintCert = this.showLoadingPrintCert.bind(this);
        this.printCert = this.printCert.bind(this);
    }
    componentDidMount() {
        this.props.getRelevantData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoadingPrintCert(show) {
        this.setState({
            loadingPrintCert: show,
        });
    }
    printCert() {
        this.showLoadingPrintCert(true);
        this.props
            .printCertByCompanyId(this.props.match.params.companyid)
            .then(res => {
                var blob = b64toBlob(res.data, 'application/pdf');
                var blobUrl = URL.createObjectURL(blob);
                this.showLoadingPrintCert(false);
                window.open(blobUrl);
            })
            .catch(() => this.showLoadingPrintCert(false));
    }
    toggleCollapse(id) {
        let obj = {};
        obj[id] = !this.state[id];
        this.setState(obj);
    }
    render() {
        return (
            <Container key="container">
                <Row className="mt-2">
                    <Col xs={12} lg={6}>
                        <MembershipDashboardWidget />
                    </Col>
                    <Col xs={12} lg={6}>
                        <DashboardWidget
                            toggleCollapse={() => this.toggleCollapse('isOpenResourceLibrary')}
                            isOpen={this.state.isOpenResourceLibrary}
                            title="RESOURCE LIBRARY"
                            // iconImagePath="/assets/icons/icon_mbr_resource_library.png"
                        >
                            {this.props.loadingResourceLibrary ? (
                                <LoadingSpinner />
                            ) : (
                                <Resources
                                    membershipInfo={this.props.memberHomeMembershipInfo}
                                    memberHomeResources={this.props.memberHomeResources}
                                />
                            )}
                        </DashboardWidget>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        // Resource Library
        dispatch(memberHomeSetLoadingResourceLibraryState(true));
        dispatch(memberHomePanelResourcesGetInfo()).then(res => {
            dispatch(memberHomeSetLoadingResourceLibraryState(false));
        });
    },
    printCertByCompanyId: companyId => {
        return dispatch(staffCompanyDetailsPrintCertByCompanyId(companyId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberHomeOld));
