import React from 'react';

import '../../css/cyder/menu/messages-dropdown.css';

const badgeObj = {
    membership: 'fa-user',
    certification: 'fa-certificate',
};

const DropdownMessages = ({ notifications }) => (
    <div className="navbar-dropdown dropdown-messages">
        <div className="dropdown-inner">
            <div className="dropdown-title">4 pending notifications</div>
            {notifications.map((notification, i) => (
                <div className="dropdown-item" key={i}>
                    <p>
                        <i
                            className={`text-${notification.badge} fa ${
                                badgeObj[notification.type]
                            }`}
                        />
                        {/* <span className={`badge badge-sm badge-${notification.badge}`}> */}
                        {/*     {notification.type} */}
                        {/* </span> */}
                        <span className="separator" />
                        <span className="title dropdown-messages-title">
                            &nbsp;
                            {notification.title}
                        </span>
                    </p>
                    <p className="d-flex">
                        <span className="ml-auto date">
                            <small>{notification.date}</small>
                        </span>
                    </p>
                </div>
            ))}
        </div>
    </div>
);

let notifications = [
    {
        badge: 'success',
        type: 'membership',
        title: '2 new membership applications',
        date: '1 day ago',
    },
    {
        badge: 'success',
        type: 'certification',
        title: '1 new SGBP application',
        date: '1 day ago',
    },
    {
        badge: 'warning',
        type: 'membership',
        title: '3 expiring membership',
        date: '3 days ago',
    },
    {
        badge: 'danger',
        type: 'membership',
        title: '5 expired membership',
        date: '5 days ago',
    },
];

const SGBCMessagesDropdown = () => (
    <DropdownMessages notifications={notifications} />
);

export default SGBCMessagesDropdown;
