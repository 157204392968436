import { SGBC_API_KEY } from 'config';

export default {
    fetch: (dispatch, getState, method, url, body, actionTypeRequest, actionTypeSuccess, actionTypeFailure, opt) => {
        const options = opt || {
            headers: {
                Authorization: getState().profile.authorizationToken != null ? getState().profile.authorizationToken : 'allow',
                'x-api-key': SGBC_API_KEY,
                'content-type': 'application/json',
            },
            body: method === 'GET' ? null : JSON.stringify(body || {}),
            method,
        };
        if (actionTypeRequest) {
            dispatch({ type: actionTypeRequest });
        }

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        throw error;
                    });
                }
                return response.json().then(res => {
                    if (actionTypeSuccess) {
                        dispatch({
                            type: actionTypeSuccess,
                            response: res,
                        });
                    }

                    return res;
                });
            })
            .catch(error => {
                if (actionTypeFailure) {
                    dispatch({
                        type: actionTypeFailure,
                        error,
                    });
                }
                return error;
            });
    },
    fetchV2: async (dispatch, getState, method, url, body, actionTypeRequest, actionTypeSuccess, actionTypeFailure, options) => {
        const { authorizationToken } = getState().profile;
        // OPTIONS
        const opt = options || {
            method,
            body: method === 'POST' ? JSON.stringify(body || {}) : null,
            headers: {
                Authorization: authorizationToken != null ? authorizationToken : 'allow',
                'x-api-key': SGBC_API_KEY,
                'content-type': 'application/json',
            },
        };
        if (actionTypeRequest) dispatch({ type: actionTypeRequest });
        try {
            // FETCH
            const res = await fetch(url, opt);
            const json = await res.json();
            // CONSTRUCT RESPONSE
            const response = json.data
                ? {
                      ...json,
                      status: res.status,
                      ok: res.ok,
                  }
                : {
                      data: json,
                      status: res.status,
                      ok: res.ok,
                  };
            // SUCCESS
            if (res.ok && actionTypeSuccess)
                dispatch({
                    type: actionTypeSuccess,
                    response,
                });
            // FAILURE
            if (!res.ok && actionTypeFailure)
                dispatch({
                    type: actionTypeFailure,
                    error: response,
                });
            return response;
        } catch (error) {
            // FAILURE
            if (actionTypeFailure)
                dispatch({
                    type: actionTypeFailure,
                    error,
                });
            return {
                ok: false,
                error: true,
                message: error,
            };
        }
    },
};
