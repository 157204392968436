import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Card, CardBody, Button, Row, Col } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import { findByCategoryAndName } from 'actions/admin/misc/resources/action';

import 'css/icons/material-design-icons.css';
import 'css/cyder/form/common-form.css';
import 'css/cyder/tooltip/menucool.css';
import 'css/ui-elements/buttons.css';

class MembershipPlansCard extends Component {
    processPackagesByTimes(packages) {
        const cutOffDate = moment().format('YYYY') + '-06-15';
        const newPackages = packages
            .map(membershipPackage => {
                if (membershipPackage.isNewPackage == 0) {
                    switch (membershipPackage.id) {
                        case 5:
                        case 4:
                            return membershipPackage;
                    }
                } else {
                    // if (moment().isBefore(cutOffDate, 'day')) {
                    //     switch (membershipPackage.id) {
                    //         case 1:
                    //         case 3:
                    //             return membershipPackage;
                    //     }
                    // } else {
                    //     switch (membershipPackage.id) {
                    //         case 2:
                    //         case 6:
                    //             return membershipPackage;
                    //     }
                    // }
                    switch (membershipPackage.id) {
                        case 1:
                        case 3:
                            return membershipPackage;
                    }
                }
            })
            .filter(x => x);
        // sort by lengthOfValidityInMonths
        return newPackages.sort((a, b) => {
            return a.lengthOfValidityInMonths - b.lengthOfValidityInMonths;
        });
    }
    render() {
        // console.log(this.props.packages.reverse());
        let packages = this.processPackagesByTimes(this.props.packages.reverse());
        // console.log(packages);
        return (
            <Fragment>
                {this.props.loading ? (
                    <LoadingSpinner text={`Please wait, we are loading the list of membership packages`} />
                ) : (
                    <Fragment>
                        <Row>
                            {packages.map((item, i) => {
                                return (
                                    <PackageCard
                                        key={i}
                                        price={item.price}
                                        id={item.id}
                                        packageName={item.packageName}
                                        selectPackage={this.props.selectPackage}
                                    />
                                );
                            })}
                        </Row>
                        <br />
                        {/*<Row>
                            <Col className="mt-2 d-flex justify-content-end">
                                <Button
                                    className="primary-btn-style"
                                    onClick={() => {
                                        // TEMPERARY SOLUTION:
                                        // If 'Next' btn is clicked instead of 'Apply' btn,
                                        // default package (first in the list) will be applied.
                                        const id = packages.length > 0 && packages[0].id;
                                        this.props.selectPackage(id);
                                    }}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">keyboard_arrow_right</i>
                                    Next
                                </Button>
                            </Col>
                                </Row>*/}
                    </Fragment>
                )}
                {this.props.view && this.props.mode === 'preview' && (
                    <div>
                        <hr />
                    </div>
                )}
            </Fragment>
        );
    }
}
const PackageCard = ({ price, packageName, id, selectPackage, shouldPackageApplyBtnDisabled }) => {
    packageName = packageName.split(' ').slice(0, 2);
    packageName.splice(1, 0, ' ');
    // Remap package name
    // if (packageName[0] === '1.5') packageName[0] = '1.5';
    return (
        <Col className="mb-2">
            <Card className="plan-card">
                <CardBody style={{ backgroundColor: '#555' }}>
                    <Row style={{ textAlign: 'center', color: 'white' }}>
                        <Col md={12}>
                            <div className="title">
                                <h2>{packageName[0]}</h2>
                                <p>{packageName[2]}</p>
                                Membership
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody className="price">
                    <h2>
                        $<strong>{price.toFixed(2)}</strong>
                    </h2>
                    {/* Hard Coded Package Discount */}
                    {id === 3 ? (
                        <h5>
                            <strong>(After 10% discount)</strong>
                        </h5>
                    ) : (
                        <br />
                    )}
                </CardBody>
                <Button
                    block
                    disabled={shouldPackageApplyBtnDisabled}
                    onClick={() => selectPackage(id)}
                    className="primary-btn-style"
                    style={{
                        fontSize: '18px',
                    }}
                >
                    <strong>Apply</strong>
                </Button>
            </Card>
        </Col>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MembershipPlansCard));
