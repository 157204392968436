import React from 'react';
import { Row, Col } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const DetailsPageFormSectionCorporateInfo = ({ company }) => (
    <RowCardHOC>
        <h5>Section B: Corporate Information</h5>
        <br />
        <Row>
            <Col sm={6}>
                <LabelItem label="Name of Organisation" content={company.name} />
                <br />
                <LabelItem label="UEN / Company Registration Number" content={company.registrationNumber} />
                <br />
                <LabelItem label="Website" content={company.website} />
            </Col>
            <Col xs={6}>
                <LabelItem label="Country" content={company.countryName} />
                <br />
                <LabelItem label="Main phone number" content={company.mainPhoneNumber} />
                <br />
                {/* <LabelItem label="Facsimile" content={company.facsimile} /> */}
            </Col>
        </Row>
    </RowCardHOC>
);
export default DetailsPageFormSectionCorporateInfo;
