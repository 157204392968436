import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import history from 'history.js';

import { membershipStatisticsSetStat } from 'actions/staff/membership/members/staffMembershipStatisticsAction';

const STATS = [
    {
        value: 'Membership Dashboard',
        label: 'Membership Dashboard',
    },
    {
        value: 'Membership Issued Analysis by Month',
        label: 'Membership Issued Analysis by Month',
    },
    {
        value: 'Membership Issued Analysis by Year',
        label: 'Membership Issued Analysis by Year',
    },
    // {
    //     value: 'Renewal Analysis',
    //     label: 'Renewal Analysis'
    // },
    {
        value: 'Expiry Management Forecasting',
        label: 'Expiry Management Forecasting',
    },
];

class StatsSelector extends React.Component {
    constructor() {
        super();
        this.state = {
            disabled: false,
            searchable: true,
            clearable: true,
        };
    }

    updateValue(obj) {
        this.setState({
            selectValue: obj,
        });
    }

    render() {
        return (
            <form>
                <div className="form-row">
                    <div className="col">
                        <div className="form-group">
                            <Select
                                onChange={this.props.changeStat}
                                autosize={false}
                                placeholder=""
                                autofocus
                                options={STATS}
                                simpleValue
                                clearable={this.state.clearable}
                                name="selected-state"
                                disabled={this.state.disabled}
                                value={this.props.selectedStat}
                                searchable={this.state.searchable}
                            />

                            {/* <small className="form-text text-muted">Please select year</small> */}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        selectedStat: state.staffMembershipStatistics.selectedStat,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        changeStat: obj => {
            dispatch(membershipStatisticsSetStat(obj));
            if (obj === 'Membership Dashboard') {
                history.push('/staff/membership/overview');
            } else if (obj === 'Membership Issued Analysis by Month') {
                history.push('/staff/membership/overview/issuedanalysisbymonth');
            } else if (obj === 'Membership Issued Analysis by Year') {
                history.push('/staff/membership/overview/issuedanalysisbyyear');
            } else if (obj === 'Renewal Analysis') {
                history.push('/staff/membership/overview/renewalanalysis');
            } else if (obj === 'Expiry Management Forecasting') {
                history.push('/staff/membership/overview/expirymanagementforecasting');
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StatsSelector);
