import {
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING,
    MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE,
    APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
    MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE,
    MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA,
    MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD,
    MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION,
    MEMBERSHIP_APPLICATION_PAYMENT_REQUEST,
    MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS,
    MEMBERSHIP_APPLICATION_PAYMENT_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE,
    MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS,
    MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE,
} from '../../../constants/actionTypesStaffMembershipApplications';

import moment from 'moment';
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';

import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_PRINT_ROOT } from 'config.js';

export function getApplicationDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'searchbyapplicationid',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE,
        );
    };
}
export function getPaymentInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'getPaymentInfoByAppld',
            applicationId: id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE,
        );
    };
}
export function processReject(applicationId, comment) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processMembershipApplication',
        });
        let body = { applicationId, action: `doReject`, comment: comment };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE,
        );
    };
}

export function resubmitQuestionnaires(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'resubmit-questionnaires',
        });
        let body = { applicationId };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE,
        );
    };
}
export function processMembershipApplication(applicationId, procAction, params) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processMembershipApplication',
        });
        let body = { applicationId, action: procAction };
        if (params != null) {
            body.params = params;
        }

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE,
        );
    };
}

export function processApprove(applicationId, procAction, params) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processMembershipApplication',
        });
        let body = { applicationId, action: procAction };
        if (params != null) {
            body.params = params;
        }
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE,
        );
    };
}

export function processMembershipApplicationComplete(applicationId, membershipTier) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processApplicationAsComplete',
        });

        const formatDate = date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const { membershipStartDate, membershipEndDate } = getState().staffMembershipApplicationsDetailsReducer.paymentFields;
        const { renewalId, membershipType } = getState().staffMembershipApplicationsDetailsReducer.application;

        const body = {
            applicationId: parseInt(applicationId, 10),
            membershipTier,
            action: 'doComplete',
            membershipStartDate: formatDate(membershipStartDate),
            membershipEndDate: formatDate(membershipEndDate),
            membershipType: membershipType && membershipType.value,
            renewalId,
        };

        return fetchlib.fetchV2(
            dispatch,
            getState,
            'POST',
            url,
            body,
            // not a mistake that it is using the same actionTypes as
            // processMembershipApplication
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
            null,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE,
        );
    };
}
export function generateXeroInvoice(applicationId, name) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/xero_v1/invoice`, {
            action: 'add',
        });
        const body = { applicationId, name };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE,
        );
    };
}
export function forceValidate() {
    return (dispatch, getState) => {
        dispatch({
            type: MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE,
        });
        return Promise.resolve();
    };
}

export function markAsPaid(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processMembershipApplication',
        });
        let body = { applicationId, action: `markAsPaid` };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE,
        );
    };
}

export function markAsPaid_Old(applicationId, invoiceId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'processApplicationAsPaidOld',
        });
        const rawPaymentFields = getState().staffMembershipApplicationsDetailsReducer.paymentFields;
        const paymentFields = Object.assign({}, rawPaymentFields, {
            amountReceived: parseFloat(rawPaymentFields.amountReceived),
            modeOfPaymentId: parseInt(rawPaymentFields.modeOfPaymentId, 10),
            paymentId: rawPaymentFields.id,
        });
        const body = {
            applicationId: parseInt(applicationId, 10),
            action: 'markAsPaid',
            invoiceId,
            ...paymentFields,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE,
        );
    };
}
export function changeInputKeywordValue(inputKeywordValue) {
    return {
        type: APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
        inputKeywordValue,
    };
}
export function forceLoadingState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function resetState() {
    return {
        type: MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE,
    };
}
export function setModalData(data) {
    return {
        type: MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA,
        data,
    };
}
export function onChangePaymentFields(key, value) {
    return {
        type: MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD,
        value,
        key,
    };
}
export function setCalculateMembershipDatesFunction(func) {
    return {
        type: MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION,
        func,
    };
}
export function getApplicationPaymentInfo(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'get-payment-info-by-applicationid',
            id: applicationId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function updatePayment(data) {
    return (dispatch, getState) => {
        let updatedObj = Object.assign({}, data);
        updatedObj.datePaymentReceived = moment(data.datePaymentReceived, 'DD/MM/YYYY').format('YYYY-MM-DD');
        updatedObj.receivedTimestamp = moment(data.receivedTimestamp, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'update-payment',
        });
        //return fetchlib.fetchV2(dispatch, getState, 'POST', url, updatedObj, null,null,null);
        return fetchlib.fetchV2(
            dispatch,
            getState,
            'POST',
            url,
            updatedObj,
            MEMBERSHIP_APPLICATION_PAYMENT_REQUEST,
            MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS,
            MEMBERSHIP_APPLICATION_PAYMENT_FAILURE,
        );
    };
}
export function getXeroInventoryItems() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/xero/inventory-item`, {
            action: 'get-all-active',
            entity: 'SGBCGreenCouncil',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function submitOnBehalf(appId, companyId, packageId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'submitOnBehalf',
        });
        const body = {
            membershipApplicationId: appId,
            companyId: companyId,
            membershipPackageId: packageId,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST,
            MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS,
            MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE,
        );
    };
}

export function withdraw(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'withdraw',
        });
        var data = {
            id,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function createReceiptInS3(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-membership-receipt',
            id: applicationId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST,
            null,
            null,
        );
    };
}

export function sendEmailReceipt(applicationId, link, email) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'send-membership-receipt',
            id: applicationId,
        });
        var data = {
            membershiplink: link,
        };
        if (email != undefined && email != null) {
            data.email = email;
        }
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            null,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE,
        );
    };
}

export function sendReceiptEmail(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'sendReceiptEmail',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            null,
            // REUSE
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE,
        );
    };
}

export function createInvoice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'create-invoice',
        });
        var data = {
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            null,
            // REUSE
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE,
        );
    };
}

export function sendInvoice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'sendInvoice',
        });
        var data = {
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE,
        );
    };
}

export function updateXeroPayment(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'update-xero-payment',
        });
        var data = {
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            null,
            // REUSE
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS,
            MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE,
        );
    };
}

export function printMembership(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-membership',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function sendRejectEmail(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'sendRejectEmail',
            id: id,
        });

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            null,
            MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS,
            MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE,
        );
    };
}

export function changeApplicationType(id, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'changeApplicationType',
        });
        const body = {
            id,
            type,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
