import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';

import { Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import InputField, { ButtonComponent } from 'pages/admin/companies/shared/InputField';

import { save, update, remove, load, getProductCategories } from 'actions/admin/product/product-sub-category/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';
const yesOrNoOptions = [{ id: 1, label: 'Yes' }, { id: 0, label: 'No' }];

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.enableLowVocChange = this.enableLowVocChange.bind(this);
        this.subcategoryChange = this.subcategoryChange.bind(this);
        this.onProdCategoriesChange = this.onProdCategoriesChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            prodCategories: [],
            data: {
                enableLowVoc: 0,
                categoryId: '',
                subcategory: '',
                id: 0,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/product/product-sub-category');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, null, null, true);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        const tasks = [this.getRelevantStuff(), this.getProductCategories()];
        Promise.all(tasks).then(() => this.showLoading(false));
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuff() {
        try {
            const id = this.props.match.params.id;
            if (!id || id === '0') return;
            const { data, error, message } = await this.props.load(id);
            const hasError = this.showModalIf('Error', message, error || !data || !data.id);
            if (hasError) return;
            if (this._isMounted) {
                this.setState({
                    data,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    async getProductCategories() {
        try {
            const { data } = await this.props.getProductCategories();
            if (this._isMounted) {
                this.setState({
                    prodCategories: data,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    async save() {
        try {
            this.showLoading(true);
            const { save, update } = this.props;
            const data = this.prepareDataBeforeSave(this.state.data);
            const isSave = !data.id || data.id === 0;
            const { error, message } = isSave ? await save(data) : await update(data);
            const hasError = this.showModalIf('Error', message, error);
            if (hasError) return;
            history.replace('/admin/product/product-sub-category');
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/product/product-sub-category');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }
    enableLowVocChange(value) {
        var data = this.state.data;
        data.enableLowVoc = value.id;
        this.setState({ data });
    }
    subcategoryChange(e) {
        var data = this.state.data;
        data.subcategory = e.target.value;
        this.setState({ data });
    }
    onProdCategoriesChange(value) {
        const data = {
            ...this.state.data,
            categoryId: value.id,
        };
        this.setState({ data });
    }
    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <InputField
                    label="ID"
                    inputProps={{
                        type: 'text',
                        id: 'id',
                        disabled: true,
                        style: { width: '100px' },
                        value: this.state.data.id,
                    }}
                />
                <InputField
                    label="Sub Category"
                    instruction="Requires alphanumeric"
                    inputProps={{
                        type: 'textarea',
                        style: { width: '600px' },
                        id: 'subcategory',
                        value: this.state.data.subcategory,
                        onChange: this.subcategoryChange,
                    }}
                />
                <InputField label="Product Category" instruction="Select product category">
                    <div style={{ width: '600px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            options={this.state.prodCategories}
                            onChange={this.onProdCategoriesChange}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.category}
                            value={this.state.prodCategories.find(x => x.id === this.state.data.categoryId)}
                        />
                    </div>
                </InputField>
                <InputField label="Enable Low Voc" instruction="Select low voc">
                    <div style={{ width: '100px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            onChange={this.enableLowVocChange}
                            options={yesOrNoOptions}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.label}
                            value={yesOrNoOptions.find(x => x.id === this.state.data.enableLowVoc)}
                        />
                    </div>
                </InputField>
                <hr />
                <FlexRow between="xs">
                    <ButtonComponent label="Save" className="primary-btn-style" onClick={this.save} />
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                Delete
                            </Button>
                            <AuditListPopup contentTypeId={32} objectId={this.state.data.id} />
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getProductCategories: () => {
            return dispatch(getProductCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
