/**
 * New Product Review request page
 *
 * Author: Fernando Karnagi
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

import { Row, Col, Container } from 'reactstrap';
import BigForm from 'cyder/forms/BigForm';
import ButtonIcon from 'cyder/button/ButtonIcon';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import {
    memberCertListHandleRemarkChange,
    memberCertListSubmitRequestForReviw,
} from 'actions/member/certification/memberCertificationListAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

const RemarksPanel = ({ editable, onChange }) => {
    return (
        <div>
            <Row>
                <Col>
                    <div className="form-group">
                        <label className="field-header">Remarks {editable && <MandatoryStar />} </label>
                        {editable && <textarea className="form-control" rows="6" onChange={onChange} />}
                        {editable && <small className="form-text text-muted">Please provide remarks for this review</small>}
                        {!editable && <div className="field-label">-</div>}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

class NewReviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.handleRemarkChange = this.handleRemarkChange.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
        };
    }
    handleRemarkChange(e) {
        this.props.handleRemarkChange(e.target.value);
    }
    submit() {
        this.props.submit(this.props.match.params.certId, this.props.match.params.type).then(() => this.props.toggleModal());
    }
    render() {
        const { certificationList, cancel } = this.props;
        const { pathname } = window.location;
        const type = pathname.includes('product') ? 'product' : 'service';

        if (certificationList.loading) return <LoadingSpinner />;
        return (
            <Fragment>
                <DialogModal
                    modalBody={certificationList.modalBody}
                    loading={certificationList.modalLoading}
                    modalAction={certificationList.modalAction}
                    modalHeader={certificationList.modalHeader}
                    buttonDisabler={certificationList.modalLoading}
                    modalOnlyOneButton={certificationList.modalOnlyOneButton}
                />
                <Container>
                    <br />
                    <BigForm
                        title="Certificate Review"
                        description="Review will take place progressively and will take approximately 3 weeks to complete, depending on the validity and quality of the submitted documents."
                    >
                        <div className="description">Please indicate the key change(s) for review in the remarks box.</div>
                        <div>
                            <RemarksPanel visible={true} editable={true} onChange={this.handleRemarkChange} />
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6">
                                <ButtonIcon className="mr-1" onClick={() => cancel(type)} icon="arrow_back" label="Back" />
                            </div>
                            <div className="col-6" style={{ textAlign: 'right' }}>
                                <ButtonIcon className="mr-1" onClick={this.submit} icon="send" label="Submit" />
                            </div>
                        </div>
                    </BigForm>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberProductReview,
        certificationList: state.memberCertificationsListReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        cancel: type => {
            history.replace(`/member/certification/${type}/certificates`);
        },
        submit: (certId, type) => {
            return dispatch(memberCertListSubmitRequestForReviw(certId, type));
        },
        handleRemarkChange: value => {
            dispatch(memberCertListHandleRemarkChange(value));
        },
        toggleModal: () => {
            dispatch(dialogModalToggle());
        },
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(NewReviewPage),
);
