import React from 'react';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import history from '../../../../history';
import moment from 'moment';

import { Row, Col, Form, Button, FormGroup, Input } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { search, generateVoters, sendVotingKey, sendVotingReminder } from 'actions/staff/election/voter/action.js';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.generateVoters = this.generateVoters.bind(this);
        this.sendVotingKey = this.sendVotingKey.bind(this);
        this.sendVotingReminder = this.sendVotingReminder.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            list: [],
            loading: false,
            loadingGenerateVoters: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            keyword: '',
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'alert',
            },
        };
    }
    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        } else if (this.state.modal.type === 'alertrefresh') {
            this.modalToggle();
            this.table.fireFetchData();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalAction = this.modalAction;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    showLoadingGenerateVoters(show) {
        this.setState({
            loadingGenerateVoters: show,
        });
    }
    sendVotingReminder(voterId) {
        // this.showLoadingSendMassVoterKey(true);
        this.props
            .sendVotingReminder(voterId)
            .then(res => {
                // this.showLoadingSendMassVoterKey(false);
                this.componentDidMount();
                this.showModal('Confirmation', `Voting reminder successfully sent.`, 'alertrefresh', false, true);
            })
            .catch(error => {
                // this.showLoadingSendMassVoterKey(false);
                this.showModal('Error', `An error occured during sending voting reminder`, 'alert', false, true);
                throw error;
            });
    }
    sendVotingKey(voterId) {
        // this.showLoadingSendMassVoterKey(true);
        this.props
            .sendVotingKey(voterId)
            .then(res => {
                // this.showLoadingSendMassVoterKey(false);
                this.componentDidMount();
                this.showModal('Confirmation', `Voter key successfully sent.`, 'alertrefresh', false, true);
            })
            .catch(error => {
                // this.showLoadingSendMassVoterKey(false);
                this.showModal('Error', `An error occured during sending voter key`, 'alert', false, true);
                throw error;
            });
    }
    generateVoters() {
        this.showLoadingGenerateVoters(true);
        this.props
            .generateVoters(this.props.match.params.electionid)
            .then(res => {
                this.table.fireFetchData();
                this.showLoadingGenerateVoters(false);
            })
            .catch(error => {
                this.showLoadingGenerateVoters(false);
                throw error;
            });
    }
    componentDidMount() {}
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, this.props.match.params.electionid, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'ID',
                minWidth: 50,
                accessor: 'id',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            // {
            //     Header: 'Full Name',
            //     minWidth: 100,
            //     accessor: 'fullName',
            //     style: { whiteSpace: 'unset' },
            //     headerStyle: { whiteSpace: 'unset' },
            // },
            {
                Header: 'Company Name',
                minWidth: 100,
                accessor: 'companyName',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Membership Expiration Date',
                minWidth: 100,
                accessor: 'membershipExpiryDate',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value).format('DD/MM/YYYY');
                },
            },
            {
                Header: 'Email',
                minWidth: 100,
                accessor: 'email',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Voter Key',
                minWidth: 100,
                accessor: 'voterKey',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Voted Timestamp',
                accessor: 'voteCastTimestamp',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value == null) {
                        return (
                            <span
                                style={{
                                    color: '#cc0000',
                                    fontWeight: 'bold',
                                }}
                            >
                                Not yet voted
                            </span>
                        );
                    } else {
                        return moment(props.value).format('DD/MM/YYYY HH:mm');
                    }
                },
            },
            {
                Header: 'Send Key Timestamp',
                accessor: 'voterKeyEmailSentTimestamp',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value == null) {
                        return (
                            <span
                                style={{
                                    color: '#cc0000',
                                    fontWeight: 'bold',
                                }}
                            >
                                Not yet sent
                            </span>
                        );
                    } else {
                        return moment(props.value).format('DD/MM/YYYY HH:mm');
                    }
                },
            },
            {
                Header: 'Resend Key',
                accessor: 'id',
                maxWidth: 125,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Button
                            className="ml-2 align-middle primary-btn-style"
                            onClick={e => {
                                this.sendVotingKey(props.value);
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">send</i>
                        </Button>
                    );
                },
            },
            {
                Header: 'Sent Reminder Timestamp',
                accessor: 'reminderEmailSentTimestamp',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value == null) {
                        return (
                            <span
                                style={{
                                    color: '#cc0000',
                                    fontWeight: 'bold',
                                }}
                            >
                                Not yet sent
                            </span>
                        );
                    } else {
                        return moment(props.value).format('DD/MM/YYYY HH:mm');
                    }
                },
            },
            {
                Header: 'Resend Reminder',
                accessor: 'id',
                maxWidth: 125,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Button
                            className="ml-2 align-middle primary-btn-style"
                            onClick={e => {
                                this.sendVotingReminder(props.value);
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">send</i>
                        </Button>
                    );
                },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={`/staff/elections/view/${this.props.match.params.electionid}/voters/${props.value}`}>
                            <Button className="ml-2 align-middle primary-btn-style">
                                {props.original.voteCast == 0 ? (
                                    <i className="mr-1 material-icons align-text-bottom ">edit</i>
                                ) : (
                                    <i className="mr-1 material-icons align-text-bottom ">folder_open</i>
                                )}
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Row className="mb-2">
                    <Col xs={6} className="d-flex mb-4">
                        <Button
                            className="primary-btn-style-outline"
                            onClick={e => {
                                history.push(`/staff/elections/view/${this.props.match.params.electionid}`);
                            }}
                        >
                            <i className="material-icons align-text-bottom">chevron_left</i>
                            &nbsp; Back
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className="form-inline float-left">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    value={this.state.keyword}
                                    onChange={this.onKeywordChange}
                                />
                            </FormGroup>
                            <Button
                                className="ml-2 align-middle primary-btn-style"
                                onClick={e => {
                                    this.table.fireFetchData();
                                }}
                            >
                                {' '}
                                <i className="material-icons align-text-bottom">search</i>
                                Search
                            </Button>
                        </div>
                        <Form inline>
                            <div className="ml-auto">
                                {this.state.loadingGenerateVoters ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Button
                                        className="ml-2 align-middle primary-btn-style"
                                        disabled={this.state.list.length > 0}
                                        onClick={this.generateVoters}
                                    >
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Generate Voters
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, electionId, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, electionId, page, pageSize, sorted, filtered));
        },
        generateVoters: electionId => {
            return dispatch(generateVoters(electionId));
        },
        sendVotingReminder: voterId => {
            return dispatch(sendVotingReminder(voterId));
        },
        sendVotingKey: voterId => {
            return dispatch(sendVotingKey(voterId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
