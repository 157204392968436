import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import Select from 'react-select';
import moment from 'moment';
import { Badge, Row, Col, Input, FormGroup, Label, Button, Container, Card, CardBody } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderHeaderPanel from 'cyder/panels/CyderHeaderPanel';
import { getPaymentInfo } from 'actions/staff/certification/staffCertificationApplicationAction';
class CreditCardPaymentInfoPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            payment: {},
        };
    }
    componentDidMount() {}
    render() {
        return this.props.loading ? (
            <LoadingSpinner center />
        ) : (
            <React.Fragment>
                <Row className="mb-2">
                    {' '}
                    <Col xs={12}>
                        {' '}
                        <FormGroup>
                            {' '}
                            <Label for="type">Payment Received On</Label>{' '}
                            <Input
                                disabled
                                style={{ width: '200px' }}
                                value={moment(this.props.paymentInfo.receivedTimestamp).format('DD/MM/YYYY')}
                            />{' '}
                        </FormGroup>{' '}
                    </Col>{' '}
                </Row>

                <Row className="mb-2">
                    {' '}
                    <Col xs={12}>
                        {' '}
                        <FormGroup>
                            {' '}
                            <Label for="type">Amount</Label>{' '}
                            <Input
                                disabled
                                style={{ width: '200px' }}
                                value={Number(this.props.paymentInfo.amountReceived.toFixed(2)).toLocaleString()}
                            />{' '}
                        </FormGroup>{' '}
                    </Col>{' '}
                </Row>

                <Row className="mb-2">
                    {' '}
                    <Col xs={12}>
                        {' '}
                        <FormGroup>
                            {' '}
                            <Label for="type">Payment Mode</Label>{' '}
                            <Input disabled style={{ width: '200px' }} value={this.props.paymentInfo.paymentMode} />{' '}
                        </FormGroup>{' '}
                    </Col>{' '}
                </Row>

                <Row className="mb-2">
                    {' '}
                    <Col xs={12}>
                        {' '}
                        <FormGroup>
                            {' '}
                            <Label for="type">Credit Card Number</Label>{' '}
                            <Input disabled style={{ width: '200px' }} value={this.props.paymentInfo.referenceNumber} />{' '}
                        </FormGroup>{' '}
                    </Col>{' '}
                </Row>

                <Row className="mb-2">
                    {' '}
                    <Col xs={12}>
                        {' '}
                        <FormGroup>
                            {' '}
                            <Label for="type">Remarks</Label> <Input disabled value={this.props.paymentInfo.remarks} />{' '}
                        </FormGroup>{' '}
                    </Col>{' '}
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPaymentInfo: id => {
            return dispatch(getPaymentInfo(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(StoredLayout(CreditCardPaymentInfoPanel)));
