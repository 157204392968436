import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import history from '../../../history';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormGroup, Label, Input, Form, Row, Col, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { setSurveyValue, resetQuestion, saveSurvey, deleteSurvey, getAllAnswers } from 'actions/staff/survey/surveyAction';
class SurveysCreatePage extends React.Component {
    constructor(props) {
        super(props);

        // this.bcaDataOptionChange = this.bcaDataOptionChange.bind(this);
        // this.sortKeyChange = this.sortKeyChange.bind(this);

        this.state = {
            surveyAnswers: [],
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({
            loading: true,
        });
        var surveyId = null;
        try {
            surveyId = this.props.match.params.surveyid;
        } catch (e) {}
        let res = await this.props.setSurveyValue(surveyId);
        let answers = await this.props.getAllAnswers(surveyId);
        if (answers != null && answers.data != null) {
            this.setState({
                surveyAnswers: answers.data,
            });
            this.props.setSurveyAnswers(answers.data);
        }
        this.setState({
            loading: false,
        });
    }

    render() {
        console.log('Category', this.props.survey);
        const surveyCategoryOptions = [
            {
                text: 'Membership',
                surveyCategoryId: 2,
            },
            {
                text: 'Events & Training',
                surveyCategoryId: 3,
            },
            {
                text: 'Certification',
                surveyCategoryId: 4,
            },
            {
                text: 'SGBC',
                surveyCategoryId: 5,
            },
        ];

        const linkTo = '/staff/surveys/view/' + this.props.match.params.surveyid;
        const stringTo = 'https://sgbc.online/staff/surveys/view/' + this.props.match.params.surveyid;
        if (this.state.loading) {
            return <LoadingSpinner center />;
        } else
            return (
                <div className="page-widget">
                    <DialogModal
                        actionToggleExternal={this.props.surveySaveModalToggle}
                        modalOpen={this.props.survey.control.surveySaveModal.show}
                        modalAction={this.props.modalAction}
                        positiveButtonHide={true}
                        negativeButtonHide={true}
                        modalBody={this.props.survey.control.surveySaveModal.message}
                        modalHeader={this.props.survey.control.surveySaveModal.header}
                    />
                    <DialogModal
                        actionToggleExternal={this.props.surveyDeleteModalToggle}
                        modalOpen={this.props.survey.control.surveyDeleteModal.show}
                        modalAction={this.props.modalAction}
                        positiveButtonHide={true}
                        negativeButtonHide={true}
                        modalBody={this.props.survey.control.surveyDeleteModal.message}
                        modalHeader={this.props.survey.control.surveyDeleteModal.header}
                    />
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.props.goBack}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                &nbsp; Back
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <div>
                                {this.props.match.params.surveyid ? (
                                    <div>
                                        <div className="d-flex">
                                            <span className="ml-auto">
                                                Survey Link:&nbsp;
                                                <Link style={{ color: 'blue' }} to={linkTo}>
                                                    {stringTo}
                                                </Link>
                                            </span>
                                        </div>
                                        <div className="d-flex mt-2">
                                            <span className="ml-auto">
                                                <Link to={'/staff/surveys/' + this.props.match.params.surveyid + '/result'}>
                                                    <Button className="primary-btn-style">View Results</Button>
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                                <Form className="mb-3">
                                    <InputFieldWithValidation
                                        type="text"
                                        label="Survey Name"
                                        value={this.props.survey.data.survey.surveyName}
                                        valueKey="aaa"
                                        validationObj={{}}
                                        handleChange={this.props.onSurveyNameChanged}
                                    />
                                    <InputFieldWithValidation
                                        type="textarea"
                                        label="Survey Description"
                                        value={this.props.survey.data.survey.surveyDescription}
                                        rows={5}
                                        valueKey="aaa"
                                        validationObj={{}}
                                        handleChange={this.props.onSurveyDescriptionChanged}
                                    />
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>Survey Category</Label>
                                                <div className="d-flex" style={{ width: 200 }}>
                                                    <Input
                                                        className="mr-2"
                                                        type="select"
                                                        val={this.props.survey.data.survey.surveyCategoryId}
                                                        onChange={this.props.onSurveyCategoryChanged}
                                                        defaultValue={this.props.survey.data.survey.surveyCategoryId || ''}
                                                    >
                                                        <option value="" disabled>
                                                            Select a category
                                                        </option>
                                                        {surveyCategoryOptions.map((item, i) => (
                                                            <option key={i} value={item.surveyCategoryId}>
                                                                {item.text}
                                                            </option>
                                                        ))}
                                                    </Input>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Start Date</Label>
                                                <div className="d-flex">
                                                    <CyderDatePicker
                                                        value={this.props.survey.data.survey.startedOn}
                                                        onChange={this.props.onStartedOnChanged}
                                                        datetimeClassName="mr-2"
                                                        dateFormat="DD/MM/YYYY"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>End Date</Label>
                                                <div className="d-flex">
                                                    <CyderDatePicker
                                                        value={this.props.survey.data.survey.finishedOn}
                                                        onChange={this.props.onFinishedOnChanged}
                                                        datetimeClassName="mr-2"
                                                        dateFormat="DD/MM/YYYY"
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <hr />
                                <Row>
                                    <Col xs={this.state.surveyAnswers == null || this.state.surveyAnswers.length <= 0 ? 5 : 12}>
                                        <h4>Questions List</h4>
                                        {this.props.survey.data.survey.questions.length > 0 ? (
                                            <div>
                                                <ListGroup>
                                                    {this.props.survey.data.survey.questions.map((qn, i) => (
                                                        <ListGroupItem key={i} className="d-flex">
                                                            <span>{qn.questionText}</span>
                                                            {this.state.surveyAnswers == null ||
                                                                (this.state.surveyAnswers.length <= 0 && (
                                                                    <div className="ml-auto">
                                                                        <Button
                                                                            className="primary-btn-style"
                                                                            onClick={() => {
                                                                                this.props.removeQuestion(qn.id);
                                                                            }}
                                                                        >
                                                                            <i className="material-icons align-text-bottom">delete</i>
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                        </ListGroupItem>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        ) : (
                                            <span className="text-muted">Please add at least one question.</span>
                                        )}
                                    </Col>
                                    {this.state.surveyAnswers == null ||
                                        (this.state.surveyAnswers.length <= 0 && (
                                            <Col>
                                                <h4>Question Info</h4>
                                                <Form>
                                                    <FormGroup className="d-flex" row>
                                                        <Input
                                                            className="col mr-2"
                                                            type="select"
                                                            val={this.props.survey.data.currentQuestion.questionTypeId}
                                                            onChange={this.props.changeQuestionType}
                                                        >
                                                            <option value="2">Single Option</option>
                                                            <option value="3">Text</option>
                                                        </Input>
                                                        <Button className="primary-btn-style" onClick={this.props.addQuestion}>
                                                            <i className="mr-1 material-icons align-text-bottom ">add</i>
                                                            Add
                                                        </Button>
                                                        &nbsp;&nbsp;
                                                        <Button className="primary-btn-style" onClick={this.props.resetQuestion}>
                                                            <i className="mr-1 material-icons align-text-bottom ">
                                                                settings_backup_restore
                                                            </i>
                                                            Reset
                                                        </Button>
                                                    </FormGroup>
                                                    {this.props.survey.data.currentQuestion.questionTypeId == 2 ? (
                                                        <div className="mb-2">
                                                            <InputFieldWithValidation
                                                                type="textarea"
                                                                rows={5}
                                                                label="Question Text"
                                                                value={this.props.survey.data.currentQuestion.questionText}
                                                                valueKey="ticketDescription"
                                                                validationObj={{}}
                                                                handleChange={this.props.onQuestionTextChanged}
                                                            />
                                                            {this.props.survey.data.currentQuestion.questionOptions.map((item, i) => {
                                                                return (
                                                                    <PossibleRadioChoice
                                                                        key={i}
                                                                        item={item}
                                                                        onOptionTextChanged={this.props.onOptionTextChanged}
                                                                        removeOption={this.props.removeOption}
                                                                    />
                                                                );
                                                            })}
                                                            <Button block onClick={this.props.addOption} className="primary-btn-style">
                                                                <i className="mr-1 material-icons align-text-bottom ">add</i>
                                                                Add Option
                                                            </Button>
                                                            <br />
                                                        </div>
                                                    ) : (
                                                        <div className="mb-2">
                                                            <InputFieldWithValidation
                                                                type="textarea"
                                                                rows={5}
                                                                label="Question Text"
                                                                value={this.props.survey.data.currentQuestion.questionText}
                                                                valueKey="ticketDescription"
                                                                validationObj={{}}
                                                                handleChange={this.props.onQuestionTextChanged}
                                                            />
                                                        </div>
                                                    )}
                                                </Form>
                                            </Col>
                                        ))}
                                </Row>
                                <hr />
                                <FlexRow between="xs">
                                    <FlexCol xs={2}>
                                        <Button className="primary-btn-style-outline" onClick={this.props.deleteSurvey}>
                                            Delete Survey
                                        </Button>
                                    </FlexCol>
                                    {this.state.surveyAnswers == null ||
                                        (this.state.surveyAnswers.length <= 0 && (
                                            <FlexCol xs={2} style={{ display: 'contents' }}>
                                                <Button
                                                    className="primary-btn-style"
                                                    disabled={
                                                        this.props.survey.data.survey.surveyCategoryId == '' ||
                                                        this.props.survey.data.survey.questions.length == 0
                                                    }
                                                    onClick={this.props.saveSurvey}
                                                >
                                                    Save Survey
                                                </Button>
                                            </FlexCol>
                                        ))}
                                </FlexRow>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
    }
}

const PossibleRadioChoice = props => {
    return (
        <FormGroup className="d-flex">
            <Input
                className="mr-2"
                value={props.item.text}
                onChange={e => {
                    props.onOptionTextChanged(props.item.id, e.target.value);
                }}
            />
            <Button
                color="danger"
                className="ml-auto"
                onClick={() => {
                    props.removeOption(props.item.id);
                }}
            >
                Remove Option
            </Button>
        </FormGroup>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        survey: state.staffSurvey,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setSurveyValue: surveyId => {
            return dispatch(setSurveyValue(surveyId));
        },
        getAllAnswers: surveyId => {
            return dispatch(getAllAnswers(surveyId));
        },
        surveySaveModalToggle: () => {
            dispatch({
                type: 'STAFF_SURVEY_TOGGLE_SAVE_MODAL',
            });
        },
        surveyDeleteModalToggle: () => {
            dispatch({
                type: 'STAFF_SURVEY_TOGGLE_DELETE_MODAL',
            });
        },
        goBack: () => {
            history.replace('/staff/surveys');
        },
        deleteSurvey: () => {
            dispatch(deleteSurvey());
        },
        saveSurvey: () => {
            dispatch(saveSurvey());
        },
        removeOption: optionId => {
            dispatch({
                type: 'STAFF_SURVEY_REMOVE_OPTION',
                id: optionId,
            });
        },
        addOption: () => {
            dispatch({
                type: 'STAFF_SURVEY_ADD_OPTION',
            });
        },
        onSurveyCategoryChanged: e => {
            dispatch({ type: 'STAFF_SURVEY_CHANGE_SURVEY_CATEGORY', surveyCategoryId: e.target.value });
        },
        onOptionTextChanged: (optionId, newValue) => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_OPTIONTEXT',
                id: optionId,
                optionText: newValue,
            });
        },
        onStartedOnChanged: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_STARTEDON',
                startedOn: e,
            });
        },
        onFinishedOnChanged: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_FINISHEDON',
                finishedOn: e,
            });
        },
        onQuestionTextChanged: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_QUESTIONTEXT',
                questionText: e.target.value,
            });
        },
        onSurveyNameChanged: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_SURVEYNAME',
                surveyName: e.target.value,
            });
        },
        onSurveyDescriptionChanged: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_SURVEYDESCRIPTION',
                surveyDescription: e.target.value,
            });
        },
        addQuestion: () => {
            dispatch({ type: 'STAFF_SURVEY_ADD_QUESTION' });
        },
        removeQuestion: questionId => {
            dispatch({ type: 'STAFF_SURVEY_REMOVE_QUESTION', id: questionId });
        },
        resetQuestion: () => {
            dispatch({ type: 'STAFF_SURVEY_RESET_QUESTION' });
        },
        changeQuestionType: e => {
            dispatch({
                type: 'STAFF_SURVEY_CHANGE_QUESTION_TYPE',
                questionTypeId: e.target.value,
            });
        },
        setSurveyAnswers: surveyAnswers => {
            dispatch({ type: 'SHARED_SURVEY_SET_SURVEY_ANSWERS', surveyAnswers });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SurveysCreatePage));
