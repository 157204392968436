import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Label, Card, CardBody, Row, Col } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';

import { findByCategoryAndName } from 'actions/admin/misc/resources/action';

import { SGBC_S3_ROOT } from 'config';

class TermsAndConditionsPanel extends Component {
    state = {
        agreeTerms: false,
    };
    async downloadTermAndConditionsDocument(name = 'Membership Terms and Conditions') {
        const { findByCategoryAndName } = this.props;
        try {
            const { data } = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + data.resource;
            window.open(url);
        } catch (error) {
            throw error;
        }
    }
    onInputChecked = e => {
        // Local state support
        const { id, checked } = e.target;
        let stateClone = Object.assign({}, this.state);
        stateClone[id] = checked;
        this.setState(stateClone);

        // External prop support
        if (this.props.onChange) this.props.onChange(checked);
    };
    render() {
        const { documentName } = this.props;
        return (
            <Card>
                <CardBody>
                    <Col>
                        <InputField
                            customLabelComponent={
                                <Label>
                                    I accept the
                                    <a href="#" onClick={() => this.downloadTermAndConditionsDocument(documentName)}>
                                        &nbsp;<u>Terms and Conditions</u>&nbsp;
                                    </a>
                                </Label>
                            }
                            inputProps={{
                                type: 'checkbox',
                                id: 'agreeTerms',
                                value: this.state.agreeTerms,
                                onChange: this.onInputChecked,
                            }}
                        />
                        <Row>
                            <Col>
                                Disclaimer: All fees are subjected to prevailing Government taxes and an administrative fee of S$50 will be
                                levied for overseas payments.
                            </Col>
                        </Row>
                    </Col>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(TermsAndConditionsPanel));
