/**
 * Product Certifications
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../../history';
import moment from 'moment';

import { Row, Col } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import CyderPaginationReactTable from 'cyder/table/CyderPaginationReactTable';
import { SGBC_DISABLED_CERT, SGBC_OVERRIDE_ENABLED_CERT_COMPANIES } from 'config.js';

import { printCert, downloadCertificate } from 'actions/shared/certificationAction';

import { b64toBlob } from 'js/util';
import { SGBC_S3_CERT_LOGO_ROOT } from 'config.js';

const BrandDetailComponent = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((brand, index) => {
                return (
                    <div key={index}>
                        <div className="text-bold">{brand.name.toUpperCase()}</div>
                        {brand.models !== undefined &&
                            brand.models.map((model, index) => {
                                return <div key={index}>{model.name}</div>; //no description
                            })}
                        <br />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const ButtonComponent = ({ onClick, label, icon, className, buttonColor }) => (
    <Col xs={12} className={'d-flex justify-content-center mb-2 ' + className}>
        <ButtonIcon flat block icon={icon} label={label} buttonColor={buttonColor} onClick={onClick} />
    </Col>
);

const columns = [
    {
        Header: 'Certificate Number',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        accessor: 'certificationNumber',
    },

    {
        Header: 'Category',
        style: { whiteSpace: 'unset' },
        accessor: 'category',
        headerStyle: { whiteSpace: 'unset' },
        Cell: props => {
            return <div>{!props.value ? 'None' : props.value}</div>;
        },
    },
    {
        Header: 'Rating',
        accessor: 'rating',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        maxWidth: 100,
        Cell: props => {
            return <div>{!props.value ? 'None' : props.original.ratingName}</div>;
        },
    },
];
const productColumns = [
    {
        Header: 'Details',
        accessor: 'brandJson',
        sortable: false,
        minWidth: 180,
        Cell: props => {
            let plainBrand = props.original.brand != null ? props.original.brand : '-';
            if (props.original.model != null && props.original.model != '') {
                plainBrand = (
                    <div>
                        <b>{plainBrand}</b>
                        <br />
                        {props.original.model}
                    </div>
                );
            }
            const products = props.value || [];
            try {
                return products.length > 0 ? <BrandDetailComponent items={products} /> : plainBrand;
            } catch (e) {
                return plainBrand;
            }
        },
    },
];
class CertificationsReactTable extends React.Component {
    printCert = async (certificateId, type) => {
        const { downloadCertificate } = this.props;
        try {
            const res = await downloadCertificate(certificateId);
            // const blob = b64toBlob(res.data, 'application/pdf');
            // const blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
        } catch (error) {
            throw error;
        }
    };
    assignPrintCertProps(certifications) {
        return certifications.map((entry, i) => {
            let newEntry = Object.assign({}, entry);
            newEntry.printCert = this.printCert;
            return newEntry;
        });
    }
    renew = (certificateId, type) => {
        if (!certificateId) {
            this.showModal('error', 'No certification id was found', '', false, true);
            return;
        }
        history.push(`/member/certification/${type}/renew/${certificateId}`);
    };
    review = (certId, type, applicationId, certificationNumber) => {
        if (applicationId != null && certificationNumber != null) {
            history.push(`/member/certification/${type.toLowerCase()}/newreview/${certId}`);
        } else {
            history.push(`/member/certification/${type.toLowerCase()}/newapplication/${certId}/${encodeURIComponent(certificationNumber)}`);
        }
    };
    viewreview = () => {
        history.push('/member/certification/product/reviewassessment/');
    };
    downloadCertLogo = (ratingId, type) => {
        const val = type === 'Product' ? 'p' + ratingId : 's';
        window.open(`${SGBC_S3_CERT_LOGO_ROOT}sgb${val}.zip`);
    };
    render() {
        const { mode, loading, tablePages, totalNum, certifications, getDataFunc } = this.props;
        const columns2 = [
            {
                Header: 'Issued',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'issued',
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Date of Expiry',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'expired',
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: '',
                accessor: 'validity',
                width: 240,
                sortable: false,
                Cell: props => {
                    const { review, printCert, viewreview, renew, downloadCertLogo } = this;
                    const {
                        value,
                        original: {
                            certType,
                            certificateId,
                            applicationId,
                            applicationTypeId,
                            certificateApplicationStatusId,
                            expired,
                            expiredInDays,
                            rating,
                            renewalStatus,
                            inReview,
                            companyName,
                        },
                    } = props;

                    const originalCertApplicationId = props.original.originalCertApplicationId;
                    const certificationNumber = props.original.certificationNumber;

                    // Temporarily disable cert renewal
                    let disabledCert = SGBC_DISABLED_CERT;
                    if (SGBC_OVERRIDE_ENABLED_CERT_COMPANIES.includes(companyName)) {
                        disabledCert = false;
                    }
                    const sixMonthsBeforeExpiring = moment(expired).subtract(6, 'months');
                    const sixMonthsAfterExpiring = moment(expired).add(6, 'months');
                    console.log('Last day of renewal', sixMonthsAfterExpiring.format('YYYY-MM-DD'));
                    console.log('First day of renewal', sixMonthsBeforeExpiring.format('YYYY-MM-DD'));

                    const checkRenewalDateRange = () => {
                        if (
                            moment().isSameOrAfter(sixMonthsBeforeExpiring, 'day') &&
                            moment().isSameOrBefore(sixMonthsAfterExpiring, 'day')
                        )
                            return true;
                        else return false;
                    };

                    const isRenew =
                        (applicationId == null ||
                            (applicationId != null &&
                                (certificateApplicationStatusId == 9 ||
                                    certificateApplicationStatusId == 11 ||
                                    certificateApplicationStatusId == 12))) &&
                        checkRenewalDateRange() &&
                        renewalStatus != 10;

                    // Determine is renew
                    //const isRenew =
                    //    (applicationId == null ||
                    //        (applicationId != null &&
                    //            (certificateApplicationStatusId == 9 ||
                    //                certificateApplicationStatusId == 11 ||
                    //                certificateApplicationStatusId == 12))) &&
                    //    -180 <= expiredInDays && expiredInDays <= 180 &&
                    //    renewalStatus != 10;

                    // Dertermine button color
                    const buttonColor = !disabledCert && isRenew ? '#e1ab4c' : null;

                    return (
                        <Row>
                            {inReview == 0 && value == 'Valid' && expiredInDays > 180 && (
                                <ButtonComponent
                                    icon="drafts"
                                    label="Review"
                                    buttonColor={buttonColor}
                                    onClick={() => review(certificateId, certType, originalCertApplicationId, certificationNumber)}
                                />
                            )}
                            {!disabledCert && isRenew && (
                                <ButtonComponent
                                    icon="replay"
                                    label="Renew"
                                    buttonColor="#e1ab4c"
                                    onClick={() => renew(certificateId, certType)}
                                />
                            )}
                            {(value == 'Valid' || value == 'Expiring') && (
                                <ButtonComponent
                                    icon="file_download"
                                    label="Download Certificate"
                                    buttonColor={buttonColor}
                                    onClick={() => printCert(certificateId, certType)}
                                />
                            )}
                            {/*props.value === 'In-Review'*/ inReview == 1 && (
                                <ButtonComponent icon="publish" label="Update Documents" buttonColor={buttonColor} onClick={viewreview} />
                            )}
                            {(value == 'Valid' || value == 'Expiring') && (
                                <ButtonComponent
                                    icon="collections"
                                    label="Download Certificate Logo"
                                    buttonColor={buttonColor}
                                    onClick={() => downloadCertLogo(rating, certType)}
                                />
                            )}
                        </Row>
                    );
                },
            },
        ];
        const tableCols = mode === 'product' ? columns.concat(productColumns, columns2) : columns.concat(columns2);
        return (
            <ReactTable
                className="-highlight mb-2"
                data={certifications}
                columns={tableCols}
                minRows={0}
                filterable={false}
                manual
                showPagination={true}
                ref={instance => {
                    if (instance != null) {
                        this.table = instance.table;
                    }
                }}
                pages={tablePages}
                loading={loading}
                onFetchData={(state, instance) => {
                    getDataFunc(state.page, state.pageSize, state.sorted, state.filtered);
                }}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    ownProps,
});
const mapDispatchToProps = dispatch => ({
    printCert: (certificateId, type) => {
        return dispatch(printCert(certificateId, type));
    },
    downloadCertificate: certificateId => {
        return dispatch(downloadCertificate(certificateId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CertificationsReactTable);
