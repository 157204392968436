// Event Details / Event Add
export const EVENT_GET_EVENT_DETAIL_REQUEST = 'EVENT_ADD_GET_EVENT_DETAIL_REQUEST';
export const EVENT_GET_EVENT_DETAIL_SUCCESS = 'EVENT_ADD_GET_EVENT_DETAIL_SUCCESS';
export const EVENT_GET_EVENT_DETAIL_FAILURE = 'EVENT_ADD_GET_EVENT_DETAIL_FAILURE';

export const EVENT_SAVE_EVENT_DETAIL_REQUEST = 'EVENT_ADD_SAVE_EVENT_DETAIL_REQUEST';
export const EVENT_SAVE_EVENT_DETAIL_SUCCESS = 'EVENT_ADD_SAVE_EVENT_DETAIL_SUCCESS';
export const GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS = 'GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS';
export const EVENT_SAVE_EVENT_DETAIL_FAILURE = 'EVENT_ADD_SAVE_EVENT_DETAIL_FAILURE';

export const EVENT_DELETE_EVENT_DETAIL_REQUEST = 'EVENT_ADD_DELETE_EVENT_DETAIL_REQUEST';
export const EVENT_DELETE_EVENT_DETAIL_SUCCESS = 'EVENT_ADD_DELETE_EVENT_DETAIL_SUCCESS';
export const EVENT_DELETE_EVENT_DETAIL_FAILURE = 'EVENT_ADD_DELETE_EVENT_DETAIL_FAILURE';
export const GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS = 'GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS';

export const EVENT_UPDATE_EVENT_DETAIL_REQUEST = 'EVENT_ADD_UPDATE_EVENT_DETAIL_REQUEST';
export const EVENT_UPDATE_EVENT_DETAIL_SUCCESS = 'EVENT_ADD_UPDATE_EVENT_DETAIL_SUCCESS';
export const EVENT_UPDATE_EVENT_DETAIL_FAILURE = 'EVENT_ADD_UPDATE_EVENT_DETAIL_FAILURE';

// Event get select options
export const EVENT_GET_FOOD_RESTRICT_REQUEST = 'EVENT_ADD_GET_FOOD_RESTRICT_REQUEST';
export const EVENT_GET_FOOD_RESTRICT_SUCCESS = 'EVENT_ADD_GET_FOOD_RESTRICT_SUCCESS';
export const EVENT_GET_FOOD_RESTRICT_FAILURE = 'EVENT_ADD_GET_FOOD_RESTRICT_FAILURE';

export const EVENT_GET_PAYMENT_MODE_REQUEST = 'EVENT_ADD_GET_PAYMENT_MODE_REQUEST';
export const EVENT_GET_PAYMENT_MODE_SUCCESS = 'EVENT_ADD_GET_PAYMENT_MODE_SUCCESS';
export const EVENT_GET_PAYMENT_MODE_FAILURE = 'EVENT_ADD_GET_PAYMENT_MODE_FAILURE';

// Input Fields action
export const EVENT_RESET_EVENT_FIELDS = 'EVENT_ADD_RESET_EVENT_FIELDS';
export const EVENT_CHANGE_EVENT_FIELD_VALUE = 'EVENT_ADD_CHANGE_FIELD_VALUE ';

// File and Image input action
export const EVENT_SET_FILE_INPUT_ID = 'EVENT_SET_FILE_INPUT_ID';
export const EVENT_REMOVE_DOCUMENT_FILE = 'EVENT_REMOVE_DOCUMENT_FILE';
export const EVENT_SET_IMAGE_SIZE = 'EVENT_SET_IMAGE_SIZE';

export const EVENT_SAVE_DOCUMENT_FILE_REQUEST = 'EVENT_SAVE_DOCUMENT_FILE_REQUEST';
export const EVENT_SAVE_DOCUMENT_FILE_SUCCESS = 'EVENT_SAVE_DOCUMENT_FILE_SUCCESS';
export const EVENT_SAVE_DOCUMENT_FILE_FAILURE = 'EVENT_SAVE_DOCUMENT_FILE_FAILURE';

export const EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST = 'EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST';
export const EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS = 'EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS';
export const EVENT_DOWNLOAD_DOCUMENT_FILE_FAILURE = 'EVENT_DOWNLOAD_DOCIMENT_FILE_FAILURE';

// Modal action
export const EVENT_SET_MODAL_DATA = 'EVENT_SET_MODAL_DATA';

// Others
export const EVENT_SET_EVENT_ID = 'EVENT_SET_EVENT_ID';
