import React from 'react';

import { Label, FormGroup, Input, Button, Row, Col } from 'reactstrap';
import InputFieldWithValidation from 'reactstrap-input';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';

// Updated to use DraftJS, due to IE-11 compability issue
import CyderRichText from 'cyder/richtext/CyderRichText';

import '../../../../css/forms/default-forms.css';
import '../../../../css/cyder/widget/page-widget.css';

const formGroupStyle = {
    borderBottom: '1px solid #eee',
    paddingBottom: '8px',
    marginBottom: '20px',
};

export const CheckboxLabel = ({ valueKey, onChange, state }) => (
    <Label check className={`checkbox-label ${!state || state === false ? '' : 'opaque'}`}>
        <Input type="checkbox" id={valueKey} defaultChecked={state} value={valueKey} name={valueKey} onChange={onChange} />
        {/* https://cydersg.atlassian.net/browse/SGBCCRM-1664 */}
        {!state || state === false ? 'Visible' : 'Visible'}
    </Label>
);

const MainComponent = props => {
    return (
        <React.Fragment>
            <FormGroup check>
                <Input
                    type="checkbox"
                    id="confirmRegRequired"
                    name="confirmRegRequired"
                    checked={props.data.confirmRegRequired}
                    onChange={props.handleEventInputChange}
                />
                Require SGBC Confirmation upon Registration
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6}>
                        <Label>Event type</Label>
                        <div style={{ width: '300px' }}>
                            <Input type="select" id="eventTypeId" value={props.data.eventTypeId} onChange={props.handleEventInputChange}>
                                <option value="">---------</option>
                                <option value="1">Public - Open to everyone</option>
                                <option value="2">Members - Open to SGBC Members Only</option>
                                <option value="3">Private - By invitation only</option>
                            </Input>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Label> Is this CPD Event? </Label>
                        <div style={{ width: '300px' }}>
                            <Input type="select" id="isGmpEvent" value={props.data.isGmpEvent} onChange={props.handleEventInputChange}>
                                <option value="0"> No </option>
                                <option value="1"> Yes </option>
                            </Input>
                        </div>
                    </Col>
                </Row>
            </FormGroup>{' '}
            {/*props.eventid && (
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Label>Event Series</Label>
                            <div style={{ width: '500px' }}>
                                <Input type="select" id="seriesId" value={props.data.seriesId} onChange={props.handleEventInputChange}>
                                    <option value="">---------</option>
                                    <option value="1">SGBC Technical Committee Meeting - Architectural</option>
                                    <option value="2">SGBC Technical Committee Meeting - Civil &amp; Structural</option>
                                    <option value="3">SGBC Technical Committee Meeting - Electrical &amp; Smart Systems</option>
                                    <option value="4">SGBC Technical Committee Meeting - Landscape</option>
                                    <option value="5">SGBC Technical Committee Meeting - Mechanical</option>
                                    <option value="6">SGBC Technical Coordination Committee Meeting</option>
                                    <option value="7">Thirstday with SGBC Networking Series</option>
                                    <option value="8">Better Places For People Series</option>
                                    <option value="9">[SGBC Green Mark Clinic Series] </option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                </FormGroup>
            )*/}
            <FormGroup>
                <Row>
                    <Col xs={3}>
                        <InputFieldWithValidation
                            type="text"
                            label="Attendee Limit"
                            value={props.data.attendeeLimit}
                            valueKey="attendeeLimit"
                            validationObj={{}}
                            handleChange={props.handleEventInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6}>
                        <InputFieldWithValidation
                            rows={4}
                            type="textarea"
                            label="Event Name"
                            value={props.data.name}
                            valueKey="name"
                            validationObj={{}}
                            handleChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col xs={6}>
                        {/* <InputFieldWithValidation rows={4} type="textarea" label="Event Venue" value={props.data.venue} valueKey="venue" validationObj={{}} handleChange={props.handleEventInputChange} /> */}
                        <Label>Event Venue</Label>
                        <CyderRichText data={props.data.venue} onChange={val => props.onChangeEventVenue(val)} />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row style={{ paddingBottom: '8px' }}>
                    <Col xs={12}>
                        <Label>Event Summary</Label>
                        <CyderRichText data={props.eventSummary} onChange={val => props.onChangeEventSummary(val)} />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row style={{ paddingBottom: '8px' }}>
                    <Col xs={12}>
                        <Label>Event Description</Label>
                        <CyderRichText data={props.eventDescription} onChange={val => props.onChangeEventDescription(val)} />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Row>
                    <Col xs={6}>
                        <InputFieldWithValidation
                            rows={4}
                            type="textarea"
                            label="Sponsors"
                            valueKey="sponsors"
                            validationObj={{}}
                            value={props.data.sponsors}
                            handleChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col xs={6}>
                        <InputFieldWithValidation
                            rows={4}
                            type="textarea"
                            label="Co-organizers Partners"
                            valueKey="coOrganizersPartners"
                            validationObj={{}}
                            value={props.data.coOrganizersPartners}
                            handleChange={props.handleEventInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Row>
                    <Col>
                        <Label>EDM PDF</Label>
                        <FormGroup>
                            <CyderFileUpload
                                attachmentId={props.edmFileId}
                                filename={props.edmFileName}
                                onUploadSuccess={props.onEDMUploadSuccess}
                                onUploadFail={props.onEDMUploadFail}
                                attachment={'edm'}
                                removeFile={props.removeFile}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <Label>Event Banner Image</Label>
                        <FormGroup>
                            <CyderFileUpload
                                attachmentId={props.eventBannerFileId}
                                filename={props.eventBannerFileName}
                                onUploadSuccess={props.onEventBannerUploadSuccess}
                                onUploadFail={props.onEventBannerUploadFail}
                                attachment={'banner'}
                                removeFile={props.removeFile}
                            />
                        </FormGroup>
                    </Col>
                    {/*<Col>
                        <Label>Registration Page Image</Label>
                        <FormGroup>
                            <CyderFileUpload
                                attachmentId={props.registrationPageImageFileId}
                                filename={props.registrationPageImageFileName}
                                onUploadSuccess={props.onRegistrationPageImageUploadSuccess}
                                onUploadFail={props.onRegistrationPageImageUploadFail}
                                attachment={'registration'}
                                removeFile={props.removeFile}
                            />
                        </FormGroup>
                    </Col>*/}
                </Row>
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Label>Payment Mode</Label>
                {props.selection.paymentMode.map((option, index) => {
                    return (
                        <FormGroup check key={index}>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name={option.id}
                                    id="paymentMode"
                                    checked={
                                        !props.eventid
                                            ? true
                                            : props.data.paymentMode[option.id]
                                            ? props.data.paymentMode[option.id]
                                            : false
                                    }
                                    onChange={props.handleEventInputChange}
                                />
                                {option.mode}
                            </Label>
                        </FormGroup>
                    );
                })}
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Row>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="SGBC-GMAP CPD"
                            valueKey="SGBCCPD"
                            validationObj={{}}
                            value={props.data.SGBCCPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="SGBCCPD_visible"
                            state={props.data.SGBCCPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="PEB PDU"
                            valueKey="PEBCPD"
                            validationObj={{}}
                            value={props.data.PEBCPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="PEBCPD_visible"
                            state={props.data.PEBCPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="SCEM PDU"
                            valueKey="SCECPD"
                            validationObj={{}}
                            value={props.data.SCECPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="SCECPD_visible"
                            state={props.data.SCECPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Row>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="BCA-Registered Energy Auditor CPD"
                            valueKey="BCACPD"
                            validationObj={{}}
                            value={props.data.BCACPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="BCACPD_visible"
                            state={props.data.BCACPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="BOA-SIA CPD"
                            valueKey="BOACPD"
                            validationObj={{}}
                            value={props.data.BOACPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="BOACPD_visible"
                            state={props.data.BOACPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                    <Col>
                        <InputFieldWithValidation
                            type="text"
                            label="SILA CPD"
                            valueKey="SILCPD"
                            validationObj={{}}
                            value={props.data.SILCPD}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel
                            valueKey="SILCPD_visible"
                            state={props.data.SILCPD_visible}
                            onChange={props.handleEventInputChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup style={formGroupStyle}>
                <Row>
                    <Col xs={2}>
                        <InputFieldWithValidation
                            type="text"
                            label="CPD 1"
                            valueKey="CPD1"
                            validationObj={{}}
                            value={props.data.CPD1}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel valueKey="CPD1_visible" state={props.data.CPD1_visible} onChange={props.handleEventInputChange} />
                    </Col>
                    <Col xs={2}>
                        <InputFieldWithValidation
                            type="text"
                            label="CPD 2"
                            valueKey="CPD2"
                            validationObj={{}}
                            value={props.data.CPD2}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel valueKey="CPD2_visible" state={props.data.CPD2_visible} onChange={props.handleEventInputChange} />
                    </Col>
                    <Col xs={2}>
                        <InputFieldWithValidation
                            type="text"
                            label="CPD 3"
                            valueKey="CPD3"
                            validationObj={{}}
                            value={props.data.CPD3}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel valueKey="CPD3_visible" state={props.data.CPD3_visible} onChange={props.handleEventInputChange} />
                    </Col>
                    <Col xs={2}>
                        <InputFieldWithValidation
                            type="text"
                            label="CPD 4"
                            valueKey="CPD4"
                            validationObj={{}}
                            value={props.data.CPD4}
                            handleChange={props.handleEventInputChange}
                        />
                        <CheckboxLabel valueKey="CPD4_visible" state={props.data.CPD4_visible} onChange={props.handleEventInputChange} />
                    </Col>
                </Row>
            </FormGroup>
            <br />
            <div className="btn-toolbar">
                <Row className="mb-2">
                    <Col xs={12} className="d-flex">
                        <Button className="m-1 primary-btn-style" onClick={props.eventid ? props.updateEvent : props.addEvent}>
                            Save Main Details
                        </Button>
                        {props.eventid ? (
                            <Button className="m-1 primary-btn-style" onClick={props.deleteEvent}>
                                Delete Entire Event
                            </Button>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default MainComponent;
