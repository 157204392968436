/**
 * Shared Survey Reducer
 *
 * Author: Fernando Karnagi
 */
// defaults
const defaultState = {
    postSurveyUrl: '',
    postSurveyResultViewUrl: '',
    applicationId: 0,
    surveyAnswers: [],
};

export default function sharedSurveyReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'SHARED_SURVEY_SET_POST_SURVEY_URL':
            newState.postSurveyUrl = action.postSurveyUrl;
            return newState;
        case 'SHARED_SURVEY_SET_POST_SURVEY_RESULT_VIEW_URL':
            newState.postSurveyResultViewUrl = action.postSurveyResultViewUrl;
            return newState;
        case 'SHARED_SURVEY_SET_APPLICATION_ID':
            newState.applicationId = action.applicationId;
            return newState;
        case 'SHARED_SURVEY_SET_SURVEY_ANSWERS':
            newState.surveyAnswers = action.surveyAnswers;
            return newState;
        default:
            return state;
    }
}
