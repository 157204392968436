import React from 'react';
import { connect } from 'react-redux';

import { Row } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';

class BankTransferPayment extends React.Component {
    render() {
        return (
            <Row>
                <div className="col-8">
                    <PaymentInput
                        label="Payment Amount (SGD)"
                        description="Please enter amount of payment"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'amountReceived')}
                        value={this.props.paymentInfo.amountReceived}
                    />
                </div>
                <br />
                <div className="col-12">
                    <label className="field-header">
                        Date of Payment <MandatoryStar />
                    </label>
                    <CyderDatePicker
                        placeholder=""
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}
                        onChange={e => this.props.onDateChanged(e, 'datePaymentReceived', 'datePaymentReceivedCtrl')}
                        value={this.props.paymentInfo.datePaymentReceivedCtrl || ''}
                    />
                    <small className="form-text text-muted">Please select date of payment</small>
                </div>
                <br />
                <div className="col-12">
                    <PaymentInput
                        label="Name of Bank"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'bank')}
                        value={this.props.paymentInfo.bank}
                    />
                </div>
                <div className="col-12">
                    <PaymentInput
                        id="referenceNumber"
                        label="Transaction No. / Cheque No."
                        description="Please enter number of transaction or cheque"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'referenceNumber')}
                        value={this.props.paymentInfo.referenceNumber}
                    />
                </div>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankTransferPayment);
