import {
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANGE,
    MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS,
    MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE,
    MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT,
    MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES,
    MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING,
    MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE,
    MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM,
    MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS,
} from 'actions/constants/actionTypesMemberCompany';
import { setReducerModalState, defaultModalKeys } from 'js/modal';
import { sanitizeStringAndObjectNullsToEmptyStrings } from '../../../js/util';
import history from 'history.js';

// defaults
const defaultState = {
    loading: false,
    isMember: true,
    loginFrom: 'member',
    isNewMember: false,
    form: {
        countries: [],
        businessActivities: [],
        categories: [],
    },
    file: {
        inputId: '',
        downloadingLogo: false,
        savingLogo: false,
        savedLogo: false,
    },
    companyInfo: {
        annualTurnover: 0,
        businessAddressLine1: '',
        businessAddressLine2: '',
        businessAddressLine3: '',
        businessPostalCode: '',
        certificationRepId: null,
        countryId: 0,
        countryName: '',
        facsimile: '',
        generalEmail: '',
        greenBuildingMovementInvolvement: '',
        haveMinimumThirtyPercentLocalShareholding: 0,
        logoLocation: null,
        id: 0,
        mailingAddressLine1: '',
        mailingAddressLine2: '',
        mailingAddressLine3: '',
        mailingPostalCode: '',
        mainPhoneNumber: '',
        name: '',
        numberOfEmployees: '',
        officialManagementRepresentativeId: null,
        organisationMainCategoryId: '',
        organisationName: '',
        productsServicesDescription: '',
        registrationNumber: '',
        secondaryRepId: 0,
        subCategory: {},
        userId: 0,
        website: '',
        isSme: 0,
        organisationProfile: '',
    },
    catSubCat: {},
    mainCatMap: {},
    companyData: {},
    ...defaultModalKeys,
};
export default function memberCompanyReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Get - Company Info
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST:
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS:
            newState = Object.assign({}, state);
            const isMember = newState.isMember;
            const response = isMember
                ? sanitizeStringAndObjectNullsToEmptyStrings(action.response.data)
                : sanitizeStringAndObjectNullsToEmptyStrings(action.response.data.companydata);
            // Code bellow handle empty subCategories
            const subCategories = action.response.data.subCategory;
            // Assign data
            Object.keys(newState.companyInfo).map(key => {
                if (response[key] != null) {
                    newState.companyInfo[key] = response[key];
                }
                // Verify whether logo data exist
                switch (key) {
                    case 'logoLocation':
                        newState.file.savedLogo = response[key].length > 0 ? true : false;
                }
            });
            // Post process subCategory
            let subCategory = {};
            newState.catSubCat = Object.assign({}, state.catSubCat);
            subCategories.map(data => {
                // Construct data
                const sc = {
                    checked: true,
                    mainName: data.mainCategoryName,
                    mainId: data.mainCategoryId,
                    subName: data.subCategoryName,
                    subId: data.organisationSubCategoryId,
                };
                subCategory[sc.subId] = sc;
                // Sort the subCatgory based on mainCategory id
                if (!newState.catSubCat[sc.mainId]) {
                    newState.catSubCat[sc.mainId] = {
                        mainName: sc.mainName,
                        subCats: [sc],
                    };
                } else {
                    newState.catSubCat[sc.mainId].subCats.push(sc);
                }
            });
            newState.companyInfo.subCategory = subCategory;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE:
            return newState;
        // Get - Countries
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST:
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS:
            newState.form.countries = action.response.data;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE:
            return newState;
        // Get - Business Activity
        case MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST:
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS:
            newState.form.businessActivities = action.response.data;
            newState.form.businessActivities.splice(0, 0, {
                id: 0,
                name: 'None',
            });
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE:
            return newState;
        // Get - Categories and SubCategories
        case MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST:
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS:
            newState.form = Object.assign({}, state.form);
            newState.form.categories = action.response.data;
            newState.mainCatMap = Object.assign({}, state.mainCatMap);
            action.response.data.forEach(item => {
                newState.mainCatMap[item.mainCategory.id] = item.mainCategory.name;
            });
            newState.mainCategoriesMap = newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE:
            return newState;
        // Update - Company Info
        case MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS:
            const { loginFrom, isNewMember } = newState;
            const { businessAddressLine1, officialManagementRepresentativeId, organisationMainCategoryId } = newState.companyInfo;
            const hasCompanyInfoFilled =
                businessAddressLine1 && officialManagementRepresentativeId && organisationMainCategoryId ? true : false;

            const modalAction = () => {
                const isFromMembershipMenu = loginFrom === 'membership';
                const url = isFromMembershipMenu ? '/member/membership/newapplication' : '/member/certification/selection';
                if (loginFrom !== '') history.push(url);
                return 'forcePageUpdate';
            };

            // Set modal state
            newState.modalLoading = false;
            newState.modalOnlyOneButton = true;
            newState.modalAction = !hasCompanyInfoFilled || !isNewMember ? 'forcePageUpdate' : modalAction;
            newState.modalBody = 'Information has been successfully saved.';
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE:
            // Set modal state
            newState.modalLoading = false;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to save company info, Please try again.';
            return newState;
        case STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST:
            newState.modalLoading = true;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS:
            // Set modal state
            newState.modalLoading = false;
            newState.modalOnlyOneButton = true;
            newState.modalAction = 'forcePageUpdate';
            newState.modalBody = 'Company Profile successfully saved.';
            return newState;
        case STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE:
            // Set modal state
            newState.modalLoading = false;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to save company info, Please try again.';
            return newState;
        // Save - Attachment
        case MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST:
            newState.file.savingLogo = true;
            newState.file.savedLogo = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS:
            newState.file.savingLogo = false;
            newState.file.savedLogo = true;
            newState.companyInfo.logoLocation = action.response.data.id;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE:
            newState.file.savingLogo = false;
            return newState;
        // Download - Attachment
        case MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST:
            newState.file.downloadingLogo = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS:
            newState.file.downloadingLogo = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE:
            newState.file.downloadingLogo = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT:
            newState.file.savedLogo = false;
            newState.companyInfo.logoLocation = null;
            return newState;
        // Input actions
        case MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANGE:
            const { event, subId, mainId, mainName } = action;
            const { id, name, value, checked, options } = event.target;
            switch (id) {
                case 'country':
                    newState.companyInfo.countryName = options[value - 1].text;
                    newState.companyInfo.countryId = value;
                    break;
                case 'organisationMainCategory':
                    newState.companyInfo.organisationMainCategoryId = value;
                    newState.companyInfo.organisationName = (options[value] && options[value].text) || '';
                    break;
                case 'haveMinimumThirtyPercentLocalShareholding':
                    newState.companyInfo.haveMinimumThirtyPercentLocalShareholding = checked ? 1 : 0;
                    break;
                case 'organisationProfile':
                    newState.companyInfo.organisationProfile = value;
                    break;
                case 'isSme':
                    newState.companyInfo.isSme = value ? parseInt(value) : '';
                    break;
                case 'subCategory':
                    newState.companyInfo.subCategory[subId] = {
                        checked,
                        mainId,
                        mainName,
                        subId,
                        subName: name,
                    };
                    break;
                case 'numberOfEmployees':
                    newState.companyInfo.numberOfEmployees = value;
                    break;
                default:
                    newState.companyInfo[id] = value;
                    break;
            }
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS:
            newState = Object.assign({}, defaultState);
            newState.companyInfo = Object.assign({}, defaultState.companyInfo);
            newState.companyInfo = {
                annualTurnover: 0,
                businessAddressLine1: '',
                businessAddressLine2: '',
                businessAddressLine3: '',
                businessPostalCode: '',
                certificationRepId: null,
                countryId: 0,
                countryName: '',
                facsimile: '',
                generalEmail: '',
                greenBuildingMovementInvolvement: '',
                haveMinimumThirtyPercentLocalShareholding: 0,
                logoLocation: null,
                id: 0,
                mailingAddressLine1: '',
                mailingAddressLine2: '',
                mailingAddressLine3: '',
                mailingPostalCode: '',
                mainPhoneNumber: '',
                name: '',
                numberOfEmployees: '',
                officialManagementRepresentativeId: null,
                organisationMainCategoryId: '',
                organisationName: '',
                productsServicesDescription: '',
                registrationNumber: '',
                secondaryRepId: 0,
                subCategory: {},
                userId: 0,
                website: '',
                isSme: '',
                organisationProfile: '',
            };
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES:
            newState.companyInfo.mailingAddressLine1 = newState.companyInfo.businessAddressLine1;
            newState.companyInfo.mailingAddressLine2 = newState.companyInfo.businessAddressLine2;
            newState.companyInfo.mailingAddressLine3 = newState.companyInfo.businessAddressLine3;
            newState.companyInfo.mailingPostalCode = newState.companyInfo.businessPostalCode;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING:
            newState.loading = action.load;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE:
            newState.isMember = action.isMember;
            newState.companyId = action.companyId;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM:
            newState.loginFrom = action.loginFrom;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS:
            newState.isNewMember = action.status === 'New';
            return newState;
        default:
            return newState;
    }
}
