/**
 * Security action creators
 *
 * Author: Fernando
 */

import {
    CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT,
    CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE,
    CREATE_ACCOUNT_CORPORATE_FAILURE,
    CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE,
    CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY,
    CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE,
    CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST,
    CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS,
    CREATE_ACCOUNT_CORPORATE_REQUEST,
    CREATE_ACCOUNT_CORPORATE_RESET_FIELDS,
    CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY,
    CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA,
    CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY,
    CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY,
    CREATE_ACCOUNT_CORPORATE_SUCCESS,
} from '../../actions/constants/actionTypesAccount';
import cyderlib from '../../js/cyderlib';
import { defaultModalKeys, setReducerModalState } from '../../js/modal';

var defaultState = {
    fields: {
        country: '200',
        email: '',
        companyname: '',
        uen_regno: '',
        password: '',
        confirmpassword: '',
        companyId: '',
        firstname: '',
        lastname: '',
    },
    noCompany: false,
    validation: {},
    loading: false,
    previewOk: false,
    errorMessage: null,
    timeout: 3,
    ...defaultModalKeys,
};

var defaultCountryCode = 200;

export function createAccountReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    // let stateResult;
    switch (action.type) {
        case CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY:
            newState.fields = Object.assign({}, state.fields);
            newState.fields.companyname = action.value.companyname;
            newState.fields.uen_regno = action.value.uen_regno;
            newState.fields.country_id = action.value.country_id;
            newState.fields.company_id = action.value.company_id;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_RESET_FIELDS:
            newState.fields = Object.assign(defaultState.fields, {
                country: defaultCountryCode,
            });
            newState.loading = false;
            newState.previewOk = false;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY:
            newState.fields = Object.assign({}, state.fields);
            newState.fields.country = action.countryId || defaultCountryCode;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE:
            newState.fields = Object.assign({}, state.fields);
            newState.fields[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);

            // Reset confirmpassword if password field changed
            if (action.key === 'password') newState.fields['confirmpassword'] = '';

            // Set Rule for password
            let pw = null;
            if (action.key === 'confirmpassword') pw = state.fields['password'];

            // Store validation result to newState.validation
            newState.validation[action.key] = cyderlib.validate(action.key, action.value, pw);
            return newState;
        case CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT:
            newState.timeout = state.timeout + action.value;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY:
            newState.noCompany = action.value;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS:
            newState.loading = false;
            newState.previewOk = true;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE:
            newState.loading = false;
            newState.previewOk = false;
            newState.errorMessage = action.error.errormessage;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_SUCCESS:
            newState.loading = false;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE:
            // GENERATE VALIDATION OBJECT
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.fields) {
                let pw = null;
                if (k === 'confirmpassword') pw = state.fields['password'];
                if (!k === 'country') newState.validation[k] = cyderlib.validate(k, state.fields[k], pw);
            }
            newState.saveButtonPressed = true;
            return newState;
        case CREATE_ACCOUNT_CORPORATE_RESET_FIELDS:
            newState = Object.assign({}, defaultState);
            return newState;
        case CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY:
            newState.previewOk = action.bool;
            return newState;
        default:
            return state;
    }
}
