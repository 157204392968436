import React from 'react';
import { connect } from 'react-redux';

import '../../../css/cyder/media/icon.css';
import '../../../css/icons/material-design-icons.css';
import '../../../css/icons/font-awesome.css';

class Memberships extends React.Component {
    render() {
        return (
            <div className="user-widget-1">
                <div className="user-data">
                    <div className="row">
                        <div className="col">
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-success">
                                    card_membership
                                </i>
                                {this.props.memberships.active} Active Members
                            </p>
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-danger">
                                    airplanemode_inactive
                                </i>
                                {this.props.memberships.expired6Months} Expiring
                                in 6 months
                            </p>
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-info">
                                    title
                                </i>
                                {this.props.memberships.total} Total
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        memberships: state.staff.dashboardInfo.memberships,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Memberships);
