import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Link } from 'react-router-dom';
import history from '../../../../history';

import { Row, Col, Form, Button, Label } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import InputField from 'pages/admin/companies/shared/InputField';

import { save, update, remove, load, getAllCategories } from 'actions/admin/companies/organisation-sub-category/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.mainCategoryIdChange = this.mainCategoryIdChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            data: {
                mainCategoryId: '',
                name: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            categories: [],
            category: {},
        };
    }
    onCategoryChange(e) {
        var data = this.state.data;
        data.mainCategoryId = e.value;
        this.setState({ data });
        this.setState({
            category: e,
        });
    }

    back() {
        history.replace('/admin/companies/organisation-sub-category');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);

        this.props
            .getAllCategories()
            .then(categories => {
                var categoryToAdd = [];
                categories.data.forEach(category => {
                    categoryToAdd.push({
                        value: category.id,
                        label: category.name,
                    });
                });
                this.setState({
                    categories: categoryToAdd,
                });

                var id = null;
                try {
                    id = this.props.match.params.id;
                } catch (e) {}
                if (id != null && id > 0) {
                    this.props
                        .load(id)
                        .then(res => {
                            this.showLoading(false);
                            if (res.error || res.data == null || res.data.id == null) {
                                this.showModal('Error', res.message, 'load', true, true);
                            } else {
                                var dataToSet = Object.assign({}, res.data);

                                categories.data.forEach(category => {
                                    if (category.id === dataToSet.mainCategoryId) {
                                        this.setState({
                                            category: {
                                                label: category.name,
                                                value: category.id,
                                            },
                                        });
                                    }
                                });

                                this.setState({
                                    data: dataToSet,
                                });
                            }
                        })
                        .catch(error => {
                            this.showLoading(false);
                            this.showModal('Error', error.message, 'load', true, true);
                        });
                } else {
                    this.setState({
                        data: {
                            mainCategoryId: '',

                            name: '',
                        },
                    });
                    this.showLoading(false);
                }
            })
            .catch(error => {});
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/companies/organisation-sub-category');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/companies/organisation-sub-category', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/companies/organisation-sub-category');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    mainCategoryIdChange(e) {
        var data = this.state.data;
        data.mainCategoryId = e.target.value;
        this.setState({ data });
    }
    nameChange(e) {
        var data = this.state.data;
        data.name = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <InputField label="Main Category">
                        <Select
                            style={{ width: '600px' }}
                            autosize={false}
                            options={this.state.categories}
                            value={this.state.category}
                            clearable={true}
                            onChange={this.onCategoryChange}
                            isMulti={false}
                            name="selected-state"
                            disabled={false}
                            searchable={true}
                        />
                        <Label>
                            <small>Select category</small>
                        </Label>
                    </InputField>
                    <InputField
                        label="Sub Category"
                        instruction="Requires alphanumeric"
                        inputProps={{
                            type: 'text',
                            id: 'name',
                            value: this.state.data.name,
                            onChange: this.nameChange,
                            style: { width: '600px' },
                        }}
                    />
                    <hr />
                    <FlexRow between="xs">
                        <div>
                            <Button className="text-bold primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </div>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2} className="d-flex justify-content-end">
                                <Button className="text-bold primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                                <AuditListPopup contentTypeId={104} objectId={this.state.data.id} />
                            </FlexCol>
                        ) : null}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getAllCategories: () => {
            return dispatch(getAllCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
