/**
 * Member product application reducer
 *
 * Author: Fernando Karnagi
 */
import {
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING,
    MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG,
    // MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO,
    // MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO,
    // MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE,
} from '../../../actions/constants/actionTypesMemberCertification';

const defaultState = {
    preview: {
        submitConfirmationVisible: false,
    },
    view: {
        applicationNo: '12345',
        finalRating: '4',
        company: 'Facebook Inc.',
        status: '',
        takeUpCaseStarted: false,
        productCatalogueResourceId: '',
        productCatalogueFilename: '',
        assignApproverStarted: false,
        assignNextApproverStarted: false,
        submittedOn: 'June 1, 2018, 7:53 p.m.',
        step: 0,
        invoiceId: 0,
    },
    new: {
        saveConfirmationVisible: false,
        addModelFormVisible: false,
        addBrandFormVisible: false,
        productCatalogueResourceId: '',
        productCatalogueFilename: '',
        productPictureResourceId: '',
        productPictureFilename: '',
        currentTabNo: 0,
        activeTabs: [true, false, false, false, false],
        selectedProductCategory: '',
        productCategories: [],
        productTypes: [],
        selectedManufacturerOption: '',
        manufacturerOptions: [
            {
                value: 'Yes',
                label: 'Yes',
            },
            {
                value: 'No',
                label: 'No',
            },
        ],
        productBrands: [],
    },
    categories: [],
    loading: false,
    savingProdCatalogue: false,
    selectedProductCat: null,
    selectedProductType: null,
    selectedManufacturerOption: null,
    uploadedFileId: null,
    savedThisSession: false,
    manufacturerDetails: '',
    savingApplChanges: false,
    productTypes: [],
    forceLoading: false,
    downloadingProductCategoryFile: false,
    savedProdCatalogue: false,
    error: false,
    errorMessage: null,
};

export function memberProductApplicationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBER_PRODUCT_SET_TAKE_UP_CASE_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.takeUpCaseStarted = action.takeUpCaseStarted;
            return newState;
        case 'MEMBER_PRODUCT_SET_ASSIGN_APPROVER_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.assignApproverStarted = action.assignApproverStarted;
            return newState;
        case 'MEMBER_PRODUCT_SET_ASSIGN_NEXT_APPROVER_STARTED':
            newState.view = Object.assign({}, state.view);
            newState.view.assignNextApproverStarted = action.assignNextApproverStarted;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS:
            newState.categories = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS:
            newState.categories = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS:
            newState.categories = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS:
            newState.productTypes = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST:
            newState.savingProdCatalogue = true;
            newState.savedProdCatalogue = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS:
            newState.savedProdCatalogue = true;
            newState.savingProdCatalogue = false;
            newState.uploadedFileId = action.response.data.id;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE:
            newState.savingProdCatalogue = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST:
            newState.downloadingProductCategoryFile = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS:
            newState.downloadingProductCategoryFile = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE:
            newState.downloadingProductCategoryFile = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID:
            newState.savedProdCatalogue = false;
            newState.uploadedFileId = null;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST:
            newState.error = false;
            newState.savingApplChanges = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS:
            newState.savingApplChanges = false;
            newState.savedThisSession = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE:
            newState.savingApplChanges = false;
            newState.error = true;
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS:
            newState.loading = true;
            newState.savedThisSession = true;
            newState.new = Object.assign({}, state.new);
            newState.new.productCatalogueResourceId = action.response.data.productCatalogue || action.response.data.serviceCatalogue;
            newState.new.productCatalogueFilename =
                action.response.data.productCatalogueFilename || action.response.data.serviceCatalogueFilename;

            newState.new.productPictureResourceId = action.response.data.productPicture || action.response.data.servicePicture;
            newState.new.productPictureFilename =
                action.response.data.productPictureFilename || action.response.data.servicePictureFilename;
            newState.new.productCategory = action.response.data.productCategoryValue;
            newState.new.templateId = action.response.data.templateId;
            newState.new.scoresheetId = action.response.data.scoresheetId;
            newState.new.invoiceId = action.response.data.invoiceId;
            newState.new.paymentId = action.response.data.paymentId;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING:
            newState.forceLoading = action.loadingState;
            return newState;
        case MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG:
            newState.savedThisSession = action.savedThisSession;
            return newState;
        case 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_CATALOGUE':
            newState.new = Object.assign({}, state.new);
            newState.new.productCatalogueResourceId = action.resourceId;
            newState.new.productCatalogueFilename = action.filename;
            return newState;
        case 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_PICTURE':
            newState.new = Object.assign({}, state.new);
            newState.new.productPictureResourceId = action.resourceId;
            newState.new.productPictureFilename = action.filename;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        default:
            return newState;
    }
}
