import React from 'react';
import moment from 'moment';
import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';

export const REGISTERED_ATTENDANTS_COLUMNS = [
    {
        Header: 'Designation',
        accessor: 'designation',
        headerStyle: { whiteSpace: 'unset' },
        maxWidth: 100,
        style: { whiteSpace: 'unset' },
    },
    {
        Header: 'Name',
        accessor: 'name',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
    },
    {
        Header: 'Email',
        accessor: 'email',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
    },
    {
        Header: 'Track',
        accessor: 'trackName',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
        minWidth: 100,
    },
    {
        Header: 'Confirmed',
        accessor: 'isConfirmed',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
        maxWidth: 100,
        Cell: props => (props.value === 1 ? `Yes` : `No`),
    },
    {
        Header: 'Waiting List',
        accessor: 'isWaitingList',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
        maxWidth: 100,
        Cell: props => (props.value === 1 ? `Yes` : `No`),
    },
    {
        Header: 'Attended',
        accessor: 'attended',
        headerStyle: { whiteSpace: 'unset' },
        style: { whiteSpace: 'unset' },
        maxWidth: 100,
        Cell: props => (props.value === 1 ? `Yes` : `No`),
    },
];
