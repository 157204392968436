import React from 'react';
import { Link } from 'react-router-dom';
import { SGBC_S3_THEMES_ROOT } from 'config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isIE } from 'react-device-detect';
import { Button, Container, Col, Row, Jumbotron, Card, CardImg, CardBody, CardText } from 'reactstrap';
import { Transition, animated, config } from 'react-spring';
import AffiliatesPanel from './components/AffiliatesPanel';
import AboutPanel from './components/AboutPanel';
import TopNavigation2 from '../../layouts/TopNavigation2';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';

class DirectoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
        this.state = { value: 0, toggle: {} };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getRelevantData();
    }
    handleToggle(i, state) {
        let toggle = Object.assign(this.state.toggle, {});
        if (state) {
            toggle[i] = state;
        } else {
            toggle[i] = !this.state.toggle;
        }
        this.setState({ toggle });
    }
    render() {
        const { history } = this.props;

        return this.props.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <AboutPanel />
                <MembershipTypesPanel
                    history={history}
                    statevalue={this.state.value}
                    handleToggle={this.handleToggle}
                    toggle={this.state.toggle}
                />
                <AffiliatesPanel />
            </div>
        );
    }
}

const urlPrefix = '/public/directory';

const cards = [
    {
        count: 450,
        text: 'SGBC Member Directory',
        url: '/public/memberdirectory',
        imageurl: `/assets/images/members_directory.png`,
    },
    {
        count: 750,
        text: 'Singapore Green Building Product (SGBP) Directory',
        url: `/public/product`,
        imageurl: `/assets/images/product_directory.png`,
    },
    {
        count: 150,
        text: 'Singapore Green Building Services (SGBS) Directory',
        url: `/public/service`,
        imageurl: `/assets/images/service_directory.png`,
    },
];
const MembershipTypesPanel = ({ toggle, handleToggle, history }) => (
    <Container className="sgbc-newtheme homepanel">
        <Row className="directory">
            {cards.map((c, i) => (
                <Col key={i} xs={12} md={4} className="p-3 mb-2">
                    <Card className="text-center" onClick={() => history.push(c.url)}>
                        <CardImg top width="100%" src={c.imageurl} style={{ maxHeight: 'unset' }} />
                        <CardBody className="d-flex justify-content-center align-items-center p-0">
                            <CardText>{c.text}</CardText>
                        </CardBody>
                    </Card>

                    {/* <Card className="text-center" style={{ height: 340 }}>
                        <div
                            onMouseLeave={() => handleToggle(i, true)}
                            style={{
                                height: '250px',
                                width: '100%',
                                clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)',
                            }}
                        >
                            <div
                                className="d-flex flex-row justify-content-center"
                                style={{
                                    position: 'absolute',
                                    height: '250px',
                                    width: '100%',
                                }}
                            >
                                <div className="d-flex flex-column justify-content-center">
                                    <Link to={c.url}>
                                        <Button className="primary-btn-style">View Directory</Button>
                                    </Link>
                                </div>
                            </div>
                            {isIE && (
                                <CardImg
                                    top
                                    src={c.imageurl}
                                    alt="Card image cap"
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                />
                            )}
                            {!isIE && (
                                <Transition
                                    native
                                    config={config.stiff}
                                    from={{
                                        transform: 'translate3d(100%,0,0)',
                                    }}
                                    enter={{
                                        transform: 'translate3d(0%,0,0)',
                                    }}
                                    leave={{
                                        transform: 'translate3d(100%,0,0)',
                                    }}
                                >
                                    {toggle[i] !== false &&
                                        (styles => (
                                            <animated.div
                                                style={Object.assign(styles, {
                                                    height: '250px',
                                                    position: 'absolute',
                                                    overflow: 'hidden',
                                                })}
                                                onMouseOver={() => handleToggle(i, false)}
                                            >
                                                <CardImg
                                                    top
                                                    src={c.imageurl}
                                                    alt="Card image cap"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        borderRadius: '5px 5px 0 0',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            </animated.div>
                                        ))}
                                </Transition>
                            )}
                        </div>
                        <CardBody>
                            <h4 className="text-info">
                                <strong>{c.text}</strong>
                            </h4>
                        </CardBody>
                    </Card> */}
                </Col>
            ))}
        </Row>
    </Container>
);
const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        // TODO: put imported actions here
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(TopNavigation2(DirectoryPage)));
