import React, { Fragment } from 'react';

import Select from 'react-select';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import {
    DetailsPanelInput,
    PreviewField,
    PanelHeader,
} from 'pages/member/certification/applications/product/components/ApplicationDetailsPanel';
import { selectStyle } from 'pages/member/certification/applications/product/components/ManufacturerDetailsPanel';

const yesNoOptions = [{ value: '', label: 'Please Select' }, { value: 1, label: 'Yes' }, { value: 0, label: 'No' }];

const ServiceDetailsPanelV2 = ({
    editable,
    isSelfFinancingCapableSelected,
    selectedYesNoOption,
    visible,
    serviceCategory,
    selectedCategoryValue,
    children,
    serviceCategoryId,
}) => {
    const isEPC = serviceCategoryId === 2 || serviceCategoryId === 10;
    const isSelfFinancing = selectedYesNoOption !== '' ? new Number(selectedYesNoOption).valueOf() : '';
    const isSelfFinancingLabel = yesNoOptions.find(x => x.value === isSelfFinancing);
    return (
        visible && (
            <Fragment>
                <PanelHeader header="2. Services Details" />
                {isEPC ? (
                    <Row className="pt-2 pb-4">
                        <Col xs="6">
                            <DetailsPanelInput
                                mandatory
                                header="Are you able to offer Zero Capital Partnership (ZCP) Scheme?"
                                editable={editable}
                                value={selectedYesNoOption ? 'Yes' : 'No'}
                            >
                                <Col xs="4" className="p-0">
                                    <Select
                                        autofocus
                                        id="isSelfFinancingCapable"
                                        options={yesNoOptions}
                                        onChange={value => isSelfFinancingCapableSelected(value)}
                                        styles={selectStyle}
                                        disabled={!editable}
                                        value={{
                                            value: isSelfFinancing,
                                            label: isSelfFinancingLabel.label,
                                        }}
                                    />
                                </Col>
                            </DetailsPanelInput>
                        </Col>
                    </Row>
                ) : null}
                <Row className="mb-6">
                    <Col>
                        <PreviewField editable={false} header="Services Category" label={serviceCategory || 'None'} />
                    </Col>
                </Row>
                {children}
            </Fragment>
        )
    );
};

export default ServiceDetailsPanelV2;
