import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import NoShadowPanel from '../../../../../cyder/panels/NoShadowPanel';
import MandatoryStar from '../../../../../cyder/forms/MandatoryStar';

import PaymentChoicesPanel from '../../payment/components/PaymentChoicesPanel';

import '../../../../../css/cyder/form/common-form.css';

import {
    membershipPaymentSetMode,
    membershipPaymentNext,
} from '../../../../../actions/member/membership/application/memberNewMembershipApplicationAction';

const PAYMENT_MODES = [
    {
        value: 'Credit Card',
        label: 'Credit Card',
    },
    {
        value: 'Bank Transfer',
        label: 'Bank Transfer',
    },
    {
        value: 'Cheque',
        label: 'Cheque',
    },
];

class PaymentPanel extends React.Component {
    constructor() {
        super();
        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
        };
    }

    render() {
        return (
            <NoShadowPanel title="Membership Payment" description="Lorem ipsum dolor sit amet">
                {!this.props.payment.start && (
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="form-group">
                                <label className="field-header">
                                    Payment Mode <MandatoryStar />
                                </label>
                                <Select
                                    autosize={false}
                                    placeholder=""
                                    autofocus
                                    options={PAYMENT_MODES}
                                    simpleValue
                                    clearable={this.state.clearable}
                                    name="selected-state"
                                    disabled={this.state.disabled}
                                    value={this.props.payment.mode}
                                    onChange={this.props.setPaymentMode}
                                    searchable={this.state.searchable}
                                />

                                <small className="form-text text-muted">Please choose payment mode</small>
                            </div>
                        </div>
                    </div>
                )}

                {this.props.payment.start && <PaymentChoicesPanel />}

                {!this.props.payment.start && (
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <button className="btn btn-primary" onClick={this.props.next}>
                                Next
                            </button>
                        </div>
                    </div>
                )}

                {this.props.payment.start && (
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <button className="btn btn-primary" onClick={this.props.submit}>
                                Submit
                            </button>
                            &nbsp;
                            <button className="btn btn-warning" onClick={this.props.cancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </NoShadowPanel>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        payment: state.memberNewMembershipApplication.payment,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setPaymentMode: mode => {
            dispatch(membershipPaymentSetMode(mode));
        },
        next: () => {
            dispatch(membershipPaymentNext());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentPanel);
