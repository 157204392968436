import React from 'react';
import history from '../../../../history';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FormGroup, Input, Row, Col, Form, Button, Label } from 'reactstrap';
import InputComponent from 'reactstrap-input';

// Updated to use DraftJS, due to IE-11 compability issue
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CyderRichText from 'cyder/richtext/CyderRichText';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import RatingsComponent from './components/RatingsComponent';
import { validateFromValidationObject } from 'js/validation';

import {
    getCriteriaSectionList,
    getCriteriaDetails,
    onChangeValue,
    onChangeRating,
    removeRating,
    addRating,
    createUpdate,
    resetState,
    forceValidateFields,
    deleteCriteria,
    setModalData,
    getTemplatesByCriteriaCode,
    inactivate,
    activate,
} from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaAddAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import { propGenerator } from 'js/util';

class StaffCertificationAssessmentCriteriaAddPage extends React.Component {
    componentWillUnmount() {
        // reset state for use in list page
        this.props.resetState();

        this.inactivate = this.inactivate.bind(this);
        this.activate = this.activate.bind(this);
        this.state = {
            loading: false,
        };
    }
    async inactivate() {
        let res = await this.props.inactivate(this.props.match.params.id);
        history.push(`/staff/certification/assessment/requirement/`);
    }
    async activate() {
        let res = await this.props.activate(this.props.match.params.id);
        history.push(`/staff/certification/assessment/requirement/`);
    }
    componentDidUpdate() {
        if (this.documentsToSubmitEditor != null) {
            this.documentsToSubmitEditor.isReadOnly =
                this.state.templates != null && this.state.templates.data != null && this.state.templates.data.length > 0;
        }
        if (this.criteriaEditor != null) {
            this.criteriaEditor.isReadOnly =
                this.state.templates != null && this.state.templates.data != null && this.state.templates.data.length > 0;
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setCriteriaType(this.props.type);
        this.props.resetState();

        if (this.props.match.params.id) {
            this.props.getData(this.props.match.params.id).then(res => {
                if (res.data) {
                    this.props.setCriteriaType(res.data.type);
                    this.props.getCriteriaSectionList(res.data.type);

                    this.props
                        .getTemplatesByCriteriaCode(res.data.criteriaCode)
                        .then(templates => {
                            this.setState({
                                templates,
                            });
                        })
                        .catch(error => {
                            throw error;
                        });
                }
            });
        } else {
            this.props.getCriteriaSectionList(this.props.type);
        }
    }
    constructor() {
        super();
        this.toggleModalDelete = this.toggleModalDelete.bind(this);
        this.forceValidate = this.forceValidate.bind(this);
        this.validateBeforeToggleModal = this.validateBeforeToggleModal.bind(this);
        this.onChangeRating = this.onChangeRating.bind(this);
        this.generateProps = this.generateProps.bind(this);
        this.state = {
            disabled: false,
            searchable: true,
            clearable: true,
            ratingError: false,
        };
    }

    handleChangeRequirement = requirement => {
        this.props.onChangeKeyValue('criteria', requirement);
        // this.setState({ requirement });
    };

    handleChangeDocuments = documents => {
        this.props.onChangeKeyValue('documentsToSubmit', documents);
        // this.setState({ documents });
    };

    generateProps(valueKey) {
        return propGenerator({
            valueKey,
            fields: this.props.fields,
            validationObj: this.props.validation,
            handleChange: this.props.onChangeValue,
            saveButtonPressed: this.props.saveButtonPressed,
        });
    }
    onChangeRating(e) {
        const dataset = Object.assign({}, e.target.dataset);
        let rating = {
            code: dataset.code,
            description: dataset.description,
            sortkey: dataset.sortkey,
        };
        rating[dataset.key] = e.target.value;
        const ratingIndex = dataset.ratingIndex;
        this.props.onChangeRating(rating, ratingIndex);
    }
    forceValidate() {
        /**
         * force validation to happen
         *
         * this has to be synchronous as it goes through redux, validates in
         * the reducer, and dispatches actions to modify state
         *
         * f() only happens within the component and really doesn't have to be
         * synchronous though
         *
         */
        const f = () => {
            const ratings = this.props.fields.ratings;
            for (let i = 0; i < ratings.length; i += 1) {
                if (ratings[i].code.toString().trim() === '' || ratings[i].description.toString().trim() === '') {
                    return Promise.resolve({ result: false });
                }
            }
            return Promise.resolve({ result: true });
        };
        var arrayOfValidateResults = [this.props.forceValidateFields()];
        if (
            (this.props.criteriaType === 'service' && this.props.fields.criteriaSectionId === 5) ||
            (this.props.criteriaType === 'product' && this.props.fields.criteriaSectionId === 2)
        ) {
            arrayOfValidateResults.push(f());
        } else {
            // No rating means no need to validate
            arrayOfValidateResults.push(Promise.resolve({ result: true }));
        }
        return Promise.all(arrayOfValidateResults);
    }
    validateBeforeToggleModal() {
        // validates the fields, if no error is found, open modal
        return this.forceValidate().then(res => {
            // sets the state based on the result of validating ratings
            this.setState({ ratingError: !res[1].result });
            // marks several fields are required so that they will be checked with validateFromValidationObject()
            const requiredObj = {
                criteriaCode: true,
                criteria: true,
                documentsToSubmit: true,
            };
            const valid = validateFromValidationObject(this.props.validation, requiredObj);
            if (valid === true && res[1].result === true) {
                // only show the dialog box if validation is passed
                return this.toggleModalCreateUpdate();
            }
            /**
             * If validation fails, do nothing. Note that validation messages
             * are handled with reactstrap-input
             *
             * SaveButtonPressed controls whether reactstrap-input validates
             * input or not. It can be set to false during initialization, and
             * only changed to true during forceUpdate, so that validation
             * does not happen immediately. Or, pass it as true to
             * InputComponents to enable validation straightaway
             */
            return Promise.resolve();
        });
    }
    toggleModalCreateUpdate() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.createUpdate(this.props.type),
            modalBody: 'Are you sure you want to save this requirement?',
        });
    }
    toggleModalDelete() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.deleteCriteria(),
            modalBody: 'Are you sure you want to delete this requirement?',
        });
    }
    render() {
        const templatesPanel = templates => {
            if (templates == null || templates.data == null || templates.data.length == 0) return '';
            return (
                <React.Fragment>
                    <Label>Template(s) that use this requirement</Label>
                    {templates != null &&
                        templates.data != null &&
                        templates.data.length > 0 &&
                        templates.data.map((template, i) => {
                            return (
                                <div id={i} key={i}>
                                    <a
                                        href="#"
                                        style={{ cursor: 'pointer' }}
                                        id={i}
                                        onClick={e => {
                                            history.push(
                                                `/staff/certification/assessment/scoresheet-criteria/new/${template.type}/${template.id}`,
                                            );
                                        }}
                                    >
                                        {template.title}
                                    </a>
                                </div>
                            );
                        })}
                </React.Fragment>
            );
        };
        if (this.props.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                <Row className="mb-4">
                    <Col xs={12}>
                        <Link to="/staff/certification/assessment/requirement">
                            <Button className="primary-btn-style-outline">
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                &nbsp; Back
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form>
                            <FormGroup>
                                <Label>Section</Label>
                                <Input
                                    onChange={this.props.onChangeValue}
                                    value={this.props.fields.criteriaSectionId}
                                    id="criteriaSectionId"
                                    type="select"
                                    disabled={this.props.fields.id != null}
                                >
                                    {this.props.sections.map((item, i) => (
                                        <option value={item.id} key={i}>
                                            {item.section}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <InputComponent
                                inputProps={this.props.fields.id && { disabled: true }}
                                label="Code"
                                {...this.generateProps('criteriaCode')}
                            />
                            <FormGroup>
                                <Label>Requirement</Label>
                                <CyderRichText
                                    data={this.props.fields.criteria}
                                    onChange={val => this.handleChangeRequirement(val)}
                                    disabled={
                                        this.state.templates != null &&
                                        this.state.templates.data != null &&
                                        this.state.templates.data.length > 0
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Documents to Submit</Label>
                                <CyderRichText
                                    data={this.props.fields.documentsToSubmit}
                                    onChange={val => this.handleChangeDocuments(val)}
                                    disabled={
                                        this.state.templates != null &&
                                        this.state.templates.data != null &&
                                        this.state.templates.data.length > 0
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Status</Label>
                                <br />
                                {this.props.fields.active === 1 ? `Active` : `Inactive`}
                            </FormGroup>
                            <FormGroup>{templatesPanel(this.state.templates)}</FormGroup>
                        </Form>
                    </Col>
                </Row>
                {((this.props.criteriaType === 'product' && this.props.fields.criteriaSectionId == 2) ||
                    (this.props.criteriaType === 'service' && this.props.fields.criteriaSectionId == 5)) && (
                    <RatingsComponent
                        generateProps={this.generateProps}
                        ratingCode={this.props.fields.ratingCode}
                        ratingDescription={this.props.fields.ratingDescription}
                        ratingSortkey={this.props.fields.ratingSortkey}
                        ratings={this.props.fields.ratings}
                        onChangeRating={this.onChangeRating}
                        removeRating={this.props.removeRating}
                        addRating={this.props.addRating}
                        readOnly={this.state.templates != null && this.state.templates.data != null && this.state.templates.data.length > 0}
                        ratingError={this.state.ratingError}
                    />
                )}
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12} className="d-flex">
                        {!(this.state.templates != null && this.state.templates.data != null && this.state.templates.data.length > 0) && (
                            <Button className="primary-btn-style" onClick={this.validateBeforeToggleModal}>
                                <i className="mr-1 material-icons align-text-bottom ">add</i>
                                Save Requirement
                            </Button>
                        )}
                        {this.props.fields.id && (
                            <div className="ml-auto">
                                {this.props.fields.active == 1 && (
                                    <Button
                                        style={{ marginRight: '5px' }}
                                        className="primary-btn-style"
                                        onClick={() => {
                                            this.inactivate();
                                        }}
                                    >
                                        <i className="mr-1 material-icons align-text-bottom ">alarm_off</i>
                                        Inactivate
                                    </Button>
                                )}
                                {this.props.fields.active == 0 && (
                                    <Button
                                        style={{ marginRight: '5px' }}
                                        className="primary-btn-style"
                                        onClick={() => {
                                            this.activate();
                                        }}
                                    >
                                        <i className="mr-1 material-icons align-text-bottom ">alarm_on</i>
                                        Activate
                                    </Button>
                                )}

                                {!(
                                    this.state.templates != null &&
                                    this.state.templates.data != null &&
                                    this.state.templates.data.length > 0
                                ) && (
                                    <Button className="primary-btn-style-outline" onClick={this.toggleModalDelete}>
                                        <i className="mr-1 material-icons align-text-bottom ">delete</i>
                                        Delete Requirement
                                    </Button>
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffCertificationAssessmentCriteriaAddReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCriteriaSectionList: type => dispatch(getCriteriaSectionList(type)),
        getData: id => dispatch(getCriteriaDetails(id)),
        onChangeValue: e => {
            const key = e.target.id;
            const value = e.target.value;
            dispatch(onChangeValue(key, value));
        },
        setCriteriaType: criteriaType => {
            return dispatch({
                type: 'STAFF_CERT_ASSESS_CRITERIA_SET_TYPE',
                criteriaType,
            });
        },
        onChangeKeyValue: (key, value) => {
            dispatch(onChangeValue(key, value));
        },
        getTemplatesByCriteriaCode: criteriaCode => {
            return dispatch(getTemplatesByCriteriaCode(criteriaCode));
        },
        onChangeRating: (rating, ratingIndex) => dispatch(onChangeRating(rating, ratingIndex)),
        removeRating: index => dispatch(removeRating(index)),
        addRating: () => dispatch(addRating()),
        createUpdate: type => dispatch(createUpdate(type)),
        resetState: () => dispatch(resetState()),
        dialogModalToggle: () => dispatch(dialogModalToggle()),
        forceValidateFields: () => dispatch(forceValidateFields()),
        deleteCriteria: () => dispatch(deleteCriteria()),
        setModalData: data => dispatch(setModalData(data)),
        inactivate: id => {
            return dispatch(inactivate(id));
        },
        activate: id => {
            return dispatch(activate(id));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StaffCertificationAssessmentCriteriaAddPage),
);
