import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import EventList from './EventList';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
class EventListPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.selectEvent = this.selectEvent.bind(this);

        this.state = {
            modal: false,
        };
    }

    selectEvent(eventName) {
        this.selectEvent(eventName);
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    componentDidMount() {}

    hide() {
        this.setState({
            modal: false,
        });
    }

    show(year, selectEvent) {
        this.setState({
            year,
            modal: true,
        });
        this.selectEvent = selectEvent;
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Events List</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <EventList year={this.state.year} selectEvent={this.selectEvent} />
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default EventListPopup;
