/**
 * Member product renew reducer
 *
 * Author: Fernando Karnagi
 */
import {
    MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE,
} from '../../../actions/constants/actionTypesMemberCertification';
var defaultState = {
    brandEditable: false,
    updateAssessment: false,
    type: '', //product or service
    selectedProductTypeId: '',
    selectedProductTypeName: '',
    manufacturerDetails: '',
    isManufacturer: '',
    productCatalogueFilename: '', //SQLHana.txt
    productCatalogue: '',
    productBrands: [],
    //For service certificate
    isSelfFinancingCapable: '',
    serviceCatalogueFilename: '',
    serviceCategoryId: '',
    serviceCatalogue: '',
    certificateNumber: '',
};

export function memberProductRenewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBERSHIP_PRODUCT_CERTIFICATION_RENEW_SET_BRAND_EDITABLE':
            // newState.new = Object.assign({}, state.new);
            newState.brandEditable = action.editable;
            return newState;
        case 'MEMBERSHIP_PRODUCT_CERTIFICATION_RENEW_SET_ASSESSMENT_UPDATE':
            // newState.new = Object.assign({}, state.new);
            newState.updateAssessment = action.update;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST:
            newState.error = false;
            newState.savingApplChanges = true;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS:
            newState.savingApplChanges = false;
            newState.savedThisSession = true;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE:
            newState.savingApplChanges = false;
            newState.error = true;
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO:
            newState.loading = true;
            newState.certification = action.response.data;
            newState.certificateNumber = action.response.data.certificateNumber;
            newState.templateId = action.response.data.templateId;
            newState.assessmentId = action.response.data.assessmentId;
            newState.productPicture = action.response.data.productPicture;
            newState.productPictureFilename = action.response.data.productPictureFilename;
            newState.productCatalogueFilename = action.response.data.productCatalogueFilename;
            newState.productCatalogue = action.response.data.productCatalogue;
            newState.type = action.response.data.typeOfApp; // Product or Services
            newState.manufacturerContactPersonName = action.response.data.manufacturerContactPersonName;
            newState.manufacturerTelephone = action.response.data.manufacturerTelephone;
            if (newState.type.toLowerCase() === 'service') {
                newState.type = 'service'; //used to make action save-service-cert-application
                //its service application
                newState.isSelfFinancingCapable = action.response.data.isSelfFinancingCapable;
                newState.productCatalogueFilename = action.response.data.productCatalogueFilename;
                newState.serviceCategoryId = action.response.data.serviceCategoryId;
                newState.prevCertificateId = action.response.data.certificateId;
                newState.greenRemarks = action.response.data.greenRemarks;
                newState.service = action.response.data.service;
            } else {
                newState.type = 'product';
                //newState.productBrands = action.response.data.brands; //its a full json
                var brands = JSON.parse(action.response.data.brands);
                var brandsTable = [];
                brands &&
                    brands.forEach(brand => {
                        var models = [];
                        brand.models.forEach(model => {
                            models.push({
                                name: model.name,
                                description: model.description,
                                certificationNumber: model.certificationNumber,
                                price: model.price,
                                ticks: model.ticks,
                            });
                        });
                        brandsTable.push({
                            name: brand.name,
                            models: models,
                        });
                    });
                newState.productBrands = brandsTable;
                newState.prevCertificateId = action.response.data.certificateId;
                newState.selectedProductTypeId = action.response.data.productType;
                newState.selectedProductTypeName = action.response.data.productTypeValue;
                newState.manufacturerDetails = action.response.data.manufacturerDetails;
                newState.isManufacturer = action.response.data.isManufacturer;
                newState.manufacturerCountryId = action.response.data.manufacturerCountryId;
                newState.manufacturerFactoryAddress = action.response.data.manufacturerFactoryAddress;
                newState.manufacturerCountryName = action.response.data.manufacturerCountryName;
                newState.manufacturerNameOfCompany = action.response.data.manufacturerNameOfCompany;
                newState.manufacturerPostalCode = action.response.data.manufacturerPostalCode;
                newState.greenRemarks = action.response.data.greenRemarks;
            }
            return newState;
        case MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO:
            newState.loading = false;
            return newState;
        default:
            return newState;
    }
}
