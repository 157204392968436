import React from 'react';
import { connect } from 'react-redux';

import { Input, Row, Col, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import ActivityTrailModal from './ActivityTrailModal';
import RatingsComponent from './RatingsComponent';
import YesNoIcon, { PendingIcon } from 'cyder/media/YesNoIcon';
// import scrollToComponent from 'react-scroll-to-component';
import { getCriteriaDetailsByCode } from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaAddAction';

import { SGBC_S3_ROOT } from 'config';
import { userInfo } from 'os';

class CriteriaViewPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.toggleTrailModal = this.toggleTrailModal.bind(this);
        this.state = {
            trailModal: false,
            previewCriteria: false,
            staff: false,
        };
    }
    toggleTrailModal() {
        this.setState({
            trailModal: !this.state.trailModal,
        });
    }
    async componentDidMount() {
        var path = window.location.pathname;
        var requirementCode = this.props.requirement.id;
        let requirement = await this.props.getCriteriaDetails(requirementCode);

        //check if its preview url then only display the ticks in section B
        const isPreview = path.toLowerCase().indexOf('/preview/') >= 0;
        const isStaff = path.toLowerCase().indexOf('staff') >= 0;
        if (this._isMounted)
            this.setState({
                previewCriteria: isPreview ? true : this.state.isPreview,
                staff: isStaff ? true : this.state.staff,
                requirement: requirement.data,
            });

        // Added by Fernando to support auto scroll (25 Sep 2019)
        if (this.props.requirementId == this.props.updatedRequirementId) {
            var elmnt = document.getElementById(`criteria-${this.props.requirementId}`);
            // console.log("scroll to elmnt: ", `criteria-${this.props.requirementId}`)
            elmnt.scrollIntoView();
            setTimeout(() => {
                window.scrollBy(0, -25);
            }, 500);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        const getSubmissionStatus = status => {
            switch (status) {
                case 'Submitted':
                    return <PendingIcon width="40" />;
                case 'Accepted':
                    return <YesNoIcon val={true} width="40" />;
                case 'Rejected':
                    return <YesNoIcon val={false} width="40" />;
                case 'Saved':
                case null:
                    return null;
            }
        };
        const status = getSubmissionStatus(this.props.requirement.status);
        const ratingSelectBoxId = `rating-${this.props.requirement.id}`;

        // Added by Fernando on 29 Jul 2019 to support multiple rows for Section B
        let sectionBRows = [];
        if (this.props.applicationInfo && this.props.applicationInfo.brands) {
            try {
                let brands = JSON.parse(this.props.applicationInfo.brands);
                let models = brands[0].models;
                for (var i = 0; i < models.length; i++) {
                    sectionBRows.push(models[i]);
                }
            } catch (e) {}
        }
        if (sectionBRows.length == 0) {
            sectionBRows.push({ name: 'default' });
        }

        if (this.state.requirement == null) return <LoadingSpinner center />;

        let sectionBRatingRows = [];
        if (this.props.requirement.rating && this.props.requirement.rating.length > 0) {
            for (var i = 0; i < this.props.requirement.rating.length; i++) {
                sectionBRatingRows.push(this.props.requirement.rating[i]);
            }
        }

        return (
            <Row
                id={`criteria-${this.props.requirementId}`}
                style={{ borderBottom: '1px dotted', borderBottomColor: '#bbb', paddingBottom: '10px', paddingTop: '10px' }}
            >
                <Col xs={1}>{this.props.id + 1}</Col>
                <Col xs={this.props.preview ? 8 : !this.props.staff ? 6 : 4}>
                    {this.state.requirement.criteria && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.requirement.criteria
                                    .split('\n<br/>')
                                    .map((item, key) => {
                                        return item + '<br/>';
                                    })
                                    .join(''),
                            }}
                        />
                    )}

                    <br />

                    <div>
                        {this.state.requirement.ratings != [] ? (
                            <RatingsComponent
                                ratings={this.state.requirement.ratings}
                                onChangeRating={null}
                                removeRating={null}
                                addRating={null}
                                ratingError={null}
                                readOnly={true}
                            />
                        ) : null}
                    </div>
                </Col>
                <Col xs={3}>
                    {this.state.requirement.documentsToSubmit && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.state.requirement.documentsToSubmit
                                    .split('\n<br/>')
                                    .map((item, key) => {
                                        return item + '<br/>';
                                    })
                                    .join(''),
                            }}
                        />
                    )}
                    <hr />
                    {this.props.requirement.documents && this.props.requirement.documents !== null && <u>Submitted Document(s)</u>}
                    {this.props.requirement.documents &&
                        this.props.requirement.documents !== null &&
                        this.props.requirement.documents.map((document, i) => (
                            <Row key={i} className="mt-2 mb-2">
                                {!(
                                    this.props.status === 'Submitted' ||
                                    this.props.requirement.status === 'Submitted' ||
                                    this.props.requirement.status === 'Accepted'
                                ) && (
                                    <Col xs={1} style={{ lineHeight: '25px' }}>
                                        <i
                                            className="material-icons material-icons-1x"
                                            style={{ cursor: 'pointer', color: 'red' }}
                                            onClick={e => this.props.onDeleteDoc(this.props.requirement.id, document.id)}
                                        >
                                            delete
                                        </i>
                                    </Col>
                                )}
                                <Col xs={11}>
                                    {!(
                                        this.props.status === 'Submitted' ||
                                        this.props.requirement.status === 'Submitted' ||
                                        this.props.requirement.status === 'Accepted'
                                    ) && (
                                        <CyderFileUpload
                                            attachmentId={document.resourceId}
                                            filename={document.filename}
                                            onUploadSuccess={file =>
                                                this.props.onDocChange(this.props.requirement.id, document.id, file.id, file.filename)
                                            }
                                            onUploadFail={e => {}}
                                        />
                                    )}
                                    {(this.props.status === 'Submitted' ||
                                        this.props.requirement.status === 'Submitted' ||
                                        this.props.requirement.status === 'Accepted') && (
                                        <a target="blank" href={`${SGBC_S3_ROOT}${document.resourceId}`}>
                                            <u style={{ fontWeight: 'bold', color: '#2b69ce' }}>{document.filename}</u>
                                        </a>
                                    )}
                                </Col>
                            </Row>
                        ))}

                    {!(
                        this.props.status === 'Submitted' ||
                        this.props.requirement.status === 'Submitted' ||
                        this.props.requirement.status === 'Accepted'
                    ) && (
                        <div className="mt-2 mb-2">
                            <Row>
                                <Col xs="12">
                                    {!(
                                        this.props.status === 'Submitted' ||
                                        this.props.requirement.status === 'Submitted' ||
                                        this.props.requirement.status === 'Accepted'
                                    ) &&
                                        (this.props.requirement.documents == null || this.props.requirement.documents.length <= 0) && (
                                            <span>
                                                Please upload document
                                                {this.props.section != 'C' && <span>(*)</span>}
                                            </span>
                                        )}
                                </Col>
                                <Col xs="12">
                                    <Button
                                        disabled={this.props.preview ? true : false}
                                        className="primary-btn-style"
                                        style={{ marginLeft: '0px', marginBottom: '20px', width: '100%' }}
                                        onClick={e => this.props.onAddDoc(this.props.requirement.id)}
                                    >
                                        <i className="mr-1 material-icons align-text-bottom ">file_upload</i>
                                        Add Document
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}

                    {!(
                        this.props.status === 'Submitted' ||
                        this.props.requirement.status === 'Submitted' ||
                        this.props.requirement.status === 'Accepted'
                    ) && <span>Please write your remarks</span>}
                    <Input
                        type="textarea"
                        disabled={
                            this.props.status === 'Submitted' ||
                            this.props.requirement.status === 'Submitted' ||
                            this.props.requirement.status === 'Accepted'
                        }
                        onChange={e => this.props.onRemarksChange(this.props.requirement.id, e.target.value)}
                        value={this.props.requirement.remarks}
                    />
                    <br />
                    {!(
                        this.props.status === 'Submitted' ||
                        this.props.requirement.status === 'Submitted' ||
                        this.props.requirement.status === 'Accepted'
                    ) && (
                        <Row>
                            <Col xs={6}>
                                <Button
                                    block
                                    disabled={
                                        this.props.requirement.documents == null ||
                                        this.props.requirement.documents.length <= 0 ||
                                        this.props.requirement.documents.filter(document => document.resourceId != null).length == 0
                                    }
                                    className="primary-btn-style"
                                    style={{ marginBottom: '20px' }}
                                    onClick={e => {
                                        this.props.save(this.props.requirement.id);
                                    }}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">save</i>
                                    Save Draft
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button
                                    block
                                    disabled={
                                        this.props.requirement.documents == null ||
                                        this.props.requirement.documents.length <= 0 ||
                                        this.props.requirement.documents.filter(document => document.resourceId != null).length == 0
                                    }
                                    className="primary-btn-style"
                                    style={{ marginBottom: '20px' }}
                                    onClick={e => this.props.submit(this.props.requirement.id)}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">send</i>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    )}

                    {!this.props.preview && this.props.staff && (
                        <Row>
                            <Col className="pt-0">
                                {this.props.requirement.trail != null && this.props.requirement.trail.length > 0 && (
                                    <React.Fragment>
                                        <a className="text-bold" href="#" onClick={this.toggleTrailModal}>
                                            <u>View History/Remarks</u>
                                        </a>
                                    </React.Fragment>
                                )}
                                <ActivityTrailModal
                                    trail={this.props.requirement.trail}
                                    toggleTrailModal={this.toggleTrailModal}
                                    trailModal={this.state.trailModal}
                                />
                            </Col>
                        </Row>
                    )}
                </Col>
                {this.props.staff && (
                    <Col xs={2}>
                        {this.props.requirement.status == 'Submitted' && (
                            <React.Fragment>
                                {this.props.withTick && (
                                    <React.Fragment>
                                        Please provide your rating below
                                        <br />
                                        <br />
                                        {sectionBRows.map((e, i) => (
                                            <React.Fragment>
                                                {e.name}
                                                <br />
                                                <select
                                                    key={userInfo}
                                                    id={ratingSelectBoxId + '-' + e.name}
                                                    name={ratingSelectBoxId}
                                                    style={{ width: '100px', marginBottom: '20px', marginLeft: '5px' }}
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                </select>
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                )}
                                <Button
                                    disabled={this.props.preview ? true : false}
                                    className="primary-btn-style primary-btn-sm"
                                    style={{ marginBottom: '20px' }}
                                    onClick={e => this.props.approve(this.props.requirement.id)}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">thumb_up</i>
                                    Accept
                                </Button>
                                <br />
                            </React.Fragment>
                        )}

                        {this.props.requirement.status == 'Submitted' && (
                            <Button
                                disabled={this.props.preview ? true : false}
                                className="primary-btn-style-outline primary-btn-sm"
                                style={{ marginBottom: '20px' }}
                                onClick={e => this.props.reject(this.props.requirement.id)}
                            >
                                <i className="mr-1 material-icons align-text-bottom ">thumb_down</i>
                                Resubmit
                            </Button>
                        )}

                        {(this.props.requirement.status == 'Accepted' || this.props.requirement.status == 'Rejected') && ''}
                    </Col>
                )}
                {!this.props.preview && (
                    <Col xs={2} className="text-center">
                        {status}
                        <br />
                        {this.props.requirement.rating && this.props.staff && (
                            <React.Fragment>
                                <Col className="text-bold pt-2" style={{ color: '#317446', paddingBottom: '10px' }}>
                                    <b>Rating</b>
                                    <br />
                                    <br />
                                    {sectionBRatingRows.map((e, i) => (
                                        <React.Fragment key={i}>
                                            {e.id}
                                            {` `}: {e.val}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Col>
                            </React.Fragment>
                        )}
                        <br />
                        {!this.props.closed && (this.props.reviewMode || this.props.staff) && (
                            <React.Fragment>
                                <Input
                                    type="textarea"
                                    onChange={e => {
                                        this.props.onRecordRemarksChanged(this.props.requirement.id, e.target.value);
                                    }}
                                    value={this.props.requirement.internalNotes}
                                />{' '}
                                <br />
                                <Button
                                    className="primary-btn-style"
                                    style={{ marginBottom: '20px' }}
                                    onClick={e => {
                                        this.props.updateRecordRemarks(this.props.requirement.id);
                                    }}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">save</i>
                                    Update Remarks
                                </Button>
                                {this.props.requirement.status != 'Submitted' && (
                                    <React.Fragment>
                                        <br />
                                        <Button
                                            className="primary-btn-style-outline"
                                            style={{ marginBottom: '20px' }}
                                            onClick={e => {
                                                this.props.reassess(this.props.requirement.id);
                                            }}
                                        >
                                            <i className="mr-1 material-icons align-text-bottom ">assignment_late</i>
                                            Re-evaluate
                                        </Button>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}

                        {/* {this.props.staff && (
                            <React.Fragment>
                                <Input
                                    type="textarea"
                                    onChange={e => {
                                        this.props.onRecordRemarksChanged(this.props.requirement.id, e.target.value);
                                    }}
                                    value={this.props.requirement.internalNotes}
                                />{' '}
                                <br />
                                <Button
                                    className="primary-btn-style"
                                    style={{ marginBottom: '20px' }}
                                    onClick={e => {
                                        this.props.updateRecordRemarks(this.props.requirement.id);
                                    }}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">save</i>
                                    Update Remarks
                                </Button>
                            </React.Fragment>
                        )} */}
                    </Col>
                )}
            </Row>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        profile: state.profile,
    };
};
const mapDispatchToProps = dispatch => ({
    getCriteriaDetails: code => dispatch(getCriteriaDetailsByCode(code)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CriteriaViewPanel);
