import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CertificationRenewalDetailsList from 'pages/analytics/components/certification-renewal/CertificationRenewalDetailsList';
class CertificationRenewalDetailsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);

        this.state = {
            modal: false,
        };
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    componentDidMount() {
        // if (this.props.ref) {
        //     this.props.ref(this.show);
        // }
    }

    show(title, renewalStatusId, type) {
        this.setState({
            title,
            renewalStatusId,
            type,
            modal: true,
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>{this.state.title}</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <CertificationRenewalDetailsList renewalStatusId={this.state.renewalStatusId} type={this.state.type} />
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default CertificationRenewalDetailsPopup;
