import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputComponent from 'reactstrap-input';
import Select from 'react-select';

import { SGBC_INVOICE_DOWNLOAD_ROOT } from 'config';

const MEMBERSHIPTIERS = [
    {
        label: `Standard`,
        value: `Standard`,
    },
    {
        label: `Partner`,
        value: `Partner`,
    },
    {
        label: `Advocate`,
        value: `Advocate`,
    },
];

const MEMBERSHIPTYPE = [
    {
        label: 'Founding Member',
        value: 'founding',
    },
    {
        label: 'Corporate Member',
        value: 'corporate',
    },
    {
        label: 'Established Member',
        value: 'established',
    },
];

const ActionButton = props => {
    const {
        sm = 6,
        to,
        label,
        materialIconName,
        faIconName,
        loading,
        onClick,
        className = 'primary-btn-style',
        buttonStyle,
        wrapperClassName = 'justify-content-center',
        disabled,
    } = props;
    const buttonComponent = (
        <Button style={buttonStyle} className={'m-2 ' + className} onClick={onClick} disabled={disabled}>
            <span>
                {faIconName && <i className={`fas fa-${faIconName}`} />}
                {materialIconName && <i className="material-icons align-text-bottom">{materialIconName}</i>}
                &nbsp;
            </span>
            {label}
        </Button>
    );
    return loading ? (
        <Col className={'d-flex ' + wrapperClassName} sm={sm}>
            <LoadingSpinner />
        </Col>
    ) : (
        <Col className={'d-flex ' + wrapperClassName} sm={sm}>
            {!loading &&
                (to ? (
                    <Link to={to} style={buttonStyle}>
                        {buttonComponent}
                    </Link>
                ) : (
                    buttonComponent
                ))}
        </Col>
    );
};

const DraftContent = ({ onClickAction, withdraw }) => {
    return (
        <Row>
            <ActionButton label="Submit on behalf" onClick={() => onClickAction('submitOnBehalf')} />
            <ActionButton label="Discontinue" onClick={withdraw} />
        </Row>
    );
};

const SubmittedContent = ({ onClickAction, withdraw, changeApplicationType, applicationType }) => {
    const label = applicationType === 'Renew' ? 'Change aplication type to New' : 'Change application type to Renew';
    return (
        <Row>
            <ActionButton sm={3} label="Start Processing" onClick={() => onClickAction('startProcessing')} />
            <ActionButton sm={3} label="Withdraw" onClick={withdraw} />
            <ActionButton sm={6} label={label} onClick={() => changeApplicationType()} />
        </Row>
    );
};

const CompletedContent = ({ onClickAction }) => {
    return (
        <Row>
            <ActionButton
                sm={2}
                faIconName="check-double"
                className="text-bold text-capitalize"
                label="Download Receipt"
                buttonStyle={{ width: 'inherit', marginRight: '5px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                onClick={() => onClickAction('downloadReceipt')}
            />
            <ActionButton
                sm={2}
                faIconName="envelope"
                className="text-bold text-capitalize"
                label="Send Receipt"
                buttonStyle={{ width: 'inherit', marginRight: '5px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                onClick={() => onClickAction('sendReceipt')}
            />
        </Row>
    );
};

const ProcessingContent = ({
    onClickAction,
    overseasCharges,
    onOverseasChargesChanged,
    reinstatementFees,
    onReinstatementFeesChanged,
    changeApplicationType,
    applicationType,
    withdraw,
}) => {
    const label = applicationType === 'Renew' ? 'Change aplication type to New' : 'Change application type to Renew';
    return (
        <Col xs={8}>
            <Row>
                <Col>
                    <ActionButton
                        sm={7}
                        label="Reject"
                        wrapperClassName="justify-content-end"
                        buttonStyle={{ left: '20px' }}
                        onClick={() => onClickAction('doReject')}
                    />
                </Col>
                <Col>
                    <ActionButton xs={7} label="Approve" wrapperClassName="p-0" onClick={() => onClickAction('doApprove')} />

                    <Col className="m-2 ml-3">
                        <Input type="checkbox" value={overseasCharges} onChange={onOverseasChargesChanged} /> Include Overseas Charges
                        ($50.00)
                    </Col>
                    <Col className="m-2 ml-3">
                        <Input type="checkbox" value={reinstatementFees} onChange={onReinstatementFeesChanged} /> Include Reinstatement
                        Charges ($300.00){' '}
                    </Col>
                </Col>
                <Col>
                    <ActionButton sm={5} label={label} onClick={() => changeApplicationType()} />
                </Col>
            </Row>
        </Col>
    );
};

const ApprovedPendingPaymentContent = ({
    applicationId,
    invoiceNumber,
    createInvoice,
    updateXeroPayment,
    loadingCreateInvoice,
    loadingUpdateXeroPayment,
    markAsPaid,
    sendInvoice,
    withdraw,
}) => {
    if (!invoiceNumber) {
        return (
            <Col>
                <Row className="p-4">
                    <Col className="d-flex justify-content-center" sm={12}>
                        <h4>Invoice is currently being generated</h4>
                    </Col>
                </Row>
                <Row>
                    <ActionButton label="Withdraw" onClick={withdraw} />
                    <ActionButton to={`./${applicationId}/payment`} label="Update Payment Info" />
                    <ActionButton loading={loadingCreateInvoice} onClick={createInvoice} label="Generate Invoice" />
                </Row>
            </Col>
        );
    }
    return (
        <div>
            <Row className="mt-4 mb-4">
                <Col className="d-flex justify-content-center" sm={12}>
                    {invoiceNumber ? (
                        <h4>
                            <strong>Invoice Number: </strong>
                            {invoiceNumber}
                        </h4>
                    ) : (
                        <h4>Invoice number not found</h4>
                    )}
                </Col>
            </Row>
            {loadingUpdateXeroPayment ? (
                <LoadingSpinner />
            ) : (
                <Row>
                    <ActionButton sm={2} label="Withdraw" onClick={withdraw} />
                    <ActionButton
                        sm="2"
                        onClick={() => {
                            window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=${invoiceNumber}`;
                        }}
                        label={!invoiceNumber ? 'Generate Xero Invoice' : 'Download Invoice'}
                    />
                    {invoiceNumber && <ActionButton sm="2" onClick={sendInvoice} label="Send Invoice" />}
                    <ActionButton sm="2" to={`./${applicationId}/payment`} label="Update Payment Info" />
                    <ActionButton sm="2" onClick={updateXeroPayment} label="Check Xero Payment" />
                    <ActionButton buttonStyle={{ width: '150px' }} sm="2" onClick={markAsPaid} label="Mark as Paid" />
                </Row>
            )}
        </div>
    );
};

const PreparationContent = ({
    application,
    membershipStartDate,
    membershipEndDate,
    onClickAction,
    validationObj,
    handleChange,
    saveButtonPressed,
    handleChangeDateTime,
    selectedMembershipTier,
    membershipTierOnChange,
    selectedMembershipType,
    membershipTypeOnChange,
    showModal,
    withdraw,
}) => {
    const dateFormat = 'DD/MM/YYYY';
    const additionalProps = {
        validationObj,
        handleChange,
        saveButtonPressed,
    };
    const Cdp = (
        <CyderDatePicker
            value={membershipStartDate}
            dateFormat={dateFormat}
            placeholder={dateFormat}
            onChange={e => handleChangeDateTime(e, 'membershipStartDate')}
            {...additionalProps}
        />
    );
    const Cdp2 = (
        <CyderDatePicker
            value={membershipEndDate}
            dateFormat={dateFormat}
            placeholder={dateFormat}
            onChange={e => handleChangeDateTime(e, 'membershipEndDate')}
            {...additionalProps}
        />
    );
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <b>Certification is ready for issuance.</b> <br /> <br />
                    Please specify the membership start date, expiry date, and membership tier, and <b>Send Receipt</b> before clicking the{' '}
                    <b>Mark as Completed</b> button to complete this application and issue membership certificate.
                    <br /> <br /> If payment is done by cheque, paynow, or bank transfer, please click <b>Payment Info</b> to update payment
                    information. If payment is done by credit card, please click <b>Payment Info</b> to view payment information.
                    <br /> <br />
                    Click <b>Download Invoice</b> to view the invoice for this application
                </Col>
            </Row>
            <br />
            <Row>
                <Col xs={12}>
                    <InputComponent
                        customInputComponent={Cdp}
                        label="Membership Start Date"
                        labelStyle={{ fontWeight: 'bold' }}
                        value={membershipStartDate}
                        valueKey={'membershipStartDate'}
                        {...additionalProps}
                    />
                </Col>
                <Col xs={12}>
                    <InputComponent
                        customInputComponent={Cdp2}
                        label="Membership Expiry Date"
                        labelStyle={{ fontWeight: 'bold' }}
                        value={membershipEndDate}
                        valueKey={'membershipEndDate'}
                        {...additionalProps}
                    />
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            <b>Membership Type </b>
                            <br />
                            <span>*Note: This cannot be amended later on</span>
                        </Label>
                        <div style={{ width: '250px' }}>
                            <Select
                                autosize={false}
                                options={MEMBERSHIPTYPE}
                                value={selectedMembershipType}
                                clearable={true}
                                onChange={membershipTypeOnChange}
                                name="selected-state"
                                disabled={false}
                                searchable={true}
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={12}>
                    <FormGroup>
                        <Label>
                            <b>Membership Tier </b>
                            <br />
                            <span>Please refer to Section J if customer has uploaded the questionnaires answers</span>
                        </Label>
                        <div style={{ width: '250px' }}>
                            <Select
                                autosize={false}
                                options={MEMBERSHIPTIERS}
                                value={selectedMembershipTier}
                                clearable={true}
                                onChange={membershipTierOnChange}
                                name="selected-state"
                                disabled={false}
                                searchable={true}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            <br />
            <Row>
                <ActionButton
                    sm={2}
                    materialIconName="payment"
                    label="Payment Info"
                    buttonStyle={{ width: 'inherit', marginRight: '10px' }}
                    onClick={!application.applicationId ? showModal('Error', 'No application Id was found', null, true) : null}
                    to={application.applicationId ? `./${application.applicationId}/payment` : '#'}
                />
                <ActionButton
                    sm={2}
                    materialIconName="receipt"
                    label="Download Invoice"
                    buttonStyle={{ width: 'inherit', marginRight: '5px' }}
                    onClick={() => {
                        window.location.href =
                            `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=` + application.invoiceNumber;
                    }}
                />
                <ActionButton sm={2} label="Withdraw" onClick={withdraw} buttonStyle={{ width: 'inherit', marginRight: '10px' }} />
            </Row>
            <Row>
                {/* <ActionButton
                    sm={2}
                    faIconName="envelope"
                    className="text-bold text-capitalize"
                    label="Send Receipt"
                    buttonStyle={{ width: 'inherit', marginRight: '5px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                    onClick={() => onClickAction('sendReceipt')}
                /> */}
                <ActionButton
                    sm={2}
                    faIconName="check-double"
                    className="text-bold text-capitalize"
                    label="Download Receipt"
                    buttonStyle={{ width: 'inherit', marginRight: '5px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                    onClick={() => onClickAction('downloadReceipt')}
                />
                <ActionButton
                    sm={2}
                    faIconName="check-double"
                    className="text-bold text-capitalize"
                    label="Mark as Completed"
                    buttonStyle={{ width: 'inherit', marginRight: '5px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                    onClick={() => onClickAction('processApplicationAsComplete')}
                    disabled={!selectedMembershipType}
                />
            </Row>
            <Row style={{ marginLeft: '10px' }}>
                <p>
                    Receipt can be sent after completing confirming the membership start and expiry date and mark this application as
                    completed
                </p>
            </Row>
        </div>
    );
};

const StatusArea = ({
    application,
    membershipStartDate,
    membershipEndDate,
    statusId,
    onClickAction,
    handleChangeDateTime,
    validationObj,
    handleChange,
    saveButtonPressed,
    withdraw,
    createInvoice,
    loadingCreateInvoice,
    updateXeroPayment,
    loadingUpdateXeroPayment,
    selectedMembershipTier,
    membershipTierOnChange,
    selectedMembershipType,
    membershipTypeOnChange,
    showModal,
    overseasCharges,
    onOverseasChargesChanged,
    markAsPaid,
    changeApplicationType,
    applicationType,
    reinstatementFees,
    onReinstatementFeesChanged,
    sendInvoice,
}) => {
    if (statusId == 1) return <DraftContent onClickAction={onClickAction} withdraw={withdraw} />;

    if (statusId == 2)
        return (
            <SubmittedContent
                onClickAction={onClickAction}
                withdraw={withdraw}
                changeApplicationType={changeApplicationType}
                applicationType={applicationType}
            />
        );

    if (statusId == 3)
        return (
            <ProcessingContent
                onClickAction={onClickAction}
                overseasCharges={overseasCharges}
                onOverseasChargesChanged={onOverseasChargesChanged}
                reinstatementFees={reinstatementFees}
                onReinstatementFeesChanged={onReinstatementFeesChanged}
                changeApplicationType={changeApplicationType}
                applicationType={applicationType}
                withdraw={withdraw}
            />
        );

    if (statusId == 4)
        return (
            <ApprovedPendingPaymentContent
                applicationId={application.applicationId}
                invoiceNumber={application.invoiceNumber}
                onClickAction={onClickAction}
                createInvoice={createInvoice}
                loadingCreateInvoice={loadingCreateInvoice}
                updateXeroPayment={updateXeroPayment}
                markAsPaid={markAsPaid}
                loadingUpdateXeroPayment={loadingUpdateXeroPayment}
                sendInvoice={sendInvoice}
                withdraw={withdraw}
            />
        );

    if (statusId == 5)
        return (
            <CompletedContent
                applicationId={application.applicationId}
                invoiceNumber={application.invoiceNumber}
                onClickAction={onClickAction}
                createInvoice={createInvoice}
                loadingCreateInvoice={loadingCreateInvoice}
                updateXeroPayment={updateXeroPayment}
                loadingUpdateXeroPayment={loadingUpdateXeroPayment}
                withdraw={withdraw}
            />
        );

    if (statusId == 9)
        return (
            <PreparationContent
                application={application}
                membershipStartDate={membershipStartDate}
                membershipEndDate={membershipEndDate}
                onClickAction={onClickAction}
                handleChangeDateTime={handleChangeDateTime}
                validationObj={validationObj}
                handleChange={handleChange}
                saveButtonPressed={saveButtonPressed}
                selectedMembershipTier={selectedMembershipTier}
                membershipTierOnChange={membershipTierOnChange}
                selectedMembershipType={selectedMembershipType}
                membershipTypeOnChange={membershipTypeOnChange}
                showModal={showModal}
                withdraw={withdraw}
            />
        );

    return null;
};

const DetailsPageFormSectionTakeAction = ({
    application,
    membershipStartDate,
    membershipEndDate,
    onClickAction,
    handleChangeDateTime,
    validationObj,
    handleChange,
    calculateMembershipDates,
    saveButtonPressed,
    withdraw,
    createInvoice,
    loadingCreateInvoice,
    updateXeroPayment,
    loadingUpdateXeroPayment,
    selectedMembershipTier,
    membershipTierOnChange,
    selectedMembershipType,
    membershipTypeOnChange,
    showModal,
    overseasCharges,
    onOverseasChargesChanged,
    markAsPaid,
    changeApplicationType,
    applicationType,
    reinstatementFees,
    onReinstatementFeesChanged,
    sendInvoice,
}) => {
    const statusId = application.statusid;
    /**
     * Switch case below is used to prevent empty card view being
     * generated by StatusArea Component
     */
    switch (statusId) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 9:
            break;
        default:
            return null;
    }
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <Card>
                    <CardBody
                        className={
                            statusId === 1 || statusId === 2 || statusId === 3 || statusId === 4 ? 'd-flex justify-content-center' : ''
                        }
                    >
                        <StatusArea
                            application={application}
                            membershipStartDate={membershipStartDate}
                            membershipEndDate={membershipEndDate}
                            onClickAction={onClickAction}
                            statusId={statusId}
                            handleChangeDateTime={handleChangeDateTime}
                            validationObj={validationObj}
                            handleChange={handleChange}
                            calculateMembershipDates={calculateMembershipDates}
                            saveButtonPressed={saveButtonPressed}
                            withdraw={withdraw}
                            markAsPaid={markAsPaid}
                            createInvoice={createInvoice}
                            loadingCreateInvoice={loadingCreateInvoice}
                            updateXeroPayment={updateXeroPayment}
                            loadingUpdateXeroPayment={loadingUpdateXeroPayment}
                            selectedMembershipTier={selectedMembershipTier}
                            membershipTierOnChange={membershipTierOnChange}
                            selectedMembershipType={selectedMembershipType}
                            membershipTypeOnChange={membershipTypeOnChange}
                            showModal={showModal}
                            overseasCharges={overseasCharges}
                            onOverseasChargesChanged={onOverseasChargesChanged}
                            changeApplicationType={changeApplicationType}
                            applicationType={applicationType}
                            reinstatementFees={reinstatementFees}
                            sendInvoice={sendInvoice}
                            onReinstatementFeesChanged={onReinstatementFeesChanged}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default DetailsPageFormSectionTakeAction;
