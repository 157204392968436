import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Container, FormGroup, Input, Row, Col, Form, Button, Nav, NavItem, NavLink } from 'reactstrap';
import EventsHeaderComponent from './EventsHeaderComponent';
import DialogModal from 'cyder/modals/DialogModal';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { SGBC_WEB_HOST } from 'config';

import {
    eventsAttendeeGetAttendeeList,
    eventAttendeeResetToDefault,
    eventsAttendeeHandleInputChange,
    eventsAttendeeCheckInAttendee,
    eventsAttendeeAddCPDPoints,
    eventsAttendeeSetModalData,
    eventsAttendeeUpdatWaitingStatus,
    eventsAttendeeGetAllAttendeeList,
    exportAttendeeList,
} from '../../../../actions/staff/events/events/eventsAttendeeAction';
import { eventGetEventDetails } from '../../../../actions/staff/events/events/eventsCreationAction';
import { dialogModalToggle } from '../../../../actions/modal/dialogModalAction';

import { searchKeywordByAlphabeticalLetter } from 'js/keywordSearch';
import { throws } from 'assert';

const FilterNavComponentWrapper = props => {
    return (
        <Col xs={12}>
            <div>
                <Row className="mb-2">
                    <Col xs={12} className="p-3">
                        <Nav tabs>{props.children}</Nav>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};
export const WrapperComponent = ({ children, mode }) => {
    if (mode === 'staff') return children;
    return <Container>{children}</Container>;
};
class EventsAttendancePage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.exportAll = this.exportAll.bind(this);
        this.state = {
            letterToFilter: 'All',
            loading: false,
            mode: 'staff',
            attendees: [],
            checkInClikced: false,
        };
    }

    async exportAll() {
        try {
            this.eventAttendancePageRef = this;
            this.showLoading(true);
            let { data } = await this.props.exportAttendeeList(this.state.eventId);
            var convert = [];
            data.forEach(x => {
                var obj = x;
                obj.paymentStatus = obj.paymentStatus ? 'Yes' : 'No';
                obj.sgbcMember = obj.sgbcMember ? 'Yes' : 'No';
                obj.attended = obj.attended ? 'Yes' : 'No';
                convert.push(obj);
            });

            let Papa = require('papaparse');
            let FileSaver = require('file-saver');

            let config = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };

            var csvPayload = Papa.unparse(convert, config);
            var blob = new Blob([csvPayload], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, 'attendee.csv');
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    componentDidMount() {
        this.eventAttendancePageRef = this;
        const { resetToDefault, getEventDetails, getAllAttendeeList, create } = this.props;
        const { params, url } = this.props.match;
        resetToDefault();
        getAllAttendeeList(params.eventid);
        if (create.fields.name === '') getEventDetails(params.eventid);
        this.setState({
            mode: url.includes('staff') ? 'staff' : 'member',
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getAttendeeList = async () => {
        const { letterToFilter } = this.state;
        const { eventid } = this.props.match.params;
        const { keyword, getAttendeeList } = this.props;
        try {
            this.showLoading(true);
            let { data } = await getAttendeeList(eventid, keyword, 0, 999);
            if (letterToFilter !== 'All') data = searchKeywordByAlphabeticalLetter(data, letterToFilter, 'companyName');
            if (!this._isMounted) return;
            this.setState({
                attendees: data,
                eventId: eventid,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    };
    handleInputChange = (event, eventId) => {
        const { handleInputChange } = this.props;
        handleInputChange(event);
    };
    checkInAttendee = async attendeeId => {
        this.showLoading(true);
        await this.props.checkInAttendee(attendeeId); //newState.modalLoading = false;
        await this.getAttendeeList();
        this.props.addAttendeeCPDPoints(attendeeId);
        this.showLoading(false);
    };
    updateAttendeeWaitingStatus = (attendeeId, status) => {
        this.props.updateAttendeeWaitingStatus(attendeeId, status).then(() => {
            this.props.dialogModalToggle();
        });
    };
    // UTIL
    showLoading(loading) {
        this.setState({ loading });
    }
    getAlphebeticalNavObject() {
        let alphabets = ['All'];
        let i = 'A'.charCodeAt(0);
        let j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            alphabets.push(String.fromCharCode(i));
        }
        return alphabets.map(letter => {
            return {
                key: letter.toLowerCase(),
                text: letter,
            };
        });
    }
    // FILTER NAV
    clickLetter = letter => {
        let state = this.state;
        state.letterToFilter = letter;
        this.setState(state, this.getAttendeeList);
    };
    renderNav = navItems => {
        return navItems.map(item => {
            const { key, text } = item;
            const { letterToFilter } = this.state;
            const className = letterToFilter === text ? 'active' : null;
            return (
                <NavItem key={key}>
                    <NavLink
                        className={'text-bold p-3 ' + className}
                        style={letterToFilter === text ? { color: '#303f9f' } : null}
                        onClick={() => {
                            this.clickLetter(text);
                        }}
                    >
                        {text}
                    </NavLink>
                </NavItem>
            );
        });
    };
    render() {
        const yesNoUnknownIcons = val => {
            switch (new Boolean(val).valueOf()) {
                case null:
                    return <i className="fa fa-icon text-muted fa-question-circle" />;
                case true:
                    return <img src="/assets/icons/tick-icon.png" width="20" alt="Tick icon" />;
                case false:
                    return <img src="/assets/icons/no-icon.png" width="20" alt="No icon" />;
            }
        };
        let columns = [
            {
                Header: 'Name',
                accessor: 'name',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 125,
                maxWidth: 200,
            },
            {
                Header: 'Company',
                accessor: 'companyName',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 125,
                maxWidth: 200,
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 125,
                Cell: props => {
                    return props.value || '-';
                },
            },
            {
                Header: 'Contact Number',
                accessor: 'contactNumber',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 100,
                Cell: props => {
                    return props.value || '-';
                },
            },
            {
                Header: 'GMAP No.',
                accessor: 'gmp',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 70,
                Cell: props => {
                    return props.value || '-';
                },
            },
            {
                Header: 'Paid',
                accessor: 'paymentStatus',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 50,
                maxWidth: 50,
                Cell: props => {
                    return props.original.totalPrice == 0 ? (
                        <div>
                            {' '}
                            <i>NA</i>{' '}
                        </div>
                    ) : (
                        yesNoUnknownIcons(props.value)
                    );
                },
            },
            {
                Header: 'Member',
                accessor: 'sgbcMember',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                minWidth: 65,
                maxWidth: 65,
                Cell: props => {
                    return yesNoUnknownIcons(props.value);
                },
            },
            {
                Header: 'Attended',
                accessor: 'attended',
                sortable: false,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 70,
                maxWidth: 70,
                Cell: props => {
                    if (props.value === 1) return yesNoUnknownIcons(props.value);
                    return (
                        <Button className="primary-btn-style" onClick={() => this.checkInAttendee(props.row.id)}>
                            <i className="fa fa-1x fa-fw fa-user-plus" />
                        </Button>
                    );
                },
            },
            {
                Header: 'Checkin Timestamp',
                accessor: 'checkinTimestamp',
                maxWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                headerStyle: { whiteSpace: 'unset' },
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    const eventId = this.props.match.params.eventid;
                    const link = `/staff/events/${eventId}/attendee/${props.value}`;
                    return (
                        <Link className="ml-2" to={link}>
                            <Button className="primary-btn-style">
                                <i className="material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                maxWidth: 500,
                minWidth: 150,
                accessor: 'registrationId',
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    const paymentStatus = props.original.payementStatus;
                    return props.original.totalPrice == 0 ? (
                        <div>
                            {' '}
                            <i />{' '}
                        </div>
                    ) : (
                        <Link to={'./regpayment/' + props.value}>
                            <Button className="primary-btn-style">Update Payment</Button>
                        </Link>
                    );
                },
            },
        ];
        const { total, attended, yetToAttend, percentAttended } = this.props.data;
        const { mode } = this.state;
        const { params } = this.props.match;
        const alphaticallNavs = this.getAlphebeticalNavObject();
        const isStaff = mode === 'staff';
        if (mode !== 'staff') columns = columns.slice(0, columns.length - 3);

        return (
            <WrapperComponent mode={mode}>
                <DialogModal
                    loading={this.props.modalLoading}
                    modalBody={this.props.modalBody}
                    modalAction={this.props.modalAction}
                    modalHeader={this.props.modalHeader}
                    boundForceUpdate={this.getAttendeeList}
                    buttonDisabler={this.props.modalLoading}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                />

                <div className={isStaff ? 'page-widget' : null}>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <Link to={isStaff ? `../${params.eventid}/main` : '/member/events/event'}>
                                <Button className="primary-btn-style-outline">
                                    <i className="material-icons align-text-bottom">chevron_left</i> Info
                                </Button>
                                &nbsp;
                            </Link>
                        </Col>
                    </Row>
                    {isStaff ? null : <hr />}
                    <br />
                    <EventsHeaderComponent attendee mode={mode} eventId={params.eventid} companyId={params.companyid} />
                    <br />
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <div>
                                <h3>Attendees</h3>
                            </div>
                            <div className="ml-auto d-flex flex-row">
                                <Link to={`/staff/event/rsvp/${params.eventid}`}>{`Staff RSVP Link`}</Link>
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex">
                            <span>{`
                                Total: ${total} | 
                                Attended: ${attended} | 
                                Yet to Attend: ${yetToAttend} |
                                Percent Attended: ${percentAttended === 'NaN' ? 0 : percentAttended}%
                            `}</span>
                            <div className="ml-auto d-flex flex-row">
                                <span className="align-self-center">
                                    <span
                                        style={{ cursor: 'pointer', fontWeight: '400' }}
                                        onClick={e => {
                                            window.open(`/public/event/rsvp/${params.eventid}`);
                                        }}
                                    >
                                        Public RSVP Link
                                    </span>
                                    {/* <Link to={`/public/event/rsvp/${params.eventid}`}>{`Public RSVP Link`}</Link> */}
                                    {/* Public RSVP Link: {`https://${SGBC_WEB_HOST}/public/event/rsvp/${params.eventid}`} */}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <div className="form-inline d-flex">
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Input
                                        type="text"
                                        onKeyDown={e => {
                                            if (e.keyCode == 13) {
                                                this.getAttendeeList();
                                            }
                                        }}
                                        placeholder="Keyword"
                                        value={this.props.keyword}
                                        onChange={event => this.handleInputChange(event, params.eventid, params.companyid, mode)}
                                    />
                                </FormGroup>
                                <Button className="primary-btn-style" onClick={this.getAttendeeList}>
                                    Search
                                </Button>
                                <Button className="primary-btn-style" style={{ marginLeft: '5px' }} onClick={this.exportAll}>
                                    <i className="mr-1 material-icons align-text-bottom ">print</i>
                                    Print
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <FilterNavComponentWrapper>{this.renderNav(alphaticallNavs)}</FilterNavComponentWrapper>
                    <br />
                    <Row>
                        <Col xs={12}>
                            <CyderReactTable
                                className="-highlight mb-2"
                                minRows={0}
                                columns={columns}
                                filterable={false}
                                defaultPageSize={10}
                                data={this.state.attendees}
                                loading={this.state.loading}
                                w
                                totalNum={this.state.attendees != null ? this.state.attendees.length : 0}
                                onFetchData={this.getAttendeeList}
                                ref={instance => {
                                    this.attendeeTable = instance;
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </WrapperComponent>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAttendeeReducer,
        create: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        exportAttendeeList: eventId => {
            return dispatch(exportAttendeeList(eventId));
        },
        getAttendeeList: (eventId, keyword, page, pageSize) => {
            return dispatch(eventsAttendeeGetAttendeeList(eventId, keyword, page, pageSize));
        },
        getAllAttendeeList: eventId => {
            return dispatch(eventsAttendeeGetAllAttendeeList(eventId));
        },
        resetToDefault: () => {
            return dispatch(eventAttendeeResetToDefault());
        },
        getEventDetails: eventid => {
            dispatch(eventGetEventDetails(eventid));
        },
        checkInAttendee: attendeeId => {
            return dispatch(eventsAttendeeCheckInAttendee(attendeeId));
        },
        addAttendeeCPDPoints: attendeeId => {
            return dispatch(eventsAttendeeAddCPDPoints(attendeeId));
        },
        handleInputChange: event => {
            dispatch(eventsAttendeeHandleInputChange(event.target.value));
        },
        setEventModalData: data => {
            dispatch(eventsAttendeeSetModalData(data));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        updateAttendeeWaitingStatus: (attendeeId, status) => {
            return dispatch(eventsAttendeeUpdatWaitingStatus(attendeeId, status));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsAttendancePage));
