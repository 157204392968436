export const EVENT_DATES_GET_EVENT_DATE_REQUEST = 'EVENT_DATES_GET_EVENT_DATE_REQUEST';
export const EVENT_DATES_GET_EVENT_DATE_SUCCESS = 'EVENT_DATES_GET_EVENT_DATE_SUCCESS';
export const GALA_EVENT_DATES_GET_EVENT_DATE_SUCCESS = 'GALA_EVENT_DATES_GET_EVENT_DATE_SUCCESS';
export const EVENT_DATES_GET_EVENT_MULTIPLE_DATES_SUCCESS = 'EVENT_DATES_GET_EVENT_MULTIPLE_DATES_SUCCESS';
export const EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS = 'EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS';
export const EVENT_DATES_GET_EVENT_DATE_FAILURE = 'EVENT_DATES_GET_EVENT_DATE_FAILURE';

export const EVENT_DATES_SAVE_EVENT_DATE_REQUEST = 'EVENT_DATES_SAVE_EVENT_DATE_REQUEST';
export const EVENT_DATES_SAVE_EVENT_DATE_SUCCESS = 'EVENT_DATES_SAVE_EVENT_DATE_SUCCESS';
export const GALA_EVENT_DATES_SAVE_EVENT_DATE_SUCCESS = 'GALA_EVENT_DATES_SAVE_EVENT_DATE_SUCCESS';
export const EVENT_DATES_SAVE_EVENT_DATE_FAILURE = 'EVENT_DATES_SAVE_EVENT_DATE_FAILURE';

export const EVENT_DATES_UPDATE_EVENT_DATE_REQUEST = 'EVENT_DATES_UPDATE_EVENT_DATE_REQUEST';
export const EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS = 'EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS';
export const GALA_EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS = 'GALA_EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS';
export const EVENT_DATES_UPDATE_EVENT_DATE_FAILURE = 'EVENT_DATES_UPDATE_EVENT_DATE_FAILURE';

export const EVENT_DATES_SAVE_EVENT_DATE_WARNING = 'EVENT_DATES_SAVE_EVENT_DATE_WARNING';

// Input field action
export const EVENT_DATES_CHANGE_FIELD_VALUE = 'EVENT_DATES_CHANGE_FIELD_VALUE';
export const GALA_EVENT_DATES_CHANGE_FIELD_VALUE = 'GALA_EVENT_DATES_CHANGE_FIELD_VALUE';

// Modal
export const EVENT_DATES_SET_MODAL_DATE = 'EVENT_DATES_SET_MODAL_DATE';

// Other
export const EVENT_DATES_RESET_EVENT_FIELDS = 'EVENT_DATES_RESET_EVENT_FIELDS';
export const EVENT_DATES_SET_EVENT_ID = 'EVENT_DATES_SET_EVENT_ID';

export const EVENT_DATES_ADD_EVENT = 'EVENT_DATES_ADD_EVENT';
export const EVENT_DATES_DELETE_EVENT = 'EVENT_DATES_DELETE_EVENT';
