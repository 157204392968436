import TopNavigationLayout from 'layouts/TopNavigationLayout';
import React from 'react';

// SGBC custom components
import QuestionnairesPanel from '../../membership/new/components/QuestionnairesPanel';

class Questionnaires extends React.Component {
    render() {
        return <QuestionnairesPanel applicationId={this.props.match ? this.props.match.params.id : 0} />;
    }
}
export default TopNavigationLayout(Questionnaires);
