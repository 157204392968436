import {
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST,
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE,
    TEAM_MANAGEMENT_SET_MODAL_DATA,
    TEAM_MANAGEMENT_RESET_FIELDS,
    TEAM_MANAGEMENT_RESET_INPUT_FIELDS,
    TEAM_MANAGEMENT_CHANGE_FIELD,
    TEAM_MANAGEMENT_HAS_EMAIL_USED,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE,
    TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE,
    TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER,
} from '../../actions/constants/actionTypesTeamManagement';
import { defaultModalKeys, setReducerModalState } from 'js/modal';
import validationlib from 'js/validation';

const defaultState = {
    teamMembers: [],
    tablePages: -1,
    totalItems: 0,
    loading: false,
    fields: {
        firstName: '',
        lastName: '',
        email: '',
    },
    validation: {},
    hasEmailUsed: false,
    ...defaultModalKeys,
};

export default function teamManagementReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST:
            newState.loading = true;
            return newState;
        case TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS:
            newState.loading = false;
            newState.teamMembers = action.response.data;
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalItems = action.response.totalNoOfItems;
            return newState;
        case TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE:
            newState.loading = false;
            return newState;
        case TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST || TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS:
            if (action.response.error === 'Email has been used') {
                newState.modalLoading = false;
                newState.modalAction = 'forceUpdate';
                newState.modalHeader = 'Error';
                newState.overrideModalWithMessage = true;
                newState.modalMessage = 'Email has been used';
                newState.modalOnlyOneButton = true;
            } else {
                newState.modalLoading = false;
                newState.modalAction = 'forceUpdate';
                newState.overrideModalWithMessage = true;
                newState.modalMessage = 'New member has been successfully added.';
                newState.modalOnlyOneButton = true;
            }
            return newState;
        case TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.overrideModalWithMessage = true;
            newState.modalMessage = 'New subscriber successfully added.';
            newState.modalOnlyOneButton = true;
            return newState;
        case TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.overrideModalWithMessage = true;
            newState.modalMessage = newState.hasEmailUsed ? 'Email has been used' : 'Failed to add team member. Please try again.';
            newState.modalOnlyOneButton = true;
            return newState;
        case TEAM_MANAGEMENT_HAS_EMAIL_USED:
            newState.hasEmailUsed = action.isUsed;
            return newState;
        case TEAM_MANAGEMENT_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            newState.modalUsage = false;
            newState.overrideModalWithMessage = action.overrideModalWithMessage;
            return newState;
        case TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER:
            newState = setReducerModalState(newState, action.data);
            newState.overrideModalWithMessage = true;
            newState.modalOnlyOneButton = true;
            newState.modalUsage = 'upgradeSubscriber';
            newState.modalMessage = 'Click OK to add subscriber to team member';
            return newState;
        case TEAM_MANAGEMENT_RESET_INPUT_FIELDS:
            newState.validation = {};
            newState.fields = Object.assign({}, defaultState.fields);
            return newState;
        case TEAM_MANAGEMENT_RESET_FIELDS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.validation = {};
            newState.modalBody = null;
            newState.modalAction = null;
            newState.modalOnlyOneButton = false;
            newState.modalLoading = false;
            return newState;
        case TEAM_MANAGEMENT_CHANGE_FIELD:
            newState.fields = Object.assign({}, state.fields);
            newState.fields[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            newState.validation[action.key] = validationlib.validate(action.key, action.value);
            return newState;
        case TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.overrideModalWithMessage = true;
            newState.modalMessage = 'Subscriber added as team member successfully.';
            newState.modalOnlyOneButton = true;
            return newState;
        case TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.overrideModalWithMessage = true;
            newState.modalMessage = 'Failed to add add subsciber as team member. Please try again.';
            newState.modalOnlyOneButton = true;
            return newState;
        default:
            return newState;
    }
}
