import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Row, Col, Button, Input } from 'reactstrap';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { getCompanies } from 'actions/security/cyderSecurityAction';
import { search } from 'actions/staff/mailinglist/action.js';

import { actionExecutor } from 'js/util';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.setMemberType = this.setMemberType.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            keyword: '',
            pageSize: 10,
            pageCount: 0,
            searchable: true,
            companyName: '',
            memberTypes: [
                {
                    value: 'member',
                    label: 'Member',
                },
                {
                    value: 'non-member',
                    label: 'Non-Member',
                },
            ],
            clearable: true,
            memberType: {
                value: 'member',
                label: 'Member',
            },
        };
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    setMemberType(e) {
        this.setState({
            memberType: e,
        });
        this.search(0, 10, null, null, e.value);
    }
    async exportAll() {
        try {
            this.setState({
                loading: true,
            });

            let totalNum = this.state.totalNum; //6498
            let itemPerPage = Math.ceil(totalNum / 10);

            var combineData = [];
            for (var i = 0; i < 10; i++) {
                const res = await this.props.search(this.state.keyword, this.state.memberType.value, 0, i, itemPerPage, null, null);
                var firstArray = combineData;
                combineData = firstArray.concat(res.data);
            }

            // const res = await this.props.search(this.state.keyword, this.state.memberType.value, 0, 0, 99999, null, null);
            const papa = require('papaparse');
            const parseConfig = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };

            var newData = combineData.map((element, i) => {
                return {
                    firstName: element.firstName,
                    lastName: element.lastName,
                    designation: element.designation,
                    email: element.email,
                    mobileNumber: element.mobileNumber,
                    company: element.realCompanyName,
                };
            });
            var csvPayload = papa.unparse(newData, parseConfig);
            var csv = 'data:text/csv;charset=utf-8,' + csvPayload;
            var data = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', 'mailing-list.csv');
            link.click();
        } catch (error) {
            throw error;
        } finally {
            this.setState({
                loading: false,
            });
        }
    }
    getCompanies = input => {
        const callback = async () => {
            const companiesRes = await this.props.getCompanies(input);
            const companies = companiesRes.data.map(company => {
                return {
                    label: company.name,
                    value: company.id,
                };
            });
            this.setState({
                companies,
            });
            return companies;
        };
        return actionExecutor(() => {}, callback);
    };
    onCompanyChange = e => {
        this.setState({ companyName: e.label });
    };
    search(page, pageSize, sorted, filtered, type) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, type ? type : this.state.memberType.value, this.state.companyName, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            { Header: 'First Name', accessor: 'firstName', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
            { Header: 'Last Name', accessor: 'lastName', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
            { Header: 'Designation', accessor: 'designation', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
            { Header: 'Email', accessor: 'email', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
            { Header: 'Contact Number', accessor: 'mobileNumber', headerStyle: { whiteSpace: 'unset' }, style: { whiteSpace: 'unset' } },
            {
                Header: 'Company',
                accessor: 'companyName',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => (props.original.realCompanyName ? props.original.realCompanyName : props.original.companyName),
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={'/admin/mailinglist/' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <Input
                                type="text"
                                placeholder="Keyword"
                                onKeyDown={e => {
                                    if (e.keyCode == 13) this.table.fireFetchData();
                                }}
                                onChange={this.onKeywordChange}
                                value={this.state.keyword}
                            />
                            <div style={{ width: '200px', marginLeft: '10px', marginRight: '10px' }}>
                                <Select
                                    autofocus
                                    simpleValue
                                    autosize={false}
                                    name="selected-state"
                                    options={this.state.memberTypes}
                                    clearable={this.state.clearable}
                                    disabled={this.state.disabled}
                                    value={this.state.memberType}
                                    onChange={this.setMemberType}
                                    searchable={this.state.searchable}
                                />
                            </div>
                            <Button className="ml-1 mr-1 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <div className="ml-auto">
                                <Button className="primary-btn-style" style={{ marginLeft: '5px' }} onClick={() => this.exportAll()}>
                                    <i className="mr-1 material-icons align-text-bottom ">print</i>
                                    Export {this.state.memberType.label}
                                </Button>
                                <Link to="/admin/mailinglist/0">
                                    <Button className="ml-1 mr-1 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, memberType, company, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, memberType, company, page, pageSize, sorted, filtered));
        },
        getCompanies: input => {
            return dispatch(getCompanies(input));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
