/**
 * IconWidget 6 (customised version)
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Card } from 'reactstrap';
import 'css/icon-widgets/icon-widget-6.css';

const IconWidget6 = ({
    textColor,
    backgroundColor,
    bg,
    bgStyle,
    headerFontSize,
    color,
    numberColor,
    icon,
    iconColor,
    text,
    number,
    padding,
    link,
    remarks,
    height = '100',
}) => {
    const bgVar = bg ? `bg-${bg}` : '';
    const style = {
        height: `${height}px`,
    };
    if (backgroundColor) style.backgroundColor = backgroundColor;
    return (
        <Card className={`icon-widget-6 ${bgVar} color-${color} p-${padding}`} onClick={link} style={bgStyle}>
            <Row className="justify-content-between align-items-center" style={style}>
                <Col className="text-left">
                    <Row>
                        <Col>
                            {!headerFontSize && (
                                <h4 className="text-bold" style={{ ...textColor }}>
                                    {text}
                                </h4>
                            )}
                            {headerFontSize && (
                                <h4 className="text-bold" style={{ fontSize: `${headerFontSize}px` }}>
                                    {text}
                                </h4>
                            )}
                        </Col>
                    </Row>
                    {icon && (
                        <Row>
                            <Col>
                                <h3 className="text-bold">{number}</h3>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <span style={{ whiteSpace: 'nowrap' }}>{remarks}</span>
                        </Col>
                    </Row>
                </Col>
                {icon && (
                    <Col className="text-right">
                        <i className={`material-icons ${iconColor} md-48`}>{icon}</i>
                    </Col>
                )}
                {!icon && (
                    <Col className="text-right">
                        <span className="number" style={{ fontSize: '36px', ...numberColor }}>
                            {number || '0'}
                        </span>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

IconWidget6.propTypes = {
    bg: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    colorColor: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.number,
};

IconWidget6.defaultProps = {
    padding: 10,
};

export default IconWidget6;
