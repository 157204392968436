import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

export const configObj = {
    layout: 'top-navigation-2',
    background: 'light',
    navbar: 'dark',
    logo: 'light',
    breadcrumb: false,
    banner: false,
    topNavigation: 'light',
};
const TopNavigation2 = compose(
    connect(state => {
        return {
            config: state.config,
        };
    }),
    lifecycle({
        componentDidMount() {
            this.props.dispatch({
                type: 'SET_CONFIG',
                config: configObj,
            });
        },
    }),
);

export default TopNavigation2;
