import {
    STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
    STAFF_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
    STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
    STAFF_MEMBERHISP_MEMBER_GET_ORGANISATION_CATGORIES_SUCCESS,
    STAFF_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS,
} from '../../../constants/actionTypesStaffMembershipMembers';

import fetchlib from '../../../../js/fetchlib';
import withQuery from 'with-query';
import cyderlib from '../../../../js/cyderlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';

export function staffMembershipMemberGetAllMemberDirectory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMemberDirectoryByStaff',
        });
        const body = {
            itemsPerPage: '500',
            page: 1,
            keyword: '',
            type: 'NotExpired',
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, STAFF_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS, null);
    };
}
export function staffMembershipMemberGetMemberDirectory(keyword, type, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMemberDirectoryByStaff',
        });
        const body = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword || '',
            type,
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
            STAFF_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
            STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
        );
    };
}
export function staffMembershipMemberGetOrganisationCategory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMainOrganisationCategory',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            null,
            STAFF_MEMBERHISP_MEMBER_GET_ORGANISATION_CATGORIES_SUCCESS,
            null,
        );
    };
}
export function staffMembershipMemberGetPublicMemberDirectory(
    type,
    keyword,
    category,
    alphabetical,
    tier,
    page,
    pageSize,
    sorted,
    filtered,
) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getPublicMemberDirectory',
        });
        const body = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword || '',
            type,
            tier,
            category,
            alphabetical,
            sorted,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
