import {
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE,
    GET_COMPANY_REP_INFO_REQUEST,
    GET_COMPANY_REP_INFO_SUCCESS,
    GET_COMPANY_REP_INFO_FAILURE,
    SET_MODAL_ACTION,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION,
} from '../constants/actionTypesTeamManagement';
import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

// Member - team management
export function getSalutationTypes() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/salutations`, {
            action: 'getSalutation',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE,
        );
    };
}
export function onChangeValue(e) {
    return {
        type: TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD,
        key: e.target.dataset.valueKey,
        value: e.target.value,
    };
}
export function onChangeSalutation(e) {
    return {
        type: TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION,
        key: 'salutation',
        value: e.value,
        label: e.label,
    };
}
export function forceValidate() {
    return dispatch => {
        dispatch({
            type: TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE,
        });
        return Promise.resolve();
    };
}
export function resetState() {
    return {
        type: TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE,
    };
}
export function setModalData(data) {
    return {
        type: TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA,
        data,
    };
}

export function forgotPassword(email) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/auth`, {
            action: 'forgotpassword',
        });
        const body = {
            emailaddress: email,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE,
        );
    };
}

export function getCompanyRepInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getcompanyrepinfo',
            companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            GET_COMPANY_REP_INFO_REQUEST,
            GET_COMPANY_REP_INFO_SUCCESS,
            GET_COMPANY_REP_INFO_FAILURE,
        );
    };
}
export function memberGetMemberDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'findbyIdForUser',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE,
        );
    };
}
export function changeUserStatus(suspend) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'changeUserStateByUser',
        });
        const body = {
            userId: getState().teamMembersDetailsReducer.fields.userId,
            // pushes in 1 if truthy, else 0
            suspend: suspend ? 1 : 0,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE,
        );
    };
}
export function memberVerifyMember() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'verifyTeamMemberInfoByUser',
        });
        const body = {
            userId: getState().teamMembersDetailsReducer.fields.userId,
            id: getState().teamMembersDetailsReducer.fields.id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE,
        );
    };
}

export function memberRemoveUserFromOrg() {
    return (dispatch, getState) => {
        // Shared modalAction on reducer state
        dispatch({
            type: SET_MODAL_ACTION,
            modalAction: '/member/myaccount/teammanagement',
        });
        // Fetch operation
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'removeTeamMemberByUser',
        });
        const body = {
            userId: getState().teamMembersDetailsReducer.fields.userId,
            id: getState().teamMembersDetailsReducer.fields.id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE,
        );
    };
}

export function transferMember(personId, companyId, isTeamMember) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_MODAL_ACTION,
            modalAction: '../team',
        });
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'transfer-member',
        });
        const body = { personId, companyId, isTeamMember };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE,
        );
    };
}

export function memberSaveMemberProfile() {
    return (dispatch, getState) => {
        // Shared modalAction on reducer state
        dispatch({
            type: SET_MODAL_ACTION,
            modalAction: '/member/myaccount/teammanagement',
        });
        // Fetch operation
        const currentFields = getState().teamMembersDetailsReducer.fields;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'updateProfileByUser',
        });
        const body = {
            id: currentFields.id,
            salutation: currentFields.salutation ? currentFields.salutation.label : null,
            firstName: currentFields.firstName,
            lastName: currentFields.lastName,
            email: currentFields.email,
            designation: currentFields.designation,
            telephoneDid: currentFields.telephoneDid,
            mobileNumber: currentFields.mobileNumber,
            department: currentFields.department,
            isCertCoordinator: currentFields.isCertCoordinator,
            isCorporateAdmin: currentFields.isCorporateAdmin,
            isOmr: currentFields.isOmr,
            isHrtrainingCoordinator: currentFields.isHrtrainingCoordinator,
            //isSecondarymr: currentFields.isSecondarymr,
            userId: currentFields.userId,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE,
        );
    };
}

// Staff - team management
export function staffGetMemberDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'getTeamMemberById',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE,
        );
    };
}
export function staffSaveMemberProfile(companyId, id) {
    return (dispatch, getState) => {
        // Shared modalAction on reducer state
        dispatch({
            type: SET_MODAL_ACTION,
            modalAction: '../team',
        });
        // Fetch operation
        const currentFields = getState().teamMembersDetailsReducer.fields;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'updateTeamMember',
        });
        const body = {
            salutation: currentFields.salutation.label,
            salutationId: currentFields.salutation.value,
            firstName: currentFields.firstName,
            lastName: currentFields.lastName,
            email: currentFields.email,
            designation: currentFields.designation,
            telephoneDiD: currentFields.telephoneDid,
            mobileNumber: currentFields.mobileNumber,
            department: currentFields.department,
            isCertCoordinator: currentFields.isCertCoordinator,
            isCorporateAdmin: currentFields.isCorporateAdmin,
            isOmr: currentFields.isOmr,
            isSecondarymr: currentFields.isSecondarymr,
            isHrtrainingCoordinator: currentFields.isHrtrainingCoordinator,
            companyId,
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE,
        );
    };
}
export function staffVerifyMember() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'verifyTeamMemberInfoByStaff',
        });
        const body = {
            userId: getState().teamMembersDetailsReducer.fields.userId,
            id: getState().teamMembersDetailsReducer.fields.id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE,
        );
    };
}
export function staffRemoveUserFromOrg() {
    return (dispatch, getState) => {
        // Shared modalAction on reducer state
        dispatch({
            type: SET_MODAL_ACTION,
            modalAction: '../team',
        });
        // Fetch operation
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'removeTeamMemberByStaff',
            id: getState().teamMembersDetailsReducer.fields.id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST,
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE,
        );
    };
}
