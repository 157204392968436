import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import React from 'react';
import InputFieldWithValidation from 'reactstrap-input';
import { Label, Form, FormGroup, Input, Button, Row, Col } from 'reactstrap';
import FileUploadPanel from '../../../member/certification/applications/shared/FileUploadPanel';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';

const MainComponent = props => {
    return (
        <Form>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Event Name"
                        value={props.data.name}
                        valueKey="name"
                        validationObj={{}}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Event Summary"
                        rows={4}
                        value={props.data.summary}
                        valueKey="summary"
                        validationObj={{}}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        rows={4}
                        label="Event Description"
                        valueKey="description"
                        value={props.data.description}
                        validationObj={props.validation}
                        handleChange={props.handleEventInputChange}
                        saveButtonPressed={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Event Venue"
                        rows={4}
                        value={props.data.venue}
                        valueKey="venue"
                        validationObj={{}}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Guest of Honour"
                        rows={4}
                        value={props.data.guestOfHonour}
                        valueKey="guestOfHonour"
                        validationObj={{}}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Sponsors"
                        valueKey="sponsors"
                        validationObj={{}}
                        value={props.data.sponsors}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
                <Col xs={6}>
                    <InputFieldWithValidation
                        type="textarea"
                        label="Co-organizers Partners"
                        valueKey="coOrganizersPartners"
                        validationObj={{}}
                        value={props.data.coOrganizersPartners}
                        handleChange={props.handleEventInputChange}
                    />
                </Col>
            </Row>
            <div className="d-flex mt-3">
                <Button className="primary-btn-style" onClick={props.eventid ? props.updateEvent : props.addEvent}>
                    Save Main Details
                </Button>
                {props.eventid ? (
                    <Button className="ml-auto primary-btn-style-outline" onClick={props.deleteEvent}>
                        Delete Entire Event
                    </Button>
                ) : null}
            </div>
        </Form>
    );
};

export default MainComponent;
