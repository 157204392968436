import React from 'react';
import ReactTable from 'react-table';
import { Input, Row, Col, Button, Label, Alert } from 'reactstrap';

const RatingsComponent = ({ ratings, onChangeRating, removeRating, addRating, ratingError, readOnly }) => (
    <React.Fragment>
        <Row>
            {readOnly != true && (
                <Col xs={12}>
                    <Label>
                        <u>Ratings</u>
                    </Label>
                    <ReactTable
                        className={'-highlight mb-2'}
                        showPagination={false}
                        minRows={1}
                        data={ratings}
                        columns={[
                            {
                                Header: 'Code',
                                accessor: 'code',
                                maxWidth: 100,
                            },
                            {
                                Header: 'Description',
                                accessor: 'description',
                                Cell: props => (
                                    <Input
                                        data-rating-index={props.index}
                                        data-key="description"
                                        data-code={props.row.code}
                                        data-description={props.row.description}
                                        data-sortkey={props.row.sortkey}
                                        value={props.value}
                                        onChange={onChangeRating}
                                    />
                                ),
                            },
                        ]}
                    />
                </Col>
            )}
            {ratings != null && ratings.length > 0 && readOnly == true && (
                <Col xs={12}>
                    <Label>
                        <u>Ratings</u>
                    </Label>
                    <ReactTable
                        className={'-striped -highlight'}
                        showPagination={false}
                        data={ratings}
                        minRows={1}
                        columns={[
                            {
                                Header: 'Code',
                                accessor: 'code',
                                maxWidth: 75,
                            },
                            {
                                Header: 'Description',
                                accessor: 'description',
                                style: { whiteSpace: 'unset' },
                                minWidth: 150,
                            },
                        ]}
                    />
                </Col>
            )}
        </Row>
    </React.Fragment>
);

export default RatingsComponent;
