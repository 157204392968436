/**
 * Member certifications reducer. This is the common one for both Services and Product certification
 *
 * Author: Fernando Karnagi
 */

var defaultState = {};

export function memberCertificationsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        default:
            return newState;
    }
}
