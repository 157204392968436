import React from 'react';

const Search = () => (
    <div className="navbar-search-form">
        <div className="form-group">
            <div className="input-group">
                <input type="text" className="form-control" placeholder="" />
                <span className="input-group-addon">
                    <i className="material-icons">search</i>
                </span>
            </div>
        </div>
    </div>
);

export default Search;
