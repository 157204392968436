/**
 * Member service renew reducer
 *
 * Author: Fernando Karnagi
 */

var defaultState = {
    brandEditable: false,
    updateAssessment: false,
    certification: {
        certificationNumber: 'SGBP 2017-950',
        category: 'Mechanical > Pump > Chilled/Condenser Water	',
        rating: '1',
        issued: '1 October 2018',
        validTill: '31 December 2018',
        brand: 'eNSC Series',
        model: '151-412,152-133',
        validity: 'In-Review',
    },
    productBrands: [
        {
            brand: 'IPhone',
            models: [
                {
                    model: 'IPhone 6',
                    description: '32 GB RAM',
                },
                {
                    model: 'IPhone 7',
                    description: '64 GB RAM',
                },
            ],
        },
    ],
    assessments: {
        sectionA: [
            {
                serialNo: 'A1',
                type: 'EE',
                title: 'Energy Management during Manufacturing Process',
                description: 'Evidence of the manufacturing plant’s energy management policies is in place.',
                documentsToSubmit: ['1) Energy Management Policies', '2) Letter of Declaration From CEO/ Authorised Representative'],
            },
            {
                serialNo: 'A2',
                type: 'WE',
                title: 'Water Management during Manufacturing Process',
                description:
                    'Evidence of the manufacturing plant’s water management policies is in place. Should there be no water consumption for the manufacturing process, a confirmation in writing shall be provided and signed by the Chief Executive Officer or Authorised Representative of the applicant company.  The written confirmation shall be deemed a fulfilment of the Pre-Requisite requirement listed in item above.',
                documentsToSubmit: ['1) Energy Management Policies', '2) Letter of Declaration From CEO/ Authorised Representative'],
            },
        ],
        sectionB: [
            {
                serialNo: 'B1',
                type: 'RE',
                title: 'Product – Clinker Content',
                condition: 'Clicker Content',
                ticks: [
                    {
                        tick: 1,
                        value: '≤ 400kg/m3',
                    },
                    {
                        tick: 2,
                        value: '≤ 380kg/m3',
                    },
                    {
                        tick: 3,
                        value: '≤ 360kg/m3',
                    },
                    {
                        tick: 4,
                        value: '≤ 340kg/m3',
                    },
                ],
                documentsToSubmit: ['1) Concrete Mix Design', '2) Letter of Declaration from CEO / Authorised Representative'],
            },
            {
                serialNo: 'B2',
                type: 'EE',
                title: 'Product – Test Material',
                condition: 'Test Material',
                ticks: [
                    {
                        tick: 1,
                        value: '≤ 400',
                    },
                    {
                        tick: 2,
                        value: '≤ 380',
                    },
                    {
                        tick: 3,
                        value: '≤ 360',
                    },
                    {
                        tick: 4,
                        value: '≤ 340',
                    },
                ],
                documentsToSubmit: ['1) Energy Management Policies', '2) Letter of Declaration From CEO/ Authorised Representative'],
            },
        ],
        sectionC: [
            {
                serialNo: 'C1',
                type: 'EE',
                title: 'Energy Management System',
                description:
                    'Evidence of certification to ISO 50001: Energy Management Systems or equivalent by an accredited certification body.',
                documentsToSubmit: ['1) ISO 50001 Certificate'],
            },
            {
                serialNo: 'C2',
                type: 'RE',
                title: 'Use of Recycled Concrete Aggregates and/or Washed Copper Slag ',
                description: 'Use of Recycled Concrete Aggregates and/or Washed Copper Slag',
                documentsToSubmit: ['1) Concrete Mix Design', '2) Letter of Declaration from CEO / Authorised Representative'],
            },
        ],
    },
};

export function memberServiceRenewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBERSHIP_SERVICE_CERTIFICATION_RENEW_SET_BRAND_EDITABLE':
            // newState.new = Object.assign({}, state.new);
            newState.brandEditable = action.editable;
            return newState;
        case 'MEMBERSHIP_SERVICE_CERTIFICATION_RENEW_SET_ASSESSMENT_UPDATE':
            // newState.new = Object.assign({}, state.new);
            newState.updateAssessment = action.update;
            return newState;
        default:
            return newState;
    }
}
