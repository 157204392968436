/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch, Route } from 'react-router';

// PrivateRoute
import PrivateRoute from './cyder/common/PrivateRoute';

import PublicMemberDirectory from './pages/public/PublicMemberDirectory';
import ChangePassword from './pages/changepassword/ChangePassword';
import ErrorPage from './pages/error-page';

// Members - Home
import MemberHomeOld from './pages/member/home/MemberHomeOld';
import MemberProfileEdit from './pages/member/myaccount/MemberProfileEdit';
import PersonalProfile from './pages/profile/PersonalProfile';

// Team Management
import MemberTeamManagementPage from './pages/member/myaccount/MemberTeamManagementPage';
import TeamMemberDetailsPage from './pages/shared/TeamMemberDetailsPage';

// Members - Membership
import MembershipOverview from './pages/member/membership/overview/MembershipOverview';
import MembershipApplicationsList from './pages/member/membership/overview/MembershipApplicationsList';
import MemberMembershipNewApplicationPage from './pages/member/membership/new/MemberMembershipNewApplicationPage';
import MemberMembershipViewApplicationPage from './pages/member/membership/view/MemberMembershipViewApplicationPage';
import MemberSearch from './pages/member/search';
import Questionnaires from './pages/member/membership/questionnaires/Questionnaires';
import MemberMembershipPayment from './pages/member/membership/payment';

// Members - Certifications
import MemberCertNewApplicationPage from './pages/member/certification/applications/shared/MemberCertNewApplicationPage';
import MemberCertNewApplicationSelectionPage from './pages/member/certification/applications/shared/MemberCertNewApplicationSelectionPage';
import MemberCertDetailsPage from './pages/member/certification/applications/shared/MemberCertDetailsPage';
import MemberCertSurveyPage from './pages/member/certification/applications/shared/MemberCertSurveyPage';
import MemberProductApplicationViewPage from './pages/member/certification/applications/product/ApplicationViewPage';
import MemberProductAssessmentPage from './pages/member/certification/applications/product/ProductAssessmentPage';
import MemberProductRenewPage from './pages/member/certification/applications/product/ProductRenewPage';
import MemberProductRenewTermsConditionsPage from './pages/member/certification/applications/product/ProductRenewTermsConditionsPage';
import MemberProductNewReviewPage from './pages/member/certification/applications/product/NewReviewPage';
import MemberServiceApplicationViewPage from './pages/member/certification/applications/service/ApplicationViewPage';
import MemberServiceAssessmentPage from './pages/member/certification/applications/service/ServiceAssessmentPage';
import MemberServiceRenewPage from './pages/member/certification/applications/service/ServiceRenewPage';
import MemberServiceRenewTermsConditionsPage from './pages/member/certification/applications/service/ServiceRenewTermsConditionsPage';
import MemberServiceNewReviewPage from './pages/member/certification/applications/product/NewReviewPage';
import MemberCertificationOverviewPage from './pages/member/certification/overview/MemberCertificationOverviewPage';
import MemberCertificationApplicationListPage from './pages/member/certification/applications/overview/MemberCertificationApplicationListPage';
import MemberCertificationListPage from './pages/member/certification/applications/overview/MemberCertificationListPage';
import MembershipDirectoryPage from './pages/member/membership/directory/MembershipDirectoryPage';
import MemberDetailPage from './pages/member/membership/directory/MemberDetailPage';

import SurveysDetailPage from './pages/surveys/SurveysDetailPage';

// Members - Events
import EventsRsvpPage from './pages/events/EventsRsvpPage';
import EventAttendeePage from './pages/staff/events/events/EventsAttendeePage';
import EventsRegistrationPage from './pages/staff/events/events/EventsRegistrationPage';
import EventRegistrationPage from './pages/events/EventRegistrationPage';
import EventRegisteredAttendantsPage from './pages/events/EventRegisteredAttendantsPage';
import EventPaymentPage from './pages/events/payment/EventPaymentPage';
import EventPayNowPage from './pages/events/payment/EventPayNowPage';
import CertificatesPayNowPage from './pages/member/certification/applications/payment/CertificatesPayNowPage';
import MembershipPayNowPage from './pages/member/membership/payment/MembershipPayNowPage';
import MemberEventListPage from './pages/member/events/event/ListPage';
import MemberEventAddPage from './pages/member/events/event/AddPage';

import ResourceListPage from './pages/member/resources/ListPage';

const Routes = () => (
    <Switch>
        <Route exact path="/member/myaccount/changepassword" component={ChangePassword} />
        <PrivateRoute exact path="/member/myaccount/teammanagement" component={MemberTeamManagementPage} />
        <PrivateRoute exact path="/member/myaccount/teammanagement/member/:memberid" component={TeamMemberDetailsPage} />
        <PrivateRoute exact path="/member/myaccount/personalprofile" component={PersonalProfile} />
        {/* member */}
        <PrivateRoute exact path="/member/home" component={MemberHomeOld} />
        <PrivateRoute exact path="/member/search" component={MemberSearch} />
        <PrivateRoute exact path="/member/myaccount/dashboard" component={MemberHomeOld} />
        <PrivateRoute exact path="/member/myaccount/companyprofile" component={MemberProfileEdit} />
        {/* member membership */}
        <PrivateRoute exact path="/member/membership/overview" component={MembershipOverview} />
        <PrivateRoute exact path="/member/membership/applicationlist" component={MembershipApplicationsList} />
        <PrivateRoute exact path="/member/membership/newapplication" type={`New`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/newapplication/:step" type={`New`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/newapplication/:step/:id" type={`New`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/renewapplication/:step/:id" type={`Renew`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/renewapplication" type={`Renew`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/renewapplication/:step" type={`Renew`} component={MemberMembershipNewApplicationPage} />
        <PrivateRoute exact path="/member/membership/viewapplication/:id" component={MemberMembershipViewApplicationPage} />
        <PrivateRoute exact path="/member/membership/questionnaires" component={Questionnaires} />
        <PrivateRoute exact path="/member/membership/questionnaires/:id" component={Questionnaires} />
        <PrivateRoute exact path="/member/memberdirectory" component={MembershipDirectoryPage} />
        <PrivateRoute exact path="/member/memberdirectory/:companyid" component={MemberDetailPage} />
        <PrivateRoute exact path="/member/membership/payment" component={MemberMembershipPayment} />
        <PrivateRoute exact path="/member/membership/application/:id/paynow" component={MembershipPayNowPage} />
        {/* member certification */}
        <PrivateRoute exact path="/member/certification/selection" component={MemberCertNewApplicationSelectionPage} />
        <PrivateRoute exact path="/member/certification/product/application/:id" component={MemberCertNewApplicationPage} type="product" />
        <PrivateRoute exact path="/member/certification/product/renew/application/:id" component={MemberCertNewApplicationPage} type="product" />
        <PrivateRoute exact path="/member/certification/service/renew/application/:id" component={MemberCertNewApplicationPage} type="service" />
        <PrivateRoute exact path="/member/certification/product/newapplication" component={MemberCertNewApplicationPage} type="product" />
        <PrivateRoute exact path="/member/certification/product/newapplication/:certId/:certNum" component={MemberCertNewApplicationPage} type="product" />
        <PrivateRoute exact path="/member/certification/product/viewapplication" component={MemberProductApplicationViewPage} />
        <PrivateRoute exact path="/member/certification/product/viewassessment/:applicationid/:templateid/:scoresheetid" component={MemberProductAssessmentPage} type="product" />
        <PrivateRoute exact path="/member/certification/product/viewassessment/renew/:certid/:templateid/:scoresheetid" component={MemberProductAssessmentPage} type="product" />
        <PrivateRoute exact path="/member/certification/product/renew/:certId" component={MemberProductRenewTermsConditionsPage} />
        <PrivateRoute exact path="/member/certification/product/newreview/:certId" component={MemberProductNewReviewPage} type="product" />
        <PrivateRoute exact path="/member/certification/service/renew/:certId" component={MemberServiceRenewTermsConditionsPage} />

        <PrivateRoute exact path="/member/certification/product/reviewassessment/:certId" component={MemberCertNewApplicationPage} />
        <PrivateRoute exact path="/member/certification/service/application/:id" component={MemberCertNewApplicationPage} type="service" />
        <PrivateRoute exact path="/member/certification/service/newapplication" component={MemberCertNewApplicationPage} type="service" />
        <PrivateRoute exact path="/member/certification/service/newapplication/:certId/:certNum" component={MemberCertNewApplicationPage} type="service" />
        <PrivateRoute exact path="/member/certification/service/viewapplication" component={MemberServiceApplicationViewPage} />
        <PrivateRoute exact path="/member/certification/service/viewassessment/:applicationid/:templateid/:scoresheetid" component={MemberProductAssessmentPage} type="service" />
        <PrivateRoute exact path="/member/certification/service/viewassessment/renew/:certid/:templateid/:scoresheetid" component={MemberProductAssessmentPage} type="service" />
        <PrivateRoute exact path="/member/certification/service/renew" component={MemberServiceRenewPage} />
        <PrivateRoute exact path="/member/certification/service/newreview/:certId" component={MemberServiceNewReviewPage} type="service" />
        <PrivateRoute exact path="/member/certification/overview" component={MemberCertificationOverviewPage} />
        <PrivateRoute exact path="/member/certification/product/applications" component={MemberCertificationApplicationListPage} type={'product'} />
        <PrivateRoute exact path="/member/certification/product/applications/all" component={MemberCertificationApplicationListPage} type={'product'} />
        <PrivateRoute exact path="/member/certification/service/applications" component={MemberCertificationApplicationListPage} type={'service'} />
        <PrivateRoute exact path="/member/certification/service/applications/all" component={MemberCertificationApplicationListPage} type={'service'} />

        <PrivateRoute exact path="/member/certification/product/confirm/:id" component={MemberCertDetailsPage} type="product" />
        <PrivateRoute exact path="/member/certification/service/confirm/:id" component={MemberCertDetailsPage} type="service" />

        <PrivateRoute exact path="/member/certification/product/survey/:id/:applicationId" component={MemberCertSurveyPage} type="product" />
        <PrivateRoute exact path="/member/certification/service/survey/:id/:applicationId" component={MemberCertSurveyPage} type="service" />

        <PrivateRoute exact path="/member/certification/product/certificates" component={MemberCertificationListPage} mode={'product'} />
        <PrivateRoute exact path="/member/certification/service/certificates" component={MemberCertificationListPage} mode={'service'} />
        <PrivateRoute exact path="/member/surveys/view/:surveyid" component={SurveysDetailPage} />
        <PrivateRoute exact path="/member/certification/application/:id/paynow/:type" component={CertificatesPayNowPage} />
        <PrivateRoute exact path="/member/certification/application/:id/paynow" component={CertificatesPayNowPage} />
        {/* member - events */}
        <PrivateRoute exact path="/member/event/rsvp/:eventid/confirmation" component={EventsRsvpPage} />
        <PrivateRoute exact path="/member/event/rsvp/:eventid/form" component={EventsRsvpPage} />
        <PrivateRoute exact path="/member/event/rsvp/:eventid/tix" component={EventsRsvpPage} />
        <PrivateRoute exact path="/member/event/:eventid/registration" component={EventRegistrationPage} mode={'registration'} />
        <PrivateRoute exact path="/member/event/:eventid/attendance" component={EventRegistrationPage} mode={'attendance'} />
        <PrivateRoute exact path="/member/event/rsvp/:eventid/registration/:registrationid" component={EventsRsvpPage} />
        <PrivateRoute exact path="/member/event/registration/:registrationid/attendant" component={EventRegisteredAttendantsPage} />
        <PrivateRoute exact path="/member/event/registration/:registrationid/payment" component={EventPaymentPage} />
        <PrivateRoute exact path="/member/event/registration/:registrationid/paynow" component={EventPayNowPage} />
        <PrivateRoute exact path="/member/event/rsvp/:eventid" component={EventsRsvpPage} />
        <PrivateRoute exact path="/member/events/event/:id" component={MemberEventAddPage} />
        <PrivateRoute exact path="/member/events/event" component={MemberEventListPage} />
        <PrivateRoute exact path="/member/events/event/:eventid/attendees/:companyid" component={EventAttendeePage} />
        <PrivateRoute exact path="/member/events/event/:eventid/registrations/:companyid" component={EventsRegistrationPage} />
        <PrivateRoute exact path="/member/misc/resources" component={ResourceListPage} />
        {/* Error */}
        <PrivateRoute path="/" component={ErrorPage} />
    </Switch>
);

export default Routes;
