import StoredLayout from '../../../../layouts/StoredLayout';
import LoadingSpinner from '../../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../../cyder/modals/DialogModal';
import LegendIcon from '../../../../cyder/media/LegendIcon';
import CyderHeaderPanel from '../../../../cyder/panels/CyderHeaderPanel';
import CyderDatePicker from '../../../../cyder/forms/CyderDatePicker';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import withQuery from 'with-query';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    FormText,
    Button,
    Card,
    CardBody,
    CardText,
    CardHeader,
    Badge,
    Collapse,
    Table,
} from 'reactstrap';
import DetailsPageFormSection1 from './DetailsPageFormSection1';

import {
    toggleCheckboxes,
    toggleCheckboxesAll,
    staffMembershipRenewalsSearchAction,
} from '../../../../actions/staff/membership/renewals/staffMembershipRenewalsDetailsAction.js';

import cyderlib from '../../../../js/cyderlib.js';
import '../../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import moment from 'moment';

class StaffMembershipRenewalsDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            enterPaymentDetailsToggle: false,
        };
    }
    componentDidMount() {
        moment.relativeTimeThreshold('d', 999);
    }
    toggle() {
        this.setState({
            enterPaymentDetailsToggle: !this.state.enterPaymentDetailsToggle,
        });
    }
    render() {
        const ed = this.props.renewal.expiry_date;
        const expireText =
            moment().diff(ed) * -1 < 0 ? 'Expired' : 'Expires' + ' ' + moment.duration(moment().diff(ed) * -1).humanize(true);
        const rightText = (
            <div className="align-middle">
                <Badge className="badge-sm" color="default">
                    {expireText}
                </Badge>
                <span className="align-middle">&nbsp;Expiry Date: {moment(ed).format('MMMM D, YYYY')}</span>
            </div>
        );
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <CyderHeaderPanel leftText="Membership Renewal ID: 1307" rightText={rightText} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h6 className="float-left">
                            Status:&nbsp;
                            <LegendIcon status={this.props.renewal.status} />
                        </h6>
                        {/*
                        <span className="float-right text-right">
                            <Button color="default" className="mb-1">
                                View Application Form
                            </Button>
                            <br/>
                            <Button color="warning" className="mb-1">
                                Edit Application on company's behalf
                            </Button>
                            <br/>
                            <Button color="danger" className="mb-1">
                                Reject Application
                            </Button>
                        </span>
                        */}
                    </Col>
                </Row>
                <div className="row">
                    <Col md={4} lg={4}>
                        <h4>Membership Details</h4>
                        <CardBody>
                            <strong>Company:</strong> Avenger Co
                            <br />
                            <br />
                            <strong>Date Joined:</strong> April 17, 2018
                            <br />
                            <br />
                            <span className="align-middle">
                                <strong>Expiry Date</strong>: {moment(ed).format('MMMM D, YYYY')}
                                &nbsp;
                            </span>
                            <Badge className="badge-sm" color="default">
                                {expireText}
                            </Badge>
                            <br />
                            <br />
                            <strong>Member ID:</strong> 618
                            <br />
                            <br />
                            <Link className="mb-1 btn btn-info btn-outline" to="#">
                                <i className="fa fa-certificate" /> View Certificate
                            </Link>
                            <br />
                            <Button color="danger">Regenerate Membership Certificate</Button>
                        </CardBody>
                    </Col>
                    <Col md={8} lg={8}>
                        <Card>
                            <CardHeader>Renewal Details</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={7} lg={7}>
                                        <Form method="post" action="" className="mb-2">
                                            <FormGroup>
                                                <Label>Remarks</Label>
                                                <Input
                                                    type="textarea"
                                                    className="textarea
                                                    form-control"
                                                    cols="40"
                                                    id="id_remarks"
                                                    maxLength="1000"
                                                    name="remarks"
                                                    rows="4"
                                                />
                                            </FormGroup>
                                            <div id="div_id_membership_renewal_status" className="form-group">
                                                <Label>Membership renewal status</Label>
                                                <Input type="select" id="id_membership_renewal_status">
                                                    <option value="">---------</option>
                                                    <option value="1">No Response</option>
                                                    <option value="2">Pending Payment</option>
                                                    <option value="3">Unsure if want to renew</option>
                                                    <option value="4">Do not wish to renew</option>
                                                    <option value="5">Successfully Renewed</option>
                                                    <option value="6">Do not wish to renew</option>
                                                </Input>
                                            </div>
                                            <div className="hide-me">
                                                <FormGroup>
                                                    <Label>Membership package</Label>
                                                    <Input type="select" id="id_membership_package">
                                                        <option value="">---------</option>
                                                        <option value="1">1 Year New Membership - S$1605.00 (incl. GST)</option>
                                                        <option value="2">1/2 Year New Membership - S$802.50 (incl. GST)</option>
                                                        <option value="3">2 Year Promotion (10% discount) - S$2889.00 (incl. GST)</option>
                                                        <option value="4">Renew for 2 Years - S$2889.00 (incl. GST)</option>
                                                        <option value="5">Renew for 1 Year - S$1605.00 (incl. GST)</option>
                                                        <option value="6">
                                                            1.5 Year New Membership (10% discount) - S$2166.75 (incl. GST)
                                                        </option>
                                                    </Input>
                                                </FormGroup>
                                            </div>
                                            <Button block color="warning" id="membershipRenewalKey">
                                                Save Status and Remarks
                                            </Button>
                                        </Form>
                                        <div className="text-center">
                                            <Button color="link" onClick={this.toggle}>
                                                <h5>
                                                    <span className="text-info">
                                                        <i className="fa fa-pencil" />
                                                        &nbsp; Enter Payment &amp; Renewal Details
                                                    </span>
                                                </h5>
                                            </Button>
                                        </div>
                                        <Collapse isOpen={this.state.enterPaymentDetailsToggle}>
                                            <Form>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={6} lg={6} className="col-lg-6 col-md-6">
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Amount received</Label>
                                                                        <Input id="id_amount_received" step="0.01" type="number" />
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Date payment received</Label>
                                                                        <CyderDatePicker />
                                                                        <FormText>YYYY-MM-DD</FormText>
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Mode of payment</Label>
                                                                        <Input type="select" id="id_mode_of_payment">
                                                                            <option value="">---------</option>
                                                                            <option value="1">Cheque</option>
                                                                            <option value="2">Wire or Bank Transfer</option>
                                                                            <option value="3">Cash</option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Invoice number</Label>
                                                                        <Input
                                                                            id="id_invoice_number"
                                                                            maxLength="100"
                                                                            name="invoice_number"
                                                                            type="text"
                                                                        />
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Bank</Label>
                                                                        <Input id="id_bank" maxLength="200" name="bank" type="text" />
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Reference number</Label>
                                                                        <Input
                                                                            id="id_reference_number"
                                                                            maxLength="100"
                                                                            name="reference_number"
                                                                            type="text"
                                                                        />
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormText>
                                                                        <Label>Remarks</Label>
                                                                        <Input id="id_remarks" maxLength="200" name="remarks" type="text" />
                                                                    </FormText>
                                                                </span>
                                                            </Col>
                                                            <Col md={6} lg={6}>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Membership package</Label>
                                                                        <Input type="select" id="id_membership_package">
                                                                            <option value="">---------</option>
                                                                            <option value="1">
                                                                                1 Year New Membership - S$1605.00 (incl. GST)
                                                                            </option>
                                                                            <option value="2">
                                                                                1/2 Year New Membership - S$802.50 (incl. GST)
                                                                            </option>
                                                                            <option value="3">
                                                                                2 Year Promotion (10% discount) - S$2889.00 (incl. GST)
                                                                            </option>
                                                                            <option value="4">
                                                                                Renew for 2 Years - S$2889.00 (incl. GST)
                                                                            </option>
                                                                            <option value="5">
                                                                                Renew for 1 Year - S$1605.00 (incl. GST)
                                                                            </option>
                                                                            <option value="6">
                                                                                1.5 Year New Membership (10% discount) - S$2166.75 (incl.
                                                                                GST)
                                                                            </option>
                                                                        </Input>
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>Renewal date</Label>
                                                                        <CyderDatePicker />
                                                                    </FormGroup>
                                                                </span>
                                                                <span>
                                                                    <FormGroup>
                                                                        <Label>New expiry date</Label>
                                                                        <CyderDatePicker />
                                                                    </FormGroup>
                                                                </span>
                                                                <div className="text-center">
                                                                    <Button
                                                                        className="mb-2"
                                                                        id="membershipRenewalCompleteKey"
                                                                        style={{
                                                                            whiteSpace: 'normal',
                                                                        }}
                                                                    >
                                                                        Mark Renewal as Complete
                                                                    </Button>
                                                                    <FormGroup>
                                                                        Membership Certificate will be Auto-Generated but not emailed. Email
                                                                        notifying company of successful renewal will be sent.
                                                                    </FormGroup>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Form>
                                        </Collapse>
                                    </Col>
                                    <Col md={5} lg={5}>
                                        <h5>Renewal Documents</h5>
                                        <Table size="sm">
                                            <tbody>
                                                <RenewalTableRow title="Renewal Letter" />
                                                <RenewalTableRow title="Renewal Notice" />
                                                <RenewalTableRow title="Renewal Notice (Late)" />
                                                <RenewalTableRow title="Warning Letter" />
                                                <RenewalTableRow title="Termination Letter" />
                                                <RenewalTableRow title="Membership Certificate" />
                                            </tbody>
                                        </Table>
                                        <h5>Renewal Emails</h5>
                                        <Table size="sm">
                                            <tbody>
                                                <RenewalTableRow email title="60 days Pre-expiry" />
                                                <RenewalTableRow email title="45 days Pre-expiry" />
                                                <RenewalTableRow email title="30 days Pre-expiry" />
                                                <RenewalTableRow email title="15 days Pre-expiry" />
                                                <RenewalTableRow email title="1 day Post-expiry" />
                                                <RenewalTableRow email title="15 day Post-expiry" />
                                                <RenewalTableRow email title="30 day Post-expiry" />
                                                <RenewalTableRow email title="45 day Post-expiry" />
                                                <RenewalTableRow email title="60 day Post-expiry" />
                                                <RenewalTableRow email title="75 day Post-expiry" />
                                                <RenewalTableRow email title="90 day Post-expiry" />
                                                <RenewalTableRow email title="120 day Post-expiry" />
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </div>
        );
    }
}

const RenewalTableRow = ({ title, email }) => {
    return (
        <tr>
            <td className="align-middle">{email ? <Link to="#">{title}</Link> : <span>{title}</span>}</td>
            <td>
                <Button style={{ padding: '0.20rem' }} color="success" size="sm" id="generateRenewalLetterKey">
                    {email ? 'Send Now' : 'Generate'}
                </Button>
            </td>
        </tr>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffMembershipRenewalsDetailsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleCheckbox: id => {
            dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            dispatch(toggleCheckboxesAll());
        },
        getGroups: (page, pageSize, sorted, filtered) => {
            dispatch(staffMembershipRenewalsSearchAction(page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipRenewalsDetailsPage));
