import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { save, update, remove, load } from 'actions/staff/events/event-checklist/action.js';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputField from 'pages/admin/companies/shared/InputField';
import moment from 'moment';

import 'css/forms/default-forms.css';

export const InputFieldWrapper = ({ label, children, labelFor }) => {
    return (
        <Row className="mb-2">
            <Col xs={8}>
                <FormGroup>
                    {label && <Label for={labelFor}>{label}</Label>}
                    {children}
                </FormGroup>
            </Col>
        </Row>
    );
};

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        // OnChange
        this.dueBeforeAfterChange = this.dueBeforeAfterChange.bind(this);
        this.duePeriodChange = this.duePeriodChange.bind(this);
        this.dueDayChange = this.dueDayChange.bind(this);
        this.completedChange = this.completedChange.bind(this);
        this.remarksChange = this.remarksChange.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.eventIdChange = this.eventIdChange.bind(this);
        this.dueDateOnChange = this.dueDateOnChange.bind(this);
        // Action
        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.onUploadFail = this.onUploadFail.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        // Modal
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.handleSaveNewChecklist = this.handleSaveNewChecklist.bind(this);
        // State
        this.state = {
            loading: false,
            duePeriods: [{ value: 'months', label: 'months' }, { value: 'weeks', label: 'weeks' }, { value: 'days', label: 'days' }],
            selectedDuePeriod: {
                value: '',
                label: '',
            },
            dueBeforeAfters: [{ value: 'before', label: 'before' }, { value: 'after', label: 'after' }],
            selectedBeforeAfter: {
                value: '',
                label: '',
            },
            completeds: [{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }],
            selectedCompleted: {
                value: '',
                label: '',
            },
            data: {
                dueBeforeAfter: '',
                duePeriod: '',
                dueDay: '',
                completed: '',
                remarks: '',
                description: '',
                eventId: '',
                dueDate: '',
                id: '',
            },
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        this.props.setMode('list', 0);
    }
    modalAction() {
        const type = this.state.modal.type;
        if (type === 'deleteConfirm') this.remove();
        if (type === 'save') this.props.setMode('list', 0);
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.getRelevantStuff();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuff() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.id;
            if (id !== null && id > 0) {
                const res = await this.props.load(id);
                let selectedDuePeriod;
                let selectedDueBeforeAfter;
                let selectedCompleted;

                if (res.error || res.data === null || res.data.id === null) {
                    this.showModal('Error', res.message, 'load', true, true);
                } else {
                    // Selected due period
                    this.state.duePeriods.forEach(option => {
                        if (option.value === res.data.duePeriod) {
                            selectedDuePeriod = option;
                        }
                    });
                    // Selected due before after
                    this.state.dueBeforeAfters.forEach(option => {
                        if (option.value === res.data.dueBeforeAfter) {
                            selectedDueBeforeAfter = option;
                        }
                    });
                    // Selected completed
                    this.state.completeds.forEach(option => {
                        if (option.value === res.data.completed) {
                            selectedCompleted = option;
                        }
                    });
                    // Set state
                    if (this._isMounted) {
                        this.setState({
                            selectedDueBeforeAfter,
                            selectedDuePeriod,
                            selectedCompleted,
                            data: res.data,
                        });
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        data: {
                            dueBeforeAfter: '',
                            duePeriod: '',
                            dueDay: '',
                            completed: '',
                            remarks: '',
                            description: '',
                            eventId: '',
                            id: '',
                        },
                    });
                }
            }
        } catch (error) {
            this.showModal('Error', error.message, 'load', true, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.eventId = this.props.event.eventid;
        newData.completed = newData.completed.toString();
        return newData;
    }
    onUploadSuccess(file) {
        var data = this.state.data;
        data.resourceId = file.id;
        this.setState({ data });
    }
    onUploadFail(error) {}
    async save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        try {
            this.showLoading(true);
            const { error } = !data.id ? await this.props.save(data) : await this.props.update(data);
            if (error) throw error;
            this.showModal('Confirmation', 'Event checklist has been successfully saved', 'save', null, true);
        } catch (error) {
            this.showModal('Error', error.message, 'save', null, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }

    handleSaveNewChecklist() {
        const data = this.prepareDataBeforeSave(this.state.data);
        const due = { due: `${data.dueDay} ${data.duePeriod} ${data.dueBeforeAfter}` };
        if (data) {
            this.props.onSaveNewChecklist({ ...data, ...due });
            this.props.history.push(`/staff/events/${this.props.eventId}/checklist`);
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm');
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                // history.replace('/staff/events/event-checklist');
                this.props.setMode('list', 0);
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }
    dueDateOnChange(e) {
        var data = Object.assign({}, this.state.data);
        try {
            data.dueDate = e;
            data.dueDate = e.format('YYYY-MM-DD');
        } catch (e) {}
        this.setState({
            data,
        });
    }
    dueBeforeAfterChange(e) {
        var data = this.state.data;
        data.dueBeforeAfter = e.value;
        this.setState({
            selectedDueBeforeAfter: e,
            data,
        });
    }
    duePeriodChange(e) {
        var data = this.state.data;
        data.duePeriod = e.value;
        this.setState({
            selectedDuePeriod: e,
            data,
        });
    }
    dueDayChange(e) {
        var data = this.state.data;
        data.dueDay = e.target.value;
        this.setState({ data });
    }
    completedChange(e) {
        var data = this.state.data;
        data.completed = e.value;
        this.setState({
            selectedCompleted: e,
            data,
        });
    }
    remarksChange(e) {
        var data = this.state.data;
        data.remarks = e.target.value;
        this.setState({ data });
    }
    descriptionChange(e) {
        var data = this.state.data;
        data.description = e.target.value;
        this.setState({ data });
    }
    eventIdChange(e) {
        var data = this.state.data;
        data.eventId = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {/* <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="id">id</Label>
                                <Input type="text" name="id" id="id" style={{width: '100px'}} disabled value={this.state.data.id} placeholder="id" />{' '}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="eventId">eventId</Label>
                                <Input
                                    type="text"
                                    name="eventId"
                                    id="eventId"
                                    value={this.state.data.eventId}
                                    placeholder="eventId"
                                    onChange={this.eventIdChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={2}>
                            <InputFieldWrapper label="Due Date" labelFor="duedate">
                                <CyderDatePicker
                                    placeholder=""
                                    dateFormat="DD/MM/YYYY"
                                    closeOnSelect={true}
                                    onChange={this.dueDateOnChange}
                                    value={this.state.data ? moment(this.state.data.dueDate).format('DD/MM/YYYY') : ''}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={7}>
                            <InputFieldWrapper label="Description" labelFor="description">
                                <Input
                                    type="textarea"
                                    row={4}
                                    name="description"
                                    id="description"
                                    value={this.state.data.description}
                                    placeholder=""
                                    onChange={this.descriptionChange}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    {/*

                     <Input
                                    type="text"
                                    name="completed"
                                    id="completed"
                                    value={this.state.data.completed}
                                    placeholder="completed"
                                    onChange={this.completedChange}
                                />{' '} 
                    <Row>
                        <Col xs={3}>
                            <InputFieldWrapper label="Due" labelFor="dueDay">
                                <Input
                                    type="text"
                                    name="dueDay"
                                    id="dueDay"
                                    value={this.state.data.dueDay}
                                    placeholder=""
                                    onChange={this.dueDayChange}
                                />
                            </InputFieldWrapper>
                        </Col>
                        <Col xs={3}>
                            <InputFieldWrapper label="Period" labelFor="duePeriod">
                                <Select
                                    autosize={false}
                                    options={this.state.duePeriods}
                                    value={this.state.selectedDuePeriod}
                                    clearable={this.state.select.clearable}
                                    onChange={this.duePeriodChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </InputFieldWrapper>
                        </Col>
                        <Col xs={3}>
                            <InputFieldWrapper label="Unit" labelFor="dueBeforeAfter">
                                <Select
                                    autosize={false}
                                    options={this.state.dueBeforeAfters}
                                    value={this.state.selectedDueBeforeAfter}
                                    clearable={this.state.select.clearable}
                                    onChange={this.dueBeforeAfterChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    */}
                    <Row>
                        <Col xs={7}>
                            <InputFieldWrapper label="Remarks" for="remarks">
                                <Input
                                    type="textarea"
                                    row={4}
                                    name="remarks"
                                    id="remarks"
                                    value={this.state.data.remarks}
                                    placeholder=""
                                    onChange={this.remarksChange}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <InputFieldWrapper label="Completed" for="completed">
                                <Select
                                    autosize={false}
                                    options={this.state.completeds}
                                    value={this.state.selectedCompleted}
                                    clearable={this.state.select.clearable}
                                    onChange={this.completedChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField label="Attachment">
                                <CyderFileUpload
                                    attachmentId={this.state.data.resourceId}
                                    filename={this.state.data.filename}
                                    onUploadSuccess={this.onUploadSuccess}
                                    onUploadFail={this.onUploadFail}
                                />
                            </InputField>
                        </Col>
                    </Row>
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2} className="footer-left justify-content-start">
                            <Button className="primary-btn-style" onClick={this.handleSaveNewChecklist}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 && (
                            <FlexCol xs={2} className="footer-right">
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const eventId = ownProps.eventId && ownProps.eventId;

    return {
        ownProps,
        eventId,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AddPage));
