import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import { Container, FormGroup, Label, Input, Row, Col, Form, Button } from 'reactstrap';

import { toggleCheckboxes, toggleCheckboxesAll } from '../../../actions/admin/companies/companyAction.js';
import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class CompanyPage extends React.Component {
    render() {
        const yesNoUnknownIcons = val => {
            switch (val) {
                case null:
                    return <i className="fa fa-icon text-muted fa-question-circle" />;
                case true:
                    return <i className="material-icons align-text-bottom text-success">check_circle</i>;
                case false:
                    return <i className="material-icons align-text-bottom text-danger">remove_circle</i>;
            }
        };
        const columns = [
            {
                Header: () => {
                    return (
                        <FormGroup check>
                            <Label>
                                <Input checked={this.props.selectedAll} type="checkbox" onChange={() => this.props.handleCheckboxAll()} />
                                <div className="invisible"> Select All</div>
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                filterable: false,
                accessor: 'id',
                width: 30,
                Cell: props => {
                    return (
                        <FormGroup check>
                            <Label>
                                <Input
                                    checked={this.props.selectedItems[props.value] ? this.props.selectedItems[props.value] : false}
                                    type="checkbox"
                                    onChange={() => this.props.handleCheckbox(props.value)}
                                />
                                <div className="invisible"> Selector</div>
                            </Label>
                        </FormGroup>
                    );
                },
            },
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Organization ID',
                accessor: 'organizationId',
            },
            {
                Header: 'Name of Organization',
                accessor: 'organizationName',

                Cell: props => {
                    return <Link to="#">{props.value}</Link>;
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
            },
            {
                Header: 'UEN / Company Registration Number',
                accessor: 'uenNo',
            },
            {
                Header: 'Organization Category',
                accessor: 'organizationCategory',
            },
            {
                Header: 'Organization Primary Business Activity',
                accessor: 'organizationPrimaryBusinessActivity',
            },
            {
                Header: 'Annual Turnover',
                accessor: 'annualTurnover',
            },
            {
                Header: 'Number Of Employees',
                accessor: 'numberOfEmployees',
            },
            {
                Header: 'Have minimum 30% local shareholding',
                accessor: 'min30percentLocalShareholding',
                sortable: false,
                Cell: props => {
                    return yesNoUnknownIcons(props.value);
                },
            },
            {
                Header: 'Is SME',
                accessor: 'isSME',
                sortable: false,
                Cell: props => {
                    return yesNoUnknownIcons(props.value);
                },
            },
            {
                Header: 'Key Membership Account',
                accessor: 'keyMembershipAccount',
                sortable: false,
                Cell: props => {
                    return yesNoUnknownIcons(props.value);
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="text" name="text" id="search-keyword" placeholder="Keyword" />
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Country</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Organisational category</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Organisational Primary Business Activity</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Is SME</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Have minimum 30% local shareholding</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">User</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Key membership account</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="select" id="search-country">
                                    <option value="notselected">Organization ID</option>
                                    <option value="SG">Singapore</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="US">United States</option>
                                </Input>
                            </FormGroup>
                            <Button>Search</Button>
                            <div className="ml-auto">
                                <Button className="ml-1 mr-1" color="default">
                                    Import
                                </Button>
                                <Button className="ml-1 mr-1" color="default">
                                    Export
                                </Button>
                                <Link to="/admin/companies/company/add">
                                    <Button className="ml-1 mr-1 align-middle" color="success">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add Company
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactTable
                            className="-highlight mb-2"
                            data={cyderlib.fakeCompanyData}
                            columns={columns}
                            minRows={0}
                            filterable={true}
                            showPagination={false}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.companyReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleCheckbox: id => {
            dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            dispatch(toggleCheckboxesAll());
        },
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CompanyPage));
