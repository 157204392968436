import React from 'react';
import { connect } from 'react-redux';
import {
    ResponsiveContainer,
    PieChart,
    Pie,
    Cell,
    Legend,
    Tooltip,
} from 'recharts';

import DashboardWidget from '../widget/DashboardWidget';

const PIEChart = ({ colors, items, title, description }) => {
    const COLORS = [
        colors.primary,
        colors.secondary,
        colors.warning,
        colors.danger,
    ];
    return (
        <DashboardWidget title={title} description={description}>
            <ResponsiveContainer width={'100%'} height={400}>
                <PieChart>
                    <Tooltip cursor={{ stroke: 'none', fill: 'none' }} />
                    <Legend
                        layout="horizontal"
                        align="center"
                        verticalAlign="bottom"
                    />
                    <Pie
                        label
                        data={items}
                        dataKey="value"
                        fill={colors.primary}
                    >
                        {items.map((entry, index) => (
                            <Cell
                                key={index}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </DashboardWidget>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        colors: state.colors,
    };
};
export default connect(mapStateToProps)(PIEChart);
