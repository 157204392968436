import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Row, Col } from 'reactstrap';

import RegisteredAttendees from 'pages/analytics/components/individual-event/RegisteredAttendees';
import CheckedIn from 'pages/analytics/components/individual-event/CheckedIn';
import MemberAttendees from 'pages/analytics/components/individual-event/MemberAttendees';
// import Export from 'pages/analytics/components/individual-event/Export';
import RegisteredList from 'pages/analytics/components/individual-event/RegisteredList';
import AttendeeList from 'pages/analytics/components/individual-event/AttendeeList';

class IndividualEventAnalytics extends React.Component {
    _isMounted = true;
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        if (!this._isMounted) return;
        return (
            <div>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={5}>
                        <CheckedIn eventId={this.props.eventId} />
                    </Col>
                    <Col xs={3}>
                        <RegisteredAttendees eventId={this.props.eventId} />
                    </Col>
                    <Col xs={4}>
                        <MemberAttendees eventId={this.props.eventId} />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={6}>
                        <RegisteredList eventId={this.props.eventId} />
                    </Col>
                    <Col xs={6}>
                        <AttendeeList eventId={this.props.eventId} />
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(IndividualEventAnalytics));
