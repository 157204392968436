import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AffiliatesPanel from './components/AffiliatesPanel';
import { capitalizeFirstLetter } from 'js/util';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import {
    Container,
    Col,
    Row,
    Button,
    Card,
    CardHeader,
    CardBody,
    UncontrolledCollapse,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from 'reactstrap';
import TopNavigation2 from '../../layouts/TopNavigation2';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';

import { memberHomePanelEventGetInfo, memberHomeSetLoadingState } from '../../actions/member/home/memberHomeAction';
import mockDirectoryTableData from 'json/mockDirectoryTableData.json';

class DirectoryPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return this.props.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <AboutPanel />
                <CollapseComponent dtype={this.props.match.params.dtype} cat={this.props.match.params.cat} />
                <AffiliatesPanel />
            </div>
        );
    }
}

const urlPrefix = '/public/directory';

const leaderTab = {
    tabLabel: 'Leader',
    tabTickCount: 4,
};
const excellentTab = {
    tabLabel: 'Excellent',
    tabTickCount: 3,
};
const veryGoodTab = {
    tabLabel: 'Very Good',
    tabTickCount: 2,
};
const goodTab = {
    tabLabel: 'Good',
    tabTickCount: 1,
};
const columns = [
    {
        Header: 'Company',
        accessor: 'company',
    },
    {
        Header: 'Certificate Number',
        accessor: 'certNo',
    },
    {
        Header: 'Valid Till',
        accessor: 'validTill',
    },
    {
        Header: 'Brand',
        accessor: 'brand',
    },
    {
        Header: 'Model',
        accessor: 'model',
    },
];

// replace this with list of product categories
// this is currently just mock data
const cards = [
    {
        subCategoryName: 'Adhesives, Sealants & Additives',
        count: 750,
        url: '1',
        tabs: [
            {
                ...excellentTab,
                data: mockDirectoryTableData,
            },
            {
                ...veryGoodTab,
                data: mockDirectoryTableData,
            },
            {
                ...goodTab,
                data: mockDirectoryTableData,
            },
        ],
    },
    {
        subCategoryName: 'Carpark Accessories',
        count: 150,
        url: '2',
        tabs: [
            {
                ...leaderTab,
                data: mockDirectoryTableData,
            },
            {
                ...goodTab,
                data: mockDirectoryTableData,
            },
        ],
    },
    {
        subCategoryName: 'Ceiling Coverings',
        count: 450,
        url: '3',
        tabs: [
            {
                ...leaderTab,
                data: mockDirectoryTableData,
            },
            {
                ...excellentTab,
                data: mockDirectoryTableData,
            },
            {
                ...veryGoodTab,
                data: mockDirectoryTableData,
            },
        ],
    },
];
const CardHeaderToggler = ({ subCategoryName, id }) => (
    <CardHeader className="d-flex text-center" id={id}>
        {subCategoryName}
        <div className="d-flex flex-column ml-auto justify-content-center">
            <i className="fa fa-plus" />
        </div>
    </CardHeader>
);
class CollapseComponent extends React.Component {
    constructor(props) {
        super(props);
        let activeTab = {};
        for (let i = 0; i < cards.length; i += 1) {
            // might want to refine this
            // each card is represented by activeTab[i]
            // default activeTab of each card is 1
            activeTab[i] = 1;
        }
        this.state = {
            activeTab,
        };
    }
    setActiveTab(card, tab) {
        let newState = {};
        newState.activeTab = this.state.activeTab;
        newState.activeTab[card] = tab;
        this.setState(newState);
    }
    render() {
        // this is just to mock as if data changed between tab changes
        let randomMockDataLength = parseInt(Math.random() * mockDirectoryTableData.length);
        return (
            <Container className="sgbc-newtheme homepanel">
                <Row>
                    {cards.map((c, i) => (
                        <Col key={i} xs={12} className="mb-2">
                            <Card>
                                <CardHeaderToggler subCategoryName={c.subCategoryName} id={`toggler-${c.url}`} />
                                <UncontrolledCollapse toggler={`#toggler-${c.url}`}>
                                    <CardBody>
                                        <Nav tabs>
                                            {c.tabs.map((tab, j) => (
                                                <NavItem key={j}>
                                                    <NavLink
                                                        className={this.state.activeTab[i] === j ? 'active' : null}
                                                        onClick={() => {
                                                            this.setActiveTab(i, j);
                                                        }}
                                                    >
                                                        {tab.tabLabel}
                                                    </NavLink>
                                                </NavItem>
                                            ))}
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab[i]}>
                                            {c.tabs.map((tab, j) => (
                                                <TabPane key={j} tabId={j}>
                                                    <ReactTable columns={columns} data={tab.data.slice(randomMockDataLength)} />
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    </CardBody>
                                </UncontrolledCollapse>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
}

const AboutPanel = () => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            <Col xs={12} sm={12} className="text-center">
                <h2>SGBC Directory</h2>
            </Col>
        </Row>
    </Container>
);

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        const getData = [dispatch(memberHomePanelEventGetInfo())];
        dispatch(memberHomeSetLoadingState(true)).then(() => {
            Promise.all(getData).then(() => {
                dispatch(memberHomeSetLoadingState(false));
            });
        });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(DirectoryPage));
