import {
    USER_MANUAL_ADD_SET_FIELDS,
    USER_MANUAL_ADD_CHANGE_FIELD_VALUE,
    USER_MANUAL_ADD_SAVE_USER_MANUAL_REQUEST,
    USER_MANUAL_ADD_SAVE_USER_MANUAL_SUCCESS,
    USER_MANUAL_ADD_SAVE_USER_MANUAL_FAILURE,
    USER_MANUAL_ADD_DELETE_USER_MANUAL_REQUEST,
    USER_MANUAL_ADD_DELETE_USER_MANUAL_SUCCESS,
    USER_MANUAL_ADD_DELETE_USER_MANUAL_FAILURE,
    USER_MANUAL_ADD_SET_MODAL_DATA,
} from '../../../actions/constants/actionTypesUserManuals';
import { setReducerModalState, defaultModalKeys } from 'js/modal';

// defaults
var defaultState = {
    userManualFields: {
        name: '',
        order_number: '',
        manual_pdf: '',
        category_id: 'notselected',
        created_on: null,
        updated_on: null,
        updated_by_id: null,
        id: null,
    },
    ...defaultModalKeys,
};

export function userManualAddReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case USER_MANUAL_ADD_SET_FIELDS:
            newState.userManualFields = action.fields;
            return newState;
        case USER_MANUAL_ADD_CHANGE_FIELD_VALUE:
            newState.userManualFields = Object.assign(
                {},
                state.userManualFields,
            );
            newState.userManualFields[action.key] = action.value;
            return newState;
        case USER_MANUAL_ADD_SAVE_USER_MANUAL_REQUEST:
            newState.modalLoading = true;
            return newState;
        case USER_MANUAL_ADD_SAVE_USER_MANUAL_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = '/staff/certification/assessment/criteria';
            newState.modalOnlyOneButton = true;
            return newState;
        case USER_MANUAL_ADD_SAVE_USER_MANUAL_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case USER_MANUAL_ADD_DELETE_USER_MANUAL_REQUEST:
            newState.modalLoading = true;
            return newState;
        case USER_MANUAL_ADD_DELETE_USER_MANUAL_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : 'User Manual successfully deleted';
            newState.modalLoading = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case USER_MANUAL_ADD_DELETE_USER_MANUAL_FAILURE:
            newState.modalMessage =
                'Failed to delete User Manual : ' + action.error.message;
            newState.modalLoading = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        case USER_MANUAL_ADD_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return state;
    }
}
