import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import InputComponent from 'reactstrap-input';
import ChosenPermissionsPanel from '../../../cyder/panels/ChosenPermissionsPanel';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import Select from 'react-select';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Button,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';

import {
    groupAddSetFields,
    groupAddAddGroup,
    forceValidate,
    groupAddDeleteGroup,
    changeFieldValueAction,
    groupAddGetPermissions,
    changePermissionValueAction,
    removePermissionValueAt,
    groupAddResetFields,
} from '../../../actions/admin/auth/groupAddAction';
// TODO: re-check DialogModal
import { dialogModalToggle } from '../../../actions/modal/dialogModalAction';
import { setJumbotronTitle } from '../../../actions/pagedata';

import { validateFromValidationObjectPromise } from '../../../js/validation';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class GroupAddPage extends React.Component {
    constructor(props) {
        super(props);
        this.removePermission = this.removePermission.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.validateBeforeToggleModal = this.validateBeforeToggleModal.bind(this);
    }
    componentDidMount() {
        this.props.groupAddResetFields().then(() => {
            if (this.props.match.params.groupCode) {
                this.props.load(this.props.match.params.groupCode).then(() => {
                    const groupName = this.props.fields.groupname;
                    this.props.setJumbotronTitle('Edit Group (' + groupName + ')');
                });
            } else {
                this.props.setJumbotronTitle('Add Group');
            }
            this.props.groupAddGetPermissions();
        });
    }
    handleToggleModal(modalAction) {
        this.props.toggleModal(modalAction);
    }
    validateBeforeToggleModal() {
        const requiredObj = {
            groupname: true,
        };
        this.props.forceValidate().then(() => {
            validateFromValidationObjectPromise(this.props.validation, requiredObj).then(res => {
                if (res === true) {
                    if (this.props.match.params.groupCode) {
                        this.props.toggleModal('update');
                    } else {
                        this.props.toggleModal('save');
                    }
                }
            });
        });
    }
    removePermission(value) {
        const index = this.props.fields.permissions.findIndex(item => item.value === value);
        this.props.removePermissionAt(index);
    }
    render() {
        const additionalInputProps = {
            handleChange: this.props.handleChange,
            validationObj: this.props.validation,
            saveButtonPressed: this.props.saveButtonPressed,
        };
        let created_on = moment(this.props.fields.created_on).format('MMMM D YYYY, HH:mm a');
        if (created_on === 'Invalid date') {
            created_on = '(None)';
        }
        let updated_on = moment(this.props.fields.updated_on).format('MMMM D YYYY, HH:mm a');
        if (updated_on === 'Invalid date') {
            updated_on = '(None)';
        }
        let updated_by_id = this.props.fields.updated_by_id;
        if (updated_by_id === null) {
            updated_by_id = '(None)';
        }
        const modalActionMap = {
            update: this.props.handleUpdate,
            refresh: () => {
                this.handleToggleModal();
                this.validateThenSearch();
            },
            save: this.props.handleSave,
            redirectdetails: () => {
                history.push('/admin/auth/group/');
            },
            delete: this.props.handleDelete,
            close: this.handleToggleModal,
            default: this.handleToggleModal,
        };
        const modalMessageMap = {
            save: 'Are you sure you want to add this group?',
            update: 'Are you sure you want to update this item?',
            refresh: null,
            delete: 'Are you sure you want to delete this item?',
            close: null,
            default: null,
        };
        const modalOnlyOneButtonMap = {
            redirectdetails: true,
            close: true,
        };
        const valueComponent = () => <div />;
        return (
            <div>
                {this.props.loading ? (
                    <LoadingSpinner />
                ) : (
                    <Row>
                        <Col xs={10}>
                            <Card className="mb-2">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Col xs={6}>
                                                <Row>
                                                    <Label xs={4}>Name:</Label>
                                                    <InputComponent
                                                        colWidth={6}
                                                        value={this.props.fields.groupname}
                                                        valueKey={'groupname'}
                                                        {...additionalInputProps}
                                                    />
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Permissions:</Label>
                                            <Col xs={5}>
                                                <h6>Available permissions</h6>
                                                <Select
                                                    multi
                                                    onChange={this.props.changePermissionValueAction}
                                                    options={this.props.permissionChoices}
                                                    placeholder="Search permissions..."
                                                    value={this.props.fields.permissions}
                                                    closeOnSelect={false}
                                                    valueComponent={valueComponent}
                                                />
                                            </Col>
                                            <Col xs={5}>
                                                <ChosenPermissionsPanel
                                                    permissionsList={this.props.fields.permissions}
                                                    removePermission={this.removePermission}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={2}>
                            <h2 style={{ visibility: 'hidden' }}>placeholder</h2>
                            <div className="sticky-top">
                                <Card>
                                    <CardBody className="text-center">
                                        <Button onClick={this.validateBeforeToggleModal} color="info" block>
                                            Save
                                        </Button>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>Save and continue editing</span>
                                        </Button>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>Save and add another</span>
                                        </Button>
                                        {this.props.match.params.groupCode ? (
                                            <Link onClick={() => this.handleToggleModal('delete')} to="#">
                                                Delete
                                            </Link>
                                        ) : null}
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                )}
                <DialogModal
                    key="modal"
                    toggle={() => this.props.toggleModal(null)}
                    modalActionMap={modalActionMap}
                    modalMessageMap={modalMessageMap}
                    modalOnlyOneButtonMap={modalOnlyOneButtonMap}
                    loadingIndicator={this.props.loading}
                    buttonDisabler={this.props.loading}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.groupAddReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        forceValidate: () => {
            return dispatch(forceValidate());
        },
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
        load: id => dispatch(groupAddSetFields(id)),
        handleDelete: () => {
            dispatch(groupAddDeleteGroup());
        },
        handleSave: () => {
            dispatch(groupAddAddGroup(false, ownProps.match.params.groupCode));
        },
        handleUpdate: () => {
            // use same action, but with update = true
            dispatch(groupAddAddGroup(true, ownProps.match.params.groupCode));
        },
        handleChange: event => {
            const key = event.target.id;
            const value = event.target.value;
            dispatch(changeFieldValueAction(key, value));
        },
        toggleModal: modalAction => {
            dispatch(dialogModalToggle(modalAction ? modalAction : null));
        },
        groupAddGetPermissions: () => {
            dispatch(groupAddGetPermissions());
        },
        changePermissionValueAction: value => {
            dispatch(changePermissionValueAction(value));
        },
        removePermissionAt: index => {
            dispatch(removePermissionValueAt(index));
        },
        groupAddResetFields: () => {
            return dispatch(groupAddResetFields());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(GroupAddPage));
