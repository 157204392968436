import {
    EVENT_SAVE_NEW_EVENT_TRACK,
    EVENT_SAVE_SINGLE_EVENT_TRACK,
    EVENT_DELETE_SINGLE_EVENT_TRACK,
    EVENT_GET_EVENT_TRACKS_REQUEST,
    EVENT_GET_EVENT_TRACKS_SUCCESS,
    EVENT_GET_EVENT_TRACKS_FAILURE,
    EVENT_SAVE_TRACK_REQUEST,
    EVENT_SAVE_TRACK_SUCCESS,
    EVENT_SAVE_TRACK_FAILURE,
    EVENT_UPDATE_TRACK_REQUEST,
    EVENT_UPDATE_TRACK_SUCCESS,
    EVENT_UPDATE_TRACK_FAILURE,
    EVENT_TRACK_CHANGE_FIELD_VALUE,
    EVENT_TRACK_SET_EVENT_ID,
    EVENT_TRACK_RESET_FIELDS,
    EVENT_TRACK_SET_MODAL_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsTrack';

import { removeArrayItem } from 'js/util';
import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';

// defaults
const defaultState = {
    loading: false,
    error: false,
    mode: 'save',
    tracks: [],
    tempTracks: {},
    fields: {
        name: '',
        description: '',
        eventId: 0,
    },
    ...defaultModalKeys,
};

export function eventsTrackReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        //Get - All Tracks
        case EVENT_GET_EVENT_TRACKS_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_GET_EVENT_TRACKS_SUCCESS:
            newState.tracks = action.response;
            newState.mode = action.response.length > 0 ? 'update' : 'save';
            newState.loading = false;
            newState.error = false;
            return newState;
        case EVENT_GET_EVENT_TRACKS_FAILURE:
            newState.loading = false;
            newState.error = true;
            return newState;
        //Update - All Tracks
        case EVENT_UPDATE_TRACK_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_UPDATE_TRACK_SUCCESS:
            newState = Object.assign({}, state);
            newState.loading = false;
            newState.error = false;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './tickets';
            newState.modalOnlyOneButton = true;
            if (!action.response.error) {
                newState.modalBody =
                    state.tracks.length > 1 ? 'Event tracks has been successfully updated' : 'Event track has been successfully updated';
            } else {
                newState.modalBody = 'Failed to update event tracks. Event tracks are not allowed to be updated.';
            }
            return newState;
        case EVENT_UPDATE_TRACK_FAILURE:
            newState.loading = false;
            newState.error = true;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './tracks';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody =
                state.tracks.length > 1
                    ? 'Failed to update event tracks. Please try again.'
                    : 'Failed to update event track. Please try again.';
            return newState;
        //Save - All Tracks
        case EVENT_SAVE_TRACK_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_SAVE_TRACK_SUCCESS:
            newState = Object.assign({}, defaultState);
            newState.loading = false;
            newState.error = false;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './tickets';
            newState.modalOnlyOneButton = true;
            newState.modalBody =
                state.tracks.length > 1 ? 'Event tracks has been successfully saved' : 'Event track has been successfully saved';
            return newState;
        case EVENT_SAVE_TRACK_FAILURE:
            newState.loading = false;
            newState.error = true;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './tracks';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody =
                state.tracks.length > 1
                    ? 'Failed to save event tracks. Please try again.'
                    : 'Failed to save event track. Please try again.';
            return newState;
        //Add - New Track
        case EVENT_SAVE_NEW_EVENT_TRACK:
            newState = Object.assign({}, state);
            newState.tracks.push(Object.assign({}, state.fields));
            newState.fields.name = '';
            newState.fields.description = '';
            return newState;
        // Save - Single Track
        case EVENT_SAVE_SINGLE_EVENT_TRACK:
            newState.tracks[action.inputid] = newState.tempTracks[action.inputid]
                ? newState.tempTracks[action.inputid]
                : newState.tracks[action.inputid];
            return newState;
        //Remove - Single Track
        case EVENT_DELETE_SINGLE_EVENT_TRACK:
            newState.tracks = removeArrayItem(newState.tracks, action.inputid);
            return newState;
        //Set - Event ID
        case EVENT_TRACK_SET_EVENT_ID:
            newState.fields.eventId = parseInt(action.eventid);
            return newState;
        //Field Actions
        case EVENT_TRACK_CHANGE_FIELD_VALUE:
            switch (action.inputid) {
                case null:
                    //Change field of new track
                    newState.fields = Object.assign({}, state.fields);
                    newState.fields[action.id] = action.value;
                    break;
                default:
                    //Change field of exisiting track
                    if (!newState.tempTracks[action.inputid]) {
                        newState.tempTracks[action.inputid] = {
                            eventId: newState.fields.eventId,
                            name: newState.tracks[action.inputid].name,
                            description: newState.tracks[action.inputid].description,
                        };
                    }
                    newState.tempTracks[action.inputid][action.id] = action.value;
                    break;
            }
            return newState;
        case EVENT_TRACK_RESET_FIELDS:
            newState = Object.assign({}, defaultState);
            return newState;
        case EVENT_TRACK_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return state;
    }
}
