import React from 'react';
import moment from 'moment';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

class PaymentInfoPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.state = {
            paymentInfo: {},
            modal: false,
        };
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    show(paymentInfo) {
        this.setState({
            paymentInfo,
            modal: true,
        });
    }
    translateDateFormat(date) {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    render() {
        const { modal, paymentInfo } = this.state;
        const { className } = this.props;
        return (
            <Modal isOpen={modal} toggle={this.toggle} className={className}>
                <ModalHeader toggle={this.toggle}>
                    <strong>Payment Information</strong>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {paymentInfo != null && paymentInfo.modeOfPaymentId == 1 && (
                        <InfoPanelComponent
                            paymentMode="Cheque"
                            paymentAmount={paymentInfo.amountReceived}
                            dateOfPayment={this.translateDateFormat(paymentInfo.datePaymentReceived)}
                            bank={paymentInfo.bank}
                            chequeNumber={paymentInfo.referenceNumber}
                        />
                    )}
                    {paymentInfo != null && paymentInfo.modeOfPaymentId == 2 && (
                        <InfoPanelComponent
                            paymentMode="Bank Transfer"
                            paymentAmount={paymentInfo.amountReceived}
                            dateOfPayment={this.translateDateFormat(paymentInfo.datePaymentReceived)}
                            bank={paymentInfo.bank}
                            referenceNumber={paymentInfo.referenceNumber}
                        />
                    )}
                    {paymentInfo != null && paymentInfo.modeOfPaymentId == 5 && (
                        <InfoPanelComponent
                            paymentMode="Corporate PayNow"
                            paymentAmount={paymentInfo.amountReceived}
                            dateOfPayment={this.translateDateFormat(paymentInfo.datePaymentReceived)}
                            referenceNumber={paymentInfo.referenceNumber}
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn-style" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
const InfoPanelComponent = props => {
    const { paymentMode, paymentAmount, dateOfPayment, bank, referenceNumber, chequeNumber } = props;
    return (
        <div>
            {paymentMode && <Field label="Payment Mode" value={paymentMode} />}
            {paymentAmount && <Field label="Payment Amount" value={'$ ' + paymentAmount} />}
            {dateOfPayment && <Field label="Date of Payment" value={dateOfPayment} />}
            {bank && <Field label="Bank" value={bank} />}
            {referenceNumber && <Field label="Reference Number" value={referenceNumber} />}
            {chequeNumber && <Field label="Cheque Number" value={chequeNumber} />}
        </div>
    );
};
const Field = ({ label, value }) => {
    return (
        <Row className="m-3">
            <Col style={{ fontWeight: 'bold' }} xs={3}>
                {label}
            </Col>
            <Col xs={4}>{value}</Col>
        </Row>
    );
};
export default PaymentInfoPopup;
