/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function search(keyword, status, year, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'getAllRenewals',
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            status: status,
            year,
            sorted,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function exportRenewals(keyword, status, year) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'exportAllRenewals',
        });
        const params = {
            keyword: keyword,
            status: status,
            year,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function getRenewalStatus() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal-status`, {
            action: 'get-all-status',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getDiscontinueReasons() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-discontinue-reason`, {
            action: 'get-all',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function sendRenewalEmail(renewalId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'renewalEmail',
            renewalId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function updateStatusRemarks(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'updateStatusRemarks',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'find-by-id-for-renewal',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function save(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'create',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'update',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-renewal`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}

export function print(action, id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: action,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}
