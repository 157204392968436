import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from '../../../../layouts/StoredLayout';

import { Label, Input, Row, Col, FormGroup, Button, Badge } from 'reactstrap';
import LoadingSpinner from '../../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputFieldWithValidation from 'reactstrap-input';
import EventsHeaderComponent from './EventsHeaderComponent';

import {
    eventAttendeeDetailsGetAttendeeDetails,
    eventAttendeeDetailsGetProfessionList,
    eventAttendeeDetailsGetTicketTypesList,
    eventAttendeeDetailsCheckInAttendee,
    eventAttendeeDetailsDeleteAttendee,
    eventAttendeeDetailsUpdateAttendee,
    eventAttendeeDetailsHandleEventInputChange,
    eventAttendeeDetailsResetToDefault,
    eventAttendeeDetailsSetModalData,
} from '../../../../actions/staff/events/events/eventsAttendeeDetailsAction';
import { dialogModalToggle } from '../../../../actions/modal/dialogModalAction';

class EventsRegPaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.updateAttendee = this.updateAttendee.bind(this);
        this.deleteAttendee = this.deleteAttendee.bind(this);
        this.checkInAttendee = this.checkInAttendee.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
    }
    componentDidMount() {
        const attendeeId = this.props.match.params.attendeeid;
        const eventId = this.props.match.params.eventid;
        if (attendeeId) {
            Promise.all([this.props.resetToDefault(), this.props.getProfessionList(), this.props.getTicketTypesList(eventId)]).then(() =>
                this.props.getAttendeeDetails(attendeeId),
            );
        }
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    checkInAttendee() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.checkInAttendee(this.props.match.params.attendeeid),
            modalBody: 'Click OK to check-in Attendee.',
        });
    }
    updateAttendee() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.updateAttendee(this.props.match.params.attendeeid),
            modalBody: 'Click OK to update Attendee’s details.',
        });
    }
    deleteAttendee() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.deleteAttendee(this.props.match.params.attendeeid),
            modalBody: 'Are you sure you want to delete this attendee?',
        });
    }
    render() {
        const attendeeInfo = this.props.attendeeInfo;
        const eventId = this.props.match.params.eventid;
        const isAttended = this.props.attendeeInfo.attended === 1;
        if (this.props.loading) return <LoadingSpinner />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.props.modalAction}
                    modalHeader={this.props.modalHeader}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    boundForceUpdate={this.boundForceUpdate}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                <div className="page-widget">
                    <EventsHeaderComponent mode="staff" eventId={eventId} />
                    <br />
                    <Row className="mb-2">
                        <Col sm={12} className="d-flex">
                            <h3>Attendee Info & Check-in</h3>
                            <span className="ml-auto">
                                Checked-in?
                                <Badge size="sm" color={isAttended ? 'success' : 'danger'} className="ml-2">
                                    {isAttended ? 'Yes' : 'No'}
                                </Badge>
                            </span>
                        </Col>
                    </Row>
                    <br />
                    <Row className="mb-2">
                        <Col sm={6}>
                            <FormGroup className="col-sm-7">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Name *"
                                    valueKey="name"
                                    value={attendeeInfo.name}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-7">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Full Company Name *"
                                    valueKey="companyName"
                                    value={attendeeInfo.companyName}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-7">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Designation *"
                                    valueKey="designation"
                                    value={attendeeInfo.designation}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-7">
                                <InputFieldWithValidation
                                    style={{ marginBottom: '0px' }}
                                    type="text"
                                    label="GMAP Number"
                                    valueKey="gmp"
                                    value={attendeeInfo.gmp}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                                <span style={{ fontSize: '12px', position: 'relative', top: '-15px' }}>
                                    This field will be blank if attendee is not a GMAP
                                </span>
                            </FormGroup>
                            {/* 
                            <FormGroup className="col-sm-4">
                                <Label>Track *</Label>
                                <Input type="select">
                                    <option>------</option>
                                    <option>Track 1</option>
                                </Input>
                            </FormGroup> */}
                            <br />
                            {/* <FormGroup className="col-sm-10">
                                <InputFieldWithValidation
                                    rows="5"
                                    type="textarea"
                                    label="Signature *"
                                    valueKey="signature"
                                    value={attendeeInfo.signature}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup> */}
                            {/* <FormGroup className="col-sm-6">
                                <Label>Attendee Profession</Label>
                                <Input
                                    type="select"
                                    id="attendeeProfessionId"
                                    value={attendeeInfo.attendeeProfessionId}
                                    onChange={this.props.handleInputChange}
                                >
                                    <option value="null">-----</option>
                                    {this.props.dropdown.professionList.map((profession, index) => {
                                        return (
                                            <option key={index} value={profession.id}>
                                                {profession.profession}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </FormGroup> */}
                        </Col>
                        <Col xs={6}>
                            <FormGroup className="col-sm-6">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Email *"
                                    valueKey="email"
                                    value={attendeeInfo.email}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-6">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Handphone / DID *"
                                    valueKey="handphone"
                                    value={attendeeInfo.handphone}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>

                            <FormGroup className="col-sm-6">
                                <Label>Ticket Type</Label>
                                <Input
                                    type="select"
                                    id="ticketTypeId"
                                    value={attendeeInfo.ticketTypeId}
                                    onChange={this.props.handleInputChange}
                                >
                                    <option value="null">-----</option>
                                    {this.props.dropdown.ticketTypesList.map((ticket, index) => {
                                        return (
                                            <option key={index} value={ticket.id}>
                                                {ticket.name}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </FormGroup>
                            {/* <FormGroup className="col-sm-4">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Professional Number"
                                    valueKey="professionalNumber"
                                    value={attendeeInfo.professionalNumber}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-4">
                                <InputFieldWithValidation
                                    type="text"
                                    label="NRIC"
                                    valueKey="nric"
                                    value={attendeeInfo.nric}
                                    validationObj={{}}
                                    handleChange={this.props.handleInputChange}
                                />
                            </FormGroup> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="d-flex">
                            <div>
                                <Button className="primary-btn-style-outline" onClick={this.deleteAttendee}>
                                    Delete
                                </Button>
                            </div>
                            <div className="ml-auto">
                                <Button className="primary-btn-style" onClick={this.updateAttendee}>
                                    Update Info
                                </Button>
                                &nbsp;
                                {this.props.attendeeInfo.attended === 0 ? (
                                    <Button className="primary-btn-style" onClick={this.checkInAttendee}>
                                        Check-in Now
                                    </Button>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <br />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAttendeeDetailsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAttendeeDetails: eventid => {
            dispatch(eventAttendeeDetailsGetAttendeeDetails(eventid));
        },
        getTicketTypesList: eventId => {
            return dispatch(eventAttendeeDetailsGetTicketTypesList(eventId));
        },
        getProfessionList: () => {
            return dispatch(eventAttendeeDetailsGetProfessionList());
        },
        handleInputChange: event => {
            dispatch(eventAttendeeDetailsHandleEventInputChange(event));
        },
        checkInAttendee: attendeeId => {
            dispatch(eventAttendeeDetailsCheckInAttendee(attendeeId));
        },
        updateAttendee: attendeeId => {
            dispatch(eventAttendeeDetailsUpdateAttendee(attendeeId));
        },
        deleteAttendee: attendeeId => {
            dispatch(eventAttendeeDetailsDeleteAttendee(attendeeId));
        },
        resetToDefault: () => {
            return dispatch(eventAttendeeDetailsResetToDefault());
        },
        setEventModalData: data => {
            dispatch(eventAttendeeDetailsSetModalData(data));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsRegPaymentPage));
