import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
// import { Link } from 'react-router-dom'

const DetailsPageFormSection6 = ({ name }) => {
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <Card>
                    <CardBody>
                        <h5>Section I: Declaration</h5>
                        <br />
                        <div>
                            <p>
                                I declare that the information given above is true and if the membership is approved, the organisation shall
                                abide by the Singapore Green Building Council (SGBC) Constitution and By-Laws, and actively support the
                                Mission and Focus Areas of the SGBC.
                            </p>
                            <p>
                                The organisation agrees that SGBC may contact its representatives at the contact information provided above.
                                We agree to receive weekly emails from SGBC, including industry event invitations, news and information from
                                partners.
                            </p>
                            <br />
                            <span>
                                <strong>Full Name of Applicant as Acknowledgement:</strong>
                                <br />
                                {name}
                            </span>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default DetailsPageFormSection6;
