import React from 'react';
import history from '../../history';
import moment from 'moment';

import { Input, Row, Col, Button, Table, Label, Form, FormGroup } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { isMobile } from 'react-device-detect';

const EventTicketPanel = ({
    staff,
    eventState,
    isTixUrl,
    isFormUrl,
    validatePromoCode,
    ticketSelectionChange,
    changeTixNum,
    fillUpAttendees,
    onPromoCodeChange,
    userContext,
}) => {
    const { eventDate } = eventState;
    const date = eventDate.length > 0 ? eventDate[0].date : null;
    const canRegister = staff ? true : moment().isSameOrBefore(moment(date, 'YYYY-MM-DD'), 'day');
    let totalTicketSelected = 0;
    for (var i = 0; i < eventState.tickets.length; i++) {
        totalTicketSelected += eventState.tickets[i].quantity;
    }
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h1 className="text-center">
                        <strong>
                            {canRegister
                                ? eventState.tickets.length > 0
                                    ? `Ticket Information`
                                    : `For further enquiry, contact us at events@sgbc.sg.`
                                : `This event has expired.`}
                        </strong>
                    </h1>
                </Col>
            </Row>
            <br />
            {eventState.loading ? (
                <LoadingSpinner />
            ) : canRegister && eventState.tickets.length > 0 ? (
                <div>
                    <Row>
                        <Col xs={12}>
                            <Table>
                                <thead>
                                    {isMobile ? (
                                        <tr>
                                            <th />
                                        </tr>
                                    ) : (
                                        <tr>
                                            <th>Type</th>
                                            <th>Registration Period</th>
                                            <th>Price (SGD)</th>
                                            <th>Quantity</th>
                                            <th className="text-right">Total (SGD)</th>
                                        </tr>
                                    )}
                                </thead>
                                <tbody>
                                    {eventState.tickets.map((ticket, i) => {
                                        const shouldTicketShown = () => {
                                            var isPublic = false;
                                            var path = window.location.pathname;
                                            if (path.toLowerCase().indexOf('/public/') >= 0) isPublic = true;
                                            if (userContext == 'staff') {
                                                return true;
                                            }
                                            var shown = true;
                                            if (isPublic && ticket.onlyAvailableForSgbcMembers == 1) shown = false;
                                            return shown;
                                        };
                                        let ticketSelectable = totalTicketSelected <= 0 || ticket.quantity > 0;

                                        if (!shouldTicketShown()) return;
                                        let total = ticket.pricePerTicket * ticket.quantity;
                                        total = total.toFixed(2);
                                        const quantityForSale = ticket.maxAllowedPerRegistration; // SGBCCRM-722: Limit to 5 tickets per registration;
                                        return [
                                            <tr key={i}>
                                                {isMobile ? (
                                                    <React.Fragment>
                                                        <td>
                                                            <b>Type:</b> <br /> {ticket.name} <br />
                                                            <b>Registration Period:</b> <br />
                                                            {ticket.registrationPeriod} <br />
                                                            <b>Price (SGD):</b> {ticket.pricePerTicket} <br />
                                                            <b>Quantity: </b>
                                                            {!ticket.disabled ? (
                                                                <Input
                                                                    disabled={!ticketSelectable || isFormUrl}
                                                                    id={`ticketNum${ticket.id}`}
                                                                    type="select"
                                                                    className="mb-1"
                                                                    onChange={e => {
                                                                        changeTixNum(e, ticket.id);
                                                                    }}
                                                                    value={ticket.quantity}
                                                                >
                                                                    {new Array(quantityForSale + 1).fill(1).map((x, i) => (
                                                                        <option key={i} value={i}>
                                                                            {i}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            ) : (
                                                                <span>Sold Out</span>
                                                            )}
                                                            <br />
                                                            <b>Total (SGD): </b>
                                                            {total}
                                                        </td>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <td>{ticket.name}</td>
                                                        <td>{ticket.registrationPeriod}</td>
                                                        <td>{ticket.pricePerTicket}</td>
                                                        <td>
                                                            {!ticket.disabled ? (
                                                                <Input
                                                                    disabled={!ticketSelectable || isFormUrl}
                                                                    id={`ticketNum${ticket.id}`}
                                                                    type="select"
                                                                    className="mb-1"
                                                                    onChange={e => {
                                                                        changeTixNum(e, ticket.id);
                                                                    }}
                                                                    value={ticket.quantity}
                                                                >
                                                                    {new Array(quantityForSale + 1).fill(1).map((x, i) => (
                                                                        <option key={i} value={i}>
                                                                            {i}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            ) : (
                                                                <span>Sold Out</span>
                                                            )}
                                                        </td>
                                                        <td className="text-right">{total}</td>
                                                    </React.Fragment>
                                                )}
                                            </tr>,
                                        ];
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <hr />
                    <Row>
                        <Col className="d-flex flex-row" xs={6}>
                            <Button
                                className="primary-btn-style-outline"
                                onClick={e => {
                                    history.goBack();
                                }}
                            >
                                <strong>Back</strong>
                            </Button>
                        </Col>
                        <Col className="d-flex flex-row-reverse" xs={6}>
                            {isTixUrl ? (
                                <Button
                                    className="primary-btn-style"
                                    onClick={fillUpAttendees}
                                    disabled={parseInt(eventState.tixnum) === 0}
                                >
                                    <strong>Continue</strong>
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            ) : (
                <div>
                    <hr />{' '}
                    <Col className="d-flex justify-content-center" xs={12}>
                        <Button
                            className="primary-btn-style-outline"
                            onClick={e => {
                                history.push('/public/eventdirectory');
                            }}
                        >
                            <strong>Back to event directory</strong>
                        </Button>
                    </Col>
                </div>
            )}
        </div>
    );
};

export default EventTicketPanel;
