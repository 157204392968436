import React from 'react';
import { connect } from 'react-redux';

import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import { getMembershipCertificateCrossAnalytic } from 'actions/analytics/certificateAnalyticsAction';

class CrossAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }
    render() {
        return (
            <AnalyticsCard title={this.props.title} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigText text={Math.round(this.state.data[0].allCertPercentage) + '%'} textColor={`#f8ac59`} />
                        <AnalyticsNormalText
                            text={`${this.state.data[0].allCertWithMemberCount} / ${
                                this.state.data[0].allCertCount
                            } Certified Product/Services Companies who are Members`}
                        />
                        <hr />
                        <AnalyticsBigText text={Math.round(this.state.data[1].allMemberPercentage) + '%'} textColor={`#78BE20`} />
                        <AnalyticsNormalText
                            text={`${this.state.data[1].allMemberWithCertCount} / ${
                                this.state.data[1].allMemberCount
                            } Members who have Certified Products/Services`}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getMembershipCertificateCrossAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CrossAnalytics));
