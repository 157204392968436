import React from 'react';
import { connect } from 'react-redux';

import '../../../css/cyder/media/icon.css';
import '../../../css/icons/material-design-icons.css';
import '../../../css/icons/font-awesome.css';

class Events extends React.Component {
    render() {
        return (
            <div className="user-widget-1">
                <div className="user-data">
                    <div className="row">
                        <div className="col">
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-success">
                                    arrow_upward
                                </i>
                                {this.props.events.upcoming} Upcoming
                            </p>
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-info">
                                    arrow_forward
                                </i>
                                {this.props.events.ongoing} On-going
                            </p>
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-warning">
                                    arrow_downward
                                </i>
                                {this.props.events.justCompleted} Just Ended
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.staff.dashboardInfo.events,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Events);
