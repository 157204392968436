// eventAttendeePage
export const EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST = 'EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST';
export const EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS = 'EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS';
export const EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE = 'EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE';

export const EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST = 'EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST';
export const EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS = 'EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS';
export const EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE = 'EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE';

export const EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS = 'EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS';
export const EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE = 'EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE';

export const EVENT_ATTENDEE_RESET_TO_DEFAULT = 'EVENT_ATTENDEE_RESET_TO_DEFAULT';

export const EVENT_ATTENDEE_HANDLE_INPUT_CHANGE = 'EVENT_ATTENDEE_HANDLE_INPUT_CHANGE';
export const EVENT_ATTENDEE_SET_MODAL_DATA = 'EVENT_ATTENDEE_SET_MODAL_DATA';

// eventAttendeeDetailsPage
export const EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST = 'EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST';
export const EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS = 'EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE = 'EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE';

export const EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS = 'EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE = 'EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE';

export const EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS = 'EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE = 'EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE';

export const EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST = 'EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST';
export const EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS = 'EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE = 'EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE';

export const EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST = 'EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST';
export const EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS = 'EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE = 'EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE';

export const EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST = 'EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST';
export const EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS = 'EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS';
export const EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE = 'EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE';

export const EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE = 'EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE';
export const EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT = 'EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT';
export const EVENT_ATTENDEE_DETAILS_SET_MODA_DATA = 'EVENT_ATTENDEE_DETAILS_SET_MODA_DATA';

export const EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST = 'EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST';
