import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';
import Select from 'react-select';

import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { staffAccountsGetCompanyAccounts } from '../../../../actions/staff/accounts/staffAccountsAction';

class StaffCompanyAccountsPage extends React.Component {
    constructor(props) {
        super(props);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            hasOmr: false,
            keyword: '',
            countrySelection: {
                label: 'All',
                value: 'all',
            },
            countryOption: [
                {
                    label: 'All',
                    value: 'all',
                },
                {
                    label: 'Singapore',
                    value: 'singapore',
                },
                {
                    label: 'Overseas Country',
                    value: 'others',
                },
            ],
            table: {
                pageCount: 0,
            },
            activeTab: {
                type: 'All',
                alphabetical: 'All',
            },
        };
    }

    onSelectChange(e) {
        var state = this.state;
        state.countrySelection = e;
        this.setState({
            state,
        });
    }
    showLoding(loading) {
        this.setState({
            loading,
        });
    }
    toggleCheckBox = event => {
        const { id } = event.target;
        let { state } = this;
        state[id] = !state[id];
        this.setState(state);
    };
    handleInputChange = event => {
        const { value } = event.target;
        this.setState({
            searchResult: [],
            keyword: value,
        });
    };
    search = async (page, pageSize, sorted, filtered) => {
        const { keyword, countrySelection, hasOmr } = this.state;
        try {
            this.showLoding(true);
            const { data, totalNoOfPages, totalNoOfItems } = await this.props.staffGetCompanyAccounts(
                keyword,
                countrySelection.value,
                hasOmr,
                page,
                pageSize,
                sorted,
                filtered,
            );
            this.setState({
                list: data,
                pageCount: totalNoOfPages,
                totalNum: totalNoOfItems,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoding(false);
        }
    };
    render() {
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    const link = '/staff/company/' + props.original.id;
                    return (
                        <Link style={{ color: 'black' }} to={link}>
                            {props.value}
                        </Link>
                    );
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'UEN',
                accessor: 'registrationNumber',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Contact',
                accessor: 'userId',
                minWidth: 200,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    const { adminFirstName, adminLastNameName } = props.original;
                    return `${adminFirstName || ''} 
                        ${adminLastNameName || ''}`;
                },
            },
            {
                Header: 'Joined Date',
                accessor: 'createdOn',
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    const date = moment(props.value).format('DD/MM/YYYY');
                    return date === 'Invalid date' ? `-` : date;
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mt-4 mb-4">
                    <Col xs={12}>
                        <div className="form-inline">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    className="mr-2"
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    value={this.state.keyword}
                                    onChange={this.handleInputChange}
                                />
                                <Select
                                    clearable={true}
                                    autosize={false}
                                    searchable={true}
                                    className="w-200 ml-2"
                                    name="selected-state"
                                    value={this.state.countrySelection}
                                    options={this.state.countryOption}
                                    onChange={e => this.onSelectChange(e)}
                                />
                                &nbsp; &nbsp; &nbsp;
                                <label>
                                    <Input type="checkbox" id="hasOmr" checked={this.state.hasOmr} onChange={this.toggleCheckBox} />
                                    Has OMR
                                </label>
                                <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                    <i className="material-icons align-text-bottom">search</i>
                                    &nbsp;Search
                                </Button>
                            </FormGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            manual
                            minRows={0}
                            columns={columns}
                            filterable={false}
                            showPagination={true}
                            defaultPageSize={10}
                            data={this.state.list}
                            loading={this.state.loading}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={state => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        staffGetCompanyAccounts: (keyword, country, hasOmr, page, pageSize, sorted, filtered) => {
            return dispatch(staffAccountsGetCompanyAccounts(keyword, country, hasOmr, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffCompanyAccountsPage));
