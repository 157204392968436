import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import { Card, CardBody, CardText } from 'reactstrap';
import ChangeMembershipPackage from './ChangeMembershipPackage';

// Use on membership application preview & staff membership application (Section A)
const MembershipPreview = ({ selectedPackage, staff, applicationType, applicationId, status, packages }) => {
    if (!selectedPackage) return null;
    const { packageName, price } = selectedPackage;
    const packageYear = packageName.substring(0, packageName.indexOf('Year') + 4); // need testing (27th Feb 2019)
    return (
        <Card className={staff ? 'mb-2' : 'p-4'}>
            <CardBody>
                {staff && (
                    <div>
                        <h5>Section A. Membership Plan</h5>
                        <br />
                    </div>
                )}
                <div className="field-header">
                    Corporate Membership Package Selected:
                    <br />
                </div>
                <div className="field-label">
                    <ul>
                        <li>
                            {packageYear} - ${price} (not inclusive of prevailing government taxes)
                        </li>
                        {!staff && <li>*For non-Singapore applications, a $50 administrative fee will be levied.</li>}
                    </ul>
                </div>
                {status <= 3 && <ChangeMembershipPackage selectedPackage={selectedPackage} applicationId={applicationId} />}
                <CardText className="text-muted pt-4">
                    Membership validity is based on calendar year and lapses annually on 31st December unless renewed.
                    <br />
                    {staff && (
                        <React.Fragment>
                            Click
                            <a
                                onClick={e => {
                                    document.getElementById('membershipFeeClause').style.display = '';
                                }}
                            >
                                &nbsp;<u>here</u>&nbsp;
                            </a>
                            for the SGBC Constitution clause on Membership Fees.
                            <div style={{ color: '#333', paddingTop: '10px', display: 'none' }} id="membershipFeeClause">
                                Every applicant for membership shall submit with his application form the entrance fee and the full amount
                                of the annual subscription payable in respect of the financial year in which the application is made
                                provided always that where an application is submitted after the midpoint of any financial year, the
                                applicant shall be liable to pay only one-half of the annual subscription payable in respect of that
                                financial year. (Extracted from SGBC Constitution, Clause 6.4)
                            </div>
                        </React.Fragment>
                    )}
                </CardText>
            </CardBody>
        </Card>
    );
};

export default MembershipPreview;
