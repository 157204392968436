import {
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST,
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS,
    STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE,
    STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';

// defaults
var defaultState = {
    loading: false,
    loggingActivitiesInfo: [],
};

export function staffCompanyDetailsLoggingActivityReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS:
            newState.loggingActivitiesInfo = action.response.data;
            newState.loading = false;
            return newState;
        case STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        default:
            return newState;
    }
}
