import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { setSurveyValue, getSurveyResult } from 'actions/staff/survey/surveyAction';

class SurveysDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            newQuestionType: 'radio',
            questionTitle: '',
        };
    }
    componentDidMount() {
        if (this.props.match.params.surveyid) {
            this.props.setSurveyValue(this.props.match.params.surveyid).then(surveyRes => {
                var survey = surveyRes.data;
                survey.questions.forEach(question => {
                    question.choices = [];
                    question.answers = [];
                    if (question.questionTypeId === 2) {
                        // multi-options
                        // Set the default count to 0
                        question.questionOptions.forEach(option => {
                            question.choices.push({
                                label: option.text,
                                value: 0,
                            });
                        });
                    }
                });

                this.setState({
                    survey: survey,
                });

                this.props.getSurveyResult(this.props.match.params.surveyid).then(resultRes => {
                    var result = resultRes.data;

                    this.setState({
                        result: result,
                    });

                    result.multi.forEach(result => {
                        survey.questions.forEach(question => {
                            if (result.question_id === question.id) {
                                question.choices.forEach(choice => {
                                    if (choice.label === result.answer_option) {
                                        choice.value = result.num_votes;
                                    }
                                });
                            }
                        });
                    });

                    result.text.forEach(result => {
                        survey.questions.forEach(question => {
                            if (result.question_id === question.id) {
                                question.answers.push({
                                    ans: result.answer_text,
                                });
                            }
                        });
                    });

                    this.setState({
                        survey: survey,
                    });
                });
            });
        }
    }
    render() {
        const colors = ['#73994A', '#7E9181', '#C7CEDB', '#A0AAB2', '#94849B'];
        return this.state.survey == null || this.state.result == null ? (
            <LoadingSpinner />
        ) : (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div>
                            <Form className="mb-3">
                                <h3>{this.state.survey.surveyName}</h3>
                                <span>{this.state.survey.surveyDescription}</span>
                            </Form>
                            <Row>
                                <Col xs={12}>
                                    <div>
                                        <ListGroup>
                                            {this.state.survey.questions.map((qn, i) => (
                                                <Row key={i}>
                                                    <Col xs={12}>
                                                        <hr />
                                                        <h5>{qn.questionText}</h5>
                                                        {qn.questionTypeId === 2 ? (
                                                            <ResponsiveContainer key={i} width={'100%'} height={300}>
                                                                <PieChart>
                                                                    <Tooltip
                                                                        cursor={{
                                                                            stroke: 'none',
                                                                            fill: 'none',
                                                                        }}
                                                                    />
                                                                    <Legend layout="horizontal" align="center" verticalAlign="bottom" />
                                                                    <Pie
                                                                        data={qn.choices}
                                                                        nameKey="label"
                                                                        dataKey="value"
                                                                        cx="50%"
                                                                        cy="50%"
                                                                        outerRadius={80}
                                                                        label
                                                                    >
                                                                        {qn.choices.map((entry, index) => (
                                                                            <Cell key={index} fill={colors[index]} />
                                                                        ))}
                                                                    </Pie>
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        ) : (
                                                            <ListGroup>
                                                                {qn.answers.map((answer, i) => (
                                                                    <ListGroupItem key={i} className="d-flex">
                                                                        <span>{answer.ans}</span>
                                                                        <span className="ml-auto text-muted">{answer.count}</span>
                                                                    </ListGroupItem>
                                                                ))}
                                                            </ListGroup>
                                                        )}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </ListGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.userReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setSurveyValue: surveyId => {
            return dispatch(setSurveyValue(surveyId));
        },
        getSurveyResult: surveyId => {
            return dispatch(getSurveyResult(surveyId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SurveysDetailPage));
