/**
 * Member product assessment reducer
 *
 * Author: Fernando Karnagi
 */

var defaultState = {
    new: {
        currentTabNo: 0,
        activeTabs: [true, false, false],
    },
};

export function memberProductAssessmentReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBER_PRODUCT_ASSESSMENT_SAMPLE_ACTION':
            newState.new.currentTabNo = 0;
            newState.new.activeTabs = [true, false, false];
            return newState;
        default:
            return newState;
    }
}
