/**
 * Membership application action
 *
 * Author: Fernando Karnagi
 */
import { MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE } from '../../../../actions/constants/actionTypesMemberMembershipApplications';

import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';

export function getMembershipApplicationInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'get-membership-info',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE);
    };
}
export function getMembershipApplicationPackage(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-package`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getMembershipApplicationTierResponse(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipTierReponse',
            applicationId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getMembershipApplicationTierDocs(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipTierDocs',
            applicationId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function withdraw(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'withdraw',
        });

        var data = {
            id: id,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function printCert(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-membership-cert',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function printCertByMembershipId(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-membership-cert-by-membership-id',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function printRenewalLetter(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-renewal-letter',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function printRenewalNotice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-renewal-notice',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function printRenewalNoticeLate(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-renewal-notice-late',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function printWarningLetter(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-warning-letter',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function printTerminationLetter(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-termination-letter',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
