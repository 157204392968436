import { MODAL_DIALOG_TOGGLE, MODAL_DIALOG_SET_TOGGLE } from '../constants/actionTypesModal';

export function dialogModalToggle() {
    return {
        type: MODAL_DIALOG_TOGGLE,
    };
}
// BACKDOOR METHOD FOR QUICK FIX ONLY
export function setDialogModalToggle(show) {
    return {
        type: MODAL_DIALOG_SET_TOGGLE,
        show,
    };
}
