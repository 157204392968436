import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MembershipRenewalDetailsList from 'pages/analytics/components/membership-renewal/MembershipRenewalDetailsList';

class MembershipRenewalDetailsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.export = this.export.bind(this);
        this.state = {
            modal: false,
        };
    }
    export() {
        try {
            const papa = require('papaparse');
            const parseConfig = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };
            var csvPayload = papa.unparse(this.state.data, parseConfig);
            var csv = 'data:text/csv;charset=utf-8,' + csvPayload;
            var data = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', 'renewal.csv');
            link.click();
        } catch (error) {
            throw error;
        }
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    show(title, renewalStatusId) {
        this.setState({
            title,
            renewalStatusId,
            modal: true,
        });
    }
    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle} style={{ color: '#317446' }}>
                    <div style={{ fontSize: '22px' }}>
                        <b>{this.state.title}</b>
                    </div>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <MembershipRenewalDetailsList
                        loadDataCb={data => {
                            this.setState({ data });
                        }}
                        renewalStatusId={this.state.renewalStatusId}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn-style" onClick={this.export}>
                        Export
                    </Button>
                    <Button className="primary-btn-style" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default MembershipRenewalDetailsPopup;
