import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import moment from 'moment';

import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import RowCardHOC from './DetailsPageRowCardHOC';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderHeaderPanel from 'cyder/panels/CyderHeaderPanel';
import DetailsPageFormSectionTakeAction from './DetailsPageFormSectionTakeAction';
import DetailsPageFormMembershipPlan from 'pages/member/membership/new/components/MembershipPreview';
import DetailsPageFormSectionCorporateInfo from './DetailsPageFormSectionCorporateInfo';
import DetailsPageFormSectionCorporateAddress from './DetailsPageFormSectionCorporateAddress';
import DetailsPageFormSectionBusinessActivity from './DetailsPageFormSectionBusinessActivity';
import DetailsPageFormSectionCorporateRemark from './DetailsPageFormSectionCorporateRemark';
import DetailsPageFormSectionPrimaryManagementRepresentative from './DetailsPageFormSectionPrimaryManagementRepresentative';
import DetailsPageFormOthers from './DetailsPageFormOthers';
import DetailsPageFormSection6 from './DetailsPageFormSection6';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

import { staffCompanyDetailsRegenerateCertificate } from 'actions/staff/company/staffCompanyDetailsMembershipAction';

import {
    getApplicationDetails,
    getPaymentInfo,
    forceLoadingState,
    processMembershipApplication,
    processApprove,
    generateXeroInvoice,
    setModalData,
    resetState,
    onChangePaymentFields,
    markAsPaid,
    forceValidate,
    setCalculateMembershipDatesFunction,
    processMembershipApplicationComplete,
    processReject,
    getXeroInventoryItems,
    withdraw,
    createInvoice,
    createReceiptInS3,
    updateXeroPayment,
    printMembership,
    resubmitQuestionnaires,
    sendInvoice,
    sendReceiptEmail,
    sendEmailReceipt,
    sendRejectEmail,
    submitOnBehalf,
    changeApplicationType,
} from 'actions/staff/membership/applications/staffMembershipApplicationsDetailsAction';
import {
    memberMembershipApplicationGetCompanyInfo,
    memberMembershipApplicationGetCategories,
    memberMembershipApplicationResetFields,
} from 'actions/member/company/memberCompanyAction';
import {
    memberMembershipApplicationGetPackageInfo,
    memberMembershipApplicationNewGetQuestions,
} from 'actions/member/membership/application/memberNewMembershipApplicationAction';
import {
    getMembershipApplicationTierResponse,
    getMembershipApplicationTierDocs,
} from 'actions/member/membership/application/membershipApplicationAction';

import { dialogModalToggle, setDialogModalToggle } from 'actions/modal/dialogModalAction';
import { getCompanyRepInfo } from 'actions/shared/teamMemberDetailsAction';
import { validateFromValidationObject } from 'js/validation';

import { SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';
import cyderlib from 'js/cyderlib.js';

import '../../../../css/forms/default-forms.css';

class GenerateAndDownloadReceipt extends React.Component {
    render() {
        return (
            <Row>
                <Col>
                    <Label>Press OK to generate and download receipt.</Label>
                </Col>
            </Row>
        );
    }
}
class RejectComment extends React.Component {
    render() {
        return (
            <Row>
                <Col>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label>Please specify reasons for rejection:</Label>
                        <Input type="text" id="rejectComment" placeholder="Remarks" />
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

class SendReceiptRemarks extends React.Component {
    render() {
        return (
            <Row>
                <Col>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label>Please specify email address if you wish to send the receipt to different person :</Label>
                        <Input type="text" id="receiptRecipient" placeholder="Receipt receipient" />
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

const modalStrings = {
    sendRejectEmail: 'Click OK to send rejection email',
    sendReceipt: 'Click OK to send receipt',
    doApprove: 'Click OK to approve the Application.',
    doReject: 'Are you sure you want to reject this application?',
    startProcessing: 'Click OK to begin processing the Application.',
    markAsPaid: 'Click OK to mark this Appliation as paid',
    generateInvoice: 'Are you sure you want to generate the invoice?',
    downloadInvoice: 'Are you sure you want to download the invoice?',
    processApplicationAsComplete: 'Click OK to complete the Application.',
    submitOnBehalf: 'Click OK to submit on behalf.',
};
class StaffMembershipApplicationsDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.printMembership = this.printMembership.bind(this);
        this.resubmitQuestionnaires = this.resubmitQuestionnaires.bind(this);
        this.createAndSendEmailReceipt = this.createAndSendEmailReceipt.bind(this);
        this.createAndDownloadReceipt = this.createAndDownloadReceipt.bind(this);
        this.onOverseasChargesChanged = this.onOverseasChargesChanged.bind(this);
        this.onReinstatementFeesChanged = this.onReinstatementFeesChanged.bind(this);
        this.onEmailForReceiptChanged = this.onEmailForReceiptChanged.bind(this);
        this.sendInvoice = this.sendInvoice.bind(this);
        this.showLoadingSendInvoice = this.showLoadingSendInvoice.bind(this);

        this.state = {
            tierQuestions: [],
            xeroInventoryItems: [],
            xeroInventoryItem: {},
            loadingCreateInvoice: false,
            loadingUpdateXeroPayment: false,
            loadPrinting: false,
            overseasCharges: false,
            reinstatementFees: false,
            emailForReceipt: null,
            selectedMembershipTier: {
                value: `Standard`,
                label: `Standard`,
            },
        };
    }
    onOverseasChargesChanged(e) {
        this.setState({
            overseasCharges: e.target.checked,
        });
    }
    onEmailForReceiptChanged(e) {
        this.setState({
            emailForReceipt: e.target.value,
        });
    }
    onReinstatementFeesChanged(e) {
        this.setState({
            reinstatementFees: e.target.checked,
        });
    }
    printMembership() {
        this.setState({
            loadPrinting: true,
        });
        this.props.printMembership(this.props.match.params.applicationId).then(res => {
            this.setState({
                loadPrinting: false,
            });
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        });
    }
    createAndSendEmailReceipt(receiptRecipient) {
        this.props.createReceiptInS3(this.props.match.params.applicationId).then(res => {
            this.props.sendEmailReceipt(this.props.match.params.applicationId, res.file, receiptRecipient);
        });
    }
    createAndDownloadReceipt() {
        this.props.createReceiptInS3(this.props.match.params.applicationId).then(res => {
            this.props.dialogModalToggle();
            window.open(res.file);
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetState();
        this.props.setCalculateMembershipDatesFunction(this.calculateMembershipDates);
        this.props.getRelevantData(this.props.match.params.applicationId);
        this.getRelevantStuffsInternally(this.props.match.params.applicationId);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getRelevantStuffsInternally = async applicationId => {
        const { getMembershipAppllicationTierResponse, getMembershipAppllicationTierDocs, getXeroInventoryItems } = this.props;
        try {
            const tierRes = await getMembershipAppllicationTierResponse(applicationId);
            const docs = await getMembershipAppllicationTierDocs(applicationId);
            const { data } = await getXeroInventoryItems();
            const xeroInventoryItems = data.map(({ code }) => {
                return {
                    value: code,
                    label: code,
                };
            });
            if (!this._isMounted) return;
            this.setState({
                xeroInventoryItems,
                tierQuestions: tierRes.data,
                docs: docs.data,
            });
        } catch (error) {
            throw error;
        }
    };
    // ONCHANGE HANDLERS
    // xeroInventoryItemsChange = e => {
    //     this.setState({
    //         xeroInventoryItem: e,
    //     });
    // };
    membershipTierOnChange = e => {
        this.setState({
            selectedMembershipTier: e,
        });
    };
    membershipTypeOnChange = e => {
        this.setState({
            selectedMembershipType: e,
        });
    };
    onChangePaymentFields(e) {
        const key = e.target.dataset.valueKey;
        const value = e.target.value;
        this.props.onChangePaymentFields(key, value);
    }
    // ACTIONS
    changeApplicationType = async () => {
        const { application, match } = this.props;
        const { applicationType } = application;
        const { applicationId } = match.params;
        const { data } = await this.props.changeApplicationType(
            applicationId,
            applicationType === 'Renew' ? 'New' : applicationType === 'New' ? 'Renew' : '',
        );
        if (data) {
            this.showModal('Success', 'Successfully changed application type', null, true);
            this.props.getRelevantData(this.props.match.params.applicationId);
            return;
        }
        this.showModal('Error', 'Fail to change application type', null, true);
    };
    updateXeroPayment = async () => {
        try {
            this.showLoadingUpdateXeroPayment(true);
            const { data } = await this.props.updateXeroPayment(this.props.match.params.applicationId);
            if (data) {
                this.showModal('Success', 'Successfully update Xero payment', null, true);
                window.location.reload();
                return;
            }
            this.showModal('Error', 'Fail to update Xero payment', null, true);
        } catch (error) {
            this.showLoadingCrshowLoadingUpdateXeroPaymenteateInvoice(false);
            throw error;
        } finally {
            this.showLoadingUpdateXeroPayment(false);
        }
    };
    createInvoice = () => {
        try {
            this.showLoadingCreateInvoice(true);
            this.props.dialogModalToggle();
            this.props.createInvoice(this.props.match.params.applicationId);
        } catch (error) {
            throw error;
        } finally {
            this.showLoadingCreateInvoice(false);
        }
    };
    sendInvoice = () => {
        try {
            this.showLoadingSendInvoice(true);
            this.props.dialogModalToggle();
            this.props.sendInvoice(this.props.match.params.applicationId);
        } catch (error) {
            throw error;
        } finally {
            this.showLoadingSendInvoice(false);
        }
    };
    withdraw = async () => {
        try {
            this.props.forceLoadingState(true);
            const { applicationId } = this.props.match.params;
            const { error } = await this.props.withdraw(applicationId);
            // SUCCESS AND FAILURE HANDFLING
            if (error) throw error;
            this.showModal(null, 'Application successfully withdrawn.', () => history.replace('/staff/membership/applications'), true);
        } catch (error) {
            this.showModal('Error', 'An error occurred during withdrawing the application', this.props.dialogModalToggle, true);
            throw error;
        } finally {
            this.props.forceLoadingState(false);
        }
    };
    markAsPaid = async () => {
        try {
            this.props.forceLoadingState(true);
            const { applicationId } = this.props.match.params;
            const { error } = await this.props.markAsPaid(applicationId);
            // SUCCESS AND FAILURE HANDFLING
            if (error) throw error;
            this.showModal(null, 'Application successfully mark as paid.', () => history.replace('/staff/membership/applications'), true);
        } catch (error) {
            this.showModal('Error', 'An error occurred during mark this application as paid', this.props.dialogModalToggle, true);
            throw error;
        } finally {
            this.props.forceLoadingState(false);
        }
    };
    validateThenToggleModalMarkAsPaid = () => {
        this.props.forceValidate().then(() => {
            const keysToValidate = {
                amountReceived: true,
                datePaymentReceived: true,
                invoiceNumber: true,
                referenceNumber: true,
            };
            const valid = validateFromValidationObject(this.props.validation, keysToValidate);
            if (valid === false) return;
            this.showModal(
                null,
                modalStrings.markAsPaid,
                () => this.props.markAsPaid(this.props.match.params.applicationId, this.props.application.invoiceId),
                true,
            );
        });
    };
    toggleModalProcess = procAction => {
        if (typeof procAction != 'string') return;
        var modalBodyToDisplay;
        switch (procAction) {
            case 'doReject':
                modalBodyToDisplay = <RejectComment />;
                break;
            case 'sendReceipt':
                modalBodyToDisplay = <SendReceiptRemarks />;
                break;
            case 'downloadReceipt':
                modalBodyToDisplay = <GenerateAndDownloadReceipt />;
                break;
            default:
                modalBodyToDisplay = modalStrings[procAction];
                break;
        }

        this.props.dialogModalToggle();
        this.props.setModalData({
            modalOnlyOneButton: () => {
                return procAction === 'doReject' ? true : false;
            },
            modalAction: async () => {
                const { applicationId } = this.props.match.params;

                // SEND RECEIPT
                if (procAction == 'sendReceipt') {
                    this.createAndSendEmailReceipt(document.getElementById(`receiptRecipient`).value);
                    return;
                }

                if (procAction == 'downloadReceipt') {
                    this.createAndDownloadReceipt();
                    return;
                }

                // INVOICE GENERATION
                if (procAction == 'generateInvoice') {
                    this.props.generateXeroInvoice(applicationId, this.props.application.applicantFullName);
                    return;
                }

                // MARK AS COMPLETE
                if (procAction == 'processApplicationAsComplete') {
                    let res1 = await this.props.processMembershipApplicationComplete(
                        this.props.match.params.applicationId,
                        this.state.selectedMembershipTier.value,
                    );
                    let res2 = await this.props.regenerateCertificate(this.props.companyInfo.id);
                    this.props.triggerMembershipApplicationCompleted();
                    return;
                }

                // SUBMIT ON BEHALF
                if (procAction == 'submitOnBehalf') {
                    this.props.submitOnBehalf(
                        this.props.application.applicationId,
                        this.props.application.companyid,
                        this.props.application.packageid,
                    );
                    return;
                }

                // REJECT
                if (procAction == 'doReject') {
                    this.props.processReject(applicationId, document.getElementById(`rejectComment`).value);
                    return;
                }

                // REJECT EMAIL
                if (procAction == 'sendRejectEmail') {
                    let res1 = await this.props.sendRejectEmail(this.props.match.params.applicationId);
                    return;
                }

                // APPROVE
                if (procAction == 'doApprove') {
                    this.props.processApprove(applicationId, procAction, {
                        overseasCharges: this.state.overseasCharges,
                        reinstatementCharges: this.state.reinstatementFees,
                    });
                    return;
                }

                // START PROCESSING
                var params = null;
                try {
                    params = {
                        itemCode: this.state.xeroInventoryItem.value,
                    };
                } catch (e) {
                    throw e;
                } finally {
                    this.props.processMembershipApplication(this.props.match.params.applicationId, procAction, params);
                }
            },
            modalBody: modalBodyToDisplay,
        });
    };
    // UTILITIES
    showModal = (modalHeader, modalBody, modalAction, modalOnlyOneButton) => {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalOnlyOneButton,
            modalHeader,
            modalBody,
            modalAction,
        });
    };
    showLoadingUpdateXeroPayment = show => {
        this.setState({
            loadingUpdateXeroPayment: show,
        });
    };
    showLoadingCreateInvoice = show => {
        this.setState({
            loadingCreateInvoice: show,
        });
    };
    showLoadingSendInvoice = show => {
        this.setState({
            loadingSendInvoice: show,
        });
    };
    async resubmitQuestionnaires() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => {
                this.props.resubmitQuestionnaires(this.props.match.params.applicationId);
                this.setState({
                    time: new Date().getTime(),
                });
            },
            modalBody: 'Click OK to resubmit questionnaire responses.',
        });
    }
    tierResponsePostProcessor(tierResponseInfo) {
        const newResponse = {};
        tierResponseInfo.forEach(({ question, option }) => {
            if (!newResponse[question]) newResponse[question] = [];
            newResponse[question].push(option);
        });
        return newResponse;
    }

    calculateMembershipDates(application) {
        const { currentMembershipJoinedDate, currentMembershipEndDate, reinstatementApplication, packageid } = application;
        const mapDate = date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const year = moment().format('YYYY');
        const currentMembershipEndYear = moment(currentMembershipEndDate, 'YYYY-MM-DD').format('YYYY');
        let membershipPeriod = {};

        switch (packageid) {
            // 1 year
            case 1:
            case 8:
            case 11:
            case 14:
            case 15:
                membershipPeriod = {
                    startDate: mapDate(`${parseInt(year)}-01-01`),
                    endDate: mapDate(`${parseInt(year)}-12-31`),
                };
                break;
            // 2 years
            case 3:
                membershipPeriod = {
                    startDate: mapDate(`${parseInt(year)}-01-01`),
                    endDate: mapDate(`${parseInt(year) + 1}-12-31`),
                };
                break;
            // 1/2 year
            case 2:
            case 7:
                membershipPeriod = {
                    startDate: mapDate(`${year}-07-01`),
                    endDate: mapDate(`${year}-12-31`),
                };
                break;
            // 1 and 1/2 years
            case 6:
            case 9:
                membershipPeriod = {
                    startDate: mapDate(`${parseInt(year)}-07-01`),
                    endDate: mapDate(`${parseInt(year) + 1}-12-31`),
                };
                break;
            // Renew 2 Years
            case 4:
            case 10:
            case 12:
            case 13:
                membershipPeriod = {
                    startDate: mapDate(currentMembershipJoinedDate),
                    endDate: mapDate(`${parseInt(currentMembershipEndYear) + 2}-12-31`),
                };
                break;
            // Renew 1 Year
            case 5:
                membershipPeriod = {
                    startDate: mapDate(currentMembershipJoinedDate),
                    endDate: mapDate(`${parseInt(currentMembershipEndYear) + 1}-12-31`),
                };
                break;
        }
        if (reinstatementApplication === 1) {
            membershipPeriod.startDate = mapDate(currentMembershipJoinedDate);
        }
        return membershipPeriod;
    }
    render() {
        if (this.props.application.membershipType && this.props.loading) {
            let selected = {};
            if (this.props.application.membershipType === 'founding') {
                selected = {
                    label: 'Founding Member',
                    value: 'founding',
                };
            }
            if (this.props.application.membershipType === 'corporate') {
                selected = {
                    label: 'Corporate Member',
                    value: 'corporate',
                };
            }
            this.setState({
                selectedMembershipType: selected,
            });
        }

        let docNumber = 1;
        if (this.props.forceLoading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    loading={this.props.modalLoading}
                    modalBody={this.props.modalBody}
                    modalHeader={this.props.modalHeader}
                    modalAction={this.props.modalAction}
                    buttonDisabler={this.props.modalLoading}
                    boundForceUpdate={this.componentDidMount}
                    forceModalAction={this.props.forceModalAction}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                />
                <Row>
                    <Col xs={12}>
                        <CyderHeaderPanel
                            leftText={this.props.companyInfo.name}
                            rightText={`Submitted on: ${this.props.application && this.props.application.submittedOn}`}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={6}>
                        <h5 className="float-left">
                            Status: &nbsp;
                            <b>{cyderlib.getApplicationStatusAttr(this.props.application.status, 'text')}</b>
                            <br />
                            <br />
                            Application Type: &nbsp; <b>{this.props.application.applicationType}</b>
                            {this.props.application.status === 'not approved' ? (
                                <div>
                                    <div>
                                        <br />
                                        Comment:&nbsp;
                                        {this.props.application.rejectionComment}
                                    </div>
                                    <div>
                                        <br />
                                        <Button
                                            className="primary-btn-style mr-2"
                                            onClick={() => this.toggleModalProcess('sendRejectEmail')}
                                            style={{ position: 'relative' }}
                                        >
                                            <i className="fas fa-envelope" />
                                            &nbsp;Send Reject Email
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </h5>
                    </Col>
                    <Col xs={6}>
                        <h6 className="float-right">
                            {this.state.loadPrinting ? (
                                <div style={{ width: '20px', float: 'right', marginRight: '50px' }}>
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <Button
                                    className="primary-btn-style mr-2"
                                    onClick={() => this.printMembership()}
                                    style={{ position: 'relative' }}
                                >
                                    <i className="fas fa-print" />
                                    &nbsp;Print
                                </Button>
                            )}
                            <br />
                            <br />
                            <AuditListPopup contentTypeId={17} type="link" objectId={this.props.application.applicationId} />
                        </h6>
                    </Col>
                </Row>
                <br />
                <DetailsPageFormSectionTakeAction
                    company={this.props.companyInfo}
                    application={this.props.application}
                    membershipStartDate={this.props.application.membershipStartDate}
                    membershipEndDate={this.props.application.membershipEndDate}
                    onClickAction={this.toggleModalProcess}
                    handleChangeDateTime={this.props.handleChangeDateTime}
                    validationObj={this.props.validation}
                    handleChange={this.onChangePaymentFields}
                    calculateMembershipDates={this.calculateMembershipDates}
                    saveButtonPressed={this.props.saveButtonPressed}
                    // xeroInventoryItems={this.state.xeroInventoryItems}
                    // xeroInventoryItemsChange={this.xeroInventoryItemsChange}
                    sendInvoice={this.sendInvoice}
                    withdraw={this.withdraw}
                    markAsPaid={this.markAsPaid}
                    createInvoice={this.createInvoice}
                    loadingCreateInvoice={this.state.loadingCreateInvoice}
                    updateXeroPayment={this.updateXeroPayment}
                    loadingUpdateXeroPayment={this.state.loadingUpdateXeroPayment}
                    selectedMembershipTier={this.state.selectedMembershipTier}
                    membershipTierOnChange={this.membershipTierOnChange}
                    selectedMembershipType={this.props.application.membershipType}
                    membershipTypeOnChange={this.props.handleChangeMembershipType}
                    showModal={this.showModal}
                    overseasCharges={this.state.overseasCharges}
                    onOverseasChargesChanged={this.onOverseasChargesChanged}
                    onReinstatementFeesChanged={this.onReinstatementFeesChanged}
                    changeApplicationType={this.changeApplicationType}
                    applicationType={this.props.application.applicationType}
                />
                <DetailsPageFormMembershipPlan
                    staff
                    packages={this.props.packages}
                    status={this.props.application.statusid}
                    applicationType={this.props.application.renewalId ? 'Renewal' : 'New'}
                    applicationId={this.props.application.applicationId}
                    selectedPackage={this.props.packages.find(p => p.id === this.props.application.packageid)}
                />
                <DetailsPageFormSectionCorporateInfo company={this.props.companyInfo} />
                <DetailsPageFormSectionCorporateAddress company={this.props.companyInfo} />
                <DetailsPageFormSectionBusinessActivity
                    company={this.props.companyInfo}
                    catSubCat={this.props.catSubCat}
                    mainCatMap={this.props.mainCatMap}
                />
                <DetailsPageFormSectionCorporateRemark company={this.props.companyInfo} />
                <DetailsPageFormSectionPrimaryManagementRepresentative
                    section={'F'}
                    type={'Official'}
                    rep={this.props.companyRepInfo.officialManagementRep}
                    linkText="Edit Team Member"
                    link={
                        this.props.application.statusid != 5
                            ? '/staff/membership/applications/' +
                              this.props.match.params.applicationId +
                              '/' +
                              +this.props.application.companyid
                            : null
                    }
                />
                <DetailsPageFormSectionPrimaryManagementRepresentative
                    section={'G'}
                    type={'Secondary'}
                    rep={this.props.companyRepInfo.secondaryManagementRep}
                />
                <DetailsPageFormOthers questions={this.props.questions} qr={this.props.application.questionResponse} />
                <DetailsPageFormSection6 name={this.props.application.applicantFullName} />

                {this.state.docs != null && Object.keys(this.tierResponsePostProcessor(this.state.tierQuestions)).length > 0 && (
                    <RowCardHOC>
                        <h5>Section J: Sustainability Commitment</h5>
                        <br />
                        <div>
                            <TierResponsePanel
                                loading={false}
                                tierResponseInfo={this.state.tierQuestions}
                                tierResponseProcessor={this.tierResponsePostProcessor}
                                resubmitQuestionnaires={this.resubmitQuestionnaires}
                            />
                            <hr />
                            <br />
                            {this.state.docs != null && this.state.docs.length >= 1 && (
                                <span className="field-header">
                                    Documentation Uploaded:
                                    <br />
                                </span>
                            )}

                            {(this.state.docs == null || this.state.docs.length <= 0) && <b>Applicant has not uploaded any document</b>}

                            {this.state.docs != null && this.state.docs.length >= 1 && this.state.docs[0].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[0].docId}`}>
                                    <u>{docNumber++}. Corporate Sustainability Policy & Practice Framework</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 2 && this.state.docs[1].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[1].docId}`}>
                                    <u>{docNumber++}. Green Procurement Practices</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 3 && this.state.docs[2].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[2].docId}`}>
                                    <u>{docNumber++}. Corporate Sustainability Report</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 4 && this.state.docs[3].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[3].docId}`}>
                                    <u>{docNumber++}. Third Party audited Environmental Management Systems</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 5 && this.state.docs[4].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[4].docId}`}>
                                    <u>{docNumber++}. Corporate Sustainability Awards & Accolades</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 6 && this.state.docs[5].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[5].docId}`}>
                                    <u>{docNumber++}. Lease/ rent/ own Green Mark certified building</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 7 && this.state.docs[6].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[6].docId}`}>
                                    <u>{docNumber++}. Lease/ rent/ own Green Mark certified office interiors/ workplaces/retail premises</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 8 && this.state.docs[7].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[7].docId}`}>
                                    <u>{docNumber++}. Summary of organisation’s green building portfolio</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 9 && this.state.docs[8].docId != null ? (
                                <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[8].docId}`}>
                                    <u>{docNumber++}. Brief summary of the services provided</u>
                                    <br />
                                </a>
                            ) : null}
                        </div>
                        {this.props.application != null &&
                            this.props.application.tierUpgraded != null &&
                            this.props.application.tierUpgraded == 1 && (
                                <React.Fragment>
                                    <hr />
                                    <Button
                                        className="primary-btn-style mr-2"
                                        onClick={this.resubmitQuestionnaires}
                                        id="resubmitQuestionnaires"
                                    >
                                        <i className="fa fa-reply" />
                                        &nbsp; Ask to Resubmit
                                    </Button>
                                </React.Fragment>
                            )}
                    </RowCardHOC>
                )}
            </div>
        );
    }
}

export const TierResponsePanel = ({
    tierResponseInfo,
    tierResponseProcessor,
    loading,
    member,
    resubmitQuestionnaires,
    suggestedLevel,
    tierUpgradedStatus,
    orgCategory,
}) => {
    if (loading) {
        return <LoadingSpinner center />;
    } else {
        const newResInfo = tierResponseProcessor(tierResponseInfo);
        return tierResponseInfo.length === 0 ? (
            <div className="field-header">* No membership tier response were found</div>
        ) : (
            <React.Fragment>
                <Row className="p-2">
                    <Col xs={12} sm={12}>
                        {member ? (
                            <div>
                                <div className="field-header">You have submitted membership tier upgrade questionnaires answers.</div>
                                <p>Please contact SGBC for further amendment of your answers</p>
                            </div>
                        ) : (
                            <div>
                                {!member && tierUpgradedStatus && tierUpgradedStatus == 1 && (
                                    <React.Fragment>
                                        <h4 style={{ color: '#317446', fontWeight: 'bold' }}>
                                            Suggested Membership Tier : {suggestedLevel}
                                        </h4>
                                        <hr />
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
                <br />
                {orgCategory != null && (
                    <div>
                        <span style={{ fontSize: '17px', fontWeight: '700' }}>Organisation Primary Business Activity</span>
                        <br />
                        {orgCategory}
                        <br />
                        <br />
                    </div>
                )}

                {Object.keys(newResInfo).map((question, index) => (
                    <div key={index}>
                        <div className="field-header">
                            {index + 1 + '. ' + question}
                            <br />
                        </div>
                        <ul>
                            {newResInfo[question].map((option, index) => (
                                <li className="field-label" key={index}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </React.Fragment>
        );
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffMembershipApplicationsDetailsReducer,
        catSubCat: state.memberCompanyReducer.catSubCat,
        mainCatMap: state.memberCompanyReducer.mainCatMap,
        companyInfo: state.memberCompanyReducer.companyInfo,
        packages: state.memberNewMembershipApplicationReducer.packages,
        questions: state.memberNewMembershipApplicationReducer.questions,
        companyRepInfo: state.teamMembersDetailsReducer.companyRepInfo,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantData: id => {
            const pa = [
                dispatch(getApplicationDetails(id)),
                dispatch(memberMembershipApplicationGetPackageInfo()),
                dispatch(memberMembershipApplicationNewGetQuestions()),
                dispatch(memberMembershipApplicationGetCategories()),
                dispatch(getPaymentInfo(id)),
            ];
            return dispatch(forceLoadingState(true))
                .then(() => Promise.all(pa))
                .then(r =>
                    Promise.all([
                        dispatch(memberMembershipApplicationGetCompanyInfo(r[0].data.companyid)),
                        dispatch(getCompanyRepInfo(r[0].data.companyid)),
                    ]),
                )
                .then(() => dispatch(forceLoadingState(false)));
        },
        processMembershipApplication: (applicationId, procAction, params) => {
            dispatch(processMembershipApplication(applicationId, procAction, params));
        },
        processApprove: (applicationId, procAction, params) => {
            dispatch(processApprove(applicationId, procAction, params));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        setModalData: data => {
            dispatch(setModalData(data));
        },
        resetState: () => {
            dispatch(resetState());
            dispatch(memberMembershipApplicationResetFields());
            dispatch(setDialogModalToggle(false));
        },
        generateXeroInvoice: (applicationId, name) => {
            dispatch(generateXeroInvoice(applicationId, name));
        },
        onChangePaymentFields: (key, value) => {
            dispatch(onChangePaymentFields(key, value));
        },
        handleChangeMembershipType: obj => {
            dispatch(onChangePaymentFields('membershipType', obj));
        },
        handleChangeDateTime: (val, key) => {
            const value = val._isAMomentObject === true ? val.format('DD/MM/YYYY') : val;
            dispatch(onChangePaymentFields(key, value));
        },
        setCalculateMembershipDatesFunction: f => {
            dispatch(setCalculateMembershipDatesFunction(f));
        },
        markAsPaid: applicationId => {
            return dispatch(markAsPaid(applicationId));
        },
        forceValidate: () => {
            dispatch(forceValidate());
        },
        processMembershipApplicationComplete: (applicationId, membershipTier) => {
            return dispatch(processMembershipApplicationComplete(applicationId, membershipTier));
        },
        processReject: (applicationId, comment) => {
            dispatch(processReject(applicationId, comment));
        },
        forceLoadingState: ls => {
            dispatch(forceLoadingState(ls));
        },
        getXeroInventoryItems: () => {
            return dispatch(getXeroInventoryItems());
        },
        withdraw: id => {
            return dispatch(withdraw(id));
        },
        createInvoice: id => {
            return dispatch(createInvoice(id));
        },
        createReceiptInS3: id => {
            return dispatch(createReceiptInS3(id));
        },
        updateXeroPayment: id => {
            return dispatch(updateXeroPayment(id));
        },
        getMembershipAppllicationTierResponse: id => {
            return dispatch(getMembershipApplicationTierResponse(id));
        },
        getMembershipAppllicationTierDocs: id => {
            return dispatch(getMembershipApplicationTierDocs(id));
        },
        resubmitQuestionnaires: applicationId => {
            return dispatch(resubmitQuestionnaires(applicationId));
        },
        regenerateCertificate: companyId => {
            return dispatch(staffCompanyDetailsRegenerateCertificate(companyId));
        },
        triggerMembershipApplicationCompleted: () => {
            dispatch({
                type: 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS',
            });
        },
        sendEmailReceipt: (applicationId, link, email) => {
            return dispatch(sendEmailReceipt(applicationId, link, email));
        },
        printMembership: id => dispatch(printMembership(id)),
        sendRejectEmail: id => dispatch(sendRejectEmail(id)),
        sendInvoice: id => dispatch(sendInvoice(id)),
        sendReceiptEmail: id => {
            return dispatch(sendReceiptEmail(id));
        },
        submitOnBehalf: (appId, companyId, packageId) => {
            return dispatch(submitOnBehalf(appId, companyId, packageId));
        },
        changeApplicationType: (applicationId, applicationType) => {
            return dispatch(changeApplicationType(applicationId, applicationType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(StaffMembershipApplicationsDetailsPage));
