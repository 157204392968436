/**
 * Staff Application List page
 *
 * Author: Fernando Karnagi
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Link } from 'react-router-dom';
import moment from 'moment';

import StaffCertificationApplicationList from './StaffCertificationApplicationList';

class StaffCertificationApplicationMyCasePage extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMount() {}

    render() {
        return <StaffCertificationApplicationList {...this.props} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StaffCertificationApplicationMyCasePage),
);
