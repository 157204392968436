/**
 * Security action creators
 *
 * Author: Fernando
 */
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST,
    CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS,
    CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE,
    CREATE_ACCOUNT_CORPORATE_REQUEST,
    CREATE_ACCOUNT_CORPORATE_SUCCESS,
    CREATE_ACCOUNT_CORPORATE_FAILURE,
    CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT,
    CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE,
    CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY,
    CREATE_ACCOUNT_CORPORATE_RESET_FIELDS,
    CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE,
    CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY,
    CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY,
    CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA,
    CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY,
} from '../constants/actionTypesAccount';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';

export function createAccountSetPreviewOkay(bool) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY,
        bool,
    };
}
export function createAccountCorporateSetDefaultCountry(countryId) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY,
        countryId,
    };
}
export function prefillCompanyInfo(value) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY,
        value,
    };
}

export function setNoCompany(value) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY,
        value,
    };
}
export function createAccountCorporateResetFields() {
    return {
        type: CREATE_ACCOUNT_CORPORATE_RESET_FIELDS,
    };
}
export function createAccountCorporateChangeTimeout(value) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT,
        value,
    };
}
export function createAccountSetModalData(data) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA,
        data,
    };
}
export function onChangeField(key, value) {
    return {
        type: CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE,
        key,
        value,
    };
}
export function getValidationObj() {
    return dispatch => {
        dispatch({ type: CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE });
        return Promise.resolve();
    };
}
export function createAccountCorporatePreview(noCompany) {
    return (dispatch, getState) => {
        const reducer = getState().createAccountReducer;
        let action;
        let body;
        if (noCompany === false) {
            action = 'previewpersonalaccount';
            body = {
                email: reducer.fields.email,
                username: reducer.fields.username,
                companyId: reducer.fields.company_id,
            };
        } else {
            action = 'previewaccount';
            body = {
                countryId: reducer.fields.country,
                email: reducer.fields.email,
                companyRegNo: reducer.fields.uen_regno,
                companyName: reducer.fields.companyname,
                password: reducer.fields.password,
                confirmpassword: reducer.fields.confirmpassword,
            };
        }
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/users', {
            action: action,
        });
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST,
            CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS,
            CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE,
            options,
        );
    };
}
export function createAccountCorporate(noCompany) {
    return (dispatch, getState) => {
        const reducer = getState().createAccountReducer;
        let action;
        let body;
        if (noCompany === false) {
            action = 'createpersonalaccount'; // EXISTING COMPANY
            body = {
                companyid: reducer.fields.company_id,
                companyname: reducer.fields.companyname,
                firstname: reducer.fields.firstname,
                lastname: reducer.fields.lastname,
                email: reducer.fields.email,
                username: reducer.fields.email,
                password: reducer.fields.password,
            };
        } else {
            action = 'createcorporateaccountV2'; // NONE EXISTING COMPANY
            body = {
                password: reducer.fields.password,
                email: reducer.fields.email,
                countryId: reducer.fields.country,
                companyRegNo: reducer.fields.uen_regno,
                companyName: reducer.fields.companyname,
                firstname: reducer.fields.firstname,
                lastname: reducer.fields.lastname,
            };
        }
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/users', {
            action: action,
        });
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetchlib.fetchV2(
            dispatch,
            getState,
            'POST',
            url,
            body,
            CREATE_ACCOUNT_CORPORATE_REQUEST,
            CREATE_ACCOUNT_CORPORATE_SUCCESS,
            CREATE_ACCOUNT_CORPORATE_FAILURE,
            options,
        );
    };
}
