import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment, Card } from 'semantic-ui-react';
import { Container } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';

import history from 'history.js';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

import Box from 'pages/public/components/Box';

const certMenuItems = [{ label: 'Product', icon: 'cert', type: 'product' }, { label: 'Services', icon: 'cert', type: 'service' }];

class MemberCertNewApplicationSelectionPage extends React.Component {
    render() {
        return (
            <Fragment>
                <Container>
                    <NoShadowPanel title={`Please select certification`} panelStyle={{ backgroundColor: '' }} flat />
                </Container>
                <Grid centered stackable className="category service-directory">
                    <Grid.Column width={12}>
                        <Segment basic>
                            <Card.Group itemsPerRow={2}>
                                {certMenuItems.map((item, i) => {
                                    const { icon, label, type } = item;
                                    return (
                                        <Box
                                            type="service"
                                            key={i}
                                            src={`/assets/icons/icon-cert.png`}
                                            icon={icon}
                                            iconSize="small"
                                            header={`Apply for ${label} Certificate`}
                                            onClick={() => history.push(`/member/certification/${type}/newapplication`)}
                                        />
                                    );
                                })}
                            </Card.Group>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberCertNewApplicationSelectionPage));
