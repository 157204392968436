/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function search(keyword, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'getAllElectionsList',
        });

        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function getAllPrivateNominees(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/nominee`, {
            action: 'getAllPrivateNominees',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllPublicNominees(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/nominee`, {
            action: 'getAllPublicNominees',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllPrivateNomineesResult(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/nominee`, {
            action: 'getAllPrivateNomineesResult',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllPublicNomineesResult(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/nominee`, {
            action: 'getAllPublicNomineesResult',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getAllScrutineer(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/scrutineer`, {
            action: 'getAllScrutineer',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getVoterInfoByElectionId(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/voter`, {
            action: 'getVoterInfoByElectionId',
            electionId: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function findById(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'findById',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function login(voterId, voterKey, electionId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/voter`, {
            action: 'login',
        });

        var data = {
            voterId,
            voterKey,
            electionId,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function vote(voterId, voterKey, electionId, pubNominees, privNominees) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/voter`, {
            action: 'vote',
        });

        var data = {
            voterId,
            voterKey,
            electionId,
            pubNominees,
            privNominees,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function sendScrutineerEmail(scrutineerId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'sendScrutineerEmail',
            scrutineerId: scrutineerId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function sendMassVotingKey(electionId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'sendMassVotingKey',
            electionId: electionId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function sendMassVotingReminder(electionId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'sendMassVotingReminder',
            electionId: electionId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

// --- The below functions are samples

export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function save(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'create',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'update',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/election_v1/election`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}
