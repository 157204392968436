import {
    USER_DETAIL_GET_DETAIL_REQUEST,
    USER_DETAIL_GET_DETAIL_SUCCESS,
    USER_DETAIL_GET_DETAIL_FAILURE,
    USER_ADD_FORCE_VALIDATE,
    USER_DETAIL_CHANGE_FIELD_VALUE,
} from '../../../actions/constants/actionTypesAuth';

import cyderlib from '../../../js/cyderlib';

// defaults
var defaultState = {
    user: {
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        email: '',
        is_active: 0,
        is_staff: 0,
        is_superuser: 0,
        last_login: '',
        date_joined: '',
    },
    validation: {},
    loading: false,
    errorMessage: null,
    saveButtonPressed: false,
    redirectId: null,
};

export function userDetailReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case USER_DETAIL_GET_DETAIL_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case USER_DETAIL_GET_DETAIL_SUCCESS:
            newState.loading = false;
            newState.user = action.userData;
            return newState;
        case USER_DETAIL_GET_DETAIL_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.user = [];
            return newState;
        case USER_DETAIL_CHANGE_FIELD_VALUE:
            newState.user = Object.assign({}, state.user);
            newState.user[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            let pw = null;
            if (action.key === 'confirmpassword') {
                pw = state.user['password'];
            }
            newState.validation[action.key] = cyderlib.validate(
                action.key,
                action.value,
                pw,
            );

            return newState;
        case USER_ADD_FORCE_VALIDATE:
            newState.user = Object.assign({}, state.user);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.user) {
                let pw = null;
                if (k === 'confirmpassword') {
                    pw = state.user['password'];
                }
                newState.validation[k] = cyderlib.validate(
                    k,
                    state.user[k],
                    pw,
                );
            }
            newState.saveButtonPressed = true;
            return newState;
        default:
            return state;
    }
}
