import Axios from 'axios';

export const MYCORP_AUTH_ENDPOINTS = 'https://auth.api.mycorp.io';
export const SGBC_API_HOST = 'api.sgbc.online';
export const SGBC_WEB_HOST = 'sgbc.online';
export const SGBC_S3_HOST = 'https://s3-ap-southeast-1.amazonaws.com/bucket.sgbc.online';
export const SGBC_API_ROOT = 'https://' + SGBC_API_HOST;
export const SGBC_API_CORS_ROOT = SGBC_API_ROOT;
export const SGBC_API_KEY = 'SicgiMG19lagbR6Ugf6dy19f66tDAUoz9OlwVbju';
export const SGBC_S3_ROOT = SGBC_S3_HOST + '/attachment/';
export const SGBC_S3_CERT_LOGO_ROOT = SGBC_S3_HOST + '/certification_logo/';
export const SGBC_S3_THEMES_ROOT = SGBC_S3_HOST + '/themes/';
// export const SGBC_INVOICE_DOWNLOAD_ROOT = 'http://util.sgbc.online:3000/';
export const SGBC_INVOICE_DOWNLOAD_ROOT = 'https://api.sgbc.online/download_v1/';
export const SGBC_PRINT_ROOT = 'http://util.sgbc.online:3001';
export const SGBC_STRIPE_PK = 'pk_live_eYjzE32Je0KF8AXUnu8d4LsY';
export const SGBC_PRODUCT_CERT_SURVEY_ID = '61';
export const SGBC_SERVICE_CERT_SURVEY_ID = '61';
export const SGBC_MEMBERSHIP_CERT_SURVEY_ID = '52';
export const SGBC_DATA_CUTOVER_DATE = '2019-02-12';
export const SGBC_MODE = 'prod';
export const SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER = true;
export const SGBC_DISABLED_CERT = false;
export const SGBC_CRYPTO_KEY = 'CYDERMZI107SGBC';
export const SGBC_OVERRIDE_ENABLED_CERT_COMPANIES = [
    'pt ici paints indonesia (aka akzo indonesia)',
    'emix industry (s) pte ltd',
    'laticrete south east asia pte ltd',
    'dow chemical pacific (singapore) pte ltd',
    'graphenstone',
    'sto sea pte ltd',
    'akzo nobel paints (singapore) pte ltd',
    'ardex singapore pte. ltd.',
    'saint-gobain malaysia',
    'exterpark singapore',
    'netzaxch  ',
    'sg-glass',
    'naka corporation singapore branch',
    'chin leong construction systems pte ltd',
    'dyson singapore pte ltd',
    'trufire protection solutions/ trusteel project pte.ltd',
    'luxx newhouse pte ltd',
    'xero flor singapore pte ltd',
    'innoplan technology pte ltd',
    'polymer applications singapore pte ltd',
    'goodrich global pte ltd',
    'office planner pte ltd',
    'nippon paint',
    'bioclimatic solutions',
    'qingyuan gani ceramics co.,ltd',
    'mapei far east pte ltd',
    'armstrong flooring pte ltd',
    'goodrich global pte ltd',
    'rockwool india pvt ltd',
    'faberca pte ltd (faber chimica srl)',
    'pq builders pte ltd',
    'agc asia pacific pte ltd',
    'buildables pte ltd',
    'genisys office system pte ltd',
    'shaw industries asia pte ltd',
    '4s enterprise pte ltd (pingform)',
    'elval colour sa',
    'bronxculture pte ltd',
    'bayer (south east asia) pte ltd',
    'modulyss',
    'profloorz international pte ltd',
    '3m technologies (s) pte ltd',
    'sh building & trading pte. ltd.',
    'lytox technolologies pte ltd',
    'zirco applied materials co., ltd',
    'welmate, a division of wbl engineering and distribution pte ltd',
    'neuflor international pte ltd',
    'pt mura maha agung',
    'holcim singapore limited',
    'wholly triumph limited',
    'heng boon seng construction pte ltd',
    'zonling thin films international pte. ltd.',
    'livinwall pte. ltd.',
    'alseal marketing sdn bhd',
    'ekostik industries sdn bhd',
    'eco panel pte ltd',
    'morris schaefer asia pacific pte ltd',
    'semec enterprise pte ltd',
    'usg boral pte ltd',
    'chop chin hin pte ltd',
    'sinmix pte ltd',
    'ytl concrete',
    'eastern pretech pte ltd',
    'hl-manufacturing industries sdn bhd',
    'k.u.s pre-cast (s) pte ltd',
    'lafarge cement sdn. bhd.',
    'lwc alliance pte.ltd.',
    'joe green pte ltd',
    'associated concrete products (m) sdn bhd',
    'lafarge concrete (malaysia) sdn bhd',
    'ly concrete',
    'trane distribution pte ltd',
    'camfil singapore pte ltd',
    'grundfos (s) pte ltd',
    'daikin air-conditioning (singapore) pte ltd',
    'geberit south east asia pte ltd',
    'imi hydronic engineering pte ltd',
    'xylem water solutions singapore pte ltd',
    'abex engineering pte ltd',
    'armstrong fluid systems shanghai limited',
    'bry-air (malaysia) sdn. bhd.',
    'george fischer pte ltd',
    'impetus engineering pte ltd',
    'insulflex corporation sdn bhd',
    'k-flex malaysia sdn bhd',
    'marketing m & e 2000 pte ltd',
    'unistream engrg. (s) pte. ltd.',
    'ecospec global technology pte ltd',
    'first choice system engineering pte ltd',
    'hvs engineering pte ltd',
    'johnson controls (s) pte ltd',
    'spiralite singapore pte ltd',
    'ubz system pte ltd',
    'wefatherm gmbh',
    'zoe international pte ltd',
    'acd filtration pte ltd',
    'aquapro solutions pte ltd',
    'asia fabrication pte ltd',
    'baltimore aircoil asia',
    'calpeda asia pacific pte ltd',
    'eom engineering pte ltd',
    'ev solutions',
    'innovative polymers pte ltd',
    'marketing m & e 2000 pte ltd',
    'rietti group srl',
    'scentiva marketing llp',
    'skeisui singapore pte ltd',
    'winston engineering',
    'siemens',
    'toan cau building materials co. ltd',
    'innovative polymers pte ltd',
    'airverclean pte ltd',
    'danfoss industries pte ltd',
    'm.e power integration pte ltd',
    'huat heng engineering pte ltd -',
    'abb pte. ltd.',
    'schneider electric singapore pte ltd',
    'fuji smbe pte ltd',
    'furutec electrical sdn. bhd.',
    'mitsubishi electric asia pte ltd',
    'otis elevator company (s) pte ltd',
    'teco electric & machinery pte ltd',
    'cummins sales and service singapore pte ltd',
    'elsteel techno (s) pte ltd',
    'inter power engineering pte ltd',
    'power-plus (s) pte ltd',
    'syntech switchgear & engineering pte ltd',
    'wieland electric',
    'yaskawa electric (singapore) pte ltd',
    'cpg consultants pte ltd',
    'e-tech building services pte ltd',
    'honeywell pte ltd',
    'd-team engineering pte ltd',
    'g-energy global pte ltd',
    'cbm pte ltd',
    'c & w services (s) pte ltd',
    'comfort management pte ltd',
    'indeco engineers pte ltd',
];

export var instance = Axios.create({
    baseURL: SGBC_API_ROOT,
    headers: {
        Authorization: 'allow',
        'content-type': 'application/json',
        'x-api-key': SGBC_API_KEY,
    },
});

export const instanceNonCors = Axios.create({
    baseURL: SGBC_API_ROOT,
    headers: {
        Authorization: 'allow',
        'content-type': 'application/json',
        'x-api-key': SGBC_API_KEY,
    },
});

export const instanceCors = Axios.create({
    baseURL: SGBC_API_CORS_ROOT,
    headers: {
        Authorization: 'allow',
        'content-type': 'application/json',
        'x-api-key': SGBC_API_KEY,
    },
});
