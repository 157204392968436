import {
    APPLICATION_SEARCH_REQUEST,
    APPLICATION_SEARCH_SUCCESS,
    APPLICATION_SEARCH_FAILURE,
    APPLICATION_KEYWORD_SEARCH_REQUEST,
    APPLICATION_KEYWORD_SEARCH_SUCCESS,
    APPLICATION_KEYWORD_SEARCH_FAILURE,
    APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../../../actions/constants/actionTypesStaffMembershipApplications';
// TODO: migrate above items to actionTypesStaffMembership
import {
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST,
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE,
    STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS,
} from '../../../../actions/constants/actionTypesStaffMembership';

const defaultState = {
    selectedItems: {},
    selectedAll: false,
    applications: [],
    tablePages: -1,
    loading: false,
    loadingCount: false,
    totalCount: 0,
    statusCount: [],
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    selectedCount: 0,
    inputKeywordValue: '',
    keyword: '',
    filterBy: 'All',
    selected: 'All',
};

export function staffMembershipApplicationsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case APPLICATION_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case APPLICATION_SEARCH_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data || action.response;
            newState.applications.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case APPLICATION_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        case APPLICATION_KEYWORD_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        case APPLICATION_KEYWORD_SEARCH_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data || action.response;
            newState.applications.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case APPLICATION_KEYWORD_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        case APPLICATION_CHANGE_INPUT_KEYWORD_VALUE:
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS:
            newState.applications = action.response.data;
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.loading = false;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST:
            newState.loadingCount = true;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS:
            newState.totalCount = action.response.data[0].totalCount;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE:
            newState.loadingCount = false;
            return newState;
        case STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS:
            newState.filterBy = action.filterBtnId;
            newState.selected = action.filterBtnName;
            return newState;
        default:
            return state;
    }
}
