/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import StoredLayout from 'layouts/StoredLayout';

import ScoresheetViewPanel from '../../assessment/components/ScoresheetViewPanel';
import { Button } from 'reactstrap';

class AssessmentTemplatePreviewPage extends React.PureComponent {
    render() {
        return (
            <div className="page-widget">
                <div>
                    <Button
                        className="ml-auto primary-btn-style-outline"
                        onClick={e => {
                            history.push(`/staff/certification/applications/viewproduct/${this.props.match.params.originapplicationid}`);
                        }}
                    >
                        <i className="mr-1 material-icons align-text-bottom ">keyboard_arrow_left</i>
                        Back
                    </Button>
                </div>
                <div>
                    <ScoresheetViewPanel
                        showInstruction
                        staff
                        adjustPrintButton
                        type={this.props.type}
                        templateId={this.props.match.params.templateid}
                        scoresheetId={this.props.match.params.scoresheetid}
                        applicationId={this.props.match.params.applicationid}
                        staff
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => ({});

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AssessmentTemplatePreviewPage),
);
