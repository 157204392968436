import moment from 'moment';
import {
    EVENT_DATES_GET_EVENT_DATE_REQUEST,
    EVENT_DATES_GET_EVENT_DATE_SUCCESS,
    EVENT_DATES_GET_EVENT_DATE_FAILURE,
    EVENT_DATES_SAVE_EVENT_DATE_REQUEST,
    EVENT_DATES_SAVE_EVENT_DATE_SUCCESS,
    EVENT_DATES_SAVE_EVENT_DATE_FAILURE,
    EVENT_DATES_UPDATE_EVENT_DATE_REQUEST,
    EVENT_DATES_GET_EVENT_MULTIPLE_DATES_SUCCESS,
    GALA_EVENT_DATES_GET_EVENT_DATE_SUCCESS,
    GALA_EVENT_DATES_CHANGE_FIELD_VALUE,
    GALA_EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS,
    EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS,
    EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS,
    EVENT_DATES_UPDATE_EVENT_DATE_FAILURE,
    EVENT_DATES_SAVE_EVENT_DATE_WARNING,
    EVENT_DATES_CHANGE_FIELD_VALUE,
    EVENT_DATES_RESET_EVENT_FIELDS,
    EVENT_DATES_SET_EVENT_ID,
    EVENT_DATES_SET_MODAL_DATE,
    EVENT_DATES_ADD_EVENT,
    EVENT_DATES_DELETE_EVENT,
} from '../../../../actions/constants/events/actionTypesStaffEventsDate';
import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';

const defaultState = {
    loading: false,
    error: false,
    mode: 'save',
    fields: {
        date: '',
        startTimeForThisDay: '',
        endTimeForThisDay: '',
        eventId: 0,
    },
    dates: [
        {
            date: '',
            startTimeForThisDay: '',
            endTimeForThisDay: '',
            eventId: 0,
            id: new Date().getTime(),
        },
    ],
    ...defaultModalKeys,
};

export function eventsDateReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Get - Event Date
        case EVENT_DATES_GET_EVENT_DATE_REQUEST:
            return newState;
        case EVENT_DATES_GET_EVENT_DATE_SUCCESS:
            newState = Object.assign({}, state);
            switch (Object.keys(action.response).length) {
                case 0:
                    newState.mode = 'save';
                    break;
                default:
                    newState.mode = 'update';
                    newState.fields = action.response;
                    newState.fields.eventId = state.fields.eventId;
                    newState.fields.date = moment(newState.fields.date).format('DD/MM/YYYY');
                    break;
            }
            newState.error = false;
            return newState;
        case GALA_EVENT_DATES_GET_EVENT_DATE_SUCCESS:
            newState = Object.assign({}, state);
            switch (Object.keys(action.response).length) {
                case 0:
                    newState.mode = 'save';
                    break;
                default:
                    newState.mode = 'update';
                    newState.fields = action.response;
                    newState.fields.eventId = state.fields.eventId;
                    newState.fields.date = moment(newState.fields.data.date).format('DD/MM/YYYY');
                    newState.fields.startTimeForThisDay = moment(newState.fields.data.startTimeForThisDay, 'HH:mm:SS').format('HH:mm');
                    newState.fields.endTimeForThisDay = moment(newState.fields.data.endTimeForThisDay, 'HH:mm:SS').format('HH:mm');
                    break;
            }
            newState.error = false;
            return newState;
        case EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS:
            newState = Object.assign({}, state);
            newState.dates = Object.assign([], state.dates);
            newState.dates =
                action.response && action.response.data && action.response.data.length > 0 ? action.response.data : action.response;
            newState.dates.forEach(dateRecord => {
                dateRecord.date = moment(dateRecord.date).format('DD/MM/YYYY');
            });
            newState.error = false;
            return newState;
        case EVENT_DATES_GET_EVENT_DATE_FAILURE:
            newState.error = true;
            return newState;
        // Save - Event Date
        case EVENT_DATES_SAVE_EVENT_DATE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_DATES_SAVE_EVENT_DATE_SUCCESS:
            newState = Object.assign({}, defaultState);
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = `./tickets`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event date successfully saved.';
            return newState;
        case EVENT_DATES_SAVE_EVENT_DATE_FAILURE:
            newState.error = true;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = `./dates`;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to save event date.';
            return newState;
        case EVENT_DATES_UPDATE_EVENT_DATE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case GALA_EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = `./${newState.fields.eventId}`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event date successfully saved.';
            return newState;
        case EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS:
            // Set modal state
            newState.modalLoading = false;
            newState.modalLoading = false;
            newState.modalAction = `./tracks`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event date successfully saved.';
            return newState;
        case EVENT_DATES_UPDATE_EVENT_DATE_FAILURE:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = `./dates`;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to save event date.';
            return newState;
        case EVENT_DATES_SAVE_EVENT_DATE_WARNING:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Please fill in all fields before submit.';
            return newState;
        case GALA_EVENT_DATES_CHANGE_FIELD_VALUE:
            newState.fields = Object.assign({}, state.fields);
            // Validation
            if (typeof action.moment != 'object') {
                newState.fields[action.inputid] = state.fields[action.inputid];
                return newState;
            }
            // Pass validation
            switch (action.inputid) {
                case 'date':
                    newState.fields[action.inputid] = action.moment.format('DD/MM/YYYY');
                    break;
                case 'startTimeForThisDay':
                    newState.fields[action.inputid] = action.moment.format('HH:mm');
                    break;
                case 'endTimeForThisDay':
                    newState.fields[action.inputid] = action.moment.format('HH:mm');
                    break;
            }
            return newState;
        case EVENT_DATES_CHANGE_FIELD_VALUE:
            newState.dates = Object.assign([], state.dates);
            // Validation
            // if (typeof action.moment !== 'object') {
            //     newState.fields[action.inputid] = state.fields[action.inputid];
            //     return newState;
            // }
            for (var i = 0; i < newState.dates.length; i++) {
                // Pass validation
                if (newState.dates[i].id == action.eventDateId) {
                    newState.dates[i] = Object.assign({}, newState.dates[i]);
                    switch (action.inputid) {
                        case 'date':
                            newState.dates[i][action.inputid] = action.moment.format('DD/MM/YYYY');
                            break;
                        case 'startTimeForThisDay':
                            newState.dates[i][action.inputid] = action.moment;
                            break;
                        case 'endTimeForThisDay':
                            newState.dates[i][action.inputid] = action.moment;
                    }
                }
            }

            return newState;
        // Set - Event Id
        case EVENT_DATES_SET_EVENT_ID:
            newState.fields.eventId = action.eventid ? action.eventid : newState.fields.eventid;
        // Reset - All fields
        case EVENT_DATES_RESET_EVENT_FIELDS:
            newState = Object.assign({}, defaultState);
            return newState;
        // Set - Modal
        case EVENT_DATES_SET_MODAL_DATE:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case EVENT_DATES_ADD_EVENT:
            newState = Object.assign({}, state);
            newState.dates = Object.assign([], state.dates);
            newState.dates.push({
                date: '',
                startTimeForThisDay: '',
                endTimeForThisDay: '',
                eventId: 0,
                id: new Date().getTime(),
            });
            return newState;
        case EVENT_DATES_DELETE_EVENT:
            newState = Object.assign({}, state);
            newState.dates = Object.assign([], newState.dates);
            newState.dates = newState.dates.filter(date => date.id != action.id);
            return newState;
        default:
            return state;
    }
}
