import {
    STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS,
    STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_PERIOD_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_PERIOD_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_PERIOD_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE,
    STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA,
    STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT,
    STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';
import { setReducerModalState, defaultModalKeys } from '../../../js/modal';

// Default State
const defaultState = {
    loading: false,
    loadingPrintCert: false,
    document: {
        certificationPDF: '',
        membershipLetter: '',
    },
    memberApplication: [],
    ...defaultModalKeys,
};

export function staffCompanyDetailsMembershipReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Get = certification details
        case STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST:
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS:
            newState.document.certificationPDF = action.response.data[0].certificate_pdf;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE:
            return newState;
        // Get - memebership letter
        case STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST:
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS:
            newState.document.membershipLetter = action.response.data[0].membership_letter_pdf;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE:
            return newState;
        // Get - membership application
        case STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS:
            // console.log("action.response.data ", action.response.data)
            //newState.memberApplication = action.response.data[0];
            newState.memberApplication = action.response.data;
            // Object.keys(newState.memberApplication).forEach(key => {
            //     if (newState.memberApplication[key].toString() === '') {
            //         newState.memberApplication[key] = 'Not specified';
            //     }
            // });
            return newState;
        // Update - memebrship tier
        case STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Membership tier has been successfully updated';
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to update membership tier. Please try again.';
            return newState;
        case STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST:
            newState.loading = true;
            newState.modalLoading = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS:
            newState.loading = false;
            newState.modalLoading = false;
            newState.modalBody = 'Successfully resubmit membership tier documents.';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE:
            newState.loading = false;
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to resubmit membership tier documents.';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Regenerate - certificate
        case STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST:
            newState.loading = true;
            newState.modalLoading = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS:
            newState.loading = false;
            newState.modalLoading = false;
            newState.modalBody = 'Successfully regenerate certificate.';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE:
            newState.loading = false;
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to regenerate certificate.';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Print - certification
        case STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST:
            newState.loadingPrintCert = true;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS:
            newState.loadingPrintCert = false;
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE:
            newState.loadingPrintCert = false;
            return newState;
        // Others
        case STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        case STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING:
            newState.loading = action.show;
            return newState;
        default:
            return newState;
    }
}
