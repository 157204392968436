// Panel info
export const MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST = 'MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST';
export const MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS = 'MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS';
export const MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE = 'MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE';

export const MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY = 'MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY';

export const MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST = 'MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST';
export const MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS = 'MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS';
export const MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID = 'MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID';
export const MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED = 'MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED';
export const MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE = 'MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE';

export const MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST = 'MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST';
export const MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS = 'MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS';
export const MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE = 'MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE';
export const MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS = 'MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS';

export const MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST = 'MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST';
export const MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS = 'MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS';
export const MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE = 'MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE';

export const MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE = 'MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE';

export const MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST = 'MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_REQUEST';
export const MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS = 'MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS';
export const MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING = 'MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_ONGOING';
export const MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST = 'MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_SUCCESS_PAST';
export const MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE = 'MEMBER_CERTIFICATION_APPLICATIONS_GET_INFO_FAILURE';

// Member certification new application
export const MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID = 'MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID';

export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST = 'MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS = 'MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS';
export const MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE = 'MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE';

export const MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING = 'MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING';
export const MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG = 'MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG';

export const MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO = 'MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO';
export const MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO = 'MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO';
export const MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO = 'MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO';

export const MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST = 'MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_REQUEST';
export const MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS = 'MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_SUCCESS';
export const MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE = 'MEMBER_CERTIFICATION_RENEW_APPL_SAVE_APPLICATION_FAILURE';

export const MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT = 'MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT';

export const MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST = 'MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST';
export const MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS = 'MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS';
export const MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE = 'MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE';
export const MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST = 'MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST';
export const MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS = 'MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS';
export const MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE = 'MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE';
