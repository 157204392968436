import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Row, Col, Form, Button, Input } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';

import { search, getAllCategories } from 'actions/admin/misc/resources/action.js';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            keyword: '',
            pageCount: 0,
            categories: [],
            category: {
                label: `All`,
                value: 0,
            },
        };
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    componentDidMount() {
        this.props
            .getAllCategories()
            .then(categories => {
                var categoryToAdd = [];
                categoryToAdd.push({
                    value: 0,
                    label: `All`,
                });
                categories.data.forEach(category => {
                    categoryToAdd.push({
                        value: category.id,
                        label: category.category,
                    });
                });
                this.setState({
                    categories: categoryToAdd,
                });
            })
            .catch(error => {});
    }
    onCategoryChange(e) {
        this.setState({
            category: e,
        });
    }
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, this.state.category.value, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'Category',
                accessor: 'category',
                minWidth: 100,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Year',
                accessor: 'year',
                maxWidth: 70,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 50,
                sortable: false,
                Cell: props => {
                    return (
                        <Link to={'/admin/misc/resources/' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <div style={{ width: '250px' }}>
                                <Select
                                    style={{ width: '250px' }}
                                    autosize={false}
                                    options={this.state.categories}
                                    value={this.state.category}
                                    clearable={true}
                                    onChange={this.onCategoryChange}
                                    isMulti={false}
                                    name="selected-state"
                                    disabled={false}
                                    searchable={true}
                                />
                            </div>
                            <Input
                                type="text"
                                placeholder="Keyword"
                                onKeyDown={e => {
                                    if (e.keyCode == 13) {
                                        this.table.fireFetchData();
                                    }
                                }}
                                onChange={this.onKeywordChange}
                                value={this.state.keyword}
                                style={{ marginLeft: '10px', marginRight: '10px' }}
                            />
                            <Button className="ml-1 mr-1 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="/admin/misc/resources/0">
                                    <Button className="ml-1 mr-1 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, categoryId, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, categoryId, page, pageSize, sorted, filtered));
        },
        getAllCategories: () => {
            return dispatch(getAllCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
