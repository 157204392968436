/**
 * Member service review reducer
 *
 * Author: Fernando Karnagi
 */

var defaultState = {
    certification: {
        certificationNumber: 'SGBP 2017-950',
        category: 'Mechanical > Pump > Chilled/Condenser Water',
        rating: '1',
        validFrom: '1 October 2018',
        validTo: '31 December 2018',
        brand: 'eNSC Series',
        model: '151-412,152-133',
        validity: 'In-Review',
    },
    assessments: {
        sectionA: [
            {
                serialNo: 'A1',
                type: 'EE',
                title: 'Energy Management during Manufacturing Process',
                description:
                    'Evidence of the manufacturing plant’s energy management policies is in place.',
                documentsToSubmit: [
                    '1) Energy Management Policies',
                    '2) Letter of Declaration From CEO/ Authorised Representative',
                ],
            },
        ],
    },
};

export function memberServiceReviewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBER_SERVICE_REVIEW_SAMPLE_ACTION':
            newState.new = Object.assign({}, state.new);
            newState.new.selectedProductCategory =
                action.selectedProductCategory;
            return newState;
        default:
            return newState;
    }
}
