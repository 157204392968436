import React from 'react';

import { CardHeader } from 'reactstrap';

const CyderHeaderPanel = ({ leftText, rightText, children }) => (
    <CardHeader className="mb-2">
        <h2 className="float-left text-bold" style={{ color: '#317446' }}>
            {leftText}
        </h2>
        <span className="text-muted float-right">{rightText}</span>
        <div className="invisible">placeholder</div>
        {children}
    </CardHeader>
);

export default CyderHeaderPanel;
