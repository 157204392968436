import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from 'history.js';

import { ListGroupItem, Label, Row, Col, Form, FormGroup, Button, Badge, Card, CardTitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import InputFieldWithValidation from 'reactstrap-input';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';

import {
    eventLoadEventPaymentModes,
    loadRegistrationInfo,
    loadAttendants,
    printReceipt,
    sendReceipt,
} from 'actions/shared/eventRsvpAction.js';
import { getPaymentInfo, updatePayment } from 'actions/member/events/payment/action';

import { sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';
import { SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';

const ContentComponent = ({ label, info, children, className, newLine }) => {
    return newLine ? (
        <Col>
            <Row>
                <strong>{label}:</strong>
            </Row>
            <Row>{children ? children : <Label>{info}</Label>}</Row>
        </Col>
    ) : (
        <Row className={'p-1 ' + className}>
            <strong>{label}:</strong>
            {children ? (
                children
            ) : (
                <Label>
                    &nbsp;
                    {' ' + info}
                </Label>
            )}
        </Row>
    );
};
const AttendeePanel = ({ loading, attendees }) => {
    if (loading) return <LoadingSpinner />;
    return (
        <Card>
            <CardBody>
                <CardTitle className="p-3 d-flex">
                    <span>
                        <i className="fa fa-fw fa-users" /> Attendees
                    </span>
                </CardTitle>
                {attendees.map((attendee, i) => {
                    return (
                        <ListGroupItem key={i} className="d-flex flex-row p-4">
                            <ContentComponent newLine label="Name" info={attendee.name} className="col-sm-4 mt-1" />
                            &nbsp;
                            <ContentComponent newLine label="Email" info={attendee.email} className="col-sm-4" />
                            &nbsp;
                            <ContentComponent newLine label="Designation" info={attendee.designation} className="col-sm-4" />
                        </ListGroupItem>
                    );
                })}
            </CardBody>
        </Card>
    );
};

const RegistrationInfoPanel = ({
    loading,
    registrationid,
    registrationInfo,
    paymentInfo,
    attendees,
    printReceipt,
    loadPrintingReceipt,
    sendReceiptConfirmation,
    paymentStatus,
}) => {
    if (loading) return <LoadingSpinner />;
    return (
        <Card>
            <CardTitle className="d-flex p-3">
                <i className="fa fa-fw fa-info-circle" /> <span>Registration Information</span>
            </CardTitle>
            <CardBody className="p-4">
                <ContentComponent label="Registration ID" info={registrationid} />
                {/* <ContentComponent label="Ticket Type" info={registrationInfo.ticketTypeId} /> */}
                <ContentComponent label="Number of Pax" info={registrationInfo.ticketQuantity} />
                <ContentComponent label="Total Price SGD" info={registrationInfo.totalPrice} />
                <ContentComponent label="Registered On" info={registrationInfo.registrationSubmittedTimestamp} />
                {attendees.length > 0 && <ContentComponent label="Main Applicant" info={attendees[0].name} />}
                {/* <ContentComponent label="Invoice Date" info={paymentInfo ? paymentInfo.invoiceDate : null} /> */}
                <ContentComponent label="Invoice Number" info={paymentInfo ? paymentInfo.invoiceNumber : null} />
                <br />
                {loadPrintingReceipt ? (
                    <LoadingSpinner />
                ) : (
                    <Fragment>
                        <Button className="primary-btn-style">
                            <a
                                target="_blank"
                                style={{ color: 'white' }}
                                href={`${SGBC_INVOICE_DOWNLOAD_ROOT}download?entity=sgbc_green_council&invoiceno=${
                                    paymentInfo.invoiceNumber
                                }`}
                            >
                                Download Invoice
                            </a>
                        </Button>
                        <Button
                            className="primary-btn-style"
                            style={{ marginLeft: '10px' }}
                            onClick={printReceipt}
                            disabled={paymentStatus === 0}
                        >
                            Download Receipt
                        </Button>
                        <Button
                            className="text-bold text-capitalize"
                            style={{ marginLeft: '10px', backgroundColor: '#e1ab4c', borderColor: '#e1ab4c' }}
                            onClick={sendReceiptConfirmation}
                            disabled={paymentStatus === 0}
                        >
                            <span>
                                <i className="fa fa-envelope" />
                                &nbsp;
                            </span>
                            Send Receipt
                        </Button>
                    </Fragment>
                )}
            </CardBody>
        </Card>
    );
};

class EventsRegPaymentPage extends React.Component {
    constructor(props) {
        super(props);
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.showLoadingRegistration = this.showLoadingRegistration.bind(this);
        this.showLoadingPaymentMode = this.showLoadingPaymentMode.bind(this);
        this.showLoadingPaymentInfo = this.showLoadingPaymentInfo.bind(this);
        this.showLoadingAttendees = this.showLoadingAttendees.bind(this);
        this.paymentDateOnChange = this.paymentDateOnChange.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.updatePaymentInfo = this.updatePaymentInfo.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.printReceipt = this.printReceipt.bind(this);
        this.sendReceipt = this.sendReceipt.bind(this);
        this.sendReceiptConfirmation = this.sendReceiptConfirmation.bind(this);
        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            loadingSubmit: false,
            loadingRegistration: false,
            loadingAttendees: false,
            loadingPaymentMode: false,
            loadingPaymentInfo: false,
            loadPrintingReceipt: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            isMemberEvent: true,
            paymentInfo: {
                paymentStatus: 0,
            },
            attendees: [],
            registration: {},
        };
    }
    onTextChanged(value, field) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo[field] = value;
        this.setState({
            paymentInfo,
        });
    }
    paymentDateOnChange(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        try {
            paymentInfo.datePaymentReceivedCtrl = e;
            paymentInfo.datePaymentReceived = e.format('YYYY-MM-DD');
        } catch (e) {}
        this.setState({
            paymentInfo,
        });
    }
    modalAction() {
        this.modalToggle();
        const type = this.state.modal.type;
        if (type === 'sendReceipt') this.sendReceipt();
        if (type === 'update') history.push(`/staff/events/${this.props.match.params.eventid}/main`);
        if (type === 'failure') return 'forceUpdate';
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    async printReceipt() {
        try {
            this.setState({
                loadPrintingReceipt: true,
            });
            const res = await this.props.printReceipt(this.state.paymentInfo.id);
            window.open(SGBC_S3_HOST + '/temp/' + res.data.file);
        } catch (error) {
            //this.showModal('Error', 'Receipt cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrintingReceipt: false,
            });
        }
    }
    async sendReceipt() {
        const { paymentInfo, registration } = this.state;
        try {
            this.setState({
                loadPrintingReceipt: true,
            });
            const { ok } = await this.props.sendReceipt(paymentInfo.id, registration.id);
            this.showModalIf('Confirmation', 'Receipt has been successfully sent.', null, ok);
            this.showModalIf('Error', 'Failed to send receipt.', null, !ok);
        } catch (error) {
            this.showModalIf('Error', 'Failed to send receipt.', null, true);
            throw error;
        } finally {
            this.setState({
                loadPrintingReceipt: false,
            });
        }
    }
    sendReceiptConfirmation() {
        this.showModal('Confirm', 'Click OK to send receipt.', 'sendReceipt', false, true);
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, type, evaluation) {
        if (evaluation) this.showModal(header, message, type, null, true);
        return evaluation;
    }
    async updatePaymentInfo() {
        let paymentInfo = this.state.paymentInfo;
        console.log('paymentInfo: ', paymentInfo);
        paymentInfo.registrationId = this.props.match.params.registrationid;
        paymentInfo.eventId = this.props.match.params.eventid;
        this.setState({
            paymentInfo,
        });
        try {
            const { ok, error } = await this.props.updatePayment(this.state.paymentInfo);
            this.showModalIf('Confirmation', 'Payment has been successfully updated', 'update', ok);
            this.showModalIf('Error', 'Failed to update payement info', 'failure', !ok || error);
            if (ok) this.componentDidMount();
        } catch (error) {
            throw error;
        }
    }
    cancel() {
        this.showModal(
            'Confirmation',
            this.state.isMemberEvent
                ? `You have decided to settle the payment later. Please note that your registration will be void if we 
                    have not received payment 1 week before the event starts. Thank you.`
                : `You have decided to settle the payment later. Please note that your application will be void if we 
                    have not received payment within 1 week time period. Thank you.`,
            null,
            false,
            true,
        );
    }
    back() {
        history.goBack();
    }
    next() {
        this.setState({
            paymentStart: true,
        });
    }
    setPaymentMode(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.modeOfPaymentId = e.value;
        this.setState({
            selectedPaymentMode: e,
            paymentInfo,
        });
    }
    showLoadingPaymentInfo(show) {
        this.setState({
            loadingPaymentInfo: show,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingRegistration(show) {
        this.setState({
            loadingRegistration: show,
        });
    }
    showLoadingAttendees(show) {
        this.setState({
            loadingAttendees: show,
        });
    }
    showLoadingPaymentMode(show) {
        this.setState({
            loadingPaymentMode: show,
        });
    }
    setComponentMode(registrationId, applicationId) {
        if (registrationId) {
            this.setState({
                isMemberEvent: true,
            });
        } else if (applicationId) {
            this.setState({
                isMemberEvent: false,
            });
        }
    }
    formatDate(date) {
        try {
            const mapDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            return mapDate;
        } catch (e) {
            throw e;
        }
    }
    // load - registration info
    async loadRegistrationInfo(registrationId) {
        this.showLoadingRegistration(true);
        try {
            const registrationRes = await this.props.loadRegistrationInfo(registrationId);
            let registration = registrationRes.data;
            registration.registrationSubmittedTimestamp = this.formatDate(registration.registrationSubmittedTimestamp);
            this.setState({
                registration,
            });
        } catch (error) {
            throw error;
        }
        this.showLoadingRegistration(false);
    }
    // load - attendee info
    async loadAttendants(registrationId) {
        this.showLoadingAttendees(true);
        try {
            const attendantsRes = await this.props.loadAttendants(registrationId);
            this.setState({
                attendees: attendantsRes.data,
            });
        } catch (error) {
            throw error;
        }
        this.showLoadingAttendees(false);
    }
    // load - payment modes
    async loadEventPaymentModes(registrationId) {
        this.showLoadingPaymentMode(true);
        try {
            const paymentModesRes = await this.props.loadEventPaymentModes(registrationId);
            const paymentModes = paymentModesRes.data
                .map(paymentMode => {
                    // if (paymentMode.modeOfPaymentId < 4) {
                    return {
                        // If not undefined memberEvents, else membershipApplication
                        value: paymentMode.modeOfPaymentId,
                        label: paymentMode.paymentMode,
                    };
                    // }
                })
                .filter(x => x !== undefined);
            this.setState({
                paymentModes,
            });
        } catch (error) {
            throw error;
        }
        this.showLoadingPaymentMode(false);
    }
    // load - payment info
    async getPaymentInfo(registrationId) {
        try {
            const { data } = await this.props.getPaymentInfo(registrationId);
            const paymentInfo = data[0] && sanitizeStringAndObjectNullsToEmptyStrings(data[0]);
            if (paymentInfo) {
                const selectedPaymentMode = {
                    label: paymentInfo.paymentMode,
                    value: paymentInfo.modeOfPaymentId,
                };
                paymentInfo.datePaymentReceivedCtrl = moment(paymentInfo.datePaymentReceived, 'YYYY-MM-DD').format('DD/MM/YYYY');
                this.setState({
                    paymentInfo,
                    paymentStart: true,
                    selectedPaymentMode,
                });
                return;
            }
            this.setState(this.state);
        } catch (error) {
            throw error;
        }
    }
    componentDidMount() {
        const registrationId = this.props.match.params.registrationid;
        this.showLoadingPaymentInfo(true);
        this.loadRegistrationInfo(registrationId);
        this.loadAttendants(registrationId);
        this.getPaymentInfo(registrationId);
        this.loadEventPaymentModes(registrationId);
    }
    render() {
        const { paymentInfo } = this.state;
        return (
            <div className="page-widget">
                <Row className="p-2">
                    <h3>Registration Info & Payment</h3>
                </Row>
                <Row className="mb-2">
                    <Col sm={12} className="d-flex">
                        <span className="ml-auto">
                            Paid?
                            {paymentInfo ? (
                                <Fragment>
                                    {!paymentInfo.paymentStatus && (
                                        <Badge size="lg" color="danger" className="ml-2 text-bold">
                                            No
                                        </Badge>
                                    )}
                                    {paymentInfo.paymentStatus === 1 && (
                                        <Badge size="lg" className="ml-2 btn primary-btn-style" style={{ cursor: 'default' }}>
                                            Yes
                                        </Badge>
                                    )}
                                </Fragment>
                            ) : null}
                        </span>
                    </Col>
                </Row>
                <Row className="pb-4">
                    <Col sm={6}>
                        <RegistrationInfoPanel
                            loading={this.state.loadingRegistration}
                            registrationInfo={this.state.registration}
                            paymentInfo={this.state.paymentInfo}
                            attendees={this.state.attendees}
                            registrationid={this.props.match.params.registrationid}
                            printReceipt={this.printReceipt}
                            loadPrintingReceipt={this.state.loadPrintingReceipt}
                            sendReceiptConfirmation={this.sendReceiptConfirmation}
                            paymentStatus={this.state.paymentInfo.paymentStatus}
                        />
                        <br />
                        <AttendeePanel loading={this.state.loadingAttendees} attendees={this.state.attendees} />
                    </Col>
                    {this.state.loadingAttendees ? (
                        <LoadingSpinner />
                    ) : (
                        <Col xs={4}>
                            <FormGroup className="col-sm-6">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Amount Received"
                                    valueKey="amountReceived"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    validationObj={{}}
                                    value={paymentInfo.amountReceived || ''}
                                    handleChange={e => this.onTextChanged(e.target.value, 'amountReceived')}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-8">
                                <Label className="text-bold">Date Payment Received</Label>
                                <CyderDatePicker
                                    placeholder=""
                                    dateFormat="DD/MM/YYYY"
                                    closeOnSelect={true}
                                    onChange={this.paymentDateOnChange}
                                    value={
                                        paymentInfo && paymentInfo.datePaymentReceivedCtrl !== 'Invalid date'
                                            ? paymentInfo.datePaymentReceivedCtrl
                                            : ''
                                    }
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-8">
                                <Label className="text-bold">Mode of payment</Label>
                                {this.state.loadingPaymentMode ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Select
                                        autosize={false}
                                        autofocus
                                        options={this.state.paymentModes}
                                        simpleValue
                                        clearable={this.state.clearable}
                                        name="selected-state"
                                        disabled={this.state.disabled}
                                        value={this.state.selectedPaymentMode}
                                        onChange={this.setPaymentMode}
                                        searchable={this.state.searchable}
                                    />
                                )}
                            </FormGroup>
                            <FormGroup className="col-sm-12">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Bank"
                                    valueKey="aaa"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    validationObj={{}}
                                    handleChange={e => this.onTextChanged(e.target.value, 'bank')}
                                    value={this.state.paymentInfo.bank || ''}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-12">
                                <InputFieldWithValidation
                                    type="text"
                                    label="Reference number"
                                    valueKey="aaa"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    value={this.state.paymentInfo.referenceNumber || ''}
                                    validationObj={{}}
                                    handleChange={e => this.onTextChanged(e.target.value, 'referenceNumber')}
                                />
                            </FormGroup>
                            <FormGroup className="col-sm-12">
                                <InputFieldWithValidation
                                    type="textarea"
                                    rows={4}
                                    label="Remarks"
                                    valueKey="aaa"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    validationObj={{}}
                                    handleChange={e => this.onTextChanged(e.target.value, 'remarks')}
                                    value={this.state.paymentInfo.remarks || ''}
                                />
                            </FormGroup>
                            <Col>
                                <Button className="primary-btn-style" onClick={this.updatePaymentInfo}>
                                    Update Payment Info
                                </Button>
                            </Col>
                        </Col>
                    )}
                </Row>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    boundForceUpdate={this.componentDidMount}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventPaymentModes: registrationId => {
            return dispatch(eventLoadEventPaymentModes(registrationId));
        },
        getPaymentInfo: registrationId => {
            return dispatch(getPaymentInfo(registrationId));
        },
        loadAttendants: registrationId => {
            return dispatch(loadAttendants(registrationId));
        },
        loadRegistrationInfo: registrationId => {
            return dispatch(loadRegistrationInfo(registrationId));
        },
        updatePayment: data => {
            return dispatch(updatePayment(data));
        },
        printReceipt: id => {
            return dispatch(printReceipt(id));
        },
        sendReceipt: (paymentId, registrationId) => {
            return dispatch(sendReceipt(paymentId, registrationId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsRegPaymentPage));
