import withQuery from 'with-query';

import {
    STAFF_HOME_PANEL_GET_INFO_REQUEST,
    STAFF_HOME_PANEL_GET_INFO_SUCCESS,
    STAFF_HOME_PANEL_GET_INFO_FAILURE,
    FETCH_STAFF_DATA_DASHBOARD,
    FETCH_STAFF_DATA_REVIEW_LIST_COUNT,
} from '../../constants/actionTypesStaffHome';
import { SGBC_API_ROOT, SGBC_API_KEY, SGBC_API_CORS_ROOT } from 'config';
import fetchlib from 'js/fetchlib';
import Axios from 'axios';

export function staffHomePanelCertGetInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/dashboard_v1/staff`, {
            action: 'getDashboard',
        });
        dispatch({ type: STAFF_HOME_PANEL_GET_INFO_REQUEST });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_HOME_PANEL_GET_INFO_REQUEST,
            STAFF_HOME_PANEL_GET_INFO_SUCCESS,
            STAFF_HOME_PANEL_GET_INFO_FAILURE,
        );
    };
}

export const fetchStaffDataDashboard = () => (dispatch, getState) => {
    Axios.get(`${SGBC_API_ROOT}/dashboard_v1/staff?action=getDashboard`, {
        headers: {
            'x-api-key': SGBC_API_KEY,
            Authorization: getState().profile.authorizationToken,
        },
    })
        .then(response => {
            dispatch({
                type: FETCH_STAFF_DATA_DASHBOARD,
                payload: response.data.data,
            });
        })
        .catch(err => {
            throw err;
        });
};

// export const fetchStaffDataReviewListCount = () => (dispatch, getState) => {
//     Axios.get(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application?action=reviewListCount`, {
//         headers: {
//             'x-api-key': SGBC_API_KEY,
//             Authorization: getState().profile.authorizationToken,
//         },
//     })
//         .then(response => {
//             dispatch({
//                 type: FETCH_STAFF_DATA_REVIEW_LIST_COUNT,
//                 payload: response.data.data.reviewCount,
//             });
//         })
//         .catch(err => {
//             throw err;
//         });
// };

export function fetchStaffDataReviewListCount() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'reviewListCount',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, FETCH_STAFF_DATA_REVIEW_LIST_COUNT, null);
    };
}
