import {
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE,
    STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE,
    FETCH_CERTIFICATE_LIST,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';
import moment from 'moment';

const defaultState = {
    loading: false,
    eventsList: [],
    keyword: '',
};
export function staffCompanyDetailsEventsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS:
            newState.eventsList = action.response.data.map(event => {
                event.eventStartDate = moment(event.eventStartDate).format('D MMMM YYYY');
                return event;
            });
            newState.totalNum = action.response.totalNoOfItems;
            newState.loading = false;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE:
            newState = Object.assign({}, state);
            newState.keyword = action.value;
            return newState;
        case FETCH_CERTIFICATE_LIST:
            return { ...state, certificates: action.payload };
        default:
            return newState;
    }
}
