import React from 'react';
import { Grid, Button, Image, Segment, List, Header, Popup, Icon, Item, Divider, Tab } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchServiceCompanyInfo, fetchCertificateInfo } from '../../../actions/public';
import { mobile } from '../dummy';
import moment from 'moment';
import { ProductCertListPanel } from '../product/CertificateInfo';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';

export const ServiceCertListPanel = ({ item, onFetchServiceDetails, ratingTicks }) => (
    <Item as="a" key={item.certificateId} onClick={() => onFetchServiceDetails(item.companyId, item.serviceId)}>
        <Item.Content>
            <Segment basic>
                <Header className="company">{item.companyName}</Header>
            </Segment>
            <Item.Description className="description-list">
                <Grid columns={2}>
                    <Grid.Column style={{ fontWeight: 'normal', marginTop: '0px' }} width={10}>
                        <Grid columns={2}>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>Certification Number:</Grid.Column>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>
                                <strong>{item.certificateNumber}</strong>
                            </Grid.Column>
                        </Grid>
                        <Grid columns={2}>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>Services category:</Grid.Column>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>
                                <strong>{item.serviceCategory}</strong>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Grid columns={2}>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>Issued on:</Grid.Column>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>
                                <strong>{item.validFrom ? item.validFrom : '-'}</strong>
                            </Grid.Column>
                        </Grid>
                        <Grid columns={2}>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>Valid until:</Grid.Column>
                            <Grid.Column style={{ marginTop: '0px', paddingTop: '0px' }}>
                                <strong>{item.validTill ? item.validTill : '-'}</strong>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid>
                <Grid.Row columns={1} style={{ marginLeft: '10px' }}>
                    <Grid.Column>
                        <br />
                        {item.ratingId !== null && (
                            <Header as="h5" className="ticks" style={{ fontSize: 'unset' }}>
                                Rating {ratingTicks(item)}
                            </Header>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Item.Description>
        </Item.Content>
    </Item>
);

class ServiceDetail extends React.Component {
    state = { showCertificate: true, activeIndex: 0 };

    componentDidMount() {
        const {
            match: {
                params: { companyId, serviceId, serviceCategoryId },
            },
            changeServiceCategoryId,
        } = this.props;
        changeServiceCategoryId(serviceCategoryId);
        this.props.fetchServiceCompanyInfo({ companyId, serviceId });

        // retrieve list of product certs
        this.props.fetchCertificateInfo(-1, companyId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.serviceId !== this.props.match.params.serviceId) {
            this.setState({ activeIndex: 1 });
        }
    }

    handleFetchProductDetails = (certificateId, companyId) => {
        this.setState({ companyId });
        this.props.fetchCertificateInfo(certificateId, companyId);
        this.props.history.push(`/public/product/certificate/${certificateId}/${companyId}`);
    };

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

    render() {
        const {
            history,
            companyInfo: { companyDetails, serviceDetails, showMoreDetails },
            loading,
            ratingTicks,
            ratingTicksService,
            onFetchServiceDetails,
            certList,
        } = this.props;

        const { activeIndex } = this.state;

        const panes = [
            {
                menuItem: { key: 'company', icon: 'building outline', content: 'Company' },
                render: () => (
                    <Tab.Pane>
                        {companyDetails && companyDetails.logo && (
                            <Segment basic>
                                <Grid columns={mobile ? 1 : 3}>
                                    <Grid.Column>
                                        <img src={SGBC_S3_ROOT + companyDetails.logo} width={'100%'} />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        )}

                        <Segment basic>
                            <Header as="h4" content="Company details" />
                        </Segment>
                        <Grid columns={3}>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Company name</List.Header>
                                        {companyDetails && companyDetails.name}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Phone number</List.Header>
                                        {companyDetails && companyDetails.main_phone_number ? companyDetails.main_phone_number : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Website</List.Header>
                                        {companyDetails && companyDetails.website ? (
                                            <a href={'//' + companyDetails.website} target="_blank">
                                                {companyDetails.website}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid columns={3}>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Business address</List.Header>
                                        {companyDetails && companyDetails.business_address_line_1
                                            ? companyDetails.business_address_line_1
                                            : '-'}{' '}
                                        <br />
                                        {companyDetails && companyDetails.business_address_line_2
                                            ? companyDetails.business_address_line_2
                                            : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>General email</List.Header>
                                        {companyDetails && companyDetails.general_email ? companyDetails.general_email : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Country</List.Header>
                                        {companyDetails && companyDetails.countryName ? companyDetails.countryName : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane>
                ),
            },

            {
                menuItem: { key: 'certificate', icon: 'certificate', content: 'Certificate' },
                render: () => (
                    <Tab.Pane>
                        <Segment basic>
                            <Header as="h4" content="Certificate details" className="certificate-details" />
                        </Segment>
                        <Grid columns={3}>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Certificate number</List.Header>
                                        {serviceDetails && serviceDetails.certificateNumber ? serviceDetails.certificateNumber : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Valid from</List.Header>
                                        {serviceDetails && serviceDetails.validFrom
                                            ? moment(serviceDetails.validFrom, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                            : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid columns={3}>
                            {/* <Grid.Column>
                            <List relaxed>
                                <List.Item>
                                    <List.Header>Services description</List.Header>
                                    {serviceDetails && serviceDetails.serviceDescription ? serviceDetails.serviceDescription : "-"}
                                </List.Item>
                            </List>
                        </Grid.Column> */}
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Services category</List.Header>
                                        {serviceDetails && serviceDetails.serviceCategory}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Valid until</List.Header>
                                        {serviceDetails && serviceDetails.validTill
                                            ? moment(serviceDetails.validTill, 'YYYY-MM-DD').format('DD/MM/YYYY')
                                            : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            {/* <Grid.Column>
                            <List relaxed>
                                <List.Item>
                                    <List.Header>Rating</List.Header>
                                    {serviceDetails && serviceDetails.ratingName ? serviceDetails.ratingName : '-'}
                                </List.Item>
                            </List>
                        </Grid.Column> */}
                        </Grid>
                    </Tab.Pane>
                ),
            },

            {
                menuItem: { key: 'list', icon: 'tasks', content: 'List of Certificate' },
                render: () => (
                    <Tab.Pane>
                        <Segment basic>
                            <Header as="h4" content="List of Certificate" />
                        </Segment>
                        <Item.Group className="certificate-info">
                            {certList &&
                                certList
                                    .sort((a, b) => b.ratingId - a.ratingId)
                                    .map(item => (
                                        <ProductCertListPanel
                                            item={item}
                                            onFetchProductDetails={this.handleFetchProductDetails}
                                            ratingTicks={ratingTicks}
                                        />
                                    ))}

                            {showMoreDetails &&
                                showMoreDetails.length > 0 &&
                                showMoreDetails.map(item => (
                                    <ServiceCertListPanel
                                        item={item}
                                        onFetchServiceDetails={onFetchServiceDetails}
                                        ratingTicks={ratingTicksService}
                                    />
                                ))}
                        </Item.Group>
                    </Tab.Pane>
                ),
            },
        ];

        return (
            <Grid stackable centered className={`service-info ${loading ? 'loading' : ''}`}>
                <Grid.Column width={12} style={{ paddingLeft: 0 }}>
                    <Segment basic className="details">
                        <Header
                            as="h3"
                            content={companyDetails && companyDetails.name}
                            subheader={serviceDetails && serviceDetails.serviceDescription}
                        />
                        <Tab
                            menu={{ fluid: true, vertical: !mobile, secondary: true }}
                            activeIndex={activeIndex}
                            onTabChange={this.handleTabChange}
                            panes={panes}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = ({ public: publicReducer }, ownProps) => {
    const companyDetails = ownProps.companyInfo && ownProps.companyInfo.companyDetails;
    const serviceDetails = ownProps.companyInfo && ownProps.companyInfo.serviceDetails;
    const showMoreDetails = ownProps.companyInfo && ownProps.companyInfo.showMoreDetails;
    const companyId = companyDetails && companyDetails.id;
    const companyInfo = publicReducer.companyInfo && publicReducer.companyInfo;

    const certList =
        publicReducer.certificateInfo && publicReducer.certificateInfo[0] && publicReducer.certificateInfo[0].allCertificateDetail;

    const ratingTicks = item => {
        const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" />;

        let text = '';

        if (item.ratingId === 1) text = <span>{checkmark}</span>;
        else if (item.ratingId === 2)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 3)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 4)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );

        return text;
    };

    const ratingTicksService = item => {
        let text = '';

        let css = {
            color: '#78be20',
            fontWeight: 'bolder',
        };

        if (item.ratingId === 5 || item.ratingId === 1) text = <span style={css}> L1</span>;
        else if (item.ratingId === 6 || item.ratingId === 2) text = <span style={css}> L2</span>;
        else if (item.ratingId === 7 || item.ratingId === 3) text = <span style={css}> L3</span>;
        else if (item.ratingId === 8 || item.ratingId === 4) text = <span style={css}> L4</span>;

        return text;
    };

    return {
        companyInfo: companyInfo ? companyInfo : { companyDetails, serviceDetails, showMoreDetails },
        services: ownProps.services,
        companyId,
        certList,
        ratingTicks,
        ratingTicksService,
    };
};

// prettier-ignore
export default connect(mapState, { fetchServiceCompanyInfo, fetchCertificateInfo },)(withRouter(ServiceDetail));
