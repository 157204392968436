import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../history';
import { Row, Col, Container } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import MembershipRenewalAnalyticsToolbar from 'pages/analytics/components/membership-renewal/MembershipRenewalAnalyticsToolbar';
import MembershipRetentionRate from 'pages/analytics/components/membership-renewal/MembershipRetentionRate';
import MembershipRenewalList from 'pages/analytics/components/membership-renewal/MembershipRenewalList';
import MembershipRenewalDistribution from 'pages/analytics/components/membership-renewal/MembershipRenewalDistribution';
import BreakdownOfSuccessfulRenewal from 'pages/analytics/components/membership-renewal/BreakdownOfSuccessfulRenewal';
import DiscontinueReasonList from 'pages/analytics/components/membership-renewal/DiscontinueReasonList';
import StaffCompanySearch from 'elements/navbar-staff/Search';

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

class MembershipRenewalAnalytics extends React.Component {
    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={4}>
                        <StaffCompanySearch />
                    </Col>
                    <Col xs={8}>
                        <Row className="float-right">
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => history.push('/staff/analytics/membership')}
                                label="Membership Analytics"
                            />
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => history.push('/staff/analytics/membership/issuedanalytics')}
                                label="Issued Analytics"
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <MembershipRenewalAnalyticsToolbar />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <MembershipRenewalList />
                        <BreakdownOfSuccessfulRenewal />
                        <DiscontinueReasonList />
                    </Col>
                    <Col xs={6}>
                        <MembershipRenewalDistribution />
                        <MembershipRetentionRate />
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipRenewalAnalytics));
