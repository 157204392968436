import React from 'react';
import { Row, Col, Input, Button, CardBody } from 'reactstrap';
import { ButtonComponent, CardWrapper } from 'pages/staff/membership/applications/StaffMembershipExportPage';
import Select from 'react-select';

const status = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Expired',
        value: 'expired',
    },
    {
        label: 'New',
        value: 'new',
    },
];

const StaffMembershipPredetermineExport = ({ exportAction, years, onSelectChange }) => {
    return (
        <React.Fragment>
            <CardWrapper>
                <Row md={12}>
                    <h4 className="p-2">
                        <strong>Export Membership Statistics</strong>
                    </h4>
                </Row>
            </CardWrapper>
            <CardBody>
                <Row className="justify-content-center">
                    <Col>
                        <Select placeholder="Year" options={years} onChange={e => onSelectChange(e, 'year')} />
                    </Col>
                    <Col>
                        <Select placeholder="Status" options={status} onChange={e => onSelectChange(e, 'status')} />
                    </Col>
                </Row>
            </CardBody>
            <CardBody>
                <Row>
                    <Col className="text-right">
                        <Button className="primary-btn-style mr-2" onClick={() => exportAction()}>
                            Export
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </React.Fragment>
    );
};

const RowWrapper = ({ children, md }) => (
    <Col md={md}>
        <Row md={6}>{children}</Row>
    </Col>
);

export default StaffMembershipPredetermineExport;
