import React from 'react';
import { Grid, Card, Header, Button, Segment } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NoData from '../components/NoData';

class ProductTypes extends React.Component {
    render() {
        // prettier-ignore
        const { productTypes, match: { params }, subcatName, history, handleFetchProducts } = this.props;
        return (
            <Grid className="product-page">
                <Grid.Column width={16}>
                    <div className="navigator">
                        <Button basic icon="chevron left" content="Back" onClick={history.goBack} />
                        <Header as="h3">
                            Subcategory
                            <Header.Subheader>{subcatName}</Header.Subheader>
                        </Header>
                    </div>
                    {productTypes && productTypes.length < 1 ? (
                        <NoData header="No products for now" />
                    ) : (
                        <Card.Group itemsPerRow={5}>
                            {productTypes &&
                                productTypes.map(type => <Card header={type.type} onClick={() => handleFetchProducts(type.id, params)} />)}
                        </Card.Group>
                    )}
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = (state, ownProps) => {
    const subId = Number(ownProps.match.params.subcategoryId);
    const subcatName = ownProps.subcategories && ownProps.subcategories.filter(sub => sub.id === subId).map(item => item.subcategory)[0];
    const productTypes = ownProps.productTypes && ownProps.productTypes.filter(type => type.subcategoryId === subId);
    const productType = ownProps.productType && ownProps.productType.filter(type => type.showLabel === 1);

    return {
        productTypes,
        subcatName,
        productType,
    };
};

// prettier-ignore
export default connect(mapState)(withRouter(ProductTypes))
