/**
 * Events Analytics
 *
 * Author: Fernando
 */
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';
import moment from 'moment';

export function getNumberOfEventsCountAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getNumberOfEventsCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getNumberOfRegisteredAttendeeCountAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getNumberOfRegisteredAttendeeCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
//  For the company attendance : sgbcregistered -totalCompanyAttended, non sgbc registered-nonSGBCRegisteredCompany ,
//  x/y - attendedText
export function getTotalShowedUpCountAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getTotalShowedUpCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getCompanyAttendencCountAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getCompanyAttendencCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getIndividualAttendenceAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getIndividualAttendenceAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getTotalShowedUpCompanyAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getTotalShowedUpCompanyAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getTotalShowedUpIndividualAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getTotalShowedUpIndividualAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getAverageAttendence() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getAverageAttendence',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getTotalNoShowCountAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getTotalNoShowCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getSGBCMemberAttendeesAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getSGBCMemberAttendeesAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getIncomeCountExpAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getIncomeCountExpAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getRegistrantsAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getRegistrantsAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getEventTableSummaryAnalytic() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().eventsAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getEventTableSummaryAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getAllAttendeesCountAnalytic(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getAllAttendeesCountAnalytic',
            eventId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMemberAttendeesCountAnalytic(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getMemberAttendeesCountAnalytic',
            eventId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCheckedInCountAnalytic(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getCheckedInCountAnalytic',
            eventId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getOrganisationalCategoryRegisteredAnalytic(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getOrganisationalCategoryRegisteredAnalytic',
            eventId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getOrganisationalCategoryAttendedAnalytic(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getOrganisationalCategoryAttendedAnalytic',
            eventId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
