import React from 'react';

// SGBC custom components
import DashboardWidget from '../../../cyder/widget/DashboardWidget';
import Memberships from '../../../cyder/staff/dashboard/Memberships';
import MembershipApplications from '../../../cyder/staff/dashboard/MembershipApplications';
import CertificateApplications from '../../../cyder/staff/dashboard/CertificateApplications';
import EventsList from '../../../cyder/staff/dashboard/EventsList';
import Certificates from '../../../cyder/staff/dashboard/Certificates';
import Events from '../../../cyder/staff/dashboard/Events';

const Home = () => (
    <div>
        <div className="row">
            <div className="col-12 col-lg-4">
                <DashboardWidget
                    title="Memberships"
                    description="Lorem ipsum dolor sit amet"
                >
                    <Memberships />
                </DashboardWidget>
                <DashboardWidget
                    title="Certificates"
                    description="Lorem ipsum dolor sit amet"
                >
                    <Certificates />
                </DashboardWidget>
                <DashboardWidget
                    title="Events"
                    description="Lorem ipsum dolor sit amet"
                >
                    <Events />
                </DashboardWidget>
            </div>
            <div className="col-12 col-lg-8">
                <DashboardWidget
                    title="Membership Applications"
                    description="Lorem ipsum dolor sit amet"
                >
                    <MembershipApplications />
                </DashboardWidget>
                <DashboardWidget
                    title="Certificates Applications"
                    description="Lorem ipsum dolor sit amet"
                >
                    <CertificateApplications />
                </DashboardWidget>
                <DashboardWidget
                    title="Upcoming Events"
                    description="Lorem ipsum dolor sit amet"
                >
                    <EventsList />
                </DashboardWidget>
            </div>
        </div>
    </div>
);
export default Home;
