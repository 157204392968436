import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card } from 'reactstrap';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';

var QRCode = require('qrcode.react');

class CorporatePayNow extends React.Component {
    render() {
        return (
            <Col lg="12" style={{ textAlign: 'center' }}>
                <Row>
                    <Col xs={12}>
                        <img src="/assets/images/qr_code_sgbc_dbs.png" />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>Please snap our SGBC Corporate PayNow</Col>
                </Row>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CorporatePayNow);
