import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { getActiveProductCategoryCountAnalytic } from 'actions/analytics/certificateAnalyticsAction';

class GreenAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Active Product Categories`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Product Categories</td>
                                        <td>{this.state.data.productsCategoryCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Product Sub-Categories</td>
                                        <td>{this.state.data.productsSubCategoryCount}</td>
                                    </tr>
                                    <tr>
                                        <td>Product Types</td>
                                        <td>{this.state.data.productsTypeCount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getActiveProductCategoryCountAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(GreenAttributes));
