import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Pie } from 'react-chartjs-2';
import { getCertifiedServiceDistributionAnalytic } from 'actions/analytics/certificateAnalyticsAction';

var ReactD3 = require('react-d3-components');
var randomColor = require('randomcolor');
var PieChart = ReactD3.PieChart;

var randomHexColor = () =>
    randomColor({
        luminosity: 'dark',
        hue: 'yellow',
        format: 'rgba',
        alpha: 0.6,
    });

class CertifiedServiceDistributionD3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            let data = [];
            res.data.forEach(element => {
                if (element.serviceCategory != null) {
                    data.push({
                        x: element.serviceCategory,
                        y: element.count,
                    });
                }
            });
            let values = {
                values: data,
            };
            this.setState({
                data: values,
            });
        });
    }

    render() {
        var tooltipPie = function(x, y) {
            return y.toString();
        };

        let data = {
            values: [{ x: 'SomethingA', y: 10 }, { x: 'SomethingB', y: 2 }, { x: 'SomethingC', y: 3 }],
        };
        // console.log(this.state.data);
        // console.log(data);
        return (
            <AnalyticsCard title={`Certified Services Distribution`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <PieChart
                            data={this.state.data}
                            width={450}
                            height={300}
                            margin={{
                                top: 10,
                                bottom: 10,
                                left: 50,
                                right: 50,
                            }}
                            tooltipOffset={{ top: 175, left: 200 }}
                            tooltipHtml={tooltipPie}
                            tooltipMode={'fixed'}
                            sort={null}
                        />
                        {/* <Pie data={this.state.data} height={150}/> */}
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getCertifiedServiceDistributionAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertifiedServiceDistributionD3));
