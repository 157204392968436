import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';

import AnalyticsToolbar from 'pages/analytics/components/AnalyticsToolbar';
import ButtonIcon from 'cyder/button/ButtonIcon';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';
import { Container } from 'semantic-ui-react';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check, icon }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon icon={icon} label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

class CertificationRenewalAnalyticsToolbar extends React.Component {
    constructor(props) {
        super(props);

        this.filterService = this.filterService.bind(this);
        this.filterProduct = this.filterProduct.bind(this);
        this.searchByDates = this.searchByDates.bind(this);
        this.filterYear = this.filterYear.bind(this);
        this.jumpToRenewal = this.jumpToRenewal.bind(this);

        this.state = {
            years: [
                { value: '2012', label: '2012' },
                { value: '2013', label: '2013' },
                { value: '2014', label: '2014' },
                { value: '2015', label: '2015' },
                { value: '2016', label: '2016' },
                { value: '2017', label: '2017' },
                { value: '2018', label: '2018' },
                { value: '2019', label: '2019' },
                { value: '2020', label: '2020' },
                { value: '2021', label: '2021' },
                { value: '2022', label: '2022' },
                { value: '2023', label: '2023' },
                { value: '2024', label: '2024' },
                { value: '2025', label: '2025' },
            ],
            selectedYear: null,
        };
    }
    jumpToRenewal() {
        history.push(``);
    }
    searchByDates() {
        this.props.refresh();
    }
    filterService() {
        this.props.setType('service');
        this.props.refresh();
    }
    filterProduct() {
        this.props.setType('product');
        this.props.refresh();
    }
    filterYear(e) {
        this.props.setStartDate(moment('01/01/' + e.value, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + e.value, 'DD/MM/YYYY'));
        this.setState({
            selectedYear: e,
        });
    }
    componentDidMount() {
        let currentYear = moment().format('YYYY');
        this.props.setStartDate(moment('01/01/' + currentYear, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + currentYear, 'DD/MM/YYYY'));
    }

    render() {
        return (
            <AnalyticsToolbar title={`Certificates Renewal Analytics`}>
                <React.Fragment>
                    <Container>
                        <Row>
                            <Col>
                                <Row>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterProduct}
                                            check={this.props.certificates.type == 'product'}
                                            label="Product Certificates"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterService}
                                            check={this.props.certificates.type == 'service'}
                                            label="Services Certificates"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{ float: 'right' }}>
                                    <Col>
                                        <Row>
                                            <Col xs="4">
                                                <Row>
                                                    <Col xs="12">Start*</Col>
                                                    <Col xs="12">
                                                        <CyderDatePicker
                                                            value={this.props.certificates.startDate}
                                                            onChange={this.props.setStartDate}
                                                            closeOnSelect={true}
                                                            datetimeClassName="mr-2"
                                                            dateFormat="DD/MM/YYYY"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="4">
                                                <Row>
                                                    <Col xs="12">End*</Col>
                                                    <Col xs="12">
                                                        <CyderDatePicker
                                                            value={this.props.certificates.endDate}
                                                            onChange={this.props.setEndDate}
                                                            closeOnSelect={true}
                                                            datetimeClassName="mr-2"
                                                            dateFormat="DD/MM/YYYY"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="2">
                                                <Row>
                                                    <Col xs="12" style={{ paddingTop: '20px' }}>
                                                        <AnalyticsButton
                                                            colStyle={buttonStyle}
                                                            onClick={this.searchByDates}
                                                            color={`primary`}
                                                            label=""
                                                            icon="arrow_forward"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                Choose by Year
                                                <Select
                                                    autosize={false}
                                                    options={this.state.years}
                                                    value={this.state.selectedYear}
                                                    clearable={true}
                                                    onChange={this.filterYear}
                                                    name="selected-state"
                                                    disabled={false}
                                                    searchable={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </AnalyticsToolbar>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        certificates: state.certificationRenewalAnalyticsReducer,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setStartDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'CERTIFICATION_RENEWAL_ANALYTICS_SET_START_DATE',
                    start: dt,
                });
            } catch (error) {}
        },
        setEndDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'CERTIFICATION_RENEWAL_ANALYTICS_SET_END_DATE',
                    end: dt,
                });
            } catch (error) {}
        },
        refresh: () => {
            try {
                return dispatch({
                    type: 'CERTIFICATION_RENEWAL_ANALYTICS_REFRESH',
                });
            } catch (error) {}
        },
        setType: chart => {
            try {
                return dispatch({
                    type: 'CERTIFICATION_RENEWAL_ANALYTICS_SET_TYPE',
                    chart,
                });
            } catch (error) {}
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationRenewalAnalyticsToolbar));
