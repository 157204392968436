import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { certificateRenewalRetentionInfoAnalytics } from 'actions/analytics/certificateAnalyticsAction';

class CertificationRetentionRate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.certification.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.certification.refresh,
            });
        }
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <AnalyticsCard title={`Retention Rate`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigText alignCenter text={`${Math.round(this.state.data.retentionRate)}%`} textColor={`#f8ac59`} />
                        <AnalyticsNormalText
                            text={`${this.state.data.totalRenewed} / ${this.state.data.totalCount} Successfully Renewed`}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        certification: state.certificationRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(certificateRenewalRetentionInfoAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationRetentionRate));
