/**
 * List of URLs for Admin
 *
 * Author: Fernando
 */

const urls = [
    {
        title: 'Administration',
        show: true,
        items: [
            {
                url: '',
                icon: 'supervisor_account',
                role: 'superuser',
                title: 'Authentication',
                show: true,
                items: [
                    {
                        url: '/admin/auth/users',
                        icon: '',
                        title: 'User',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/persons/salutations',
                        icon: '',
                        title: 'Salutation',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'account_circle',
                title: 'Company',
                role: 'superuser',
                show: true,
                items: [
                    {
                        url: '/admin/companies/company',
                        icon: '',
                        title: 'Company',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/companies/organisation-main-category',
                        icon: '',
                        title: 'Main Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/companies/organisation-sub-category',
                        icon: '',
                        title: 'Sub Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/companies/organisational-category-group',
                        icon: '',
                        title: 'Category Group',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/companies/organisational-category',
                        icon: '',
                        title: 'Category',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '/admin/mailinglist',
                icon: 'email',
                title: 'Mailing List',
                role: 'mailing_list_admin',
                show: true,
                items: [],
            },
            {
                url: '',
                icon: 'card_membership',
                title: 'Membership',
                role: 'membership_admin',
                show: true,
                items: [
                    {
                        url: '/admin/memberships/membership-application-status',
                        icon: '',
                        title: 'Application Status',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/memberships/membership-renewal-status',
                        icon: '',
                        title: 'Renewal Status',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/memberships/membership-discontinue-reason',
                        icon: '',
                        title: 'Discontinue Reason',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/memberships/membership-packages',
                        icon: '',
                        title: 'Membership Packages',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'stars',
                role: 'cert_admin',
                title: 'Certification',
                show: true,
                items: [
                    {
                        url: '/admin/product/product-category',
                        icon: '',
                        title: 'Product Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/product/product-sub-category',
                        icon: '',
                        title: 'Product Sub Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/product/product-type',
                        icon: '',
                        title: 'Product Type',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/product/product-listing-renewal-status',
                        icon: '',
                        title: 'Renewal Status',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/certificates/termination-reason',
                        icon: '',
                        title: 'Termination Reason',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/service/service-category',
                        icon: '',
                        title: 'Services Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/certificates/bca-data-option',
                        icon: '',
                        title: 'BCA Categories',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/certificates/other-data-option',
                        icon: '',
                        title: 'Green Attributes',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/certificates/building-type-option',
                        icon: '',
                        title: 'Green Credentials',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'event',
                title: 'Event',
                role: 'event_admin',
                show: true,
                items: [
                    {
                        url: '/admin/events/event-type',
                        icon: '',
                        title: 'Event Type',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/events/food-preference',
                        icon: '',
                        title: 'Food Preferences',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'list',
                title: 'Survey',
                role: 'All',
                show: true,
                items: [
                    {
                        url: '/admin/survey/survey-category',
                        icon: '',
                        title: 'Survey Category',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/admin/survey/survey-question-type',
                        icon: '',
                        title: 'Question Type',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'library_books',
                title: 'Resource',
                role: 'All',
                show: true,
                items: [
                    {
                        url: '/admin/misc/resources',
                        icon: '',
                        title: 'Resources',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'attach_money',
                title: 'Finances',
                role: 'All',
                show: true,
                items: [
                    {
                        url: '/admin/finances/payment-mode',
                        icon: '',
                        title: 'Payment Mode',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'dashboard',
                role: 'All',
                title: 'Miscellaneous',
                show: true,
                items: [
                    {
                        url: '/admin/misc/country',
                        icon: '',
                        title: 'Country',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                icon: 'home',
                title: 'SGBC Online',
                navToStaff: true,
                show: true,
                role: 'All',
                items: [],
            },
        ],
    },
];
export function navigationAdmin(state = Array.from(urls), action) {
    switch (action.type) {
        case 'SET_ADMINNAVIGATION':
            return Object.assign({}, state);
        default:
            return state;
    }
}
