import React from 'react';
import { Container, Col, Row } from 'reactstrap';

const AboutPanel = () => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            <Col xs={12} sm={12} className="text-center">
                <div className="welcome" style={{ marginBottom: 0 }}>
                    <span>SGBC</span> Directory
                </div>
            </Col>
        </Row>
    </Container>
);

export default AboutPanel;
