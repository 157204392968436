import React from 'react';
import validationlib from '../../../js/validation.js';
import {
    Input,
    // Table,
    Col,
    FormFeedback,
} from 'reactstrap';
const InputField = ({
    saveButtonPressed,
    validationObj,
    inputProps,
    value,
    valueKey,
    type,
    handleChange,
    noFeedback,
    xs,
    disabled,
}) => {
    return (
        <Col xs={xs || 10}>
            <Input
                disabled={disabled}
                value={value}
                type={type}
                id={valueKey}
                valid={validationlib.validInput(
                    validationObj,
                    valueKey,
                    saveButtonPressed,
                )}
                invalid={
                    validationlib.validInput(
                        validationObj,
                        valueKey,
                        saveButtonPressed,
                    ) === false
                }
                onChange={event => handleChange(event)}
                {...inputProps}
            />
            {noFeedback ? null : (
                <FormFeedback
                    valid={validationlib.formFeedbackValid(
                        validationObj,
                        valueKey,
                    )}
                >
                    {validationlib.formFeedbackText(validationObj, valueKey)}
                </FormFeedback>
            )}
        </Col>
    );
};

export default InputField;
