import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import history from '../../../../../history';
import { SGBC_S3_HOST } from 'config';

// COMPONENT
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import { withRouter } from 'react-router-dom';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

// PANEL
import CertificationDetailsPanel from 'pages/staff/certification/certifications/product/StaffCertificationApplicationProductCertDetails';
import AssessmentDetailsPanel from '../../assessment/components/ScoresheetViewPanel';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';
import { printCertificationApplication, getAllSuperAdmin } from 'actions/staff/certification/staffCertificationApplicationAction';
import { printCert, handleRegenerate, handleDelete, downloadCertificate } from 'actions/shared/certificationAction';

import { loadByApplicationId } from 'actions/staff/certification/markUserAgreementAction.js';

// ACTION
import {
    staffCertApplCertDetailsGetInfo,
    staffCertApplCertDetailsGetRating,
    staffCertApplCertDetailsSaveCertDetails,
} from 'actions/staff/certification/certifications/staffCertificationApplicationProductCertDetailsAction';
import { getServiceCategory } from 'actions/member/certification/applications/memberCertNewApplAction';

import { b64toBlob, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

class StaffCertificationApplicationServiceDetails extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.onTextChange = this.onTextChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleRegenerate = this.handleRegenerate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.onRatingChange = this.onRatingChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onServiceCategoryChange = this.onServiceCategoryChange.bind(this);
        this.onCertDetailsSave = this.onCertDetailsSave.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.printCert = this.printCert.bind(this);
        this.viewUserAgreementModalToggle = this.viewUserAgreementModalToggle.bind(this);
        this.viewUserAgreement = this.viewUserAgreement.bind(this);

        this.haveMUA = this.haveMUA.bind(this);
        this.printMUA = this.printMUA.bind(this);

        this.state = {
            haveMua: false,
            loading: false,
            isAdmin: false,
            activeTab: 'certDetails',
            certDetailsState: {
                mode: 'service',
                certDetails: {},
                productTypeOptions: [],
                serviceCategoryOptions: [],
                ratingOptions: [],
                selectedRatingName: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            viewUserAgreementModalShow: false,
        };
    }
    viewUserAgreementModalToggle() {
        this.setState({
            viewUserAgreementModalShow: !this.state.viewUserAgreementModalShow,
        });
    }
    // LIFECYCLE
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    // UTIL
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    async printMUA() {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printCertificationApplication(
                this.state.certDetailsState.certDetails.certificateApplicationId,
                'service',
                true,
            );
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        } catch (error) {
            this.showModal('Error', 'MUA cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }
    async haveMUA(id) {
        const { data } = await this.props.loadByApplicationId(id);
        if (data.length === 0) this.setState({ haveMua: false });
        else this.setState({ haveMua: true });
    }
    // GET DATA
    async getRelevantStuffs() {
        const certId = this.props.match.params.certId;
        switch (this.state.activeTab) {
            case 'certDetails':
                this.getRelevantStuffsForCertDetails(certId);
                break;
            case 'assessmentDetails':
                break;
        }
    }
    viewUserAgreement() {
        this.viewUserAgreementModalToggle();
    }
    async getRelevantStuffsForCertDetails(certId) {
        try {
            this.showLoading(true);
            const { getCertDetails, getServiceCategory, getRating, getAllSuperAdmin } = this.props;
            const ratings = await getRating(0); // isProduct false
            const certDetails = await getCertDetails(certId);
            const serviceCategory = await getServiceCategory();

            let managersRes = await getAllSuperAdmin();
            let staffs = managersRes.filter(item => item.id == this.props.profile.id);

            if (this._isMounted) {
                this.setState({
                    certDetailsState: {
                        ...this.state.certDetailsState,
                        ratingOptions: ratings.data,
                        serviceCategoryOptions: serviceCategory.data,
                        certDetails: sanitizeStringAndObjectNullsToEmptyStrings(certDetails.data),
                    },
                    isAdmin: staffs.length > 0,
                });
            }
            this.haveMUA(this.state.certDetailsState.certDetails.certificateApplicationId);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    onTextChange(e, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = e.target.value;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onCheckboxChange(e, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = new Number(e.target.checked).valueOf();
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onDateChange(date, fieldName) {
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = date.format('YYYY-MM-DD');
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onRatingChange(selected, fieldName) {
        const { value } = selected;
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[fieldName] = value;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onServiceCategoryChange(value, serviceCategoryIdName, serviceCategoryIdValueName) {
        const { id, category } = value;
        const state = this.state.certDetailsState.certDetails;
        let certDetails = Object.assign({}, state);
        certDetails[serviceCategoryIdName] = id;
        certDetails[serviceCategoryIdValueName] = category;
        this.setState({
            certDetailsState: {
                ...this.state.certDetailsState,
                certDetails,
            },
        });
    }
    onCertDetailsSave(values) {
        this.showLoading(true);
        const { saveCertDetails } = this.props;
        const certificationDetails = {
            certificateId: values.id,
            ratingId: values.ratingId,
            certificateNumber: values.certificateNumber,
            mainContactPerson: values.mainContactPerson,
            mainContactNo: values.mainContactNo,
            mainContactEmail: values.mainContactEmail,
            serviceCategory: values.serviceCategory,
            serviceCategoryValue: values.serviceCategoryValue,
            productTypeId: values.productTypeId,
            validFrom: values.validFrom,
            validTo: values.validTo,
            brand: values.brand,
            lowVoc: values.lowVoc,
            withAppendix: values.withAppendix,
        };
        saveCertDetails(certificationDetails).then(res => {
            this.showLoading(false);
            this.showModal(res.error ? 'Error' : 'Confirmation', res.message, '', false, true);
        });
    }
    async printCert() {
        try {
            this.showLoading(true);
            await this.props.downloadCertificate(this.state.certDetailsState.certDetails.id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async confirmDelete() {
        this.showModal(
            'Are you sure you want to delete this certificate?',
            'This is a non reversible action. Deletion of certificate will also delete the service from services directory.',
            'Alert',
            false,
            false,
            () => {
                this.handleDelete();
            },
        );
    }
    async handleDelete() {
        try {
            this.showLoading(true);
            const { data, error } = await this.props.handleDelete(this.state.certDetailsState.certDetails.id, 'service');
            this.showModalIf('Confirmation', 'Certificate successfully deleted.', data, () => {
                history.push(`/staff/certification/certificates/service`);
            });
            this.showModalIf('Error', 'Certificate cannot be deleted', !data || error);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async handleRegenerate() {
        try {
            this.showLoading(true);
            const { data } = await this.props.handleRegenerate(this.state.certDetailsState.certDetails.id, 'service');
            if (data) {
                this.showModal('Confirmation', <p>Certificate has been regenerated.</p>, '', false, true);
            } else {
                this.showModal('Error', <p>Error in certificate regeneration.</p>, '', false, true);
            }
            // var blob = b64toBlob(data, 'application/pdf');
            // var blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    // TABS NAVIGATION
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => this.componentDidMount(),
            );
        }
    }
    renderNavItem(navItems) {
        return navItems.map(navItem => {
            const { key, text, icon } = navItem;
            return (
                <NavItem key={key}>
                    <NavLink
                        className={this.state.activeTab === key ? 'active' : null}
                        onClick={() => {
                            this.toggleTab(key);
                        }}
                    >
                        <i className={'fas ' + icon} />
                        {' ' + text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    // MODAL
    modalAction() {
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModalIf(header, message, evaluation, postHandler) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true, postHandler);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide, postHandler) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.postHandler = postHandler;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    render() {
        const navs = [
            {
                key: 'certDetails',
                text: 'Certification Details',
                icon: 'fa-pencil-alt',
            },
            {
                key: 'assessmentDetails',
                text: 'Assessment Details',
                icon: 'fa-edit',
            },
        ];

        const { modal, loading, activeTab, certDetailsState } = this.state;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={modal.postHandler || this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <React.Fragment>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <Nav tabs>{this.renderNavItem(navs)}</Nav>
                        </Col>
                    </Row>
                    {loading && <LoadingSpinner center />}
                    {!loading && (
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="certDetails">
                                <CertificationDetailsPanel
                                    state={certDetailsState}
                                    onDateChange={this.onDateChange}
                                    onTextChange={this.onTextChange}
                                    onRatingChange={this.onRatingChange}
                                    onCheckboxChange={this.onCheckboxChange}
                                    onServiceCategoryChange={this.onServiceCategoryChange}
                                    onCertDetailsSave={this.onCertDetailsSave}
                                    printCert={this.printCert}
                                    handleRegenerate={this.handleRegenerate}
                                    handleDelete={this.confirmDelete}
                                    onCertWithPrefixChange={this.onCertWithPrefixChange}
                                    viewUserAgreement={this.printMUA}
                                    isAdmin={this.state.isAdmin}
                                    haveMua={this.state.haveMua}
                                />
                            </TabPane>
                            <TabPane tabId="assessmentDetails">
                                {certDetailsState.certDetails.assessmentScoreSheetId != null &&
                                certDetailsState.certDetails.assessmentScoreSheetId != '' &&
                                certDetailsState.certDetails.assessmentTemplateId != null &&
                                certDetailsState.certDetails.assessmentTemplateId != '' &&
                                certDetailsState.certDetails.certificateApplicationId != null &&
                                certDetailsState.certDetails.certificateApplicationId != '' ? (
                                    <AssessmentDetailsPanel
                                        staff
                                        type={'service'}
                                        templateId={certDetailsState.certDetails.assessmentTemplateId || ''}
                                        scoresheetId={certDetailsState.certDetails.assessmentScoreSheetId || ''}
                                        applicationId={certDetailsState.certDetails.certificateApplicationId || ''}
                                        closed
                                    />
                                ) : (
                                    `No assessment is available`
                                )}
                            </TabPane>
                        </TabContent>
                    )}
                </React.Fragment>
                <ViewUserAgreementModal
                    viewUserAgreementModalShow={this.state.viewUserAgreementModalShow}
                    viewUserAgreementModalToggle={this.viewUserAgreementModalToggle}
                    applicationId={certDetailsState.certDetails.certificateApplicationId}
                />
            </div>
        );
    }
}

const ViewUserAgreementModal = props => (
    <Modal isOpen={props.viewUserAgreementModalShow} toggle={props.viewUserAgreementModalToggle}>
        <ModalBody>
            <MarkUserAgreementPanel applicationId={props.applicationId} readonly applicationSource={`Certification`} />
        </ModalBody>
        <ModalFooter>
            <Button className="text-bold primary-btn-style" onClick={props.viewUserAgreementModalToggle}>
                Close
            </Button>
        </ModalFooter>
    </Modal>
);
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberProductApplication,
        ...state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // Cert Detail
        getCertDetails: certId => {
            return dispatch(staffCertApplCertDetailsGetInfo(certId));
        },
        getRating: isProduct => {
            return dispatch(staffCertApplCertDetailsGetRating(isProduct));
        },
        getServiceCategory: () => {
            return dispatch(getServiceCategory());
        },
        saveCertDetails: data => {
            return dispatch(staffCertApplCertDetailsSaveCertDetails(data, 'service'));
        },
        printCert: (certificateId, type) => {
            return dispatch(downloadCertificate(certificateId));
        },
        handleRegenerate: (certificateId, type) => {
            return dispatch(handleRegenerate(certificateId, type));
        },
        handleDelete: (certificateId, type) => {
            return dispatch(handleDelete(certificateId, type));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
        loadByApplicationId: id => {
            return dispatch(loadByApplicationId(id));
        },
        printCertificationApplication: (id, type, muaLocked) => {
            return dispatch(printCertificationApplication(id, type, muaLocked));
        },
        getAllSuperAdmin: () => {
            return dispatch(getAllSuperAdmin());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(StoredLayout(StaffCertificationApplicationServiceDetails)));
