import React, { Fragment } from 'react';

import { Row, Col, FormGroup } from 'reactstrap';
import Select from 'react-select';

import {
    DetailsPanelInput,
    PreviewField,
    PanelHeader,
} from 'pages/member/certification/applications/product/components/ApplicationDetailsPanel';

import { getOptionLabelProductTypes } from 'js/reactSelectLib';
const ProductDetailsPanelV2 = ({
    visible,
    editable,
    loading,
    productTypes,
    onProductTypeChange,
    selectedProductType,
    selectedProductCategory, // Available only when product type is selected in form
    selectedProductSubcategory,
    children,
    selectedCategoryValue, // Available after category selected from SelectCategoryPanel
}) => {
    return (
        visible && (
            <Fragment>
                <PanelHeader header="2. Product Details" />
                <Row className="pb-5 pt-4">
                    <Col className="" xs="10">
                        {editable ? (
                            <FormGroup>
                                <PreviewField row header="Product Category" label={selectedCategoryValue || selectedProductCategory} />
                                <p />
                                <p />
                                <DetailsPanelInput
                                    mandatory
                                    header="Product Type"
                                    editable={editable}
                                    value={selectedProductType && selectedProductType.type}
                                >
                                    <Col className="p-0" xs="6">
                                        <Select
                                            name="selected-state"
                                            autofocus
                                            isLoading={loading}
                                            options={productTypes}
                                            value={selectedProductType}
                                            onChange={onProductTypeChange}
                                            getOptionValue={option => option.id}
                                            getOptionLabel={getOptionLabelProductTypes}
                                        />
                                        <small className="form-text text-muted">
                                            Please select product type. If unsure, please select ‘Others’.
                                        </small>
                                    </Col>
                                </DetailsPanelInput>
                            </FormGroup>
                        ) : (
                            <Fragment>
                                <PreviewField
                                    header="Product Type"
                                    editable={editable}
                                    label={!selectedProductType ? 'Others' : selectedProductType.type}
                                />
                                <PreviewField header="Product Category" editable={editable} label={selectedProductCategory} />
                                <PreviewField header="Product Subcategory" editable={editable} label={selectedProductSubcategory} />
                            </Fragment>
                        )}
                    </Col>
                </Row>
                {children}
            </Fragment>
        )
    );
};
export default ProductDetailsPanelV2;
