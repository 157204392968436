import React from 'react';
import Logo from '../logo';
import { Link } from 'react-router-dom';
import Search from './Search';
import ToggleRightSidebar from './ToggleRightSidebar';
import DropdownUser from './DropdownUser';
import DropdownFlags from './DropdownFlags';
import '../../css/elements/navbar-2.css';
import './NavBar2.css';
import { NavItem, NavLink, Button } from 'reactstrap';
import moment from 'moment';

const Navbar2 = props => (
    <nav className="navbar navbar-2 d-flex justify-content-around align-items-center flex-nowrap">
        <Logo type={'member'} />
        {/* <Search /> */}
        {/*
        <NavLink to="/" tag={Link}>
            Resource Library
    </NavLink>
        <NavLink>
            Help
        </NavLink>
        */}
        <div className="separator" />
        <ul className="nav nav-inline nav-inline-2">
            <NavItem
                className="nav-item-dropdown"
                style={{
                    height: '100%',
                    paddingRight: '20px',
                }}
            />
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link nav-link-avatar">
                    <span className="nav-item navbar-text">
                        Welcome, {props.user.first_name}
                    </span>
                </a>
                <DropdownUser />
            </li>
        </ul>
    </nav>
);

export default Navbar2;
