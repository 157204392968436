import React from 'react';
import { connect } from 'react-redux';
import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';

class PaymentReceivedInfoPanel extends React.Component {
    render() {
        return (
            <Row>
                <Col>
                    {this.props.datePaymentReceived != null ? (
                        <div>
                            <br />
                            We have received your payment on {this.props.datePaymentReceived} as much as {this.props.amountReceived}
                            <br />
                        </div>
                    ) : (
                        <div>We have not received any payment</div>
                    )}

                    {this.props.modeOfPaymentId == null ? (
                        <div>
                            <br />
                            Please provide payment details below
                            <br />
                            <br />
                        </div>
                    ) : (
                        <div />
                    )}
                </Col>
            </Row>
        );
    }
}

export default PaymentReceivedInfoPanel;
