import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button } from 'reactstrap';

import { search } from 'actions/admin/misc/user-manual/action.js';

import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    componentDidMount() {}
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search('', page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'categoryId',
                accessor: 'categoryId',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'manualPdf',
                accessor: 'manualPdf',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'orderNumber',
                accessor: 'orderNumber',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'name',
                accessor: 'name',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 50,
                sortable: false,
                Cell: props => {
                    return (
                        <Link to={'/admin/misc/user-manual/' + props.value}>
                            <Button size="sm" color="info">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form inline>
                            <div className="ml-auto">
                                <Link to="/admin/misc/user-manual/0">
                                    <Button className="ml-1 mr-1 align-middle" color="success">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
