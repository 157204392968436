import React from 'react';
import history from '../../history';
import '../../css/ui-elements/buttons.css';

import '../../css/cyder/table/dashboard-table.css';
import { SGBC_S3_ROOT } from 'config';
import { Table, Button } from 'reactstrap';

const TableWidgetResourceLibrary = ({ items }) => (
    <div data-dashboard-table="resource-library">
        <div className="dashboard-table">
            <Table striped={false}>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Name</th>
                        <th>Year</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {items &&
                        items.map((item, i) => (
                            <tr key={i}>
                                <td className="py-2">{item.docNo}</td>
                                <td className="py-2">{item.docDescription}</td>
                                <td className="py-2">{item.docYear}</td>
                                <td className="py-2">
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        className="indicator"
                                        onClick={e => {
                                            if (item.resource.indexOf(`resources/`) >= 0) {
                                                window.open(`https://sgbc.online/media/${item.resource}`);
                                            } else {
                                                window.open(`${SGBC_S3_ROOT}${item.resource}`);
                                            }
                                        }}
                                    >
                                        <i className={'fa fa-download'} />
                                    </span>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Button
                className="ml-1 mr-1 align-middle primary-btn-style"
                onClick={e => {
                    history.push(`/member/misc/resources`);
                }}
            >
                <i className="material-icons align-text-bottom">chevron_right</i>
                &nbsp;View All
            </Button>
        </div>
    </div>
);

export default TableWidgetResourceLibrary;
