import { connect } from 'react-redux';
import { compose, branch, renderComponent, renderNothing } from 'recompose';
import LeftSidebar from './LeftSidebar';

const layouts = ['default-sidebar-1', 'collapsed-sidebar-1'];
const mapStateToProps = state => {
    if (state.profile.adminMode != null && state.profile.adminMode) {
        let navAdmin = Object.assign([], state.navigationAdmin);
        navAdmin[0] = Object.assign({}, state.navigationAdmin[0]);
        navAdmin[0].items = Object.assign([], state.navigationAdmin[0].items);
        let items = navAdmin[0].items.filter(item => {
            return (
                state.profile.user.is_superuser == 1 ||
                item.role == 'All' ||
                (state.profile.user.is_cert_admin == 1 && item.role != null && item.role.indexOf('cert_admin') >= 0) ||
                (state.profile.user.is_cert_manager == 1 && item.role != null && (item.role.indexOf('cert_manager') >= 0 || item.role.indexOf('cert_admin') >= 0)) || 
                (state.profile.user.is_event_admin == 1 && item.role != null && item.role.indexOf('event_admin') >= 0) ||
                (state.profile.user.is_mailing_list_admin == 1 && item.role != null && item.role.indexOf('mailing_list_admin') >= 0) ||
                (state.profile.user.is_membership_admin == 1 && item.role != null && item.role.indexOf('membership_admin') >= 0)
            );
        });
        navAdmin[0].items = items;
        return {
            navigation: navAdmin,
            layout: state.config.layout,
        };
    } else {
        return {
            navigation:
                state.profile.user.is_staff == 1 ||
                state.profile.user.is_superuser == 1 ||
                state.profile.user.is_cert_admin == 1 ||
                state.profile.user.is_cert_manager == 1 ||
                state.profile.user.is_event_admin == 1 ||
                state.profile.user.is_mailing_list_admin == 1 ||
                state.profile.user.is_membership_admin == 1
                    ? state.navigationStaffAsAdmin
                    : state.navigationStaff,
            layout: state.config.layout,
        };
    }
};
const Component = compose(
    connect(mapStateToProps),
    branch(({ layout }) => layouts.includes(layout), renderComponent(LeftSidebar), renderNothing),
)(LeftSidebar);

export default Component;
