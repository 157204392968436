import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import DialogModal from 'cyder/modals/DialogModal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, Input, FormGroup, Label } from 'reactstrap';
import { search, assignTable, changeTableName } from 'actions/staff/dinner/registration/action.js';
import { load, loadTableName, loadRegistrant } from 'actions/staff/dinner/events/eventsTicketAction';
import LegendIcon from 'cyder/media/LegendIcon';
import Dropdown from 'pages/staff/dinner/seating/components/tableDropdown';
import { SGBC_API_ROOT, SGBC_API_KEY, SGBC_API_CORS_ROOT } from 'config';
import withQuery from 'with-query';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { ChartPanel, MealTypePanel } from '../dashboard/DinnerEventsDashboardPage';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setTableAttribute = this.setTableAttribute.bind(this);
        this.search = this.search.bind(this);
        this.tableTypeChange = this.tableTypeChange.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.assignTableAction = this.assignTableAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.changeTableName = this.changeTableName.bind(this);
        this.tableNameChangeAction = this.tableNameChangeAction.bind(this);
        this.onChangeTableName = this.onChangeTableName.bind(this);
        this.onChangeTableNo = this.onChangeTableNo.bind(this);
        this.getTableCount = this.getTableCount.bind(this);
        this.onTableChange = this.onTableChange.bind(this);
        this.getRelevantData = this.getRelevantData.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            tableLabel: '',
            buttonText: '',
            actualTableLabelArray: [],
            keyword: '',
            tableTypes: [
                { value: 'VIP', label: 'VIP' },
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Gold', label: 'Gold' },
                { value: 'Standard', label: 'Standard' },
                { value: 'All', label: 'All' },
            ],
            vipTables: [],
            platinumTables: [],
            goldTables: [],
            standardTables: [],
            tableType: { value: 'All', label: 'All' },
            selectedRegistrationId: 0,
            selectedTableId: 0,
            selectedTableNo: 0,
            selectedTableType: '',
            selectedTables: [],
            tableNameOptions: [],
            tableNameSelected: '',
            nonStandardMealType: 'all',
            tableCount: {
                vip: 0,
                platinum: 0,
                gold: 0,
                standard: 0,
                alltable: 0,
            },
            modal: {
                showModal: false,
                modalAction: '',
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }

    onTableChange(tableNo, tableId, tableType) {
        var state = this.state;
        state.selectedTableNo = tableNo;
        state.selectedTableId = tableId;
        state.selectedTableType = tableType;
        this.setState({
            state,
        });
    }
    onChangeTableName(e) {
        var state = this.state;
        state.tableNameSelected = e.target.value;
        this.setState({
            state,
        });
    }
    onChangeTableNo(e) {
        var state = this.state;
        state.selectedTableNo = e.target.value;
        this.setState({
            state,
        });
    }
    changeTableName(originalTableName) {
        var tableSection = (
            <Row className="mb-2">
                <Col>
                    <p>Please provide a table name</p>
                    <Input type="text" placeholder={originalTableName} onChange={e => this.onChangeTableName(e)} />
                </Col>
            </Row>
        );
        this.showModal('Change Table Name', tableSection, 'save', false, false, this.tableNameChangeAction);
    }
    showModal(header, body, type, positiveButtonHide, negativeButtonHide, action) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalAction = action;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalBody = body;
        this.setState({
            modal,
        });
    }
    setTableAttribute(tables) {
        console.log('tables', tables);
        var tableSelected = tables.selected;
        var tableNo = tables.tableNo;
        var tableName = tables.tableName;
        var buttonColor;
        var borderColor;
        var fontColor;
        var tableId = 0;
        var tableCount = 0;

        tables.registrants &&
            tables.registrants.map(registrant => {
                tableCount = +registrant.registrationType === 'Individual' ? 1 : 10;
            });
        tableName = tableName + ' ' + '{' + tableCount + '}';

        //buttonColor = tableSelected ? 'primary' : 'warning';
        if (tables.occupied) {
            if (tables.tableType === 'VIP') {
                buttonColor = '#78be20';
                borderColor = '#78be20';
            } else if (tables.tableType === 'Platinum') {
                buttonColor = '#c1c1c1';
                borderColor = '#c1c1c1';
            } else if (tables.tableType === 'Gold') {
                buttonColor = '#eab14d';
                borderColor = '#eab14d';
            } else if (tables.tableType === 'Standard') {
                buttonColor = '#6ba17d';
                borderColor = '#6ba17d';
            }
            fontColor = '#ffffff';
        } else {
            if (tables.tableType === 'VIP') {
                borderColor = '#78be20';
                fontColor = '#78be20';
            } else if (tables.tableType === 'Platinum') {
                borderColor = '#c1c1c1';
                fontColor = '#c1c1c1';
            } else if (tables.tableType === 'Gold') {
                borderColor = '#eab14d';
                fontColor = '#eab14d';
            } else if (tables.tableType === 'Standard') {
                borderColor = '#6ba17d';
                fontColor = '#6ba17d';
            }
            buttonColor = '#ffffff';
        }

        return (
            <Button
                block
                className="text-bold"
                style={{ 'background-color': buttonColor, 'border-color': borderColor, color: fontColor }}
                onClick={() => {
                    if (this.state.tableType.value !== 'All') {
                        this.onTableChange(tableNo, tableId, tables.tableType);
                        this.changeTableName(tables.tableName);
                    }
                }}
            >
                {tableName}
            </Button>
        );
    }
    tableNameChangeAction() {
        this.props
            .changeTableName(
                this.state.selectedTableNo,
                this.props.match.params.eventid,
                this.state.selectedTableType,
                this.state.selectedTableId,
                this.state.tableNameSelected,
            )
            .then(res => {
                this.modalToggle();
                this.getRelevantData(this.state.tableType.value);
                this.table.fireFetchData();
            })
            .catch(error => {});
    }
    assignTableAction(tableNo, registrationId) {
        this.props
            .assignTable(tableNo, registrationId)
            .then(res => {
                this.getRelevantData(this.state.tableType.value);
                this.table.fireFetchData();
            })
            .catch(error => {});
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    handleInputChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    async getTableCount() {
        var eventId = this.props.match.params.eventid;
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event-dinner-ticket-type', {
            action: 'getTableMaxCount',
            eventId,
        });
        const options = {
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };

        try {
            const response = await fetch(url, options);
            const text = await response.text();
            const data = JSON.parse(text);
            var tableCount = {
                vip: data.data[0].vip,
                platinum: data.data[0].platinum,
                gold: data.data[0].gold,
                standard: data.data[0].standard,
                alltable: data.data[0].alltable,
            };
            this.setState({
                tableCount,
            });
        } catch (error) {
            console.log('getTableCount error', error);
            throw error;
        }
    }
    getRelevantData(tableType) {
        var eventId = this.props.match.params.eventid;
        Promise.all([this.getTableCount(), this.props.getTicketType(eventId)]).then(() => {
            this.props.getTableName(eventId).then(() => {
                this.props.getRegistrant(eventId).then(() => {
                    let selectedTables;
                    if (tableType == 'Standard') {
                        selectedTables = this.props.standardTables;
                    } else if (tableType == 'VIP') {
                        selectedTables = this.props.vipTables;
                    } else if (tableType == 'Gold') {
                        selectedTables = this.props.goldTables;
                    } else if (tableType == 'Platinum') {
                        selectedTables = this.props.platinumTables;
                    } else if (tableType == 'All') {
                        selectedTables = this.props.allTables;
                    }
                    var tableNameOptions = [];
                    selectedTables.map(table => {
                        var obj = {
                            value: table.tableNo,
                            label: table.tableName,
                        };
                        tableNameOptions.push(obj);
                    });

                    this.setState({
                        selectedTables: selectedTables,
                        tableNameOptions: tableNameOptions,
                    });
                });
            });
        });
        this.table && this.table.fireFetchData();
    }
    componentDidMount() {
        this.getRelevantData(this.state.tableType.value);
    }
    search(page, pageSize, sorted, filtered) {
        var tables = [];
        if (this.state.tableType.value == 'Standard') {
            tables = this.state.standardTables;
        } else if (this.state.tableType.value == 'VIP') {
            tables = this.state.vipTables;
        } else if (this.state.tableType.value == 'Gold') {
            tables = this.state.goldTables;
        } else if (this.state.tableType.value == 'Platinum') {
            tables = this.state.platinumTables;
        } else if (this.state.tableType.value == 'All') {
            tables = this.state.allTables;
        }
        this.setState({
            loading: true,
        });
        this.props
            .search(
                this.state.keyword,
                this.state.tableType.value,
                this.state.nonStandardMealType,
                this.props.match.params.eventid,
                page,
                pageSize,
                sorted,
                filtered,
            )
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                    selectedTables: tables,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    tableTypeChange(e) {
        var state = this.state;
        state.tableType = e;
        this.setState({ state });
        this.getRelevantData(e.value);
    }

    setFilterBy(id) {
        var obj = { value: 'VIP', label: 'VIP' };
        switch (id) {
            case '0':
                obj = { value: 'All', label: 'All' };
                break;
            case '1':
                obj = { value: 'VIP', label: 'VIP' };
                break;
            case '2':
                obj = { value: 'Platinum', label: 'Platinum' };
                break;
            case '3':
                obj = { value: 'Gold', label: 'Gold' };
                break;
            case '4':
                obj = { value: 'Standard', label: 'Standard' };
                break;
        }
        this.tableTypeChange(obj);
    }

    render() {
        const columns = [
            { Header: 'Contact Name', accessor: 'contactName', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Contact Phone', accessor: 'contactPhone', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Contact Email', accessor: 'contactEmail', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Company Name', accessor: 'companyName', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            {
                Header: 'Registration Type',
                accessor: 'registrationType',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Table Name',
                accessor: 'tableNo',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                show: false,
            },
            {
                Header: 'Table Name',
                accessor: 'id',
                sortable: false,
                maxWidth: 150,
                Cell: props => {
                    return (
                        <Dropdown
                            options={this.state.tableNameOptions}
                            registrationId={props.row.id}
                            selectedTableNo={props.row.tableNo}
                            assignTable={this.assignTableAction}
                        />
                    );
                },
            },
        ];
        console.log('This.props', this.props);
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.state.modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={false}
                    negativeButtonHide={false}
                    modalBody={this.state.modal.modalBody}
                    modalHeader={this.state.modal.modalHeader}
                />

                <Row className="mb-2">
                    <Col xs={12}>
                        <Link to={`/staff/galadinner/${this.props.match.params.eventid}/info`}>
                            <Button className="primary-btn-style-outline">
                                <i className="material-icons align-text-bottom">chevron_left</i> Back
                            </Button>
                            &nbsp;
                        </Link>
                    </Col>
                </Row>
                <br />
                <Row className="mb-2">
                    <Col xs={12}>
                        <LegendIcon
                            clicked={this.state.tableType.value}
                            count={this.state.tableCount.alltable}
                            status="all"
                            text="All"
                            module="galadinner"
                            onClick={() => this.setFilterBy('0')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.state.tableType.value}
                            count={this.state.tableCount.vip}
                            status="vip"
                            text="VIP"
                            module="galadinner"
                            onClick={() => this.setFilterBy('1')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.state.tableType.value}
                            count={this.state.tableCount.platinum}
                            status="platinum"
                            text="Platinum"
                            module="galadinner"
                            onClick={() => this.setFilterBy('2')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.state.tableType.value}
                            count={this.state.tableCount.gold}
                            status="gold"
                            text="Gold"
                            module="galadinner"
                            onClick={() => this.setFilterBy('3')}
                        />
                        &nbsp;
                        <LegendIcon
                            clicked={this.state.tableType.value}
                            count={this.state.tableCount.standard}
                            status="standard"
                            text="Standard"
                            module="galadinner"
                            onClick={() => this.setFilterBy('4')}
                        />
                    </Col>
                </Row>
                <hr />
                {this.state.tableType.value !== 'All' && (
                    <Row style={{ backgroundColor: '#efefef' }}>
                        <TableComponent selectedTables={this.state.selectedTables} setTableAttribute={this.setTableAttribute} />
                    </Row>
                )}

                {this.state.tableType.value === 'All' && (
                    <div>
                        <h4 className="pt-1 pb-1">VIP Tables</h4>
                        <Row style={{ backgroundColor: '#efefef' }}>
                            <TableComponent
                                selectedTables={this.props.vipTables ? this.props.vipTables : []}
                                setTableAttribute={this.setTableAttribute}
                            />
                        </Row>

                        <h4 className="pt-3 pb-1">Platinum Tables</h4>
                        <Row style={{ backgroundColor: '#efefef' }}>
                            <TableComponent
                                selectedTables={this.props.platinumTables ? this.props.platinumTables : []}
                                setTableAttribute={this.setTableAttribute}
                            />
                        </Row>

                        <h4 className="pt-3 pb-1">Gold Tables</h4>
                        <Row style={{ backgroundColor: '#efefef' }}>
                            <TableComponent
                                selectedTables={this.props.goldTables ? this.props.goldTables : []}
                                setTableAttribute={this.setTableAttribute}
                            />
                        </Row>
                        <h4 className="pt-3 pb-1">Standard Tables</h4>
                        <Row style={{ backgroundColor: '#efefef' }}>
                            <TableComponent
                                selectedTables={this.props.standardTables ? this.props.standardTables : []}
                                setTableAttribute={this.setTableAttribute}
                            />
                        </Row>
                    </div>
                )}
                <hr />
                {this.state.tableType.value !== 'All' && (
                    <Row>
                        <Col xs={12}>
                            <CyderReactTable
                                className="-highlight mb-2"
                                data={this.state.list}
                                columns={columns}
                                minRows={0}
                                filterable={false}
                                manual
                                showPagination={this.state.pagination}
                                defaultPageSize={this.state.pageSize}
                                ref={instance => {
                                    if (instance != null) {
                                        this.table = instance.table;
                                    }
                                }}
                                totalNum={this.state.totalNum}
                                pages={this.state.pageCount}
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {
                                    this.search(state.page, state.pageSize, state.sorted, state.filtered);
                                }}
                            />
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

const TableComponent = props => {
    const selectedTables = props.selectedTables;
    const setTableAttribute = props.setTableAttribute;
    if (selectedTables !== []) {
        return selectedTables.map((tables, index) => {
            return (
                <Col xs="2" className="mt-1 mb-1" key={index}>
                    <Row>
                        {setTableAttribute(tables)}
                        {tables.occupied && (
                            <div style={{ marginLeft: '10px', color: 'black' }}>
                                {tables.registrants &&
                                    tables.registrants.map((registrant, j) => {
                                        return (
                                            <Row style={{ marginBottom: '10px' }} key={j}>
                                                {registrant.registrationType === 'Individual'
                                                    ? registrant.contactName
                                                    : registrant.companyName}{' '}
                                                {registrant.vegetarianMealAmount > 0
                                                    ? 'Ⓥ'
                                                    : registrant.halalMealAmount > 0
                                                    ? 'Ⓗ'
                                                    : registrant.halalMealAmount > 0 && registrant.vegetarianMealAmount > 0
                                                    ? 'Ⓗ Ⓥ'
                                                    : ''}
                                            </Row>
                                        );
                                    })}
                            </div>
                        )}
                    </Row>
                </Col>
            );
        });
    }
};

const mapStateToProps = (state, ownProps) => {
    console.log('state', state);
    const eventTickets = state.eventsTicketReducer.tickets && state.eventsTicketReducer.tickets[0];
    const tickets = {
        vip: eventTickets && eventTickets.vip,
        vipBooked: eventTickets && eventTickets.vipBooked,
        platinum: eventTickets && eventTickets.platinum,
        platinumBooked: eventTickets && eventTickets.platinumBooked,
        gold: eventTickets && eventTickets.gold,
        goldBooked: eventTickets && eventTickets.goldBooked,
        standard: eventTickets && eventTickets.standard,
        standardBooked: eventTickets && eventTickets.standardBooked,
    };

    return {
        ...state.dinnerEventsAllEventsReducer,
        mealType: state.eventsTicketReducer.mealType && state.eventsTicketReducer.mealType[0],
        tableCount: state.eventsTicketReducer.tableCount,
        vipTables: state.eventsTicketReducer.vipTables,
        platinumTables: state.eventsTicketReducer.platinumTables,
        goldTables: state.eventsTicketReducer.goldTables,
        standardTables: state.eventsTicketReducer.standardTables,
        allTables: state.eventsTicketReducer.allTables,
        registrants: state.eventsTicketReducer.registrants,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, tableType, eventId, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, tableType, eventId, page, pageSize, sorted, filtered));
        },
        getTicketType: eventId => {
            return dispatch(load(eventId));
        },
        changeTableName: (tableNo, eventId, tableType, tableId, tableName) => {
            return dispatch(changeTableName(tableNo, eventId, tableType, tableId, tableName));
        },
        assignTable: (tableNo, registrationId) => {
            return dispatch(assignTable(tableNo, registrationId));
        },
        getTableName: eventId => {
            return dispatch(loadTableName(eventId));
        },
        getRegistrant: eventId => {
            return dispatch(loadRegistrant(eventId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
