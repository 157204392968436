import React, { Fragment } from 'react';

import { Row, Col, FormGroup, Input } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import Select from 'react-select';

import {
    DetailsPanelInput,
    PreviewField,
    PanelHeader,
} from 'pages/member/certification/applications/product/components/ApplicationDetailsPanel';
import { Label } from 'semantic-ui-react';

export const selectStyle = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};

const ManufacturerDetailsPanel = ({
    visible,
    editable,
    countryList,
    manufacturerNameOfCompany,
    manufacturerNameOfCompanyPlant,
    manufacturerCountryId,
    manufacturerNameOfCountry,
    onManufacturerNameOfCompanyChange,
    manufacturerFactoryAddress,
    manufacturerFactoryAddressPlant,
    onManufacturerFactoryAddressChange,
    handleInputCountryChange,
    feedbackValidationObj,
    sameAsManufacturer,
    sameAsManufacturerChange,
    sameAsManufacturerIsChecked,
    step,
}) => {
    return (
        visible && (
            <Fragment>
                <PanelHeader header="3. Manufacturer Details" />
                <Col>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Row className="pb-2 pt-2">
                                    <Col xs={8} style={{ padding: '0px' }}>
                                        <DetailsPanelInput
                                            mandatory
                                            header="Name of Manufacturer"
                                            editable={editable}
                                            value={manufacturerNameOfCompany}
                                            onChange={onManufacturerNameOfCompanyChange}
                                            valueKey="manufacturerNameOfCompany"
                                            validationObj={feedbackValidationObj}
                                        />
                                        {/* <DetailsPanelInput
                                    mandatory
                                    header="ISO"
                                    editable={editable}
                                    value={manufacturerIsoCode}
                                    onChange={onManufacturerIsoCodeChange}
                                /> */}
                                    </Col>
                                </Row>
                                <Row className="pb-2 pt-2">
                                    <Col xs={8} style={{ padding: '0px' }}>
                                        <DetailsPanelInput
                                            mandatory
                                            header="Address of Manufacturer"
                                            editable={editable}
                                            type="textarea"
                                            rows={6}
                                            value={manufacturerFactoryAddress}
                                            onChange={onManufacturerFactoryAddressChange}
                                            valueKey="manufacturerFactoryAddress"
                                            validationObj={feedbackValidationObj}
                                        />
                                    </Col>
                                </Row>
                                <Row className="pb-1 pt-2">
                                    <Col xs={5} style={{ padding: '0px', height: '100px' }}>
                                        <DetailsPanelInput
                                            mandatory
                                            header="Country of Origin"
                                            editable={editable}
                                            value={manufacturerNameOfCountry}
                                        >
                                            <Select
                                                autofocus
                                                name="countries"
                                                options={countryList}
                                                onChange={handleInputCountryChange}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                styles={selectStyle}
                                                value={{
                                                    id: manufacturerCountryId,
                                                    name: manufacturerNameOfCountry,
                                                }}
                                            />
                                        </DetailsPanelInput>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Row className="pb-2 pt-2">
                                    <Col xs={8} style={{ padding: '0px' }}>
                                        <DetailsPanelInput
                                            disabled={sameAsManufacturer}
                                            mandatory
                                            header="Name of Manufacturing Plant"
                                            editable={editable}
                                            value={manufacturerNameOfCompanyPlant}
                                            onChange={onManufacturerNameOfCompanyChange}
                                            valueKey="manufacturerNameOfCompanyPlant"
                                            validationObj={feedbackValidationObj}
                                        />
                                        {/* <DetailsPanelInput
                                    mandatory
                                    header="ISO"
                                    editable={editable}
                                    value={manufacturerIsoCode}
                                    onChange={onManufacturerIsoCodeChange}
                                /> */}
                                    </Col>
                                </Row>
                                <Row className="pb-2 pt-2">
                                    <Col xs={8} style={{ padding: '0px' }}>
                                        <DetailsPanelInput
                                            disabled={sameAsManufacturer}
                                            mandatory
                                            header="Address of Manufacturing Plant"
                                            editable={editable}
                                            type="textarea"
                                            rows={6}
                                            value={manufacturerFactoryAddressPlant}
                                            onChange={onManufacturerFactoryAddressChange}
                                            valueKey="manufacturerFactoryAddressPlant"
                                            validationObj={feedbackValidationObj}
                                        />
                                    </Col>
                                </Row>
                                {step !== 3 && (
                                    <Row className="pb-1 pt-2">
                                        <Col xs={5} style={{ padding: '0px', height: '100px' }}>
                                            <Col xs={12}>
                                                <input
                                                    type="checkbox"
                                                    id="sameAsManufacturer"
                                                    disabled={!editable}
                                                    onChange={() => sameAsManufacturerChange()}
                                                    checked={sameAsManufacturerIsChecked}
                                                />
                                                &nbsp;
                                                <label>Same as Manufacturer</label>
                                            </Col>
                                        </Col>
                                    </Row>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Fragment>
        )
    );
};
export default ManufacturerDetailsPanel;
