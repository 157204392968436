// New Application
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE';

export const MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE';

export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING';

export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE';

export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE';

export const MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA = 'MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA';

// Preview Application
export const MEMBERSHIP_APPLICATION_SUBMIT = 'MEMBERSHIP_APPLICATION_SUBMIT';
export const MEMBERSHIP_APPLICATION_CONFIRM_SHOW = 'MEMBERSHIP_APPLICATION_CONFIRM_SHOW';
export const MEMBERSHIP_APPLICATION_CONFIRM_HIDE = 'MEMBERSHIP_APPLICATION_CONFIRM_HIDE';

// Questionnaires
export const MEMBER_QUESTIONNAIRES_NEXT = 'MEMBER_QUESTIONNAIRES_NEXT';
export const MEMBER_QUESTIONNAIRES_PREV = 'MEMBER_QUESTIONNAIRES_PREV';
export const MEMBER_QUESTIONNAIRES_RESET_FORM = 'MEMBER_QUESTIONNAIRES_RESET_FORM';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION1 = 'MEMBER_QUESTIONNAIRES_SET_QUESTION1';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION2 = 'MEMBER_QUESTIONNAIRES_SET_QUESTION2';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION3 = 'MEMBER_QUESTIONNAIRES_SET_QUESTION3';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION4 = 'MEMBER_QUESTIONNAIRES_SET_QUESTION4';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION5 = 'MEMBER_QUESTIONNAIRES_SET_QUESTION5';
export const MEMBER_QUESTIONNAIRES_SET_QUESTION_ANSWER = 'MEMBER_QUESTIONNAIRES_SET_QUESTION_ANSWER';

// Get MembershipInfo
export const MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE';

// Payment
export const MEMBER_MEMBERSHIP_PAYMENT_SET_MODE = 'MEMBER_MEMBERSHIP_PAYMENT_SET_MODE';
export const MEMBER_MEMBERSHIP_PAYMENT_NEXT = 'MEMBER_MEMBERSHIP_PAYMENT_NEXT';
