const local_ch = {
    translations: {
        "Key": "钥匙",
        "Key_kvp": "密钥",
        "Key_lock": "钥匙",

        "Value": "价值",
        "Value_kvp": "价值",

        "Action": "动作",
    },
    sidemenu: {
        "Cyder Intranet" : "Cyder 内部网",
        "Main" : "主要功能",
        "Home" : "主页",
        "Expenses" : "花费",
        "My Receipts" : "我的收据",
        "My Reports" : "我的收据报告",
        "Timesheet" : "时间记录",
        "Current Month" : "这个月",
        "Reports" : "报告",
        "Leave" : "请假",
        "New" : "请求请假",
        "Report" : "请假报告",
        "Administration" : "管理",
        "Security" : "安全",
        "Groups" : "安全组",
        "Users" : "用户",
    }
}
export default local_ch;