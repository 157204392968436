import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Card, CardBody, CardTitle } from 'reactstrap';

class AnalyticsCard extends React.Component {
    render() {
        return (
            <Card style={{ borderRadius: '0px', marginBottom: '20px', height: this.props.height === 0 ? 'auto' : this.props.height + '%' }}>
                <CardBody>
                    {this.props.title && <CardTitle style={{ fontWeight: '600', fontSize: '14px' }}>{this.props.title}</CardTitle>}
                    {this.props.children}
                </CardBody>
            </Card>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AnalyticsCard));
