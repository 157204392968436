import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { membershipRenewalAnalytics } from 'actions/analytics/memberAnalyticsAction';
import MembershipRenewalDetailsPopup from 'pages/analytics/components/membership-renewal/MembershipRenewalDetailsPopup';

class MembershipRenewalList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);

        this.state = {
            refreshState: new Date().getTime(),
        };
    }
    componentDidMount() {
        this.load();
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.membership.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.membership.refresh,
            });
        }
    }
    load() {
        this.props.load().then(res => {
            let total = 0;
            res.data.forEach(item => {
                if (item.status == 'Total') {
                    total = item.count;
                }
            });
            this.setState({
                data: res.data,
                total,
            });
        });
    }
    render() {
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Memberships Expiring/Expired within ${moment(this.props.membership.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.membership.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <MembershipRenewalDetailsPopup ref={instance => (this.popup = instance)} />
                        <h2 style={{ color: '#78BE20' }}>Overview</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => {
                                        if (!item.status) return '';
                                        var status = item.status;
                                        var count = item.count;
                                        var updatedRenewalStatusId = item.renewalStatusId;
                                        if (status == 'Total') {
                                            if (item.count <= 0) {
                                                count = item.totalExpiringCurrentYear;
                                                updatedRenewalStatusId = -2;
                                            } else {
                                                updatedRenewalStatusId = -1;
                                            }
                                        }

                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '50%' }}>{status}</td>
                                                <td style={{ width: '20%' }}>{count}</td>
                                                <td style={{ width: '20%' }}>
                                                    {this.state.total > 0 ? Math.round((count * 100) / this.state.total) : 0}
                                                </td>
                                                <td style={{ width: '10%' }}>
                                                    <Button
                                                        className="primary-btn-style"
                                                        onClick={e => this.popup.show(item.status, updatedRenewalStatusId)}
                                                    >
                                                        <i className="mr-1 material-icons align-text-bottom ">remove_red_eye</i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        membership: state.membershipRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(membershipRenewalAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipRenewalList));
