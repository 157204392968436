import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    USER_ADD_SAVE_USER_REQUEST,
    USER_ADD_SAVE_USER_SUCCESS,
    USER_ADD_SAVE_USER_FAILURE,
    USER_ADD_CHANGE_FIELD_VALUE,
    USER_ADD_FORCE_VALIDATE,
    USER_ADD_REMOVE_REDIRECT_ID,
} from '../../constants/actionTypesAuth';
import withQuery from 'with-query';
import sha512 from 'js-sha512';

export function removeRedirectId() {
    return {
        type: USER_ADD_REMOVE_REDIRECT_ID,
    };
}
export function userAddAddAction() {
    return (dispatch, getState) => {
        dispatch({ type: USER_ADD_SAVE_USER_REQUEST });

        const url = withQuery(SGBC_API_ROOT + '/auth_v1/users', {
            action: 'add',
        });
        const user = getState().userAddReducer.user;

        // Updated by Fernando, sending as Plaintext
        // which is fine, as the API is HTTPS, so the password is sent in secured connection.
        // The password checking is done at the backend

        const data = {
            username: user.username,
            password: user.password,
        };
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: USER_ADD_SAVE_USER_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: USER_ADD_SAVE_USER_SUCCESS,
                            response,
                        });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: USER_ADD_SAVE_USER_FAILURE, error });
                return error;
            });
    };
}
export function onChangeField(key, value) {
    return {
        type: USER_ADD_CHANGE_FIELD_VALUE,
        key,
        value,
    };
}

export function forceValidate() {
    return dispatch => {
        dispatch({ type: USER_ADD_FORCE_VALIDATE });
        // return this for async actions
        return Promise.resolve();
    };
}
