// Events Get
export const EVENT_ALL_GET_EVENTS_REQUEST = 'EVENT_ALL_GET_EVENTS_REQUEST';
export const EVENT_ALL_GET_EVENTS_SUCCESS = 'EVENT_ALL_GET_EVENTS_SUCCESS';
export const EVENT_ALL_GET_DINNER_EVENTS_SUCCESS = 'EVENT_ALL_GET_DINNER_EVENTS_SUCCESS';
export const EVENT_ALL_GET_EVENTS_FAILURE = 'EVENT_ALL_GET_EVENTS_FAILURE';

// Event Input
export const EVENT_ALL_CHANGE_INPUT = 'EVENT_ALL_CHANGE_INPUT ';

// Dinner Event Input
export const EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE = 'EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE';
export const DINNER_EVENT_ALL_GET_EVENTS_REQUEST = 'DINNER_EVENT_ALL_GET_EVENTS_REQUEST';
export const DINNER_EVENT_ALL_GET_EVENTS_SUCCESS = 'DINNER_EVENT_ALL_GET_EVENTS_SUCCESS';
export const DINNER_EVENT_ALL_GET_EVENTS_FAILURE = 'DINNER_EVENT_ALL_GET_EVENTS_FAILURE';
export const DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE = 'DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE';
export const DINNER_EVENT_ALL_SEARCH_KEYWORD = 'DINNER_EVENT_ALL_SEARCH_KEYWORD';
export const FETCH_TABLE_COUNT = 'FETCH_TABLE_COUNT';
