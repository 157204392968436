import React from 'react';

import { connect } from 'react-redux';

import VerticalBarChartOneBar from 'cyder/chart/VerticalBarChartOneBar';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import StatsSelector from './components/StatsSelector';

import CyderDatePicker from 'cyder/forms/CyderDatePicker';

const Dashboard = () => (
    <div>
        <StatsSelector />
        <div className="row">
            <div className="col-12">
                <div>
                    <NoShadowPanel
                        title="Expiry Management Forecasting"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                    >
                        <form>
                            <div className="form-row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>Date: {''}</label>
                                        <CyderDatePicker />
                                        <small className="form-text text-muted">
                                            Please pick a date
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </NoShadowPanel>
                    <div className="row">
                        <div className="col-6">
                            <VerticalBarChartOneBar
                                items={this.props.before}
                                color={this.props.colors.warning}
                                firstKey="expiring"
                                title="Memberships Expiring within 6 months before 10 May 2018"
                                description="Lorem ipsum dolor sit amet"
                            />
                        </div>
                        <div className="col-6">
                            <VerticalBarChartOneBar
                                items={this.props.after}
                                color={this.props.colors.warning}
                                firstKey="expiring"
                                title="Memberships Expiring within 6 months after 10 May 2018"
                                description="Lorem ipsum dolor sit amet"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default Dashboard;
