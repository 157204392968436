import React from 'react';
import IconWidget6 from 'icon-widgets/IconWidget6';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import history from '../../../../../history';

const AllCertificationsSummary = ({
    product,
    title,
    linkText,
    certIssuedThisYear,
    certIssuedThisMonth,
    certRenewedLastYear,
    certRenewedThisYear,
}) => {
    const commonProps = {
        height: 70,
        color: 'white',
        bgStyle: { backgroundColor: product ? '#AFC13D' : '#858687' },
    };
    const widgetData = [
        {
            text: 'Issued',
            number: certIssuedThisYear,
            remarks: ' this year',
            type: 'certIssuedThisYear',
            ...commonProps,
        },
        {
            text: 'Issued',
            number: certIssuedThisMonth,
            remarks: ' this month',
            type: 'certIssuedThisMonth',
            ...commonProps,
        },
        {
            text: 'Renewed%',
            number: certRenewedLastYear && certRenewedLastYear.toFixed(1),
            remarks: ' last year',
            type: 'certExpiredLastYear',
            ...commonProps,
        },
        {
            text: 'Renewed%',
            number: certRenewedThisYear && certRenewedThisYear.toFixed(1),
            remarks: ' this year',
            type: 'certExpiredThisYear',
            ...commonProps,
        },
    ];
    const prefix = title;
    return (
        <NoShadowPanel title={prefix} borderSize={2} flat>
            <Row>
                <Col>
                    <Row style={{ paddingBottom: '10px' }}>
                        {widgetData.map((widget, i) => (
                            <Col key={i}>
                                <IconWidget6
                                    {...widget}
                                    link={() =>
                                        window.location.pathname !== linkText
                                            ? history.push(linkText, {
                                                  type: widget.type,
                                              })
                                            : history.replace(linkText, {
                                                  type: widget.type,
                                              })
                                    }
                                />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </NoShadowPanel>
    );
};

export default AllCertificationsSummary;
