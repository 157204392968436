import React from 'react';
import { Grid, Header, List, Image, Icon, Segment, Label, Card, Form, Button, Popup } from 'semantic-ui-react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    fetchRenewalDetails,
    fetchRenewalStatus,
    updateRenewalStatus,
    fetchTerminationReasons,
    fetchRenewalDetailsV2,
} from 'actions/staff/certification/renewalActions';
import moment from 'moment';

class RenewalDetails extends React.Component {
    state = { renewalStatusRemarks: undefined, renewalStatusId: undefined, terminationReasonId: undefined, prompt: false, renewal: null };

    componentDidMount() {
        this.props.fetchRenewalStatus();
        this.props.fetchTerminationReasons();
        this.fetchRenewalDetails();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.certId !== this.props.certId) {
            this.fetchRenewalDetails(this.props.certId);
        }
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    handleOpenPrompt = () => this.setState({ prompt: true });
    handleClosePrompt = () => this.setState({ prompt: false });

    handleUpdateRenewalStatus = () => {
        const { renewalStatusRemarks, renewalStatusId, terminationReasonId } = this.state;
        const { certId } = this.props;

        const data = {
            renewalStatusId: renewalStatusId,
            terminationReasonId: terminationReasonId,
            renewalStatusRemarks: renewalStatusRemarks,
            certificateId: certId,
        };
        this.props.updateRenewalStatus(data).then(() => {
            this.setState({ prompt: false });
            this.fetchRenewalDetails();
        });
    };

    fetchRenewalDetails() {
        this.props.fetchRenewalDetailsV2(this.props.certId).then(res => {
            const { certificateRenewalRemarks, certificateRenewalStatusId, terminationReasonId } = res.data[0];
            this.setState({
                renewal: res.data[0],
                renewalStatusRemarks: certificateRenewalRemarks,
                renewalStatusId: certificateRenewalStatusId,
                terminationReasonId: terminationReasonId,
            });
        });
    }

    render() {
        const {
            loading,
            certId,
            type,
            ratingTicks,
            rating,
            terminationReasonOptions,
            remarks,
            statusId,
            reasonId,
            terminationId,
            statusOptions,
        } = this.props;
        const { renewalStatusRemarks, renewalStatusId, terminationReasonId, renewal } = this.state;
        const isExpired = renewal ? moment(new Date()) > renewal.expiryDate : null;
        const expiresIn = renewal ? moment(new Date()).to(renewal.expiryDate) : null;
        return (
            <Grid columns={2} className="page-widget renewal-details">
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Segment clearing>
                            <Header as="h3" floated="right" className="text-muted">
                                <Label circular color={isExpired ? 'red' : null}>
                                    {isExpired ? 'Expired' : 'Expires'} {expiresIn}
                                </Label>
                                Expiry date: {renewal && moment(renewal.expiryDate).format('DD MMM YYYY')}
                            </Header>
                            <Header
                                as="h1"
                                floated="left"
                                style={{
                                    color: '#317446',
                                    fontWeight: 'bold',
                                }}
                                content={`Certification Renewal ID: ${certId}`}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="status">
                        <Header>
                            Status: &nbsp;
                            <span className="text-bold">{renewal && renewal.certificateRenewalStatus}</span>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Card.Group itemsPerRow={2}>
                            <Card className={`${loading ? 'loading' : null}`}>
                                <Card.Content>
                                    <Card.Header as="h3">
                                        <b>Certification Details &nbsp;</b>
                                        <Link
                                            className="float-right"
                                            to={`/staff/certification/certificates/${type}/certdetails/${certId}`}
                                            style={{ color: '#78be20' }}
                                        >
                                            More details
                                        </Link>{' '}
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <Grid columns={2}>
                                        <Grid.Column>
                                            <List relaxed="very">
                                                <List.Item>
                                                    <List.Header content="Cert. number" />
                                                    {renewal && renewal.certNo}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Company" />
                                                    {renewal && renewal.companyName}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Issued on" />
                                                    {renewal && moment(renewal.issuedOn).format('DD MMM YYYY')}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Expires on" />
                                                    {renewal && moment(renewal.expiryDate).format('DD MMM YYYY')}
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <List relaxed="very">
                                                <List.Item>
                                                    <List.Header content="Category" />
                                                    {renewal && renewal.category}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Rating" />
                                                    {ratingTicks(rating)}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Status" />
                                                    {renewal && renewal.certificateRenewalStatus}
                                                </List.Item>
                                                <List.Item>
                                                    <List.Header content="Case officer" />-
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                    </Grid>
                                </Card.Content>
                            </Card>
                            <Card className={`${loading ? 'loading' : null}`}>
                                <Card.Content>
                                    <Card.Header as="h3" className="text-bold">
                                        Renewal Details
                                    </Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <Form onSubmit={this.handleUpdateRenewalStatus}>
                                        <Form.TextArea
                                            name="renewalStatusRemarks"
                                            label="Remarks"
                                            placeholder="Please add remarks for this certification"
                                            onChange={this.handleChange}
                                            value={renewalStatusRemarks}
                                        />
                                        <Form.Select
                                            name="renewalStatusId"
                                            placeholder="Status of renewal"
                                            onChange={this.handleChange}
                                            label="Certification renewal status"
                                            options={statusOptions}
                                            value={renewalStatusId}
                                        />
                                        {renewalStatusId === 12 && (
                                            <Form.Select
                                                name="terminationReasonId"
                                                placeholder="Termination reason"
                                                onChange={this.handleChange}
                                                label="Termination reason"
                                                options={terminationReasonOptions}
                                                value={terminationReasonId}
                                            />
                                        )}
                                        <Button
                                            type="submit"
                                            fluid
                                            content="Save Status and Remarks"
                                            className="btn primary-btn-style"
                                            onClick={this.handleUpdateRenewalStatus}
                                            disabled={!renewalStatusId}
                                        />
                                        {/* <Popup
                                            trigger={
                                            }
                                            content={
                                                <Segment basic>
                                                    <Header as="h6" icon>
                                                        <Icon name="lightbulb" />
                                                        Are you sure want to update the certificate renewal?
                                                    </Header>
                                                    <Button
                                                        fluid
                                                        className="btn roboto primary-btn-style"
                                                        onClick={this.handleUpdateRenewalStatus}
                                                        content="Yes"
                                                    />
                                                </Segment>
                                            }
                                            open={this.state.prompt}
                                            on="click"
                                            onOpen={this.handleOpenPrompt}
                                            onClose={this.handleClosePrompt}
                                            position="top center"
                                            className="prompt"
                                        /> */}
                                    </Form>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const mapState = ({ staffCertificationApplicationReducer: cert }, ownProps) => {
    const certId = ownProps.match.params.id;
    const type = cert.renewal && cert.renewal.type.toLowerCase();
    const rating = cert.renewal && cert.renewal.rating;
    const expiry = cert.renewal && moment(cert.renewal.expiryDate);
    console.log('expiry', expiry);
    const expiresIn = moment(new Date()).to(expiry);
    const isExpired = moment(new Date()) > expiry;
    const statusOptions = cert.status ? cert.status.map(item => ({ key: item.id, value: item.id, text: item.status })) : [];
    const terminationReasonOptions = cert.terminationReason
        ? cert.terminationReason.map(item => ({ key: item.id, value: item.id, text: item.reason }))
        : [];

    const ratingTicks = rating => {
        const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" width="14" height="12" />;

        // prettier-ignore
        if (rating === '1') return <span>{checkmark}</span>;
        else if (rating === '2') return ( <span> {checkmark} {checkmark} </span> );
        else if (rating === '3') return ( <span> {checkmark} {checkmark} {checkmark} </span> );
        else if (rating === '4') return ( <span> {checkmark} {checkmark} {checkmark} {checkmark} </span> );
    };
    console.log('Cert', cert);

    return {
        renewal: cert.renewal,
        loading: cert.loading,
        remarks: cert.renewal && cert.renewal.certificateRenewalRemarks,
        statusId: cert.renewal && cert.renewal.certificateRenewalStatusId,
        terminationId: cert.renewal && cert.renewal.terminationReasonId,
        statusOptions,
        terminationReasonOptions,
        certId,
        rating,
        ratingTicks,
        type,
        expiresIn,
        isExpired,
    };
};

const actionList = { fetchRenewalDetailsV2, fetchRenewalDetails, fetchRenewalStatus, updateRenewalStatus, fetchTerminationReasons };

// prettier-ignore
export default connect(mapState, actionList)(StoredLayout(RenewalDetails))
