import approve from 'approvejs';

const validationTypes = {
    lat: {
        title: 'Latitude',
        required: true,
        format: {
            regex: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
            message: 'Invalid latitude',
        },
    },
    lng: {
        title: 'Longitude',
        required: true,
        format: {
            regex: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
            message: 'Invalid longitude',
        },
    },
    title: {
        title: 'Code',
        required: true,
    },
    uen_regno: {
        title: 'UEN No',
        required: true,
        max: 200,
    },
    country: {
        title: 'Country',
        required: true,
    },
    username: {
        title: 'Username',
        required: true,
        format: {
            regex: /^[@.+-_a-zA-Z0-9]+$/,
            message: 'Invalid Username',
        },
    },
    email: {
        title: 'Email',
        required: true,
        email: true,
        max: 200,
    },
    email2: {
        title: 'Secondary Email',
        ignoreNull: true,
        email: true,
        max: 200,
    },
    criteriaCode: {
        title: 'Code',
        required: true,
    },
    criteria: {
        title: 'Criteria',
        required: true,
    },
    requiredDocuments: {
        title: 'Required Documents',
        required: true,
    },
    documentsToSubmit: {
        title: 'Documents to Submit',
        required: true,
    },
    firstname: {
        title: 'First Name',
        required: true,
        format: {
            regex: /^[A-Za-z 0-9]+$/,
            message: 'First Name can only contain alphanumeric characters and space',
        },
        max: 50,
    },
    lastname: {
        title: 'Last Name',
        required: true,
        format: {
            regex: /^[A-Za-z 0-9]+$/,
            message: 'Last Name can only contain alphanumeric characters and space',
        },
        max: 50,
    },
    password: {
        title: 'Password',
        required: true,
        max: 20,
    },
    confirmpassword: {
        title: 'Confirm Password',
        required: true,
        max: 20,
    },
    amount: {
        required: true,
        title: 'Amount',
        currency: true,
        max: 20,
    },
    telephoneDid: {
        title: 'Telephone DID',
        format: {
            regex: /^$|(^\+?[0-9]*$)/,
            message: 'Invalid Phone Number',
        },
        max: 20,
    },
    mobilenumber: {
        title: 'Mobile Number',
        format: {
            regex: /^$|(^\+?[0-9]*$)/,
            message: 'Invalid Phone Number',
        },
        max: 20,
        required: true,
    },
    officenumber: {
        title: 'Office Number',
        numeric: true,
        ignoreNull: true,
        max: 20,
    },
    homenumber: {
        title: 'Home Number',
        numeric: true,
        ignoreNull: true,
        max: 20,
    },
    companyname: {
        title: 'Company Name',
        required: true,
        ignoreNull: false,
        max: 200,
    },
    designation: {
        title: 'Designation',
        required: true,
        ignoreNull: false,
        max: 50,
    },
    fullname: {
        title: 'Full Name',
        required: true,
        ignoreNull: false,
        max: 50,
    },
    gmpNumber: {
        title: 'GMAP Number',
        required: true,
        min: 6,
        max: 6,
        format: {
            regex: /^((SG)[0-9]{4})$/,
            message: 'Invalid GMAP Number. Please use valid format: SG9999',
        },
    },
    companyaddress: {
        title: 'Company Address',
        ignoreNull: true,
        max: 300,
    },
    homeaddress: {
        title: 'Home Address',
        ignoreNull: true,
        max: 300,
    },
    // TODO: better validation
    dateofbirth: {
        format: {
            regex: /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))\/(0[1-9]|1[0-2])\/([12]\d{3})$/,
            message: 'Invalid date. Please Enter DD/MM/YYYY.',
        },
        required: true,
    },
    // TODO: better validation
    idno: {
        title: 'ID No.',
        required: true,
        max: 20,
    },
    idtype: {
        title: 'ID Type',
        required: true,
    },
    gender: {
        title: 'Gender',
        required: true,
    },
    groupname: {
        title: 'Group Name',
        required: true,
        max: 50,
        format: {
            regex: /^[a-zA-Z0-9]*$/,
            message: 'Invalid group name. Please use alphanumeric characters only.',
        },
    },
    attendeeLimit: {
        title: 'Attendee Limit',
        required: true,
        format: {
            regex: /^[0-9]*$/,
            message: 'Invalid number. Please use valid integers only.',
        },
    },
    description: {
        title: 'Description',
        required: true,
        max: 2000,
    },
    groupdescription: {
        title: 'Group Description',
        required: true,
        max: 50,
    },
    tfa: {
        title: 'TFA',
    },
    amountReceived: {
        required: true,
        title: 'Amount Received',
        format: {
            regex: /^[0-9]*(.[0-9]{1,2})?$/,
            message: 'Invalid Amount',
        },
    },
    datePaymentReceived: {
        required: true,
        title: 'Date Payment Received',
        date: 'ymd',
    },
    invoiceNumber: {
        required: true,
        title: 'Invoice Number',
    },
    bank: {
        title: 'Bank',
    },
    referenceNumber: {
        required: true,
        title: 'Reference Number',
    },
    manufacturerContactPersonName: {
        title: 'Main Contact Person',
        ignoreNull: false,
        max: 50,
        required: true,
    },
    manufacturerTelephone: {
        title: 'Main Contact Number',
        format: {
            regex: /^$|(^\+?[0-9]*$)/,
            message: 'Invalid Phone Number',
        },
        max: 20,
        required: true,
    },
    manufacturerNameOfCompany: {
        title: 'Name of Manufacturer',
        ignoreNull: false,
        max: 100,
        required: true,
    },
    manufacturerFactoryAddress: {
        title: 'Address of Manufacturer',
        ignoreNull: false,
        max: 100,
        required: true,
    },
    manufacturerNameOfCompanyPlant: {
        title: 'Name of Manufacturing Plant',
        ignoreNull: false,
        max: 100,
        required: true,
    },
    manufacturerFactoryAddressPlant: {
        title: 'Address of Manufacturing Plant',
        ignoreNull: false,
        max: 100,
        required: true,
    },
    greenRemarks: {
        title: 'Green Remarks',
        ignoreNull: false,
        max: 2000,
        required: true,
    },
    paymentAmount: {
        title: 'Payment Amount',
        format: {
            regex: /^((0?\.((0[1-9])|[1-9]\d))|([1-9]\d*(\.\d{2})?))$/,
            message: 'Invalid Payment Amount',
        },
        ignoreNull: false,
        max: 20,
        required: true,
    },
    bankDetails: {
        title: 'Bank Details',
        ignoreNull: false,
        max: 50,
        required: true,
    },
    chequeNumber: {
        title: 'Cheque Number',
        format: {
            regex: /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/,
            message: 'Invalid Cheque Number',
        },
        ignoreNull: false,
        max: 20,
        required: true,
    },
    referenceNumber: {
        title: 'Reference Number',
        format: {
            regex: /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/,
            message: 'Invalid Reference Number',
        },
        ignoreNull: false,
        max: 20,
        required: true,
    },
};

// TURN KEYS IN VALIDATION TYPES OBJECT TO LOWER CASE
const validationTypesKeys = Object.keys(validationTypes);
const lcValidationTypesKeys = validationTypesKeys.map(k => k.toLowerCase());

// GET KEY FROM VALIDATIONTYPES ELSE RETURN KEY GIVEN
const lenientKey = key => {
    const lcKey = key.toLowerCase();
    // keyName for rule as defined in validationObj
    const keyName = validationTypesKeys[lcValidationTypesKeys.indexOf(lcKey)];
    return keyName || key;
};

export default {
    validate: function(k, v, pw) {
        const value = v === null || v === undefined ? '' : v;
        const key = lenientKey(k);
        if (!validationTypes[key]) return false;
        let rules = validationTypes[key] || null;

        // SET RULE FOR PASSWORD
        if ((key === 'confirmpassword' || key === 'password') && pw) {
            const isConfirmPassword = key === 'confirmpassword';
            rules = {
                required: true,
                title: isConfirmPassword ? 'Confirm Password' : 'Password',
                equal: {
                    value: pw,
                    field: isConfirmPassword ? 'Password' : 'Confirm Password',
                },
                max: 20,
            };
        }

        return approve.value(value, rules);
    },
};

/**
 * THIS IS validateFromValidationObject WITHOUT Promise.resolve()
 *
 * It return a boolean value that signifies whether the validation succeeded or failed
 * @param {*} validationObj the validation obj containing validation result from approved.js Eg. { paymentAmount: {approved: true, ...} }
 * @param {*} requiredObj object containing whether does an element in validation object required to be validated Eg. { paymentAmount: true }
 */
export const validateFromValidationObject = (validationObj, requiredObj, returnPromise = false) => {
    if (!validationObj) return false;
    const keys = Object.keys(validationObj);
    for (let i = 0; i < keys.length; i += 1) {
        if (requiredObj[keys[i]] === true && validationObj[keys[i]].approved === false) {
            return false;
        }
    }
    return true;
};

/**
 * THIS IS  validateFromValidationObject WITH Promise.resolve()
 *
 * It return a boolean value in promise that signifies whther the validation succeeded ot failed
 * @param {*} validationObj the validation obj containing validation result from approved.js Eg. { paymentAmount: {approved: true, ...} }
 * @param {*} requiredObj object containing whether does an element in validation object required to be validated Eg. { paymentAmount: true }
 */
export const validateFromValidationObjectPromise = (validationObj, requiredObj) => {
    if (!validationObj) return Promise.resolve(false);
    const keys = Object.keys(validationObj);
    for (let i = 0; i < keys.length; i += 1) {
        if (requiredObj[keys[i]] === true && validationObj[keys[i]].approved === false) {
            return Promise.resolve(false);
        }
    }
    return Promise.resolve(true);
};

/**
 * THIS IS ADVANCE validateFromValidationObject WITH Promise.resolve()
 *
 * It return not only the valid property but also the key from validation obj which is invalid
 * @param {*} validationObj the validation obj containing validation result from approved.js Eg. { paymentAmount: {approved: true, ...} }
 * @param {*} requiredObj object containing whether does an element in validation object required to be validated Eg. { paymentAmount: true }
 */
export const validateFromValidationObjectAdvance = (validationObj, requiredObj) => {
    if (!validationObj) return Promise.resolve({ valid: false });
    const keys = Object.keys(validationObj);
    for (let i = 0; i < keys.length; i += 1) {
        if (requiredObj[keys[i]] === true && validationObj[keys[i]].approved === false) {
            return Promise.resolve({ key: keys[i], valid: false });
        }
    }
    return Promise.resolve({ valid: true });
};
