import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../history';
import { Link } from 'react-router-dom';
import ActiveSGBPCertifiedProducts from 'pages/analytics/components/certification/ActiveSGBPCertifiedProducts';
import ActiveCertificates from 'pages/analytics/components/certification/ActiveCertificates';
import TotalProductsCertifiedBySGBPCumulative from 'pages/analytics/components/certification/TotalProductsCertifiedBySGBPCumulative';
import CertifiedProductDistribution from 'pages/analytics/components/certification/CertifiedProductDistribution';
import CertifiedServiceDistribution from 'pages/analytics/components/certification/CertifiedServiceDistribution';
import CertifiedServiceDistributionD3 from 'pages/analytics/components/certification/CertifiedServiceDistributionD3';
import TotalCompaniesCertifiedBySGBP from 'pages/analytics/components/certification/TotalCompaniesCertifiedBySGBP';
import TotalProductsCertifiedBySGBP from 'pages/analytics/components/certification/TotalProductsCertifiedBySGBP';
import BCAFunctionalSystems from 'pages/analytics/components/certification/BCAFunctionalSystems';
import GreenAttributes from 'pages/analytics/components/certification/GreenAttributes';
import GreenCredentials from 'pages/analytics/components/certification/GreenCredentials';
import ActiveProductCategories from 'pages/analytics/components/certification/ActiveProductCategories';
import CrossAnalytics from 'pages/analytics/components/certification/CrossAnalytics';
import { FormGroup, Input, Row, Col, Form, Button, Container } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

class CertificationDashboard extends React.Component {
    constructor(props) {
        super(props);
        // this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {}

    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <Row className="float-right">
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => {
                                    history.push('/staff/analytics/certification/issuedanalytics');
                                }}
                                label="Issued Analytics"
                            />
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                onClick={e => {
                                    history.push('/staff/analytics/certification/renewalanalytics');
                                }}
                                label="Renewal Analytics"
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={3}>
                        <ActiveSGBPCertifiedProducts />
                    </Col>
                    <Col xs={3}>
                        <ActiveCertificates />
                    </Col>
                    <Col xs={3}>
                        <TotalProductsCertifiedBySGBPCumulative />
                    </Col>
                    <Col xs={3}>
                        <CrossAnalytics type="certification" title="Certification Cross-Analysis against Membership" />
                    </Col>
                </Row>

                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={6}>
                        <CertifiedProductDistribution />
                        <TotalCompaniesCertifiedBySGBP />
                        <BCAFunctionalSystems />
                        <GreenCredentials />
                    </Col>
                    <Col xs={6}>
                        <CertifiedServiceDistribution />
                        <TotalProductsCertifiedBySGBP />
                        <GreenAttributes />
                        <ActiveProductCategories />
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationDashboard));
