import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const DetailsPageFormSectionBusinessActivity = ({
    company,
    catSubCat,
    mainCatMap,
}) => {
    return (
        <RowCardHOC>
            <h5>Section D: Business Activity</h5>
            <br />
            <div>
                <Row>
                    <Col xs={12}>
                        <LabelItem
                            label=" Organisation Primary Business Activity "
                            content={
                                mainCatMap[company.organisationMainCategoryId]
                            }
                        />
                        <br />
                        <span>
                            <strong>Services offered by organisation:</strong>
                            <br />
                            {Object.keys(catSubCat).map((cat, i) => (
                                <React.Fragment key={i}>
                                    <div>
                                        <u>{catSubCat[cat].mainName}</u>
                                        {catSubCat[cat].subCats.map(
                                            (sub, k) => (
                                                <div className="p-2" key={k}>
                                                    {'- ' + sub.subName}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <br />
                                </React.Fragment>
                            ))}
                        </span>
                    </Col>
                </Row>
            </div>
        </RowCardHOC>
    );
};

export default DetailsPageFormSectionBusinessActivity;
