import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Row, Col, Badge, Form, FormGroup } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import { getMembershipTierUpgradeHistory } from 'actions/member/membership/membership/action';
import moment from 'moment';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';

class MembershipTierHistoryPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.state = {
            modal: false,
        };
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    show() {
        this.setState({
            modal: true,
        });
    }
    render() {
        const columns = [
            {
                Header: 'Membership Tier',
                accessor: 'tier',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['tier'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Since',
                accessor: 'since',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['since'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return moment(props.value).format('DD/MM/YYYY');
                },
            },
        ];

        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle} style={{ color: '#317446' }}>
                    <div style={{ fontSize: '22px' }}>
                        <b>Membership Tier History</b>
                    </div>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <div className="table-responsive">
                        <ReactTable
                            columns={columns}
                            filterable={true}
                            minRows={0}
                            showPagination={false}
                            className="-striped -highlight"
                            data={this.props.tierHistory}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn-style" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

class MembershipTierHistoryPanel extends React.Component {
    constructor(props) {
        super(props);
        this.viewHistory = this.viewHistory.bind(this);
        this.state = {};
    }
    async componentDidMount() {
        let res = await this.props.getMembershipTierUpgradeHistory(this.props.membershipId);
        this.setState({
            tierHistory: res.data,
        });
    }
    viewHistory() {
        this.popup.show();
    }
    render() {
        return (
            <React.Fragment>
                <MembershipTierHistoryPopup ref={instance => (this.popup = instance)} tierHistory={this.state.tierHistory} />
                {this.state.tierHistory && this.state.tierHistory.length >= 2 && (
                    <span>
                        <a style={{ color: 'dark gray' }} onClick={this.viewHistory} href="#">
                            (<u>view more</u>)
                        </a>
                        {this.props.addLineBreak && <br />}
                    </span>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMembershipTierUpgradeHistory: membershipId => {
            return dispatch(getMembershipTierUpgradeHistory(membershipId));
        },
    };
};
export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MembershipTierHistoryPanel),
);
