/**
 * Member Service Certification Application action
 *
 * Author: Fernando Karnagi
 */
import withQuery from 'with-query';

export function setTakeUpCase(takeUpCaseStarted) {
    return {
        type: 'MEMBER_SERVICE_SET_TAKE_UP_CASE_STARTED',
        takeUpCaseStarted,
    };
}

export function setAssignApprover(assignApproverStarted) {
    return {
        type: 'MEMBER_SERVICE_SET_ASSIGN_APPROVER_STARTED',
        assignApproverStarted,
    };
}

export function setAssignNextApprover(assignNextApproverStarted) {
    return {
        type: 'MEMBER_SERVICE_SET_ASSIGN_NEXT_APPROVER_STARTED',
        assignNextApproverStarted,
    };
}
