import React from 'react';
import { Grid, Segment, Card } from 'semantic-ui-react';
import { Container } from 'reactstrap';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

import Box from 'pages/public/components/Box';

const SelectCategoryPanel = ({ loading, onSelectCategory, categories, mode }) => {
    const type = mode === 'product' ? mode : 'services';
    if (loading) return <LoadingSpinner center />;
    return (
        <React.Fragment>
            <Container>
                <NoShadowPanel title={`Please select category of your ${type}`} panelStyle={{ backgroundColor: '' }} flat />
            </Container>
            <Grid centered stackable className="category service-directory">
                <Grid.Column width={12}>
                    <Segment basic>
                        <Card.Group itemsPerRow={4}>
                            {categories.map((item, i) => {
                                var category = item.category.toLowerCase();
                                category = escape(category);
                                return (
                                    <Box
                                        type="service"
                                        key={item.id}
                                        src={`/assets/icons/${item.filename}`}
                                        icon={item.icon}
                                        iconSize="small"
                                        header={item.category}
                                        onClick={() => onSelectCategory(item)}
                                    />
                                );
                            })}
                        </Card.Group>
                    </Segment>
                </Grid.Column>
            </Grid>
        </React.Fragment>
    );
};
export default SelectCategoryPanel;
