import React from 'react';

import { Row, Col, Input, FormGroup, Form, Button } from 'reactstrap';

import { FieldFormGroup } from '../product/StaffCertificationApplicationProductCertDetails';

const StaffCertificationApplicationProductGreenAttributes = props => {
    const { checkboxOptions, selectedCheckboxOptions } = props.state;
    const { onGreenAttributesChange, onGreenAttributesSave } = props;
    return (
        <Row className="page-widget">
            <Col className="p-4">
                <Form>
                    <h4>Green Attributes Options</h4>
                    <FieldFormGroup name="greenAttributeOptions" label="Please select below">
                        {checkboxOptions.map(option => (
                            <CheckboxComponent
                                key={option.id}
                                id={option.id}
                                label={option.otherDataOption}
                                checked={selectedCheckboxOptions[option.id] || false}
                                onChange={e => onGreenAttributesChange(e, option.id)}
                            />
                        ))}
                    </FieldFormGroup>
                </Form>
                <Col className="text-right mt-4">
                    <Button type="submit" className="primary-btn-style" onClick={onGreenAttributesSave}>
                        Save Changes
                    </Button>
                </Col>
            </Col>
        </Row>
    );
};

const CheckboxComponent = ({ id, checked, onChange, label }) => (
    <FormGroup check>
        <Input id={id} type="checkbox" checked={checked} onChange={onChange} />
        {label}
    </FormGroup>
);

export default StaffCertificationApplicationProductGreenAttributes;
