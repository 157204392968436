import {
    STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_PERIOD_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_PERIOD_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_PERIOD_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE,
    STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE,
    STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS,
    STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA,
    STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT,
    STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING,
    STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';

import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_S3_HOST } from '../../../config';

export function staffCompanyDetailsGetCertificationPDF(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipCertByCompanyId',
            companyId: companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE,
        );
    };
}
export function staffCompanyDetailsGetMembershipLetter(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipLetterByCompanyId',
            companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE,
        );
    };
}
export function staffCompanyDetailsGetMemberApplication(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'get-company-membership-applications',
            companyId,
            nopagination: true,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, {}, null, STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS, null);
    };
}
export function staffCompanyDetailsUpdateMembershipTier(companyId, membershipTier) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'update-membership-tier',
        });
        var data = {
            companyId: companyId,
            membershipTier: membershipTier,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE,
        );
    };
}
export function updateMembershipPeriod(companyId, startDate, expiryDate, foundingMember) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'update-membership-period',
        });
        var data = {
            date_joined: startDate,
            expiry_date: expiryDate,
            founding_member: foundingMember,
            id: companyId,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_PERIOD_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_PERIOD_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_PERIOD_FAILURE,
        );
    };
}
export function staffCompanyDetailsPrintCertByCompanyId(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'print-membership-cert-by-company-id',
            id: companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE,
        );
    };
}
export function staffCompanyDetailsResubmitQuestionnaires(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'resubmit-questionnaires',
        });
        let body = { applicationId };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE,
        );
    };
}
export function staffCompanyDetailsRegenerateCertificate(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: `generate-membership-cert`,
            companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST,
            STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS,
            STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE,
        );
    };
}
export function downloadCertificate(companyId) {
    return (dispatch, getState) => {
        window.open(SGBC_S3_HOST + '/certification/sgbc-member-' + companyId + '.pdf?refresh=' + new Date().getTime());
    };
}
export function staffCompanyDetailsMembershipSetEventModalToggle(data) {
    return {
        type: STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA,
        data,
    };
}
export function staffCompanyDetailsOverviewSetModalToggle(data) {
    return {
        type: STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA,
        data,
    };
}
export function staffCompanyDetailsMembershipShowLoading(show) {
    return {
        type: STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING,
        show,
    };
}
export function staffCompanyDetailsMembershipResetToDefault() {
    return dispatch => {
        dispatch({
            type: STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT,
        });
        return Promise.resolve();
    };
}
