import {
    TEMPLATE_EDIT_SET_MODAL_DATA,
    SAVE_SERVICE_REQUEST,
    SAVE_SERVICE_SUCCESS,
    SAVE_SERVICE_FAILURE,
    SAVE_PRODUCT_REQUEST,
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_FAILURE,
    STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE,
    STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS,
    STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY,
} from '../../constants/actionTypesStaffCertificationAssessmentTemplate';
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { removeNullFieldsFromObject } from 'js/util';

import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_PRINT_ROOT } from 'config';

// Group action types constant (service / product)
const actionTypesService = [SAVE_SERVICE_REQUEST, SAVE_SERVICE_SUCCESS, SAVE_SERVICE_FAILURE];
const actionTypesProduct = [SAVE_PRODUCT_REQUEST, SAVE_PRODUCT_SUCCESS, SAVE_PRODUCT_FAILURE];

export function criteriaTemplateSetSelectedStatus(status) {
    return dispatch => {
        dispatch({
            type: STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS,
            status,
        });
        return Promise.resolve();
    };
}
export function criteriaTemplateSetSelectedCategory(category) {
    return dispatch => {
        dispatch({
            type: STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY,
            category,
        });
        return Promise.resolve();
    };
}
export function criteriaTemplateSetFilterType(filterType) {
    return dispatch => {
        dispatch({
            type: STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE,
            filterType,
        });
        return Promise.resolve();
    };
}
export function getCategories(type = 'product') {
    return (dispatch, getState) => {
        const typeUrl = type === 'service' ? 'service_v1/cn/category' : 'product_v1/cn/product-category';
        const url = withQuery(`${SGBC_API_ROOT}/${typeUrl}`, {
            action: type === 'service' ? 'get-all-active' : 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getProductCategories() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product-category`, {
            action: 'get-all-active-with-type',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getSubCategories(categoryid) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product-type`, {
            action: 'get-all-active-product-types-by-category-id',
            categoryid,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function save(body, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'create-v2',
        });
        const actionTypes = type === 'service' ? actionTypesService : actionTypesProduct;
        return fetchlib.fetch(dispatch, getState, 'POST', url, removeNullFieldsFromObject(body), ...actionTypes);
    };
}
export function update(body, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'update-v2',
        });
        const actionTypes = type === 'service' ? actionTypesService : actionTypesProduct;
        return fetchlib.fetch(dispatch, getState, 'POST', url, removeNullFieldsFromObject(body), ...actionTypes);
    };
}
export function deleteItem(id, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'delete-v2',
        });
        const body = {
            id,
        };
        const actionTypes = type === 'service' ? actionTypesService : actionTypesProduct;
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, ...actionTypes);
    };
}

export function activate(id, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'update-active',
        });
        const body = {
            id,
            active: 1,
        };
        const actionTypes = type === 'service' ? actionTypesService : actionTypesProduct;
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, ...actionTypes);
    };
}

export function printMarkUserAgreementForm(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-certificate-mark-user-agreement',
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data);
    };
}

export function printCriteria(id, type, category) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-assessment-criteria',
            id,
        });
        if (type == 'product') {
            type = 'Product';
        } else {
            type = 'Service';
        }
        const body = {
            type,
            category,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function inactivate(id, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'update-active',
        });
        const body = {
            id,
            active: 0,
        };
        const actionTypes = type === 'service' ? actionTypesService : actionTypesProduct;
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, ...actionTypes);
    };
}
export function search(type, keyword, selectedCategory, status, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'get-templates-for-admin',
        });

        const params = {
            type,
            keyword,
            selectedCategory,
            status,
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}
export function getActiveCriteriaSections(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/criteria-section`, {
            action: 'get-active-criteria',
            type,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getCriteriaBySection(sectionid) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'get-criteria-by-section',
            sectionid,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getCriteriaByType(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'get-criteria-by-type',
            type,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getSavedItem(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'find-by-id-v2',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getAssessmentScoresheet(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/scoresheet`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function setModalData(data) {
    return {
        type: TEMPLATE_EDIT_SET_MODAL_DATA,
        data,
    };
}
export function updateAssessment(body, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/scoresheet`, {
            action: 'update-assessment',
        });
        let postBody = {
            id: body.id,
            templateId: body.templateId,
            criteria: body.criteria,
            updatedOn: body.updatedOn,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, removeNullFieldsFromObject(postBody), null, null, null);
    };
}
export function saveAssessment(body, type, reject, id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/scoresheet`, {
            action: 'save-assessment',
        });
        let postBody = {
            id: body.id,
            templateId: body.templateId,
            criteria: body.criteria,
            reject: reject !== true ? false : true,
            applicationId: id,
            updatedOn: body.updatedOn,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, removeNullFieldsFromObject(postBody), null, null, null);
    };
}
export function submitAssessment(body, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/scoresheet`, {
            action: 'submit-assessment',
        });
        let postBody = {
            id: body.id,
            templateId: body.templateId,
            criteria: body.criteria,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, removeNullFieldsFromObject(postBody), null, null, null);
    };
}
