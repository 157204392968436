import React, { Component } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

const CarouselContent = ({ header, caption, children, contentStyle }) => {
    const style = {
        color: 'white',
        fontSize: '3vw',
        lineHeight: '4.5vw',
        fontWeight: 'bold',
        top: '8.5vw',
    };
    return (
        <div style={style} className={'carousel-caption d-none d-md-block justify-content-center d-flex flex-column'}>
            <h1 style={{ fontSize: '5vw' }}>
                <strong>{header}</strong>
            </h1>
            <br />
            <div style={contentStyle}>
                <p>
                    <strong>{caption}</strong>
                </p>
                {children}
                <br />
                <br />
            </div>
        </div>
    );
};

class CarouselPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    onExiting() {
        this.animating = true;
    }
    onExited() {
        this.animating = false;
    }
    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }
    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }
    render() {
        const { activeIndex } = this.state;
        const imgStyle = {
            minWidth: '100%',
            maxHeight: '100%',
            minHeight: '1000px',
            position: 'absolute',
            objectFit: 'cover',
        };

        return (
            <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
                {/* <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} /> */}
                {this.props.items.map((item, index) => {
                    return (
                        <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={index}>
                            {item.customComponent ? (
                                <div style={{ height: this.props.height || '100vh' }}>{item.customComponent}</div>
                            ) : (
                                <div
                                    style={{
                                        height: this.props.height || '600px',
                                    }}
                                >
                                    <img src={item.src} alt={item.header} style={imgStyle} />
                                    <CarouselContent header={item.header} caption={item.caption} contentStyle={this.props.contentStyle}>
                                        {item.children}
                                    </CarouselContent>
                                </div>
                            )}
                        </CarouselItem>
                    );
                })}
                {this.props.control ? (
                    <React.Fragment>
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </React.Fragment>
                ) : null}
            </Carousel>
        );
    }
}

export default CarouselPanel;
