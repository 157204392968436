import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import CyderPaginationReactTable from 'cyder/table/CyderPaginationReactTable';
import { BrandDetailComponent } from 'pages/member/certification/applications/overview/MemberCertificationApplicationListPage';
import history from '../../../../../history';
const columnsStyle = {
    headerStyle: { whiteSpace: 'unset' },
    sortable: false,
    style: { whiteSpace: 'unset' },
};
var moment = require('moment');
const ButtonComponent = ({ onClick }) => (
    <Button className="primary-btn-style float-right" onClick={onClick}>
        <i className="material-icons m-1">file_download</i>
    </Button>
);
const generateOpenLink = (type, id) => `/member/certification/${type.toLowerCase()}/certificates`;
const CertificatesCertificates = props => {
    const { loading, certificates, type, activeTab } = props.state;
    if (props.tabId != activeTab) {
        return '';
    }
    const { getDataFunc, printCert } = props;
    const productColumns = [
        {
            Header: 'Cert Number',
            accessor: 'certificationNumber',
            ...columnsStyle,
            Cell: props => (
                <Link style={{ color: 'unset' }} to={generateOpenLink(type, props.value)}>
                    {props.value}
                </Link>
            ),
        },
        {
            Header: 'Brand & Model',
            accessor: 'brandJson', //its brandJson
            ...columnsStyle,
            Cell: props => {
                const plainBrand = props.original.brand != null ? props.original.brand : '-';
                try {
                    const products = props.value && JSON.parse(props.value);
                    return products.length > 0 ? <BrandDetailComponent items={products} /> : plainBrand;
                } catch (error) {
                    return plainBrand;
                }
            },
        },
        {
            Header: 'Valid Till',
            accessor: 'expired',
            ...columnsStyle,
            Cell: props => {
                return moment(props.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
            },
        },
        {
            Header: '',
            sortable: false,
            accessor: 'validity',
            ...columnsStyle,
            Cell: props => {
                const {
                    value,
                    original: { type, certificateId },
                } = props;
                return value !== 'Expired' ? (
                    <span
                        className="primary-btn-style float-right"
                        onClick={() => {
                            printCert(certificateId, type);
                        }}
                    >
                        <i className={'fa fa-download'} />
                    </span>
                ) : null;
            },
        },
    ];
    const serviceColumns = [
        {
            Header: 'Cert Number',
            accessor: 'certificationNumber',
            ...columnsStyle,
            Cell: props => (
                <Link style={{ color: 'unset' }} to={generateOpenLink(type, props.value)}>
                    {props.value}
                </Link>
            ),
        },
        {
            Header: 'Services Category',
            accessor: 'category',
            ...columnsStyle,
            Cell: props => {
                return props.value || '-';
            },
        },
        {
            Header: 'Valid Till',
            accessor: 'expired',
            ...columnsStyle,
            Cell: props => {
                return moment(props.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
            },
        },
        {
            Header: '',
            sortable: false,
            accessor: 'validity',
            ...columnsStyle,
            Cell: props => {
                const {
                    value,
                    original: { type, certificateId },
                } = props;
                return value !== 'Expired' ? (
                    <span
                        className="primary-btn-style float-right"
                        onClick={() => {
                            printCert(certificateId, type);
                        }}
                    >
                        <i className={'fa fa-download'} />
                    </span>
                ) : null;
            },
        },
    ];
    return (
        <React.Fragment>
            <CyderPaginationReactTable
                loading={loading}
                columns={type === 'product' ? productColumns : serviceColumns}
                data={certificates}
                getDataFunc={getDataFunc}
                pagination={false}
                filterable={false}
            />
            {certificates != null && certificates.length > 0 && (
                <Button
                    className="ml-1 mr-1 align-middle primary-btn-style"
                    onClick={() => {
                        if (type == 'product') {
                            history.push(`/member/certification/product/certificates`);
                        } else {
                            history.push(`/member/certification/service/certificates`);
                        }
                    }}
                >
                    <i className="material-icons align-text-bottom">chevron_right</i>
                    &nbsp;View All
                </Button>
            )}
        </React.Fragment>
    );
};
export default CertificatesCertificates;
