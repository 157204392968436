import React from 'react';
import { Card, Button, Image, Grid, Icon, Segment, List, Transition, Header } from 'semantic-ui-react';
import { mobile } from '../dummy';

const Box = data => {
    // const classNames = () => {
    const {
        header,
        onFetchProducts,
        onFetchLowVoc,
        src,
        button,
        icon,
        iconSize,
        loading,
        onClick = null,
        history,
        subcategories,
        id,
        style,
        selected,
        type,
        as,
        loadingProductCategory,
        loadingAllProductTypes,
        loadingProductSubcategory,
    } = data;
    let classNames = 'box';

    if (loading) classNames += ' loading';
    if (button) classNames += ' button-attached';
    if (selected === id && type !== 'service') classNames += ' expanded';

    const handleClick = (productCategoryId, productSubcategoryId) => {
        if (id !== 99) {
            onFetchProducts(productCategoryId, productSubcategoryId);
        } else {
            onFetchLowVoc();
        }
    };

    const handleServiceClick = id => {
        if (onClick) onClick(id);
        else {
            if (id === 'lowvoc') {
                onFetchLowVoc();
            }
        }
    };

    return (
        <Card className={classNames} as={as} style={style}>
            <Card.Content onClick={() => handleServiceClick(id)}>
                {type === 'service' ? (
                    <Header as="h2" icon={icon ? true : false} textAlign="center">
                        {src ? (
                            <Image src={src} size={header === 'Mechanical & Electrical' ? 'big' : 'tiny'} />
                        ) : (
                            <Icon name={icon} size={iconSize || 'large'} />
                        )}
                        {header}
                    </Header>
                ) : (
                    <Header as="h2">
                        {src && id !== 'lowvoc' ? (
                            <Image src={src} size={header === 'Mechanical' ? 'big' : 'tiny'} />
                        ) : (
                            <Icon name={icon} size={iconSize || 'large'} />
                        )}
                        {header}
                    </Header>
                )}

                {/* prettier-ignore */}
                {type !== 'service' && (
                    <Segment basic>
                        <List relaxed>
                            {subcategories &&
                                subcategories
                                    .filter(item => item.productCategoryId === Number(id))
                                    .map(item => (
                                        <List.Item
                                            style={{
                                                whiteSpace: 'pre'
                                            }}
                                            key={item.productSubcategoryId}
                                            // as="a"
                                            onClick={() => handleClick(item.productCategoryId, item.productSubcategoryId)}
                                        >
                                            {item.subcategory}
                                            <Icon name="chevron right" />
                                        </List.Item>
                                    ))}
                        </List>
                    </Segment>
                )}
            </Card.Content>
            {/* {button && (
                <Button
                    basic
                    className="btn-link"
                    attached="bottom"
                    onClick={() => onTogglePanel(id)}
                    content={selected === id ? 'Close' : 'See more'}
                    icon={selected === id ? 'chevron up' : 'chevron down'}
                />
            )} */}
        </Card>
    );
};

export default Box;
