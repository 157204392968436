import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';

import '../../css/cyder/widget/dashboard-widget.css';

const DashboardWidget = ({ toggleCollapse, isOpen, title, description, children, icon, faIcon, button, iconBgColor, iconImagePath }) => {
    return (
        <div className="mb-2">
            <div onClick={toggleCollapse} className="py-1 dashboard-widget-card-header">
                <h4 className="py-1 my-0 d-flex flex-row align-items-center">
                    {iconImagePath ? <img src={iconImagePath} width="48" height="48" /> : null}
                    {faIcon ? (
                        <div className={'sgbcdashboarwidget-header-' + iconBgColor}>
                            <i className={'color-white sgbcdashboarwidget-header-icon fa fa-2x ' + faIcon} />
                        </div>
                    ) : null}
                    <span style={{ color: '#555' }}>
                        <strong>{faIcon ? '\u00A0\u00A0' + title : '\u00A0' + title}</strong>
                    </span>
                    <div className="ml-auto">
                        {button !== null && button !== undefined ? (
                            <Button className="border-color-cyder-pink-1 bg-cyder-pink-1 bg-hover-cyder-pink-1-darken">{button}</Button>
                        ) : null}
                    </div>
                </h4>
                <span>{description}</span>
            </div>
            <Collapse isOpen={isOpen !== undefined ? isOpen : true} style={{ minHeight: '340px' }}>
                <CardBody>
                    <div className="col">{children}</div>
                </CardBody>
            </Collapse>
        </div>
    );
};

DashboardWidget.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
};

export default DashboardWidget;
