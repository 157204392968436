import PropTypes from 'prop-types';

// Ordinary keyword search
export const searchKeyword = (dataArray, keyword) => {
    // Handle undefined params
    if (!dataArray && !keyword) {
        throw !dataArray ? 'Parameter (dataArray) cannot be undefined' : 'Parameter (keyword) cannot be undefined';
    }
    try {
        // Keyword search in case-insensitive
        const searchResult = dataArray
            .map(object => {
                let result = undefined;
                Object.keys(object).forEach(key => {
                    if (
                        String(object[key])
                            .toLowerCase()
                            .includes(keyword.toLowerCase())
                    ) {
                        result = object;
                    }
                });
                return result;
            })
            .filter(results => results !== undefined);
        return searchResult;
    } catch (error) {}
    return undefined;
};
// Search by alphabetical letter
export const searchKeywordByAlphabeticalLetter = (dataArray, keyword, dataKey) => {
    // Handle undefined params
    if (!dataArray || !keyword || !dataKey) {
        if (!dataArray) throw 'Parameter (dataArray) cannot be undefined';
        if (!keyword) throw 'Parameter (keyword) cannot be undefined';
        if (!dataKey) throw 'Parmeter (dataKey) cannot be undefined';
        return undefined;
    }
    try {
        // Keyword search in case-insensitive
        const searchResult = dataArray
            .map(object => {
                let result = undefined;
                if (
                    String(object[dataKey])
                        .toLowerCase()
                        .substr(0, 1) === keyword.toLowerCase()
                ) {
                    result = object;
                }
                return result;
            })
            .filter(results => results !== undefined);
        return searchResult;
    } catch (error) {
        // Handle errors in keyword search
        throw new Error('Error looking for search result: ', error);
    }
};
// Necessary params
export const defaultKeywordSearchKeys = {
    keyword: '',
    searchResult: [],
};
// Proptypes
searchKeywordByAlphabeticalLetter.prototype = {
    dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyword: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
};
searchKeyword.prototype = {
    dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyword: PropTypes.string.isRequired,
};
