import {
    PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST,
    PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS,
    PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE,
    PERSONAL_PROFILE_RESET_TO_DEFAULT,
    PERSONAL_PROFILE_HANDLE_INPUT_CHANGE,
    PERSONAL_PROFILE_SET_MODAL_DATA,
} from '../constants/actionTypes';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../config';

export function personalProfileGetProfileInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'getAccountProfile',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST,
            PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS,
            PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE,
        );
    };
}
export function personalProfileSaveProfileInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'updateAccountProfile',
        });
        const body = getState().personalProfileReducer.profileInfo;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST,
            PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS,
            PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE,
        );
    };
}
export function personalProfileResetToDefault() {
    return (dispatch, getState) => {
        dispatch({ type: PERSONAL_PROFILE_RESET_TO_DEFAULT });
        return Promise.resolve();
    };
}
export function personalProfileHandleInputChange(event) {
    return {
        type: PERSONAL_PROFILE_HANDLE_INPUT_CHANGE,
        event,
    };
}
export function personalProfileSetModalData(data) {
    return {
        type: PERSONAL_PROFILE_SET_MODAL_DATA,
        data,
    };
}
