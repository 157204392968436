import React from 'react';

import { Row, Col } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { capitalizeFirstLetter as cfl } from 'js/util';

const FileUploadPanel = ({
    id,
    type,
    editable,
    handleInputChange,
    savingFile,
    savedFile,
    uploadedFileId,
    downloadFileAction,
    downloadingFile,
    removeFileAction,
    fileInputRef,
    filename,
    virusMessage,
    scanning,
}) => {
    return (
        <React.Fragment>
            {editable && (
                <React.Fragment>
                    <input
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={e => handleInputChange(e)}
                        id={id ? id : 'file-input'}
                        type="file"
                    />
                    {filename && (
                        <Col>
                            <Row>
                                <p className="text-bold">File:</p>&nbsp;
                                {filename}
                            </Row>
                        </Col>
                    )}
                    <small className="form-text text-muted">Please upload your {type} (maximum 20 MB)</small>
                    {virusMessage && (
                        <p
                            style={{ color: 'red' }}
                            className="mt-2 form-text"
                        >{`[Unable to upload] Virus scan found '${virusMessage}' in one of the files.`}</p>
                    )}
                    <ButtonIcon
                        icon={'save'}
                        className="mx-1"
                        disabled={false}
                        onClick={() => !savedFile && fileInputRef.current.click()}
                        label={
                            scanning
                                ? 'Virus Scanning ...'
                                : savingFile
                                ? 'Uploading File ...'
                                : savedFile
                                ? 'Uploaded'
                                : `Upload ${(uploadedFileId ? 'New ' : '') + cfl(type)}`
                        }
                    />
                </React.Fragment>
            )}
            {uploadedFileId && (
                <React.Fragment>
                    {editable && (
                        <ButtonIcon className="mx-1" color={'danger'} onClick={removeFileAction} label="Remove File" icon={'cancel'} />
                    )}
                    <ButtonIcon
                        className="mx-1"
                        color={'info'}
                        onClick={downloadFileAction}
                        disabled={downloadingFile}
                        label={downloadingFile ? 'Downloading File...' : `Download ${cfl(type)}`}
                        icon={'save'}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default FileUploadPanel;
