import React from 'react';
import { Row, Col } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const DetailsPageFormSectionCorporateAddress = ({ link, company }) => (
    <RowCardHOC link={link}>
        <h5>Section C: Corporate Address</h5>
        <br />
        <Row>
            <Col xs={6}>
                <LabelItem label="Business address line 1" content={company.businessAddressLine1} />
                <br />
                <LabelItem label="Business address line 2" content={company.businessAddressLine2} />
                <br />
                <LabelItem label="Business address line 3" content={company.businessAddressLine3} />
                <br />
                <LabelItem label="Business postal code" content={company.businessPostalCode} />
            </Col>
            <Col sm={6} className="col-lg-6 col-md-6 space-top">
                <LabelItem label="Mailing address line 1" content={company.mailingAddressLine1} />
                <br />
                <LabelItem label="Mailing address line 2" content={company.mailingAddressLine2} />
                <br />
                <LabelItem label="Mailing address line 3" content={company.mailingAddressLine3} />
                <br />
                <LabelItem label="Mailing postal code" content={company.mailingPostalCode} />
            </Col>
        </Row>
    </RowCardHOC>
);

export default DetailsPageFormSectionCorporateAddress;
