/**
 *  * Authentication Socket
 *   */
import socketIOClient from 'socket.io-client';
import { v1 as uuidv1 } from 'uuid';
import { MYCORP_AUTH_ENDPOINTS } from 'config';

class MyCorpTFASocket {
    _socket = null;
    _initialised = false;

    init(token, account, system, module, onConnect) {
        this._socket = socketIOClient(MYCORP_AUTH_ENDPOINTS, {
            path: '/auth',
            query: {
                token,
                account,
                system,
                module,
            },
        });
        this._initialised = false;

        this._socket.on('socketInitialized', response => {
            if (!this._initialised) {
                this._initialised = true;
                onConnect();
            }
        });
    }

    sendTfaRequest(callback) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket
        console.log('[' + correlationId + '] sendTfaRequest');
        this._socket.emit('tfa', { correlationId });

        this._socket.on('tfa', response => {
            callback && callback(response);
        });
    }

    cancelTfaRequest(tfaId) {
        const correlationId = uuidv1(); // correlationId is a required field, used for tracking the response from backend socket
        console.log('[' + correlationId + '] cancelTfaRequest');
        this._socket.emit('cancelTfa', { correlationId, tfaId });
    }
}

const myCorpTFASocket = new MyCorpTFASocket();
export { myCorpTFASocket };
