import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SGBCLeftMenuItem from 'cyder/menu/SGBCLeftMenuItem';
import 'css/elements/left-sidebar-1.css';
import { Trans, translate } from 'react-i18next';
import { setConfig } from 'actions/config';

import FrontLogo from 'cyder/media/FrontLogo';

class LeftSidebar extends React.Component {
    state = { activeIndex: null, title: '' };

    handleActiveIndex = (activeIndex, title) => {
        this.setState({ activeIndex, title });
        this.props.closeSideMenu();
    };

    render() {
        const { navigation, config, path, pathSub } = this.props;
        return (
            <div>
                <div className="left-sidebar-placeholder" />
                <div className="left-sidebar left-sidebar-1">
                    <div className="wrapper">
                        <div className="content">
                            {config.layout === 'default-sidebar-1' ? <FrontLogo divclassname="text-center" /> : null}
                            {navigation.map((menu, i) => (
                                <div key={i} className="section">
                                    <div className="section-title">
                                        <Trans>{menu.title}</Trans>
                                    </div>
                                    <ul className="list-unstyled">
                                        {menu.items.map((items, i) => (
                                            <SGBCLeftMenuItem
                                                key={i}
                                                index={i}
                                                handleActiveIndex={this.handleActiveIndex}
                                                states={this.state}
                                                items={items}
                                                path={path}
                                                pathSub={pathSub}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const pathname = window.location.pathname.toLowerCase().split('/');
    const path = pathname[2];
    const pathSub = pathname.length > 4 ? pathname[4] : pathname[3];
    return {
        path,
        pathname,
        config: state.config,
        pathSub:
            path === 'galadinner'
                ? 'galadinner'
                : pathSub === 'scoresheet-criteria'
                ? 'criteria'
                : pathSub === 'create'
                ? 'addnewevent'
                : pathSub === 'membership-renewal'
                ? 'renewals'
                : pathSub === 'members'
                ? 'directory'
                : pathSub,
    };
};

const mapDispatch = dispatch => ({
    closeSideMenu: () => {
        dispatch(setConfig('collapsed', false));
    },
});

export default translate()(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatch,
        )(LeftSidebar),
    ),
);
// export default translate()(LeftSidebar)
