import React from 'react';

import { Row, Col, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';

const TextAreaComponent = props => {
    const { disabled, questionId, value, label, onChange, visible, lg = 6 } = props;
    return (
        <Col xs={12} lg={lg} className="mb-2">
            {visible ? <small>{label + ': '}</small> : null}
            <Input
                rows="6"
                type="textarea"
                data-qid={questionId}
                disabled={disabled}
                value={value}
                onChange={onChange}
                style={{
                    marginTop: '0px',
                    marginBottom: '0px',
                    resize: 'none',
                    visibility: visible ? 'visible' : 'hidden',
                }}
            />
        </Col>
    );
};

const QuestionaireComponent = props => {
    return (
        <Card className="p-4">
            <CardBody>
                <Row>
                    {props.questions.map((q, i) => {
                        const answer = props.answersState[i + 1];
                        const isFirstQuestion = q.question.id === 1;
                        const isRecomendedByOption = answer && answer[answer.length - 2] && isFirstQuestion;
                        const isOtherOption = answer && answer[answer.length - 1];
                        const label = isRecomendedByOption ? 'Recommended By' : 'Others';
                        return (
                            <React.Fragment key={i}>
                                <QuestionItem
                                    onClick={props.handleChangeCheckbox}
                                    answersState={props.answersState}
                                    preview={props.preview}
                                    otherAnswer={props.othersState[q.question.id] || ''}
                                    q={q}
                                    lg={props.preview ? 12 : 6}
                                />
                                {!props.preview ? (
                                    <TextAreaComponent
                                        label={
                                            isFirstQuestion && isRecomendedByOption && isOtherOption ? 'Recommended by and Others' : label
                                        }
                                        lg={6}
                                        visible={isOtherOption || isRecomendedByOption}
                                        disabled={props.preview}
                                        questionId={q.question.id}
                                        value={props.othersState[q.question.id] || ''}
                                        onChange={props.othersTextareaOnChange}
                                    />
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </Row>
            </CardBody>
        </Card>
    );
};
const QuestionItem = ({ onClick, answersState, otherAnswer, preview, q, lg = 6 }) => {
    const answers = !preview
        ? q.answer
        : q.answer
              .map((a, i) => {
                  return answersState[q.question.id][i] === true ? a : undefined;
              })
              .filter(x => x);

    return (
        <Col xs={12} lg={lg} className="mb-4">
            <div className="field-header">
                {q.question.name + ' '}
                {!preview && <MandatoryStar />} <br />
            </div>
            {!preview ? (
                <FormGroup>
                    {answers.map((a, i) => {
                        return (
                            <FormGroup key={i} check className="mb-0">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        disabled={preview}
                                        onChange={onClick}
                                        checked={(answersState[q.question.id] && answersState[q.question.id][i]) || false}
                                        data-question-id={q.question.id}
                                        data-answer-id={q.question.id === 1 ? a.id : i + 1}
                                    />
                                    {a.name}
                                </Label>
                            </FormGroup>
                        );
                    })}
                </FormGroup>
            ) : (
                <FormGroup>
                    {answers.map((a, i) => {
                        let qtnName = a.name;
                        let textAnswer = '';
                        if (qtnName.toLowerCase().indexOf('recommended') >= 0 || qtnName.toLowerCase().indexOf('other') >= 0) {
                            textAnswer = otherAnswer;
                        }
                        return (
                            <FormGroup key={i} className="mb-0">
                                <li>
                                    {a.name} {textAnswer}
                                </li>
                            </FormGroup>
                        );
                    })}
                </FormGroup>
            )}
        </Col>
    );
};

export default QuestionaireComponent;
