import * as Types from '../../actions/constants/public';
import { SGBC_MODE } from 'config.js';

const initialState = {
    categories: [],
    subcategories: [],
};

export default (state = initialState, action) => {
    var productType = [];
    switch (action.type) {
        case Types.LOADING_PUBLIC:
            return { ...state, loading: true };
        case Types.UN_LOADING_PUBLIC:
            return { ...state, loading: false };
        case Types.LOADING_PUBLIC_PRODUCT_CATEGORY:
            return { ...state, loadingProductCategory: true };
        case Types.LOADING_PUBLIC_PRODUCT_SUB_CATEGORY:
            return { ...state, loadingProductSubcategory: true };
        case Types.LOADING_LOWVOC_PRODUCT_TYPES:
            return { ...state, loadingLowVocProductTypes: true };
        case Types.LOADING_ALL_PRODUCT_TYPES:
            return { ...state, loadingAllProductTypes: true };
        case Types.LOADING_SINGLE_SUBCATEGORY:
            return { ...state, loadingSingleSubcategory: true };
        case Types.LOADING_SPECIFIC_PRODUCT_TYPES:
            return { ...state, loadingSpecificProductTypes: true };
        case Types.FETCH_PRODUCT_CATEGORY:
            let categories = action.payload;
            // Hard code Low VOC Category

            categories.push({
                active: 1,
                category: 'Low VOC',
                color: 'rgb(27, 113, 213)',
                createdOn: '2019-09-25 00:00:00',
                filename: 'icon-architecture.png',
                icon: 'building outline',
                id: 99,
                sortkey: 99,
                updatedById: 1,
                updatedOn: '2015-12-01 22:36:38',
            });

            return { ...state, categories, loadingProductCategory: false };
        case Types.FETCH_PRODUCT_SUBCATEGORY:
            let subcategories = action.payload;
            // Hard code Low VOC subcategory
            subcategories.push({
                productCategoryId: 99,
                productSubcategoryId: 99,
                showLabel: 1,
                subcategory: 'Low VOC',
            });
            return { ...state, subcategories: action.payload, loadingProductSubcategory: false };
        case Types.FETCH_LOWVOC_PRODUCT_TYPES:
            productType = [
                {
                    productSubcategoryId: 'all',
                    productTypeId: 'all',
                    showLabel: 1,
                    type: 'All',
                },
            ];
            productType = productType.concat(action.payload);
            return { ...state, productType, loadingLowVocProductTypes: false };
        case Types.FETCH_SINGLE_SUBCATEGORY:
            return { ...state, subcategory: action.payload, loadingSingleSubcategory: false };
        case Types.FETCH_ALL_PRODUCT_TYPES:
            return { ...state, productTypes: action.payload, loadingAllProductTypes: false };
        case Types.FETCH_SPECIFIC_PRODUCT_TYPES:
            productType = [
                {
                    productSubcategoryId: 'all',
                    productTypeId: 'all',
                    showLabel: 1,
                    type: 'All',
                },
            ];
            productType = productType.concat(action.payload);
            return { ...state, productType, loadingSpecificProductTypes: false };
        case Types.FETCH_ALL_FILTER_OPTIONS:
            return { ...state, options: action.payload, loading: false };
        case Types.FETCH_PRODUCTS:
            return { ...state, products: action.payload, loading: false };
        case Types.FETCH_ALL_PRODUCTS:
            return { ...state, allProducts: action.payload, loading: false };
        case Types.SEARCH_PRODUCTS:
            return { ...state, products: action.payload, loading: false };
        case Types.FETCH_COMPANY_INFO:
            return { ...state, companyInfo: action.payload, loading: false };
        case Types.FETCH_CERTIFICATE_INFO:
            return { ...state, certificateInfo: action.payload, loading: false };
        case Types.FETCH_CERTIFICATE_INFO_TAGGING:
            return { ...state, tagging: action.payload, loading: false };
        case Types.FETCH_RATINGS:
            return { ...state, ratings: action.payload, loading: false };
        case Types.FETCH_RATING_IN_SUBCATEGORY:
            return { ...state, ratings: action.payload, loading: false };
        case Types.FETCH_FILTERING_LABELS:
            return { ...state, filteringLabels: action.payload, loading: false };
        case Types.FETCH_RATING:
            return { ...state, ratings: action.payload, loading: false };
        case Types.FETCH_SERVICE_CATEGORIES:
            return { ...state, serviceCategories: action.payload, loading: false };
        case Types.FILTER_SERVICES:
            return { ...state, services: action.payload, loading: false };
        case Types.SEARCH_SERVICES:
            return { ...state, services: action.payload, loading: false };
        case Types.FETCH_SERVICE_COMPANY_INFO:
            return { ...state, companyInfo: action.payload, loading: false };
        default:
            return state;
    }
};
