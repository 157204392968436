/**
 * Product Application view page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import TopNavigationLayout from 'layouts/TopNavigationLayout';

import { Row, Col, Button, Container, Card, CardBody, Label } from 'reactstrap';
import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import ProductsListTable from '../product/components/ProductsListTable';
import { SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';
import DialogModal from 'cyder/modals/DialogModal';

import {
    getCategoryList,
    getProductTypes,
    getSavedCertAppl,
    saveCertApplication,
    forceSetLoading,
    setSavedFlag,
    resetStateToDefault,
} from 'actions/member/certification/applications/memberCertNewApplAction';
import {
    getInvoiceInfo,
    completeApplication,
    updateApplicationFeedback,
    updateGreenRemarks,
    updateBrandsByMember,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { printCertByCertNumber } from 'actions/shared/certificationAction';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';
import { getCountriesGeneric } from 'actions/misc/countryAction.js';
import { printMarkUserAgreementForm } from 'actions/staff/certification/staffCertAssessmentAction';
import { getSurvey, getActiveSurveyByCategory } from 'actions/staff/survey/surveyAction';

import { actionExecutor as execute, capitalizeFirstLetter, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

// import { SGBC_S3_ROOT } from 'config.js';

class MemberCertDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.setProductCatalogue = this.setProductCatalogue.bind(this);
        this.setProductPicture = this.setProductPicture.bind(this);
        this.setWhyGreen = this.setWhyGreen.bind(this);
        this.setWhyGreenService = this.setWhyGreenService.bind(this);
        this.printMUATermsAndConditions = this.printMUATermsAndConditions.bind(this);
        this.printMUAPage = this.printMUAPage.bind(this);
        this.confirmCert = this.confirmCert.bind(this);
        this.updateMarkUserAgreementStates = this.updateMarkUserAgreementStates.bind(this);
        this.updateGreenRemarks = this.updateGreenRemarks.bind(this);
        this.updateInternalModelField = this.updateInternalModelField.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            surveyId: 0,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                modalAction: '',
                type: 'save',
            },
            applicationId: '',
            muaName: '',
            muaNameError: '',
            muaDesignation: '',
            muaDesignationError: '',
            muaEmail: '',
            muaEmailError: '',
            muaContactNumber: '',
            muaContactNumberError: '',
            muaAgreedTermsError: '1', // default as with error
            muaAgreedError: '1', // default as with error
            muaAgreed: false,
            muaAgreedTerms: false,
        };
    }
    componentDidMount() {
        this.props.resetStateToDefault().then(() => {
            this.getDataFromServer();
        });
    }
    setWhyGreenService(value) {
        let service = this.state.service;
        service.greenRemarks = value;
        this.setState({
            service,
        });
    }
    updateMarkUserAgreementStates(newState) {
        this.setState(newState);
    }
    muaContactNumberChange = e => {
        const muaContactNumber = e.target.value || '';
        const muaContactNumberError = !muaContactNumber ? 'This field is mandatory' : '';
        this.setState({ muaContactNumber, muaContactNumberError });
    };
    muaEmailChange = e => {
        const muaEmail = e.target.value || '';
        const regexTest = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(muaEmail);
        const muaEmailError = !muaEmail ? 'This field is mandatory' : !regexTest ? 'Please enter a valid email address' : '';
        this.setState({ muaEmail, muaEmailError });
    };
    muaDesignationChange = e => {
        const muaDesignation = e.target.value || '';
        const muaDesignationError = !muaDesignation ? 'This field is mandatory' : '';
        this.setState({ muaDesignation, muaDesignationError });
    };
    muaAgreedChange = e => {
        const muaAgreed = e.target.checked || false;
        const muaAgreedError = !muaAgreed ? 'This field is mandatory' : '';
        this.setState({
            muaAgreedError,
            muaAgreed,
        });
    };
    muaAgreedTermsChanged = e => {
        const muaAgreedTerms = e.target.checked || false;
        const muaAgreedTermsError = !muaAgreedTerms ? 'This field is madatory' : '';
        this.setState({ muaAgreedTermsError, muaAgreedTerms });
    };
    muaNameChange = e => {
        const value = e.target.value;
        const condition = value && value.length > 0;
        const muaNameError = !condition ? 'This fielld is mandatory' : '';
        this.setState({ muaName: condition ? value : '', muaNameError });
    };
    async updateGreenRemarks(greenRemarks) {
        const { updateGreenRemarks } = this.props;
        const { id } = this.props.match.params;
        const { ok } = await updateGreenRemarks(id, greenRemarks);
    }
    async updateBrands(brands) {
        const { updateBrands } = this.props;
        const { id } = this.props.match.params;
        const { ok } = await updateBrands(id, brands);
    }
    confirmCert() {
        const {
            productBrands,
            muaContactNumberError,
            muaDesignationError,
            muaEmailError,
            muaNameError,
            surveyData,
            activeSurveys,
        } = this.state;
        const { type, forceSetLoading } = this.props;
        const { id } = this.props.match.params;

        if (muaContactNumberError === '' && muaDesignationError === '' && muaEmailError === '' && muaNameError === '') {
            forceSetLoading(true);
            this.markUserAgreement.save();

            /*if (type === 'product') {
                var brands = productBrands;
                // take the first model why green as the application my green
                const productRes = await this.updateGreenRemarks(brands[0].models[0].greenRemarks);
                // update brands now
                const productBrandRes = await this.updateBrands(brands);
            } else {
                const serviceRes = await this.updateGreenRemarks(this.state.service.greenRemarks);
            }*/

            var pushTo = null;
            if (
                (surveyData == null || surveyData.length === 0) &&
                activeSurveys != null &&
                activeSurveys.data != null &&
                activeSurveys.data.length > 0
            ) {
                pushTo = () => history.push(`/member/certification/${type}/survey/${activeSurveys.data[0].id}/${id}`);
            } else {
                pushTo = () => history.push(`/member/certification/${type}/application/${id}`);
            }
            forceSetLoading(false);
            this.showModalIf('Confirmation', `${capitalizeFirstLetter(type)} information successfully updated.`, true, pushTo);
        }
    }
    updateInternalModelField(model, fieldName, value) {
        var brands = this.state.productBrands;
        if (brands[0].models != null && brands[0].models.length > 0) {
            let models = brands[0].models.map((modelEl, i) => {
                let newModel = Object.assign({}, modelEl);
                if (newModel.name == model.name) {
                    newModel[fieldName] = value;
                }
                return newModel;
            });
            brands[0].models = models;
        }
        this.setState({
            productBrands: brands,
        });
    }
    setWhyGreen(newWhyGreenValue, model) {
        this.updateInternalModelField(model, 'greenRemarks', newWhyGreenValue);
    }
    setProductCatalogue(fileId, filename, model) {
        this.updateInternalModelField(model, 'productCatalogue', fileId);
        this.updateInternalModelField(model, 'productCatalogueFilename', filename);
    }
    setProductPicture(fileId, filename, model) {
        this.updateInternalModelField(model, 'productPicture', fileId);
        this.updateInternalModelField(model, 'productPictureFilename', filename);
    }
    async getDataFromServer() {
        try {
            this.props.forceSetLoading(true);
            const id = this.props.match.params.id;
            const countries = await this.props.getCountriesList();
            const activeSurveys = await this.props.getActiveSurveyByCategory(4);
            // if (activeSurveys != null && activeSurveys.data != null && activeSurveys.data.length > 0) {
            //     this.setState({
            //         activeSurveys: activeSurveys.data,
            //     });
            // }
            if (id) {
                const surveyData = await this.props.getSurvey(id);
                const data = sanitizeStringAndObjectNullsToEmptyStrings(await this.props.getRelevantData(id, this.props.type));
                // console.log("data: ", data)
                if (this.props.type === 'product') this.props.getProductTypes(data.productCategoryId);
                let brands = data.brands;

                if (brands && brands.length > 0) {
                    if (data.brands[0].models && data.brands[0].models.length > 0) {
                        // Merging product catalogue, product picture, and why green into brand json
                        let models = data.brands[0].models.map((model, i) => {
                            let newModel = Object.assign({}, model);
                            if (!newModel.productCatalogue) {
                                newModel.productCatalogue = data.productCatalogue;
                            }
                            if (!newModel.productCatalogueFilename) {
                                newModel.productCatalogueFilename = data.productCatalogueFilename;
                            }
                            if (!newModel.productPicture) {
                                newModel.productPicture = data.productPicture;
                            }
                            if (!newModel.productPictureFilename) {
                                newModel.productPictureFilename = data.productPictureFilename;
                            }
                            if (!newModel.greenRemarks) {
                                newModel.greenRemarks = data.greenRemarks;
                            }
                            return newModel;
                        });
                        data.brands[0].models = models;
                    }
                }

                let serviceJson = data.service;
                if (this.props.type === 'service' && serviceJson) {
                    serviceJson = JSON.parse(serviceJson);
                    if (!serviceJson.greenRemarks) {
                        serviceJson.greenRemarks = data.greenRemarks;
                    }
                }

                this.setState({
                    fileLength: 0,
                    status: data.status,
                    applicationId: data.id,
                    productBrands: brands,
                    applicationType: data.applicationTypeId, //1 or 2 new or renew
                    templateId: data.templateId,
                    scoresheetId: data.assessmentId,
                    serviceCategory: data.serviceCategoryValue,
                    manufacturerDetails: data.manufacturerDetails, //name
                    manufacturerNameOfCompany: data.manufacturerNameOfCompany,
                    manufacturerFactoryAddress: data.manufacturerFactoryAddress,
                    manufacturerContactPersonName: data.manufacturerContactPersonName,
                    manufacturerTelephone: data.manufacturerTelephone,
                    manufacturerIsoCode: data.manufacturerIsoCode,
                    manufacturerCountryId: data.manufacturerCountryId,
                    manufacturerCountryName: data.manufacturerCountryName,
                    selectedCategory: data.productCategoryId || data.serviceCategoryId,
                    productCatalogue: data.productCatalogue,
                    productCatalogueFilename: data.productCatalogueFilename,
                    productPicture: data.productPicture,
                    productPictureFilename: data.productPictureFilename,
                    remarks: !data.remarks ? '' : data.remarks,
                    greenRemarks: data.greenRemarks,
                    service: serviceJson,
                    surveyData,
                    activeSurveys,
                    isSelfFinancingCapable: data.isSelfFinancingCapable,
                });
            }
            this.setState({
                countryList: countries.data,
            });
        } catch (error) {
            throw error;
        } finally {
            this.props.forceSetLoading(false);
            return this.props.getCategoryList(this.props.type);
        }
    }
    printMUAPage = () => {
        const callback = async () => {
            const { muaName, muaDesignation, muaEmail, muaContactNumber } = this.state;
            const { id } = this.props.match.params;

            let params = { id, type: this.props.type, muaName, muaDesignation, muaEmail, muaContactNumber };
            const res = await this.props.printMarkUserAgreementForm(params);
            if (res.ok && res.data != null && res.data.file != null && res.data.file != '') {
                var url = SGBC_S3_HOST + '/temp/' + res.data.file;
                window.open(url);
            } else {
                this.showModalIf('Error', 'Failed to print Mark User Agreement', true);
            }
        };
        execute(this.props.forceSetLoading, callback);
    };
    async printMUATermsAndConditions() {
        try {
            const { findByCategoryAndName } = this.props;
            const { data } = await findByCategoryAndName(5, `${this.props.type == 'product' ? 'SGBP' : 'SGBS'} Mark User Agreement`);
            var url = SGBC_S3_ROOT + data.resource;
            window.open(url);
        } catch (error) {
            throw error;
        }
    }
    // MODAL
    modalAction = () => {
        this.modalToggle();
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide, modalAction) => {
        var modal = this.state.modal;
        modal.modalAction = modalAction || this.modalAction;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    showModalIf(header, message, evaluation, modalAction) {
        if (evaluation) this.showModal(header, message, null, null, true, modalAction);
        return evaluation;
    }
    render() {
        const {
            modal,
            muaNameError,
            muaContactNumberError,
            muaEmailError,
            muaDesignationError,
            muaName,
            muaContactNumber,
            muaDesignation,
            muaEmail,
            muaAgreedError,
            muaAgreedTermsError,
        } = this.state;
        const noError =
            muaContactNumberError === '' &&
            muaDesignationError === '' &&
            muaEmailError === '' &&
            muaNameError === '' &&
            muaAgreedTermsError === '' &&
            muaAgreedError === '' &&
            muaName &&
            muaContactNumber &&
            muaDesignation &&
            muaEmail;

        const isProduct = this.props.type === 'product';
        const title = isProduct ? 'Product' : 'Service';
        if (this.props.application.loading || this.props.application.forceLoading) return <LoadingSpinner center />;
        const { productBrands, service } = this.state;
        const models = isProduct ? productBrands && productBrands[0].models : new Array(service);
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <Container className="mt-4" style={{ marginTop: '30px' }}>
                    <Card>
                        <Container className="mt-4" style={{ marginTop: '30px' }}>
                            <MarkUserAgreementPanel
                                customAgreeComponent
                                models={models}
                                applicationId={this.state.applicationId}
                                type={this.props.type}
                                applicationSource={`Certification`}
                                certId={this.props.match.params.id}
                                handlers={{
                                    handleMuaName: this.muaNameChange,
                                    handleMuaEmail: this.muaEmailChange,
                                    handleMuaContactNumber: this.muaContactNumberChange,
                                    handleMuaDesignation: this.muaDesignationChange,
                                    muaAgreedTermsChanged: this.muaAgreedTermsChanged,
                                    muaAgreedChange: this.muaAgreedChange,
                                }}
                                padding
                                noError={noError}
                                updateMarkUserAgreementStates={this.updateMarkUserAgreementStates}
                                // printMUAPage={this.printMUAPage}
                                states={this.state}
                                errors={{ muaNameError, muaDesignationError, muaEmailError, muaContactNumberError }}
                                onRef={ref => (this.markUserAgreement = ref)}
                            />
                        </Container>
                        {this.props.type === 'product' && (
                            <Container className="mt-4" style={{ marginTop: '30px' }}>
                                <CardBody>
                                    <Col xs={12}>
                                        <h3 className="text-bold">{title} Information</h3>
                                        <br />
                                        As a final step, please confirm your certified {this.props.type}’s details. The information will be
                                        published on the SGBC Directory of Certified {title}s and will be visible to the industry.
                                        {isProduct && '(Maximum file size of 1MB)'}
                                    </Col>
                                    <ProductsListTable
                                        editable={(this.state.step === 2 && this.state.status === 'Draft') || false}
                                        onGenericTextFieldChange={this.onGenericTextFieldChange}
                                        brandNameField={this.state.brandNameField}
                                        modelNameField={this.state.modelNameField}
                                        modelDescriptionField={this.state.modelDescriptionField}
                                        toggleAddBrandForm={this.toggleAddBrandForm}
                                        toggleAddModelForm={this.toggleAddModelForm}
                                        addBrandFormVisible={this.state.addBrandFormVisible}
                                        addModelFormVisible={this.state.addModelFormVisible}
                                        currentBrandToAddModel={this.state.currentBrandToAddModel}
                                        addBrand={this.addBrand}
                                        addModel={this.addModel}
                                        removeBrand={this.removeBrand}
                                        removeModel={this.removeModel}
                                        productBrands={this.state.productBrands}
                                        printCertificate={this.printCertificate}
                                        status={this.state.status}
                                        setProductCatalogue={this.setProductCatalogue}
                                        setProductPicture={this.setProductPicture}
                                        allowChangeCatalogue={true}
                                        setWhyGreen={this.setWhyGreen}
                                    />
                                </CardBody>
                            </Container>
                        )}

                        {this.props.type === 'service' && (
                            <Container className="mt-4" style={{ marginTop: '30px' }}>
                                <CardBody>
                                    <Col xs={12}>
                                        <h3 className="text-bold">{title} Information</h3>
                                        <br />
                                        As a final step, please confirm your certified {this.props.type}’s details. The information will be
                                        published on the SGBC Directory of Certified {title}s and will be visible to the industry.
                                        {isProduct && '(Maximum file size of 1MB)'}
                                        <Card className="p-2">
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <b>Service Category</b>
                                                        <br />
                                                        {this.state.serviceCategory}
                                                    </Col>
                                                    <Col>
                                                        <b>Rating</b>
                                                        <br />
                                                        {this.state.service &&
                                                        this.state.service.ticks &&
                                                        this.state.service.ticks != '' &&
                                                        this.state.service.ticks != '-'
                                                            ? `L${parseInt(this.state.service.ticks) - 4}`
                                                            : '-'}
                                                    </Col>
                                                    {(this.state.selectedCategory === 2 || this.state.selectedCategory === 10) && (
                                                        <Col>
                                                            <b>Are you EPC self-financing capable?</b>
                                                            <br />
                                                            {this.state.isSelfFinancingCapable == 1 ? 'Yes' : 'No'}
                                                        </Col>
                                                    )}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </CardBody>
                            </Container>
                        )}

                        <Container style={{ marginTop: '5px' }}>
                            <CardBody>
                                <hr />
                                <AgreePanel
                                    muaAgreedTerms={this.state.muaAgreedTerms}
                                    muaAgreedTermsChanged={this.markUserAgreement && this.markUserAgreement.muaAgreedTermsChanged}
                                    muaAgreed={this.state.muaAgreed}
                                    type={this.props.type}
                                    muaAgreedChange={this.markUserAgreement && this.markUserAgreement.muaAgreedChange}
                                    printMUATermsAndConditions={this.printMUATermsAndConditions}
                                />
                            </CardBody>
                        </Container>
                    </Card>
                </Container>
                {/* As requested to hide on 16th jan */}
                {/* <SurveysDetailPage surveyid={SGBC_PRODUCT_CERT_SURVEY_ID} embedded={true} onRef={ref => (this.surveyComponent = ref)} /> */}
                <Container className="mt-4" style={{ marginTop: '30px' }}>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-end">
                            <Button disabled={!noError} className="primary-btn-style mr-2" onClick={this.confirmCert}>
                                <i className="mr-1 material-icons align-text-bottom ">send</i>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

const AgreePanel = ({
    muaAgreedTerms,
    muaAgreedTermsChanged = () => {},
    muaAgreed,
    muaAgreedChange = () => {},
    printMarkUserAgreementForm,
    printMUATermsAndConditions,
    type,
    // printCertificateLetterOfDeclaration,
}) => {
    return (
        <React.Fragment>
            {/* <Row>
                <Col>
                    As a final step, please confirm your certified product’s details. The information will be published on the SGBC
                    Directory of Certified Products and will be visible to the industry.
                </Col>
            </Row>
            <br /> */}
            <Col>
                <Row>
                    <InputField
                        customLabelComponent={
                            <Label className="text-bold" style={{ maxWidth: '97%' }}>
                                I declare that I possess requisite authority and power of the Company / Applicant to agree to the terms and
                                conditions and other agreements with SGBC Pte Ltd. and bind the Company / Applicant to its obligations.
                            </Label>
                        }
                        labelClassName="field-header"
                        inputProps={{
                            type: 'checkbox',
                            id: 'muaAgreed',
                            checked: muaAgreed,
                            onChange: muaAgreedChange,
                        }}
                    />
                </Row>
                <Row>
                    <InputField
                        customLabelComponent={
                            <Label className="text-bold">
                                ‌I accept the
                                <a href="#" onClick={printMUATermsAndConditions}>
                                    &nbsp;<u>Terms & Conditions</u>&nbsp;
                                </a>
                                of the Mark User Agreement
                                {/* <a href="#" onClick={printMarkUserAgreementForm}>
                                    &nbsp;
                                </a> */}
                                .
                            </Label>
                        }
                        labelClassName="field-header"
                        inputProps={{
                            type: 'checkbox',
                            id: 'muaAgreedTerms',
                            checked: muaAgreedTerms,
                            onChange: muaAgreedTermsChanged,
                        }}
                    />
                </Row>
            </Col>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberProductApplication,
        assessment: state.memberProductAssessment,
        certifications: state.memberCertifications,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getSurvey: id => {
            const data = [dispatch(getSurvey(id))];
            return Promise.all(data).then(res => res[0].data);
        },
        getActiveSurveyByCategory: id => {
            return dispatch(getActiveSurveyByCategory(id));
        },
        getRelevantData: (id, type) => {
            const array = [dispatch(getCategoryList(type)), dispatch(getSavedCertAppl(id, type))];
            return Promise.all(array).then(res => res[1].data);
        },
        getCategoryList: type => {
            dispatch(getCategoryList(type));
        },
        getProductTypes: id => {
            return dispatch(getProductTypes(id));
        },
        getCountriesList: () => {
            return dispatch(getCountriesGeneric());
        },
        forceSetLoading: loadingState => {
            dispatch(forceSetLoading(loadingState));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        setProductCatalogue: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_CATALOGUE',
                resourceId,
                filename,
            });
        },
        setProductPicture: (resourceId, filename) => {
            return dispatch({
                type: 'MEMBER_CERTIFICATION_NEW_APPL_SET_PRODUCT_PICTURE',
                resourceId,
                filename,
            });
        },
        saveCertApplication: (type, body, forSubmit) => {
            return dispatch(saveCertApplication(type, body, forSubmit));
        },
        getInvoiceInfo: id => {
            return dispatch(getInvoiceInfo(id));
        },
        setSavedFlag: savedThisSession => {
            dispatch(setSavedFlag(savedThisSession));
        },
        resetStateToDefault: () => {
            return dispatch(resetStateToDefault());
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
        printMarkUserAgreementForm: data => {
            return dispatch(printMarkUserAgreementForm(data));
        },
        printCert: (certNumber, type) => {
            return dispatch(printCertByCertNumber(certNumber, type));
        },
        completeApplication: (id, brands, type, service) => {
            return dispatch(completeApplication(id, brands, type, service));
        },
        updateApplicationFeedback: (id, brands, type, service) => {
            return dispatch(updateApplicationFeedback(id, brands, type, service));
        },
        updateGreenRemarks: (id, remarks) => {
            return dispatch(updateGreenRemarks(id, remarks));
        },
        updateBrands: (id, brands) => {
            return dispatch(updateBrandsByMember(id, brands));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
    };
};
export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(MemberCertDetailsPage),
);
