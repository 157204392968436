import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Form, Button, Label } from 'reactstrap';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import DialogModal from 'cyder/modals/DialogModal';

import { fetchEventChecklist, fetchEventChecklistById } from 'actions/staff/events/event-checklist/action';

class ListPage extends React.Component {
    import = React.createRef();
    _isMounted = true;
    state = {
        loading: false,
        pagination: true,
        pageSize: 10,
        pageCount: 0,
        csvPayload: '',
        modal: {
            showModal: false,
            modalMessage: '',
            modalHeader: '',
            positiveButtonHide: true,
            negativeButtonHide: true,
            type: 'save',
        },
    };
    showLoding(loading) {
        if (this._isMounted) this.setState({ loading });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.event.eventid > 0) {
            this.props.fetchEventChecklistById(Number(this.props.event.eventid), { itemsPerPage: 20, page: 1 });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onFileChange = async file => {
        this.showLoding(true);
        var state = this.state;
        const parseConfig = {
            header: true,
            skipEmptyLines: true,
            comments: true,
            complete: function(results, file) {},
        };
        var extension = file.name
            .split('.')
            .pop()
            .toLowerCase();
        const isSuccess = ['csv'].indexOf(extension) > -1;
        if (isSuccess) {
            const content = await new Response(file).text();
            const papa = require('papaparse');
            var csvPayload = papa.parse(content, parseConfig);
            state.csvPayload = csvPayload;
            state.filename = file.name;
            const shouldSetState = await this.uploadFile(csvPayload);
            if (shouldSetState) this.setState(state);
            this.showLoding(false);
            return;
        }
        this.showModalIf('Error', 'File extension ' + file.name.split('.').pop() + ' not accepted.', !isSuccess);
        throw new Error('File extension ' + file.name.split('.').pop() + ' not accepted.');
    };
    uploadFile = file => {
        if (file) {
            const data = file.data.map(eachItem => {
                eachItem.eventId = this.props.event.eventid;
                return eachItem;
            });
            return this.props.fetchEventChecklist(data).then(res => {
                this.showModalIf('Success', 'CSV file has been successfully imported', !res.errno);
                this.showModalIf(
                    'Error',
                    'Failed to import CSV file. This error might resulted from incorrect table fields found in the inserted dataset.',
                    res.errno,
                );
                return !res.errno;
            });
        }
    };
    // MODAL
    modalAction = () => {
        this.modalToggle();
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    render() {
        const columns = [
            {
                Header: 'Description',
                accessor: 'description',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Due',
                accessor: 'due',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Completed',
                accessor: 'completed',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value == 1) {
                        return `Yes`;
                    } else {
                        return `No`;
                    }
                },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Button
                            size="sm"
                            className="primary-btn-style"
                            onClick={e => {
                                this.props.setMode('add', props.value);
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    );
                },
            },
        ];
        const { modal } = this.state;
        const { data } = this.props;
        return (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <Row className="mb-2">
                    <Col xs={12} className="text-right">
                        {this.state.filename && (
                            <Label>
                                <strong>Uploaded file: </strong>&nbsp;
                                {this.state.filename}
                            </Label>
                        )}
                    </Col>
                    <Col xs={12}>
                        <Form inline>
                            <div className="ml-auto">
                                <input
                                    type="file"
                                    id="xlsximport"
                                    name="xlsximport"
                                    ref={this.import}
                                    style={{ display: 'none' }}
                                    onChange={e => this.onFileChange(e.target.files[0])}
                                />
                                <Button className="ml-1 mr-1 align-middle primary-btn-style" onClick={() => this.import.current.click()}>
                                    <i className="material-icons align-text-bottom">file_upload</i>
                                    &nbsp;Import
                                    {this.state.filename && ' New '} CSV
                                </Button>
                                <Button
                                    className="ml-1 align-middle primary-btn-style"
                                    onClick={e => {
                                        this.props.setMode('add', 0);
                                    }}
                                >
                                    <i className="material-icons align-text-bottom">add</i>
                                    &nbsp;Add
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={data}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            sortable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={data != null ? data.length : 0}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                // this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const checklist = ownProps.checklist; // Checklist from import csv data
    const checklistItems = ownProps.checklistItems; //Original checklist items
    const checklistMapped =
        checklist &&
        checklist.map(item => ({
            completed: item.completed === 'no' ? 0 : 1,
            description: item.description,
            due: item.due,
            eventId: item.eventId,
            period: item.period,
            remarks: item.remarks,
            unit: item.unit,
        }));
    // Combining csv data from import to checklist else use original checklist
    const data = checklist ? [...checklistMapped, ...checklistItems] : checklistItems;
    return {
        ownProps,
        data,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchEventChecklist: data => dispatch(fetchEventChecklist(data)),
        fetchEventChecklistById: (eventId, data) => dispatch(fetchEventChecklistById(eventId, data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListPage);
