import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import CertificationServiceCertAnalyticsDetailsPopup from 'pages/analytics/components/certification/CertificationServiceCertAnalyticsDetailsPopup';
import { getTotalProductsCertifiedAnalytic } from 'actions/analytics/certificateAnalyticsAction';

class TotalProductsCertifiedBySGBPCumulative extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            var totalProductCount = res.data[0].productCount + res.data[1].productCount;
            this.setState({
                totalProductCount,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Total Products Certified by SGBC (Cumulative)`} height={100}>
                {this.state.totalProductCount == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigText text={this.state.totalProductCount} textColor={`#f8ac59`} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getTotalProductsCertifiedAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(TotalProductsCertifiedBySGBPCumulative));
