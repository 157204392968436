import React from 'react';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import 'css/cyder/form/common-form.css';

export const QuestionaireComponent = ({ label, labelDescription, children }) => {
    return (
        <React.Fragment>
            <div>
                <label className="field-header">
                    {label}
                    {labelDescription && <small className="form-text text-muted">{labelDescription}</small>}
                </label>
                <div className="p-2">{children}</div>
            </div>
            <br />
        </React.Fragment>
    );
};

export const updateCheckedNoUpdateState = (tierResponseInfo, question, answer) => {
    let checked = false;
    if (tierResponseInfo != null && tierResponseInfo.length > 0) {
        tierResponseInfo.forEach(element => {
            if (element.question == question && element.option == answer) {
                checked = true;
            }
        });
    }
    return checked;
};

export const updateChecked = (
    tierResponseInfo,
    question,
    answer,
    myProps,
    answerState1,
    answerState2,
    answer1,
    answer2,
    elementId,
    questionnaires,
) => {
    let checked = false;

    if (tierResponseInfo != null && tierResponseInfo.length > 0) {
        tierResponseInfo.forEach(element => {
            if (element.question == question && element.option == answer) {
                checked = true;
            }
        });
    }

    if (checked) {
        myProps.setAnswerState(answerState1, answerState2, checked || null);
        myProps.answer(answer1, answer2, checked);
    }

    return checked;
};

class QuestionnairesSingleWidget extends React.Component {
    constructor(props) {
        super(props);
        this._visibleMounted = false;

        this.onCertifiedBuildingUploadSuccess = this.onCertifiedBuildingUploadSuccess.bind(this);
        this.onCertifiedBuildingUploadFail = this.onCertifiedBuildingUploadFail.bind(this);
        this.onCertifiedInteriorUploadSuccess = this.onCertifiedInteriorUploadSuccess.bind(this);
        this.onCertifiedInteriorUploadFail = this.onCertifiedInteriorUploadFail.bind(this);

        this.onInternalPoliciesUploadSuccess = this.onInternalPoliciesUploadSuccess.bind(this);
        this.onInternalPoliciesUploadFail = this.onInternalPoliciesUploadFail.bind(this);
        this.onInternalStatementUploadSuccess = this.onInternalStatementUploadSuccess.bind(this);
        this.onInternalStatementUploadFail = this.onInternalStatementUploadFail.bind(this);
        this.onPublishedReportUploadSuccess = this.onPublishedReportUploadSuccess.bind(this);
        this.onPublishedReportUploadFail = this.onPublishedReportUploadFail.bind(this);
        this.onCertifiedUploadSuccess = this.onCertifiedUploadSuccess.bind(this);
        this.onCertifiedUploadFail = this.onCertifiedUploadFail.bind(this);
        this.onSustainabilityAwardUploadSuccess = this.onSustainabilityAwardUploadSuccess.bind(this);
        this.onSustainabilityAwardUploadFail = this.onSustainabilityAwardUploadFail.bind(this);

        this.onQuestion3bUploadSuccess = this.onQuestion3bUploadSuccess.bind(this);
        this.onQuestion3bUploadFail = this.onQuestion3bUploadFail.bind(this);
        this.onQuestion3cUploadSuccess = this.onQuestion3cUploadSuccess.bind(this);
        this.onQuestion3cUploadFail = this.onQuestion3cUploadFail.bind(this);

        this.state = {
            mounted: false,

            certifiedBuildingAttachmentId: '',
            certifiedBuildingFileName: '',
            certifiedInteriorAttachmentId: '',
            certifiedInteriorFileName: '',

            internalPoliciesAttachmentId: '',
            internalPoliciesFileName: '',
            internalStatementAttachmentId: '',
            internalStatementFileName: '',
            publishedReportAttachmentId: '',
            publishedReportFileName: '',
            certifiedAttachmentId: '',
            certifiedFileName: '',
            sustainabilityAwardAttachmentId: '',
            sustainabilityAwardFileName: '',

            question3bAttachmentId: '',
            question3bFileName: '',
            question3cAttachmentId: '',
            question3cFileName: '',
        };
    }
    componentDidMount() {
        // console.log("this.props.questionnaires: ", this.props.questionnaires)
        updateChecked(
            this.props.tierResponseInfo,
            'Which of the following sustainable service(s) does your organisation provide?',
            'Design and Development / Construction / Maintenance of the Green Mark Building and Facilited',
            this.props,
            3,
            1,
            1,
            3,
            'question11',
            this.props.questionnaires,
        );
        updateChecked(
            this.props.tierResponseInfo,
            'Which of the following sustainable service(s) does your organisation provide?',
            'Green Professional Services i.e Environmental Impact Assessment (EIA) Environmental Testing or R&D and Consultancy Services',
            this.props,
            3,
            1,
            1,
            4,
            'question12',
            this.props.questionnaires,
        );
        updateChecked(this.props.tierResponseInfo, 'Are you a SGBS certified company?', 'Yes', this.props, 3, 2, 2, 1, 'question21');
        updateChecked(this.props.tierResponseInfo, 'Are you a SGBS certified company?', 'No', this.props, 3, 2, 2, 2, 'question22');
        updateChecked(this.props.tierResponseInfo, 'Do you have SGBP certification?', 'Yes', this.props, 3, 3, 3, 5, 'question31');
        updateChecked(this.props.tierResponseInfo, 'Do you have SGBP certification?', 'No', this.props, 3, 3, 3, 6, 'question32');

        updateChecked(
            this.props.tierResponseInfo,
            'Which of the following sustainable business operations does your organisation provide?',
            'Green Mark Certified Buildings (or equivalent for foreign-based organisations)',
            this.props,
            4,
            4,
            4,
            7,
            'question41',
            this.props.questionnaires,
        );
        updateChecked(
            this.props.tierResponseInfo,
            'Which of the following sustainable business operations does your organisation provide?',
            'Green Mark-certified Office Interiors or Premises (or equivalent for foreign-based organisations)',
            this.props,
            4,
            4,
            4,
            8,
            'question42',
            this.props.questionnaires,
        );

        try {
            this.setState({
                internalPoliciesAttachmentId: this.props.docs[0].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                internalStatementAttachmentId: this.props.docs[1].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                publishedReportAttachmentId: this.props.docs[2].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                certifiedAttachmentId: this.props.docs[3].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                sustainabilityAwardAttachmentId: this.props.docs[4].docId,
            });
        } catch (e) {}

        try {
            this.setState({
                certifiedBuildingAttachmentId: this.props.docs[5].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                certifiedInteriorAttachmentId: this.props.docs[6].docId,
            });
        } catch (e) {}

        try {
            this.setState({
                question3bAttachmentId: this.props.docs[7].docId,
            });
        } catch (e) {}
        try {
            this.setState({
                question3cAttachmentId: this.props.docs[8].docId,
            });
        } catch (e) {}

        try {
            this.props.updateQuestion5({
                internalPoliciesAttachmentId: this.props.docs[0].docId,
                internalStatementAttachmentId: this.props.docs[1].docId,
                publishedReportAttachmentId: this.props.docs[2].docId,
                certifiedAttachmentId: this.props.docs[3].docId,
                sustainabilityAwardAttachmentId: this.props.docs[4].docId,
            });
            this.props.updateQuestion1({
                certifiedBuildingAttachmentId: this.props.docs[5].docId,
                certifiedInteriorAttachmentId: this.props.docs[6].docId,
            });
            this.props.updateQuestion3({
                question3bAttachmentId: this.props.docs[7].docId,
                question3cAttachmentId: this.props.docs[8].docId,
            });
        } catch (e) {}
        this.setState({
            mounted: true,
        });
    }

    onQuestion3bUploadSuccess(file) {
        this.setState({
            question3bAttachmentId: file.id,
        });
        this.props.updateQuestion3(this.state);
    }
    onQuestion3bUploadFail(error) {}

    onQuestion3cUploadSuccess(file) {
        this.setState({
            question3cAttachmentId: file.id,
        });
        this.props.updateQuestion3(this.state);
    }
    onQuestion3cUploadFail(error) {}

    onCertifiedBuildingUploadSuccess(file) {
        this.setState({
            certifiedBuildingAttachmentId: file.id,
        });
        this.props.updateQuestion1(this.state);
    }
    onCertifiedBuildingUploadFail(error) {}

    onCertifiedInteriorUploadSuccess(file) {
        this.setState({
            certifiedInteriorAttachmentId: file.id,
        });
        this.props.updateQuestion1(this.state);
    }
    onCertifiedInteriorUploadFail(error) {}

    onInternalPoliciesUploadSuccess(file) {
        this.setState({
            internalPoliciesAttachmentId: file.id,
        });
        this.props.updateQuestion5(this.state);
    }
    onInternalPoliciesUploadFail(error) {}
    onInternalStatementUploadSuccess(file) {
        this.setState({
            internalStatementAttachmentId: file.id,
        });
        this.props.updateQuestion5(this.state);
    }
    onInternalStatementUploadFail(error) {
        throw new Error(`onInternalStatementUploadFail: ${error}`);
    }
    onPublishedReportUploadSuccess(file) {
        this.setState({
            publishedReportAttachmentId: file.id,
        });
        this.props.updateQuestion5(this.state);
    }
    onPublishedReportUploadFail(error) {
        throw new Error(`onPublishedReportUploadFail: ${error}`);
    }
    onCertifiedUploadSuccess(file) {
        this.setState({
            certifiedAttachmentId: file.id,
        });
        this.props.updateQuestion5(this.state);
    }
    onCertifiedUploadFail(error) {
        throw new Error(`onCertifiedUploadFail: ${error}`);
    }
    onSustainabilityAwardUploadSuccess(file) {
        this.setState({
            sustainabilityAwardAttachmentId: file.id,
        });
        this.props.updateQuestion5(this.state);
    }
    onSustainabilityAwardUploadFail(error) {
        throw new Error(`onCertifiedUploadFail: ${error}`);
    }
    render() {
        return this.state.mounted ? (
            <Col className="col-12 p-4">
                <QuestionaireComponent label="1. Does your organisation have in place the following sustainability policies and/ or certification(s)?">
                    <div className="form-group field-label">
                        A. Corporate Sustainability Policy & Practice Framework (i.e. Internal statement of commitment outlining
                        organisation’s sustainability objectives and priorities, signed off by top management.
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.internalPoliciesAttachmentId}
                            filename={this.state.internalPoliciesFileName}
                            onUploadSuccess={this.onInternalPoliciesUploadSuccess}
                            onUploadFail={this.onInternalPoliciesUploadFail}
                        />
                    </div>
                    <br />
                    <div className="form-group field-label">
                        B. Green Procurement Practices (i.e. corporate policy or statement with specific clauses on green procurement
                        practices)
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.internalStatementAttachmentId}
                            filename={this.state.internalStatementFileName}
                            onUploadSuccess={this.onInternalStatementUploadSuccess}
                            onUploadFail={this.onInternalStatementUploadFail}
                        />
                    </div>
                    <br />
                    <div className="form-group field-label">
                        C. Corporate Sustainability Report (i.e. publicly available report communication organisation’s sustainability
                        objectives and priorities based on established reporting frameworks. This can be standalone or part of the Annual
                        Report.)
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.publishedReportAttachmentId}
                            filename={this.state.publishedReportFileName}
                            onUploadSuccess={this.onPublishedReportUploadSuccess}
                            onUploadFail={this.onPublishedReportUploadFail}
                        />
                    </div>
                    <br />
                    <div className="form-group field-label">
                        D. Third Party audited Environmental Management Systems (Includes but not limited to: ISO 14001 EMS, ISO 50001 EnMS,
                        ISO 14064-1, 14001-2 or 14064-3 for GHG Emissions, ISO 14044 Life Cycle Assessment, ISO 16745 Carbon Footprinting
                        for Existing Buildings)
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.certifiedAttachmentId}
                            filename={this.state.certifiedFileName}
                            onUploadSuccess={this.onCertifiedUploadSuccess}
                            onUploadFail={this.onCertifiedUploadFail}
                        />
                    </div>
                    <br />
                    <div className="form-group field-label">
                        E. Corporate Sustainability Awards & Accolades
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.sustainabilityAwardAttachmentId}
                            filename={this.state.publishedReportFileName}
                            onUploadSuccess={this.onSustainabilityAwardUploadSuccess}
                            onUploadFail={this.onSustainabilityAwardUploadFail}
                        />
                    </div>
                </QuestionaireComponent>

                <QuestionaireComponent label="2. Does your organisation undertake the following sustainability practice(s) for your operations?">
                    <div className="form-group field-label">
                        A. Lease/ rent/ own Green Mark certified (or equivalent for overseas-headquartered organisations) buildings (i.e.
                        valid BCA Green Mark Award Certificate(s) or Letter(s) of Award)
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.certifiedBuildingAttachmentId}
                            filename={this.state.certifiedBuildingFileName}
                            onUploadSuccess={this.onCertifiedBuildingUploadSuccess}
                            onUploadFail={this.onCertifiedBuildingUploadFail}
                        />
                    </div>
                    <br />
                    <div className="form-group field-label">
                        B. Lease/ rent/ own Green Mark certified office interiors/ workplaces/retail premises (i.e. valid BCA Green Mark
                        Award Certificate(s) or Letter(s) of Award)
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.certifiedInteriorAttachmentId}
                            filename={this.state.certifiedInteriorFileName}
                            onUploadSuccess={this.onCertifiedInteriorUploadSuccess}
                            onUploadFail={this.onCertifiedInteriorUploadFail}
                        />
                    </div>
                    <br />
                </QuestionaireComponent>

                <QuestionaireComponent label="3A. Does your organisation design, develop, construct, manage, operate or maintain Green Mark certified buildings and/ or facilities? ">
                    <div className="form-group field-label">
                        Upload a summary of your organisation’s green building portfolio.
                        <div style={{ fontSize: '0.8em' }}>
                            <i>
                                *Only applicable for the following type of companies: Real estate owners and developers, Builders and
                                contractors, Property / facility management and real estate services, Professional building and
                                environmental consultancies, and Energy services specialists
                            </i>
                        </div>
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.question3bAttachmentId}
                            filename={this.state.question3bFileName}
                            onUploadSuccess={this.onQuestion3bUploadSuccess}
                            onUploadFail={this.onQuestion3bUploadFail}
                        />
                    </div>
                </QuestionaireComponent>

                <QuestionaireComponent label="3B. Does your organisation provide complementary green professional services (i.e. EIA, environmental testing, R&D and consultancy services)? ">
                    <div className="form-group field-label">
                        Upload a brief summary of the services provided.
                        <div style={{ fontSize: '0.8em' }}>
                            <i>
                                *Only applicable for the following type of companies: Professional building and environmental consultancies,
                                Testing laboratories, and Research and tertiary institutions
                            </i>
                        </div>
                        <br />
                        <CyderFileUpload
                            attachmentId={this.state.question3cAttachmentId}
                            filename={this.state.question3cFileName}
                            onUploadSuccess={this.onQuestion3cUploadSuccess}
                            onUploadFail={this.onQuestion3cUploadFail}
                        />
                    </div>
                </QuestionaireComponent>
            </Col>
        ) : null;
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        answer: (q, a, s) => {
            dispatch({
                type: 'MEMBER_QUESTIONNAIRES_SET_QUESTION_ANSWER',
                answers: { q, a, s },
            });
        },
        updateQuestion5: answers => {
            var newAnswer = {
                corporateSustainability: answers.internalPoliciesAttachmentId,
                statementOfCommitment: answers.internalStatementAttachmentId,
                sustainabilityReport: answers.publishedReportAttachmentId,
                certifiedEnvironment: answers.certifiedAttachmentId,
                sustainabilityAward: answers.sustainabilityAwardAttachmentId,
            };
            dispatch({
                type: `MEMBER_QUESTIONNAIRES_SET_QUESTION5`,
                answers: newAnswer,
            });
        },
        updateQuestion1: answers => {
            var newAnswer = {
                certifiedBuilding: answers.certifiedBuildingAttachmentId,
                certifiedInterior: answers.certifiedInteriorAttachmentId,
            };
            dispatch({
                type: `MEMBER_QUESTIONNAIRES_SET_QUESTION1`,
                answers: newAnswer,
            });
        },
        updateQuestion3: answers => {
            var newAnswer = {
                question3b: answers.question3bAttachmentId,
                question3c: answers.question3cAttachmentId,
            };
            dispatch({
                type: `MEMBER_QUESTIONNAIRES_SET_QUESTION3`,
                answers: newAnswer,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuestionnairesSingleWidget);
