/**
 * Security action creators
 *
 * Author: Fernando
 */
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';
import moment from 'moment';

export function getActiveCertifiedProductAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedProductAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getActiveCertifiedProductCountAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedProductCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getActiveCertifiedServiceCountAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedServiceCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getActiveCertifiedProductAnalyticDetail() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedProductAnalyticDetail',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getActiveCertifiedProductCountAnalyticDetail() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedProductCountAnalyticDetail',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getActiveCertifiedServiceCountAnalyticDetail() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveCertifiedServiceCountAnalyticDetail',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getMembershipCertificateCrossAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getMembershipCertificateCrossAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCertifiedProductDistributionAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getCertifiedProductDistributionAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCertifiedServiceDistributionAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getCertifiedServiceDistributionAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getTotalProductsCertifiedAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getTotalProductsCertifiedAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getTotalCompaniesCertifiedAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getTotalCompaniesCertifiedAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getBCACertificateProductAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getBCACertificateProductAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getGreenAttributeCertificateProductAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getGreenAttributeCertificateProductAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getGreenCredentialCertificateProductAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getGreenCredentialCertificateProductAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getActiveProductCategoryCountAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getActiveProductCategoryCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function ceritificateIssuedChartAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationIssuanceAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'ceritificateIssuedChartAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function certificateNumberIssuedAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationIssuanceAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateNumberIssuedAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function certificateRenewalTerminateAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateRenewalTerminateAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function certificateRenewalOverviewAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateRenewalOverviewAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function certificateRenewalDetailAnalytics(renewalStatusId) {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.renewalStatusId = renewalStatusId;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateRenewalDetailAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function certificateRenewalRetentionInfoAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateRenewalRetentionInfoAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
export function certificateRenewalRetentionChartAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().certificationRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateRenewalRetentionChartAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
