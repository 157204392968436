import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Pie } from 'react-chartjs-2';
import { getCertifiedServiceDistributionAnalytic } from 'actions/analytics/certificateAnalyticsAction';
import CyderDoughnut from 'pages/analytics/components/charts/CyderDoughnut';
var randomColor = require('randomcolor');
var tinygradient = require('tinygradient');

// var gradient = tinygradient('#68C0D6', '#78BE20');
var gradient = tinygradient('#36a2eb', '#36eb87', '#ffce56', '#ff8686');

var generateGradient = step => {
    var colorsRgb = gradient.rgb(step);
    return colorsRgb;
};

class CertifiedServiceDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legendconfig: {
                legend: {
                    onClick: null,
                },
            },
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            var col = generateGradient(res.data.length);
            let labels = [];
            let data = [];
            let total = 0;
            let backgroundColor = [];
            let hoverBackgroundColor = [];
            res.data.forEach((element, i) => {
                if (element.serviceCategory != null) {
                    labels.push(element.serviceCategory);
                    data.push(element.count);
                    total += element.count;
                    backgroundColor.push(col[i].toHexString());
                }
            });
            this.setState({
                data,
                backgroundColor,
                hoverBackgroundColor,
                labels,
                total,
                centerText: total,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Certified Services Distribution`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CyderDoughnut {...this.state} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getCertifiedServiceDistributionAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertifiedServiceDistribution));
