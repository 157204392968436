/**
 * Membership Issuance Analytics Reducer
 *
 * Author: Fernando
 *
 */
import moment from 'moment';

const defaultState = {
    startDate: '01/01/' + moment().format('YYYY'),
    endDate: '31/12/' + moment().format('YYYY'),
    year: null,
    chart: 'all',
    interval: 'monthly',
    refresh: null,
};

/**
 * Login reducer
 *
 * @param {*} state
 * @param {*} action
 */
export default function membershipIssuanceAnalyticsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_START_DATE':
            var newState = Object.assign({}, state);
            newState.startDate = action.start;
            return newState;

        case 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_END_DATE':
            var newState = Object.assign({}, state);
            newState.endDate = action.end;
            return newState;

        case 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_CHART':
            var newState = Object.assign({}, state);
            newState.chart = action.chart;
            return newState;

        case 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_INTERVAL':
            var newState = Object.assign({}, state);
            newState.interval = action.interval;
            return newState;

        case 'MEMBERSHIP_ISSUANCE_ANALYTICS_REFRESH':
            var newState = Object.assign({}, state);
            newState.refresh = new Date().getTime();
            return newState;

        default:
            return state;
    }
}
