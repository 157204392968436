/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch, Route } from 'react-router';

import ErrorPage from './pages/error-page';

// PrivateRoute
import PrivateRoute from './cyder/common/PrivateRoute';

// Staff Membership
import StaffHome from './pages/staff/home';
import StaffNewDashboard from './pages/staff/home/Home2';
import StaffMembershipOverviewPage from './pages/staff/membership/overview/StaffMembershipOverviewPage';
import MembershipIssuedAnalyticsByMonth from './pages/staff/membership/overview/MembershipIssuedAnalyticsByMonth';
import MembershipIssuedAnalyticsByYear from './pages/staff/membership/overview/MembershipIssuedAnalyticsByYear';
import MemberProfileEdit from './pages/member/myaccount/MemberProfileEdit';
import TeamMemberDetailsPage from './pages/shared/TeamMemberDetailsPage';
import TeamManagementPage from './pages/shared/TeamManagementComponent';
import ExpiryManagementForecasting from './pages/staff/membership/overview/ExpiryManagementForecastingIndex';
import StaffMembershipApplicationsPage from './pages/staff/membership/applications/StaffMembershipApplicationsPage';
import StaffMembershipApplicationsDetailsPage from './pages/staff/membership/applications/StaffMembershipApplicationsDetailsPage';
import StaffMembershipExportPage from './pages/staff/membership/applications/StaffMembershipExportPage';
import StaffMembershipRenewalsPage from './pages/staff/membership/renewals/StaffMembershipRenewalsPage';
import StaffMembershipRenewalsDetailsPage from './pages/staff/membership/renewals/StaffMembershipRenewalsDetailsPage';
import StaffMembershipMembersPage from './pages/staff/membership/members/StaffMembershipMembersPage';
import StaffCompanyAccountsPage from './pages/staff/membership/members/StaffCompanyAccountsPage';
import StaffCompanyDetailsPage from './pages/staff/company/StaffCompanyDetailsPage';
import StaffTeamMemberDetails from './pages/staff/company/StaffTeamMemberDetails';
import StaffSearch from './pages/staff/search';
import StaffMembershipApplicationsUpdatePaymentPage from './pages/staff/membership/applications/StaffMembershipApplicationsUpdatePaymentPage';
import MembershipRenewalListPage from './pages/staff/membership/renewals/ListPage';
import MembershipRenewalAddPage from './pages/staff/membership/renewals/AddPage';

// Staff Certification
import StaffCertificationApplicationReviewPage from './pages/staff/certification/applications/StaffCertificationApplicationReviewPage';
import StaffCertificationOverviewPage from './pages/staff/certification/applications/overview/StaffCertificationOverviewPage';
import RenewalPage from './pages/staff/certification/RenewalPage';
import StaffCertificationApplicationListPage from './pages/staff/certification/StaffCertificationApplicationListPage';
import StaffCertificationApplicationList from './pages/staff/certification/StaffCertificationApplicationList';
import StaffCertificationApplicationMyCasePage from './pages/staff/certification/StaffCertificationApplicationMyCasePage';
import StaffCertificationListPage from './pages/staff/certification/StaffCertificationListPage';
import StaffCertificationApplicationProductDetails from './pages/staff/certification/certifications/product/StaffCertificationApplicationProductDetails';
import StaffCertificationApplicationServiceDEtails from './pages/staff/certification/certifications/service/StaffCertificationApplicationServiceDetails';
import StaffCertificationAssessmentCriteriaAddPage from './pages/staff/certification/assessment/StaffCertificationAssessmentCriteriaAddPage';
import StaffCertificationAssessmentCriteriaListPage from './pages/staff/certification/assessment/StaffCertificationAssessmentCriteriaListPage';
import StaffCertificationAssessmentTemplateList from './pages/staff/certification/assessment/AssessmentTemplateList';
import TemplateEditPage from './pages/staff/certification/assessment/TemplateEditPage';
import ScoresheetView from './pages/staff/certification/assessment/ScoresheetViewPage';
import ProductAssessmentTemplatePreviewPage from './pages/staff/certification/applications/product/AssessmentTemplatePreviewPage';
import ServiceAssessmentTemplatePreviewPage from './pages/staff/certification/applications/service/AssessmentTemplatePreviewPage';
import StaffProductCertificationApplicationViewPage from './pages/staff/certification/applications/product/ApplicationViewPage';
import StaffServiceCertificationApplicationViewPage from './pages/staff/certification/applications/service/ApplicationViewPage';
import StaffProductApplicationEditOnBehalfPage from './pages/staff/certification/applications/product/edit-on-behalf/AddPage';
import StaffServiceApplicationEditOnBehalfPage from './pages/staff/certification/applications/service/edit-on-behalf/AddPage';
import StaffCertificationExport from './pages/staff/certification/export/StaffCertificationExport';

// Staff - Events
import EventsAllPage from './pages/staff/events/events/EventsAllPage';
import EventAttendeePage from './pages/staff/events/events/EventsAttendeePage';
import EventsAttendeeDetailsPage from './pages/staff/events/events/EventsAttendeeDetailsPage';
import EventsRegPaymentPage from './pages/staff/events/events/EventsRegPaymentPage';
import EventsRegistrationPage from './pages/staff/events/events/EventsRegistrationPage';
import EventsCreatePage from './pages/staff/events/events/EventsCreatePage';
import PromoCodesListPage from './pages/staff/events/promocodes/ListPage';
import PromoCodesAddPage from './pages/staff/events/promocodes/AddPage';
import EventsRsvpPage from './pages/events/EventsRsvpPage';

// Staff - Dinner Events
import GalaDinnerEventsAllPage from './pages/staff/dinner/events/EventsAllPage';
import GalaDinnerEventsCreatePage from './pages/staff/dinner/events/EventsCreatePage';
import GalaDinnerEventsDashboardPage from './pages/staff/dinner/dashboard/DinnerEventsDashboardPage';
import EventDinnerRegistrationListPage from './pages/staff/dinner/registration/ListPage';
import EventDinnerRegistrationAddPage from './pages/staff/dinner/registration/AddPage';
import GalaDinnerEventsSeatingPlanPage from './pages/staff/dinner/seating/ListPage';

// Staff - Elections
import ElectionsAllPage from './pages/staff/elections/ElectionsAllPage';
import ElectionsCreatePage from './pages/staff/elections/ElectionsCreatePage';
import ElectionsDetailPage from './pages/staff/elections/ElectionsDetailPage';
import ElectionsResultPage from './pages/staff/elections/ElectionsResultPage';
import ElectionsResultPrint from './pages/staff/elections/ElectionsResultPrint';
import ElectionsScrutineerLoginPage from './pages/staff/elections/ElectionsScrutineerLoginPage';
import ElectionNomineeListPage from './pages/staff/elections/nominee/ListPage';
import ElectionNomineeAddPage from './pages/staff/elections/nominee/AddPage';
import ElectionScrutineerListPage from './pages/staff/elections/scrutineer/ListPage';
import ElectionScrutineerAddPage from './pages/staff/elections/scrutineer/AddPage';
import ElectionVoterListPage from './pages/staff/elections/voter/ListPage';
import ElectionVoterAddPage from './pages/staff/elections/voter/AddPage';

// Staff - Survey
import SurveysAllPage from './pages/staff/surveys/SurveysAllPage';
import SurveyDashboard from './pages/staff/surveys/SurveyDashboard';
import SurveysCreatePage from './pages/staff/surveys/SurveysCreatePage';
import SurveysResultPage from './pages/staff/surveys/SurveysResultPage';
import SurveysDetailPage from './pages/surveys/SurveysDetailPage';
import CertApplicationSurveyResultPanel from './pages/staff/certification/applications/survey/CertApplicationSurveyResultPanel';

// Staff - Update Profile
import UpdateStaffProfile from './pages/staff/profile/UpdateStaffProfile';

// Staff - Analytics
import CertificationDashboard from './pages/analytics/CertificationDashboard';
import CertificatesIssuedAnalytics from './pages/analytics/CertificatesIssuedAnalytics';
import CertificatesRenewalAnalytics from './pages/analytics/CertificatesRenewalAnalytics';
import MembershipIssuedAnalytics from './pages/analytics/MembershipIssuedAnalytics';
import MembershipRenewalAnalytics from './pages/analytics/MembershipRenewalAnalytics';
import MembershipDashboard from './pages/analytics/MembershipDashboard';
import EventDashboard from './pages/analytics/EventDashboard';
import RenewalDetails from './pages/staff/certification/RenewalDetails';

import MailingListAddPage from './pages/staff/mailinglist/AddPage';
import MailingListListPage from './pages/staff/mailinglist/ListPage';

const Routes = () => (
    <Switch>
        {/* staff */}
        <PrivateRoute exact path="/staff/newdashboard" component={StaffHome} />
        <PrivateRoute exact path="/staff/home" component={StaffNewDashboard} />
        <PrivateRoute exact path="/staff/profile" component={UpdateStaffProfile} />
        <PrivateRoute exact path="/staff/membership/overview" component={MembershipDashboard} />
        <PrivateRoute exact path="/staff/membership/applications/:applicationId" component={StaffMembershipApplicationsDetailsPage} />
        <PrivateRoute exact path="/staff/membership/applications" component={StaffMembershipApplicationsPage} />
        <PrivateRoute exact path="/staff/membership/applications/:applicationId/payment" component={StaffMembershipApplicationsUpdatePaymentPage} />
        <PrivateRoute exact path="/staff/membership/applications/:applicationid/:companyid" component={TeamManagementPage} />
        <PrivateRoute exact path="/staff/membership/export" component={StaffMembershipExportPage} />
        <PrivateRoute exact path="/staff/membership/renewals/:renewalId" component={StaffMembershipRenewalsDetailsPage} />
        <PrivateRoute exact path="/staff/membership/renewals" component={StaffMembershipRenewalsPage} />
        <PrivateRoute exact path="/staff/membership/members" component={StaffMembershipMembersPage} />
        <PrivateRoute exact path="/staff/accounts" component={StaffCompanyAccountsPage} />
        <PrivateRoute exact path="/staff/company/:companyid" component={StaffCompanyDetailsPage} activeTab="overview" />
        <PrivateRoute exact path="/staff/company/:companyid/staffmemberdetail/:memberid" component={StaffTeamMemberDetails} />
        <PrivateRoute exact path="/staff/company/:companyid/memberdetail/:memberid" component={TeamMemberDetailsPage} />
        <PrivateRoute exact path="/staff/company/:companyid/team" component={StaffCompanyDetailsPage} activeTab="team" />
        <PrivateRoute exact path="/staff/company/companyprofile/:companyId" component={MemberProfileEdit} />
        <PrivateRoute exact path="/staff/search" component={StaffSearch} />
        <PrivateRoute exact path="/staff/membership/overview/issuedanalysisbymonth" component={MembershipIssuedAnalyticsByMonth} />
        <PrivateRoute exact path="/staff/membership/overview/issuedanalysisbyyear" component={MembershipIssuedAnalyticsByYear} />
        <PrivateRoute exact path="/staff/membership/overview/expirymanagementforecasting" component={ExpiryManagementForecasting} />
        {/* <PrivateRoute exact path="/staff/membership/membership-renewal/:id?keyword=:keyword&page=:page&status=:status&year=:year" component={MembershipRenewalAddPage} /> */}
        <PrivateRoute exact path="/staff/membership/membership-renewal/:id" component={MembershipRenewalAddPage} />
        {/* <PrivateRoute exact path="/staff/membership/membership-renewal?keyword=:keyword&page=:page&status=:status&year=:year" component={MembershipRenewalListPage} /> */}
        <PrivateRoute exact path="/staff/membership/membership-renewal" component={MembershipRenewalListPage} />

        {/* staff certification */}
        <PrivateRoute exact path="/staff/certification/overview" component={StaffCertificationOverviewPage} />
        <PrivateRoute exact path="/staff/certification/myreview" component={StaffCertificationApplicationReviewPage} />
        <PrivateRoute exact path="/staff/certification/applications" component={StaffCertificationApplicationListPage} mode={'all'} />
        {/* <PrivateRoute exact path="/staff/certification/applications" component={StaffCertificationApplicationList} mode={'all'} /> */}
        <PrivateRoute exact path="/staff/certification/mycases" component={StaffCertificationApplicationMyCasePage} mode={'mycase'} />
        <PrivateRoute exact path="/staff/certification/renewal" component={RenewalPage} />
        <PrivateRoute exact path="/staff/certification/renewal/:id" component={RenewalDetails} />
        <PrivateRoute exact path="/staff/certification/certificates/product" component={StaffCertificationListPage} type="product" />
        <PrivateRoute exact path="/staff/certification/certificates/service" component={StaffCertificationListPage} type="service" />
        <PrivateRoute exact path="/staff/certification/assessment/requirement/new/product" component={StaffCertificationAssessmentCriteriaAddPage} type="product" />
        <PrivateRoute exact path="/staff/certification/assessment/requirement/new/service" component={StaffCertificationAssessmentCriteriaAddPage} type="service" />
        <PrivateRoute exact path="/staff/certification/certificates/product/certdetails/:certId" component={StaffCertificationApplicationProductDetails} type="product" />
        <PrivateRoute exact path="/staff/certification/certificates/service/certdetails/:certId" component={StaffCertificationApplicationServiceDEtails} type="service" />
        <PrivateRoute exact path="/staff/certification/assessment/requirement/:id" component={StaffCertificationAssessmentCriteriaAddPage} />
        <PrivateRoute exact path="/staff/certification/assessment/requirement" component={StaffCertificationAssessmentCriteriaListPage} />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet-criteria/new/product/:id?" component={TemplateEditPage} type="product" />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet/product/:templateid/:scoresheetid" component={ScoresheetView} type="product" />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet/preview/product/:templateid/:scoresheetid" component={ScoresheetView} preview type="product" />
        <PrivateRoute exact path="/staff/certification/applications/viewproducttemplate/:applicationid/:templateid/:scoresheetid/:originapplicationid" component={ProductAssessmentTemplatePreviewPage} type="product" />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet-criteria/new/service/:id?" component={TemplateEditPage} type="service" />
        <PrivateRoute exact path="/staff/certification/applications/viewservicetemplate/:applicationid/:templateid/:scoresheetid/:originapplicationid" component={ServiceAssessmentTemplatePreviewPage} type="service" />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet/service/:templateid/:scoresheetid" component={ScoresheetView} type="service" />
        <PrivateRoute exact path="/staff/certification/assessment/scoresheet/preview/service/:templateid/:scoresheetid" component={ScoresheetView} preview type="service" />
        <PrivateRoute exact path="/staff/certification/export" component={StaffCertificationExport} />

        <PrivateRoute exact path="/staff/certification/assessment/scoresheet-criteria/" component={StaffCertificationAssessmentTemplateList} mode={'all'} />
        <PrivateRoute exact path="/staff/certification/edit-application-on-behalf/product/:id" component={StaffProductApplicationEditOnBehalfPage} />
        <PrivateRoute exact path="/staff/certification/edit-application-on-behalf/service/:id" component={StaffServiceApplicationEditOnBehalfPage} />

        {/* staff product certification */}
        <PrivateRoute exact path="/staff/certification/applications/viewproduct/:id" component={StaffProductCertificationApplicationViewPage} />

        {/* staff services certification */}
        <PrivateRoute exact path="/staff/certification/applications/viewservice/:id" component={StaffServiceCertificationApplicationViewPage} />
        <PrivateRoute exact path="/staff/certification/applications/survey/:id" component={CertApplicationSurveyResultPanel} />

        {/* staff - events */}
        <PrivateRoute exact path="/staff/events/all" component={EventsAllPage} />
        <PrivateRoute exact path="/staff/events/create" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/main" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/dates" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/tracks" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/tickets" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/materials" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/checklist" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/expenditures" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/create/:eventid/analytics" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/main" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/dates" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/tracks" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/tickets" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/materials" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/checklist" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/expenditures" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/:eventid/analytics" component={EventsCreatePage} />
        <PrivateRoute exact path="/staff/events/promocodes/:id" component={PromoCodesAddPage} />
        <PrivateRoute exact path="/staff/events/promocodes/create" component={PromoCodesAddPage} />
        <PrivateRoute exact path="/staff/events/promocodes" component={PromoCodesListPage} />
        <PrivateRoute exact path="/staff/events/:eventid/attendee/:attendeeid" component={EventsAttendeeDetailsPage} />
        <PrivateRoute exact path="/staff/events/:eventid/attendees" component={EventAttendeePage} />
        <PrivateRoute exact path="/staff/events/:eventid/regpayment/:registrationid" component={EventsRegPaymentPage} />
        <PrivateRoute exact path="/staff/events/:eventid/registrations" component={EventsRegistrationPage} />

        {/* staff - gala dinner events */}
        <PrivateRoute exact path="/staff/galadinner/all" component={GalaDinnerEventsAllPage} />
        <PrivateRoute exact path="/staff/galadinner/create" component={GalaDinnerEventsCreatePage} />
        <PrivateRoute exact path="/staff/galadinner/:eventid" component={GalaDinnerEventsCreatePage} />
        <PrivateRoute exact path="/staff/galadinner/:eventid/info" component={GalaDinnerEventsDashboardPage} />
        <PrivateRoute exact path="/staff/galadinner/:eventid/seating" component={GalaDinnerEventsSeatingPlanPage} />
        <PrivateRoute exact path="/staff/galadinner/:eventid/registration/:id" component={EventDinnerRegistrationAddPage} />
        <PrivateRoute exact path="/staff/galadinner/:eventid/registration" component={EventDinnerRegistrationListPage} />

        {/* staff - elections */}
        <PrivateRoute exact path="/staff/elections/all" component={ElectionsAllPage} />
        <PrivateRoute exact path="/staff/elections/create" component={ElectionsCreatePage} />
        <PrivateRoute exact path="/staff/elections/edit/:electionid" component={ElectionsCreatePage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/result" component={ElectionsResultPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/print" component={ElectionsResultPrint} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/vault" component={ElectionsScrutineerLoginPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/nominee/:type" component={ElectionNomineeListPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/nominee/:type/:id" component={ElectionNomineeAddPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/scrutineers/:id" component={ElectionScrutineerAddPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/scrutineers" component={ElectionScrutineerListPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid" component={ElectionsDetailPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/voters/:id" component={ElectionVoterAddPage} />
        <PrivateRoute exact path="/staff/elections/view/:electionid/voters" component={ElectionVoterListPage} />

        {/* staff - survey */}
        <PrivateRoute exact path="/staff/surveys" component={SurveysAllPage} />
        <PrivateRoute exact path="/staff/surveys/all" component={SurveysAllPage} />
        <PrivateRoute exact path="/staff/surveys/create" component={SurveysCreatePage} />
        <PrivateRoute exact path="/staff/surveys/:surveyid/result" component={SurveysResultPage} />
        <PrivateRoute exact path="/staff/surveys/:surveyid" component={SurveysCreatePage} />
        <PrivateRoute exact path="/staff/surveys/view/:surveyid" component={SurveysDetailPage} />

        {/* staff - events */}
        <PrivateRoute exact path="/staff/event/rsvp/:eventid/confirmation" component={EventsRsvpPage} staff={true} />
        <PrivateRoute exact path="/staff/event/rsvp/:eventid/form" component={EventsRsvpPage} staff={true} />
        <PrivateRoute exact path="/staff/event/rsvp/:eventid/tix" component={EventsRsvpPage} staff={true} />
        <PrivateRoute exact path="/staff/event/rsvp/:eventid" component={EventsRsvpPage} staff={true} />

        {/* staff - analytics */}
        <Route exact path="/staff/analytics/event" component={EventDashboard} />
        <Route exact path="/staff/analytics/membership" component={MembershipDashboard} />
        <Route exact path="/staff/analytics/certification" component={CertificationDashboard} />
        <Route exact path="/staff/analytics/certification/issuedanalytics" component={CertificatesIssuedAnalytics} />
        <Route exact path="/staff/analytics/certification/renewalanalytics" component={CertificatesRenewalAnalytics} />
        <Route exact path="/staff/analytics/membership/issuedanalytics" component={MembershipIssuedAnalytics} />
        <Route exact path="/staff/analytics/membership/renewalanalytics" component={MembershipRenewalAnalytics} />

        {/* staff - mailing list */}
        <PrivateRoute exact path="/staff/mailinglist/:id" component={MailingListAddPage} />
        <PrivateRoute exact path="/staff/mailinglist" component={MailingListListPage} />

        {/* Error */}
        <PrivateRoute path="/" component={ErrorPage} />
    </Switch>
);

export default Routes;
