import { SGBC_API_KEY } from 'config';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    APPLICATION_SEARCH_REQUEST,
    APPLICATION_SEARCH_SUCCESS,
    APPLICATION_SEARCH_FAILURE,
    APPLICATION_KEYWORD_SEARCH_REQUEST,
    APPLICATION_KEYWORD_SEARCH_SUCCESS,
    APPLICATION_KEYWORD_SEARCH_FAILURE,
    APPLICATION_TOGGLE_CHECKBOX,
    APPLICATION_TOGGLE_CHECKBOX_ALL,
    APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../../constants/actionTypesStaffMembershipRenewals';
import withQuery from 'with-query';
import cyderlib from '../../../../js/cyderlib';

export function staffMembershipRenewalsSearchAction(page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        dispatch({ type: APPLICATION_SEARCH_REQUEST });
        let response = { data: cyderlib.fakeRenewals };
        dispatch({ type: APPLICATION_SEARCH_SUCCESS, response });
        return Promise.resolve({});

        const url = withQuery(SGBC_API_ROOT + '/auth_v1/groups', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'findallwithpagination',
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
        };
        const options = {
            body: JSON.stringify(params),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: APPLICATION_SEARCH_FAILURE, error });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: APPLICATION_SEARCH_SUCCESS,
                            response,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: APPLICATION_SEARCH_FAILURE, error });
            });
    };
}
export function groupKeywordSearchAction(inputKeywordValue) {
    return (dispatch, getState) => {
        dispatch({
            type: APPLICATION_KEYWORD_SEARCH_REQUEST,
            inputKeywordValue,
        });

        const url = withQuery(SGBC_API_ROOT + '/misc_v1/group', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'search',
        });
        const body = {
            keyword: inputKeywordValue,
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: APPLICATION_KEYWORD_SEARCH_FAILURE,
                            error,
                        });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: APPLICATION_KEYWORD_SEARCH_SUCCESS,
                            response,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: APPLICATION_KEYWORD_SEARCH_FAILURE, error });
            });
    };
}
export function toggleCheckboxes(id) {
    return {
        type: APPLICATION_TOGGLE_CHECKBOX,
        id,
    };
}
export function toggleCheckboxesAll() {
    return {
        type: APPLICATION_TOGGLE_CHECKBOX_ALL,
    };
}
export function changeInputKeywordValue(inputKeywordValue) {
    return {
        type: APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
        inputKeywordValue,
    };
}
