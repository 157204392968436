import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import moment from 'moment';
import { b64toBlob, getParameterByName } from '../../../../js/util';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';
import {
    Input,
    Row,
    Col,
    Form,
    Button,
    FormGroup,
    Label,
    FormText,
    Card,
    CardBody,
    CardText,
    CardHeader,
    Badge,
    Collapse,
    Table,
} from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import LegendIcon from 'cyder/media/LegendIcon';
import CyderHeaderPanel from 'cyder/panels/CyderHeaderPanel';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import Select from 'react-select';

import {
    save,
    update,
    remove,
    load,
    getRenewalStatus,
    updateStatusRemarks,
    print,
    getDiscontinueReasons,
} from 'actions/staff/membership/renewals/action.js';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.printRenewalDocuments = this.printRenewalDocuments.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onDiscontinueReasonChange = this.onDiscontinueReasonChange.bind(this);
        this.viewRenewalApplication = this.viewRenewalApplication.bind(this);
        this.paymentIdChange = this.paymentIdChange.bind(this);
        this.membershipIdChange = this.membershipIdChange.bind(this);
        this.companyIdChange = this.companyIdChange.bind(this);
        this.caseOfficerIdChange = this.caseOfficerIdChange.bind(this);
        this.remarksChange = this.remarksChange.bind(this);
        this.certificatePdfChange = this.certificatePdfChange.bind(this);
        this.newExpiryDateChange = this.newExpiryDateChange.bind(this);
        this.renewalDateChange = this.renewalDateChange.bind(this);
        this.renewalCaseClosedChange = this.renewalCaseClosedChange.bind(this);
        this.renewalNoticePdfChange = this.renewalNoticePdfChange.bind(this);
        this.renewalLetterPdfChange = this.renewalLetterPdfChange.bind(this);
        this.warningLetterPdfChange = this.warningLetterPdfChange.bind(this);
        this.renewalCompleteTimestampChange = this.renewalCompleteTimestampChange.bind(this);
        this.renewalLengthInMonthsChange = this.renewalLengthInMonthsChange.bind(this);
        this.dateOfMembershipExpiryChange = this.dateOfMembershipExpiryChange.bind(this);
        this.membership_packageIdChange = this.membership_packageIdChange.bind(this);
        this.membership_renewal_statusIdChange = this.membership_renewal_statusIdChange.bind(this);
        this.terminationLetterPdfChange = this.terminationLetterPdfChange.bind(this);
        this.renewalNoticeLatePdfChange = this.renewalNoticeLatePdfChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.saveStatusAndRemarks = this.saveStatusAndRemarks.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            loadingSaveStatus: false,
            data: {
                postExpiry60DaysEmailSentTimestamp: '',
                dateJoined: '',
                expiryDate: '',
                loadingPrintRenewalLetter: false,
                loadingPrintWarningLetter: false,
                loadingPrintTerminationLetter: false,
                companyName: '',
                dateOfMembershipExpiry: '',
                membershipApplicationId: '',
                paymentId: '',
                membershipRenewalStatusId: '',
                membershipPackageId: '',
                membershipId: '',
                companyId: '',
                caseOfficerId: '',
                remarks: '',
                renewalCompleteTimestamp: '',
                certificatePdf: '',
                renewalLengthInMonths: '',
                newExpiryDate: '',
                renewalDate: '',
                renewalCaseClosed: '',
                terminationLetterPdf: '',
                warningLetterPdf: '',
                renewalNoticeLatePdf: '',
                renewalNoticePdf: '',
                renewalLetterPdf: '',
                id: '',
                status: {},
                discontinueReason: {},
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/staff/membership/membership-renewal');
    }
    onStatusChange(e) {
        var data = this.state.data;
        data.status = e;
        this.setState({ data });
    }
    onDiscontinueReasonChange(e) {
        var data = this.state.data;
        data.discontinueReason = e;
        this.setState({ data });
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoadingSaveStatus(show) {
        this.setState({
            loadingSaveStatus: show,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.modalAction = null;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    saveStatusAndRemarks() {
        this.showLoadingSaveStatus(true);

        let data = {
            remarks: this.state.data.remarks,
            statusId: this.state.data.status.value,
            renewalId: this.state.data.id,
            discontinueReason: this.state.data.status.label,
            discontinueReasonId: this.state.data.discontinueReason ? this.state.data.discontinueReason.value : null,
        };
        this.props
            .updateStatusRemarks(data)
            .then(result => {
                history.push(
                    `/staff/membership/membership-renewal?keyword=${getParameterByName('keyword')}&page=${getParameterByName(
                        'page',
                    )}&status=${getParameterByName('status')}&year=${getParameterByName('year')}`,
                );
            })
            .catch(error => {
                this.showModal(`Error`, `An error during updating status and remarks, ${error.message}`, 'save', false, false);
                throw error;
            });
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.showLoading(true);
        let statusToAdd = [];
        let discontinueReasonsToAdd = [];

        let statuses = await this.props.getRenewalStatus();
        let discontinueReasons = await this.props.getDiscontinueReasons();
        statuses.data.forEach(status => {
            statusToAdd.push({
                value: status.id,
                label: status.status,
            });
        });
        discontinueReasons.data.forEach(status => {
            discontinueReasonsToAdd.push({
                value: status.id,
                label: status.reason,
            });
        });
        this.setState({
            statuses: statusToAdd,
            discontinueReasons: discontinueReasonsToAdd,
        });

        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id != null && id > 0) {
            let res = await this.props.load(id);
            if (res.error || res.data == null || res.data.id == null) {
                this.showModal('Error', res.message, 'load', true, true);
            } else {
                this.setState({
                    data: res.data,
                });
            }

            statuses.data.forEach(status => {
                if (res.data.membershipRenewalStatusId === status.id) {
                    var data = this.state.data;
                    data.status = {
                        label: status.status,
                        value: status.id,
                    };
                    this.setState({ data });
                }
            });

            discontinueReasons.data.forEach(reason => {
                if (res.data.discontinueReasonId === reason.id) {
                    var data = this.state.data;
                    data.discontinueReason = {
                        label: reason.reason,
                        value: reason.id,
                    };
                    this.setState({ data });
                }
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/staff/membership/membership-renewal');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/staff/membership/membership-renewal', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    viewRenewalApplication() {
        history.replace(`/staff/membership/applications/${this.state.data.membershipApplicationId}`);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/staff/membership/membership-renewal');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }

    dateOfMembershipExpiryChange(e) {
        var data = this.state.data;
        data.dateOfMembershipExpiry = e.target.value;
        this.setState({ data });
    }

    paymentIdChange(e) {
        var data = this.state.data;
        data.paymentId = e.target.value;
        this.setState({ data });
    }

    membership_renewal_statusIdChange(e) {
        var data = this.state.data;
        data.membership_renewal_statusId = e.target.value;
        this.setState({ data });
    }

    membership_packageIdChange(e) {
        var data = this.state.data;
        data.membership_packageId = e.target.value;
        this.setState({ data });
    }

    membershipIdChange(e) {
        var data = this.state.data;
        data.membershipId = e.target.value;
        this.setState({ data });
    }

    companyIdChange(e) {
        var data = this.state.data;
        data.companyId = e.target.value;
        this.setState({ data });
    }

    caseOfficerIdChange(e) {
        var data = this.state.data;
        data.caseOfficerId = e.target.value;
        this.setState({ data });
    }

    remarksChange(e) {
        var data = this.state.data;
        data.remarks = e.target.value;
        this.setState({ data });
    }

    renewalCompleteTimestampChange(e) {
        var data = this.state.data;
        data.renewalCompleteTimestamp = e.target.value;
        this.setState({ data });
    }

    certificatePdfChange(e) {
        var data = this.state.data;
        data.certificatePdf = e.target.value;
        this.setState({ data });
    }

    renewalLengthInMonthsChange(e) {
        var data = this.state.data;
        data.renewalLengthInMonths = e.target.value;
        this.setState({ data });
    }

    newExpiryDateChange(e) {
        var data = this.state.data;
        data.newExpiryDate = e.target.value;
        this.setState({ data });
    }

    renewalDateChange(e) {
        var data = this.state.data;
        data.renewalDate = e.target.value;
        this.setState({ data });
    }

    renewalCaseClosedChange(e) {
        var data = this.state.data;
        data.renewalCaseClosed = e.target.value;
        this.setState({ data });
    }

    terminationLetterPdfChange(e) {
        var data = this.state.data;
        data.terminationLetterPdf = e.target.value;
        this.setState({ data });
    }

    warningLetterPdfChange(e) {
        var data = this.state.data;
        data.warningLetterPdf = e.target.value;
        this.setState({ data });
    }

    renewalNoticeLatePdfChange(e) {
        var data = this.state.data;
        data.renewalNoticeLatePdf = e.target.value;
        this.setState({ data });
    }

    renewalNoticePdfChange(e) {
        var data = this.state.data;
        data.renewalNoticePdf = e.target.value;
        this.setState({ data });
    }

    renewalLetterPdfChange(e) {
        var data = this.state.data;
        data.renewalLetterPdf = e.target.value;
        this.setState({ data });
    }

    printRenewalDocuments = async (action, id) => {
        try {
            this.setState({
                loadingPrintRenewalLetter: true,
            });
            const res = await this.props.print(action, id);
            this.setState({
                loadingPrintRenewalLetter: false,
            });
            const blob = b64toBlob(res.data, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        } catch (error) {
            throw error;
        }
    };

    render() {
        const ed = moment(this.state.data.expiryDate, 'YYYY-MM-DD');
        const expireText =
            moment().diff(ed) * -1 < 0 ? 'Expired' : 'Expires' + ' ' + moment.duration(moment().diff(ed) * -1).humanize(true);
        const rightText = (
            <div className="align-middle">
                <Badge className="badge-sm text-bold" color={expireText.includes('Expired') ? 'danger' : 'warning'}>
                    {expireText}
                </Badge>
                &nbsp;
                <span className="align-middle text-bold">&nbsp;Expiry Date: {moment(ed).format('DD/MM/YYYY')}</span>
            </div>
        );
        return (
            <div className="page-widget">
                {this.state.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <div>
                        <DialogModal
                            negativeButtonAction={this.negativeModalAction}
                            actionToggleExternal={this.modalToggle}
                            modalOpen={this.state.modal.showModal}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                        />
                        <Row>
                            <Col xs={12}>
                                <CyderHeaderPanel leftText={`Membership Renewal ID: ${this.state.data.id}`} rightText={rightText} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12}>
                                <h6 className="float-left">
                                    Status:&nbsp;
                                    {this.state.data.status != null ? this.state.data.status.label : ''}
                                    {/* <LegendIcon status={this.state.data.membershipRenewalStatusId} /> */}
                                </h6>
                                <h6 className="float-right">
                                    <AuditListPopup contentTypeId={18} objectId={this.state.data.id} />
                                </h6>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={4} lg={4}>
                                <Card>
                                    <CardHeader className="p-3 d-flex">
                                        Membership Details
                                        <span className="ml-auto">
                                            <Badge
                                                className="badge-sm text-bold"
                                                color={expireText.includes('Expired') ? 'danger' : 'warning'}
                                            >
                                                {expireText}
                                            </Badge>
                                        </span>
                                    </CardHeader>
                                    <CardBody>
                                        <strong>Member ID:</strong> {this.state.data.membershipId}
                                        <br />
                                        <br />
                                        <strong>Company:</strong> {this.state.data.companyName}
                                        <br />
                                        <br />
                                        <strong>Date Joined:</strong>{' '}
                                        {moment(this.state.data.dateJoined, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                        <br />
                                        <br />
                                        <strong>Expiry Date:</strong>{' '}
                                        {moment(this.state.data.expiryDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={8} lg={8}>
                                <Card>
                                    <CardHeader className="p-3 d-flex">Renewal Details</CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12}>
                                                <Form method="post" action="" className="mb-2">
                                                    <FormGroup>
                                                        <Label>Remarks*</Label>
                                                        <Input
                                                            type="textarea"
                                                            className="textarea form-control"
                                                            id="id_remarks"
                                                            maxLength="1000"
                                                            name="remarks"
                                                            rows="10"
                                                            cols="40"
                                                            value={this.state.data.remarks}
                                                            onChange={this.remarksChange}
                                                        />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <div id="div_id_membership_renewal_status" className="form-group">
                                                                <Label>Membership renewal status*</Label>
                                                                <Select
                                                                    isMulti={false}
                                                                    name="selected-state"
                                                                    disabled={false}
                                                                    searchable={true}
                                                                    autosize={false}
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    options={this.state.statuses}
                                                                    value={this.state.data.status}
                                                                    clearable={true}
                                                                    onChange={this.onStatusChange}
                                                                />
                                                            </div>

                                                            {this.state.data.status != null &&
                                                                (this.state.data.status.value == 6 ||
                                                                    this.state.data.status.value == 4) && (
                                                                    <div id="div_id_membership_discontinue_reason" className="form-group">
                                                                        <Label>Discontinuation Reason*</Label>
                                                                        <Select
                                                                            isMulti={false}
                                                                            name="selected-state"
                                                                            disabled={false}
                                                                            searchable={true}
                                                                            autosize={false}
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            options={this.state.discontinueReasons}
                                                                            value={this.state.data.discontinueReason}
                                                                            clearable={true}
                                                                            onChange={this.onDiscontinueReasonChange}
                                                                        />
                                                                    </div>
                                                                )}
                                                        </Col>
                                                        {this.state.loadingSaveStatus ? (
                                                            <LoadingSpinner />
                                                        ) : (
                                                            <React.Fragment>
                                                                <Col xs={3} style={{ marginTop: '30px' }}>
                                                                    <Button
                                                                        block
                                                                        id="membershipRenewalKey"
                                                                        className="primary-btn-style"
                                                                        onClick={this.saveStatusAndRemarks}
                                                                    >
                                                                        Save Changes
                                                                    </Button>
                                                                </Col>
                                                                {this.state.data.membershipApplicationId != null && (
                                                                    <Col xs={3} style={{ marginTop: '30px' }}>
                                                                        <Button
                                                                            block
                                                                            id="viewRenewalApplication"
                                                                            className="primary-btn-style"
                                                                            onClick={this.viewRenewalApplication}
                                                                        >
                                                                            View Application
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col xs={0} style={{ display: 'none' }}>
                                                <h5>Renewal Documents</h5>
                                                <Table size="sm">
                                                    <tbody>
                                                        {/* <RenewalTableRow
                                                            title="Renewal Letter"
                                                            value="print-renewal-letter"
                                                            clickAction={this.printRenewalDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        /> */}
                                                        {/* <RenewalTableRow
                                                            title="Renewal Notice"
                                                            value="print-renewal-notice"
                                                            clickAction={this.printRenewalDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        />
                                                        <RenewalTableRow
                                                            title="Renewal Notice (Late)"
                                                            value="print-renewal-notice-late"
                                                            clickAction={this.printRenewalDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        /> */}
                                                        {/* <RenewalTableRow
                                                            title="Warning Letter"
                                                            value="print-warning-letter"
                                                            clickAction={this.printWarningDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        />
                                                        <RenewalTableRow
                                                            title="Termination Letter"
                                                            value="print-termination-letter"
                                                            clickAction={this.printTerminationDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        /> */}
                                                        {/* <RenewalTableRow
                                                            title="Membership Certificate"
                                                            value="print-membership-cert"
                                                            clickAction={this.printRenewalDocuments}
                                                            membershipApplicationId={this.state.data.id}
                                                        /> */}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

const RenewalTableRow = ({ title, email, value, clickAction, membershipApplicationId }) => {
    return (
        <tr>
            <td className="align-middle">{email ? <Link to="#">{title}</Link> : <span>{title}</span>}</td>
            <td>
                <Button
                    size="sm"
                    className="primary-btn-style"
                    style={{ padding: '0.20rem' }}
                    id="generateRenewalLetterKey"
                    onClick={() => clickAction(value, membershipApplicationId)}
                >
                    {email ? 'Send Now' : 'Generate'}
                </Button>
            </td>
        </tr>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getRenewalStatus: () => {
            return dispatch(getRenewalStatus());
        },
        getDiscontinueReasons: () => {
            return dispatch(getDiscontinueReasons());
        },
        updateStatusRemarks: data => {
            return dispatch(updateStatusRemarks(data));
        },
        print: (action, id) => {
            return dispatch(print(action, id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
