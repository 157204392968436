import {
    DINNER_EVENT_ALL_GET_EVENTS_REQUEST,
    DINNER_EVENT_ALL_GET_EVENTS_SUCCESS,
    DINNER_EVENT_ALL_GET_EVENTS_FAILURE,
    DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE,
    DINNER_EVENT_ALL_SEARCH_KEYWORD,
    FETCH_TABLE_COUNT,
} from '../../../constants/events/actionTypesStaffEvents';
import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib';
import { SGBC_API_ROOT, instance, SGBC_API_CORS_ROOT } from '../../../../config';

export function eventsDinnerAllGetEventsAction(page, pageSize, keyword, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'getAllGalaEventList',
        });
        const params = {
            keyword,
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            params,
            DINNER_EVENT_ALL_GET_EVENTS_REQUEST,
            DINNER_EVENT_ALL_GET_EVENTS_SUCCESS,
            DINNER_EVENT_ALL_GET_EVENTS_FAILURE,
        );
    };
}
export function eventsAllChangeInputKeywordValue(keyword) {
    return {
        type: DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE,
        keyword,
    };
}

export function getEventInfo(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'findbyid',
            id: eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllRegistrations(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'get-all-gala-registrations',
            id: eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export const loadTableCount = eventId => dispatch => {
    instance
        .get(`/events_v1/cn/event-dinner-ticket-type?action=getTableMaxCount&eventId=${eventId}`)
        .then(response => {
            dispatch({ type: FETCH_TABLE_COUNT, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};
