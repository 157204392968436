import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { certificateRenewalTerminateAnalytics } from 'actions/analytics/certificateAnalyticsAction';
import { HorizontalBar, Chart } from 'react-chartjs-2';

class DistributionOfTerminationList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load().then(res => {
            let total = 0;
            res.data.forEach(item => {
                total += item.count;
            });
            this.setState({
                data: res.data,
                total,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.certification.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.certification.refresh,
            });
        }
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Certificates Expiring/Expired within ${moment(this.props.certification.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.certification.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <h2 style={{ color: '#ed5565' }}>Distribution of Reasons for Termination</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ width: '60%' }}>{item.renewalStatus}</td>
                                            <td style={{ width: '20%' }}>{item.count}</td>
                                            <td style={{ width: '20%' }}>{Math.round((item.count * 100) / this.state.total)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        certification: state.certificationRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(certificateRenewalTerminateAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(DistributionOfTerminationList));
