import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsToolbar from 'pages/analytics/components/AnalyticsToolbar';
import ButtonIcon from 'cyder/button/ButtonIcon';
import Select from 'react-select';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';
import { Container } from 'semantic-ui-react';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check, icon }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    // console.log("buttonColor: ", buttonColor, ", check: ", check);
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon icon={icon} label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

class MembershipIssuanceAnalyticsToolbar extends React.Component {
    constructor(props) {
        super(props);

        this.filterAll = this.filterAll.bind(this);
        this.filterAllNR = this.filterAllNR.bind(this);

        this.filterMonthly = this.filterMonthly.bind(this);
        this.filterYearly = this.filterYearly.bind(this);

        this.searchByDates = this.searchByDates.bind(this);
        this.filterYear = this.filterYear.bind(this);

        this.state = {
            years: [
                { value: '2012', label: '2012' },
                { value: '2013', label: '2013' },
                { value: '2014', label: '2014' },
                { value: '2015', label: '2015' },
                { value: '2016', label: '2016' },
                { value: '2017', label: '2017' },
                { value: '2018', label: '2018' },
                { value: '2019', label: '2019' },
                { value: '2020', label: '2020' },
                { value: '2021', label: '2021' },
                { value: '2022', label: '2022' },
                { value: '2023', label: '2023' },
                { value: '2024', label: '2024' },
                { value: '2025', label: '2025' },
            ],
            selectedYear: {
                value: moment().format('YYYY'),
                label: moment().format('YYYY'),
            },
        };
    }
    searchByDates() {
        // console.log("searchByDates");
        this.props.refresh();
    }
    filterMonthly() {
        // console.log("filterAll");
        this.props.setInterval('monthly');
        this.props.refresh();
    }
    filterYearly() {
        // console.log("filterAll");
        this.props.setInterval('yearly');
        this.props.refresh();
    }

    filterAll() {
        // console.log("filterAll");
        this.props.setChart('all');
        this.props.refresh();
    }
    filterAllNR() {
        // console.log("filterAll");
        this.props.setChart('allNR');
        this.props.refresh();
    }

    filterYear(e) {
        this.props.setStartDate(moment('01/01/' + e.value, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + e.value, 'DD/MM/YYYY'));
        this.setState({
            selectedYear: e,
        });
        this.props.refresh();
    }
    componentDidMount() {
        let currentYear = moment().format('YYYY');
        this.props.setStartDate(moment('01/01/' + currentYear, 'DD/MM/YYYY'));
        this.props.setEndDate(moment('31/12/' + currentYear, 'DD/MM/YYYY'));
    }

    render() {
        return (
            <AnalyticsToolbar title={`Membership Issued Analytics`}>
                <React.Fragment>
                    <Container>
                        <Row>
                            <Col xs="3">
                                <Row>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterAll}
                                            check={this.props.membership.chart == 'all'}
                                            label="All"
                                        />
                                    </Col>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterAllNR}
                                            check={this.props.membership.chart == 'allNR'}
                                            label="All (New vs Renewal)"
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col xs="auto" style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.filterYearly}
                                            check={this.props.membership.interval == 'yearly'}
                                            label="Yearly"
                                        />
                                    </Col>
                                    <Col xs="auto">
                                        <AnalyticsButton
                                            style={{ paddingLeft: '2px', paddingRight: '2px' }}
                                            colStyle={buttonStyle}
                                            onClick={this.filterMonthly}
                                            check={this.props.membership.interval == 'monthly'}
                                            label="Monthly"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <div style={{ display: 'inline' }}>
                                    <div style={{ display: 'inline-block', width: '100px' }}>
                                        <Select
                                            autosize={false}
                                            options={this.state.years}
                                            value={this.state.selectedYear}
                                            clearable={true}
                                            onChange={this.filterYear}
                                            name="selected-state"
                                            disabled={false}
                                            searchable={true}
                                        />
                                    </div>
                                    <div style={{ display: 'inline-block', width: '50px' }}>
                                        <AnalyticsButton
                                            colStyle={buttonStyle}
                                            onClick={this.searchByDates}
                                            color={`primary`}
                                            label=""
                                            icon="arrow_forward"
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            </AnalyticsToolbar>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        membership: state.membershipIssuanceAnalyticsReducer,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setStartDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_START_DATE',
                    start: dt,
                });
            } catch (error) {}
        },
        setEndDate: e => {
            try {
                let dt = e.format('DD/MM/YYYY');
                return dispatch({
                    type: 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_END_DATE',
                    end: dt,
                });
            } catch (error) {}
        },
        setChart: chart => {
            try {
                return dispatch({
                    type: 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_CHART',
                    chart,
                });
            } catch (error) {}
        },
        setInterval: interval => {
            try {
                return dispatch({
                    type: 'MEMBERSHIP_ISSUANCE_ANALYTICS_SET_INTERVAL',
                    interval,
                });
            } catch (error) {}
        },
        refresh: () => {
            try {
                return dispatch({
                    type: 'MEMBERSHIP_ISSUANCE_ANALYTICS_REFRESH',
                });
            } catch (error) {}
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipIssuanceAnalyticsToolbar));
