import withQuery from 'with-query';
import { generatePaginationBody } from 'js/reactTableLib';

import {
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST,
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
    TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE,
    TEAM_MANAGEMENT_SET_MODAL_DATA,
    TEAM_MANAGEMENT_CHANGE_FIELD,
    TEAM_MANAGEMENT_RESET_FIELDS,
    TEAM_MANAGEMENT_RESET_INPUT_FIELDS,
    TEAM_MANAGEMENT_HAS_EMAIL_USED,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE,
    TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS,
    TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE,
    TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER,
} from 'actions/constants/actionTypesTeamManagement';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function memberGetTeamMembers(page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'getTeamMemberListForUser',
        });
        const body = Object.assign({}, generatePaginationBody(page, pageSize, sorted, filtered));
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST,
            TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS,
            TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE,
        );
    };
}
export function setModalData(data, overrideModalWithMessage) {
    return {
        type: TEAM_MANAGEMENT_SET_MODAL_DATA,
        data,
        overrideModalWithMessage,
    };
}
export function setModalDataUpgradeSubscriber(data) {
    return {
        type: TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER,
        data,
    };
}
export function onChangeValue(key, value) {
    return {
        type: TEAM_MANAGEMENT_CHANGE_FIELD,
        key,
        value,
    };
}
export function resetState() {
    return {
        type: TEAM_MANAGEMENT_RESET_FIELDS,
    };
}
export function resetAddTeamMemberInputs() {
    return {
        type: TEAM_MANAGEMENT_RESET_INPUT_FIELDS,
    };
}
export function setHasEmailUsed(isUsed) {
    return {
        type: TEAM_MANAGEMENT_HAS_EMAIL_USED,
        isUsed,
    };
}
// Use for both staff and member
export function createTeamMember(mode, companyId, companyName, isSubscriber) {
    return (dispatch, getState) => {
        // url
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: mode === 'staff' ? 'createTeamMemberByStaff' : 'createTeamMember',
        });
        // body
        const fields = getState().teamManagementReducer.fields;
        const body = {
            firstName: fields.firstName,
            lastName: fields.lastName,
            email: fields.email,
            isSubscriber,
            mode: 'createpanel',
        };
        if (mode === 'staff') {
            body.companyId = parseInt(companyId);
            body.companyName = companyName;
        }
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST,
            isSubscriber ? TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS : TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS,
            TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE,
        );
    };
}

export function upgradeSubscriber(personId) {
    return (dispatch, getState) => {
        // url
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'upgradeSubsriber',
        });
        const body = {
            personId,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST,
            TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS,
            TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE,
        );
    };
}

export function createTeamMemberViaArgs(companyId, companyName, firstName, lastName, designation, email, telephoneDid, isOMR) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'createTeamMemberByStaff',
        });
        const body = {
            firstName,
            lastName,
            email,
            designation,
            companyId,
            companyName,
            isOMR,
            telephoneDid,
            mode: 'omrpanel',
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body);
    };
}
