/**
 * Certiifcate pay action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function loadApplicationInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'get-payment-info-by-applicationid',
            id: id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function applicationLoadEventPaymentModes(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment-mode`, {
            action: 'getFinancePaymentMode',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
