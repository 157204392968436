import React from 'react';
import { connect } from 'react-redux';

import DashboardWidget from '../../../cyder/widget/DashboardWidget';

// SGBC custom components
import SearchPanel from '../../../cyder/search/SearchPanel';

class Home extends React.Component {
    render() {
        let title = 'Search Result For "cyder"';
        if (this.props.profile.user.role === 'Staff') {
            return (
                <DashboardWidget title={title} description="About 5 result">
                    <SearchPanel items={this.props.staff} />
                </DashboardWidget>
            );
        } else if (this.props.profile.user.role === 'Member') {
            return (
                <DashboardWidget title={title} description="About 5 result">
                    <SearchPanel items={this.props.staff} />
                </DashboardWidget>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        profile: state.profile,
        staff: state.staff.search,
        member: state.member.search,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home);
