import {
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS,
    MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE,
    MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING,
    MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG,
    MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO,
    MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS,
    MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS,
    MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE,
} from '../../../constants/actionTypesMemberCertification';

import fetchlib from '../../../../js/fetchlib';
import withQuery from 'with-query';

import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';

export function forceSetLoading(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_CERTIFICATION_NEW_APPL_FORCE_LOADING,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function getAllOrganisationSubCategory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/orgmaincategories`, {
            action: 'getallmainandsubcategory',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_REQUEST,
            MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_SUCCESS,
            MEMBERSHIP_GET_ORGANISATION_SUBCATEGORY_LIST_FAILURE,
        );
    };
}
export function getAllOrganisationalCategory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership/`, {
            action: 'getAllOrganisationalCategory',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_REQUEST,
            MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_SUCCESS,
            MEMBERSHIP_GET_ORGANISATIONAL_CATEGORY_LIST_FAILURE,
        );
    };
}
export function getCategoryList(type) {
    return (dispatch, getState) => {
        const typeString = type === 'service' ? 'service_v1/cn/category' : 'product_v1/cn/product-category';
        const url = withQuery(`${SGBC_API_ROOT}/${typeString}`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            { exclude: 'Others' },
            MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_CATEGORY_LIST_FAILURE,
        );
    };
}
export function getRenewCertApplFromCertID(certId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/certificate/`, {
            action: `findApplicationDetailsByCertId`,
            id: certId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_RENEW_APPL_GET_REQUEST_INFO,
            MEMBER_CERTIFICATION_RENEW_APPL_GET_SUCCESS_INFO,
            MEMBER_CERTIFICATION_RENEW_APPL_GET_FAILURE_INFO,
        );
    };
}

export function getSavedCertAppl(id, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: `get-${type}-cert-application`,
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_SAVED_APPLICATION_FAILURE,
        );
    };
}
export function memberGetSavedProdCatalogue() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/attachment`, {
            action: 'download',
            id: getState().memberCertNewApplReducer.uploadedFileId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE,
        );
    };
}
export function deleteCertApplication(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: `delete`,
        });
        const body = {
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE,
        );
    };
}
export function memberGetSavedProdPicture() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/attachment`, {
            action: 'download',
            id: getState().memberCertNewApplReducer.uploadedFileId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PRODUCT_CATALOGUE_FAILURE,
        );
    };
}
export function saveCertApplication(type, body, forSubmit) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: `${forSubmit === true ? 'submit' : 'save'}-${type}-cert-application`,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_APPLICATION_FAILURE,
        );
    };
}
export function getProductTypes(categoryid) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product-type`, {
            action: 'get-all-active-product-types-by-category-id',
            categoryid,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_GET_PROD_TYPES_FAILURE,
        );
    };
}
export function getServiceCategory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/service_v1/cn/category`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function saveProductCatalogue(contenttype, filename, attachment) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/attachment`, {
            action: 'upload',
        });
        const body = {
            contenttype,
            filename,
            attachment,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_REQUEST,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_SUCCESS,
            MEMBER_CERTIFICATION_NEW_APPL_SAVE_PRODUCT_CATALOGUE_FAILURE,
        );
    };
}

export function updateBrands(id, brands) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-brands',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, brands, null, null, null);
    };
}

export function setViewMode(mode) {
    return {
        type: 'MEMBERSHIP_PRODUCT_APPLICATION_SET_VIEW_MODE',
        mode,
    };
}

export function setProductApplicationStatus(status) {
    return {
        type: 'MEMBER_PRODUCT_SET_APPLICATION_STATUS',
        status,
    };
}

export function setAddBrandFormVisible(show) {
    return {
        type: 'MEMBER_PRODUCT_SET_ADD_BRAND_FORM_VISIBLE',
        show,
    };
}

export function setAddModelFormVisible(show) {
    return {
        type: 'MEMBER_PRODUCT_SET_ADD_MODEL_FORM_VISIBLE',
        show,
    };
}

export function setTakeUpCase(takeUpCaseStarted) {
    return {
        type: 'MEMBER_PRODUCT_SET_TAKE_UP_CASE_STARTED',
        takeUpCaseStarted,
    };
}

export function setAssignApprover(assignApproverStarted) {
    return {
        type: 'MEMBER_PRODUCT_SET_ASSIGN_APPROVER_STARTED',
        assignApproverStarted,
    };
}

export function removeProductCatalogue() {
    return {
        type: MEMBER_CERTIFICATION_NEW_APPL_REMOVE_PRODUCT_CATALOGUE_ID,
    };
}
export function setSavedFlag(savedThisSession) {
    return {
        type: MEMBER_CERTIFICATION_NEW_APPL_SET_SAVED_FLAG,
        savedThisSession,
    };
}

export function setAssignNextApprover(assignNextApproverStarted) {
    return {
        type: 'MEMBER_PRODUCT_SET_ASSIGN_NEXT_APPROVER_STARTED',
        assignNextApproverStarted,
    };
}

export function resetStateToDefault() {
    return dispatch => {
        dispatch({ type: MEMBER_CERTIFICATION_RENEW_APPL_RESET_TO_DEFAULT });
        return Promise.resolve();
    };
}
