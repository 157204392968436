import {
    STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE,
    STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE,
} from '../../constants/actionTypesStaffCompanyDetails';
import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';

export function staffCompanyDetailsGetTeamMemberList(page, pageSize, sorted, filtered, companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'getTeamMemberListForStaff',
        });
        const body = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            companyId,
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST,
            STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS,
            STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE,
        );
    };
}

export function staffCompanyDetailsGetSubscriberList(page, pageSize, sorted, filtered, companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/persons`, {
            action: 'getSubscriberListForStaff',
        });
        const body = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            companyId,
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST,
            STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS,
            STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE,
        );
    };
}
