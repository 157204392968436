// Group Search
export const APPLICATION_SEARCH_REQUEST = 'APPLICATION_SEARCH_REQUEST';
export const APPLICATION_SEARCH_SUCCESS = 'APPLICATION_SEARCH_SUCCESS';
export const APPLICATION_SEARCH_FAILURE = 'APPLICATION_SEARCH_FAILURE';
export const APPLICATION_KEYWORD_SEARCH_REQUEST = 'APPLICATION_KEYWORD_SEARCH_REQUEST';
export const APPLICATION_KEYWORD_SEARCH_SUCCESS = 'APPLICATION_KEYWORD_SEARCH_SUCCESS';
export const APPLICATION_KEYWORD_SEARCH_FAILURE = 'APPLICATION_KEYWORD_SEARCH_FAILURE';
export const APPLICATION_TOGGLE_CHECKBOX = 'APPLICATION_TOGGLE_CHECKBOX';
export const APPLICATION_TOGGLE_CHECKBOX_ALL = 'APPLICATION_TOGGLE_CHECKBOX_ALL';

export const APPLICATION_CHANGE_INPUT_KEYWORD_VALUE = 'APPLICATION_CHANGE_INPUT_KEYWORD_VALUE ';

// Group Details / Group Add
export const APPLICATION_ADD_GET_DETAIL_REQUEST = 'APPLICATION_ADD_GET_DETAIL_REQUEST';
export const APPLICATION_ADD_GET_DETAIL_SUCCESS = 'APPLICATION_ADD_GET_DETAIL_SUCCESS';
export const APPLICATION_ADD_GET_DETAIL_FAILURE = 'APPLICATION_ADD_GET_DETAIL_FAILURE';

export const APPLICATION_ADD_SAVE_APPLICATION_REQUEST = 'APPLICATION_ADD_SAVE_APPLICATION_REQUEST';
export const APPLICATION_ADD_SAVE_APPLICATION_SUCCESS = 'APPLICATION_ADD_SAVE_APPLICATION_SUCCESS';
export const APPLICATION_ADD_SAVE_APPLICATION_FAILURE = 'APPLICATION_ADD_SAVE_APPLICATION_FAILURE';

export const APPLICATION_ADD_DELETE_APPLICATION_REQUEST = 'APPLICATION_ADD_DELETE_APPLICATION_REQUEST';
export const APPLICATION_ADD_DELETE_APPLICATION_SUCCESS = 'APPLICATION_ADD_DELETE_APPLICATION_SUCCESS';
export const APPLICATION_ADD_DELETE_APPLICATION_FAILURE = 'APPLICATION_ADD_DELETE_APPLICATION_FAILURE';

export const APPLICATION_ADD_SET_FIELDS = 'APPLICATION_ADD_SET_FIELDS';

export const APPLICATION_ADD_CHANGE_FIELD_VALUE = 'APPLICATION_ADD_CHANGE_FIELD_VALUE ';

export const APPLICATION_ADD_MODAL_TOGGLE = 'APPLICATION_ADD_MODAL_TOGGLE';
