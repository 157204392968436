import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import { Container, FormGroup, Label, Input, Row, Col, Form, Button } from 'reactstrap';

import { toggleCheckboxes, toggleCheckboxesAll, groupSearchAction } from '../../../actions/admin/auth/groupAction.js';

import cyderlib from '../../../js/cyderlib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class GroupPage extends React.Component {
    render() {
        const groupDetailsUrlPrefix = '/admin/auth/group/';
        const yesNoUnknownIcons = val => {
            switch (val) {
                case null:
                    return <i className="fa fa-icon text-muted fa-question-circle" />;
                case true:
                    return <i className="material-icons align-text-bottom text-success">check_circle</i>;
                case false:
                    return <i className="material-icons align-text-bottom text-danger">remove_circle</i>;
            }
        };
        const columns = [
            {
                Header: () => {
                    return (
                        <FormGroup check>
                            <Label>
                                <Input checked={this.props.selectedAll} type="checkbox" onChange={() => this.props.handleCheckboxAll()} />
                                <div className="invisible"> Select All</div>
                            </Label>
                        </FormGroup>
                    );
                },
                sortable: false,
                filterable: false,
                accessor: 'id',
                width: 30,
                Cell: props => {
                    return (
                        <FormGroup check>
                            <Label>
                                <Input
                                    checked={this.props.selectedItems[props.value] ? this.props.selectedItems[props.value] : false}
                                    type="checkbox"
                                    onChange={() => this.props.handleCheckbox(props.value)}
                                />
                                <div className="invisible"> Selector</div>
                            </Label>
                        </FormGroup>
                    );
                },
            },
            {
                Header: 'Group',
                accessor: 'name',
                Cell: props => {
                    return <Link to={groupDetailsUrlPrefix + props.row.id}>{props.value}</Link>;
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="text" name="text" id="search-keyword" placeholder="Keyword" />
                            </FormGroup>
                            <Button>Search</Button>
                            <div className="ml-auto">
                                <Link to="/admin/auth/group/add">
                                    <Button className="ml-1 mr-1 align-middle" color="success">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add Group
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactTable
                            className="-highlight mb-2"
                            data={this.props.groups}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            manual
                            pages={this.props.tablePages}
                            loading={this.props.loading}
                            onFetchData={(state, instance) => {
                                this.props.getGroups(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Button className="mr-2">Delete Selected</Button>
                        {Object.keys(this.props.selectedItems).length} out of {cyderlib.fakeOrganizationMainCategories.length} selected
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.groupReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleCheckbox: id => {
            dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            dispatch(toggleCheckboxesAll());
        },
        getGroups: (page, pageSize, sorted, filtered) => {
            dispatch(groupSearchAction(page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(GroupPage));
