import React, { Component } from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

// COMPONENT
import { Collapse, Row, Col, Button, FormGroup, Label, Input } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';

// ACTIONS
import { getCategoryList } from 'actions/member/certification/applications/memberCertNewApplAction';
import { getDataList } from 'actions/staff/certification/staffCertificationExportAction';

import 'react-dates/initialize';
import { capitalizeFirstLetter } from 'js/util';
import { exportCSVFile } from 'js/jsonToCsvExport';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const defaultState = {
    // GENERAL
    loading: false,
    isExport: false,
    isOpen: true,
    tableList: [],
    totalNum: 0,
    totalPages: 1,
    // PERIOD INPUT
    periodOptions: [{ value: 'By Date Range', checked: false }, { value: 'By Year', checked: false }],
    focusedInput: null,
    issuedFrom: {
        id: 'Issued From',
        focused: false,
        date: null,
        placeholder: 'Issued From',
    },
    issuedTo: {
        id: 'Issued To',
        focused: false,
        date: null,
        placeholder: 'Issue To',
    },
    yearOptions: [],
    issuedByYear: null,
    // CATEGORY INPUT
    categoryOptions: [],
    // RATING INPUT
    productRatingOptions: new Array(4).fill((x, i) => i),
    serviceRatingOptions: new Array(4).fill((x, i) => i),
    //CERIFICATION STATUS INPUT
    certStatusOptions: [],
    certStatus: null,
    //CERIFICATION TYPE INPUT
    certTypeOptions: [],
    certType: null,
    //MEMBERSHIP STATUS INPUT
    membershipStatusOptions: [],
    membershipStatus: null,
};

class DynamicExportPanel extends Component {
    table = React.createRef();
    state = defaultState;
    // LIFECYCLE
    componentDidMount() {
        this.getRelevantData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.clearState().then(() => {
                this.getRelevantData();
                this.setState({ isOpen: true });
            });
        }
    }
    // DATA GETTER
    getRelevantData = () => {
        this.showLoading(true);
        const tasks = [
            this.getCategoryList(),
            this.getRatingList(),
            this.generateYears(2000),
            this.generateCertStatusList(),
            this.generateCertTypeList(),
            this.generateMembershipStatusList(),
        ];
        return Promise.all(tasks).then(res => this.showLoading(false));
    };
    getCategoryList = async () => {
        try {
            const { data } = await this.props.getCategoryList(this.props.activeTab);
            if (!data) return;
            // PROVIDE CHECKED PROP
            const newData = data.map(opt => {
                opt.checked = false;
                return opt;
            });
            this.setState({
                categoryOptions: newData,
            });
        } catch (error) {
            throw error;
        }
    };
    getRatingList = () => {
        const { activeTab } = this.props;
        let clone = Array.from(this.state[`${activeTab}RatingOptions`]);
        // GENERATE VALUE
        clone = clone.map((opt, index) => {
            const val = index + 1;
            let productTickVal = '';
            if (val == 1) {
                productTickVal = 'Good';
            } else if (val == 2) {
                productTickVal = 'Very Good';
            } else if (val == 3) {
                productTickVal = 'Excellent';
            } else if (val == 4) {
                productTickVal = 'Leader';
            }
            return {
                id: val,
                value: `${activeTab === 'product' ? productTickVal : 'L' + val}`,
                checked: false,
            };
        });
        if (activeTab !== 'product')
            clone.push({
                id: 'N.A',
                value: 'N.A',
                checked: false,
            });
        // SET STATE
        const state = Object.assign({}, this.state);
        state[`${activeTab}RatingOptions`] = clone;
        this.setState(state);
    };
    generateYears = minYear => {
        const currentYear = new Date().getFullYear();
        let yearOptions = new Array(currentYear + 1 - minYear).fill(x => '');
        yearOptions = yearOptions
            .map((x, i) => {
                const year = minYear + i;
                return {
                    label: year,
                    value: year,
                };
            })
            .reverse();
        this.setState({ yearOptions });
    };
    generateCertStatusList = () => {
        const labels = ['All', 'Active', 'Expired'];
        const certStatusOptions = labels.map(label => {
            return {
                value: label,
                label: label,
            };
        });
        this.setState({
            certStatusOptions: certStatusOptions,
        });
    };
    generateCertTypeList = () => {
        const labels = ['All', 'New', 'Renew'];
        const certTypeOptions = labels.map(label => {
            return {
                value: label,
                label,
            };
        });
        this.setState({
            certTypeOptions,
        });
    };
    generateMembershipStatusList = () => {
        const labels = ['All', 'Member', 'Non Member'];
        const membershipStatusOptions = labels.map(label => {
            return {
                value: label,
                label,
            };
        });
        this.setState({
            membershipStatusOptions,
        });
    };
    // UTIL
    showLoading(loading) {
        this.setState({ loading });
    }
    shouldPeriodOptionDisabled = id => {
        const { periodOptions } = this.state;
        const option = periodOptions.find(opt => opt.value === id);
        return !option.checked;
    };
    resetDatesValue = () => {
        let issuedFromState = Object.assign({}, this.state.issuedFrom);
        let issuedToState = Object.assign({}, this.state.issuedTo);
        issuedFromState.date = null;
        issuedToState.date = null;
        this.setState({
            issuedFrom: issuedFromState,
            issuedTo: issuedToState,
        });
    };
    resetYearValue = () => {
        this.setState({
            issuedByYear: null,
        });
    };
    clearState = () => {
        const { isOpen } = this.state;
        const periodOptions = this.state.periodOptions.map(x => {
            x.checked = false;
            return x;
        });
        this.setState({ ...defaultState, isOpen, periodOptions });
        return Promise.resolve();
    };
    constructData = (page, pageSize, sorted) => {
        const { activeTab } = this.props;
        const { issuedFrom, issuedTo, issuedByYear, certStatus, certType, membershipStatus, isExport } = this.state;
        // CONSTRUCT DATA
        const isProduct = activeTab === 'product';
        const categoryOptions = this.state.categoryOptions.filter(x => x.checked);
        const ratingOptions = this.state[`${activeTab}RatingOptions`].filter(x => x.checked);
        const data = {
            type: activeTab,
            year: issuedByYear ? issuedByYear.value : '',
            startDate: issuedFrom.date ? issuedFrom.date.format('YYYY-MM-DD') : '',
            endDate: issuedTo.date ? issuedTo.date.format('YYYY-MM-DD') : '',
            productRating: !isProduct ? [] : ratingOptions.map(x => x.id),
            serviceRating: isProduct ? [] : ratingOptions.map(x => x.id),
            productCategoryId: !isProduct ? [] : categoryOptions.map(x => x.id),
            serviceCategoryId: isProduct ? [] : categoryOptions.map(x => x.id),
            certType: certType ? certType.value.toLowerCase() : '',
            certStatus: certStatus ? certStatus.value.toLowerCase() : '',
            memberStatus: membershipStatus
                ? membershipStatus.value
                    .toLowerCase()
                    .split(' ')
                    .join('')
                : '',
            isExport: isExport ? 1 : 2,
            page: !page ? 1 : page,
            itemsPerPage: pageSize,
            sorted,
        };
        return data;
    };
    // EVENT HANDLER
    onPeriodClick = e => {
        const id = e.target.id;
        if (id === 'By Year') this.resetDatesValue(); // RESET DATE RANGE INPUT
        if (id === 'By Date Range') this.resetYearValue(); // RESET YEAR INPUT
        // UPDATE
        const periodOptions = this.state.periodOptions.map(opt => {
            if (opt.value === id) {
                opt.checked = !opt.checked;
            } else {
                opt.checked = false;
            }
            return opt;
        });
        // SET STATE
        this.setState({
            periodOptions,
        });
    };
    onDateChange = (startDate, endDate) => {
        let issuedFromState = Object.assign({}, this.state.issuedFrom);
        let issuedToState = Object.assign({}, this.state.issuedTo);
        // UPDATE
        switch (this.state.focusedInput) {
            case 'startDate':
                issuedFromState.date = startDate;
                break;
            case 'endDate':
                issuedToState.date = endDate;
                break;
        }
        // SET STATE
        this.setState({
            issuedFrom: issuedFromState,
            issuedTo: issuedToState,
        });
    };
    onIssuedYearChange = issuedByYear => {
        this.setState({
            issuedByYear,
        });
    };
    onCategoryChange = e => {
        const key = e.target.id;
        let categoryOptionsClone = Array.from(this.state.categoryOptions);
        // UPDATE
        categoryOptionsClone = categoryOptionsClone.map(opt => {
            if (opt.category === key) opt.checked = !opt.checked;
            return opt;
        });
        // SET STATE
        this.setState({
            categoryOptions: categoryOptionsClone,
        });
    };
    onRatingChange = e => {
        const { activeTab } = this.props;
        const key = e.target.id;
        let options = Array.from(this.state[`${activeTab}RatingOptions`]);
        // UPDATE
        options = options.map(opt => {
            if (opt.value === key) opt.checked = !opt.checked;
            return opt;
        });
        // SET STATE
        const state = Object.assign({}, this.state);
        state[`${activeTab}RatingOptions`] = options;
        this.setState(state);
    };
    onSelectChange = (val, type) => {
        const state = Object.assign({}, this.state);
        state[type] = val;
        this.setState(state);
    };
    getData = async (page = 1, pageSize = 10, sorted = []) => {
        try {
            this.showLoading(true);
            const { isExport } = this.state;
            const { showModalIf } = this.props;
            const body = this.constructData(page, pageSize, sorted);
            const { data, totalNoOfItems, totalNoOfPages, ok } = await this.props.getDataList(body);

            // IF NO DATA EXIST
            showModalIf('Error', `Failed to ${isExport ? 'export' : 'get'} data`, !data && !ok);
            if (!data || data.length === 0) {
                this.setState({
                    tableList: [],
                    totalNum: 0,
                    totalPages: 0,
                    isOpen: true,
                });
                return;
            }

            // IF DATA EXIST
            const isDataEmpty = data.length === 0;
            // FOR EXPORT
            if (isExport) {
                exportCSVFile(data, 'dynamic-cert-export.csv');
                this.setState({ isOpen: false });
            }
            // FOR POPULATING TABLE
            if (!isDataEmpty && !isExport) {
                this.setState({
                    tableList: data,
                    totalNum: totalNoOfItems,
                    totalPages: totalNoOfPages,
                    isOpen: false,
                });
            }
            // ERROR HANDLING
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    };
    fireFetchData = () => {
        this.table.fireFetchData();
    };
    render() {
        const {
            loading,
            periodOptions,
            focusedInput,
            issuedFrom,
            issuedTo,
            issuedByYear,
            yearOptions,
            categoryOptions,
            certStatusOptions,
            certStatus,
            certTypeOptions,
            certType,
            membershipStatusOptions,
            membershipStatus,
            isOpen,
            tableList,
            totalNum,
            totalPages,
        } = this.state;
        const { activeTab, activeTabText } = this.props;
        const ratingOptions = this.state[`${activeTab}RatingOptions`];
        return (
            <React.Fragment>
                <div className="page-widget p-4">
                    <HeaderPanel isFilterOpen={isOpen} onClick={() => this.setState({ isOpen: !isOpen })} />
                    {isOpen ? <hr /> : null}
                    <Collapse isOpen={isOpen}>
                        <React.Fragment>
                            <InputPanelWrapper label="Period">
                                <Row>
                                    {periodOptions.map(({ value, checked }, index) => {
                                        return (
                                            <Col sm="6" key={index}>
                                                <Label check>
                                                    <Input
                                                        id={value}
                                                        type="radio"
                                                        value={value}
                                                        checked={checked}
                                                        onChange={this.onPeriodClick}
                                                    />
                                                    {value}
                                                </Label>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Row className="m-2">
                                    <Col sm="6" className="p-0">
                                        <DateRangePicker
                                            small
                                            showClearDates
                                            showDefaultInputIcon
                                            startDate={issuedFrom.date}
                                            startDateId={issuedFrom.id}
                                            endDate={issuedTo.date}
                                            endDateId={issuedTo.id}
                                            focusedInput={focusedInput}
                                            disabled={this.shouldPeriodOptionDisabled('By Date Range')}
                                            onDatesChange={({ startDate, endDate }) => this.onDateChange(startDate, endDate)}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            isOutsideRange={day => { }}
                                        />
                                    </Col>
                                    <Col sm="3">
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder="Please Select"
                                            value={issuedByYear}
                                            options={yearOptions}
                                            onChange={this.onIssuedYearChange}
                                            isDisabled={this.shouldPeriodOptionDisabled('By Year')}
                                        />
                                    </Col>
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label={`${activeTabText} Category`}>
                                <Row>
                                    {categoryOptions.map(({ category, checked }, index) => {
                                        return (
                                            <Col sm={4} key={index}>
                                                <FormGroup check>
                                                    <Input
                                                        id={category}
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={this.onCategoryChange}
                                                    />
                                                    {category}
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label="Rating">
                                <Row>
                                    {ratingOptions.map(({ id, checked, value }, index) => {
                                        return (
                                            <Col sm={2} key={index}>
                                                <FormGroup check>
                                                    <Input
                                                        id={value}
                                                        type="checkbox"
                                                        checked={checked || false}
                                                        onChange={this.onRatingChange}
                                                    />
                                                    {value}
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label="Certification Status">
                                <Row>
                                    <Col sm="3">
                                        <Select
                                            isClearable={true}
                                            placeholder="Please Select"
                                            value={certStatus}
                                            options={certStatusOptions}
                                            onChange={e => this.onSelectChange(e, 'certStatus')}
                                        />
                                    </Col>
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label="Certification Type">
                                <Row>
                                    <Col sm="3">
                                        <Select
                                            isClearable={true}
                                            placeholder="Please Select"
                                            value={certType}
                                            options={certTypeOptions}
                                            onChange={e => this.onSelectChange(e, 'certType')}
                                        />
                                    </Col>
                                </Row>
                            </InputPanelWrapper>
                            <InputPanelWrapper label="Membership Status">
                                <Row>
                                    <Col sm="3">
                                        <Select
                                            isClearable={true}
                                            placeholder="Please Select"
                                            value={membershipStatus}
                                            options={membershipStatusOptions}
                                            onChange={e => this.onSelectChange(e, 'membershipStatus')}
                                        />
                                    </Col>
                                </Row>
                            </InputPanelWrapper>
                            <Row>
                                <Col sm="12" className="text-right">
                                    <Button
                                        className="primary-btn-style mr-2"
                                        onClick={() => this.setState({ isExport: true }, () => this.table.fireFetchData())}
                                    >
                                        Export
                                    </Button>
                                    <Button
                                        className="primary-btn-style"
                                        onClick={() => this.setState({ isExport: false }, () => this.table.fireFetchData())}
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    </Collapse>
                </div>
                <TablePanel
                    activeTab={activeTab}
                    loading={loading}
                    data={tableList}
                    totalNum={totalNum}
                    totalPages={totalPages}
                    onFetchData={(state, instance) => this.getData(state.page, state.pageSize, state.sorted, state.filtered)}
                    instanceRef={instance => {
                        if (instance) this.table = instance.table;
                    }}
                />
            </React.Fragment>
        );
    }
}

const TablePanel = ({ activeTab, data, loading, totalNum, totalPages, instanceRef, onFetchData }) => {
    const printData = props => props.value || '-';
    const isProduct = activeTab === 'product';
    const columnStyle = {
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
    };
    const columns = [
        {
            Header: 'Company',
            accessor: 'companyName',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Membership Status',
            accessor: 'membershipStatus',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
        {
            Header: 'Cert Scheme',
            accessor: 'certScheme',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
        {
            Header: 'Cert Type',
            accessor: 'certType',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
        {
            Header: 'Cert No',
            accessor: 'certNumber',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
        {
            Header: 'Issued On',
            accessor: 'issuedOn',
            ...columnStyle,
            maxWidth: 100,
            Cell: props => {
                const date = props.value;
                return date ? moment(date).format('DD MMM YYYY') : '-';
            },
        },
        {
            Header: 'Expiry Date',
            accessor: 'eventDates',
            ...columnStyle,
            maxWidth: 100,
            Cell: props => {
                const date = props.value;
                return date ? moment(date).format('DD MMM YYYY') : '-';
            },
        },
        {
            Header: 'Brand',
            accessor: 'brand',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Models',
            accessor: 'models',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Product Category',
            accessor: 'category',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: 'Product sub-Category',
            accessor: 'subcategory',
            ...columnStyle,
            minWidth: 200,
            Cell: printData,
        },
        {
            Header: `${capitalizeFirstLetter(activeTab)} Type`,
            accessor: 'type',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
        {
            Header: `${isProduct ? 'Tick' : 'Level'} Rating`,
            accessor: 'rating',
            ...columnStyle,
            maxWidth: 100,
        },
        {
            Header: 'Cert Rep/Corporate Admin Name',
            accessor: 'repName',
            ...columnStyle,
            minWidth: 300,
            Cell: printData,
        },
        {
            Header: 'Cert Rep/Corporate Admin Email',
            accessor: 'repEmail',
            ...columnStyle,
            minWidth: 300,
            Cell: printData,
        },
        {
            Header: 'Low VOC',
            accessor: 'low_voc',
            ...columnStyle,
            minWidth: 200,
            Cell: props => {
                const lowVoc = props.value;
                return lowVoc && lowVoc > 0 ? "Yes" : 'No';
            },
        },
        {
            Header: 'Status',
            accessor: 'renewalStatus',
            ...columnStyle,
            maxWidth: 100,
            Cell: printData,
        },
    ];
    return (
        <div className={'page-widget'}>
            <CyderReactTable
                manual
                showPagination
                data={data}
                loading={loading}
                totalNum={totalNum}
                pages={totalPages}
                minRows={0}
                columns={columns}
                onFetchData={onFetchData}
                ref={instanceRef}
            />
        </div>
    );
};

const HeaderPanel = ({ isFilterOpen, onClick }) => {
    const shouldExpand = isFilterOpen ? 'collapse' : 'expand';
    const icon = isFilterOpen ? 'close' : 'filter_list';
    const style = {
        color: 'black',
        fontSize: '20px',
    };
    return (
        <Row style={{ cursor: 'pointer' }} onClick={onClick}>
            <Col>
                <p className="field-header">Dynamic Certificate Export</p>
                <small>* Click here to {shouldExpand} filters</small>
            </Col>
            <Col className="text-right">
                <i className="material-icons" style={style}>
                    {icon}
                </i>
            </Col>
        </Row>
    );
};

const InputPanelWrapper = ({ label, children }) => {
    return (
        <Row className="m-4 p-1">
            <Col sm="2">
                <p className="text-bold text-capitalize">{label + ': '}</p>
            </Col>
            <Col>{children}</Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCategoryList: type => {
            return dispatch(getCategoryList(type));
        },
        getDataList: body => {
            return dispatch(getDataList(body));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(DynamicExportPanel),
);
