import {
    DINNER_EVENT_ALL_GET_EVENTS_REQUEST,
    DINNER_EVENT_ALL_GET_EVENTS_SUCCESS,
    DINNER_EVENT_ALL_GET_EVENTS_FAILURE,
    DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE,
    FETCH_TABLE_COUNT,
} from '../../../../actions/constants/events/actionTypesStaffEvents';

import moment from 'moment';

// defaults
var defaultState = {
    allApplications: [],
    applications: [],
    tablePages: -1,
    loading: false,
    keyword: '',
};
export function eventsDinnerAllReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case DINNER_EVENT_ALL_GET_EVENTS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case DINNER_EVENT_ALL_GET_EVENTS_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data.map(event => {
                event.eventDates = moment(event.eventDates).format('D MMM YYYY');
                return event;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.selectedAll = false;
            return newState;
        case DINNER_EVENT_ALL_GET_EVENTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        case DINNER_EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE:
            newState.keyword = action.keyword;
            return newState;
        case FETCH_TABLE_COUNT:
            return { ...state, tableCount: action.payload[0] };
        default:
            return state;
    }
}
