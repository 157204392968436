import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import DashboardWidget from 'cyder/widget/DashboardWidget';

class CertificationCrossAnalysis extends React.Component {
    render() {
        return (
            <DashboardWidget
                title="Certification Cross-Analysis"
                description=""
            >
                <Row>
                    <Col>
                        <div className="mb-3">
                            <h5 className="mb-1 text-warning font-weight-bold">
                                {
                                    this.props.crossAnalysis
                                        .certificationMembersPercentage
                                }
                            </h5>
                            {
                                this.props.crossAnalysis
                                    .certificationMembersNumber
                            }{' '}
                            certification customers are members
                        </div>
                        <div className="mb-3">
                            <h5 className="mb-1 text-success font-weight-bold">
                                {
                                    this.props.crossAnalysis
                                        .activeMembersPercentage
                                }
                            </h5>
                            {this.props.crossAnalysis.activeMembersNumber}{' '}
                            members have active certificates
                        </div>
                    </Col>
                </Row>
            </DashboardWidget>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        crossAnalysis: state.staffMembershipStatistics.dashboard.crossAnalysis,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CertificationCrossAnalysis);
