// Export and download fie as csv
export const exportCSVFile = (objArray, filename) => {
    // Add header
    objArray.unshift(getHeaders(objArray));
    var csv = convertObjArrayToCSV(objArray);
    var exportedFilename = filename || 'export.csv';
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename);
    } else {
        var link = document.createElement('a');
        // Determine if browsers support HTML5 download attribute
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.getElementById('root').appendChild(link); // Required by Firefox; Chrome support with or without
            link.click();
        }
    }
};
// Get headers object
const getHeaders = objArray => {
    let headersObj = {};
    for (let index in objArray[0]) {
        headersObj[index] = index;
    }
    return headersObj;
};
// Convert object array to csv
const convertObjArrayToCSV = objArray => {
    let str = '';
    objArray.forEach(obj => {
        let line = '';
        for (let index in obj) {
            if (line != '') line += ',';
            line += dataCleaning(obj[index]);
        }
        str += line + '\r\n';
    });
    return str;
};
// Data cleaning
const dataCleaning = value => {
    if (typeof value === 'string' || typeof value === 'number') return '"' + value + '"';
    if (value === '' || value === null || value === undefined) return ' ';
    return value;
};
