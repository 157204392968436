import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import history from '../../../../../../history';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Row, Col, Form, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import Select from 'react-select';
import { ButtonComponent, InputField, selectStyle } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';

import {
    save,
    update,
    remove,
    load,
    getAllServiceCategories,
    getXeroInventoryCodesByPrefix,
    findServiceCategoryInfo,
} from 'actions/staff/certification/edit-on-behalf/action.js';

import { getAuditList } from 'actions/misc/auditAction';

import { sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

import 'css/forms/default-forms.css';

export const selectStyleForText = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
        width: '200px',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};
class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.back = this.back.bind(this);
        this.productPictureChange = this.productPictureChange.bind(this);
        this.handleInputCategoryChange = this.handleInputCategoryChange.bind(this);
        this.productCatalogueChange = this.productCatalogueChange.bind(this);

        this.greenRemarksChange = this.greenRemarksChange.bind(this);

        this.isSelfFinancingCapableChange = this.isSelfFinancingCapableChange.bind(this);
        this.onManufacturerContactPersonNameChange = this.onManufacturerContactPersonNameChange.bind(this);
        this.onManufacturerTelephoneChange = this.onManufacturerTelephoneChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            data: {
                productPictureFilename: '',
                productPicture: '',
                productCatalogueFilename: '',
                manufacturerContactPersonName: '',
                manufacturerTelephone: '',
                productCatalogue: '',
                service: '',
                greenRemarks: '',
                isSelfFinancingCapable: '',
                serviceCategoryId: '',
                id: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace(`/staff/certification/applications/viewservice/${this.props.match.params.id}`);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(loading) {
        if (!this._isMounted) return;
        this.setState({
            loading,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    async componentDidMount() {
        const { getAllServiceCategories, findServiceCategoryInfo, load, getAuditList } = this.props;
        try {
            this.showLoading(true);
            const id = this.props.match.params.id;
            if (id && id > 0) {
                var { data } = await load(id);
                const allCategories = await getAllServiceCategories();
                const serviceCategoryInfo = await findServiceCategoryInfo(data.serviceCategoryId);
                if (serviceCategoryInfo && serviceCategoryInfo.data) {
                    data.serviceCategoryId = serviceCategoryInfo.data.id;
                    data.serviceCategoryName = serviceCategoryInfo.data.category;
                }
                data = sanitizeStringAndObjectNullsToEmptyStrings(data);
                let auditInfo = await getAuditList(id);
                this.setState({
                    data,
                    categories: allCategories.data,
                    auditInfo,
                });
            } else {
                this.setState({ data: this.state.data });
            }
        } catch (e) {
            throw e;
        } finally {
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.type = 'service';
        return newData;
    }
    save() {
        const id = this.props.match.params.id;
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        this.props
            .update(data)
            .then(res => {
                this.showLoading(false);
                if (res.error) {
                    this.showModal('Error', res.message);
                } else {
                    history.replace(`/staff/certification/applications/viewservice/${id}`, 'update', true, true);
                }
            })
            .catch(error => {
                this.showLoading(false);
                this.showModal('Error', error.message, 'update', true, true);
            });
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this application?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/staff/certification/applications');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }

    handleInputCategoryChange(option) {
        var data = this.state.data;
        data.serviceCategoryId = option.id;
        data.serviceCategoryName = option.category;
        this.setState({ data });
    }

    productPictureChange(resourceId, filename) {
        var data = this.state.data;
        data.productPicture = resourceId;
        data.productPictureFilename = filename;
        this.setState({ data });
    }

    productCatalogueChange(resourceId, filename) {
        var data = this.state.data;
        data.productCatalogue = resourceId;
        data.productCatalogueFilename = filename;
        this.setState({ data });
    }

    greenRemarksChange(e) {
        var data = this.state.data;
        data.greenRemarks = e.target.value;
        this.setState({ data });
    }

    isSelfFinancingCapableChange(e) {
        var data = this.state.data;
        data.isSelfFinancingCapable = e.target.checked ? 1 : 0;
        this.setState({ data });
    }
    onManufacturerContactPersonNameChange(e) {
        var data = this.state.data;
        data.manufacturerContactPersonName = e.target.value;
        this.setState({ data });
    }
    onManufacturerTelephoneChange(e) {
        var data = this.state.data;
        data.manufacturerTelephone = e.target.value;
        this.setState({ data });
    }

    serviceCategoryIdChange(e) {
        var data = this.state.data;
        data.serviceCategoryId = e.target.value;
        this.setState({ data });
    }

    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Row className="mb-2">
                    <Form>
                        <Col xs={5}>
                            <Button className="ml-auto primary-btn-style-outline" onClick={this.back}>
                                <i className="mr-1 material-icons align-text-bottom ">keyboard_arrow_left</i>
                                Back
                            </Button>
                            {/* <i onClick={this.back} style={{ cursor: 'pointer' }} className={`fa fa-2x fa-angle-left`} />
                            <div
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                }}
                                onClick={this.back}
                            >
                                Back
                            </div> */}
                        </Col>
                    </Form>
                </Row>
                <hr />
                <Row>
                    <Col xs={3}>
                        <label> ID : </label> {this.state.data.id}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={4}>
                        <InputField
                            label="Main Contact Person"
                            styles={{ selectStyleForText }}
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerContactPersonName',
                                name: 'manufacturerContactPersonName',
                                placeholder: 'manufacturerContactPersonName',
                                onChange: this.onManufacturerContactPersonNameChange,
                                value: this.state.data.manufacturerContactPersonName,
                            }}
                        />
                    </Col>
                    <Col xs={3} style={{ padding: '0px', marginLeft: '10px' }}>
                        <InputField
                            label="Main Contact Phone"
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerTelephone',
                                name: 'manufacturerTelephone',
                                placeholder: 'manufacturerTelephone',
                                onChange: this.onManufacturerTelephoneChange,
                                value: this.state.data.manufacturerTelephone,
                            }}
                        />
                    </Col>
                    {this.state.auditInfo && this.state.auditInfo.data && this.state.auditInfo.data.length > 0 && (
                        <Col xs={4} style={{ padding: '0px', marginLeft: '10px' }}>
                            <InputField
                                label="Main Contact Email"
                                disabled
                                inputProps={{
                                    type: 'text',
                                    value: this.state.auditInfo.data[this.state.auditInfo.data.length - 1].email,
                                }}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={4}>
                        <InputField label="Services Category">
                            <Select
                                autofocus
                                name="categories"
                                styles={selectStyle}
                                options={this.state.categories}
                                onChange={this.handleInputCategoryChange}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.category}
                                value={{
                                    id: this.state.data.serviceCategoryId,
                                    category: this.state.data.serviceCategoryName,
                                }}
                            />
                            {/* <Input
                            type="text"
                            name="serviceCategoryId"
                            id="serviceCategoryId"
                            value={this.state.data.serviceCategoryId}
                            placeholder="serviceCategoryId"
                            onChange={this.serviceCategoryIdChange}
                        /> */}
                        </InputField>
                    </Col>
                </Row>

                {(this.state.data.serviceCategoryId === 2 || this.state.data.serviceCategoryId === 10) && (
                    <Row>
                        <Col>
                            <InputField
                                label=" Are you EPC self-financing capable?"
                                inputProps={{
                                    type: 'checkbox',
                                    id: 'isSelfFinancingCapable',
                                    style: { marginLeft: '0px' },
                                    onChange: this.isSelfFinancingCapableChange,
                                    checked: this.state.data.isSelfFinancingCapable === 1,
                                }}
                            />
                        </Col>
                    </Row>
                )}

                {/* <Input
                        type="text"
                        name="isSelfFinancingCapable"
                        id="isSelfFinancingCapable"
                        value={this.state.data.isSelfFinancingCapable}
                        placeholder="isSelfFinancingCapable"
                        onChange={this.isSelfFinancingCapableChange}
                    /> */}
                {/*
                <Row>
                    <Col xs={5}>
                        <InputField
                            label="Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy saving properties, recyclability, usage of recycled content, etc.)."
                            inputProps={{
                                rows: 5,
                                type: 'textarea',
                                id: 'greenRemarks',
                                name: 'greenRemarks',
                                placeholder: 'greenRemarks',
                                value: this.state.data.greenRemarks,
                                onChange: this.greenRemarksChange,
                            }}
                        />
                    </Col>
                </Row>
                */}
                {/* <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="service">service</Label>
                                <Input
                                    type="text"
                                    name="service"
                                    id="service"
                                    value={this.state.data.service}
                                    placeholder="service"
                                    onChange={this.serviceChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                {/*
                <Row>
                    <InputField label="Services Catalogue">
                        <CyderFileUpload
                            attachmentId={this.state.data.productCatalogue}
                            filename={this.state.data.productCatalogueFilename}
                            onUploadSuccess={file => {
                                this.productCatalogueChange(file.id, file.filename);
                            }}
                            onUploadFail={e => {
                                throw e;
                            }}
                        />
                    </InputField>
                </Row>
                */}
                {/* <InputField label="Product Picture">
                     <CyderFileUpload
                        attachmentId={this.state.data.productPicture}
                        filename={this.state.data.productPictureFilename}
                        onUploadSuccess={({ id, filename }) => this.productPictureChange(id, filename)}
                        onUploadFail={e => {
                            throw e;
                        }}
                    />
                </InputField> */}
                <hr />
                <FlexRow between="xs" style={{ marginBottom: '20px' }}>
                    {this.state.data.id > 0 && (
                        <ButtonComponent
                            label="Delete"
                            className="text-capitalize primary-btn-style-outline"
                            onClick={this.removeConfirm}
                        />
                    )}
                    <ButtonComponent
                        label="Save"
                        className="primary-btn-style"
                        colClassName="d-flex justify-content-end"
                        onClick={this.save}
                    />
                </FlexRow>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getAllServiceCategories: () => {
            return dispatch(getAllServiceCategories());
        },
        getXeroInventoryCodesByPrefix: () => {
            return dispatch(getXeroInventoryCodesByPrefix('CS'));
        },
        findServiceCategoryInfo: serviceCategoryId => {
            return dispatch(findServiceCategoryInfo(serviceCategoryId));
        },
        getAuditList: id => {
            return dispatch(getAuditList(49, id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
