export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const FETCH_PRODUCT_SUBCATEGORY = 'FETCH_PRODUCT_SUBCATEGORY';
export const FETCH_SINGLE_SUBCATEGORY = 'FETCH_SINGLE_SUBCATEGORY';
export const FETCH_BCA_FUNCTIONAL_SYSTEM = 'FETCH_BCA_FUNCTIONAL_SYSTEM';
export const FETCH_GREEN_ATTRIBUTES = 'FETCH_GREEN_ATTRIBUTES';
export const FETCH_BUILDING_PROJECT_TYPES = 'FETCH_BUILDING_PROJECT_TYPES';
export const LOADING_PUBLIC = 'LOADING_PUBLIC';
export const UN_LOADING_PUBLIC = 'UN_LOADING_PUBLIC';
export const LOADING_PUBLIC_PRODUCT_CATEGORY = 'LOADING_PUBLIC_PRODUCT_CATEGORY';
export const LOADING_PUBLIC_PRODUCT_SUB_CATEGORY = 'LOADING_PUBLIC_PRODUCT_SUB_CATEGORY';
export const LOADING_ALL_PRODUCT_TYPES = 'LOADING_ALL_PRODUCT_TYPES';
export const LOADING_SINGLE_SUBCATEGORY = 'LOADING_SINGLE_SUBCATEGORY';
export const LOADING_SPECIFIC_PRODUCT_TYPES = 'LOADING_SPECIFIC_PRODUCT_TYPES';
export const FETCH_ALL_FILTER_OPTIONS = 'FETCH_ALL_FILTER_OPTIONS';
export const FETCH_SPECIFIC_PRODUCT_TYPES = 'FETCH_SPECIFIC_PRODUCT_TYPES';
export const FETCH_ALL_PRODUCT_TYPES = 'FETCH_ALL_PRODUCT_TYPES';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_COMPANY_INFO = 'FETCH_COMPANY_INFO';
export const FETCH_CERTIFICATE_INFO = 'FETCH_CERTIFICATE_INFO';
export const FETCH_CERTIFICATE_INFO_TAGGING = 'FETCH_CERTIFICATE_INFO_TAGGING';
export const FETCH_RATINGS = 'FETCH_RATINGS';
export const FETCH_FILTERING_LABELS = 'FETCH_FILTERING_LABELS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const FETCH_RATING_IN_SUBCATEGORY = 'FETCH_RATING_IN_SUBCATEGORY';
export const FETCH_RATING = 'FETCH_RATING';
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_PRODUCT_TAGGINGS = 'FETCH_PRODUCT_TAGGINGS';
export const LOADING_LOWVOC_PRODUCT_TYPES = 'LOADING_LOWVOC_PRODUCT_TYPES';
export const FETCH_LOWVOC_PRODUCT_TYPES = 'FETCH_LOWVOC_PRODUCT_TYPES';

// Services
export const FETCH_SERVICE_CATEGORIES = 'FETCH_SERVICE_CATEGORIES';
export const FILTER_SERVICES = 'FILTER_SERVICES';
export const SEARCH_SERVICES = 'SEARCH_SERVICES';
export const FETCH_SERVICE_COMPANY_INFO = 'FETCH_SERVICE_COMPANY_INFO';
