import React from 'react';
import { connect } from 'react-redux';

import '../../../css/cyder/media/icon.css';
import '../../../css/icons/material-design-icons.css';
import '../../../css/icons/font-awesome.css';

class Certificates extends React.Component {
    render() {
        return (
            <div className="user-widget-1">
                <div className="user-data">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <p className="name">
                                        <i className="material-icons material-icons-3x vertical-center-aligned-icon-success">
                                            done
                                        </i>
                                        {this.props.certificates.valid} Valid
                                        Certificates
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="name">
                                        <i className="material-icons material-icons-3x vertical-center-aligned-icon-info">
                                            grade
                                        </i>
                                        {
                                            this.props.certificates
                                                .certifiedProducts
                                        }{' '}
                                        Certified Products
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <p className="name">
                                        <i className="material-icons material-icons-3x vertical-center-aligned-icon-warning">
                                            bookmark
                                        </i>
                                        {this.props.certificates.sgbp} SGBG
                                    </p>
                                </div>
                                <div className="col">
                                    <p className="name">
                                        <i className="material-icons material-icons-3x vertical-center-aligned-icon-warning">
                                            bookmark
                                        </i>
                                        {this.props.certificates.sgbs} SGBS
                                    </p>
                                </div>
                            </div>
                            <p className="name">
                                <i className="material-icons material-icons-3x vertical-center-aligned-icon-danger">
                                    airplanemode_inactive
                                </i>
                                {this.props.certificates.expired6Months} Expired
                                in 6 Months
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        certificates: state.staff.dashboardInfo.certificates,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Certificates);
