import {
    MEMBER_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS,
    MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
    MEMBER_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
    MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
} from '../../../actions/constants/actionTypesMemberDirectory';
import moment from 'moment';

// Defaults Params
const defaultState = {
    loading: false,
    directory: [],
    allDirectory: [],
    tablePages: -1,
};

export function memberDirectoryReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS:
            newState.directory = action.response.data;
            newState.tablePages = action.response.message.totalNoOfPages;
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS:
            newState.allDirectory = action.response.data;
            // Map date to display format
            const mapDate = date => moment(date).format('MMM D, YYYY');
            newState.allDirectory = action.response.data.map(directory => {
                directory.dateJoined = mapDate(directory.dateJoined);
                directory.expiryDate = mapDate(directory.expiryDate);
                return directory;
            });
            return newState;
        default:
            return state;
    }
}
