import {
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION,
    MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE,
    MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA,
    MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING,
    MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE,
    MEMBERSHIP_APPLICATION_PAYMENT_REQUEST,
    MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS,
    MEMBERSHIP_APPLICATION_PAYMENT_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS,
    MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE,
    MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_FAILURE,
    MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_SUCCESS,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS,
    MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE,
    MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS,
    MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE,
} from '../../../../actions/constants/actionTypesStaffMembershipApplications';

import validationlib from 'js/validation';
import { sanitizeObjectNullsToEmptyStrings } from 'js/util';
import { setReducerModalState, defaultModalKeys } from 'js/modal';

const defaultState = {
    application: {},
    errorMessage: null,
    forceLoading: false,
    ...defaultModalKeys,
    paymentFields: {
        amountReceived: '',
        datePaymentReceived: '',
        modeOfPaymentId: '',
        invoiceNumber: '',
        bank: '',
        referenceNumber: '',
        remarks: '',
        membershipStartDate: '',
        membershipEndDate: '',
    },
    validation: {},
    modeOfPayments: [],
    loadingPayment: false,
    saveButtonPressed: false,
};

export function staffMembershipApplicationsDetailsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Get - application details
        case MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST:
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS:
            newState.application = action.response.data;
            newState.application.status = action.response.data.status.toLowerCase();
            // Get menship period
            const membershipPeriod = newState.calculateMembershipPeriod(newState.application);
            // Set on application
            newState.application.membershipEndDate = membershipPeriod.endDate;
            newState.application.membershipStartDate = membershipPeriod.startDate;
            if (newState.application.membershipType) {
                if (newState.application.membershipType === 'founding') {
                    newState.application.membershipType = {
                        label: 'Founding Member',
                        value: 'founding',
                    };
                }
                if (newState.application.membershipType === 'corporate') {
                    newState.application.membershipType = {
                        label: 'Corporate Member',
                        value: 'corporate',
                    };
                }
                if (newState.application.membershipType === 'established') {
                    newState.application.membershipType = {
                        label: 'Established Member',
                        value: 'established',
                    };
                }
            } else {
                newState.application.membershipType = null;
            }
            // Set on payment fields
            newState.paymentFields.membershipEndDate = membershipPeriod.endDate;
            newState.paymentFields.membershipStartDate = membershipPeriod.startDate;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST:
            newState.modalLoading = true;
            return newState;
        // Generate - invoice
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = action.error.message;
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Receipt
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Receipt successfully sent to Applicant.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to send receipt.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Approve application
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Membership Application successfully approved.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to approve membership application.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Process application
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Successfully started membership application process.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to start membership application process.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Reject
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Membership Application successfully rejected.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to reject membership application.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Email sending in progress';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Unable to Send Email';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
        case MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Membership application submitted on behalf successfully.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to submit membership application on behalf';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Send Reject Email
        case MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Reject email successfully sent.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to complete send reject email .';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Send Receipt Email
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Membership application receipt email successfully sent.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to complete send membership application receipt email.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Complete
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Membership application marked completed successfully.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to complete membership application.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Resubmit Questionaires
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Questionnaire responses successfully resubmitted.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE:
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to resubmit questionaires.';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            return newState;
        // Mark as paid
        case MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Get - payment info
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST:
            newState.modalLoading = true;
            newState.modalHeader = 'Invoice is being sent';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Invoice sent successfully';
            newState.modalHeader = 'Confirmation';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalHeader = 'Error';
            newState.modalAction = 'forcePageUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Get - payment info

        case MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST:
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS:
            if (action.response.data.length > 0)
                newState.paymentFields = Object.assign(
                    {},
                    defaultState.paymentFields,
                    sanitizeObjectNullsToEmptyStrings(action.response.data[0]),
                );
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE:
            return newState;
        case MEMBERSHIP_APPLICATION_PAYMENT_REQUEST:
            return newState;
        case MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS:
            return newState;
        case MEMBERSHIP_APPLICATION_PAYMENT_FAILURE:
            return null;
        // Others
        case MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD:
            newState.paymentFields = Object.assign({}, state.paymentFields);
            newState.paymentFields[action.key] = action.value;
            if (action.key === 'membershipStartDate' || action.key === 'membershipEndDate' || action.key === 'membershipType') {
                newState.application[action.key] = action.value;
            }
            // Validation
            newState.validation = Object.assign({}, state.validation);
            newState.validation[action.key] = validationlib.validate(action.key, action.value);
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING:
            newState.forceLoading = action.loadingState;
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE:
            newState = Object.assign({}, defaultState);
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE:
            newState.saveButtonPressed = true;
            newState.validation = Object.assign({}, state.validation);
            const fkeys = Object.keys(state.paymentFields);
            for (let i = 0; i < fkeys.length; i += 1) {
                newState.validation[fkeys[i]] = validationlib.validate(fkeys[i], state.paymentFields[fkeys[i]]);
            }
            return newState;
        case MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION:
            newState.calculateMembershipPeriod = action.func;
            return newState;
        default:
            return state;
    }
}
