import {
    EVENT_GET_EVENT_TICKETS_REQUEST,
    EVENT_UPDATE_TICKET_REQUEST,
    EVENT_UPDATE_TICKET_SUCCESS,
    EVENT_UPDATE_TICKET_FAILURE,
    EVENT_TICKET_SET_MODAL_DATA,
    FETCH_MEAL_TYPE,
    FETCH_TICKETS,
    FETCH_TABLE_COUNT,
    FETCH_TICKET_TYPE_REQUEST,
    FETCH_TICKET_TYPE_SUCCESS,
    FETCH_TICKET_TYPE_FAILURE,
    FETCH_TABLE_NAME_REQUEST,
    FETCH_TABLE_NAME_SUCCESS,
    FETCH_TABLE_NAME_FAILURE,
    FETCH_TABLE_REGISTRANT_REQUEST,
    FETCH_TABLE_REGISTRANT_SUCCESS,
    FETCH_TABLE_REGISTRANT_FAILURE,
} from 'actions/constants/events/actionTypesStaffEventsTicket';

import { setReducerModalState } from 'js/modal';

// defaults
const defaultState = {
    loading: false,
    error: false,
};

export function eventsTicketReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case EVENT_UPDATE_TICKET_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_UPDATE_TICKET_SUCCESS:
            newState = Object.assign({}, state);
            newState.loading = false;
            newState.error = false;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './all';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event tickets have been successfully saved';
            return newState;
        case EVENT_UPDATE_TICKET_FAILURE:
            newState.loading = false;
            newState.error = true;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './' + newState.fields.eventId;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to save event tickets. Please try again.';
            return newState;
        case FETCH_TICKET_TYPE_REQUEST:
            newState.loading = true;
            return newState;
        case FETCH_TICKET_TYPE_SUCCESS:
            newState.loading = false;
            newState.error = false;
            var response = action.response.data[0];
            var platinumTables = [];
            var goldTables = [];
            var standardTables = [];
            var vipTables = [];
            var allTables = [];
            for (var i = 0; i < response.vip; i++) {
                var obj = {
                    occupied: false,
                    tableId: `vip-${i}`,
                    actualTableNo: i + 1,
                    tableNo: i + 1,
                    tableType: 'VIP',
                };
                vipTables.push(obj);
                allTables.push(obj);
            }

            for (var i = 0; i < response.platinum; i++) {
                var obj = {
                    occupied: false,
                    tableId: `platinum-${i}`,
                    actualTableNo: i + 1 + response.vip,
                    tableNo: i + 1,
                    tableType: 'Platinum',
                };
                platinumTables.push(obj);
                allTables.push(obj);
            }

            for (var i = 0; i < response.gold; i++) {
                var obj = {
                    occupied: false,
                    tableId: `gold-${i}`,
                    actualTableNo: i + 1 + response.vip + response.platinum,
                    tableNo: i + 1,
                    tableType: 'Gold',
                };
                goldTables.push(obj);
                allTables.push(obj);
            }

            for (var i = 0; i < response.standard; i++) {
                var obj = {
                    occupied: false,
                    tableId: `standard-${i}`,
                    actualTableNo: i + 1 + response.vip + response.platinum + response.gold,
                    tableNo: i + 1,
                    tableType: 'Standard',
                };
                standardTables.push(obj);
                allTables.push(obj);
            }
            newState.vipTables = vipTables;
            newState.platinumTables = platinumTables;
            newState.goldTables = goldTables;
            newState.standardTables = standardTables;
            newState.allTables = allTables;
            return newState;
        case FETCH_TABLE_NAME_REQUEST:
            newState.loading = true;
            return newState;
        case FETCH_TABLE_NAME_SUCCESS:
            newState.loading = false;
            newState.error = false;
            var response = action.response.data;
            var platinumTables = newState.platinumTables;
            var goldTables = newState.goldTables;
            var standardTables = newState.standardTables;
            var vipTables = newState.vipTables;
            var allTables = [];

            var vipTables = vipTables.map((obj, index) => {
                let object = obj;
                var number = parseInt(1) + parseInt(index);
                let tableName = obj.tableType + ' Table ' + number;
                response.map(resObj => {
                    tableName = obj.tableNo === resObj.tableNo && obj.tableType === resObj.tableType ? resObj.tableName : tableName;
                });
                object.tableName = tableName;
                object.registrants = [];
                return object;
            });

            var platinumTables = platinumTables.map((obj, index) => {
                let object = obj;
                var number = parseInt(1) + parseInt(index);
                let tableName = obj.tableType + ' Table ' + number;
                response.map(resObj => {
                    tableName = obj.tableNo === resObj.tableNo && obj.tableType === resObj.tableType ? resObj.tableName : tableName;
                });
                object.tableName = tableName;
                object.registrants = [];
                return object;
            });

            var goldTables = goldTables.map((obj, index) => {
                let object = obj;
                var number = parseInt(1) + parseInt(index);
                let tableName = obj.tableType + ' Table ' + number;
                response.map(resObj => {
                    tableName = obj.tableNo === resObj.tableNo && obj.tableType === resObj.tableType ? resObj.tableName : tableName;
                });
                object.tableName = tableName;
                object.registrants = [];
                return object;
            });

            var standardTables = standardTables.map((obj, index) => {
                let object = obj;
                var number = parseInt(1) + parseInt(index);
                let tableName = obj.tableType + ' Table ' + number;
                response.map(resObj => {
                    tableName = obj.tableNo === resObj.tableNo && obj.tableType === resObj.tableType ? resObj.tableName : tableName;
                });
                object.tableName = tableName;
                object.registrants = [];
                return object;
            });
            allTables = [...vipTables, ...platinumTables, ...goldTables, ...standardTables];
            newState.vipTables = vipTables;
            newState.platinumTables = platinumTables;
            newState.goldTables = goldTables;
            newState.standardTables = standardTables;
            newState.allTables = allTables;
            return newState;
        case FETCH_TABLE_NAME_FAILURE:
            newState.loading = false;
            newState.error = true;
            return newState;
        case FETCH_TABLE_REGISTRANT_REQUEST:
            newState.loading = true;
            return newState;
        case FETCH_TABLE_REGISTRANT_SUCCESS:
            newState.loading = false;
            newState.error = false;
            let registrants = action.response.data;
            var platinumTables = newState.platinumTables;
            var goldTables = newState.goldTables;
            var standardTables = newState.standardTables;
            var vipTables = newState.vipTables;
            var allTables = [];

            registrants.forEach(registration => {
                var tableNo = parseInt(registration.tableNo) - 1;
                var tableType = registration.tableType;
                if (tableType === 'VIP') {
                    vipTables[tableNo].occupied = true;
                    vipTables[tableNo].registrants.push(registration);
                } else if (tableType === 'Platinum') {
                    platinumTables[tableNo].occupied = true;
                    platinumTables[tableNo].registrants.push(registration);
                } else if (tableType === 'Gold') {
                    goldTables[tableNo].occupied = true;
                    goldTables[tableNo].registrants.push(registration);
                } else if (tableType === 'Standard') {
                    standardTables[tableNo].occupied = true;
                    standardTables[tableNo].registrants.push(registration);
                }
            });

            allTables = [...vipTables, ...platinumTables, ...goldTables, ...standardTables];
            newState.vipTables = vipTables;
            newState.platinumTables = platinumTables;
            newState.goldTables = goldTables;
            newState.standardTables = standardTables;
            newState.allTables = allTables;
            newState.registrants = action.response.data;
            return newState;
        case FETCH_TABLE_REGISTRANT_FAILURE:
            newState.loading = false;
            newState.error = true;
            return newState;
        case FETCH_TICKET_TYPE_FAILURE:
            newState.loading = false;
            newState.error = true;
            return newState;
        case EVENT_TICKET_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case FETCH_MEAL_TYPE:
            return { ...state, mealType: action.payload };
        case FETCH_TICKETS:
            return { ...state, tickets: action.payload[0] };
        case FETCH_TABLE_COUNT:
            return { ...state, tableCount: action.payload[0] };
        default:
            return state;
    }
}
