import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import moment from 'moment';

import { Row, Col, Button, Container, Card, CardBody } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import PaymentChoicesPanel from 'pages/events/payment/components/PaymentChoicesPanel';
import CyderHeaderPanel from 'cyder/panels/CyderHeaderPanel';
import CreditCardPaymentInfoPanel from 'pages/shared/CreditCardPaymentInfoPanel';
import { getApplicationPaymentInfo, updatePayment } from 'actions/staff/membership/applications/staffMembershipApplicationsDetailsAction';

const paymentModes = [
    {
        label: 'Cheque',
        value: 1,
    },
    {
        label: 'Wire or Bank Transfer',
        value: 2,
    },
    {
        label: 'Corporate PayNow',
        value: 5,
    },
];

class StaffMembershipApplicationsUpdatePaymentPage extends React.Component {
    constructor() {
        super();
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.showLoadingPaymentMode = this.showLoadingPaymentMode.bind(this);
        this.showLoadingPaymentInfo = this.showLoadingPaymentInfo.bind(this);

        this.onDateChanged = this.onDateChanged.bind(this);

        this.determineSavable = this.determineSavable.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.submit = this.submit.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            loadingSubmit: false,
            loadingPaymentMode: false,
            loadingPaymentInfo: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            paymentInfo: {},
        };
    }
    onDateChanged(e, field, fieldCtrl) {
        try {
            var paymentInfo = Object.assign({}, this.state.paymentInfo);
            paymentInfo[fieldCtrl] = e;
            paymentInfo[field] = e.format('YYYY-MM-DD');
            paymentInfo.datePaymentReceived = e;
            paymentInfo.receivedTimestamp = e.format('YYYY-MM-DD hh:mm:ss');
            this.setState({
                paymentInfo,
            });
        } catch (error) {
            //IGNORE
        }
    }
    onTextChanged(value, field) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo[field] = value;
        this.setState({
            paymentInfo,
        });
    }
    determineSavable() {
        var hasEmpty = false;
        const paymentInfo = this.state.paymentInfo;
        // IF NOT ALL INPUT FILLED
        const totalInputs = document.querySelectorAll('input').length;
        if (Object.keys(paymentInfo).length <= totalInputs) {
            return true;
        }
        if (!paymentInfo.amountReceived) {
            hasEmpty = true;
        } else if (!paymentInfo.bank) {
            hasEmpty = true;
        } else if (!paymentInfo.referenceNumber) {
            hasEmpty = true;
        } else if (!paymentInfo.datePaymentReceived) {
            hasEmpty = true;
        }
        // IF INVALID VALUE IS FOUND
        // for (let key in paymentInfo) {
        //     // Exclude remarks key
        //     if (
        //         key != 'remarks' &&
        //         key != 'paymentMode' &&
        //         key != 'paymentStatus' &&
        //         key != 'xeroPaymentId' &&
        //         key != 'xeroSyncDate' &&
        //         key != 'xeroPaymentId'
        //     ) {
        //         if (!paymentInfo[key]) {
        //             hasEmpty = true;
        //             break;
        //         }
        //     }
        // }
        return hasEmpty;
    }
    modalAction() {
        this.modalToggle();
        history.push(`/staff/membership/applications/${this.props.match.params.applicationId}`);
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }

    submit() {
        this.props
            .updatePayment(this.state.paymentInfo)
            .then(res => {
                if (res.status == 500) {
                    this.showModal('error', 'Failed to update payment details.', 'update', false, true);
                } else {
                    this.showModal('Confirmation', 'Payment details successfully updated.', 'update', false, true);
                    return;
                }
            })
            .catch(error => {
                this.showModal('error', 'Failed to update payment details.', 'update', false, true);
                throw error;
            });
    }
    back() {
        history.goBack();
    }
    next() {
        this.setState({
            paymentStart: true,
        });
    }
    setPaymentMode(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.modeOfPaymentId = e.value;
        this.setState({
            selectedPaymentMode: e,
            paymentInfo,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingPaymentInfo(show) {
        this.setState({
            loadingPaymentInfo: show,
        });
    }
    showLoadingPaymentMode(show) {
        this.setState({
            loadingPaymentMode: show,
        });
    }
    componentDidMount() {
        const applicationId = this.props.match.params.applicationId;
        this.props
            .getPaymentInfo(applicationId)
            .then(paymentInfo => {
                if (paymentInfo != null && paymentInfo.data != null && paymentInfo.data.length > 0) {
                    try {
                        paymentInfo.data[0].datePaymentReceivedCtrl = moment(paymentInfo.data[0].datePaymentReceived, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY',
                        );
                        paymentInfo.data[0].datePaymentReceived = moment(paymentInfo.data[0].datePaymentReceived, 'YYYY-MM-DD').format(
                            'DD/MM/YYYY',
                        );
                        paymentInfo.data[0].receivedTimestamp = moment(paymentInfo.data[0].datePaymentReceived, 'YYYY-MM-DD').format(
                            'YYYY-MM-DD hh:mm:ss',
                        );
                    } catch (e) {
                        throw e;
                    }
                    let paymentStart = false;
                    let selectedPaymentMode = null;
                    if (paymentInfo.data[0].modeOfPaymentId != null) {
                        selectedPaymentMode = {
                            label: 'x',
                            value: paymentInfo.data[0].modeOfPaymentId,
                        };
                        paymentStart = true;
                    }
                    this.setState({
                        paymentInfo: paymentInfo.data[0],
                        paymentStart,
                        selectedPaymentMode,
                    });
                }
            })
            .catch(error => {
                this.showLoadingPaymentMode(false);
                throw error;
            });
    }
    render() {
        return (
            <div className="page-widget">
                <Row>
                    <Col xs={12}>
                        <Button className="text-bold primary-btn-style-outline" onClick={this.back}>
                            <i className="material-icons align-text-bottom">chevron_left</i>
                            Back
                        </Button>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderHeaderPanel leftText="Membership Payment Info" />
                    </Col>
                </Row>
                <br />
                <Row className="mb-2">
                    <Col xs={12}>
                        <Card>
                            <CardBody>
                                {!this.state.paymentStart && (
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="field-header">
                                                    Payment Mode <MandatoryStar />
                                                </label>
                                                {this.state.loadingPaymentMode ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <Col xs={3} className="p-0">
                                                        <Select
                                                            autosize={false}
                                                            autofocus
                                                            options={paymentModes}
                                                            simpleValue
                                                            clearable={this.state.clearable}
                                                            name="selected-state"
                                                            disabled={this.state.disabled}
                                                            value={this.state.selectedPaymentMode}
                                                            onChange={this.setPaymentMode}
                                                            searchable={this.state.searchable}
                                                        />
                                                    </Col>
                                                )}

                                                <small className="form-text text-muted">Please choose payment mode</small>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <Col xs={12}>
                                    {this.state.paymentStart && this.state.selectedPaymentMode.value != 4 && (
                                        <PaymentChoicesPanel
                                            onDateChanged={this.onDateChanged}
                                            onTextChanged={this.onTextChanged}
                                            paymentInfo={this.state.paymentInfo}
                                            paymentMode={this.state.selectedPaymentMode.value}
                                        />
                                    )}

                                    {this.state.paymentStart && this.state.selectedPaymentMode.value == 4 && (
                                        <CreditCardPaymentInfoPanel paymentInfo={this.state.paymentInfo} />
                                    )}

                                    {!this.state.paymentStart && (
                                        <Row>
                                            <Col className="d-flex flex-row p-0">
                                                <Button
                                                    onClick={this.next}
                                                    className="primary-btn-style"
                                                    disabled={!this.state.paymentInfo.modeOfPaymentId}
                                                >
                                                    Next
                                                </Button>
                                            </Col>
                                            {/* <Col className="d-flex flex-row-reverse" xs={3}>
                                            <Button className="text-bold primary-btn-style-outline" onClick={this.back}>
                                                Back
                                            </Button>
                                        </Col> */}
                                        </Row>
                                    )}

                                    {this.state.paymentStart && this.state.selectedPaymentMode.value != 4 && (
                                        <Row>
                                            <Col className="d-flex flex-row p-0">
                                                <Button
                                                    className="primary-btn-style"
                                                    disabled={this.determineSavable()}
                                                    onClick={this.submit}
                                                >
                                                    Update
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <DialogModal
                                    modalAction={this.modalAction}
                                    negativeButtonAction={this.negativeModalAction}
                                    actionToggleExternal={this.modalToggle}
                                    modalOpen={this.state.modal.showModal}
                                    positiveButtonHide={this.state.modal.positiveButtonHide}
                                    negativeButtonHide={this.state.modal.negativeButtonHide}
                                    modalBody={this.state.modal.modalMessage}
                                    modalHeader={this.state.modal.modalHeader}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getPaymentInfo: applicationId => {
            return dispatch(getApplicationPaymentInfo(applicationId));
        },
        updatePayment: data => {
            return dispatch(updatePayment(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StaffMembershipApplicationsUpdatePaymentPage);
