import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import UsersInputField from './UsersInputField';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Button,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Alert,
} from 'reactstrap';

import {
    userDetailGetUserDetails,
    onChangeField,
    // forceValidate,
    // userAddAddAction, // for save
} from '../../../actions/admin/auth/userDetailAction.js';

import { removeRedirectId } from '../../../actions/admin/auth/userAddAction.js';
import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import controlledInputLib from '../../../js/controlledInputLib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import CyderDatePicker from '../../../cyder/forms/CyderDatePicker';

class UsersDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userExists: false,
        };
    }
    componentDidMount() {
        this.props.userDetailGetUserDetails(this.props.match.params.userid);
        this.props.setJumbotronTitle('Add User');
    }
    componentWillUnmount() {
        this.props.removeAlert();
    }
    render() {
        const additionalInputProps = {
            handleChange: this.props.onChangeField,
            validationObj: this.props.validation,
            saveButtonPressed: this.props.saveButtonPressed,
        };
        return (
            <div>
                {this.props.justAdded ? (
                    <Row>
                        <Col xs={12}>
                            <Alert color="success">Successfully added user. You may now enter additional details</Alert>
                        </Col>
                    </Row>
                ) : null}
                {this.props.loading ? (
                    <LoadingSpinner />
                ) : (
                    <Row>
                        <Col xs={10}>
                            <Card className="mb-2">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label xs={2}>
                                                Name: <span className="text-muted">*</span>
                                            </Label>
                                            <UsersInputField
                                                xs={5}
                                                value={this.props.user.username}
                                                type="text"
                                                valueKey={'username'}
                                                {...additionalInputProps}
                                            />
                                            <Label className="text-muted" xs={5}>
                                                Required. 254 characters or fewer. Letters, digits and @/./+/-/_ only.
                                            </Label>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>
                                                Password: <span className="text-muted">*</span>
                                            </Label>
                                            <Col className="d-flex" xs={10}>
                                                <Link className="align-self-center" to="#">
                                                    Change user password
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                            <h2>Personal info</h2>
                            <Card className="mb-2">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label xs={2}>First name:</Label>
                                            <UsersInputField
                                                xs={5}
                                                value={this.props.user.first_name}
                                                type="text"
                                                valueKey={'first_name'}
                                                {...additionalInputProps}
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Last name:</Label>
                                            <UsersInputField
                                                xs={5}
                                                value={this.props.user.last_name}
                                                type="text"
                                                valueKey={'last_name'}
                                                {...additionalInputProps}
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Email address:</Label>
                                            <UsersInputField
                                                xs={5}
                                                value={this.props.user.email}
                                                type="email"
                                                valueKey={'email'}
                                                {...additionalInputProps}
                                            />
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                            <h2>Permissions</h2>
                            <Card className="mb-2">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label xs={2}>Active</Label>
                                            <CheckboxInput
                                                user={this.props.user}
                                                onChangeField={this.props.onChangeField}
                                                valueKey="is_active"
                                                textDescription="Designates whether this user should be treated as active. Unselect this instead of deleting accounts."
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Staff status</Label>
                                            <CheckboxInput
                                                user={this.props.user}
                                                onChangeField={this.props.onChangeField}
                                                valueKey="is_admin"
                                                textDescription="Designates whether the user can log into this admin site."
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Superuser status</Label>
                                            <CheckboxInput
                                                user={this.props.user}
                                                onChangeField={this.props.onChangeField}
                                                valueKey="is_superuser"
                                                textDescription="Designates that this user has all permissions without explicitly assigning them."
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Groups:</Label>
                                            <Col xs={5}>
                                                <h6>Available groups</h6>
                                                <Input type="select" multiple multiple="multiple" className="selectfilter">
                                                    <option value="3">Certifications</option>
                                                    <option value="3">Elections</option>
                                                    <option value="3">Finance</option>
                                                    <option value="3">Management</option>
                                                    <option value="3">Membership</option>
                                                </Input>
                                                <Link to="#">Choose All</Link>
                                            </Col>
                                            <Col xs={5}>
                                                <h6>Chosen groups</h6>
                                                <Input type="select" multiple multiple="multiple" className="selectfilter" />
                                            </Col>
                                            <Col xs={{ size: 10, offset: 2 }}>
                                                <span className="text-muted">
                                                    The groups this user belongs to. A user will get all permissions granted to each of
                                                    their groups. Hold down "Control", or "Command" on a Mac, to select more than one.
                                                </span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Permissions:</Label>
                                            <Col xs={5}>
                                                <h6>Available user permissions</h6>
                                                <Input type="select" multiple multiple="multiple" className="selectfilter">
                                                    <option value="3">account | email address | Can add email address</option>
                                                    <option value="3">account | email address | Can add email address</option>
                                                    <option value="22" title="account | email address | Can add email address">
                                                        account | email address | Can add email address
                                                    </option>
                                                    <option value="14" title="contenttypes | content type | Can change content type">
                                                        contenttypes | content type | Can change content type
                                                    </option>
                                                    <option value="15" title="contenttypes | content type | Can delete content type">
                                                        contenttypes | content type | Can delete content type
                                                    </option>
                                                    <option value="328" title="elections | Election | Can add Election">
                                                        elections | Election | Can add Election
                                                    </option>
                                                    <option value="329" title="elections | Election | Can change Election">
                                                        elections | Election | Can change Election
                                                    </option>
                                                    <option value="330" title="elections | Election | Can delete Election">
                                                        elections | Election | Can delete Election
                                                    </option>
                                                    <option value="331" title="elections | Nominee | Can add Nominee">
                                                        elections | Nominee | Can add Nominee
                                                    </option>
                                                    <option value="332" title="elections | Nominee | Can change Nominee">
                                                        elections | Nominee | Can change Nominee
                                                    </option>
                                                    <option value="333" title="elections | Nominee | Can delete Nominee">
                                                        elections | Nominee | Can delete Nominee
                                                    </option>
                                                    <option value="334" title="elections | Scrutineer | Can add Scrutineer">
                                                        elections | Scrutineer | Can add Scrutineer
                                                    </option>
                                                    <option value="335" title="elections | Scrutineer | Can change Scrutineer">
                                                        elections | Scrutineer | Can change Scrutineer
                                                    </option>
                                                    <option value="336" title="elections | Scrutineer | Can delete Scrutineer">
                                                        elections | Scrutineer | Can delete Scrutineer
                                                    </option>
                                                    <option value="337" title="elections | Voter | Can add Voter">
                                                        elections | Voter | Can add Voter
                                                    </option>
                                                    <option value="338" title="elections | Voter | Can change Voter">
                                                        elections | Voter | Can change Voter
                                                    </option>
                                                    <option value="339" title="elections | Voter | Can delete Voter">
                                                        elections | Voter | Can delete Voter
                                                    </option>
                                                    <option value="313" title="emails | Email Category | Can add Email Category">
                                                        emails | Email Category | Can add Email Category
                                                    </option>
                                                    <option value="314" title="emails | Email Category | Can change Email Category">
                                                        emails | Email Category | Can change Email Category
                                                    </option>
                                                    <option value="315" title="emails | Email Category | Can delete Email Category">
                                                        emails | Email Category | Can delete Email Category
                                                    </option>
                                                    <option value="180" title="events | Event Track | Can delete Event Track">
                                                        events | Event Track | Can delete Event Track
                                                    </option>
                                                    <option value="283" title="events | Event Type | Can add Event Type">
                                                        events | Event Type | Can add Event Type
                                                    </option>
                                                    <option value="284" title="events | Event Type | Can change Event Type">
                                                        events | Event Type | Can change Event Type
                                                    </option>
                                                    <option value="285" title="events | Event Type | Can delete Event Type">
                                                        events | Event Type | Can delete Event Type
                                                    </option>
                                                    <option value="193" title="events | Food Preference | Can add Food Preference">
                                                        events | Food Preference | Can add Food Preference
                                                    </option>
                                                    <option value="194" title="events | Food Preference | Can change Food Preference">
                                                        events | Food Preference | Can change Food Preference
                                                    </option>
                                                    <option value="195" title="events | Food Preference | Can delete Food Preference">
                                                        events | Food Preference | Can delete Food Preference
                                                    </option>
                                                    <option value="208" title="events | Option | Can add Option">
                                                        events | Option | Can add Option
                                                    </option>
                                                    <option value="209" title="events | Option | Can change Option">
                                                        events | Option | Can change Option
                                                    </option>
                                                    <option value="210" title="events | Option | Can delete Option">
                                                        events | Option | Can delete Option
                                                    </option>
                                                    <option value="202" title="events | Question | Can add Question">
                                                        events | Question | Can add Question
                                                    </option>
                                                    <option value="203" title="events | Question | Can change Question">
                                                        events | Question | Can change Question
                                                    </option>
                                                    <option value="204" title="events | Question | Can delete Question">
                                                        events | Question | Can delete Question
                                                    </option>
                                                    <option value="214" title="events | Question Response | Can add Question Response">
                                                        events | Question Response | Can add Question Response
                                                    </option>
                                                    <option value="184" title="events | Registration | Can add Registration">
                                                        events | Registration | Can add Registration
                                                    </option>
                                                    <option value="185" title="events | Registration | Can change Registration">
                                                        events | Registration | Can change Registration
                                                    </option>
                                                    <option value="186" title="events | Registration | Can delete Registration">
                                                        events | Registration | Can delete Registration
                                                    </option>
                                                    <option value="199" title="events | Survey | Can add Survey">
                                                        events | Survey | Can add Survey
                                                    </option>
                                                    <option value="200" title="events | Survey | Can change Survey">
                                                        events | Survey | Can change Survey
                                                    </option>
                                                    <option value="201" title="events | Survey | Can delete Survey">
                                                        events | Survey | Can delete Survey
                                                    </option>
                                                    <option value="211" title="events | Survey Response | Can add Survey Response">
                                                        events | Survey Response | Can add Survey Response
                                                    </option>
                                                    <option value="212" title="events | Survey Response | Can change Survey Response">
                                                        events | Survey Response | Can change Survey Response
                                                    </option>
                                                    <option value="213" title="events | Survey Response | Can delete Survey Response">
                                                        events | Survey Response | Can delete Survey Response
                                                    </option>
                                                    <option value="205" title="events | Survey Section | Can add Survey Section">
                                                        events | Survey Section | Can add Survey Section
                                                    </option>
                                                    <option value="206" title="events | Survey Section | Can change Survey Section">
                                                        events | Survey Section | Can change Survey Section
                                                    </option>
                                                    <option value="207" title="events | Survey Section | Can delete Survey Section">
                                                        events | Survey Section | Can delete Survey Section
                                                    </option>
                                                    <option value="190" title="events | Ticket | Can add Ticket">
                                                        events | Ticket | Can add Ticket
                                                    </option>
                                                    <option value="191" title="events | Ticket | Can change Ticket">
                                                        events | Ticket | Can change Ticket
                                                    </option>
                                                    <option value="192" title="events | Ticket | Can delete Ticket">
                                                        events | Ticket | Can delete Ticket
                                                    </option>
                                                    <option value="88" title="finances | Invoice | Can add Invoice">
                                                        finances | Invoice | Can add Invoice
                                                    </option>
                                                    <option value="89" title="finances | Invoice | Can change Invoice">
                                                        finances | Invoice | Can change Invoice
                                                    </option>
                                                    <option value="90" title="finances | Invoice | Can delete Invoice">
                                                        finances | Invoice | Can delete Invoice
                                                    </option>
                                                </Input>
                                                <Link to="#">Choose All</Link>
                                            </Col>
                                            <Col xs={5}>
                                                <h6>Chosen user permissions</h6>
                                                <Input type="select" multiple multiple="multiple" className="selectfilter" />
                                            </Col>
                                            <Col xs={{ size: 10, offset: 2 }}>
                                                <span className="text-muted">
                                                    Specific permissions for this user. Hold down "Control", or "Command" on a Mac, to
                                                    select more than one.
                                                </span>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                            <h2>Important dates</h2>
                            <Card className="mb-2">
                                <CardBody>
                                    <Form>
                                        <FormGroup row>
                                            <Label xs={2}>Last login:</Label>
                                            <Col xs={3}>
                                                <CyderDatePicker
                                                    datetimeClassName="rdtPickerOpenUpwards"
                                                    value={this.props.user.last_login}
                                                    placeholder="DD/MM/YYYY HH:mm a"
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat="HH:mm a"
                                                    onChange={e =>
                                                        this.props.onChangeField(
                                                            controlledInputLib.generateDateTimeSynthEvent(
                                                                e,
                                                                'last_login',
                                                                'DD/MM/YYYY HH:mm a',
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Label xs={7}>
                                                <Link to="#">Now</Link>
                                            </Label>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label xs={2}>Date joined:</Label>
                                            <Col xs={3}>
                                                <CyderDatePicker
                                                    datetimeClassName="rdtPickerOpenUpwards"
                                                    value={this.props.user.date_joined}
                                                    placeholder="DD/MM/YYYY HH:mm a"
                                                    dateFormat="DD/MM/YYYY"
                                                    timeFormat="HH:mm a"
                                                    onChange={e =>
                                                        this.props.onChangeField(
                                                            controlledInputLib.generateDateTimeSynthEvent(
                                                                e,
                                                                'date_joined',
                                                                'DD/MM/YYYY HH:mm a',
                                                            ),
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Label xs={7}>
                                                <Link to="#">Now</Link>
                                            </Label>
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={2}>
                            <div className="sticky-top">
                                <Card className="mb-2">
                                    <CardBody>
                                        <Button color="info" block>
                                            Save
                                        </Button>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>Save and continue editing</span>
                                        </Button>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>Save and add another</span>
                                        </Button>
                                        <div className="text-center">
                                            <Link to="#">Delete</Link>
                                        </div>
                                    </CardBody>
                                </Card>
                                <h5>Tools</h5>
                                <Card>
                                    <CardBody>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>History</span>
                                        </Button>
                                        <Button color="default" block>
                                            <span style={{ whiteSpace: 'normal' }}>Add user</span>
                                        </Button>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}

const CheckboxInput = ({ valueKey, user, onChangeField, textDescription }) => {
    return (
        <Col xs={10}>
            <Label className="align-text-bottom" check>
                <Input
                    id={valueKey}
                    checked={user[valueKey] === 1}
                    onChange={e => onChangeField(controlledInputLib.generateCheckboxSynthEvent(e, 1, 0))}
                    type="checkbox"
                />
                <span className="text-muted">{textDescription}</span>
            </Label>
        </Col>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.userDetailReducer,
        justAdded: state.userAddReducer.redirectId !== null,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onChangeField: e => {
            const key = e.target.id;
            const value = e.target.value;
            dispatch(onChangeField(key, value));
        },
        userDetailGetUserDetails: userId => dispatch(userDetailGetUserDetails(userId)),
        removeAlert: () => dispatch(removeRedirectId()),
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(UsersDetailPage));
