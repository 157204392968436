import React from 'react';
import { connect } from 'react-redux';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import DashboardWidget from '../widget/DashboardWidget';

const data = [
    { name: 'Jan', new: 5, renewal: 90 },
    { name: 'Feb', new: 1, renewal: 35 },
    { name: 'Mar', new: 2, renewal: 28 },
    { name: 'Apr', new: 2, renewal: 2 },
    { name: 'May', new: 1, renewal: 71 },
];

const VerticalBarChartTwoBars = ({
    colors,
    items,
    title,
    description,
    firstKey,
    secondKey,
}) => (
    <DashboardWidget title={title} description={description}>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={items}
                margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
            >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip cursor={{ stroke: 'none', fill: 'none' }} />
                <Legend />
                <Bar dataKey={firstKey} fill={colors.success} />
                <Bar dataKey={secondKey} fill={colors.info} />
            </BarChart>
        </ResponsiveContainer>
    </DashboardWidget>
);

const mapStateToProps = (state, ownProps) => {
    return {
        colors: state.colors,
    };
};
export default connect(mapStateToProps)(VerticalBarChartTwoBars);
