/**
 * Events Analytics Reducer
 *
 * Author: Fernando
 *
 */
import moment from 'moment';

const defaultState = {
    startDate: '01/01/' + moment().format('YYYY'),
    endDate: '31/12/' + moment().format('YYYY'),
    year: null,
    filter: 'all',
    refresh: null,
};

/**
 * Login reducer
 *
 * @param {*} state
 * @param {*} action
 */
export default function eventsAnalyticsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'EVENTS_ANALYTICS_SET_START_DATE':
            var newState = Object.assign({}, state);
            newState.startDate = action.start;
            return newState;

        case 'EVENTS_ANALYTICS_SET_END_DATE':
            var newState = Object.assign({}, state);
            newState.endDate = action.end;
            return newState;

        case 'EVENTS_ANALYTICS_SET_FILTER':
            var newState = Object.assign({}, state);
            newState.filter = action.filter;
            return newState;

        case 'EVENTS_ANALYTICS_REFRESH':
            var newState = Object.assign({}, state);
            newState.refresh = new Date().getTime();
            return newState;

        default:
            return state;
    }
}
