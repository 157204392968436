import withQuery from 'with-query';

import {
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA,
} from '../../../constants/actionTypesStaffCertificationAssessmentCriteria';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import fetchlib from 'js/fetchlib';

export function getCriteriaDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE,
        );
    };
}

export function getCriteriaDetailsByCode(code) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'find-by-code',
            code,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function deleteCriteria(id) {
    return (dispatch, getState) => {
        const deletionId = id || getState().staffCertificationAssessmentCriteriaAddReducer.fields.id;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'delete',
        });
        const body = {
            id: deletionId,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST,
            STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS,
            STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE,
        );
    };
}
export function createUpdate(type) {
    return (dispatch, getState) => {
        const id2 = getState().staffCertificationAssessmentCriteriaAddReducer.fields.id;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: id2 ? 'update' : 'create',
        });
        const fields = getState().staffCertificationAssessmentCriteriaAddReducer.fields;
        var ratingToSave = fields.ratings;
        if (
            (getState().staffCertificationAssessmentCriteriaAddReducer.criteriaType === 'product' && fields.criteriaSectionId != 2) ||
            (getState().staffCertificationAssessmentCriteriaAddReducer.criteriaType === 'service' && fields.criteriaSectionId != 5)
        ) {
            ratingToSave = [];
        }
        const body = {
            criteria: fields.criteria,
            criteriaCode: fields.criteriaCode,
            documentsToSubmit: fields.documentsToSubmit,
            criteriaSectionId: parseInt(fields.criteriaSectionId, 0),
            requiredDocuments: fields.requiredDocuments,
            type: type || fields.type,
            ratings: ratingToSave.map(item =>
                Object.assign({}, item, {
                    sortkey: parseInt(item.sortkey, 10) || 0,
                }),
            ),
            id: fields.id,
        };

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST,
            STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS,
            STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE,
        );
    };
}
export function onChangeValue(key, value) {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD,
        key,
        value,
    };
}
export function onChangeRating(rating, ratingIndex, key) {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING,
        rating,
        ratingIndex,
        key,
    };
}
export function removeRating(index) {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING,
        index,
    };
}
export function addRating() {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING,
    };
}
export function forceValidateFields() {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE,
    };
}
export function resetState() {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS,
    };
}

export function setModalData(data) {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA,
        data,
    };
}
export function getCriteriaSectionList(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/criteria-section`, {
            action: 'get-active-criteria',
            type,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS,
            STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE,
        );
    };
}

export function getTemplatesByCriteriaCode(criteriaCode) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'get-templates-by-criteria-code',
            criteriaCode,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function activate(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'update-active',
        });
        const body = {
            id,
            active: 1,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function inactivate(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'update-active',
        });
        const body = {
            id,
            active: 0,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
