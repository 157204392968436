/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function search(keyword, tableType, nonStandardMealType, eventId, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'search',
        });

        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            tableType: tableType,
            nonStandardMealType: nonStandardMealType,
            eventId: eventId,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function save(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'create',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'update',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}

export function assignTable(tableNo, registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'assign-table',
        });

        var data = {
            tableNo: tableNo,
            id: registrationId,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function loadTableName(eventId, tableType) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'getTableName',
            eventId,
            tableType,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function changeTableName(tableNo, eventId, tableType, tableId, tableName) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'changeTableName',
        });

        var data = {
            tableNo: tableNo,
            eventId: eventId,
            tableType: tableType,
            tableId: tableId,
            tableName: tableName,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
