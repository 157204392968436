import {
    EVENT_SAVE_EVENT_DETAIL_REQUEST,
    EVENT_SAVE_EVENT_DETAIL_SUCCESS,
    GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS,
    EVENT_SAVE_EVENT_DETAIL_FAILURE,
    EVENT_DELETE_EVENT_DETAIL_REQUEST,
    EVENT_DELETE_EVENT_DETAIL_SUCCESS,
    EVENT_DELETE_EVENT_DETAIL_FAILURE,
    EVENT_UPDATE_EVENT_DETAIL_REQUEST,
    EVENT_UPDATE_EVENT_DETAIL_SUCCESS,
    GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS,
    EVENT_UPDATE_EVENT_DETAIL_FAILURE,
    EVENT_GET_FOOD_RESTRICT_REQUEST,
    EVENT_GET_FOOD_RESTRICT_SUCCESS,
    EVENT_GET_FOOD_RESTRICT_FAILURE,
    EVENT_GET_PAYMENT_MODE_REQUEST,
    EVENT_GET_PAYMENT_MODE_SUCCESS,
    EVENT_GET_PAYMENT_MODE_FAILURE,
    EVENT_GET_EVENT_DETAIL_REQUEST,
    EVENT_GET_EVENT_DETAIL_SUCCESS,
    EVENT_GET_EVENT_DETAIL_FAILURE,
    EVENT_CHANGE_EVENT_FIELD_VALUE,
    EVENT_SAVE_DOCUMENT_FILE_REQUEST,
    EVENT_SAVE_DOCUMENT_FILE_SUCCESS,
    EVENT_SAVE_DOCUMENT_FILE_FAILURE,
    EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST,
    EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS,
    EVENT_DOWNLOAD_DOCUMENT_FILE_FAILURE,
    EVENT_REMOVE_DOCUMENT_FILE,
    EVENT_RESET_EVENT_FIELDS,
    EVENT_SET_FILE_INPUT_ID,
    EVENT_SET_IMAGE_SIZE,
    EVENT_SET_EVENT_ID,
    EVENT_SET_MODAL_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsApplication';
import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';

import validationlib from '../../../../js/validation';
import { capitalizeFirstLetter } from '../../../../js/util';

// defaults
const defaultState = {
    loading: false,
    error: false,
    validation: {},
    eventid: 0,
    selection: {
        paymentMode: [],
        foodRestrict: [],
    },
    file: {
        inputId: '',
        downloadingEdmPdf: false,
        savingEdmPdf: false,
        savedEdmPdf: false,
        downloadingBannerImage: false,
        savingBaennerImage: false,
        savedBannerImage: false,
        downloadingRegistrationImage: false,
        savingRegistrationPageImage: false,
        savedRegistrationPageImage: false,
    },
    fields: {
        name: '',
        seriesId: '',
        eventTypeId: '',
        isGmpEvent: 0,
        summary: '',
        venue: '',
        attendeeLimit: '',
        foodRestrict: {},
        sponsors: '',
        coOrganizersPartners: '',
        edmPdf: null,
        bannerImage: null,
        bannerImageHeight: 0,
        guestOfHonour: '',
        bannerImageWidth: 0,
        registrationPageImage: null,
        registrationPageImageHeight: 0,
        registrationPageImageWidth: 0,
        paymentMode: [],
        description: '',
        confirmRegRequired: true,
        SGBCCPD: 0,
        PEBCPD: 0,
        SCECPD: 0,
        BCACPD: 0,
        BOACPD: 0,
        SILCPD: 0,
        CPD1: 0,
        CPD2: 0,
        CPD3: 0,
        CPD4: 0,
        SGBCCPD_visible: false,
        PEBCPD_visible: false,
        SCECPD_visible: false,
        BCACPD_visible: false,
        BOACPD_visible: false,
        SILCPD_visible: false,
        CPD1_visible: false,
        CPD2_visible: false,
        CPD3_visible: false,
        CPD4_visible: false,
    },
    ...defaultModalKeys,
};
export function eventsCreationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let id = newState.file.inputId;
    switch (action.type) {
        // Get - food restrcition
        case EVENT_GET_FOOD_RESTRICT_REQUEST:
            return newState;
        case EVENT_GET_FOOD_RESTRICT_SUCCESS:
            newState.selection.foodRestrict = action.response.data;
            newState.error = false;
            return newState;
        case EVENT_GET_FOOD_RESTRICT_FAILURE:
            newState.error = true;
            return newState;
        case EVENT_GET_PAYMENT_MODE_REQUEST:
            return newState;
        case EVENT_GET_PAYMENT_MODE_SUCCESS:
            newState.selection.paymentMode = action.response.data;
            newState.error = false;
            return newState;
        case EVENT_GET_PAYMENT_MODE_FAILURE:
            newState.error = true;
            return newState;
        case EVENT_SAVE_EVENT_DETAIL_REQUEST:
            newState.modalLoading = true;
            return newState;
        case GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.error = false;
            newState.modalLoading = false;
            newState.modalAction = `/staff/galadinner/all`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event successfully saved.';
            return newState;
        case EVENT_SAVE_EVENT_DETAIL_SUCCESS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.error = false;
            newState.modalLoading = false;
            newState.modalAction = `/staff/events/create/${action.response.data.eventId}/dates`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event successfully created.';
            return newState;
        case EVENT_SAVE_EVENT_DETAIL_FAILURE:
            newState.error = true;
            newState.modalLoading = false;
            newState.modalAction = './create';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to create event.';
            return newState;
        case EVENT_DELETE_EVENT_DETAIL_REQUEST:
            newState.modaloading = true;
            return newState;
        case EVENT_DELETE_EVENT_DETAIL_SUCCESS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.error = false;
            newState.modalLoading = false;
            newState.modalAction = './all';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event successfully deleted.';
            return newState;
        case EVENT_DELETE_EVENT_DETAIL_FAILURE:
            newState.error = true;
            newState.modalLoading = false;
            newState.modalAction = './' + newState.eventid;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to delete event.';
            return newState;
        case EVENT_UPDATE_EVENT_DETAIL_REQUEST:
            newState.modalLoading = true;
            return newState;
        case GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS:
            // newState.fields = Object.assign({}, defaultState.fields);
            newState.error = false;
            newState.modalLoading = false;
            newState.modalAction = './' + newState.eventid;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event successfully updated.';
            return newState;
        case EVENT_UPDATE_EVENT_DETAIL_SUCCESS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.error = false;
            newState.modalLoading = false;
            newState.modalAction = `/staff/events/${newState.eventid}/dates`;
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Event successfully updated.';
            return newState;
        case EVENT_UPDATE_EVENT_DETAIL_FAILURE:
            newState.error = true;
            newState.modalLoading = false;
            newState.modalAction = `./${state.eventid}`;
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to update event.';
            return newState;
        case EVENT_GET_EVENT_DETAIL_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_GET_EVENT_DETAIL_SUCCESS:
            newState = Object.assign({}, state);
            const response = Object.assign({}, action.response.data);
            // Assign data to fields object
            Object.keys(newState.fields).map(key => {
                try {
                    if (response[key]) {
                        newState.fields[key] = response[key];
                    }
                } catch (e) {}
                // Special cases
                switch (key) {
                    case 'edmPdf':
                        try {
                            newState.file.savedEdmPdf = response[key].length > 0 ? true : false;
                        } catch (e) {}
                        break;
                    case 'bannerImage':
                        try {
                            newState.file.savedBannerImage = response[key].length > 0 ? true : false;
                        } catch (e) {}
                        break;
                    case 'registrationPageImage':
                        try {
                            newState.file.savedRegistrationPageImage = response[key].length > 0 ? true : false;
                        } catch (e) {}
                        break;
                    case 'confirmRegRequired':
                        newState.fields[key] = response[key];
                    case 'SGBCCPD':
                        newState.fields[key] = response.sgbccpd;
                        break;
                    case 'SILCPD':
                        newState.fields[key] = response.silcpd;
                        break;
                    case 'SCECPD':
                        newState.fields[key] = response.scecpd;
                        break;
                    case 'PEBCPD':
                        newState.fields[key] = response.pebcpd;
                        break;
                    case 'BOACPD':
                        newState.fields[key] = response.boacpd;
                        break;
                    case 'BCACPD':
                        newState.fields[key] = response.bcacpd;
                        break;
                    case 'CPD1':
                        newState.fields[key] = response.cpd1;
                        break;
                    case 'CPD2':
                        newState.fields[key] = response.cpd2;
                        break;
                    case 'CPD3':
                        newState.fields[key] = response.cpd3;
                        break;
                    case 'CPD4':
                        newState.fields[key] = response.cpd4;
                        break;
                    case 'SGBCCPD_visible':
                        newState.fields[key] = response.sgbccpdVisible === 1;
                        break;
                    case 'PEBCPD_visible':
                        newState.fields[key] = response.pebcpdVisible === 1;
                        break;
                    case 'SCECPD_visible':
                        newState.fields[key] = response.scecpdVisible === 1;
                        break;
                    case 'BCACPD_visible':
                        newState.fields[key] = response.bcacpdVisible === 1;
                        break;
                    case 'BOACPD_visible':
                        newState.fields[key] = response.boacpdVisible === 1;
                        break;
                    case 'SILCPD_visible':
                        newState.fields[key] = response.silcpdVisible === 1;
                        break;
                    case 'CPD1_visible':
                        newState.fields[key] = response.cpd1Visible === 1;
                        break;
                    case 'CPD2_visible':
                        newState.fields[key] = response.cpd2Visible === 1;
                        break;
                    case 'CPD3_visible':
                        newState.fields[key] = response.cpd3Visible === 1;
                        break;
                    case 'CPD4_visible':
                        newState.fields[key] = response.cpd4Visible === 1;
                        break;
                }
            });
            newState.fields.description = response.description;
            newState.fields.confirmRegRequired = response.confirmRegRequired;
            newState.fields.CBDPoints = response.CBDPoints;
            newState.fields.guestOfHonour = response.guestOfHonour;
            // Post process paymentMode
            newState.fields.paymentMode = {};
            response.paymentMode.forEach(val => (newState.fields.paymentMode[val] = true));
            newState.loading = false;
            newState.error = false;
            return newState;
        case EVENT_GET_EVENT_DETAIL_FAILURE:
            newState.loading = false;
            newState.error = true;
            return newState;
        case EVENT_CHANGE_EVENT_FIELD_VALUE:
            newState.fields = Object.assign({}, state.fields);
            switch (action.id) {
                case 'paymentMode':
                    newState.fields.paymentMode[action.name] = action.value;
                    break;
                case 'foodRestrict':
                    newState.fields.foodRestrict[action.name] = action.value;
                    break;
                default:
                    newState.fields[action.id] = action.value;
                    // Validation
                    newState.validation = Object.assign({}, state.validation);
                    newState.validation[action.id] = validationlib.validate(action.id, action.value);
                    break;
            }
            return newState;
        case EVENT_SET_IMAGE_SIZE:
            newState.fields = Object.assign({}, state.fields);
            switch (action.inputId) {
                case 'bannerImage':
                    newState.fields.bannerImageHeight = action.height;
                    newState.fields.bannerImageWidth = action.width;
                    break;
                case 'registrationPageImage':
                    newState.fields.registrationPageImageHeight = action.height;
                    newState.fields.registrationPageImageWidth = action.width;
                    break;
            }
            return newState;
        case EVENT_RESET_EVENT_FIELDS:
            newState = Object.assign({}, defaultState);
            newState.fields = Object.assign({}, defaultState.fields);
            newState.file = Object.assign({}, defaultState.file);
            return newState;
        case EVENT_SET_EVENT_ID:
            newState.eventid = action.eventid;
            return newState;
        case EVENT_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            newState.modalHeader = action.data.modalHeader ? action.data.modalHeader : null;
            return newState;
        // File & Image input
        case EVENT_SET_FILE_INPUT_ID:
            newState.file.inputId = action.inputId;
            return newState;
        case EVENT_REMOVE_DOCUMENT_FILE:
            newState.file['saved' + capitalizeFirstLetter(action.inputId)] = false;
            newState.fields[action.inputId] = null;
            return newState;
        case EVENT_SAVE_DOCUMENT_FILE_REQUEST:
            newState.file[`saving${capitalizeFirstLetter(id)}`] = true;
            newState.file[`saved${capitalizeFirstLetter(id)}`] = false;
            return newState;
        case EVENT_SAVE_DOCUMENT_FILE_SUCCESS:
            newState.file[`saving${capitalizeFirstLetter(id)}`] = false;
            newState.file[`saved${capitalizeFirstLetter(id)}`] = true;
            newState.fields[id] = action.response.data.id;
            return newState;
        case EVENT_SAVE_DOCUMENT_FILE_FAILURE:
            newState.file[`saving${capitalizeFirstLetter(id)}`] = false;
            return newState;
        case EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST:
            newState.file[`downloading${capitalizeFirstLetter(id)}`] = true;
            return newState;
        case EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS:
            newState.file[`downloading${capitalizeFirstLetter(id)}`] = false;
            return newState;
        case EVENT_DOWNLOAD_DOCUMENT_FILE_FAILURE:
            newState.file[`downloading${capitalizeFirstLetter(id)}`] = false;
            return newState;
        default:
            return state;
    }
}
