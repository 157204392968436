import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

import { Button, Container, Col, Row } from 'reactstrap';
import TopNavigation2 from '../../layouts/TopNavigation2';
import AffiliatesPanel from './components/AffiliatesPanel';

import { memberHomePanelEventGetInfo, memberHomeSetLoadingState } from '../../actions/member/home/memberHomeAction';

class MembershipPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        localStorage.setItem('loginFrom', 'membership');
    }
    handleGoToRegister = () => {
        this.props.history.push('/public/createaccount');
        localStorage.setItem('panel', 4);
    };

    render() {
        return (
            <div className="membership-page">
                <Row>
                    <Col>
                        <Container className="wrapper text-center">
                            <div className="hero">
                                <img src="/assets/icons/icon-membership.png" width="50" />
                                <h3>
                                    {/* <span>Join</span> the Community! */}
                                    Be a part of the <br />
                                    <span style={{ color: '#78be20' }}>Green Building</span> movement!
                                </h3>
                                <div className="intro">
                                    {/* <p>
                                        <b>
                                            Be a part of the <span style={{ color: '#78be20' }}>Green Building</span> movement!
                                        </b>
                                    </p> */}
                                    <p>
                                        Meet and partner with like-minded green building organisations to uncover business and collaboration
                                        opportunities.
                                    </p>
                                    <p>
                                        To apply for corporate membership or certification services, you will first need to create a
                                        Corporate Profile. It takes about 3 minutes to create a Corporate Profile and you will require your
                                        organisation’s Unique Entity Number (UEN).
                                    </p>
                                    <p>
                                        For foreign companies not registered in Singapore, you may use the Company Registration Number as
                                        registered in your country.
                                    </p>
                                </div>
                                <Button
                                    style={{ display: 'block', margin: '3em auto 0 auto', textAlign: 'center' }}
                                    className="primary-green"
                                    onClick={this.handleGoToRegister}
                                >
                                    Proceed
                                </Button>
                                <br />
                                <div style={{ fontWeight: 'bolder' }}>
                                    LOGIN{' '}
                                    <a
                                        onClick={e => {
                                            history.push(`/public/login/member`);
                                        }}
                                        style={{ color: '#78be20' }}
                                    >
                                        <u>here</u>
                                    </a>{' '}
                                    if you already have a Corporate Profile
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
                <AffiliatesPanel />
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        const getData = [dispatch(memberHomePanelEventGetInfo())];
        dispatch(memberHomeSetLoadingState(true)).then(() => {
            Promise.all(getData).then(() => {
                dispatch(memberHomeSetLoadingState(false));
            });
        });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(MembershipPage));
