export function config(
    state = {
        appId: 'SGBC_CRM',
        layout: 'default-sidebar-1',
        background: 'light',
        navbar: 'light',
        topNavigation: 'light',
        logo: 'dark',
        leftSidebar: 'light',
        leftSidebarIcons: 'light',
        rightSidebar: false,
        collapsed: false,
        breadcrumb: true,
        banner: false,
        defaultTablePageSize: 20,
        fontColor: 'grey',
    },
    action,
) {
    switch (action.type) {
        case 'SET_CONFIG':
            return Object.assign({}, state, {
                ...action.config,
            });
        default:
            return state;
    }
}
