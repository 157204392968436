import React from 'react';
import PropTypes from 'prop-types';
import 'css/icon-widgets/icon-widget-6.css';

const NumberPanel1 = ({
    backgroundColor,
    bg,
    color,
    icon,
    iconColor,
    textTop,
    number,
    padding,
    link,
    textBottom,
    height,
    textSize,
}) => {
    if (!height) {
        height = '90';
    }
    if (!textSize) {
        textSize = '16';
    }

    let bgVar = '';
    if (bg) {
        bgVar = `bg-${bg}`;
    }

    let style = {
        height: `${height}px`,
    };
    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    }

    return (
        <div
            className={`icon-widget-6 ${bgVar} color-${color} p-${padding}`}
            style={style}
            onClick={null}
        >
            <div className="row justify-content-between align-items-center">
                <div className="col text-left">
                    <div className="row">
                        <div className="col">
                            <span
                                className="text"
                                style={{ fontSize: `${textSize}px` }}
                            >
                                {textTop}
                            </span>
                        </div>
                    </div>
                    {icon && (
                        <div className="row">
                            <div className="col">
                                <span className="number">{number}</span>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            <span style={{ whiteSpace: 'nowrap' }}>
                                {textBottom}
                            </span>
                        </div>
                    </div>
                </div>
                {icon && (
                    <div className="col text-right">
                        <i className={`material-icons ${iconColor} md-48`}>
                            {icon}
                        </i>
                    </div>
                )}
                {!icon && (
                    <div className="col text-right">
                        <span className="number" style={{ fontSize: '36px' }}>
                            {number}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

NumberPanel1.propTypes = {
    bg: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    colorColor: PropTypes.string,
    textTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    padding: PropTypes.number,
};

NumberPanel1.defaultProps = {
    padding: 10,
};

export default NumberPanel1;
