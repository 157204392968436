import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import Select from 'react-select';
import { FormGroup, Input, Row, Col, Form, Button, Label } from 'reactstrap';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

import { fetchReviewList } from 'actions/staff/certification/staffCertificationApplicationAction';

import 'css/forms/default-forms.css';

const typeOptions = [
    { key: 'product', label: 'Product', value: 'product', selected: true },
    { key: 'service', label: 'Service', value: 'service' },
    { key: 'all', label: 'All', value: 'all' },
];
const BrandDetailComponent = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((brand, index) => {
                return (
                    <div key={index}>
                        <div className="text-bold">{brand.name.toUpperCase()}</div>
                        {brand.models.map((model, index) => {
                            return <div key={index}>{model.name}</div>; //no description now
                        })}
                        <br />
                    </div>
                );
            })}
        </React.Fragment>
    );
};
class ApplicationList extends React.Component {
    _isMounted = true;
    state = {
        reviewList: [],
        loading: false,
        pagination: true,
        pageSize: 10,
        pageCount: 0,
        type: 'all',
        keyword: '',
        reviewType: { label: 'All', value: 'all' },
        // args: {
        //     page: 1,
        //     itemsPerPage: 20,
        //     type: 'all',
        //     keyword: '',
        //     loginId: 0,
        //     reviewType: {label: 'All', value: 'all'}
        // },
    };
    componentDidMount() {
        // if (this._isMounted) {
        //     let state = this.state;
        //     state.args.loginId = this.props.login.user.id; // Needed for backend process
        //     this.setState(state, () => this.getReviewList());
        // }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getReviewList = async (page, pageSize, sorted, filtered) => {
        try {
            this.setState({
                loading: true,
            });
            let args = {
                keyword: this.state.keyword,
                page: page + 1 || 1,
                itemsPerPage: pageSize || this.props.config.defaultTablePageSize,
                sorted,
                type: this.state.type,
                reviewType: this.state.reviewType.value,
            };
            const { data, totalNoOfItems, totalNoOfPages } = await this.props.fetchReviewList(args);
            if (this._isMounted) {
                this.setState({ reviewList: data, pageCount: totalNoOfPages, totalNum: totalNoOfItems });
            }
            this.setState({
                loading: false,
            });
        } catch (error) {}
    };
    onTextChange = e => {
        const { value } = e.target;
        let state = this.state;
        state.keyword = value;
        this.setState(state);
    };
    onReviewTypeChanged = e => {
        let state = this.state;
        state.reviewType = e;
        this.setState(state);
    };
    onTypeChange = e => {
        const { value } = e.target;
        let state = this.state;
        state.type = value;
        this.setState(state);
    };
    render() {
        const reviewTypes = [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Direct',
                value: 'direct',
            },
            {
                label: 'Peer',
                value: 'peer',
            },
        ];
        const columns = [
            {
                Header: 'Company',
                accessor: 'company',
                style: { whiteSpace: 'unset' },
                minWidth: 120,
            },
            {
                Header: 'Category',
                accessor: 'category',
                style: { whiteSpace: 'unset' },
                minWidth: 130,
            },
            {
                Header: 'Details',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'brandJson',
                minWidth: 130,
                Cell: props => {
                    const products = props.value || [];
                    try {
                        return products.constructor === Array && products.length > 0 ? <BrandDetailComponent items={products} /> : null;
                    } catch (e) {
                        return '-';
                    }
                },
            },
            {
                Header: 'Type',
                accessor: 'reviewType',
                style: { whiteSpace: 'unset' },
                minWidth: 80,
            },
            {
                Header: 'Case Officer',
                minWidth: 90,
                style: { whiteSpace: 'unset' },
                accessor: 'caseOfficer',
            },
            {
                Header: '',
                sortable: false,
                accessor: '',
                width: 100,
                Cell: props => {
                    const { clazz } = props.original;
                    const viewType = clazz === 'Service' ? 'viewservice' : 'viewproduct';
                    const url = `./applications/${viewType}/${props.original.id}`;
                    return (
                        <Link to={url}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="fas fa-folder-open mr-1" />
                                Open
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <NoShadowPanel title="My Review" borderSize={10}>
                <Row className="pb-4">
                    <Col xs={12}>
                        <div className="form-inline">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    placeholder="Keyword"
                                    onChange={this.onTextChange}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.getReviewList();
                                        }
                                    }}
                                    value={this.state.keyword}
                                />
                            </FormGroup>
                            {typeOptions.map(item => (
                                <Label check key={item.key}>
                                    <Input
                                        name="type"
                                        type="radio"
                                        value={item.value}
                                        checked={item.value === this.state.type}
                                        onChange={this.onTypeChange}
                                    />
                                    {item.label}
                                </Label>
                            ))}
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0" style={{ width: '200px' }}>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        autosize={false}
                                        options={reviewTypes}
                                        value={this.state.reviewType}
                                        clearable={true}
                                        onChange={this.onReviewTypeChanged}
                                        placeholder="Select type"
                                        name="selected-state"
                                        disabled={false}
                                        searchable={true}
                                    />
                                </div>
                            </FormGroup>
                            <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.getReviewList()}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            minRows={0}
                            filterable={false}
                            manual
                            defaultPageSize={this.state.pageSize}
                            className="-highlight mb-2"
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            columns={columns}
                            totalNum={this.state.totalNum}
                            //totalNum={this.state.reviewList != null ? this.state.reviewList.length : 0}
                            data={this.state.reviewList}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.getReviewList(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </NoShadowPanel>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        login: state.profile,
        applicationDetailStatuses: state.memberCertifications.statuses,
        reviewList: state.memberCertifications.reviewList,
        ...state.staffCertificationApplicationReducer,
        config: state.config,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchReviewList: data => dispatch(fetchReviewList(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ApplicationList));
