/**
 * Product Application view page
 *
 * Author: Fernando Karnagi
 */

import matchSorter from 'match-sorter';
import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../../history';
import moment from 'moment';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import Select from 'react-select';
import { Row, Col, Input, FormGroup, Label, Container, Button } from 'reactstrap';
import ReactTable from 'react-table';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderApplicationStatusPanel from 'cyder/panels/CyderApplicationStatusPanel';
import MarkUserAgreementPanel from 'pages/staff/certification/MarkUserAgreementPanel';
import { ProductDetailsPanel } from 'pages/staff/certification/applications/product/components/ProductDetailsPanel';
import DialogModal from 'cyder/modals/DialogModal';
import BigForm from 'cyder/forms/BigForm';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import { actionExecutor } from 'js/util';

import { getAssessmentScoresheet } from 'actions/staff/certification/staffCertAssessmentAction';
import { setTakeUpCase, setAssignApprover, setAssignNextApprover } from 'actions/member/certification/applications/memberCertNewApplAction';
import {
    printCertByCertNumber,
    handleRegenerateByCertNumber,
    downloadCertificate,
    findCertByCertNumber,
} from 'actions/shared/certificationAction';
import {
    loadPaymentMode,
    getApplicationInfo,
    getCompanyInfo,
    getApplicationStatusInfo,
    getProductTypeInfo,
    getProductAssessmentTemplatesByProductType,
    getProductAssessmentTemplatesByProductCategory,
    acceptApplication,
    discontinueApplication,
    startAssessment,
    updateRemarks,
    updateStatus,
    completeAssessment,
    unlinkInvoice,
    getAllStaffs,
    getAllCertManagers,
    getAllSuperAdmin,
    sendForApproval,
    approveApplication,
    rejectApplication,
    startFeedback,
    startPreparation,
    completeApplication,
    saveEditOnBehalf,
    markAsPaid,
    getInvoiceInfo,
    authorizingInvoice,
    getBCAOptions,
    getOtherOptions,
    updateTagging,
    fetchXeroInventoryCodes,
    printCert,
    savePaymentInfo,
    generateCertNumber,
    takeOverCase,
    returnMua,
    closeCase,
    getXeroInvoiceInfo,
    findApproverInfo,
    sendWithdrawalEmail,
    createReceiptInS3ForCertificate,
    getPaymentDetails,
    checkCertNumberDuplication,
    sendReceipt,
    updateNumberSequence,
    getAllCertificateList,
    printCertificationApplication,
    removeInvoice,
} from 'actions/staff/certification/staffCertificationApplicationAction';

import { SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';
import { pad, b64toBlob, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';
import modalMessageDict from 'pages/staff/certification/applications/product/data/modalMessageDict';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export const checkDisabledCompleteAssessment = (scoresheetInfo, type) => {
    try {
        let criteria = JSON.parse(scoresheetInfo.data.criteria);
        let vote = 0;

        if (type == 'product') {
            vote = 0;

            let sectionAMet = true;
            criteria.savedSectionA.forEach(element => {
                if (element.status == undefined || element.status == null || element.status == '' || element.status != 'Accepted') {
                    sectionAMet = false;
                }
            });
            if (sectionAMet) vote += 1;

            let sectionBMet = true;
            criteria.savedSectionB.forEach(element => {
                if (element.status == undefined || element.status == null || element.status == '' || element.status != 'Accepted') {
                    sectionBMet = false;
                }
            });
            if (sectionBMet) vote += 1;

            let sectionCCount = 0;
            criteria.savedSectionC.forEach(element => {
                if (element.status != undefined && element.status != null && element.status == 'Accepted') {
                    sectionCCount++;
                }
            });
            if (sectionCCount >= criteria.ratingRule.good.supplementary) vote += 1;
        } else {
            const savedSectionCServiceMinimumCriteria = criteria.savedSectionCServiceMinimumCriteria
                ? parseInt(criteria.savedSectionCServiceMinimumCriteria)
                : 5;

            if (criteria.savedSectionBService.length > 0) {
                // Type 1
                vote += 2;

                let sectionBMet = true;
                criteria.savedSectionBService.forEach(element => {
                    if (element.status == undefined || element.status == null || element.status == '' || element.status != 'Accepted') {
                        sectionBMet = false;
                    }
                });
                if (sectionBMet) vote += 1;

                // console.log('sectionBMet: ', sectionBMet);
            } else {
                // Type 2
                vote += 1;

                let sectionAMet = true;
                criteria.savedSectionAService.forEach(element => {
                    if (element.status == undefined || element.status == null || element.status == '' || element.status != 'Accepted') {
                        sectionAMet = false;
                    }
                });
                if (sectionAMet) vote += 1;

                let sectionCCount = 0;
                criteria.savedSectionCService.forEach(element => {
                    if (element.status != undefined && element.status != null && element.status == 'Accepted') {
                        sectionCCount++;
                    }
                });
                if (sectionCCount >= savedSectionCServiceMinimumCriteria) vote += 1;
            }
        }

        // console.log('vote: ', vote);
        return vote < 3;
    } catch (e) {
        return true;
    }
};
const ApplicationStatus = ({ visible, status, step }) => {
    if (visible) {
        return (
            <div>
                <Row>
                    <Col>
                        <CyderApplicationStatusPanel status={status} step={step} />
                    </Col>
                </Row>
            </div>
        );
    } else {
        return null;
    }
};
const InputField = ({ label, inputProps, children, xs = 12, mandatory }) => (
    <Col xs={xs}>
        <FormGroup>
            <Label>
                {label}
                {mandatory && <MandatoryStar />}
            </Label>
            <div className="pt-1 pb-1">{children || <Input {...inputProps} />}</div>
        </FormGroup>
    </Col>
);
const CertListModal = props => {
    const { loading, certificates } = props;
    const columns = [
        {
            Header: 'Running Cert No.',
            accessor: 'certNumber',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certNumber'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Status',
            accessor: 'status',
            style: { whiteSpace: 'unset' },
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['status'] }),
            filterAll: true,
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: 'Company',
            accessor: 'companyName',
            filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
            filterAll: true,
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
        },
        {
            Header: '',
            accessor: '',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            minWidth: 70,
            Cell: ({ value }) => {
                const url = `/staff/certification/applications/viewproduct/${value.certAppId}`;
                return (
                    <Button className="ml-2 align-middle primary-btn-style primary-btn-xs" onClick={() => history.push(url)}>
                        <i className="fas fa-folder-open mr-1" />
                        Open
                    </Button>
                );
            },
        },
    ];
    if (!certificates && !loading) return 'Failed to load certificate list.';

    console.log(certificates);

    let filterCertificates = certificates;

    if (certificates !== undefined) {
        if (certificates[0] === undefined) filterCertificates = [];
    }

    console.log(filterCertificates);

    return (
        <ReactTable
            columns={columns}
            filterable={true}
            defaultPageSize={10}
            className="-striped -highlight"
            data={filterCertificates}
            defaultSorted={[
                {
                    id: 'certNumber',
                    desc: true,
                },
            ]}
        />

        // <CyderReactTable
        //     manual
        //     minRows={0}
        //     filterable={false}
        //     columns={columns}
        //     className="-highlight mb-2"
        //     showPagination={false}
        //     defaultPageSize={10}
        //     loading={loading}
        //     data={certificates}
        //     totalNum={certificateSize || 0}
        // />
    );
};
class DialogComponent extends React.Component {
    state = {
        focused: false,
        date: null,
    };
    onFocusChange = ({ focused }) => {
        this.setState({ focused });
    };
    onDateChange = date => {
        this.props.paymentDateOnChange(date);
        this.setState({ date });
    };
    render() {
        const { paymentModes, onTextChanged, selectedPaymentMode, setPaymentMode } = this.props;
        let { amountReceived, bank, referenceNumber, remarks, datePaymentReceived } = this.props.paymentInfo;
        const icon = <i className="material-icons">calendar_today</i>;
        if (!datePaymentReceived) {
            datePaymentReceived = moment().format('YYYY-MM-DD');
        }
        return (
            <Col xs={12} className="update-payment">
                <InputField label="Mode of Payment" xs={12} mandatory={true}>
                    <Select
                        autofocus
                        simpleValue
                        autosize={false}
                        name="selected-state"
                        options={paymentModes}
                        value={selectedPaymentMode}
                        onChange={setPaymentMode}
                    />
                </InputField>

                <InputField
                    xs={12}
                    label="Amount Received"
                    inputProps={{
                        type: 'text',
                        id: 'amountReceived',
                        value: amountReceived,
                        onChange: onTextChanged,
                    }}
                    mandatory={true}
                />
                <InputField
                    xs={12}
                    label="Name of Bank"
                    inputProps={{
                        type: 'text',
                        id: 'bank',
                        value: bank,
                        onChange: onTextChanged,
                    }}
                    mandatory={true}
                />
                <InputField
                    xs={12}
                    label="Transaction No. / Cheque No."
                    inputProps={{
                        type: 'text',
                        id: 'referenceNumber',
                        value: referenceNumber,
                        onChange: onTextChanged,
                    }}
                    mandatory={true}
                />
                <InputField
                    label="Remarks"
                    inputProps={{
                        type: 'textarea',
                        id: 'remarks',
                        value: remarks,
                        onChange: onTextChanged,
                    }}
                />
                <FormGroup style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <Label>
                        Date Received
                        <MandatoryStar />
                    </Label>
                    <CyderDatePicker
                        placeholder=""
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect={true}
                        onChange={this.onDateChange}
                        value={datePaymentReceived || moment(datePaymentReceived).format('YYYY-MM-DD')}
                        width="100%"
                    />
                </FormGroup>
            </Col>
        );
    }
}
class ApplicationViewPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.checkCertNumberDuplication = this.checkCertNumberDuplication.bind(this);
        this.sendWithdrawalEmail = this.sendWithdrawalEmail.bind(this);
        this.paymentDateOnChange = this.paymentDateOnChange.bind(this);
        this.unlinkInvoice = this.unlinkInvoice.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.onTextChanged = this.onTextChanged.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.downloadReceipt = this.downloadReceipt.bind(this);
        this.createAndSendReceipt = this.createAndSendReceipt.bind(this);
        this.createAndSendReceiptConfirmation = this.createAndSendReceiptConfirmation.bind(this);
        this.sendReceipt = this.sendReceipt.bind(this);
        this.viewPicture = this.viewPicture.bind(this);
        this.viewCatalogue = this.viewCatalogue.bind(this);
        this.viewCertificates = this.viewCertificates.bind(this);
        this.onTemplateChange = this.onTemplateChange.bind(this);
        this.previewTemplate = this.previewTemplate.bind(this);
        this.takeUpConfirm = this.takeUpConfirm.bind(this);
        this.takeUpCase = this.takeUpCase.bind(this);
        this.addRemarks = this.addRemarks.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.onRemarksChange = this.onRemarksChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.discontinueApplication = this.discontinueApplication.bind(this);
        this.startAssessment = this.startAssessment.bind(this);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.startAssigningApprover = this.startAssigningApprover.bind(this);
        this.startAssigningNextApprover = this.startAssigningNextApprover.bind(this);
        this.sendForApproval = this.sendForApproval.bind(this);
        this.approveApplication = this.approveApplication.bind(this);
        this.rejectApplication = this.rejectApplication.bind(this);
        this.startFeedback = this.startFeedback.bind(this);
        this.startPreparation = this.startPreparation.bind(this);
        this.completeApplication = this.completeApplication.bind(this);
        this.markAsPaid = this.markAsPaid.bind(this);
        this.printCertificate = this.printCertificate.bind(this);
        this.saveEditOnBehalf = this.saveEditOnBehalf.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.authorizeInvoice = this.authorizeInvoice.bind(this);
        this.authorizeInvoiceDialog = this.authorizeInvoiceDialog.bind(this);
        this.discontinueDialog = this.discontinueDialog.bind(this);
        this.viewPreviousCertificate = this.viewPreviousCertificate.bind(this);
        this.viewPreviousApplication = this.viewPreviousApplication.bind(this);
        this.viewPreviousAssessment = this.viewPreviousAssessment.bind(this);
        this.updateProductBrandsWithCertificate = this.updateProductBrandsWithCertificate.bind(this);
        this.updateTagging = this.updateTagging.bind(this);
        this.unassignedBrandFromCertificate = this.unassignedBrandFromCertificate.bind(this);
        this.onOverseasTransactionChargesChange = this.onOverseasTransactionChargesChange.bind(this);
        this.onDisableInvoiceChange = this.onDisableInvoiceChange.bind(this);
        this.sendToCaseOfficer = this.sendToCaseOfficer.bind(this);
        this.onSelectedOfficerChange = this.onSelectedOfficerChange.bind(this);
        this.onSelectedPeerChange = this.onSelectedPeerChange.bind(this);
        this.onSelectedManagerChange = this.onSelectedManagerChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.startTagging = this.startTagging.bind(this);
        this.editApplication = this.editApplication.bind(this);
        this.viewSurveyResult = this.viewSurveyResult.bind(this);
        //this.generateCertNumber = this.generateCertNumber.bind(this);
        this.startFeedbackDialog = this.startFeedbackDialog.bind(this);
        this.previewOldTemplate = this.previewOldTemplate.bind(this);
        this.takeOverCase = this.takeOverCase.bind(this);
        this.updateMarkUserAgreementStates = this.updateMarkUserAgreementStates.bind(this);
        this.returnMua = this.returnMua.bind(this);
        this.handleRegenerate = this.handleRegenerate.bind(this);
        this.printCert = this.printCert.bind(this);
        this.printCertByCertNumber = this.printCertByCertNumber.bind(this);
        this.viewProductCertificateByCertNumber = this.viewProductCertificateByCertNumber.bind(this);
        this.closeCase = this.closeCase.bind(this);
        this.getXeroInvoiceInfo = this.getXeroInvoiceInfo.bind(this);
        this.showsLoading = this.showsLoading.bind(this);
        this.printMUA = this.printMUA.bind(this);
        this.removeInvoice = this.removeInvoice.bind(this);

        this.state = {
            step: 0,
            loading: false,
            disabled: false,
            searchable: true,
            clearable: true,
            newTemplateChoosen: false,
            enableCloseCaseBtn: true,
            openUpdatePayment: false,
            otCharges: 0,
            disableInvoice: 0,
            completeAssessmentDisabled: true,
            selectValue: '',
            openCertificatesModal: false,
            applicationDetails: {
                data: {
                    remarks: '',
                },
            },
            companyDetails: {
                data: {
                    name: '',
                },
            },
            applicationStatus: {
                data: {
                    status: '',
                    remarks: '',
                },
            },
            selectedTemplate: {
                label: '',
                value: '',
            },
            productTypeInfo: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: false,
                negativeButtonHide: true,
                type: 'save',
                action: '',
            },
            paymentInfo: {
                amountReceived: '',
                bank: '',
                referenceNumber: '',
                remarks: '',
                modeOfPaymentId: '',
                datePaymentReceivedCtrl: '',
                datePaymentReceived: '',
            },
            paymentModes: '',
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            generatedCertNumber: '',
            certificationNumber: '',
            itemCode: this.props.itemCode ? this.props.itemCode[0] : '',
            numberOfTicks: '',
            muaNameError: '',
            muaContactNumberError: '',
            muaDesignationError: '',
            muaEmailError: '',
            sendReceiptPressed: false,
            isApplicantDetailsOpen: false,
            sendForApprovalClicked: false,
        };
    }
    updateMarkUserAgreementStates(newStates) {
        this.setState(newStates);
    }
    async componentDidMount(oldCertId) {
        this.props.takeUpCase(false);
        const {
            getApplicationStatusInfo,
            fetchXeroInventoryCodes,
            getApplicationInfo,
            getCompanyInfo,
            getPaymentModes,
            getProductTypeInfo,
            getProductAssessmentTemplatesByProductType,
            getProductAssessmentTemplatesByProductCategory,
            getAllCertificateList,
        } = this.props;
        this.showLoading(true);
        const id = oldCertId || this.props.match.params.id;
        try {
            // GET - Application Details
            const applicationDetails = await getApplicationInfo(id);
            const {
                certificateApplicationStatusId,
                productTypeId,
                templateId,
                companyId,
                assessmentId,
                paymentId,
            } = applicationDetails.data;

            {
                const getAllCertificateListProductSize = await getAllCertificateList('productSize');
                const limit = 500;
                const pages =
                    (getAllCertificateListProductSize.data && Math.round(getAllCertificateListProductSize.data.count) / limit) || 1;

                let allCertificateList = [];
                for (let i = 0; i <= pages; i++) {
                    let offset = 0;

                    if (i > 0) {
                        offset = 500 * i;
                    }

                    const getAllCertificateList = await this.props.getAllCertificateList('product', offset, limit);
                    const data = getAllCertificateList.data;
                    if (allCertificateList.length <= 0) {
                        allCertificateList = data;
                    } else {
                        allCertificateList = allCertificateList.concat(data);
                    }
                }

                allCertificateList && this.setState({ certificates: allCertificateList });
            }

            // GET - others
            const xeroCodes = await fetchXeroInventoryCodes();
            const companyDetails = await getCompanyInfo(companyId);
            const applicationStatus = await getApplicationStatusInfo(certificateApplicationStatusId);

            if (applicationDetails.data.certificateToRenewOrAmendId != null) {
                this.props.setUsePrevAssessmentTemplaTe();
            }
            await this.getXeroInvoiceInfo(applicationDetails);
            await this.getApproverId(applicationDetails);

            this.props.resetAssigningApprover();

            // GET - payment mode options
            const response = await getPaymentModes();
            let paymentModes = response.data
                .map(({ id, mode }) => {
                    // Filter out credit card payment
                    if (id !== 4) {
                        return {
                            value: id,
                            label: mode,
                        };
                    }
                })
                .filter(x => x);

            if (applicationDetails.data.paymentId !== null) {
                await this.getPaymentInfo(applicationDetails.data.paymentId, paymentModes);
            }

            // GET = templates, productTypesId, Assessment Templates
            let templates = { data: [] };
            let productTypeInfo = {};
            let templatesByProductType = { data: [] };
            // handle null value
            if (productTypeId) {
                productTypeInfo = await getProductTypeInfo(productTypeId);
                templates = await getProductAssessmentTemplatesByProductCategory(productTypeId);
                templatesByProductType = await getProductAssessmentTemplatesByProductType(productTypeId);
            }
            const selectedTemplate = this.getChoosenTemplate(templates.data || [], templateId, templatesByProductType, productTypeId);

            const scoresheetInfo = await this.props.getAssessmentScoresheet(assessmentId);
            const disabledCompleteAssessment = checkDisabledCompleteAssessment(scoresheetInfo, 'product');

            // SET STATE
            this.setState({
                xeroCodes,
                templates,
                paymentModes,
                companyDetails,
                productTypeInfo,
                certificateApplicationStatusId,
                applicationStatus,
                selectedTemplate,
                applicationDetails: {
                    data: sanitizeStringAndObjectNullsToEmptyStrings(applicationDetails.data),
                },
                originalApplicationDetails: {
                    data: sanitizeStringAndObjectNullsToEmptyStrings(applicationDetails.data),
                },
                completeAssessmentDisabled: disabledCompleteAssessment,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) this.componentDidMount();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getChoosenTemplate(templates, choosenTemplateId, templatesByProductType, productTypeId) {
        var foundTemplate = this.state.selectedTemplate;
        templates.forEach(template => {
            if (template.id === choosenTemplateId) {
                foundTemplate = { label: template.title, value: template.id };
            }
        });
        if (!foundTemplate.value) {
            if (templatesByProductType) {
                templatesByProductType.data.forEach(template => {
                    if (template.productTypeId === productTypeId) {
                        foundTemplate = {
                            label: template.title,
                            value: template.id,
                        };
                    }
                });
            }
        }
        if (this.props.selectedTemplate != null && this.props.selectedTemplate.value != null) {
            foundTemplate = this.props.selectedTemplate;
        }
        if (foundTemplate.value) {
            this.setState({
                newTemplateChoosen: true,
            });
        }
        return foundTemplate;
    }
    async getPaymentInfo(paymentId, paymentModes) {
        try {
            this.showLoading(true);
            let { data } = await this.props.getPaymentInfo(
                this.state.applicationDetails.data.paymentId ? this.state.applicationDetails.data.paymentId : paymentId,
            );
            if (!data) {
                this.showModalIf('Error', modalMessageDict['getPaymentInfoFailure'], true);
                return;
            }
            // SET PAYMENT INFO
            const {
                amountReceived,
                bank,
                referenceNumber,
                remarks,
                modeOfPaymentId,
                datePaymentReceived,
            } = sanitizeStringAndObjectNullsToEmptyStrings(data);
            const paymentInfo = {
                amountReceived,
                bank,
                referenceNumber,
                remarks,
                modeOfPaymentId,
                datePaymentReceived,
            };

            var markAsPaidDisabled = true;
            if (!amountReceived || !bank || !referenceNumber || !modeOfPaymentId || !datePaymentReceived) {
                markAsPaidDisabled = true;
            } else {
                markAsPaidDisabled = false;
            }

            // SET SELECTED PAYMENT MODE
            var currentPaymentMode = this.state.paymentModes ? this.state.paymentModes : paymentModes;
            const mode = currentPaymentMode.find(x => x.value === modeOfPaymentId);
            const selectedPaymentMode = {
                value: mode ? mode.value : '',
                label: mode ? mode.label : '',
            };
            this.setState({ paymentInfo, selectedPaymentMode, markAsPaidDisabled });
        } catch (error) {
            this.showLoading(false);
            this.showModalIf('Error', modalMessageDict['getPaymentInfoFailure'], true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    showLoading(show) {
        if (!this._isMounted) return;
        this.setState({
            loading: show,
        });
    }
    showsLoading = loading => {
        this.setState({ loading });
    };
    startTagging() {
        this.setState({
            taggingPanelVisible: true,
        });
    }
    editApplication() {
        history.push(`/staff/certification/edit-application-on-behalf/product/${this.state.applicationDetails.data.id}`);
    }
    viewSurveyResult() {
        this.props.setPostSurveyResultViewUrl(`/staff/certification/applications/viewproduct/${this.state.applicationDetails.data.id}`);
        history.push(`/staff/certification/applications/survey/${this.state.applicationDetails.data.id}`);
    }

    updateTagging() {
        this.props.authorizingInvoice(this.state.applicationDetails.data.id);
    }
    updateProductBrandsWithCertificate(certificationNumber, itemCode, ticks, selectedBrandModel, testValue) {
        var brands = JSON.parse(this.state.applicationDetails.data.brands);
        brands.forEach(brand => {
            brand.models.forEach(model => {
                var combinedBrandName = brand.name + '|' + model.name;
                if (selectedBrandModel.indexOf(combinedBrandName) >= 0) {
                    model.certificationNumber = certificationNumber;
                    model.itemCode = itemCode;
                    model.ticks = ticks;
                    model.testValue = testValue;
                }
            });
        });
        var applicationDetails = Object.assign({}, this.state.applicationDetails);
        applicationDetails.data.brands = JSON.stringify(brands);
        this.setState({
            applicationDetails,
        });
    }
    unassignedBrandFromCertificate(selectedBrandModel) {
        var brands = JSON.parse(this.state.applicationDetails.data.brands);
        brands.forEach(brand => {
            brand.models.forEach(model => {
                var combinedBrandName = brand.name + '|' + model.name;
                if (selectedBrandModel.indexOf(combinedBrandName) >= 0) {
                    model.certificationNumber = '';
                    model.price = '';
                    model.ticks = '';
                    model.itemCode = '';
                    model.testValue = '';
                }
            });
        });
        var applicationDetails = Object.assign({}, this.state.applicationDetails);
        applicationDetails.data.brands = JSON.stringify(brands);
        this.setState({
            applicationDetails,
        });
    }
    previewOldTemplate() {
        let templateName = '';
        this.state.templates.data.forEach(template => {
            if (template.id == this.state.applicationDetails.data.templateId) {
                templateName = template.title;
            }
        });
        let selectedTemplate = {
            label: templateName,
            value: this.state.applicationDetails.data.templateId,
        };
        this.onTemplateChange(selectedTemplate);
        this.props.storeSelectedTemplate(selectedTemplate);
        this.viewAssessment();
    }
    viewAssessment() {
        history.push(
            `/staff/certification/applications/viewproducttemplate/${this.state.applicationDetails.data.id}/${this.state.applicationDetails.data.templateId}/${this.state.applicationDetails.data.assessmentId}/${this.state.applicationDetails.data.id}`,
        );
    }
    previewTemplate() {
        const { selectedTemplate, applicationDetails, newTemplateChoosen } = this.state;
        this.showModalIf('Error', modalMessageDict['previewTemplateValid'], !newTemplateChoosen);
        history.push(
            `/staff/certification/applications/viewproducttemplate/${applicationDetails.data.id}/${selectedTemplate.value}/0/${applicationDetails.data.id}`,
        );
    }
    takeUpCase(take) {
        const { selectedTemplate, applicationDetails } = this.state;
        const hasError = this.showModalIf(
            'Error',
            modalMessageDict['takeUpCaseValid'],
            !selectedTemplate.value && !applicationDetails.data.templateId,
        );
        if (hasError) return;
        this.props.takeUpCase(take);
    }
    takeOverCase() {
        this.props.takeOverCase(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    async sendWithdrawalEmail() {
        try {
            this.showLoading(true);
            await this.props.sendWithdrawalEmail(this.state.applicationDetails.data.id);
            this.showModal('Confirmation', modalMessageDict['sendWithdrawalSuccess'], null, false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    returnMua() {
        this.props.returnMua(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    removeInvoice() {
        this.props.removeInvoice(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    startPreparation() {
        this.props.startPreparation(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    viewPicture() {
        window.open(`${SGBC_S3_ROOT}${this.state.applicationDetails.data.productPicture}`);
    }
    viewCatalogue() {
        window.open(`${SGBC_S3_ROOT}${this.state.applicationDetails.data.productCatalogue}`);
    }
    viewCertificates = () => {
        this.showModalIf('Certificates', null, true);
        this.setState({ openCertificatesModal: true, openUpdatePayment: false });
        // const callback = async () => {
        //     const { data } = await this.props.getAllCertificateList('product');
        //     const certs = data.filter(d => d.certAppId !== undefined);
        //     this.setState({ certificates: certs, certificateSize: data[data.length - 1].count && data[data.length - 1].count });
        // };
        // actionExecutor(this.showsLoading, callback);
    };
    // ACTION HANDLER
    async checkCertNumberDuplication(certNumber) {
        var currentCertNumber = [];
        var currentPrice = [];
        var currentTicks = [];
        var currentItemCode = [];
        var currentTestValue = [];
        var brands = JSON.parse(this.state.originalApplicationDetails.data.brands);
        brands.forEach(brand => {
            brand.models.forEach(model => {
                currentCertNumber.push(model.certificationNumber);
                currentPrice.push(model.price);
                currentTicks.push(model.ticks);
                currentItemCode.push(model.itemCode);
                currentTestValue.push(model.testValue);
            });
        });
        // Reset modal dialog
        this.setState({
            openUpdatePayment: false,
            openCertificatesModal: false,
        });
        try {
            this.showLoading(true);
            if (certNumber.length >= 1) {
                var result;
                var isFound = false;
                const { applicationTypeId, peerReviewStatus, managerStatus } = this.state.applicationDetails.data;
                // New application requires cert to have a new unique cert number, unless it is rejected (Dec 9 2019 Ken Poon)
                if (applicationTypeId === 1 && peerReviewStatus !== 'Rejected' && managerStatus !== 'Rejected') {
                    result = await this.props.checkCertNumberDuplication(certNumber);
                    isFound = result.data;
                } else {
                    // Renew/Review or any rejected application can reuse cert number, unless they change it which require
                    if (
                        (currentCertNumber != null && currentCertNumber.indexOf(certNumber) > -1) ||
                        (this.state.originalApplicationDetails.data.certificateNumberToRenewOrAmend != null &&
                            this.state.originalApplicationDetails.data.certificateNumberToRenewOrAmend.indexOf(certNumber) > -1)
                    ) {
                        isFound = false;
                    } else {
                        result = await this.props.checkCertNumberDuplication(certNumber);
                        isFound = result.data;
                    }
                }

                if (isFound) {
                    var brands = JSON.parse(this.state.applicationDetails.data.brands);
                    brands.forEach(brand => {
                        brand.models.forEach((model, index) => {
                            model.certificationNumber = currentCertNumber[index];
                            model.price = currentPrice[index];
                            model.ticks = currentTicks[index];
                            model.itemCode = currentItemCode[index];
                            model.testValue = currentTestValue[index];
                        });
                    });
                    var applicationDetails = Object.assign({}, this.state.applicationDetails);
                    applicationDetails.data.brands = JSON.stringify(brands);
                    this.setState({
                        applicationDetails,
                    });
                    this.showModalIf('Error', modalMessageDict['certNumberDuplication'], result.data);
                }
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getApproverId(applicationDetails) {
        try {
            if (applicationDetails != null && applicationDetails.data != null) {
                let caseOfficer = null;
                caseOfficer = await this.props.findApproverInfo(applicationDetails.data.caseOfficerId);
                let peerOfficer = null;
                peerOfficer = await this.props.findApproverInfo(applicationDetails.data.peerReviewId);
                let managerOfficer = null;
                managerOfficer = await this.props.findApproverInfo(applicationDetails.data.managerId);

                this.setState({
                    caseOfficer,
                    peerOfficer,
                    managerOfficer,
                });
            }
        } catch (error) {
            throw error;
        } finally {
        }
    }
    async getXeroInvoiceInfo(applicationDetails) {
        try {
            if (applicationDetails && applicationDetails.data && applicationDetails.data.invoiceId) {
                const { data } = await this.props.getInvoiceInfo(applicationDetails.data.invoiceId);
                let xeroInvoice = await this.props.getXeroInvoiceInfo(data.invoiceNumber);
                this.setState({
                    xeroInvoice,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    async printCert() {
        try {
            this.showLoading(true);
            await this.props.downloadCertificate(this.state.certificates.data[0].id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async printCertByCertNumber(certificationNumber) {
        try {
            this.showLoading(true);
            let cert = await this.props.findCertByCertNumber(certificationNumber);
            await this.props.downloadCertificate(cert.data.id);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async closeCase() {
        try {
            this.showLoading(true);
            let { data } = await this.props.closeCase(this.props.match.params.id);
            this.showModalIf('Confirmation', modalMessageDict['closeCaseConfimation'], data);
            this.showModalIf('Error', modalMessageDict['closeCaseFailure'], !data);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async viewProductCertificateByCertNumber(certificationNumber) {
        try {
            this.showLoading(true);
            let cert = await this.props.findCertByCertNumber(certificationNumber);
            history.push(`/staff/certification/certificates/product/certdetails/${cert.data.id}`);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async handleRegenerate() {
        try {
            this.showLoading(true);
            let brands = JSON.parse(this.state.applicationDetails.data.brands);
            //console.log("brands: ", brands)
            for (let brand of brands) {
                for (let model of brand.models) {
                    await this.props.handleRegenerateByCertNumber(model.certificationNumber, 'product');
                }
            }
            // brands.forEach(async brand => {
            //     brand.models.forEach(async model => {
            //         await this.props.handleRegenerateByCertNumber(model.certificationNumber, 'product');
            //     });
            // });
            //const { data } = await this.props.handleRegenerate(this.state.certificates.data[0].id, 'service');
            this.showModalIf('Confirmation', modalMessageDict['handleRegenerateConfirm'], true);
            this.setState({ enableCloseCaseBtn: false });
            // var blob = b64toBlob(data, 'application/pdf');
            // var blobUrl = URL.createObjectURL(blob);
            // window.open(blobUrl);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async printCertificate(certNumber) {
        try {
            this.showLoading(true);
            const res = await this.props.printCert(certNumber, 'product');
            var blob = b64toBlob(res.data, 'application/pdf');
            var blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
        } catch (error) {
            this.showModalIf('Error', modalMessageDict['printCertificateFailure'], error);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async authorizeInvoice() {
        try {
            this.showLoading(true);
            const res = await this.props.authorizingInvoice(this.state.applicationDetails.data.id);
            const falseCondition = res
                .toString()
                .toLowerCase()
                .includes('error');
            this.showModalIf('Error', modalMessageDict['authorizeInvoiceFailure'], falseCondition);
            this.showModalIf('Confirmation', modalMessageDict['authorizeInvoiceSuccess'], !falseCondition);
            this.componentDidMount();
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    /*async generateCertNumber() {
        let res = await this.props.generateCertNumber();
        var certNumber = res.data.certNumber;
        certNumber = 'SGBP ' + pad(certNumber, 4);
        this.setState({
            generatedCertNumber: certNumber,
        });
    }*/
    async sendToCaseOfficer() {
        const { applicationDetails, selectedOfficer } = this.state;
        // Add Overseas Transaction Charges
        let details = Object.assign({}, applicationDetails);
        let brands = JSON.parse(details.data.brands);
        brands.forEach(brand => {
            brand.OTCharges = this.state.otCharges;
            brand.disableInvoice = this.state.disableInvoice;
        });

        // Populate products pics, etc.
        let missingFields = false;
        if (brands && brands.length > 0) {
            if (brands[0].models && brands[0].models.length > 0) {
                // Merging product catalogue, product picture, and why green into brand json
                let models = brands[0].models.map((model, i) => {
                    if (model.certificationNumber === '' || model.itemCode == '' || model.ticks == '') {
                        missingFields = true;
                    }

                    let newModel = Object.assign({}, model);
                    if (!newModel.productCatalogue) {
                        newModel.productCatalogue = details.data.productCatalogue;
                    }
                    if (!newModel.productCatalogueFilename) {
                        newModel.productCatalogueFilename = details.data.productCatalogueFilename;
                    }
                    if (!newModel.productPicture) {
                        newModel.productPicture = details.data.productPicture;
                    }
                    if (!newModel.productPictureFilename) {
                        newModel.productPictureFilename = details.data.productPictureFilename;
                    }
                    if (!newModel.greenRemarks) {
                        newModel.greenRemarks = details.data.greenRemarks;
                    }
                    return newModel;
                });
                brands[0].models = models;
            }
        }
        const hasMissingFieldError = this.showModalIf('Error', modalMessageDict['missingCertApplicationFields'], missingFields);
        if (hasMissingFieldError) return;
        const hasError = this.showModalIf('Error', modalMessageDict['sendToCaseOfficerValid'], !selectedOfficer);
        if (hasError) return;
        this.showLoading(true);

        try {
            const brandJson = JSON.stringify(brands);
            const res = await this.props.saveCompleteAssessment(applicationDetails.data.id, selectedOfficer.value, brandJson);
            this.showModalIf('Error', modalMessageDict['sendToCaseOfficerFailure'], !res.ok);
            if (res.ok) this.showModal('Confimation', modalMessageDict['sendToCaseOfficerConfirm'], 'pushToCertList', false, true);

            var models = brands[0].models;
            var certNumberArray = [];
            var res1;
            if (models.length === 1) {
                res1 = await this.props.updateNumberSequence(1, 'product');
            } else {
                models.forEach(eachModel => {
                    certNumberArray.push(eachModel.certificationNumber);
                });
                let uniqueCertNumberArray = [...new Set(certNumberArray)];
                res1 = await this.props.updateNumberSequence(uniqueCertNumberArray.length, 'product');
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async sendForApproval() {
        try {
            this.showLoading(true);
            const { ok } = await this.props.sendForApproval(
                this.state.applicationDetails.data.id,
                this.state.selectedPeer.value,
                this.state.selectedManager.value,
                this.state.applicationDetails.data.brands,
            );
            this.showModalIf('Error', modalMessageDict['sendForApprovalFailure'], !ok);
            if (ok) this.showModal('Confirmation', modalMessageDict['sendForApprovalSuccess'], 'pushToCertList', false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async unlinkInvoice() {
        try {
            this.showLoading(true);
            const { ok } = await this.props.unlinkInvoice(this.state.applicationDetails.data.id);
            console.log(ok);
            this.showModalIf('Error', 'Failed to remove invoice', !ok);
            this.showModalIf('Confirmation', 'Invoice successfully removed.', ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async startAssigningNextApprover() {
        let sendForApprovalClicked = this.state.sendForApprovalClicked;
        sendForApprovalClicked = true;
        // let staffsRes = await this.props.getAllStaffs();
        // make staff to be same as managers
        // Refer to https://cydersg.atlassian.net/browse/SGBCCRM-1584
        let staffsRes = await this.props.getAllCertManagers();
        // change managers to be only super admin
        // https://cydersg.atlassian.net/browse/SUP-19
        let managersRes = await this.props.getAllSuperAdmin();
        let staffs = staffsRes.map((staff, i) => {
            return {
                label: staff.first_name + ' ' + staff.last_name,
                value: staff.id,
            };
        });
        let managers = managersRes.map((manager, i) => {
            return {
                label: manager.first_name + ' ' + manager.last_name,
                value: manager.id,
            };
        });
        this.setState({
            peers: staffs,
            managers,
            sendForApprovalClicked,
        });
        this.props.startAssigningNextApprover();
    }
    async startAssigningApprover() {
        // Updated by Fernando on 1 Oct 2019, based on https://cydersg.atlassian.net/browse/SGBCCRM-1640
        // let res = await this.props.getAllStaffs();
        let res = await this.props.getAllCertManagers();
        //this.generateCertNumber();
        let officers = res.map((officer, i) => {
            return {
                label: officer.first_name + ' ' + officer.last_name,
                value: officer.id,
            };
        });
        this.setState({
            officers,
        });
        this.props.startAssigningApprover();
    }
    async updateStatus() {
        const { id } = this.state.applicationDetails.data;
        const statusId = this.state.statusId.value;
        const { updateStatus } = this.props;
        try {
            this.showLoading(true);
            const { ok } = await updateStatus(id, statusId);
            this.showModalIf('Error', 'Failed to update status', !ok);
            this.showModalIf('Confirmation', 'Status successfully updated. Please reload the page to get the updated status.', ok);
            // window.location.reload();
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async addRemarks() {
        const { id, remarks } = this.state.applicationDetails.data;
        const { updateRemarks } = this.props;
        try {
            this.showLoading(true);
            const { ok } = await updateRemarks(id, remarks);
            this.showModalIf('Error', 'Failed to update remarks', !ok);
            this.showModalIf('Confirmation', 'Remarks successfully updated.', ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async downloadInvoice() {
        try {
            this.showLoading(true);
            const { data } = await this.props.getInvoiceInfo(this.state.applicationDetails.data.invoiceId);
            const hasError = this.showModalIf('Error', modalMessageDict['downloadInvoiceFailure'], !data || !data.invoiceNumber);
            if (hasError) return;
            window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_pte_ltd&invoiceno=${data.invoiceNumber}`;
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async discontinueApplication() {
        const { discontinueApplication } = this.props;
        const id = this.state.applicationDetails.data.id;
        try {
            this.showLoading(true);
            const res = await discontinueApplication(id);
            this.showModalIf('Error', modalMessageDict['discontinueApplicationFailure'], !res.ok);
            this.showModalIf('Confirmation', 'Application (ID: ' + id + ') successfully discontinued.', res.ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startAssessment() {
        await this.props.startAssessment(this.state.applicationDetails.data.id);
        this.componentDidMount();
    }
    async approveApplication() {
        const { id, peerReviewStatus } = this.state.applicationDetails.data;
        const { approveApplication } = this.props;
        const approvaLevel = peerReviewStatus === 'Pending' ? 'peer' : 'manager';
        try {
            this.showLoading(true);
            const { ok } = await approveApplication(id, approvaLevel);
            this.showModalIf('Error', modalMessageDict['approveApplicationFailure'], !ok);
            if (ok) this.showModal('Confirmation', modalMessageDict['approveApplicationSuccess'], 'pushToCertList', false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async rejectApplication() {
        const remarks = 'rejected';
        const approvaLevel = this.state.applicationDetails.data.peerReviewStatus === 'Pending' ? 'peer' : 'manager';
        try {
            this.showLoading(true);
            const { ok } = await this.props.rejectApplication(this.state.applicationDetails.data.id, approvaLevel, remarks);
            this.showModalIf('Error', modalMessageDict['rejectApplicationFailure'], !ok);
            if (ok) this.showModal('Confirmation', modalMessageDict['rejectApplicationSuccess'], 'pushToCertList', false, true);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async saveEditOnBehalf() {
        let res = await this.props.saveEditOnBehalf(this.state.applicationDetails.data.id, this.state.applicationDetails.data.brands);
        this.componentDidMount();
    }
    async markAsPaid() {
        const { markAsPaid } = this.props;
        const { id } = this.state.applicationDetails.data;
        try {
            this.showLoading(true);
            const { ok } = await markAsPaid(id);
            this.showModalIf('Error', modalMessageDict['markAsPaidFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['markAsPaidSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async savePaymentInfo() {
        try {
            this.showLoading(true);
            this.setState({ openUpdatePayment: false });
            const { paymentInfo, applicationDetails } = this.state;
            const { datePaymentReceived } = paymentInfo;
            var newInfo = Object.assign({}, paymentInfo);
            if (datePaymentReceived) {
                const dateReceived = moment(datePaymentReceived).format('YYYY-MM-DD');
                newInfo.datePaymentReceived = dateReceived;
            }
            const { error } = await this.props.savePaymentInfo(newInfo, applicationDetails.data.id);
            this.showModalIf('Error', modalMessageDict['savePaymentInfoFailure'], error);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async startFeedback() {
        const { startFeedback } = this.props;
        const { id } = this.state.applicationDetails.data;
        try {
            let { ok } = await startFeedback(id);
            this.showModalIf('Error', modalMessageDict['startFeedbackFailure'], !ok);
            this.showModalIf('Confirmation', modalMessageDict['startFeedbackSuccess'], ok);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async completeApplication() {
        this.showLoading(true);
        const { brands, id } = this.state.applicationDetails.data;
        const { completeApplication } = this.props;
        if (this.state.applicationDetails.data.applicationTypeId !== 3) {
            var parsedBrands = JSON.parse(brands);
            parsedBrands.forEach(brand => {
                brand.models.forEach(model => {
                    model.issued_on = moment().format('YYYY-MM-DD');
                    model.expiry_date = moment()
                        .add(2, 'years')
                        .add(-1, 'days')
                        .format('YYYY-MM-DD');
                });
            });
            var brandJson = JSON.stringify(parsedBrands);
        } else {
            var brandJson = brands;
        }

        try {
            const { ok, data } = await completeApplication(id, brandJson, 'product');
            this.showModalIf('Error', modalMessageDict['completeApplicationFailure'], data.errno || !ok);
            this.showModalIf('Confirmation', modalMessageDict['completeApplicationSuccess'], data && !data.errno);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    async takeUpConfirm() {
        let { selectedTemplate, applicationDetails, newTemplateChoosen } = this.state;
        const { acceptApplication } = this.props;
        try {
            this.showLoading(true);

            const { data } = await acceptApplication(
                applicationDetails.data.id,
                selectedTemplate.value,
                new Number(this.props.usePreviousAssessmentTemplate.value).valueOf(),
            );

            const hasError = this.showModalIf('Error', modalMessageDict['takeUpConfirmFailure'], data);
            if (hasError) this.props.takeUpCase(false);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            this.componentDidMount();
        }
    }
    // ONCHANGE HANDLERS
    handleChange(e) {
        const name = e.target.name;

        // Updated by Fernando on 20 June 2020
        // CRMSD-290, customer requested to remove this validation

        // if (e.target.name === 'certificationNumber') {
        //     var regex = '^$|^(([1-9]{1}[0-9]{0,3}){1})$';
        //     regex = new RegExp(regex);
        //     regex.test(e.target.value) && this.setState({ [name]: e.target.value });
        //     return;
        // } else {
        //     this.setState({ [name]: e.target.value });
        // }

        this.setState({ [name]: e.target.value });
    }
    onTemplateChange(e) {
        this.setState({
            newTemplateChoosen: true,
            selectedTemplate: e,
        });
        this.props.storeSelectedTemplate(e);
    }
    onSelectedOfficerChange(e) {
        this.setState({
            selectedOfficer: e,
        });
    }
    onSelectedPeerChange(e) {
        this.setState({
            selectedPeer: e,
        });
    }
    onSelectedManagerChange(e) {
        this.setState({
            selectedManager: e,
        });
    }
    onStatusChange(e) {
        this.setState({
            statusId: e,
        });
    }
    onRemarksChange(e) {
        var applicationDetails = this.state.applicationDetails;
        applicationDetails.data.remarks = e.target.value;
        this.setState({
            applicationDetails,
        });
    }
    onOverseasTransactionChargesChange(e) {
        this.setState({
            otCharges: new Number(e.target.checked).valueOf(),
        });
    }
    onDisableInvoiceChange(e) {
        this.setState({
            disableInvoice: new Number(e.target.checked).valueOf(),
        });
    }
    viewPreviousCertificate(oldCertId) {
        history.push(`/staff/certification/certificates/product/certdetails/${oldCertId}`);
    }
    viewPreviousApplication(oldApplicationId) {
        window.location.href = `/staff/certification/applications/viewproduct/${oldApplicationId}`;
        this.componentDidMount(oldApplicationId);
    }
    viewPreviousAssessment(oldApplicationId, oldAssessmentId, oldAssessmentTemplateId) {
        this.setState({
            newTemplateChoosen: false,
        });
        history.push(
            `/staff/certification/applications/viewproducttemplate/${oldApplicationId}/${oldAssessmentTemplateId}/${oldAssessmentId}/${this.state.applicationDetails.data.id}`,
        );
    }
    // CONFIMATION / OTHER DIALOG ACTION DIALOG
    markAsPaidConfimation = () => {
        this.showModal('Confirmation', modalMessageDict['markAsPaidConfirmation'], 'markAsPaid');
    };
    startFeedbackDialog() {
        this.showModal('Confirmation', modalMessageDict['startFeedbackConfirm'], 'feedback', false, false);
    }
    discontinueDialog() {
        this.showModal('Confirmation', modalMessageDict['discontinueDialogConfirm'], 'discontinue', false, false);
    }
    authorizeInvoiceDialog() {
        this.showModal('Confirmation', modalMessageDict['authorizeInvoiceConfirm'], 'authorizeInvoice', false, false);
    }
    async updatePayment() {
        await this.getPaymentInfo();
        this.showModal('Update Payment Information', null, 'updatePayment');
        this.setState({
            openUpdatePayment: true,
            openCertificatesModal: false,
        });
    }
    sendReceipt(certificatelink, id) {
        this.props.sendReceipt(certificatelink, id);
    }

    async printMUA() {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printCertificationApplication(this.props.match.params.id, 'product', true);
            window.open(SGBC_S3_HOST + '/temp/' + res.file);
        } catch (error) {
            this.showModal('Error', 'Application cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }

    createAndSendReceiptConfirmation() {
        this.showModal('Confirmation', modalMessageDict['createAndSendReceiptConfirm'], 'sendReceipt', null, true);
    }
    async createAndSendReceipt() {
        let paymentData = await this.props.getPaymentInfo(this.state.applicationDetails.data.paymentId);

        // Updated: 19 April 2019 (paymentStatus was not found)
        if (paymentData.message.toLowerCase().includes('successful')) {
            let data = await this.props.downloadReceipt(this.state.applicationDetails.data.paymentId);

            // Failure
            const hasError = this.showModalIf(
                'Error',
                modalMessageDict['downloadReceiptFailureNoPaymentDone'],
                !data || !this.state.applicationDetails.data.paymentId,
            );
            if (hasError) return;

            // Success
            this.props.sendReceipt(data.file, this.props.match.params.id);
            this.setState({ sendReceiptPressed: true });
            this.showModal('Confirmation', modalMessageDict['createAndSendReceiptSuccess'], 'Alert', null, true);
        } else {
            this.showModalIf('Error', modalMessageDict['createAndSendReceiptFailure'], !paymentData.paymentStatus);
        }
    }
    async downloadReceipt() {
        // this.props.downloadReceipt()
        try {
            this.showLoading(true);
            let paymentData = await this.props.getPaymentInfo(this.state.applicationDetails.data.paymentId);
            if (paymentData != null && paymentData.data != null && paymentData.data.datePaymentReceived != null) {
                let data = await this.props.downloadReceipt(this.state.applicationDetails.data.paymentId);
                const hasError = this.showModalIf(
                    'Error',
                    modalMessageDict['downloadReceiptFailure'],
                    !data || !this.state.applicationDetails.data.paymentId,
                );
                if (hasError) return;
                window.open(data.file, '_blank');
            } else {
                const hasError = this.showModalIf(
                    'Error',
                    modalMessageDict['downloadReceiptFailureNoPaymentDone'],
                    !paymentData.paymentStatus,
                );
                if (hasError) return;
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    // DIALOG MODAL
    modalAction() {
        const { modal } = this.state;
        if (modal.type === 'sendReceipt') this.createAndSendReceipt();
        if (modal.type === 'updatePayment') this.savePaymentInfo();
        if (modal.type === 'markAsPaid') this.markAsPaid();
        if (modal.type === 'authorizeInvoice') this.authorizeInvoice();
        if (modal.type === 'discontinue') this.discontinueApplication();
        if (modal.type === 'finalisation') this.startFeedback();
        if (modal.type === 'feedback') this.startFeedback();
        if (modal.type === 'pushToCertList') history.push(`/staff/certification/applications`);
        this.modalToggle();
    }
    negativeModalAction() {
        this.setState({ openUpdatePayment: false });
        this.modalToggle();
    }
    modalToggle() {
        const { modal } = this.state;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onTextChanged(e) {
        var paymentInfo = this.state.paymentInfo;
        paymentInfo[e.target.id] = e.target.value;
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            paymentInfo,
            modal,
        });
    }
    setPaymentMode(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.modeOfPaymentId = e.value;
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            selectedPaymentMode: e,
            paymentInfo,
            modal,
        });
    }
    paymentDateOnChange(e) {
        var paymentInfo = Object.assign({}, this.state.paymentInfo);
        paymentInfo.datePaymentReceivedCtrl = e || '';
        paymentInfo.datePaymentReceived = e ? e.format('YYYY-MM-DD') : '';
        var modal = this.state.modal;
        modal.refresh = new Date().getTime();
        this.setState({
            paymentInfo,
            modal,
        });
    }
    toggleApplicantDetailsOpen = () => {
        this.setState({ isApplicantDetailsOpen: !this.state.isApplicantDetailsOpen });
    };

    // RENDER
    render() {
        const selectedProductType = Object.assign({}, this.state.productTypeInfo.data);
        const { muaNameError, muaDesignationError, muaEmailError, muaContactNumberError } = this.state;
        const { certificationNumber, itemCode, numberOfTicks, testValue } = this.state;
        const certModal = <CertListModal loading={this.state.loading} certificates={this.state.certificates} />;
        let appStatus = '';
        if (this.state.applicationStatus.data) {
            appStatus = this.state.applicationStatus.data.status;
            if (appStatus === 'Approval') {
                if (this.state.applicationDetails.data.peerReviewStatus === 'Rejected') {
                    appStatus = 'Rejected by Peer';
                } else if (this.state.applicationDetails.data.peerReviewStatus === 'Pending') {
                    appStatus = 'Peer Approval';
                } else if (this.state.applicationDetails.data.managerStatus === 'Rejected') {
                    appStatus = 'Rejected by Manager';
                } else if (this.state.applicationDetails.data.managerStatus === 'Pending') {
                    appStatus = 'Manager Approval';
                }
            }
        }
        // REASSIGN STEP
        let applicationStep = (this.state.applicationStatus.data && this.state.applicationStatus.data.id) || 0;
        switch (applicationStep) {
            case 11:
                applicationStep = 0;
                break;
        }

        const muaPanel =
            this.state.certificateApplicationStatusId != null &&
            this.state.certificateApplicationStatusId >= 8 &&
            this.state.certificateApplicationStatusId <= 10 ? (
                <MarkUserAgreementPanel
                    applicationId={this.props.match.params.id}
                    readonly
                    updateMarkUserAgreementStates={this.updateMarkUserAgreementStates}
                    states={this.state}
                    applicationSource={`Certification`}
                    errors={{ muaEmailError, muaNameError, muaDesignationError, muaContactNumberError }}
                />
            ) : (
                ''
            );

        // UPDATE PAYEMENT INFO DIALOG
        const customDialog = (
            <DialogComponent
                paymentInfo={this.state.paymentInfo}
                onTextChanged={this.onTextChanged}
                paymentModes={this.state.paymentModes}
                setPaymentMode={this.setPaymentMode}
                paymentDateOnChange={this.paymentDateOnChange}
                selectedPaymentMode={this.state.selectedPaymentMode}
            />
        );
        return (
            <div className="page-widget">
                <DialogModal
                    size={this.state.openCertificatesModal && 'lg'}
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={
                        this.state.openUpdatePayment
                            ? customDialog
                            : this.state.openCertificatesModal
                            ? certModal
                            : this.state.modal.modalMessage
                    }
                    modalHeader={this.state.modal.modalHeader}
                    refresh={this.state.modal.refresh}
                />
                {this.state.loading && <LoadingSpinner center />}
                {!this.state.loading && this.state.applicationDetails.data && this.state.applicationStatus.data && (
                    <BigForm
                        title={
                            this.state.applicationDetails.data.applicationTypeId !== 2
                                ? 'Product Certification Application'
                                : 'Product Renewal Certification Application'
                        }
                    >
                        <div>
                            <ApplicationStatus visible={true} step={applicationStep} />

                            <ProductDetailsPanel
                                printMUA={this.printMUA}
                                visible={true}
                                itemCode={this.props.itemCode}
                                selectState={this.state}
                                certificateDetails={{
                                    certificationNumber,
                                    itemCode,
                                    numberOfTicks,
                                    testValue,
                                }}
                                viewCertificates={this.viewCertificates}
                                checkCertNumberDuplication={this.checkCertNumberDuplication}
                                takeUpCase={this.takeUpCase}
                                takeUpConfirm={this.takeUpConfirm}
                                addRemarks={this.addRemarks}
                                updateStatus={this.updateStatus}
                                statusId={this.state.statusId}
                                onStatusChange={this.onStatusChange}
                                greenRemarks={this.state.applicationDetails.data.greenRemarks}
                                onHandleChange={this.handleChange}
                                applicationTypeOldRenew={this.state.applicationDetails.data.applicationTypeId}
                                prevAssessmentId={this.state.applicationDetails.data.assessmentId}
                                previewOldTemplate={this.previewOldTemplate}
                                previewTemplate={this.previewTemplate}
                                newTemplateChoosen={this.state.newTemplateChoosen}
                                onRemarksChange={this.onRemarksChange}
                                remarks={this.state.applicationDetails.data.remarks}
                                completeAssessment={this.startAssigningApprover}
                                unlinkInvoice={this.unlinkInvoice}
                                completeAssessmentDisabled={this.state.completeAssessmentDisabled}
                                viewPreviousCertificate={this.viewPreviousCertificate}
                                viewPreviousApplication={this.viewPreviousApplication}
                                viewPreviousAssessment={this.viewPreviousAssessment}
                                sendForApproval={this.sendForApproval}
                                sendToCaseOfficer={this.sendToCaseOfficer}
                                officers={this.state.officers}
                                selectedOfficer={this.state.selectedOfficer}
                                onSelectedOfficerChange={this.onSelectedOfficerChange}
                                sendForNextApproval={this.startAssigningNextApprover}
                                templates={this.state.templates}
                                selectedTemplate={this.state.selectedTemplate}
                                onTemplateChange={this.onTemplateChange}
                                discontinue={this.discontinueApplication}
                                startAssessment={this.startAssessment}
                                viewAssessment={this.viewAssessment}
                                updatePayment={this.updatePayment}
                                downloadReceipt={this.downloadReceipt}
                                viewCatalogue={this.viewCatalogue}
                                viewPicture={this.viewPicture}
                                takeUpCaseStarted={this.props.application.view.takeUpCaseStarted}
                                assignApproverStarted={this.props.application.view.assignApproverStarted}
                                assignNextApproverStarted={this.props.application.view.assignNextApproverStarted}
                                productBrands={this.state.applicationDetails.data.brands}
                                updateProductBrandsWithCertificate={this.updateProductBrandsWithCertificate}
                                otCharges={this.state.otCharges}
                                disableInvoice={this.state.disableInvoice}
                                onOverseasTransactionChargesChange={this.onOverseasTransactionChargesChange}
                                onDisableInvoiceChange={this.onDisableInvoiceChange}
                                unassignedBrandFromCertificate={this.unassignedBrandFromCertificate}
                                status={appStatus}
                                manufacturerDetails={this.state.applicationDetails.data.manufacturerDetails}
                                isManufacturer={this.state.applicationDetails.data.isManufacturer}
                                selectedProductType={selectedProductType}
                                sendWithdrawalEmail={this.sendWithdrawalEmail}
                                submittedOn={
                                    this.state.applicationDetails.data.applicationFirstSubmittedTimestamp != null &&
                                    this.state.applicationDetails.data.applicationFirstSubmittedTimestamp != ''
                                        ? moment(this.state.applicationDetails.data.applicationFirstSubmittedTimestamp).format('DD/MM/YYYY')
                                        : '-'
                                }
                                title={this.state.companyDetails.data.name}
                                selectedPeer={this.state.selectedPeer}
                                peers={this.state.peers}
                                peerReviewStatus={this.state.applicationDetails.data.peerReviewStatus}
                                onSelectedPeerChange={this.onSelectedPeerChange}
                                selectedManager={this.state.selectedManager}
                                managers={this.state.managers}
                                managerStatus={this.state.applicationDetails.data.managerStatus}
                                onSelectedManagerChange={this.onSelectedManagerChange}
                                approve={this.approveApplication}
                                reject={this.rejectApplication}
                                startFeedback={this.startFeedback}
                                startPreparation={this.startPreparation}
                                completeApplication={this.completeApplication}
                                markAsPaid={this.markAsPaidConfimation}
                                type={this.state.applicationDetails.data.isProductCertificateApplication === 1 ? 'product' : 'service'}
                                printCertificate={this.printCertificate}
                                peerReviewRemarks={this.state.applicationDetails.data.peerReviewRemarks}
                                managerRemarks={this.state.applicationDetails.data.managerRemarks}
                                editApplication={this.editApplication}
                                authorizeInvoiceDialog={this.authorizeInvoiceDialog}
                                discontinueDialog={this.discontinueDialog}
                                startFeedbackDialog={this.startFeedbackDialog}
                                downloadInvoice={this.downloadInvoice}
                                startTagging={this.startTagging}
                                taggingPanelVisible={this.state.taggingPanelVisible}
                                applicationId={this.props.match.params.id}
                                applicationDetails={this.state.applicationDetails.data}
                                viewSurveyResult={this.viewSurveyResult}
                                //generatedCertNumber={this.state.generatedCertNumber}
                                takeOverCase={this.takeOverCase}
                                returnMua={this.returnMua}
                                printCertByCertNumber={this.printCertByCertNumber}
                                handleRegenerate={this.handleRegenerate}
                                viewProductCertificateByCertNumber={this.viewProductCertificateByCertNumber}
                                closeCase={this.closeCase}
                                xeroInvoice={this.state.xeroInvoice}
                                profile={this.props.profile}
                                approvers={{
                                    caseOfficer: this.state.caseOfficer,
                                    peerOfficer: this.state.peerOfficer,
                                    managerOfficer: this.state.managerOfficer,
                                }}
                                attachPreviousTemplate={this.props.attachPreviousTemplate}
                                createAndSendReceipt={this.createAndSendReceipt}
                                createAndSendReceiptConfirmation={this.createAndSendReceiptConfirmation}
                                toggleApplicantDetailsOpen={this.toggleApplicantDetailsOpen}
                                isApplicantDetailsOpen={this.state.isApplicantDetailsOpen}
                                muaPanel={muaPanel}
                                markAsPaidDisabled={this.state.markAsPaidDisabled}
                                sendForApprovalClicked={this.state.sendForApprovalClicked}
                                paymentInfo={this.state.paymentInfo}
                                removeInvoice={this.removeInvoice}
                            />
                        </div>
                    </BigForm>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const xero = state.staffCertificationApplicationReducer && state.staffCertificationApplicationReducer.xeroCodes;
    const itemCode = xero && xero.map(item => item.itemCode);
    const applicationId = ownProps.match && ownProps.match.params.id;
    const profile = state.profile;

    return {
        ownProps,
        application: state.memberProductApplication,
        certifications: state.memberCertifications,
        xeroCodes: state.staffCertificationApplicationReducer.xeroCodes,
        itemCode,
        applicationId,
        profile,
        selectedTemplate: state.staffCertificationApplicationReducer.selectedTemplate,
        usePreviousAssessmentTemplate: state.staffCertificationApplicationReducer.usePreviousAssessmentTemplate,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        authorizingInvoice: id => {
            return dispatch(authorizingInvoice(id));
        },
        takeUpCase: take => {
            dispatch(setTakeUpCase(take));
        },
        resetAssigningApprover: () => {
            dispatch(setAssignApprover(false));
            dispatch(setAssignNextApprover(false));
        },
        startAssigningApprover: () => {
            dispatch(setAssignApprover(true));
        },
        sendForApproval: (id, selectedPeerId, selectedManagerId, brands) => {
            return dispatch(sendForApproval(id, selectedPeerId, selectedManagerId, brands));
        },
        startAssigningNextApprover: () => {
            dispatch(setAssignNextApprover(true));
        },
        getApplicationInfo: id => {
            return dispatch(getApplicationInfo(id));
        },
        getCompanyInfo: id => {
            return dispatch(getCompanyInfo(id));
        },
        getApplicationStatusInfo: id => {
            return dispatch(getApplicationStatusInfo(id));
        },
        getProductTypeInfo: id => {
            return dispatch(getProductTypeInfo(id));
        },
        getProductAssessmentTemplatesByProductType: id => {
            return dispatch(getProductAssessmentTemplatesByProductType(id));
        },
        getProductAssessmentTemplatesByProductCategory: id => {
            return dispatch(getProductAssessmentTemplatesByProductCategory(id));
        },
        acceptApplication: (id, templateId, newTemplateChoosen) => {
            // Reset the stored template
            dispatch({
                type: 'STAFF_CERT_APPLICATION_STORE_SELECTED_TEMPLATE',
                template: {},
            });
            return dispatch(acceptApplication(id, templateId, newTemplateChoosen));
        },
        discontinueApplication: id => {
            return dispatch(discontinueApplication(id));
        },
        startAssessment: id => {
            return dispatch(startAssessment(id));
        },
        saveCompleteAssessment: (id, selectedOfficerId, brands) => {
            return dispatch(completeAssessment(id, selectedOfficerId, brands));
        },
        unlinkInvoice: id => {
            return dispatch(unlinkInvoice(id));
        },
        updateRemarks: (id, remarks) => {
            return dispatch(updateRemarks(id, remarks));
        },
        updateStatus: (id, statusId) => {
            return dispatch(updateStatus(id, statusId));
        },
        getAllStaffs: () => {
            return dispatch(getAllStaffs());
        },
        getAllCertManagers: () => {
            return dispatch(getAllCertManagers());
        },
        getAllSuperAdmin: () => {
            return dispatch(getAllSuperAdmin());
        },
        approveApplication: (id, approvalLevel) => {
            return dispatch(approveApplication(id, approvalLevel));
        },
        rejectApplication: (id, approvalLevel, remarks) => {
            return dispatch(rejectApplication(id, approvalLevel, remarks));
        },
        rejectApplication: (id, approvalLevel, remarks) => {
            return dispatch(rejectApplication(id, approvalLevel, remarks));
        },
        saveEditOnBehalf: (id, brands) => {
            return dispatch(saveEditOnBehalf(id, brands));
        },
        startFeedback: id => {
            return dispatch(startFeedback(id));
        },
        startPreparation: id => {
            return dispatch(startPreparation(id));
        },
        completeApplication: (id, brands, type) => {
            return dispatch(completeApplication(id, brands, type, null));
        },
        markAsPaid: id => {
            return dispatch(markAsPaid(id));
        },
        getInvoiceInfo: id => {
            return dispatch(getInvoiceInfo(id));
        },
        getBCAOptions: () => {
            return dispatch(getBCAOptions());
        },
        getOtherOptions: () => {
            return dispatch(getOtherOptions());
        },
        updateTagging: (id, tagging) => {
            return dispatch(updateTagging(id, tagging));
        },
        printCert: certNumber => {
            return dispatch(printCertByCertNumber(certNumber, 'product'));
        },
        setPostSurveyResultViewUrl: postSurveyResultViewUrl => {
            return dispatch({
                type: 'SHARED_SURVEY_SET_POST_SURVEY_RESULT_VIEW_URL',
                postSurveyResultViewUrl,
            });
        },
        sendReceipt: (certificatelink, id) => {
            return dispatch(sendReceipt(certificatelink, id));
        },
        fetchXeroInventoryCodes: () => dispatch(fetchXeroInventoryCodes()),
        getPaymentModes: () => {
            return dispatch(loadPaymentMode());
        },
        savePaymentInfo: (paymentInfo, id) => {
            return dispatch(savePaymentInfo(paymentInfo, id));
        },
        takeOverCase: id => {
            return dispatch(takeOverCase(id));
        },
        sendWithdrawalEmail: id => {
            return dispatch(sendWithdrawalEmail(id));
        },
        generateCertNumber: () => {
            return dispatch(generateCertNumber('SGBP'));
        },
        attachPreviousTemplate: attachPreviousTemplate => {
            return dispatch({
                type: 'ATTACH_PREVIOUS_TEMPLATE',
                attachPreviousTemplate,
            });
        },
        storeSelectedTemplate: template => {
            return dispatch({
                type: 'STAFF_CERT_APPLICATION_STORE_SELECTED_TEMPLATE',
                template,
            });
        },
        returnMua: id => {
            return dispatch(returnMua(id));
        },
        removeInvoice: id => {
            return dispatch(removeInvoice(id));
        },
        closeCase: id => {
            return dispatch(closeCase(id));
        },
        handleRegenerateByCertNumber: (certificateId, type) => {
            return dispatch(handleRegenerateByCertNumber(certificateId, type));
        },
        downloadCertificate: certificateId => {
            return dispatch(downloadCertificate(certificateId));
        },
        findCertByCertNumber: certificationNumber => {
            return dispatch(findCertByCertNumber(certificationNumber));
        },
        getXeroInvoiceInfo: certificationNumber => {
            return dispatch(getXeroInvoiceInfo(certificationNumber));
        },
        findApproverInfo: approverId => {
            return dispatch(findApproverInfo(approverId));
        },
        downloadReceipt: paymentId => {
            return dispatch(createReceiptInS3ForCertificate(paymentId));
        },
        getPaymentInfo: paymentId => {
            return dispatch(getPaymentDetails(paymentId));
        },
        checkCertNumberDuplication: certNumber => {
            return dispatch(checkCertNumberDuplication(certNumber));
        },
        getAssessmentScoresheet: id => {
            return dispatch(getAssessmentScoresheet(id));
        },
        setUsePrevAssessmentTemplaTe: () => {
            return dispatch({
                type: 'STAFF_CERT_APPLICATION_USE_PREV_TEMPLATE',
            });
        },
        updateNumberSequence: (value, type) => {
            return dispatch(updateNumberSequence(value, type));
        },
        getAllCertificateList: (type, offset, limit) => {
            return dispatch(getAllCertificateList(type, offset, limit));
        },
        printCertificationApplication: (id, type, muaLocked) => {
            return dispatch(printCertificationApplication(id, type, muaLocked));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationViewPage),
);
