import React from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import StoredLayout from 'layouts/StoredLayout';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Button, CardBody, Row, Col } from 'reactstrap';
import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';
import { RowColWrapper } from 'pages/surveys/SurveysDetailPage';

import { save, update, saveOrUpdate, remove, loadByApplicationId } from 'actions/staff/certification/markUserAgreementAction.js';
import { capitalizeFirstLetter } from 'js/util';

class MarkUserAgreementPanel extends React.Component {
    _isMounted = true;
    state = {
        loading: false,
        mode: '',
        data: {
            muaContactNumber: '',
            muaDesignation: '',
            muaAgreed: false,
            muaEmail: '',
            muaName: '',
            applicationSource: null,
            applicationId: null,
            muaAgreedTerms: false,
        },
        modal: {
            type: 'save',
            modalHeader: '',
            modalMessage: '',
            showModal: false,
            positiveButtonHide: true,
            negativeButtonHide: true,
        },
    };
    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        this.determineMode();
        this.getRelevantStuff();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getRelevantStuff = async () => {
        const { applicationId, applicationSource, updateMarkUserAgreementStates } = this.props;
        try {
            this.showLoading(true);
            const { data } = await this.props.load(applicationId);
            if (data) {
                var newData = data[0] || {};
                newData.muaAgreed = 0;
                newData.muaAgreedTerms = 0;
                updateMarkUserAgreementStates && updateMarkUserAgreementStates(newData);
                if (this._isMounted) {
                    this.setState({
                        data: newData,
                    });
                }
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
        return;
    };
    showLoading = loading => {
        if (this._isMounted) {
            this.setState({
                loading,
            });
        }
    };
    back = () => {
        history.replace('/staff/memberships/membership-renewal-status');
    };
    determineMode() {
        const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
        this.setState({ mode });
    }
    prepareDataBeforeSave = data => {
        var newData = data;
        const { applicationId, applicationSource } = this.props;
        newData.applicationId = applicationId;
        newData.applicationSource = applicationSource;
        newData.muaAgreed = new Number(newData.muaAgreed).valueOf();
        return newData;
    };
    save = () => {
        const { muaName, muaEmail, muaContactNumber, muaDesignation } = this.props.states;
        var data = this.prepareDataBeforeSave(this.state.data);
        const additionals = { muaName, muaEmail, muaContactNumber, muaDesignation };
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            let combinedData = { ...data, ...additionals };
            this.props
                .save(combinedData)
                .then(res => this.showLoading(false))
                .catch(error => this.showLoading(false));
        } else {
            let combinedData = { ...data, ...additionals };
            this.props
                .update(combinedData)
                .then(res => this.showLoading(false))
                .catch(error => this.showLoading(false));
        }
    };
    remove = () => {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => history.replace('/staff/memberships/membership-renewal-status'))
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    };
    muaAgreedChange = e => {
        var data = this.state.data;
        data.muaAgreed = e.target.checked;
        this.setState({ data });
        this.props.handlers.muaAgreedChange(e);
    };
    muaAgreedTermsChanged = e => {
        var data = this.state.data;
        data.muaAgreedTerms = e.target.checked;
        this.setState({ data });
        this.props.handlers.muaAgreedTermsChanged(e);
    };
    muaContactNumberChange = e => {
        var data = this.state.data;
        data.muaContactNumber = e.target.value;
        this.setState({ data });
    };
    muaEmailChange = e => {
        var data = this.state.data;
        data.muaEmail = e.target.value;
        this.setState({ data });
    };
    muaDesignationChange = e => {
        var data = this.state.data;
        data.muaDesignation = e.target.value;
        this.setState({ data });
    };
    muaNameChange = e => {
        var data = this.state.data;
        data.muaName = e.target.value;

        if (!data.muaName) {
            this.setState({ muaNameError: 'This field is mandatory' });
        } else {
            this.setState({ data, muaNameError: '' });
        }
    };
    // MODAL
    modalAction = () => {
        if (this.state.modal.type === 'deleteConfirm') this.remove();
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    getAllCertNumber() {
        const { models } = this.props;
        if (!models) return;
        const certNums = models.filter(x => x).map(x => x.certificationNumber);
        return certNums.join(', ');
    }
    render() {
        const muaEmailError = this.props.errors === undefined ? null : this.props.errors.muaEmailError;
        const { states, handlers } = this.props;
        const { data } = this.state;
        console.log('States', states);
        if (this.state.loading) return <LoadingSpinner />;
        return (
            <FilterWrapper isMember={window.location.pathname.toLowerCase().includes('member')}>
                <CardBody style={!this.props.padding ? { padding: 'unset' } : {}}>
                    {(this.props.readonly && this.state.data.id) || !this.props.readonly ? (
                        <React.Fragment>
                            {!this.props.readonly && (
                                <React.Fragment>
                                    <Row className="pb-4">
                                        <Col xs={6}>
                                            <h3 className="text-bold">Mark User Agreement</h3>
                                        </Col>
                                        {this.props.printMUAPage && (
                                            <Col xs={6}>
                                                <Button
                                                    disabled={!this.props.noError}
                                                    className="float-right primary-btn-style-outline"
                                                    onClick={this.props.printMUAPage}
                                                >
                                                    <i className="mr-1 material-icons align-text-bottom ">print</i>
                                                    Print
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="form-inline">
                                        {this.props.type == 'product' && (
                                            <RowColWrapper xs="12">
                                                Upon completion of the Mark User Agreement, you will be able to access and download the
                                                Certificate(s) as well as the SGBP logo(s) for use on your marketing collaterals. Please
                                                note that the SGBP logo(s) used must be used with the corresponding certified product(s) and
                                                SGBC reserves the right to revoke any Certificate(s) if marketing materials are found to
                                                bear inaccurate or misused SGBP logos.
                                            </RowColWrapper>
                                        )}
                                        {this.props.type == 'service' && (
                                            <RowColWrapper xs="12">
                                                Upon completion of the Mark User Agreement, you will be able to access and download the
                                                Certificate as well as the SGBS logo for use on your marketing collaterals. Please note that
                                                the SGBS logo used must be used with the corresponding services and SGBC reserves the right
                                                to revoke any Certificate if marketing materials are found to bear inaccurate or misused
                                                SGBS logo.
                                            </RowColWrapper>
                                        )}

                                        {this.props.certId && (
                                            <RowColWrapper xs="12" className="mt-4">
                                                <h5 className="text-bold">
                                                    This Mark User Agreement is for {capitalizeFirstLetter(this.state.mode)} Certification
                                                    No. {this.getAllCertNumber()}.
                                                </h5>
                                            </RowColWrapper>
                                        )}
                                    </div>
                                    <hr />
                                </React.Fragment>
                            )}
                            <h4 className="text-bold mb-4">Authorised Personnel Details</h4>
                            <Row>
                                <Col xs={5}>
                                    <InputField
                                        colstyle={8}
                                        label="Name"
                                        readOnly={this.props.readonly}
                                        labelClassName="field-header"
                                        required={!this.props.readonly}
                                        inputProps={{
                                            type: 'text',
                                            name: 'muaName',
                                            id: 'muaName',
                                            value: states.muaName ? states.muaName : data.muaName,
                                            onChange: handlers !== undefined ? handlers.handleMuaName : () => {},
                                        }}
                                    />
                                </Col>
                                <Col xs={5}>
                                    <InputField
                                        label="Designation"
                                        colstyle={8}
                                        readOnly={this.props.readonly}
                                        labelClassName="field-header"
                                        required={!this.props.readonly}
                                        inputProps={{
                                            type: 'text',
                                            name: 'muaDesignation',
                                            id: 'muaDesignation',
                                            value: states.muaDesignation ? states.muaDesignation : data.muaDesignation,
                                            onChange: handlers !== undefined ? handlers.handleMuaDesignation : () => {},
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={5}>
                                    <InputField
                                        label="Email"
                                        colstyle={8}
                                        readOnly={this.props.readonly}
                                        labelClassName="field-header"
                                        error={muaEmailError}
                                        required={!this.props.readonly}
                                        inputProps={{
                                            type: 'text',
                                            name: 'muaEmail',
                                            id: 'muaEmail',
                                            value: states.muaEmail ? states.muaEmail : data.muaEmail,
                                            onChange: handlers !== undefined ? handlers.handleMuaEmail : () => {},
                                        }}
                                    />
                                </Col>
                                <Col xs={5}>
                                    <InputField
                                        label="Contact Number"
                                        colstyle={12}
                                        readOnly={this.props.readonly}
                                        labelClassName="field-header"
                                        required={!this.props.readonly}
                                        inputProps={{
                                            type: 'text',
                                            name: 'muaContactNumber',
                                            id: 'muaContactNumber',
                                            value: states.muaContactNumber ? states.muaContactNumber : data.muaContactNumber,
                                            onChange: handlers !== undefined ? handlers.handleMuaContactNumber : () => {},
                                        }}
                                    />
                                </Col>
                            </Row>
                            {!this.props.readonly && !this.props.customAgreeComponent && (
                                <React.Fragment>
                                    <Row>
                                        <InputField
                                            label="I confirm that the information I provided is correct"
                                            readOnly={this.props.readonly}
                                            labelClassName="field-header"
                                            inputProps={{
                                                type: 'checkbox',
                                                id: 'muaAgreedTerms',
                                                checked: this.state.data.muaAgreedTerms,
                                                onChange: this.muaAgreedTermsChanged,
                                            }}
                                        />
                                    </Row>
                                    <Row>
                                        <InputField
                                            label="I am the authorised person to mark user agreement"
                                            readOnly={this.props.readonly}
                                            labelClassName="field-header"
                                            inputProps={{
                                                type: 'checkbox',
                                                id: 'muaAgreed',
                                                checked: this.state.data.muaAgreed,
                                                onChange: this.muaAgreedChange,
                                            }}
                                        />
                                    </Row>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <div />
                    )}
                </CardBody>
            </FilterWrapper>
        );
    }
}

const FilterWrapper = ({ isMember, children }) => {
    return children;
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        saveOrUpdate: data => {
            return dispatch(saveOrUpdate(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(loadByApplicationId(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MarkUserAgreementPanel));
