/**
 * Member service assessment reducer
 *
 * Author: Fernando Karnagi
 */

var defaultState = {
    assessments: [
        {
            serialNo: '1',
            title:
                'Financial Annual Net Worth based on the latest audited annual accounts (S$)',
            ticks: [
                {
                    tick: 1,
                    value: '>=$50,000',
                },
                {
                    tick: 2,
                    value: '$50,000  to $500,000',
                },
                {
                    tick: 3,
                    value: '>$500,000 to $1000,000',
                },
                {
                    tick: 4,
                    value: '> $1000,000',
                },
            ],
            documentsToSubmit: [
                '1) Latest Audited Annual Accounts ',
                '2) ACRA (only for startups)',
            ],
        },
        {
            serialNo: '2',
            title: 'Aggregate of EPC Project Amount for last 3 years (S$)',
            ticks: [
                {
                    tick: 1,
                    value: '-',
                },
                {
                    tick: 2,
                    value: '> S$1 Mil',
                },
                {
                    tick: 3,
                    value: '> S$3 Mil',
                },
                {
                    tick: 4,
                    value: '> S$3 Mil',
                },
            ],
            documentsToSubmit: [
                '1) Evidence of Verified EPC Project Amount in EPC Contract',
            ],
        },
    ],
};

export function memberServiceAssessmentReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'MEMBER_SERVICE_ASSESSMENT_SAMPLE_ACTION':
            newState.new.currentTabNo = 0;
            newState.new.activeTabs = [true, false, false];
            return newState;
        default:
            return newState;
    }
}
