// Group Search
export const GROUP_SEARCH_REQUEST = 'GROUP_SEARCH_REQUEST';
export const GROUP_SEARCH_SUCCESS = 'GROUP_SEARCH_SUCCESS';
export const GROUP_SEARCH_FAILURE = 'GROUP_SEARCH_FAILURE';
export const GROUP_KEYWORD_SEARCH_REQUEST = 'GROUP_KEYWORD_SEARCH_REQUEST';
export const GROUP_KEYWORD_SEARCH_SUCCESS = 'GROUP_KEYWORD_SEARCH_SUCCESS';
export const GROUP_KEYWORD_SEARCH_FAILURE = 'GROUP_KEYWORD_SEARCH_FAILURE';
export const GROUP_TOGGLE_CHECKBOX = 'GROUP_TOGGLE_CHECKBOX';
export const GROUP_TOGGLE_CHECKBOX_ALL = 'GROUP_TOGGLE_CHECKBOX_ALL';

export const GROUP_CHANGE_INPUT_KEYWORD_VALUE = 'GROUP_CHANGE_INPUT_KEYWORD_VALUE ';

// Group Details / Group Add
export const GROUP_ADD_GET_DETAIL_REQUEST = 'GROUP_ADD_GET_DETAIL_REQUEST';
export const GROUP_ADD_GET_DETAIL_SUCCESS = 'GROUP_ADD_GET_DETAIL_SUCCESS';
export const GROUP_ADD_GET_DETAIL_FAILURE = 'GROUP_ADD_GET_DETAIL_FAILURE';

export const GROUP_ADD_SAVE_GROUP_REQUEST = 'GROUP_ADD_SAVE_GROUP_REQUEST';
export const GROUP_ADD_SAVE_GROUP_SUCCESS = 'GROUP_ADD_SAVE_GROUP_SUCCESS';
export const GROUP_ADD_SAVE_GROUP_FAILURE = 'GROUP_ADD_SAVE_GROUP_FAILURE';

export const GROUP_ADD_GET_PERMISSIONS_REQUEST = 'GROUP_ADD_GET_PERMISSIONS_REQUEST';
export const GROUP_ADD_GET_PERMISSIONS_SUCCESS = 'GROUP_ADD_GET_PERMISSIONS_SUCCESS';
export const GROUP_ADD_GET_PERMISSIONS_FAILURE = 'GROUP_ADD_GET_PERMISSIONS_FAILURE';

export const GROUP_ADD_CHANGE_PERMISSIONS_VALUE = 'GROUP_ADD_CHANGE_PERMISSIONS_VALUE';
export const GROUP_ADD_REMOVE_PERMISSION_AT = 'GROUP_ADD_REMOVE_PERMISSION_AT';

export const GROUP_ADD_DELETE_GROUP_REQUEST = 'GROUP_ADD_DELETE_GROUP_REQUEST';
export const GROUP_ADD_DELETE_GROUP_SUCCESS = 'GROUP_ADD_DELETE_GROUP_SUCCESS';
export const GROUP_ADD_DELETE_GROUP_FAILURE = 'GROUP_ADD_DELETE_GROUP_FAILURE';

export const GROUP_ADD_RESET_FIELDS = 'GROUP_ADD_RESET_FIELDS';

export const GROUP_ADD_CHANGE_FIELD_VALUE = 'GROUP_ADD_CHANGE_FIELD_VALUE ';

export const GROUP_ADD_MODAL_TOGGLE = 'GROUP_ADD_MODAL_TOGGLE';
export const GROUP_ADD_FORCE_VALIDATE = 'GROUP_ADD_FORCE_VALIDATE ';

// User Search
export const USER_SEARCH_REQUEST = 'USER_SEARCH_REQUEST';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAILURE = 'USER_SEARCH_FAILURE';
export const USER_KEYWORD_SEARCH_REQUEST = 'USER_KEYWORD_SEARCH_REQUEST';
export const USER_KEYWORD_SEARCH_SUCCESS = 'USER_KEYWORD_SEARCH_SUCCESS';
export const USER_KEYWORD_SEARCH_FAILURE = 'USER_KEYWORD_SEARCH_FAILURE';
export const USER_TOGGLE_CHECKBOX = 'USER_TOGGLE_CHECKBOX';
export const USER_TOGGLE_CHECKBOX_ALL = 'USER_TOGGLE_CHECKBOX_ALL';

export const USER_CHANGE_INPUT_KEYWORD_VALUE = 'USER_CHANGE_INPUT_KEYWORD_VALUE ';

// User Details / User Add
export const USER_ADD_SAVE_USER_REQUEST = 'USER_ADD_SAVE_USER_REQUEST';
export const USER_ADD_SAVE_USER_SUCCESS = 'USER_ADD_SAVE_USER_SUCCESS';
export const USER_ADD_SAVE_USER_FAILURE = 'USER_ADD_SAVE_USER_FAILURE';

export const USER_ADD_DELETE_USER_REQUEST = 'USER_ADD_DELETE_USER_REQUEST';
export const USER_ADD_DELETE_USER_SUCCESS = 'USER_ADD_DELETE_USER_SUCCESS';
export const USER_ADD_DELETE_USER_FAILURE = 'USER_ADD_DELETE_USER_FAILURE';

export const USER_ADD_SET_FIELDS = 'USER_ADD_SET_FIELDS';

export const USER_ADD_CHANGE_FIELD_VALUE = 'USER_ADD_CHANGE_FIELD_VALUE ';
export const USER_ADD_FORCE_VALIDATE = 'USER_ADD_FORCE_VALIDATE ';

export const USER_ADD_MODAL_TOGGLE = 'USER_ADD_MODAL_TOGGLE';
export const USER_ADD_REMOVE_REDIRECT_ID = 'USER_ADD_REMOVE_REDIRECT_ID';

export const USER_DETAIL_GET_DETAIL_REQUEST = 'USER_DETAIL_GET_DETAIL_REQUEST';
export const USER_DETAIL_GET_DETAIL_SUCCESS = 'USER_DETAIL_GET_DETAIL_SUCCESS';
export const USER_DETAIL_GET_DETAIL_FAILURE = 'USER_DETAIL_GET_DETAIL_FAILURE';

export const USER_DETAIL_CHANGE_FIELD_VALUE = 'USER_DETAIL_CHANGE_FIELD_VALUE ';
