import React from 'react';
import moment from 'moment';

import { Row, Col } from 'reactstrap';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

const columns = [
    {
        Header: 'Date',
        accessor: 'date',
        style: { whiteSpace: 'unset' },
        minWidth: 150,
        Cell: props => moment(props.value).format('D MMMM YYYY'),
    },
    {
        Header: 'Detail',
        accessor: 'detail',
        minWidth: 300,
    },
];
const CompanyDetailsPageLoggingActivityPanel = props => {
    return (
        <React.Fragment>
            {/* <Row>
                <Col xs={12} className="d-flex">
                    <h3>Logging Activities</h3>
                </Col>
            </Row>
            <br /> */}
            <div className="page-widget">
                {/* <br /> */}
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            classname="-highlight mb-2"
                            data={props.loggingActivitiesList}
                            columns={columns}
                            totalNum={props.loggingActivitiesList != null ? props.loggingActivitiesList.length : 0}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            pages={1}
                            loading={props.loading}
                            onFetchData={(state, instance) =>
                                props.getLoggingActivitiesListAction(props.companyId, state.sorted, state.filtered)
                            }
                        />
                    </Col>
                </Row>
                <br />
            </div>
        </React.Fragment>
    );
};
export default CompanyDetailsPageLoggingActivityPanel;
