import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import VerticalBarChartTwoBars from 'cyder/chart/VerticalBarChartTwoBars';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import YearSelector from 'cyder/forms/YearSelector';

class MembershipIssuedAnalysis extends React.Component {
    render() {
        return (
            <div>
                <NoShadowPanel
                    title="Workload KPI by Year"
                    description="date filter uses Membership Application Completed Timestamp"
                >
                    <YearSelector />
                </NoShadowPanel>
                <VerticalBarChartTwoBars
                    items={this.props.data}
                    firstKey="new"
                    secondKey="renewal"
                    title="Membership from 2015 - 2018"
                    description="Lorem ipsum dolor sit amet"
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        data: state.staffMembershipStatistics.issuedAnalysis.byYear,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipIssuedAnalysis));
