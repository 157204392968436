import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';
import CertificationActiveProductAnalyticsDetailsPopup from 'pages/analytics/components/certification/CertificationActiveProductAnalyticsDetailsPopup';
import { getActiveCertifiedProductAnalytic } from 'actions/analytics/certificateAnalyticsAction';

class ActiveSGBPCertifiedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.showPopup = this.showPopup.bind(this);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }
    showPopup() {
        alert('popup');
    }
    render() {
        return (
            <AnalyticsCard title={`Active SGBP Certified Products`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CertificationActiveProductAnalyticsDetailsPopup
                            ref={instance => {
                                this.popup = instance;
                            }}
                        />
                        <AnalyticsBigText
                            text={this.state.data.activeProductCount}
                            onClick={e => {
                                this.popup.show('Active Certified Product');
                            }}
                            textColor={`#78BE20`}
                        />
                        <AnalyticsNormalText text={`Avg. Products Per Certificate: ${this.state.data.averageProductCertificate}`} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getActiveCertifiedProductAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ActiveSGBPCertifiedProducts));
