import React from 'react';

// SGBC custom components
import PaymentPanel from '../../membership/new/components/PaymentPanel';

const Payment = () => (
    <div>
        <PaymentPanel />
    </div>
);
export default Payment;
