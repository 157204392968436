/**
 * DinnerEventsDashboardPage.
 *
 * Author: Fernando Karnagi
 */
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import IconWidget6 from 'icon-widgets/IconWidget6';
import React from 'react';
import history from '../../../../history';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NumberPanel1 from 'cyder/panels/NumberPanel1';
import 'css/ui-elements/typography.css';
import Widget from 'elements/Widget';
import { getEventInfo, loadTableCount, getAllRegistrations } from 'actions/staff/dinner/events/eventsAllAction';
import { load, fetchMealType } from 'actions/staff/dinner/events/eventsTicketAction';
import { eventDatesGetEventDate } from 'actions/staff/dinner/events/eventsDateAction';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
const BackPanel = () => (
    <Row className="mb-2">
        <Col xs={12}>
            <Link to="/staff/galadinner/all">
                <Button className="primary-btn-style-outline">
                    <i className="material-icons align-text-bottom">chevron_left</i> Back
                </Button>
                &nbsp;
            </Link>
        </Col>
    </Row>
);

const HeaderPanel = props => (
    <Row className="mb-2">
        <Col xs={6} className="d-flex">
            <div>
                {' '}
                <h4 className="display-4" style={{ color: '#317446', fontSize: '3.2em', fontWeight: 500 }}>
                    {props.data.name}
                </h4>
            </div>
        </Col>
        <Col xs={6}>
            <Button
                className="float-right ml-2 primary-btn-style"
                onClick={() => {
                    history.push('/staff/galadinner/' + props.eventId);
                }}
            >
                <i className="material-icons align-text-bottom">edit</i> {'Edit'}
            </Button>
            &nbsp;
        </Col>
    </Row>
);

const InfoPanel = props => (
    <Widget style={{ padding: '0px' }}>
        {}
        <Row>
            <Col>
                <blockquote className={`blockquote blockquote-warning`}>
                    <div className="text-muted text-sm">Venue</div>
                    <p className="m-b-0">{props.data.venue}</p>
                </blockquote>
            </Col>
            <Col>
                <blockquote className={`blockquote blockquote-reverse blockquote-warning`}>
                    <div className="text-muted text-sm">Date</div>
                    <p className="m-b-0">
                        {props.date.date === 'Invalid date'
                            ? 'Date Not Selected'
                            : props.date.date + ' ' + props.date.startTimeForThisDay + ' - ' + props.date.endTimeForThisDay}
                    </p>
                </blockquote>
            </Col>
        </Row>
        <Row>
            <Col>
                <blockquote className={`blockquote blockquote-warning`}>
                    <div className="text-muted text-sm">Guest Of Honor</div>
                    <p className="m-b-0">{props.data.guestOfHonour}</p>
                </blockquote>
            </Col>
        </Row>
        <ChartPanel tableCount={props.tableCount} ticket={props.ticket} eventId={props.eventId} />
        <MealTypePanel mealType={props.mealType} eventId={props.eventId} />
    </Widget>
);
/*
                    <NumberPanel1
                        backgroundColor={`#FDAB9F`}
                        color={`white`}
                        textBottom={`meals`}
                        number={mealType && mealType.halalMealAmount !== null ? mealType.halalMealAmount : 0}
                        textTop={`Halal`}
                        height={70}
                    />
                    <NumberPanel1
                        backgroundColor={`#b39700`}
                        color={`white`}
                        textBottom={`meals`}
                        number={mealType && mealType.vegetarianMealAmount !== null ? mealType.vegetarianMealAmount : 0}
                        textTop={`Vegetarian`}
                        height={70}
                    />
*/
export const MealTypePanel = ({ mealType, eventId }) => (
    <Row>
        <NoShadowPanel
            title={
                'Meals (' +
                ((mealType && mealType.standardMealAmount !== null ? mealType.standardMealAmount : 0) +
                    (mealType && mealType.halalMealAmount !== null ? mealType.halalMealAmount : 0) +
                    (mealType && mealType.vegetarianMealAmount !== null ? mealType.vegetarianMealAmount : 0)) +
                ' Pax)'
            }
            fontSize={'1.8em'}
            borderSize={1}
            flat
            titleClass="info"
            galaTitleColor={{ color: '#63666a' }}
            mealType={mealType}
        >
            <Col>
                <Row style={{ paddingBottom: '10px' }}>
                    <Col>
                        <IconWidget6
                            text={'Standard'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={mealType && mealType.standardMealAmount !== null ? mealType.standardMealAmount : 0}
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + eventId + '/registration', {
                                    nonStandardMeal: { value: 'Standard', label: 'Standard' },
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <IconWidget6
                            text={'Halal'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={mealType && mealType.halalMealAmount !== null ? mealType.halalMealAmount : 0}
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + eventId + '/registration', {
                                    nonStandardMeal: { value: 'Halal', label: 'Halal' },
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <IconWidget6
                            text={'Vegetarian'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={mealType && mealType.vegetarianMealAmount !== null ? mealType.vegetarianMealAmount : 0}
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + eventId + '/registration', {
                                    nonStandardMeal: { value: 'Vegetarian', label: 'Vegetarian' },
                                })
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </NoShadowPanel>
    </Row>
);

/*
                        <NumberPanel1
                            backgroundColor={`#FDAB9F`}
                            color={`white`}
                            textBottom={`tables`}
                            number={
                                (props && props.ticket.vipBooked !== undefined ? props.ticket.vipBooked : 0) +
                                '/' +
                                (props && props.ticket.vip !== undefined ? props.ticket.vip : 0)
                            }
                            textTop={`VIP`}
                            height={70}
                        />
                        
                        <NumberPanel1
                            backgroundColor={`#9d9892`}
                            color={`white`}
                            textBottom={`tables`}
                            number={
                                (props && props.ticket.platinumBooked !== undefined ? props.ticket.platinumBooked : 0) +
                                '/' +
                                (props && props.ticket.platinum !== undefined ? props.ticket.platinum : 0)
                            }
                            textTop={`Platinum`}
                            height={70}
                        />
                        <NumberPanel1
                            backgroundColor={`#b39700`}
                            color={`white`}
                            textBottom={`tables`}
                            number={
                                (props && props.ticket.goldBooked !== undefined ? props.ticket.goldBooked : 0) +
                                '/' +
                                (props && props.ticket.gold !== undefined ? props.ticket.gold : 0)
                            }
                            textTop={`Gold`}
                            height={70}
                        />
                        <NumberPanel1
                            backgroundColor={`#228B22`}
                            color={`white`}
                            textBottom={`tables`}
                            number={
                                (props && props.ticket.standardBooked !== undefined ? props.ticket.standardBooked : 0) +
                                '/' +
                                (props && props.ticket.standard !== undefined ? props.ticket.standard : 0)
                            }
                            textTop={`Standard`}
                            height={70}
                        />

*/

export const ChartPanel = props => (
    <Row>
        <NoShadowPanel
            title={'Table Allocation (' + props.ticket.maxTable + ' Tables)'}
            borderSize={1}
            flat
            titleClass="info"
            galaTitleColor={{ color: '#63666a' }}
            fontSize={'1.8em'}
        >
            <Col>
                <Row style={{ paddingBottom: '10px' }}>
                    <Col>
                        <IconWidget6
                            text={'VIP'}
                            //remarks={'tables'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={
                                (props && props.ticket.vipBooked !== undefined ? props.ticket.vipBooked : 0) +
                                '/' +
                                (props && props.ticket.vip !== undefined ? props.ticket.vip : 0)
                            }
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + props.eventId + '/seating', {
                                    tableType: { value: 'VIP', label: 'VIP' },
                                    vip: props && props.ticket.vip !== undefined ? props.ticket.vip : 0,
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <IconWidget6
                            text={'Platinum'}
                            //remarks={'tables'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={
                                (props && props.ticket.platinumBooked !== undefined ? props.ticket.platinumBooked : 0) +
                                '/' +
                                (props && props.ticket.platinum !== undefined ? props.ticket.platinum : 0)
                            }
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + props.eventId + '/seating', {
                                    tableType: { value: 'Platinum', label: 'Platinum' },
                                    platinum: props && props.ticket.platinum !== undefined ? props.ticket.platinum : 0,
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <IconWidget6
                            text={'Gold'}
                            //remarks={'tables'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={
                                (props && props.ticket.goldBooked !== undefined ? props.ticket.goldBooked : 0) +
                                '/' +
                                (props && props.ticket.gold !== undefined ? props.ticket.gold : 0)
                            }
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + props.eventId + '/seating', {
                                    tableType: { value: 'Gold', label: 'Gold' },
                                    gold: props && props.ticket.gold !== undefined ? props.ticket.gold : 0,
                                })
                            }
                        />
                    </Col>
                    <Col>
                        <IconWidget6
                            text={'Standard'}
                            //remarks={'tables'}
                            textColor={{ color: '#63666a' }}
                            numberColor={{ color: '#78BE20' }}
                            number={
                                (props && props.ticket.standardBooked !== undefined ? props.ticket.standardBooked : 0) +
                                '/' +
                                (props && props.ticket.standard !== undefined ? props.ticket.standard : 0)
                            }
                            height={70}
                            link={() =>
                                history.push('/staff/galadinner/' + props.eventId + '/seating', {
                                    tableType: { value: 'Standard', label: 'Standard' },
                                    standard: props && props.ticket.standard !== undefined ? props.ticket.standard : 0,
                                })
                            }
                        />
                    </Col>
                </Row>
            </Col>
        </NoShadowPanel>
    </Row>
);

{
    /*<Button className="ml-2 align-middle primary-btn-style" onClick={() => this.props.getAllEvents(null, null, this.props.keyword)}>
    <i className="material-icons align-text-bottom">search</i>
    &nbsp;Search
</Button>*/
}

const ActionPanel = ({ edit, info, seating, registration, exportSeating, exportLoading }) => (
    <Row className="mb-2">
        <Col xs={12} className="d-flex flex-row-reverse">
            {exportLoading ? (
                <LoadingSpinner />
            ) : (
                <React.Fragment>
                    <Button className="ml-2 align-middle primary-btn-style" onClick={seating}>
                        <i className="material-icons align-text-bottom">event_seat</i> {'Seating Plan'}
                    </Button>
                    &nbsp;
                    <Button className="ml-2 align-middle primary-btn-style" onClick={registration}>
                        <i className="material-icons align-text-bottom">reorder</i> {'Registration'}
                    </Button>
                    <Button className="ml-2 align-middle primary-btn-style" onClick={exportSeating}>
                        <i className="material-icons align-text-bottom">print</i> {'Export'}
                    </Button>
                    &nbsp;
                </React.Fragment>
            )}
        </Col>
    </Row>
);

class DinnerEventsDashboardPage extends React.Component {
    constructor(props) {
        super(props);

        this.exportSeating = this.exportSeating.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.state = {
            loading: false,
            exportLoading: false,
            data: {},
            ticket: {
                platinumPrice: 0,
                goldPrice: 0,
                standardPrice: 0,
                looseseatPrice: 0,
                vipPrice: 0,
            },
            date: {},
        };
    }
    async exportSeating() {
        try {
            this.setState({
                exportLoading: true,
            });
            let res = await this.props.getAllRegistrations(this.props.match.params.eventid);
            const papa = require('papaparse');
            const parseConfig = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };
            var csvPayload = papa.unparse(res.data, parseConfig);
            var csv = 'data:text/csv;charset=utf-8,' + csvPayload;
            var data = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', 'galadinnerregistration.csv');
            link.click();
        } catch (error) {
            throw error;
        } finally {
            this.setState({
                exportLoading: false,
            });
        }
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    componentDidMount() {
        this.props.getMaxTableCount(this.props.match.params.eventid);
        this.showLoading(true);
        let eventId = this.props.match.params.eventid;
        this.props.fetchMealType(eventId);
        this.props
            .getEventInfo(eventId)
            .then(res => {
                this.setState({
                    data: res.data[0],
                });
                this.props
                    .getTickets(eventId)
                    .then(tickets => {
                        if (tickets != null && tickets.data != null && tickets.data.length > 0) {
                            this.setState({
                                ticket: tickets.data[0],
                            });
                        }
                        this.props
                            .getEventDates(eventId)
                            .then(date => {
                                this.setState({
                                    date: date,
                                });
                                this.showLoading(false);
                            })
                            .catch(error => {
                                this.showLoading(false);
                            });
                    })
                    .catch(error => {
                        this.showLoading(false);
                    });
            })
            .catch(error => {
                this.showLoading(false);
            });
    }
    render() {
        return (
            <div className="page-widget">
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <BackPanel />
                        <br />
                        <HeaderPanel
                            data={this.state.data}
                            ticket={this.state.ticket}
                            date={this.state.date}
                            eventId={this.props.match.params.eventid}
                        />
                        <hr />
                        <InfoPanel
                            data={this.state.data}
                            mealType={this.props.mealType}
                            ticket={this.state.ticket}
                            date={this.state.date}
                            eventId={this.props.match.params.eventid}
                        />
                        <hr />
                        <ActionPanel
                            edit={this.props.edit}
                            info={this.props.info}
                            seating={this.props.seating}
                            exportSeating={this.exportSeating}
                            registration={this.props.registration}
                            exportLoading={this.state.exportLoading}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.dinnerEventsAllEventsReducer,
        mealType: state.eventsTicketReducer.mealType && state.eventsTicketReducer.mealType[0],
        tableCount: state.eventsTicketReducer.tableCount,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getEventInfo: eventId => {
            return dispatch(getEventInfo(eventId));
        },
        getTickets: eventId => {
            return dispatch(load(eventId));
        },
        edit: eventId => {
            history.push(`/staff/galadinner/${ownProps.match.params.eventid}`);
        },
        info: eventId => {
            history.push(`/staff/galadinner/${ownProps.match.params.eventid}/info`);
        },
        seating: eventId => {
            history.push(`/staff/galadinner/${ownProps.match.params.eventid}/seating`, ownProps.tableCount);
        },
        registration: eventId => {
            history.push(`/staff/galadinner/${ownProps.match.params.eventid}/registration`);
        },
        getEventDates: eventid => {
            return dispatch(eventDatesGetEventDate(eventid));
        },
        getAllRegistrations: eventid => {
            return dispatch(getAllRegistrations(eventid));
        },
        fetchMealType: eventId => dispatch(fetchMealType(eventId)),
        getMaxTableCount: eventId => dispatch(loadTableCount(eventId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(DinnerEventsDashboardPage));
