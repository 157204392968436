import React from 'react';
import ReactTable from 'react-table';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ActivityTrailModal extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.state = {
            modal: false,
            list: [],
        };
    }
    search(page, pageSize, sorted, filtered) {
        // console.log(this.props.trail);
        let totalNoOfPages = Math.ceil(this.props.trail.length / pageSize);
        var start = page * pageSize;
        var end = (page + 1) * pageSize > this.props.trail.length ? this.props.trail.length : (page + 1) * pageSize;
        let tmpTrail = this.props.trail.slice(start, end);
        // console.log(page, pageSize);
        this.setState({
            list: tmpTrail,
            pageCount: totalNoOfPages,
        });
    }
    render() {
        const columns = [
            { Header: 'Date', accessor: 'timestamp', style: { whiteSpace: 'unset' } },
            { Header: 'By', accessor: 'actor', style: { whiteSpace: 'unset' } },
            { Header: 'Remarks', accessor: 'message', style: { whiteSpace: 'unset' } },
        ];
        return (
            <div>
                <Modal isOpen={this.props.trailModal} toggle={this.props.toggleTrailModal} className={this.props.className}>
                    <ModalHeader toggle={this.props.toggleTrailModal}>Assessment Activity History</ModalHeader>
                    <ModalBody>
                        <ReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={true}
                            defaultPageSize={5}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button className="primary-btn-style primary-btn-xs" onClick={this.props.toggleTrailModal}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ActivityTrailModal;
