/**
 * Survey action
 *
 * Author: Fernando Karnagi
 *
 */

import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import history from '../../../history';

export function setSurveyCategory(surveyCategory) {
    return {
        type: 'STAFF_SURVEY_SET_SURVEY_CATEGORY',
        surveyCategory,
    };
}

export function resetQuestion() {
    return (dispatch, getState) => {};
}

export function saveQuestion() {
    return (dispatch, getState) => {};
}

export function getDashboard() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'get-active-survey-count',
        });

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            'STAFF_SURVEY_SET_DASHBOARD_VALUE_REQUEST',
            'STAFF_SURVEY_SET_DASHBOARD_VALUE_SUCCESS',
            'STAFF_SURVEY_SET_DASHBOARD_VALUE_FAILURE',
        );
    };
}

export function getSurveyResult(surveyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'get-survey-result',
            id: surveyId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function setSurveyValue(surveyId) {
    return (dispatch, getState) => {
        if (surveyId == null || surveyId === 0) {
            const moment = require('moment');

            const survey = {
                id: 0,
                surveyCategoryId: '',
                surveyName: '',
                surveyDescription: '',
                startedOn: moment().format('DD/MM/YYYY'),
                finishedOn: moment()
                    .add(7, 'day')
                    .format('DD/MM/YYYY'),
                questions: [],
            };
            dispatch({
                type: 'STAFF_SURVEY_SET_SURVEY',
                survey,
            });
        } else {
            const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
                action: 'find-survey-by-id',
                id: surveyId,
            });

            return fetchlib.fetch(
                dispatch,
                getState,
                'POST',
                url,
                null,
                'STAFF_SURVEY_SET_SURVEY_VALUE_REQUEST',
                'STAFF_SURVEY_SET_SURVEY_VALUE_SUCCESS',
                'STAFF_SURVEY_SET_SURVEY_VALUE_FAILURE',
            );
        }
    };
}

export function getAnswersByApplicationId(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-answer`, {
            action: 'get-answers-by-application-id',
            applicationId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllAnswers(surveyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-answer`, {
            action: 'get-answers-by-survey-id',
            surveyId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getActiveSurveyByCategory(surveyCategoryId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'get-active-survey-by-category',
            surveyCategoryId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function submitAnswer(survey, applicationId) {
    return (dispatch, getState) => {
        if (applicationId == null) {
            applicationId = getState().survey.applicationId;
        }
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: `submit-answer`,
            applicationId,
        });

        var answersToSubmit = [];
        survey.questions.forEach(question => {
            if (question.questionTypeId === 2) {
                answersToSubmit.push({
                    questionId: question.id,
                    answerText: '',
                    answerOption: question.answer,
                });
            } else if (question.questionTypeId === 3) {
                answersToSubmit.push({
                    questionId: question.id,
                    answerText: question.answer,
                    answerOption: '',
                });
            }
        });

        return fetchlib
            .fetch(dispatch, getState, 'POST', url, answersToSubmit, null, null, null)
            .then(res => {
                return res;
            })
            .catch(error => {
                return error;
            });
    };
}

export function saveSurvey() {
    return (dispatch, getState) => {
        console.log('State', getState().staffSurvey);
        var survey = getState().staffSurvey.data.survey;
        var surveyAnswers = getState().survey.surveyAnswers;

        var questionsToAdd = [];
        survey.questions.forEach(question => {
            var optionsToAdd = [];
            if (question.questionOptions != null) {
                question.questionOptions.forEach(option => {
                    optionsToAdd.push(option.text);
                });
            }

            question.questionOptions = optionsToAdd;
            question.questionTypeId = parseInt(question.questionTypeId);
            questionsToAdd.push(question);
        });

        const moment = require('moment');
        var surveyDataToSave = {
            id: survey.id,
            surveyName: survey.surveyName,
            surveyCategoryId: parseInt(survey.surveyCategoryId),
            surveyDescription: survey.surveyDescription,
            startedOn: moment(survey.startedOn, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            finishedOn: moment(survey.finishedOn, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            questions: questionsToAdd,
        };

        var actionParam = 'create-survey';
        if (survey.id === 0 || survey.id == null) {
            actionParam = 'create-survey';
        } else {
            if (surveyAnswers != null && surveyAnswers.length > 0) {
                actionParam = 'update-partial';
            } else {
                actionParam = 'full-update-survey';
            }
        }

        console.log('Action', actionParam);
        console.log('Data', surveyDataToSave);

        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: actionParam,
        });

        return fetchlib
            .fetch(dispatch, getState, 'POST', url, surveyDataToSave, null, 'STAFF_SURVEY_SET_SAVE_STATUS', 'STAFF_SURVEY_SET_SAVE_STATUS')
            .then(res => {
                var success = getState().staffSurvey.control.surveySaveSuccess;
                if (!success) {
                    dispatch({
                        type: 'STAFF_SURVEY_SHOW_SAVE_MODAL',
                        message: res.message,
                        header: 'Error',
                        show: true,
                    });
                } else {
                    history.replace('/staff/surveys');
                }
            })
            .catch(error => {});
    };
}

export function deleteSurvey() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'delete',
        });

        const params = {
            id: getState().staffSurvey.data.survey.id,
        };

        return fetchlib
            .fetch(dispatch, getState, 'POST', url, params, null, 'STAFF_SURVEY_SET_DELETE_STATUS', 'STAFF_SURVEY_SET_DELETE_STATUS')
            .then(res => {
                var success = getState().staffSurvey.control.surveyDeleteSuccess;
                if (!success) {
                    dispatch({
                        type: 'STAFF_SURVEY_SHOW_DELETE_MODAL',
                        message: res.message,
                        header: 'Error',
                        show: true,
                    });
                } else {
                    history.replace('/staff/surveys');
                }
            })
            .catch(error => {});
    };
}

export function getSurveys(page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'search-survey',
        });

        const newSurveyCategoryId = getState().staffSurvey.data.surveyCategoryId;
        const newStatus = getState().staffSurvey.control.status.value;
        const newKeyword = getState().staffSurvey.control.keyword;

        dispatch({
            type: 'STAFF_SURVEY_SET_SURVEY_PAGESIZE',
            pageSize,
        });

        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: newKeyword,
            surveyCategoryId: newSurveyCategoryId,
            status: newStatus,
            sorted,
        };

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            params,
            'STAFF_SURVEY_SET_SURVEYS_VALUE_REQUEST',
            'STAFF_SURVEY_SET_SURVEYS_VALUE_SUCCESS',
            'STAFF_SURVEY_SET_SURVEYS_VALUE_FAILURE',
        );
    };
}

export function getSurvey(applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/survey`, {
            action: 'isSurveyCompleted',
            applicationId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
