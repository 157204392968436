import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';

import { Row, Col, Button } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import PaymentChoicesPanel from './PaymentChoicesPanel';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import { eventLoadEventPaymentModes, loadRegistrationInfo } from 'actions/shared/eventRsvpAction.js';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import 'css/cyder/form/common-form.css';

class PayNowPanel extends React.Component {
    constructor() {
        super();
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.showLoadingCreditCard = this.showLoadingCreditCard.bind(this);
        this.showLoadingPaymentMode = this.showLoadingPaymentMode.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);

        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            paymentStart: false,
            loadingSubmit: false,
            loading: false,
            loadingCreditCard: false,
            loadingPaymentMode: false,
            registrationInfo: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                modalAction: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    modalAction() {
        this.modalToggle();

        var path = window.location.pathname;
        if (path.toLowerCase().indexOf('/public') >= 0) {
            history.push(`/`);
        } else {
            history.push(`/member/events/event`);
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide, modalAction) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.modalAction = modalAction || this.modalAction;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    boundForceUpdate() {
        this.modalToggle();
        this.componentDidMount();
    }
    submit() {}
    cancel() {
        this.showModal(
            'Confirmation',
            `You have decided to settle the payment later. Please note that your registration will be void if we 
        have not received payment 1 week before the event starts. Thank you.`,
            null,
            false,
            true,
        );
    }
    back() {
        this.setState({
            paymentStart: false,
        });
    }
    next() {
        if (this.state.selectedPaymentMode.value === '') {
            this.showModal('Warning', 'Please select a payment mode before proceed.', 'alert', false, true, 'forceUpdate');
            return;
        }
        this.setState({
            paymentStart: true,
        });
    }
    setPaymentMode(e) {
        this.setState({
            selectedPaymentMode: e,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingCreditCard(show) {
        this.setState({
            loadingCreditCard: show,
        });
    }
    showLoadingPaymentMode(show) {
        this.setState({
            loadingPaymentMode: show,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var registrationId = this.props.match.params.registrationid;
        this.showLoadingPaymentMode(true);
        this.props
            .loadEventPaymentModes(registrationId)
            .then(paymentModes => {
                this.showLoadingPaymentMode(false);
                var paymentModesToAdd = [];
                paymentModes.data.forEach(paymentMode => {
                    // Allow credit card and corporate now
                    if (paymentMode.modeOfPaymentId === 4 || paymentMode.modeOfPaymentId === 5) {
                        paymentModesToAdd.push({
                            value: paymentMode.modeOfPaymentId,
                            label: paymentMode.paymentMode,
                        });
                    }
                });
                this.setState({
                    paymentModes: paymentModesToAdd,
                });
            })
            .catch(error => {
                this.showLoadingPaymentMode(false);
                throw error;
            });

        this.showLoading(true);
        this.props
            .loadRegistrationInfo(registrationId)
            .then(registrationInfo => {
                this.setState({
                    registrationInfo: registrationInfo.data,
                });
                this.showLoading(false);
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    render() {
        return (
            <Col>
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col xs={12}>
                                <h3>
                                    <strong>{this.state.registrationInfo.eventName}</strong>
                                </h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h4>
                                    <strong>
                                        Invoice Number : {this.state.registrationInfo.invoiceNumber} (Amount :{' '}
                                        {this.state.registrationInfo.totalWithGst} SGD)
                                    </strong>
                                </h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <br />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.registrationInfo.eventSummary || '* No summary provided',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <hr />
                                <h3>
                                    <strong>
                                        {this.state.registrationInfo.paymentStatus == 0
                                            ? `Event Registration Payment`
                                            : `This registration has been fully paid on ${this.state.registrationInfo.paymentTimestamp}`}
                                    </strong>
                                </h3>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                <br />
                {this.state.registrationInfo.paymentStatus == 0 && (
                    <React.Fragment>
                        <Row style={{ paddingBottom: '10px' }}>
                            <div className="col-12 col-lg-12">
                                For payment methods other than the ones indicated, please contact SGBC at{' '}
                                <u>
                                    <a href="mailto:events@sgbc.sg">events@sgbc.sg</a>{' '}
                                </u>{' '}
                                or (65) 6732 5518
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                {!this.state.paymentStart && (
                                    <Row>
                                        <Col xs="4">
                                            <div className="form-group">
                                                <label className="field-header">
                                                    Payment Mode <MandatoryStar />
                                                </label>
                                                {this.state.loadingPaymentMode ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <Select
                                                        autofocus
                                                        simpleValue
                                                        autosize={false}
                                                        name="selected-state"
                                                        placeholder="Please select"
                                                        options={this.state.paymentModes}
                                                        onChange={this.setPaymentMode}
                                                        searchable={this.state.searchable}
                                                        clearable={this.state.clearable}
                                                        disabled={this.state.disabled}
                                                    />
                                                )}
                                                <small className="form-text text-muted">Please choose payment mode</small>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.paymentStart && (
                                    <PaymentChoicesPanel
                                        registration={this.state.registrationInfo}
                                        back={this.back}
                                        cancel={this.cancel}
                                        paymentMode={this.state.selectedPaymentMode.value}
                                        dialogModalToggle={this.props.toggleDialogModal}
                                    />
                                )}
                                {!this.state.paymentStart && (
                                    <Row>
                                        {/* <Col className="d-flex" xs={2}>
                                            <Button className="primary-btn-style-outline mr-2" onClick={this.cancel}>
                                                <strong>Settle Payment Later</strong>
                                            </Button>
                                        </Col> */}
                                        <Col className="d-flex" xs={2}>
                                            <Button className="primary-btn-style" onClick={this.next}>
                                                <strong>Next</strong>
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}

                <DialogModal
                    modalAction={this.state.modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    boundForceUpdate={this.boundForceUpdate}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventPaymentModes: registrationId => {
            return dispatch(eventLoadEventPaymentModes(registrationId));
        },
        loadRegistrationInfo: registrationId => {
            return dispatch(loadRegistrationInfo(registrationId));
        },
        toggleDialogModal: () => {
            dispatch(dialogModalToggle());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayNowPanel);
