import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Input } from 'reactstrap';

import { search } from 'actions/admin/companies/organisation-sub-category/action.js';

import CyderReactTable from 'cyder/table/CyderReactTable';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            keyword: '',
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'Main Category',
                accessor: 'mainCategory',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Sub Category',
                accessor: 'subCategory',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'subCategoryId',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={'/admin/companies/organisation-sub-category/' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <Input
                                type="text"
                                placeholder="Keyword"
                                onKeyDown={e => {
                                    if (e.keyCode == 13) {
                                        this.table.fireFetchData();
                                    }
                                }}
                                onChange={this.onKeywordChange}
                                value={this.state.keyword}
                            />
                            <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="/admin/companies/organisation-sub-category/0">
                                    <Button className="ml-1 mr-1 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
