import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import LegendIcon from 'cyder/media/LegendIcon';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import { Container, FormGroup, Label, Input, Row, Col, Form, Button } from 'reactstrap';

import {
    toggleCheckboxes,
    toggleCheckboxesAll,
    staffMembershipRenewalsSearchAction,
} from 'actions/staff/membership/renewals/staffMembershipRenewalsAction.js';

import cyderlib from 'js/cyderlib.js';
import 'css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import 'css/cyder/widget/page-widget.css';

class StaffMembershipRenewalsPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        moment.relativeTimeThreshold('d', 999);
    }
    render() {
        const groupDetailsUrlPrefix = '/admin/auth/group/';
        const yesNoUnknownIcons = val => {
            switch (val) {
                case null:
                    return <i className="fa fa-icon text-muted fa-question-circle" />;
                case true:
                    return <i className="material-icons align-text-bottom text-success">check_circle</i>;
                case false:
                    return <i className="material-icons align-text-bottom text-danger">remove_circle</i>;
            }
        };
        const columns = [
            {
                Header: 'Company',
                accessor: 'name',
                style: { whiteSpace: 'unset' },
                minWidth: 150,
            },
            {
                Header: 'Membership Expiry Date',
                accessor: 'expiry_date',
                Cell: props => {
                    return moment(props.value).format('DD/MM/YYYY');
                },
                style: { whiteSpace: 'unset' },
                maxWidth: 150,
            },
            {
                Header: <i className="material-icons align-text-bottom text-danger">access_time</i>,
                accessor: 'expiry_date',
                Cell: props => {
                    const expireText = moment().diff(props.value) * -1 < 0 ? 'Expired' : 'Expires';
                    return expireText + ' ' + moment.duration(moment().diff(props.value) * -1).humanize(true);
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                maxWidth: 75,
                Cell: props => {
                    return props.value === undefined || props.value === null ? null : (
                        <div className={'badge badge-rounded badge-' + cyderlib.getApplicationStatusAttr(props.value, 'color')}>
                            <i className={'material-icons align-text-bottom text-white'}>
                                {cyderlib.getApplicationStatusAttr(props.value, 'icon')}
                            </i>
                        </div>
                    );
                },
            },
            {
                Header: 'Officer',
                accessor: 'officer',
                maxWidth: 110,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: '',
                accessor: 'rn_exists',
                sortable: false,
                maxWidth: 140,
                Cell: props => {
                    return props.value ? (
                        <Button size="sm" color="primary">
                            Generate RN
                        </Button>
                    ) : (
                        <Link to="#">View RN</Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: '',
                maxWidth: 100,
                Cell: props => {
                    return (
                        // <Link to={groupDetailsUrlPrefix + props.row.id}>
                        props.row.rn_exists ? (
                            <Link to={'./renewals/' + props.row.renewal_id}>
                                <Button size="sm" color="success">
                                    <i className="mr-1 material-icons align-text-bottom ">edit</i>
                                    Open
                                </Button>
                            </Link>
                        ) : null
                    );
                },
            },
        ];
        return (
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <LegendIcon status="no_response" module="membership" />
                        <LegendIcon status="unsure" module="membership" />
                        <LegendIcon status="pending_payment" module="membership" />
                        <LegendIcon status="no_renew" module="membership" />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form inline className="float-left">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input type="text" name="text" id="search-keyword" placeholder="Keyword" />
                            </FormGroup>
                            <Button>Search</Button>
                        </Form>
                        <div className="float-right">
                            <Button style={{ width: '240px' }} className="mb-1 float-right" color="success">
                                <i className="material-icons align-text-bottom ">remove_red_eye</i>
                                &nbsp; See All Renewals
                            </Button>
                            <br />
                            <Button style={{ width: '240px' }} color="danger">
                                <i className="material-icons align-text-bottom ">email</i>
                                &nbsp; Mass Send Email Renewals
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <ReactTable
                            className="-highlight mb-2"
                            data={this.props.applications}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            manual
                            pages={this.props.tablePages}
                            loading={this.props.loading}
                            onFetchData={(state, instance) => {
                                this.props.getGroups(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffMembershipRenewalsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleCheckbox: id => {
            dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            dispatch(toggleCheckboxesAll());
        },
        getGroups: (page, pageSize, sorted, filtered) => {
            dispatch(staffMembershipRenewalsSearchAction(page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipRenewalsPage));
