import { connect } from 'react-redux';
import { compose, branch, renderComponent, renderNothing } from 'recompose';
import TopNavigation1 from './TopNavigation1';

const layouts = ['top-navigation-1'];

const Component = compose(
    connect(state => {
        var navigations = state.navigation;
        if (state.profile.user.is_staff == 1) {
            if (
                state.profile.user.is_staff == 1 ||
                state.profile.user.is_superuser == 1 ||
                state.profile.user.is_cert_admin == 1 ||
                state.profile.user.is_cert_manager == 1 ||
                state.profile.user.is_event_admin == 1 ||
                state.profile.user.is_mailing_list_admin == 1 ||
                state.profile.user.is_membership_admin == 1
            ) {
                navigations = state.navigationStaffAsAdmin;
            } else {
                navigations = state.navigationStaff;
            }
        } else if (state.profile.user.is_active == 1) {
            navigations = state.navigationMember;
        } else {
            navigations = state.navigationPublic;
        }
        return {
            navigation: navigations,
            layout: state.config.layout,
        };
    }),
    branch(({ layout }) => layouts.includes(layout), renderComponent(TopNavigation1), renderNothing),
)(TopNavigation1);

export default Component;
