/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function search(keyword, categoryId, year, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/resource`, {
            action: 'search',
        });

        if (year == 0) {
            year = null;
        }
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            year: year,
            categoryId: categoryId,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function searchForMember(keyword, categoryId, year, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/resource`, {
            action: 'search-for-member',
        });

        if (year == 0) {
            year = null;
        }
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            year: year,
            categoryId: categoryId,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function getAllCategories() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/category`, {
            action: 'get-all-categories',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
