import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Button,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';

import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class CompanyPage extends React.Component {
    componentDidMount() {
        this.props.setJumbotronTitle('Add Company');
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={10}>
                        <h2>Key Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    ID:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Name of SGBC: <span className="text-muted">*</span>
                                                </Label>
                                                <Col xs={5}>
                                                    <Input />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    UEN:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Gst registration number:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Contact Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Address line 1:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Address line 2:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Address line 3:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Telephone:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fax:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Website:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Email:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Payment Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Swift Code:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Bank account number:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Bank address:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Beneficiary:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Files Available to Members</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Sgbc constitution pdf:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Constitution_June_2015.pdf
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Sgbc constitution by laws pdf:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Constitution_By_Laws.pdf
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Sgbc mark guidelines pdf:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Mark_Guidelines.pdf
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermark thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/304.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermark width:
                                                </Label>
                                                <Label xs={10}>385</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermark height:
                                                </Label>
                                                <Label xs={10}>513</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    SGBC Member Mark:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Member.JPG
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermarkeps thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/304.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermarkeps width:
                                                </Label>
                                                <Label xs={10}>1476</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Membermarkeps height:
                                                </Label>
                                                <Label xs={10}>2090</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    SGBC Member Mark EPS:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Member_Logo.eps
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermark thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/304.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermark width:
                                                </Label>
                                                <Label xs={10}>463</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermark height:
                                                </Label>
                                                <Label xs={10}>532</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    SGBC Founding Member Mark:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Founding-Member.jpg
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermarkeps thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/304.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermarkeps width:
                                                </Label>
                                                <Label xs={10}>1523</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Fmembermarkeps height:
                                                </Label>
                                                <Label xs={10}>2086</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    SGBC Founding Member Mark EPS:
                                                </Label>
                                                <Col xs={10}>
                                                    Currently:{' '}
                                                    <Link className="mr-2" to="#">
                                                        sgbc/SGBC_Founding_Member_Logo.eps
                                                    </Link>
                                                    <Label check>
                                                        <Input type="checkbox" /> Clear
                                                    </Label>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <h2>Signatories Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={12}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    President name:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    President signature thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/306.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    President signature width:
                                                </Label>
                                                <Label xs={10}>385</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    President signature height:
                                                </Label>
                                                <Label xs={10}>513</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    President's signature (MUST use dimensions 321 x 213):
                                                </Label>
                                                <Col xs={10}>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Secretary name:
                                                </Label>
                                                <Col xs={5}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Secretary signature thumb:
                                                </Label>
                                                <Col xs={10}>
                                                    <img src="/assets/images/307.jpg" className="max-w-150" alt="" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Secretary signature width:
                                                </Label>
                                                <Label xs={10}>385</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Secretary signature height:
                                                </Label>
                                                <Label xs={10}>513</Label>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={2}>
                                                    Secretary's signature:
                                                </Label>
                                                <Col xs={10}>
                                                    <Input type="file" />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={2}>
                        <h2 style={{ visibility: 'hidden' }}>placeholder</h2>
                        <div className="sticky-top">
                            <Card>
                                <CardBody>
                                    <Button color="info" block>
                                        Save
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and continue editing</span>
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and add another</span>
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderExpenseMyReceiptsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CompanyPage));
