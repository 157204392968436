import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/cyder/media/front-logo.css';

const FrontLogo = props => {
    return (
        <Link to={window.location.pathname.toLowerCase().indexOf('/staff/') >= 0 ? `/staff/home` : `/staff/home`}>
            <div className={'sgbc_front_logo ' + props.divclassname}>
                <img width="165" height="213" src="/assets/sgbc/sgbc-logo-medium.jpg" />
            </div>
        </Link>
    );
};

export default FrontLogo;
