import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import { save, update, remove, load, getAllEvents, getAllCompanies } from 'actions/staff/events/promocodes/promoCodesAction.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.codeChange = this.codeChange.bind(this);
        this.discountChange = this.discountChange.bind(this);
        this.discountTypeChange = this.discountTypeChange.bind(this);
        this.maxNumOfUsersChange = this.maxNumOfUsersChange.bind(this);
        this.eventIdChange = this.eventIdChange.bind(this);
        this.eligibleCompaniesChange = this.eligibleCompaniesChange.bind(this);
        this.startDateChange = this.startDateChange.bind(this);
        this.endDateChange = this.endDateChange.bind(this);
        // button
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        // modal
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            events: [],
            companies: [],
            // discountTypes: [{ value: 'Fixed', label: 'Fixed' }, { value: 'Percent', label: 'Percent' }],
            discountTypes: [{ value: 'Percent', label: 'Percent' }],
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            selectedEvents: [],
            selectedCompanies: [],
            selectedDiscountType: {},
            data: {
                id: '',
                code: '',
                discount: '',
                discountType: '',
                maxNumOfUsers: '',
                eventId: '',
                eligibleCompanies: '',
                startDate: '',
                endDate: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/staff/events/promocodes');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        let modal = this.state.modal;
        modal.type = type;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.modalMessage = message;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuffs() {
        const mapDate = date => moment(date).format('DD/MM/YYYY');
        const id = this.props.match.params.id;
        try {
            this.showLoading(true);
            // Get - events
            const events = await this.props.getAllEvents();
            let eventsOptions = events.data.map(event => {
                return {
                    label: event.name,
                    value: event.id,
                };
            });
            // Get - companies
            const companies = await this.props.getAllCompanies();
            const companiesOptions = companies.data.map(company => {
                return {
                    label: company.name,
                    value: company.id,
                };
            });

            let startDate = moment();
            let endDate = moment().add('days', 7);
            if (id !== 'create') {
                const res = await this.props.load(id);
                if (res.error || res.data === null || res.data.id === null) {
                    this.showModal('Error', res.message, 'load', true, true);
                    return;
                }

                let eventIdsFromAPI = JSON.parse(res.data.eventId);
                var eventId = [];
                eventsOptions.forEach(option => {
                    if (eventIdsFromAPI != null) {
                        eventIdsFromAPI.forEach(event => {
                            if (option.value === event) {
                                eventId.push(option);
                            }
                        });
                    }
                });

                let eligibleCompaniesFromAPI = JSON.parse(res.data.eligibleCompanies);
                var eligibleCompanies = [];
                companiesOptions.forEach(option => {
                    if (eligibleCompaniesFromAPI != null) {
                        eligibleCompaniesFromAPI.forEach(company => {
                            if (option.value === company) {
                                eligibleCompanies.push(option);
                            }
                        });
                    }
                });

                var selectedDiscountType = '';
                this.state.discountTypes.forEach(option => {
                    if (option.value === res.data.discountType) {
                        selectedDiscountType = option;
                    }
                });

                startDate = mapDate(res.data.startDate);
                endDate = mapDate(res.data.endDate);
                var formattedPromoCodeData = Object.assign({}, res.data || this.state.data);
                formattedPromoCodeData.startDate = startDate;
                formattedPromoCodeData.endDate = endDate;
                if (this._isMounted) {
                    this.setState({
                        events: eventsOptions,
                        companies: companiesOptions,
                        selectedEvents: eventId,
                        selectedCompanies: eligibleCompanies,
                        data: formattedPromoCodeData,
                        selectedDiscountType,
                    });
                }
            } else if (this._isMounted) {
                this.setState({
                    events: eventsOptions,
                    companies: companiesOptions,
                    data: {
                        id: '',
                        code: '',
                        discount: '',
                        discountType: '',
                        maxNumOfUsers: '',
                        eventId: '',
                        eligibleCompanies: '',
                        startDate: startDate,
                        endDate: endDate,
                    },
                });
            }
        } catch (error) {
            this.showModal('Error', 'Failed to load data for promocode ' + id || '', 'load', true, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        const mapDate = date => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let newData = data;
        newData.maxNumOfUsers = parseInt(data.maxNumOfUsers);
        newData.discount = parseFloat(data.discount);
        //newData.eventId = parseInt(data.eventId);
        newData.startDate = mapDate(newData.startDate);
        newData.endDate = mapDate(newData.endDate);
        return newData;
    }
    async save() {
        this.showLoading(true);
        const data = this.prepareDataBeforeSave(this.state.data);
        try {
            if (data.id === null || data.id === 0 || data.id === '') {
                const saveRes = await this.props.save(data);
                if (saveRes.error) {
                    throw saveRes.error;
                } else {
                    history.replace('/staff/events/promocodes');
                    return;
                }
            } else {
                const updateRes = await this.props.update(data);
                if (updateRes.error) {
                    throw updateRes.error;
                } else {
                    history.replace('/staff/events/promocodes', 'update', true, true);
                    return;
                }
            }
        } catch (error) {
            this.showModal('Error', error.message, 'save', true, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/staff/events/promocodes');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    discountTypeChange(e) {
        var data = this.state.data;
        data.discountType = e.value;
        this.setState({
            data,
        });
        this.setState({
            selectedDiscountType: e,
        });
    }
    maxNumOfUsersChange(e) {
        var data = this.state.data;
        data.maxNumOfUsers = e.target.value;
        this.setState({
            data,
        });
    }
    eventIdChange(e) {
        var data = this.state.data;
        var eventIdList = [];
        // data.eventId = e.value;
        e.forEach(eventId => {
            eventIdList.push(eventId.value);
        });
        data.eventId = JSON.stringify(eventIdList);
        this.setState({
            data,
        });
        this.setState({
            selectedEvents: e,
        });
    }
    eligibleCompaniesChange(e) {
        var data = this.state.data;
        var companies = [];
        e.forEach(company => {
            companies.push(company.value);
        });
        data.eligibleCompanies = JSON.stringify(companies);
        this.setState({
            data,
        });
        this.setState({
            selectedCompanies: e,
        });
    }
    startDateChange(e) {
        var data = this.state.data;
        data.startDate = e.format('DD/MM/YYYY');
        this.setState({
            data,
        });
    }
    endDateChange(e) {
        var data = this.state.data;
        data.endDate = e.format('DD/MM/YYYY');
        this.setState({
            data,
        });
    }
    codeChange(e) {
        var data = this.state.data;
        data.code = e.target.value;
        this.setState({
            data,
        });
    }
    discountChange(e) {
        var data = this.state.data;
        data.discount = e.target.value;
        this.setState({
            data,
        });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <i onClick={this.back} style={{ cursor: 'pointer' }} className={`fa fa-2x fa-angle-left`} />
                            <div
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                }}
                                onClick={this.back}
                            >
                                Back
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup className="col-sm-4">
                                <Label for="code">Code</Label>
                                <Input
                                    type="text"
                                    name="code"
                                    id="code"
                                    value={this.state.data.code}
                                    placeholder=""
                                    onChange={this.codeChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup className="col-sm-3">
                                <Label for="discount">Discount</Label>
                                <Input
                                    type="text"
                                    name="discount"
                                    id="discount"
                                    value={this.state.data.discount}
                                    placeholder=""
                                    onChange={this.discountChange}
                                />
                            </FormGroup>

                            <FormGroup className="col-sm-3">
                                <Label for="discountType">Discount Type</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.discountTypes}
                                    value={this.state.selectedDiscountType}
                                    clearable={this.state.select.clearable}
                                    onChange={this.discountTypeChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup className="col-sm-3">
                                <Label for="maxNumOfUsers">Max Users</Label>
                                <Input
                                    type="text"
                                    name="discountType"
                                    id="maxNumOfUsers"
                                    value={this.state.data.maxNumOfUsers}
                                    placeholder=""
                                    onChange={this.maxNumOfUsersChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={8}>
                            <FormGroup className="col-sm-15">
                                <Label for="eventId">Event</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.events}
                                    value={this.state.selectedEvents}
                                    clearable={this.state.select.clearable}
                                    onChange={this.eventIdChange}
                                    isMulti={true}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={8}>
                            <FormGroup className="col-sm-15">
                                <Label for="eligibleCompanies">Eligible Companies</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.companies}
                                    value={this.state.selectedCompanies}
                                    clearable={this.state.select.clearable}
                                    onChange={this.eligibleCompaniesChange}
                                    isMulti={true}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup className="col-sm-3">
                                <Label for="startDate">Valid From</Label>
                                <CyderDatePicker
                                    value={this.state.data.startDate}
                                    onChange={this.startDateChange}
                                    closeOnSelect={true}
                                    datetimeClassName="mr-2"
                                    dateFormat="DD/MM/YYYY"
                                />
                            </FormGroup>

                            <FormGroup className="col-sm-3">
                                <Label for="endDate">Valid Until</Label>
                                <CyderDatePicker
                                    value={this.state.data.endDate}
                                    onChange={this.endDateChange}
                                    closeOnSelect={true}
                                    datetimeClassName="mr-2"
                                    dateFormat="DD/MM/YYYY"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr />
                    <FlexRow between="xs" style={{ marginBottom: '20px' }}>
                        <FlexCol xs={2}>
                            <Button color="success" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2}>
                                <Button color="danger" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        ) : (
                            <div />
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getAllEvents: () => {
            return dispatch(getAllEvents());
        },
        getAllCompanies: () => {
            return dispatch(getAllCompanies());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
