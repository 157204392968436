import React from 'react';
import { connect } from 'react-redux';

import { Input } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';

export const PaymentInput = ({ label, description, placeholder, value, onChange, id, type, rows }) => (
    <div className="form-group">
        <label className="field-header">
            {label} <MandatoryStar />
        </label>
        <Input
            id={id}
            type={type || 'text'}
            rows={rows || 1}
            className="form-control"
            placeholder={placeholder}
            onChange={onChange}
            value={value || ''}
        />
        {description && <small className="form-text text-muted">{description}</small>}
    </div>
);

class CashPayment extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-5 col-lg-5">
                    <PaymentInput
                        label="Payment Amount"
                        description="Please enter amount of payment"
                        value={this.props.paymentInfo.amountReceived}
                        onChange={e => this.props.onTextChanged(e.target.value, 'amountReceived')}
                    />
                </div>
                <div className="col-5 col-lg-5">
                    <PaymentInput
                        label="Date of Payment"
                        description="Please select date of payment"
                        value={this.props.paymentInfo.datePaymentReceived}
                        onChange={e => this.props.onTextChanged(e.target.value, 'datePaymentReceived')}
                    />
                </div>
                <br />
                <div className="col-5 col-lg-5">
                    <PaymentInput
                        label="Remarks"
                        type="textarea"
                        rows={3}
                        description="Please enter remarks"
                        value={this.props.paymentInfo.remarks}
                        onChange={e => this.props.onTextChanged(e.target.value, 'remarks')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CashPayment);
