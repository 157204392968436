import withQuery from 'with-query';
import moment from 'moment';
import fetchlib from '../../../../js/fetchlib.js';
import {
    EVENT_DATES_GET_EVENT_DATE_REQUEST,
    EVENT_DATES_GET_EVENT_DATE_SUCCESS,
    EVENT_DATES_GET_EVENT_DATE_FAILURE,
    EVENT_DATES_SAVE_EVENT_DATE_REQUEST,
    EVENT_DATES_SAVE_EVENT_DATE_SUCCESS,
    EVENT_DATES_SAVE_EVENT_DATE_FAILURE,
    EVENT_DATES_UPDATE_EVENT_DATE_REQUEST,
    EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS,
    EVENT_DATES_GET_EVENT_MULTIPLE_DATES_SUCCESS,
    EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS,
    EVENT_DATES_UPDATE_EVENT_DATE_FAILURE,
    EVENT_DATES_SAVE_EVENT_DATE_WARNING,
    EVENT_DATES_CHANGE_FIELD_VALUE,
    EVENT_DATES_RESET_EVENT_FIELDS,
    EVENT_DATES_SET_EVENT_ID,
    EVENT_DATES_SET_MODAL_DATE,
    EVENT_DATES_ADD_EVENT,
    EVENT_DATES_DELETE_EVENT,
} from '../../../constants/events/actionTypesStaffEventsDate.js';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config.js';

export function eventDatesGetEventDate(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-date`, {
            action: 'findByEventId',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_DATES_GET_EVENT_DATE_REQUEST,
            EVENT_DATES_GET_EVENT_DATE_SUCCESS,
            EVENT_DATES_GET_EVENT_DATE_FAILURE,
        );
    };
}

export function eventMultipleDatesGetEventDate(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-date`, {
            action: 'find-multiple-by-eventid',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_DATES_GET_EVENT_DATE_REQUEST,
            EVENT_DATES_EVENT_GET_EVENT_MULTIPLE_DATES_SUCCESS,
            EVENT_DATES_GET_EVENT_DATE_FAILURE,
        );
    };
}

export function eventDatesSaveOrUpdateEventDate() {
    return (dispatch, getState) => {
        let dates = getState().eventsDateReducer.dates;
        const hasEmpty = eventDatesVerifyEmptyAllDates(dates);
        if (hasEmpty) {
            dispatch({ type: EVENT_DATES_SAVE_EVENT_DATE_WARNING });
            return;
        }
        dates.forEach(dateRecord => {
            dateRecord.date = moment(dateRecord.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        });
        const body = {
            dates,
            eventId: getState().eventsCreationReducer.eventid,
        };
        // If date given
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-date`, {
            action: 'create-or-update',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_DATES_SAVE_EVENT_DATE_REQUEST,
            EVENT_DATES_SAVE_EVENT_DATE_SUCCESS,
            EVENT_DATES_SAVE_EVENT_DATE_FAILURE,
        );
    };
}

export function eventDatesSaveEventDate() {
    return (dispatch, getState) => {
        const body = getState().eventsDateReducer.fields;
        const hasEmpty = eventDatesVerifyEmptyFields(body);
        if (hasEmpty) {
            dispatch({ type: EVENT_DATES_SAVE_EVENT_DATE_WARNING });
            return;
        }
        // If date given
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-date`, {
            action: 'create',
        });
        body.date = moment(body.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.eventId = getState().eventsCreationReducer.eventid;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_DATES_SAVE_EVENT_DATE_REQUEST,
            EVENT_DATES_SAVE_EVENT_DATE_SUCCESS,
            EVENT_DATES_SAVE_EVENT_DATE_FAILURE,
        );
    };
}
export function eventDatesUpdateEventDate() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-date`, {
            action: 'update',
        });
        var body = getState().eventsDateReducer.fields;
        body.eventDate = moment(body.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.eventId = getState().eventsCreationReducer.eventid;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            getState().eventsDateReducer.fields,
            EVENT_DATES_UPDATE_EVENT_DATE_REQUEST,
            EVENT_DATES_UPDATE_EVENT_DATE_SUCCESS,
            EVENT_DATES_UPDATE_EVENT_DATE_FAILURE,
        );
    };
}
export function eventDatesChangeDateInput(moment, inputid, eventDateId) {
    return {
        type: EVENT_DATES_CHANGE_FIELD_VALUE,
        moment,
        inputid,
        eventDateId,
    };
}
export function eventDatesResetFields() {
    return (dispatch, getState) => {
        dispatch({ type: EVENT_DATES_RESET_EVENT_FIELDS });
        return Promise.resolve();
    };
}
export function eventDatesSetModalData(data) {
    return {
        type: EVENT_DATES_SET_MODAL_DATE,
        data,
    };
}
export function eventDatesSetEventId(eventid) {
    return {
        type: EVENT_DATES_SET_EVENT_ID,
        eventid,
    };
}
export function eventDatesVerifyEmptyAllDates(dates) {
    let isEmpty = false;
    let emptyDate = dates == null || dates.length <= 0;
    if (emptyDate) {
        return true;
    }
    let recordWithEmptyDate = dates.filter(dateRecord => dateRecord.date == null || dateRecord.date == '');
    if (recordWithEmptyDate != null && recordWithEmptyDate.length >= 1) {
        isEmpty = true;
    }
    return isEmpty;
}
export function eventDatesVerifyEmptyFields(date) {
    let isEmpty = false;
    if (date.date === '') {
        isEmpty = true;
    }
    return isEmpty;
}
export function eventDatesAddDate() {
    return {
        type: EVENT_DATES_ADD_EVENT,
    };
}
export function eventDatesDeleteDate(id) {
    return {
        type: EVENT_DATES_DELETE_EVENT,
        id,
    };
}
