import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TierResponsePanel } from '../../staff/membership/applications/StaffMembershipApplicationsDetailsPage';

import { SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';
class TierResponsePopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            modal: false,
        };
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    hide() {
        this.setState({
            modal: false,
        });
    }
    show() {
        this.setState({
            title: 'Membership Tier Upgrade',
            modal: true,
        });
    }
    render() {
        let tierUpgradedStatus =
            this.props.memberApplication != null && this.props.memberApplication.length > 0
                ? this.props.memberApplication[0].tierUpgraded
                : 0;
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>
                    <b>{this.state.title}</b>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <MemberTierFormPanel
                        tierQuestions={this.props.tierQuestions}
                        tierResponsePostProcessor={this.props.tierResponsePostProcessor}
                        docs={this.props.docs}
                        resubmitQuestionnaires={this.props.resubmitQuestionnaires}
                        tierUpgradedStatus={tierUpgradedStatus}
                        upgradeTierPanel={this.props.upgradeTierPanel}
                        orgCategory={this.props.orgCategory}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn-style" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export const MemberTierFormPanel = ({
    tierQuestions,
    tierResponsePostProcessor,
    docs,
    resubmitQuestionnaires,
    tierUpgradedStatus,
    upgradeTierPanel,
    orgCategory,
}) => {
    let suggestedLevel = 'Standard';
    let ableToBecomePartner = false;
    let ableToBecomeAdvocate = false;

    // question1 is always ignored, as the company is always one of the categories.
    // this was requested by Wenyi SGBC as of 21 Mar 2019
    tierQuestions.push({
        questionId: 1,
    });

    let section1FullFilled = false;
    let section2FullFilled = false;
    if (tierQuestions != null && tierQuestions.length > 0) {
        tierQuestions.forEach(question => {
            if (question.questionId == 1) {
                // question1 is always ignored, as the company is always one of the categories.
                // this was requested by Wenyi SGBC as of 21 Mar 2019
                section1FullFilled = true;
            }
            if (question.questionId == 2 && question.option == 'Yes') {
                section1FullFilled = true;
            }
            if (question.questionId == 3 && question.option == 'Yes') {
                section1FullFilled = true;
            }
            if (question.questionId == 4) {
                section2FullFilled = true;
            }
        });
    }
    if (section1FullFilled && section2FullFilled && docs != null && docs.length >= 1 && docs[0].docId != null) {
        ableToBecomePartner = true;
    }
    if (
        section1FullFilled &&
        section2FullFilled &&
        docs != null &&
        ((docs.length >= 4 && docs[3].docId != null) || (docs.length >= 2 && docs[1].docId != null)) &&
        (docs.length >= 3 && docs[2].docId != null)
    ) {
        ableToBecomeAdvocate = true;
    }
    if (ableToBecomePartner) {
        suggestedLevel = 'Partner';
    }
    if (ableToBecomeAdvocate) {
        suggestedLevel = 'Advocate';
    }
    let docNumber = 1;
    tierQuestions.pop();
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <TierResponsePanel
                        loading={false}
                        tierResponseInfo={tierQuestions}
                        tierResponseProcessor={tierResponsePostProcessor}
                        resubmitQuestionnaires={resubmitQuestionnaires}
                        suggestedLevel={suggestedLevel}
                        tierUpgradedStatus={tierUpgradedStatus}
                        orgCategory={orgCategory}
                    />
                </Col>
                <hr />
            </Row>
            <Row>
                <Col>
                    {docs != null && docs.length >= 1 && (
                        <span className="field-header">
                            These are the documents that applicant has uploaded:
                            <br />
                        </span>
                    )}

                    {(docs == null || docs.length <= 0) && <b>Applicant has not uploaded any document</b>}

                    {docs != null && docs.length >= 1 && docs[0].docId != null ? (
                        <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${docs[0].docId}`}>
                            <u>{docNumber++}. Internal Corporate Sustainability Policies & Practices</u>
                            <br />
                        </a>
                    ) : null}
                    {docs != null && docs.length >= 2 && docs[1].docId != null ? (
                        <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${docs[1].docId}`}>
                            <u>{docNumber++}. Internal Statement of Commitment on Sustainable Supplier Engagement Practices</u>
                            <br />
                        </a>
                    ) : null}
                    {docs != null && docs.length >= 3 && docs[2].docId != null ? (
                        <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${docs[2].docId}`}>
                            <u>{docNumber++}. Published Sustainability Report</u>
                            <br />
                        </a>
                    ) : null}
                    {docs != null && docs.length >= 4 && docs[3].docId != null ? (
                        <a style={{ color: '#424242' }} target="blank" href={`${SGBC_S3_ROOT}${docs[3].docId}`}>
                            <u>{docNumber++}. 3rd Party Certified Environmental Management Systems</u>
                            <br />
                        </a>
                    ) : null}
                </Col>
            </Row>

            {/* <Row>
                <Col>
                    {resubmitQuestionnaires && (
                        <React.Fragment>
                            <br />
                            <Button
                                className="primary-btn-style mr-2"
                                onClick={() => resubmitQuestionnaires()}
                                style={{ position: 'relative' }}
                            >
                                &nbsp;Resubmit
                            </Button>
                        </React.Fragment>
                    )}
                </Col>
            </Row> */}

            <Row>
                <Col style={{ padding: '10px' }}>
                    <hr />
                    {upgradeTierPanel}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default TierResponsePopup;
