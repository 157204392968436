/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function charge(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/stripe`, {
            action: 'charge',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function getPaymentInfo(registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'get-payment-info-by-registrationid',
            id: registrationId,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function updatePayment(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'update-payment',
        });

        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
