import React from 'react';
import { Row, Col } from 'reactstrap';
import { ButtonComponent, CardWrapper } from 'pages/staff/membership/applications/StaffMembershipExportPage';

const StaffCertificationPredetermineExport = ({ exportAction }) => {
    return (
        <React.Fragment>
            <CardWrapper>
                <Row md={12}>
                    <h4 className="p-2">
                        <strong>Product Category</strong>
                    </h4>
                </Row>
            </CardWrapper>

            <RowWrapper>
                <ButtonComponent onClick={e => exportAction('product', 1, 'tick-1')} label="Tick 1" />
                <ButtonComponent onClick={e => exportAction('product', 2, 'tick-2')} label="Tick 2" />
                <ButtonComponent onClick={e => exportAction('product', 3, 'tick-3')} label="Tick 3" />
            </RowWrapper>
            <RowWrapper md={8}>
                <ButtonComponent onClick={e => exportAction('product', 4, 'tick-4')} label="Tick 4" />
                <ButtonComponent onClick={e => exportAction('product', 'all')} label="All" />
            </RowWrapper>
            <RowWrapper />
            <hr />
            <CardWrapper>
                <Row md={12}>
                    <h4 className="p-2">
                        <strong>Services Category</strong>
                    </h4>
                </Row>
            </CardWrapper>
            <RowWrapper md={4}>
                <ButtonComponent onClick={e => exportAction('service', 'all')} label="All" />
            </RowWrapper>
        </React.Fragment>
    );
};

const RowWrapper = ({ children, md }) => (
    <Col md={md}>
        <Row md={6}>{children}</Row>
    </Col>
);

export default StaffCertificationPredetermineExport;
