/**
 * Button Icon
 *
 * Author: Fernando Karnagi
 */
import React from 'react';

class ButtonIcon extends React.Component {
    render() {
        const {
            icon,
            flat,
            fontStyle,
            borderStyle,
            width,
            height,
            block,
            disabled,
            onClick,
            className,
            label,
            customLabel,
            fontColor,
            buttonColor,
        } = this.props;

        let iconClazz = '';
        if (icon) iconClazz = 'btn-icon';

        let flatClazz = '';
        if (flat) flatClazz = 'btn-flat';

        let updatedFontColor = fontColor;
        if (fontColor == null) updatedFontColor = 'white';

        let buttonStyle = {
            color: updatedFontColor,
            ...fontStyle,
            ...borderStyle,
        };
        if (buttonColor != null) {
            buttonStyle.borderColor = buttonColor;
            buttonStyle.backgroundColor = buttonColor;
        }

        const iconStyle = {
            fontWeight: 'bold',
            ...fontStyle,
        };

        if (width) buttonStyle.width = width;
        if (height) buttonStyle.height = height;

        const btnBlock = block ? 'btn-block' : '';
        return (
            <button
                type="button"
                style={buttonStyle}
                disabled={disabled}
                onClick={onClick}
                className={`text-bold btn ${btnBlock} ${buttonColor == null && `primary-btn-style`} 
                ${iconClazz} ${flatClazz} ${className || ' text-center'}`}
            >
                {icon && (
                    <i className="material-icons align-text-bottom" style={iconStyle}>
                        {icon}
                    </i>
                )}
                {customLabel || <p>{label}</p>}
            </button>
        );
    }
}

export default ButtonIcon;
