/**
 * Staff Application List page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';

import { FormGroup, Input, Row, Col, Form, Button } from 'reactstrap';
import { Image } from 'semantic-ui-react';
import AllCertificationsSummary from './applications/overview/AllCertificationsSummary';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderReactTable from 'cyder/table/CyderReactTable';
import Select from 'react-select';
import TextTruncate from 'react-text-truncate';
import {
    staffCertListing,
    // staffCertListPageSetLoadingState,
    getCertificationStats,
} from 'actions/staff/certification/staffCertificationApplicationAction';

import { capitalizeFirstLetter } from 'js/util';

import 'css/forms/default-forms.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

const statusSelectOptions = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'expired',
        label: 'Expired',
    },
];

class StaffCertificationListPage extends React.Component {
    constructor() {
        super();
        this.getInfo = this.getInfo.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.state = {
            status: {
                value: 'active',
                label: 'Active',
            },
            type: null,
            keyword: null,
            page: null,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            issuedFrom: {
                id: 'Issued From',
                focused: false,
                date: null,
                placeholder: 'Issued From',
            },
            issuedTo: {
                id: 'Issued To',
                focused: false,
                date: null,
                placeholder: 'Issue To',
            },
            focusedInput: null,
        };
    }
    componentDidMount() {
        this.props.getCertificateStats(this.props.type);
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.type === 'certIssuedThisYear' || this.props.location.state.type === 'certIssuedThisMonth') {
                this.onDateChange(this.props.location.state.type, null, null);
                setTimeout(() => {
                    this.table.fireFetchData();
                }, 150);
            } else if (
                this.props.location.state.type === 'certExpiredLastYear' ||
                this.props.location.state.type === 'certExpiredThisYear'
            ) {
                this.onFilterChange(this.props.location.state.type, this.props.type);
                setTimeout(() => {
                    this.table.fireFetchData();
                }, 150);
            }
        }
    }
    componentWillReceiveProps(prevProps) {
        if (prevProps.type !== this.props.type) {
            this.setState({
                status: {
                    value: 'active',
                    label: 'Active',
                },
            });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type) {
            this.props.getCertificateStats(this.props.type);
            this.table.fireFetchData();
        }
    }
    getInfo(
        page = 0,
        pageSize = 20,
        keyword = null,
        status = null,
        sorted = null,
        filtered = null,
        issuedFrom = null,
        issuedTo = null,
        filterType = null,
    ) {
        this.props.staffCertListing(
            page,
            pageSize,
            sorted,
            filtered,
            keyword,
            status,
            this.props.type,
            null,
            issuedFrom,
            issuedTo,
            filterType,
        );
    }
    onSelectChange(e) {
        var state = this.state;
        state.status = e;
        this.setState({
            state,
        });
        // this.table.fireFetchData();
        //this.getInfo(state.page, state.pageSize, state.sorted, state.filtered, state.keyword, state.status);
    }
    onKeywordChange(e) {
        var state = this.state;
        state.keyword = e.target.value;
        this.setState({
            state,
        });
        // this.table.fireFetchData();
        // this.getInfo(state.page, state.pageSize, state.sorted, state.filtered, state.keyword, state.status);
    }
    onDateChange = (type = null, startDate, endDate) => {
        let issuedFromState = Object.assign({}, this.state.issuedFrom);
        let issuedToState = Object.assign({}, this.state.issuedTo);
        var startDateInput = startDate;
        var endDateInput = endDate;
        if (type === 'certIssuedThisYear') {
            issuedFromState.date = moment().startOf('year');
            issuedToState.date = moment().endOf('year');
        } else if (type === 'certIssuedThisMonth') {
            issuedFromState.date = moment().startOf('month');
            issuedToState.date = moment().endOf('month');
        }

        // UPDATE
        switch (this.state.focusedInput) {
            case 'startDate':
                issuedFromState.date = startDateInput;
                break;
            case 'endDate':
                issuedToState.date = endDateInput;
                break;
        }
        // SET STATE
        this.setState({
            issuedFrom: issuedFromState,
            issuedTo: issuedToState,
        });
    };
    onFilterChange = (filterType, type) => {
        var state = this.state;
        state.filterType = filterType;
        state.type = type;
        this.setState({ state });
    };
    render() {
        const isProduct = this.props.type === 'product';
        const columns = [
            {
                Header: 'Company',
                accessor: 'companyName',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Cert No.',
                accessor: 'certificateNumber',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Description',
                accessor: 'category',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 200,
                Cell: props => {
                    var { certType, serviceCategory, brand, model, productCategory, productSubcategory, productType } = props.original;
                    var productDetail = productCategory + ' > ' + productSubcategory + ' > ' + productType;
                    if (certType == 'service') {
                        return <b>{serviceCategory}</b>;
                    } else {
                        return (
                            <React.Fragment>
                                <b>{productDetail}</b>
                                <div>{brand ? brand.toUpperCase() : ''}</div>
                                <div>{model ? model : ''}</div>
                            </React.Fragment>
                        );
                    }
                },
            },
            /*{
                Header: 'Category',
                accessor: 'serviceCategory',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
                show: this.props.type === 'service' ? true : false,
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                style: { whiteSpace: 'unset' },
                minWidth: 100,
                show: this.props.type === 'product' ? true : false,
            },
            {
                Header: 'Model',
                style: { whiteSpace: 'unset' },
                accessor: 'model',
                minWidth: 200,
                show: this.props.type === 'product' ? true : false,
                Cell: props => {
                    return <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.value}</div>;
                },
            },*/
            {
                Header: 'Rating',
                style: { whiteSpace: 'unset' },
                accessor: 'rating',
                maxWidth: 100,
                Cell: props => {
                    const { serviceRating } = props.original;

                    if (!props.value) return;
                    if (isProduct) {
                        const array = new Array(parseInt(props.value)).fill(0);
                        return (
                            <Row style={{ marginLeft: '-5px' }}>
                                {array.map((x, i) => {
                                    return <Image key={i} src="/assets/icons/tick.png" alt="tick" width="16" height="12" />;
                                })}
                            </Row>
                        );
                    } else {
                        return serviceRating === '0' ? '-' : `L${serviceRating}`;
                    }
                },
            },
            {
                Header: 'Issued',
                style: { whiteSpace: 'unset' },
                accessor: 'issuedOn',
                maxWidth: 100,
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Valid Till',
                accessor: 'expiryDate',
                style: { whiteSpace: 'unset' },
                maxWidth: 100,
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: '',
                sortable: false,
                accessor: '',
                width: 100,
                Cell: props => {
                    const url = `../certificates/${props.value.certType}/certdetails/${props.value.certId}`;
                    return (
                        <Link to={url}>
                            <Button className="ml-2 align-middle primary-btn-style primary-btn-xs">
                                <i className="fas fa-folder-open mr-1" />
                                Open
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        if (this.props.forceLoading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <Row>
                    <Col xs={12}>
                        <AllCertificationsSummary
                            product={isProduct}
                            title={capitalizeFirstLetter(this.props.type)}
                            certIssuedThisYear={this.props.stats.issuedThisYear}
                            certIssuedThisMonth={this.props.stats.issuedThisMonth}
                            certRenewedLastYear={this.props.stats.renewedPercentageLastYear}
                            certRenewedThisYear={this.props.stats.renewedPercentageThisYear}
                            linkText={'/staff/certification/certificates/' + this.props.type}
                        />
                    </Col>
                </Row>
                <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Col xs={12}>
                        <Form inline>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.table.fireFetchData();
                                    }}
                                    placeholder="Keyword"
                                    onChange={e => this.onKeywordChange(e)}
                                />
                                <Select
                                    onChange={e => this.onSelectChange(e)}
                                    value={this.state.status}
                                    className="w-200 ml-2"
                                    placeholder=""
                                    autofocus
                                    options={statusSelectOptions}
                                    id="status-select"
                                    simpleValue
                                    name="selected-state"
                                    searchable={true}
                                />
                                &nbsp;
                                <DateRangePicker
                                    small
                                    showClearDates
                                    showDefaultInputIcon
                                    startDate={this.state.issuedFrom.date}
                                    startDateId={this.state.issuedFrom.id}
                                    endDate={this.state.issuedTo.date}
                                    endDateId={this.state.issuedTo.id}
                                    focusedInput={this.state.focusedInput}
                                    onDatesChange={({ startDate, endDate }) => this.onDateChange(null, startDate, endDate)}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    isOutsideRange={day => {}}
                                />
                                <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                    <i className="material-icons align-text-bottom">search</i>
                                    &nbsp;Search
                                </Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.props.certs}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            manual
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            pages={this.props.tablePages}
                            totalNum={this.props.totalNum}
                            loading={this.props.loading}
                            onFetchData={(state, instance) => {
                                this.getInfo(
                                    state.page,
                                    state.pageSize,
                                    this.state.keyword,
                                    this.state.status,
                                    state.sorted,
                                    state.filtered,
                                    this.state.issuedFrom.date ? this.state.issuedFrom.date.format('YYYY-MM-DD') : null,
                                    this.state.issuedTo.date ? this.state.issuedTo.date.format('YYYY-MM-DD') : null,
                                    this.state.filterType,
                                );
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        applicationDetailStatuses: state.memberCertifications.statuses,
        ...state.staffCertificationApplicationReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        staffCertListing: (page, pageSize, sorted, filtered, keyword, status, type, companyId = null, issuedFrom, issuedTo, filterType) => {
            dispatch(
                staffCertListing(page, pageSize, sorted, filtered, keyword, status, type, companyId, issuedFrom, issuedTo, filterType),
            );
        },
        getCertificateStats: type => {
            dispatch(getCertificationStats(type));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StaffCertificationListPage),
);
