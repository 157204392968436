import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    USER_DETAIL_GET_DETAIL_REQUEST,
    USER_DETAIL_GET_DETAIL_SUCCESS,
    USER_DETAIL_GET_DETAIL_FAILURE,
    USER_DETAIL_CHANGE_FIELD_VALUE,
} from '../../constants/actionTypesAuth';
import withQuery from 'with-query';
import moment from 'moment';

export function userDetailGetUserDetails(userid) {
    return (dispatch, getState) => {
        dispatch({ type: USER_DETAIL_GET_DETAIL_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/users', {
            action: 'getuserinfo',
            userid: userid,
        });
        const body = {
            userid,
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: USER_DETAIL_GET_DETAIL_FAILURE,
                            error,
                        });
                    });
                } else {
                    return response.json().then(response => {
                        let sanitizedUserInfo = response.data.userInfo;
                        const sanitizedLastLogin = moment(sanitizedUserInfo.last_login);
                        const sanitizedDateJoined = moment(sanitizedUserInfo.date_joined);
                        sanitizedUserInfo.last_login = sanitizedLastLogin;
                        sanitizedUserInfo.date_joined = sanitizedDateJoined;
                        dispatch({
                            type: USER_DETAIL_GET_DETAIL_SUCCESS,
                            userData: sanitizedUserInfo,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: USER_DETAIL_GET_DETAIL_FAILURE, error });
            });
    };
}
export function onChangeField(key, value) {
    return {
        type: USER_DETAIL_CHANGE_FIELD_VALUE,
        key,
        value,
    };
}
