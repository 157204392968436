import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';

class Search extends React.Component {
    componentDidMount() {
        // Do nothing
    }

    render() {
        return (
            <div className="navbar-search-form">
                <div className="form-group">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder=""
                        />
                        <span
                            className="input-group-addon"
                            onClick={this.props.search}
                        >
                            <i className="material-icons">search</i>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: () => {
            history.replace('/staff/search');
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
