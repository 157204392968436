/**
 * Member Certification Overview page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import { SGBC_DISABLED_CERT, SGBC_OVERRIDE_ENABLED_CERT_COMPANIES } from 'config.js';
import { Row, Col, Container, Nav, NavItem, NavLink } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import IconWidget0 from 'cyder/widget/IconWidget0';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
// import ApplicationsSummary from './ApplicationsSummary';
// import CertificationsSummary from './CertificationsSummary';

import { memberCertOverviewGetInfo, memberCertOverviewSetFilteredBy } from 'actions/member/certification/memberCertificationOverviewAction';

const FilterNavComponentWrapper = props => {
    return (
        <div>
            <Nav pills>{props.children}</Nav>
        </div>
    );
};

const containerStyle = { width: 1000, marginBottom: '5em' };

class MemberCertificationOverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.cardOnclickHandler = this.cardOnclickHandler.bind(this);
        this.state = {
            activeTab: 'product',
        };
    }
    componentDidMount() {
        let disabledCert = SGBC_DISABLED_CERT;
        if (
            this.props.profile != null &&
            this.props.profile.profile != null &&
            this.props.profile.profile.company_name != null &&
            SGBC_OVERRIDE_ENABLED_CERT_COMPANIES.includes(this.props.profile.profile.company_name.toLowerCase())
        ) {
            disabledCert = false;
        }
        this.setState({
            disabledCert,
            activeTab: disabledCert ? 'productCert' : 'productAppl',
        });

        this.props.memberCertOverviewGetInfo();
    }
    cardOnclickHandler(category, type, status) {
        this.props.memberCertOverviewSetFilteredBy(status);
        let url;
        if (category === 'product') {
            switch (type.toLowerCase()) {
                case 'application':
                    url = '/member/certification/product/applications';
                    break;
                case 'certification':
                    url = '/member/certification/product/certificates';
                    break;
            }
        }
        if (category === 'service') {
            switch (type.toLowerCase()) {
                case 'application':
                    url = '/member/certification/service/applications';
                    break;
                case 'certification':
                    url = '/member/certification/service/certificates';
                    break;
            }
        }
        history.push(url);
    }
    toggleTab(tab) {
        this.setState({
            activeTab: tab,
        });
    }
    renderNav(navItems) {
        return navItems.map(item => {
            return (
                <NavItem key={item.key} className="mr-1">
                    <NavLink
                        className={this.state.activeTab === item.key ? 'active p-2' : 'p-2'}
                        onClick={() => {
                            this.toggleTab(item.key);
                        }}
                    >
                        <strong className="field-header">{item.text.toUpperCase()}</strong>
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        const typeNavs = [
            {
                key: 'product',
                text: 'Product',
            },
            {
                key: 'service',
                text: 'Service',
            },
        ];
        const prodAppls = [
            {
                color: '#428156',
                icon: 'far fa-calendar-check',
                topText: 'Application',
                numberText: this.props.prodApplAppl,
            },
            {
                color: '#89aa49',
                icon: 'fas fa-money-check-alt',
                topText: 'Payment',
                numberText: this.props.prodApplPayment,
            },
            {
                color: '#858687',
                icon: 'fas fa-award',
                topText: 'Issued',
                numberText: this.props.prodApplIssued,
            },
        ];
        const prodCerts = [
            {
                color: '#428156',
                icon: 'far fa-calendar-times',
                topText: 'Expired',
                numberText: this.props.prodCertExpired,
            },
            {
                color: '#89aa49',
                icon: 'fas fa-hourglass-half',
                topText: 'Expiring',
                numberText: this.props.prodCertExpiring,
            },
            {
                color: '#858687',
                icon: 'fas fa-check',
                topText: 'Valid',
                numberText: this.props.prodCertValid,
            },
        ];
        const prodApplTotal = this.props.prodApplTotal;
        const prodCertTotal = this.props.prodCertExpired + this.props.prodCertExpiring + this.props.prodCertValid;
        // services
        const servAppls = [
            {
                color: '#428156',
                icon: 'far fa-calendar-check',
                topText: 'Application',
                numberText: this.props.servApplAppl,
            },
            {
                color: '#89aa49',
                icon: 'fas fa-money-check-alt',
                topText: 'Payment',
                numberText: this.props.servApplPayment,
            },
            {
                color: '#858687',
                icon: 'fas fa-award',
                topText: 'Issued',
                numberText: this.props.servApplIssued,
            },
        ];
        const servCerts = [
            {
                color: '#428156',
                icon: 'far fa-calendar-times',
                topText: 'Expired',
                numberText: this.props.servCertExpired,
            },
            {
                color: '#89aa49',
                icon: 'fas fa-hourglass-half',
                topText: 'Expiring',
                numberText: this.props.servCertExpiring,
            },
            {
                color: '#858687',
                icon: 'fas fa-check',
                topText: 'Valid',
                numberText: this.props.servCertValid,
            },
        ];
        const servApplTotal = this.props.servApplTotal;
        const servCertTotal = this.props.servCertExpired + this.props.servCertExpiring + this.props.servCertValid;
        return this.props.loading ? (
            <LoadingSpinner center />
        ) : (
            <Container style={containerStyle}>
                <br />
                <Row>
                    <Col xs={12} className="d-flex">
                        <h2>
                            <strong>CERTIFICATION</strong>
                        </h2>
                    </Col>
                </Row>
                <br />
                <FilterNavComponentWrapper>{this.renderNav(typeNavs)}</FilterNavComponentWrapper>
                <React.Fragment>
                    <div
                        style={{
                            width: '100%',
                            height: '10px',
                            backgroundColor: '#4b4b4b',
                            borderTopRightRadius: '5px',
                        }}
                    />
                    <div
                        className="page-widget"
                        style={{
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                        }}
                    >
                        {this.state.activeTab === 'product' ? (
                            <Col xs={12}>
                                <NoShadowPanel flat borderSize={30} icon="extension" title="Product" panelStyle={{ color: '#424242' }}>
                                    {!this.state.disabledCert && (
                                        <InfoPanel
                                            topTitle={`Applications (${prodApplTotal})`}
                                            items={prodAppls}
                                            onClick={this.cardOnclickHandler}
                                            category="product"
                                            type="application"
                                        />
                                    )}
                                    <br />
                                    <InfoPanel
                                        topTitle={`Certifications (${prodCertTotal})`}
                                        items={prodCerts}
                                        onClick={this.cardOnclickHandler}
                                        category="product"
                                        type="certification"
                                    />
                                </NoShadowPanel>
                            </Col>
                        ) : (
                            <Col xs={12}>
                                <NoShadowPanel flat borderSize={30} icon="streetview" title="Services" panelStyle={{ color: '#424242' }}>
                                    {!this.state.disabledCert && (
                                        <InfoPanel
                                            topTitle={`Applications (${servApplTotal})`}
                                            items={servAppls}
                                            onClick={this.cardOnclickHandler}
                                            category="service"
                                            type="application"
                                        />
                                    )}
                                    <br />
                                    <InfoPanel
                                        topTitle={`Certifications (${servCertTotal})`}
                                        items={servCerts}
                                        onClick={this.cardOnclickHandler}
                                        category="service"
                                        type="certification"
                                    />
                                </NoShadowPanel>
                            </Col>
                        )}
                        <br />
                    </div>
                </React.Fragment>
            </Container>
        );
    }
}

const InfoPanel = ({ items, topTitle, onClick, category, type }) => {
    return (
        <div className="info-panel">
            <Row>
                <Col xs={12}>
                    <h5 style={{ color: '#757575' }} className="text-bold">
                        {topTitle}
                    </h5>
                </Col>
            </Row>
            <Row>
                {items.map((item, i) => (
                    <Col key={i} xs={4} className="p-1">
                        <IconWidget0
                            icon={item.icon}
                            color={item.color}
                            topText={item.topText}
                            numberText={item.numberText}
                            numberTextStyle={{
                                fontSize: '70px',
                                fontWeight: 'bold',
                                marginTop: '-20px',
                            }}
                            iconStyle={{ fontSize: '40px', marginTop: '5px' }}
                            onClick={() => onClick(category, type, item.topText.toLowerCase())}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
        ...state.memberCertificationsOverviewReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        memberCertOverviewGetInfo: () => {
            dispatch(memberCertOverviewGetInfo());
        },
        memberCertOverviewSetFilteredBy: status => {
            dispatch(memberCertOverviewSetFilteredBy(status));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberCertificationOverviewPage));
