import React from 'react';

import { Elements, StripeProvider } from 'react-stripe-elements';
import BankTransferPayment from './BankTransferPayment';
// import CashPayment from './CashPayment';
import ChequePayment from './ChequePayment';
import CreditCardPayment from './CreditCardPayment';
import CorporatePayNow from './CorporatePayNow';

import { SGBC_STRIPE_PK } from '../../../../../../config';

class PaymentChoicesPanel extends React.Component {
    render() {
        const props = {
            back: this.props.back,
            cancel: this.props.cancel,
            paymentMode: this.props.paymentMode,
            applicationInfo: this.props.applicationInfo,
            dialogModalToggle: this.props.dialogModalToggle,
            type: this.props.type,
        };
        return (
            <StripeProvider apiKey={SGBC_STRIPE_PK}>
                <Elements>
                    <React.Fragment>
                        {this.props.paymentMode === 1 && <ChequePayment {...props} />}
                        {this.props.paymentMode === 2 && <BankTransferPayment {...props} />}
                        {this.props.paymentMode === 4 && <CreditCardPayment {...props} />}
                        {this.props.paymentMode === 5 && <CorporatePayNow />}
                        {/* CASH PAYMENT COMPONENT IS UNTOUCHED */}
                        {/* {this.props.paymentMode === 3 && (
                            <CashPayment
                                onDateChanged={this.props.onDateChanged}
                                onTextChanged={this.props.onTextChanged}
                                paymentInfo={this.props.paymentInfo}
                            />
                        )} */}
                    </React.Fragment>
                </Elements>
            </StripeProvider>
        );
    }
}

export default PaymentChoicesPanel;
