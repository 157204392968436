import {
    MEMBER_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS,
    MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
    MEMBER_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
    MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
} from '../../constants/actionTypesMemberDirectory';

import fetchlib from '../../../js/fetchlib';
import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';

export function memberMembershipMemberGetAllMemberDirectory() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}`, {
            action: '',
        });
        const body = {
            itemsPerPage: '500',
            page: 1,
            keyword: '',
            type: 'NotExpired',
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, MEMBER_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS, null);
    };
}
export function memberMembershipMemberGetMemberDirectory(type, page, pageSize) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}`, {
            action: '',
        });
        const body = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: '',
            type,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
            MEMBER_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
            MEMBER_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
        );
    };
}
