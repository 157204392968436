import React, { Fragment } from 'react';
import { List, Segment, Header, Icon, Dropdown, Button, Divider, Checkbox, Accordion } from 'semantic-ui-react';
import { Input, Label } from 'reactstrap';

const Sidebar = ({
    isProductListPage,
    isProductDetailPage,
    isProductSearchPage,
    category,
    subCategory,
    options,
    checkboxes,
    onChangeProductType,
    onReset,
    handleChanges,
    states,
    loading,
}) => {
    const { productTypeOptions } = options;
    const { onChangeBca, onChangeGreenCredentials, onChangeGreenAttributes, onChangeLowVoc } = handleChanges;
    const { productTypeId, lowvoc } = states;
    const panels = [
        {
            key: 0,
            title: 'BCA Functional System',
            content:
                checkboxes &&
                checkboxes.bca.map(cb => (
                    <div key={cb.bcaDataOption}>
                        <Checkbox onChange={onChangeBca} value={cb.id} name="bca" label={cb.bcaDataOption} />
                    </div>
                )),
        },
        {
            key: 1,
            title: 'Green Credentials',
            content:
                checkboxes &&
                checkboxes.buildingTypes.map(cb => (
                    <div key={cb.buildingTypeOption}>
                        <Checkbox onChange={onChangeGreenCredentials} value={cb.id} name="greenCredentials" label={cb.buildingTypeOption} />
                    </div>
                )),
        },
        {
            key: 2,
            title: 'Green Attributes',
            content:
                checkboxes &&
                checkboxes.green.map(cb => (
                    <div key={cb.otherDataOption}>
                        <Checkbox onChange={onChangeGreenAttributes} value={cb.id} name="greenAttributes" label={cb.otherDataOption} />
                    </div>
                )),
        },
    ];

    // console.log('Sidebar category', category);

    // console.log('Sidebar subcategory', subCategory);
    return (
        <Segment basic className={loading ? 'loadingup' : ''} style={{ paddingTop: '35px' }}>
            {/* <Segment clearing basic> */}
            {/* <Header as="h4" floated="right" content="Reset" className="reset" icon="remove" onClick={onReset} /> */}
            {/* </Segment> */}
            {/*isProductListPage && (
                <Fragment>
                    <div className="field pl-1">
                        <Header
                            as="h4"
                            className="mx-2 my-1"
                            style={{ color: '#78be20', fontSize: 28 }}
                            content={`${category && category.length > 0 ? category[0].category : ''}`}
                        />
                        <Header
                            as="h5"
                            className="mx-2 my-1"
                            style={{
                                color: '#555',
                                fontSize: 20,
                            }}
                            content={`${subCategory && subCategory.length > 0 ? subCategory[0].subcategory : ''}`}
                        />
                    </div>
                </Fragment>
            )*/}

            {productTypeOptions && (
                <div className="field pl-1">
                    <u>
                        <Header as="h4" content="Product Type" />
                    </u>
                    <List bulleted link relaxed="very">
                        {productTypeOptions.map(item => (
                            <List.Item
                                style={{ cursor: 'pointer' }}
                                active={item.value === productTypeId}
                                key={item.key}
                                onClick={e => onChangeProductType(e, item)}
                            >
                                {item.text}
                            </List.Item>
                        ))}
                    </List>
                </div>
            )}
            <Divider />
            {/* 
                When enabling the acordion checkboxes, please remove Low VOC from green attributes panel.
                Low VOC is only tag on cert level and we do not store in "certificates_certificate_other_data_options" table.
                Therefore I created an individual checkbox for Low VOC.
                - Ken, 25/09/2019 
            */}

            {category && category.length > 0 && category[0].id !== 99 && (
                <Fragment>
                    {/*<Accordion panels={panels} exclusive={false} />*/}
                    {/*<div key="Low VOC">
                        <Checkbox disabled onChange={e => onChangeLowVoc()} name="lowvoc" label={'Low VOC'} />
            </div>*/}
                    <Label>Low VOC</Label>
                    <Input type="select" id="lowvoctype" value={lowvoc} onChange={e => onChangeLowVoc(e)}>
                        <option value="all">All</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </Input>
                </Fragment>
            )}
        </Segment>
    );
};

export default Sidebar;
