import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib.js';
import {
    EVENT_SAVE_NEW_EVENT_TRACK,
    EVENT_SAVE_SINGLE_EVENT_TRACK,
    EVENT_DELETE_SINGLE_EVENT_TRACK,
    EVENT_GET_EVENT_TRACKS_REQUEST,
    EVENT_GET_EVENT_TRACKS_SUCCESS,
    EVENT_GET_EVENT_TRACKS_FAILURE,
    EVENT_SAVE_TRACK_REQUEST,
    EVENT_SAVE_TRACK_SUCCESS,
    EVENT_SAVE_TRACK_FAILURE,
    EVENT_UPDATE_TRACK_REQUEST,
    EVENT_UPDATE_TRACK_SUCCESS,
    EVENT_UPDATE_TRACK_FAILURE,
    EVENT_TRACK_CHANGE_FIELD_VALUE,
    EVENT_TRACK_SET_EVENT_ID,
    EVENT_TRACK_RESET_FIELDS,
    EVENT_TRACK_SET_MODAL_DATA,
} from '../../../constants/events/actionTypesStaffEventsTrack.js';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config.js';

export function eventGetEventTracks(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-track`, {
            action: 'findByEventId',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_GET_EVENT_TRACKS_REQUEST,
            EVENT_GET_EVENT_TRACKS_SUCCESS,
            EVENT_GET_EVENT_TRACKS_FAILURE,
        );
    };
}
export function eventSaveAllEventTracks() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-track`, {
            action: 'create',
        });
        const body = { tracks: getState().eventsTrackReducer.tracks };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_SAVE_TRACK_REQUEST,
            EVENT_SAVE_TRACK_SUCCESS,
            EVENT_SAVE_TRACK_FAILURE,
        );
    };
}
export function eventUpdateAllEventTracks(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-track`, {
            action: 'update',
        });
        const body = {
            eventId,
            tracks: getState().eventsTrackReducer.tracks,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_UPDATE_TRACK_REQUEST,
            EVENT_UPDATE_TRACK_SUCCESS,
            EVENT_UPDATE_TRACK_FAILURE,
        );
    };
}
export function eventSaveSingleEventTrack(inputid) {
    return (dispatch, getState) => {
        dispatch({
            type: EVENT_SAVE_SINGLE_EVENT_TRACK,
            inputid,
        });
        return Promise.resolve();
    };
}
export function eventRemoveSingleEventTrack(inputid) {
    return (dispatch, getState) => {
        dispatch({
            type: EVENT_DELETE_SINGLE_EVENT_TRACK,
            inputid,
        });
        return Promise.resolve();
    };
}
export function eventSaveNewEventTrack() {
    return {
        type: EVENT_SAVE_NEW_EVENT_TRACK,
    };
}
export function eventTrackSetEventId(eventid) {
    return {
        type: EVENT_TRACK_SET_EVENT_ID,
        eventid,
    };
}
export function eventChangeTrackInput(value, id, inputid) {
    return {
        type: EVENT_TRACK_CHANGE_FIELD_VALUE,
        value,
        id,
        inputid,
    };
}
export function eventResetTrackFields() {
    return (dispatch, getState) => {
        dispatch({
            type: EVENT_TRACK_RESET_FIELDS,
        });
        return Promise.resolve();
    };
}
export function eventTracksSetModalData(data) {
    return {
        type: EVENT_TRACK_SET_MODAL_DATA,
        data,
    };
}
