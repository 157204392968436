import {
    STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST,
    STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS,
    STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE,
    STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE,
    STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';
import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';

export function staffCompanyDetailsGetCompanyInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getcompanyinfo',
            companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST,
            STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS,
            STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE,
        );
    };
}
export function staffCompanyDetailsGetMemberInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipInfo',
            companyId: companyId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, {}, null, STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS, null);
    };
}
export function staffCompanyDetailsGetMemberInfoIncludingExpiry(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipInfoIncludingExpiry',
            companyId: companyId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, {}, null, STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS, null);
    };
}
export function staffCompanyDetailsGetCertificationInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getCountCertificateByCompanyId',
            companyId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, {}, null, STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS, null);
    };
}
export function staffCompanyDetailsGetEventInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event`, {
            action: 'getCountEventsAttendedByCompanyId',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, {}, null, STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS, null);
    };
}
export function staffCompanyDetailsOverviewHandleInputChange(event) {
    return {
        type: STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE,
        event,
    };
}
export function staffCompanyDetailsOverviewResetToDefault() {
    return dispatch => {
        dispatch({ type: STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT });
        return Promise.resolve();
    };
}
