import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import history from './history';
import rootReducer from './reducers/index';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middleware = routerMiddleware(history);
export default function configureStore() {
    const config = {
        key: 'root',
        whitelist: [
            'cyderProfileReducer',
            'cyderLoginReducer',
            'profile',
            'member',
            'config',
        ],
        storage,
    };
    const combinedReducer = persistReducer(config, rootReducer);

    const actionSanitizer = action => {
        return action;
        /*
        let newAction = Object.assign({}, action);
        let suppress = false;
        if (action.type === CYDER_EXPENSE_NEW_TRAVEL_LOCATION_START_SET ||
            action.type === CYDER_EXPENSE_NEW_TRAVEL_LOCATION_DESTINATION_SET ||
            action.type === CYDER_EXPENSE_NEW_TRAVEL_LOCATION_REMOVE_MARKERS) {
            suppress = true;
        }
        if (suppress === true) {
            newAction = {
                ...action,
                place: action.place.name,
                marker: '<<marker>>',
            }
        }
        return newAction;
        */
    };
    const stateSanitizer = state => {
        return state;
        /*
        let newState = Object.assign({}, state);
        if (state.cyderExpenseTravelReducer.travelLocStartMarker) {
            newState.cyderExpenseTravelReducer.travelLocStartMarker = '<<travelLocStartMarker>>';
            newState.cyderExpenseTravelReducer.travelLocStart = '<<travelLocStart>>';
        }

        if (state.cyderExpenseTravelReducer.travelLocDestinationMarker) {
            newState.cyderExpenseTravelReducer.travelLocDestinationMarker = '<<travelLocDestinationMarker>>';
            newState.cyderExpenseTravelReducer.travelLocDestination = '<<travelLocDestination>>';
        }

        return newState;
        */
    };
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  actionSanitizer,
                  stateSanitizer,
              })
            : compose;

    const store = createStore(
        combinedReducer,
        {},
        composeEnhancers(applyMiddleware(middleware, thunk)),
    );
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    const persistor = persistStore(store);
    // return store;
    return { store, persistor };
}
