import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

import { Row, Col, Button } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import PaymentChoicesPanel from './PaymentChoicesPanel';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import { applicationLoadEventPaymentModes, loadApplicationInfo } from 'actions/member/membership/application/MembershipPayNowAction.js';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import 'css/cyder/form/common-form.css';

class PayNowPanel extends React.Component {
    constructor() {
        super();
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.showLoadingCreditCard = this.showLoadingCreditCard.bind(this);
        this.showLoadingPaymentMode = this.showLoadingPaymentMode.bind(this);
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.cancel = this.cancel.bind(this);
        this.submit = this.submit.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);

        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            paymentStart: false,
            loadingSubmit: false,
            loadingCreditCard: false,
            loadingPaymentMode: false,
            applicationInfo: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                modalAction: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    modalAction() {
        this.modalToggle();
        history.push(`/member/membership/applicationlist`);
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide, modalAction) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.modalAction = modalAction || this.modalAction;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    boundForceUpdate() {
        this.modalToggle();
        this.componentDidMount();
    }
    submit() {}
    cancel() {
        this.showModal(
            'Confirmation',
            `You have decided to settle the payment later. Please note that your membership cannot be issue if we 
        have not received payment . Thank you.`,
            null,
            false,
            true,
        );
    }
    back() {
        this.setState({
            paymentStart: false,
        });
    }
    next() {
        if (this.state.selectedPaymentMode.value === '') {
            this.showModal('Warning', 'Please select a payment mode before proceed.', 'alert', false, true, 'forceUpdate');
            return;
        }
        this.setState({
            paymentStart: true,
        });
    }
    setPaymentMode(e) {
        this.setState({
            selectedPaymentMode: e,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingCreditCard(show) {
        this.setState({
            loadingCreditCard: show,
        });
    }
    showLoadingPaymentMode(show) {
        this.setState({
            loadingPaymentMode: show,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var applicationId = this.props.match.params.id;
        this.showLoadingPaymentMode(true);
        this.props
            .loadEventPaymentModes()
            .then(paymentModes => {
                this.showLoadingPaymentMode(false);
                var paymentModesToAdd = [];
                paymentModes.data.forEach(paymentMode => {
                    // Allow corporate now ONLY, as requested by Jaq as per https://cydersg.atlassian.net/browse/CRMSD-167
                    // if (paymentMode.id === 4 || paymentMode.id === 5) {
                    if (paymentMode.id === 5) {
                        paymentModesToAdd.push({
                            value: paymentMode.id,
                            label: paymentMode.mode,
                        });
                    }
                });
                this.setState({
                    paymentModes: paymentModesToAdd,
                });
            })
            .catch(error => {
                this.showLoadingPaymentMode(false);
                throw error;
            });

        this.props
            .loadMembershipInfo(applicationId)
            .then(applicationInfo => {
                this.setState(
                    {
                        applicationInfo: applicationInfo.data[0],
                    },
                    () => {},
                );
            })
            .catch(error => {
                throw error;
            });
    }
    render() {
        return (
            <Col className="m-4">
                <Row>
                    <Col xs={12}>
                        <h3>
                            <strong>Membership Payment</strong>
                        </h3>
                    </Col>
                </Row>
                <br />
                {!this.state.paymentStart && (
                    <div className="row">
                        <div className="col-5 col-lg-5">
                            <div className="form-group">
                                <label className="field-header">
                                    Payment Mode <MandatoryStar />
                                </label>
                                {this.state.loadingPaymentMode ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Select
                                        autofocus
                                        simpleValue
                                        autosize={false}
                                        name="selected-state"
                                        placeholder="Please select"
                                        options={this.state.paymentModes}
                                        onChange={this.setPaymentMode}
                                        searchable={this.state.searchable}
                                        clearable={this.state.clearable}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                <small className="form-text text-muted">Please choose payment mode</small>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.paymentStart && (
                    <PaymentChoicesPanel
                        applicationInfo={this.state.applicationInfo}
                        back={this.back}
                        cancel={this.cancel}
                        paymentMode={this.state.selectedPaymentMode.value}
                        dialogModalToggle={this.props.toggleDialogModal}
                    />
                )}
                {!this.state.paymentStart && (
                    <Row>
                        <Col className="d-flex" xs={3}>
                            <Button className="primary-btn-style-outline mr-2" onClick={this.cancel}>
                                <strong>Back</strong>
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end" xs={2}>
                            <Button className="primary-btn-style" onClick={this.next}>
                                <strong>Next</strong>
                            </Button>
                        </Col>
                    </Row>
                )}
                <DialogModal
                    modalAction={this.state.modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    boundForceUpdate={this.boundForceUpdate}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventPaymentModes: () => {
            return dispatch(applicationLoadEventPaymentModes());
        },
        loadMembershipInfo: applicationId => {
            return dispatch(loadApplicationInfo(applicationId));
        },
        toggleDialogModal: () => {
            dispatch(dialogModalToggle());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayNowPanel);
