/**
 * Security action creators
 *
 * Author: Fernando
 */
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import { COUNTRY_SEARCH_REQUEST, COUNTRY_SEARCH_SUCCESS, COUNTRY_SEARCH_FAILURE } from '../constants/actionTypesCountries';
import withQuery from 'with-query';

export function getCountriesGeneric() {
    return (dispatch, getState) => {
        dispatch({ type: COUNTRY_SEARCH_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/misc_v1/cn/country', {
            action: 'findall',
        });

        const options = {
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: COUNTRY_SEARCH_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: COUNTRY_SEARCH_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: COUNTRY_SEARCH_FAILURE, error });
                return error;
            });
    };
}
