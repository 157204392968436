import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getEventTableSummaryAnalytic } from 'actions/analytics/eventsAnalyticsAction';
import moment from 'moment';

class EventsSummaryList extends React.Component {
    constructor(props) {
        super(props);
        this.sort = this.sort.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
            sort: 'date',
        };
        this.format = this.format.bind(this);
    }

    componentDidMount() {
        this.props.load().then(res => {
            var data = Object.assign([], res.data);
            data.sort((a, b) => (b[this.state.sort] > a[this.state.sort] ? 1 : -1));
            this.setState({
                data,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                var data = Object.assign([], res.data);
                data.sort((a, b) => (b[this.state.sort] > a[this.state.sort] ? 1 : -1));
                this.setState({
                    data,
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }

    format(surDef) {
        if (surDef >= 0) {
            return surDef;
        } else {
            let abs = Math.abs(surDef);
            return '(' + abs + ')';
        }
    }
    sort(field) {
        var data = Object.assign([], this.state.data);
        data.sort((a, b) => (b[field] > a[field] ? 1 : -1));
        this.setState({
            data,
            sort: field,
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Events Summary`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%' }}>Event</th>
                                        <th style={{ width: '10%' }}>
                                            Dates
                                            <span
                                                style={{ cursor: 'pointer', color: this.state.sort == 'date' ? '#f8ac59' : 'unset' }}
                                                onClick={e => this.sort('date')}
                                            >
                                                <i className="material-icons material-icons-2x">arrow_drop_down</i>
                                            </span>
                                        </th>
                                        <th style={{ width: '10%' }}>
                                            Total Registrants
                                            <span
                                                style={{
                                                    cursor: 'pointer',
                                                    color: this.state.sort == 'allRegistered' ? '#f8ac59' : 'unset',
                                                }}
                                                onClick={e => this.sort('allRegistered')}
                                            >
                                                <i className="material-icons material-icons-2x">arrow_drop_down</i>
                                            </span>
                                        </th>
                                        <th style={{ width: '10%' }}>Company Attendance</th>
                                        <th style={{ width: '10%' }}>Individual Attendance</th>
                                        <th style={{ width: '10%' }}>Individual Attendance %</th>
                                        <th style={{ width: '10%' }}>Income (SGD $)</th>
                                        <th style={{ width: '10%' }}>Expenditure (SGD $)</th>
                                        <th style={{ width: '10%' }}>Surplus/(Deficit) (SGD $)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.name}</td>
                                            <td style={{ minWidth: '125px' }}>{moment(item.date, 'YYYY-MM-DD').format('DD MMM YYYY')}</td>
                                            <td>{item.allRegistered}</td>
                                            <td>{item.showedUp}</td>
                                            <td>{item.showupPercentage}</td>
                                            <td>{item.sgbcMembers}</td>
                                            <td>{item.totalIncome}</td>
                                            <td>{item.totalExpenditure}</td>
                                            <td>{this.format(item.surDef)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getEventTableSummaryAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsSummaryList));
