import React from 'react';
import { Link } from 'react-router-dom';

import { Row, Col, FormGroup, Card, CardBody, Button } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';

class ProductsListTable extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <BrandTableContent
                            brandInfoDisplaySize={this.props.brandInfoDisplaySize}
                            brandTableContentSize={this.props.brandTableContentSize}
                            productBrands={this.props.productBrands}
                            editable={this.props.editable}
                            addModelFormVisible={this.props.addModelFormVisible}
                            toggleAddModelForm={this.props.toggleAddModelForm}
                            addModel={this.props.addModel}
                            currentBrandToAddModel={this.props.currentBrandToAddModel}
                            removeBrand={this.props.removeBrand}
                            removeModel={this.props.removeModel}
                            onGenericTextFieldChange={this.props.onGenericTextFieldChange}
                            modelNameField={this.props.modelNameField}
                            modelDescriptionField={this.props.modelDescriptionField}
                            printCertificate={this.props.printCertificate}
                            status={this.props.status}
                            setProductCatalogue={this.props.setProductCatalogue}
                            setProductPicture={this.props.setProductPicture}
                            allowChangeCatalogue={this.props.allowChangeCatalogue}
                            setWhyGreen={this.props.setWhyGreen}
                        />
                        {this.props.editable && this.props.addBrandFormVisible && (
                            <AddBrandFormContent
                                brandInfoDisplaySize={this.props.brandInfoDisplaySize}
                                onGenericTextFieldChange={this.props.onGenericTextFieldChange}
                                brandNameField={this.props.brandNameField}
                                addBrand={this.props.addBrand}
                            />
                        )}
                    </Col>
                </Row>
                {this.props.editable && !this.props.addBrandFormVisible && this.props.productBrands && this.props.productBrands.length < 1 && (
                    <Row>
                        <Col>
                            <Button className="primary-btn-style" onClick={this.props.toggleAddBrandForm}>
                                Add Brand
                            </Button>
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const DeleteIcon = ({ onClick }) => (
    <Link onClick={onClick} to="#">
        <i className="red-icons material-icons material-icons-1x">delete</i>
    </Link>
);

const BrandTableContent = ({
    productBrands,
    editable,
    addModelFormVisible,
    toggleAddModelForm,
    addModel,
    currentBrandToAddModel,
    removeBrand,
    removeModel,
    onGenericTextFieldChange,
    modelNameField,
    modelDescriptionField,
    printCertificate,
    status,
    setProductCatalogue,
    setProductPicture,
    allowChangeCatalogue,
    setWhyGreen,
    brandInfoDisplaySize,
    brandTableContentSize,
}) => {
    if (!productBrands) return null;
    return productBrands.map((item, i) => (
        <Row key={i}>
            <BrandInfoDisplay brandInfoDisplaySize={brandInfoDisplaySize} removeBrand={removeBrand} editable={editable} i={i} item={item} />
            <Col className="ticker" xs={brandTableContentSize || 9}>
                {item &&
                    item.models &&
                    item.models.map((model, j) => (
                        <ModelInfoDisplay
                            key={j}
                            j={j}
                            i={i}
                            editable={editable}
                            removeModel={removeModel}
                            model={model}
                            printCertificate={printCertificate}
                            status={status}
                            setProductCatalogue={setProductCatalogue}
                            setProductPicture={setProductPicture}
                            allowChangeCatalogue={allowChangeCatalogue}
                            setWhyGreen={setWhyGreen}
                        />
                    ))}
                {editable && addModelFormVisible && currentBrandToAddModel === i && (
                    <React.Fragment>
                        <AddModelFields
                            onGenericTextFieldChange={onGenericTextFieldChange}
                            modelNameField={modelNameField}
                            modelDescriptionField={modelDescriptionField}
                        />
                        <AddModelButtons
                            disabled={modelNameField.length === 0}
                            i={i}
                            addModel={addModel}
                            toggleAddModelForm={toggleAddModelForm}
                        />
                    </React.Fragment>
                )}
                {editable && !addModelFormVisible && <AddModelFormContent brandIndex={i} toggleAddModelForm={toggleAddModelForm} />}
            </Col>
        </Row>
    ));
};

const BrandInfoDisplay = ({ brandInfoDisplaySize, removeBrand, editable, i, item }) => (
    <Col xs={brandInfoDisplaySize || 3}>
        <Card className="p-2">
            <CardBody>
                <div className="d-flex">
                    <b>Brand</b>
                    <div className="ml-auto">{editable && <DeleteIcon onClick={() => removeBrand(i)} />}</div>
                </div>
                {item && <div>{item.name}</div>}
            </CardBody>
        </Card>
    </Col>
);
const ModelInfoDisplay = ({
    editable,
    removeModel,
    model,
    i,
    j,
    printCertificate,
    status,
    setProductCatalogue,
    setProductPicture,
    allowChangeCatalogue,
    setWhyGreen,
}) => {
    return (
        <React.Fragment>
            <Card className="mb-4">
                <CardBody>
                    <Row className="p-2">
                        {model.certificationNumber && status && status === 'Issued' && (
                            <Col className="ticker">
                                <b>Certificate Number</b>
                                <br />
                                {model.certificationNumber}
                                <br />
                                <Button
                                    onClick={e => printCertificate(model.certificationNumber, 'product')}
                                    className="text-bold text-capitalize primary-btn-style"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                    }}
                                >
                                    <i
                                        className="mr-1 material-icons align-text-bottom"
                                        style={{
                                            marginLeft: '0px 5px',
                                        }}
                                    >
                                        cloud_download
                                    </i>
                                    Download Certificate
                                </Button>
                            </Col>
                        )}
                        <Col className="ticker">
                            <div className="d-flex">
                                <b>Model</b>
                                <div className="ml-auto">{editable && <DeleteIcon onClick={() => removeModel(i, j)} />}</div>
                            </div>
                            {model.name}
                        </Col>
                        {allowChangeCatalogue && (
                            <React.Fragment>
                                <Col className="ticker">
                                    <Col>
                                        <div className="d-flex">
                                            <b>Certification No.</b>
                                        </div>
                                        {model.certificationNumber}
                                    </Col>
                                    <br />
                                    <Col>
                                        <div className="d-flex">
                                            <b>Rating</b>
                                        </div>
                                        {model.ticks}
                                    </Col>
                                </Col>
                                <Col className="ticker" style={{ width: '20%' }}>
                                    <b>Product Catalogue</b>
                                    <br />
                                    <CyderFileUpload
                                        attachmentId={model.productCatalogue}
                                        filename={model.productCatalogueFilename}
                                        onUploadSuccess={file => setProductCatalogue(file.id, file.filename, model)}
                                        onUploadFail={e => {
                                            throw ('Failed: ', e);
                                        }}
                                    />
                                </Col>
                                <Col className="ticker" style={{ width: '20%' }}>
                                    <b>Product Picture</b>
                                    <br />
                                    <CyderFileUpload
                                        attachmentId={model.productPicture}
                                        filename={model.productPictureFilename}
                                        onUploadSuccess={file => setProductPicture(file.id, file.filename, model)}
                                        onUploadFail={e => {}}
                                    />
                                </Col>
                            </React.Fragment>
                        )}
                    </Row>
                </CardBody>
            </Card>
            {allowChangeCatalogue && (
                <Row className="mb-4 pb-4">
                    <Col>
                        <b>
                            Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy saving
                            properties, recyclability, usage of recycled content, etc.).
                        </b>
                        <textarea
                            rows="6"
                            className="form-control"
                            onChange={e => setWhyGreen(e.target.value, model)}
                            value={model.greenRemarks}
                        />
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

const AddModelFields = ({ onGenericTextFieldChange, modelNameField, modelDescriptionField }) => (
    <Row key={'newmodel'}>
        <Col className="ticker">
            <FormGroup>
                <input
                    type="text"
                    className="form-control"
                    value={modelNameField}
                    onChange={e => onGenericTextFieldChange('modelNameField', e)}
                />
                <small className="form-text text-muted">Please enter model</small>
            </FormGroup>
        </Col>
        {/* <Col className="ticker">
            <FormGroup>
                <input
                    onChange={e => onGenericTextFieldChange('modelDescriptionField', e)}
                    value={modelDescriptionField}
                    type="text"
                    className="form-control"
                    placeholder=""
                />
                <small className="form-text text-muted">Please enter description</small>
            </FormGroup>
        </Col> */}
    </Row>
);

const AddModelButtons = ({ disabled, i, addModel, toggleAddModelForm }) => (
    <Row>
        <Col>
            <ButtonIcon disabled={disabled} onClick={() => addModel(i)} color={'success'} icon={'add'} label={'Save'} />
            <ButtonIcon className="ml-2" onClick={() => toggleAddModelForm()} color={'danger'} icon={'cancel'} label={'Cancel'} />
        </Col>
    </Row>
);
const AddModelFormContent = ({ toggleAddModelForm, brandIndex }) => (
    <FormGroup>
        <ButtonIcon color={'btn btn-primary'} onClick={() => toggleAddModelForm(brandIndex)} icon={'add'} flat label={'Add Model'} />
    </FormGroup>
);

const AddBrandFormContent = ({ brandInfoDisplaySize, onGenericTextFieldChange, brandNameField, addBrand }) => (
    <Row key={'newbrand'}>
        <Col className="ticker" xs={brandInfoDisplaySize || 3}>
            <FormGroup>
                <input
                    onChange={e => onGenericTextFieldChange('brandNameField', e)}
                    value={brandNameField}
                    id="brand-name"
                    type="text"
                    className="form-control"
                    placeholder=""
                />
                <small className="form-text text-muted">Please enter brand</small>
            </FormGroup>
            <ButtonIcon disabled={brandNameField.length === 0} onClick={addBrand} color={'success'} icon={'add'} label={'Save'} />
        </Col>
    </Row>
);
export default ProductsListTable;
