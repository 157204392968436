import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../../../history';

import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import ProductsListTable from 'pages/member/certification/applications/product/components/ProductsListTable';
import {
    update,
    remove,
    load,
    getAllProductCategories,
    getAllProductSubCategories,
    getAllProductTypes,
    findProductTypeInfo,
    getXeroInventoryCodesByPrefix,
} from 'actions/staff/certification/edit-on-behalf/action.js';
import { getCountriesGeneric } from 'actions/misc/countryAction.js';
import { getAuditList } from 'actions/misc/auditAction';
import { removeArrayItem, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

export const selectStyle = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
        width: '400px',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};
export const selectStyleForText = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
        width: '200px',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};
class AddPage extends React.Component {
    constructor(props) {
        super(props);

        this.back = this.back.bind(this);
        this.productPictureChange = this.productPictureChange.bind(this);
        this.handleInputCountryChange = this.handleInputCountryChange.bind(this);
        this.handleInputCategoryChange = this.handleInputCategoryChange.bind(this);
        this.handleInputSubCategoryChange = this.handleInputSubCategoryChange.bind(this);
        this.handleInputProductTypeChange = this.handleInputProductTypeChange.bind(this);
        this.productCatalogueChange = this.productCatalogueChange.bind(this);
        this.greenRemarksChange = this.greenRemarksChange.bind(this);
        this.manufacturerCountryIdChange = this.manufacturerCountryIdChange.bind(this);
        this.manufacturerFactoryAddressChange = this.manufacturerFactoryAddressChange.bind(this);
        this.manufacturerNameOfCompanyChange = this.manufacturerNameOfCompanyChange.bind(this);

        this.manufacturerFactoryAddressPlantChange = this.manufacturerFactoryAddressPlantChange.bind(this);
        this.manufacturerNameOfCompanyPlantChange = this.manufacturerNameOfCompanyPlantChange.bind(this);

        this.manufacturerContactPersonNameChange = this.manufacturerContactPersonNameChange.bind(this);
        this.manufacturerTelephoneChange = this.manufacturerTelephoneChange.bind(this);
        this.isManufacturerChange = this.isManufacturerChange.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.save = this.save.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        // Product list
        this.onGenericTextFieldChange = this.onGenericTextFieldChange.bind(this);
        this.addModel = this.addModel.bind(this);
        this.removeModel = this.removeModel.bind(this);
        this.addBrand = this.addBrand.bind(this);
        this.removeBrand = this.removeBrand.bind(this);
        this.toggleAddBrandForm = this.toggleAddBrandForm.bind(this);
        this.toggleAddModelForm = this.toggleAddModelForm.bind(this);
        // ---
        this.state = {
            loading: false,
            countryList: [],
            categories: [],
            subcategories: [],
            producttypes: [],
            itemCode: [],
            data: {
                productPictureFilename: '',
                productPicture: '',
                productCatalogueFilename: '',
                productCatalogue: '',
                brandsJSON: [],
                brands: '',
                greenRemarks: '',
                manufacturerCountryId: '',
                manufacturerFactoryAddress: '',
                manufacturerNameOfCompanyPlant: '',
                manufacturerFactoryAddressPlant: '',
                manufacturerNameOfCompany: '',
                manufacturerContactPersonName: '',
                manufacturerTelephone: '',
                isManufacturer: '',
                productCategoryId: '',
                productSubCategoryId: '',
                productTypeId: '',
                id: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            // product list table
            brandNameField: '',
            modelNameField: '',
            modelDescriptionField: '',
            addModelFormVisible: false,
            addBrandFormVisible: false,
            currentBrandToAddModel: 0,
        };
    }
    // product list table
    onGenericTextFieldChange(id, e) {
        const newState = {};
        newState[id] = e.target.value;
        this.setState(newState);
    }
    toggleAddModelForm(i) {
        this.setState({
            currentBrandToAddModel: i,
            addModelFormVisible: !this.state.addModelFormVisible,
        });
    }
    removeModel(brandIndex, removeIndex) {
        var data = this.state.data;
        let brandsJSON = data.brandsJSON;
        let brand = brandsJSON[brandIndex];
        const remaining = removeArrayItem(brand.models, removeIndex);
        brand.models = remaining;
        brandsJSON[brandIndex] = brand;
        data.brandsJSON = brandsJSON;
        this.setState({ data });
    }
    addModel(brandIndex) {
        var data = this.state.data;

        // model structure
        const model = {
            name: this.state.modelNameField,
            description: this.state.modelDescriptionField,
        };
        let brandsJSON = data.brandsJSON;
        const existingBrandInfo = brandsJSON[brandIndex];
        let modelInfo = existingBrandInfo.models;
        modelInfo.push(model);

        const newBrandInfo = Object.assign(existingBrandInfo, {
            models: modelInfo,
        });
        brandsJSON[brandIndex] = newBrandInfo;
        data.brandsJSON = brandsJSON;
        const newState = {
            data,
            modelNameField: '',
            modelDescriptionField: '',
        };
        this.setState(newState);
    }
    toggleAddBrandForm() {
        this.setState({ addBrandFormVisible: !this.state.addBrandFormVisible });
    }
    removeBrand(removeIndex) {
        var data = this.state.data;
        const pbs = data.brandsJSON;
        const remaining = removeArrayItem(pbs, removeIndex);
        data.brandsJSON = remaining;
        this.setState({ data });
    }
    addBrand() {
        var data = this.state.data;
        var pbs = data.brandsJSON;
        const brand = {
            name: this.state.brandNameField,
            models: [],
        };
        pbs.push(brand);
        data.brandsJSON = pbs;
        this.setState({ data, brandNameField: '' });
        this.toggleAddBrandForm();
        // this.props.setSavedFlag(false);
    }
    // ---
    back() {
        history.replace(`/staff/certification/applications/viewproduct/${this.props.match.params.id}`);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({ loading: show });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({ modal });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({ modal });
    }
    async componentDidMount() {
        const {
            getCountriesList,
            getAllProductCategories,
            getXeroInventoryCodesByPrefix,
            findProductTypeInfo,
            getAuditList,
            load,
        } = this.props;
        try {
            this.showLoading(true);
            const id = this.props.match.params.id;
            if (id && id > 0) {
                const countries = await getCountriesList();
                const allCategories = await getAllProductCategories();
                const itemCode = await getXeroInventoryCodesByPrefix();
                var { data } = await load(id);

                var productTypeInfo = await findProductTypeInfo(data.productTypeId);
                if (productTypeInfo && productTypeInfo.data) {
                    data.productCategoryId = productTypeInfo.data.categoryId;
                    data.productCategoryName = productTypeInfo.data.category;
                    data.productSubCategoryId = productTypeInfo.data.subcategoryId;
                    data.productSubCategoryName = productTypeInfo.data.subcategory;
                    data.productTypeId = productTypeInfo.data.id;
                    data.productTypeName = productTypeInfo.data.type;
                }
                data.brandsJSON = JSON.parse(data.brands);
                data = sanitizeStringAndObjectNullsToEmptyStrings(data);
                var allSubCategories = await this.props.getAllProductSubCategories(data.productCategoryId);
                var allProductTypes = await this.props.getAllProductTypes(data.productCategoryId, data.productSubCategoryId);
                let auditInfo = await getAuditList(id);

                this.setState({
                    data,
                    countryList: countries.data,
                    categories: allCategories.data,
                    subcategories: allSubCategories.data,
                    producttypes: allProductTypes.data,
                    itemCode,
                    auditInfo,
                });
            } else {
                this.setState({
                    data: this.state.data,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.type = 'product';
        newData.brands = JSON.stringify(data.brandsJSON);
        return newData;
    }
    save() {
        const id = this.props.match.params.id;
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        this.props
            .update(data)
            .then(res => {
                this.showLoading(false);
                if (res.error) {
                    this.showModal('Error', res.message);
                } else {
                    history.replace(`/staff/certification/applications/viewproduct/${id}`, 'update', true, true);
                }
            })
            .catch(error => {
                this.showLoading(false);
                this.showModal('Error', error.message, 'update', true, true);
            });
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this application?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/staff/certification/applications');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }

    productPictureChange(resourceId, filename) {
        var data = this.state.data;
        data.productPicture = resourceId;
        data.productPictureFilename = filename;
        this.setState({ data });
    }

    productCatalogueChange(resourceId, filename) {
        var data = this.state.data;
        data.productCatalogue = resourceId;
        data.productCatalogueFilename = filename;
        this.setState({ data });
    }

    handleInputCountryChange(option) {
        var data = this.state.data;
        data.manufacturerCountryId = option.id;
        data.manufacturerCountryOfOrigin = option.name;
        this.setState({ data });
    }
    async handleInputCategoryChange(option) {
        var data = this.state.data;
        data.productCategoryId = option.id;
        data.productCategoryName = option.category;
        var allSubCategories = await this.props.getAllProductSubCategories(data.productCategoryId);
        data.productSubCategoryId = '';
        data.productSubCategoryName = '';
        data.productTypeId = '';
        data.productTypeName = '';
        this.setState({
            data,
            subcategories: allSubCategories.data,
            producttypes: [],
        });
    }
    async handleInputSubCategoryChange(option) {
        var data = this.state.data;
        data.productSubCategoryId = option.id;
        data.productSubCategoryName = option.subcategory;
        var allProductTypes = await this.props.getAllProductTypes(this.state.data.productCategoryId, data.productSubCategoryId);
        data.productTypeId = '';
        data.productTypeName = '';
        this.setState({ data, producttypes: allProductTypes.data });
    }
    handleInputProductTypeChange(option) {
        var data = this.state.data;
        data.productTypeId = option.id;
        data.productTypeName = option.type;
        this.setState({ data });
    }
    greenRemarksChange(e) {
        var data = this.state.data;
        data.greenRemarks = e.target.value;
        this.setState({ data });
    }
    manufacturerCountryIdChange(e) {
        var data = this.state.data;
        data.manufacturerCountryId = e.target.value;
        this.setState({ data });
    }
    manufacturerFactoryAddressChange(e) {
        var data = this.state.data;
        data.manufacturerFactoryAddress = e.target.value;
        this.setState({ data });
    }
    manufacturerNameOfCompanyPlantChange(e) {
        var data = this.state.data;
        data.manufacturerNameOfCompanyPlant = e.target.value;
        this.setState({ data });
    }
    manufacturerFactoryAddressPlantChange(e) {
        var data = this.state.data;
        data.manufacturerFactoryAddressPlant = e.target.value;
        this.setState({ data });
    }
    manufacturerNameOfCompanyChange(e) {
        var data = this.state.data;
        data.manufacturerNameOfCompany = e.target.value;
        this.setState({ data });
    }
    manufacturerContactPersonNameChange(e) {
        var data = this.state.data;
        data.manufacturerContactPersonName = e.target.value;
        this.setState({ data });
    }
    manufacturerTelephoneChange(e) {
        var data = this.state.data;
        data.manufacturerTelephone = e.target.value;
        this.setState({ data });
    }
    isManufacturerChange(e) {
        var data = this.state.data;
        data.isManufacturer = e.target.checked ? 1 : 0;
        this.setState({ data });
    }
    render() {
        console.log('this.state.data: ', this.state.data);
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Row className="mb-2">
                    <Col xs={5}>
                        <Form>
                            <Button className="ml-auto primary-btn-style-outline" onClick={this.back}>
                                <i className="mr-1 material-icons align-text-bottom">keyboard_arrow_left</i>
                                Back
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={3}>
                        <label className={`text-bold`}> ID : </label> {this.state.data.id}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <InputField label="Product Category">
                            <Select
                                autofocus
                                name="categories"
                                styles={selectStyle}
                                options={this.state.categories}
                                onChange={this.handleInputCategoryChange}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.category}
                                value={{
                                    id: this.state.data.productCategoryId,
                                    category: this.state.data.productCategoryName,
                                }}
                            />
                        </InputField>
                    </Col>
                    <Col>
                        <InputField label="Product Subcategory">
                            <Select
                                autofocus
                                name="categories"
                                styles={selectStyle}
                                options={this.state.subcategories}
                                onChange={this.handleInputSubCategoryChange}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.subcategory}
                                isDisabled={!this.state.data.productCategoryId}
                                value={{
                                    id: this.state.data.productSubCategoryId,
                                    subcategory: this.state.data.productSubCategoryName,
                                }}
                            />
                        </InputField>
                    </Col>
                    <Col>
                        <InputField label="Product Type">
                            <Select
                                autofocus
                                name="categories"
                                styles={selectStyle}
                                options={this.state.producttypes}
                                onChange={this.handleInputProductTypeChange}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.type}
                                isDisabled={!this.state.data.productSubCategoryId}
                                value={{
                                    id: this.state.data.productTypeId,
                                    type: this.state.data.productTypeName,
                                }}
                            />
                        </InputField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <InputField
                            label="Main Contact Person"
                            styles={{ selectStyleForText }}
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerContactPersonName',
                                name: 'manufacturerContactPersonName',
                                placeholder: 'manufacturerContactPersonName',
                                onChange: this.manufacturerContactPersonNameChange,
                                value: this.state.data.manufacturerContactPersonName,
                            }}
                        />
                    </Col>
                    <Col xs={3} style={{ padding: '0px', marginLeft: '10px' }}>
                        <InputField
                            label="Main Contact Phone"
                            styles={selectStyleForText}
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerTelephone',
                                name: 'manufacturerTelephone',
                                placeholder: 'manufacturerTelephone',
                                onChange: this.manufacturerTelephoneChange,
                                value: this.state.data.manufacturerTelephone,
                            }}
                        />
                    </Col>
                    {this.state.auditInfo && this.state.auditInfo.data && this.state.auditInfo.data.length > 0 && (
                        <Col xs={4} style={{ padding: '0px', marginLeft: '10px' }}>
                            <InputField
                                label="Main Contact Email"
                                disabled
                                inputProps={{
                                    type: 'text',
                                    value: this.state.auditInfo.data[this.state.auditInfo.data.length - 1].email,
                                }}
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <InputField
                            label="Are you a Distributor/Reseller/Agent?"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isManufacturer',
                                style: { marginLeft: '0px' },
                                onChange: this.isManufacturerChange,
                                checked: this.state.data.isManufacturer === 1,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <InputField
                            label="Name Of Manufacturer"
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerNameOfCompany',
                                name: 'manufacturerNameOfCompany',
                                placeholder: 'manufacturerNameOfCompany',
                                onChange: this.manufacturerNameOfCompanyChange,
                                value: this.state.data.manufacturerNameOfCompany,
                            }}
                        />
                        <InputField
                            label="Address of Manufacturer"
                            inputProps={{
                                rows: '5',
                                type: 'textarea',
                                id: 'manufacturerFactoryAddress',
                                name: 'manufacturerFactoryAddress',
                                placeholder: 'manufacturerFactoryAddress',
                                value: this.state.data.manufacturerFactoryAddress,
                                onChange: this.manufacturerFactoryAddressChange,
                            }}
                        />
                        <InputField label="Country Of Origin">
                            <Select
                                autofocus
                                name="countries"
                                styles={selectStyle}
                                options={this.state.countryList}
                                onChange={this.handleInputCountryChange}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.name}
                                value={{
                                    id: this.state.data.manufacturerCountryId,
                                    name: this.state.data.manufacturerCountryOfOrigin,
                                }}
                            />
                        </InputField>
                    </Col>

                    <Col xs={6}>
                        <InputField
                            label="Name Of Manufacturing Plant"
                            inputProps={{
                                type: 'text',
                                id: 'manufacturerNameOfCompanyPlant',
                                name: 'manufacturerNameOfCompanyPlant',
                                placeholder: 'manufacturerNameOfCompanyPlant',
                                onChange: this.manufacturerNameOfCompanyPlantChange,
                                value: this.state.data.manufacturerNameOfCompanyPlant,
                            }}
                        />
                        <InputField
                            label="Address of Manufacturing Plant"
                            inputProps={{
                                rows: '5',
                                type: 'textarea',
                                id: 'manufacturerFactoryAddressPlant',
                                name: 'manufacturerFactoryAddressPlant',
                                placeholder: 'manufacturerFactoryAddressPlant',
                                value: this.state.data.manufacturerFactoryAddressPlant,
                                onChange: this.manufacturerFactoryAddressPlantChange,
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={5}>
                        <InputField
                            label="Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy saving properties, recyclability, usage of recycled content, etc.)."
                            inputProps={{
                                rows: 5,
                                type: 'textarea',
                                id: 'greenRemarks',
                                name: 'greenRemarks',
                                placeholder: 'greenRemarks',
                                value: this.state.data.greenRemarks,
                                onChange: this.greenRemarksChange,
                            }}
                        />
                    </Col>
                </Row>
                <InputField label="Brand and Models">
                    <ProductsListTable
                        editable={true}
                        onGenericTextFieldChange={this.onGenericTextFieldChange}
                        brandNameField={this.state.brandNameField}
                        modelNameField={this.state.modelNameField}
                        modelDescriptionField={this.state.modelDescriptionField}
                        toggleAddModelForm={this.toggleAddModelForm}
                        addModelFormVisible={this.state.addModelFormVisible}
                        currentBrandToAddModel={this.state.currentBrandToAddModel}
                        addModel={this.addModel}
                        removeModel={this.removeModel}
                        productBrands={this.state.data.brandsJSON}
                        toggleAddBrandForm={this.toggleAddBrandForm}
                        addBrandFormVisible={this.state.addBrandFormVisible}
                        addBrand={this.addBrand}
                        removeBrand={this.removeBrand}
                    />
                    {/* <CreateCertificateDetailsPanel
                                    type={`product`}
                                    itemCode={this.state.itemCode}
                                    productBrands={this.state.data.brands}
                                    unassignedBrandFromCertificate={this.unassignedBrandFromCertificate}
                                    updateProductBrandsWithCertificate={this.updateProductBrandsWithCertificate}
                                    // status={status}
                                    readonly={false}
                                /> */}
                </InputField>
                <InputField label="Product Catalogue">
                    <CyderFileUpload
                        attachmentId={this.state.data.productCatalogue}
                        filename={this.state.data.productCatalogueFilename}
                        onUploadSuccess={file => {
                            this.productCatalogueChange(file.id, file.filename);
                        }}
                        onUploadFail={e => {
                            throw e;
                        }}
                    />
                </InputField>
                <InputField label="Product Picture">
                    <CyderFileUpload
                        attachmentId={this.state.data.productPicture}
                        filename={this.state.data.productPictureFilename}
                        onUploadSuccess={file => {
                            this.productPictureChange(file.id, file.filename);
                        }}
                        onUploadFail={e => {
                            throw e;
                        }}
                    />
                </InputField>
                <hr />
                <FlexRow between="xs" style={{ marginBottom: '20px' }}>
                    {this.state.data.id > 0 && this.state.data.certificateApplicationStatusId === 2 && (
                        <ButtonComponent label="Delete" className="primary-btn-style-outline" onClick={this.removeConfirm} />
                    )}
                    <ButtonComponent
                        label="Save"
                        className="primary-btn-style"
                        colClassName="d-flex justify-content-end"
                        onClick={this.save}
                    />
                </FlexRow>
            </div>
        );
    }
}
export const ButtonComponent = ({ color, className, colClassName, onClick, label, xs }) => (
    <FlexCol xs={xs} className={colClassName}>
        <Button className={'text-bold ' + className} color={color} onClick={onClick}>
            {label}
        </Button>
    </FlexCol>
);
export const InputField = ({
    inputProps = {},
    required,
    labelClassName = '',
    className,
    readOnly,
    disabled,
    children,
    label,
    customLabelComponent,
    colstyle,
    error,
}) => {
    const { type, id, value, checked } = inputProps;
    // FOR CHECKBOX TYPE ONLY
    const isCheckboxType = type === 'checkbox';
    const checkedVal = checked ? 'Yes' : 'No';
    const check = isCheckboxType && {
        inline: readOnly ? false : true,
        check: '',
        style: { display: '-webkit-inline-box' },
    };
    // COMPONENT
    const PreviewComponent = ({ label }) => <div>{label}</div>;
    const LabelComponent = ({ required }) => {
        const shouldNotBold = labelClassName.includes('text-default');
        return (
            customLabelComponent || (
                <Label for={id} className={`${shouldNotBold ? '' : 'text-bold'} ${labelClassName} ${required ? 'required' : null}`}>
                    {label} {required && <span> *</span>}
                </Label>
            )
        );
    };
    return (
        <Row className={className || 'mt-2 mb-2'}>
            <Col xs={colstyle || 12}>
                <FormGroup>
                    <Form onSubmit={e => e.preventDefault()} {...check}>
                        {!isCheckboxType && <LabelComponent required={required} />}
                        {!readOnly && (children || <Input {...inputProps} />)}
                        {isCheckboxType && <LabelComponent />}
                        {readOnly && <PreviewComponent label={isCheckboxType ? checkedVal : value} />}
                        {error && error !== '' && <span className="help-text">{error}</span>}
                    </Form>
                </FormGroup>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return { ownProps };
};
const mapDispatchToProps = dispatch => {
    return {
        update: data => {
            return dispatch(update(data));
        },
        getCountriesList: () => {
            return dispatch(getCountriesGeneric());
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getAllProductCategories: () => {
            return dispatch(getAllProductCategories());
        },
        getAllProductSubCategories: categoryId => {
            return dispatch(getAllProductSubCategories(categoryId));
        },
        findProductTypeInfo: productTypeId => {
            return dispatch(findProductTypeInfo(productTypeId));
        },
        getAllProductTypes: (categoryId, subCategoryId) => {
            return dispatch(getAllProductTypes(categoryId, subCategoryId));
        },
        getXeroInventoryCodesByPrefix: () => {
            return dispatch(getXeroInventoryCodesByPrefix('CP'));
        },
        getAuditList: id => {
            return dispatch(getAuditList(49, id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
