import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../history';

import SSGBCMembers from 'pages/analytics/components/membership/SSGBCMembers';
import NewMembershipApplication from 'pages/analytics/components/membership/NewMembershipApplication';
import NewMembersAnalytic from 'pages/analytics/components/membership/NewMembersAnalytic';
import SME from 'pages/analytics/components/membership/SME';
import LocalGrown from 'pages/analytics/components/membership/LocalGrown';
import OrgPrimaryBusinessActivity from 'pages/analytics/components/membership/OrgPrimaryBusinessActivity';
import AgeDistribution from 'pages/analytics/components/membership/AgeDistribution';
import AgeDistributionList from 'pages/analytics/components/membership/AgeDistributionList';
import MembersEventList from 'pages/analytics/components/membership/MembersEventList';
import CrossAnalytics from 'pages/analytics/components/certification/CrossAnalytics';
import { Row, Col, Container } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import StaffCompanySearch from 'elements/navbar-staff/Search';

var buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const AnalyticsButton = ({ btnProps, colStyle, onClick, label, check }) => {
    const buttonColor = check ? 'grey' : null;
    const fontColor = check ? 'white' : null;
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

class MembershipDashboard extends React.Component {
    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={4}>
                        <StaffCompanySearch />
                    </Col>
                    <Col xs={8}>
                        <Row className="float-right">
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                label="Issued Analytics"
                                onClick={e => history.push('/staff/analytics/membership/issuedanalytics')}
                            />
                            <AnalyticsButton
                                colStyle={buttonStyle}
                                label="Renewal Analytics"
                                onClick={e => history.push('/staff/analytics/membership/renewalanalytics')}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={3}>
                        <SSGBCMembers />
                    </Col>
                    <Col xs={3}>
                        <NewMembershipApplication />
                    </Col>
                    <Col xs={3}>
                        <NewMembersAnalytic />
                    </Col>
                    <Col xs={3}>
                        <CrossAnalytics type="member" title="Membership Cross-Analysis against Certification" />
                    </Col>
                </Row>

                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12}>
                        <OrgPrimaryBusinessActivity />
                    </Col>
                </Row>

                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={6}>
                        <LocalGrown />
                    </Col>
                    <Col xs={6}>
                        <SME />
                    </Col>
                </Row>

                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={7}>
                        <AgeDistribution />
                    </Col>
                    <Col xs={5}>
                        <AgeDistributionList />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={6}>
                        <MembersEventList />
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipDashboard));
