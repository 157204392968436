import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { getGreenCredentialCertificateProductAnalytic } from 'actions/analytics/certificateAnalyticsAction';

class GreenAttributes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Green Credentials`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>No. of Active Certificates</th>
                                        <th>No. of Active Products</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.buildingTypeOption}</td>
                                            <td>{item.certificatesCount}</td>
                                            <td>{item.productsCount}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getGreenCredentialCertificateProductAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(GreenAttributes));
