import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import MainComponent from './EventsCreatePageMainComponent';
import DatesComponent from './EventsCreatePageDatesComponent';
import TicketsComponent from './EventsCreatePageTicketsComponent';
// import MaterialsComponent from './EventsCreatePageMaterialsComponent';
// import TracksComponent from './EventsCreatePageTracksComponent';
import ChecklistListPage from '../event-checklist/ListPage';
import ChecklistAddPage from '../event-checklist/AddPage';
import ExpendituresListPage from '../event-expenditure/ListPage';
import ExpendituresAddPage from '../event-expenditure/AddPage';

import {
    eventResetEventFields,
    eventGetEventDetails,
    eventSaveEventDetails,
    eventRemoveEventDetails,
    eventUpdateEventDetails,
    eventChangeEventInput,
    eventHandleImageInput,
    eventGetfoodRestriction,
    eventGetPaymentMode,
    eventSetEventId,
    eventSetModalData,
    eventSaveDocumentFile,
    eventRemoveDocumentFile,
    eventDownloadDocumentFile,
} from 'actions/staff/dinner/events/eventsCreationAction';
import {
    eventDatesGetEventDate,
    eventDatesSaveEventDate,
    eventDatesUpdateEventDate,
    eventDatesChangeDateInput,
    eventDatesResetFields,
    eventDatesSetModalData,
    eventDatesSetEventId,
} from 'actions/staff/dinner/events/eventsDateAction';
import {
    eventTrackSetEventId,
    eventGetEventTracks,
    eventSaveAllEventTracks,
    eventUpdateAllEventTracks,
    eventSaveNewEventTrack,
    eventSaveSingleEventTrack,
    eventRemoveSingleEventTrack,
    eventChangeTrackInput,
    eventResetTrackFields,
    eventTracksSetModalData,
} from 'actions/staff/dinner/events/eventsTrackAction';
// import { uploadChecklist } from 'actions/staff/dinner/event-checklist/action';
import { eventsAttendeeGetAttendeeList } from 'actions/staff/dinner/events/eventsAttendeeAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import { blobDownload, parseContentType } from '../../../../js/util';

class EventsAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.removeMockExpenditure = this.removeMockExpenditure.bind(this);
        this.addMockExpenditure = this.addMockExpenditure.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        //Event - Main Details
        this.saveEventDetails = this.saveEventDetails.bind(this);
        this.deleteEventDetails = this.deleteEventDetails.bind(this);
        this.updateEventDetails = this.updateEventDetails.bind(this);
        this.handleEventFileInput = this.handleEventFileInput.bind(this);
        this.handleEventImageInput = this.handleEventImageInput.bind(this);
        this.downloadDocumentFile = this.downloadDocumentFile.bind(this);
        // New File Upload
        this.onEDMUploadSuccess = this.onEDMUploadSuccess.bind(this);
        this.onEDMUploadFail = this.onEDMUploadFail.bind(this);
        this.onEventBannerUploadSuccess = this.onEventBannerUploadSuccess.bind(this);
        this.onEventBannerUploadFail = this.onEventBannerUploadFail.bind(this);
        this.onRegistrationPageImageUploadSuccess = this.onRegistrationPageImageUploadSuccess.bind(this);
        this.onRegistrationPageImageUploadFail = this.onRegistrationPageImageUploadFail.bind(this);
        // Event - Dates
        this.saveEventDates = this.saveEventDates.bind(this);
        this.updateEventDates = this.updateEventDates.bind(this);
        //Event - Track
        this.saveEventTracks = this.saveEventTracks.bind(this);
        this.updateEventTracks = this.updateEventTracks.bind(this);
        this.saveSingleEventTrack = this.saveSingleEventTrack.bind(this);
        this.deleteSingleEventTrack = this.deleteSingleEventTrack.bind(this);
        this.renderNavItem = this.renderNavItem.bind(this);
        // Event - Checklist
        this.changeChecklistMode = this.changeChecklistMode.bind(this);
        // Event - Expenditure
        this.changeExpenditureMode = this.changeExpenditureMode.bind(this);
        this.state = {
            activeTab: 'main',
            checklist: {
                mode: 'list',
                id: 0,
            },
            expenditure: {
                mode: 'list',
                id: 0,
            },
            tracksArray: [],
            checklistArray: [],
            edmFileId: '',
            edmFileName: '',
            registrationPageImageFileId: '',
            registrationPageImageFileName: '',
            eventBannerFileId: '',
            eventBannerFileName: '',
        };
    }
    onEventBannerUploadSuccess(file) {
        this.setState({
            eventBannerFileId: file.id,
        });
    }
    onEventBannerUploadFail(error) {}
    onRegistrationPageImageUploadSuccess(file) {
        this.setState({
            registrationPageImageFileId: file.id,
        });
    }
    onRegistrationPageImageUploadFail(error) {}
    onEDMUploadSuccess(file) {
        this.setState({
            edmFileId: file.id,
        });
    }
    onEDMUploadFail(error) {}
    componentDidMount() {
        this.props
            .getRelevantStuffs()
            .then(() =>
                this.props.match.params.eventid
                    ? this.getAllEventDetails(this.props.match.params.eventid)
                    : this.props.eventResetEventFields(),
            );
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.componentDidMount();
        }
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    changeExpenditureMode(type, id) {
        let expenditure = Object.assign({}, this.state.expenditure);
        expenditure.mode = type;
        expenditure.id = id;
        this.setState({
            expenditure,
        });
    }
    changeChecklistMode(type, id) {
        let checklist = Object.assign({}, this.state.checklist);
        checklist.mode = type;
        checklist.id = id;
        this.setState({
            checklist,
        });
    }
    getAllEventDetails(eventid) {
        if (eventid) {
            //Event - Main Details
            this.props.eventResetEventFields().then(() => {
                this.props.getEventDetails(eventid).then(res => {
                    this.setState({
                        edmFileId: this.props.main.fields.edmPdf,
                        edmFileName: `Download EDM PDF`,
                        registrationPageImageFileId: this.props.main.fields.registrationPageImage,
                        registrationPageImageFileName: `Download Registration Page Image`,
                        eventBannerFileId: this.props.main.fields.bannerImage,
                        eventBannerFileName: `Download Banner Image`,
                    });
                });
                this.props.eventSetEventId(eventid);
            });
            //Event - Tracks
            this.props
                .eventResetTrackFields()
                .then(() => this.props.eventGetAttendeeList(eventid))
                .then(() => {
                    this.props.getEventTracks(eventid);
                    this.props.eventTrackSetEventId(eventid);
                });
            //Event - Dates
            this.props.eventResetDatesFields().then(() => {
                this.props.getEventDates(eventid);
                this.props.eventDatesSetEventId(eventid);
            });
        }
    }
    //Event - Main Details
    saveEventDetails() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () =>
                this.props.saveEventDetails(this.state.edmFileId, this.state.registrationPageImageFileId, this.state.eventBannerFileId),
            modalBody: 'Are you sure you want to create this event?',
        });
    }
    deleteEventDetails() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.deleteEventDetails(this.props.match.params.eventid),
            modalBody: 'Are you sure you want to delete this event?',
        });
    }
    updateEventDetails() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () =>
                this.props.updateEventDetails(
                    this.props.match.params.eventid,
                    this.state.edmFileId,
                    this.state.registrationPageImageFileId,
                    this.state.eventBannerFileId,
                ),
            modalBody: 'Are you sure you want to save the changes?',
        });
    }
    handleEventImageInput(event) {
        const supportType = ['image/png', 'image/jpeg'];
        const reader = new FileReader();
        const file = event.target.files[0];
        const id = event.target.id;
        if (supportType.includes(file.type)) {
            reader.onload = () => {
                const b64string = reader.result.substring(reader.result.indexOf(',') + 1);
                this.props.saveDocumentFile(file.type, file.name, b64string, id);
            };
            // Handle cancel behaviour
            if (file.length !== 0) {
                reader.readAsDataURL(file);
            }
        } else {
            this.props.dialogModalToggle();
            this.props.setEventModalData({
                modalAction: './' + this.props.match.params.eventid,
                modalBody: 'File type not supported',
                modalHeader: 'Error',
                modalOnlyOneButton: true,
            });
        }
    }
    handleEventFileInput(event) {
        const reader = new FileReader();
        const file = event.target.files[0];
        const id = event.target.id;
        reader.onload = () => {
            const b64string = reader.result.substring(reader.result.indexOf(',') + 1);
            this.props.saveDocumentFile(file.type, file.name, b64string, id);
        };
        if (file.length !== 0) {
            reader.readAsDataURL(file);
        }
    }
    downloadDocumentFile(inputId) {
        this.props.downloadDocumentFile(inputId).then(res => {
            const contentType = parseContentType(res.data.contenttype);
            const str = res.data.attachment;
            blobDownload(res.data.filename, contentType, str);
        });
    }
    //Event - Dates
    saveEventDates() {
        this.props.dialogModalToggle();
        this.props.setEventDatesModalData({
            modalAction: () => this.props.saveEventDates(),
            modalBody: 'Are you sure you want to save this event date?',
        });
    }
    updateEventDates() {
        this.props.dialogModalToggle();
        this.props.setEventDatesModalData({
            modalAction: () => this.props.updateEventDates(),
            modalBody: 'Are you sure you want to save this event date?',
        });
    }
    //Event - Tracks
    saveEventTracks() {
        this.props.dialogModalToggle();
        this.props.setEventTracksModalData({
            modalAction: () => this.props.saveEventTracks(),
            modalBody: 'Are you sure you want to save these event tracks?',
        });
    }
    updateEventTracks() {
        this.props.dialogModalToggle();
        this.props.setEventTracksModalData({
            modalAction: () => this.props.updateEventTracks(this.props.match.params.eventid),
            modalBody: 'Are you sure you want to update these event tracks?',
        });
    }
    saveSingleEventTrack(inputid) {
        this.props.saveSingleEventTrack(inputid);
    }
    deleteSingleEventTrack(inputid) {
        this.props.deleteSingleEventTrack(inputid);
    }
    // Events - Expenditure - Mock
    addMockExpenditure() {
        let expendituresArray = this.state.expendituresArray.concat([]);
        expendituresArray.push({
            transactionDate: '20 Jul 2018',
            transactionType: 'expenditure',
            amount: 299900,
            description: 'Venue Booking Fee',
        });
        this.setState({ expendituresArray });
    }
    removeMockExpenditure() {
        let expendituresArray = this.state.expendituresArray.concat([]);
        expendituresArray.pop();
        this.setState({ expendituresArray });
    }
    // Render
    renderNav(navs) {
        const navItems = this.props.match.params.eventid ? navs.map(this.renderNavItem) : navs.slice(0, 1).map(this.renderNavItem);
        return navItems;
    }
    renderNavItem(navItem) {
        return (
            <NavItem key={navItem.key}>
                <NavLink
                    className={this.state.activeTab === navItem.key ? 'active' : null}
                    onClick={() => {
                        this.toggleTab(navItem.key);
                    }}
                >
                    {navItem.text}
                </NavLink>
            </NavItem>
        );
    }
    render() {
        var navs = [
            {
                key: 'main',
                text: 'Main Details',
            },
            {
                key: 'dates',
                text: 'Date & Time',
            },
            {
                key: 'tickets',
                text: 'Tickets',
            },
            {
                key: 'checklist',
                text: 'Checklist',
            },
            {
                key: 'expenditures',
                text: 'Income & Expenditure',
            },
        ];

        return (
            <div className="page-widget">
                {this.props.main.loading || this.props.loading ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <DialogModal
                            boundForceUpdate={this.boundForceUpdate}
                            loading={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalLoading : false}
                            modalBody={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalBody : ``}
                            modalOnlyOneButton={
                                this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalOnlyOneButton : false
                            }
                            buttonDisabler={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalLoading : true}
                            modalAction={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalAction : null}
                            modalHeader={
                                this.props[this.state.activeTab] && this.props[this.state.activeTab].modalHeader
                                    ? this.props[this.state.activeTab].modalHeader
                                    : 'Confirmation'
                            }
                        />
                        <React.Fragment>
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <Link to="/staff/galadinner/all">
                                        <Button className="primary-btn-style-outline">
                                            <i className="material-icons align-text-bottom">chevron_left</i> Back
                                        </Button>
                                        &nbsp;
                                    </Link>
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <Nav tabs>{this.renderNav(navs)}</Nav>
                                </Col>
                            </Row>
                        </React.Fragment>
                        <div className="page-widget">
                            <Row>
                                <Col xs={12}>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="main">
                                            <MainComponent
                                                // Data
                                                data={this.props.main.fields}
                                                state={this.state}
                                                selection={this.props.main.selection}
                                                validation={this.props.main.validation}
                                                eventid={this.props.match.params.eventid}
                                                // Input props
                                                addEvent={this.saveEventDetails}
                                                deleteEvent={this.deleteEventDetails}
                                                updateEvent={this.updateEventDetails}
                                                handleEventInputChange={this.props.changeEventInput}
                                                // File input props
                                                file={this.props.main.file}
                                                handleEventFileInput={this.handleEventFileInput}
                                                handleEventImageInput={this.handleEventImageInput}
                                                handleEventImageInput={this.handleEventImageInput}
                                                removeDocumentFileAction={this.props.removeDocumentFile}
                                                downloadDocumentFileAction={this.downloadDocumentFile}
                                                onEDMUploadSuccess={this.onEDMUploadSuccess}
                                                onEDMUploadFail={this.onEDMUploadFail}
                                                onEventBannerUploadSuccess={this.onEventBannerUploadSuccess}
                                                onEventBannerUploadFail={this.onEventBannerUploadFail}
                                                onRegistrationPageImageUploadSuccess={this.onRegistrationPageImageUploadSuccess}
                                                onRegistrationPageImageUploadFail={this.onRegistrationPageImageUploadFail}
                                                edmFileId={this.state.edmFileId}
                                                registrationPageImageFileId={this.state.registrationPageImageFileId}
                                                eventBannerFileId={this.state.eventBannerFileId}
                                                edmFileName={this.state.edmFileName}
                                                registrationPageImageFileName={this.state.registrationPageImageFileName}
                                                eventBannerFileName={this.state.eventBannerFileName}
                                            />
                                        </TabPane>
                                        {this.props.match.params.eventid ? (
                                            <TabPane tabId="dates">
                                                <DatesComponent
                                                    mode={this.props.dates.mode}
                                                    data={this.props.dates.date}
                                                    fields={this.props.dates.fields}
                                                    saveEventDates={this.saveEventDates}
                                                    updateEventDates={this.updateEventDates}
                                                    handleEventInputChange={this.props.changeDateInput}
                                                />
                                            </TabPane>
                                        ) : null}
                                        {this.props.match.params.eventid ? (
                                            <TabPane tabId="tickets">
                                                <TicketsComponent eventId={this.props.match.params.eventid} />
                                            </TabPane>
                                        ) : null}
                                        {this.props.match.params.eventid ? (
                                            <TabPane tabId="checklist">
                                                {this.state.checklist.mode === 'list' && (
                                                    <ChecklistListPage
                                                        id={this.state.checklist.id}
                                                        setMode={this.changeChecklistMode}
                                                        checklist={this.props.checklist}
                                                        checklistItems={this.props.checklistItems}
                                                    />
                                                )}
                                                {this.state.checklist.mode === 'add' && (
                                                    <ChecklistAddPage id={this.state.checklist.id} setMode={this.changeChecklistMode} />
                                                )}
                                            </TabPane>
                                        ) : null}
                                        {this.props.match.params.eventid ? (
                                            <TabPane tabId="expenditures">
                                                {this.state.expenditure.mode === 'list' && (
                                                    <ExpendituresListPage
                                                        id={this.state.expenditure.id}
                                                        setMode={this.changeExpenditureMode}
                                                    />
                                                )}
                                                {this.state.expenditure.mode === 'add' && (
                                                    <ExpendituresAddPage
                                                        id={this.state.expenditure.id}
                                                        setMode={this.changeExpenditureMode}
                                                    />
                                                )}
                                            </TabPane>
                                        ) : null}
                                    </TabContent>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAllReducer,
        main: state.eventsCreationReducer,
        tracks: state.eventsTrackReducer,
        dates: state.eventsDateReducer,
        // tickets: state.eventsTicketReducer,
        materials: state.eventsMaterialReducer,
        checklist: state.eventsChecklistReducer.checklist,
        checklistItems: state.eventsChecklistReducer.checklistItems,
        expenditures: state.eventsExpenditureReducer,
        attendees: state.eventsAttendeeReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantStuffs: () => {
            return dispatch(eventGetfoodRestriction()).then(() => dispatch(eventGetPaymentMode()));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        // Events - Main Details
        getEventDetails: eventid => {
            return dispatch(eventGetEventDetails(eventid));
        },
        saveEventDetails: (edmFileId, registrationPageImageFileId, eventBannerFileId) => {
            return dispatch(eventSaveEventDetails(edmFileId, registrationPageImageFileId, eventBannerFileId));
        },
        deleteEventDetails: eventid => {
            dispatch(eventRemoveEventDetails(eventid));
        },
        updateEventDetails: (eventid, edmFileId, registrationPageImageFileId, eventBannerFileId) => {
            dispatch(eventUpdateEventDetails(eventid, edmFileId, registrationPageImageFileId, eventBannerFileId));
        },
        eventSetEventId: eventid => {
            dispatch(eventSetEventId(eventid));
        },
        changeEventInput: event => {
            const id = event.target.id;
            const evaluation = id === 'foodRestrict' || id === 'paymentMode' || id === 'confirmRegRequired';
            dispatch(eventChangeEventInput(evaluation ? event.target.checked : event.target.value, id, event.target.name));
        },
        handleEventSize: (height, width, inputId) => {
            dispatch(eventHandleImageInput(height, width, inputId));
        },
        eventResetEventFields: () => {
            return dispatch(eventResetEventFields());
        },
        setEventModalData: data => {
            dispatch(eventSetModalData(data));
        },
        saveDocumentFile: (contenttype, filename, attachment, inputId) => {
            dispatch(eventSaveDocumentFile(contenttype, filename, attachment, inputId));
        },
        removeDocumentFile: inputId => {
            dispatch(eventRemoveDocumentFile(inputId));
        },
        downloadDocumentFile: inputId => {
            return dispatch(eventDownloadDocumentFile(inputId));
        },
        // Event - Dates
        getEventDates: eventid => {
            dispatch(eventDatesGetEventDate(eventid));
        },
        saveEventDates: () => {
            dispatch(eventDatesSaveEventDate());
        },
        updateEventDates: () => {
            dispatch(eventDatesUpdateEventDate());
        },
        eventResetDatesFields: () => {
            return dispatch(eventDatesResetFields());
        },
        eventDatesSetEventId: eventid => {
            dispatch(eventDatesSetEventId(eventid));
        },
        changeDateInput: (moment, inputid) => {
            dispatch(eventDatesChangeDateInput(moment, inputid));
        },
        setEventDatesModalData: data => {
            dispatch(eventDatesSetModalData(data));
        },
        // Event - Tracks
        eventGetAttendeeList: eventid => {
            return dispatch(eventsAttendeeGetAttendeeList(eventid));
        },
        getEventTracks: eventid => {
            return dispatch(eventGetEventTracks(eventid));
        },
        saveEventTracks: () => {
            return dispatch(eventSaveAllEventTracks());
        },
        updateEventTracks: eventid => {
            return dispatch(eventUpdateAllEventTracks(eventid));
        },
        saveNewEventTrack: () => {
            dispatch(eventSaveNewEventTrack());
        },
        saveSingleEventTrack: inputid => {
            dispatch(eventSaveSingleEventTrack(inputid));
        },
        deleteSingleEventTrack: inputid => {
            dispatch(eventRemoveSingleEventTrack(inputid));
        },
        eventTrackSetEventId: eventid => {
            dispatch(eventTrackSetEventId(eventid));
        },
        changeTrackInput: (event, inputid) => {
            dispatch(eventChangeTrackInput(event.target.value, event.target.id, inputid));
        },
        eventResetTrackFields: () => {
            return dispatch(eventResetTrackFields());
        },
        setEventTracksModalData: data => {
            dispatch(eventTracksSetModalData(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsAllPage));
