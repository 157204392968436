import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import VerticalBarChartTwoBars from 'cyder/chart/VerticalBarChartTwoBars';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';

class MembershipIssuedAnalysisByMonth extends React.Component {
    render() {
        return (
            <div>
                <NoShadowPanel
                    title="Workload KPI by Month"
                    description="date filter uses Membership Application Completed Timestamp"
                >
                    <CyderDatePicker />
                    <CyderDatePicker />
                </NoShadowPanel>
                <VerticalBarChartTwoBars
                    items={this.props.data}
                    firstKey="new"
                    secondKey="renewal"
                    title="Membership within 1 Jan 2018 - 10 May 2018"
                    description="Lorem ipsum dolor sit amet"
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        data: state.staffMembershipStatistics.issuedAnalysis.byMonth,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipIssuedAnalysisByMonth));
