import React from 'react';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import MemberResourcesDownloads from './MemberResourcesDownloads';
import MemberResourcesPastEvents from './MemberResourcesPastEvents';
import MemberResourcesPartnerEvents from './MemberResourcesPartnerEvents';
import MemberResourcesReports from './MemberResourcesReports';
import DashboardWidgetComponent from '../DashboardWidgetComponent';

import 'css/cyder/membership/resources.css';

class Resources extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    render() {
        const resources = this.props.memberHomeResources;

        const isIE = /*@cc_on!@*/ false || !!document.documentMode;
        const paddingLeft = isIE ? '100px' : '10px';

        if (this.props.membershipInfo != null && this.props.membershipInfo.id != null) {
            return (
                <div className="nav-tabs-vertical-resources nav-tabs-primary">
                    <Nav tabs className="nav-vertical">
                        <NavItem>
                            <NavLink className={this.state.activeTab == 0 ? 'active' : ''} onClick={() => this.toggle(0)}>
                                Membership Materials
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={this.state.activeTab == 1 ? 'active' : ''} onClick={() => this.toggle(1)}>
                                SGBC Event Materials
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={this.state.activeTab == 2 ? 'active' : ''} onClick={() => this.toggle(2)}>
                                Circulars & Notices
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={this.state.activeTab == 3 ? 'active' : ''} onClick={() => this.toggle(3)}>
                                Reports & Publications
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab.toString()} style={{ paddingLeft }}>
                        <TabPane tabId="0">
                            <MemberResourcesDownloads downloads={resources.downloads} />
                        </TabPane>
                        <TabPane tabId="1">
                            <MemberResourcesPastEvents pastEvents={resources.pastEvents} />
                        </TabPane>
                        <TabPane tabId="2">
                            <MemberResourcesPartnerEvents partnerEvents={resources.circulars} />
                        </TabPane>
                        <TabPane tabId="3">
                            <MemberResourcesReports reports={resources.reports} />
                        </TabPane>
                    </TabContent>
                </div>
            );
        }
        return (
            <DashboardWidgetComponent
                // src={'/assets/images/resource-library-dashboard.jpg'}
                description={'The Resource Library is only available to SGBC Members. Sign up for SGBC Membership to unlock this feature.'}
            />
        );
    }
}

export default Resources;
