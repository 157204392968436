import React from 'react';

import { Button, Container } from 'reactstrap';
import PublicLogin from 'pages/public/PublicLogin';
import ForgotPassword from 'pages/forgotpassword/ForgotPassword';
import CreateAccount from 'pages/createaccount/CreateAccountPage';

const divStyle = {
    color: 'white',
    fontSize: '2.5vw', // Default
    lineHeight: '4.0vw',
    fontWeight: 'bold',
    top: '10vw',
};
const centreAlignStyle = (breakpoint, defaultWidth) => {
    return {
        display: 'inline-block',
        justifyContent: 'center',
        width: breakpoint ? defaultWidth : '100%',
        height: '100%',
    };
};

class MembershipPanel extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.changePanel = this.changePanel.bind(this);
        this.setWindowSize = this.setWindowSize.bind(this);
        this.setCreateAccountStatus = this.setCreateAccountStatus.bind(this);
        this.state = {
            panel: 1,
            status: 'createAccount',
            windowSize: 0,
            location: '',
        };
    }
    componentDidMount() {
        window.addEventListener('resize', this.setWindowSize);
        this.setWindowSize();
        const panel = localStorage.getItem('panel');
        const panelToStart = panel ? JSON.parse(panel) : this.props.panelToStart;
        const location = this.props.location;
        if (panelToStart) this.changePanel(panelToStart, location);
    }
    componentDidUpdate(prevProps) {
        // Refer to PublicMemberLogin.js
        if (prevProps.panelToStart !== this.props.panelToStart) this.componentDidMount();
    }
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.setWindowSize);
        localStorage.removeItem('panel');
    }
    setWindowSize() {
        this.setState({
            windowSize: window.innerWidth,
        });
    }
    setCreateAccountStatus(whereAbout) {
        this.setState({
            status: whereAbout,
        });
    }
    changePanel(panel, location) {
        if (this._isMounted) {
            this.setState({
                panel,
                location,
            });
        }
    }
    render() {
        const panel = this.state.panel;
        return (
            <React.Fragment>
                {panel === 1 && <MembershipPanel1 changePanel={this.changePanel} windowSize={this.state.windowSize} />}
                {panel === 2 && <MembershipPanel2 changePanel={this.changePanel} windowSize={this.state.windowSize} />}
                {panel === 3 && (
                    <MembershipPanel3 windowSize={this.state.windowSize} changePanel={this.changePanel} location={this.state.location} />
                )}
                {panel === 4 && (
                    <MembershipPanel4
                        status={this.state.status}
                        changePanel={this.changePanel}
                        windowSize={this.state.windowSize}
                        setCreateAccountStatus={this.setCreateAccountStatus}
                    />
                )}
                {panel === 5 && <MembershipPanel5 changePanel={this.changePanel} windowSize={this.state.windowSize} />}
            </React.Fragment>
        );
    }
}
const MembershipPanel1 = ({ changePanel, windowSize }) => {
    const breakpoint = windowSize > 1100;
    return (
        <MembershipBannerWrapper style={{ ...divStyle, fontSize: breakpoint ? '40px' : '24px' }}>
            <p className="p-2">
                <strong>
                    Meet and partner with like-minded green building organisations to uncover business and collaboration opportunities.
                </strong>
            </p>
            <Button size="lg" style={{ fontSize: '1.3vw' }} onClick={() => changePanel(2)} className="primary-btn-style">
                <strong>Join us now</strong>
            </Button>
        </MembershipBannerWrapper>
    );
};
const MembershipPanel2 = ({ changePanel, windowSize }) => {
    const breakpoint = windowSize > 1100;
    return (
        <MembershipBannerWrapper
            style={{
                ...divStyle,
                top: '10vw',
                fontSize: breakpoint ? '40px' : '24px',
            }} // Style and height of second panel
            className="justify-content-center"
        >
            <p>
                <strong>Join us as corporate member today</strong>
            </p>
            <div className="d-flex justify-content-center">
                <Button
                    xs="12"
                    size="lg"
                    className="primary-btn-style m-2"
                    style={{ fontSize: windowSize < 1000 ? '18px' : '1.3vw' }}
                    onClick={() => changePanel(4)}
                >
                    <strong>Create New Account</strong>
                </Button>
            </div>
            <p>
                <strong>Already our member ?</strong>
            </p>
            <div className="d-flex justify-content-center">
                <Button
                    xs="12"
                    size="lg"
                    className="primary-btn-style d-flex justify-content-between m-2"
                    style={{ fontSize: windowSize < 1000 ? '18px' : '1.3vw' }}
                    onClick={() => changePanel(3)}
                >
                    <strong>Log in Now</strong>
                </Button>
            </div>
        </MembershipBannerWrapper>
    );
};
const MembershipPanel3 = ({ windowSize, changePanel, location }) => {
    const breakpoint = windowSize > 1100;
    var location = location.params.page === undefined ? 'default' : 'renew';
    return (
        <MembershipBannerWrapper headerDisabled style={{ top: '5vw' }}>
            <div
                style={{
                    ...centreAlignStyle(breakpoint, '35%'),
                }}
            >
                <PublicLogin changePanel={changePanel} location={location} />
            </div>
        </MembershipBannerWrapper>
    );
};
const MembershipPanel4 = ({ status, windowSize, changePanel, setCreateAccountStatus }) => {
    const breakpoint = windowSize > 1100;
    return (
        <MembershipBannerWrapper
            headerDisabled
            style={{
                top:
                    status !== 'createAccount' && status !== 'previewAccount'
                        ? '13vw' // Height used during select company
                        : status === 'createAccount'
                        ? '2vw' // Height used during creete account stage
                        : '2vw', // Height used during preview account
            }}
        >
            <div className="membership-page">
                <Container className="membership-wrapper text-center" style={{ display: 'block' }}>
                    <div className="hero">
                        <img src="/assets/icons/icon-membership.png" width="50" />
                        <h3>
                            <span>Join</span> the Community!
                        </h3>
                        <p>We require a few details to create your Corporate Profile.</p>
                    </div>
                </Container>
            </div>
            <div
                style={{
                    ...centreAlignStyle(breakpoint),
                    width: breakpoint ? (status !== 'previewAccount' && status !== 'createAccount' ? '60%' : '60%') : '100%',
                }}
            >
                <CreateAccount changePanel={changePanel} setCreateAccountStatus={setCreateAccountStatus} />
            </div>
        </MembershipBannerWrapper>
    );
};
const MembershipPanel5 = ({ windowSize, changePanel }) => {
    return (
        <MembershipBannerWrapper
            headerDisabled
            style={{
                top: '3vw',
            }}
        >
            <div>
                <ForgotPassword changePanel={changePanel} />
            </div>
        </MembershipBannerWrapper>
    );
};
// !important
const MembershipBannerWrapper = ({ children, headerDisabled, imageComponent, className, style }) => (
    <div>
        {imageComponent || null}
        <div
            style={style}
            className={'hero-section d-none d-md-block d-flex flex-column ' + className}
            // // USED FOR CAROUSEL FEATURES
            // className={'carousel-caption hero-section d-none d-md-block d-flex flex-column ' + className}
            // id="carousel-wrapper"
        >
            {!headerDisabled && (
                <div className="title">
                    <h1 style={{ fontSize: '5vw' }}>
                        <strong>Join the Community</strong>
                    </h1>
                    <br />
                </div>
            )}
            <div className="description text-center" style={{ minHeight: '400px' }}>
                {children}
            </div>
        </div>
    </div>
);

export default MembershipPanel;
