/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, instance, SGBC_API_CORS_ROOT } from 'config';
import {
    FETCH_EVENT_CHECKLIST_SUCCESS,
    FETCH_EVENT_CHECKLIST_BY_ID,
    SAVE_NEW_CHECKLIST,
    EVENT_CHECKLIST_SET_MODAL_DATA,
    EVENT_CHECKLIST_GENERATE_REQUEST,
    EVENT_CHECKLIST_GENERATE_SUCCESS,
    EVENT_CHECKLIST_GENERATE_FAILURE,
} from '../../../../actions/constants/events/actionTypesStaffEventsCheckList';

export function search(keyword, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'search',
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function getByEventId(eventId, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'get-by-eventid',
            id: eventId,
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function save(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'create',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'update',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}

export const fetchEventChecklist = data => dispatch => {
    return instance
        .post('/events_v1/cn/event-checklist?action=import-checklist', data)
        .then(response => {
            const res = response.data.data;
            if (res.errno) return res;
            dispatch({ type: FETCH_EVENT_CHECKLIST_SUCCESS, payload: data });
            return response;
        })
        .catch(err => {
            throw err;
        });
};

export function fetchEventChecklistById(eventId, data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'get-by-eventid',
            id: eventId,
        });
        console.log('fetch', eventId, data);

        const params = {
            itemsPerPage: data.itemsPerPage || getState().config.defaultTablePageSize,
            page: data.page + 1 || 1,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export const saveNewChecklist = data => dispatch => {
    var action = data.id ? `update` : `create`;
    return instance
        .post(`/events_v1/cn/event-checklist?action=${action}`, data)
        .then(response => {
            dispatch({ type: SAVE_NEW_CHECKLIST, payload: data });
        })
        .catch(err => {
            throw err;
        });
};

export function generateRecords(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-checklist`, {
            action: 'generateRecords',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_CHECKLIST_GENERATE_REQUEST,
            EVENT_CHECKLIST_GENERATE_SUCCESS,
            EVENT_CHECKLIST_GENERATE_FAILURE,
        );
    };
}

export function eventSetModalData(data) {
    return {
        type: EVENT_CHECKLIST_SET_MODAL_DATA,
        data,
    };
}
