import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { getMembersEventProfileDetails } from 'actions/analytics/memberAnalyticsAction';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

class MembersEventDetailList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load(this.props.category).then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const columns = [
            {
                Header: 'Company',
                accessor: 'name',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['name'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Link key={props.original.id} to={`/staff/company/` + props.original.id}>
                            <span style={{ color: 'black' }}>{props.value}</span>
                            <br />
                        </Link>
                    );
                },
            },
            {
                Header: 'Attended Events',
                accessor: 'totalEvents',
                maxWidth: 125,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['totalEvents'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: category => {
            return dispatch(getMembersEventProfileDetails(category));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembersEventDetailList));
