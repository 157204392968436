import StoredLayout from 'layouts/StoredLayout';
import DefaultSidebar1 from 'layouts/DefaultSidebar1';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import Select from 'react-select';
import { Alert, Table, Badge, Card, CardBody, Container, FormGroup, Label, Input, Row, Col, Form, Button } from 'reactstrap';
import {
    getAllPrivateNomineesResult,
    getAllPublicNomineesResult,
    getAllScrutineer,
    getVoterInfoByElectionId,
    findById,
} from 'actions/staff/election/action';

class ElectionsResultPage extends React.Component {
    constructor(props) {
        super(props);

        this.back = this.back.bind(this);
        this.printResult = this.printResult.bind(this);

        this.state = {
            loading: true,
            data: {},
            pubNominees: [],
            privNominees: [],
            voterInfo: {},
        };
    }
    printResult() {
        window.open(`/staff/elections/view/${this.props.match.params.electionid}/print`);
    }
    unlock() {
        this.setState({ d: false });
    }
    back() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}`);
    }
    componentDidMount() {
        this.props
            .findById(this.props.match.params.electionid)
            .then(res => {
                if (res.data.resultsUnlockedByScrutineers === 0) {
                    history.push(`/staff/elections/view/${this.props.match.params.electionid}/vault`);
                }
                this.setState({
                    data: res.data,
                    loading: false,
                });
            })
            .catch(error => {});

        this.props
            .getVoterInfoByElectionId(this.props.match.params.electionid)
            .then(res => {
                this.setState({
                    voterInfo: res.data[0][0],
                });
            })
            .catch(error => {});

        this.props
            .getAllPublicNominees(this.props.match.params.electionid)
            .then(res => {
                var i = 0;
                var tempNominees = [];
                res.data.forEach(nominee => {
                    i++;
                    nominee.sn = i;
                    nominee.name = nominee.fullName;
                    nominee.org = nominee.organizationName;
                    nominee.votes = nominee.votesCount;
                    if (i <= 8) {
                        nominee.in = true;
                    }
                    tempNominees.push(nominee);
                });
                var newTempNominees = tempNominees.sort(function(a, b) {
                    if (a.sn > b.sn) {
                        return 1;
                    }
                    if (b.sn > a.sn) {
                        return -1;
                    }
                    return 0;
                });
                this.setState({
                    pubNominees: newTempNominees,
                });
            })
            .catch(error => {});

        this.props
            .getAllPrivateNominees(this.props.match.params.electionid)
            .then(res => {
                var i = 0;
                var tempNominees = [];
                res.data.forEach(nominee => {
                    i++;
                    nominee.sn = i;
                    nominee.name = nominee.fullName;
                    nominee.org = nominee.organizationName;
                    nominee.votes = nominee.votesCount;
                    if (i <= 6) {
                        nominee.in = true;
                    }
                    tempNominees.push(nominee);
                });
                var newTempNominees = tempNominees.sort(function(a, b) {
                    if (a.sn > b.sn) {
                        return 1;
                    }
                    if (b.sn > a.sn) {
                        return -1;
                    }
                    return 0;
                });
                this.setState({
                    privNominees: newTempNominees,
                });
            })
            .catch(error => {});
    }
    render() {
        //const ended = this.props.match.params.electionid == '9';
        const ended = true;
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="mb-3">
                                    <Col xs={{ size: 8, offset: 2 }} className="text-center">
                                        <h1>
                                            <strong>Result Vault: {this.state.data.name}</strong>
                                        </h1>
                                        <div
                                            style={{ marginBottom: '1em' }}
                                            dangerouslySetInnerHTML={{ __html: this.state.data.summary }}
                                        />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.data.description }} />
                                        {/* <span className="text-muted">{this.state.data.summary}</span> */}
                                        <br />
                                        <div>
                                            <strong>Start:</strong> {moment(this.state.data.startDate).format('DD/MM/YYYY')}
                                            &nbsp;&nbsp;&nbsp;
                                            <strong>End:</strong> {moment(this.state.data.endDate).format('DD/MM/YYYY')}
                                        </div>
                                    </Col>
                                    <Col xs={2} className="d-flex justify-content-center flex-column">
                                        <Button className="my-1" color="warning" block onClick={this.back}>
                                            <i className="material-icons align-text-bottom">chevron_left</i> Back
                                        </Button>

                                        <Button className="mt-1" color="default" onClick={this.printResult}>
                                            <i className="material-icons align-text-bottom">print</i> Print
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ size: 6, offset: 3 }}>
                                        {ended ? (
                                            <div>
                                                <div className="d-flex">
                                                    <h5>
                                                        Total Eligible Voters: <strong>{this.state.voterInfo.numberOfVoters}</strong>
                                                    </h5>
                                                    <Link
                                                        className="ml-auto"
                                                        to={'/staff/elections/view/' + this.props.match.params.electionid + '/voters'}
                                                    >
                                                        View Voter List
                                                    </Link>
                                                </div>
                                                <h5>
                                                    Total Votes Received:{' '}
                                                    <strong>
                                                        {this.state.voterInfo.numberOfVotes} (
                                                        {`${Math.round(
                                                            (this.state.voterInfo.numberOfVotes / this.state.voterInfo.numberOfVoters) *
                                                                100,
                                                        )}%`}
                                                        )
                                                    </strong>
                                                </h5>
                                            </div>
                                        ) : (
                                            <div>
                                                <Alert color="info">Election has no ended yet.</Alert>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={6}>
                        {ended && (
                            <Card>
                                <CardBody>
                                    <div>
                                        <h4>
                                            <strong>Public Sector Category - {this.state.data.publicSectorVacancies} Seats</strong>
                                        </h4>
                                        <NomineesTable candidates={this.state.pubNominees} />
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                    <Col xs={6}>
                        {ended && (
                            <Card>
                                <CardBody>
                                    <div>
                                        <h4>
                                            <strong>Private Sector Category - {this.state.data.privateSectorVacancies} Seats</strong>
                                        </h4>
                                        <NomineesTable candidates={this.state.privNominees} />
                                    </div>
                                </CardBody>
                            </Card>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const NomineesTable = candidates => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>S/N</th>
                    <th>Name</th>
                    <th>Organization</th>
                    <th>Votes</th>
                    <th>Result</th>
                </tr>
            </thead>
            {candidates.candidates !== null && candidates.candidates.length > 0 && (
                <tbody>
                    {candidates.candidates.map((candidate, i) => {
                        return (
                            <tr key={i} style={candidate.in ? { backgroundColor: '#d0e9c6' } : null}>
                                <td>{candidate.sn}</td>
                                <td>{candidate.name}</td>
                                <td>{candidate.org}</td>
                                <td>{candidate.votes}</td>
                                <td>{candidate.in ? <i className="fa fa-check" /> : <i className="fa fa-close" />}</td>
                            </tr>
                        );
                    })}
                </tbody>
            )}
        </Table>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        findById: id => {
            return dispatch(findById(id));
        },
        getVoterInfoByElectionId: id => {
            return dispatch(getVoterInfoByElectionId(id));
        },
        getAllPrivateNominees: id => {
            return dispatch(getAllPrivateNomineesResult(id));
        },
        getAllPublicNominees: id => {
            return dispatch(getAllPublicNomineesResult(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSidebar1(ElectionsResultPage));
