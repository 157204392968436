import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';

import { getCompanyAttendencCountAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class TotallShowedUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            var attendenceText = res.data[2][0].totalCompaniesAttended + '/' + res.data[3][0].totalCompaniesRegistered;
            res.data[4] = attendenceText;
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                var attendenceText = res.data[2][0].totalCompaniesAttended + '/' + res.data[3][0].totalCompaniesRegistered;

                res.data[4] = attendenceText;

                this.setState({
                    data: res.data,
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }

    render() {
        const xs = {
            bigxs: 3,
            normalxs: 9,
        };
        return (
            <AnalyticsCard title={`Attendance: By Company`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigTextWithNormalText
                            normalText={`Members Registered`}
                            normalTextColor={`unset`}
                            bigText={this.state.data[0][0].sgbcRegisteredCompany}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Non-Members Registered`}
                            normalTextColor={`unset`}
                            bigText={this.state.data[1][0].nonsgbcRegisteredCompany}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Attended`}
                            //  text={`${this.state.data[0][0].sgbcMemberAttendees} or ${Math.round(this.state.data.percentage)}%`}
                            normalTextColor={`unset`}
                            bigText={this.state.data[4]}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getCompanyAttendencCountAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(TotallShowedUp));
