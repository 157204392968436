import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from 'history.js';

import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { dialogModalToggle } from 'actions/modal/dialogModalAction';

class DialogModal extends React.Component {
    constructor(props) {
        super(props);
        this.actionToggle = this.actionToggle.bind(this);
        this.forceUpdate = this.forceUpdate.bind(this);
    }
    determineModalNegativeAction(modalAction) {
        if (this.props.negativeButtonAction) return this.props.negativeButtonAction;
        return this.props.actionToggle;
    }
    actionToggle() {
        if (this.props.toggler) this.props.toggler();
        if (this.props.forceModalAction) this.determineModalPositiveAction();
        if (this.props.actionToggleExternal) {
            this.props.actionToggleExternal();
            return;
        }
        this.props.actionToggle();
    }
    forceUpdate() {
        this.props.actionToggle();
        try {
            this.props.boundForceUpdate && this.props.boundForceUpdate();
        } catch (e) {}
    }
    determineModalPositiveAction() {
        const modalAction = this.props.modalAction;
        // IF IT IS STRING, INTERPRET AS REDIRECT URL AFTER BUTTON IS CLICKED
        if (modalAction && typeof modalAction === 'string') {
            // FOR CIRCUMSTANCES THAT REQUIRED PAGE RELOAD
            if (modalAction === 'forcePageUpdate') return () => window.location.reload();
            // FOR CIRCUMSTANCES FORCE RELOADING COMPONENT
            if (modalAction === 'forceUpdate') return this.forceUpdate;
            return () => {
                this.props.actionToggle();
                history.push(modalAction);
            };
        }

        if (!modalAction) return this.props.toggler || this.actionToggle;
        return () => {
            const shouldUpdate = modalAction();
            if (shouldUpdate === 'forceUpdate') this.forceUpdate();
            if (shouldUpdate === 'forcePageUpdate') window.location.reload();
        };
    }
    render() {
        const { closeModal } = this.props;
        const modalAction = this.determineModalPositiveAction();
        const positiveButtonText = this.props.positiveButtonText || 'Ok';
        // NEGATIVE BUTTON ACTION IS MOSTLY UNUSED, DEFAULTS TO JUST CLOSE THE MODAL
        const negativeButtonAction = this.determineModalNegativeAction(modalAction);
        const negativeButtonText = this.props.negativeButtonText || 'Cancel';

        return (
            <Modal size={this.props.size || 'md'} isOpen={this.props.modalOpen} toggle={this.actionToggle} className={this.props.className}>
                <ModalHeader className="border-0" toggle={this.actionToggle}>
                    <strong>{this.props.modalHeader || 'Confirmation'}</strong>
                </ModalHeader>
                <ModalBody className="ml-2 mr-2">{this.props.loading ? <LoadingSpinner /> : this.props.modalBody}</ModalBody>
                {(!this.props.positiveButtonHide || !this.props.modalOnlyOneButton || !this.props.negativeButtonHide) && (
                    <ModalFooter className="border-0">
                        {!this.props.positiveButtonHide && (
                            <Button
                                onClick={modalAction}
                                className="text-bold primary-btn-xs primary-btn-style"
                                disabled={this.props.positiveButtonDisabler || this.props.buttonDisabler}
                            >
                                {positiveButtonText}
                            </Button>
                        )}
                        {!this.props.modalOnlyOneButton && !this.props.negativeButtonHide && (
                            <Button
                                disabled={this.props.negativeButtonDisabler || this.props.buttonDisabler}
                                onClick={closeModal ? closeModal : negativeButtonAction}
                                className="primary-btn-style-outline primary-btn-xs"
                            >
                                {negativeButtonText}
                            </Button>
                        )}
                    </ModalFooter>
                )}
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.dialogModalReducer,
        ...ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        actionToggle: () => dispatch(dialogModalToggle()),
    };
};

DialogModal.propTypes = {
    toggle: PropTypes.func,
    modalOpen: PropTypes.bool,
    modalBody: PropTypes.node,
    buttonDisabler: PropTypes.bool,
    modalHeader: PropTypes.string,
    modalMessageMap: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.string,
    negativeButtonText: PropTypes.string,
    negativeButtonAction: PropTypes.func,
    overrideMessage: PropTypes.string,
};

// translate() is if we want to use HOC to perform t()
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DialogModal);
