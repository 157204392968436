import React, { Component } from 'react';
import StoredLayout from 'layouts/StoredLayout';

// COMPONENT
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import DynamicExportPanel from 'pages/staff/certification/export/DynamicExportPanel';

class StaffCertificationDynamicExport extends Component {
    state = {
        loading: false,
        activeTab: 'product',
        activeTabText: 'Product',
    };
    toggleTab(tab, text) {
        if (this.state.activeTab !== tab)
            this.setState({
                activeTab: tab,
                activeTabText: text,
            });
    }
    renderNavItem(navItems) {
        return navItems.map(navItem => {
            const { key, text, icon } = navItem;
            return (
                <NavItem key={key}>
                    <NavLink
                        className={this.state.activeTab === key ? 'active' : null}
                        onClick={() => {
                            this.toggleTab(key, text);
                        }}
                    >
                        <i className={'fas ' + icon} />
                        {' ' + text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        const navs = [
            {
                key: 'product',
                text: 'Product',
            },
            {
                key: 'service',
                text: 'Services',
            },
        ];
        const { loading, activeTab, activeTabText } = this.state;
        const { showModalIf } = this.props;
        return (
            <React.Fragment>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Nav tabs>{this.renderNavItem(navs)}</Nav>
                    </Col>
                </Row>
                <hr />
                {loading ? (
                    <LoadingSpinner center />
                ) : (
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={activeTab}>
                            <DynamicExportPanel activeTab={activeTab} activeTabText={activeTabText} showModalIf={showModalIf} />
                        </TabPane>
                    </TabContent>
                )}
            </React.Fragment>
        );
    }
}

export default StoredLayout(StaffCertificationDynamicExport);
