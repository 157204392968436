import withQuery from 'with-query';

import {
    MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE,
    MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED,
    MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS,
} from '../../constants/actionTypesMemberCertification';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

// export function memberCertListGetInfo(status, type, page, pageSize, sorted, filtered) {
//     return (dispatch, getState) => {
//         const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
//             action: 'memberCertificateListByType',
//         });
//         const body = {
//             status,
//             type,
//             keyword: '',
//             itemsPerPage: pageSize || getState().config.defaultTablePageSize,
//             page: page + 1 || 1,
//             active: 1,
//         };
//         return fetchlib.fetch(
//             dispatch,
//             getState,
//             'POST',
//             url,
//             body,
//             MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST,
//             MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS,
//             MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE,
//         );
//     };
// }

export function memberAllCertListGetInfo(status, type, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'memberCertificateListByType',
        });
        const body = {
            status,
            type,
            keyword: '',
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            sorted,
        };
        let successActionReducer =
            status == 'valid' ? MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID : MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            // null,
            // null,
            // null
            MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST,
            successActionReducer,
            MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE,
        );
    };
}

export function memberCertListSubmitRequestForReviw(certId, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-products`, {
            action: 'reviewCertificate',
        });
        const body = {
            certId: certId,
            remarks: getState().memberCertificationsOverviewReducer.remarks,
        };
        var successAction =
            type == 'product' ? MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS : MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST,
            successAction,
            MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE,
        );
    };
}
export function memberCertListHandleRemarkChange(value) {
    return {
        type: MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE,
        value,
    };
}
