/**
 * List of URLs for Staff
 *
 * Author: Fernando
 */
import { urls as staffUrls } from './navigationStaff';
var clone = require('clone');

const adminItems = [
    {
        icon: 'settings',
        title: 'Admin Panel',
        navToAdmin: true,
        show: true,
        items: [],
    },
];

let mergedUrl = clone(staffUrls);
mergedUrl[0].items.push(adminItems[0]);

export function navigationStaffAsAdmin(state = Array.from(mergedUrl), action) {
    switch (action.type) {
        case 'SET_ADMIN_STAFFNAVIGATION':
            return Object.assign({}, state);
        case 'GET_REVIEW_LIST_COUNT':
            let newState = state.slice();
            const reviewCount = action.response.data.reviewCount;
            newState[0].items[3].items[4].badge.title = reviewCount;
            return newState;
        default:
            return state;
    }
}
