import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Input, Button, Container, Row, Col, Form, FormGroup, CardTitle, CardBody, Label } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';

import { updatePassword } from 'actions/profile/action';

import {
    personalProfileGetProfileInfo,
    personalProfileSaveProfileInfo,
    personalProfileResetToDefault,
    personalProfileHandleInputChange,
    personalProfileSetModalData,
} from 'actions/profile/personalProfileAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

const GenericPanel = props => {
    return (
        <Row className="d-flex justify-content-center">
            <div style={{ marginBottom: '20px', width: '100%' }}>
                {/* <div className="standard-4080-panel"> */}
                <CardBody>{props.headerContent}</CardBody>
                {props.children}
                {/* </div> */}
            </div>
        </Row>
    );
};
const ChangePasswordPanel = ({ updatePassword, onPasswordChange, onConfirmPasswordChange, password, confirmPassword }) => {
    return (
        <CardBody>
            <Row>
                <Col md={7}>
                    <Form>
                        <FormGroup>
                            <Label>Password *</Label>
                            <Input name="password" type="password" onChange={onPasswordChange} value={password} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirm Password *</Label>
                            <Input name="confirmPassword" type="password" onChange={onConfirmPasswordChange} value={confirmPassword} />
                        </FormGroup>
                        <Button size="lg" className="primary-btn-style" onClick={updatePassword}>
                            <strong>Change Password</strong>
                        </Button>
                    </Form>
                </Col>
            </Row>
        </CardBody>
    );
};
class PersonalProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.saveProfileInfo = this.saveProfileInfo.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showLoadingPassword = this.showLoadingPassword.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loadingPassword: false,
            password: '',
            confirmPassword: '',
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: false,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoadingPassword(show) {
        this.setState({
            loadingPassword: show,
        });
    }
    modalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onPasswordChange(e) {
        this.setState({
            password: e.target.value,
        });
    }
    onConfirmPasswordChange(e) {
        this.setState({
            confirmPassword: e.target.value,
        });
    }
    updatePassword() {
        if (this.state.password === '' || this.state.confirmPassword === '') {
            this.showModal(`Error`, `Please enter password and confirm password`, 'alert', false, true);
            return false;
        }
        if (this.state.password !== this.state.confirmPassword) {
            this.showModal(`Error`, `Password does not match confirm password`, 'alert', false, true);
            return false;
        }
        this.showLoadingPassword(true);
        this.props
            .updatePassword(this.state.password)
            .then(response => {
                this.showLoadingPassword(false);
                this.showModal(`Confirmation`, `Your password has been updated`, 'alert', false, true);
            })
            .catch(error => {
                this.showLoadingPassword(false);
                this.showModal(`Error`, `Change password is not successful`, 'alert', false, true);
            });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetToDefault().then(() => {
            this.props.getProfileInfo();
        });
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    saveProfileInfo() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.saveProfileInfo(),
            modalBody: 'Are you sure you want to save the changes',
        });
    }
    render() {
        const changePasswordHeader = (
            <h5>
                <strong>CHANGE ACCOUNT PASSWORD</strong>
            </h5>
        );
        const accountInfoHeader = (
            <h5>
                <strong>PROFILE DETAILS</strong>
            </h5>
        );
        const profileInfo = this.props.profileInfo;
        return (
            <React.Fragment>
                <DialogModal
                    modalHeader={this.props.modalHeader}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    boundForceUpdate={this.boundForceUpdate}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                <Container key={0}>
                    <Row>
                        <Col xs={6} md={4}>
                            {this.props.loading ? (
                                <LoadingSpinner key={0} />
                            ) : (
                                <GenericPanel headerContent={accountInfoHeader}>
                                    <CardBody>
                                        {/* <FormGroup>
                                            <Label>Username*</Label>
                                            <Input readOnly disabled type="text" id="username" value={this.props.username} />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Label>Email*</Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                value={profileInfo.email}
                                                onChange={this.props.handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>First Name</Label>
                                            <Input
                                                type="text"
                                                id="firstName"
                                                value={profileInfo.firstName}
                                                onChange={this.props.handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Last Name</Label>
                                            <Input id="lastName" value={profileInfo.lastName} onChange={this.props.handleInputChange} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Mobile Number</Label>
                                            <Input
                                                type="text"
                                                id="mobileNumber"
                                                value={profileInfo.mobileNumber}
                                                onChange={this.props.handleInputChange}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Office Number</Label>
                                            <Input
                                                type="text"
                                                id="officeNumber"
                                                value={profileInfo.officeNumber}
                                                onChange={this.props.handleInputChange}
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Label>Company Name</Label>
                                            <Input
                                                type="text"
                                                id="companyName"
                                                value={profileInfo.companyName}
                                                onChange={this.props.handleInputChange}
                                            />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Row className="p-2">
                                                <Button
                                                    size="lg"
                                                    className="mr-2 primary-btn-style"
                                                    tabIndex="-1"
                                                    onClick={this.saveProfileInfo}
                                                >
                                                    <strong>Update</strong>
                                                </Button>
                                            </Row>
                                        </FormGroup>
                                    </CardBody>
                                </GenericPanel>
                            )}
                        </Col>
                        <Col xs={6} md={6}>
                            {this.state.loadingPassword ? (
                                <LoadingSpinner />
                            ) : (
                                <GenericPanel headerContent={changePasswordHeader}>
                                    <ChangePasswordPanel
                                        updatePassword={this.updatePassword}
                                        onPasswordChange={this.onPasswordChange}
                                        onConfirmPasswordChange={this.onConfirmPasswordChange}
                                        password={this.state.password}
                                        confirmPassword={this.state.confirmPassword}
                                    />
                                </GenericPanel>
                            )}
                        </Col>
                    </Row>
                </Container>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.personalProfileReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getProfileInfo: () => {
            dispatch(personalProfileGetProfileInfo());
        },
        saveProfileInfo: () => {
            dispatch(personalProfileSaveProfileInfo());
        },
        resetToDefault: () => {
            return dispatch(personalProfileResetToDefault());
        },
        handleInputChange: event => {
            dispatch(personalProfileHandleInputChange(event));
        },
        saveProfileInfo: () => {
            dispatch(personalProfileSaveProfileInfo());
        },
        setModalData: data => {
            dispatch(personalProfileSetModalData(data));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        updatePassword: password => {
            return dispatch(updatePassword(password));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(PersonalProfilePage));
