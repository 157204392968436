import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

const Loading = ({ text }) => (
    <Segment>
        <Dimmer active page inverted>
            <Loader>{text || 'Please wait...'}</Loader>
        </Dimmer>
    </Segment>
);

export default Loading;
