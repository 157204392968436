import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    GROUP_SEARCH_REQUEST,
    GROUP_SEARCH_SUCCESS,
    GROUP_SEARCH_FAILURE,
    GROUP_KEYWORD_SEARCH_REQUEST,
    GROUP_KEYWORD_SEARCH_SUCCESS,
    GROUP_KEYWORD_SEARCH_FAILURE,
    GROUP_TOGGLE_CHECKBOX,
    GROUP_TOGGLE_CHECKBOX_ALL,
    GROUP_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../constants/actionTypesAuth';
import withQuery from 'with-query';

export function groupSearchAction(page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_SEARCH_REQUEST });

        const url = withQuery(SGBC_API_ROOT + '/auth_v1/groups', {
            //appid: getState().cyderProfileReducer.profile.appId,
            action: 'findallwithpagination',
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
        };
        const options = {
            body: JSON.stringify(params),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: GROUP_SEARCH_FAILURE, error });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: GROUP_SEARCH_SUCCESS, response });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_SEARCH_FAILURE, error });
            });
    };
}
export function groupKeywordSearchAction(inputKeywordValue) {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_KEYWORD_SEARCH_REQUEST, inputKeywordValue });

        const url = withQuery(SGBC_API_ROOT + '/misc_v1/group', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'search',
        });
        const body = {
            keyword: inputKeywordValue,
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: GROUP_KEYWORD_SEARCH_FAILURE, error });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: GROUP_KEYWORD_SEARCH_SUCCESS,
                            response,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_KEYWORD_SEARCH_FAILURE, error });
            });
    };
}
export function toggleCheckboxes(id) {
    return {
        type: GROUP_TOGGLE_CHECKBOX,
        id,
    };
}
export function toggleCheckboxesAll() {
    return {
        type: GROUP_TOGGLE_CHECKBOX_ALL,
    };
}
export function changeInputKeywordValue(inputKeywordValue) {
    return {
        type: GROUP_CHANGE_INPUT_KEYWORD_VALUE,
        inputKeywordValue,
    };
}
