import React from 'react';
import { cyderLogoutAction } from '../../actions/security/cyderSecurityAction';
import { connect } from 'react-redux';
import '../../css/elements/dropdown-user.css';
import history from '../../history';

const DropdownUser = ({ fields, items, menuAction }) => (
    <div className="navbar-dropdown dropdown-user">
        <div className="dropdown-title">My account</div>
        {items.map((item, i) => (
            <div
                className="dropdown-item"
                key={i}
                onClick={() => menuAction(fields, item)}
            >
                <i className={item.icon} />
                <span className="title">{item.name}</span>
                <div className="separator" />
                {item.badge && (
                    <span className={item.badgeClass}>{item.badgeText}</span>
                )}
            </div>
        ))}
    </div>
);

let items = [
    {
        icon: 'sli-settings',
        iconColor: 'default',
        name: 'Profile',
        badge: false,
        badgeText: false,
        badgeClass: false,
        action: 'profile',
    },
    {
        icon: 'sli-power',
        iconColor: 'default',
        name: 'Logout',
        badge: false,
        badgeText: false,
        badgeClass: false,
        action: 'logout',
    },
];

const mapStateToProps = (state, ownProps) => {
    return {
        fields: state.cyderLoginReducer.fields,
        items: items,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        menuAction: (fields, menuItem) => {
            // This function ONLY does the binding. Validation should be done in the reducer

            switch (menuItem.action) {
                case 'profile':
                    history.push('/member/myaccount/personalprofile');
                    break;
                case 'logout':
                    dispatch(cyderLogoutAction()).then(res => {
                        history.push('/');
                    });
                    break;
                default:
                    return;
            }
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DropdownUser);
