import {
    STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST,
    STAFF_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS,
    STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE,
    STAFF_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS,
    STAFF_MEMBERHISP_MEMBER_GET_ORGANISATION_CATGORIES_SUCCESS,
} from '../../../../actions/constants/actionTypesStaffMembershipMembers';
import moment from 'moment';

// Defaults Params
const defaultState = {
    loading: false,
    directory: [],
    allDirectory: [],
    orgCategories: [],
    tablePages: -1,
};

export function staffMembershipMembersReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_MEMBERHISP_MEMBER_GET_MEMBER_DIRECTORY_SUCCESS:
            newState.directory = action.response.data;
            newState.tablePages = action.response.message.totalNoOfPages;
            newState.totalNum = action.response.message.totalNoOfItems;
            newState.loading = false;
            return newState;
        case STAFF_MEMBERSHIP_MEMBER_GET_MEMBER_DIRECTORY_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_MEMBERHISP_MEMBER_GET_ALL_MEMBER_DIRECTORY_SUCCESS:
            newState.allDirectory = action.response.data;
            // Map date to display format
            const mapDate = date => moment(date).format('MMM D, YYYY');
            newState.allDirectory = action.response.data.map(directory => {
                directory.dateJoined = mapDate(directory.dateJoined);
                directory.expiryDate = mapDate(directory.expiryDate);
                return directory;
            });
            return newState;
        case STAFF_MEMBERHISP_MEMBER_GET_ORGANISATION_CATGORIES_SUCCESS:
            newState.orgCategories = action.response.data.map(orgCategory => ({
                label: orgCategory.name,
                value: orgCategory.id,
            }));
            newState.orgCategories.unshift({ label: '------', value: '' });
            return newState;
        default:
            return state;
    }
}
