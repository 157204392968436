import React from 'react';
import '../../css/cyder/form/cyder-form.css';

const CyderForm = ({ title, description, children, className }) => (
    <div className={'cyder-form' + (className ? ' ' + className : '')}>
        <h3>{title}</h3>
        <form onSubmit={e => e.preventDefault()}>
            <div className="description">{description}</div>
            {children}
        </form>
    </div>
);

export default CyderForm;
