/**
 * Product renew terms and conditions page
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

// COMPONENTS
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Container } from 'reactstrap';
import BigForm from 'cyder/forms/BigForm';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import TermsAndConditions from '../shared/TermsAndConditions';

import { saveRenewCertApplication, getCertificateInfoById } from 'actions/member/certification/renew/memberProductRenewAction';
import { forceSetLoading } from 'actions/member/certification/applications/memberCertNewApplAction';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';
import { getRenewCertApplFromCertID } from 'actions/member/certification/applications/memberCertNewApplAction';

import { SGBC_S3_ROOT } from 'config';

class ProductRenewTermsConditionsPage extends React.Component {
    constructor(props) {
        super(props);
        this.agreeTc = this.agreeTc.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.downloadTermAndConditionsDocument = this.downloadTermAndConditionsDocument.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.state = {
            agreeTc: false,
            loading: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    getRelevantStuffs() {
        return new Promise(async (resolve, reject) => {
            try {
                this.showLoading(true);
                const certId = this.props.match.params.certId;
                const certificateInfo = await this.props.getCertificateInfoById(certId);
                let res = await this.props.getRenewData(certId);
                if (this._isMounted) {
                    this.setState({
                        productBrands: this.props.renew.productBrands,
                        certificateInfo: certificateInfo.data,
                    });
                }
                resolve(certificateInfo);
            } catch (error) {
                reject(error);
            } finally {
                this.showLoading(false);
            }
        });
    }
    constructBody(data) {
        return {
            productType: this.props.renew.selectedProductTypeId
                ? this.props.renew.selectedProductTypeId
                : data.productTypeId
                ? data.productTypeId
                : '',
            isManufacturer: this.props.renew.isManufacturer,
            productCatalogueFilename: this.props.renew.productCatalogueFilename,
            brands: this.props.renew.selectedProductTypeId ? JSON.stringify(this.props.renew.selectedProductTypeId) : data.brandJson,
            manufacturerDetails: this.props.renew.manufacturerDetails,
            productCatalogue: this.props.renew.productCatalogue,
            productPicture: this.props.renew.productPicture,
            productPictureFilename: this.props.renew.productPictureFilename,
            certificateNumberToRenewOrAmend: this.props.renew.certificateNumber,
            certId: this.props.match.params.certId,
            templateId: this.props.renew.templateId,
            assessmentId: this.props.renew.assessmentId,
            productCategoryId: this.props.renew.productCategoryId
                ? this.props.renew.productCategoryId
                : data.productCategoryId
                ? data.productCategoryId
                : '',
            manufacturerCountryId: this.props.renew.manufacturerCountryId,
            manufacturerCountryName: this.props.renew.manufacturerCountryName,
            manufacturerFactoryAddress: this.props.renew.manufacturerFactoryAddress,
            manufacturerNameOfCompany: this.props.renew.manufacturerNameOfCompany,
            manufacturerPostalCode: this.props.renew.manufacturerPostalCode,
            manufacturerTelephone: this.props.renew.manufacturerTelephone
                ? this.props.renew.manufacturerTelephone
                : data.mainContactNo
                ? data.mainContactNo
                : '',
            remarks: '',
            manufacturerContactPersonName: this.props.renew.manufacturerContactPersonName
                ? this.props.renew.manufacturerContactPersonName
                : data.mainContactPerson
                ? data.mainContactPerson
                : '',
            manufacturerNameOfCompanyPlant: this.props.renew.certification
                ? this.props.renew.certification.manufacturerNameOfCompanyPlant
                : '',
            manufacturerFactoryAddressPlant: this.props.renew.certification
                ? this.props.renew.certification.manufacturerFactoryAddressPlant
                : '',
            sameAsManufacturer: this.props.renew.certification ? this.props.renew.certification.sameAsManufacturer : '',
            greenRemarks: this.props.renew.greenRemarks,
        };
    }
    async changeStep(e, amount = 1) {
        const type = this.props.renew.type; //'product  or service'
        const idObj = {};

        this.showLoading(true);

        let { data } = await this.getRelevantStuffs();

        const body = {
            ...idObj,
            ...this.constructBody(data),
        };
        try {
            const res = await this.props.saveRenewCertApplication('product', body);
            if (res.data.id) {
                history.push(`/member/certification/product/renew/application/` + res.data.id);
                return;
            }
            this.showModal('error', 'Failed to start certification renewal', '', false, true);
            throw this.props.error;
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    agreeTc() {
        var state = this.state;
        state.agreeTc = !state.agreeTc;
        this.setState({
            state,
        });
    }
    async downloadTermAndConditionsDocument(type) {
        const { findByCategoryAndName, forceSetLoading } = this.props;
        try {
            forceSetLoading(true);
            let name = 'Certification Terms and Conditions';
            const res = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + res.data.resource;
            window.open(url);
        } catch (error) {
            this.showModal('Error', 'Term and Conditions is not available', null, false, true);
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <Container>
                    <br />
                    <BigForm title={'Product Certificate Renewal'} className="ml-2">
                        <TermsAndConditions
                            mode={`product`}
                            changeStep={this.changeStep}
                            downloadTermAndConditionsDocument={this.downloadTermAndConditionsDocument}
                            agreeTc={this.agreeTc}
                            agreeTcValue={this.state.agreeTc}
                            isRenew={true}
                        />
                    </BigForm>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        assessment: state.memberProductAssessment,
        application: state.memberProductApplication,
        review: state.memberProductReview,
        certifications: state.memberCertifications,
        renew: state.memberProductRenew,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        forceSetLoading: loadingState => {
            dispatch(forceSetLoading(loadingState));
        },
        getCertificateInfoById: certId => {
            return dispatch(getCertificateInfoById(certId));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
        getRenewData: certId => {
            return dispatch(getRenewCertApplFromCertID(certId));
        },
        saveRenewCertApplication: (type, body) => {
            return dispatch(saveRenewCertApplication(type, body, false));
        },
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductRenewTermsConditionsPage),
);
