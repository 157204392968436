import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Row, Col, Form, Button, Input } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { certificateRenewalOverviewAnalytics } from 'actions/analytics/certificateAnalyticsAction';
import CertificationRenewalDetailsPopup from 'pages/analytics/components/certification-renewal/CertificationRenewalDetailsPopup';

class CertificationRenewalList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);

        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load().then(res => {
            let total = 0;
            res.data.forEach(item => {
                if (item.status == 'Total') {
                    total = item.count;
                }
            });
            this.setState({
                data: res.data,
                total,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.certification.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.certification.refresh,
            });
        }
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Certificates Expiring/Expired within ${moment(this.props.certification.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.certification.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CertificationRenewalDetailsPopup
                            ref={instance => {
                                this.popup = instance;
                            }}
                            className={`modal-lg`}
                        />
                        <h2 style={{ color: '#78BE20' }}>Overview</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map(
                                        (item, i) =>
                                            item.status != null && (
                                                <tr key={i}>
                                                    <td style={{ width: '50%' }}>{item.status}</td>
                                                    <td style={{ width: '20%' }}>{item.count}</td>
                                                    <td style={{ width: '20%' }}>{Math.round((item.count * 100) / this.state.total)}</td>
                                                    <td style={{ width: '10%' }}>
                                                        {item.renewalStatusId != null && (
                                                            <ButtonIcon
                                                                icon={`remove_red_eye`}
                                                                onClick={e => {
                                                                    this.popup.show(
                                                                        item.status,
                                                                        item.renewalStatusId,
                                                                        this.props.certification.type,
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            ),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        certification: state.certificationRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(certificateRenewalOverviewAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationRenewalList));
