export const setReducerModalState = (newState, data) => {
    let stateToReturn = Object.assign({}, newState);
    stateToReturn.modalAction = data.modalAction;
    stateToReturn.modalBody = data.modalBody;
    stateToReturn.modalLoading = data.modalLoading;
    stateToReturn.modalOnlyOneButton = data.modalOnlyOneButton;
    stateToReturn.modalHeader = data.modalHeader;
    return stateToReturn;
};
export const defaultModalKeys = {
    modalAction: null,
    modalBody: null,
    modalOnlyOneButton: false,
    modalLoading: false,
};
