import React from 'react';
import { Grid, Segment, Button, Header, Label, Item, Form, Input } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import NoData from '../components/NoData';

class ServiceList extends React.Component {
    state = { rating: 4 };

    async componentDidMount() {
        const { handlers, match } = this.props;
        const { serviceCategoryId } = match.params;
        const { onFetchServices } = handlers;
        if (serviceCategoryId) onFetchServices(serviceCategoryId, 'all');
    }

    render() {
        let {
            services,
            ratings,
            states: { serviceCategoryId, ratingId, serviceCategoryName },
            loading,
            handlers,
            ratingOptions,
            ratingTicks,
            zcpRatingOption,
        } = this.props;
        if (serviceCategoryId == 2 || serviceCategoryId == 10) {
            if (ratings != null) ratings.push(zcpRatingOption);
        }
        const { onFetchServiceDetails, onFetchServices, onFilterRating, onHandleChange, onSearchSubmit } = handlers;
        return (
            <Grid stackable className={loading ? 'service-list loading' : 'service-list'}>
                <Grid.Column width={16}>
                    <Segment basic className="navigator">
                        <Grid className="found">
                            <Grid.Column width={4}>
                                <Header as="h3">
                                    {(services && services.length < 1) || !services
                                        ? 'No results found'
                                        : `Found ${services && services.length} ${services && services.length > 1 ? 'results' : 'result'}`}
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                {services && (
                                    <Form onSubmit={onSearchSubmit}>
                                        <Input
                                            fluid
                                            // icon={{ name: 'arrow right', circular: true, link: true }}
                                            size="large"
                                            name="keyword"
                                            onChange={onHandleChange}
                                            placeholder="Search from current result"
                                        />
                                    </Form>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    {serviceCategoryId != 1 && serviceCategoryId != 3 && serviceCategoryId != 4 && serviceCategoryId != 5 && (
                        <Segment basic className="filtering" style={{ paddingBottom: '0px' }}>
                            <Label.Group circular size="medium">
                                {ratings &&
                                    ratings.map(item => {
                                        return (
                                            <Label
                                                as="a"
                                                key={item.key}
                                                id={item.key}
                                                content={item.text}
                                                onClick={() => onFetchServices(serviceCategoryId, item.value, serviceCategoryName)}
                                                className={item.value === ratingId ? 'active ticks' : 'ticks'}
                                            />
                                        );
                                    })}
                            </Label.Group>
                        </Segment>
                    )}

                    <Item.Group relaxed="very" link>
                        {services && services.length > 0 ? (
                            services.map(item => {
                                const {
                                    contactPerson,
                                    email,
                                    firstName,
                                    lastName,
                                    contactNo,
                                    mainContactEmail,
                                    mainContactNo,
                                    mainContactPerson,
                                    validFrom,
                                    validTill,
                                } = item;
                                return (
                                    <Item
                                        as="a"
                                        key={item.serviceId}
                                        onClick={() => onFetchServiceDetails(item.companyId, item.serviceId, item.serviceCategoryId)}
                                    >
                                        <Item.Content>
                                            <Segment basic>
                                                <Header className="company">{item.companyName}</Header>
                                            </Segment>
                                            <Item.Description className="description-list">
                                                <Grid>
                                                    <Grid.Row columns={2}>
                                                        <Grid.Column>
                                                            <p>
                                                                Certification number: <strong>{item.certificateNumber}</strong>
                                                            </p>
                                                            <p>
                                                                Services category: <strong>{item.serviceCategory}</strong>
                                                            </p>
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <p>
                                                                Issued on: <strong>{validFrom || '-'}</strong>
                                                            </p>
                                                            <p>
                                                                Valid until: <strong>{validTill || '-'}</strong>
                                                            </p>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row columns={1} style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                                        <Grid.Column>
                                                            <hr style={{ marginBottom: '0px', marginTop: '0px' }} />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row columns={2}>
                                                        <Grid.Column>
                                                            <p>
                                                                Main Contact Person:{' '}
                                                                <strong>
                                                                    {mainContactPerson ? mainContactPerson : firstName + ' ' + lastName}
                                                                </strong>
                                                            </p>
                                                            {/* <p>
                                                                Main Person Email:{' '}
                                                                <strong>{mainContactPerson ? mainContactEmail : email}</strong>
                                                            </p> */}
                                                        </Grid.Column>
                                                        {/* <Grid.Column>
                                                            <p>
                                                                Main Contact Person No:{' '}
                                                                <strong>{mainContactPerson ? mainContactNo : contactNo}</strong>
                                                            </p>
                                                        </Grid.Column> */}
                                                    </Grid.Row>
                                                    {item.ratingId && (
                                                        <Grid.Row columns={1}>
                                                            <Grid.Column>
                                                                <Header className="ticks service">Rating: {ratingTicks(item)}</Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}
                                                </Grid>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                );
                            })
                        ) : (
                            <NoData header="No services found" />
                        )}
                    </Item.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

export default withRouter(ServiceList);
