import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';

import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';

import { getActiveMembersAnalytic, getExpiringMemberCountAnalytic } from 'actions/analytics/memberAnalyticsAction';

class SSGBCMembers extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {};
    }

    componentDidMount() {
        this.props.loadActive().then(res => {
            if (this._isMounted) {
                this.setState({
                    active: res.data[0],
                });
            }
        });

        this.props.loadExpiring().then(res => {
            if (this._isMounted) {
                this.setState({
                    expiring: res.data[0],
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const xs = {
            bigxs: 4,
            normalxs: 8,
        };
        return (
            <AnalyticsCard title={`SGBC Members`} height={100}>
                {this.state.active == null || this.state.expiring == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigTextWithNormalText
                            normalText={`Active`}
                            normalTextColor={`unset`}
                            bigText={this.state.active.allMemberCount}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Expired in ${this.state.expiring.prevYear}`}
                            normalTextColor={`unset`}
                            bigText={this.state.expiring.prevYearCount}
                            bigTextColor={`#ed5565`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Expiring in ${this.state.expiring.currentYear}`}
                            normalTextColor={`unset`}
                            bigText={this.state.expiring.currentYearCount}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Expiring in ${this.state.expiring.nextYear}`}
                            normalTextColor={`unset`}
                            bigText={this.state.expiring.nextYearCount}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Contacts per Company`}
                            normalTextColor={`unset`}
                            bigText={(this.state.active.allContacts / this.state.active.allMemberCount).toFixed(0)}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadActive: () => {
            return dispatch(getActiveMembersAnalytic());
        },
        loadExpiring: () => {
            return dispatch(getExpiringMemberCountAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SSGBCMembers));
