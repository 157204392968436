import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import CyderPaginationReactTable from 'cyder/table/CyderPaginationReactTable';
import { BrandDetailComponent } from 'pages/member/certification/applications/overview/MemberCertificationApplicationListPage';
import history from '../../../../../history';

const columnsStyle = {
    headerStyle: { whiteSpace: 'unset' },
    sortable: false,
    style: { whiteSpace: 'unset' },
};
const generateOpenLink = (type, id) => `/member/certification/${type.toLowerCase()}/application/${id}`;

const CertificatesApplication = props => {
    const { applications, type, activeTab } = props.state;
    let { loading } = props;
    if (props.tabId != activeTab) {
        return '';
    }
    const { getDataFunc } = props;
    const productColumns = [
        {
            Header: 'Brand & Model',
            accessor: 'brandJson',

            ...columnsStyle,
            Cell: props => {
                const plainBrand = props.original.brand != null ? props.original.brand : '-';
                try {
                    const products = props.value;
                    return products.length > 0 ? <BrandDetailComponent items={products} /> : plainBrand;
                } catch (error) {
                    return plainBrand;
                }
            },
        },
        {
            Header: 'Application Status',
            accessor: 'statusName',
            ...columnsStyle,
            Cell: props => (
                <Link style={{ color: 'unset' }} to={generateOpenLink(type, props.original.id)}>
                    {props.value === 'Accepted' ? 'Application Accepted' : props.value}
                </Link>
            ),
        },
    ];
    const serviceColumns = [
        {
            Header: 'Services Category',
            accessor: 'serviceCategoryName',
            ...columnsStyle,
            Cell: props => props.value || '-',
        },
        {
            Header: 'Application Status',
            accessor: 'statusName',
            ...columnsStyle,
            Cell: props => (
                <Link style={{ color: 'unset' }} to={generateOpenLink(type, props.original.id)}>
                    {props.value === 'Accepted' ? 'Application Accepted' : props.value}
                </Link>
            ),
        },
    ];
    return (
        <React.Fragment>
            <CyderPaginationReactTable
                loading={loading}
                columns={type === 'product' ? productColumns : serviceColumns}
                data={applications}
                getDataFunc={getDataFunc}
                pagination={false}
                filterable={false}
            />
            {applications != null && applications.length > 0 && (
                <Button
                    className="ml-1 mr-1 align-middle primary-btn-style"
                    onClick={() => {
                        if (type == 'product') {
                            history.push(`/member/certification/product/applications/all`);
                        } else {
                            history.push(`/member/certification/service/applications/all`);
                        }
                    }}
                >
                    <i className="material-icons align-text-bottom">chevron_right</i>
                    &nbsp;View All
                </Button>
            )}
        </React.Fragment>
    );
};
export default CertificatesApplication;
