// Overview
export const STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST = 'STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST';
export const STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS = 'STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS';
export const STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE = 'STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE';

// List of Existing Certifications
export const STAFF_CERT_APPL_LIST_FORCE_LOADING = 'STAFF_CERT_APPL_LIST_FORCE_LOADING';

// Product/Service Certification listing
export const STAFF_CERT_LIST_GET_INFO_REQUEST = 'STAFF_CERT_LIST_GET_INFO_REQUEST';
export const STAFF_CERT_LIST_GET_INFO_SUCCESS = 'STAFF_CERT_LIST_GET_INFO_SUCCESS';
export const STAFF_CERT_LIST_GET_INFO_FAILURE = 'STAFF_CERT_LIST_GET_INFO_FAILURE';

export const FETCH_XERO_INVENTORY_CODES = 'FETCH_XERO_INVENTORY_CODES';
export const FETCH_APPLICATION_LIST = 'FETCH_APPLICATION_LIST';
export const FETCH_NUMBER_OF_TICKS = 'FETCH_NUMBER_OF_TICKS';

export const FETCH_CERTIFICATION_RENEWALS = 'FETCH_CERTIFICATION_RENEWALS';
export const LOADING_CERTIFICATION = 'LOADING_CERTIFICATION';
export const FETCH_RENEWAL_DETAILS = 'FETCH_RENEWAL_DETAILS';
export const FETCH_RENEWAL_STATUS = 'FETCH_RENEWAL_STATUS';
export const FETCH_TERMINATION_REASON = 'FETCH_TERMINATION_REASON';
export const UPDATE_RENEWAL_STATUS = 'UPDATE_RENEWAL_STATUS';

export const ATTACH_PREVIOUS_TEMPLATE = 'ATTACH_PREVIOUS_TEMPLATE';
