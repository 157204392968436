import React from 'react';
import { connect } from 'react-redux';
import { setConfig } from '../../actions/config';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

class SecuringWrapper extends React.Component {
    render() {
        // console.log("this.props.profile: ", this.props.profile.user);
        // console.log("this.props.isCorporateAdmin: ", this.props.isCorporateAdmin, ", this.props.profile.isCorporateAdmin: ", this.props.profile.isCorporateAdmin);
        if (
            (this.props.isCertCoordinator && this.props.profile.user.is_cert_coordinator == 1) ||
            (this.props.isCorporateAdmin && this.props.profile.user.is_corporate_admin == 1) ||
            (this.props.isOmr && this.props.profile.user.is_omr == 1) ||
            (this.props.isStaff && this.props.profile.user.is_staff == 1) ||
            (this.props.isTeamMember && this.props.profile.user.is_team_member == 1) ||
            (this.props.isSecondarymr && this.props.profile.user.is_secondarymr == 1)
        ) {
            return this.props.children;
        } else {
            return '';
        }
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SecuringWrapper);
