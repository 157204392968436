import React from 'react';
import { Form } from 'reactstrap';

// Default placement
const spinnerStyle = {
    textAlign: 'center',
    width: '100%',
};

// For centering loading spinner
const spinnerStyle2 = {
    height: '100vh',
    bottom: '0',
    top: '0',
    left: '0',
};
const innerStyle = {
    position: 'relative',
    top: '45%',
    left: '50%',
    marginTop: '-25px',
};

const LoadingSpinner = ({ text, style, wrapperStyle, center }) => (
    <div style={wrapperStyle || (!center ? spinnerStyle : spinnerStyle2)}>
        <div style={center ? innerStyle : { justifyContent: 'center' }}>
            <i style={style} className="fa fa-2x fa-spin fa-spinner justify-content-center" />
            <span style={{ fontSize: '15px', paddingLeft: '20px', ...style }} className="text-bold mt-1">
                {text}
            </span>
        </div>
    </div>
);

export default LoadingSpinner;
