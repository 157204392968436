import fetchlib from '../../../js/fetchlib';
import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';

export function staffAccountsGetCompanyAccounts(keyword, country, hasOmr, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getCompanyAccountsByStaff',
        });

        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword,
            country,
            hasOmr,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}
