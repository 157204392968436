import React from 'react';
import Logo from '../logo';
import Search from './Search';
import ToggleLayout1 from './ToggleLayout1';
import ToggleLayout2 from './ToggleLayout2';
import DropdownTasks from './DropdownTasks';
import DropdownUser from './DropdownUser';
import '../../css/elements/navbar-1.css';
import './NavBar1.css';

import SGBCModulesDropdown from '../../cyder/menu/SGBCModulesDropdown';
import SGBCMessagesDropdown from '../../cyder/menu/SGBCMessagesDropdown';

const Navbar1 = () => (
    <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
        <Logo type={'non-member'} />
        <ToggleLayout1 />
        <ul className="nav nav-inline nav-inline-1">
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link">
                    <i className="material-icons">fullscreen</i>
                </a>
                <SGBCModulesDropdown />
            </li>
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link">
                    <i className="material-icons">rss_feed</i>
                </a>
                <DropdownTasks />
            </li>
            <li className="nav-item nav-item-dropdown">
                <a className="nav-link nav-link-badge">
                    <i className="material-icons">notifications_none</i>
                    <span className="badge badge-sm badge-rounded badge-danger">
                        3
                    </span>
                </a>
                <SGBCMessagesDropdown />
            </li>
        </ul>
        <div className="separator" />
        <Search />
        <ul className="nav nav-inline nav-inline-2">
            <li className="nav-item nav-item-dropdown last-item-navbar">
                <a className="nav-link nav-link-avatar">
                    <span className="badge badge-sm badge-rounded badge-warning">
                        1
                    </span>
                    <img
                        src="/assets/faces/m7.png"
                        className="rounded-circle"
                        alt="avatar"
                    />
                </a>
                <DropdownUser />
            </li>
        </ul>
        <ToggleLayout2 />
    </nav>
);

export default Navbar1;
