import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../../history';

import { Row, Col, Button, Badge, Form, FormGroup, Input } from 'reactstrap';

import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import moment from 'moment';

const columns = [
    {
        Header: 'Event',
        accessor: 'eventName',
        style: { whiteSpace: 'unset' },
        minWidth: 300,
        Cell: props => (props.value ? props.value : '-'),
    },
    {
        Header: 'Dates',
        accessor: 'eventStartDate',
        style: { whiteSpace: 'unset' },
        minWidth: 150,
        Cell: props => moment(props.value).format('D MMMM YYYY'),
    },
    {
        Header: 'Ended',
        accessor: 'eventStartDate',
        minWidth: 150,
        Cell: props => {
            const dateDiff = moment().diff(moment(props.value).format('D MMMM YYYY'), 'days');
            return <Badge color="grey">{'Ended ' + (isNaN(dateDiff) ? '0' : dateDiff) + ' days ago'}</Badge>;
        },
    },
    {
        Header: '',
        sortable: false,
        accessor: 'eventId',
        maxWidth: 100,
        Cell: props => {
            return (
                <Button size="sm" className="primary-btn-style" onClick={() => history.push('/staff/events/' + props.value + '/main')}>
                    <i className="material-icons align-text-bottom">add</i>
                    <br />
                    More
                </Button>
            );
        },
    },
];
const CompanyDetailsPageEventPanel = props => {
    return (
        <React.Fragment>
            {/* <Row>
                <Col xs={12} className="d-flex">
                    <h3>Events</h3>
                </Col>
            </Row>
            <br /> */}
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline float-left">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    id="keywordSearch"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            props.getEventAttendedAction();
                                        }
                                    }}
                                    placeholder="Keyword"
                                    value={props.keyword}
                                    onChange={props.handleInputChangeAction}
                                />
                            </FormGroup>
                            <Button className="primary-btn-style" onClick={() => props.getEventAttendedAction()}>
                                Search
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            classname="-highlight mb-2"
                            data={props.eventsList}
                            totalNum={props.totalNum}
                            columns={columns}
                            minRows={0}
                            defaultPageSize={20}
                            filterable={false}
                            pages={1}
                            loading={props.loading}
                            onFetchData={(state, instance) =>
                                props.getEventAttendedAction(state.page, state.pageSize, state.sorted, state.filtered)
                            }
                        />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};
export default CompanyDetailsPageEventPanel;
