import React from 'react';
import { connect } from 'react-redux';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import DashboardWidget from '../widget/DashboardWidget';

const VerticalBarChartOneBar = ({
    colors,
    items,
    title,
    description,
    firstKey,
    color,
}) => (
    <DashboardWidget title={title} description={description}>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                data={items}
                margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
            >
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip cursor={{ stroke: 'none', fill: 'none' }} />
                <Legend />
                <Bar dataKey={firstKey} fill={color} />
            </BarChart>
        </ResponsiveContainer>
    </DashboardWidget>
);

const mapStateToProps = (state, ownProps) => {
    return {};
};
export default connect(mapStateToProps)(VerticalBarChartOneBar);
