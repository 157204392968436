import React from 'react';
import history from '../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import ButtonIcon from 'cyder/button/ButtonIcon';

// UI FRAMEWORK
import { Row, Col } from 'reactstrap';

// SGBC CUSTOMS COMPONENTS
import DashboardWidget from 'cyder/widget/DashboardWidget2';
import PreviewPanel1 from 'cyder/staff/dashboard/PreviewPanel1';
import PreviewPanel2 from 'cyder/staff/dashboard/PreviewPanel2';
import PreviewPanel3 from 'cyder/staff/dashboard/PreviewPanel3';
import PreviewPanel4 from 'cyder/staff/dashboard/PreviewPanel4';
import PreviewPanel5 from 'cyder/staff/dashboard/PreviewPanel5';
import PreviewPanel6 from 'cyder/staff/dashboard/PreviewPanel6';
import PreviewPanel7 from 'cyder/staff/dashboard/PreviewPanel7';
import PreviewPanel8 from 'cyder/staff/dashboard/PreviewPanel8';
import PreviewPanel9 from 'cyder/staff/dashboard/PreviewPanel9';
import StaffCompanySearch from 'elements/navbar-staff/Search';

// ACTION
import {
    staffHomePanelCertGetInfo,
    fetchStaffDataDashboard,
    fetchStaffDataReviewListCount,
} from '../../../actions/staff/home/staffHomeAction';

const HomeButton = ({ btnProps, colStyle, onClick, label, check }) => {
    let buttonColor = null;
    let fontColor = null;
    if (check) {
        buttonColor = 'grey';
        fontColor = 'white';
    }
    return (
        <Row style={{ marginBottom: '5px' }}>
            <Col style={colStyle}>
                <ButtonIcon label={label} onClick={onClick} {...btnProps} buttonColor={buttonColor} fontColor={fontColor} />
            </Col>
        </Row>
    );
};

var buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
};
class Home extends React.Component {
    componentDidMount() {
        this.props.fetchStaffDataDashboard();
        this.props.fetchStaffDataReviewListCount();
    }
    render() {
        const {
            membersExpiring = '...',
            certificatesExpiring = '...',
            upcomingEvents = '...',
            membershipApplicationProcessing = '...',
            membershipApplicationPendingPayment = '...',
            onGoingProductAssesment = '...',
            onGoingServiceAssesment = '...',
            onGoingEvents = '...',
            eventsCompleted = '...',
        } = this.props;

        return (
            <div className="page-widget">
                <Row>
                    <Col className="p-1">
                        <div style={{ float: 'left', width: '300px' }}>
                            <StaffCompanySearch />
                        </div>
                    </Col>
                    <Row className="p-1 m-0">
                        {this.props.reviewListCount != null && this.props.reviewListCount > 0 && (
                            <MembershipApplStatus
                                action="/staff/certification/myreview"
                                label={`${this.props.reviewListCount || 0} Pending Review`}
                                submit={0}
                            />
                        )}
                        {this.props.membershipSubmittedCount != null && this.props.membershipSubmittedCount > 0 && (
                            <MembershipApplStatus
                                action="/staff/membership/applications"
                                label={`${this.props.membershipSubmittedCount || 0} Submitted Membership Application`}
                                submit={1}
                            />
                        )}
                        {this.props.certificateSubmittedCount != null && this.props.certificateSubmittedCount > 0 && (
                            <MembershipApplStatus
                                action="/staff/certification/applications"
                                label={`${this.props.certificateSubmittedCount || 0} Submitted Certification Application`}
                                submit={2}
                            />
                        )}
                    </Row>
                </Row>
                <div className="data-grid">
                    <Row>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#317446">
                                <PreviewPanel1 membersExpiring={membersExpiring} textColor="#FFF" />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#428156">
                                <PreviewPanel4 textColor="#FFF" membershipApplicationProcessing={membershipApplicationProcessing} />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#598a69">
                                <PreviewPanel7 textColor="#FFF" membershipApplicationPendingPayment={membershipApplicationPendingPayment} />
                            </DashboardWidget>
                        </Col>
                    </Row>
                    <Row className="pt-0">
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#9daf2c">
                                <PreviewPanel2 certificatesExpiring={certificatesExpiring} textColor="#FFF" />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#afc13d">
                                <PreviewPanel5 textColor="#FFF" onGoingProductAssesment={onGoingProductAssesment} />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#b4c25c">
                                <PreviewPanel8 textColor="#FFF" onGoingServiceAssesment={onGoingServiceAssesment} />
                            </DashboardWidget>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col
                            className="p-2"
                            xs={12}
                            lg={4}
                            onClick={() =>
                                window.location.pathname.toLowerCase() !== '/staff/events/all'
                                    ? history.push('/staff/events/all', { type: 'upcoming' })
                                    : history.replace('/staff/events/all', { type: 'upcoming' })
                            }
                        >
                            <DashboardWidget className="staff-card" bgcolor="#76777b">
                                <PreviewPanel3 upcomingEvents={upcomingEvents} textColor="#FFF" />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#858687">
                                <PreviewPanel6 onGoingEvents={onGoingEvents} textColor="#FFF" />
                            </DashboardWidget>
                        </Col>
                        <Col className="p-2" xs={12} lg={4}>
                            <DashboardWidget className="staff-card" bgcolor="#959596">
                                <PreviewPanel9 completedEvents={eventsCompleted} textColor="#FFF" />
                            </DashboardWidget>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const MembershipApplStatus = ({ action, label, submit }) => (
    <HomeButton
        colStyle={buttonStyle}
        onClick={() =>
            history.push(action, {
                membershipStatusSubmitted: submit === 1 ? 1 : null,
                certificateStatusSubmitted: submit === 2 ? 2 : null,
            })
        }
        label={label}
    />
);

const mapState = ({ staffHome }) => ({
    membersExpiring: staffHome.dashboard && staffHome.dashboard[0].membersExpiring,
    certificatesExpiring: staffHome.dashboard && staffHome.dashboard[1].certificatesExpiring,
    upcomingEvents: staffHome.dashboard && staffHome.dashboard[2].upcomingEvents,
    membershipApplicationProcessing: staffHome.dashboard && staffHome.dashboard[3].membershipApplicationProcessing,
    membershipApplicationPendingPayment: staffHome.dashboard && staffHome.dashboard[4].membershipApplicationPendingPayment,
    onGoingProductAssesment: staffHome.dashboard && staffHome.dashboard[5].onGoingProductAssesment,
    onGoingServiceAssesment: staffHome.dashboard && staffHome.dashboard[6].onGoingServiceAssesment,
    onGoingEvents: staffHome.dashboard && staffHome.dashboard[7].onGoingEvents,
    membershipSubmittedCount: staffHome.dashboard && staffHome.dashboard[8].membershipSubmittedCount,
    certificateSubmittedCount: staffHome.dashboard && staffHome.dashboard[9].certificateSubmittedCount,
    eventsCompleted: staffHome.dashboard && staffHome.dashboard.length >= 11 && staffHome.dashboard[10].eventsCompleted,
    reviewListCount: staffHome.reviewList,
});
export default connect(
    mapState,
    { staffHomePanelCertGetInfo, fetchStaffDataDashboard, fetchStaffDataReviewListCount },
)(StoredLayout(Home));
