/**
 * Audit Action
 *
 * Author: Fernando
 */
import { SGBC_API_CORS_ROOT } from 'config';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';

export function getAuditList(contentTypeId, objectId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/misc_v1/cn/audit`, {
            action: 'find-by-content-type-and-object',
        });
        const body = {
            contentTypeId,
            objectId,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
