import {
    MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS,
    MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE,
    MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE,
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST,
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS,
    MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS,
    MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID,
    MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED,
} from '../../../actions/constants/actionTypesMemberCertification';
import { setReducerModalState, defaultModalKeys } from 'js/modal';
import { CommentActions } from 'semantic-ui-react';

const defaultState = {
    loading: false,
    validcertifications: [],
    expiredcertifications: [],
    validTablePages: -1,
    expiredTablePages: -1,
    remarks: '',
    ...defaultModalKeys,
};

export default function memberCertificationsListReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_CERTIFICATION_LIST_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS:
            if (action.response.data[0].tablestatus === 'valid') {
                newState.validcertifications = action.response.data;
            } else if (action.response.data[0].tablestatus === 'expired') {
                newState.expiredcertifications = action.response.data;
            }
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_VALID:
            newState.validcertifications = action.response.data;
            newState.loading = false;
            newState.validTablePages = action.response.totalNoOfPages;
            newState.validTableItems = action.response.totalNoOfItems;
            return newState;
        case MEMBER_CERTIFICATION_LIST_GET_INFO_SUCCESS_EXPIRED:
            newState.expiredcertifications = action.response.data;
            newState.loading = false;
            newState.expiredTablePages = action.response.totalNoOfPages;
            newState.expiredTableItems = action.response.totalNoOfItems;
            return newState;
        case MEMBER_CERTIFICATION_LIST_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_LIST_ON_SUBMIT_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_LIST_ON_SUBMIT_SUCCESS:
            newState.loading = false;
            newState.modalAction = '/member/certification/product/certificates';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Request for review has been successfully submitted.';
            return newState;
        case MEMBER_CERTIFICATION_SERVICE_LIST_ON_SUBMIT_SUCCESS:
            newState.loading = false;
            newState.modalAction = '/member/certification/service/certificates';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Request for review has been successfully submitted.';
            return newState;
        case MEMBER_CERTIFICATION_LIST_ON_SUBMIT_FAILURE:
            newState.loading = false;
            newState.modalAction = '/member/certification/product/certificates';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to submit request for review. Please try again later.';
            return newState;
        case MEMBER_CERTIFICATION_LIST_HANDLE_VALUE_CHANGE:
            newState.remarks = action.value;
            return newState;
        case MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_SUCCESS:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_LIST_PRINT_CERTIFICATE_FAILURE:
            newState.loading = false;
            return newState;
        default:
            return newState;
    }
}
