import React from 'react';

import { Row, Col } from 'reactstrap';
import IconWidget6 from 'icon-widgets/IconWidget6';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

const AllApplicationsSummary = ({
    title,
    link,
    applNew,
    applInvoice,
    applPaidAndFinalisation,
    applPayment,
    applAssessment,
    applIssued,
    applTotal,
}) => {
    const commonProps = {
        height: 70,
        color: 'white',
        bgStyle: { backgroundColor: '#428156' },
    };
    const widgetData = [
        {
            text: 'Submitted',
            number: applNew,
            ...commonProps,
        },
        {
            text: 'In-progress',
            number: applAssessment,
            ...commonProps,
        },
        {
            text: 'Invoice',
            number: applInvoice,
            ...commonProps,
        },
        {
            text: 'Paid & Finalisation',
            number: applPaidAndFinalisation,
            ...commonProps,
        },
    ];
    const prefix = title;
    return (
        <NoShadowPanel title={prefix} borderSize={2} flat>
            <Row>
                <Col>
                    <Row style={{ paddingBottom: '10px' }}>
                        {widgetData.map((item, i) => (
                            <Col key={i}>
                                <IconWidget6 {...item} link={link} />
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </NoShadowPanel>
    );
};

export default AllApplicationsSummary;
