import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AffiliatesPanel from './components/AffiliatesPanel';
import { capitalizeFirstLetter } from 'js/util';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';
import TopNavigation2 from '../../layouts/TopNavigation2';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';

import {
    memberHomePanelEventGetInfo,
    memberHomeSetLoadingState,
} from '../../actions/member/home/memberHomeAction';

class DirectoryPage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return this.props.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <AboutPanel />
                <CardsPanel
                    dtype={this.props.match.params.dtype}
                    cat={this.props.match.params.cat}
                />
                <AffiliatesPanel />
            </div>
        );
    }
}

const urlPrefix = '/public/directory';
// replace this with list of product categories
const cards = [
    {
        category: 'Adhesives, Sealants & Additives',
        count: 750,
        url: '1',
    },
    {
        category: 'Carpark Accessories',
        count: 150,
        url: '2',
    },
    {
        category: 'Ceiling Coverings',
        count: 450,
        url: '3',
    },
    {
        category: 'Decorative Coverings',
        count: 450,
        url: '4',
    },
    {
        category: 'Door System',
        count: 450,
        url: '5',
    },
    {
        category: 'Drymix',
        count: 450,
        url: '6',
    },
];
const CardsPanel = ({ dtype, cat }) => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            {cards.map((c, i) => (
                <Col key={i} xs={12} md={4} className="mb-2">
                    <Card className="text-center">
                        <CardBody>
                            <p>
                                <Link
                                    to={`${urlPrefix}/${dtype}/${cat}/${c.url}`}
                                >
                                    {c.category}
                                </Link>
                            </p>
                            <p>
                                {`${c.count} Certified ${capitalizeFirstLetter(
                                    dtype,
                                )}`}
                            </p>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
);
const AboutPanel = () => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            <Col xs={12} sm={12} className="text-center">
                <h2>SGBC Directory</h2>
            </Col>
        </Row>
    </Container>
);

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        const getData = [dispatch(memberHomePanelEventGetInfo())];
        dispatch(memberHomeSetLoadingState(true)).then(() => {
            Promise.all(getData).then(() => {
                dispatch(memberHomeSetLoadingState(false));
            });
        });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(DirectoryPage));
