import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle } from 'reactstrap';

import '../../css/cyder/widget/dashboard-widget.css';

const DashboardWidget = ({ minHeight, bgcolor, bg, children, gradientbg, color1, color2, title, className }) => {
    const commonStyle = {
        minHeight: minHeight || '200px',
        height: '100%',
    };
    return (
        <Card
            className={className}
            style={
                gradientbg
                    ? {
                          background: 'linear-gradient(to right, ' + color1 + ', ' + color2 + ')',
                      }
                    : bg
                    ? {
                          ...commonStyle,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundImage: "url('" + bg + "')",
                      }
                    : {
                          ...commonStyle,
                          backgroundColor: bgcolor || null,
                      }
            }
        >
            {title ? (
                <CardTitle className="p-3 py-5">
                    <strong>{title}</strong>
                </CardTitle>
            ) : null}
            <CardBody style={{ height: '100%' }}>
                <div>{children}</div>
            </CardBody>
        </Card>
    );
};

DashboardWidget.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
};

export default DashboardWidget;
