/**
 * Product Application view page
 *
 * Author: Fernando Karnagi
 */

import TopNavigationLayout from 'layouts/TopNavigationLayout';
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import { Row, Col } from 'reactstrap';
import BigForm from 'cyder/forms/BigForm';
import CyderApplicationStatusPanel from 'cyder/panels/CyderApplicationStatusPanel';

import ProductDetailsPanel from './components/ProductDetailsPanel';

const ApplicationStatus = ({ visible, status, step }) => {
    if (visible) {
        return (
            <div>
                <Row>
                    <Col>
                        <CyderApplicationStatusPanel status={status} step={step} />
                    </Col>
                </Row>
            </div>
        );
    } else {
        return '';
    }
};

class ApplicationViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
        };
    }
    viewAssessment() {}
    render() {
        return (
            <div>
                <BigForm title="Product Certification Application" description="">
                    <div>
                        <ApplicationStatus
                            visible={true}
                            status={this.props.application.view.status}
                            step={this.props.application.view.step}
                        />
                        <ProductDetailsPanel
                            visible={true}
                            finalRating={this.props.application.view.finalRating}
                            greenRemarks={this.state.applicationDetails.data.greenRemarks}
                            viewAssessment={e => {}}
                            newFuc={() => {}}
                            selectState={this.state}
                            productBrands={this.props.application.new.productBrands}
                            status={this.props.application.view.status}
                            submittedOn={this.props.application.view.submittedOn}
                            certificate={this.props.certifications.productCertificate}
                            title={`Application ID : ${this.props.application.view.applicationNo}`}
                        />
                    </div>
                </BigForm>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberProductApplication,
        assessment: state.memberProductAssessment,
        certifications: state.memberCertifications,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationViewPage),
);
