/**
 * Survey reducer
 *
 * Author: Fernando Karnagi
 */
const moment = require('moment');
// defaults
const defaultState = {
    control: {
        loading: false,
        keyword: '',
        pageSize: 10,
        showPagination: true,
        status: {
            value: 'Current',
            label: 'Current',
        },
        surveySaveSuccess: true,
        surveySaveModal: {
            message: '',
            header: '',
            show: false,
        },
        surveyDeleteSuccess: true,
        surveyDeleteModal: {
            message: '',
            header: '',
            show: false,
        },
        dashboard: [
            {
                bgStyle: {
                    backgroundColor: '#428156',
                },
                color: 'white',
                headerFontSize: '16',
                text: 'Membership',
                number: '...',
                surveyCategoryId: 2,
                remarks: 'active surveys',
            },
            {
                bgStyle: {
                    backgroundColor: '#89aa49',
                },
                color: 'white',
                headerFontSize: '16',
                text: 'Events & Training',
                number: '...',
                surveyCategoryId: 3,
                remarks: 'active surveys',
            },
            {
                bgStyle: {
                    backgroundColor: '#858687',
                },
                color: 'white',
                text: 'Certification',
                headerFontSize: '16',
                number: '...',
                surveyCategoryId: 4,
                remarks: 'active surveys',
            },
            {
                bgStyle: {
                    backgroundColor: '#646565',
                },
                color: 'white',
                headerFontSize: '16',
                text: 'SGBC',
                number: '...',
                surveyCategoryId: 5,
                remarks: 'active surveys',
            },
            {
                bgStyle: {
                    backgroundColor: '#428156',
                },
                color: 'white',
                headerFontSize: '16',
                text: 'All',
                number: '...',
                surveyCategoryId: null,
                remarks: 'active surveys',
            },
        ],
    },
    data: {
        surveyCategory: '',
        surveyCategoryId: null,
        surveys: [],
        totalSurveyPages: 0,
        totalSurveyCount: 0,
        selectedSurveyId: 0,
        currentQuestion: {
            id: 0,
            surveyId: 0,
            questionTypeId: 2,
            questionText: '',
            questionOptions: [],
        },
        survey: {
            id: 0,
            surveyCategoryId: null,
            surveyName: '',
            surveyDescription: '',
            startedOn: '10/01/2018',
            finishedOn: '20/02/2019',
            questions: [
                {
                    id: 0,
                    surveyId: 0,
                    questionTypeId: 0,
                    questionText: '',
                    questionOptions: [],
                },
            ],
        },
    },
};

export default function staffSurveyReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'STAFF_SURVEY_RESET_QUESTION':
            newState.data = state.data;
            newState.data.currentQuestion = {
                id: 0,
                surveyId: 0,
                questionTypeId: 2,
                questionText: '',
                questionOptions: [],
            };

            return newState;
        case 'STAFF_SURVEY_SET_SELECTEDSURVEYID':
            newState.data = Object.assign({}, state.data);
            newState.data.selectedSurveyId = action.selectedSurveyId;
            return newState;
        case 'STAFF_SURVEY_SHOW_DELETE_MODAL':
            newState.control = Object.assign({}, state.control);
            newState.control.surveyDeleteModal = Object.assign({}, state.control.surveyDeleteModal);
            newState.control.surveyDeleteModal.message = action.message;
            newState.control.surveyDeleteModal.header = action.header;
            newState.control.surveyDeleteModal.show = action.show;
            return newState;
        case 'STAFF_SURVEY_TOGGLE_DELETE_MODAL':
            newState.control = Object.assign({}, state.control);
            newState.control.surveyDeleteModal = Object.assign({}, state.control.surveyDeleteModal);
            newState.control.surveyDeleteModal.show = !newState.control.surveyDeleteModal.show;
            return newState;
        case 'STAFF_SURVEY_SHOW_SAVE_MODAL':
            newState.control = Object.assign({}, state.control);
            newState.control.surveySaveModal = Object.assign({}, state.control.surveySaveModal);
            newState.control.surveySaveModal.message = action.message;
            newState.control.surveySaveModal.header = action.header;
            newState.control.surveySaveModal.show = action.show;
            return newState;
        case 'STAFF_SURVEY_TOGGLE_SAVE_MODAL':
            newState.control = Object.assign({}, state.control);
            newState.control.surveySaveModal = Object.assign({}, state.control.surveySaveModal);
            newState.control.surveySaveModal.show = !newState.control.surveySaveModal.show;
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY':
            newState.data = Object.assign({}, state.data);
            console.log('RIGHTHERE', state.data);
            newState.data.survey = action.survey;
            return newState;
        case 'STAFF_SURVEY_SET_DELETE_STATUS':
            newState.control = Object.assign({}, state.control);
            if (action.error) {
                newState.control.surveyDeleteSuccess = false;
            } else {
                newState.control.surveyDeleteSuccess = true;
            }
            return newState;
        case 'STAFF_SURVEY_SET_SAVE_STATUS':
            newState.control = Object.assign({}, state.control);
            if (action.error) {
                newState.control.surveySaveSuccess = false;
            } else {
                newState.control.surveySaveSuccess = true;
            }
            return newState;
        case 'STAFF_SURVEY_REMOVE_QUESTION':
            const questionId = action.id;
            newState.data = Object.assign({}, state.data);
            newState.data.survey = Object.assign({}, state.data.survey);
            newState.data.survey.questions = newState.data.survey.questions.filter(item => item.id !== questionId);
            return newState;
        case 'STAFF_SURVEY_ADD_QUESTION':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = Object.assign({}, state.data.survey);
            newState.data.survey.questions = state.data.survey.questions;
            var questionToAdd = Object.assign({}, state.data.currentQuestion);
            questionToAdd.id = state.data.survey.questions.length;
            newState.data.survey.questions.push(questionToAdd);
            newState.data.currentQuestion = {
                id: 0,
                surveyId: 0,
                questionTypeId: 2,
                questionText: '',
                questionOptions: [],
            };
            return newState;
        case 'STAFF_SURVEY_ADD_OPTION':
            newState.data = Object.assign({}, state.data);
            var optionToAdd = {
                id: state.data.currentQuestion.questionOptions.length,
                text: '',
            };
            newState.data.currentQuestion = Object.assign({}, state.data.currentQuestion);
            newState.data.currentQuestion.questionOptions = Object.assign([], state.data.currentQuestion.questionOptions);
            newState.data.currentQuestion.questionOptions.push(optionToAdd);
            return newState;
        case 'STAFF_SURVEY_REMOVE_OPTION':
            const optionId = action.id;
            newState.data = Object.assign({}, state.data);
            newState.data.currentQuestion = Object.assign({}, state.data.currentQuestion);
            newState.data.currentQuestion.questionOptions = newState.data.currentQuestion.questionOptions.filter(
                item => item.id !== optionId,
            );
            return newState;
        case 'STAFF_SURVEY_CHANGE_QUESTION_TYPE':
            newState.data = Object.assign({}, state.data);
            newState.data.currentQuestion = state.data.currentQuestion;
            newState.data.currentQuestion.questionTypeId = action.questionTypeId;
            return newState;
        case 'STAFF_SURVEY_CHANGE_QUESTIONTEXT':
            newState.data = Object.assign({}, state.data);
            newState.data.currentQuestion = state.data.currentQuestion;
            newState.data.currentQuestion.questionText = action.questionText;
            return newState;
        case 'STAFF_SURVEY_CHANGE_STARTEDON':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = Object.assign({}, state.data.survey);
            try {
                newState.data.survey.startedOn = action.startedOn.format('DD/MM/YYYY');
            } catch (e) {}
            return newState;
        case 'STAFF_SURVEY_CHANGE_FINISHEDON':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = Object.assign({}, state.data.survey);
            try {
                newState.data.survey.finishedOn = action.finishedOn.format('DD/MM/YYYY');
            } catch (e) {}
            return newState;
        case 'STAFF_SURVEY_CHANGE_QUESTIONTEXT':
            newState.data = Object.assign({}, state.data);
            newState.data.currentQuestion = state.data.currentQuestion;
            newState.data.currentQuestion.questionText = action.questionText;
            return newState;
        case 'STAFF_SURVEY_CHANGE_OPTIONTEXT':
            newState.data = Object.assign({}, state.data);
            newState.data.currentQuestion = Object.assign({}, state.data.currentQuestion);
            var optionText = action.optionText;
            var id = action.id;
            newState.data.currentQuestion.questionOptions[id] = Object.assign({}, newState.data.currentQuestion.questionOptions[id]);
            newState.data.currentQuestion.questionOptions[id].text = optionText;
            return newState;
        case 'STAFF_SURVEY_CHANGE_SURVEYNAME':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = state.data.survey;
            newState.data.survey.surveyName = action.surveyName;
            return newState;
        case 'STAFF_SURVEY_CHANGE_SURVEYDESCRIPTION':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = state.data.survey;
            newState.data.survey.surveyDescription = action.surveyDescription;
            return newState;
        case 'STAFF_SURVEY_CHANGE_SURVEY_CATEGORY':
            newState.data = Object.assign({}, state.data);
            newState.data.survey.surveyCategoryId = action.surveyCategoryId;
            return newState;
        case 'STAFF_SURVEY_SET_KEYWORD':
            newState.control = Object.assign({}, state.control);
            newState.control.keyword = action.keyword;
            return newState;
        case 'STAFF_SURVEY_SET_STATUS':
            newState.control = Object.assign({}, state.control);
            newState.control.status = action.status;
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY_CATEGORY':
            newState.data = Object.assign({}, state.data);
            newState.data.surveyCategory = action.surveyCategory.text;
            newState.data.surveyCategoryId = action.surveyCategory.surveyCategoryId;
            return newState;
        case 'STAFF_SURVEY_SET_DASHBOARD_VALUE_REQUEST':
            return newState;
        case 'STAFF_SURVEY_SET_DASHBOARD_VALUE_SUCCESS':
            newState.control = Object.assign({}, state.control);
            newState.control.dashboard = Object.assign({}, state.control.dashboard);
            newState.control.dashboard[0] = Object.assign({}, newState.control.dashboard[0]);
            newState.control.dashboard[0].number = action.response.data.membership;

            newState.control.dashboard[1] = Object.assign({}, newState.control.dashboard[1]);
            newState.control.dashboard[1].number = action.response.data.event;

            newState.control.dashboard[2] = Object.assign({}, newState.control.dashboard[2]);
            newState.control.dashboard[2].number = action.response.data.certification;

            newState.control.dashboard[3] = Object.assign({}, newState.control.dashboard[3]);
            newState.control.dashboard[3].number = action.response.data.sgbc;

            newState.control.dashboard[4] = Object.assign({}, newState.control.dashboard[4]);
            newState.control.dashboard[4].number = action.response.data.all;
            return newState;
        case 'STAFF_SURVEY_SET_DASHBOARD_VALUE_FAILURE':
            newState.data = Object.assign({}, state.data);
            return newState;
        case 'STAFF_SURVEY_SET_DASHBOARD_VALUE_REQUEST':
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY_VALUE_REQUEST':
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY_VALUE_SUCCESS':
            newState.data = Object.assign({}, state.data);
            newState.data.survey = action.response.data;
            newState.data.survey.surveyCategoryId = parseInt(newState.data.survey.surveyCategoryId);
            // newState.data.surveyCategoryId = newState.data.survey.surveyCategoryId;
            // try {
            //     if (newState.data.surveyCategoryId === 2) {
            //         newState.data.surveyCategory = 'Membership';
            //     } else if (newState.data.surveyCategoryId === 3) {
            //         newState.data.surveyCategory = 'Events & Training';
            //     } else if (newState.data.surveyCategoryId === 4) {
            //         newState.data.surveyCategory = 'Certification';
            //     } else if (newState.data.surveyCategoryId === 5) {
            //         newState.data.surveyCategory = 'SGBC';
            //     }
            // } catch (e) {}

            newState.data.survey.startedOn = moment(newState.data.survey.startOn).format('DD/MM/YYYY');
            newState.data.survey.finishedOn = moment(newState.data.survey.finishedOn).format('DD/MM/YYYY');
            try {
                newState.data.survey.questions.forEach(question => {
                    question.questionOptions = JSON.parse(question.questionOptions);
                    var optionIndex = 0;
                    question.questionOptions.forEach(option => {
                        optionIndex++;
                        var tempOption = option;
                        option = {
                            id: optionIndex,
                            text: tempOption,
                        };
                        question.questionOptions[optionIndex - 1] = option;
                    });
                });
            } catch (e) {}
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY_VALUE_FAILURE':
            return newState;
        case 'STAFF_SURVEY_SET_SURVEY_PAGESIZE':
            newState.control = Object.assign({}, state.control);
            newState.control.pageSize = action.pageSize;
            return newState;
        case 'STAFF_SURVEY_SET_SURVEYS_VALUE_REQUEST':
            newState.control = Object.assign({}, state.control);
            newState.control.loading = true;
            newState.control.showPagination = false;
            return newState;
        case 'STAFF_SURVEY_SET_SURVEYS_VALUE_SUCCESS':
            newState.data = Object.assign({}, state.data);
            newState.data.surveys = action.response.data;
            newState.data.surveys.forEach(element => {
                try {
                    let startMoment = moment(element.startedOn, 'YYYY-MM-DD HH:mm:ss');
                    let finishMoment = moment(element.finishedOn, 'YYYY-MM-DD HH:mm:ss');
                    element.period = startMoment.format('DD/MM/YYYY') + ' - ' + finishMoment.format('DD/MM/YYYY');
                } catch (e) {}
            });

            newState.data.totalSurveyPages = action.response.totalNoOfPages;
            newState.data.totalSurveyCount = action.response.totalNoOfItems;
            newState.control = Object.assign({}, state.control);
            newState.control.loading = false;

            if (newState.data.totalSurveyCount <= newState.control.pageSize) {
                newState.control.showPagination = false;
            } else {
                newState.control.showPagination = true;
            }
            return newState;
        case 'STAFF_SURVEY_SET_SURVEYS_VALUE_FAILURE':
            newState.data = Object.assign({}, state.data);
            newState.data.surveys = [];
            newState.data.totalSurveyPages = 0;
            newState.control = Object.assign({}, state.control);
            newState.control.loading = false;
            newState.control.showPagination = false;

            return newState;

        default:
            return state;
    }
}
