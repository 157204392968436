import React from 'react';
import QuestionaireComponent from 'pages/member/membership/new/components/QuestionaireComponent';
import MembershipPreview from 'pages/member/membership/new/components/MembershipPreview';
// import Confirmation from 'pages/member/membership/new/components/Confirmation';

import { Row, Col, Card, CardBody } from 'reactstrap';

import 'css/ui-elements/modals.css';

// SGBC custom components

const PreviewApplication = ({ recommendedBy, othersState, answersState, selectedPackage, isNewMember, questions }) => (
    <React.Fragment>
        <Row>
            <Col xs={12}>
                <MembershipPreview selectedPackage={selectedPackage} />
            </Col>
        </Row>
        {isNewMember && (
            <React.Fragment>
                <br />
                <Row>
                    <Col xs={12}>
                        <QuestionaireComponent preview={true} questions={questions} othersState={othersState} answersState={answersState} />
                    </Col>
                </Row>
                {/* <Row>
                    <Col xs={12}>
                        <Confirmation selectedPackage={selectedPackage} />
                    </Col>
                </Row> */}
            </React.Fragment>
        )}
    </React.Fragment>
);
export default PreviewApplication;
