import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';

import { Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputField from 'pages/admin/companies/shared/InputField';
import Select from 'react-select';
import { save, update, remove, load } from 'actions/admin/memberships/membership-packages/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.isNewPackageChange = this.isNewPackageChange.bind(this);
        this.itemCodeChange = this.itemCodeChange.bind(this);
        this.lengthOfValidityInMonthsChange = this.lengthOfValidityInMonthsChange.bind(this);
        this.packageNameChange = this.packageNameChange.bind(this);
        this.priceChange = this.priceChange.bind(this);
        this.idChange = this.idChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            data: {
                isNewPackage: {
                    label: 'Yes',
                    value: 1,
                },
                itemCode: '',
                lengthOfValidityInMonths: 12,
                packageName: '',
                price: 0,
                id: '',
            },
            isNewPackagesOptions: [
                {
                    label: 'Yes',
                    value: 1,
                },
                {
                    label: 'No',
                    value: 0,
                },
            ],
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/memberships/membership-packages');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    this.showLoading(false);
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        if (res.data.isNewPackage == 1) {
                            res.data.isNewPackage = { label: 'Yes', value: 1 };
                        } else {
                            res.data.isNewPackage = { label: 'No', value: 0 };
                        }
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    isNewPackage: 1,
                    itemCode: '',
                    lengthOfValidityInMonths: 12,
                    packageName: '',
                    price: 0,
                    id: '',
                },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.isNewPackage = data.isNewPackage.value;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/memberships/membership-packages');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/memberships/membership-packages', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/memberships/membership-packages');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }

    isNewPackageChange(e) {
        var data = this.state.data;
        data.isNewPackage = e;
        this.setState({ data });
    }
    itemCodeChange(e) {
        var data = this.state.data;
        data.itemCode = e.target.value;
        this.setState({ data });
    }
    lengthOfValidityInMonthsChange(e) {
        var data = this.state.data;
        data.lengthOfValidityInMonths = e.target.value;
        this.setState({ data });
    }
    packageNameChange(e) {
        var data = this.state.data;
        data.packageName = e.target.value;
        this.setState({ data });
    }
    priceChange(e) {
        var data = this.state.data;
        data.price = e.target.value;
        this.setState({ data });
    }

    statusChange(e) {
        var data = this.state.data;
        data.status = e.target.value;
        this.setState({ data });
    }

    idChange(e) {
        var data = this.state.data;
        data.id = e.target.value;
        this.setState({ data });
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <InputField
                        label="ID"
                        inputProps={{
                            type: 'text',
                            id: 'id',
                            disabled: true,
                            value: this.state.data.id,
                            style: { width: '100px' },
                        }}
                    />
                    <InputField
                        label="Package Name"
                        inputProps={{
                            type: 'text',
                            id: 'packageName',
                            value: this.state.data.packageName,
                            onChange: this.packageNameChange,
                            style: { width: '600px' },
                        }}
                    />
                    <InputField label="Is a package for new membership applications">
                        <div style={{ width: '150px' }}>
                            <Select
                                style={{ width: '150px' }}
                                autosize={false}
                                options={this.state.isNewPackagesOptions}
                                value={this.state.data.isNewPackage}
                                clearable={true}
                                onChange={this.isNewPackageChange}
                                isMulti={false}
                                name="selected-state"
                                disabled={false}
                                searchable={true}
                            />
                        </div>
                        {/* <Label>
                            <small>Select category</small>
                        </Label> */}
                    </InputField>
                    {/* <InputField
                        label="Is a package for new membership applications"
                        inputProps={{
                            type: 'text',
                            id: 'isNewPackage',
                            value: this.state.data.isNewPackage,
                            onChange: this.isNewPackageChange,
                            style: { width: '600px' },
                        }}
                    /> */}
                    <InputField
                        label="Length in validity in months"
                        inputProps={{
                            type: 'text',
                            id: 'lengthOfValidityInMonths',
                            value: this.state.data.lengthOfValidityInMonths,
                            onChange: this.lengthOfValidityInMonthsChange,
                            style: { width: '50px' },
                        }}
                    />
                    <InputField
                        label="Xero Item Code"
                        inputProps={{
                            type: 'text',
                            id: 'itemCode',
                            value: this.state.data.itemCode,
                            onChange: this.itemCodeChange,
                            style: { width: '300px' },
                        }}
                    />
                    <InputField
                        label="Price (w/o GST)"
                        inputProps={{
                            type: 'text',
                            id: 'price',
                            value: this.state.data.price,
                            onChange: this.priceChange,
                            style: { width: '100px' },
                        }}
                    />
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2} className="d-flex justify-content-end">
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                                <div style={{ paddingLeft: 5 }}>
                                    <AuditListPopup contentTypeId={22} objectId={this.state.data.id} />
                                </div>
                            </FlexCol>
                        ) : null}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
