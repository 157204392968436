/**
 * Action
 */
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function search(keyword, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-type`, {
            action: 'search',
        });

        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            keyword: keyword,
            sorted,
        };

        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-type`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function save(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-type`, {
            action: 'create',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function update(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-type`, {
            action: 'update',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function remove(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/survey_v1/cn/question-type`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: id }, null, null, null);
    };
}
