import React from 'react';
import { Grid, Image, Segment, Header, List, Item, Icon, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { fetchCompanyInfo } from '../../../actions/public';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';
import { mobile } from '../dummy';

class CompanyInfo extends React.Component {
    componentDidMount() {
        this.props.fetchCompanyInfo(this.props.companyId);
    }

    render() {
        const { companyInfo, certificateInfo, history } = this.props;
        const website = companyInfo && companyInfo.website && 'http://' + companyInfo.website;
        const logo = companyInfo && companyInfo.logo && companyInfo.logo !== '';

        // console.log("companyInfo: ", companyInfo);
        return (
            <Grid stackable className="company-info">
                {/* {companyInfo && companyInfo.logo && <Segment basic>
                    <Grid columns={mobile ? 1 : 3}>
                        <Grid.Column>
                            <img src={SGBC_S3_ROOT + companyInfo.logo} width={'100%'} />
                        </Grid.Column>
                    </Grid>
                </Segment>} */}

                <Grid.Row>
                    <Grid.Column>
                        <Segment basic className="navigator" style={{ paddingLeft: '3em' }}>
                            <Button basic icon="chevron left" content="Back" onClick={history.goBack} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Column width={4} className="company-logo">
                    <Image src={logo ? logo : 'https://react.semantic-ui.com/images/wireframe/image.png'} size="small" circular />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Segment basic className="details">
                        <Header as="h3">
                            <Icon name="building outline" />
                            Company details
                        </Header>
                        <Grid stackable>
                            <Grid.Column width={8}>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Company name</List.Header>
                                        {companyInfo && companyInfo.name}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Business address</List.Header>
                                        {companyInfo && companyInfo.business_address_line_1}
                                        {companyInfo && companyInfo.business_address_line_2}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Country</List.Header>
                                        {companyInfo && companyInfo.countryName ? companyInfo.countryName : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <List>
                                    <List.Item>
                                        <List.Header>Phone number</List.Header>
                                        {companyInfo && companyInfo.main_phone_number ? companyInfo.main_phone_number : '-'}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Website</List.Header>
                                        {companyInfo && companyInfo.website ? (
                                            <a href={'//' + companyInfo.website} target="_blank">
                                                {companyInfo.website}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>General email</List.Header>
                                        {companyInfo && companyInfo.general_email ? companyInfo.general_email : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment basic className="certificate-info">
                        <Header as="h3">
                            <Icon name="compass outline" />
                            Certificate Info
                        </Header>
                        <Item.Group>
                            {certificateInfo &&
                                certificateInfo.map(item => (
                                    <Item as={Link} to={`/public/product/certificate/${item.certificateId}`}>
                                        <Item.Image
                                            size="small"
                                            src={item.productPicture || 'https://react.semantic-ui.com/images/wireframe/image.png'}
                                        />
                                        <Item.Content>
                                            <Item.Header>{item.brandName}</Item.Header>
                                            <Item.Description>
                                                <Grid columns={2}>
                                                    <Grid.Column>
                                                        <span>Certification number</span> {item.certNo} <br />
                                                        <span>Product type</span> {item.productType} <br />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <span>Issued on</span> {item.validFrom} <br />
                                                        <span>Valid until</span> {item.validTill} <br />
                                                    </Grid.Column>
                                                </Grid>
                                            </Item.Description>
                                            <Item.Extra>
                                                <Icon name="checkmark" color="green" /> {item.rating}
                                            </Item.Extra>
                                        </Item.Content>
                                    </Item>
                                ))}
                        </Item.Group>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = ({ public: publicReducer }, ownProps) => {
    const companyId = Number(ownProps.match.params.companyId);

    return {
        companyInfo: publicReducer.companyInfo && publicReducer.companyInfo.companyInfo,
        certificateInfo: publicReducer.companyInfo && publicReducer.companyInfo.certificateInfo,
        companyId,
    };
};

// prettier-ignore
export default connect(mapState, { fetchCompanyInfo },)(withRouter(CompanyInfo));
