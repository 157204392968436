import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import history from '../../../../../history';

import { Row, Col, Container } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
// import IconWidget0 from 'cyder/widget/IconWidget0';
import CyderPaginationReactTable from 'cyder/table/CyderPaginationReactTable';
import ButtonIcon from 'cyder/button/ButtonIcon';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { deleteCertApplication } from 'actions/member/certification/applications/memberCertNewApplAction';
import { memberCertApplicationListGetInfo } from 'actions/member/certification/memberCertificationApplicationListAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';

import { actionExecutor, sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';

import { memberCertOverviewGetInfo, memberCertOverviewSetFilteredBy } from 'actions/member/certification/memberCertificationOverviewAction';

const generateOpenLink = (id, type) => `/member/certification/${type.toLowerCase()}/application/${id}`;
const determineNumberSize = applicationStatuses => {
    for (let i = 0; i < applicationStatuses.length; i++) {
        const hasExceed = applicationStatuses[i].numberText.toString().length > 2;
        if (hasExceed) return 35;
    }
    return 40;
};

const BrandDetailComponent = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((brand, index) => {
                return (
                    <div key={index}>
                        <div className="text-bold">{brand && brand.name && brand.name.toUpperCase()}</div>
                        {brand.models != null &&
                            brand.models.map((model, index) => {
                                return <div key={index}>{model.name}</div>;
                                //no more using description hence removed
                            })}
                        <br />
                    </div>
                );
            })}
        </React.Fragment>
    );
};
const CertificationApplicationsTable = ({ mode, items, loading, tablePages, getDataFunc, type, tableRef, deleteApplication }) => {
    var columns = [];
    columns.push({
        Header: 'Certificate Number to Renew / Amend',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        accessor: 'certificateNumberToRenewOrAmend',
        Cell: props => {
            return props.value || '-';
        },
    });
    columns.push({
        Header: 'Application Type',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        accessor: 'applicationType',
    });
    if (type === 'product') {
        columns.push({
            Header: 'Product Type',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            accessor: 'productType',
        });
        columns.push({
            Header: 'Details',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            accessor: 'brands',
            Cell: props => {
                const plainBrand = props.original.brand != null ? props.original.brand : '-';
                const products = props.value || [];
                try {
                    return products.constructor === Array && products.length > 0 ? <BrandDetailComponent items={products} /> : plainBrand;
                } catch (e) {
                    return plainBrand;
                }
            },
        });
    }
    columns.push({
        Header: 'Category',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        accessor: 'category',
    });
    columns.push({
        Header: 'Status',
        style: { whiteSpace: 'unset' },
        headerStyle: { whiteSpace: 'unset' },
        Cell: props => {
            return props.original.status == 'Accepted' ? 'Application Accepted' : props.original.status;
        },
    });
    let show = false;
    items.forEach(x => {
        if (x.submittedOn) {
            show = true;
        }
    });
    if (show) {
        columns.push({
            Header: 'Submitted On',
            style: { whiteSpace: 'unset' },
            headerStyle: { whiteSpace: 'unset' },
            accessor: 'submittedOn',
            Cell: props => props.value && moment(props.value).format('DD/MM/YYYY'),
        });
    }
    columns.push({
        Header: '',
        minWidth: 100,
        accessor: 'id',
        style: { textAlign: 'end' },
        sortable: false,
        Cell: props => {
            return (
                <React.Fragment>
                    <Link to={generateOpenLink(props.value, props.original.clazz)}>
                        <ButtonIcon className="m-2" icon="folder_open" label="Open" />
                    </Link>
                    {props.original.status.toLowerCase() == 'draft' && (
                        <ButtonIcon className="m-2" icon="delete" label="Delete" onClick={e => deleteApplication(props.value)} />
                    )}
                </React.Fragment>
            );
        },
    });
    return (
        <CyderPaginationReactTable
            data={items}
            columns={columns}
            filterable={false}
            pagination={false}
            defaultPageSize={100}
            pages={tablePages}
            loading={loading}
            getDataFunc={getDataFunc}
            tableRef={tableRef}
        />
    );
};

const CertificationApplications = ({
    mode,
    loading,
    tablePages,
    getDataFunc,
    ongoingapplications,
    pastapplications,
    openApplication,
    overviewData,
    type,
    tableRef,
    shouldApplyButtonDisabled,
    deleteApplication,
}) => {
    const label = mode === 'product' ? 'Product' : 'Services';
    const labelCaps = label.toUpperCase();
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <br />
                <Row className="d-flex">
                    <Col xs={6}>
                        <h2>
                            <strong>{labelCaps + ' APPLICATIONS'}</strong>{' '}
                        </h2>
                    </Col>
                    <Col xs={6}>
                        <ButtonIcon
                            flat
                            icon="folder_open"
                            className="float-md-right"
                            label={'Apply for ' + label + ' certificates'}
                            onClick={() => history.push(`/member/certification/${mode}/newapplication`)}
                            disabled={shouldApplyButtonDisabled()}
                        />
                    </Col>
                </Row>
                <br />
                <br />
                <div>
                    <span
                        xs={6}
                        style={{
                            color: '#317446',
                        }}
                    >
                        <h3>
                            <strong>{`ONGOING ${labelCaps} APPLICATIONS`}</strong>
                        </h3>
                    </span>
                    <br />
                    <Row>
                        <Col>
                            <CertificationApplicationsTable
                                mode={mode}
                                loading={loading}
                                items={ongoingapplications}
                                tablePages={tablePages}
                                openApplication={openApplication}
                                getDataFunc={(a, b) => getDataFunc('ongoing', mode)}
                                type={type}
                                tableRef={tableRef}
                                deleteApplication={deleteApplication}
                            />
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                <div>
                    <span
                        style={{
                            color: '#317446',
                        }}
                    >
                        <h3>
                            <strong>{`PAST ${labelCaps} APPLICATIONS`}</strong>
                        </h3>
                    </span>
                    <br />
                    <Row>
                        <Col xs={12}>
                            <CertificationApplicationsTable
                                mode={mode}
                                loading={loading}
                                items={pastapplications}
                                tablePages={tablePages}
                                openApplication={openApplication}
                                getDataFunc={(a, b) => getDataFunc('past', mode)}
                                type={type}
                                tableRef={tableRef}
                            />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

class MemberCertificationApplicationListPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.getDataFunc = this.getDataFunc.bind(this);
        this.deleteApplication = this.deleteApplication.bind(this);
        this.shouldApplyButtonDisabled = this.shouldApplyButtonDisabled.bind(this);
        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            loading: false,
            companyInfo: {},
        };
    }
    async componentDidMount() {
        this.showLoading(true);
        try {
            let res1 = await this.props.memberCertOverviewGetInfo();
            const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
            let res2 = await this.getDataFunc('ongoing', mode);
            let res3 = await this.getDataFunc('past', mode);
            let res4 = await this.getCompanyInfo();
        } catch (error) {
            console.log(error);
        } finally {
            this.showLoading(false);
        }
    }
    async componentDidUpdate(prevProps) {
        /**
         * !IMPORTANT
         *
         * Use to force reload this component if other pages using this component is clicked.
         * For example, click on card view in '/member/certification/overview' and again click
         * on card view on top of the directed page. The componenet will force reload based on
         * respective filter status (application, payment, issued, or all).
         */
        if (this.props.mode != prevProps.mode) {
            const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
            this.showLoading(true);
            await this.getDataFunc('ongoing', mode);
            await this.getDataFunc('past', mode);
            this.showLoading(false);
        } else if (prevProps.match.url != this.props.match.url) {
            const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
            this.showLoading(true);
            await this.getDataFunc('ongoing', mode);
            await this.getDataFunc('past', mode);
            this.showLoading(false);
        }
    }
    async deleteApplication(id) {
        let res = await this.props.deleteApplication(id);
        const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
        window.location.reload();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getDataFunc(status, mode) {
        // Dertermine status for ForceReload
        // Function call
        // Updated by Fernando, to fix to 50 records
        await this.props.memberCertApplicationListGetInfo(status, mode);
    }
    getCompanyInfo() {
        const callback = async () => {
            const { data } = await this.props.getMembershipInfo();
            const companyInfo = sanitizeStringAndObjectNullsToEmptyStrings(data || {});
            if (companyInfo != null && data != null && this._isMounted) {
                this.setState({
                    companyInfo,
                });
            }
        };
        actionExecutor(() => { }, callback);
    }
    shouldApplyButtonDisabled() {
        const { organisationMainCategoryId, officialManagementRepresentativeId, businessAddressLine1 } = this.state.companyInfo;
        if (!organisationMainCategoryId || !businessAddressLine1) return true;
        return false;
    }
    showLoading(show) {
        if (!this._isMounted) return;
        this.setState({
            loading: show,
        });
    }
    render() {
        const determinedMode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
        const mode = this.props.mode || determinedMode;
        return (
            <Container>
                {this.state.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <CertificationApplications
                        mode={mode}
                        openApplication={() => { }}
                        shouldApplyButtonDisabled={this.shouldApplyButtonDisabled}
                        ongoingapplications={this.props.ongoingapplications}
                        pastapplications={this.props.pastapplications}
                        tablePages={this.props.tablePages}
                        loading={this.state.loading}
                        getDataFunc={this.getDataFunc}
                        overviewData={this.props.overview}
                        type={this.props.type}
                        deleteApplication={this.deleteApplication}
                        tableRef={instance => {
                            this.table = instance;
                        }}
                    />
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberCertificationsApplicationListReducer,
        overview: state.memberCertificationsOverviewReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMembershipInfo: () => {
            return dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        memberCertApplicationListGetInfo: (type, keyword, a, b, c, d) => {
            return dispatch(memberCertApplicationListGetInfo(type, keyword, a, b, c, d));
        },
        memberCertOverviewGetInfo: () => {
            return dispatch(memberCertOverviewGetInfo());
        },
        memberCertSetFilterBy: filterBy => {
            return dispatch(memberCertOverviewSetFilteredBy(filterBy));
        },
        deleteApplication: id => {
            return dispatch(deleteCertApplication(id));
        },
    };
};

export { BrandDetailComponent };
export default connect(mapStateToProps, mapDispatchToProps)(TopNavigationLayout(MemberCertificationApplicationListPage));
