import React from 'react';
import { connect } from 'react-redux';

import TableWidgeEventsListWidget from '../../table/TableWidgetEventsListWidget';

class EventsList extends React.Component {
    render() {
        return <TableWidgeEventsListWidget items={this.props.events} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.staff.dashboardInfo.events.list,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EventsList);
