import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { configObj } from './TopNavigation2.js';
const TopNavigationLayout = compose(
    connect(state => {
        return {
            config: state.config,
        };
    }),
    lifecycle({
        componentDidMount() {
            this.props.dispatch({
                type: 'SET_CONFIG',
                config: configObj,
            });
        },
    }),
);

export default TopNavigationLayout;
