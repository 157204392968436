import withQuery from 'with-query';
import {
    MEMBER_MEMBERSHIP_PAYMENT_SET_MODE,
    MEMBER_MEMBERSHIP_PAYMENT_NEXT,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA,
    MEMBER_QUESTIONNAIRES_NEXT,
    MEMBER_QUESTIONNAIRES_PREV,
    MEMBER_QUESTIONNAIRES_RESET_FORM,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE,
} from '../../../constants/actionTypesMemberMembershipApplications';

import fetchlib from '../../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';

export function memberMembershipApplicationNewGetPackageInfo(renewMembership) {
    return (dispatch, getState) => {
        const data = { renew: renewMembership };
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-package`, {
            action: 'get-all-new-membership',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            data,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE,
        );
    };
}
export function memberMembershipApplicationGetPackageInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-package`, {
            action: 'get-all-membership',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE,
        );
    };
}
export function submitApplication(body) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'submitmembershipapplication',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE,
        );
    };
}
export function saveApplication(body) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'save_membership_application',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE,
        );
    };
}
export function submitQuestionainerAnswers() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application-tier-response`, {
            action: 'saveAnswers',
        });
        var body = [];
        // getState().memberNewMembershipApplicationReducer.questionnaires.question1.forEach(ans => {
        //     body.push({
        //         questionId: 1,
        //         responseId: ans,
        //     });
        // });
        getState().memberNewMembershipApplicationReducer.questionnaires.question4.forEach(ans => {
            body.push({
                questionId: 4,
                responseId: ans,
            });
        });
        var data = {
            corporateSustainability: getState().memberNewMembershipApplicationReducer.question5.corporateSustainability,
            statementOfCommitment: getState().memberNewMembershipApplicationReducer.question5.statementOfCommitment,
            sustainabilityReport: getState().memberNewMembershipApplicationReducer.question5.sustainabilityReport,
            certifiedEnvironment: getState().memberNewMembershipApplicationReducer.question5.certifiedEnvironment,
            sustainabilityAward: getState().memberNewMembershipApplicationReducer.question5.sustainabilityAward,
            certifiedBuilding: getState().memberNewMembershipApplicationReducer.question1.certifiedBuilding,
            certifiedInterior: getState().memberNewMembershipApplicationReducer.question1.certifiedInterior,
            question3b: getState().memberNewMembershipApplicationReducer.question3.question3b,
            question3c: getState().memberNewMembershipApplicationReducer.question3.question3c,
            body: body,
        };
        return fetchlib.fetchV2(
            dispatch,
            getState,
            'POST',
            url,
            data,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE,
        );
    };
}

export function memberMembershipApplicationNewGetQuestions() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'getmembershipotherquestions',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE,
        );
    };
}
export function membershipPaymentSetMode(mode) {
    return {
        type: MEMBER_MEMBERSHIP_PAYMENT_SET_MODE,
        mode,
    };
}

export function membershipPaymentNext() {
    return {
        type: MEMBER_MEMBERSHIP_PAYMENT_NEXT,
    };
}
export function forceLoadingState(loadingState) {
    return dispatch => {
        dispatch({
            type: MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING,
            loadingState,
        });
        return Promise.resolve();
    };
}
export function setModalData(data) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA,
        data,
    };
}

export function questionnairesNext() {
    return {
        type: MEMBER_QUESTIONNAIRES_NEXT,
    };
}

export function questionnairesPrev() {
    return {
        type: MEMBER_QUESTIONNAIRES_PREV,
    };
}
export function questionnairesResetForm() {
    return {
        type: MEMBER_QUESTIONNAIRES_RESET_FORM,
    };
}
