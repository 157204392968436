import React from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';

class CorporatePayNow extends React.Component {
    render() {
        return (
            <Row>
                <Col xs="12" style={{ textAlign: 'left' }}>
                    <Row>
                        <Col xs={3}>
                            <img src="/assets/images/qr_code_sgbc_dbs.png" style={{ width: 'inherit' }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>Scan the QR Code to make payment via Corporate PayNow.</Col>
                    </Row>
                    <Row>
                        <Col xs={12}>Please enter the Invoice Number in the Bill Reference No. field.</Col>
                    </Row>
                </Col>
                {/* <Col xs="9">
                    <Row>
                        <Col xs={3}>
                            <PaymentInput
                                label="Payment Amount (SGD)"
                                description="Please enter amount of payment"
                                placeholder=""
                                onChange={e => this.props.onTextChanged(e.target.value, 'amountReceived')}
                                value={this.props.paymentInfo.amountReceived}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className="field-header">
                                Date of Payment <MandatoryStar />
                            </label>
                            <CyderDatePicker
                                placeholder=""
                                dateFormat="DD/MM/YYYY"
                                closeOnSelect={true}
                                onChange={e => this.props.onDateChanged(e, 'datePaymentReceived', 'datePaymentReceivedCtrl')}
                                value={this.props.paymentInfo.datePaymentReceivedCtrl || ''}
                            />
                            <small className="form-text text-muted">Please select date of payment</small>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <PaymentInput
                                label="Name of Bank"
                                placeholder=""
                                onChange={e => this.props.onTextChanged(e.target.value, 'bank')}
                                value={this.props.paymentInfo.bank}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <PaymentInput
                                id="referenceNumber"
                                label="Reference Number / Remarks"
                                description="Please enter reference number / remarks"
                                placeholder=""
                                onChange={e => this.props.onTextChanged(e.target.value, 'referenceNumber')}
                                value={this.props.paymentInfo.referenceNumber}
                            />
                        </Col>
                    </Row>
                </Col> */}
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CorporatePayNow);
