export default {
    flattenArrayToKeys: arr => {
        let obj = {};
        arr.forEach(each => {
            const o = Object.keys(each);
            o.forEach(key => {
                obj[key] = each[key];
            });
        });
        return obj;
    },
};

/**
 * Util function used to execute action with elimination of try/catch clause and loading calls
 *
 * @param {*} loading: Required; loading function to be executed
 * @param {*} callback: Required: action callback executed on try clause
 * @param {*} catchCallback: Optional: callback executed on catch clause
 * @param {*} finallyCallback: Optional: callback executed on finally clause
 */
export const actionExecutor = async (loading, callback, catchCallback, finallyCallback) => {
    // ARGUMENTS VALIDATION
    const hasArgs = loading && callback;
    const areFunction = typeof loading === 'function' && typeof callback === 'function';
    if (!hasArgs) throw new Error('Require loading and action callback');
    if (!areFunction) throw new Error('Loading and action callback must be a type of function');

    // EXECUTION
    try {
        loading(true);
        const res = await callback();
        return res;
    } catch (error) {
        if (catchCallback && typeof catchCallback === 'function') catchCallback(error);
        throw error;
    } finally {
        if (finallyCallback && typeof finallyCallback === 'function') finallyCallback();
        loading(false);
    }
};

// B64 TO BLOB CONVERTER
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    // atob decode b64 encoded string
    const byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

// FILE DOWNLOAD - SUPPORT ON FIREFOX AND CHROME
export function anchorElementDownload(href) {
    var anchor = document.createElement('a');
    anchor.href = href;
    anchor.target = '_blank';
    anchor.style.visibility = 'hidden';
    document.getElementById('root').appendChild(anchor);
    anchor.click();
    document.getElementById('root').removeChild(anchor);
}

// PAD A STRING WITH VALUE 0 ON ONE THAT DO NOT FIT THE GIVEN LENGTH
export function pad(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeArrayItem(array, removeIndex) {
    return array.slice(0, removeIndex).concat(array.slice(removeIndex + 1, array.length));
}

export function addArrayItem(array, item, addIndex) {
    return array
        .slice(0, addIndex)
        .concat(item)
        .concat(array.slice(addIndex, array.length));
}

// CONTENT TYPE PARSER
export const parseContentType = s => {
    switch (s) {
        case 'jpg':
            return 'image/jpg';
        case 'png':
            return 'image/png';
        case 'pdf':
            return 'application/pdf';
        default:
            return s;
    }
};

// BLOD DOWNLOAD FUNCTION
export const blobDownload = (filename, contentType, b64Data) => {
    // const cType = contentType === 'pdf' ? 'application/pdf' : contentType;
    const cType = contentType;
    const blob = b64toBlob(b64Data, cType);
    const blobUrl = URL.createObjectURL(blob);

    let a = document.createElement('a');
    a.href = blobUrl;
    /*
    let typeString = cType.substring(cType.indexOf('/') + 1);
    if (typeString === 'plain') typeString = 'txt';
    */
    a.download = `${filename}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const propGenerator = ({ valueKey, fields, ...rest }) => ({
    value: fields[valueKey],
    valueKey,
    ...rest,
});

// function that returns false if undefined or null. otherwise, returns true
export const truthyUN = thing => {
    if (thing === undefined) return false;
    if (thing === null) return false;
    return true;
};

// THIS MIGHT CHANGE IN THE FUTURE AS KEYS ARE EVENTUALLY CONVERTED TO CAMELCASE
export const hasElevatedRoles = userdata => {
    if (
        userdata.is_superuser === 1 ||
        userdata.is_corporate_admin === 1 ||
        userdata.is_omr === 1 ||
        userdata.is_secondarymr === 1 ||
        userdata.is_cert_coordinator === 1
    )
        return true;
    return false;
};

// THIS MIGHT CHANGE IN THE FUTURE AS KEYS ARE EVENTUALLY CONVERTED TO CAMELCASE
export const isCertManager = userdata => {
    if (userdata.is_cert_manager === 1) return true;
    return false;
};

// REMOVE FIELD CONTAINE NULL VALUE IN AN OBJECT
export const removeNullFieldsFromObject = sourceObj => {
    return Object.keys(sourceObj).reduce((object, key) => {
        let a = object;
        if (sourceObj[key] !== null) {
            a[key] = sourceObj[key];
        }
        return a;
    }, {});
};

// SANITIZE ALL INVALID VALUE (NULL ONlY) ON VALUES OF AN OBJECT TYPE
export const sanitizeObjectNullsToEmptyStrings = (sourceObj, fields, reverse) => {
    const objKeys = fields !== undefined ? Object.keys(sourceObj).filter(key => fields[key] !== undefined) : Object.keys(sourceObj);
    let sanitizedObj = {};
    for (let i = 0; i < objKeys.length; i += 1) {
        const key = objKeys[i];
        // allows for null on empty string if reverse === true
        if (reverse === true) {
            sanitizedObj[key] = sourceObj[key] === '' ? null : sourceObj[key];
        } else {
            sanitizedObj[key] = sourceObj[key] === null ? '' : sourceObj[key];
        }
    }
    return Object.assign({}, sourceObj, sanitizedObj);
};

/**
 * SANITIZE ALL INVALID VALUE (NULL, UNDEFINED, AND '') ON VALUES OF AN OBJECT TYPE
 *
 * Util to tackle problems on ivalid values return from API call.
 *
 * @param sourceObj: Dictionary object to filter
 * @param fields: Specification on the particular key/value within sourceObj to filter. Eg. { key: true }
 * @param reverse: Accept true or false; All invalid value in during fields evaluation will be change to null only
 * */
export const sanitizeStringAndObjectNullsToEmptyStrings = (sourceObj, fields, reverse) => {
    let sanitizedObj = {};
    if (sourceObj !== undefined) {
        const objKeys = fields !== undefined ? Object.keys(sourceObj).filter(key => fields[key] !== undefined) : Object.keys(sourceObj);
        for (let i = 0; i < objKeys.length; i += 1) {
            const key = objKeys[i];
            // Change all empty string and undefined values to null if reverse === true
            if (reverse === true) {
                sanitizedObj[key] = !sourceObj[key] ? null : sourceObj[key];
            } else {
                sanitizedObj[key] = sourceObj[key] === 'null' || sourceObj[key] === null ? '' : sourceObj[key];
            }
        }
    }
    return Object.assign({}, sourceObj, sanitizedObj);
};

export const isEmpty = obj => {
    for (var key in obj) {
        if (obj[key] != null) return false;
    }
    return true;
};

/**
 * Reorder array of object as specified on expected argument
 *
 * @param original: original list of array to be reordered
 * @param expected: array of values based from original value prop
 * @param basedOn: prop to be used for reorder
 * */
export function reorderArray(original, expected, basedOn) {
    // Type checking
    if (typeof original !== 'object' || typeof expected !== 'object' || typeof basedOn !== 'string') {
        console.error('Invalid argument type for function reorderArray');
        return;
    }

    // Logic
    return expected
        .slice()
        .map((x, i) => {
            for (var y = 0; y < original.length; y++) {
                if (x === original[y][basedOn]) return original[y];
            }
            return '';
        })
        .filter(x => x);
}

export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
