import React from 'react';
import { connect } from 'react-redux';

import { Input } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';

export const PaymentInput = ({ label, description, placeholder, value, onChange, id }) => (
    <div className="form-group">
        <label className="field-header">
            {label} <MandatoryStar />
        </label>
        <Input id={id} type="text" className="form-control" placeholder={placeholder} onChange={onChange} value={value} />
        {description && <small className="form-text text-muted">{description}</small>}
    </div>
);

class CashPayment extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-lg-12">
                    <PaymentInput
                        label="Payment Amount"
                        description="Please enter amount of payment"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'amountReceived');
                        }}
                        value={this.props.paymentInfo.amountReceived}
                    />
                    <PaymentInput
                        label="Date of Payment"
                        description="Please select date of payment"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'datePaymentReceived');
                        }}
                        value={this.props.paymentInfo.datePaymentReceived}
                    />
                    <PaymentInput
                        label="Remarks"
                        description="Please enter remarks"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'remarks');
                        }}
                        value={this.props.paymentInfo.remarks}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CashPayment);
