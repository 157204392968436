import CyderDatePicker from '../../../../cyder/forms/CyderDatePicker';
import React from 'react';
import moment from 'moment';
import Cleave from 'cleave.js/react';
import { Input, Label, Row, Col, Form, FormGroup, Button, Card, CardBody } from 'reactstrap';

const divTimeStyle = {
    width: '150px',
    padding: '0px',
    margin: '0px',
};
const divDateStyle = {
    width: '200px',
    padding: '0px',
    margin: '0px',
};
const DatesComponent = props => {
    // console.log("props: ", props)
    return (
        <div>
            <Row>
                <Col xs={2}>
                    <FormGroup>
                        <div style={divDateStyle}>
                            <Label>Date</Label>
                            <CyderDatePicker
                                id="date"
                                value={moment(props.fields.date, 'DD/MM/YYYY')}
                                onChange={moment => props.handleEventInputChange(moment, 'date', props.fields.id)}
                                dateFormat="DD/MM/YYYY"
                            />
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <div style={divTimeStyle}>
                            <Label>Start Time (optional)</Label>
                            {/*<CyderDatePicker
                                id="startTimeForThisDay"
                                datetimeClassName={divTimeStyle}
                                value={moment(props.fields.startTimeForThisDay, 'HH:mm')}
                                dateFormat={null}
                                timeFormat="HH:mm"
                                onChange={moment => props.handleEventInputChange(moment, 'startTimeForThisDay', props.fields.id)}
                                readOnly={false}
    />*/}
                            <Cleave
                                placeholder="HH:MM"
                                style={{
                                    marginRight: '10px',
                                    padding: '0.3rem 0.75rem',
                                    width: '60px',
                                    border: '1px solid rgba(0, 0, 0, 0.15)',
                                }}
                                options={{
                                    time: true,
                                    timePattern: ['h', 'm'],
                                }}
                                value={props.fields.startTimeForThisDay ? props.fields.startTimeForThisDay : '00:00'}
                                onChange={e => props.handleEventInputChange(e.target.value, 'startTimeForThisDay', props.fields.id)}
                            />
                            {` `}hh:mm
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <div style={divTimeStyle}>
                            <Label>End Time (optional)</Label>
                            <Cleave
                                style={{
                                    marginRight: '10px',
                                    padding: '0.3rem 0.75rem',
                                    width: '60px',
                                    border: '1px solid rgba(0, 0, 0, 0.15)',
                                }}
                                placeholder="HH:MM"
                                options={{
                                    time: true,
                                    timePattern: ['h', 'm'],
                                }}
                                value={props.fields.endTimeForThisDay ? props.fields.endTimeForThisDay : '00:00'}
                                onChange={e => props.handleEventInputChange(e.target.value, 'endTimeForThisDay', props.fields.id)}
                            />
                            {` `}hh:mm
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={5} style={{ marginLeft: '10px', marginTop: '30px' }}>
                    {props.deletable && (
                        <Button
                            className="primary-btn-style-outline"
                            onClick={e => {
                                props.deleteEventDate(props.fields.id);
                            }}
                        >
                            Delete
                        </Button>
                    )}
                </Col>
            </Row>
            <br />
            {/* <Row className="mt-2">
                <Col>
                    <Button className="primary-btn-style" onClick={props.mode === 'update' ? props.updateEventDates : props.saveEventDates}>
                        Save Date
                    </Button>
                </Col>
            </Row> */}
        </div>
    );
};

export default DatesComponent;
