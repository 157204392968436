/**
 * Security action creators
 *
 * Author: Fernando
 */
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import withQuery from 'with-query';
import fetchlib from '../../js/fetchlib';
import moment from 'moment';

export function getMembersAgeProfile() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembersAgeProfile',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembersAgeProfileDetails(ageProfile) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembersAgeProfileDetails',
            ageProfile,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembersEventProfile() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembersEventProfile',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembersEventProfileDetails(category) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembersEventProfileList',
            category,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getActiveMembersAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getActiveMembersAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembershipIssuedAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipIssuedAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembershipApplicationAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getMembershipApplicationAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getOrganisationPrimaryBusinessAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getOrganisationPrimaryBusinessAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getExpiringMemberCountAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getExpiringMemberCountAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function membershipIssuedChartAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipIssuanceAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipIssuedChartAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipRenewalAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipRenewalAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipDiscontinueReasonDetailAnalytics(reasonId) {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.reasonId = reasonId;
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipDiscontinueReasonDetailAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipDiscontinueReasonAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipDiscontinueReasonAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipRenewalDetailAnalytics(renewalStatusId) {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.renewalStatusId = renewalStatusId;
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipRenewalDetailAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipRenewalRetentionInfoAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipRenewalRetentionInfoAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function membershipRenewalSuccessAnalytics() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().membershipRenewalAnalyticsReducer);
        body.startDate = moment(body.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        body.endDate = moment(body.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'membershipRenewalSuccessAnalytics',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getNewMembershipApplicationAnalytic() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getNewMembershipApplicationAnalytic',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getSMECount() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getSMECount',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getLocalCount() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership`, {
            action: 'getLocalCount',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
