import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import TeamManagementComponent from 'pages/shared/TeamManagementComponent.js';

class MemberTeamManagementPage extends React.Component {
    render() {
        return (
            <Container key="container">
                <TeamManagementComponent mode="member" />
            </Container>
        );
    }
}
export default MemberTeamManagementPage;
