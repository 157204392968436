import {
    APPLICATION_SEARCH_REQUEST,
    APPLICATION_SEARCH_SUCCESS,
    APPLICATION_SEARCH_FAILURE,
    APPLICATION_KEYWORD_SEARCH_REQUEST,
    APPLICATION_KEYWORD_SEARCH_SUCCESS,
    APPLICATION_KEYWORD_SEARCH_FAILURE,
    // APPLICATION_SEARCH_PAGE_RESET_STATE,
    APPLICATION_TOGGLE_CHECKBOX,
    APPLICATION_TOGGLE_CHECKBOX_ALL,
    APPLICATION_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../../../actions/constants/actionTypesStaffMembershipRenewals';
import cyderlib from '../../../../js/cyderlib.js';

// defaults
var defaultState = {
    renewal: cyderlib.fakeRenewals[0],
};

export function staffMembershipRenewalsDetailsReducer(
    state = defaultState,
    action,
) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case APPLICATION_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case APPLICATION_SEARCH_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data || action.response;
            newState.applications.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case APPLICATION_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        // case APPLICATION_SEARCH_PAGE_RESET_STATE:
        // casereturn defaultState;
        case APPLICATION_KEYWORD_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        case APPLICATION_KEYWORD_SEARCH_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data || action.response;
            newState.applications.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case APPLICATION_KEYWORD_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        case APPLICATION_TOGGLE_CHECKBOX:
            newSelectedItems = Object.assign({}, state.selectedItems);
            if (newSelectedItems[action.id] === undefined) {
                newSelectedItems[action.id] = true;
                newState.selectedCount = newState.selectedCount + 1;
            } else {
                newSelectedItems[action.id] = !newSelectedItems[action.id];
                if (newSelectedItems[action.id] === true) {
                    newState.selectedCount = newState.selectedCount + 1;
                } else {
                    newState.selectedCount = newState.selectedCount - 1;
                }
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        case APPLICATION_TOGGLE_CHECKBOX_ALL:
            stateResult = !newState.selectedAll;
            newState.selectedAll = stateResult;
            if (newState.selectedAll === true) {
                newState.selectedCount = Object.keys(
                    newState.selectedItems,
                ).length;
            } else {
                newState.selectedCount = 0;
            }
            for (let key in newState.selectedItems) {
                newState.selectedItems[key] = stateResult;
            }
            return newState;
        case APPLICATION_CHANGE_INPUT_KEYWORD_VALUE:
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        default:
            return state;
    }
}
