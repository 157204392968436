// defaults
var defaultState = {
    loading: false,
};
export function dinnerEventsAllEventsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'DINNER_EVENT_DASHBOARD_SET_SECTION':
            newState.dinnerEventDashboardSection = action.section;
            return newState;
        default:
            return state;
    }
}
