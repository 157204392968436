import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import moment from 'moment';

import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import Select from 'react-select';
import DialogModal from 'cyder/modals/DialogModal';

import { getAllTickets, saveTickets, getXeroInventoryItems } from '../../../../actions/staff/events/events/eventsTicketAction';

class TicketsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.saveTickets = this.saveTickets.bind(this);
        this.textChange = this.textChange.bind(this);
        this.dateChange = this.dateChange.bind(this);
        this.chcekboxChange = this.chcekboxChange.bind(this);
        this.addNewTicket = this.addNewTicket.bind(this);
        this.xeroInventoryItemsChange = this.xeroInventoryItemsChange.bind(this);
        this.state = {
            loading: false,
            data: { type: '' },
            tickets: [],
            xeroInventoryItems: [],
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            select: { disabled: false, searchable: true, clearable: true },
        };
    }
    async getRelevantStuffs() {
        let tickets = [];
        let xeroInventoryItems = [];
        const mapDate = date => moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
        try {
            if (this.props.event.eventid > 0) {
                const ticket = await this.props.getAllTickets(this.props.event.eventid);
                tickets = ticket.data.map(ticket => {
                    return {
                        id: ticket.id,
                        ticketName: ticket.name,
                        itemCode: ticket.itemCode,
                        price: ticket.pricePerTicket,
                        ticketDescription: ticket.description,
                        totalQuantityAvailable: ticket.quantityAvailableForSale,
                        maxAllowedPerReg: ticket.maxAllowedPerRegistration,
                        availableStartDate: mapDate(ticket.salesStartDate),
                        availableEndDate: mapDate(ticket.salesEndDate),
                        visibleOnRegPage: ticket.visibleOnRegistrationPage === 1 ? true : false,
                        onlyAvailableForSGBCMembers: ticket.onlyAvailableForSgbcMembers === 1 ? true : false,
                        xeroInventoryItem: {
                            value: ticket.itemCode,
                            label: ticket.itemCode,
                        },
                        ticketUsed: ticket.ticketUsed === 'Used' ? true : false,
                    };
                });
            }
            const xeroItems = await this.props.getXeroInventoryItems();
            xeroInventoryItems =
                xeroItems &&
                xeroItems.data
                    .map(({ itemCode, amount }) => {
                        if (itemCode.includes('EV') || itemCode.includes('TC'))
                            return {
                                value: itemCode,
                                label: itemCode,
                                amount: amount,
                            };
                        return;
                    })
                    .filter(x => x);
        } catch (error) {
            throw error;
        }
        if (this._isMounted) {
            this.setState({
                tickets,
                xeroInventoryItems,
            });
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // Solution to resolve 'Warning: can't call setState (or forceUpdate) on an unmounted component'
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        // Solution to resolve 'Warning: can't call setState (or forceUpdate) on an unmounted component'
        this._isMounted = false;
    }
    delete(ticketId) {
        const new_state = Object.assign({}, this.state);
        const tickets = new_state.tickets
            .map(ticket => {
                if (ticket.id !== parseInt(ticketId)) {
                    return ticket;
                }
            })
            .filter(x => x !== undefined);
        this.setState({ tickets });
    }
    dateChange(e, fieldName, ticketId) {
        const new_state = Object.assign({}, this.state);
        const tickets = new_state.tickets.map(ticket => {
            if (ticket.id === ticketId) {
                try {
                    ticket[fieldName] = e.format('DD/MM/YYYY');
                } catch (e) {
                    throw e;
                }
            }
            return ticket;
        });
        this.setState({ tickets });
    }
    xeroInventoryItemsChange(e, ticketId) {
        const newState = Object.assign({}, this.state);
        newState.tickets = Object.assign([], this.state.tickets);
        const tickets = newState.tickets.map(ticket => {
            if (ticket.id === ticketId) {
                ticket.itemCode = e.value;
                ticket.xeroInventoryItem = e;
                ticket.price = e.amount;
            }
            return ticket;
        });
        this.setState({ tickets });
    }
    textChange(e) {
        var fieldName = e.target.id.split('-')[0];
        var ticketId = e.target.id.split('-')[1];
        let new_state = Object.assign({}, this.state);
        let tickets = new_state.tickets;
        console.log('text chabge: ', e.target.id);
        tickets.forEach(ticket => {
            if (ticket.id == ticketId) {
                ticket[fieldName] = e.target.value;
            }
        });
        this.setState({ tickets });
    }
    chcekboxChange(e) {
        var fieldName = e.target.id.split('-')[0];
        var ticketId = e.target.id.split('-')[1];
        let new_state = Object.assign({}, this.state);
        let tickets = new_state.tickets;
        tickets.forEach(ticket => {
            if (ticket.id === parseInt(ticketId)) {
                ticket[fieldName] = new Number(e.target.checked).valueOf();
            }
        });
        this.setState({ tickets });
    }
    addNewTicket() {
        let new_state = Object.assign({}, this.state);
        let tickets = new_state.tickets;
        tickets.push({
            id: new Date().getTime(),
            ticketName: '',
            ticketDescription: '',
            visibleOnRegPage: true,
            totalQuantityAvailable: 0,
            xeroInventoryItem: {},
            maxAllowedPerReg: 0,
            price: 0,
            onlyAvailableForSGBCMembers: false,
            availableStartDate: moment().format('DD/MM/YYYY'),
            availableEndDate: moment().format('DD/MM/YYYY'),
        });

        this.setState({ tickets });
    }
    saveTickets() {
        const ticketsToSave = this.state.tickets.map(ticket => {
            var newTicket = Object.assign({}, ticket);
            newTicket.onlyAvailableForSGBCMembers = 0;
            newTicket.visibleOnRegPage = newTicket.visibleOnRegPage ? 1 : 0;
            return newTicket;
        });
        this.props.showLoading();
        this.props
            .saveTickets(parseInt(this.props.event.eventid), ticketsToSave)
            .then(res => {
                this.props.showSuccessUpdate();
                history.push('./materials');
            })
            .catch(error => {
                this.props.showFailureUpdate();
                throw error;
            });
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        {this.props.date.date === '' && (this.props.dates == null || this.props.dates.length <= 0) ? (
                            <Label className="text-bold">Note: Please select an event date before adding tickets.</Label>
                        ) : null}
                        {this.props.date.date !== '' &&
                        (this.props.dates == null || this.props.dates.length <= 0) &&
                        this.state.tickets.length === 0 ? (
                            <Label className="text-bold">No event tickets available*</Label>
                        ) : null}
                        {this.state.tickets.map((ticket, i) => {
                            return [
                                <TicketChildComponent
                                    key={i}
                                    ticket={ticket}
                                    delete={this.delete}
                                    save={this.save}
                                    textChange={this.textChange}
                                    dateChange={this.dateChange}
                                    checkboxChange={this.chcekboxChange}
                                    selectState={this.state.select}
                                    xeroInventoryItemsChange={this.xeroInventoryItemsChange}
                                    xeroInventoryItems={this.state.xeroInventoryItems}
                                />,
                                <hr className="pt-2 pb-2" key={'aa'} />,
                            ];
                        })}
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Button
                        className="m-2 primary-btn-style"
                        onClick={this.saveTickets}
                        disabled={this.props.date.date === '' && (this.props.dates == null || this.props.dates.length <= 0) ? true : false}
                    >
                        Save Tickets
                    </Button>
                    <Button
                        className="m-2 primary-btn-style-outline"
                        onClick={this.addNewTicket}
                        disabled={this.props.date.date === '' && (this.props.dates == null || this.props.dates.length <= 0) ? true : false}
                    >
                        Add New Ticket
                    </Button>
                </Row>
            </div>
        );
    }
}

const TicketChildComponent = props => {
    return (
        <Form>
            <Row>
                <Col xs={6}>
                    <InputFieldWithValidation
                        colWidth="9"
                        type="text"
                        rows={1}
                        label="Ticket Name *"
                        valueKey={`ticketName-${props.ticket.id}`}
                        value={props.ticket.ticketName}
                        validationObj={{}}
                        handleChange={props.textChange}
                    />
                </Col>
                <Col xs={6}>
                    <InputFieldWithValidation
                        colWidth="10"
                        type="textarea"
                        rows={4}
                        label="Ticket Description"
                        valueKey={`ticketDescription-${props.ticket.id}`}
                        value={props.ticket.ticketDescription}
                        validationObj={{}}
                        handleChange={props.textChange}
                    />
                </Col>
                {/* <FormGroup className="d-flex flex-column justify-content-center" check>
                    <Label check>
                        <Input
                            type="checkbox"
                            id={`visibleOnRegPage-${props.ticket.id}`}
                            checked={new Boolean(
                                props.ticket.visibleOnRegPage,
                            ).valueOf()}
                            onChange={props.checkboxChange}
                        />{' '}
                        Visible on registration page
                    </Label>
                </FormGroup> */}
            </Row>
            <Row>
                <Col xs={3}>
                    <InputFieldWithValidation
                        type="number"
                        label="Total Available *"
                        valueKey={`totalQuantityAvailable-${props.ticket.id}`}
                        value={props.ticket.totalQuantityAvailable}
                        validationObj={{}}
                        handleChange={props.textChange}
                    />
                </Col>
                <Col xs={3}>
                    <InputFieldWithValidation
                        type="number"
                        label="Max Allowed per registration"
                        valueKey={`maxAllowedPerReg-${props.ticket.id}`}
                        value={props.ticket.maxAllowedPerReg}
                        validationObj={{}}
                        handleChange={props.textChange}
                    />
                </Col>

                <Col xs={3}>
                    <FormGroup>
                        <Label for="eligibleCompanies">Xero Code *</Label>
                        <div style={{ width: '150px' }}>
                            <Select
                                autosize={false}
                                isMulti={false}
                                name="selected-state"
                                options={props.xeroInventoryItems}
                                clearable={props.selectState.clearable}
                                disabled={props.selectState.disabled}
                                searchable={props.selectState.searchable}
                                id={`xeroInventoryItems-${props.ticket.id}`}
                                value={props.ticket.xeroInventoryItem.value ? props.ticket.xeroInventoryItem : ''}
                                onChange={e => {
                                    props.xeroInventoryItemsChange(e, props.ticket.id);
                                }}
                            />
                        </div>
                    </FormGroup>
                </Col>

                <Col xs={3}>
                    <FormGroup>
                        <Label for="eligibleCompanies">Price</Label>
                        <Input
                            style={{ width: '100px' }}
                            id={`price-${props.ticket.id}`}
                            key={`price-${props.ticket.id}`}
                            onChange={props.textChange}
                            value={props.ticket.price}
                        />
                    </FormGroup>
                    {/* <InputFieldWithValidation
                        disabled
                        colWidth="7"
                        type="number"
                        label="Price *"
                        valueKey={`price-${props.ticket.id}`}
                        value={props.ticket.price}
                        validationObj={{}}
                        handleChange={props.textChange}
                    /> */}
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <FormGroup className="col-sm-8">
                        <Label>Tickets available from *</Label>
                        <CyderDatePicker
                            value={props.ticket.availableStartDate}
                            closeOnSelect={true}
                            id={`availableStartDate-${props.ticket.id}`}
                            onChange={e => {
                                props.dateChange(e, 'availableStartDate', props.ticket.id);
                            }}
                            dateFormat="DD/MM/YYYY"
                        />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup className="col-sm-10">
                        <Label>Tickets no longer available after *</Label>
                        <CyderDatePicker
                            value={props.ticket.availableEndDate}
                            closeOnSelect={true}
                            id={`availableEndDate-${props.ticket.id}`}
                            onChange={e => {
                                props.dateChange(e, 'availableEndDate', props.ticket.id);
                            }}
                            dateFormat="DD/MM/YYYY"
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <FormGroup className="col-sm-4">
                    <Label check>
                        <Input
                            type="checkbox"
                            id={`onlyAvailableForSGBCMembers-${props.ticket.id}`}
                            checked={new Boolean(props.ticket.onlyAvailableForSGBCMembers).valueOf()}
                            onChange={props.checkboxChange}
                        />{' '}
                        Only available for SGBC members
                    </Label>
                </FormGroup>
            </Row> */}
            <Row>
                <FormGroup className="col-sm-4">
                    <Label check>
                        <Input
                            type="checkbox"
                            id={`visibleOnRegPage-${props.ticket.id}`}
                            checked={new Boolean(props.ticket.visibleOnRegPage).valueOf()}
                            onChange={props.checkboxChange}
                        />
                        Visible on Registration
                    </Label>
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className="col-sm">
                    <Label className="float-left">
                        {props.ticket.ticketUsed && <em>Note: This ticket can no longer be deleted, because it has been purchased.</em>}
                    </Label>
                </FormGroup>

                <FormGroup className="col-sm">
                    <Button
                        id={`delete-${props.ticket.id}`}
                        className="primary-btn-style float-right"
                        disabled={props.ticket.ticketUsed}
                        onClick={e => {
                            let id = e.target.id.substr(7);
                            props.delete(id);
                        }}
                    >
                        Delete
                    </Button>
                </FormGroup>
            </Row>
        </Form>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        saveTickets: (id, tickets) => {
            return dispatch(saveTickets(id, tickets));
        },
        getAllTickets: id => {
            return dispatch(getAllTickets(id));
        },
        showLoading: () => {
            dispatch({ type: 'EVENT_UPDATE_TICKET_REQUEST' });
        },
        showSuccessUpdate: () => {
            dispatch({ type: 'EVENT_UPDATE_TICKET_SUCCESS' });
        },
        showFailureUpdate: () => {
            dispatch({ type: 'EVENT_UPDATE_TICKET_FAILURE' });
        },
        getXeroInventoryItems: () => {
            return dispatch(getXeroInventoryItems());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TicketsComponent);
