import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import Select from 'react-select';
import { Badge, Row, Col, Input, FormGroup, Label, Button, Card, CardBody } from 'reactstrap';
import cil from 'js/controlledInputLib';
import InputComponent from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import SecuringWrapper from 'cyder/common/SecuringWrapper';
import { validateFromValidationObject } from 'js/validation';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderHeaderPanel from 'cyder/panels/CyderHeaderPanel';
import {
    memberGetMemberDetails,
    staffGetMemberDetails,
    onChangeValue,
    resetState,
    setModalData,
    changeUserStatus,
    memberSaveMemberProfile,
    staffSaveMemberProfile,
    memberRemoveUserFromOrg,
    staffRemoveUserFromOrg,
    memberVerifyMember,
    transferMember,
    staffVerifyMember,
    forceValidate,
    forgotPassword,
    getSalutationTypes,
    onChangeSalutation,
} from 'actions/shared/teamMemberDetailsAction';
import { getCompanies } from 'actions/security/cyderSecurityAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';
import { asyncSelectStyle } from '../public/PublicLogin';
import { propGenerator } from 'js/util';
import { Async } from 'react-select';

class TeamMemberDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.toggleModalRemoveUser = this.toggleModalRemoveUser.bind(this);
        this.toggleModalVerifyUser = this.toggleModalVerifyUser.bind(this);
        this.toggleTranferMember = this.toggleTranferMember.bind(this);
        this.toggleModalResetPassword = this.toggleModalResetPassword.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.getCompanies = this.getCompanies.bind(this);

        this.state = {
            saveButtonPressed: false,
            salutation: {},
        };
        this.back = this.back.bind(this);
    }
    onCompanyChange(e) {
        this.setState({
            selectedCompany: e,
            selectedCompanyId: e.value,
        });
    }
    async getCompanies(input) {
        try {
            const inputLength = input.length;
            const companiesObj = await this.props.getCompanies(input);
            const companies = companiesObj.data
                .map(company => {
                    const { name, id } = company;
                    if (name.substring(0, inputLength).toLowerCase() === input.toLowerCase()) {
                        return {
                            label: name,
                            value: id,
                        };
                    }
                })
                .filter(x => x);
            if (this._isMounted) {
                this.setState({
                    companies,
                });
            }
            return companies;
        } catch (error) {
            throw error;
        }
    }
    back() {
        //TODO
        //history.goBack();
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetState();
        if (this.props.match.params.companyid) {
            // Staff -> team management
            this.props.staffGetMemberDetails(this.props.match.params.memberid);
        } else {
            // Member -> team management
            this.props.memberGetMemberDetails(this.props.match.params.memberid);
        }
        this.props.getSalutationTypes();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.memberid !== this.props.match.params.memberid) {
            this.componentDidMount();
        }
    }
    generateProps(valueKey) {
        return propGenerator({
            valueKey,
            fields: this.props.fields,
            validationObj: this.props.validation,
            handleChange: this.props.onChangeValue,
            saveButtonPressed: this.state.saveButtonPressed,
        });
    }

    // Not idea what this is
    generatePropsCheckbox(valueKey) {
        return propGenerator({
            valueKey,
            fields: this.props.fields,
            handleChange: e => this.props.onChangeValue(cil.generateCheckboxSynthEvent(e, 1, 0)),
        });
    }
    toggleModalChangeUserState(suspend) {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.changeUserStatus(suspend),
            modalBody: `Click OK to ${!suspend ? 'un' : ''}suspend user.`,
        });
    }
    toggleModalRemoveUser() {
        const { isTeamMember } = this.props.fields;

        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.removeUserFromOrg(),
            modalBody: `Click OK to remove ${isTeamMember ? 'user' : 'subscriber'}.`,
        });
    }
    toggleTranferMember() {
        const { isTeamMember } = this.props.fields;

        const modalBody = (
            <div>
                Please select new company
                <br />
                <Async
                    name="company"
                    placeholder=""
                    cacheOptions
                    defaultOptions
                    loadOptions={this.getCompanies}
                    onChange={this.onCompanyChange}
                    styles={asyncSelectStyle}
                    className="input-transparent"
                />
            </div>
        );

        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => {
                this.props.transferMember(this.props.match.params.memberid, this.state.selectedCompanyId, isTeamMember);
            },
            modalBody,
        });
    }
    toggleModalVerifyUser() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.verifyUser(this.props.match.params.companyid ? true : false),
            modalBody: 'Click OK to verify user.',
        });
    }
    toggleModalResetPassword() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.forgotPassword(this.props.fields.email),
            modalBody: 'Click OK to reset user password.',
        });
    }
    toggleModalForSave() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => {
                const { companyid, memberid } = this.props.match.params;
                this.props.saveMemberProfile(companyid ? true : false, companyid, memberid);
            },
            modalBody: 'Click OK to save changes.',
        });
    }
    forceValidateThenToggleModalForSave() {
        this.setState({ saveButtonPressed: true });
        return this.props.forceValidate().then(() => {
            const keysToValidate = {
                firstName: true,
                lastName: true,
                email: true,
                telephoneDid: true,
                mobileNumber: true,
                designation: true,
                department: true,
            };
            const valid = validateFromValidationObject(this.props.validation, keysToValidate);
            if (valid === true) {
                // only show the dialog box if validation is passed
                return this.toggleModalForSave();
            }
            /**
             * If validation fails, do nothing. Note that validation messages
             * are handled with reactstrap-input
             *
             * SaveButtonPressed controls whether reactstrap-input validates
             * input or not. It can be set to false during initialization, and
             * only changed to true during forceUpdate, so that validation
             * does not happen immediately. Or, pass it as true to
             * InputComponents to enable validation straightaway
             */
            return Promise.resolve();
        });
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    render() {
        const userId = this.props.profile.person_id;
        const memberId = parseInt(this.props.match.params.memberid);

        if (this.props.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    forceModalAction={this.props.forceModalAction}
                    boundForceUpdate={this.boundForceUpdate}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                {/* <Row>
                    <Col xs={12}>
                    <Button color="warning" className="text-bold" onClick={this.back}>
                                Back
                    </Button>
                    </Col>
                </Row> */}
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderHeaderPanel leftText={this.props.fields.isTeamMember == 1 ? 'Team Member Details' : 'Subscriber Details'} />

                        <div className="d-flex flex-row-reverse">
                            {/* <div>
                                <h4>
                                    <strong>Team Member Details</strong>
                                </h4>
                            </div> */}
                            {this.props.match.params.params && (
                                <div className="ml-auto">
                                    {this.props.fields.isTeamMember === 1 && <Badge color="success">Verified</Badge>}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                <Card className="mb-4">
                    <CardBody>
                        <br />
                        <Row>
                            <Col xs={2}>
                                <FormGroup>
                                    <Label className="text-bold">Salutation</Label>
                                    <Select
                                        autosize={false}
                                        options={this.props.salutationTypes}
                                        value={this.props.fields.salutation === null ? '' : this.props.fields.salutation}
                                        onChange={this.props.onChangeSalutation}
                                        searchable={false}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <InputComponent
                                    label="First Name*"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('firstName')}
                                />
                            </Col>
                            <Col xs={3}>
                                <InputComponent
                                    label="Last Name*"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('lastName')}
                                />
                            </Col>
                            <Col xs={5} />
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <InputComponent
                                    type="email"
                                    label="Email"
                                    inputProps={{ disabled: true }}
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('email')}
                                />
                            </Col>
                            <Col xs={2}>
                                <InputComponent
                                    label="Telephone"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('telephoneDid')}
                                />
                            </Col>
                            <Col xs={2}>
                                <InputComponent
                                    label="Mobile Number*"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('mobileNumber')}
                                />
                            </Col>
                            <Col xs={4} />
                        </Row>

                        <Row>
                            <Col xs={3}>
                                <InputComponent
                                    label="Designation*"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('designation')}
                                />
                            </Col>
                            <Col xs={3}>
                                <InputComponent
                                    label="Department"
                                    labelStyle={{ fontWeight: 'bold' }}
                                    {...this.generateProps('department')}
                                />
                            </Col>
                            <Col xs={4} />
                        </Row>
                        {this.props.fields.isTeamMember == 1 && this.props.match.params.memberid && (
                            <React.Fragment>
                                <h4>
                                    <strong>Roles</strong>
                                </h4>
                                <CheckboxFormGroup {...this.generatePropsCheckbox('isOmr')} label="Official Management Representative" />
                                <CheckboxFormGroup {...this.generatePropsCheckbox('isCorporateAdmin')} label="Corporate Administrator" />
                                <CheckboxFormGroup {...this.generatePropsCheckbox('isCertCoordinator')} label="Certification Coordinator" />
                                <CheckboxFormGroup
                                    {...this.generatePropsCheckbox('isHrtrainingCoordinator')}
                                    label="Learning & Development Coordinator"
                                />
                                {/*
                                <CheckboxFormGroup {...this.generatePropsCheckbox('isHrtrainingCoordinator')} label="HR Representative" />
                                <CheckboxFormGroup
                                    {...this.generatePropsCheckbox('isSecondarymr')}
                                    label="Secondary Management Representative"
                                />*/}
                            </React.Fragment>
                        )}

                        <br />
                        <Row style={{ marginBottom: '20px' }}>
                            <Col xs={12} className="d-flex">
                                {this.props.fields.isTeamMember == 1 && (
                                    <React.Fragment>
                                        {this.props.fields.isApproved !== 1 ? (
                                            <SecuringWrapper isCorporateAdmin isStaff isCertCoordinator isOmr isSecondarymr>
                                                <Button onClick={this.toggleModalVerifyUser} color="success" className="mr-1">
                                                    Verify
                                                </Button>
                                            </SecuringWrapper>
                                        ) : userId !== memberId ? (
                                            <React.Fragment>
                                                {this.props.fields.isApproved === 1 ? (
                                                    <SecuringWrapper isCorporateAdmin isCertCoordinator isSecondarymr isStaff isOmr>
                                                        <Button
                                                            className="mr-1 primary-btn-style"
                                                            onClick={() => this.toggleModalChangeUserState(true)}
                                                        >
                                                            Suspend
                                                        </Button>
                                                    </SecuringWrapper>
                                                ) : (
                                                    <SecuringWrapper isCorporateAdmin isCertCoordinator isSecondarymr isStaff isOmr>
                                                        <Button
                                                            className="mr-1 primary-btn-style"
                                                            onClick={() => this.toggleModalChangeUserState(false)}
                                                        >
                                                            Unsuspend
                                                        </Button>
                                                    </SecuringWrapper>
                                                )}
                                            </React.Fragment>
                                        ) : null}
                                    </React.Fragment>
                                )}
                                {userId !== memberId ? (
                                    <SecuringWrapper isCorporateAdmin isCertCoordinator isSecondarymr isStaff isOmr>
                                        <Button className="primary-btn-style-outline" onClick={this.toggleModalRemoveUser}>
                                            Remove from Organization
                                        </Button>
                                    </SecuringWrapper>
                                ) : null}

                                {/* Added by Fernando 11 Sep 2019, 
                                https://cydersg.atlassian.net/browse/SGBCCRM-1583 */}
                                {userId !== memberId ? (
                                    <SecuringWrapper isCorporateAdmin isCertCoordinator isSecondarymr isStaff isOmr>
                                        <Button
                                            className="primary-btn-style-outline"
                                            onClick={this.toggleTranferMember}
                                            style={{ marginLeft: '5px' }}
                                        >
                                            Transfer Member
                                        </Button>
                                    </SecuringWrapper>
                                ) : null}

                                <div className="ml-auto">
                                    <SecuringWrapper isCorporateAdmin isStaff isCertCoordinator isOmr isSecondarymr>
                                        {this.props.fields.isTeamMember == 1 && (
                                            <Button
                                                className="mr-1 primary-btn-style-outline"
                                                onClick={() => this.toggleModalResetPassword()}
                                            >
                                                Reset password
                                            </Button>
                                        )}
                                        <Button className="primary-btn-style" onClick={() => this.forceValidateThenToggleModalForSave()}>
                                            Save
                                        </Button>
                                    </SecuringWrapper>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const CheckboxFormGroup = ({ value, valueKey, handleChange, label }) => (
    <FormGroup check>
        <Label data-value-key={valueKey} check>
            <Input checked={value === 1} data-value-key={valueKey} onChange={handleChange} type="checkbox" /> {label}
        </Label>
    </FormGroup>
);

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.teamMembersDetailsReducer,
        profile: state.profile.user,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        memberGetMemberDetails: id => {
            dispatch(memberGetMemberDetails(id));
        },
        staffGetMemberDetails: id => {
            dispatch(staffGetMemberDetails(id));
        },
        removeUserFromOrg: isStaff => {
            var path = window.location.pathname;
            if (path.toLowerCase().indexOf('/staff/') >= 0) {
                isStaff = true;
            } else {
                isStaff = false;
            }
            if (isStaff) {
                dispatch(staffRemoveUserFromOrg());
            } else {
                dispatch(memberRemoveUserFromOrg());
            }
        },
        transferMember: (memberId, companyId, isTeamMember) => {
            dispatch(transferMember(memberId, companyId, isTeamMember));
        },
        forgotPassword: email => {
            dispatch(forgotPassword(email));
        },
        saveMemberProfile: (isStaff, companyid, id) => {
            if (isStaff) {
                dispatch(staffSaveMemberProfile(companyid, id));
            } else {
                dispatch(memberSaveMemberProfile());
            }
        },
        verifyUser: isStaff => {
            if (isStaff) {
                dispatch(staffVerifyMember());
            } else {
                dispatch(memberVerifyMember());
            }
        },
        onChangeSalutation: e => {
            dispatch(onChangeSalutation(e));
        },
        onChangeValue: e => {
            dispatch(onChangeValue(e));
        },
        resetState: () => {
            dispatch(resetState());
        },
        setModalData: data => {
            dispatch(setModalData(data));
        },
        changeUserStatus: suspend => {
            dispatch(changeUserStatus(suspend));
        },
        forceValidate: () => {
            return dispatch(forceValidate());
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        getSalutationTypes: () => {
            dispatch(getSalutationTypes());
        },
        getCompanies: keyword => {
            return dispatch(getCompanies(keyword));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoredLayout(TeamMemberDetailsPage)));
