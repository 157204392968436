import React from 'react';

import { CardBody, Row, Col } from 'reactstrap';

const imageStyle = {
    display: 'block',
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    marginRight: '20px',
};

const DashboardWidgetComponent = ({ src, description, children, headerStyle }) => (
    <CardBody>
        <Row>
            {src && <img src={src} style={imageStyle} />}
            <Col>
                <h5 style={headerStyle || { marginBottom: src ? undefined : '4em' }}>
                    <strong>{description}</strong>
                </h5>
                {children}
            </Col>
        </Row>
    </CardBody>
);

export default DashboardWidgetComponent;
