import React from 'react';
import { SGBC_API_ROOT, SGBC_API_KEY } from 'config';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Input, Row, Col, Button, Card } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import DialogModal from 'cyder/modals/DialogModal';
import MandatoryStar from 'cyder/forms/MandatoryStar';

import withQuery from 'with-query';

import { setReducerModalState, defaultModalKeys } from '../../../../../../js/modal';
// import { charge } from 'actions/member/certification/applications/CertificatePayNowAction.js';

const StripeInputWrapper = props => {
    return (
        <div className="form-group">
            <div className="field-header">
                {props.label} <MandatoryStar />
                {props.wrap ? <Card className="p-2 col-12">{props.children}</Card> : props.children}
            </div>
            <small className="form-text text-muted">{props.description}</small>
        </div>
    );
};

class CreditCardPayment extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.onCardHolderNameChanged = this.onCardHolderNameChanged.bind(this);
        this.setButtonLoading = this.setButtonLoading.bind(this);
        this.state = {
            cardHolderName: '',
            loading: false,
            buttonLoading: false,
            ...defaultModalKeys,
        };
    }
    onCardHolderNameChanged(e) {
        this.setState({
            cardHolderName: e.target.value,
        });
    }
    setLoading(show) {
        this.setState({ loading: show });
    }
    setButtonLoading(show) {
        this.setState({ buttonLoading: show });
    }
    toggleDialofModal(header, body, action) {
        const data = {
            modalAction: action,
            modalBody: body,
            modalHeader: header,
            modalOnlyOneButton: true,
        };
        this.props.dialogModalToggle();
        const state = setReducerModalState(setReducerModalState, data);
        this.setState(state);
    }
    async submit(ev) {
        this.setButtonLoading(false);

        // User clicked submit
        let { token } = await this.props.stripe.createToken({
            name: this.state.cardHolderName,
        });

        this.setLoading(true);

        if (token == null) {
            this.setLoading(false);
            this.setButtonLoading(false);
            this.toggleDialofModal('Error', 'Your credit card is not valid');
            return;
        }
        var body = {
            amount: this.props.applicationInfo.price * 100,
            source: token.id,
            description: 'SGBC Certificate Payment',
            currency: 'sgd',
        };
        const url = withQuery(SGBC_API_ROOT + '/finances_v1/stripe', {
            action: 'charge',
        });
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    const updatePaymentUrl = withQuery(SGBC_API_ROOT + '/finances_v1/cn/payment', {
                        action: 'update-payment-creditcardCertificate',
                    });

                    var updatedPaymentBody = {
                        amountReceived: this.props.applicationInfo.price,
                        referenceNumber: `${token.card.object} ${token.card.brand} ...${token.card.last4}`,
                        remarks: `ClientIP ${token.client_ip}, CreatedTS ${token.created}, TokenID ${token.id}`,
                        id: this.props.applicationInfo.paymentId,
                    };

                    const paymentOption = {
                        body: JSON.stringify(updatedPaymentBody),
                        method: 'POST',
                        headers: {
                            'x-api-key': SGBC_API_KEY,
                            'Content-Type': 'application/json',
                            Authorization: 'allow',
                        },
                    };

                    fetch(updatePaymentUrl, paymentOption)
                        .then(responsePayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialofModal(
                                'Confirmation',
                                'Your credit payment was successful.',
                                // TODO:
                                // history.push(`/member/event/${this.props.registration.data.eventId}/registration`);
                                `/member/certification/${this.props.type}/applications/all`,
                            );
                        })
                        .catch(errorPayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialofModal('Error', 'We are sorry that this credit card payment is not successful');
                            throw errorPayment;
                        });
                } else {
                    alert('We are sorry that this credit card payment is not successful');
                }
            })
            .catch(error => {
                this.setLoading(false);
                this.setButtonLoading(false);

                this.toggleDialofModal('Error', 'We are sorry that this credit card payment is not successful');
                throw error;
            });
    }
    render() {
        const inputStyle = {
            base: {
                fontSize: '18px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
        };
        if (this.state.loading) return <LoadingSpinner />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.state.modalAction}
                    modalHeader={this.state.modalHeader}
                    modalBody={this.state.modalBody}
                    modalOnlyOneButton={this.state.modalOnlyOneButton}
                    loading={this.state.loading}
                    buttonDisabler={this.state.modalLoading}
                />
                <div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <Row>
                                <Col xs="4">
                                    <StripeInputWrapper wrap label="Credit Card Number" description="Please enter credit card number">
                                        <CardNumberElement style={inputStyle} />
                                    </StripeInputWrapper>
                                </Col>
                                <Col xs="4">
                                    <StripeInputWrapper label="Card Holder Name" description="Please enter card holder name">
                                        <Input
                                            style={{ borderRadius: 5 }}
                                            value={this.state.cardHolderName}
                                            onChange={this.onCardHolderNameChanged}
                                        />
                                    </StripeInputWrapper>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="4">
                                    <StripeInputWrapper wrap label="Credit Card Expiry" description="Please enter card expiry date">
                                        <CardExpiryElement style={inputStyle} />
                                    </StripeInputWrapper>
                                </Col>
                                <Col xs="4">
                                    <StripeInputWrapper wrap label="CCV" description="Please enter CCV">
                                        <CardCVCElement style={inputStyle} />
                                    </StripeInputWrapper>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <br />
                    <Row>
                        <Col className="d-flex flex-row">
                            <Button
                                style={{ marginRight: '10px' }}
                                className="primary-btn-style-outline"
                                onClick={this.props.back}
                                size="sm"
                            >
                                <strong>Select Alternative Payment</strong>
                            </Button>
                            {this.state.buttonLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Button className="primary-btn-style" onClick={this.submit} size="sm">
                                    <strong>Submit</strong>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-row pt-4 pb-4" xs={6}>
                            <img src="/assets/sgbc/SGBC_credit_cards_stripe.png" width={300} height={65} />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default injectStripe(CreditCardPayment);
