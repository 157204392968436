import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../../../../history';

import { Row, Col, Button } from 'reactstrap';
import SecuringWrapper from 'cyder/common/SecuringWrapper';
import { SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER } from 'config.js';

import {
    getMembershipApplicationTierResponse,
    getMembershipApplicationTierDocs,
} from 'actions/member/membership/application/membershipApplicationAction';

import { SGBC_INVOICE_DOWNLOAD_ROOT } from 'config';

class MembershipApplicationInformation extends React.Component {
    constructor(props) {
        super(props);
        this.renewMembership = this.renewMembership.bind(this);
        this.upgradeMembershipTier = this.upgradeMembershipTier.bind(this);
        this.state = {
            isOpenMembershipInformation: true,
            isOpenAccountInformation: true,
            isOpenResourceLibrary: true,
            isOpenUpcomingEvents: true,
            isOpenCertificates: true,
            hasQtnAnswers: false,
        };
    }
    renewMembership() {
        history.push('/member/membership/renewapplication');
    }
    upgradeMembershipTier() {
        let id = this.props.membershipApplicationInfo.id != null ? this.props.membershipApplicationInfo.id : 0;
        history.push(`/member/membership/questionnaires/${id}`);
    }
    tierResponsePostProcessor(tierResponseInfo) {
        const newResponse = {};
        tierResponseInfo.forEach(response => {
            if (!newResponse[response.question]) newResponse[response.question] = [];
            newResponse[response.question].push(response.option);
        });
        return newResponse;
    }
    render() {
        const applicationInfo = this.props.membershipApplicationInfo;
        const invoiceNumber = applicationInfo.invoiceNumber;
        const applicationStatus = applicationInfo.applicationStatus;
        const membershipInfoViewUrl = `/member/membership/viewapplication/${applicationInfo.id}`;
        const { applicationRefNo } = applicationInfo;

        // STATUS ID REASSIGNMENT
        let applicationStatusId;
        switch (applicationInfo.applicationStatusId) {
            case 0:
            case 6:
            case 7:
            case 8:
                applicationStatusId = 0;
                break;
            case 2:
            case 3:
            case 9:
            default:
                applicationStatusId = applicationInfo.applicationStatusId;
        }
        if (applicationStatusId === null) applicationStatusId = 0;
        // RETURN
        switch (true) {
            case applicationStatusId == 0:
                return (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <br />
                        {this.props.membershipInfo.renewalId && (
                            <Button className="primary-btn-style primary-btn-lg" onClick={this.renewMembership}>
                                <i className="fa fa-fw fa-newspaper-o" />
                                &nbsp; Renew Membership
                            </Button>
                        )}
                        <br />
                    </Col>
                );
            // WHEN MEMBERSHIP APPLICATION IS SAVED AS DRAFT
            case applicationStatusId == 1:
                return (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <br />
                        <ApplicationStatusPanel
                            applicationStatus={applicationStatus}
                            applicationStatusId={applicationStatusId}
                            applicationRefNo={applicationRefNo}
                            renewalId={this.props.membershipInfo.renewalId}
                        />
                        <Link to={`/member/membership/newapplication/2/${applicationInfo.id}`}>
                            <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
                                <Button className="primary-btn-style primary-btn-lg" onClick={() => {}}>
                                    Edit Application
                                </Button>
                            </SecuringWrapper>
                        </Link>
                        <br />
                    </Col>
                );
            case applicationStatusId == 2 || applicationStatusId == 3 || applicationStatusId == 9:
                if (this.props.isUpgradeMembershipShown) this.props.isUpgradeMembershipShown(true);
                return (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <br />
                        <ApplicationStatusPanel
                            applicationStatus={applicationStatus}
                            applicationStatusId={applicationStatusId}
                            applicationRefNo={applicationRefNo}
                            renewalId={this.props.membershipInfo.renewalId}
                        />
                        <Row>
                            <Col xs={12} sm={6}>
                                <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
                                    <Button className="primary-btn-style primary-btn-lg" onClick={e => history.push(membershipInfoViewUrl)}>
                                        <i className="fas fa-eye" /> &nbsp; View Application
                                    </Button>
                                </SecuringWrapper>
                            </Col>
                            {!SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER && (
                                <Col xs={12} sm={6} className="text-center">
                                    <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
                                        <Button className="primary-btn-style" onClick={this.upgradeMembershipTier}>
                                            <i className="fa fa-arrow-up" /> &nbsp; Complete Sustainability Commitment
                                        </Button>
                                    </SecuringWrapper>
                                </Col>
                            )}
                        </Row>
                    </Col>
                );
            case applicationStatusId == 4:
                return (
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <br />
                        <ApplicationStatusPanel
                            applicationStatus={applicationStatus}
                            applicationStatusId={applicationStatusId}
                            applicationRefNo={applicationRefNo}
                            renewalId={this.props.membershipInfo.renewalId}
                        />
                        <Row className="m-2">
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Button
                                    className="primary-btn-style primary-btn-lg"
                                    onClick={e => {
                                        window.location.href =
                                            `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=` + invoiceNumber;
                                    }}
                                >
                                    <i className="fas fa-file-download" />
                                    &nbsp; Download Invoice
                                </Button>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Button
                                    className="primary-btn-style primary-btn-lg"
                                    onClick={e => {
                                        history.push(`/member/membership/application/${applicationInfo.id}/paynow`);
                                    }}
                                >
                                    <i className="fa fa-dollar" />
                                    &nbsp; Make Payment
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                );
            default:
                if (this.props.isUpgradeMembershipShown) this.props.isUpgradeMembershipShown(false);
                return null;
        }
    }
}

const ApplicationStatusPanel = ({ applicationRefNo, applicationStatusId, applicationStatus, renewalId }) => {
    let status = applicationStatus.toLowerCase();
    switch (status) {
        case 'approved & pending payment':
            status = 'approved and will only be activated upon receipt of payment';
            break;
        case 'draft':
            status = 'saved as draft';
            break;
        default:
            break;
    }
    return (
        <Col className="p-1">
            <Row className="sgbcdashboarwidget-label mb-1 d-flex align-items-center">
                {applicationStatusId == 3 && (
                    <strong>Membership Application is being processed. SGBC will get in touch with you if required.</strong>
                )}
                {applicationStatusId == 9 && (
                    <React.Fragment>
                        <strong>We have received your payment. We are now preparing your membership certification.</strong>
                        <br />
                        <span>
                            Please click <b>View Application</b> button to view more details on this {renewalId != null && `renewal`}{' '}
                            membership application.
                            {!SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER && (
                                <span>
                                    If you wish to obtain upgraded tier on your membership, please click{' '}
                                    <b>Complete Sustainability Commitment</b> button.{' '}
                                </span>
                            )}
                        </span>
                    </React.Fragment>
                )}
                {applicationStatusId != 3 && applicationStatusId != 9 && (
                    <strong>
                        {renewalId && `Renewal`} {!renewalId ? 'Membership' : 'membership'} application has been {status}.
                    </strong>
                )}
                <br />
                {/* <i>
                    (This is your {renewalId != null && `renewal`} application reference number <b>{applicationRefNo}</b>. Please quote this
                    number should there is any inquiry regarding this application with us)
                </i> */}
            </Row>
            <br />
        </Col>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipAppllicationTierResponse: id => {
            return dispatch(getMembershipApplicationTierResponse(id));
        },
        getMembershipAppllicationTierDocs: id => {
            return dispatch(getMembershipApplicationTierDocs(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipApplicationInformation);
