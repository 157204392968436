import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function staffCertApplGreenAttributesGetInfo(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-other-data-options`, {
            action: 'get-all-by-certificate-id',
            certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function staffCertApplGreenAttributesGetCheckboxOptions() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/other-data-option`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function staffCertApplGreenAttributesSaveOptions(certificateId, options) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/other-data-option`, {
            action: 'update-by-certificate-id',
        });
        const body = { certificateId, options };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null, null);
    };
}
