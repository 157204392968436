import React from 'react';

const DetailsPageLabelItem = ({ label, content, noColon }) => (
    <div>
        <strong>
            {label}
            {!noColon && ':'}
        </strong>
        <br />
        {content || <br />}
    </div>
);
export default DetailsPageLabelItem;
