import React from 'react';
import { connect } from 'react-redux';

import { Row } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';

class ChequePayment extends React.Component {
    render() {
        return (
            <Row>
                <div className="col-8">
                    <PaymentInput
                        label="Payment Amount"
                        description="Please enter amount of payment"
                        onChange={e => this.props.onTextChanged(e.target.value, 'amountReceived')}
                        value={this.props.paymentInfo.amountReceived}
                    />
                </div>
                <br />
                <div className="col-12">
                    <label className="field-header">
                        Date of Payment <MandatoryStar />
                    </label>
                    <CyderDatePicker
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}
                        onChange={e => this.props.onDateChanged(e, 'datePaymentReceived', 'datePaymentReceivedCtrl')}
                        value={
                            this.props.paymentInfo.datePaymentReceivedCtrl == 'Invalid date'
                                ? ''
                                : this.props.paymentInfo.datePaymentReceivedCtrl
                        }
                    />
                    <small className="form-text text-muted">Please select date of payment</small>
                </div>
                <br />
                <div className="col-12">
                    <PaymentInput
                        label="Bank"
                        description="Please enter bank details"
                        onChange={e => this.props.onTextChanged(e.target.value, 'bank')}
                        value={this.props.paymentInfo.bank}
                    />
                </div>
                <br />
                <div className="col-12">
                    <PaymentInput
                        label="Cheque Number"
                        description="Please enter cheque number"
                        onChange={e => this.props.onTextChanged(e.target.value, 'referenceNumber')}
                        value={this.props.paymentInfo.referenceNumber}
                    />
                </div>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChequePayment);
