import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import history from '../../../../history';

import { Button, Card, CardBody, Row, Col } from 'reactstrap';
import SecuringWrapper from 'cyder/common/SecuringWrapper';
import MembershipTierHistoryPanel from 'pages/shared/MembershipTierHistoryPanel';
import { SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER } from 'config.js';
import { downloadCertificate } from 'actions/staff/company/staffCompanyDetailsMembershipAction';
import { printCertByMembershipId } from 'actions/member/membership/application/membershipApplicationAction';

class MembershipInformation extends React.Component {
    constructor(props) {
        super(props);
        this.printCertByMembershipId = this.printCertByMembershipId.bind(this);
        this.showLoadingPrintCert = this.showLoadingPrintCert.bind(this);
        this.upgradeMembershipTier = this.upgradeMembershipTier.bind(this);
        this.renewMembership = this.renewMembership.bind(this);
        this.signUp = this.signUp.bind(this);
        this.state = {
            isOpenMembershipInformation: true,
            isOpenAccountInformation: true,
            isOpenCertificates: true,
            isOpenResourceLibrary: true,
            isOpenUpcomingEvents: true,
            loadingPrintCert: false,
        };
    }
    showLoadingPrintCert(show) {
        this.setState({
            loadingPrintCert: show,
        });
    }
    formatDate(date) {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    }
    renewMembership() {
        history.push('/member/membership/renewapplication/1');
    }
    signUp() {
        history.push('/member/membership/newapplication/1');
    }
    printCertByMembershipId() {
        this.props.downloadCertificate(this.props.membershipInfo.companyId);
    }
    upgradeMembershipTier() {
        const { id } = this.props.applicationInfo;
        history.push(`/member/membership/questionnaires/${id || 0}`);
    }
    isMembershipApplInProgress() {
        if (this.props.applicationInfo == null) {
            return false;
        }
        const { applicationStatusId } = this.props.applicationInfo;
        switch (applicationStatusId) {
            case 1: // DRAFT
            case 2: // SUBMITTED
            case 3: // PROCESSING
            case 4: // APPROVED & PENDING PAYMENT
            case 9: // PAYMENT RECEIVED (CERT PREPARATION)
                return true;
            default:
                return false;
        }
    }
    render() {
        if (!this.props.membershipInfo || !this.props.membershipInfo.id) return '';
        const { isUpgradeMembershipShown, membershipInfo, expiredBeforeSixMonths } = this.props;
        const { corporateMember, foundingMember, dateJoined, expiryDate } = membershipInfo;
        const { renewalId } = membershipInfo;
        const isMembershipApplProgressing = this.isMembershipApplInProgress();

        const membershipInfoText = `Member since ${this.formatDate(dateJoined)}`;
        const tierInfoText = `(${membershipInfo.membershipTier === null ? 'Standard' : membershipInfo.membershipTier})`;
        return (
            <Card className="mt-4" style={{ width: '100%' }}>
                <CardBody>
                    <Row>
                        <Col xs="7">
                            <InfoComponent
                                color="info"
                                iconName="fa-id-card-o"
                                description={
                                    corporateMember === 1
                                        ? `Corporate Member ${tierInfoText}`
                                        : foundingMember === 1
                                            ? `Founding Member ${tierInfoText}`
                                            : ''
                                }
                            />
                        </Col>
                        <Col style={{ paddingTop: '10px' }} xs="5">
                            <MembershipTierHistoryPanel membershipId={this.props.membershipInfo.id} />
                        </Col>
                    </Row>

                    <InfoComponent color="success" iconName="fa-birthday-cake" description={membershipInfoText} />

                    <InfoComponent color="warning" iconName="fa-clock-o" description={expirationDescription(this.formatDate, expiryDate)} />
                    <div className="mb-1 d-flex align-items-center p-2">
                        <Row>
                            {expiredBeforeSixMonths && (
                                <div style={{ margin: 5 }}>
                                    <Button
                                        className="primary-btn-style primary-btn-lg"
                                        onClick={e => this.printCertByMembershipId()}
                                    >
                                        <i className="fas fa-file-download" />
                                        &nbsp; Download Certificate
                                    </Button>
                                </div>
                            )}
                            {!SGBC_DISABLED_MEMBERSHIP_UPGRADE_TIER && !renewalId && !isUpgradeMembershipShown && (
                                <div style={{ margin: 5 }}>
                                    <MembershipInfoButton label="Complete Sustainability Commitment" onClick={this.upgradeMembershipTier} />
                                </div>
                            )}
                            {renewalId && !isMembershipApplProgressing && expiredBeforeSixMonths && (
                                <div style={{ margin: 5 }}>
                                    <MembershipInfoButton label="Renew Membership" onClick={this.renewMembership} />
                                </div>
                            )}
                            {!isMembershipApplProgressing && !expiredBeforeSixMonths && (
                                <div style={{ margin: 5 }}>
                                    <MembershipInfoButton label="Sign Up" onClick={this.signUp} />
                                </div>
                            )}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

const expirationDescription = (formatDate, expiryDate) => {
    var description = '';
    if (moment(expiryDate).isBefore(moment())) {
        description = `Expired on ${formatDate(expiryDate)}`;
    } else {
        description = `Expiring on ${formatDate(expiryDate)} (expiring in ${moment
            .duration(moment(expiryDate).diff(moment()))
            .humanize()})`;
    }

    return description;
};

const MembershipInfoButton = props => {
    const { label, onClick } = props;
    return (
        <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
            <Button className="primary-btn-style" onClick={onClick}>
                <i className="fa fa-newspaper-o" /> &nbsp; {label}
            </Button>
        </SecuringWrapper>
    );
};

const InfoComponent = props => {
    const { description, iconName, color } = props;
    return (
        <div className="mb-1 d-flex align-items-center p-2">
            <i
                style={{
                    flexShrink: 1,
                }}
                className={`fa fa-fw text-${color} fa-2x ${iconName}`}
            />
            &nbsp;
            <span
                style={{
                    flexShrink: 9,
                }}
            >
                {description}
            </span>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        printCertByMembershipId: id => {
            return dispatch(printCertByMembershipId(id));
        },
        downloadCertificate: companyId => {
            return dispatch(downloadCertificate(companyId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MembershipInformation);
