import React from 'react';

import '../../css/cyder/table/dashboard-table.css';

const TableWidgetCertificateApplicationsWidget = ({ items }) => (
    <div data-dashboard-table="three-column">
        <div className="dashboard-table">
            <table className="table table-unbordered table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Company</th>
                        <th>Package</th>
                    </tr>
                </thead>
                <tbody>
                    {items &&
                        items.map((item, i) => (
                            <tr key={i}>
                                <td className="ticker">{item.id}</td>
                                <td className="ticker">{item.company}</td>
                                <td className="ticker">{item.type}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <button className={`btn btn-primary btn-circle btn-xs btn-flat`}>
                <span>more</span>
            </button>
        </div>
    </div>
);

export default TableWidgetCertificateApplicationsWidget;
