import InputFieldWithValidation from 'reactstrap-input';
import React from 'react';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import { connect } from 'react-redux';
import { Label, Row, Col, Form, FormGroup, Button, Alert, CardBody, Input } from 'reactstrap';
import { load, save } from 'actions/staff/dinner/events/eventsTicketAction';

class TicketsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.showLoadingSave = this.showLoadingSave.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.maxTableChange = this.maxTableChange.bind(this);
        this.minTableChange = this.minTableChange.bind(this);
        this.platinumChange = this.platinumChange.bind(this);
        this.goldChange = this.goldChange.bind(this);
        this.standardChange = this.standardChange.bind(this);
        this.looseseatChange = this.looseseatChange.bind(this);

        this.platinumPriceChange = this.platinumPriceChange.bind(this);
        this.goldPriceChange = this.goldPriceChange.bind(this);
        this.standardPriceChange = this.standardPriceChange.bind(this);
        this.looseseatPriceChange = this.looseseatPriceChange.bind(this);

        this.vipPriceChange = this.vipPriceChange.bind(this);
        this.vipChange = this.vipChange.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.save = this.save.bind(this);

        this.state = {
            loading: false,
            loadingSave: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            maxTable: 0,
            minTable: 0,
            platinum: 0,
            gold: 0,
            standard: 0,
            looseseat: 0,
            platinumPrice: 0,
            goldPrice: 0,
            standardPrice: 0,
            looseseatPrice: 0,
            vipPrice: 0,
            vip: 0,
            rest: 0,
        };
    }
    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoadingSave(show) {
        this.setState({
            loadingSave: show,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        let eventId = this.props.eventId;
        this.showLoading(true);
        this.props
            .load(eventId)
            .then(res => {
                if (res !== null && res.data !== null && res.data.length > 0) {
                    this.setState({
                        maxTable: res.data[0].maxTable,
                        minTable: res.data[0].minTable,
                        platinumPrice: res.data[0].platinumPrice,
                        goldPrice: res.data[0].goldPrice,
                        standardPrice: res.data[0].standardPrice,
                        looseseatPrice: res.data[0].looseSeatPrice,
                        platinum: res.data[0].platinum,
                        gold: res.data[0].gold,
                        standard: res.data[0].standard,
                        looseseat: res.data[0].looseSeat,
                        vip: res.data[0].vip,
                        vipPrice: res.data[0].vipPrice,
                    });
                }
                this.showLoading(false);
            })
            .catch(error => {
                this.showLoading(false);
            });
    }
    save() {
        let eventId = this.props.eventId;
        let ticket = {
            maxTable: this.state.maxTable,
            minTable: this.state.minTable,
            platinum: this.state.platinum,
            gold: this.state.gold,
            standard: this.state.standard,
            looseseat: this.state.looseseat,
            platinumPrice: this.state.platinumPrice,
            goldPrice: this.state.goldPrice,
            standardPrice: this.state.standardPrice,
            looseseatPrice: this.state.looseseatPrice,
            vip: this.state.vip,
            vipPrice: this.state.vipPrice,
        };
        this.showLoadingSave(true);
        this.props
            .save(eventId, ticket)
            .then(res => {
                this.showLoadingSave(false);
                this.showModal(`Confirmation`, `Ticket has been successfully saved`, `alert`, false, true);
                this.componentDidMount();
            })
            .catch(error => {
                this.showLoadingSave(false);
                this.showModal(`Error`, `Ticket cannot be saved`, `alert`, false, true);
            });
    }
    maxTableChange(e) {
        this.setState({
            maxTable: e.target.value,
        });
    }
    minTableChange(e) {
        this.setState({
            minTable: e.target.value,
        });
    }
    platinumChange(e) {
        this.setState({
            platinum: e.target.value,
        });
    }
    goldChange(e) {
        this.setState({
            gold: e.target.value,
        });
    }
    standardChange(e) {
        this.setState({
            standard: e.target.value,
        });
    }
    looseseatChange(e) {
        this.setState({
            looseseat: e.target.value,
        });
    }
    platinumPriceChange(e) {
        this.setState({
            platinumPrice: e.target.value,
        });
    }
    goldPriceChange(e) {
        this.setState({
            goldPrice: e.target.value,
        });
    }
    standardPriceChange(e) {
        this.setState({
            standardPrice: e.target.value,
        });
    }
    looseseatPriceChange(e) {
        this.setState({
            looseseatPrice: e.target.value,
        });
    }
    vipPriceChange(e) {
        this.setState({
            vipPrice: e.target.value,
        });
    }
    vipChange(e) {
        this.setState({
            vip: e.target.value,
        });
    }
    render() {
        const { maxTable, minTable, vip, gold, standard, platinum } = this.state;
        const typesCombined = Number(vip) + Number(gold) + Number(standard) + Number(platinum);
        const rest = Number(maxTable) - typesCombined;

        return (
            <div className="event-ticket">
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <DialogModal
                            modalAction={this.modalAction}
                            negativeButtonAction={this.negativeModalAction}
                            actionToggleExternal={this.modalToggle}
                            modalOpen={this.state.modal.showModal}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                        />
                        <Row>
                            <Col xs={12}>
                                <h5>
                                    Available Table: <span className={rest < 0 ? 'deficit' : ''}>{rest}</span>
                                </h5>
                                {rest < 0 && <Alert color="danger">There's no table available</Alert>}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <InputFieldWithValidation
                                colWidth="2"
                                type="number"
                                label="Max Tables"
                                valueKey="placeholder"
                                value={this.state.maxTable}
                                validationObj={{}}
                                handleChange={this.maxTableChange}
                            />
                            <InputFieldWithValidation
                                colWidth="2"
                                type="number"
                                label="Min Tables"
                                value={this.state.minTable}
                                valueKey="placeholder"
                                validationObj={{}}
                                handleChange={this.minTableChange}
                            />
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12}>
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>VIP</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Number of table"
                                            valueKey="placeholder"
                                            value={this.state.vip}
                                            validationObj={{}}
                                            handleChange={this.vipChange}
                                        />
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Price per table"
                                            valueKey="placeholder"
                                            value={this.state.vipPrice}
                                            validationObj={{}}
                                            handleChange={this.vipPriceChange}
                                        />
                                    </Row>
                                </Form>
                                <br />
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Platinum</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Number of table"
                                            valueKey="placeholder"
                                            value={this.state.platinum}
                                            validationObj={{}}
                                            handleChange={this.platinumChange}
                                        />
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Price per table"
                                            valueKey="placeholder"
                                            value={this.state.platinumPrice}
                                            validationObj={{}}
                                            handleChange={this.platinumPriceChange}
                                        />
                                    </Row>
                                </Form>
                                <br />
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Gold</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Number of table"
                                            valueKey="placeholder"
                                            value={this.state.gold}
                                            validationObj={{}}
                                            handleChange={this.goldChange}
                                        />
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Price per table"
                                            valueKey="placeholder"
                                            value={this.state.goldPrice}
                                            validationObj={{}}
                                            handleChange={this.goldPriceChange}
                                        />
                                    </Row>
                                </Form>
                                <br />
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Standard</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Number of table"
                                            valueKey="placeholder"
                                            value={this.state.standard}
                                            validationObj={{}}
                                            handleChange={this.standardChange}
                                        />
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Price per table"
                                            valueKey="placeholder"
                                            value={this.state.standardPrice}
                                            validationObj={{}}
                                            handleChange={this.standardPriceChange}
                                        />
                                    </Row>
                                </Form>
                                <br />
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <h3>Standard Individual</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Number of seats"
                                            valueKey="placeholder"
                                            value={this.state.looseseat}
                                            validationObj={{}}
                                            handleChange={this.looseseatChange}
                                        />
                                        <InputFieldWithValidation
                                            colWidth="2"
                                            type="number"
                                            label="Price per seat"
                                            valueKey="placeholder"
                                            value={this.state.looseseatPrice}
                                            validationObj={{}}
                                            handleChange={this.looseseatPriceChange}
                                        />
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mt-2">
                            <Col>
                                {this.state.loadingSave ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Button className="primary-btn-style" onClick={this.save}>
                                        Save Tickets
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: (id, ticket) => {
            return dispatch(save(id, ticket));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TicketsComponent);
