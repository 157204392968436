import React from 'react';
import { PreviewPanelWrapper } from './PreviewPanel1';
import { Col } from 'reactstrap';

const PreviewPanel4 = ({ textColor, membershipApplicationProcessing }) => (
    <PreviewPanelWrapper textColor={textColor} icon="fa-users">
        <Col>
            <h4 className="pt-3">Members Applications Processing</h4>
        </Col>
        <Col className="text-right">
            <h1 className="display-4 text-bold">{membershipApplicationProcessing}</h1>
        </Col>
    </PreviewPanelWrapper>
);

export default PreviewPanel4;
