import DefaultSidebar1 from 'layouts/DefaultSidebar1';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import history from '../../../history';
import withQuery from 'with-query';
import Select from 'react-select';
import { Badge, Card, CardBody, Container, FormGroup, Label, Input, Row, Col, Form, Button } from 'reactstrap';
import {
    getAllPrivateNominees,
    getAllPublicNominees,
    getAllScrutineer,
    getVoterInfoByElectionId,
    findById,
    sendMassVotingKey,
    sendMassVotingReminder,
    sendScrutineerEmail,
} from 'actions/staff/election/action';
import { SGBC_S3_ROOT } from 'config';

class ElectionsDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.publicNominees = this.publicNominees.bind(this);
        this.privateNominees = this.privateNominees.bind(this);
        this.scrutineers = this.scrutineers.bind(this);
        this.showLoadingSendMassVoterKey = this.showLoadingSendMassVoterKey.bind(this);
        this.showLoadingSendMassReminder = this.showLoadingSendMassReminder.bind(this);
        this.showLoadingSendScrutineerEmail = this.showLoadingSendScrutineerEmail.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.sendMassVotingKey = this.sendMassVotingKey.bind(this);
        this.sendMassVotingReminder = this.sendMassVotingReminder.bind(this);
        this.sendScrutineerEmail = this.sendScrutineerEmail.bind(this);
        this.voters = this.voters.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            loadingSendMassVoterKey: false,
            loadingSendMassReminder: false,
            loadingSendScrutineerEmail: false,
            vkes: false,
            rtves: false,
            selectedOption: null,
            pubNominees: [],
            privNominees: [],
            scrutineers: [],
            data: {},
            voterInfo: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'alert',
            },
        };
    }
    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        } else if (this.state.modal.type === 'alertrefresh') {
            this.modalToggle();
            this.componentDidMount();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalAction = this.modalAction;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    sendScrutineerEmail(scrutineerId) {
        this.showLoadingSendScrutineerEmail(true);
        this.props
            .sendScrutineerEmail(scrutineerId)
            .then(res => {
                this.showLoadingSendScrutineerEmail(false);
                this.showModal('Confirmation', `Scrutineer key has been successfully sent`, 'alertrefresh', false, true);
            })
            .catch(error => {
                this.showLoadingSendScrutineerEmail(false);
                this.showModal('Error', `An error occured during sending scrutineer key`, 'alert', false, true);
                throw error;
            });
    }
    sendMassVotingReminder() {
        this.showLoadingSendMassReminder(true);
        this.props
            .sendMassVotingReminder(this.props.match.params.electionid)
            .then(res => {
                this.showLoadingSendMassReminder(false);
                this.showModal('Confirmation', `Reminder emails have been successfully sent`, 'alertrefresh', false, true);
            })
            .catch(error => {
                this.showLoadingSendMassReminder(false);
                this.showModal('Error', `An error occured during sending mass reminder email`, 'alert', false, true);
                throw error;
            });
    }
    sendMassVotingKey() {
        this.showLoadingSendMassVoterKey(true);
        this.props
            .sendMassVotingKey(this.props.match.params.electionid)
            .then(res => {
                this.showLoadingSendMassVoterKey(false);
                this.showModal('Confirmation', `Voter keys emails have been successfully sent`, 'alertrefresh', false, true);
            })
            .catch(error => {
                this.showLoadingSendMassVoterKey(false);
                this.showModal('Error', `An error occured during sending mass voter keys`, 'alert', false, true);
                throw error;
            });
    }
    showLoadingSendScrutineerEmail(show) {
        this.setState({
            loadingSendScrutineerEmail: show,
        });
    }
    showLoadingSendMassVoterKey(show) {
        this.setState({
            loadingSendMassVoterKey: show,
        });
    }
    showLoadingSendMassReminder(show) {
        this.setState({
            loadingSendMassReminder: show,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    async getRelevantStuffs(electionid) {
        try {
            this.showLoading(true);
            const publicNomineesRes = await this.props.getAllPublicNominees(electionid);
            const privateNominees = await this.props.getAllPrivateNominees(electionid);
            const voterInfoRes = await this.props.getVoterInfoByElectionId(electionid);
            const scrutineerRes = await this.props.getAllScrutineer(electionid);
            const findByIdRes = await this.props.findById(electionid);
            this.setState({
                pubNominees: publicNomineesRes.data,
                scrutineers: scrutineerRes.data,
                privNominees: privateNominees.data,
                voterInfo: voterInfoRes.data[0][0],
                data: findByIdRes.data,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    componentDidMount() {
        this.getRelevantStuffs(this.props.match.params.electionid);
    }
    publicNominees() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}/nominee/1`);
    }
    privateNominees() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}/nominee/2`);
    }
    scrutineers() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}/scrutineers`);
    }
    voters() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}/voters`);
    }
    handleChange(selectedOption) {
        this.setState({ selectedOption });
    }
    handleChange2(selectedOption2) {
        this.setState({ selectedOption2 });
    }
    ss(variable) {
        let obj = {};
        obj[variable] = true;
        this.setState(obj);
    }
    render() {
        // TODO:
        //const ended = this.props.match.params.electionid === '1';
        const ended = moment().isAfter(this.state.data.endDate);
        const isUnlock = this.state.data.resultsUnlockedByScrutineers === 1 ? true : false;
        return (
            <div className="page-widget">
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <DialogModal
                            modalAction={this.modalAction}
                            negativeButtonAction={this.negativeModalAction}
                            actionToggleExternal={this.modalToggle}
                            modalOpen={this.state.modal.showModal}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                        />
                        <Row className="mb-2">
                            <Col>
                                <Card>
                                    <CardBody className="d-flex">
                                        <div className="d-flex flex-column-reverse mb-2">
                                            <div className="ml-1">
                                                <strong>Public Sector vacancies:</strong> {this.state.data.publicSectorVacancies}{' '}
                                                <strong>Private Sector vacancies:</strong> {this.state.data.privateSectorVacancies}
                                            </div>
                                            <div className="my-4">
                                                <div className="badge badge-info mr-2">
                                                    <strong>Start:</strong> {moment(this.state.data.startDate).format('DD/MM/YYYY')}
                                                </div>
                                                <div className="badge badge-info">
                                                    <strong>End:</strong> {moment(this.state.data.endDate).format('DD/MM/YYYY')}
                                                </div>
                                            </div>
                                            <h1 className="d-flex">
                                                <strong> {this.state.data.name}</strong>
                                                {/* <div className="d-flex flex-column justify-content-center ml-2">
                                                    {ended ? (
                                                        <div className="badge badge-danger">Ended</div>
                                                    ) : (
                                                        <div className="badge badge-success">Ongoing</div>
                                                    )}
                                                </div> */}
                                            </h1>
                                        </div>
                                        <div className="ml-auto d-flex justify-content-center flex-column">
                                            <Link to="/staff/elections/all">
                                                <Button
                                                    block
                                                    className="ml-2 align-middle primary-btn-style"
                                                    style={{ marginBottom: '10px' }}
                                                >
                                                    <i className="material-icons align-text-bottom">chevron_left</i> Back to Election List
                                                </Button>
                                            </Link>
                                            <Link
                                                to={
                                                    isUnlock
                                                        ? '/staff/elections/view/' + this.props.match.params.electionid + '/result'
                                                        : '/staff/elections/view/' + this.props.match.params.electionid + '/vault'
                                                }
                                            >
                                                <Button
                                                    block
                                                    className="ml-2 align-middle primary-btn-style"
                                                    disabled={ended ? false : true}
                                                >
                                                    <i className="material-icons align-text-bottom">{isUnlock ? 'lock_open' : 'lock'}</i>{' '}
                                                    Results Vault {isUnlock ? '(unlocked)' : '(locked)'}
                                                </Button>
                                            </Link>
                                            {/* <Button block className="mt-1" color="default">
                                                <i className="material-icons align-text-bottom">print</i> Print
                                            </Button> */}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h3>
                                            <strong>Nominees</strong>
                                        </h3>
                                        <h5>Public Sector ({this.state.pubNominees.length})</h5>
                                        <Button onClick={this.publicNominees} className="ml-2 align-middle primary-btn-style" block>
                                            Go to Public Nominees List
                                        </Button>

                                        {this.state.pubNominees.map((nominee, i) => {
                                            return (
                                                <div key={i} className="d-flex mb-2">
                                                    <div className="align-self-stretch">
                                                        <h5 className="mx-3">{i + 1}</h5>
                                                    </div>
                                                    <div>
                                                        <div>Name: {nominee.fullName}</div>
                                                        <div>Organization: {nominee.organizationName}</div>
                                                        <div>
                                                            CV & Manifesto:&nbsp;
                                                            <a target="blank" href={`${SGBC_S3_ROOT}${nominee.cvAndManifestoPdf}`}>
                                                                download
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <h5 className="mt-3">Private Sector ({this.state.privNominees.length})</h5>
                                        <Button onClick={this.privateNominees} className="ml-2 align-middle primary-btn-style" block>
                                            Go to Private Nominees List
                                        </Button>
                                        {this.state.privNominees.map((nominee, i) => {
                                            return (
                                                <div key={i} className="d-flex mb-2">
                                                    <div className="align-self-stretch">
                                                        <h5 className="mx-3">{i + 1}</h5>
                                                    </div>
                                                    <div>
                                                        <div>Name: {nominee.fullName}</div>
                                                        <div>Organization: {nominee.organizationName}</div>
                                                        <div>
                                                            CV & Manifesto:&nbsp;
                                                            <a target="blank" href={`${SGBC_S3_ROOT}${nominee.cvAndManifestoPdf}`}>
                                                                download
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h3>
                                            <strong>Scrutineers</strong>
                                        </h3>
                                        <Button onClick={this.scrutineers} className="ml-2 align-middle primary-btn-style" block>
                                            Go to Scrutineers List
                                        </Button>
                                        {this.state.scrutineers.map((scr, i) => (
                                            <div className="mb-2" key={i}>
                                                <h5>Scrutineer {i + 1}</h5>
                                                <div>
                                                    <strong>Name:</strong> {scr.fullName}
                                                </div>
                                                <div>
                                                    <strong>Designation:</strong> {scr.designation}
                                                </div>
                                                <div>
                                                    <strong>Organization:</strong> {scr.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Email:</strong> {scr.email}
                                                </div>
                                                <div>
                                                    <strong>Secret Key email sent:</strong>{' '}
                                                    {scr.scrutineerSecretKeyEmailSentTimestamp
                                                        ? `Yes on ${moment(scr.scrutineerSecretKeyEmailSentTimestamp).format(
                                                              'DD/MM/YYYY HH:mm',
                                                          )}`
                                                        : 'No'}
                                                </div>
                                                <Button
                                                    className="ml-2 align-middle primary-btn-style"
                                                    block
                                                    onClick={e => {
                                                        this.sendScrutineerEmail(scr.id);
                                                    }}
                                                >
                                                    Send Scrutineer Email
                                                </Button>
                                            </div>
                                        ))}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={4}>
                                <Card>
                                    <CardBody>
                                        <h3>
                                            <strong>Voters</strong>
                                        </h3>
                                        <h5>Total Eligible Voters</h5>
                                        <h4>
                                            <strong>{this.state.voterInfo.numberOfVoters}</strong>
                                        </h4>
                                        <h5>Total Votes Received</h5>
                                        <h4>
                                            <strong>
                                                {this.state.voterInfo.numberOfVotes} (
                                                {`${Math.round(
                                                    (this.state.voterInfo.numberOfVotes / this.state.voterInfo.numberOfVoters) * 100,
                                                )}%`}
                                                )
                                            </strong>
                                        </h4>
                                        <Button className="ml-2 align-middle primary-btn-style" block onClick={this.voters}>
                                            Go to Voter List
                                        </Button>

                                        <div className="mt-3">
                                            <strong>Voter Key email sent:</strong>{' '}
                                            {this.state.data.massSendVoterKeyEmailSentTimestamp != null
                                                ? `Yes on ${moment(this.state.data.massSendVoterKeyEmailSentTimestamp).format(
                                                      'DD/MM/YYYY HH:mm',
                                                  )}`
                                                : 'No'}
                                        </div>
                                        {this.state.loadingSendMassVoterKey ? (
                                            <LoadingSpinner />
                                        ) : (
                                            <Button onClick={this.sendMassVotingKey} className="ml-2 align-middle primary-btn-style" block>
                                                Send Mass Voter Key Email
                                            </Button>
                                        )}

                                        <div className="mt-3">
                                            <strong>Reminder to vote email sent:</strong>{' '}
                                            {this.state.data.massSendVoterReminderEmailSentTimestamp != null
                                                ? `Yes on ${moment(this.state.data.massSendVoterReminderEmailSentTimestamp).format(
                                                      'DD/MM/YYYY HH:mm',
                                                  )}`
                                                : 'No'}
                                        </div>
                                        {this.state.loadingSendMassReminder ? (
                                            <LoadingSpinner />
                                        ) : (
                                            <Button
                                                onClick={this.sendMassVotingReminder}
                                                className="ml-2 align-middle primary-btn-style"
                                                block
                                            >
                                                Send Mass Reminder Email
                                            </Button>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.userReducer,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAllPrivateNominees: id => {
            return dispatch(getAllPrivateNominees(id));
        },
        getAllPublicNominees: id => {
            return dispatch(getAllPublicNominees(id));
        },
        getAllScrutineer: id => {
            return dispatch(getAllScrutineer(id));
        },
        getVoterInfoByElectionId: id => {
            return dispatch(getVoterInfoByElectionId(id));
        },
        findById: id => {
            return dispatch(findById(id));
        },
        sendMassVotingKey: id => {
            return dispatch(sendMassVotingKey(id));
        },
        sendMassVotingReminder: id => {
            return dispatch(sendMassVotingReminder(id));
        },
        sendScrutineerEmail: scrutineerId => {
            return dispatch(sendScrutineerEmail(scrutineerId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DefaultSidebar1(ElectionsDetailPage));
