import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Link, withRouter } from 'react-router-dom';
import history from '../../../../history';
import moment from 'moment';
import { Input, Row, Col, Form, Button, Label } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputField from 'pages/admin/companies/shared/InputField';

import { save, update, remove, load } from 'actions/admin/auth/users/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        const type = props.location.state ? props.location.state.type : 0;
        this.passwordChange = this.passwordChange.bind(this);
        this.is_staffChange = this.is_staffChange.bind(this);
        this.is_activeChange = this.is_activeChange.bind(this);
        this.is_superuserChange = this.is_superuserChange.bind(this);
        this.is_certAdminChange = this.is_certAdminChange.bind(this);
        this.is_certManagerChange = this.is_certManagerChange.bind(this);
        this.is_membershipAdminChange = this.is_membershipAdminChange.bind(this);
        this.is_eventAdminChange = this.is_eventAdminChange.bind(this);
        this.is_mailingListAdminChange = this.is_mailingListAdminChange.bind(this);
        this.tfaEnabledChange = this.tfaEnabledChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.last_nameChange = this.last_nameChange.bind(this);
        this.first_nameChange = this.first_nameChange.bind(this);
        this.usernameChange = this.usernameChange.bind(this);
        this.idChange = this.idChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            data: {
                password: '',
                isActive: 1,
                isStaff: type === 'Staff' ? 1 : 0,
                isSuperuser: 0,
                isCertAdmin: 0,
                isCertManager: 0,
                isMembershipAdmin: 0,
                isEventAdmin: 0,
                isMailingListAdmin: 0,
                email: '',
                lastName: '',
                firstName: '',
                username: '',
                id: 0,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/auth/users');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.getUserData();
    }

    getUserData = async () => {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id && id !== '0') {
            this.props
                .load(id)
                .then(res => {
                    this.showLoading(false);
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        res.data.password = '';
                        console.log('res.data,: ', res.data);
                        res.data.dateJoined = res.data.dateJoined
                            ? moment(res.data.dateJoined, 'YYYY-MM-DD HH:mm:SS').format('DD-MM-YYYY HH:mm')
                            : '-';
                        res.data.lastLogin = res.data.lastLogin
                            ? moment(res.data.lastLogin, 'YYYY-MM-DD HH:mm:SS').format('DD-MM-YYYY HH:mm')
                            : '-';
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                    throw error;
                });
        } else {
            this.showLoading(false);
        }
    };
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (!data.id) {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/auth/users');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                    throw error;
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/auth/users', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                    throw error;
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/auth/users');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }
    passwordChange(e) {
        var data = this.state.data;
        data.password = e.target.value;
        this.setState({ data });
    }

    is_staffChange(e) {
        var data = this.state.data;
        data.isStaff = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }

    is_activeChange(e) {
        var data = this.state.data;
        data.isActive = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }

    is_superuserChange(e) {
        var data = this.state.data;
        data.isSuperuser = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    is_certAdminChange(e) {
        var data = this.state.data;
        data.isCertAdmin = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    is_certManagerChange(e) {
        var data = this.state.data;
        data.isCertManager = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    is_membershipAdminChange(e) {
        var data = this.state.data;
        data.isMembershipAdmin = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    is_eventAdminChange(e) {
        var data = this.state.data;
        data.isEventAdmin = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    is_mailingListAdminChange(e) {
        var data = this.state.data;
        data.isMailingListAdmin = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    tfaEnabledChange(e) {
        var data = this.state.data;
        data.tfaEnabled = new Number(e.target.checked).valueOf();
        this.setState({ data });
    }
    emailChange(e) {
        var data = this.state.data;
        data.email = e.target.value;
        this.setState({ data });
    }
    last_nameChange(e) {
        var data = this.state.data;
        data.lastName = e.target.value;
        this.setState({ data });
    }

    first_nameChange(e) {
        var data = this.state.data;
        data.firstName = e.target.value;
        this.setState({ data });
    }

    usernameChange(e) {
        var data = this.state.data;
        data.username = e.target.value;
        this.setState({ data });
    }

    idChange(e) {
        var data = this.state.data;
        data.id = e.target.value;
        this.setState({ data });
    }

    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <InputField
                    label="Is Staff"
                    inputProps={{
                        type: 'checkbox',
                        id: 'isStaff',
                        onChange: this.is_staffChange,
                        checked: new Boolean(this.state.data.isStaff).valueOf(),
                    }}
                />
                <InputField
                    label="ID"
                    inputProps={{
                        disabled: true,
                        type: 'text',
                        id: 'id',
                        value: this.state.data.id,
                        onChange: this.idChange,
                        style: { width: '150px' },
                    }}
                />
                <InputField
                    label="Username"
                    instruction="requires alphanumeric or valid email address"
                    inputProps={{
                        type: 'text',
                        id: 'username',
                        value: this.state.data.username,
                        onChange: this.usernameChange,
                        style: { width: '300px' },
                    }}
                />
                <InputField
                    label="Email"
                    instruction="requires valid email address"
                    inputProps={{
                        type: 'text',
                        id: 'email',
                        value: this.state.data.email,
                        onChange: this.emailChange,
                        style: { width: '300px' },
                    }}
                />
                <InputField
                    label="First Name"
                    instruction="requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        name: 'firstName',
                        id: 'firstName',
                        value: this.state.data.firstName,
                        onChange: this.first_nameChange,
                        style: { width: '400px' },
                    }}
                />
                <InputField
                    label="Last Name"
                    instruction="requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        id: 'lastName',
                        value: this.state.data.lastName,
                        onChange: this.last_nameChange,
                        style: { width: '400px' },
                    }}
                />
                <InputField label="Password">
                    <Input
                        style={{ width: '400px' }}
                        type="password"
                        name="password"
                        id="password"
                        value={this.state.data.password}
                        onChange={this.passwordChange}
                    />
                    <Label>
                        <small>leave empty if you do not decide to apply changes on current password</small>
                    </Label>
                </InputField>
                <InputField
                    label="Active"
                    inputProps={{
                        type: 'checkbox',
                        name: 'isActive',
                        id: 'isActive',
                        onChange: this.is_activeChange,
                        checked: new Boolean(this.state.data.isActive).valueOf(),
                    }}
                />
                {this.props.match.params.id !== '0' && this.state.data.isStaff ? (
                    <Fragment>
                        <InputField
                            label="Super User"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isSuperuser',
                                onChange: this.is_superuserChange,
                                checked: new Boolean(this.state.data.isSuperuser).valueOf(),
                            }}
                        />
                        <InputField
                            label="Cert Manager"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isCertManager',
                                onChange: this.is_certManagerChange,
                                checked: new Boolean(this.state.data.isCertManager).valueOf(),
                            }}
                        />
                        <InputField
                            label="Cert Admin"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isCertAdmin',
                                onChange: this.is_certAdminChange,
                                checked: new Boolean(this.state.data.isCertAdmin).valueOf(),
                            }}
                        />

                        <InputField
                            label="Membership Admin"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isMembershipAdmin',
                                onChange: this.is_membershipAdminChange,
                                checked: new Boolean(this.state.data.isMembershipAdmin).valueOf(),
                            }}
                        />
                        <InputField
                            label="Event Admin"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isEventAdmin',
                                onChange: this.is_eventAdminChange,
                                checked: new Boolean(this.state.data.isEventAdmin).valueOf(),
                            }}
                        />
                        <InputField
                            label="Mailing List Admin"
                            inputProps={{
                                type: 'checkbox',
                                id: 'isMailingListAdmin',
                                onChange: this.is_mailingListAdminChange,
                                checked: new Boolean(this.state.data.isMailingListAdmin).valueOf(),
                            }}
                        />
                        <InputField
                            label="TFA Enabled"
                            inputProps={{
                                type: 'checkbox',
                                id: 'tfaEnabled',
                                onChange: this.tfaEnabledChange,
                                checked: new Boolean(this.state.data.tfaEnabled).valueOf(),
                            }}
                        />
                    </Fragment>
                ) : null}
                <hr />
                <InputField
                    label="Joined Date"
                    readOnly
                    inputProps={{
                        type: 'text',
                        id: 'dateJoined',
                        value: this.state.data.dateJoined,
                        style: { width: '400px' },
                    }}
                />
                <InputField
                    label="Last Login"
                    readOnly
                    inputProps={{
                        type: 'text',
                        id: 'lastLogin',
                        value: this.state.data.lastLogin,
                        style: { width: '400px' },
                    }}
                />
                <hr />
                <FlexRow between="xs">
                    <div>
                        <Button className="text-bold primary-btn-style" onClick={this.save}>
                            Save
                        </Button>
                    </div>
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <Button className="text-bold primary-btn-style-outline" onClick={this.removeConfirm}>
                                Delete
                            </Button>
                            <AuditListPopup contentTypeId={4} objectId={this.state.data.id} />
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoredLayout(AddPage)));
