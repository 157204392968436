import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import { Alert, Table, Card, CardBody, Input, Row, Col, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';

import {
    getAllPrivateNominees,
    getAllPublicNominees,
    getAllScrutineer,
    getVoterInfoByElectionId,
    login,
    findById,
    vote,
} from 'actions/staff/election/action';

import { SGBC_S3_ROOT } from 'config';

class ElectionsDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.onVoterIdChange = this.onVoterIdChange.bind(this);
        this.onVoterKeyChange = this.onVoterKeyChange.bind(this);
        this.unlock = this.unlock.bind(this);

        this.showLoadingSubmitVote = this.showLoadingSubmitVote.bind(this);
        this.showLoadingElectionInfo = this.showLoadingElectionInfo.bind(this);
        this.showLoadingPostLoginInfo = this.showLoadingPostLoginInfo.bind(this);
        this.showLoadingVoterLogin = this.showLoadingVoterLogin.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.checkboxChange = this.checkboxChange.bind(this);

        this.vote = this.vote.bind(this);

        this.state = {
            d: false,
            voted: false,
            data: {},
            loadingElectionInfo: false,
            loadingVoterLogin: false,
            loadingPostLoginInfo: false,
            loadingLoadPrivate: false,
            loadingLoadPublic: false,
            loadingSubmitVote: false,
            voterLogin: {},
            pubNominees: [],
            privNominees: [],
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }

    vote() {
        this.showLoadingSubmitVote(true);
        this.props
            .vote(
                this.state.voterLogin.voterId,
                this.state.voterLogin.voterKey,
                this.props.match.params.electionid,
                this.state.pubNominees,
                this.state.privNominees,
            )
            .then(res => {
                this.showLoadingSubmitVote(false);
                if (res != null && res.data != null && res.data.errno != null) {
                    this.showModal(`Error`, `You have voted on this election, and are only allowed to vote once.`, `alert`, false, true);
                } else {
                    this.setState({
                        voted: true,
                    });
                }
            })
            .catch(error => {
                this.showLoadingSubmitVote(false);
                this.showModal(`Error`, `You have voted on this election, and are only allowed to vote once.`, `alert`, false, true);
                throw error;
            });
    }

    checkboxChange(e) {
        var nomineeId = e.target.id;
        let new_state = Object.assign({}, this.state);
        let pubNominees = new_state.pubNominees;
        let privNominees = new_state.privNominees;
        var privCount = 0;
        var pubCount = 0;
        privNominees.forEach(x => {
            if (x.voted === 1) {
                privCount = privCount + 1;
            }
        });

        pubNominees.forEach(x => {
            if (x.voted === 1) {
                pubCount = pubCount + 1;
            }
        });

        pubNominees.forEach(nominee => {
            if (nominee.id === parseInt(nomineeId)) {
                if (pubCount < this.state.data.publicSectorVacancies) {
                    nominee.voted = new Number(e.target.checked).valueOf();
                } else if (!e.target.checked) {
                    nominee.voted = new Number(e.target.checked).valueOf();
                }
            }
        });

        privNominees.forEach(nominee => {
            if (nominee.id === parseInt(nomineeId)) {
                if (privCount < this.state.data.privateSectorVacancies) {
                    nominee.voted = new Number(e.target.checked).valueOf();
                } else if (!e.target.checked) {
                    nominee.voted = new Number(e.target.checked).valueOf();
                }
            }
        });

        console.log('pubNominees', pubNominees);
        console.log('privNominees', privNominees);
        this.setState({ pubNominees, privNominees });
    }
    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    onVoterIdChange(e) {
        var voterLogin = this.state.voterLogin;
        voterLogin.voterId = e.target.value;
        this.setState({ voterLogin });
    }
    onVoterKeyChange(e) {
        var voterLogin = this.state.voterLogin;
        voterLogin.voterKey = e.target.value;
        this.setState({ voterLogin });
    }
    showLoadingPublic(show) {
        this.setState({
            loadingLoadPublic: show,
        });
    }
    showLoadingPrivate(show) {
        this.setState({
            loadingLoadPrivate: show,
        });
    }
    showLoadingSubmitVote(show) {
        this.setState({
            loadingSubmitVote: show,
        });
    }
    showLoadingElectionInfo(show) {
        this.setState({
            loadingElectionInfo: show,
        });
    }
    showLoadingPostLoginInfo(show) {
        this.setState({
            loadingPostLoginInfo: show,
        });
    }
    showLoadingVoterLogin(show) {
        this.setState({
            loadingVoterLogin: show,
        });
    }
    unlock() {
        this.showLoadingVoterLogin(true);
        this.props
            .login(this.state.voterLogin.voterId, this.state.voterLogin.voterKey, this.props.match.params.electionid)
            .then(res => {
                this.showLoadingVoterLogin(false);
                if (res == null || res.data == null || res.data.length <= 0 || res.data[0] == null) {
                    this.showModal(`Error`, `Invalid voter ID or key`, `alert`, false, true);
                } else {
                    if (res.data[0].voteCast == 1) {
                        this.showModal(
                            `Error`,
                            `You have voted on this election, and are only allowed to vote once.`,
                            `alert`,
                            false,
                            true,
                        );
                    } else {
                        var voterLogin = this.state.voterLogin;
                        voterLogin.companyName = res.data[0].companyName;
                        this.setState({ voterLogin });
                        this.setState({ d: true });

                        this.showLoadingPublic(true);
                        this.props
                            .getAllPublicNominees(this.props.match.params.electionid)
                            .then(res => {
                                this.setState({
                                    pubNominees: res.data,
                                });
                                this.showLoadingPublic(false);
                            })
                            .catch(error => {
                                this.showLoadingPublic(false);
                                throw error;
                            });

                        this.showLoadingPrivate(true);
                        this.props
                            .getAllPrivateNominees(this.props.match.params.electionid)
                            .then(res => {
                                this.setState({
                                    privNominees: res.data,
                                });
                                this.showLoadingPrivate(false);
                            })
                            .catch(error => {
                                this.showLoadingPrivate(false);
                                throw error;
                            });
                    }
                }
            })
            .catch(error => {
                this.showLoadingVoterLogin(false);
                this.showModal(`Error`, `Invalid voter ID or key`, `alert`, false, true);
                throw error;
            });
    }
    componentDidMount() {
        this.showLoadingElectionInfo(true);
        this.props
            .findById(this.props.match.params.electionid)
            .then(res => {
                this.setState({
                    data: res.data,
                });
                this.showLoadingElectionInfo(false);
            })
            .catch(error => {
                this.showLoadingElectionInfo(false);
                throw error;
            });
    }
    render() {
        const ended = this.props.match.params.electionid === '1';
        return moment().isBetween(moment(this.state.data.startDate), moment(this.state.data.endDate)) ? (
            this.state.loadingElectionInfo ? (
                <LoadingSpinner />
            ) : (
                <Row className="mb-2">
                    <DialogModal
                        modalAction={this.modalAction}
                        negativeButtonAction={this.negativeModalAction}
                        actionToggleExternal={this.modalToggle}
                        modalOpen={this.state.modal.showModal}
                        positiveButtonHide={this.state.modal.positiveButtonHide}
                        negativeButtonHide={this.state.modal.negativeButtonHide}
                        modalBody={this.state.modal.modalMessage}
                        modalHeader={this.state.modal.modalHeader}
                    />
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="mb-3" id="root" style={{ backgroundColor: 'unset' }}>
                                    <Col xs={12} className="text-center">
                                        <h1 style={{ marginBottom: '1em' }}>
                                            <strong>{this.state.data.name}</strong>
                                        </h1>
                                        <div
                                            style={{ marginBottom: '1em' }}
                                            dangerouslySetInnerHTML={{ __html: this.state.data.summary }}
                                        />
                                        <div dangerouslySetInnerHTML={{ __html: this.state.data.description }} />
                                        <br />
                                        <br />
                                        <div>
                                            <strong>Start:</strong> {moment(this.state.data.startDate).format('DD/MM/YYYY')}
                                            &nbsp;&nbsp;&nbsp;
                                            <strong>End:</strong> {moment(this.state.data.endDate).format('DD/MM/YYYY')}
                                        </div>
                                        <br />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
                                        {this.state.d ? (
                                            <div color={this.state.voted ? 'success' : 'info'}>
                                                {this.state.voted
                                                    ? `Thank you. Your vote has been successfully submitted.`
                                                    : 'Voter log-in successful. Please cast your vote.'}
                                            </div>
                                        ) : null}
                                        {this.state.d ? (
                                            this.state.voted ? null : (
                                                <div>
                                                    <h6 className="my-4">
                                                        Please cast your vote by marking a <i className="fa fa-check" /> tick in the box
                                                        next to the name of the nominee of your choice.
                                                    </h6>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <h4 className="mb-3">
                                                                <strong style={{ color: '#317446' }}>
                                                                    Public Sector Category - {this.state.pubNominees.length} Nominees
                                                                </strong>
                                                            </h4>
                                                            <h6>
                                                                <u>Tick a maximum of {this.state.data.publicSectorVacancies} boxes only</u>
                                                            </h6>
                                                            {this.state.loadingLoadPublic ? (
                                                                <LoadingSpinner />
                                                            ) : (
                                                                <Table>
                                                                    <thead>
                                                                        <tr className="text-bold">
                                                                            <td>Organization</td>
                                                                            <td>Name</td>
                                                                            <td>CV & Manifesto</td>
                                                                            <td />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.pubNominees.map(nominee => (
                                                                            <tr>
                                                                                <td>{nominee.organizationName}</td>
                                                                                <td>{nominee.fullName}</td>
                                                                                <td>
                                                                                {nominee.cvAndManifestoPdf ? 
                                                                                    <a
                                                                                        target="blank"
                                                                                        href={`${SGBC_S3_ROOT}${nominee.cvAndManifestoPdf}`}
                                                                                    >
                                                                                        <u>View</u>
                                                                                    </a>
                                                                                    : "-" }
                                                                                </td>
                                                                                <td>
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        id={`${nominee.id}`}
                                                                                        checked={new Boolean(nominee.voted).valueOf()}
                                                                                        onChange={this.checkboxChange}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <h4 className="mb-3">
                                                                <strong style={{ color: '#317446' }}>
                                                                    Private Sector Category - {this.state.privNominees.length} Nominees
                                                                </strong>
                                                            </h4>
                                                            <h6>
                                                                <u>Tick a maximum of {this.state.data.privateSectorVacancies} boxes only</u>
                                                            </h6>
                                                            {this.state.loadingLoadPrivate ? (
                                                                <LoadingSpinner />
                                                            ) : (
                                                                <Table>
                                                                    <thead>
                                                                        <tr>
                                                                            <td>Organization</td>
                                                                            <td>Name</td>
                                                                            <td>CV & Manifesto</td>
                                                                            <td />
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.privNominees.map(nominee => (
                                                                            <tr>
                                                                                <td>{nominee.organizationName}</td>
                                                                                <td>{nominee.fullName}</td>
                                                                                <td>
                                                                                    {nominee.cvAndManifestoPdf ? 
                                                                                    <a
                                                                                        target="blank"
                                                                                        href={`${SGBC_S3_ROOT}${nominee.cvAndManifestoPdf}`}
                                                                                    >
                                                                                        <u>View</u>
                                                                                    </a>
                                                                                    : "-"}
                                                                                </td>
                                                                                <td>
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        id={`${nominee.id}`}
                                                                                        checked={new Boolean(nominee.voted).valueOf()}
                                                                                        onChange={this.checkboxChange}
                                                                                    />
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                    <Row className="mt-5">
                                                        <Col xs={12}>
                                                            <h6>
                                                                <strong>
                                                                    I am submitting this vote on behalf of{' '}
                                                                    {this.state.voterLogin.companyName}.
                                                                </strong>
                                                            </h6>

                                                            <div className="text-center">
                                                                {this.state.loadingSubmitVote ? (
                                                                    <LoadingSpinner />
                                                                ) : (
                                                                    <Button className="primary-btn-style" onClick={() => this.vote()}>
                                                                        Submit Vote
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        ) : (
                                            <div>
                                                <div>
                                                    <InputFieldWithValidation
                                                        type="text"
                                                        label="Voter ID *"
                                                        validationObj={{}}
                                                        value={this.state.voterLogin.voterId}
                                                        handleChange={this.onVoterIdChange}
                                                    />
                                                    <InputFieldWithValidation
                                                        type="text"
                                                        label="Voter Key *"
                                                        validationObj={{}}
                                                        value={this.state.voterLogin.voterKey}
                                                        handleChange={this.onVoterKeyChange}
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    {this.state.loadingVoterLogin ? (
                                                        <LoadingSpinner />
                                                    ) : (
                                                        <Button className="primary-btn-style" onClick={this.unlock}>
                                                            Log-in to vote
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )
        ) : (
            <Col>
                <Card>
                    <CardBody>
                        <Row className="mb-3">
                            <Col xs={12} className="text-center">
                                <h1 style={{ marginBottom: '1em' }}>
                                    <strong>{this.state.data.name}</strong>
                                </h1>
                                <div style={{ marginBottom: '1em' }} dangerouslySetInnerHTML={{ __html: this.state.data.summary }} />
                                <div dangerouslySetInnerHTML={{ __html: this.state.data.description }} />
                                <br />
                                <br />
                                <div>
                                    <strong>Start:</strong> {moment(this.state.data.startDate).format('DD/MM/YYYY')}
                                    &nbsp;&nbsp;&nbsp;
                                    <strong>End:</strong> {moment(this.state.data.endDate).format('DD/MM/YYYY')}
                                </div>
                                <br />
                                <br />
                                <span>
                                    {moment().isAfter(this.state.data.endDate) && 'The voting period for this election has ended.'}
                                    {moment().isBefore(this.state.data.endDate) &&
                                        'The voting period for this election has not started yet.'}
                                </span>
                                <br />
                                <br />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.userReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        findById: id => {
            return dispatch(findById(id));
        },
        login: (voterId, voterKey, electionId) => {
            return dispatch(login(voterId, voterKey, electionId));
        },
        getAllPrivateNominees: id => {
            return dispatch(getAllPrivateNominees(id));
        },
        getAllPublicNominees: id => {
            return dispatch(getAllPublicNominees(id));
        },
        vote: (voterId, voterKey, electionId, pubNominees, privNominees) => {
            return dispatch(vote(voterId, voterKey, electionId, pubNominees, privNominees));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ElectionsDetailPage));
