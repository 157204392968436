/**
 * Product certificate renew page
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

// COMPONENTS
import { Container } from 'reactstrap';
import BigForm from 'cyder/forms/BigForm';
import DialogModal from 'cyder/modals/DialogModal';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import { ProductCertificationRenewPanel } from './components/ProductCertificationRenewPanel';

// ACTIONS
import {
    saveRenewCertApplication,
    setBrandEditable,
    getCertificateInfoById,
} from 'actions/member/certification/renew/memberProductRenewAction';
import { saveCertApplication, getRenewCertApplFromCertID } from 'actions/member/certification/applications/memberCertNewApplAction';

// UTIL
import { removeArrayItem } from 'js/util';

const productListTableState = {
    productBrands: [],
    brandNameField: '',
    modelNameField: '',
    modelDescriptionField: '',
    currentBrandToAddModel: 0,
    addModelFormVisible: true,
    addBrandFormVisible: true,
};
class ProductRenewPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.addBrand = this.addBrand.bind(this);
        this.addModel = this.addModel.bind(this);
        this.removeBrand = this.removeBrand.bind(this);
        this.removeModel = this.removeModel.bind(this);
        this.toggleAddBrandForm = this.toggleAddBrandForm.bind(this);
        this.toggleAddModelForm = this.toggleAddModelForm.bind(this);
        this.onGenericTextFieldChange = this.onGenericTextFieldChange.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.submitCertRenewAppl = this.submitCertRenewAppl.bind(this);
        this.viewAssessment = this.viewAssessment.bind(this);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            activeTab: 0,
            selectValue: '',
            clearable: true,
            certificateInfo: {},
            loading: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            // test
            ...productListTableState,
        };
    }
    componentDidMount() {
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    async getRelevantStuffs() {
        try {
            this.showLoading(true);
            const certId = this.props.match.params.certId;
            const certificateInfo = await this.props.getCertificateInfoById(certId);
            this.props.getRenewData(certId);
            if (this._isMounted) {
                this.setState({
                    productBrands: this.props.renew.productBrands,
                    certificateInfo: certificateInfo.data,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    viewAssessment() {
        history.push(
            `/member/certification/${this.props.renew.type}/viewassessment/renew/${this.state.certificateInfo.certificateApplicationId}/${
                this.props.renew.templateId
            }/${this.props.renew.assessmentId}`,
        );
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    onGenericTextFieldChange(id, e) {
        const newState = {};
        newState[id] = e.target.value;
        this.setState(newState);
    }
    toggleAddBrandForm() {
        this.setState({ addBrandFormVisible: !this.state.addBrandFormVisible });
    }
    addBrand() {
        const stateProductBrands = this.state.productBrands.push({
            name: this.state.brandNameField,
            models: [],
        });
        this.setState({
            stateProductBrands,
            brandNameField: '',
            addBrandFormVisible: !this.state.addBrandFormVisible,
        });
    }
    removeBrand(removeIndex) {
        const productBrands = this.state.productBrands;
        const remaining = removeArrayItem(productBrands, removeIndex);
        this.setState({ productBrands: remaining });
    }
    removeModel(brandIndex, removeIndex) {
        // Get product brand and models
        let productBrands = this.state.productBrands;
        let brand = this.state.productBrands[brandIndex];
        // Remove Items
        const remains = removeArrayItem(brand.models, removeIndex);
        // Reassignment
        brand.models = remains;
        productBrands[brandIndex] = brand;
        this.setState({ productBrands: productBrands });
    }
    toggleAddModelForm(i) {
        this.setState({
            currentBrandToAddModel: i,
            addModelFormVisible: !this.state.addModelFormVisible,
        });
    }
    addModel(brandIndex) {
        // Product model data structure
        const model = {
            name: this.state.modelNameField,
            description: this.state.modelDescriptionField,
        };
        // Data manipulation
        let stateProductBrands = this.state.productBrands;
        let brandInfo = stateProductBrands[brandIndex];
        let modelInfo = brandInfo.models;
        modelInfo.push(model);
        const newBrandInfo = Object.assign(brandInfo, {
            models: modelInfo,
        });
        stateProductBrands[brandIndex] = newBrandInfo;
        // Set state
        const newState = {
            ...this.state,
            stateProductBrands,
            modelNameField: '',
            modelDescriptionField: '',
        };
        this.setState(newState);
        this.toggleAddModelForm();
        // this.props.setSavedFlag(false); // TODO: restrict save if all fields not filled
    }
    constructBody() {
        //return this.props.renew.type === 'product'
        return {
            productType: this.props.renew.selectedProductTypeId,
            isManufacturer: this.props.renew.isManufacturer,
            productCatalogueFilename: this.props.renew.productCatalogueFilename,
            brands: JSON.stringify(this.props.renew.productBrands),
            manufacturerDetails: this.props.renew.manufacturerDetails,
            productCatalogue: this.props.renew.productCatalogue,
            productPicture: this.props.renew.productPicture,
            productPictureFilename: this.props.renew.productPictureFilename,
            certificateNumberToRenewOrAmend: this.props.renew.certificateNumber,
            certId: this.props.match.params.certId,
            templateId: this.props.renew.templateId,
            assessmentId: this.props.renew.assessmentId,
            productCategoryId: this.props.renew.productCategoryId,
            manufacturerCountryId: this.props.renew.manufacturerCountryId,
            manufacturerCountryName: this.props.renew.manufacturerCountryName,
            manufacturerFactoryAddress: this.props.renew.manufacturerFactoryAddress,
            manufacturerNameOfCompany: this.props.renew.manufacturerNameOfCompany,
            manufacturerPostalCode: this.props.renew.manufacturerPostalCode,
            manufacturerTelephone: this.props.renew.manufacturerTelephone,
            remarks: '',
            manufacturerContactPersonName: this.props.renew.manufacturerContactPersonName,
            greenRemarks: this.props.renew.greenRemarks,
        };
        // : {
        //       //not invoked
        //       isSelfFinancingCapable: this.props.renew.isSelfFinancingCapable,
        //       serviceCatalogueFilename: this.props.renew.productCatalogueFilename,
        //       serviceCategoryId: this.props.renew.serviceCategoryId,
        //       serviceCatalogue: this.props.renew.productCatalogue,
        //       productPictureFilename: this.props.renew.productPictureFilename,
        //       certificateNumberToRenewOrAmend: this.props.renew.certificateNumber,
        //       certId: this.props.match.params.certId,
        //       templateId: this.props.renew.templateId,
        //       assessmentId: this.props.renew.assessmentId,
        //       manufacturerCountryId: '',
        //       manufacturerCountryName: '',
        //       manufacturerFactoryAddress: '',
        //       manufacturerNameOfCompany: '',
        //       manufacturerPostalCode: '',
        //       remarks: '',
        //   };
    }
    async submitCertRenewAppl() {
        const type = this.props.renew.type; //'product  or service'
        const idObj = {};

        const body = {
            ...idObj,
            ...this.constructBody(),
        };
        try {
            const res = await this.props.saveRenewCertApplication(type, body);
            if (res.data.id) {
                history.push(`/member/certification/${type}/renew/application/` + res.data.id);
                return;
            }
            this.showModal('error', 'Failed to start certification renewal', '', false, true);
            throw this.props.error;
        } catch (error) {
            throw error;
        }
    }
    // MODAL
    modalAction = () => {
        this.modalToggle();
    };
    negativeModalAction = () => {
        this.modalToggle();
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    render() {
        const { modal } = this.state;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <Container>
                    <br />
                    <BigForm title={'Product Certificate Renewal'} className="ml-2">
                        <ProductCertificationRenewPanel
                            downloadShow={false}
                            toggle={this.toggle}
                            editBrand={false}
                            saveBrand={false}
                            certificate={this.state.certificateInfo}
                            submitRenewal={this.submitCertRenewAppl}
                            viewAssessment={this.viewAssessment}
                            shouldSubmitAssessmentDisabled={!this.props.renew.templateId && !this.props.renew.assessmentId}
                            /* EditableProductsListTable */
                            // DATA
                            productBrands={this.props.renew.productBrands}
                            // ACTIONS
                            addModel={this.addModel}
                            addBrand={this.addBrand}
                            removeModel={this.removeModel}
                            removeBrand={this.removeBrand}
                            toggleAddModelForm={this.toggleAddModelForm}
                            toggleAddBrandForm={this.toggleAddBrandForm}
                            onGenericTextFieldChange={this.onGenericTextFieldChange}
                            // CONTROLS
                            brandEditable={this.props.renew.brandEditable}
                            addBrandFormVisible={this.state.addBrandFormVisible}
                            addModelFormVisible={this.state.addModelFormVisible}
                            brandNameField={this.state.brandNameField} // brand name field
                            modelNameField={this.state.modelNameField} // model name field
                            modelDescriptionField={this.state.modelDescriptionField} // modal description field
                            currentBrandToAddModel={this.state.currentBrandToAddModel}
                        />
                    </BigForm>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        assessment: state.memberProductAssessment,
        application: state.memberProductApplication,
        review: state.memberProductReview,
        certifications: state.memberCertifications,
        renew: state.memberProductRenew,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificateInfoById: certId => {
            return dispatch(getCertificateInfoById(certId));
        },
        editBrand: () => {
            // User cannot edit anything, only staff can edit
            dispatch(setBrandEditable(false));
        },
        saveBrand: () => {
            // User cannot edit anything, only staff can edit
            dispatch(setBrandEditable(false));
        },
        getRenewData: certId => {
            return dispatch(getRenewCertApplFromCertID(certId));
        },
        saveRenewCertApplication: (type, body) => {
            return dispatch(saveRenewCertApplication(type, body, false));
        },
        saveCertApplication: (type, body, forSubmit) => {
            return dispatch(saveCertApplication(type, body, forSubmit));
        },
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductRenewPage),
);
