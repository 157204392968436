import StoredLayout from '../../layouts/StoredLayout';
import { Link } from 'react-router-dom';
import React from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    CardText,
    CardBody,
    CardTitle,
} from 'reactstrap';

// SGBC custom components
/*
import SGBCDashboardWidget from '../../cyder/widget/SGBCDashboardWidget'
import MembershipInformation from '../../cyder/member/MembershipInformation'
import Resources from '../../cyder/member/Resources' 
import MemberDownloads from '../../cyder/member/MemberDownloads'
*/

const Help = () => (
    <Container>
        <Row>
            <HelpPanel
                icon="help"
                title="Overview"
                items={['System Overview']}
            />
            <HelpPanel
                icon="group"
                title="Membership"
                items={['Membership Overview']}
            />
            <HelpPanel
                icon="stars"
                title="Certification"
                items={['Certification Overview']}
            />
            <HelpPanel icon="event" title="Events" />
            <HelpPanel
                icon="chat_bubble"
                title="Communication"
                items={['Certification Overview']}
            />
        </Row>
    </Container>
);

const HelpPanel = ({ icon, title, items }) => (
    <Col xs={4}>
        <Card className="mb-2">
            <CardTitle className="p-3">
                <i className="material-icons align-middle">{icon}</i>
                &nbsp;
                <span className="align-middle">{title}</span>
            </CardTitle>
            <CardBody>
                {items
                    ? items.map((item, i) => {
                          return (
                              <CardText key={i}>
                                  <Link to="#">
                                      {item}{' '}
                                      <i className="material-icons align-middle">
                                          open_in_new
                                      </i>
                                  </Link>
                              </CardText>
                          );
                      })
                    : null}
            </CardBody>
        </Card>
    </Col>
);
export default StoredLayout(Help);
