import React from 'react';
import Task from './Task';
import { random } from '../../functions';
import '../../css/elements/dropdown-tasks.css';

const DropdownTasks = ({ tasksForToday, tasksForTomorrow }) => (
    <div className="navbar-dropdown dropdown-tasks">
        <div className="dropdown-inner">
            <div className="dropdown-title">2 pending tasks</div>
            {tasksForToday.map((task, i) => (
                <Task key={i} {...task} />
            ))}
        </div>
    </div>
);

let tasksForToday = [
    {
        title: 'Veldon Corporation (Singapore) Pte Ltd (Membership)',
        color: 'secondary',
        value: random(50, 90),
    },
    {
        title: 'Protec Green Pte Ltd (Product Certification)',
        color: 'info',
        value: random(50, 90),
    },
];

let tasksForTomorrow = [];

const SampleDropdownTasks = () => (
    <DropdownTasks
        tasksForToday={tasksForToday}
        tasksForTomorrow={tasksForTomorrow}
    />
);

export default SampleDropdownTasks;
