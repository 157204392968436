import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { FormGroup, Input, Row, Col, Form, CardTitle } from 'reactstrap';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import {
    getActiveCertifiedProductCountAnalytic,
    getActiveCertifiedServiceCountAnalytic,
} from 'actions/analytics/certificateAnalyticsAction';
import CertificationActiveCertAnalyticsDetailsPopup from 'pages/analytics/components/certification/CertificationActiveCertAnalyticsDetailsPopup';

class ActiveCertificates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.loadProduct().then(res => {
            this.setState({
                productData: res.data[0],
            });
        });
        this.props.loadService().then(res => {
            this.setState({
                serviceData: res.data[0],
            });
        });
    }

    render() {
        return (
            <AnalyticsCard height={100}>
                {this.state.productData == null || this.state.serviceData == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CertificationActiveCertAnalyticsDetailsPopup
                            ref={instance => {
                                this.popup = instance;
                            }}
                        />
                        <CardTitle style={{ fontWeight: '600', fontSize: '14px' }}>Active Product Certificates</CardTitle>
                        <AnalyticsBigText
                            text={this.state.productData.activeProductCertificateCount}
                            onClick={e => {
                                this.popup.show('Active Product Certificates ');
                            }}
                            textColor={`#78BE20`}
                        />
                        <AnalyticsNormalText text={`Latest certificate issued ${this.state.productData.latestDay} days ago`} />
                        <hr />
                        <CardTitle style={{ fontWeight: '600', fontSize: '14px' }}>Active Service Certificates</CardTitle>
                        <AnalyticsBigText
                            text={this.state.serviceData.activeServiceCertificateCount}
                            onClick={e => {
                                this.popup.show('Active Services Certificates ');
                            }}
                            textColor={`#f8ac59`}
                        />
                        <AnalyticsNormalText text={`Latest certificate issued ${this.state.serviceData.latestDay} days ago`} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadProduct: () => {
            return dispatch(getActiveCertifiedProductCountAnalytic());
        },
        loadService: () => {
            return dispatch(getActiveCertifiedServiceCountAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ActiveCertificates));
