/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import StoredLayout from 'layouts/StoredLayout';

import { Button } from 'reactstrap';
import ScoresheetViewPanel from '../../assessment/components/ScoresheetViewPanel';

import { getApplicationInfo } from 'actions/staff/certification/staffCertificationApplicationAction';

import { actionExecutor } from 'js/util';

class AssessmentTemplatePreviewPage extends React.PureComponent {
    state = {
        categoryId: null,
    };
    componentDidMount() {
        this.getServiceCategory();
    }
    getServiceCategory() {
        const { applicationid } = this.props.match.params;
        const callback = async () => {
            const { data } = await this.props.getApplicationInfo(applicationid);
            if (!data) return;
            this.setState({
                categoryId: data.serviceCategoryId,
            });
        };
        if (applicationid) actionExecutor(() => {}, callback);
    }
    render() {
        return (
            <div className="page-widget">
                <div>
                    <Button
                        className="ml-auto primary-btn-style-outline"
                        onClick={e => {
                            history.push(`/staff/certification/applications/viewservice/${this.props.match.params.originapplicationid}`);
                        }}
                    >
                        <i className="mr-1 material-icons align-text-bottom ">keyboard_arrow_left</i>
                        Back
                    </Button>
                </div>
                <div>
                    <ScoresheetViewPanel
                        showInstruction
                        adjustPrintButton
                        type={this.props.type}
                        categoryId={this.state.categoryId}
                        templateId={this.props.match.params.templateid}
                        scoresheetId={this.props.match.params.scoresheetid}
                        applicationId={this.props.match.params.applicationid}
                        staff
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => ({
    getApplicationInfo: id => {
        return dispatch(getApplicationInfo(id));
    },
});

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AssessmentTemplatePreviewPage),
);
