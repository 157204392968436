import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Col, Row } from 'reactstrap';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import Truncate from 'react-truncate';

import '../../css/cyder/table/dashboard-table.css';

const TableWidgetUpcomingEvent = ({ items }) => {
    const rowRenderer = props => {
        const { style, key, index } = props;
        const { eventId, eventDescription, eventDate, eventSummary } = items[index];
        const formattedDate = moment(eventDate).format('DD/MM/YYYY');
        const linkTo = `/public/event/rsvp/${eventId}`;
        return (
            <Col key={key} style={style}>
                {index > 0 ? <hr /> : null}
                <Col className="pr-4 pl-4">
                    <Row className="ticker">{formattedDate}</Row>
                    <Row className="ticker">
                        <Link to={linkTo}>{eventDescription}</Link>
                    </Row>
                    <Row className="ticker">
                        <Truncate
                            lines={3}
                            ellipsis="..."
                            children={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: eventSummary,
                                    }}
                                />
                            }
                        />
                    </Row>
                </Col>
            </Col>
        );
    };
    if (!items || items.length <= 0) return <caption>Stay tuned with us for more upcoming events</caption>;
    return (
        <div height={400}>
            <AutoSizer>
                {({ width }) => {
                    return (
                        // div below is important to keep the width of List component
                        <div style={{ width }}>
                            <List
                                rowRenderer={rowRenderer}
                                rowHeight={130}
                                height={300}
                                width={width}
                                rowCount={items.length}
                                style={{ outline: 'none' }}
                            />
                        </div>
                    );
                }}
            </AutoSizer>
        </div>
    );
};

export default TableWidgetUpcomingEvent;
