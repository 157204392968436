import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import history from '../../../../history';
import moment from 'moment';
import { Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import InputField from 'pages/admin/companies/shared/InputField';
import EventListPopup from './EventListPopup';
import { save, update, remove, load, getAllCategories } from 'actions/admin/misc/resources/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onSelectedYearChange = this.onSelectedYearChange.bind(this);
        this.resourceChange = this.resourceChange.bind(this);
        this.selectEvent = this.selectEvent.bind(this);

        this.yearChange = this.yearChange.bind(this);
        this.onUploadFail = this.onUploadFail.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.nameChange = this.nameChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        var currentYear = moment().format('YYYY');
        var years = [];
        for (var startYear = 2010; startYear <= parseInt(currentYear) + 1; startYear++) {
            years.push({
                label: startYear,
                value: startYear,
            });
        }
        this.state = {
            loading: false,
            data: {
                categoryId: '',
                resource: '',
                year: currentYear,
                name: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            years,
            selectedYear: {
                label: currentYear,
                value: currentYear,
            },
            categories: [],
            category: {},
        };
    }
    selectEvent(eventName) {
        var data = this.state.data;
        data.name = eventName;
        this.setState({ data });
        this.popup.hide();
    }
    onSelectedYearChange(e) {
        var data = this.state.data;
        data.year = e.value;
        this.setState({
            selectedYear: e,
            data,
        });
    }
    onCategoryChange(e) {
        this.setState({
            category: e,
        });
    }
    onUploadSuccess(file) {
        var data = this.state.data;
        data.resource = file.id;
        this.setState({ data });
    }
    onUploadFail(error) {}
    back() {
        history.replace('/admin/misc/resources');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);

        this.props
            .getAllCategories()
            .then(categories => {
                var categoryToAdd = [];
                categories.data.forEach(category => {
                    categoryToAdd.push({
                        value: category.id,
                        label: category.category,
                    });
                });
                this.setState({
                    categories: categoryToAdd,
                });

                var id = null;
                try {
                    id = this.props.match.params.id;
                } catch (e) {}
                if (id != null && id > 0) {
                    this.props
                        .load(id)
                        .then(res => {
                            if (res.error || res.data == null || res.data.id == null) {
                                this.showModal('Error', res.message, 'load', true, true);
                            } else {
                                var dataToSet = Object.assign({}, res.data);
                                categories.data.forEach(category => {
                                    if (category.id === dataToSet.categoryId) {
                                        this.setState({
                                            category: {
                                                label: category.category,
                                                value: category.id,
                                            },
                                        });
                                    }
                                });

                                this.setState({
                                    data: dataToSet,
                                    selectedYear: {
                                        label: res.data.year,
                                        value: res.data.year,
                                    },
                                });

                                this.showLoading(false);
                            }
                        })
                        .catch(error => {
                            this.showLoading(false);
                            this.showModal('Error', error.message, 'load', true, true);
                        });
                } else {
                    this.setState({
                        data: {
                            categoryId: '',

                            resource: '',

                            year: moment().format('YYYY'),

                            name: '',
                        },
                    });
                    this.showLoading(false);
                }
            })
            .catch(error => {});
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            data.categoryId = this.state.category.value;
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/misc/resources');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            data.categoryId = this.state.category.value;
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/misc/resources', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/misc/resources');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    categoryIdChange(e) {
        var data = this.state.data;
        data.categoryId = e.target.value;
        this.setState({ data });
    }

    resourceChange(e) {
        var data = this.state.data;
        data.resource = e.target.value;
        this.setState({ data });
    }

    yearChange(e) {
        var data = this.state.data;
        data.year = e.target.value;
        this.setState({ data });
    }

    nameChange(e) {
        var data = this.state.data;
        data.name = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <EventListPopup
                    className={`modal-lg`}
                    ref={instance => {
                        this.popup = instance;
                    }}
                />
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <InputField label="Category">
                        <div style={{ width: '300px' }}>
                            <Select
                                autosize={false}
                                options={this.state.categories}
                                value={this.state.category}
                                clearable={true}
                                onChange={this.onCategoryChange}
                                isMulti={false}
                                name="selected-state"
                                disabled={false}
                                searchable={true}
                            />
                        </div>
                    </InputField>
                    <InputField label="Year">
                        <div style={{ width: '120px' }}>
                            <Select
                                autosize={false}
                                options={this.state.years}
                                value={this.state.selectedYear}
                                clearable={true}
                                onChange={this.onSelectedYearChange}
                                isMulti={false}
                                name="selected-state"
                                disabled={false}
                                searchable={true}
                            />
                        </div>
                    </InputField>
                    <InputField
                        label="Name"
                        inputProps={{
                            type: 'text',
                            id: 'name',
                            value: this.state.data.name,
                            onChange: this.nameChange,
                            style: { width: '800px' },
                        }}
                    />
                    {this.state.category.value == 1 && (
                        <React.Fragment>
                            <span
                                onClick={e => {
                                    this.popup.show(this.state.data.year, this.selectEvent);
                                }}
                            >
                                <i style={{ cursor: 'pointer' }}>(click here to pick name from list of SGBC events)</i>
                            </span>
                            <br />
                            <br />
                        </React.Fragment>
                    )}
                    <InputField label="Attachment">
                        <CyderFileUpload
                            attachmentId={this.state.data.resource}
                            filename={this.state.data.filename}
                            onUploadSuccess={this.onUploadSuccess}
                            onUploadFail={this.onUploadFail}
                        />
                    </InputField>
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2} className="d-flex justify-content-end">
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                                <AuditListPopup contentTypeId={102} objectId={this.state.data.id} />
                            </FlexCol>
                        ) : null}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getAllCategories: () => {
            return dispatch(getAllCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
