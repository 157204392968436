import React, { Component } from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import ButtonIcon from 'cyder/button/ButtonIcon';
import StaffCertificationPredeterminedExport from 'pages/staff/certification/export/StaffCertificationPredetermineExport';
import StaffCertificationDynamicExport from 'pages/staff/certification/export/StaffCertificatioDynamicExport';

import { staffCertificationExport } from 'actions/staff/certification/staffCertificationExportAction';
import { exportCSVFile } from 'js/jsonToCsvExport';

class StaffCertificationExport extends Component {
    constructor(props) {
        super(props);
        this.setActiveExport = this.setActiveExport.bind(this);
        this.exportData = this.exportData.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.showModalIf = this.showModalIf.bind(this);
        this.state = {
            loading: false,
            activeExport: 'static',
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    showLoading(loading) {
        this.setState({ loading });
    }
    setActiveExport(activeExport) {
        this.setState({
            activeExport,
        });
    }
    async exportData(type, tick, filename) {
        try {
            this.showLoading(true);
            const { data } = await this.props.getExportData(type, tick);
            const hasError = this.showModalIf('Error', `Failed to export ${type} category file. Please try again later.`, !data);
            if (hasError) return;
            exportCSVFile(data, `${type}-category-${filename || tick}.csv`);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    // MODAL
    modalAction() {
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    render() {
        const { modal, loading, activeExport } = this.state;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <HeaderPanel activeExportType={activeExport} setActiveExport={this.setActiveExport} />
                {loading ? (
                    <LoadingSpinner center />
                ) : (
                    <React.Fragment>
                        {activeExport === 'static' && <StaffCertificationPredeterminedExport exportAction={this.exportData} />}
                        {activeExport === 'dynamic' && <StaffCertificationDynamicExport showModalIf={this.showModalIf} />}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const HeaderPanel = ({ activeExportType, setActiveExport }) => {
    const isStatic = activeExportType === 'static';
    const getClassName = evaluation => (evaluation ? 'primary-btn-style-selected' : 'primary-btn-style') + ' mr-2';
    return (
        <Row className="text-right">
            <Col>
                <ButtonIcon
                    className={getClassName(isStatic)}
                    onClick={() => setActiveExport('static')}
                    label="Static Predetermined Export"
                    icon="assignment"
                />
                <ButtonIcon
                    className={getClassName(!isStatic)}
                    onClick={() => setActiveExport('dynamic')}
                    label="Dynamic Export"
                    icon="autorenew"
                />
            </Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getExportData: (type, tick) => {
            return dispatch(staffCertificationExport(type, tick));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StaffCertificationExport),
);
