import withQuery from 'with-query';
import fetchlib from 'js/fetchlib.js';
import { SGBC_API_ROOT, instance, SGBC_API_CORS_ROOT } from 'config.js';
import {
    FETCH_MEAL_TYPE,
    FETCH_TICKETS,
    FETCH_TABLE_COUNT,
    FETCH_TICKET_TYPE_REQUEST,
    FETCH_TICKET_TYPE_SUCCESS,
    FETCH_TICKET_TYPE_FAILURE,
    FETCH_TABLE_NAME_REQUEST,
    FETCH_TABLE_NAME_SUCCESS,
    FETCH_TABLE_NAME_FAILURE,
    FETCH_TABLE_REGISTRANT_REQUEST,
    FETCH_TABLE_REGISTRANT_SUCCESS,
    FETCH_TABLE_REGISTRANT_FAILURE,
} from '../../../constants/events/actionTypesStaffEventsTicket';

export function load(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-ticket-type`, {
            action: 'get-by-event-id',
            id: eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            FETCH_TICKET_TYPE_REQUEST,
            FETCH_TICKET_TYPE_SUCCESS,
            FETCH_TICKET_TYPE_FAILURE,
        );
    };
}
// Not ready
export function save(eventId, ticket) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-ticket-type`, {
            action: 'save',
        });
        const body = {
            eventId,
            ticket,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export const fetchMealType = eventId => dispatch => {
    instance
        .get(`/events_v1/cn/event-dinner-registration?action=getMealTypeCount&eventId=${eventId}`)
        .then(response => {
            dispatch({ type: FETCH_MEAL_TYPE, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchTickets = eventId => dispatch => {
    instance
        .get(`/events_v1/cn/event-dinner-ticket-type?action=get-by-event-id&id=${eventId}`)
        .then(response => {
            dispatch({ type: FETCH_TICKETS, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const loadTableCount = eventId => dispatch => {
    instance
        .get(`/events_v1/cn/event-dinner-ticket-type?action=getTableMaxCount&eventId=${eventId}`)
        .then(response => {
            dispatch({ type: FETCH_TABLE_COUNT, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export function loadTableName(eventId) {
    return (dispatch, getState) => {
        var tableType = 'All';
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'getTableName',
            eventId,
            tableType,
        });

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            FETCH_TABLE_NAME_REQUEST,
            FETCH_TABLE_NAME_SUCCESS,
            FETCH_TABLE_NAME_FAILURE,
        );
    };
}

export function loadRegistrant(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-dinner-registration`, {
            action: 'get-by-event-id',
            id,
        });

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            FETCH_TABLE_REGISTRANT_REQUEST,
            FETCH_TABLE_REGISTRANT_SUCCESS,
            FETCH_TABLE_REGISTRANT_FAILURE,
        );
    };
}
