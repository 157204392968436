/**
 * Staff certification assessment ci reducer
 *
 * Author: Fernando Karnagi
 */
import {
    STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE,
    STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS,
    STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY,
} from 'actions/constants/actionTypesStaffCertificationAssessmentTemplate';

const defaultState = {
    type: 'product',
    selectedCategory: null,
    status: { value: 'active', label: 'Active' },
};

export function staffAssessmentCriteriaReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS:
            newState.status = action.status;
            return newState;
        case STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY:
            newState.selectedCategory = action.category;
            return newState;
        case STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE:
            newState.type = action.filterType;
            return newState;
        default:
            return newState;
    }
}
