/**
 * Assign officer panel
 *
 * Author: Fernando
 */
import React from 'react';
import ButtonIcon from 'cyder/button/ButtonIcon';
import CreateCertificateDetailsPanel from './CreateCertificateDetailsPanel';
import { Row, Col, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';

const selectState = {
    step: 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

const AssignOfficerPanel = ({
    sendToCaseOfficer,
    officers,
    selectedOfficer,
    onSelectedOfficerChange,
    type,
    productBrands,
    updateProductBrandsWithCertificate,
    checkCertNumberDuplication,
    unassignedBrandFromCertificate,
    updateServiceWithCertificate,
    onOverseasTransactionChargesChange,
    otCharges,
    generatedCertNumber,
    certificateDetails,
    onHandleChange,
    applicationId,
    applicationDetails,
    onDisableInvoiceChange,
    disableInvoice,
}) => (
    <Row>
        <Col>
            <Row style={{ paddingBottom: '10px' }}>
                <Col xs={3}>
                    <label className="field-header">1. Create Certificate Details</label>
                </Col>
                <Col xs={3}>
                    <FormGroup check className="ml-2 pl-2" style={{ height: '100%' }}>
                        <Input
                            id="overseaskTransactionCharges"
                            type="checkbox"
                            checked={otCharges}
                            onChange={e => {
                                const { checked } = e.target;
                                if (type === 'product') {
                                    onOverseasTransactionChargesChange(e);
                                } else {
                                    onOverseasTransactionChargesChange(e);
                                }
                            }}
                        />
                        Overseas Transaction Charges
                    </FormGroup>
                </Col>
                <Col xs={3}>
                    <FormGroup check className="ml-2 pl-2" style={{ height: '100%' }}>
                        <Input
                            id="disableInvoice"
                            type="checkbox"
                            checked={disableInvoice}
                            onChange={e => {
                                const { checked } = e.target;
                                onDisableInvoiceChange(e);
                            }}
                        />
                        Disable invoice generation
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <CreateCertificateDetailsPanel
                        type={type}
                        productBrands={productBrands}
                        applicationId={applicationId}
                        applicationDetails={applicationDetails}
                        unassignedBrandFromCertificate={unassignedBrandFromCertificate}
                        checkCertNumberDuplication={checkCertNumberDuplication}
                        updateProductBrandsWithCertificate={updateProductBrandsWithCertificate}
                        updateServiceWithCertificate={updateServiceWithCertificate}
                        onOverseasTransactionChargesChange={onOverseasTransactionChargesChange}
                        otCharges={otCharges}
                        generatedCertNumber={generatedCertNumber}
                        certificateDetails={certificateDetails}
                        onHandleChange={onHandleChange}
                    />
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <label className="field-header">2. Please choose a case officer</label>
                </Col>
            </Row>
            <Row style={{ paddingBottom: '10px' }}>
                <Col xs={4}>
                    <Select
                        autosize={false}
                        placeholder=""
                        autofocus
                        options={officers}
                        simpleValue
                        clearable={selectState.clearable}
                        name="selected-state"
                        disabled={selectState.disabled}
                        value={selectedOfficer}
                        searchable={selectState.searchable}
                        onChange={onSelectedOfficerChange}
                    />
                </Col>
            </Row>

            <Row style={{ paddingBottom: '10px', paddingLeft: '10px' }}>
                <ButtonIcon onClick={sendToCaseOfficer} icon="check" label="Send to Case Officer" />
            </Row>
        </Col>
    </Row>
);

export default AssignOfficerPanel;
