import {
    GROUP_SEARCH_REQUEST,
    GROUP_SEARCH_SUCCESS,
    GROUP_SEARCH_FAILURE,
    GROUP_KEYWORD_SEARCH_REQUEST,
    GROUP_KEYWORD_SEARCH_SUCCESS,
    GROUP_KEYWORD_SEARCH_FAILURE,
    // GROUP_SEARCH_PAGE_RESET_STATE,
    GROUP_TOGGLE_CHECKBOX,
    GROUP_TOGGLE_CHECKBOX_ALL,
    GROUP_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../../actions/constants/actionTypesAuth';

// defaults
var defaultState = {
    selectedItems: {},
    selectedAll: false,
    groups: [],
    tablePages: -1,
    loading: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    selectedCount: 0,
    inputKeywordValue: '',
};

export function groupReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let newSelectedItems = {};
    let stateResult;
    switch (action.type) {
        case GROUP_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case GROUP_SEARCH_SUCCESS:
            newState.loading = false;
            newState.groups = action.response.data || action.response;
            newState.groups.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case GROUP_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.groups = [];
            return newState;
        // case GROUP_SEARCH_PAGE_RESET_STATE:
        // casereturn defaultState;
        case GROUP_KEYWORD_SEARCH_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        case GROUP_KEYWORD_SEARCH_SUCCESS:
            newState.loading = false;
            newState.groups = action.response.data || action.response;
            newState.groups.forEach(each => {
                newSelectedItems[each.id] = false;
            });
            newState.selectedItems = newSelectedItems;
            newState.selectedAll = false;
            return newState;
        case GROUP_KEYWORD_SEARCH_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.groups = [];
            return newState;
        case GROUP_TOGGLE_CHECKBOX:
            newSelectedItems = Object.assign({}, state.selectedItems);
            if (newSelectedItems[action.id] === undefined) {
                newSelectedItems[action.id] = true;
                newState.selectedCount = newState.selectedCount + 1;
            } else {
                newSelectedItems[action.id] = !newSelectedItems[action.id];
                if (newSelectedItems[action.id] === true) {
                    newState.selectedCount = newState.selectedCount + 1;
                } else {
                    newState.selectedCount = newState.selectedCount - 1;
                }
            }
            newState.selectedItems = newSelectedItems;
            return newState;
        case GROUP_TOGGLE_CHECKBOX_ALL:
            stateResult = !newState.selectedAll;
            newState.selectedAll = stateResult;
            if (newState.selectedAll === true) {
                newState.selectedCount = Object.keys(
                    newState.selectedItems,
                ).length;
            } else {
                newState.selectedCount = 0;
            }
            for (let key in newState.selectedItems) {
                newState.selectedItems[key] = stateResult;
            }
            return newState;
        case GROUP_CHANGE_INPUT_KEYWORD_VALUE:
            newState.inputKeywordValue = action.inputKeywordValue;
            return newState;
        default:
            return state;
    }
}
