import React, { Fragment } from 'react';

import { Row, Col, Button, Input, FormGroup } from 'reactstrap';
import InputFieldWithValidation from 'reactstrap-input';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import PaymentInfoPopup from 'pages/member/certification/applications/shared/PaymentInfoPopup';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import Select from 'react-select';
import { selectStyle } from 'pages/member/certification/applications/product/components/ManufacturerDetailsPanel';

class ApplicationDetailsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.viewPayment = this.viewPayment.bind(this);
        this.state = {
            list: [],
            loading: false,
            keyword: '',
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    viewPayment() {
        this.popup.show(this.props.paymentInfo);
    }
    render() {
        let {
            type,
            title,
            visible,
            status,
            editable,
            paynow,
            companyInfo,
            feedbackSurvey,
            viewAssessment,
            downloadInvoice,
            onManufacturerContactPersonNameChange,
            onManufacturerTelephoneChange,
            printCertificate,
            downloadCertLogo,
            manufacturerContactPersonName,
            manufacturerTelephone,
            service,
            selectedYesNoOption,
            onYesNoOptionChange,
            feedbackValidationObj,
            paymentInfo,
            applicationDetails,
            productBrands,
            loadPrinting,
            printApplication,
            isDistributorChange,
            isDistributor,
        } = this.props;
        const {
            name,
            mainPhoneNumber,
            businessAddressLine1,
            businessAddressLine2,
            businessAddressLine3,
            businessPostalCode,
            countryName,
        } = companyInfo;
        console.log('applicationDetails: ', applicationDetails);
        return (
            visible && (
                <Fragment>
                    <PaymentInfoPopup ref={instance => (this.popup = instance)} className={`modal-lg`} />
                    <Row>
                        <Col xs={10}>
                            <NoShadowPanel flat title={title} />
                        </Col>
                    </Row>
                    {status !== 'Draft' && (
                        <Fragment>
                            <Row>
                                <Col>
                                    <StatusLabel status={status} muaLocked={applicationDetails.muaLocked} />
                                </Col>
                                <Col xs={12} className="text-right">
                                    {loadPrinting ? (
                                        <div style={{ width: '20px', float: 'right', marginRight: '50px' }}>
                                            <LoadingSpinner />
                                        </div>
                                    ) : (
                                        <Button
                                            className="ml-auto mr-2 primary-btn-style"
                                            onClick={() => printApplication()}
                                            style={{ position: 'relative', top: '-50px' }}
                                        >
                                            <i className="mr-1 material-icons align-text-bottom ">print</i>
                                            &nbsp;Print
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                {(status === 'Assessment' || status === 'Accepted') && (
                                    <DetailsPanelButton label="Upload Document" icon="cloud_upload" onClick={viewAssessment} />
                                )}
                                {status === 'Issued' && type === 'service' && (
                                    <Fragment>
                                        <DetailsPanelButton
                                            label={`Download ${service.certificationNumber}`}
                                            icon="cloud_download"
                                            style={{
                                                marginBottom: '10px',
                                                width: '300px',
                                            }}
                                            onClick={e => printCertificate(service.certificationNumber)}
                                        />
                                        <DetailsPanelButton
                                            label={`Download ${service.certificationNumber} Logo`}
                                            icon="perm_media"
                                            style={{
                                                marginBottom: '10px',
                                                width: '300px',
                                            }}
                                            onClick={e => downloadCertLogo(service.ticks, 'Service')}
                                        />
                                    </Fragment>
                                )}
                                {status === 'Issued' &&
                                    type === 'product' &&
                                    productBrands &&
                                    productBrands.length > 0 &&
                                    productBrands[0].models.map((element, i) => {
                                        const { certificationNumber, ticks } = element;
                                        const buttonStyle = {
                                            marginBottom: '10px',
                                            width: '300px',
                                        };
                                        return (
                                            <Fragment key={i}>
                                                <DetailsPanelButton
                                                    label={`Download ${element.certificationNumber}`}
                                                    icon="cloud_download"
                                                    style={buttonStyle}
                                                    onClick={e => printCertificate(certificationNumber, 'product')}
                                                />
                                                <DetailsPanelButton
                                                    label={`Download ${element.certificationNumber} Logo`}
                                                    icon="perm_media"
                                                    style={buttonStyle}
                                                    onClick={e => downloadCertLogo(ticks, 'Product')}
                                                />
                                            </Fragment>
                                        );
                                    })}
                                {(status === 'Invoice' || status === 'Payment') && applicationDetails.withInvoice == 1 && (
                                    <DetailsPanelButton
                                        label="Download Invoice"
                                        icon="get_app"
                                        onClick={downloadInvoice}
                                        style={{
                                            marginBottom: '10px',
                                            width: '300px',
                                        }}
                                    />
                                )}
                                {status === 'Invoice' &&
                                    paymentInfo &&
                                    (paymentInfo.modeOfPaymentId == null ||
                                        paymentInfo.modeOfPaymentId == '' ||
                                        paymentInfo.modeOfPaymentId == 0) && (
                                        <DetailsPanelButton
                                            label="Update Payment"
                                            icon="attach_money"
                                            onClick={paynow}
                                            style={{
                                                marginBottom: '10px',
                                                width: '300px',
                                            }}
                                        />
                                    )}
                                {status === 'Finalisation' && applicationDetails.muaLocked == 0 && (
                                    <Col>
                                        <DetailsPanelButton
                                            icon="create"
                                            onClick={feedbackSurvey}
                                            label="Fill up Mark User Agreement"
                                            style={{
                                                marginBottom: '10px',
                                                width: '300px',
                                            }}
                                        >
                                            <small className="form-text text-muted">
                                                {'(*) This is the final step to get your certified ' + type}
                                            </small>
                                        </DetailsPanelButton>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    {status === 'Invoice' &&
                                        paymentInfo &&
                                        paymentInfo.modeOfPaymentId != null &&
                                        paymentInfo.modeOfPaymentId != '' &&
                                        paymentInfo.modeOfPaymentId != 0 && (
                                            <React.Fragment>
                                                <Row className="mb-4 text-bold">
                                                    <Col>Payment information have been updated.</Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        Click{' '}
                                                        <a onClick={() => this.viewPayment()}>
                                                            <span style={{ color: '#317446' }}>
                                                                <u>
                                                                    <b>view</b>
                                                                </u>
                                                            </span>
                                                        </a>{' '}
                                                        to see payment information
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-bold">or</Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        Click{' '}
                                                        <a onClick={() => paynow()}>
                                                            <span style={{ color: '#317446' }}>
                                                                <u>
                                                                    <b>update</b>
                                                                </u>
                                                            </span>
                                                        </a>{' '}
                                                        to make further amendments
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                    <hr />
                    <PanelHeader header="1. Applicant Details" />
                    <Col>
                        <Row className="pb-2 pt-2">
                            <Col xs={5} style={{ padding: '0px' }}>
                                <PreviewField row header="Company Name" label={name} colwidth={5} />
                            </Col>
                            <Col sm="4" style={{ padding: '0px' }}>
                                <PreviewField colwidth={7} row header="Company Phone Number" label={mainPhoneNumber} />
                            </Col>
                        </Row>
                        <Row className="pb-2 pt-2">
                            <Col style={{ padding: '0px' }}>
                                <PreviewField
                                    row
                                    header="Company Address"
                                    label={
                                        businessAddressLine1 || businessAddressLine2 || businessAddressLine3 || businessPostalCode ? (
                                            <p>
                                                {businessAddressLine1 == null ? '-' : businessAddressLine1}
                                                <br />
                                                {businessAddressLine2 == null ? '-' : businessAddressLine2}
                                                <br />
                                                {businessAddressLine3 == null || businessAddressLine3 == '' ? '-' : businessAddressLine3}
                                                <br />
                                                {countryName == null ? ' - ' : countryName + ' '}
                                                {businessPostalCode == null ? '-' : businessPostalCode}
                                            </p>
                                        ) : (
                                            '-'
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2 pt-2">
                            <Col xs="5" sm="4" style={{ padding: '0px' }}>
                                <DetailsPanelInput
                                    mandatory
                                    header="Main Contact Person"
                                    editable={editable}
                                    value={manufacturerContactPersonName}
                                    onChange={onManufacturerContactPersonNameChange}
                                    valueKey="manufacturerContactPersonName"
                                    validationObj={feedbackValidationObj}
                                />
                            </Col>
                            <Col sm="4" md={{ offset: 1 }} style={{ padding: '0px' }}>
                                <DetailsPanelInput
                                    mandatory
                                    header="Main Contact Phone"
                                    editable={editable}
                                    value={manufacturerTelephone}
                                    onChange={onManufacturerTelephoneChange}
                                    valueKey="manufacturerTelephone"
                                    validationObj={feedbackValidationObj}
                                    fieldSize={7}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-2 pt-2">
                            {type == 'product' && editable && (
                                <Col xs="5" sm="4" style={{ padding: '0px' }}>
                                    <DetailsPanelInput
                                        mandatory
                                        header="Are you a Distributor/Reseller/Agent?"
                                        editable={editable}
                                        value={isDistributor}
                                    >
                                        <Select
                                            styles={selectStyle}
                                            name="selected-state"
                                            autofocus
                                            options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]}
                                            value={isDistributor}
                                            onChange={isDistributorChange}
                                        />
                                    </DetailsPanelInput>
                                </Col>
                            )}
                            {type == 'product' && !editable && (
                                <React.Fragment>
                                    <PreviewField
                                        header="Are you a Distributor/Reseller/Agent?"
                                        editable={editable}
                                        label={isDistributor.value === 1 ? 'Yes' : 'No'}
                                    />
                                </React.Fragment>
                            )}
                        </Row>
                        <hr />
                    </Col>
                </Fragment>
            )
        );
    }
}

const DetailsPanelInput = ({
    disabled,
    mandatory,
    header,
    value,
    valueKey = '',
    validationObj = {},
    children,
    type = 'text',
    editable = false,
    onChange = () => {},
    rows,
    fieldSize,
}) => {
    return (
        <Col xs={fieldSize || 12}>
            <label className="field-header">
                {header}&nbsp;
                {mandatory && editable && <MandatoryStar />}
            </label>
            {editable ? (
                <React.Fragment>
                    {children || (
                        <InputFieldWithValidation
                            type={type}
                            rows={type == 'textarea' ? rows : null}
                            labelStyle={{ display: 'none' }}
                            valueKey={valueKey}
                            value={value}
                            validationObj={validationObj} // Entire validation objs (object key must match the value key given)
                            handleChange={onChange}
                            saveButtonPressed={true}
                            inputProps={{ disabled }}
                        />
                    )}
                </React.Fragment>
            ) : (
                <div>{!value ? 'Not specified' : value}</div>
            )}
        </Col>
    );
};
const PreviewField = ({ header, label, row, length, colwidth, children }) => {
    const field = (
        <React.Fragment>
            <label className={'field-header ' + (row && 'd-inline')}>
                {header}
                {row && ' '}
            </label>
            &nbsp; <br />
            <div className={'field-label align-items-bottom ' + (row && 'd-inline')}>{label || '-'}</div>
            {children && (
                <React.Fragment>
                    <hr />
                    {children}
                </React.Fragment>
            )}
        </React.Fragment>
    );
    return <Col xs={colwidth || 5}>{field}</Col>;
};
export const StatusLabel = ({ status, muaLocked, withInvoice }) => {
    const getStatusRemarks = () => {
        switch (status) {
            case 'Draft':
                return 'This application is in draft, has not been submitted.';
            case 'Submitted':
                return 'Application Submitted. SGBC will review the application and be in touch soon with the certification criteria and documents required.';
            case 'Accepted':
            case 'Assessment':
                return (
                    <label>
                        Assessment in progress. <br />
                        Click on <b>Upload Document</b> to begin document submission.
                    </label>
                );
            case 'Approval':
            case 'Peer Approval':
            case 'Manager Approval':
                return 'Assessment pending approval.';
            case 'Invoice':
                return (
                    <label>
                        Assessment Complete.{' '}
                        {withInvoice == 1 ? `Proceed with payment by clicking on <b>Download Invoice</b>` : `SGBC will send invoice to you`}
                        . Once payment is complete, click on
                        <b> Update Payment</b> to enter payment details.
                    </label>
                );
            case 'Paid':
                return 'Payment Successful.';
            case 'Finalisation':
                return (
                    <label>
                        Pending Mark User Agreement and application details confirmation. <br />
                        You will be able to access and download the Certificate(s) as well as the Certification logo(s) after this step.
                    </label>
                );
            case 'Issued':
                return 'Assessment complete. You may download the Certificate(s) as well as the Certification logo(s).';
            case 'Discontinued':
                return 'Application has been discontinued.';
        }
    };
    return (
        <FormGroup>
            <label>{getStatusRemarks()}</label>
        </FormGroup>
    );
};
const DetailsPanelButton = ({ onClick, style, icon, label, className, children }) => (
    <React.Fragment>
        <Button
            className={'primary-btn-style mr-2 ' + className}
            onClick={onClick}
            style={{
                ...style,
                height: '40px',
                fontSize: '14px',
            }}
        >
            <i className="mr-1 material-icons align-text-bottom ">{icon}</i>
            {label}
        </Button>
        {children}
        <br />
    </React.Fragment>
);
const PanelHeader = ({ header }) => (
    <Row className="pb-4 pt-4">
        <Col>
            <h4 className="text-bold text-uppercase">{header}</h4>
        </Col>
    </Row>
);

export { PreviewField, DetailsPanelInput, DetailsPanelButton, PanelHeader };
export default ApplicationDetailsPanel;
