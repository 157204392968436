import {
    MEMBERSHIP_APPLICATION_CONFIRM_SHOW,
    MEMBERSHIP_APPLICATION_CONFIRM_HIDE,
    MEMBER_MEMBERSHIP_PAYMENT_SET_MODE,
    MEMBER_MEMBERSHIP_PAYMENT_NEXT,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE,
    MEMBER_QUESTIONNAIRES_SET_QUESTION_ANSWER,
    MEMBER_QUESTIONNAIRES_NEXT,
    MEMBER_QUESTIONNAIRES_PREV,
    MEMBER_QUESTIONNAIRES_RESET_FORM,
    MEMBER_QUESTIONNAIRES_SET_QUESTION3,
    MEMBER_QUESTIONNAIRES_SET_QUESTION1,
    MEMBER_QUESTIONNAIRES_SET_QUESTION5,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE,
} from '../../../../actions/constants/actionTypesMemberMembershipApplications';
import { setReducerModalState, defaultModalKeys } from 'js/modal';

// defaults
const defaultState = {
    packages: [],
    questions: [],
    preview: {
        submitConfirmationVisible: false,
    },
    question5: {
        corporateSustainability: '',
        statementOfCommitment: '',
        sustainabilityReport: '',
        certifiedEnvironment: '',
        sustainabilityAward: '',
    },
    question1: {
        certifiedBuilding: '',
        certifiedInterior: '',
    },
    question3: {
        question3b: '',
        question3c: '',
    },
    questionnaires: {
        saveConfirmationVisible: false,
        submitConfirmationVisible: false,
        currentTabNo: 0,
        activeTabs: [true, false, false],
        tabColors: ['now', 'future', 'future'],
        question1: [],
        question2: [],
        question3: [],
        question4: [],
    },
    viewMode: 'view',
    loading: false,
    forceLoading: false,
    ...defaultModalKeys,
};
// viewMode: view / preview

export default function memberNewMembershipApplicationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_QUESTIONNAIRES_SET_QUESTION_ANSWER:
            newState.questionnaires = Object.assign({}, state.questionnaires);
            newState.questionnaires[`question${action.answers.q}`] = Object.assign(
                [],
                newState.questionnaires[`question${action.answers.q}`],
            );
            var newQ = newState.questionnaires[`question${action.answers.q}`];
            if (action.answers.s) {
                newQ.push(action.answers.a);
            } else {
                newQ = newQ.filter(element => {
                    return element != action.answers.a;
                });
            }
            // console.log(action.answers.q, ', ',newQ)
            newState.questionnaires[`question${action.answers.q}`] = newQ;
            return newState;
        case MEMBER_QUESTIONNAIRES_SET_QUESTION1:
            newState.question1 = Object.assign({}, newState.question1);
            newState.question1 = action.answers;
            return newState;
        case MEMBER_QUESTIONNAIRES_SET_QUESTION3:
            newState.question3 = Object.assign({}, newState.question3);
            newState.question3 = action.answers;
            return newState;
        case MEMBER_QUESTIONNAIRES_SET_QUESTION5:
            newState.question5 = Object.assign({}, newState.question5);
            newState.question5 = action.answers;
            return newState;
        case MEMBERSHIP_APPLICATION_CONFIRM_HIDE:
            newState.preview = Object.assign({}, newState.preview);
            newState.preview.submitConfirmationVisible = false;
            return newState;
        case MEMBERSHIP_APPLICATION_CONFIRM_SHOW:
            newState.preview = Object.assign({}, newState.preview);
            newState.preview.submitConfirmationVisible = true;
            return newState;
        case MEMBER_MEMBERSHIP_PAYMENT_SET_MODE:
            newState.payment = Object.assign({}, newState.payment);
            newState.payment.mode = action.mode;
            return newState;
        case MEMBER_MEMBERSHIP_PAYMENT_NEXT:
            newState.payment = Object.assign({}, newState.payment);
            newState.payment.start = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_SUCCESS:
            newState.packages = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_PACKAGE_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_SUCCESS:
            newState.packages = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_ALL_GET_PACKAGE_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_SUCCESS:
            newState.questions = action.response.data;
            newState.loading = false;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_GET_QUESTIONS_FAILURE:
            newState.loading = false;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to get questionaire for Membership Application.';
            newState.modalAction = '/member/myaccount/dashboard';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_FORCE_LOADING:
            newState.forceLoading = action.loadingState;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_SUCCESS:
            newState.modalLoading = false;
            newState.modalHeader = 'Confirmation';
            newState.modalBody = 'Thank you for your commitment to green building and sustainability. We will be in touch very soon.';
            newState.modalAction = '/member/myaccount/dashboard';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SUBMIT_APPLICATION_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE:
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to get saved data.';
            newState.modalAction = '/member/myaccount/dashboard';
            newState.modalOnlyOneButton = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_REQUEST:
            newState.modalLoading = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_SUCCESS:
            newState.modalLoading = false;
            newState.modalHeader = 'Confirmation';
            newState.modalBody = 'Membership Application has been successfully saved.';
            newState.modalAction = '';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case MEMBER_MEMBERSHIP_APPLICATION_NEW_SAVE_APPLICATION_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case MEMBER_MEMBERSHIP_PAYMENT_SET_MODE:
            newState.payment = Object.assign({}, newState.payment);
            newState.payment.mode = action.mode;
            return newState;
        case MEMBER_MEMBERSHIP_PAYMENT_NEXT:
            newState.payment = Object.assign({}, newState.payment);
            newState.payment.start = true;
            return newState;
        case MEMBER_QUESTIONNAIRES_RESET_FORM:
            newState.questionnaires = Object.assign({}, defaultState.questionnaires);
            newState.questionnaires.activeTabs = [true, false, false];
            newState.questionnaires.tabColors = ['now', 'future', 'future'];
            return newState;
        case MEMBER_QUESTIONNAIRES_NEXT:
            if (state.questionnaires.currentTabNo === 2) {
                return newState;
            }
            newState.questionnaires.currentTabNo = state.questionnaires.currentTabNo + 1;
            var activeTabs = state.questionnaires.activeTabs;
            var length = activeTabs.length;
            for (var i = 0; i < length; i++) {
                if (i == newState.questionnaires.currentTabNo) {
                    activeTabs[i] = true;
                } else {
                    activeTabs[i] = false;
                }
                if (i < state.questionnaires.currentTabNo) {
                    newState.questionnaires.tabColors[i] = 'past';
                } else if (i > state.questionnaires.currentTabNo) {
                    newState.questionnaires.tabColors[i] = 'future';
                } else {
                    newState.questionnaires.tabColors[i] = 'now';
                }
            }
            return newState;
        case MEMBER_QUESTIONNAIRES_PREV:
            if (state.questionnaires.currentTabNo === 0) {
                return newState;
            }
            newState.questionnaires.currentTabNo = state.questionnaires.currentTabNo - 1;
            var activeTabs = state.questionnaires.activeTabs;
            var length = activeTabs.length;
            for (var i = 0; i < length; i++) {
                if (i == newState.questionnaires.currentTabNo) {
                    activeTabs[i] = true;
                } else {
                    activeTabs[i] = false;
                }
                if (i < state.questionnaires.currentTabNo) {
                    newState.questionnaires.tabColors[i] = 'past';
                } else if (i > state.questionnaires.currentTabNo) {
                    newState.questionnaires.tabColors[i] = 'future';
                } else {
                    newState.questionnaires.tabColors[i] = 'now';
                }
            }
            return newState;
        default:
            return newState;
    }
}
