import React from 'react';
import '../../css/cyder/form/big-form.css';

const BigForm = ({ title, description, children, className }) => (
    <div className={'big-form ' + className}>
        <h3>
            <strong>{title}</strong>
        </h3>
        <form onSubmit={e => e.preventDefault()}>
            <div className="description">{description}</div>
            {children}
        </form>
    </div>
);

export default BigForm;
