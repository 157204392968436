import React from 'react';
import { connect } from 'react-redux';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import Select from 'react-select';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import InputField from 'pages/admin/companies/shared/InputField';
import { save, update, remove, load } from 'actions/staff/events/event-checklist/action.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);

        this.dueBeforeAfterChange = this.dueBeforeAfterChange.bind(this);
        this.duePeriodChange = this.duePeriodChange.bind(this);
        this.dueDayChange = this.dueDayChange.bind(this);
        this.completedChange = this.completedChange.bind(this);
        this.remarksChange = this.remarksChange.bind(this);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.eventIdChange = this.eventIdChange.bind(this);

        this.save = this.save.bind(this);
        this.back = this.back.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.onUploadFail = this.onUploadFail.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            duePeriods: [{ value: 'months', label: 'months' }, { value: 'weeks', label: 'weeks' }, { value: 'days', label: 'days' }],
            selectedDuePeriod: {},
            dueBeforeAfters: [{ value: 'before', label: 'before' }, { value: 'after', label: 'after' }],
            selectedBeforeAfter: {},
            completeds: [{ value: '1', label: 'yes' }, { value: '0', label: 'no' }],
            selectedCompleted: {},
            data: {
                dueBeforeAfter: '',
                duePeriod: '',
                dueDay: '',
                completed: '',
                remarks: '',
                description: '',
                eventId: '',
                id: '',
            },
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        this.props.setMode('list', 0);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        this.setState({
                            data: res.data,
                        });
                        this.showLoading(false);

                        this.state.duePeriods.forEach(option => {
                            if (option.value == res.data.duePeriod) {
                                this.setState({
                                    selectedDuePeriod: option,
                                });
                            }
                        });

                        this.state.dueBeforeAfters.forEach(option => {
                            if (option.value == res.data.dueBeforeAfter) {
                                this.setState({
                                    selectedDueBeforeAfter: option,
                                });
                            }
                        });

                        this.state.completeds.forEach(option => {
                            if (option.value == res.data.completed) {
                                this.setState({
                                    selectedCompleted: option,
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    dueBeforeAfter: '',
                    duePeriod: '',
                    dueDay: '',
                    completed: '',
                    remarks: '',
                    description: '',
                    eventId: '',
                    id: '',
                },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.eventId = this.props.event.eventid;
        return newData;
    }
    onUploadSuccess(file) {
        var data = this.state.data;
        data.resourceId = file.id;
        this.setState({ data });
    }
    onUploadFail(error) {}
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        this.props.setMode('list', 0);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        // history.replace('/staff/events/event-checklist', 'update', true, true);
                        this.props.setMode('list', 0);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                // history.replace('/staff/events/event-checklist');
                this.props.setMode('list', 0);
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    dueBeforeAfterChange(e) {
        var data = this.state.data;
        data.dueBeforeAfter = e.value;
        this.setState({
            data,
        });
        this.setState({
            selectedDueBeforeAfter: e,
        });
    }

    duePeriodChange(e) {
        var data = this.state.data;
        data.duePeriod = e.value;
        this.setState({
            data,
        });
        this.setState({
            selectedDuePeriod: e,
        });
    }

    dueDayChange(e) {
        var data = this.state.data;
        data.dueDay = e.target.value;
        this.setState({ data });
    }

    completedChange(e) {
        var data = this.state.data;
        data.completed = e.value;
        this.setState({
            data,
        });
        this.setState({
            selectedCompleted: e,
        });
    }

    remarksChange(e) {
        var data = this.state.data;
        data.remarks = e.target.value;
        this.setState({ data });
    }

    descriptionChange(e) {
        var data = this.state.data;
        data.description = e.target.value;
        this.setState({ data });
    }

    eventIdChange(e) {
        var data = this.state.data;
        data.eventId = e.target.value;
        this.setState({ data });
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col className="d-flex " xs={12}>
                            <Button className="text-bold primary-btn-style-outline" onClick={this.back}>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={this.state.data.description}
                                    placeholder=""
                                    onChange={this.descriptionChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={6}>
                            <FormGroup>
                                <Label for="remarks">Remarks</Label>
                                <Input
                                    type="text"
                                    name="remarks"
                                    id="remarks"
                                    value={this.state.data.remarks}
                                    placeholder=""
                                    onChange={this.remarksChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={2}>
                            <FormGroup>
                                <Label for="completed">Completed</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.completeds}
                                    value={this.state.selectedCompleted}
                                    clearable={this.state.select.clearable}
                                    onChange={this.completedChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                                {/* <Input
                                    type="text"
                                    name="completed"
                                    id="completed"
                                    value={this.state.data.completed}
                                    placeholder="completed"
                                    onChange={this.completedChange}
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="dueDay">Due</Label>
                                <Input
                                    type="text"
                                    name="dueDay"
                                    id="dueDay"
                                    value={this.state.data.dueDay}
                                    placeholder=""
                                    onChange={this.dueDayChange}
                                />
                            </FormGroup>
                        </Col>
                        {/* </Row>
                    <Row className="mb-2"> */}
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="duePeriod">Period</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.duePeriods}
                                    value={this.state.selectedDuePeriod}
                                    clearable={this.state.select.clearable}
                                    onChange={this.duePeriodChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                                {/* <Input
                                    type="text"
                                    name="duePeriod"
                                    id="duePeriod"
                                    value={this.state.data.duePeriod}
                                    placeholder="duePeriod"
                                    onChange={this.duePeriodChange}
                                /> */}
                            </FormGroup>
                        </Col>
                        {/* </Row>
                    <Row className="mb-2">
                         */}
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="dueBeforeAfter">Unit</Label>{' '}
                                <Select
                                    autosize={false}
                                    options={this.state.dueBeforeAfters}
                                    value={this.state.selectedDueBeforeAfter}
                                    clearable={this.state.select.clearable}
                                    onChange={this.dueBeforeAfterChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                                {/* <Input
                                    type="text"
                                    name="dueBeforeAfter"
                                    id="dueBeforeAfter"
                                    value={this.state.data.dueBeforeAfter}
                                    placeholder="dueBeforeAfter"
                                    onChange={this.dueBeforeAfterChange}
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField label="Attachment">
                                <CyderFileUpload
                                    attachmentId={this.state.data.resourceId}
                                    filename={this.state.data.filename}
                                    onUploadSuccess={this.onUploadSuccess}
                                    onUploadFail={this.onUploadFail}
                                />
                            </InputField>
                        </Col>
                    </Row>
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2}>
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        ) : (
                            <div />
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddPage);
