import withQuery from 'with-query';
import { generatePaginationBody } from 'js/reactTableLib';

import {
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE,
} from '../../../constants/actionTypesStaffCertificationAssessmentCriteria';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';
import fetchlib from 'js/fetchlib';

export function getCriteriaSectionList(keyword, type, page, pageSize, sorted, filtered) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'get-criteria-for-admin',
            type,
        });
        const body = Object.assign({}, generatePaginationBody(page, pageSize, sorted, filtered), {
            keyword,
            type,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST,
            STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS,
            STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE,
        );
    };
}

export function getAllProductCategory(status) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product-category`, {
            action: 'get-all-active-with-assessment-criteria-count',
        });
        const body = {
            status,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getAllServiceCategory(status) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/service_v1/cn/category`, {
            action: 'get-all-active-with-assessment-criteria-count',
        });
        const body = {
            status,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function serRequirementListType(listType) {
    return {
        type: STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE,
        listType,
    };
}
