export const TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST = 'TEAM_MANAGEMENT_GET_TEAM_MEMBERS_REQUEST';
export const TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS = 'TEAM_MANAGEMENT_GET_TEAM_MEMBERS_SUCCESS';
export const TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE = 'TEAM_MANAGEMENT_GET_TEAM_MEMBERS_FAILURE';

export const TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST = 'TEAM_MANAGEMENT_ADD_TEAM_MEMBER_REQUEST';
export const TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS = 'TEAM_MANAGEMENT_ADD_TEAM_MEMBER_SUCCESS';
export const TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE = 'TEAM_MANAGEMENT_ADD_TEAM_MEMBER_FAILURE';
export const TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS = 'TEAM_MANAGEMENT_ADD_SUBSCRIBER_SUCCESS';

export const TEAM_MANAGEMENT_SET_MODAL_DATA = 'TEAM_MANAGEMENT_SET_MODAL_DATA';
export const TEAM_MANAGEMENT_CHANGE_FIELD = 'TEAM_MANAGEMENT_CHANGE_FIELD';
export const TEAM_MANAGEMENT_RESET_FIELDS = 'TEAM_MANAGEMENT_RESET_FIELDS';
export const TEAM_MANAGEMENT_RESET_INPUT_FIELDS = 'TEAM_MANAGEMENT_RESET_INPUT_FIELDS';
export const TEAM_MANAGEMENT_HAS_EMAIL_USED = 'TEAM_MANAGEMENT_HAS_EMAIL_USED';

// Member Details Page
export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA = 'TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD = 'TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD';
export const SET_MODAL_ACTION = 'SET_MODAL_ACTION';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE';

// first used by staff view membership application page
export const GET_COMPANY_REP_INFO_REQUEST = 'GET_COMPANY_REP_INFO_REQUEST';
export const GET_COMPANY_REP_INFO_SUCCESS = 'GET_COMPANY_REP_INFO_SUCCESS';
export const GET_COMPANY_REP_INFO_FAILURE = 'GET_COMPANY_REP_INFO_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS';
export const TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE = 'TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE';

export const TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION = 'TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION';

export const TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST = 'TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_REQUEST';
export const TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS = 'TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_SUCCESS';
export const TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE = 'TEAM_MANAGEMENT_UPGRADE_SUBSCRIBER_FAILURE';
export const TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER = 'TEAM_MANAGEMENT_SET_MODAL_DATA_UPGRADE_SUBSCRIBER';
