/**
 * Cyder Login Reducer
 *
 * Author: Fernando
 *
 */
import {
    CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST,
    CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS,
    CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE,
    CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_REQUEST,
    CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_SUCCESS,
    CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_FAILURE,
    CYDER_CHANGE_PASSWORD_HANDLE_INPUT_CHANGE,
    CYDER_CHANGE_PASSWORD_SET_MODAL_DATA,
} from '../../actions/constants/actionTypes';
import { setReducerModalState, defaultModalKeys } from '../../js/modal';
import validationlib from '../../js/validation';

var cyderChangePasswordIntialState = {
    loading: false,
    showPassword: false,
    fields: {
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
        emailaddress: '',
    },
    accountInfo: {
        officialManagementRep: '',
        officialManagementRepEmail: '',
    },
    validation: {},
    ...defaultModalKeys,
};
export function cyderChangePasswordReducer(
    state = cyderChangePasswordIntialState,
    action,
) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // Use on ForgotPassword.js
        case CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE:
            newState.message = action.message;
            return newState;
        // ChangePassword.js
        case CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS:
            const data = action.response.data.officialManagementRep;
            newState.accountInfo = {
                officialManagementRep: data.firstName + ' ' + data.lastName,
                officialManagementRepEmail: data.email,
            };
            newState.loading = false;
            return newState;
        case CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_REQUEST:
            newState.loading = true;
            newState.modalLoading = true;
            return newState;
        case CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_SUCCESS:
            newState.loading = false;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './dashboard';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Confirmation';
            newState.modalBody = 'Password has been successfully saved';
            newState.forceModalAction = true;
            return newState;
        case CYDER_CHANGE_PASSWORD_SAVE_PASSWORD_FAILURE:
            newState.loading = false;
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = action.modalHeader
                ? action.modalHeader
                : 'Error';
            newState.modalBody = action.modalBody
                ? action.modalBody
                : 'Failed to save password. Please try again.';
            newState.forceModalAction = true;
            return newState;
        case CYDER_CHANGE_PASSWORD_HANDLE_INPUT_CHANGE:
            const target = action.event.target;
            switch (target.id) {
                case 'password':
                    newState.fields.newpassword = target.value;
                    break;
                case 'confirmpassword':
                    newState.fields.confirmpassword = target.value;
                case 'showpassword':
                    newState.showPassword = target.checked;
                    break;
            }
            // Validation
            newState.validation = Object.assign({}, state.validation);
            newState.validation[target.id] = validationlib.validate(
                target.id,
                target.value,
            );
            return newState;
        case CYDER_CHANGE_PASSWORD_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return state;
    }
}
