import React from 'react';
import PropTypes from 'prop-types';

import '../../css/cyder/table/dashboard-table.css';

const TableWidgetOrgPrimaryBusinessActivity = ({ items }) => (
    <div data-dashboard-table="three-column-80-10-10">
        <div className="dashboard-table">
            <table className="table table-unbordered table-striped">
                <thead>
                    <tr>
                        <th>Organisation Primary Business Activity</th>
                        <th>Quantity</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {items &&
                        items.map((item, i) => (
                            <tr key={i}>
                                <td className="ticker">{item.name}</td>
                                <td className="ticker">{item.quantity}</td>
                                <td className="ticker">{item.percentage}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    </div>
);

export default TableWidgetOrgPrimaryBusinessActivity;
