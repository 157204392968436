/**
 * CyderProductApplicationStatusPanel
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Jumbotron } from 'reactstrap';

const StepCircle = ({ step, lit, passed, color, label }) => {
    // BACKGROUND STYLE
    const bgStyle = {
        backgroundColor: lit ? 'white' : passed ? color : 'white',
        textAlign: 'center',
    };
    // STEP STYLE
    const stepStyle = {
        color: bgStyle.backgroundColor !== 'white' ? 'white' : 'black',
        width: '100%',
        textAlign: 'center',
    };
    // OUTER AND RING BORDER
    const borderStyle = {
        maxWidth: '4rem',
        borderWidth: '7px',
        backgroundColor: passed ? '#1a424a' : '',
        borderColor: lit ? color : passed ? color : 'white',
    };
    // OTHERS
    const frameSpace = {
        width: '10%',
        backgroundColor: '#606C7',
    };
    return (
        <Row style={frameSpace}>
            <Col>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <LayerWrapper
                            style={borderStyle}
                            className={' rounded-circle stepCircle d-flex justify-content-center align-items-center'}
                        >
                            <LayerWrapper style={bgStyle} className={' rounded-circle stepCircle1 d-flex align-items-center'}>
                                <LayerWrapper style={stepStyle} className="align-self-center">
                                    <h4 className="m-0">{lit ? <strong>{step}</strong> : step}</h4>
                                </LayerWrapper>
                            </LayerWrapper>
                        </LayerWrapper>
                    </Col>
                </Row>
                <LabelPanel title={label} />
            </Col>
        </Row>
    );
};
const LayerWrapper = ({ style, className, children }) => {
    return (
        <div style={style} className={className}>
            {children}
        </div>
    );
};
const LabelPanel = ({ title }) => (
    <Row>
        <Col className="align-items-center">
            <div style={{ fontSize: '10', textAlign: 'center' }}>{title}</div>
        </Col>
    </Row>
);

const getNumberOfStepsDict = currentStep => {
    return {
        1: {
            color: '#7DCBD8',
            label: 'Draft',
        },
        2: {
            color: '#61B7C6',
            label: 'Submitted',
        },
        3: {
            color: '#51ACBE',
            label: 'Application Accepted',
        },
        4: {
            color: '#3DA0B5',
            label: 'Assessment',
        },
        5: {
            color: '#398897',
            label: 'Approval',
        },
        6: {
            color: '#347C89',
            label: 'Invoice',
        },
        7: {
            color: '#2F717D',
            label: 'Paid',
        },
        8: {
            color: '#2E707E',
            label: 'Finalisation',
        },
        9: {
            color: '#255B65',
            label: 'Issued',
        },
    };
};
const CyderApplicationStatusPanel = ({ step }) => {
    const numberOfStepsDict = getNumberOfStepsDict(step);
    const numberOfSteps = Object.keys(numberOfStepsDict);
    const jumbotronStyle = {
        backgroundColor: '',
        padding: '8px 0px',
    };
    return (
        <Jumbotron style={jumbotronStyle}>
            <div className="d-flex justify-content-around">
                {numberOfSteps.map((item, i) => {
                    return [
                        <StepCircle
                            key={i + 1}
                            step={i + 1}
                            lit={step !== 9 && i + 1 === step}
                            passed={step === 9 || i + 1 < step}
                            {...numberOfStepsDict[i + 1]}
                        />,
                    ];
                })}
            </div>
        </Jumbotron>
    );
};
// PropsTypes
CyderApplicationStatusPanel.propTypes = {
    step: PropTypes.number.isRequired,
};

export default CyderApplicationStatusPanel;
