import React from 'react';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const NoData = ({ header, redirect, btnText }) => (
    <Segment secondary placeholder className="no-data">
        <Header icon>
            <Icon name="file alternate outline" />
            {header}
        </Header>
        {redirect && <Button circular as={Link} className="btn-sgbc" content={btnText} to={redirect} />}
    </Segment>
);

export default NoData;
