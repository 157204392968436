import {
    EVENT_ALL_GET_EVENTS_REQUEST,
    EVENT_ALL_GET_EVENTS_SUCCESS,
    EVENT_ALL_GET_EVENTS_FAILURE,
    EVENT_ALL_CHANGE_INPUT,
    EVENT_ALL_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../../../actions/constants/events/actionTypesStaffEvents';

import moment from 'moment';

// defaults
var defaultState = {
    allApplications: [],
    applications: [],
    tablePages: -1,
    loading: false,
    keyword: '',
    status: {
        value: 'all',
        label: 'All',
    },
};
export function eventsAllReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case EVENT_ALL_GET_EVENTS_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case EVENT_ALL_GET_EVENTS_SUCCESS:
            newState.loading = false;
            newState.applications = action.response.data.map(event => {
                try {
                    let datesArr = event.eventDates.split(',');
                    let actualDates = '';
                    let index = 0;
                    if (datesArr != null && datesArr.length > 0) {
                        datesArr.forEach(dateRecord => {
                            if (index > 0) {
                                actualDates += '<br/>';
                            }
                            index++;
                            actualDates += moment(dateRecord, 'YYYY-MM-DD').format('DD MMM YYYY');
                        });
                    }
                    event.eventDates = actualDates;
                } catch (e) {}
                return event;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.selectedAll = false;
            return newState;
        case EVENT_ALL_GET_EVENTS_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.applications = [];
            return newState;
        case EVENT_ALL_CHANGE_INPUT:
            if (action.changetype === 'keyword') {
                newState.keyword = action.input.value;
            } else {
                newState.status = action.input;
            }
            return newState;
        default:
            return state;
    }
}
