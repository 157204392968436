import React, { Component } from 'react';
import StoredLayout from 'layouts/StoredLayout';

// COMPONENT
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import DynamicExportPanel from 'pages/staff/membership/export/DynamicExportPanel';

class StaffMembershipDynamicExport extends Component {
    state = {
        loading: false,
    };
    render() {
        const { loading } = this.state;
        const { showModalIf, years } = this.props;
        return (
            <React.Fragment>
                {loading ? (
                    <LoadingSpinner center />
                ) : (
                    <TabContent>
                        <TabPane>
                            <DynamicExportPanel showModalIf={showModalIf} yearoption={years} />
                        </TabPane>
                    </TabContent>
                )}
            </React.Fragment>
        );
    }
}

export default StoredLayout(StaffMembershipDynamicExport);
