/**
 * Staff Template List page
 *
 * Author: Fernando Karnagi
 */
import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import TemplateListPanel from './TemplateListPanel';

import {
    getAllProductCategory,
    getAllServiceCategory,
} from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaListAction';

import {
    search,
    criteriaTemplateSetFilterType,
    criteriaTemplateSetSelectedStatus,
    criteriaTemplateSetSelectedCategory,
} from 'actions/staff/certification/staffCertAssessmentAction';

import 'css/forms/default-forms.css';

class AssessmentTemplateList extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.getDataFunc = this.getDataFunc.bind(this);
        this.filterCategory = this.filterCategory.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.state = {
            templates: [],
            tablePages: 0,
            loading: false,
            keyword: '',
            statuses: [
                { value: 'active', label: 'Active' },
                { value: 'not active', label: 'Not active' },
                { value: 'all', label: 'Active and not active' },
            ],
        };
    }
    componentDidMount() {
        this.getDataFunc();
    }
    componentWillUnmmount() {
        this._isMounted = false;
    }
    showLoading(show) {
        if (this._isMounted) this.setState({ loading: show });
    }
    onStatusChange(value) {
        this.props.setSelectedStatus(value).then(() => this.getDataFunc());
    }
    filterCategory(category) {
        this.props.setSelectedCategory(category).then(() => this.getDataFunc());
    }
    onFilterChange(type) {
        Promise.all([this.props.setFilterType(type), this.props.setSelectedCategory(null)]).then(() => this.getDataFunc());
    }
    handleInputChange(e) {
        let newState = {};
        newState[e.target.dataset.valueKey] = e.target.value;
        this.setState(newState);
    }
    async exportAll() {
        try {
            this.showLoading(true);
            const res = await this.props.getTemplateList(
                this.state.type,
                this.state.keyword,
                this.state.selectedCategory,
                this.state.status.value,
                0,
                9999,
                null,
                null,
            );
            const papa = require('papaparse');
            const parseConfig = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };
            var csvPayload = papa.unparse(res.data, parseConfig);
            var csv = 'data:text/csv;charset=utf-8,' + csvPayload;
            var data = encodeURI(csv);
            var link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', 'criteria.csv');
            link.click();
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async getDataFunc(a = 0, b = 20, c = null, d = null) {
        const { type, selectedCategory, status } = this.props.criteria;
        const { keyword } = this.state;
        try {
            this.showLoading(true);
            let productCategories = await this.props.getAllProductCategory(status.value);
            let serviceCategories = await this.props.getAllServiceCategory(status.value);
            const res = await this.props.getTemplateList(type, keyword, selectedCategory, status.value, a, b, c, d);
            if (!this._isMounted) return;
            this.setState({
                templates: res.data,
                tablePages: res.totalNoOfPages,
                totalNum: res.totalNoOfItems,
                loading: false,
                productCategories,
                serviceCategories,
            });
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    render() {
        return (
            <div className="page-widget">
                <TemplateListPanel
                    getDataFunc={this.getDataFunc}
                    templates={this.state.templates}
                    handleInputChange={this.handleInputChange}
                    onFilterChange={this.onFilterChange}
                    loading={this.state.loading}
                    tablePages={this.state.tablePages}
                    totalNum={this.state.totalNum}
                    productCategories={this.state.productCategories}
                    serviceCategories={this.state.serviceCategories}
                    onCategorySelected={this.filterCategory}
                    exportAll={this.exportAll}
                    onStatusChange={this.onStatusChange}
                    statuses={this.state.statuses}
                    status={this.props.criteria.status}
                    typeState={this.props.criteria.type}
                    selectedCategory={this.props.criteria.selectedCategory}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        criteria: state.staffCertificationAssessmentCriteria,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAllProductCategory: status => {
            return dispatch(getAllProductCategory(status));
        },
        getAllServiceCategory: status => {
            return dispatch(getAllServiceCategory(status));
        },
        getTemplateList: (type, keyword, selectedCategory, status, a, b, c, d) => {
            return dispatch(search(type, keyword, selectedCategory, status, a, b, c, d));
        },
        setFilterType: type => {
            return dispatch(criteriaTemplateSetFilterType(type));
        },
        setSelectedCategory: category => {
            return dispatch(criteriaTemplateSetSelectedCategory(category));
        },
        setSelectedStatus: status => {
            return dispatch(criteriaTemplateSetSelectedStatus(status));
        },
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AssessmentTemplateList),
);
