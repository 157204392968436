import React from 'react';

import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';

const InputField = ({ inputProps = {}, labelClassName, className, readOnly, children, label, instruction }) => {
    const { type, id, value, checked } = inputProps;
    // FOR CHECKBOX TYPE ONLY
    const isCheckboxType = type === 'checkbox';
    const checkedVal = checked ? 'Yes' : 'No';
    const check = isCheckboxType && {
        inline: readOnly ? false : true,
        check: '',
    };
    // COMPONENT
    const PreviewComponent = ({ label }) => <div>{label}</div>;
    const LabelComponent = () => (
        <Label for={id} className={'text-bold ' + labelClassName}>
            {label}
        </Label>
    );
    return (
        <Row className={className || 'mt-2 mb-2'}>
            <Col xs={12}>
                <FormGroup>
                    <Form {...check}>
                        {!isCheckboxType && <LabelComponent />}
                        {!readOnly && (children || <Input {...inputProps} />)}
                        {isCheckboxType && <LabelComponent />}
                        {readOnly && <PreviewComponent label={isCheckboxType ? checkedVal : value} />}
                        {instruction && (
                            <Label>
                                <small>{instruction}</small>
                            </Label>
                        )}
                    </Form>
                </FormGroup>
            </Col>
        </Row>
    );
};

export const ButtonComponent = ({ color, className, colClassName, onClick, label }) => (
    <FlexCol xs={2} className={colClassName}>
        <Button className={'text-bold ' + className} color={color} onClick={onClick}>
            {label}
        </Button>
    </FlexCol>
);

export default InputField;
