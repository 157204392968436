import React from 'react';
import { connect } from 'react-redux';
import { SGBC_S3_ROOT } from 'config.js';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { Container, Col, Row, Button } from 'reactstrap';
import TopNavigation2 from 'layouts/TopNavigation2';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AffiliatesPanel from '../components/AffiliatesPanel';

import { publicEventPageEventGetInfo } from 'actions/member/home/memberHomeAction';

import './event-directory.css';

class DirectoryPage extends React.Component {
    _isMounted = true;
    state = { loading: false, cards: [] };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getRelevantStuff();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(loading) {
        this.setState({ loading });
    }
    async getRelevantStuff() {
        try {
            this.showLoading(true);
            const { data } = await this.props.getEventInfo();
            if (this._isMounted) {
                this.setState({
                    cards: data,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    extractInnerHTMLFromElement(htmlString) {
        let div = document.createElement('div');
        div.innerHTML = htmlString.replace(/<[^>]+>/g, '');
        let text = (div.firstChild && div.firstChild.innerHTML) || div.innerHTML;
        text = text.replace(/&nbsp;/g, ' ');
        text = text.replace(/&amp;/g, '&');
        return text;
    }
    render() {
        if (this.state.loading)
            return (
                <div style={{ marginTop: '30px' }}>
                    <LoadingSpinner />
                </div>
            );
        return (
            <div>
                <CardsPanel
                    goBack={this.props.history.goBack}
                    cards={this.state.cards}
                    extractInnerHTMLFromElement={this.extractInnerHTMLFromElement}
                />
                <AffiliatesPanel />
            </div>
        );
    }
}

const CardsPanel = ({ cards, extractInnerHTMLFromElement, goBack }) => {
    const ImagePanel = ({ src }) => (
        <div className="event-image">
            <img src={src} />
        </div>
    );
    const DatePanel = ({ dateRecord }) => {
        let dateArr = [];
        let tempDtArray = dateRecord.split(',');
        for (var i = 0; i < tempDtArray.length; i++) {
            try {
                let tempDtItemArray = tempDtArray[i].split('~');
                let dtStr = moment(tempDtItemArray[0], 'YYYY-MM-DD').format('DD MMMM YYYY');
                if (tempDtItemArray.length > 1 && tempDtItemArray[1] != null && tempDtItemArray[1] != '') {
                    dtStr += ', ' + moment(tempDtItemArray[1], 'hh:mm A').format('hh:mm A');
                }
                if (tempDtItemArray.length > 2 && tempDtItemArray[2] != null && tempDtItemArray[2] != '') {
                    dtStr += ' - ' + moment(tempDtItemArray[2], 'hh:mm A').format('hh:mm A');
                }
                dateArr.push(dtStr);
            } catch (e) {
                // console.log(e)
            }
        }

        return dateArr.map((dateItem, i) => (
            <span key={i}>
                {dateItem}
                <br />
            </span>
        ));
        // {eventDatesWithTimes.split(',').map((dateRecord, i) => (
        //     <React.Fragment>

        //         {moment(dateRecord.trim()).format('DD MMMM YY')}
        //         <br />
        //     </React.Fragment>
        // ))}
        // {moment(eventDate, 'YYYY-MM-DD').format('DD MMMM YY')} - {moment(eventTime, 'hh:mm A').format('hh:mm A')} <br />
    };
    const HeaderPanel = ({ eventDescription, eventVenue, eventDate, eventDatesWithTimes, eventTime }) => (
        <div className="eventCard-content padding--left" style={{ overflowY: 'scroll', height: '130px' }}>
            <h3 className="eventCard-title text--sectionTitle" style={{ height: 'auto' }}>
                {eventDescription === ' ' || !eventDescription
                    ? '-'
                    : // : eventDescription.length > 40
                      // ? eventDescription.substring(0, 40) + '...'
                      extractInnerHTMLFromElement(eventDescription)}
            </h3>
            {/* <br /> */}
            <p className="eventCard-dateTime text--secondary">
                <DatePanel dateRecord={eventDatesWithTimes} />
                <br />
                <strong>
                    {eventVenue === null
                        ? ''
                        : // : eventVenue.length > 40
                          // ? eventVenue.substring(0, 40) + '...'
                          extractInnerHTMLFromElement(eventVenue)}
                </strong>
            </p>
        </div>
    );
    const DescriptionPanel = ({ eventSummary }) => (
        <div className="flex flex--row eventCard-content meta padding--left">
            <div className="flex-item">
                <p className="text--small text--secondary text--ellipsisOneLine">{eventSummary}</p>
            </div>
        </div>
    );

    return (
        <Container className="sgbc-newtheme homepanel">
            <Row style={{ position: 'relative', left: '5px', top: '-35px', paddingLeft: '10px', paddingRight: '10px' }}>
                <div>
                    <h5>
                        <b>
                            To <span style={{ color: '#78be20' }}>subscribe</span> to SGBC news and events, please click{' '}
                            <u>
                                <a href="https://mailchi.mp/sgbc/subscribe-public-bulletin" target="_blank" style={{ color: '#78be20' }}>
                                    here
                                </a>
                            </u>
                            .
                        </b>
                    </h5>
                </div>
            </Row>
            <Row>
                {cards && cards.length > 0 ? (
                    cards.map((c, i) => {
                        const eventDescription = extractInnerHTMLFromElement(c.eventDescription);
                        const eventBanner =
                            c.bannerImage !== null
                                ? SGBC_S3_ROOT + c.bannerImage
                                : 'https://blogs.umass.edu/natsci397a-eross/files/2016/12/Green-Building-Logo.jpg';
                        return (
                            <Col sm={12} md={6} key={i} style={{ marginBottom: '20px' }}>
                                <div className="card card--hasShadow card--hasShadowPlusHover exploreHome-eventCard">
                                    <a href={`/public/event/rsvp/${c.eventId}`} className="display--block">
                                        <ImagePanel src={eventBanner} />
                                        <HeaderPanel
                                            eventDescription={eventDescription}
                                            eventVenue={c.eventVenue}
                                            eventDate={c.eventDate}
                                            eventDatesWithTimes={c.eventDatesWithTimes}
                                            eventTime={`${c.eventStartTime}`}
                                        />
                                    </a>
                                    {/* <DescriptionPanel eventSummary={c.eventSummary} /> */}
                                </div>
                            </Col>
                        );
                    })
                ) : (
                    <div>
                        <h2>No events found</h2>
                        {/* <p>There's no event to show for now. Please check back later.</p> */}
                        <Button color="info" size="lg" className="primary-btn-style" onClick={goBack}>
                            Back
                        </Button>
                    </div>
                )}
            </Row>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getEventInfo: () => {
        return dispatch(publicEventPageEventGetInfo());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(TopNavigation2(DirectoryPage)));
