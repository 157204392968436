import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getMembersAgeProfile } from 'actions/analytics/memberAnalyticsAction';
import CyderVerticalBar from 'pages/analytics/components/charts/CyderVerticalBar';

class AgeDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.load().then(res => {
            let labels = [];
            let data = [];
            let total = 0;
            res.data.forEach(element => {
                labels.push(element.ageProfile);
                data.push(element.totalMembers);
                total += element.totalMembers;
            });
            this.setState({
                labels,
                total,
                title: `Age Profile for Companies (${total})`,
                data,
                height: 200,
            });
        });
    }
    render() {
        return (
            <AnalyticsCard title={'Age Profile for Companies'}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CyderVerticalBar {...this.state} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getMembersAgeProfile());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AgeDistribution));
