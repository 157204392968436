import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import SecuringWrapper from 'cyder/common/SecuringWrapper';

import { Row, Col, Badge, Button, Form, FormGroup } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';
import DialogModal from 'cyder/modals/DialogModal';
import YesNoIcon from 'cyder/media/YesNoIcon';
import EditButton from 'cyder/media/EditButton';
import UpgradeAsTeamMember from 'cyder/media/UpgradeAsTeamMember';
import InputComponent from 'reactstrap-input';

import {
    setModalData,
    setModalDataUpgradeSubscriber,
    onChangeValue,
    resetState,
    resetAddTeamMemberInputs,
    createTeamMember,
    upgradeSubscriber,
    memberGetTeamMembers,
    setHasEmailUsed,
} from 'actions/shared/teamManagementAction';
import {
    staffCompanyDetailsGetTeamMemberList,
    staffCompanyDetailsGetSubscriberList,
} from '../../actions/staff/company/staffCompanyDetailsTeamManagementAction';

import moment from 'moment';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';
import { truthyUN, propGenerator, hasElevatedRoles, isCertManager } from 'js/util';
import { validateFromValidationObject } from 'js/validation';

import './TeamManagementComponent.css';

const VerifiedBadge = () => (
    <Badge className="text-bold" style={{ width: '80px', backgroundColor: '#317446' }}>
        Active
    </Badge>
);
const UnverifiedBadge = () => (
    <Badge color="warning" style={{ fontWeight: 'bold', width: '80px' }}>
        Suspended
    </Badge>
);
class TeamManagementComponent extends React.Component {
    constructor(props) {
        super(props);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.memberGetDataFunc = this.memberGetDataFunc.bind(this);
        this.staffGetDataFunc = this.staffGetDataFunc.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetState();
        try {
            this.boundForceUpdate();
        } catch (e) {}
    }
    boundForceUpdate() {
        if (this.props.mode == 'member') {
            this.props.memberGetTeamMembers(0, 10, null, null);
        } else if (this.props.modalUsage == 'upgradeSubscriber') {
            // added by fernando on 27 Jan 2020 to support new modal for upgrade subscriber
            this.props.staffGetSubscribers(0, 5, null, null, this.props.companyId || this.props.match ? this.props.companyId : null);
            this.props.staffGetTeamMembers(0, 5, null, null, this.props.companyId || this.props.match ? this.props.companyId : null);
        } else {
            if (this.props.subscriber) {
                this.props.staffGetSubscribers(0, 5, null, null, this.props.companyId || this.props.match ? this.props.companyId : null);
            } else {
                this.props.staffGetTeamMembers(0, 5, null, null, this.props.companyId || this.props.match ? this.props.companyId : null);
            }
        }
    }
    memberGetDataFunc(a = 0, b = 10, c, d) {
        this.props.memberGetTeamMembers(a, b, c, d);
    }
    staffGetDataFunc(a = 0, b = 5, c, d) {
        this.props.staffGetTeamMembers(a, b, c, d, this.props.companyId || (this.props.match ? this.props.match.params.companyid : null));
    }
    async subscriberGetDataFunc(a = 0, b = 5, c, d) {
        this.setState({
            loading: true,
        });
        await this.props.staffGetSubscribers(
            a,
            b,
            c,
            d,
            this.props.companyId || (this.props.match ? this.props.match.params.companyid : null),
        );
        this.setState({
            loading: false,
        });
    }
    toggleModalAddTeamMember(modalBody, isSubscriber) {
        this.props.resetAddTeamMemberInputs();
        this.props.dialogModalToggle();
        this.props.setModalData(
            {
                modalAction: () => {
                    this.props
                        .createTeamMember(this.props.mode, this.props.companyId, this.props.companyInfo.name, isSubscriber)
                        .then(res => {
                            this.boundForceUpdate();
                        });
                },
                modalBody,
            },
            false,
        );
    }

    toggleModalUpgradeSubscriber(personId) {
        this.props.dialogModalToggle();
        this.props.setModalDataUpgradeSubscriber({
            modalAction: () => {
                this.props.upgradeSubscriber(personId).then(res => {
                    this.boundForceUpdate();
                });
            },
        });
    }

    generateProps(valueKey) {
        return propGenerator({
            valueKey,
            fields: this.props.fields,
            validationObj: this.props.validation,
            handleChange: this.props.onChangeValue,
            saveButtonPressed: true,
        });
    }
    determineSavable(mode, modalUsage) {
        // Added by Fernando on 27 Jan 2020 to support new modal dialog for
        // add subscriber confirmation
        if (modalUsage != null && modalUsage === 'upgradeSubscriber') {
            return true;
        }

        // Validate empty input
        if (this.props.fields.firstName.length === 0) return false;
        if (this.props.fields.lastName.length === 0) return false;
        if (this.props.fields.email.length === 0) return false;
        // Validate matched email
        const memberList = mode === 'member' ? this.props.teamMembers : this.props.tableData.memberList;

        let hasNoError = true;
        for (let i = 0; i < memberList.length; i++) {
            if (memberList[i].email === this.props.fields.email) {
                this.props.setHasEmailUsed(true);
                hasNoError = false;
            }
        }
        if (hasNoError) this.props.setHasEmailUsed(false);
        // Validate email format
        return validateFromValidationObject(this.props.validation, {
            email: true,
        });
    }
    renderMemberList(mode, columns) {
        return mode === 'member' ? (
            <CyderReactTable
                data={this.props.teamMembers}
                columns={columns}
                pages={this.props.tablePages}
                defaultPageSize={5}
                loading={this.props.loading}
                totalNum={this.props.totalItems}
                minRows={0}
                onFetchData={state => {
                    this.memberGetDataFunc(state.page, state.pageSize, state.sorted, state.filtered);
                }}
            />
        ) : (
            <CyderReactTable
                data={this.props.tableData.memberList}
                columns={columns}
                minRows={0}
                filterable={false}
                manual
                pages={this.props.tableData.tablePages}
                defaultPageSize={5}
                // loading={this.props.tableData.loading}
                totalNum={this.props.tableData.totalItems}
                onFetchData={state => {
                    this.staffGetDataFunc(state.page, state.pageSize, state.sorted, state.filtered);
                }}
            />
        );
    }
    renderSubscriberList(mode, columns) {
        return (
            <CyderReactTable
                data={this.props.subscriberData.memberList}
                columns={columns}
                minRows={0}
                filterable={false}
                manual
                pages={this.props.subscriberData.tablePages}
                defaultPageSize={5}
                // loading={this.props.subscriberData.loading}
                totalNum={this.props.subscriberData.totalItems}
                onFetchData={state => {
                    this.subscriberGetDataFunc(state.page, state.pageSize, state.sorted, state.filtered);
                }}
            />
        );
    }

    render() {
        const memberColumns = [
            {
                Header: 'Name',
                accessor: 'firstName',
                headerStyle: { whiteSpace: 'unset' },
                width: 150,
                style: { whiteSpace: 'unset' },
                Cell: props =>
                    this.props.mode ? (
                        <div>
                            {truthyUN(props.value) && props.value} {truthyUN(props.original.lastName) && props.original.lastName}
                        </div>
                    ) : (
                        <div>
                            {truthyUN(props.value) && props.value} {truthyUN(props.original.lastName) && props.original.lastName}
                            {props.original.designation && ` (${props.original.designation})`}
                        </div>
                    ),
            },
            {
                Header: 'Mobile Number',
                headerStyle: { whiteSpace: 'unset' },
                accessor: 'mobileNumber',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Email',
                accessor: 'email',
                minWidth: 150,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Status',
                accessor: 'personalAccountApproved',
                style: { whiteSpace: 'unset' },
                maxWidth: 100,
                Cell: props => {
                    return props.value === 1 ? <VerifiedBadge /> : <UnverifiedBadge />;
                },
            },
            {
                Header: 'Official Management Representative',
                accessor: 'officialMangementRep',
                maxWidth: 130,
                sortable: false,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Corporate Administrator',
                accessor: 'corporateAdmin',
                maxWidth: 130,
                style: { whiteSpace: 'unset' },
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Certification Coordinator',
                accessor: 'sgbcCertCoordinator',
                maxWidth: 130,
                headerStyle: { whiteSpace: 'unset' },
                sortable: false,
                style: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Learning & Development Coordinator',
                //Header: 'HR Representative',
                accessor: 'hrCoordinator',
                maxWidth: 120,
                sortable: false,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Joined',
                accessor: 'dateJoined',
                maxWidth: 130,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => (props.value != null ? moment(props.value).format('DD/MM/YYYY') : '-'),
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 100,
                // show: hasElevatedRoles(this.props.profile.user),
                sortable: false,
                Cell: props => {
                    const staffLink = '/staff/company/' + props.original.companyId + '/staffmemberdetail/' + props.value;
                    const memberLink = '/member/myaccount/teammanagement/member/' + props.value;
                    return (
                        <SecuringWrapper isCorporateAdmin isStaff isCertCoordinator isOmr isSecondarymr>
                            <Link to={this.props.mode === 'member' ? memberLink : staffLink}>
                                <EditButton />
                            </Link>
                        </SecuringWrapper>
                    );
                },
            },
        ];

        const staffColumns = [
            {
                Header: 'Name',
                accessor: 'firstName',
                style: { whiteSpace: 'unset' },
                width: 200,
                Cell: props => (
                    <div>
                        {truthyUN(props.value) && props.value} {truthyUN(props.original.lastName) && props.original.lastName}
                    </div>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Status',
                accessor: 'personalAccountApproved',
                style: { whiteSpace: 'unset' },
                width: 200,
                Cell: props => {
                    return props.value === 1 ? <VerifiedBadge /> : <UnverifiedBadge />;
                },
            },
            {
                Header: 'Official Management Representative',
                accessor: 'officialMangementRep',
                maxWidth: 130,
                sortable: false,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Corporate Administrator',
                accessor: 'corporateAdmin',
                maxWidth: 130,
                style: { whiteSpace: 'unset' },
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Certification Coordinator',
                accessor: 'sgbcCertCoordinator',
                maxWidth: 130,
                headerStyle: { whiteSpace: 'unset' },
                sortable: false,
                style: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Learning & Development Coordinator',
                //Header: 'HR Representative',
                accessor: 'hrCoordinator',
                maxWidth: 120,
                sortable: false,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => <YesNoIcon negativeIcon="cancel" val={props.value} />,
            },
            {
                Header: 'Joined',
                accessor: 'dateJoined',
                maxWidth: 130,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => (props.value != null ? moment(props.value).format('DD/MM/YYYY') : '-'),
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 100,
                show: hasElevatedRoles(this.props.profile.user) || isCertManager(this.props.profile.user),
                sortable: false,
                Cell: props => {
                    const staffLink = '/staff/company/' + props.original.companyId + '/staffmemberdetail/' + props.value;
                    const memberLink = '/member/myaccount/teammanagement/member/' + props.value;
                    return (
                        <SecuringWrapper isCorporateAdmin isStaff isCertCoordinator isOmr isSecondarymr>
                            <Link to={this.props.mode === 'member' ? memberLink : staffLink}>
                                <EditButton />
                            </Link>
                        </SecuringWrapper>
                    );
                },
            },
        ];
        const columns = this.props.mode === 'member' ? memberColumns : staffColumns;
        const customModalBody = () => (
            <Form>
                <FormGroup className="col-sm-6">
                    <InputComponent label="First Name *" {...this.generateProps('firstName')} />
                </FormGroup>
                <FormGroup className="col-sm-6">
                    <InputComponent label="Last Name *" {...this.generateProps('lastName')} />
                </FormGroup>
                <FormGroup className="col-sm-10">
                    <InputComponent label="Email *" type="email" {...this.generateProps('email')} />
                </FormGroup>
            </Form>
        );

        const subscriberColumns = [
            {
                Header: 'Name',
                accessor: 'firstName',
                style: { whiteSpace: 'unset' },
                Cell: props => (
                    <div>
                        {truthyUN(props.value) && props.value} {truthyUN(props.original.lastName) && props.original.lastName}
                    </div>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                maxWidth: 200,
                show: hasElevatedRoles(this.props.profile.user) || isCertManager(this.props.profile.user),
                sortable: false,
                Cell: props => {
                    const staffLink = '/staff/company/' + props.original.companyId + '/staffmemberdetail/' + props.value;
                    const memberLink = '/member/myaccount/teammanagement/member/' + props.value;
                    return (
                        <SecuringWrapper isCorporateAdmin isStaff isCertCoordinator isOmr isSecondarymr>
                            <Link to={this.props.mode === 'member' ? memberLink : staffLink}>
                                <EditButton />
                            </Link>
                            {` `}
                            {this.props.mode === 'staff' && (
                                <UpgradeAsTeamMember
                                    onClick={e => {
                                        this.toggleModalUpgradeSubscriber(props.value);
                                    }}
                                />
                            )}
                        </SecuringWrapper>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <DialogModal
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    modalHeader={this.props.modalHeader}
                    boundForceUpdate={this.boundForceUpdate}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.overrideModalWithMessage ? this.props.modalMessage : customModalBody()}
                    positiveButtonDisabler={!this.determineSavable(this.props.mode, this.props.modalUsage)}
                    buttonDisabler={this.props.modalLoading}
                />
                {!this.props.subscriber && this.props.mode === 'member' && (
                    <React.Fragment>
                        <br />
                        <Row>
                            <Col xs={12} className="d-flex">
                                <div>
                                    <h3>
                                        <strong>TEAM MANAGEMENT</strong>
                                    </h3>
                                    <p>Please contact SGBC regarding additions of Team Members</p>
                                </div>
                            </Col>
                        </Row>

                        <br />
                    </React.Fragment>
                )}
                {/* On 1 Feb 2019, customer requested to dis-allow self team management by member */}
                {!this.props.subscriber && this.props.mode !== 'member' && (
                    <Row className="pb-4">
                        <Col xs={8}>
                            <TeamMemberWriteUpPanel />
                        </Col>
                        <Col xs={4}>
                            <SecuringWrapper isCorporateAdmin isCertCoordinator isOmr isSecondarymr isStaff>
                                <Button className="float-right primary-btn-style" onClick={() => this.toggleModalAddTeamMember(null)}>
                                    Add Team Member
                                </Button>
                            </SecuringWrapper>
                        </Col>
                        <br />
                    </Row>
                )}
                {this.props.subscriber && this.props.mode !== 'member' && (
                    <Row className="pb-4">
                        <Col xs={12}>
                            <SecuringWrapper isCorporateAdmin isCertCoordinator isOmr isSecondarymr isStaff>
                                <Button className="float-right primary-btn-style" onClick={() => this.toggleModalAddTeamMember(null, true)}>
                                    Add Subscriber
                                </Button>
                            </SecuringWrapper>
                        </Col>
                        <br />
                    </Row>
                )}
                {!this.props.subscriber && (
                    <Row>
                        <Col xs={12}>{this.renderMemberList(this.props.mode, columns)}</Col>
                    </Row>
                )}

                {this.props.subscriber && (
                    <Row className="pb-4">
                        <Col xs={8}>
                            <SubcriberWriteUpPanel />
                        </Col>

                        <br />
                    </Row>
                )}
                {this.props.subscriber && (
                    <Row>
                        <Col xs={12}>{this.renderSubscriberList(this.props.mode, subscriberColumns)}</Col>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const SubcriberWriteUpPanel = () => {
    return (
        <div>
            <h3 className="text-bold">Subscribers</h3>
        </div>
    );
};

const TeamMemberWriteUpPanel = () => {
    const representativeAdmin = [
        'Sign Up/ Renew SGBC Membership',
        'Receive email updates from SGBC',
        'Manage Organisation Profile',
        'Access Resource Library',
        'Apply/ Renew SGBC Certification applications',
    ];

    const teamMember = [
        'Receive email updates from SGBC',
        'Manage Organisation Profile',
        'Access Resource Library',
        'Apply/ Renew SGBC Certification applications',
    ];
    return (
        <div>
            <div className="mb-4">
                <h3 className="text-bold">Official Management Representative/ Corporate Admin is able to:</h3>
                {representativeAdmin.map((label, i) => (
                    <div className="p-1" key={i}>
                        <i className="material-icons text-bold align-text-bottom">done</i>
                        {' ' + label}
                    </div>
                ))}
            </div>
            <div className="mb-4">
                <h3 className="text-bold">Team Member is able to:*</h3>
                {teamMember.map((label, i) => (
                    <div className="p-1" key={i}>
                        <i className="material-icons text-bold align-text-bottom">done</i>
                        {' ' + label}
                    </div>
                ))}
            </div>
            <i>Account must first be created by SGBC Secretariat</i>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.memberCompanyReducer,
        ...state.teamManagementReducer,
        ...state.staffCompanyDetailsTeamManagementReducer,
        profile: state.profile,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        memberGetTeamMembers: (a, b, c, d) => {
            dispatch(memberGetTeamMembers(a, b, c, d));
        },
        staffGetTeamMembers: (a, b, c, d, companyId) => {
            dispatch(staffCompanyDetailsGetTeamMemberList(a, b, c, d, companyId));
        },
        staffGetSubscribers: (a, b, c, d, companyId) => {
            return dispatch(staffCompanyDetailsGetSubscriberList(a, b, c, d, companyId));
        },
        setModalData: (data, overrideModalWithMessage) => {
            dispatch(setModalData(data, overrideModalWithMessage));
        },
        setModalDataUpgradeSubscriber: data => {
            dispatch(setModalDataUpgradeSubscriber(data));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        resetState: () => {
            dispatch(resetState());
        },
        resetAddTeamMemberInputs: () => {
            dispatch(resetAddTeamMemberInputs());
        },
        createTeamMember: (mode, companyId, companyName, isSubscriber) => {
            return dispatch(createTeamMember(mode, companyId, companyName, isSubscriber));
        },
        upgradeSubscriber: personId => {
            return dispatch(upgradeSubscriber(personId));
        },
        onChangeValue: e => {
            const key = e.target.id;
            const value = e.target.value;
            dispatch(onChangeValue(key, value));
        },
        setHasEmailUsed: isUsed => {
            dispatch(setHasEmailUsed(isUsed));
        },
    };
};
export default StoredLayout(connect(mapStateToProps, mapDispatchToProps)(TeamManagementComponent));
