/**
 * Common Date Picker
 *
 * Author: Fernando
 */

import React from 'react';
import Datetime from 'react-datetime';

import '../../css/forms/react-datetime.css';

const CyderDatePicker = ({
    id,
    onChange,
    value,
    defaultValue,
    valid,
    placeholder,
    dateFormat,
    timeFormat,
    datetimeClassName,
    closeOnSelect,
    disabled,
    width,
    readOnly = true,
}) => {
    let className = 'form-control';
    let closeOnSelectVal = closeOnSelect ? true : false;
    if (valid === true) className = className + (valid ? ' is-valid' : ' is-invalid');
    return (
        <div style={{ width: width || 100 }}>
            <Datetime
                className={datetimeClassName}
                value={value || ''}
                defaultValue={defaultValue}
                inputProps={{ id, className: className, placeholder: placeholder }}
                dateFormat={dateFormat !== undefined ? dateFormat : 'DD/MM/YYYY'}
                timeFormat={timeFormat !== undefined ? timeFormat : false}
                input={true}
                viewMode={'days'}
                onChange={onChange}
                closeOnSelect={closeOnSelectVal}
                inputProps={{ disabled, readOnly: readOnly }}
            />
        </div>
    );
};

export default CyderDatePicker;
