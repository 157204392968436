/**
 * List of URLs for Non Member
 *
 * Author: Fernando
 */

const urls = [
    // {
    //     title: 'Membership',
    //     url: '/public/membership',
    // },
    // {
    //     title: 'Certification',
    //     url: '/public/certifications',
    // },
    {
        title: 'Directory',
        url: '/public/directory',
    },
    {
        title: 'Login',
        url: '/public/login/member',
    },
];
export function navigationPublic(state = Array.from(urls), action) {
    switch (action.type) {
        case 'SET_NONMEMBERNAVIGATION':
            return Object.assign({}, state);
        default:
            return state;
    }
}
