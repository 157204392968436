import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'reactstrap';
import moment from 'moment';
import { getByEventId } from 'actions/staff/events/event-expenditure/action.js';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import '../../../../scss/main.scss';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.getRelevantStuff = this.getRelevantStuff.bind(this);
        this.state = {
            list: [],
            loading: true,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuff(page, pageSize, sorted, filtered) {
        let list = [];
        let pageCount = 0;
        let totalNum = 0;
        try {
            const res = await this.props.search(this.props.event.eventid, page, pageSize, sorted, filtered);
            list = res.data;
            pageCount = res.totalNoOfPages;
            totalNum = res.totalNoOfItems;
        } catch (error) {
            throw error;
        } finally {
            if (this._isMounted) {
                let total = 0;
                if (list != null && list.length > 0) {
                    list.forEach(item => {
                        if (item.transactionType == 'Expenditure') {
                            total = total - item.amount;
                        } else {
                            total = total + item.amount;
                        }
                    });
                }
                this.setState({
                    list,
                    pageCount,
                    loading: false,
                    total,
                    totalNum,
                });
            }
        }
    }
    render() {
        const { total } = this.state;

        const formatter = new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: 'SGD',
            minimumFractionDigits: 2,
        });

        const columns = [
            {
                Header: 'Trasaction Date',
                accessor: 'transactionDate',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Transaction Type',
                accessor: 'transactionType',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.original.transactionType == 'Expenditure') {
                        return <span className="deficit">{formatter.format(props.value)}</span>;
                    } else {
                        return <span className="surplus">{formatter.format(props.value)}</span>;
                    }
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Button
                            className="primary-btn-style"
                            onClick={e => {
                                this.props.setMode('add', props.value);
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">edit</i>
                        </Button>
                    );
                },
            },
        ];
        return (
            <div className="dinner-income">
                <Row className="mb-2">
                    <Col xs={12}>
                        <Form inline>
                            <div>
                                <h3>
                                    {total < 0 ? (
                                        <div>
                                            Deficit {` `} <span className="deficit">{formatter.format(total)}</span>
                                        </div>
                                    ) : (
                                        <div>
                                            Surplus {` `} <span className="surplus">{formatter.format(total)}</span>
                                        </div>
                                    )}
                                </h3>
                            </div>
                            <div className="ml-auto">
                                <Button
                                    className="ml-1 mr-1 align-middle primary-btn-style"
                                    onClick={e => {
                                        this.props.setMode('add', 0);
                                    }}
                                >
                                    <i className="material-icons align-text-bottom">add</i>
                                    &nbsp;Add
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            sortable={false}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.getRelevantStuff(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (eventId, page, pageSize, sorted, filtered) => {
            return dispatch(getByEventId(eventId, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListPage);
