import { SGBC_DISABLED_CERT, SGBC_OVERRIDE_ENABLED_CERT_COMPANIES } from 'config.js';
import { isMobile } from 'react-device-detect';
/**
 * List of URLs for Member
 *
 * Author: Fernando
 */

const urls = [
    {
        title: 'Account',
        items: [
            {
                url: '/member/myaccount/dashboard',
                title: 'Dashboard',
            },
            {
                url: '/member/myaccount/teammanagement',
                title: 'Team Management',
            },
            {
                url: '/member/myaccount/companyprofile',
                title: 'Company Profile',
            },
            {
                url: '/member/myaccount/personalprofile',
                title: 'My Details',
            },
            {
                url: '/',
                title: 'Log out',
                action: 'logout',
            },
        ],
    },
    {
        title: 'Membership',
        items: [
            {
                url: '/member/membership/overview',
                title: 'Overview',
            },
            {
                url: '/member/membership/applicationlist',
                title: 'Applications',
            },
        ],
    },
    {
        title: 'Certification',
        items: SGBC_DISABLED_CERT
            ? [
                  {
                      url: '/member/certification/overview',
                      title: 'Overview',
                  },
                  {
                      url: '/member/certification/product/certificates',
                      title: 'Product Certificates',
                  },
                  {
                      url: '/member/certification/service/certificates',
                      title: 'Services Certificates',
                  },
              ]
            : [
                  {
                      url: '/member/certification/overview',
                      title: 'Overview',
                  },
                  {
                      url: '/member/certification/product/applications/all',
                      title: 'Product Application',
                  },
                  {
                      url: '/member/certification/service/applications/all',
                      title: 'Services Application',
                  },
                  {
                      url: '/member/certification/product/certificates',
                      title: 'Product Certificates',
                  },
                  {
                      url: '/member/certification/service/certificates',
                      title: 'Services Certificates',
                  },
              ],
    },
    {
        url: '/member/events/event',
        title: 'Events',
    },
    {
        title: 'Directories',
        items: [
            {
                url: '/member/memberdirectory',
                title: 'SGBC Members',
            },
            {
                url: '/public/product',
                title: 'Certified Products',
            },
            {
                url: '/public/service',
                title: 'Certified Services',
            },
        ],
    },
    {
        title: 'Industry',
        items: [
            {
                url: 'https://www.sgbc.sg/sgbc-certifications/sgbp-recognition-in-bca-green-mark',
                external: true,
                title: 'SGBP Recognition in BCA Green Mark',
            },
        ],
    },
];

const urlsMobile = [
    {
        url: '/member/myaccount/dashboard',
        title: 'Dashboard',
    },
    {
        url: '/member/membership/overview',
        title: 'Membership',
    },
    {
        url: '/member/certification/overview',
        title: 'Certification',
    },
    {
        url: '/member/events/event',
        title: 'Events',
    },
    {
        url: '/',
        title: 'Log out',
        action: 'logout',
    },
];
export function navigationMember(state = Array.from(isMobile ? urlsMobile : urls), action) {
    switch (action.type) {
        case 'SET_MEMBERNAVIGATION':
            return Object.assign({}, state);
        default:
            return state;
    }
}
