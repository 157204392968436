/**
 * Service details panel
 *
 * Author: Fernando Karnagi
 */
import React, { Fragment } from 'react';
import history from 'history.js';

import { Row, Col, Input, Button, Collapse } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import TakeUpCasePanel from 'pages/staff/certification/applications/case/TakeUpCasePanel';
import AssignApproverPanel from 'pages/staff/certification/applications/approval/AssignApproverPanel';
import AssignOfficerPanel from 'pages/staff/certification/applications/approval/AssignOfficerPanel';
import ScoresheetViewPanel from 'pages/staff/certification/assessment/components/ScoresheetViewPanel';
import {
    StatusLabel,
    ProductDetailsButton as ServiceDetailsButton,
} from 'pages/staff/certification/applications/product/components/ProductDetailsPanel';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';
import UpdateStatusPopup from 'pages/staff/certification/applications/update/UpdateStatusPopup';

const buttonStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const parseRating = rating => {
    switch (rating) {
        case 5:
            return 'L1';
        case 6:
            return 'L2';
        case 7:
            return 'L3';
        case 8:
            return 'L4';
        default:
            return 'No Rating';
    }
};

export const ServiceDetails = ({
    visible,
    title,
    status,
    selectState,
    unlinkInvoice,
    remarks,
    greenRemarks,
    isSelfFinancingCapable,
    selectedServiceCategory,
    submittedOn,
    certificateDetails,
    onRemarksChange,
    addRemarks,
    updateStatus,
    statusId,
    onStatusChange,
    startAssessment,
    assessmentStarted,
    viewAssessment,
    viewAssessmentStarted,
    takeUpCase,
    takeUpCaseStarted,
    takeUpConfirm,
    templates,
    selectedTemplate,
    onTemplateChange,
    newTemplateChoosen,
    previewTemplate,
    prevAssessmentId,
    previewOldTemplate,
    applicationTypeOldRenew,
    completeAssessment,
    completeAssessmentDisabled,
    assignApproverStarted,
    sendForApproval,
    sendToCaseOfficer,
    selectedOfficer,
    officers,
    onSelectedOfficerChange,
    assignNextApproverStarted,
    sendForNextApproval,
    updatePayment,
    downloadReceipt,
    discontinue,
    certificateStatus,
    applicationId,
    downloadInvoice,
    selectedPeer,
    peers,
    onSelectedPeerChange,
    peerReviewStatus,
    selectedManager,
    managers,
    onSelectedManagerChange,
    managerStatus,
    approve,
    reject,
    startFeedback,
    startPreparation,
    completeApplication,
    markAsPaid,
    type,
    updateServiceWithCertificate,
    printCertificate,
    peerReviewRemarks,
    managerRemarks,
    applicationDetails,
    authorizeInvoice,
    editApplication,
    itemCode,
    viewSurveyResult,
    paymentInfo,
    otCharges,
    disableInvoice,
    generatedCertNumber,
    manufacturerContactPersonName,
    manufacturerTelephone,
    onHandleChange,
    onOverseasTransactionChargesChange,
    onDisableInvoiceChange,
    takeOverCase,
    returnMua,
    certificates,
    printCert,
    handleRegenerate,
    closeCase,
    xeroInvoice,
    profile,
    approvers,
    viewPreviousAssessment,
    viewPreviousApplication,
    viewPreviousCertificate,
    createAndSendReceipt,
    toggleApplicantDetailsOpen,
    isApplicantDetailsOpen,
    sendWithdrawalEmail,
    muaPanel,
    markAsPaidDisabled,
    sendForApprovalClicked,
    viewCertificates,
    printMUA,
}) => {
    const {
        certificateToRenewOrAmendId,
        oldCertificateApplicationId,
        oldAssessmentId,
        oldAssessmentTemplateId,
        tagging,
        rating,
        muaLocked,
        serviceCategoryId,
    } = applicationDetails;
    const isEPC = serviceCategoryId === 2 || serviceCategoryId === 10;

    var serviceJson = {};
    try {
        serviceJson = JSON.parse(applicationDetails.service);
    } catch (e) {}

    if (!visible) return '';
    return (
        <NoShadowPanel title={title} borderSize={1} flat hasNolineBreak titleStyle={{ color: '#317446', fontSize: '3.25em' }}>
            <div>
                {status != 'Draft' && (
                    <Row>
                        <Col className="align-items-center text-bold" style={{ textAlign: 'right' }}>
                            {profile.profile.is_superuser === 1 && (
                                <UpdateStatusPopup
                                    updateStatus={updateStatus}
                                    onStatusChange={onStatusChange}
                                    type="button"
                                    statusId={statusId}
                                />
                            )}
                            <AuditListPopup withIcon contentTypeId={49} type="button" objectId={applicationDetails.id} />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col xs="12">
                        <StatusLabel
                            status={status}
                            peerReviewStatus={peerReviewStatus}
                            managerStatus={managerStatus}
                            peerReviewRemarks={peerReviewRemarks}
                            managerRemarks={managerRemarks}
                            additionalInfo={submittedOn}
                            muaLocked={muaLocked}
                            caseClosed={applicationDetails.caseClosed}
                            xeroInvoice={xeroInvoice}
                            approvers={approvers}
                            withInvoice={applicationDetails.withInvoice}
                            applicationTypeId={applicationDetails.applicationTypeId}
                            muaPanel={muaPanel}
                        />
                        {status === 'Submitted' && (
                            <React.Fragment>
                                <TakeUpCasePanel
                                    templates={templates}
                                    onTemplateChange={onTemplateChange}
                                    selectedTemplate={selectedTemplate}
                                    takeUpCaseStarted={takeUpCaseStarted}
                                    previewTemplate={previewTemplate}
                                    previewOldTemplate={previewOldTemplate}
                                    applicationTypeNewRenew={applicationTypeOldRenew}
                                    newTemplateChoosen={newTemplateChoosen}
                                    prevAssessmentId={prevAssessmentId}
                                    applicationDetails={applicationDetails}
                                />
                            </React.Fragment>
                        )}

                        <div className="form-group">
                            {status === 'Assessment' && !assessmentStarted && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}
                            {status === 'Finalisation' && (
                                <Fragment>
                                    {muaLocked == 1 && (
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={returnMua}
                                            icon="keyboard_return"
                                            label="Return MUA"
                                        />
                                    )}
                                </Fragment>
                            )}

                            {(status === 'Issued' || status === 'Finalisation') && (
                                <Fragment>
                                    {muaLocked == 1 && (
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={printMUA}
                                            icon="remove_red_eye"
                                            label="View MUA"
                                        />
                                    )}

                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={viewSurveyResult}
                                        icon="feedback"
                                        label="View Survey Submission"
                                    />
                                </Fragment>
                            )}
                            {(status === 'Peer Approval' || status === 'Manager Approval') && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}
                            {status === 'Paid' && applicationDetails.withInvoice == 1 && (
                                <React.Fragment>
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={updatePayment}
                                        icon="payment"
                                        label="Update Payment Info"
                                    />
                                </React.Fragment>
                            )}
                            {(status === 'Paid' || status === 'Finalisation' || status === 'Issued') &&
                                applicationDetails.withInvoice == 1 && (
                                    <React.Fragment>
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={downloadReceipt}
                                            icon="receipt"
                                            label="Download Receipt"
                                        />
                                    </React.Fragment>
                                )}
                            {status === 'Approval' && !viewAssessmentStarted && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={viewAssessment}
                                    icon="format_list_bulleted"
                                    label="View Assessment"
                                />
                            )}
                            {status !== 'Draft' &&
                                status !== 'Submitted' &&
                                status !== 'Discontinued' &&
                                status !== 'Assessment' &&
                                status !== 'Finalisation' &&
                                status !== 'Issued' &&
                                applicationDetails.withInvoice == 1 &&
                                status !== 'Accepted' && (
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={downloadInvoice}
                                        icon="receipt"
                                        label="Download Invoice"
                                    />
                                )}
                            {status === 'Issued' && (
                                <Fragment>
                                    {/* {applicationDetails.caseClosed == 0 && (
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={handleRegenerate}
                                            icon="payment"
                                            label="Generate Certificate PDF"
                                        />
                                    )} */}
                                    {(applicationDetails.caseClosed == 1 || selectState.enablePrintCert) && (
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            onClick={printCert}
                                            icon="payment"
                                            label="Print Certificate"
                                        />
                                    )}
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={e => {
                                            history.push(
                                                `/staff/certification/certificates/service/certdetails/${certificates.data[0].id}`,
                                            );
                                        }}
                                        icon="payment"
                                        label="View Certificate Details"
                                    />
                                </Fragment>
                            )}
                            {(status === 'Submitted' ||
                                status === 'Accepted' ||
                                status === 'Assessment' ||
                                status === 'Approval' ||
                                status === 'Rejected by Peer' ||
                                status === 'Rejected by Manager') &&
                                !assessmentStarted && (
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        icon="edit"
                                        label="Edit Application"
                                        onClick={editApplication}
                                    />
                                )}
                            {(status === 'Submitted' || status === 'Accepted' || status === 'Assessment' || status === 'Approval') &&
                                certificateToRenewOrAmendId && (
                                    <Fragment>
                                        <ServiceDetailsButton
                                            colStyle={buttonStyle}
                                            icon="file_copy"
                                            label="View Previous Certificate"
                                            onClick={() => viewPreviousCertificate(certificateToRenewOrAmendId)}
                                        />
                                        {oldAssessmentId && oldAssessmentTemplateId && (
                                            <ServiceDetailsButton
                                                colStyle={buttonStyle}
                                                icon="format_list_bulleted"
                                                label="View Previous Assessment"
                                                onClick={() =>
                                                    viewPreviousAssessment(
                                                        oldCertificateApplicationId,
                                                        oldAssessmentId,
                                                        oldAssessmentTemplateId,
                                                    )
                                                }
                                            />
                                        )}
                                        {oldCertificateApplicationId && (
                                            <ServiceDetailsButton
                                                colStyle={buttonStyle}
                                                icon="format_list_bulleted"
                                                label="View Previous Application"
                                                onClick={() => viewPreviousApplication(oldCertificateApplicationId)}
                                            />
                                        )}
                                    </Fragment>
                                )}

                            {(status === 'Assessment' || status === 'Accepted') && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={discontinue}
                                    icon="not_interested"
                                    label="Discontinue Application"
                                    btnProps={{ flat: true }}
                                />
                            )}
                            {status !== 'Draft' && status !== 'Submitted' && status !== 'Issued' && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    icon="cached"
                                    label="Take Over Case"
                                    onClick={() => takeOverCase()}
                                />
                            )}
                            {status === 'Discontinued' && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    icon="cached"
                                    label="Send Withdrawal Email"
                                    onClick={() => sendWithdrawalEmail()}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {status === 'Accepted' && (
                            <ServiceDetailsButton
                                colStyle={buttonStyle}
                                onClick={startAssessment}
                                color={`#e1ab4c`}
                                icon="format_list_bulleted"
                                label="Start Assessment"
                            />
                        )}
                        {status === 'Assessment' && (
                            <React.Fragment>
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    disabled={completeAssessmentDisabled}
                                    color={`#e1ab4c`}
                                    onClick={completeAssessment}
                                    icon="done"
                                    label="Complete Assessment"
                                />
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    color={`#e1ab4c`}
                                    onClick={unlinkInvoice}
                                    icon="remove_circle_outline"
                                    label="Remove Invoice"
                                />
                            </React.Fragment>
                        )}
                        {(status === 'Approval' || status === 'Rejected by Peer' || status === 'Rejected by Manager') &&
                            (peerReviewStatus === null ||
                                peerReviewStatus === '' ||
                                peerReviewStatus === 'Rejected' ||
                                managerStatus === 'Rejected') && (
                                <Fragment>
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        color={`#e1ab4c`}
                                        onClick={sendForNextApproval}
                                        icon="description"
                                        label="Send for Approval"
                                        disabled={sendForApprovalClicked}
                                    />
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        onClick={reject}
                                        icon="thumb_down"
                                        color={`#e1ab4c`}
                                        label="Send for Re-evaluation"
                                        btnProps={{ flat: true }}
                                    />
                                </Fragment>
                            )}
                        {status !== 'Submitted' &&
                            status !== 'Assessment' &&
                            status !== 'Accepted' &&
                            applicationDetails.withInvoice == 1 &&
                            status === 'Manager Approval' &&
                            profile.user.id == applicationDetails.managerId && (
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    disabled={
                                        xeroInvoice != null &&
                                        xeroInvoice.data != null &&
                                        xeroInvoice.data.length > 0 &&
                                        (xeroInvoice.data[0].Status == 'AUTHORISED' || xeroInvoice.data[0].Status == 'PAID')
                                    }
                                    onClick={authorizeInvoice}
                                    icon="receipt"
                                    color={`#e1ab4c`}
                                    label="Authorise Invoice"
                                />
                            )}
                        {status === 'Peer Approval' && profile.user.id == applicationDetails.peerReviewId && (
                            <ServiceDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                onClick={approve}
                                icon="thumb_up"
                                label="Approve"
                            />
                        )}
                        {status === 'Manager Approval' && profile.user.id == applicationDetails.managerId && (
                            <ServiceDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                disabled={
                                    xeroInvoice != null &&
                                    xeroInvoice.data != null &&
                                    xeroInvoice.data.length > 0 &&
                                    xeroInvoice.data[0].Status != 'AUTHORISED' &&
                                    xeroInvoice.data[0].Status != 'PAID'
                                }
                                onClick={approve}
                                icon="thumb_up"
                                label="Approve"
                            />
                        )}
                        {((status === 'Peer Approval' && profile.user.id == applicationDetails.peerReviewId) ||
                            (status === 'Manager Approval' && profile.user.id == applicationDetails.managerId)) && (
                            <ServiceDetailsButton
                                colStyle={buttonStyle}
                                onClick={reject}
                                icon="thumb_down"
                                disabled={
                                    xeroInvoice != null &&
                                    xeroInvoice.data != null &&
                                    xeroInvoice.data.length > 0 &&
                                    (xeroInvoice.data[0].Status == 'AUTHORISED' || xeroInvoice.data[0].Status == 'PAID')
                                }
                                color={`#e1ab4c`}
                                label="Reject"
                                btnProps={{ flat: true }}
                            />
                        )}
                        {status === 'Invoice' && (
                            <React.Fragment>
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={updatePayment}
                                    color={`#e1ab4c`}
                                    icon="payment"
                                    label="Update Payment Info"
                                />
                                <ServiceDetailsButton
                                    color={`#e1ab4c`}
                                    colStyle={buttonStyle}
                                    onClick={markAsPaid}
                                    icon="payment"
                                    label="Mark as Paid"
                                    disabled={markAsPaidDisabled}
                                />
                            </React.Fragment>
                        )}
                        {status === 'Finalisation' && (
                            <ServiceDetailsButton
                                colStyle={buttonStyle}
                                color={`#e1ab4c`}
                                onClick={completeApplication}
                                icon="done"
                                disabled={muaLocked == 0}
                                label="Proceed to Generate Cert"
                            />
                        )}
                        {status == 'Paid' && (
                            <Fragment>
                                {applicationDetails.withInvoice == 1 && (
                                    <ServiceDetailsButton
                                        colStyle={buttonStyle}
                                        color={`#e1ab4c`}
                                        onClick={createAndSendReceipt}
                                        icon="done"
                                        label="Send Receipt"
                                    />
                                )}
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    color={`#e1ab4c`}
                                    onClick={startFeedback}
                                    icon="done"
                                    label="Start Finalisation"
                                    disabled={applicationDetails.withInvoice == 1 && !selectState.sendReceiptPressed}
                                />
                            </Fragment>
                        )}
                        {status === 'Issued' && applicationDetails.caseClosed == 0 && (
                            <Fragment>
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={handleRegenerate}
                                    icon="payment"
                                    color={`#e1ab4c`}
                                    label="Generate Certificate PDF"
                                />
                                <ServiceDetailsButton
                                    colStyle={buttonStyle}
                                    onClick={closeCase}
                                    disabled={selectState.enableCloseCaseBtn}
                                    icon="done"
                                    color={`#e1ab4c`}
                                    label="Close the case"
                                />
                            </Fragment>
                        )}
                    </Col>
                </Row>
                {status != 'Draft' && (
                    <Row>
                        <Col className="align-items-center text-bold" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <a
                                href="#"
                                onClick={viewCertificates}
                                style={{ color: '#317446', display: 'flex', justifyContent: 'flex-end' }}
                            >
                                <u>View List of Certificate Numbers</u>
                            </a>
                        </Col>
                    </Row>
                )}
                <hr />

                {status === 'Assessment' && assignApproverStarted && (
                    <Fragment>
                        <AssignOfficerPanel
                            sendToCaseOfficer={sendToCaseOfficer}
                            selectedOfficer={selectedOfficer}
                            officers={officers}
                            onSelectedOfficerChange={onSelectedOfficerChange}
                            type={type}
                            certificateDetails={certificateDetails}
                            applicationId={applicationId}
                            applicationDetails={applicationDetails}
                            updateServiceWithCertificate={updateServiceWithCertificate}
                            onHandleChange={onHandleChange}
                            otCharges={otCharges}
                            generatedCertNumber={generatedCertNumber}
                            onOverseasTransactionChargesChange={onOverseasTransactionChargesChange}
                            onDisableInvoiceChange={onDisableInvoiceChange}
                            disableInvoice={disableInvoice}
                        />
                        <hr />
                    </Fragment>
                )}

                {status === 'Approval' && assignNextApproverStarted && (
                    <Fragment>
                        <AssignApproverPanel
                            sendForApproval={sendForApproval}
                            selectedPeer={selectedPeer}
                            peers={peers}
                            onSelectedPeerChange={onSelectedPeerChange}
                            selectedManager={selectedManager}
                            managers={managers}
                            onSelectedManagerChange={onSelectedManagerChange}
                        />
                        <hr />
                    </Fragment>
                )}

                {/* APPLICANT DETAILS */}
                <Fragment>
                    <Row style={{ marginBottom: '1.5em' }}>
                        <Col xs="3">
                            <div className="form-group">
                                <label className="field-header">Services Category</label>
                                <div className="field-label">{selectedServiceCategory}</div>
                            </div>
                        </Col>
                        <Col xs="3">
                            <div className="form-group">
                                <label className="field-header">Rating</label>
                                <div className="field-label">{parseRating(rating)}</div>
                            </div>
                        </Col>
                        {isEPC && (
                            <Col xs="6">
                                <div className="form-group">
                                    <label className="field-header">Are you able to offer Zero Capital Partnership (ZCP) Scheme?</label>
                                    <div className="field-label">{applicationDetails.isSelfFinancingCapable === 1 ? 'Yes' : 'No'}</div>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row onClick={toggleApplicantDetailsOpen} style={{ marginBottom: '1.5em' }}>
                        <Col>
                            <label className="field-header">
                                <h4 className="text-bold">Applicant Details</h4>
                            </label>
                        </Col>
                        <Col className="text-right">
                            <i
                                className="material-icons"
                                style={{
                                    color: 'black',
                                    fontSize: '20px',
                                }}
                            >
                                {isApplicantDetailsOpen ? 'close' : 'filter_list'}
                            </i>
                        </Col>
                    </Row>
                    <Collapse isOpen={isApplicantDetailsOpen}>
                        <Row style={{ marginBottom: '1.5em' }}>
                            <Col md={4} className="pb-0">
                                <div className="form-group">
                                    <h5 className="text-bold">Main Contact Person </h5>
                                    <div className="field-label">{manufacturerContactPersonName ? manufacturerContactPersonName : '-'}</div>
                                </div>
                            </Col>
                            <Col md={4} className="pb-0">
                                <div className="form-group">
                                    <h5 className="text-bold">Main Contact Phone</h5>
                                    <div className="field-label">{manufacturerTelephone ? manufacturerTelephone : '-'}</div>
                                </div>
                            </Col>
                        </Row>
                    </Collapse>
                </Fragment>

                {/* SCORESHEET VIEW PANEL */}
                <Fragment>
                    {(status === 'Peer Approval' || status === 'Manager Approval') && applicationDetails != null && (
                        <Fragment>
                            <hr />
                            <h4 className="text-bold">Assessment</h4>
                            {applicationDetails && applicationDetails.assessmentId && (
                                <ScoresheetViewPanel
                                    hidePrint
                                    reviewMode
                                    scoresheetId={applicationDetails.assessmentId}
                                    type={type}
                                    staff
                                    templateId={applicationDetails.templateId}
                                    applicationId={applicationDetails.id}
                                />
                            )}
                        </Fragment>
                    )}
                    {(status === 'Approval' || status === 'Rejected by Peer' || status === 'Rejected by Manager') &&
                        (peerReviewStatus === null ||
                            peerReviewStatus === '' ||
                            peerReviewStatus === 'Rejected' ||
                            managerStatus === 'Rejected') && (
                            <Fragment>
                                <hr />
                                <h4 className="text-bold">Assessment</h4>
                                {applicationDetails && applicationDetails.assessmentId && (
                                    <ScoresheetViewPanel
                                        hidePrint
                                        staff
                                        reviewMode
                                        scoresheetId={applicationDetails.assessmentId}
                                        type={type}
                                        templateId={applicationDetails.templateId}
                                        applicationId={applicationDetails.id}
                                    />
                                )}
                            </Fragment>
                        )}
                </Fragment>

                {!applicationDetails && !applicationDetails.service && (
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <label className="field-header">Certificate Number</label>
                            <div className="field-label">{serviceJson.certificationNumber}</div>
                        </Col>
                        <Col>
                            <label className="field-header">Xero Item Code</label>
                            <div className="field-label">{serviceJson.itemCode}</div>
                        </Col>
                        <Col>
                            <label className="field-header">Rating</label>
                            <div className="field-label">{serviceJson.ticks}</div>
                        </Col>
                    </Row>
                )}
                {/* <Row>
                    <Col xs="5">
                        <div className="form-group">
                            <h5 className="text-bold">
                                Please indicate the product’s Green Feature(s) and/or Attribute(s), (e.g. low VOC level, energy saving properties, recyclability, usage of recycled content, etc.).
                            </h5>
                            {!takeUpCaseStarted ? (
                                <React.Fragment>
                                    <div className="field-label">
                                        <Input type="textarea" value={greenRemarks} disabled={true} />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="field-label">
                                    {greenRemarks === '' || greenRemarks == null ? 'No green Remarks provided' : greenRemarks}
                                </div>
                            )}
                        </div>
                    </Col>
                </Row> */}

                {/* REMARKS */}
                <Row>
                    <Col xs="5">
                        <div className="form-group">
                            <h5 className="text-bold">Remarks</h5>
                            {!takeUpCaseStarted ? (
                                <React.Fragment>
                                    <div className="field-label">
                                        <Input type="textarea" value={remarks} onChange={onRemarksChange} />
                                    </div>
                                    <Button className="ml-auto primary-btn-style" onClick={addRemarks}>
                                        <i className="mr-1 material-icons align-text-bottom ">save</i>
                                        Update Remarks
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <div className="field-label">{remarks === '' ? 'No remarks provided' : remarks}</div>
                            )}
                        </div>
                    </Col>
                </Row>

                {/* BUTTON PANEL */}
                <hr />
                <Row>
                    <Col xs="12" className="text-right">
                        {status === 'Submitted' && takeUpCaseStarted && (
                            <Fragment>
                                <ButtonIcon className="mr-2 primary-btn-style-outline" onClick={() => takeUpCase(false)} label="Back" />
                                <ButtonIcon onClick={takeUpConfirm} icon="check" label="Accept Application" />
                            </Fragment>
                        )}
                        {status === 'Submitted' && !takeUpCaseStarted && <ButtonIcon onClick={() => takeUpCase(true)} label="Next" />}
                    </Col>
                </Row>
            </div>
        </NoShadowPanel>
    );
};
