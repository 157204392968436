import React from 'react';

const YesIcon = props => <img src="/assets/icons/tick-icon.png" {...props} />;
const NoIcon = props => <img src="/assets/icons/no-icon.png" {...props} />;
const PendingIcon = props => <img src="/assets/icons/pending-icon.png" {...props} />;

const IconComponent = ({ yes, yesAlt, noAlt, onClick, width = '20' }) => {
    const style = onClick && { cursor: 'pointer' };
    const icon = yes ? (
        <YesIcon style={style} alt={yesAlt} onClick={onClick} width={width} />
    ) : (
        <NoIcon style={style} alt={noAlt} onClick={onClick} width={width} />
    );
    return icon;
};

const YesNoIcon = props => {
    const { val } = props;
    switch (val) {
        case null:
            return <i className="fa fa-icon text-muted fa-question-circle" />;
        case true:
        case 1:
            return <IconComponent yes {...props} />;
        case false:
        case 0:
            return <IconComponent {...props} />;
        default:
            return null;
    }
};

export { PendingIcon };
export default YesNoIcon;
