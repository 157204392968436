import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { Media } from 'reactstrap';

import '../../css/elements/logo.css';

const LogoImg = ({ user, type, src, size }) => {
    const style2 = {
        width: size || '80%',
        height: size || '80%',
    };
    return (
        <div className="logo-img-thing d-flex justify-content-center">
            <Media style={style2} object src={type ? `/assets/images/sgbc-${type}.png` : src} />
        </div>
    );
};

const Logo = ({ user, type, src, size }) => {
    return (
        <Link
            to={window.location.pathname.toLowerCase().indexOf('/staff/') >= 0 ? `/staff/home` : `/staff/home`}
            className="logo logo-low-padding d-flex justify-content-start align-items-center flex-nowrap"
        >
            <LogoImg user={user} type={type} src={src} size={size} />
        </Link>
    );
};

export default translate()(Logo);
