export const EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST = 'EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST';
export const EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS = 'EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS';
export const EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE = 'EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE';

export const EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST = 'EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST';

export const EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS = 'EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS';
export const EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE = 'EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE';

export const EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS = 'EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS';
export const EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE = 'EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE';

export const EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS = 'EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS';
export const EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE = 'EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE';

export const EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST = 'EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST';

export const EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE = 'EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE';

export const EVENT_REGISTRATION_RESET_TO_DEFAULT = 'EVENT_REGISTRATION_RESET_TO_DEFAULT';
export const EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA = 'EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA';
