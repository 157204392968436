import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import {
    AnalyticsBigTextWithNormalText,
    AnalyticsCardBigText,
    AnalyticsBigText,
    AnalyticsNormalText,
} from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getSGBCMemberAttendeesAnalytics, getRegistrantsAnalytics } from 'actions/analytics/eventsAnalyticsAction';

class SGBCMemberAttendees extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                this.setState({
                    data: res.data,
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }

    render() {
        const xs = {
            bigxs: 3,
            normalxs: 9,
        };
        return (
            <AnalyticsCard title={`Total Registrants (Cumulative)`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigTextWithNormalText
                            normalText={`Total Registrants`}
                            normalTextColor={`unset`}
                            // bigText={this.state.data[0][0].sgbcMemberRegistrants + this.state.data[1][0].nonsgbcMemberRegistrants}
                            bigText={this.state.data[2][0].totalRegistrants}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Member Registrants`}
                            normalTextColor={`unset`}
                            bigText={this.state.data[0][0].sgbcMemberRegistrants}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Non-Member Registrants`}
                            normalTextColor={`unset`}
                            bigText={this.state.data[1][0].nonsgbcMemberRegistrants}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getRegistrantsAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SGBCMemberAttendees));
