import React from 'react';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Input, Row, Col, Button, Card } from 'reactstrap';
import withQuery from 'with-query';

import DialogModal from 'cyder/modals/DialogModal';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import { setReducerModalState, defaultModalKeys } from '../../../../../js/modal';
import { SGBC_API_ROOT, SGBC_API_KEY } from 'config';
// import { charge } from 'actions/member/membership/application/MembershipPayNowAction.js';

const StripeInputWrapper = props => {
    return (
        <div className="form-group">
            <div className="field-header">
                {props.label} <MandatoryStar />
                {props.wrap ? <Card className="p-2 col-12">{props.children}</Card> : props.children}
            </div>
            <small className="form-text text-muted">{props.description}</small>
        </div>
    );
};

class CreditCardPayment extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setButtonLoading = this.setButtonLoading.bind(this);
        this.onCardHolderNameChanged = this.onCardHolderNameChanged.bind(this);
        this.state = {
            cardHolderName: '',
            loading: false,
            ...defaultModalKeys,
        };
    }
    onCardHolderNameChanged(e) {
        this.setState({
            cardHolderName: e.target.value,
        });
    }
    setLoading(loading) {
        this.setState({ loading });
    }
    setButtonLoading(show) {
        this.setState({ buttonLoading: show });
    }
    toggleDialogModal(header, body, action) {
        const data = {
            modalAction: action,
            modalBody: body,
            modalHeader: header,
            modalOnlyOneButton: true,
        };
        this.props.dialogModalToggle();
        const state = setReducerModalState(setReducerModalState, data);
        this.setState(state);
    }
    async submitOld(ev) {
        console.log('is.props.applicationInfo.price: ', this.props.applicationInfo);
    }
    async submit(ev) {
        // User clicked submit

        this.setButtonLoading(true);

        try {
            var { token } = await this.props.stripe.createToken({
                name: this.state.cardHolderName,
            });
        } catch (error) {
            this.setLoading(false);
            this.setButtonLoading(false);
        }

        this.setLoading(true);
        if (token == null) {
            this.setLoading(false);
            this.setButtonLoading(false);
            this.toggleDialogModal('Error', 'Your credit card is not valid');
            return;
        }
        var body = {
            amount: this.props.applicationInfo.price * 100,
            source: token.id,
            description: 'SGBC Membership Payment',
            currency: 'sgd',
            // receipt_email: this.props.registration.applicantEmail,
        };
        const url = withQuery(SGBC_API_ROOT + '/finances_v1/stripe', {
            action: 'charge',
        });
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    const updatePaymentUrl = withQuery(SGBC_API_ROOT + '/finances_v1/cn/payment', {
                        action: 'update-payment-creditcard',
                    });

                    var updatedPaymentBody = {
                        amountReceived: this.props.applicationInfo.price,
                        referenceNumber: `${token.card.object} ${token.card.brand} ...${token.card.last4}`,
                        remarks: `ClientIP ${token.client_ip}, CreatedTS ${token.created}, TokenID ${token.id}`,
                        id: this.props.applicationInfo.id,
                        template: 'membership',
                    };

                    const paymentOption = {
                        body: JSON.stringify(updatedPaymentBody),
                        method: 'POST',
                        headers: {
                            'x-api-key': SGBC_API_KEY,
                            'Content-Type': 'application/json',
                            Authorization: 'allow',
                        },
                    };

                    fetch(updatePaymentUrl, paymentOption)
                        .then(responsePayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialogModal(
                                'Confirmation',
                                'Payment successfully completed.',
                                // TODO:
                                // history.push(`/member/event/${this.props.registration.data.eventId}/registration`);
                                '/member/membership/applicationlist',
                            );
                        })
                        .catch(errorPayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialogModal('Error', 'We are sorry that this credit card payment is not successful');
                            throw errorPayment;
                        });
                } else {
                    this.toggleDialogModal('Error', 'We are sorry that this credit card payment is not successful');
                }
            })
            .catch(error => {
                this.setLoading(false);
                this.setButtonLoading(false);
                this.toggleDialogModal('Error', 'We are sorry that this credit card payment is not successful');
                throw error;
            });
    }
    render() {
        const inputStyle = {
            base: {
                fontSize: '18px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
        };
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.state.modalAction}
                    modalHeader={this.state.modalHeader}
                    modalBody={this.state.modalBody}
                    modalOnlyOneButton={this.state.modalOnlyOneButton}
                    loading={this.state.loading}
                    buttonDisabler={this.state.modalLoading}
                />
                <div>
                    <Row>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="Credit Card Number" description="Please enter credit card number">
                                <CardNumberElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                        <Col xs="4">
                            <StripeInputWrapper label="Card Holder Name" description="Please enter card holder name">
                                <Input
                                    style={{ borderRadius: 5 }}
                                    value={this.state.cardHolderName}
                                    onChange={this.onCardHolderNameChanged}
                                />
                            </StripeInputWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="Credit Card Expiry" description="Please enter card expiry date">
                                <CardExpiryElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="CCV" description="Please enter CCV">
                                <CardCVCElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={4}>
                            <Button className="primary-btn-style-outline" onClick={this.props.back}>
                                <strong>Back</strong>
                            </Button>
                        </Col>
                        <Col xs={4} className="d-flex justify-content-end">
                            {this.state.buttonLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Button className="primary-btn-style" onClick={this.submit}>
                                    <strong>Submit</strong>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-row pt-4 pb-4" xs={6}>
                            <img src="/assets/sgbc/SGBC_credit_cards_stripe.png" width={300} height={65} />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default injectStripe(CreditCardPayment);
