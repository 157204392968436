import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

import { Container, Button, Col, Row } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
// SGBC custom components
import QuestionnairesSingleWidget from './QuestionnairesSingleWidget';

import { SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';

import { TierResponsePanel } from 'pages/staff/membership/applications/StaffMembershipApplicationsDetailsPage';

import {
    questionnairesNext,
    questionnairesPrev,
    questionnairesResetForm,
    submitQuestionainerAnswers,
} from 'actions/member/membership/application/memberNewMembershipApplicationAction';

import {
    getMembershipApplicationInfo,
    getMembershipApplicationTierResponse,
    getMembershipApplicationTierDocs,
} from 'actions/member/membership/application/membershipApplicationAction';

class QuestionnairesPanel extends React.Component {
    state = {
        answerState: {},
        numOfQuestions: null,
        loading: false,
        modal: {
            showModal: false,
            modalMessage: '',
            loading: false,
            modalAction: '',
            modalHeader: '',
            positiveButtonHide: true,
            negativeButtonHide: true,
            type: 'save',
        },
    };
    async componentDidMount() {
        this.props.resetForm();
        this.setState({
            loading: true,
        });
        window.scrollTo(0, 0);
        let applicationId = this.props.applicationId;

        const tierRes = await this.props.getMembershipAppllicationTierResponse(applicationId);
        const docs = await this.props.getMembershipAppllicationTierDocs(applicationId);
        const res = await this.props.getMembershipApplicationInfo(applicationId);
        this.setState({
            tierQuestions: tierRes.data,
            docs: docs.data,
            application: res.data[0][0], // Need testing (API changed 12th March 2019)
            loading: false,
            isMounted: true,
        });
    }
    tierResponsePostProcessor(tierResponseInfo) {
        const newResponse = {};
        tierResponseInfo.forEach(({ question, option }) => {
            if (!newResponse[question]) newResponse[question] = [];
            newResponse[question].push(option);
        });
        return newResponse;
    }
    setAnswerState = (numOfQuestions, questionNo, value) => {
        let { answerState } = this.state;
        answerState[questionNo] = value;
        this.setState({
            answerState,
            numOfQuestions,
        });
    };
    determineNextable = () => {
        const { numOfQuestions, answerState } = this.state;
        const result = Object.keys(answerState).filter(x => answerState[x]);
        if (result.length < numOfQuestions) return true;
        return false;
    };
    prev = () => {
        // this.resetAnswerState();
        this.setState({
            numOfQuestions: 3,
        });
        this.props.prev();
    };
    next = () => {
        // this.resetAnswerState();
        this.setState({
            numOfQuestions: 4,
        });
        this.props.next();
    };
    resetAnswerState = () => {
        this.setState({
            answerState: {},
            numOfQuestions: null,
        });
    };
    submit = async () => {
        const pushTo = () => history.replace('/member/home');
        try {
            const res = await this.props.submit();
            if (res.ok) {
                this.showModal('Confirmation', 'SGBC Sustainability Commitment successfully submitted.', pushTo, null, null, true, true);
            } else {
                this.showModal('Error', 'Failed to submit your questionaire answers', null, null, true, true);
            }
        } catch (error) {
            throw error;
        }
    };
    // MODAL
    negativeModalAction = () => {
        this.modalToggle();
    };
    showModal = (header, message, action, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        modal.modalAction = action || this.modalToggle;
        this.setState({
            modal,
        });
    };
    showModalIf = (header, body, evaluation, action) => {
        if (evaluation) this.showModal(header, body, action, null, true, null);
        return evaluation;
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    };
    render() {
        const { modal } = this.state;
        let docNumber = 1;
        if (!this.state.isMounted || this.props.application.modalLoading || this.state.loading) return <LoadingSpinner center />;
        else
            return (
                <Container>
                    <DialogModal
                        negativeButtonAction={this.negativeModalAction}
                        actionToggleExternal={this.modalToggle}
                        positiveButtonHide={modal.positiveButtonHide}
                        negativeButtonHide={modal.negativeButtonHide}
                        modalBody={modal.modalMessage}
                        modalAction={modal.modalAction}
                        modalHeader={modal.modalHeader}
                        modalOpen={modal.showModal}
                    />

                    {this.state.application != null &&
                    (this.state.application.tierUpgraded == 1 || this.state.application.tier_upgraded == 1) ? (
                        <div className="page-widget">
                            <TierResponsePanel
                                loading={false}
                                tierResponseInfo={this.state.tierQuestions}
                                tierResponseProcessor={this.tierResponsePostProcessor}
                                member
                            />

                            {this.state.docs != null && this.state.docs.length >= 1 && (
                                <span className="field-header">
                                    These are the documents that you have uploaded:
                                    <br />
                                </span>
                            )}
                            {(this.state.docs == null || this.state.docs.length <= 0) && <b>You have not uploaded any document</b>}

                            {this.state.docs != null && this.state.docs.length >= 1 && this.state.docs[0].docId != null ? (
                                <div>
                                    <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[0].docId}`}>
                                        <u>{docNumber++}. Corporate Sustainability Policy & Practice Framework</u>
                                        <br />
                                    </a>
                                </div>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 2 && this.state.docs[1].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[1].docId}`}>
                                    <u>{docNumber++}. Green Procurement Practices</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 3 && this.state.docs[2].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[2].docId}`}>
                                    <u>{docNumber++}. Corporate Sustainability Report</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 4 && this.state.docs[3].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[3].docId}`}>
                                    <u>{docNumber++}. Third Party audited Environmental Management Systems</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 5 && this.state.docs[4].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[4].docId}`}>
                                    <u>{docNumber++}. Corporate Sustainability Awards & Accolades</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 6 && this.state.docs[5].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[5].docId}`}>
                                    <u>{docNumber++}. Lease/ rent/ own Green Mark certified building</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 7 && this.state.docs[6].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[6].docId}`}>
                                    <u>{docNumber++}. Lease/ rent/ own Green Mark certified office interiors/ workplaces/retail premises</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 8 && this.state.docs[7].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[7].docId}`}>
                                    <u>{docNumber++}. Summary of your organisation’s green building portfolio</u>
                                    <br />
                                </a>
                            ) : null}

                            {this.state.docs != null && this.state.docs.length >= 9 && this.state.docs[8].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[8].docId}`}>
                                    <u>{docNumber++}. Brief summary of the services provided</u>
                                    <br />
                                </a>
                            ) : null}
                            <br />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="page-widget">
                                <InstructionPanel />
                                <Row>
                                    <div className="col-12">
                                        <QuestionnairesSingleWidget
                                            tierResponseInfo={this.state.tierQuestions}
                                            docs={this.state.docs}
                                            setAnswerState={this.setAnswerState}
                                            questionnaires={this.props.application.questionnaires}
                                            visible={this.props.application.questionnaires.activeTabs[0]}
                                        />
                                    </div>
                                </Row>
                            </div>
                            <div className="col-12 footer-right">
                                <Button className="btn primary-btn-style m-2" onClick={this.submit}>
                                    Submit
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </Container>
            );
    }
}

const InstructionPanel = props => {
    return (
        <Col className="text-align-justify mt-4">
            <p>
                Showcase and profile your organisation’s onus and focus on green building and sustainability. Complete the SGBC Member
                Sustainability Commitment self-declaration checklist and position your organisation as a Partner or Advocate of the green
                building movement. Completing the Commitment will serve three key objectives:
                <br />
                <ul>
                    <li>Showcase your organisation’s environmental sustainability achievements</li>
                    <li>Brand your organisation as one aligned with environmental sustainability</li>
                    <li>Identify areas in corporate sustainability policies that can be further enhanced</li>
                </ul>
            </p>
            <p>
                While the Commitment is optional, we highly recommend for all SGBC Members to complete the checklist. This Commitment will
                be reflected on your SGBC Membership Certificate which will be updated at no cost. The checklist will take you about 15
                minutes to complete. Please note that each SGBC Member organisation can only submit the Commitment once. For any
                clarifications, please contact SGBC Membership at <a href="mailto:membership@sgbc.sg">membership@sgbc.sg</a> or call
                6732-5518.
            </p>
            <p>
                <b>Important Note:</b>
                <ul>
                    <li>Provide as much information on your sustainability practices as possible.</li>
                    <li>
                        It is not necessary to respond to every question in the checklist and verification will be based on the questions
                        responded to with the necessary documentation uploaded.
                    </li>
                    <li>Multiple documents and/or files can be uploaded as a zipped folder (no larger than 5MB).</li>
                </ul>
            </p>
            <hr />
        </Col>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberNewMembershipApplicationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        next: () => {
            dispatch(questionnairesNext());
        },
        prev: () => {
            dispatch(questionnairesPrev());
        },
        submit: () => {
            return dispatch(submitQuestionainerAnswers());
        },
        resetForm: () => {
            dispatch(questionnairesResetForm());
        },
        getMembershipAppllicationTierResponse: id => {
            return dispatch(getMembershipApplicationTierResponse(id));
        },
        getMembershipAppllicationTierDocs: id => {
            return dispatch(getMembershipApplicationTierDocs(id));
        },
        getMembershipApplicationInfo: id => {
            return dispatch(getMembershipApplicationInfo(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuestionnairesPanel);
