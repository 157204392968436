import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function staffCertApplCertDetailsGetInfo(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'find-by-id',
            certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function staffCertApplCertDetailsGetRating(isProduct) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-criteria`, {
            action: 'get-all-active-rating',
            isProduct,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function staffCertApplCertDetailsSaveCertDetails(data, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'update_certification_details',
        });
        data.type = type;
        const body = { certificationDetails: data }; // type: product
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
