import React from 'react';

import { Row, Col, FormGroup, Input } from 'reactstrap';

import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import {
    DetailsPanelButton,
    DetailsPanelInput,
    PreviewField,
} from 'pages/member/certification/applications/product/components/ProductDetailsPanel';

const ServiceDetailsPanel = ({
    editable,
    onYesNoOptionChange,
    status,
    selectedYesNoOption,
    title,
    visible,
    viewAssessment,
    downloadInvoice,
    serviceCategory,
    paynow,
    printCertificate,
    service,
    companyInfo,
    feedbackSurvey,
    manufacturerContactPersonName,
    onManufacturerContactPersonNameChange,
    manufacturerTelephone,
    onManufacturerTelephoneChange,
    muaLocked,
}) => {
    const { name, mainPhoneNumber, businessAddressLine1, businessAddressLine2, businessAddressLine3, businessPostalCode } = companyInfo;
    return (
        visible && (
            <NoShadowPanel title={title} flat>
                {status !== 'Draft' && (
                    <Row>
                        <Col>
                            <PreviewField header="Status" label={status} />
                            {status === 'Issued' && <PreviewField header="Certificate Number" label={service.certificationNumber} />}
                        </Col>
                        <Col>
                            <FormGroup>
                                {(status === 'Assessment' || status === 'Accepted') && (
                                    <DetailsPanelButton label="Upload Document" icon="cloud_upload" onClick={viewAssessment} />
                                )}
                                {status === 'Issued' && (
                                    <DetailsPanelButton
                                        label="Download Certificate"
                                        icon="cloud_download"
                                        onClick={e => {
                                            printCertificate(service.certificationNumber, 'service');
                                        }}
                                    />
                                )}
                                {(status === 'Invoice' || status === 'Payment') && (
                                    <DetailsPanelButton
                                        label="Download Invoice"
                                        icon="get_app"
                                        onClick={downloadInvoice}
                                        style={{
                                            marginBottom: '10px',
                                            width: '200px',
                                        }}
                                    />
                                )}
                                {status === 'Invoice' && (
                                    <DetailsPanelButton
                                        label="Update Payment"
                                        icon="attach_money"
                                        onClick={paynow}
                                        style={{
                                            marginBottom: '10px',
                                            width: '200px',
                                        }}
                                    />
                                )}
                                {status === 'Finalisation' && (
                                    <DetailsPanelButton
                                        label="Submit Mark User Agreement"
                                        icon="finalisation"
                                        onClick={feedbackSurvey}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <small className="form-text text-muted">
                                            (*) This is the final step to get your certified product
                                        </small>
                                    </DetailsPanelButton>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                <hr />
                {/* {(status == 'Assessment' || status == 'Accepted') && (
                <Row>
                    <Col>
                        <FormGroup>
                            <Button color="success" onClick={viewAssessment}>
                                <i className="mr-1 material-icons align-text-bottom ">format_list_bulleted</i>
                                View Assessment
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            )} */}
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Company Name" editable={false} value={name} />
                    <DetailsPanelInput header="Company Phone Number" editable={false} value={mainPhoneNumber} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Address 1" editable={false} value={businessAddressLine1} />
                    <DetailsPanelInput header="Address 2" editable={false} value={businessAddressLine2} />
                    <DetailsPanelInput header="Address 3" editable={false} value={businessAddressLine3} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Postal Code" editable={false} value={businessPostalCode} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput
                        mandatory
                        header="Main Contact Person"
                        editable={editable}
                        value={manufacturerContactPersonName}
                        onChange={onManufacturerContactPersonNameChange}
                    />
                    <DetailsPanelInput
                        mandatory
                        header="Main Contact Phone"
                        editable={editable}
                        value={manufacturerTelephone}
                        onChange={onManufacturerTelephoneChange}
                    />
                </Row>
                <Row className="pb-4 pt-4">
                    <Col>
                        <FormGroup check>
                            <label className="field-header">
                                <Input
                                    disabled={!editable}
                                    type="checkbox"
                                    className="mt-2"
                                    id="isSelfFinancingCapable"
                                    checked={selectedYesNoOption}
                                    onChange={onYesNoOptionChange}
                                />
                                &nbsp;Are you EPC self-financing capable?
                                {editable && <MandatoryStar />}
                            </label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="pb-4 pt-4">
                    <PreviewField editable={false} header="Category" label={serviceCategory || 'None'} />
                </Row>
            </NoShadowPanel>
        )
    );
};

export default ServiceDetailsPanel;
