import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Table, Button } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { certificateRenewalDetailAnalytics } from 'actions/analytics/certificateAnalyticsAction';
import { HorizontalBar, Chart } from 'react-chartjs-2';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

class CertificationRenewalDetailsList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load(this.props.renewalStatusId).then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const columns = [
            {
                Header: 'Company',
                accessor: 'companyName',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <Link to={'/staff/company/' + props.original.companyId}>{props.value}</Link>;
                },
            },
            {
                Header: 'Qty',
                accessor: 'count',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['count'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Cert Numbers',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certificateNumbers'] }),
                filterAll: true,
                accessor: 'certificateNumbers',
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return props.value == null
                        ? ''
                        : props.value.split(',').map((item, i) => {
                              let idArr = props.original.certificateIds.split(',');
                              let expiryArr = props.original.certExpiry.split(',');
                              return (
                                  <Link key={i} to={`/staff/certification/certificates/${this.props.type}/certdetails/` + idArr[i].trim()}>
                                      {item.trim()} ({expiryArr[i].trim()})
                                      <br />
                                  </Link>
                              );
                          });
                },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: renewalStatusId => {
            return dispatch(certificateRenewalDetailAnalytics(renewalStatusId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationRenewalDetailsList));
