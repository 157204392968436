import {
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST,
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS,
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE,
    EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST,
    EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST,
    EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE,
    EVENT_REGISTRATION_RESET_TO_DEFAULT,
    EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsRegistration';

import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';

export function exportRegistrationList(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'getAllRegistrationExport',
        });
        const params = {
            eventId: parseInt(eventId),
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function eventsRegistrationGetRegistrationList(eventId, keyword, page, pageSize, sorted, isExport) {
    return (dispatch, getState) => {
        !isExport && dispatch({ type: 'EVENT_REGISTRATION_CHANGE_PAGE_SIZE' });
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'getAllRegistration',
        });
        const params = {
            eventId: parseInt(eventId),
            keyword: keyword || '',
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            sorted,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            params,
            EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST,
            EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS,
            EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE,
        );
    };
}
export function eventsRegistrationGetAllRegistrationList(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'findByEventId',
            eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST, null);
    };
}
// API called but getAllRegistration not update
export function eventsRegistrationConfirmRegistration(registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'registrationConfirm',
            registrationId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST,
            EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS,
            EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE,
        );
    };
}
// API called but getAllRegistration not update
export function eventsRegistrationUnconfirmRegistration(registrationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'registrationUnconfirm',
            registrationId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST,
            EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS,
            EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE,
        );
    };
}
export function eventsRegistrationHandleInputChange(value) {
    return {
        type: EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE,
        value,
    };
}
export function eventsRegistrationResetToDefault() {
    return { type: EVENT_REGISTRATION_RESET_TO_DEFAULT };
}

export function eventsRegistrationDelete(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/registration`, {
            action: 'delete-by-registration-id',
        });
        const params = {
            id,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            params,
            EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST,
            EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS,
            EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE,
        );
    };
}
export function eventsRegistrationSetDialogModalData(data) {
    return {
        type: EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA,
        data,
    };
}
