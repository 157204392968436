import {
    COUNTRY_ADD_SET_FIELDS,
    COUNTRY_ADD_CHANGE_FIELD_VALUE,
    COUNTRY_ADD_MODAL_TOGGLE,
    COUNTRY_ADD_SAVE_COUNTRY_REQUEST,
    COUNTRY_ADD_SAVE_COUNTRY_SUCCESS,
    COUNTRY_ADD_SAVE_COUNTRY_FAILURE,
    COUNTRY_ADD_DELETE_COUNTRY_REQUEST,
    COUNTRY_ADD_DELETE_COUNTRY_SUCCESS,
    COUNTRY_ADD_DELETE_COUNTRY_FAILURE,
} from '../../../actions/constants/actionTypesCountries';

// defaults
var defaultState = {
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    countryFields: {
        created_on: null,
        id: null,
        iso: '',
        name: '',
        updated_by_id: null,
        updated_on: null,
    },
};

export function countryAddReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case COUNTRY_ADD_SET_FIELDS:
            newState.countryFields = action.fields;
            return newState;
        case COUNTRY_ADD_CHANGE_FIELD_VALUE:
            newState.countryFields = Object.assign({}, state.countryFields);
            newState.countryFields[action.key] = action.value;
            return newState;
        case COUNTRY_ADD_SAVE_COUNTRY_REQUEST:
            newState.saving = true;
            return newState;
        case COUNTRY_ADD_SAVE_COUNTRY_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : 'Country successfully saved';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case COUNTRY_ADD_SAVE_COUNTRY_FAILURE:
            newState.modalMessage =
                'Failed to save Country : ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        case COUNTRY_ADD_DELETE_COUNTRY_REQUEST:
            newState.saving = true;
            return newState;
        case COUNTRY_ADD_DELETE_COUNTRY_SUCCESS:
            newState.modalMessage = action.response.message
                ? action.response.message
                : 'Country successfully deleted';
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'redirect';
            return newState;
        case COUNTRY_ADD_DELETE_COUNTRY_FAILURE:
            newState.modalMessage =
                'Failed to delete Country : ' + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = 'close';
            newState.modalOpen = true;
            return newState;
        case COUNTRY_ADD_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === 'save') {
                newState.modalMessage =
                    'Are you sure you want to save this Country?';
                newState.saved = false;
            } else if (action.modalAction === 'delete') {
                newState.modalMessage =
                    'Are you sure you want to delete this Country?';
                newState.saved = false;
            }
            return newState;
        default:
            return state;
    }
}
