import {
    PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST,
    PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS,
    PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS,
    PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE,
    PERSONAL_PROFILE_RESET_TO_DEFAULT,
    PERSONAL_PROFILE_HANDLE_INPUT_CHANGE,
    PERSONAL_PROFILE_SET_MODAL_DATA,
} from '../../actions/constants/actionTypes';
import { setReducerModalState, defaultModalKeys } from '../../js/modal';

const defaultState = {
    loading: false,
    username: '',
    companyId: 0,
    profileInfo: {
        companyId: null,
        email: '',
        firstName: '',
        lastName: '',
        officeNumber: 0,
        mobileNumber: 0,
        companyName: '',
    },
    ...defaultModalKeys,
};

export default function personalProfileReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case PERSONAL_PROFILE_GET_PROFILE_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case PERSONAL_PROFILE_GET_PROFILE_INFO_SUCCESS:
            const response = action.response[0];
            newState.username = response.username;
            newState.companyId = response.companyId;
            newState.profileInfo = {
                companyId: response.companyId,
                email: response.email,
                firstName: response.firstName,
                lastName: response.lastName,
                officeNumber: response.telephoneDid,
                mobileNumber: response.mobileNumber,
                companyName: response.companyName,
            };
            // Handle null or undefined
            Object.keys(newState.profileInfo).forEach(key => {
                const value = newState.profileInfo[key];
                if (!value) {
                    newState.profileInfo[key] = '';
                }
            });
            newState.loading = false;
            return newState;
        case PERSONAL_PROFILE_GET_PROFILE_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case PERSONAL_PROFILE_SAVE_PROFILE_INFO_REQUEST:
            newState.modalLoading = true;
            return newState;
        case PERSONAL_PROFILE_SAVE_PROFILE_INFO_SUCCESS:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = './dashboard';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Your Profile Details have been updated successfully.';
            return newState;
        case PERSONAL_PROFILE_SAVE_PROFILE_INFO_FAILURE:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Failed to save personal profile. Please try again.';
            return newState;
        case PERSONAL_PROFILE_RESET_TO_DEFAULT:
            newState = Object.assign({}, state);
            return newState;
        case PERSONAL_PROFILE_HANDLE_INPUT_CHANGE:
            newState.profileInfo = Object.assign({}, state.profileInfo);
            const id = action.event.target.id;
            const value = action.event.target.value;
            newState.profileInfo[id] = value;
            return newState;
        case PERSONAL_PROFILE_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return newState;
    }
}
