import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import Select from 'react-select';

const selectState = {
    step: 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

class UpdateStatusPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);

        this.state = {
            modal: false,
        };
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    componentDidMount() {
        // if (this.props.ref) {
        //     this.props.ref(this.show);
        // }
    }

    show() {
        this.setState({
            modal: true,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Update Status</ModalHeader>
                    <ModalBody style={{ height: '350px', maxHeight: '400px', overflowY: 'auto' }}>
                        <Select
                            autosize={false}
                            placeholder=""
                            autofocus
                            options={[
                                { label: 'Draft', value: 1 },
                                { label: 'Submitted', value: 2 },
                                { label: 'Accepted', value: 3 },
                                { label: 'Assessment', value: 4 },
                                { label: 'Approval', value: 5 },
                                { label: 'Invoice', value: 6 },
                                { label: 'Paid', value: 7 },
                                { label: 'Finalisation', value: 8 },
                                { label: 'Issued', value: 9 },
                                { label: 'Preparation', value: 10 },
                                { label: 'Discontinued', value: 11 },
                            ]}
                            simpleValue
                            clearable={selectState.clearable}
                            name="selected-state"
                            disabled={selectState.disabled}
                            value={this.props.statusId}
                            searchable={selectState.searchable}
                            onChange={this.props.onStatusChange}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={e => {
                                this.props.updateStatus();
                            }}
                        >
                            Save
                        </Button>
                        <Button color="secondary" onClick={this.toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
                <ButtonIcon
                    borderStyle={{ marginRight: '10px', marginBottom: '10px' }}
                    width={'auto'}
                    icon={`autorenew`}
                    label={`Update Status`}
                    onClick={e => {
                        this.setState({
                            modal: true,
                        });
                    }}
                    fontStyle={{ justifyContent: 'center' }}
                />
            </React.Fragment>
        );
    }
}
export default UpdateStatusPopup;
