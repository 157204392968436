/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import { Link } from 'react-router-dom';

import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import Select from 'react-select';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import FootNotesComponent from './components/FootNotesComponent';
import { FormGroup, Input, Row, Col, Form, Button } from 'reactstrap';

import {
    getCategories,
    getProductCategories,
    getSubCategories,
    getActiveCriteriaSections,
    getSavedItem,
    getCriteriaBySection,
    getCriteriaByType,
    save,
    update,
    setModalData,
    deleteItem,
    inactivate,
    activate,
} from 'actions/staff/certification/staffCertAssessmentAction';
import { getCriteriaDetails } from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaAddAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';
import CustomMultiSelect from './components/CustomMultiSelect';
import { Label } from 'semantic-ui-react';

const generateSubCatLabel = item => `${item.category} > ${item.subcategory} > ${item.type}`;

const selectState = {
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

class TemplateEditPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.toggleModalDelete = this.toggleModalDelete.bind(this);
        this.toggleModalActivate = this.toggleModalActivate.bind(this);
        this.toggleModalInactivate = this.toggleModalInactivate.bind(this);
        this.toggleModalSave = this.toggleModalSave.bind(this);
        this.toggleModalClone = this.toggleModalClone.bind(this);
        this.save = this.save.bind(this);
        this.handleChangeSavedSectionCServiceMinimumCriteria = this.handleChangeSavedSectionCServiceMinimumCriteria.bind(this);
        this.handleChangeCodeTitle = this.handleChangeCodeTitle.bind(this);
        this.initialLoadGetSubCategories = this.initialLoadGetSubCategories.bind(this);
        this.clearModalState = this.clearModalState.bind(this);
        this.getCriteriaBySection = this.getCriteriaBySection.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getActiveCriteriaSections = this.getActiveCriteriaSections.bind(this);
        this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getProductCategories = this.getProductCategories.bind(this);
        this.preview = this.preview.bind(this);
        this.productSectionAChange = this.productSectionAChange.bind(this);
        this.productSectionBChange = this.productSectionBChange.bind(this);
        this.productSectionCChange = this.productSectionCChange.bind(this);
        this.serviceSectionAChange = this.serviceSectionAChange.bind(this);
        this.serviceSectionBChange = this.serviceSectionBChange.bind(this);
        this.serviceSectionCChange = this.serviceSectionCChange.bind(this);
        this.onFootNotesFieldChange = this.onFootNotesFieldChange.bind(this);
        this.onUploadFail = this.onUploadFail.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.deleteDoc = this.deleteDoc.bind(this);
        this.state = {
            loading: false,
            categories: [],
            subCategories: [],
            selectedCategory: null,
            selectedSubCategory: null,
            modalShow: false,
            existingSectionA: [],
            savedSectionA: [],
            existingSectionB: [],
            savedSectionB: [],
            existingSectionC: [],
            savedSectionC: [],
            savedSectionCServiceMinimumCriteria: 5,
            existingSectionAService: [],
            savedSectionAService: [],
            existingSectionBService: [],
            savedSectionBService: [],
            existingSectionCService: [],
            savedSectionCService: [],

            userGuideResourceId: '',
            userGuideFileName: '',

            ratingRule: {
                good: {
                    critical: 1,
                    supplementary: 1,
                },
                veryGood: {
                    critical: 2,
                    supplementary: 3,
                },
                excellent: {
                    critical: 3,
                    supplementary: 5,
                },
                leader: {
                    critical: 4,
                    supplementary: 7,
                },
            },
            cloneTitle: 'Hello',
        };
    }
    preview() {
        const url = `/staff/certification/assessment/scoresheet/preview/${this.state.type}/${this.state.id}/0`;
        history.push(url);
    }
    onFootNotesFieldChange(field, subField, value) {
        var ratingRule = Object.assign({}, this.state.ratingRule);
        var fieldObj = Object.assign({}, ratingRule[field]);
        fieldObj[subField] = value;
        ratingRule[field] = fieldObj;
        this.setState({ ratingRule });
    }
    productSectionAChange(selectedItems) {
        this.setState({ savedSectionA: selectedItems });
    }
    productSectionBChange(selectedItems) {
        this.setState({ savedSectionB: selectedItems });
    }
    productSectionCChange(selectedItems) {
        this.setState({ savedSectionC: selectedItems });
    }
    serviceSectionAChange(selectedItems) {
        this.setState({ savedSectionAService: selectedItems });
    }
    serviceSectionBChange(selectedItems) {
        this.setState({ savedSectionBService: selectedItems });
    }
    serviceSectionCChange(selectedItems) {
        this.setState({ savedSectionCService: selectedItems });
    }
    componentDidMount() {
        this.getRelevantData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(loading) {
        if (this._isMounted) this.setState({ loading });
    }
    onUploadSuccess(file) {
        this.setState({
            userGuideResourceId: file.id,
            userGuideFileName: file.filename,
        });
    }
    deleteDoc() {
        this.setState({
            userGuideResourceId: null,
            userGuideFileName: null,
        });
    }
    onUploadFail(error) { }
    getRelevantData() {
        this.showLoading(true);
        Promise.all([
            this.getCategories(),
            this.getProductCategories(),
            this.getActiveCriteriaSections(this.props.type),
            this.getSavedItem(),
            this.props.getCriteriaBySection(1),
            this.props.getCriteriaBySection(2),
            this.props.getCriteriaBySection(3),
            this.props.getCriteriaBySection(4),
            this.props.getCriteriaBySection(5),
            this.props.getCriteriaBySection(6),
        ])
            .then(res => {
                let productSectionACriterias = res[4].data;
                let productSectionBCriterias = res[5].data;
                let productSectionCCriterias = res[6].data;
                let serviceSectionACriterias = res[7].data;
                let serviceSectionBCriterias = res[8].data;
                let serviceSectionCCriterias = res[9].data;
                if (this._isMounted)
                    this.setState({
                        existingSectionA: productSectionACriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        existingSectionB: productSectionBCriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        existingSectionC: productSectionCCriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        existingSectionAService: serviceSectionACriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        existingSectionBService: serviceSectionBCriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        existingSectionCService: serviceSectionCCriterias.map(criteria => {
                            const label = (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: criteria.criteria,
                                    }}
                                />
                            );
                            return {
                                id: criteria.criteriaCode,
                                label,
                            };
                        }),
                        type: this.props.type,
                    });
                if (res[3]) {
                    const savedItem = res[3].data;
                    if (savedItem.type === 'product') {
                        // PRODUCT
                        var productTypesJson = savedItem.productTypesJson;
                        if (productTypesJson == null) productTypesJson = '';
                        var productTypesJsonArr = productTypesJson.split(',');
                        var selectedSubCategoryArr = [];
                        productTypesJsonArr.forEach(type => {
                            var existingSubCategory = res[1].data.find(item => item.productTypeId == type);
                            if (existingSubCategory != null) {
                                selectedSubCategoryArr.push({
                                    label: existingSubCategory.fullName,
                                    value: existingSubCategory.productTypeId,
                                });
                            }
                        });

                        if (this._isMounted) {
                            this.setState({
                                loading: false,
                                id: savedItem.id,
                                title: savedItem.title,
                                selectedSubCategory: selectedSubCategoryArr,
                                savedSectionA: savedItem.criteria.savedSectionA,
                                savedSectionB: savedItem.criteria.savedSectionB,
                                savedSectionC: savedItem.criteria.savedSectionC,
                                ratingRule: savedItem.criteria.ratingRule,
                                type: savedItem.type,
                                active: savedItem.active,
                                userGuideResourceId: savedItem.attachment,
                            });
                        }
                    } else {
                        // SERVICE
                        const existingCategory = res[0].data.find(item => item.id === savedItem.serviceCategoryId);
                        var selectedCategory = {};
                        if (existingCategory) {
                            selectedCategory = {
                                value: existingCategory.id,
                                label: existingCategory.category,
                            };
                        }
                        if (this._isMounted) {
                            this.setState({
                                loading: false,
                                id: savedItem.id,
                                title: savedItem.title,
                                selectedCategory,
                                savedSectionAService: savedItem.criteria.savedSectionAService,
                                savedSectionBService: savedItem.criteria.savedSectionBService,
                                savedSectionCService: savedItem.criteria.savedSectionCService,
                                savedSectionCServiceMinimumCriteria: savedItem.criteria.savedSectionCServiceMinimumCriteria,
                                type: savedItem.type,
                                active: savedItem.active,
                            });
                        }
                    }

                    // load attachment
                    this.setState({
                        userGuideResourceId: savedItem.attachment,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(error => {
                console.log('error, ', error);
            });
    }
    initialLoadGetSubCategories(selectedCategory, catId, currentData) {
        /**
         * this only happens for product as services do not have subcats?
         * props get passed here so setState only needs to happen once
         */

        if (catId == null) {
            catId = 0;
        }
        this.getSubCategories(catId).then(res => {
            const ssc = res.data.find(item => item.id === currentData.productTypeId) || null;
            this.setState({
                selectedCategory,
                selectedSubCategory: ssc && {
                    value: ssc.id,
                    label: generateSubCatLabel(ssc),
                },
                loading: false,
                title: currentData.title,
            });
        });
    }
    getSavedItem() {
        if (this.props.match.params.id) return this.props.getSavedItem(this.props.match.params.id);
        return Promise.resolve();
    }
    getActiveCriteriaSections(type) {
        return this.props.getActiveCriteriaSections(type).then(data => {
            if (this.state.modalSelectedSection === null) {
                this.getCriteriaBySection(data.data[0].id);
            }
            this.setState({
                modalSections: data.data,
                modalSelectedSection: data.data[0],
            });
            return data;
        });
    }
    getCategories() {
        return this.props.getCategories(this.props.type).then(data => {
            const cats = data.data.map(item => ({
                value: item.id,
                label: item.category,
            }));
            this.setState({
                categories: cats,
            });
            return data;
        });
    }
    getProductCategories() {
        return this.props.getProductCategories().then(data => {
            const cats = data.data.map(item => ({
                value: item.productTypeId,
                label: item.fullName,
            }));
            this.setState({
                productCategories: cats,
            });
            return data;
        });
    }
    getSubCategories(categoryid) {
        return this.props
            .getSubCategories(categoryid)
            .then(data => {
                const subcats = data.data.map(item => {
                    const st = generateSubCatLabel(item);
                    return {
                        value: item.id,
                        label: st,
                    };
                });
                this.setState({
                    subCategories: subcats,
                });
                return data;
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }
    // because code === title
    handleChangeCodeTitle(e) {
        this.setState({ title: e.target.value });
    }
    handleChangeSavedSectionCServiceMinimumCriteria(e) {
        this.setState({ savedSectionCServiceMinimumCriteria: e.target.value });
    }
    handleChangeCategory(e) {
        // dont need to do anything if same option is selected
        if (this.state.selectedCategory !== null && e.value === this.state.selectedCategory.value) return;
        const isService = this.props.type === 'service';
        if (!isService) {
            // only get if type === product
            this.getSubCategories(e.value);
        }

        const { currentData } = this.state;
        const catIdObj = isService ? { serviceCategoryId: e.value } : { productCategoryId: e.value };

        const newCurrentData = Object.assign({}, currentData, catIdObj);
        this.setState({
            currentData: newCurrentData,
            selectedCategory: e,
            selectedSubCategory: null,
        });
    }
    getCriteriaBySection(sectionid) {
        this.props.getCriteriaBySection(sectionid).then(res => {
            this.setState({
                modalCriteria: res.data ? res.data : [],
                modalSelectedCriteria: res.data ? res.data[0] : null,
            });
        });
    }
    handleChangeSection(e) {
        this.setState({
            modalSelectedSection: e,
            modalSelectedCriteria: null,
        });
        this.getCriteriaBySection(e.id);
    }
    handleChangeSubCategory(e) {
        this.setState({
            selectedSubCategory: e,
        });
    }
    clearModalState() {
        this.setState({
            modalSelectedSection: null,
            modalSelectedCriteria: null,
            modalCriteria: [],
            modalRequiredDocuments: '',
            modalDocumentsToSubmit: '',
        });
    }
    toggleModal(modalType, modalRatings, criteriaIndex) {
        if (this.state.modalShow === true) this.clearModalState();
        this.setState({
            modalRatingArray: modalRatings || [],
            modalShow: !this.state.modalShow,
            criteriaIndex,
        });
        // to prevent flash
        setTimeout(() => {
            this.setState({ modalType: modalType || 'criteria' });
        }, 400);
    }
    save(clone) {
        const { type } = this.props;
        var attachment = this.state.userGuideResourceId;
        var serviceCategoryId = null;
        if (type === 'service') {
            serviceCategoryId = this.state.selectedCategory.value;
        }

        var productTypes = [];
        if (this.state.selectedSubCategory && this.state.selectedSubCategory.length > 0) {
            this.state.selectedSubCategory.forEach(type => {
                productTypes.push(type.value);
            });
        }

        const postBody = {
            id: this.state.id,
            serviceCategoryId,
            attachment,
            productTypes,
            title: this.state.title,
            criteria: {
                savedSectionA: this.state.savedSectionA,
                savedSectionB: this.state.savedSectionB,
                savedSectionC: this.state.savedSectionC,
                savedSectionAService: this.state.savedSectionAService,
                savedSectionBService: this.state.savedSectionBService,
                savedSectionCService: this.state.savedSectionCService,
                ratingRule: this.state.ratingRule,
                savedSectionCServiceMinimumCriteria: this.state.savedSectionCServiceMinimumCriteria
            },
            type,
        };
        if (clone === true) {
            this.props.save(
                Object.assign({}, postBody, {
                    id: undefined,
                    active: undefined,
                    createdOn: undefined,
                    updatedById: undefined,
                    updatedOn: undefined,
                }),
                type,
            );
        } else if (this.state.id) {
            this.props.update(postBody, type);
        } else {
            this.props.save(postBody, type);
        }
    }
    toggleModalSave() {
        const { type } = this.props;
        if (type === 'service') {
            const numOfSelectedSection3 = this.state.savedSectionCService.length;
            const minimumSection3 = parseInt(this.state.savedSectionCServiceMinimumCriteria);
            if (minimumSection3 > numOfSelectedSection3) {
                this.props.dialogModalToggle();
                this.props.setModalData({ 
                    modalOnlyOneButton: true,
                    modalHeader: 'Warning',
                    modalBody: 'Minimum criteria should not be more than selected criterias in section 3',
                });
                return;
            }
        }
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.save(),
            modalBody: 'Are you sure you want to save this criteria?',
        });
    }
    toggleModalClone() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.save(true),
            modalBody: <DialogComponent title={this.state.title} onChangeTitle={this.handleChangeCodeTitle} />,
        });
    }
    toggleModalDelete() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.deleteItem(this.props.match.params.id, this.props.type),
            modalBody: 'Are you sure you want to delete this criteria?',
        });
    }
    toggleModalActivate() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.activate(this.props.match.params.id, this.props.type),
            modalBody: 'Are you sure you want to activate this criteria?',
        });
    }
    toggleModalInactivate() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.props.inactivate(this.props.match.params.id, this.props.type),
            modalBody: 'Are you sure you want to inactivate this criteria?',
        });
    }

    render() {
        const reactSelectProps = {
            disabled: this.state.loading,
            clearable: selectState.clearable,
            searchable: selectState.searchable,
            autosize: false,
            placeholder: '',
            autofocus: true,
            simpleValue: true,
        };

        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <DialogModal
                    forceModalAction={this.props.forceModalAction}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                    modalHeader={this.props.modalHeader}
                />
                <Row className="mb-4">
                    <Col xs={12}>
                        <Link to="/staff/certification/assessment/scoresheet-criteria/">
                            <Button className="primary-btn-style-outline">
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                &nbsp; Back
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Form>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <label>Code</label>
                                <Input type="text" value={this.state.title || ''} onChange={this.handleChangeCodeTitle} />
                            </FormGroup>
                            <br />
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <label>Category</label>
                                {this.props.type === 'service' && (
                                    <Select
                                        onChange={this.handleChangeCategory}
                                        options={this.state.categories}
                                        value={this.state.selectedCategory}
                                        {...reactSelectProps}
                                    />
                                )}
                                {this.props.type === 'product' && (
                                    <Select
                                        isMulti={true}
                                        onChange={this.handleChangeSubCategory}
                                        options={this.state.productCategories}
                                        value={this.state.selectedSubCategory}
                                        {...reactSelectProps}
                                    />
                                )}
                            </FormGroup>
                            <br />
                            {this.state.id != null && (
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <label>Status</label>
                                    <Input disabled type="text" value={this.state.active === 1 ? `Active` : `Inactive`} />
                                </FormGroup>
                            )}
                            <br />
                        </Col>
                    </Row>
                    <hr />
                    {this.props.type === 'service' && (
                        <React.Fragment>
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <label>
                                        <b>Section 1 Criteria</b>
                                    </label>
                                    {this.props.getCriteriaInfoLoading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <CustomMultiSelect
                                            items={this.state.existingSectionAService}
                                            selectedItems={this.state.savedSectionAService}
                                            onChange={this.serviceSectionAChange}
                                            groupName="section1"
                                        />
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <label>
                                        <b>Section 2 Criteria</b>
                                    </label>
                                    {this.props.getCriteriaInfoLoading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <CustomMultiSelect
                                            items={this.state.existingSectionBService}
                                            selectedItems={this.state.savedSectionBService}
                                            onChange={this.serviceSectionBChange}
                                            groupName="section2"
                                        />
                                    )}
                                </Col>
                            </Row>
                            <br />
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <label>
                                        <b>Section 3 Criteria</b>
                                    </label>
                                    {this.props.getCriteriaInfoLoading ? (
                                        <LoadingSpinner />
                                    ) : (
                                        <CustomMultiSelect
                                            items={this.state.existingSectionCService}
                                            selectedItems={this.state.savedSectionCService}
                                            onChange={this.serviceSectionCChange}
                                            groupName="section3"
                                        />
                                    )}
                                </Col>

                                <Col xs={12}>
                                    <br />
                                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                        <label>Minimum Criteria</label>
                                        <Input style={{
                                            maxlength: 10,
                                            width: 100
                                        }} type="number" value={this.state.savedSectionCServiceMinimumCriteria || ''} onChange={this.handleChangeSavedSectionCServiceMinimumCriteria} />
                                    </FormGroup>
                                </Col>
                                <br />
                            </Row>
                        </React.Fragment>
                    )}
                    {this.props.type === 'product' && (
                        <Row className="mb-2">
                            <Col xs={12}>
                                <label>
                                    <b>Section A Criteria</b>
                                </label>
                                {this.props.getCriteriaInfoLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <CustomMultiSelect
                                        items={this.state.existingSectionA}
                                        selectedItems={this.state.savedSectionA}
                                        onChange={this.productSectionAChange}
                                        groupName="sectionA"
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                    <br />
                    {this.props.type === 'product' && (
                        <Row className="mb-2">
                            <Col xs={12}>
                                <label>
                                    <b>Section B Criteria</b>
                                </label>
                                {this.props.getCriteriaInfoLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <CustomMultiSelect
                                        items={this.state.existingSectionB}
                                        selectedItems={this.state.savedSectionB}
                                        onChange={this.productSectionBChange}
                                        groupName="sectionB"
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                    <br />
                    {this.props.type === 'product' && (
                        <Row className="mb-2">
                            <Col xs={12}>
                                <label>
                                    <b>Section C Criteria</b>
                                </label>
                                {this.props.getCriteriaInfoLoading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <CustomMultiSelect
                                        items={this.state.existingSectionC}
                                        selectedItems={this.state.savedSectionC}
                                        onChange={this.productSectionCChange}
                                        groupName="sectionC"
                                    />
                                )}
                            </Col>
                        </Row>
                    )}

                    {this.props.type === 'product' && (
                        <React.Fragment>
                            <hr />
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <label>
                                        <b>Minimum Supplementary Requirement</b>
                                    </label>
                                    <FootNotesComponent
                                        ratingRule={this.state.ratingRule}
                                        onFieldChange={this.onFootNotesFieldChange}
                                        sectionC={this.state.savedSectionC}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}

                    <React.Fragment>
                        <hr />
                        <Row className="mb-2">
                            <Col xs={12}>
                                <label>
                                    <b>Attachment</b>
                                </label>
                                <CyderFileUpload
                                    attachmentId={this.state.userGuideResourceId}
                                    filename={this.state.userGuideFileName}
                                    onUploadSuccess={this.onUploadSuccess}
                                    onUploadFail={this.onUploadFail}
                                />
                                {this.state.userGuideResourceId != null && (
                                    <Button className="primary-btn-style" onClick={this.deleteDoc}>
                                        <i className="mr-1 material-icons align-text-bottom ">delete</i>
                                        Delete Attachment
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                </Form>
                <hr />
                <Row style={{ marginBottom: '20px' }}>
                    <Col xs={12} className="d-flex">
                        <Button className="primary-btn-style" onClick={this.toggleModalSave}>
                            <i className="mr-1 material-icons align-text-bottom ">save</i>
                            Save
                        </Button>
                        {this.props.match.params.id && (
                            <React.Fragment>
                                <Button className="ml-1 primary-btn-style" onClick={this.preview}>
                                    <i className="mr-1 material-icons align-text-bottom ">pageview</i>
                                    Preview
                                </Button>
                                <Button className="ml-1 primary-btn-style" onClick={this.toggleModalClone}>
                                    <i className="mr-1 material-icons align-text-bottom ">content_copy</i>
                                    Copy
                                </Button>
                                <div className="ml-auto">
                                    {this.state.active == 1 && (
                                        <Button
                                            style={{ marginRight: '5px' }}
                                            className="text-bold primary-btn-style"
                                            onClick={this.toggleModalInactivate}
                                        >
                                            <i className="mr-1 material-icons align-text-bottom ">alarm_off</i>
                                            Inactivate
                                        </Button>
                                    )}
                                    {this.state.active == 0 && (
                                        <Button
                                            style={{ marginRight: '5px' }}
                                            className="text-bold primary-btn-style"
                                            onClick={this.toggleModalActivate}
                                        >
                                            <i className="mr-1 material-icons align-text-bottom ">alarm_on</i>
                                            Activate
                                        </Button>
                                    )}
                                    <Button className="text-bold primary-btn-style-outline" onClick={this.toggleModalDelete}>
                                        <i className="mr-1 material-icons align-text-bottom ">delete</i>
                                        Delete
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const DialogComponent = ({ onChangeTitle, title }) => {
    return (
        <div>
            <span>Please enter the code for this criteria and click OK to confirm.</span>
            <Input type="text" defaultValue={title} onChange={onChangeTitle} />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffCertificationSTemplateReducer,
        ownProps,
        getCriteriaInfoLoading: state.staffCertificationAssessmentCriteriaAddReducer.loading,
    };
};

const mapDispatchToProps = dispatch => ({
    getSubCategories: categoryid => dispatch(getSubCategories(categoryid)),
    getActiveCriteriaSections: type => dispatch(getActiveCriteriaSections(type)),
    getSavedItem: id => dispatch(getSavedItem(id)),
    save: (body, type) => dispatch(save(body, type)),
    update: (body, type) => dispatch(update(body, type)),
    getCategories: type => dispatch(getCategories(type)),
    getProductCategories: type => dispatch(getProductCategories(type)),
    dialogModalToggle: () => dispatch(dialogModalToggle()),
    getCriteriaDetails: id => dispatch(getCriteriaDetails(id)),
    deleteItem: (id, type) => dispatch(deleteItem(id, type)),
    inactivate: (id, type) => dispatch(inactivate(id, type)),
    activate: (id, type) => dispatch(activate(id, type)),
    setModalData: data => dispatch(setModalData(data)),
    getCriteriaBySection: section => {
        return dispatch(getCriteriaBySection(section));
    },
    getCriteriaByType: type => {
        return dispatch(getCriteriaByType(type));
    },
});

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(TemplateEditPage),
);
