import { instance, instanceNonCors, instanceCors } from '../../config';
import * as Types from '../../actions/constants/public';
import Axios from 'axios';
import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_MODE } from 'config.js';

export const loadingPublic = () => ({ type: Types.LOADING_PUBLIC });
export const unloadingPublic = () => ({ type: Types.UN_LOADING_PUBLIC });

export const loadingPublicProductCategory = () => ({ type: Types.LOADING_PUBLIC_PRODUCT_CATEGORY });
export const fetchProductCategory = () => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-category?action=get-all-active`, { test: 1, exclude: 'Others' })
        .then(response => {
            dispatch({ type: Types.FETCH_PRODUCT_CATEGORY, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const loadingPublicProductSubcategory = () => ({ type: Types.LOADING_PUBLIC_PRODUCT_SUB_CATEGORY });
export const fetchProductSubcategory = () => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-category?action=get-all-active-processed&categoryId=all`, '')
        .then(response => dispatch({ type: Types.FETCH_PRODUCT_SUBCATEGORY, payload: response.data.data }))
        .catch(err => {
            throw err;
        });
};

export const loadingSingleSubCategory = () => ({ type: Types.LOADING_SINGLE_SUBCATEGORY });
export const fetchSingleSubcategory = categoryId => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-category?action=get-all-active-processed&categoryId=${categoryId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_SINGLE_SUBCATEGORY, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const loadingLowVocProductTypes = () => ({ type: Types.LOADING_LOWVOC_PRODUCT_TYPES });
export const fetchLowVocProductTypes = () => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-subcategory?action=get-all-active-processed&subCategoryId=lowvoc`, '')
        .then(response => dispatch({ type: Types.FETCH_LOWVOC_PRODUCT_TYPES, payload: response.data.data }))
        .catch(err => {
            throw err;
        });
};

export const loadingAllProductTypes = () => ({ type: Types.LOADING_ALL_PRODUCT_TYPES });
export const fetchAllProductTypes = () => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-subcategory?action=get-all-active-processed&subCategoryId=all`, '')
        .then(response => dispatch({ type: Types.FETCH_ALL_PRODUCT_TYPES, payload: response.data.data }))
        .catch(err => {
            throw err;
        });
};

export const loadingSpecificProductTypes = () => ({ type: Types.LOADING_SPECIFIC_PRODUCT_TYPES });
export const fetchSpecificProductTypes = subCategoryId => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product-subcategory?action=get-all-active-processed&subCategoryId=${subCategoryId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_SPECIFIC_PRODUCT_TYPES, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

const fetchBCAFunctionalSystem = () => instance.get(`/certificates_v1/cn/bca-data-option?action=get-all-active`);

const fetchGreenAttributes = () => instance.get(`/certificates_v1/cn/other-data-option?action=get-all-active`);

const fetchBuildingProjectTypes = () => instance.get(`/certificates_v1/cn/building-type-option?action=get-all-active`);

export const fetchAllFilterOptions = () => dispatch => {
    dispatch(loadingPublic());
    Axios.all([fetchBCAFunctionalSystem(), fetchGreenAttributes(), fetchBuildingProjectTypes()]).then(
        Axios.spread((bca, green, buildingTypes) => {
            dispatch({
                type: Types.FETCH_ALL_FILTER_OPTIONS,
                payload: {
                    bca: bca.data.data,
                    green: green.data.data,
                    buildingTypes: buildingTypes.data.data,
                },
            });
        }),
    );
};

export const fetchProductTaggings = certificateId => dispatch => {
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product?action=product_directory_details_tagging&certificateId=${certificateId}`, '')
        .then(response => {
            dispatch(loadingPublic());
            dispatch({ type: Types.FETCH_PRODUCT_TAGGINGS, payload: response.data.data });
        })
        .catch(err => console.error(err.response));
};

export const fetchProducts = data => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/product_v1/cn/product?action=product_directory_filter', data)
        .then(response => {
            dispatch({ type: Types.FETCH_PRODUCTS, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchAllProducts = data => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/product_v1/cn/product?action=product_directory_filter', data)
        .then(response => {
            dispatch({ type: Types.FETCH_ALL_PRODUCTS, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const searchProducts = data => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/product_v1/cn/product?action=product_directory_search', data)
        .then(response => {
            dispatch({ type: Types.SEARCH_PRODUCTS, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchCompanyInfo = companyId => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product?action=product_directory_company&companyId=${companyId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_COMPANY_INFO, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchCertificateInfo = (certificateId, companyId) => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product?action=product_directory_details&certificateId=${certificateId}&companyId=${companyId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_CERTIFICATE_INFO, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchCertificateInfoTagging = certificateId => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/product_v1/cn/product?action=product_directory_details_tagging&certificateId=${certificateId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_CERTIFICATE_INFO_TAGGING, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchRatingInSubcategory = subCategoryId => dispatch => {
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/assessment_v1/cn/assessment-criteria?action=get-all-active-rating&isProduct=1&productSubCategoryId=${subCategoryId}`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_RATING_IN_SUBCATEGORY, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

const fetchProductTypeLabels = subCategoryId =>
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors).post(
        `/product_v1/cn/product-subcategory?action=get-all-active-processed&subCategoryId=${subCategoryId}`,
        {},
    );
const fetchRatings = () =>
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors).post(
        `/assessment_v1/cn/assessment-criteria?action=get-all-active-rating&isProduct=1`,
        {},
    );
const fetchRatingsServices = () =>
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors).post(
        `/assessment_v1/cn/assessment-criteria?action=get-all-service-active-rating&isProduct=1`,
        {},
    );

export const fetchFilteringLabels = subCategoryId => dispatch => {
    dispatch(loadingPublic());
    return Axios.all([fetchProductTypeLabels(subCategoryId), fetchRatings()]).then(
        Axios.spread((productTypes, ratings) => {
            dispatch({
                type: Types.FETCH_FILTERING_LABELS,
                payload: {
                    productTypes: productTypes.data.data,
                    ratings: ratings.data.data,
                },
            });
        }),
    );
};

export const fetchRating = () => dispatch => {
    dispatch(loadingPublic());
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(`/assessment_v1/cn/assessment-criteria?action=get-all-active-rating&isProduct=1`, '')
        .then(response => {
            dispatch({ type: Types.FETCH_RATING, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};
export const fetchRatingService = categoryId => dispatch => {
    dispatch(loadingPublic());
    var url = `/assessment_v1/cn/assessment-criteria?action=get-all-service-active-rating&serviceCategoryId=` + categoryId;
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post(url, '')
        .then(response => {
            dispatch({ type: Types.FETCH_RATING, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};
// Services
export const fetchServiceCategories = () => dispatch => {
    dispatch(loadingPublic());
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/service_v1/cn/category?action=get-all-active', '')
        .then(response => {
            dispatch({ type: Types.FETCH_SERVICE_CATEGORIES, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const filterServices = data => dispatch => {
    dispatch(loadingPublic());
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/service_v1/cn/service?action=service_directory_filter', data)
        .then(response => {
            dispatch({ type: Types.FILTER_SERVICES, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const searchServices = data => dispatch => {
    dispatch(loadingPublic());
    return (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/service_v1/cn/service?action=service_directory_search', data)
        .then(response => {
            dispatch({ type: Types.SEARCH_SERVICES, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchServiceCompanyInfo = data => dispatch => {
    dispatch(loadingPublic());
    (SGBC_MODE == 'local' ? instanceNonCors : instanceCors)
        .post('/service_v1/cn/service?action=service_directory_details', data)
        .then(response => {
            dispatch({ type: Types.FETCH_SERVICE_COMPANY_INFO, payload: response.data.data });
        })
        .catch(err => {
            throw err;
        });
};

export function validateEncryption(body) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/auth`, {
            action: 'validateEncryption',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function changeEncryptionPassword(body) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/auth`, {
            action: 'changeEncryptionPassword',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
