import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, InputGroup, CustomInput, Input, Row, Col, Button, Tooltip } from 'reactstrap';
import Truncate from 'react-truncate';
import List from 'react-virtualized/dist/commonjs/List';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default class CustomMultiSelect extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        selectedItems: PropTypes.array.isRequired,
        height: PropTypes.number,
        groupName: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this._isMounted = true;

        this.state = {
            selectedCount: 0,
            searchValue: '',
            originalItems: [],
            selectedItems: [],
            loading: false,
        };
    }

    componentDidMount() {
        let { items, selectedItems } = this.props;
        const originalItems = this.setCheckedOnOriginalItems(items, selectedItems);
        if (this._isMounted) {
            this.setState({
                originalItems,
                selectedItems,
                selectedCount: selectedItems.length,
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    // UTIL
    setCheckedOnOriginalItems = (originalItems, selectedItems) => {
        // SET ITEMS CHECKED PROPERTY BASED ON GIVEN SELECTEDITEMS
        const newItems = originalItems.map(item => {
            item.checked = selectedItems.find(selected => selected.id === item.id) ? true : false;
            return item;
        });
        return newItems;
    };
    setItemSelected = itemsSelected => {
        const { originalItems } = this.state;
        this.setCheckedOnOriginalItems(originalItems, itemsSelected);
        itemsSelected = this.clearChecked(itemsSelected);
        this.props.onChange(itemsSelected);
        this.setState({
            selectedItems: itemsSelected,
            selectedCount: itemsSelected.length,
        });
    };
    clearChecked = itemsList => {
        return itemsList.map(item => {
            const { id, label } = item;
            const labelString = typeof label === 'object' ? label.props.dangerouslySetInnerHTML.__html : label;
            return {
                id,
                label: labelString,
            };
        });
    };
    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    // EVENT HANDLER
    removeItemsHandler = id => {
        const { selectedItems } = this.state;
        const itemsSelected = selectedItems
            .map(item => {
                if (item.id !== id) return item;
            })
            .filter(x => x);
        this.setItemSelected(itemsSelected);
    };
    handleCheckboxChange = (e, id) => {
        const { checked } = e.target;
        const itemsSelected = checked ? this.addSelectedItem(id) : this.removeSelectedItem(id);
        this.setItemSelected(itemsSelected);
    };
    addSelectedItem = id => {
        let { originalItems, selectedItems } = this.state;
        const item = originalItems.find(item => item.id === id);
        selectedItems.push({
            id: item.id,
            label: item.label.props.dangerouslySetInnerHTML.__html,
        });
        return selectedItems;
    };
    removeSelectedItem = id => {
        const { selectedItems } = this.state;
        const itemsSelected = selectedItems.map(item => (item.id !== id ? item : null)).filter(x => x);
        return itemsSelected;
    };
    onSearchInputChange = e => {
        const { value } = e.target;
        this.setState({
            searchValue: value,
        });
        // Set back to original items list
        if (value.length === 0) {
            const { items } = this.props;
            this.setState({
                originalItems: items,
            });
        }
    };
    onSearchButtonClicked = () => {
        const { searchValue, originalItems } = this.state;
        let returnItems;
        if (searchValue.length === 0) {
            returnItems = originalItems;
        } else {
            // Search result
            returnItems = originalItems
                .map(item => {
                    const { id } = item;
                    return id.toLowerCase().includes(searchValue.toLowerCase()) && item;
                })
                .filter(x => x);
        }
        this.setState({
            originalItems: returnItems,
        });
    };
    onSelectAllClicked = () => {
        const { originalItems } = this.state;
        const selectedItems = this.setCheckedOnOriginalItems(originalItems, originalItems);
        this.setItemSelected(selectedItems);
    };
    onClearClicked = () => {
        const { originalItems } = this.state;
        this.setCheckedOnOriginalItems(originalItems, []);
        this.props.onChange([]);
        this.setState({
            selectedItems: [],
            selectedCount: 0,
        });
    };
    onDragEnd = result => {
        // If drop outsite of droppable
        if (!result.destination) {
            return;
        }
        // Reorder list
        const items = this.reorder(this.state.selectedItems, result.source.index, result.destination.index);
        // Set state
        this.props.onChange(items);
        this.setState({
            selectedItems: items,
        });
    };
    render() {
        const { height = 300, groupName, truncateText } = this.props;
        const { originalItems, selectedItems, selectedCount, searchValue } = this.state;
        return (
            <div className="border">
                {/* Header */}
                <HeaderPanel
                    searchValue={searchValue}
                    selectedCount={selectedCount}
                    onClearClicked={this.onClearClicked}
                    onSelectAllClicked={this.onSelectAllClicked}
                    onSearchInputChange={this.onSearchInputChange}
                    onSearchButtonClicked={this.onSearchButtonClicked}
                />
                {/* Body */}
                <Row xs="12" className="m-0">
                    <ListWrapper height={height}>
                        <SourceList
                            items={originalItems}
                            height={height}
                            onChange={this.handleCheckboxChange}
                            groupName={groupName}
                            truncateText={truncateText}
                        />
                    </ListWrapper>
                    <ListWrapper height={height}>
                        <DestinationList
                            items={selectedItems}
                            height={height}
                            onDragEnd={this.onDragEnd}
                            removeItemsHandler={this.removeItemsHandler}
                            truncateText={truncateText}
                        />
                    </ListWrapper>
                </Row>
            </div>
        );
    }
}

const HeaderPanel = ({ onClearClicked, onSelectAllClicked, onSearchInputChange, onSearchButtonClicked, selectedCount, searchValue }) => (
    <Row xs="12" className="m-0">
        <Col className="border pt-4 pb-4 m-0">
            <InputGroup>
                <Input
                    type="text"
                    placeholder="Search"
                    className="border-0 mr-1"
                    style={{ zIndex: 0 }}
                    value={searchValue}
                    onChange={onSearchInputChange}
                    onKeyDown={e => {
                        if (e.keyCode == 13) onSearchButtonClicked();
                    }}
                />
                <Button className="primary-btn-style mr-1" onClick={onSearchButtonClicked}>
                    <i className="fas fa-search" />
                </Button>
                <Button className="primary-btn-style mr-1" onClick={onSelectAllClicked}>
                    Select All
                </Button>
            </InputGroup>
        </Col>
        <Col xs="6" className="border pt-4 pb-4 m-0">
            <InputGroup className="d-flex align-items-center">
                <Col className="text-left">{selectedCount} selected</Col>
                <Col className="text-right">
                    <Button className="primary-btn-style" onClick={onClearClicked}>
                        Clear
                    </Button>
                </Col>
            </InputGroup>
        </Col>
    </Row>
);

export class TooltipComponent extends Component {
    state = {
        isTooltipOpen: false,
    };
    onTooltipOpen = e => {
        const { isTooltipOpen } = this.state;
        this.setState({
            isTooltipOpen: e && !isTooltipOpen,
        });
    };
    render() {
        const { isTooltipOpen } = this.state;
        const { id, label, children } = this.props;
        return (
            <div>
                {children}
                <Tooltip
                    innerClassName="tooltip-wrapper"
                    placement="auto"
                    boundariesElement={'window'}
                    autohide={false}
                    target={id}
                    isOpen={isTooltipOpen}
                    toggle={e => this.onTooltipOpen(e)}
                >
                    <div
                        style={{ width: '500px' }}
                        dangerouslySetInnerHTML={{
                            __html: label,
                        }}
                    />
                </Tooltip>
            </div>
        );
    }
}

const ListWrapper = ({ height, children }) => (
    <Col
        xs="12"
        sm="6"
        className="border p-0"
        style={{
            minHeight: `${height}px`,
        }}
    >
        {children}
    </Col>
);

const SourceList = ({ items, onChange, height, groupName, truncateText }) => {
    const rowRenderer = ({ style, key, index }) => {
        const { id, label, checked } = items[index];
        // TOOLTIP COMPONENT USES UNIQUE IDENTIFIER TO DETERMINE WHAT TOOLTIP TO SHOW
        const identifier = groupName + index;
        const innerHTML = label.props.dangerouslySetInnerHTML.__html;
        return (
            <div style={style} key={key} className="p-2">
                <FormGroup className="pt-1 d-flex" check>
                    <CustomInput type="checkbox" id={id} checked={checked} onChange={e => onChange(e, id)} />
                    <TooltipComponent id={identifier} label={innerHTML}>
                        <div id={identifier}>{truncateText ? <Truncate lines={1} children={id} ellipsis="…" /> : <p>{id}</p>}</div>
                    </TooltipComponent>
                </FormGroup>
            </div>
        );
    };
    return (
        <AutoSizer disableHeight>
            {({ width }) => {
                return (
                    <List
                        rowRenderer={rowRenderer}
                        rowHeight={40}
                        height={height}
                        width={width}
                        rowCount={items.length}
                        style={{ outline: 'none' }}
                    />
                );
            }}
        </AutoSizer>
    );
};

const DestinationList = ({ items, removeItemsHandler, height, onDragEnd, truncateText }) => {
    const RowRenderer = ({ index }) => {
        const { id } = items[index];
        return (
            <Draggable draggableId={id} index={index} key={id}>
                {provided => {
                    const { innerRef, dragHandleProps, draggableProps } = provided;
                    return (
                        <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                            <Row xs="12" className="p-2 m-0">
                                <Col xs="11">
                                    <div style={{ width: '100%' }}>
                                        {truncateText ? <Truncate lines={1} children={id} ellipsis="…" /> : <p>{id}</p>}
                                    </div>
                                </Col>
                                <Col xs="1">
                                    <Button close className="float-right" onClick={() => removeItemsHandler(id)} />
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Draggable>
        );
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                    const { innerRef } = provided;
                    return (
                        <div ref={innerRef} style={{ height, overflow: 'auto' }}>
                            {items.map((item, index) => (
                                <RowRenderer key={index} index={index} />
                            ))}
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </DragDropContext>
    );
};
