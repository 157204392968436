import {
    STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE,
    STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST,
    STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS,
    STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';

const defaultState = {
    tableData: {
        loading: false,
        memberList: [],
        tablePages: -1,
        totalItems: 0,
    },
    subscriberData: {
        loading: false,
        memberList: [],
        tablePages: -1,
        totalItems: 0,
    },
};
export function staffCompanyDetailsTeamManagementReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST:
            newState.tableData.loading = true;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS:
            newState.tableData = Object.assign({}, state.tableData);
            newState.tableData.tablePages = action.response.totalNoOfPages;
            newState.tableData.totalItems = action.response.totalNoOfItems;
            newState.tableData.memberList = action.response.data;
            newState.tableData.loading = false;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE:
            newState.tableData.loading = false;
            return newState;
        case STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST:
            newState.subscriberData.loading = true;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS:
            newState.subscriberData = Object.assign({}, state.subscriberData);
            newState.subscriberData.tablePages = action.response.totalNoOfPages;
            newState.subscriberData.totalItems = action.response.totalNoOfItems;
            newState.subscriberData.memberList = action.response.data;
            newState.subscriberData.loading = false;
            return newState;
        case STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE:
            newState.subscriberData.loading = false;
            return newState;
        default:
            return newState;
    }
}
