import React from 'react';
import '../../css/cyder/panel/standard-4080-panel.css';

const Cyder4080Panel = ({ title, description, children, className }) => (
    <div className={'standard-4080-panel' + (className ? ' ' + className : '')}>
        <div className="row m-b-20">
            <div className="col-4 col-md-4 col-lg-4">{children}</div>
            <div className="col-8 col-md-8 col-lg-8">
                <h3>{title}</h3>
                <div>
                    <div className="description">{description}</div>
                </div>
            </div>
        </div>
    </div>
);

export default Cyder4080Panel;
