import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Button, FormGroup, Label, Input } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputField, { ButtonComponent } from 'pages/admin/companies/shared/InputField';

import { updateUenName, load } from 'actions/admin/companies/companyAction.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.registrationNumberChange = this.registrationNumberChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.save = this.save.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            data: {
                registrationNumber: '',
                name: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/companies/company');
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = this.props.match.params.id;
        this.props
            .load(id)
            .then(res => {
                this.showLoading(false);
                if (res.error || res.data == null || res.data.id == null) {
                    this.showModal('Error', res.message, 'load', true, true);
                } else {
                    this.setState({
                        data: res.data,
                    });
                }
            })
            .catch(error => {
                this.showLoading(false);
                this.showModal('Error', error.message, 'load', true, true);
                throw error;
            });
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        this.props
            .update(data)
            .then(res => {
                this.showLoading(false);
                if (res.error) {
                    this.showModal('Error', res.message);
                } else {
                    this.showModal('Confirmation', 'Company updated successsfully', 'updateSuccessful', false, true);
                    // history.replace('/admin/companies/company', 'update', true, true);
                }
            })
            .catch(error => {
                this.showLoading(false);
                this.showModal('Error', error.message, 'update', true, true);
                throw error;
            });
    }
    registrationNumberChange(e) {
        var data = this.state.data;
        data.registrationNumber = e.target.value;
        this.setState({ data });
    }
    nameChange(e) {
        var data = this.state.data;
        data.name = e.target.value;
        this.setState({ data });
    }
    modalAction() {
        if (this.state.modal.type === 'updateSuccessful') {
            this.modalToggle();
        }
    }
    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <InputField
                    label="Name"
                    instruction="Requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        id: 'name',
                        onChange: this.nameChange,
                        value: this.state.data.name,
                        style: { width: '300px' },
                    }}
                />
                <InputField
                    label="UEN"
                    instruction="Requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        id: 'registrationNumber',
                        value: this.state.data.registrationNumber,
                        onChange: this.registrationNumberChange,
                        style: { width: '100px' },
                    }}
                />

                <hr />
                <FlexRow between="xs">
                    <FlexCol xs={2}>
                        <Button className="primary-btn-style" onClick={this.save}>
                            Save
                        </Button>
                    </FlexCol>
                    <FlexCol xs={2} className="d-flex justify-content-end">
                        <Link to={'/staff/company/' + this.state.data.id}>
                            <Button className="primary-btn-style-outline">View Company Details</Button>
                        </Link>
                        <AuditListPopup contentTypeId={13} objectId={this.state.data.id} />
                    </FlexCol>
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        update: data => {
            return dispatch(updateUenName(data));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
