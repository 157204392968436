import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from '../,,/../../layouts/StoredLayout';
import moment from 'moment';

import { Container, FormGroup, Label, Input, Row, Col, Form, Button, Badge, Nav, NavItem, NavLink, CardText } from 'reactstrap';
import Select from 'react-select';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import {
    memberMembershipMemberGetAllMemberDirectory,
    memberMembershipMemberGetMemberDirectory,
} from '../../actions/member/directory/memberDirectoryAction';

import { searchKeyword, defaultKeywordSearchKeys, searchKeywordByAlphabeticalLetter } from '../../js/keywordSearch';

/**
 * REQUIRE HOOK UP
 * This component has built in search and filter feature, except 'filter by organisation category'
 */

const FilterNavComponentWrapper = props => {
    return (
        <Col xs={12}>
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Nav tabs>{props.children}</Nav>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};
const FilterNavTitle = props => {
    return (
        <CardText className="p-2">
            <strong>{props.label + ': '}</strong>
            <br />
        </CardText>
    );
};
const SimplyReactTable = props => {
    return (
        <ReactTable
            manual
            className="-highlight mb-2"
            data={props.data}
            columns={props.columns}
            minRows={0}
            filterable={false}
            pageSize={20}
            pages={props.pages}
            loading={props.loading}
            onFetchData={props.onFetchData}
        />
    );
};
class StaffMembershipMembersPage extends React.Component {
    constructor(props) {
        super(props);
        this.getFilterSearchResult = this.getFilterSearchResult.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            activeTab: {
                type: 'All',
                alphabetical: 'All',
            },
            loading: false,
            ...defaultKeywordSearchKeys,
        };
    }
    componentDidMount() {
        this.props.getAllDirectory();
    }
    handleInputChange(event) {
        this.setState({
            searchResult: [],
            keyword: event.target.value,
        });
    }
    getFilterSearchResult(searchBy) {
        let searchResult = this.props.allDirectory;
        // Search is arranged by priority
        for (let i = 0; i < searchBy.length; i++) {
            // Search by keyword
            if (i === 0 && searchBy[0] !== '') {
                searchResult = searchKeyword(searchResult, this.state.keyword);
            }
            // Search by type
            if (i === 1 && searchBy[1] !== 'All') {
                searchResult = searchKeyword(searchResult, searchBy[i]);
            }
            // search by alphabet
            if (i === 2 && searchBy[2] !== 'All') {
                searchResult = searchKeywordByAlphabeticalLetter(searchResult, searchBy[i], 'name');
            }
        }
        this.setState({
            searchResult,
            loading: false,
        });
    }
    getAlphebeticalNavObject() {
        let alphabets = ['All'];
        let i = 'A'.charCodeAt(0);
        let j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            alphabets.push(String.fromCharCode(i));
        }
        return alphabets.map(letter => {
            return {
                key: letter.toLowerCase(),
                text: letter,
            };
        });
    }
    toggleTab(tab, id) {
        let activeTab = this.state.activeTab;
        activeTab[id] = tab;
        this.setState({
            activeTab,
            loading: true,
            searchResult: [],
        });
        return Promise.resolve();
    }
    renderNav(navItems, id) {
        return navItems.map(item => {
            return (
                <NavItem key={item.key}>
                    <NavLink
                        className={this.state.activeTab[id] === item.text ? 'active p-2' : 'p-2'}
                        onClick={() => {
                            this.toggleTab(item.text, id).then(() =>
                                this.getFilterSearchResult([
                                    this.props.keyword,
                                    this.state.activeTab.type,
                                    this.state.activeTab.alphabetical,
                                ]),
                            );
                        }}
                    >
                        {item.text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        const yesNoUnknownIcons = val => {
            switch (val ? new Boolean(val).valueOf() : null) {
                case null:
                    return <i className="fa fa-icon text-muted fa-question-circle" />;
                case true:
                    return <i className="material-icons align-text-bottom text-success">check_circle</i>;
                case false:
                    return <i className="material-icons align-text-bottom text-danger">remove_circle</i>;
            }
        };
        var typeNavs = [
            {
                key: 'all',
                text: 'All',
            },
            {
                key: 'foundingMembers',
                text: 'Founding Member',
            },
            {
                key: 'corporateMembers',
                text: 'Corporate Member',
            },
        ];
        const columns = [
            {
                Header: 'ID',
                accessor: 'id',
                minWidth: 50,
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 300,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <Link to={'/staff/company/' + props.row.companyId}>{props.value}</Link>;
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
            },
            {
                Header: 'UEN',
                accessor: 'UEN',
            },
            {
                Header: 'Org. Category',
                accessor: 'orgCategory',
                style: { whiteSpace: 'unset' },
                minWidth: 200,
            },
            {
                Header: 'SME',
                maxWidth: 40,
                accessor: 'isSME',
                Cell: props => {
                    return yesNoUnknownIcons(props.value);
                },
            },
            {
                Header: 'Membership Type',
                accessor: 'membershipType',
                minWidth: 125,
                Cell: props => {
                    return <Badge>{props.value}</Badge>;
                },
            },
            {
                Header: 'Join Date',
                accessor: 'dateJoined',
                Cell: props => {
                    return moment(props.value).format('MMM D, YYYY');
                },
            },
            {
                Header: 'Expiry Date',
                accessor: 'expiryDate',
                Cell: props => {
                    return moment(props.value).format('MMM D, YYYY');
                },
            },
            {
                Header: '',
                accessor: 'companyId',
                sortable: false,
                Cell: props => {
                    return (
                        <Link className="btn btn-info btn-sm" to={'/staff/company/' + props.value}>
                            <i className="material-icons align-text-bottom">add</i>
                            <br />
                            More
                        </Link>
                    );
                },
            },
        ];
        const alphaticallNavs = this.getAlphebeticalNavObject();
        return (
            <div className="page-widget">
                <Row className="mb-2 p-4">
                    <Col xs={6}>
                        <Form inline className="float-left">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    value={this.state.keyword}
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                            <Button
                                onClick={() =>
                                    this.getFilterSearchResult([
                                        this.state.keyword,
                                        this.state.activeTab.type,
                                        this.state.activeTab.alphabetical,
                                    ])
                                }
                            >
                                Search
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={6}>
                        <div className="float-right">
                            <Button
                                className="mb-1"
                                style={{ width: '360px' }}
                                color="success"
                                onClick={() => this.props.getMemberDirectory('All')}
                            >
                                View All Memberships including Expired ones
                            </Button>
                            <br />
                            <Button style={{ width: '360px' }} color="success" onClick={() => this.props.getMemberDirectory('GracePeriod')}>
                                View All 6 Month Grace Period Memberships
                            </Button>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <FilterNavComponentWrapper>
                            <FilterNavTitle label="Type" />
                            {this.renderNav(typeNavs, 'type')}
                        </FilterNavComponentWrapper>
                        <FilterNavComponentWrapper>
                            <FilterNavTitle label="Alphabetical" />
                            {this.renderNav(alphaticallNavs, 'alphabetical')}
                        </FilterNavComponentWrapper>
                        <Col xs={7}>
                            <Select
                                className="p-2"
                                classNamePrefix="select"
                                placeholder="Select Organisation Catgory"
                                isLoading={this.state.loading}
                                onChange={e => this.exportData(e, 'expiredMemberByYear')}
                                options={this.state.years}
                            />
                        </Col>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={12}>
                        {this.state.activeTab.type !== 'All' ||
                        this.state.activeTab.alphabetical !== 'All' ||
                        this.state.keyword.length !== 0 ? (
                            <SimplyReactTable
                                data={this.state.searchResult}
                                columns={columns}
                                pages={1}
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {}}
                            />
                        ) : (
                            <SimplyReactTable
                                data={this.props.directory}
                                columns={columns}
                                pages={this.props.tablePages}
                                loading={this.props.loading}
                                onFetchData={(state, instance) => {
                                    this.props.getMemberDirectory('NotExpired', state.page, state.pageSize);
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.memberDirectory,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getAllDirectory: () => {
            dispatch(memberMembershipMemberGetAllMemberDirectory());
        },
        getMemberDirectory: (type, page, pageSize, sorted, filtered) => {
            dispatch(memberMembershipMemberGetMemberDirectory(type, page, pageSize));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipMembersPage));
