import withQuery from 'with-query';
import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import fetchlib from '../../../../js/fetchlib.js';
import {
    EVENT_SAVE_EVENT_DETAIL_REQUEST,
    EVENT_SAVE_EVENT_DETAIL_SUCCESS,
    GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS,
    EVENT_SAVE_EVENT_DETAIL_FAILURE,
    EVENT_DELETE_EVENT_DETAIL_REQUEST,
    EVENT_DELETE_EVENT_DETAIL_SUCCESS,
    EVENT_DELETE_EVENT_DETAIL_FAILURE,
    EVENT_UPDATE_EVENT_DETAIL_REQUEST,
    EVENT_UPDATE_EVENT_DETAIL_SUCCESS,
    GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS,
    EVENT_UPDATE_EVENT_DETAIL_FAILURE,
    EVENT_GET_FOOD_RESTRICT_REQUEST,
    EVENT_GET_FOOD_RESTRICT_SUCCESS,
    EVENT_GET_FOOD_RESTRICT_FAILURE,
    EVENT_GET_PAYMENT_MODE_REQUEST,
    EVENT_GET_PAYMENT_MODE_SUCCESS,
    EVENT_GET_PAYMENT_MODE_FAILURE,
    EVENT_GET_EVENT_DETAIL_REQUEST,
    EVENT_GET_EVENT_DETAIL_SUCCESS,
    EVENT_GET_EVENT_DETAIL_FAILURE,
    EVENT_CHANGE_EVENT_FIELD_VALUE,
    EVENT_SAVE_DOCUMENT_FILE_REQUEST,
    EVENT_SAVE_DOCUMENT_FILE_SUCCESS,
    EVENT_SAVE_DOCUMENT_FILE_FAILURE,
    EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST,
    EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS,
    EVENT_DOWNLOAD_DOCUMENT_FILE_FAILURE,
    EVENT_REMOVE_DOCUMENT_FILE,
    EVENT_RESET_EVENT_FIELDS,
    EVENT_SET_FILE_INPUT_ID,
    EVENT_SET_IMAGE_SIZE,
    EVENT_SET_EVENT_ID,
    EVENT_SET_MODAL_DATA,
} from '../../../constants/events/actionTypesStaffEventsApplication.js';

export function eventGetEventDetails(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'findByEventId',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_GET_EVENT_DETAIL_REQUEST,
            EVENT_GET_EVENT_DETAIL_SUCCESS,
            EVENT_GET_EVENT_DETAIL_FAILURE,
        );
    };
}
export function eventSaveEventDetails(edmFileId, registrationPageImageFileId, eventBannerFileId) {
    return (dispatch, getState) => {
        let eventObj = getState().eventsCreationReducer.fields;
        // Hardcode to be Gala Dinner event
        eventObj.eventTypeId = 4;
        // Process food retriction
        eventObj.foodRestrict = [];
        // Process payment mode
        eventObj.paymentMode = [];
        // Process confirmRegRequired
        eventObj.confirmRegRequired = 0;

        eventObj.attendeeLimit = 0;
        eventObj.bannerImageHeight = 0;
        eventObj.bannerImageWidth = 0;
        eventObj.registrationPageImageHeight = 0;
        eventObj.registrationPageImageWidth = 0;
        eventObj.seriesId = 1;

        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'create',
        });
        eventObj.edmPdf = edmFileId;
        eventObj.bannerImage = eventBannerFileId;
        eventObj.registrationPageImage = registrationPageImageFileId;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            eventObj,
            EVENT_SAVE_EVENT_DETAIL_REQUEST,
            GALA_EVENT_SAVE_EVENT_DETAIL_SUCCESS,
            EVENT_SAVE_EVENT_DETAIL_FAILURE,
        );
    };
}
export function eventRemoveEventDetails(eventid) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'delete',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            { id: eventid },
            EVENT_DELETE_EVENT_DETAIL_REQUEST,
            EVENT_DELETE_EVENT_DETAIL_SUCCESS,
            EVENT_DELETE_EVENT_DETAIL_FAILURE,
        );
    };
}
export function eventUpdateEventDetails(eventid, edmFileId, registrationPageImageFileId, eventBannerFileId) {
    return (dispatch, getState) => {
        let fields = getState().eventsCreationReducer.fields;
        // Process food retriction
        fields.foodRestrict = Object.keys(fields.foodRestrict)
            .map(key => (fields.foodRestrict[key] === true ? key : null))
            .filter(x => x !== null);
        // Process payment mode
        fields.paymentMode = Object.keys(fields.paymentMode)
            .map(key => (fields.paymentMode[key] === true ? key : null))
            .filter(x => x !== null);
        // Process confirmRegRequired
        fields.confirmRegRequired = fields.confirmRegRequired ? 1 : 0;
        // Assign eventid
        fields.eventId = eventid;
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'update',
        });
        fields.edmPdf = edmFileId;
        fields.bannerImage = eventBannerFileId;
        fields.registrationPageImage = registrationPageImageFileId;
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            fields,
            EVENT_UPDATE_EVENT_DETAIL_REQUEST,
            GALA_EVENT_UPDATE_EVENT_DETAIL_SUCCESS,
            EVENT_UPDATE_EVENT_DETAIL_FAILURE,
        );
    };
}
export function eventGetfoodRestriction() {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'getFoodRestriction',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            undefined,
            EVENT_GET_FOOD_RESTRICT_REQUEST,
            EVENT_GET_FOOD_RESTRICT_SUCCESS,
            EVENT_GET_FOOD_RESTRICT_FAILURE,
        );
    };
}
export function eventGetPaymentMode() {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'getPaymentMode',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            undefined,
            EVENT_GET_PAYMENT_MODE_REQUEST,
            EVENT_GET_PAYMENT_MODE_SUCCESS,
            EVENT_GET_PAYMENT_MODE_FAILURE,
        );
    };
}

export function eventChangeEventInput(value, id, name) {
    return {
        type: EVENT_CHANGE_EVENT_FIELD_VALUE,
        value,
        id,
        name,
    };
}
export function eventHandleImageInput(height, width, inputId) {
    return {
        type: EVENT_SET_IMAGE_SIZE,
        height,
        width,
        inputId,
    };
}
export function eventResetEventFields() {
    return (dispatch, getState) => {
        dispatch({ type: EVENT_RESET_EVENT_FIELDS });
        return Promise.resolve();
    };
}
export function eventSetEventId(eventid) {
    return {
        type: EVENT_SET_EVENT_ID,
        eventid,
    };
}
export function eventSetModalData(data) {
    return {
        type: EVENT_SET_MODAL_DATA,
        data,
    };
}
export function eventRemoveDocumentFile(inputId) {
    return {
        type: EVENT_REMOVE_DOCUMENT_FILE,
        inputId,
    };
}
export function eventSaveDocumentFile(contenttype, filename, attachment, inputId) {
    return (dispatch, getState) => {
        dispatch({ type: EVENT_SET_FILE_INPUT_ID, inputId });
        const url = withQuery(SGBC_API_ROOT + '/resources_v1/attachment', { action: 'upload' });
        const body = {
            contenttype,
            filename,
            attachment,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_SAVE_DOCUMENT_FILE_REQUEST,
            EVENT_SAVE_DOCUMENT_FILE_SUCCESS,
            EVENT_SAVE_DOCUMENT_FILE_FAILURE,
        );
    };
}
export function eventDownloadDocumentFile(inputId) {
    return (dispatch, getState) => {
        dispatch({ type: EVENT_SET_FILE_INPUT_ID, inputId });
        const url = withQuery(SGBC_API_ROOT + '/resources_v1/attachment', {
            action: 'download',
            id: getState().eventsCreationReducer.fields[inputId],
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_DOWNLOAD_DOCUMENT_FILE_REQUEST,
            EVENT_DOWNLOAD_DOCUMENT_FILE_SUCCESS,
            EVENT_DOWNLOAD_DOCUMENT_FILE_FAILURE,
        );
    };
}
