const local_en = {
    translations: {
        "Key": "Key",
        "Key_kvp": "Key",
        "Key_lock": "Key",

        "Value": "Value",
        "Value_kvp": "Value",

        "Action": "Action",
    },
    sidemenu: {
        "Cyder Intranet" : "Cyder Intranet",
        "Main" : "Main",
        "Home" : "Home",
        "Expenses" : "Expenses",
        "My Receipts" : "My Receipts",
        "My Reports" : "My Reports",
        "Timesheet" : "Timesheet",
        "Current Month" : "Current Month",
        "Reports" : "Reports",
        "Leave" : "Leave",
        "New" : "New",
        "Report" : "Report",
        "Administration" : "Administration",
        "Security" : "Security",
        "Groups" : "Groups",
        "Users" : "Users",
        "Admin": "Admin",
    }
}
export default local_en;
