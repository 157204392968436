import React from 'react';
import { PreviewPanelWrapper } from './PreviewPanel1';
import { Col } from 'reactstrap';

const PreviewPanel2 = ({ textColor, certificatesExpiring }) => {
    const year = new Date().getFullYear();
    return (
        <PreviewPanelWrapper textColor={textColor} icon="fa-calendar-check">
            <br />
            <Col sm={7}>
                <h4 className="pt-3">
                    Certifications <br /> Expiring in {year}
                </h4>
            </Col>
            <Col sm={5} className="text-right">
                <h1 className="display-4 text-bold">{certificatesExpiring}</h1>
            </Col>
        </PreviewPanelWrapper>
    );
};

export default PreviewPanel2;
