import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Input, Row, Col, Badge, Nav, NavItem, NavLink, CardText, Button } from 'reactstrap';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { mobile } from '../../../public/dummy';
import {
    staffMembershipMemberGetOrganisationCategory,
    staffMembershipMemberGetPublicMemberDirectory,
} from 'actions/staff/membership/members/staffMembershipMembersAction.js';

import { defaultKeywordSearchKeys } from 'js/keywordSearch';

const FilterNavComponentWrapper = props => {
    return (
        <Col xs={12} {...props}>
            <div>
                <Row className="mb-2">
                    <Col xs={12}>
                        <Nav tabs>{props.children}</Nav>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};
const FilterNavTitle = props => {
    return (
        <CardText className="p-2">
            <strong>{props.label + ': '}</strong>
            <br />
        </CardText>
    );
};
class StaffMembershipMembersPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            activeTab: {
                type: 'All',
                tier: '',
                alphabetical: 'All',
            },
            selectedOrgCategory: {
                value: '',
                label: '',
            },
            directoryList: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            ...defaultKeywordSearchKeys,
        };
        this.onKeywordChange = this.onKeywordChange.bind(this);
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getOrganisationCategory();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading = loading => {
        this.setState({
            loading,
        });
    };
    handleInputChange = event => {
        this.setState({
            keyword: event.target.value,
        });
    };
    onSelectChange = e => {
        this.setState({
            selectedOrgCategory: e,
        });
    };
    search = async (page, pageSize = 20, sorted, filtered) => {
        const { keyword, selectedOrgCategory } = this.state;
        const { type, alphabetical, tier } = this.state.activeTab;
        try {
            this.showLoading(true);
            const { data, totalNoOfPages, totalNoOfItems } = await this.props.getMemberDirectory(
                type,
                keyword,
                selectedOrgCategory.value,
                alphabetical,
                tier,
                page,
                pageSize,
                sorted,
            );
            this.setState({
                directoryList: data,
                loading: false,
                pageCount: totalNoOfPages,
                totalNum: totalNoOfItems,
            });
        } catch (error) {
            throw error;
        }
    };
    getAlphebeticalNavObject() {
        let alphabets = ['All'];
        let i = 'A'.charCodeAt(0);
        let j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            alphabets.push(String.fromCharCode(i));
        }
        return alphabets.map(letter => {
            return {
                key: letter.toLowerCase(),
                text: letter,
            };
        });
    }
    toggleTab(tab, id) {
        let activeTab = this.state.activeTab;
        activeTab[id] = tab;
        this.setState({
            activeTab,
            loading: true,
        });
        return Promise.resolve();
    }
    renderNav(navItems, id) {
        return navItems.map(item => {
            const isActive = this.state.activeTab[id] === item.text;
            return (
                <NavItem key={item.key}>
                    <NavLink
                        style={{
                            zIndex: 0,
                            fontWeight: isActive ? 'bold' : '',
                        }}
                        className={isActive ? 'active p-2' : 'p-2'}
                        onClick={() => {
                            this.toggleTab(item.text, id).then(() => this.table.fireFetchData());
                        }}
                    >
                        {item.text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        var typeNavs = [
            {
                key: 'all',
                text: 'All',
            },
            {
                key: 'foundingMembers',
                text: 'Founding Member',
            },
            {
                key: 'corporateMembers',
                text: 'Corporate Member',
            },
            {
                key: 'establishedMembers',
                text: 'Established Member',
            },
        ];
        var tierNavs = [
            {
                key: 'standard',
                text: 'Standard',
            },
            {
                key: 'partner',
                text: 'Partner',
            },
            {
                key: 'advocate',
                text: 'Advocate',
            },
        ];
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 300,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Link className="link" to={'/staff/company/' + props.original.companyId}>
                            {props.value}
                        </Link>
                    );
                },
            },
            {
                Header: 'Type',
                accessor: 'membershipType',
                minWidth: 125,
                Cell: props => {
                    return (
                        <Badge className="btn primary-btn-style" style={{ cursor: 'default' }}>
                            {props.value}
                        </Badge>
                    );
                },
            },
            // Requested by Wenyi on 22 August 2019, to hide this column
            // https://cydersg.atlassian.net/browse/SGBCCRM-1572
            // {
            //     Header: 'Tier',
            //     accessor: 'membershipTierCalculated',
            //     minWidth: 125,
            //     Cell: props => {
            //         if (props.value === 'N/A' || !props.value) return this.state.activeTab.tier;
            //         return props.value;
            //     },
            // },
            {
                Header: 'Website',
                accessor: 'website',
                minWidth: 125,
                Cell: props => {
                    if (!props.value) return `-`;
                    if (props.value == null) return `-`;
                    if (props.value == 'null') return `-`;
                    if (props.value.indexOf('http') < 0) {
                        const url = `http://${props.value}`;
                        return (
                            <a className="link" target="_blank" href={url}>
                                {props.value}
                            </a>
                        );
                    }
                    return (
                        <a className="link" target="_blank" href={props.value}>
                            {props.value}
                        </a>
                    );
                },
            },
            {
                Header: 'Category',
                accessor: 'orgCategory',
                style: { whiteSpace: 'unset' },
                minWidth: 200,
            },
        ];
        const alphaticallNavs = this.getAlphebeticalNavObject();
        return (
            <div className="page-widget">
                <Row className="mb-2 p-4">
                    <div className="form-inline">
                        <div style={{ paddingRight: '10px' }}>
                            <Input
                                type="text"
                                placeholder="Keyword"
                                onKeyDown={e => {
                                    if (e.keyCode == 13) {
                                        this.table.fireFetchData();
                                    }
                                }}
                                value={this.state.keyword}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div style={{ width: '350px', paddingRight: '10px' }}>
                            <Select
                                classNamePrefix="select"
                                placeholder={mobile ? 'Organisation Category' : 'Select Organisation Category'}
                                isLoading={this.state.loading}
                                options={this.props.orgCategories}
                                onChange={this.onSelectChange}
                            />
                        </div>
                        <Button className="ml-2 align-middle primary-btn-style" onClick={() => this.table.fireFetchData()}>
                            <i className="material-icons align-text-bottom">search</i>
                            &nbsp;Search
                        </Button>
                    </div>
                </Row>
                <RowColWrapper>
                    <FilterNavComponentWrapper>
                        <FilterNavTitle label="Type" />
                        {this.renderNav(typeNavs, 'type')}
                    </FilterNavComponentWrapper>
                    <FilterNavComponentWrapper className="tier">
                        <FilterNavTitle label="Tier" />
                        {this.renderNav(tierNavs, 'tier')}
                    </FilterNavComponentWrapper>
                    <FilterNavComponentWrapper className="alphabetical">
                        <FilterNavTitle label="Alphabetical" />
                        {this.renderNav(alphaticallNavs, 'alphabetical')}
                    </FilterNavComponentWrapper>
                </RowColWrapper>
                <RowColWrapper>
                    <div style={{ paddingLeft: '16px' }}>
                        <CyderReactTable
                            manual
                            minRows={0}
                            filterable={false}
                            columns={columns}
                            className="-highlight mb-2"
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            data={this.state.directoryList}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) this.table = instance.table;
                            }}
                            onFetchData={state => this.search(state.page, state.pageSize, state.sorted, state.filtered)}
                        />
                    </div>
                </RowColWrapper>
            </div>
        );
    }
}

const RowColWrapper = props => {
    const { children, rowStyle, colStyle, colClassName } = props;
    return (
        <Row style={rowStyle}>
            <Col xs={12} className={colClassName} style={colStyle}>
                {children}
            </Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffMembershipMembersReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getMemberDirectory: (type, keyword, category, alphabetical, tier, page, pageSize, sorted, filtered) => {
            return dispatch(
                staffMembershipMemberGetPublicMemberDirectory(
                    type,
                    keyword,
                    category,
                    alphabetical,
                    tier,
                    page,
                    pageSize,
                    sorted,
                    filtered,
                ),
            );
        },
        getOrganisationCategory: () => {
            return dispatch(staffMembershipMemberGetOrganisationCategory());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipMembersPage));
