import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Pie } from 'react-chartjs-2';
import { getSMECount } from 'actions/analytics/memberAnalyticsAction';
import CyderDoughnut from 'pages/analytics/components/charts/CyderDoughnut';
var randomColor = require('randomcolor');
var tinygradient = require('tinygradient');

// var gradient = tinygradient('#68C0D6', '#78BE20');
var gradient = tinygradient('#36a2eb', '#ff8686');
var generateGradient = step => {
    var colorsRgb = gradient.rgb(step);
    return colorsRgb;
};

class CertifiedServiceDistribution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legendconfig: {
                legend: {
                    onClick: null,
                },
            },
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            var col = generateGradient(3);
            let labels = ['SME', 'Non-SME'];
            let data = [];
            let total = 0;
            let backgroundColor = [];
            let hoverBackgroundColor = [];
            data.push(res.data.isSME);
            data.push(res.data.notSME);
            total = res.data.allCount;
            backgroundColor.push(col[0].toHexString());
            backgroundColor.push(col[1].toHexString());
            this.setState({
                data,
                backgroundColor,
                hoverBackgroundColor,
                labels,
                total,
                centerText: total,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`SME Proportion`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CyderDoughnut {...this.state} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getSMECount());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertifiedServiceDistribution));
