import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Table, Button } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getActiveCertifiedProductCountAnalyticDetail } from 'actions/analytics/certificateAnalyticsAction'; //anoth one
import { HorizontalBar, Chart } from 'react-chartjs-2';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';

class CertificationActiveCertAnalyticsDetailsList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const columns = [
            {
                Header: 'ID',
                accessor: 'certificateId',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certificateId'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <Link to={'/staff/certification/certificates/product/certdetails/' + props.value}>{props.value}</Link>;
                },
            },
            {
                Header: 'CertNumber',
                accessor: 'certificateId',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['certificateId'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <Link to={'/staff/certification/certificates/product/certdetails/' + props.value}>{props.value}</Link>;
                },
            },
            {
                Header: 'Model',
                accessor: 'models',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['models'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'BrandName',
                accessor: 'brandName',
                maxWidth: 75,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['brandName'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'CompanyName',
                accessor: 'companyName',
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ['companyName'] }),
                filterAll: true,
                style: { whiteSpace: 'unset' },
                Cell: ({ row, original }) => {
                    return <Link to={'/staff/company/' + original.companyId}>{original.companyName}</Link>;
                },
            },
        ];

        return this.state.data == null ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <div className="table-responsive">
                    <ReactTable
                        columns={columns}
                        filterable={true}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        data={this.state.data}
                    />
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getActiveCertifiedProductCountAnalyticDetail());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationActiveCertAnalyticsDetailsList));
