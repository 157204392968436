import {
    STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST,
    STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS,
    STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE,
    STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS,
    STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE,
    STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT,
    STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA,
} from '../../../actions/constants/actionTypesStaffCompanyDetails';

import { setReducerModalState, defaultModalKeys } from '../../../js/modal';

// defaults
var defaultState = {
    loading: false,
    subCategory: [],
    selectedMembershipTier: {
        label: `Standard`,
        value: `Standard`,
    },
    companyInfo: {
        additionalRepresentative1Id: 0,
        annualTurnover: 0,
        businessAddressLine1: '  Address',
        businessAddressLine2: '',
        businessAddressLine3: '',
        businessPostalCode: '',
        certificationRepId: 0,
        countryId: 0,
        countryName: 'Country',
        facsimile: '',
        generalEmail: 'Email',
        greenBuildingMovementInvolvement: 'None',
        haveMinimumThirtyPercentLocalShareholding: 0,
        id: 0,
        logoLocation: '',
        mailingAddressLine1: '',
        mailingAddressLine2: '',
        mailingAddressLine3: '',
        mailingPostalCode: '',
        mainPhoneNumber: ' Mobile Number',
        name: 'Corporate Name',
        numberOfEmployees: 0,
        officialManagementRepresentativeId: 0,
        organisationMainCategoryId: 0,
        organisationName: '',
        productsServicesDescription: '',
        registrationNumber: 'Registration Number',
        secondaryRepId: 0,
        userId: 0,
        website: 'Website',
    },
    memberInfo: {
        isSME: 1,
        dateJoined: 0,
        expiryDate: 0,
        membershipType: 'Founding Member',
    },
    count: {
        certification: 0,
        event: 0,
    },
};

export function staffCompanyDetailsOverviewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS:
            const data = action.response.data;
            newState.companyInfo = data.companydata;
            newState.subCategory = data.subCategory;
            Object.keys(newState.companyInfo).forEach(key => {
                switch (data.companydata[key]) {
                    case '':
                    case 'null':
                    case null:
                        newState.companyInfo[key] = 'Not specified';
                        break;
                }
            });
            newState.loading = false;
            return newState;
        case STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE:
            newState.companyInfo = Object.assign({}, defaultState.companyInfo);
            newState.loading = false;
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS:
            newState.memberInfo = action.response.data[0];
            newState.selectedMembershipTier = {
                label: newState.memberInfo.membershipTier || defaultState.selectedMembershipTier.label,
                value: newState.memberInfo.membershipTier || defaultState.selectedMembershipTier.value,
            };
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS:
            newState.count.certification = action.response.data[0].count;
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS:
            newState.count.event = action.response.data[0].count;
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE:
            newState.selectedMembershipTier = action.event;
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        case STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return newState;
    }
}
