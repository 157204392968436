import React from 'react';
import { Grid, Item, Button, Header, List, Input, Segment, Image, Label, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { fetchProducts } from '../../../actions/public';
import NoData from '../components/NoData';
import { mobile } from '../dummy';

class ProductList extends React.Component {
    state = { productTypeLabels: 'all', ratings: 4 };

    async componentDidMount() {
        const { handleFetchProducts, match } = this.props;
        const { categoryId, subcategoryId, productId } = match.params;
        handleFetchProducts(categoryId, subcategoryId, productId ? productId : null);
    }

    render() {
        const {
            history,
            loading,
            products,
            states,
            foundMessage,
            handleChanges,
            ratingLabels,
            ratingOptions,
            ratingTicks,
            match,
        } = this.props;
        const { sortRating, ratingId } = states;
        const { onSearchChange, onFilterRating, onFetchProductDetails, onSearchCurrentResult } = handleChanges;

        return (
            <Grid stackable centered className={loading ? 'loading product-list' : 'product-list'}>
                <Grid.Column width={16}>
                    <Segment basic className="navigator">
                        <Grid className="found">
                            <Grid.Column width={4}>
                                <Header as="h3">{foundMessage}</Header>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                {products && (
                                    <Form onSubmit={onSearchCurrentResult}>
                                        <Input
                                            fluid
                                            // icon={{ name: 'arrow right', circular: true, link: true }}
                                            size="large"
                                            name="keyword"
                                            onChange={onSearchChange}
                                            placeholder="Search from current result"
                                        />
                                    </Form>
                                )}
                            </Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment basic className="filtering">
                        <Label.Group circular size="medium">
                            {ratingOptions &&
                                ratingOptions
                                    .sort((a, b) => b.key - a.key)
                                    .map(item => (
                                        <Label
                                            as="a"
                                            key={item.key}
                                            content={item.text}
                                            value={item.value}
                                            onClick={onFilterRating}
                                            className={item.value === ratingId ? 'active ticks' : ' ticks'}
                                        />
                                    ))}
                        </Label.Group>
                    </Segment>

                    <Item.Group link>
                        {/* prettier-ignore */}
                        {products && products.length > 0 ? (
                            products
                                .sort((a, b) => b.ratingId - a.ratingId)
                                .map(item => {
                                    return (
                                        <Item
                                            key={item.productId}
                                            onClick={() =>
                                                onFetchProductDetails(
                                                    item.certificateId,
                                                    item.companyId,
                                                    item.productTypeId,
                                                    item.productSubcategoryId,
                                                    item.productCategoryId,
                                                )
                                            }
                                        >
                                            <Item.Content>
                                                <Segment basic>
                                                    <Header className="company">{item.companyName}</Header>
                                                </Segment>
                                                <Item.Description className="description-list">
                                                    <Grid stackable>
                                                        <Grid.Row columns={2}>
                                                            <Grid.Column>
                                                                <List>
                                                                    <List.Item>
                                                                        <div>Brand:</div> &nbsp;
                                                                        <div>
                                                                            <strong>{item.brandName}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <div>Model:</div> &nbsp;
                                                                        <div>
                                                                            <strong>{item.models}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <div>Product type:</div> &nbsp;
                                                                        <div>
                                                                            <strong>{item.productType}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                </List>
                                                            </Grid.Column>
                                                            <Grid.Column>
                                                                <List>
                                                                    <List.Item>
                                                                        <div>Certification number:</div> &nbsp;{' '}
                                                                        <div>
                                                                            <strong>{item.certNo}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <div>Issued on:</div> &nbsp;{' '}
                                                                        <div>
                                                                            <strong>{item.validFrom}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <div>Valid until:</div> &nbsp;{' '}
                                                                        <div>
                                                                            <strong>{item.validTill}</strong>
                                                                        </div>
                                                                    </List.Item>
                                                                </List>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row columns={1}>
                                                            <Grid.Column>
                                                                <Header as="h5" className="ticks">
                                                                    {item.rating} &nbsp; {ratingTicks(item)}
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Item.Description>
                                            </Item.Content>
                                        </Item>
                                    );
                                })
                        ) : (
                            <NoData header="No products found" />
                        )}
                    </Item.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = (state, ownProps) => {
    const subId = ownProps.match.params && ownProps.match.params.subcategoryId;
    const products = ownProps.products && ownProps.products;
    const productsLength = ownProps.productsLength && ownProps.productsLength;
    const filteringLabels = ownProps.productType && ownProps.productType.filter(item => item.showLabel === 1);
    const filteringLabelsId = ownProps.productType && ownProps.productType.map(item => item.productTypeId);
    const noProducts = (productsLength && productsLength < 1) || !productsLength || productsLength === undefined;
    // console.log("products: ", products)
    const foundMessage =
        products == null || products.length <= 0
            ? 'No products found '
            : `Found ${products.length} ${products.length > 1 ? 'products ' : 'product '}`;
    const ratingLabels = ratingState =>
        ownProps.ratingLabels &&
        ownProps.ratingLabels
            .filter(item => {
                if (item.ratingId === 'all') return 'All';
                return item.ratingId === ratingState;
            })
            .map(item => item.ratingName)[0];
    const ratings = ownProps.ratings && ownProps.ratings;
    const ratingTicks = item => {
        const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" />;

        let text = '';

        if (item.ratingId === 1) text = <span>{checkmark}</span>;
        else if (item.ratingId === 2)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 3)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 4)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );

        return text;
    };
    const ratingsId = ownProps.ratings && ownProps.ratings.map(item => item.id);
    const ratingAll = {
        ratingId: 'all',
        ratingName: 'All',
        ratingNumber: 5,
        type: 'product',
    };
    const productTypeAll = {
        productTypeId: 'all',
        showLabel: 1,
        type: 'All',
    };

    return {
        products,
        subId,
        ratings: ratings && [...ratings, ratingAll],
        ratingsId,
        filteringLabels: filteringLabels && [...filteringLabels, productTypeAll],
        filteringLabelsId,
        foundMessage,
        ratingLabels,
        ratingTicks,
    };
};

// prettier-ignore
export default connect( mapState, { fetchProducts } )(withRouter(ProductList));
