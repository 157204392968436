/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import ScoresheetViewPanel from 'pages/staff/certification/assessment/components/ScoresheetViewPanel';
import { Button, Container } from 'reactstrap';
import history from '../../../../../history';

class ProductAssessmentPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onBackClicked = this.onBackClicked.bind(this);
    }
    onBackClicked() {
        const { applicationid, certid } = this.props.match.params;
        const pathname = window.location.pathname;
        const type = pathname.toLowerCase().includes('product') ? 'product' : 'service';
        const link = applicationid
            ? `/member/certification/${type}/application/${applicationid}`
            : `/member/certification/${type}/renew/${certid}`;
        history.push(link);
    }
    render() {
        return (
            <Container style={{ marginTop: '30px' }}>
                <div>
                    <Button className="primary-btn-style mr-2" onClick={this.onBackClicked}>
                        <i className="mr-1 material-icons align-text-bottom ">keyboard_arrow_left</i>
                        Back
                    </Button>
                </div>
                <div>
                    {this.props.match.params.scoresheetid && this.props.match.params.applicationid && (
                        <ScoresheetViewPanel
                            adjustPrintButton
                            type={this.props.type}
                            templateId={this.props.match.params.templateid}
                            scoresheetId={this.props.match.params.scoresheetid}
                            isMember={true}
                            applicationId={this.props.match.params.applicationid}
                            showInstruction={true}
                        />
                    )}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ProductAssessmentPage),
);
