export const CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST = 'CREATE_ACCOUNT_CORPORATE_PREVIEW_REQUEST';
export const CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS = 'CREATE_ACCOUNT_CORPORATE_PREVIEW_SUCCESS';
export const CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE = 'CREATE_ACCOUNT_CORPORATE_PREVIEW_FAILURE';

export const CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY = 'CREATE_ACCOUNT_CORPORATE_SET_DEFAULT_COUNTRY';
export const CREATE_ACCOUNT_CORPORATE_RESET_FIELDS = 'CREATE_ACCOUNT_CORPORATE_RESET_FIELDS';

export const CREATE_ACCOUNT_CORPORATE_REQUEST = 'CREATE_ACCOUNT_CORPORATE_REQUEST';
export const CREATE_ACCOUNT_CORPORATE_SUCCESS = 'CREATE_ACCOUNT_CORPORATE_SUCCESS';
export const CREATE_ACCOUNT_CORPORATE_FAILURE = 'CREATE_ACCOUNT_CORPORATE_FAILURE';

export const CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT = 'CREATE_ACCOUNT_CORPORATE_CHANGE_TIMEOUT';
export const CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE = 'CREATE_ACCOUNT_CORPORATE_CHANGE_VALUE';

export const CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE = 'CREATE_ACCOUNT_CORPORATE_FORCE_VALIDATE';

export const CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY = 'CREATE_ACCOUNT_CORPORATE_PREFILL_COMPANY';

export const CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY = 'CREATE_ACCOUNT_CORPORATE_SET_NO_COMPANY';

export const CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA = 'CREATE_ACCOUNT_CORPORATE_SET_MODAL_DATA';

export const CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY = 'CREATE_ACCOUNT_CORPORATE_SET_PREVIEW_OKAY';
