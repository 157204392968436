import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from '../../../../layouts/StoredLayout';

import { FormGroup, Input, Row, Col, Button } from 'reactstrap';
import EventsHeaderComponent from './EventsHeaderComponent';
import { WrapperComponent } from './EventsAttendeePage';
import DialogModal from 'cyder/modals/DialogModal';
import CyderReactTable from 'cyder/table/CyderReactTable';
import YesNoIcon from 'cyder/media/YesNoIcon';
import { SGBC_WEB_HOST } from 'config';

import {
    eventsRegistrationGetAllRegistrationList,
    eventsRegistrationGetRegistrationList,
    eventsRegistrationUnconfirmRegistration,
    eventsRegistrationConfirmRegistration,
    eventsRegistrationHandleInputChange,
    eventsRegistrationResetToDefault,
    eventsRegistrationDelete,
    eventsRegistrationSetDialogModalData,
    exportRegistrationList,
} from '../../../../actions/staff/events/events/eventsRegistrationAction';
import { eventGetEventDetails } from '../../../../actions/staff/events/events/eventsCreationAction';
import { dialogModalToggle, setDialogModalToggle } from 'actions/modal/dialogModalAction';

import { SGBC_INVOICE_DOWNLOAD_ROOT } from 'config';

class EventsRegistrationPage extends React.Component {
    constructor(props) {
        super(props);
        this.confirmRegistarion = this.confirmRegistarion.bind(this);
        this.unconfirmRegistration = this.unconfirmRegistration.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.exportAll = this.exportAll.bind(this);
        this.state = { loading: false };
    }
    componentDidMount() {
        const eventid = this.props.match.params.eventid;
        this.props.resetToDefault();
        this.props.getAllRegistrationList(eventid);
        if (this.props.create.fields.name === '') this.props.getEventDetails(eventid);
    }
    boundForceUpdate() {
        this.table.fireFetchData();
        // this.props.getRegistrationList(this.props.match.params.eventid);
    }

    toJson(array) {}

    async exportAll() {
        try {
            this.setState({ loading: true });
            const { data } = await this.props.exportRegistrationList(this.props.match.params.eventid);
            var convert = [];
            data.forEach(x => {
                var obj = x;
                obj.joinMailingList = obj.joinMailingList ? 'Yes' : 'No';
                obj.isRegistrationConfirmed = obj.isRegistrationConfirmed ? 'Yes' : 'No';
                obj.paymentStatus = obj.paymentStatus ? 'Yes' : 'No';
                convert.push(obj);
            });

            let Papa = require('papaparse');
            let FileSaver = require('file-saver');

            let config = {
                quotes: false,
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ',',
                header: true,
                newline: '\r\n',
            };

            var csvPayload = Papa.unparse(convert, config);
            var blob = new Blob([csvPayload], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, 'registration.csv');
        } catch (error) {
            throw error;
        } finally {
            this.setState({ loading: false });
        }
    }

    unconfirmRegistration(id) {
        this.props.dialogModalToggle();
        this.props.setDialogModalData({
            modalAction: () => this.props.unconfirmRegistration(id),
            modalBody: 'Click OK to unconfirm this Attendee.',
        });
    }
    confirmRegistarion(id) {
        this.props.dialogModalToggle();
        this.props.setDialogModalData({
            modalAction: () => this.props.confirmRegistarion(id),
            modalBody: 'Click OK to confirm this Attendee.',
        });
    }
    deleteRegistration(id) {
        this.props.dialogModalToggle();
        this.props.setDialogModalData({
            modalAction: () => this.props.eventsRegistrationDelete(id),
            modalBody: 'Click OK to delete this registration.',
        });
    }
    render() {
        const mode = this.props.match.url.toLowerCase().includes('staff') ? 'staff' : 'member';
        let columns = [
            {
                Header: 'Name',
                accessor: 'attendeeName',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
                Cell: props => {
                    return (
                        <React.Fragment>
                            <span style={{ fontWeight: 'bold' }}>
                                {props.original.attendeeName + ' (' + props.original.applicantEmail + ')'}{' '}
                            </span>
                            <br />
                            {props.original.attendeeDesignation}
                            <br />
                            {props.original.attendeePhone}
                        </React.Fragment>
                    );
                },
            },
            {
                Header: 'Company',
                accessor: 'nameOfBillingOrganisation',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 100,
            },
            {
                Header: 'Ticket',
                accessor: 'ticketTypeName',
                minWidth: 100,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <span>
                            {props.original.ticketQuantity + ' X ' + props.original.ticketTypeName}
                            <br />
                            <b>Total ${props.original.totalPrice}</b>
                        </span>
                    );
                },
            },
            {
                Header: 'Registration',
                accessor: 'createdOn',
                maxWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Confirmed',
                accessor: 'registrationConfirmed',
                maxWidth: 100,
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <YesNoIcon
                            val={props.value}
                            onClick={() =>
                                props.value === 1 ? this.unconfirmRegistration(props.row.id) : this.confirmRegistarion(props.row.id)
                            }
                        />
                    );
                },
            },
            {
                Header: 'Invoice',
                accessor: 'invoiceNumber',
                style: { whiteSpace: 'unset' },
                minWidth: 50,
                Cell: props => {
                    return props.value !== null ? (
                        <div>
                            {props.value}
                            &nbsp;
                            <i
                                className="fa fa-1x fa-icon fa-download"
                                style={{ cursor: 'pointer', color: '#6ba17d' }}
                                onClick={e => {
                                    window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=${props.value}`;
                                }}
                            />
                            {/* <Button
                                className="primary-btn-style"
                                onClick={e => {
                                    window.location.href = `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=${
                                        props.value
                                    }`;
                                }}
                            >
                                <i className="fa fa-1x fa-download" />
                            </Button> */}
                        </div>
                    ) : props.original.totalPrice == 0 ? (
                        <div>
                            {' '}
                            <i>NA</i>{' '}
                        </div>
                    ) : (
                        <div>
                            <i>Pending</i>
                        </div>
                    );
                },
            },
            {
                Header: 'Paid',
                accessor: 'paymentStatus',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                maxWidth: 50,
                Cell: props => {
                    return props.original.totalPrice == 0 ? (
                        <div>
                            {' '}
                            <i>NA</i>{' '}
                        </div>
                    ) : (
                        <Link to={'./regpayment/' + props.original.id}>
                            <YesNoIcon val={props.value} />
                        </Link>
                    );
                },
            },
            {
                Header: 'Delete',
                accessor: 'id',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                maxWidth: 60,
                Cell: props => {
                    if (props.original.registrationConfirmed == 0 || props.original.paymentStatus == 0 || props.original.totalPrice == 0) {
                        return (
                            <i
                                className="fa fa-1x fa-icon fa-trash"
                                style={{ cursor: 'pointer', color: 'red' }}
                                onClick={e => {
                                    this.deleteRegistration(props.original.id);
                                }}
                            />
                        );
                    } else {
                        return ``;
                    }
                },
            },
            // {
            //     Header: '',
            //     sortable: false,
            //     accessor: 'id',
            //     Cell: props => {
            //         return (
            //             <div>
            //                 <Link to={'./regpayment/' + props.value}>
            //                     <Button className="primary-btn-style">
            //                         <i className="material-icons align-text-bottom ">attach_money</i>
            //                     </Button>
            //                 </Link>
            //             </div>
            //         );
            //     },
            // },
        ];
        columns = mode === 'staff' ? columns : columns.slice(0, columns.length - 2);
        const { params } = this.props.match;

        return (
            <WrapperComponent mode={mode}>
                <DialogModal
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    modalHeader={this.props.modalHeader}
                    boundForceUpdate={this.boundForceUpdate}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    buttonDisabler={this.props.modalLoading}
                />
                <div className={mode === 'staff' ? 'page-widget' : null}>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <Link to={mode === 'staff' ? '../' + this.props.match.params.eventid + '/main' : '/member/events/event'}>
                                <Button className="primary-btn-style-outline">
                                    <i className="material-icons align-text-bottom">chevron_left</i> Info
                                </Button>
                                &nbsp;
                            </Link>
                        </Col>
                    </Row>
                    {mode === 'staff' ? null : <hr />}
                    <br />
                    <EventsHeaderComponent registration mode={mode} eventId={params.eventid} companyId={params.companyid} />
                    <br />
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <div>
                                <h3>Registrations</h3>
                            </div>
                            <div className="ml-auto d-flex flex-row">
                                <Link to={`/staff/event/rsvp/${params.eventid}`}>{`Staff RSVP Link`}</Link>
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex">
                            <span>
                                Total: {this.props.data.total} | Paid: {this.props.data.paid} | Not Paid: {this.props.data.notPaid}
                            </span>
                            <div className="ml-auto d-flex flex-row">
                                <span className="align-self-center">
                                    <span
                                        style={{ cursor: 'pointer', fontWeight: '400' }}
                                        onClick={e => {
                                            window.open(`/public/event/rsvp/${params.eventid}`);
                                        }}
                                    >
                                        Public RSVP Link
                                    </span>
                                    {/* <Link to={`/public/event/rsvp/${params.eventid}`}>{`Public RSVP Link`}</Link> */}
                                    {/* Public RSVP Link: {`https://${SGBC_WEB_HOST}/public/event/rsvp/${params.eventid}`} */}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <div className="form-inline">
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Input
                                        type="text"
                                        id="keywordSearch"
                                        placeholder="Keyword"
                                        onKeyDown={e => {
                                            if (e.keyCode == 13) {
                                                this.props.getRegistrationList(this.props.match.params.eventid, this.props.keyword);
                                            }
                                        }}
                                        value={this.props.keyword}
                                        onChange={event => this.props.handleInputChange(this.props.match.params.eventid, event)}
                                    />
                                </FormGroup>
                                <Button
                                    className="primary-btn-style"
                                    onClick={() => this.props.getRegistrationList(this.props.match.params.eventid, this.props.keyword)}
                                >
                                    Search
                                </Button>
                                <Button className="primary-btn-style" style={{ marginLeft: '5px' }} onClick={this.exportAll}>
                                    <i className="mr-1 material-icons align-text-bottom ">print</i>
                                    Print
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={12}>
                            <CyderReactTable
                                manual
                                className="-highlight mb-2"
                                data={this.props.registrationList}
                                columns={columns}
                                minRows={0}
                                defaultPageSize={20}
                                filterable={false}
                                pages={this.props.tablePages}
                                totalNum={this.props.totalNum}
                                ref={instance => {
                                    if (instance != null) {
                                        this.table = instance.table;
                                    }
                                }}
                                loading={this.props.loading || this.state.loading}
                                onFetchData={(state, instance) => {
                                    this.props.getRegistrationList(
                                        this.props.match.params.eventid,
                                        this.props.keyword,
                                        state.page,
                                        state.pageSize,
                                        state.sorted,
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            </WrapperComponent>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsRegistrationReducer,
        create: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        exportRegistrationList: eventId => {
            return dispatch(exportRegistrationList(eventId));
        },
        getRegistrationList: (eventId, keyword, page, pageSize, sorted) => {
            return dispatch(eventsRegistrationGetRegistrationList(eventId, keyword, page, pageSize, sorted));
        },
        getAllRegistrationList: eventid => {
            return dispatch(eventsRegistrationGetAllRegistrationList(eventid));
        },
        confirmRegistarion: registrationId => {
            return dispatch(eventsRegistrationConfirmRegistration(registrationId));
        },
        unconfirmRegistration: registrationId => {
            return dispatch(eventsRegistrationUnconfirmRegistration(registrationId));
        },
        eventsRegistrationDelete: registrationId => {
            return dispatch(eventsRegistrationDelete(registrationId));
        },
        resetToDefault: () => {
            dispatch(eventsRegistrationResetToDefault());
        },
        getEventDetails: eventid => {
            dispatch(eventGetEventDetails(eventid));
        },
        handleInputChange: (eventId, event) => {
            const value = event.target.value;
            dispatch(eventsRegistrationHandleInputChange(value));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        setDialogModalData: modalData => {
            dispatch(eventsRegistrationSetDialogModalData(modalData));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsRegistrationPage));
