import {
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS,
    STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE,
    STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA,
} from '../../../../actions/constants/actionTypesStaffCertificationAssessmentCriteria';
import validationlib from 'js/validation';
import { setReducerModalState, defaultModalKeys } from 'js/modal';

const defaultState = {
    loading: false,
    sections: [],
    fields: {
        criteriaCode: '',
        criteria: '',
        requiredDocuments: '',
        documentsToSubmit: '',
        criteriaSectionId: '',
        ratings: [],
        ratingCode: '',
        ratingDescription: '',
        ratingSortkey: '',
    },
    criteriaType: '',
    validation: {},
    saveButtonPressed: true,
    ...defaultModalKeys,
};

export default function staffCertificationAssessmentCriteriaAddReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'STAFF_CERT_ASSESS_CRITERIA_SET_TYPE':
            newState.criteriaType = action.criteriaType;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS:
            newState.sections = action.response.data;
            newState.fields = Object.assign({}, state.fields);
            if (!state.fields.id) newState.fields.criteriaSectionId = action.response.data[0].id;
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = '/staff/certification/assessment/requirement';
            newState.modalOnlyOneButton = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            if (action.error.data != null && action.error.data.sqlMessage != null) {
                newState.modalBody = newState.modalBody + ' (' + action.error.data.sqlMessage + ')';
            }
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = '/staff/certification/assessment/requirement';
            newState.modalOnlyOneButton = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS:
            newState.fields = Object.assign({}, defaultState.fields, action.response.data);
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE:
            const fkeys = Object.keys(state.fields);
            newState.validation = Object.assign({}, state.validation);
            for (let i = 0; i < fkeys.length; i += 1) {
                newState.validation[fkeys[i]] = validationlib.validate(fkeys[i], state.fields[fkeys[i]]);
            }
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD:
            newState.fields = Object.assign({}, state.fields);
            newState.fields[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            newState.validation[action.key] = validationlib.validate(action.key, action.value);
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING:
            let ratings = state.fields.ratings;
            ratings[action.ratingIndex] = action.rating;
            newState.fields = Object.assign({}, state.fields);
            newState.fields.ratings = ratings;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING:
            const cr = state.fields.ratings;
            const newRatingsArray = cr.slice(0, action.index).concat(cr.slice(action.index + 1, cr.length));
            newState.fields = Object.assign({}, state.fields);
            newState.fields.ratings = newRatingsArray;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING:
            newState.fields = Object.assign({}, state.fields);
            const ratingObj = {
                code: state.fields.ratingCode,
                description: state.fields.ratingDescription,
                sortkey: state.fields.ratingSortkey,
            };

            newState.fields.code = '';
            newState.fields.description = '';
            newState.fields.sortkey = '';

            newState.fields.ratings.push(ratingObj);
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS:
            newState.fields = Object.assign({}, defaultState.fields);
            newState.modalBody = null;
            newState.modalAction = null;
            newState.modalOnlyOneButton = false;
            newState.modalLoading = false;

            let ratingsToAdd = [];
            if (newState.criteriaType === 'product') {
                ratingsToAdd.push(
                    {
                        code: '1 Tick',
                        description: '',
                    },
                    {
                        code: '2 Ticks',
                        description: '',
                    },
                    {
                        code: '3 Ticks',
                        description: '',
                    },
                    {
                        code: '4 Ticks',
                        description: '',
                    },
                );
            } else if (newState.criteriaType === 'service') {
                ratingsToAdd.push(
                    {
                        code: 'L1',
                        description: '',
                    },
                    {
                        code: 'L2',
                        description: '',
                    },
                    {
                        code: 'L3',
                        description: '',
                    },
                    {
                        code: 'L4',
                        description: '',
                    },
                );
            }

            newState.fields.ratings = ratingsToAdd;

            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return newState;
    }
}
