import React from 'react';
import { Segment, Header, Icon, Dropdown, Button, List } from 'semantic-ui-react';

const Sidebar = ({ options, handleChange, onFilter, categories, onFetchServices, states: { ratingId, serviceCategoryId } }) => {
    if (!options || !options[0]) return 'Loading...';
    console.log('Sidebar', serviceCategoryId);

    return (
        <Segment basic>
            <Header as="h4" content="Category" />
            <List bulleted link relaxed="very">
                {categories &&
                    categories.map(item => (
                        <List.Item
                            active={serviceCategoryId == item.id}
                            key={item.id}
                            onClick={() => onFetchServices(item.id, ratingId, item.category)}
                        >
                            {item.category}
                        </List.Item>
                    ))}
            </List>
        </Segment>
    );
};

export default Sidebar;
