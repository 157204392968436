// Get - event TICKETs
export const EVENT_GET_EVENT_TICKETS_REQUEST = 'EVENT_GET_EVENT_TICKETS_REQUEST';
export const EVENT_GET_EVENT_TICKETS_SUCCESS = 'EVENT_GET_EVENT_TICKETS_SUCCESS';
export const EVENT_GET_EVENT_TICKETS_FAILURE = 'EVENT_GET_EVENT_TICKETS_FAILURE';

// Save - event TICKETs
export const EVENT_SAVE_TICKET_REQUEST = 'EVENT_SAVE_TICKET_REQUEST';
export const EVENT_SAVE_TICKET_SUCCESS = 'EVENT_SAVE_TICKET_SUCCESS';
export const EVENT_SAVE_TICKET_FAILURE = 'EVENT_SAVE_TICKET_FAILURE';

// Update - event TICKETs
export const EVENT_UPDATE_TICKET_REQUEST = 'EVENT_UPDATE_TICKET_REQUEST';
export const EVENT_UPDATE_TICKET_SUCCESS = 'EVENT_UPDATE_TICKET_SUCCESS';
export const EVENT_UPDATE_TICKET_FAILURE = 'EVENT_UPDATE_TICKET_FAILURE';

// Add, save, and delete - Single TICKETs
export const EVENT_SAVE_NEW_EVENT_TICKET = 'EVENT_ADD_NEW_EVENT_TICKET';
export const EVENT_SAVE_SINGLE_EVENT_TICKET = 'EVENT_SAVE_SINGLE_EVENT_TICKET';
export const EVENT_DELETE_SINGLE_EVENT_TICKET = 'EVENT_DELETE_SINGLE_EVENT_TICKET';

// Set - event Id
export const EVENT_TICKET_SET_EVENT_ID = 'EVENT_TICKET_SET_EVENT_ID';

// Input fields action
export const EVENT_TICKET_CHANGE_FIELD_VALUE = 'EVENT_TICKET_CHANGE_FIELD_VALUE';

// Reset - all fields
export const EVENT_TICKET_RESET_FIELDS = 'EVENT_TICKET_RESET_FIELDS';

// Modal
export const EVENT_TICKET_SET_MODAL_DATA = 'EVENT_TICKET_SET_MODAL_DATA';

export const FETCH_MEAL_TYPE = 'FETCH_MEAL_TYPE';
export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_TABLE_COUNT = 'FETCH_TABLE_COUNT';

export const FETCH_TICKET_TYPE_REQUEST = 'FETCH_TICKET_TYPE_REQUEST';
export const FETCH_TICKET_TYPE_SUCCESS = 'FETCH_TICKET_TYPE_SUCCESS';
export const FETCH_TICKET_TYPE_FAILURE = 'FETCH_TICKET_TYPE_FAILURE';

export const FETCH_TABLE_NAME_REQUEST = 'FETCH_TABLE_NAME_REQUEST';
export const FETCH_TABLE_NAME_SUCCESS = 'FETCH_TABLE_NAME_SUCCESS';
export const FETCH_TABLE_NAME_FAILURE = 'FETCH_TABLE_NAME_FAILURE';

export const FETCH_TABLE_REGISTRANT_REQUEST = 'FETCH_TABLE_REGISTRANT_REQUEST';
export const FETCH_TABLE_REGISTRANT_SUCCESS = 'FETCH_TABLE_REGISTRANT_SUCCESS';
export const FETCH_TABLE_REGISTRANT_FAILURE = 'FETCH_TABLE_REGISTRANT_FAILURE';
