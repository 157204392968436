import React from 'react';
import { Container, Row, Col, FormGroup, Button, Label, Alert } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { cyderForgotPasswordAction } from '../../actions/security/cyderSecurityAction';

const errorStyle = {
    color: 'red',
};

class ResetPassword extends React.Component {
    state = { submitted: false };

    handleSubmitFalse = () => this.setState({ submitted: false });

    render() {
        const { message, error } = this.props;
        const invalid = 'Invalid email';

        if (this.state.submitted) {
            return (
                <Container className="reset-password">
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <h3>{message ? 'Your password has been reset' : "There's something with the input"}</h3>
                            <p>{message ? message : error}</p>
                            {message ? (
                                <Button className="primary-btn-style" onClick={() => this.props.history.push('/')}>
                                    got it
                                </Button>
                            ) : (
                                <Button color="success" onClick={this.handleSubmitFalse}>
                                    Back
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Container className="reset-password">
                <Row form>
                    <Col md={{ size: 6, offset: 3 }}>
                        <Formik
                            initialValues={{ emailaddress: '' }}
                            onSubmit={(values, actions) => {
                                this.props.cyderForgotPasswordAction(values.emailaddress).then(() => {
                                    actions.setSubmitting(false);
                                    this.setState({ submitted: true });
                                });
                            }}
                            validate={validate}
                            render={({ errors, touched, isSubmitting }) => (
                                <Form className={isSubmitting ? 'loading' : ''}>
                                    <FormGroup>
                                        <Label>Email address</Label>
                                        <Field
                                            name="emailaddress"
                                            type="email"
                                            className="form-control"
                                            placeholder="Please enter your email address"
                                        />
                                        {errors.emailaddress && touched.emailaddress && (
                                            <span style={errorStyle}>{errors.emailaddress}</span>
                                        )}
                                    </FormGroup>
                                    {message && <Alert color="warning">{message}</Alert>}
                                    <Button type="submit" disabled={isSubmitting} className="primary-btn-style">
                                        {' '}
                                        submit{' '}
                                    </Button>
                                    <Button color="link" onClick={this.props.history.goBack}>
                                        {' '}
                                        Cancel{' '}
                                    </Button>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapState = ({ forgotPasswordReducer }) => ({
    message: forgotPasswordReducer.message,
    error: forgotPasswordReducer.error,
});

const validate = values => {
    let errors = {};

    if (!values.emailaddress) {
        errors.emailaddress = 'You have to enter your email address';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailaddress)) {
        errors.emailaddress = 'This is not a valid email address';
    }

    return errors;
};

export default connect(
    mapState,
    { cyderForgotPasswordAction },
)(ResetPassword);
