import React from 'react';
import { PreviewPanelWrapper } from './PreviewPanel1';
import { Col } from 'reactstrap';

const PreviewPanel7 = ({ textColor, membershipApplicationPendingPayment }) => (
    <PreviewPanelWrapper textColor={textColor} icon="fa-users">
        <Col sm={7}>
            <h4 className="pt-3">
                Members Applications
                <br />
                Pending Payment
            </h4>
        </Col>
        <Col sm={5} className="text-right">
            <h1 className="display-4 text-bold">{membershipApplicationPendingPayment}</h1>
        </Col>
    </PreviewPanelWrapper>
);

export default PreviewPanel7;
