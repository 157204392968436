import {
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST,
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS,
    EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE,
    EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST,
    EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS,
    EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE,
    EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST,
    EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE,
    EVENT_REGISTRATION_RESET_TO_DEFAULT,
    EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsRegistration';

import moment from 'moment';
import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';

// defaults
var defaultState = {
    loading: false,
    tablePages: -1,
    allRegistrationList: [],
    registrationList: [],
    keyword: '',
    data: {
        total: 0,
        paid: 0,
        notPaid: 0,
    },
};

export function eventsRegistrationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case EVENT_REGISTRATION_GET_REGISTRATION_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_REGISTRATION_GET_REGISTRATION_LIST_SUCCESS:
            newState.registrationList = action.response.data;
            // Map data to displayed format
            newState.registrationList = action.response.data.map(registration => {
                // Map date
                registration.createdOn = moment(registration.createdOn).format('D MMM YYYY HH:mm');
                return registration;
            });
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.loading = false;
            return newState;
        case EVENT_REGISTRATION_GET_REGISTRATION_LIST_FAILURE:
            newState = Object.assign({}, state);
            newState.loading = false;
            return newState;
        case EVENT_REGISTRATION_GET_ALL_REGISTRATION_LIST:
            newState.allRegistrationList = action.response.data;
            // Map data to displayed format
            newState.allRegistrationList = action.response.data.map(registration => {
                // Calculate paid
                if (registration.paymentStatus === 1) {
                    newState.data.paid++;
                }
                return registration;
            });
            // Data Calculation
            newState.data.total = newState.allRegistrationList.length;
            newState.data.notPaid = newState.data.total - newState.data.paid;
            return newState;
        case EVENT_REGISTRATION_CONFIRM_UNCONFIRM_REGISTRATION_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_REGISTRATION_CONFIRM_REGISTRATION_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalBody = 'Attendee registration successfully confirmed.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_CONFIRM_REGISTRATION_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to confirm attendee.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalBody = 'Attendee registration successfully unconfirmed.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_UNCONFIRM_REGISTRATION_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to unconfirm attendee.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_DELETE_REGISTRATION_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalBody = 'Registration successfully deleted.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_DELETE_REGISTRATION_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to delete registration.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_REGISTRATION_HANDLE_EVENT_INPUT_CHANGE:
            newState.keyword = action.value;
            return newState;
        case EVENT_REGISTRATION_RESET_TO_DEFAULT:
            newState.data = Object.assign({}, defaultState.data);
            return newState;
        case EVENT_REGISTRATION_SET_DIALOG_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return newState;
    }
}
