import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getOrganisationPrimaryBusinessAnalytic } from 'actions/analytics/memberAnalyticsAction';
import CyderHorizontalBar from 'pages/analytics/components/charts/CyderHorizontalBar';

class OrgPrimaryBusinessActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.load().then(res => {
            let labels = [];
            let data = [];
            let total = 0;
            res.data.forEach(element => {
                if (element.mainCategory != null) {
                    labels.push(element.mainCategory);
                    data.push(element.count);
                    total += element.count;
                }
            });
            this.setState({
                labels,
                total,
                title: `Organisation Primary Business Activity of active members (${total})`,
                data,
            });
        });
    }
    render() {
        return (
            <AnalyticsCard title={"Member's Business Activity Analysis"}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CyderHorizontalBar {...this.state} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getOrganisationPrimaryBusinessAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(OrgPrimaryBusinessActivity));
