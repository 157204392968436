import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Transition, animated, config } from 'react-spring';
import AffiliatesPanel from './components/AffiliatesPanel';
import { capitalizeFirstLetter } from 'js/util';
import { Button, Container, Col, Row, Card, CardImg, CardBody } from 'reactstrap';
import TopNavigation2 from '../../layouts/TopNavigation2';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';

import { memberHomePanelEventGetInfo, memberHomeSetLoadingState } from '../../actions/member/home/memberHomeAction';

class DirectoryPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleToggle = this.handleToggle.bind(this);
        this.state = { value: 0, toggle: {}, cards: [] };
    }
    componentDidMount() {
        if (this.props.match.params.dtype === 'products') {
            this.setState({
                cards: products,
            });
        } else {
            this.setState({
                cards: services,
            });
        }
    }
    handleToggle(i, state) {
        let toggle = Object.assign(this.state.toggle, {});
        if (state) {
            toggle[i] = state;
        } else {
            toggle[i] = !this.state.toggle;
        }
        this.setState({ toggle });
    }
    render() {
        return this.props.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <AboutPanel />
                <CardsPanel
                    statevalue={this.state.value}
                    handleToggle={this.handleToggle}
                    toggle={this.state.toggle}
                    cards={this.state.cards}
                    dtype={this.props.match.params.dtype}
                />
                <AffiliatesPanel />
            </div>
        );
    }
}

const urlPrefix = '/public/directory';
// replace this with list of product categories
const products = [
    {
        category: 'Architectural',
        count: 750,
        url: '1',
        imageurl: `/assets/images/cert_prod_archi1.png`,
    },
    {
        category: 'Civil & Structural',
        count: 150,
        url: '2',
        imageurl: `/assets/images/cert_prod_civil1.png`,
    },
    {
        category: 'Mechanical',
        count: 450,
        url: '3',
        imageurl: `/assets/images/cert_prod_mechanical1.png`,
    },
    {
        category: 'Electrical',
        count: 450,
        url: '4',
        imageurl: `/assets/images/cert_prod_mechanical1.png`,
    },
    {
        category: 'Smart',
        count: 450,
        url: '5',
        imageurl: `/assets/images/cert_prod_smart1.png`,
    },
    {
        category: 'Mechanical',
        count: 450,
        url: '6',
        imageurl: `/assets/images/cert_prod_mechanical2.png`,
    },
];

const services = [
    {
        category: 'Architecture',
        count: 750,
        url: '1',
        imageurl: `/assets/images/cert_prod_archi1.png`,
    },
    {
        category: 'Mechanical & Electrical',
        count: 150,
        url: '2',
        imageurl: `/assets/images/cert_prod_civil1.png`,
    },
    {
        category: 'Quantity Surveying',
        count: 450,
        url: '3',
        imageurl: `/assets/images/cert_prod_mechanical1.png`,
    },
    {
        category: 'Environment Sustainability Design',
        count: 450,
        url: '4',
        imageurl: `/assets/images/cert_prod_mechanical1.png`,
    },
    {
        category: 'Green Facilities Management',
        count: 450,
        url: '5',
        imageurl: `/assets/images/cert_prod_smart1.png`,
    },
    {
        category: 'Energy Performance Contracting',
        count: 450,
        url: '6',
        imageurl: `/assets/images/cert_prod_mechanical2.png`,
    },
];
const CardsPanel = ({ toggle, handleToggle, cards, dtype }) => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            {cards.map((c, i) => (
                <Col key={i} xs={12} md={4} className="mb-2">
                    <Card className="text-center">
                        <div
                            onMouseLeave={() => handleToggle(i, true)}
                            style={{
                                height: '300px',
                                width: '100%',
                                clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)',
                            }}
                        >
                            <div
                                className="d-flex flex-row justify-content-center"
                                style={{
                                    position: 'absolute',
                                    height: '300px',
                                    width: '100%',
                                }}
                            >
                                <div className="d-flex flex-column justify-content-center">
                                    <Link to={`${urlPrefix}/${dtype}/${c.url}`}>
                                        <Button className="border-light btn-newtheme">View Directory</Button>
                                    </Link>
                                </div>
                            </div>
                            <Transition
                                native
                                config={config.stiff}
                                from={{
                                    // opacity: 0,
                                    transform: 'translate3d(100%,0,0)',
                                }}
                                enter={{
                                    // opacity: 1,
                                    transform: 'translate3d(0%,0,0)',
                                }}
                                leave={{
                                    // opacity: 0,
                                    transform: 'translate3d(100%,0,0)',
                                }}
                            >
                                {toggle[i] !== false &&
                                    (styles => (
                                        <animated.div
                                            style={Object.assign(styles, {
                                                width: '100%',
                                                height: '300px',
                                                position: 'absolute',
                                            })}
                                            onMouseOver={() => handleToggle(i, false)}
                                        >
                                            <CardImg
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                top
                                                src={c.imageurl}
                                                alt="Card image cap"
                                            />
                                        </animated.div>
                                    ))}
                            </Transition>
                        </div>
                        <CardBody>
                            <p>{c.category}</p>
                            <p className="text-info">{`${c.count} Certified ${capitalizeFirstLetter(dtype)}`}</p>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
);
const AboutPanel = () => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            <Col xs={12} sm={12} className="text-center">
                <h2>SGBC Directory</h2>
            </Col>
        </Row>
    </Container>
);

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        const getData = [dispatch(memberHomePanelEventGetInfo())];
        dispatch(memberHomeSetLoadingState(true)).then(() => {
            Promise.all(getData).then(() => {
                dispatch(memberHomeSetLoadingState(false));
            });
        });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(DirectoryPage));
