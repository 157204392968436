// Country Search
export const COMPANY_SEARCH_REQUEST = 'COMPANY_SEARCH_REQUEST';
export const COMPANY_SEARCH_SUCCESS = 'COMPANY_SEARCH_SUCCESS';
export const COMPANY_SEARCH_FAILURE = 'COMPANY_SEARCH_FAILURE';
export const COMPANY_KEYWORD_SEARCH_REQUEST = 'COMPANY_KEYWORD_SEARCH_REQUEST';
export const COMPANY_KEYWORD_SEARCH_SUCCESS = 'COMPANY_KEYWORD_SEARCH_SUCCESS';
export const COMPANY_KEYWORD_SEARCH_FAILURE = 'COMPANY_KEYWORD_SEARCH_FAILURE';
export const COMPANY_TOGGLE_CHECKBOX = 'COMPANY_TOGGLE_CHECKBOX';
export const COMPANY_TOGGLE_CHECKBOX_ALL = 'COMPANY_TOGGLE_CHECKBOX_ALL';

export const COMPANY_CHANGE_INPUT_KEYWORD_VALUE = 'COMPANY_CHANGE_INPUT_KEYWORD_VALUE ';

// Country Details / Country Add
export const COMPANY_ADD_GET_DETAIL_REQUEST = 'COMPANY_ADD_GET_DETAIL_REQUEST';
export const COMPANY_ADD_GET_DETAIL_SUCCESS = 'COMPANY_ADD_GET_DETAIL_SUCCESS';
export const COMPANY_ADD_GET_DETAIL_FAILURE = 'COMPANY_ADD_GET_DETAIL_FAILURE';

export const COMPANY_ADD_ADD_COMPANY_REQUEST = 'COMPANY_ADD_ADD_COMPANY_REQUEST';
export const COMPANY_ADD_ADD_COMPANY_SUCCESS = 'COMPANY_ADD_ADD_COMPANY_SUCCESS';
export const COMPANY_ADD_ADD_COMPANY_FAILURE = 'COMPANY_ADD_ADD_COMPANY_FAILURE';

export const COMPANY_ADD_SET_FIELDS = 'COMPANY_ADD_SET_FIELDS';

export const COMPANY_ADD_CHANGE_FIELD_VALUE = 'COMPANY_ADD_CHANGE_FIELD_VALUE ';
