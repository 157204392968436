import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Button, Row, Col, Card, CardHeader, CardBody, CardTitle, FormGroup, FormText, Label, Input } from 'reactstrap';
import DialogModal from '../../../cyder/modals/DialogModal';
import SecuringWrapper from 'cyder/common/SecuringWrapper';
import InputFieldWithValidation from 'reactstrap-input';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import FileUploadPanel from '../../member/certification/applications/shared/FileUploadPanel';
import { WrapperComponent } from '../../staff/events/events/EventsAttendeePage';
import MandatoryStar from 'cyder/forms/MandatoryStar';

import {
    memberMembershipApplicationGetCompanyInfo,
    memberMembershipApplicationGetCountries,
    memeberMembershipApplicationGetBusinessActivity,
    memberMembershipApplicationUpdateCompanyProfile,
    memberMembershipApplicationUploadAttachment,
    memberMembershipApplicationDownloadAttachment,
    memberMembershipApplicationRemoveAttachment,
    memberMembershipApplicationGetCategories,
    memberMembershipApplicationHandleInputChange,
    memberMembershipApplicationResetFields,
    memeberMembershipApplicationSetModalData,
    memeberMembershipApplicationCopyAddresses,
    memberMembershipApplicationSetLoading,
    memberMembershipApplicationSetMemberProfileMode,
    memberMembershipApplicationSetLoginFrom,
    memberMembershipApplicationSetMembershipStatus,
    staffCompanyDetailsUpdateCompanyProfile,
    staffCompanyDetailsGetCompanyInfo,
} from '../../../actions/member/company/memberCompanyAction';
import { memberHomePanelMembershipApplicationGetInfo } from 'actions/member/home/memberHomeAction';
import { dialogModalToggle } from '../../../actions/modal/dialogModalAction';

import { blobDownload, parseContentType } from '../../../js/util';

const axios = require('axios').default;
const FormData = require('form-data');
const SideButton = props => (
    <div style={{ height: '100%' }} className="ml-auto">
        <Button
            color={props.color || null}
            onClick={props.onClick}
            className={props.className}
            style={{ ...(props.buttonStyle || null), color: 'white' }}
        >
            {props.iconName ? <i className={props.iconName} /> : null}
            <strong>{props.label}</strong>
        </Button>
    </div>
);
class MemberProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.saveCompanyInfo = this.saveCompanyInfo.bind(this);
        this.handleImageInputChange = this.handleImageInputChange.bind(this);
        this.removeDocumentFile = this.removeDocumentFile.bind(this);
        this.downloadDocumentFile = this.downloadDocumentFile.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.changeEditMode = this.changeEditMode.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.state = {
            isEditMode: false,
            filename: '',
            virusMessage: '',
            scanning: false,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        Promise.all([this.props.resetFields(), this.props.getMembershipInfo()]).then(res => {
            // Indentify member or staff view
            const companyId = this.props.match.params.companyId;
            const isMember = companyId ? false : true;
            this.props.setMemberProfileMode(isMember, companyId);

            let loginFrom;
            if (isMember) {
                // Determine from which public menu does new user create account from
                loginFrom = localStorage.getItem('loginFrom');
                this.props.setLoginFrom(loginFrom);
                // Reset loginFrom so that subsequent login will required to go through read mode
                localStorage.setItem('loginFrom', '');

                // Set membership application status
                const { applicationStatus } = res[1].data;
                this.props.setMembershipStatus(applicationStatus);
            }

            // Go straight to edit on staff view
            this.setState({
                isEditMode: companyId || loginFrom ? true : false,
            });
            this.getRelevantStuffs();
        });
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    getRelevantStuffs() {
        Promise.all([
            this.props.setLoading(true),
            this.props.getCategories(),
            this.props.getCountries(),
            this.props.getBusinessActivities(),
        ])
            .then(() => {
                this.props.member.isMember
                    ? this.props.memberGetCompanyInfo()
                    : this.props.staffGetCompanyInfo(this.props.match.params.companyId);
            })
            .then(() => this.props.setLoading(false));
    }
    saveCompanyInfo() {
        this.setState({
            isEditMode: false,
        });
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.saveCompanyProfile(this.props.member.isMember),
            modalBody: 'Click OK to save changes.',
        });
    }
    showModal = (header, body, action, modalOnlyOneButton) => {
        const { dialogModalToggle, setEventModalData } = this.props;
        dialogModalToggle();
        setEventModalData({
            modalHeader: header,
            modalAction: action,
            modalBody: body,
            modalOnlyOneButton,
        });
    };
    handleInputChange = e => {
        const { value, id } = e.target;
        var isValid = false;
        switch (id) {
            case 'mainPhoneNumber':
                const isNumeric = /^\d+$/;
                isValid = isNumeric.test(value);
                if (!isValid && value.length > 1) {
                    this.showModal('Error', 'Please provide valid contact number.', null, true);
                    return;
                }
                break;
        }
        this.props.handleInputChange(e);
    };
    handleImageInputChange(event) {
        this.setState({
            scanning: true,
        });
        const reader = new FileReader();
        const file = event.target.files[0];

        const files = Array.from(event.target.files);

        // Virus Scanning
        const uploaders = files.map(x => {
            const formData = new FormData();
            formData.append('file', x);

            return axios.post('https://av.sgbc.online/api/av', formData, {
                headers: { 'Content-Type': 'multipart/form-dta' },
            });
        });

        console.log('Scanning files before uploading.');

        axios
            .all(uploaders)
            .then(res => {
                this.setState({
                    scanning: false,
                });
                console.log('Files are all clean.');
                this.setState({
                    virusMessage: '',
                });
                reader.onload = () => {
                    const b64string = reader.result.substring(reader.result.indexOf(',') + 1);
                    this.props.saveDocumentFile(file.type, file.name, b64string);
                };
                // Handle cancel
                if (file.length !== 0) {
                    reader.readAsDataURL(file);
                    this.setState({ filename: file.name });
                }
            })
            .catch(err => {
                const { viruses } = err.response.data;
                this.setState({
                    virusMessage: viruses,
                    scanning: false,
                });
            });
    }
    downloadDocumentFile() {
        this.props.downloadDocumentFile().then(res => {
            const contentType = parseContentType(res.data.contenttype);
            const str = res.data.attachment;
            blobDownload(res.data.filename, contentType, str);
        });
    }
    removeDocumentFile() {
        this.setState({ filename: '' });
        this.props.removeDocumentFileAction();
    }
    cancelEdit() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => {
                this.props.dialogModalToggle();
                this.getRelevantStuffs();
                this.changeEditMode(false);
            },
            modalBody: 'Click OK to discard your changes.',
        });
    }
    changeEditMode(isEditMode) {
        this.setState({
            isEditMode,
        });
    }
    getLabel = title => {
        return (
            <strong>
                {title} <MandatoryStar bold />
            </strong>
        );
    };
    render() {
        return (
            <WrapperComponent mode={this.props.match.params.companyId ? 'staff' : 'member'}>
                {this.props.member.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <React.Fragment>
                        <DialogModal
                            modalAction={this.props.member.modalAction}
                            modalBody={this.props.member.modalBody}
                            modalHeader={this.props.member.modalHeader}
                            modalOnlyOneButton={this.props.member.modalOnlyOneButton}
                            boundForceUpdate={this.boundForceUpdate}
                            loading={this.props.member.modalLoading}
                            buttonDisabler={this.props.member.modalLoading}
                        />
                        {!this.props.isMember ? null : (
                            <Row className="mb-2">
                                <Col xs={12}>
                                    <Link
                                        to={
                                            this.props.member.isMember ? 'dashboard' : '/staff/company/' + this.props.match.params.companyId
                                        }
                                    >
                                        <Button className="btn btn-default">
                                            <i className="material-icons align-text-bottom">chevron_left</i>
                                            &nbsp; Back
                                        </Button>
                                        &nbsp;
                                    </Link>
                                </Col>
                                <hr />
                            </Row>
                        )}
                        <Panel1
                            scanning={this.state.scanning}
                            virusMessage={this.state.virusMessage}
                            handleImageInputChange={this.handleImageInputChange}
                            downloadLogoFile={this.downloadDocumentFile}
                            removeDocumentFile={this.removeDocumentFile}
                            changeEditMode={this.changeEditMode}
                            cancelEdit={this.cancelEdit}
                            isEditMode={this.state.isEditMode}
                            fileInputRef={this.fileInputRef}
                            filename={this.state.filename}
                            organisationProfileOptions={this.state.organisationProfileOptions}
                            getLabel={this.getLabel}
                            {...this.props}
                            handleInputChange={this.handleInputChange}
                        />
                        <br />
                        <Panel2 isEditMode={this.state.isEditMode} getLabel={this.getLabel} {...this.props} />
                        <br />
                        <Panel3
                            isEditMode={this.state.isEditMode}
                            getLabel={this.getLabel}
                            saveCompanyInfo={this.saveCompanyInfo}
                            {...this.props}
                        />
                    </React.Fragment>
                )}
            </WrapperComponent>
        );
    }
}

const HeaderArea = props => {
    return (
        <CardHeader className="darkCardHeader">
            <CardTitle className="d-flex align-middle mb-0">
                <div className="mt-auto mb-auto align-middle">
                    {props.icon ? <i className={'fa ' + props.icon} /> : null}
                    &nbsp;
                    {props.headerText}
                </div>
                {props.children}
            </CardTitle>
        </CardHeader>
    );
};

const Panel1 = props => {
    const disabled = props.isEditMode ? false : true;
    const { getLabel, virusMessage, scanning } = props;
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <Row>
                    <Col className="mb-3">
                        <div>
                            The information you provide below will be visible on SGBC’s public directories once membership/ certification’s
                            certificate has been issued.
                        </div>
                        <div>
                            All fields marked with an asterisk (
                            <MandatoryStar bold />) are required.
                        </div>
                    </Col>
                </Row>
                <Card>
                    <HeaderArea headerText="Account Information">
                        {!props.isEditMode ? (
                            <SecuringWrapper isCorporateAdmin isOmr isSecondarymr isStaff>
                                <SideButton label="Edit" className="primary-btn-style" onClick={() => props.changeEditMode(true)} />
                            </SecuringWrapper>
                        ) : (
                            <SideButton label="Cancel" className="primary-btn-style" onClick={() => props.cancelEdit()} />
                        )}
                    </HeaderArea>
                    <CardBody>
                        <br />
                        <div>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>
                                            <strong>Name of Organisation:</strong>
                                        </Label>
                                        <br />
                                        {props.member.companyInfo.name}
                                        <FormText muted>Please contact SGBC to edit this field.</FormText>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            <strong>UEN / Company Registration Number:</strong>
                                        </Label>
                                        <br />
                                        {props.member.companyInfo.registrationNumber}
                                        <FormText muted>Please contact SGBC to edit this field.</FormText>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label="Website:"
                                            valueKey="website"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.website}
                                            handleChange={props.handleInputChange}
                                            inputProps={{ disabled }}
                                        />
                                    </FormGroup>
                                    <FormGroup className="pt-2">
                                        <Label>{getLabel('Organisation Profile')}</Label>
                                        <Input
                                            type="select"
                                            id="organisationProfile"
                                            name="organisationProfile"
                                            value={props.member.companyInfo.organisationProfile}
                                            onChange={props.handleInputChange}
                                            disabled={disabled}
                                        >
                                            <option value="">Please Select</option>
                                            <option value="singaporeGrown">
                                                Singapore Owned Company ( >30% shares owned by Singapore)
                                            </option>
                                            <option value="foreignCompanyBased">Foreign Company - Presence/Based in Singapore</option>
                                            <option value="foreignCompany">Foreign Company</option>
                                            <option value="professionalBodies">Professional Bodies, Trade Association and Chamber</option>
                                            <option value="researchInstitution">Research and Tertiary Institution</option>
                                            <option value="governmentAgency">Government Agency</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label>{getLabel('Country')}</Label>
                                        <Input
                                            type="select"
                                            id="country"
                                            onChange={props.handleInputChange}
                                            value={props.member.companyInfo.countryId}
                                            disabled={disabled}
                                        >
                                            {props.member.form.countries.map(country => {
                                                return (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label="Contact number:"
                                            label={getLabel('Contact Number')}
                                            valueKey="mainPhoneNumber"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.mainPhoneNumber}
                                            handleChange={props.handleInputChange}
                                            inputProps={{ disabled }}
                                        />
                                    </FormGroup>
                                    <FormGroup className="p-1">
                                        <InputFieldWithValidation
                                            type="text"
                                            label={getLabel('General Email')}
                                            valueKey="generalEmail"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.generalEmail}
                                            handleChange={props.handleInputChange}
                                            inputProps={{ disabled }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{getLabel('SME Company')}</Label>
                                        <Input
                                            type="select"
                                            id="isSme"
                                            name="isSme"
                                            value={props.member.companyInfo.isSme}
                                            onChange={props.handleInputChange}
                                            disabled={disabled}
                                        >
                                            <option value="">Please Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <SecuringWrapper isCorporateAdmin isOmr isSecondarymr isStaff>
                                    <Col xs={12}>
                                        <FileUploadPanel
                                            scanning={scanning}
                                            virusMessage={virusMessage}
                                            type="Company Logo"
                                            id="logo"
                                            editable={!disabled}
                                            filename={props.filename}
                                            uploadedFileId={props.member.companyInfo.logoLocation}
                                            savingFile={props.member.file.savingLogo}
                                            savedFile={props.member.file.savedLogo}
                                            downloadingFile={props.member.file.downloadingLogo}
                                            handleInputChange={props.handleImageInputChange}
                                            removeFileAction={props.removeDocumentFile}
                                            downloadFileAction={props.downloadLogoFile}
                                            fileInputRef={props.fileInputRef}
                                        />
                                    </Col>
                                </SecuringWrapper>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const Panel2 = props => {
    const disabled = props.isEditMode ? false : true;
    const { getLabel } = props;
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <Card>
                    <HeaderArea headerText="Address" />
                    <CardBody>
                        <div>
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={getLabel('Business Address Line 1')}
                                            valueKey="businessAddressLine1"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.businessAddressLine1}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={'Business Address Line 2'}
                                            valueKey="businessAddressLine2"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.businessAddressLine2}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label="Business Address Line 3:"
                                            valueKey="businessAddressLine3"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.businessAddressLine3}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={getLabel('Business Postal Code')}
                                            valueKey="businessPostalCode"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.businessPostalCode}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormText muted>Please do not include Country in the business address.</FormText>
                                </Col>
                                <Col md={6} lg={6}>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={getLabel('Mailing Address Line 1')}
                                            valueKey="mailingAddressLine1"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.mailingAddressLine1}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={'Mailing Address Line 2'}
                                            valueKey="mailingAddressLine2"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.mailingAddressLine2}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label="Mailing Address Line 3:"
                                            valueKey="mailingAddressLine3"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.mailingAddressLine3}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <InputFieldWithValidation
                                            type="text"
                                            label={getLabel('Mailing Postal Code')}
                                            valueKey="mailingPostalCode"
                                            labelStyle={{ fontWeight: 'bold' }}
                                            validationObj={{}}
                                            value={props.member.companyInfo.mailingPostalCode}
                                            handleChange={props.handleInputChange}
                                            inputProps={{
                                                disabled,
                                            }}
                                        />
                                    </FormGroup>
                                    <FormText muted>Please do not include Country in the mailing address.</FormText>
                                </Col>
                            </Row>
                            {disabled ? null : (
                                <SecuringWrapper isCorporateAdmin isCorporateAdmin isOmr isSecondarymr isStaff>
                                    <Row>
                                        <Col className="d-flex justify-content-end" xs={12}>
                                            <Button onClick={props.copyAddresses} className="primary-btn-style">
                                                Click here if same as business address
                                            </Button>
                                        </Col>
                                    </Row>
                                </SecuringWrapper>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const Panel3 = props => {
    const disabled = props.isEditMode ? false : true;
    const { getLabel } = props;
    return (
        <Row className="mb-2">
            <Col xs={12}>
                <Card>
                    <HeaderArea headerText="Corporate Information" />
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>{getLabel('Organisation Primary Business Activity')}</Label>
                                    <Input
                                        type="select"
                                        id="organisationMainCategory"
                                        onChange={props.handleInputChange}
                                        value={props.member.companyInfo.organisationMainCategoryId}
                                        disabled={disabled}
                                    >
                                        <option value="">Please Select</option>
                                        {props.member.form.categories.map(category => {
                                            return (
                                                <option key={category.mainCategory.id} value={category.mainCategory.id}>
                                                    {category.mainCategory.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{getLabel('Services offered by organisation (you may select more than one)')}</Label>
                                </FormGroup>
                                <FormGroup>
                                    <CategoriesCheckboxComponent
                                        data={props.member.form.categories}
                                        subCategory={props.member.companyInfo.subCategory}
                                        handleInputChange={props.handleInputChange}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} lg={3}>
                                <FormGroup>
                                    <Label className="text-bold">{getLabel('Annual Turnover ($)')}</Label>
                                    <Input
                                        id="annualTurnover"
                                        type="select"
                                        onChange={props.handleInputChange}
                                        value={props.member.companyInfo.annualTurnover}
                                        disabled={disabled}
                                    >
                                        <option value="">Please Select</option>
                                        <option value="1">$0 - $1M</option>
                                        <option value="2">$1M - $10M</option>
                                        <option value="3">$10M - $50M</option>
                                        <option value="4">$50M - $100M</option>
                                        <option value="5">$100M - $250M</option>
                                        <option value="6">$250M - $500M</option>
                                        <option value="7">$500M - $1B</option>
                                        <option value="8">Do not wish to disclose</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{getLabel('Number of Employees')}</Label>
                                    <Input
                                        id="numberOfEmployees"
                                        type="select"
                                        onChange={props.handleInputChange}
                                        value={props.member.companyInfo.numberOfEmployees}
                                        disabled={disabled}
                                    >
                                        <option value="">Please Select</option>
                                        <option value="1">Less than 10 employees</option>
                                        <option value="50">11 - 50 employees</option>
                                        <option value="200">51 - 200 employees</option>
                                        <option value="500">201 - 500 employees</option>
                                        <option value="1000">501 - 1,000 employees</option>
                                        <option value="5000">1,001 - 5,000 employees</option>
                                        <option value="10000">5,001 - 10,000 employees</option>
                                        <option value="10001">More than 10,000 employees</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} lg={6}>
                                <FormGroup>
                                    <InputFieldWithValidation
                                        type="textarea"
                                        cols="40"
                                        rows="10"
                                        label={getLabel('Type of Products or Services')}
                                        valueKey="productsServicesDescription"
                                        labelStyle={{ fontWeight: 'bold' }}
                                        validationObj={{}}
                                        value={props.member.companyInfo.productsServicesDescription}
                                        handleChange={props.handleInputChange}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <InputFieldWithValidation
                                        type="textarea"
                                        cols="40"
                                        rows="10"
                                        label={getLabel(
                                            'Describe the ways in which your organisation is or will be involved in the green building movement',
                                        )}
                                        valueKey="greenBuildingMovementInvolvement"
                                        labelStyle={{ fontWeight: 'bold' }}
                                        validationObj={{}}
                                        handleChange={props.handleInputChange}
                                        value={props.member.companyInfo.greenBuildingMovementInvolvement}
                                        inputProps={{
                                            disabled,
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {disabled ? null : (
                            <SecuringWrapper isCorporateAdmin isCorporateAdmin isOmr isSecondarymr isStaff>
                                <Row>
                                    <Col xs={12} className="text-right">
                                        <Button onClick={props.saveCompanyInfo} disabled={!props.saveable} className="primary-btn-style">
                                            Save Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </SecuringWrapper>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

const CategoriesCheckboxComponent = props => {
    const data = props.data;
    const value = props.subCategory;
    if (data.length > 0) {
        return data.map(category => {
            return (
                <React.Fragment key={category.mainCategory.id}>
                    <FormText>
                        <strong>
                            <u>{category.mainCategory.name}</u>
                        </strong>
                    </FormText>
                    {category.subCategories.map(subCategory => {
                        return (
                            <FormGroup check key={subCategory.id}>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        id="subCategory"
                                        name={subCategory.name}
                                        disabled={props.disabled}
                                        checked={value[subCategory.id] ? value[subCategory.id].checked || false : false}
                                        onChange={event =>
                                            props.handleInputChange(
                                                event,
                                                subCategory.id,
                                                category.mainCategory.id,
                                                category.mainCategory.name,
                                            )
                                        }
                                    />
                                    {subCategory.name}
                                </Label>
                            </FormGroup>
                        );
                    })}
                </React.Fragment>
            );
        });
    } else {
        return null;
    }
};
const mapStateToProps = (state, ownProps) => {
    let saveable = false;
    console.log(state.memberCompanyReducer.companyInfo);
    if (
        state.memberCompanyReducer.companyInfo.mainPhoneNumber &&
        state.memberCompanyReducer.companyInfo.organisationProfile &&
        state.memberCompanyReducer.companyInfo.generalEmail &&
        state.memberCompanyReducer.companyInfo.isSme !== '' &&
        state.memberCompanyReducer.companyInfo.businessAddressLine1 &&
        state.memberCompanyReducer.companyInfo.businessPostalCode &&
        state.memberCompanyReducer.companyInfo.mailingAddressLine1 &&
        state.memberCompanyReducer.companyInfo.mailingPostalCode &&
        state.memberCompanyReducer.companyInfo.organisationMainCategoryId &&
        state.memberCompanyReducer.companyInfo.numberOfEmployees &&
        state.memberCompanyReducer.companyInfo.productsServicesDescription &&
        state.memberCompanyReducer.companyInfo.greenBuildingMovementInvolvement &&
        state.memberCompanyReducer.companyInfo.annualTurnover !== '' &&
        state.memberCompanyReducer.companyInfo.annualTurnover !== '0' &&
        state.memberCompanyReducer.companyInfo.annualTurnover !== 0
    ) {
        saveable = true;
    }
    return {
        member: state.memberCompanyReducer,
        memberHome: state.memberHomeReducer,
        saveable,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setLoading: load => {
            dispatch(memberMembershipApplicationSetLoading(load));
        },
        setLoginFrom: loginFrom => {
            dispatch(memberMembershipApplicationSetLoginFrom(loginFrom));
        },
        setMembershipStatus: status => {
            dispatch(memberMembershipApplicationSetMembershipStatus(status));
        },
        getCountries: () => {
            return dispatch(memberMembershipApplicationGetCountries());
        },
        getMembershipInfo: () => {
            return dispatch(memberHomePanelMembershipApplicationGetInfo());
        },
        getBusinessActivities: () => {
            return dispatch(memeberMembershipApplicationGetBusinessActivity());
        },
        getCategories: () => {
            return dispatch(memberMembershipApplicationGetCategories());
        },
        memberGetCompanyInfo: () => {
            dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        staffGetCompanyInfo: companyId => {
            dispatch(staffCompanyDetailsGetCompanyInfo(companyId));
        },
        saveCompanyProfile: isMember => {
            return isMember
                ? // Member - company profile
                  dispatch(memberMembershipApplicationUpdateCompanyProfile())
                : // Staff - company profile
                  dispatch(staffCompanyDetailsUpdateCompanyProfile());
        },
        saveDocumentFile: (contenttype, filename, attachment) => {
            dispatch(memberMembershipApplicationUploadAttachment(contenttype, filename, attachment));
        },
        removeDocumentFileAction: () => {
            dispatch(memberMembershipApplicationRemoveAttachment());
        },
        downloadDocumentFile: () => {
            return dispatch(memberMembershipApplicationDownloadAttachment());
        },
        handleInputChange: (event, ...args) => {
            dispatch(memberMembershipApplicationHandleInputChange(event, ...args));
        },
        resetFields: () => {
            return dispatch(memberMembershipApplicationResetFields());
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        setEventModalData: data => {
            dispatch(memeberMembershipApplicationSetModalData(data));
        },
        copyAddresses: () => {
            dispatch(memeberMembershipApplicationCopyAddresses());
        },
        setMemberProfileMode: (isMember, companyId) => {
            dispatch(memberMembershipApplicationSetMemberProfileMode(isMember, companyId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MemberProfilePage));
