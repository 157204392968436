/**
 * Take up case panel
 *
 * Author: Fernando
 */
import React from 'react';
import { connect } from 'react-redux';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

const selectState = {
    step: 0,
    disabled: false,
    searchable: true,
    selectValue: '',
    clearable: true,
};

const HeaderComponent = ({ label }) => (
    <Row style={{ paddingBottom: '10px' }}>
        <Col>
            <label className="field-header">{label}</label>
        </Col>
    </Row>
);

export class TakeUpCasePanel extends React.Component {
    constructor(props) {
        super(props);
        this.onUsePrevTemplateChange = this.onUsePrevTemplateChange.bind(this);
    }
    componentDidMount() {
        // This is to support renewal of certs with no application
        const isRenewed = this.props.applicationDetails.applicationTypeId === 2 || this.props.applicationDetails.applicationTypeId === 3;
        if (
            !isRenewed ||
            (isRenewed &&
                (!this.props.usePreviousAssessmentTemplate.value ||
                    this.props.applicationDetails.oldAssessmentId == null ||
                    this.props.applicationDetails.oldAssessmentId == ''))
        ) {
            this.props.attachPreviousTemplate(false);
        }
    }
    onUsePrevTemplateChange(e) {
        this.props.attachPreviousTemplate(e);
    }
    render() {
        const {
            previewTemplate,
            templates,
            selectedTemplate,
            onTemplateChange,
            takeUpCaseStarted,
            previewOldTemplate,
            applicationTypeNewRenew,
            prevAssessmentId,
            newTemplateChoosen,
            applicationDetails,
        } = this.props;

        const isRenewed = applicationDetails.applicationTypeId === 2 || applicationDetails.applicationTypeId === 3;
        const options =
            templates &&
            templates.data.map((template, i) => {
                return {
                    value: template.id,
                    label: template.title,
                };
            });

        return (
            <React.Fragment>
                {/* <Row>
                {!takeUpCaseStarted && applicationTypeNewRenew === 2 && prevAssessmentId && (
                    <React.Fragment>
                        &nbsp;
                        <Row style={{ paddingBottom: '10px' }}>
                            <Col>
                                <ButtonIcon
                                    flat
                                    onClick={previewOldTemplate}
                                    icon="format_list_bulleted"
                                    label="View & Attach Previous Assessment"
                                />
                                <br />
                                &nbsp; <HeaderComponent label="OR" />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Row> */}
                <Row>
                    <Col>
                        {!takeUpCaseStarted ? (
                            <React.Fragment>
                                {(!isRenewed ||
                                    (isRenewed &&
                                        (!this.props.usePreviousAssessmentTemplate.value ||
                                            applicationDetails.oldAssessmentId == null ||
                                            applicationDetails.oldAssessmentId == ''))) && (
                                    <React.Fragment>
                                        {' '}
                                        <label style={{ fontSize: '14px' }}>Please choose the assessment criteria</label>
                                        <Row style={{ paddingBottom: '10px' }}>
                                            <Col className="p-0" xs="4">
                                                <Select
                                                    autosize={false}
                                                    placeholder=""
                                                    autofocus
                                                    options={options}
                                                    simpleValue
                                                    clearable={selectState.clearable}
                                                    name="selected-state"
                                                    onChange={onTemplateChange}
                                                    value={{
                                                        label: newTemplateChoosen ? selectedTemplate.label : '',
                                                        value: newTemplateChoosen ? selectedTemplate.value : '',
                                                    }}
                                                    searchable={selectState.searchable}
                                                />
                                            </Col>
                                            <Col>
                                                <ButtonIcon
                                                    flat
                                                    onClick={previewTemplate}
                                                    icon="search"
                                                    label="Preview"
                                                    disabled={
                                                        isRenewed &&
                                                        applicationDetails.oldAssessmentId != null &&
                                                        applicationDetails.oldAssessmentId != '' &&
                                                        this.props.usePreviousAssessmentTemplate.value
                                                    }
                                                />
                                            </Col>
                                        </Row>{' '}
                                        <br />
                                    </React.Fragment>
                                )}

                                {isRenewed && applicationDetails.oldAssessmentId != null && applicationDetails.oldAssessmentId != '' && (
                                    <React.Fragment>
                                        <label style={{ fontSize: '14px' }}>Do you wish to use the previous assessment criteria?</label>
                                        <div style={{ width: '150px' }}>
                                            <Select
                                                autosize={false}
                                                placeholder=""
                                                autofocus
                                                options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                                                simpleValue
                                                clearable={true}
                                                name="selected-state"
                                                onChange={this.onUsePrevTemplateChange}
                                                disabled={false}
                                                value={this.props.usePreviousAssessmentTemplate}
                                                searchable={true}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <HeaderComponent label="Assessment Template" />
                                <Row style={{ paddingBottom: '10px' }}>
                                    <Col xs={4}>
                                        <label>{selectedTemplate.label}</label>
                                    </Col>
                                    <Col className="text-left">
                                        <ButtonIcon
                                            flat
                                            onClick={newTemplateChoosen ? previewTemplate : previewOldTemplate}
                                            icon="search"
                                            label="Preview"
                                        />
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )}
                        <Row style={{ paddingBottom: '10px', paddingTop: '10px' }}>
                            <Col>
                                <label>
                                    <i>(*) Applicant will be notified to start assessment once this application is accepted</i>
                                </label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        usePreviousAssessmentTemplate: state.staffCertificationApplicationReducer.usePreviousAssessmentTemplate,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        attachPreviousTemplate: usePreviousAssessmentTemplate => {
            return dispatch({
                type: 'ATTACH_PREVIOUS_TEMPLATE',
                usePreviousAssessmentTemplate,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TakeUpCasePanel);
