import React from 'react';
import { connect } from 'react-redux';
import StaffNavbar from '../../elements/navbar-staff';
import AdminNavbar from '../../elements/navbar-admin';
import MemberNavBar from '../../elements/navbar-member';
import NonMemberNavBar from '../../elements/navbar-nonmember';

class SGBCNavBar extends React.Component {
    render() {
        if (this.props.profile.user.is_staff === 1) {
            if (this.props.profile.adminMode) return <AdminNavbar />;
            return <StaffNavbar />;
        }
        if (this.props.profile.user.is_active === 1) return <MemberNavBar />;
        return <NonMemberNavBar />;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SGBCNavBar);
