/**
 * Member profile reducer
 *
 * Author: Fernando
 */
import { CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP } from '../../actions/constants/actionTypes';

// temp/fake member profil]
import ACCOUNT_INFO from '../../json/sgbc-temp-account-info.json';
import MEMBERSHIP_INFO from '../../json/sgbc-temp-membership-info.json';
import MEMBERSHIP_DOWNLOAD from '../../json/sgbc-temp-member-download.json';
import MEMBERSHIP_CERTIFICATES from '../../json/sgbc-temp-member-certificates.json';
import MEMBERSHIP_PAST_EVENTS from '../../json/sgbc-temp-member-past-event.json';
import MEMBERSHIP_PARTNER_EVENTS from '../../json/sgbc-temp-member-partner-event.json';
import MEMBERSHIP_REPORTS from '../../json/sgbc-temp-member-reports.json';
import MEMBERSHIP_UPCOMING_EVENTS from '../../json/sgbc-temp-member-upcoming-events.json';
import MEMBERSHIP_APPLICATIONS from '../../json/sgbc-temp-member-applications.json';
import MEMBER_SEARCH from '../../json/sgbc-temp-member-search.json';

var memberDefaultState = {
    accountInfo: ACCOUNT_INFO,
    membershipInfo: MEMBERSHIP_INFO,
    membershipDownload: MEMBERSHIP_DOWNLOAD,
    membershipCertificates: MEMBERSHIP_CERTIFICATES,
    membershipPastEvents: MEMBERSHIP_PAST_EVENTS,
    membershipPartnerEvents: MEMBERSHIP_PARTNER_EVENTS,
    membershipReports: MEMBERSHIP_REPORTS,
    membershipUpcomingEvents: MEMBERSHIP_UPCOMING_EVENTS,
    membershipApplications: MEMBERSHIP_APPLICATIONS,
    search: MEMBER_SEARCH,
};

export function memberReducer(state = memberDefaultState, action) {
    let newState = Object.assign({}, state);
    let memberStatus = 'new';
    switch (action.type) {
        case CYDER_LOGIN_SUCCESS_MOCK_MEMBERSHIP:
            switch (action.username) {
                case 'memberuser1@cyder.com.sg':
                    memberStatus = 'new';
                    break;
                case 'memberuser2@cyder.com.sg':
                    memberStatus = 'draft';
                    break;
                case 'memberuser3@cyder.com.sg':
                    memberStatus = 'submitted';
                    break;
                case 'memberuser4@cyder.com.sg':
                    memberStatus = 'approved';
                    break;
                case 'memberuser5@cyder.com.sg':
                    memberStatus = 'completed';
                    break;
                default:
                    memberStatus = 'approved';
                    break;
            }
            newState.membershipInfo = Object.assign(MEMBERSHIP_INFO, {
                membershipApplicationStatus: memberStatus,
            });
            return newState;
        default:
            return state;
    }
}
