import DefaultSidebar1 from 'layouts/DefaultSidebar1';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import CyderFileUpload from 'cyder/forms/CyderFileUpload';
import { connect } from 'react-redux';
import history from '../../../history';
import controlledInputLib from '../../../js/controlledInputLib';

// Updated to use DraftJS, due to IE-11 compability issue
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CyderRichText from 'cyder/richtext/CyderRichText';

import moment from 'moment';
import { FormGroup, Label, Row, Col, Form, Button, Input } from 'reactstrap';

import { save, update, remove, load } from 'actions/staff/election/action';

class ElectionsCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.onPublicUploadFail = this.onPublicUploadFail.bind(this);
        this.onPublicUploadSuccess = this.onPublicUploadSuccess.bind(this);

        this.onPrivateUploadFail = this.onPrivateUploadFail.bind(this);
        this.onPrivateUploadSuccess = this.onPrivateUploadSuccess.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.onVoteConfirmationChange = this.onVoteConfirmationChange.bind(this);
        this.onLoginInstructionChange = this.onLoginInstructionChange.bind(this);
        this.onElectionDescriptionChange = this.onElectionDescriptionChange.bind(this);
        this.onElectionSummaryChange = this.onElectionSummaryChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onUnlockDateChange = this.onUnlockDateChange.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onElectionNameChange = this.onElectionNameChange.bind(this);
        this.onPublicVacancyChange = this.onPublicVacancyChange.bind(this);
        this.onPrivateVacancyChange = this.onPrivateVacancyChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.state = {
            selectedOption: null,
            election: {
                publicVacancy: 8,
                privateVacancy: 8,
            },
            loading: false,
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    onPublicVacancyChange(e) {
        var election = this.state.election;
        election.publicVacancy = e.target.value;
        this.setState({
            election,
        });
    }
    onPrivateVacancyChange(e) {
        var election = this.state.election;
        election.privateVacancy = e.target.value;
        this.setState({
            election,
        });
    }
    onPublicUploadSuccess(file) {
        var election = this.state.election;
        election.compiledPublicNomineesCvAndManifestoPdf = file.id;
        election.compiledPublicNomineesCvAndManifestoPdfName = file.filename;
        this.setState({
            election,
        });
    }
    onPublicUploadFail(error) {}
    onPrivateUploadSuccess(file) {
        var election = this.state.election;
        election.compiledPrivateNomineesCvAndManifestoPdf = file.id;
        election.compiledPrivateNomineesCvAndManifestoPdfName = file.filename;
        this.setState({
            election,
        });
    }
    onPrivateUploadFail(error) {}
    componentDidMount() {
        var id = null;
        try {
            id = this.props.match.params.electionid;
        } catch (e) {}
        if (id != null) {
            this.showLoading(true);
            this.props
                .load(id)
                .then(res => {
                    var election = res.data;
                    election.electionName = election.name;
                    election.startDate = moment(election.startDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
                    election.endDate = moment(election.endDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
                    election.unlockDate = moment(election.unlockDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
                    election.electionSummary = election.summary ? election.summary : '';
                    election.electionDescription = election.description ? election.description : '';
                    election.loginInstruction = election.loginInstructions;
                    election.voteConfirmation = election.voteConfirmation;
                    election.publicVacancy = election.publicSectorVacancies;
                    election.privateVacancy = election.privateSectorVacancies;
                    this.setState({
                        election,
                    });
                    this.showLoading(false);
                })
                .catch(error => {
                    this.showLoading(false);
                });
        }
    }
    back() {
        history.replace('/staff/elections/all');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }

    onElectionNameChange(e) {
        var election = this.state.election;
        election.electionName = e.target.value;
        this.setState({
            election,
        });
    }
    onElectionSummaryChange(summary) {
        var election = this.state.election;
        election.electionSummary = summary;
        this.setState({ election });
    }
    onElectionDescriptionChange(description) {
        var election = this.state.election;
        election.electionDescription = description;
        this.setState({ election });
    }
    onLoginInstructionChange(e) {
        var election = this.state.election;
        election.loginInstruction = e.target.value;
        this.setState({
            election,
        });
    }
    onVoteConfirmationChange(e) {
        var election = this.state.election;
        election.voteConfirmation = e.target.value;
        this.setState({
            election,
        });
    }
    onStartDateChange(e) {
        var election = this.state.election;
        election.startDate = controlledInputLib.generateDateTimeSynthEvent(e, 'startDate', 'DD/MM/YYYY HH:mm').target.value;
        this.setState({
            election,
        });
    }
    onEndDateChange(e) {
        var election = this.state.election;
        election.endDate = controlledInputLib.generateDateTimeSynthEvent(e, 'endDate', 'DD/MM/YYYY HH:mm').target.value;
        this.setState({
            election,
        });
    }
    onUnlockDateChange(e) {
        var election = this.state.election;
        election.unlockDate = controlledInputLib.generateDateTimeSynthEvent(e, 'unlockDate', 'DD/MM/YYYY HH:mm').target.value;
        this.setState({
            election,
        });
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.startDate = moment(newData.startDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        newData.endDate = moment(newData.endDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        newData.unlockDate = moment(newData.unlockDate, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.election);
        console.log('Data', data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/staff/elections/all');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/staff/elections/all', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this election?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/staff/events/promocodes');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }

    handleChangeSummary = summary => {
        this.setState({ summary });
    };

    render() {
        const { electionSummary, electionDescription } = this.state.election;

        return (
            <div>
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div className="page-widget">
                        <DialogModal
                            modalAction={this.modalAction}
                            negativeButtonAction={this.negativeModalAction}
                            actionToggleExternal={this.modalToggle}
                            modalOpen={this.state.modal.showModal}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                        />
                        <Row className="mb-2">
                            <Col xs={12} className="d-flex">
                                <Button className="primary-btn-style-outline" onClick={this.back}>
                                    <i className="material-icons align-text-bottom">chevron_left</i>
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mb-2">
                            <Col xs={12}>
                                <div>
                                    <Form>
                                        <InputFieldWithValidation
                                            type="text"
                                            label="Election Name"
                                            valueKey="electionName"
                                            value={this.state.election.electionName}
                                            validationObj={{}}
                                            handleChange={this.onElectionNameChange}
                                        />
                                        <FormGroup row style={{ marginBottom: '1rem' }}>
                                            <Col>
                                                <Label>Start active date/time</Label>
                                                <div className="d-flex">
                                                    <CyderDatePicker
                                                        width={150}
                                                        datetimeClassName="mr-2"
                                                        value={this.state.election.startDate}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat="HH:mm"
                                                        onChange={this.onStartDateChange}
                                                        closeOnSelect
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <Label>End active date/time</Label>
                                                <div className="d-flex">
                                                    <CyderDatePicker
                                                        width={150}
                                                        datetimeClassName="mr-2"
                                                        dateFormat="DD/MM/YYYY"
                                                        value={this.state.election.endDate}
                                                        timeFormat="HH:mm"
                                                        onChange={this.onEndDateChange}
                                                        closeOnSelect
                                                    />
                                                </div>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: '1rem' }}>
                                            <Col>
                                                <Label>Unlocking date/time</Label>
                                                <div className="d-flex">
                                                    <CyderDatePicker
                                                        width={150}
                                                        datetimeClassName="mr-2"
                                                        value={this.state.election.unlockDate}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat="HH:mm"
                                                        onChange={this.onUnlockDateChange}
                                                        closeOnSelect
                                                    />
                                                </div>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row style={{ marginBottom: '1rem' }}>
                                            <Col>
                                                <Label>Public Sector Vacancy</Label>
                                                <div className="d-flex">
                                                    <Input
                                                        type="number"
                                                        value={this.state.election.publicVacancy}
                                                        onChange={this.onPublicVacancyChange}
                                                        style={{ width: '14%' }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <Label>Private Sector Vacancy</Label>
                                                <div className="d-flex">
                                                    <Input
                                                        type="number"
                                                        value={this.state.election.privateVacancy}
                                                        onChange={this.onPrivateVacancyChange}
                                                        style={{ width: '14%' }}
                                                    />
                                                </div>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Election Summary</Label>
                                            <CyderRichText data={electionSummary} onChange={val => this.onElectionSummaryChange(val)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Election Description</Label>
                                            <CyderRichText
                                                data={electionDescription}
                                                onChange={val => this.onElectionDescriptionChange(val)}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Compiled Public Nominees CV and Manifesto PDF</Label>
                                            <CyderFileUpload
                                                attachmentId={this.state.election.compiledPublicNomineesCvAndManifestoPdf}
                                                filename={this.state.election.compiledPublicNomineesCvAndManifestoPdfFileName}
                                                onUploadSuccess={this.onPublicUploadSuccess}
                                                onUploadFail={this.onPublicUploadFail}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Compiled Private Nominees CV and Manifesto PDF</Label>
                                            <CyderFileUpload
                                                attachmentId={this.state.election.compiledPrivateNomineesCvAndManifestoPdf}
                                                filename={this.state.election.compiledPrivateNomineesCvAndManifestoPdfFileName}
                                                onUploadSuccess={this.onPrivateUploadSuccess}
                                                onUploadFail={this.onPrivateUploadFail}
                                            />
                                        </FormGroup>
                                        <InputFieldWithValidation
                                            type="textarea"
                                            rows={5}
                                            label="Login Instructions"
                                            valueKey="loginInstruction"
                                            value={this.state.election.loginInstruction}
                                            handleChange={this.onLoginInstructionChange}
                                            validationObj={{}}
                                        />
                                        <InputFieldWithValidation
                                            type="textarea"
                                            rows={5}
                                            label="Vote confirmation"
                                            valueKey="voteConfirmation"
                                            value={this.state.election.voteConfirmation}
                                            handleChange={this.onVoteConfirmationChange}
                                            validationObj={{}}
                                        />
                                    </Form>
                                    <Button className="ml-2 align-middle primary-btn-style" onClick={this.save}>
                                        Save
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSidebar1(ElectionsCreatePage));
