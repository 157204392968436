import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locale_en from './locale/locale_en';
import locale_ch from './locale/locale_ch';

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: locale_en,
        ch: locale_ch,
    },
    fallbackLng: 'en',

    // have a common namespace used around the full app
    ns: ['translations', 'sidemenu'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
    },

    react: {
        wait: true,
    },
});

export default i18n;
