/**
 * Staff profile reducer
 *
 * Author: Fernando
 */

// temp/fake member profil]
import DASHBOARD_INFO from '../../json/sgbc-temp-staff-dashboard.json';
import MEMBER_SEARCH from '../../json/sgbc-temp-staff-search.json';

var staffDefaultState = {
    dashboardInfo: DASHBOARD_INFO,
    search: MEMBER_SEARCH,
};

export function staffReducer(state = staffDefaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'SGBC_STAFF_XXX':
            // TODO: need to change here...
            newState.profile = {};
            return newState;
        default:
            return state;
    }
}
