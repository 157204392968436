import React from 'react';
import { connect } from 'react-redux';

import { Input } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';

class BankTransferPayment extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-lg-12">
                    <PaymentInput
                        label="Payment Amount (SGD)"
                        description="Please enter amount of payment"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'amountReceived')}
                        value={this.props.paymentInfo.amountReceived}
                    />
                    <div className="form-group">
                        <label className="field-header">
                            Date of Payment <MandatoryStar />
                        </label>
                        <CyderDatePicker
                            placeholder=""
                            dateFormat="DD/MM/YYYY"
                            closeOnSelect={true}
                            onChange={e => this.props.onDateChanged(e, 'datePaymentReceived', 'datePaymentReceivedCtrl')}
                            value={this.props.paymentInfo.datePaymentReceivedCtrl}
                        />
                        <small className="form-text text-muted">Please select date of payment</small>
                    </div>
                    <PaymentInput
                        label="Name of Bank"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'bank')}
                        value={this.props.paymentInfo.bank}
                    />
                    <PaymentInput
                        id="referenceNumber"
                        label="Transaction No. / Cheque No."
                        description="Please enter number of transaction or cheque"
                        placeholder=""
                        onChange={e => this.props.onTextChanged(e.target.value, 'referenceNumber')}
                        value={this.props.paymentInfo.referenceNumber}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankTransferPayment);
