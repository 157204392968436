import React from 'react';
import history from '../../../history';
const navLabelStyle = {
    color: '#424242',
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '3px',
    display: 'block',
};
const headerStyle = {
    textTransform: 'uppercase',
    marginBottom: '10px',
    fontWeight: 'bold',
};
const containerStyle = {
    display: 'inline',
    float: 'left',
    padding: '10px',
    width: '100%',
    margin: '30px 0px',
};

const AffiliatesPanel = () => {
    return (
        <footer id="footer" style={{ textAlign: 'center', float: 'left', display: 'inline', width: '100%' }}>
            <div style={{ float: 'left', display: 'inline', width: '100%', backgroundColor: 'transparent', padding: 0 }}>
                <div className="container" style={{ paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto' }}>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-3">
                            <div className="single_footer_widget" style={{ ...containerStyle, textAlign: 'center' }}>
                                <img
                                    onClick={e => {
                                        window.open(`https://www.sgbc.sg`);
                                    }}
                                    width="150"
                                    height="150"
                                    src="/assets/sgbc/sgbc-log-transparent.png"
                                    alt="SGBC Online"
                                    style={{
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex">
                            <div className="single_footer_widget" style={{ ...containerStyle, paddingTop: '2em' }}>
                                <h3 style={headerStyle}>About Us</h3>
                                <p style={{ fontSize: 13, lineHeight: 1.8 }}>
                                    The Singapore Green Building Council (SGBC) is an enabler of sustainability in the built environment.
                                    Represented by a distinctive public-private partnership that spans across the real estate value chain,
                                    SGBC is the nexus for the advocacy of green buildings that are healthier and more resource-efficient.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 d-flex">
                            <div
                                className="single_footer_widget nav-menu"
                                style={{ ...containerStyle, paddingTop: '4.5em', paddingLeft: '5em' }}
                            >
                                <ul className="footer_widget_nav" style={{ padding: '0', margin: '0', listStyle: 'none' }}>
                                    <li>
                                        <a
                                            style={navLabelStyle}
                                            onClick={e => {
                                                history.push(`/public/membership`);
                                            }}
                                        >
                                            Membership
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            style={navLabelStyle}
                                            onClick={e => {
                                                history.push(`/public/certifications`);
                                            }}
                                        >
                                            Certification
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            style={navLabelStyle}
                                            onClick={e => {
                                                history.push(`/public/eventdirectory`);
                                            }}
                                        >
                                            Events
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            style={navLabelStyle}
                                            onClick={e => {
                                                history.push(`/public/login/staff`);
                                            }}
                                            href="#"
                                        >
                                            Staff Login
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            style={navLabelStyle}
                                            onClick={e => {
                                                history.push(`/public/login/member`);
                                            }}
                                        >
                                            Member Login
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default AffiliatesPanel;
