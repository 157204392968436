import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../../history';

import { Row, Col, Button } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import PaymentChoicesPanel from './PaymentChoicesPanel';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';

import {
    applicationLoadEventPaymentModes,
    loadCertificationInfo,
} from 'actions/member/certification/applications/CertificatePayNowAction.js';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import 'css/cyder/form/common-form.css';

class PayNowPanel extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        // EVENT
        this.setPaymentMode = this.setPaymentMode.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.cancel = this.cancel.bind(this);
        // MODAL
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        // STATE
        this.state = {
            disabled: false,
            searchable: true,
            selectValue: '',
            paymentStart: false,
            clearable: true,
            paymentModes: [],
            selectedPaymentMode: {
                value: '',
                label: '',
            },
            paymentStart: false,
            loading: false,
            applicationInfo: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                modalAction: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getRelevantStuff();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading(loading) {
        if (this._isMounted) this.setState({ loading });
    }
    async getRelevantStuff() {
        const { loadEventPaymentModes, loadRegistrationInfo } = this.props;
        try {
            this.showLoading(true);
            const applicationId = this.props.match.params.id;
            const { data } = await loadEventPaymentModes();
            const applicationInfo = await loadRegistrationInfo(applicationId);
            const paymentModes = data
                .map(({ id, mode }) => {
                    // Allow credit card and corporate now
                    if (id === 4) return;
                    return {
                        value: id,
                        label: mode,
                    };
                })
                .filter(x => x);
            if (this._isMounted) {
                this.setState({
                    paymentModes,
                    applicationInfo: applicationInfo.data[0],
                });
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    // EVENT HANDLERS
    setPaymentMode(e) {
        this.setState({
            selectedPaymentMode: e,
        });
    }
    cancel() {
        let type = 'product';
        try {
            type = this.props.match.params.type;
        } catch (e) {}
        history.push(`/member/certification/${type}/application/${this.props.match.params.id || 'all'}`);
    }
    cancelOld() {
        this.showModalIf(
            'Confirmation',
            `You have decided to settle the payment later. Please note that your certificate cannot be issue if we 
        have not received payment . Thank you.`,
            true,
        );
    }
    next() {
        const hasError = this.showModalIf(
            'Warning',
            'Please select a payment mode before proceed.',
            this.state.selectedPaymentMode.value === '',
        );
        if (hasError) return;
        this.setState({
            paymentStart: true,
        });
    }
    back() {
        this.setState({
            paymentStart: false,
        });
    }
    // DIALOG MODAL METHODS
    modalAction() {
        this.modalToggle();
        let type = 'product';
        try {
            type = this.props.match.params.type;
        } catch (e) {
            console.error(e);
        }
        history.push(`/member/certification/${type}/applications/all`);
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide, modalAction) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.modalAction = modalAction || this.modalAction;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    boundForceUpdate() {
        this.modalToggle();
        this.componentDidMount();
    }
    render() {
        return (
            <Col className="m-4">
                <Row>
                    <Col xs={5}>
                        <h3>
                            <strong>Certification Payment</strong>
                        </h3>
                    </Col>
                </Row>
                <br />
                {!this.state.paymentStart && (
                    <Row>
                        <Col className="col-5 col-lg-5">
                            <div className="form-group">
                                <label className="field-header">
                                    Payment Mode <MandatoryStar />
                                </label>
                                {this.state.loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Select
                                        autofocus
                                        simpleValue
                                        autosize={false}
                                        name="selected-state"
                                        placeholder="Please select"
                                        options={this.state.paymentModes}
                                        onChange={this.setPaymentMode}
                                        searchable={this.state.searchable}
                                        clearable={this.state.clearable}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                <small className="form-text text-muted">Please choose payment mode</small>
                            </div>
                        </Col>
                    </Row>
                )}
                {this.state.paymentStart && (
                    <PaymentChoicesPanel
                        applicationInfo={this.state.applicationInfo}
                        back={this.back}
                        cancel={this.cancel}
                        paymentMode={this.state.selectedPaymentMode.value}
                        dialogModalToggle={this.props.toggleDialogModal}
                        type={this.props.match.params.type}
                    />
                )}
                {!this.state.paymentStart && (
                    <Row>
                        <Col className="d-flex" xs={3}>
                            <Button className="primary-btn-style-outline" onClick={this.cancel}>
                                <strong>Cancel</strong>
                            </Button>
                        </Col>
                        <Col className="d-flex justify-content-end" xs={2}>
                            <Button
                                className="primary-btn-style"
                                style={{
                                    cursor:
                                        this.state.selectedPaymentMode.value == null || this.state.selectedPaymentMode.value == ''
                                            ? 'initial'
                                            : 'pointer',
                                }}
                                disabled={this.state.selectedPaymentMode.value == null || this.state.selectedPaymentMode.value == ''}
                                onClick={this.next}
                                size="sm"
                            >
                                <strong>Next</strong>
                            </Button>
                        </Col>
                    </Row>
                )}
                <DialogModal
                    modalAction={this.state.modal.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    boundForceUpdate={this.boundForceUpdate}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventPaymentModes: () => {
            return dispatch(applicationLoadEventPaymentModes());
        },
        loadRegistrationInfo: applicationId => {
            return dispatch(loadCertificationInfo(applicationId));
        },
        toggleDialogModal: () => {
            dispatch(dialogModalToggle());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PayNowPanel);
