export default {
    generateCheckboxSynthEvent(e, truevalue, falsevalue) {
        const returnTrueValue = truevalue !== undefined ? truevalue : true;
        const returnFalseValue = falsevalue !== undefined ? falsevalue : false;
        const synthEvent = {
            target: {
                id: e.target.id,
                dataset: e.target.dataset,
                value:
                    e.target.checked === true
                        ? returnTrueValue
                        : returnFalseValue,
            },
        };
        return synthEvent;
    },
    generateDateTimeSynthEvent(momentOrStringVal, id, momentFormat) {
        const synthEvent = {
            target: {
                id,
                value: momentOrStringVal.format
                    ? momentOrStringVal.format(momentFormat)
                    : momentOrStringVal,
            },
        };
        return synthEvent;
    },
};
