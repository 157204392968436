/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch } from 'react-router';

// PrivateRoute
import PrivateRoute from './cyder/common/PrivateRoute';

// Admin
import GroupPage from './pages/admin/auth/GroupPage';
import GroupAddPage from './pages/admin/auth/GroupAddPage';
import UserListPage from './pages/admin/auth/users/ListPage';
import UserAddPage from './pages/admin/auth/users/AddPage';
import UserDetailPage from './pages/admin/auth/UserDetailPage';
import CompanyPage from './pages/admin/companies/CompanyPage';
import CompanyAddPage from './pages/admin/companies/CompanyAddPage';
import SGBCSettingsPage from './pages/admin/misc/SGBCSettingsPage';
import SingaporeSettingsPage from './pages/admin/misc/SingaporeSettingsPage';
import EventTypeListPage from './pages/admin/events/event-type/ListPage';
import EventTypeAddPage from './pages/admin/events/event-type/AddPage';
import CountryListPage from './pages/admin/misc/country/ListPage';
import CountryAddPage from './pages/admin/misc/country/AddPage';
import UserManualCategoriesListPage from './pages/admin/misc/user-manual-categories/ListPage';
import UserManualCategoriesAddPage from './pages/admin/misc/user-manual-categories/AddPage';
import UserManualListPage from './pages/admin/misc/user-manual/ListPage';
import UserManualAddPage from './pages/admin/misc/user-manual/AddPage';
import OrganisationMainCategoryListPage from './pages/admin/companies/organisation-main-category/ListPage';
import CompanyAdminAddPage from './pages/admin/companies/company/AddPage';
import CompanyAdminListPage from './pages/admin/companies/company/ListPage';
import OrganisationMainCategoryAddPage from './pages/admin/companies/organisation-main-category/AddPage';
import OrganisationSubCategoryListPage from './pages/admin/companies/organisation-sub-category/ListPage';
import OrganisationSubCategoryAddPage from './pages/admin/companies/organisation-sub-category/AddPage';
import OrganisationCategoryGroupListPage from './pages/admin/companies/organisational-category-group/ListPage';
import OrganisationCategoryGroupAddPage from './pages/admin/companies/organisational-category-group/AddPage';
import OrganisationCategoryListPage from './pages/admin/companies/organisational-category/ListPage';
import OrganisationCategoryAddPage from './pages/admin/companies/organisational-category/AddPage';
import FoodPreferenceListPage from './pages/admin/events/food-preference/ListPage';
import FoodPreferenceAddPage from './pages/admin/events/food-preference/AddPage';
import PaymentModeListPage from './pages/admin/finances/payment-mode/ListPage';
import PaymentModeAddPage from './pages/admin/finances/payment-mode/AddPage';
import MembershipApplicationStatusListPage from './pages/admin/memberships/membership-application-status/ListPage';
import MembershipApplicationStatusAddPage from './pages/admin/memberships/membership-application-status/AddPage';
import MembershipRenewalStatusListPage from './pages/admin/memberships/membership-renewal-status/ListPage';
import MembershipRenewalStatusAddPage from './pages/admin/memberships/membership-renewal-status/AddPage';
import MembershipPackagesListPage from './pages/admin/memberships/membership-packages/ListPage';
import MembershipPackagesAddPage from './pages/admin/memberships/membership-packages/AddPage';
import SalutationsListPage from './pages/admin/persons/salutations/ListPage';
import SalutationsAddPage from './pages/admin/persons/salutations/AddPage';
import ProfessionListPage from './pages/admin/persons/profession/ListPage';
import ProfessionAddPage from './pages/admin/persons/profession/AddPage';
import ProductCategoryListPage from './pages/admin/product/product-category/ListPage';
import ProductCategoryAddPage from './pages/admin/product/product-category/AddPage';
import ProductSubCategoryListPage from './pages/admin/product/product-sub-category/ListPage';
import ProductSubCategoryAddPage from './pages/admin/product/product-sub-category/AddPage';
import ProductTypeListPage from './pages/admin/product/product-type/ListPage';
import ProductTypeAddPage from './pages/admin/product/product-type/AddPage';
import ProductListingRenewalStatusListPage from './pages/admin/product/product-listing-renewal-status/ListPage';
import ProductListingRenewalStatusAddPage from './pages/admin/product/product-listing-renewal-status/AddPage';
import SurveyCategoryListPage from './pages/admin/survey/survey-category/ListPage';
import SurveyCategoryAddPage from './pages/admin/survey/survey-category/AddPage';
import SurveyQuestionTypeListPage from './pages/admin/survey/survey-question-type/ListPage';
import SurveyQuestionTypeAddPage from './pages/admin/survey/survey-question-type/AddPage';
import ServiceCategoryListPage from './pages/admin/service/service-category/ListPage';
import ServiceCategoryAddPage from './pages/admin/service/service-category/AddPage';
import ServiceSubCategoryListPage from './pages/admin/service/service-sub-category/ListPage';
import ServiceSubCategoryAddPage from './pages/admin/service/service-sub-category/AddPage';
import ResourceListPage from './pages/admin/misc/resources/ListPage';
import ResourceAddPage from './pages/admin/misc/resources/AddPage';
import BCADataOptionListPage from './pages/admin/certificates/bca-data-option/ListPage';
import BCADataOptionAddPage from './pages/admin/certificates/bca-data-option/AddPage';
import OtherDataOptionListPage from './pages/admin/certificates/other-data-option/ListPage';
import OtherDataOptionAddPage from './pages/admin/certificates/other-data-option/AddPage';
import BuildingTypeOptionListPage from './pages/admin/certificates/building-type-option/ListPage';
import BuildingTypeOptionAddPage from './pages/admin/certificates/building-type-option/AddPage';
import MembershipDiscontinueReasonListPage from './pages/admin/memberships/membership-discontinue-reason/ListPage';
import MembershipDiscontinueReasonAddPage from './pages/admin/memberships/membership-discontinue-reason/AddPage';
import CertificatesTerminationReasonListPage from './pages/admin/certificates/termination-reason/ListPage';
import CertificatesTerminationReasonAddPage from './pages/admin/certificates/termination-reason/AddPage';
import MailingListAddPage from './pages/staff/mailinglist/AddPage';
import MailingListListPage from './pages/staff/mailinglist/ListPage';

const RoutesAdmin = () => (
    <Switch>
        <PrivateRoute exact path="/admin/auth/group/add" component={GroupAddPage} />
        <PrivateRoute exact path="/admin/auth/group/:groupCode" component={GroupAddPage} />
        <PrivateRoute exact path="/admin/auth/group" component={GroupPage} />

        <PrivateRoute exact path="/admin/auth/users/:id" component={UserAddPage} />
        <PrivateRoute exact path="/admin/auth/users" component={UserListPage} />

        {/* <PrivateRoute exact path="/admin/companies/company/add" component={CompanyAddPage} />
        <PrivateRoute exact path="/admin/companies/company" component={CompanyPage} />
        */}

        <PrivateRoute exact path="/admin/companies/company/:id" component={CompanyAdminAddPage} />
        <PrivateRoute exact path="/admin/companies/company" component={CompanyAdminListPage} />

        <PrivateRoute exact path="/admin/companies/organisation-main-category/:id" component={OrganisationMainCategoryAddPage} />
        <PrivateRoute exact path="/admin/companies/organisation-main-category" component={OrganisationMainCategoryListPage} />

        <PrivateRoute exact path="/admin/companies/organisation-sub-category/:id" component={OrganisationSubCategoryAddPage} />
        <PrivateRoute exact path="/admin/companies/organisation-sub-category" component={OrganisationSubCategoryListPage} />

        <PrivateRoute exact path="/admin/companies/organisational-category-group/:id" component={OrganisationCategoryGroupAddPage} />
        <PrivateRoute exact path="/admin/companies/organisational-category-group" component={OrganisationCategoryGroupListPage} />

        <PrivateRoute exact path="/admin/companies/organisational-category/:id" component={OrganisationCategoryAddPage} />
        <PrivateRoute exact path="/admin/companies/organisational-category" component={OrganisationCategoryListPage} />

        <PrivateRoute exact path="/admin/misc/country/:id" component={CountryAddPage} />
        <PrivateRoute exact path="/admin/misc/country" component={CountryListPage} />

        <PrivateRoute exact path="/admin/misc/sgbc_settings" component={SGBCSettingsPage} />
        <PrivateRoute exact path="/admin/misc/singapore_settings" component={SingaporeSettingsPage} />
        <PrivateRoute exact path="/admin/misc/user-manual-categories/:id" component={UserManualCategoriesAddPage} />
        <PrivateRoute exact path="/admin/misc/user-manual-categories" component={UserManualCategoriesListPage} />
        <PrivateRoute exact path="/admin/events/event-type/:id" component={EventTypeAddPage} />
        <PrivateRoute exact path="/admin/events/event-type" component={EventTypeListPage} />
        <PrivateRoute exact path="/admin/misc/user-manual/:id" component={UserManualAddPage} />
        <PrivateRoute exact path="/admin/misc/user-manual" component={UserManualListPage} />
        <PrivateRoute exact path="/admin/events/food-preference/:id" component={FoodPreferenceAddPage} />
        <PrivateRoute exact path="/admin/events/food-preference" component={FoodPreferenceListPage} />

        <PrivateRoute exact path="/admin/finances/payment-mode/:id" component={PaymentModeAddPage} />
        <PrivateRoute exact path="/admin/finances/payment-mode" component={PaymentModeListPage} />

        <PrivateRoute exact path="/admin/memberships/membership-application-status/:id" component={MembershipApplicationStatusAddPage} />
        <PrivateRoute exact path="/admin/memberships/membership-application-status" component={MembershipApplicationStatusListPage} />

        <PrivateRoute exact path="/admin/memberships/membership-renewal-status/:id" component={MembershipRenewalStatusAddPage} />
        <PrivateRoute exact path="/admin/memberships/membership-renewal-status" component={MembershipRenewalStatusListPage} />

        <PrivateRoute exact path="/admin/memberships/membership-packages/:id" component={MembershipPackagesAddPage} />
        <PrivateRoute exact path="/admin/memberships/membership-packages" component={MembershipPackagesListPage} />

        <PrivateRoute exact path="/admin/persons/salutations/:id" component={SalutationsAddPage} />
        <PrivateRoute exact path="/admin/persons/salutations" component={SalutationsListPage} />
        <PrivateRoute exact path="/admin/persons/profession/:id" component={ProfessionAddPage} />
        <PrivateRoute exact path="/admin/persons/profession" component={ProfessionListPage} />

        <PrivateRoute exact path="/admin/product/product-category/:id" component={ProductCategoryAddPage} />
        <PrivateRoute exact path="/admin/product/product-category" component={ProductCategoryListPage} />
        <PrivateRoute exact path="/admin/product/product-sub-category/:id" component={ProductSubCategoryAddPage} />
        <PrivateRoute exact path="/admin/product/product-sub-category" component={ProductSubCategoryListPage} />

        <PrivateRoute exact path="/admin/product/product-type/:id" component={ProductTypeAddPage} />
        <PrivateRoute exact path="/admin/product/product-type" component={ProductTypeListPage} />

        <PrivateRoute exact path="/admin/product/product-listing-renewal-status/:id" component={ProductListingRenewalStatusAddPage} />
        <PrivateRoute exact path="/admin/product/product-listing-renewal-status" component={ProductListingRenewalStatusListPage} />
        <PrivateRoute exact path="/admin/survey/survey-category/:id" component={SurveyCategoryAddPage} />
        <PrivateRoute exact path="/admin/survey/survey-category" component={SurveyCategoryListPage} />
        <PrivateRoute exact path="/admin/survey/survey-question-type/:id" component={SurveyQuestionTypeAddPage} />
        <PrivateRoute exact path="/admin/survey/survey-question-type" component={SurveyQuestionTypeListPage} />
        <PrivateRoute exact path="/admin/service/service-category/:id" component={ServiceCategoryAddPage} />
        <PrivateRoute exact path="/admin/service/service-category" component={ServiceCategoryListPage} />
        <PrivateRoute exact path="/admin/service/service-sub-category/:id" component={ServiceSubCategoryAddPage} />
        <PrivateRoute exact path="/admin/service/service-sub-category" component={ServiceSubCategoryListPage} />

        <PrivateRoute exact path="/admin/misc/resources/:id" component={ResourceAddPage} />
        <PrivateRoute exact path="/admin/misc/resources" component={ResourceListPage} />

        <PrivateRoute exact path="/admin/certificates/bca-data-option/:id" component={BCADataOptionAddPage} />
        <PrivateRoute exact path="/admin/certificates/bca-data-option" component={BCADataOptionListPage} />

        <PrivateRoute exact path="/admin/certificates/other-data-option/:id" component={OtherDataOptionAddPage} />
        <PrivateRoute exact path="/admin/certificates/other-data-option" component={OtherDataOptionListPage} />

        <PrivateRoute exact path="/admin/certificates/building-type-option/:id" component={BuildingTypeOptionAddPage} />
        <PrivateRoute exact path="/admin/certificates/building-type-option" component={BuildingTypeOptionListPage} />

        <PrivateRoute exact path="/admin/memberships/membership-discontinue-reason/:id" component={MembershipDiscontinueReasonAddPage} />
        <PrivateRoute exact path="/admin/memberships/membership-discontinue-reason" component={MembershipDiscontinueReasonListPage} />

        <PrivateRoute exact path="/admin/certificates/termination-reason/:id" component={CertificatesTerminationReasonAddPage} />
        <PrivateRoute exact path="/admin/certificates/termination-reason" component={CertificatesTerminationReasonListPage} />

        <PrivateRoute exact path="/admin/mailinglist/:id" component={MailingListAddPage} />
        <PrivateRoute exact path="/admin/mailinglist" component={MailingListListPage} />
    </Switch>
);

export default RoutesAdmin;
