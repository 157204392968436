import withQuery from 'with-query';

import {
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST,
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE,
    STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS,
} from '../../../constants/actionTypesStaffMembership';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';
import fetchlib from '../../../../js/fetchlib';

export function staffMembershipApplicationsGetStatusCount(statusId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'getApplicationCountByStatus',
            statusId: statusId,
        });

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST,
            STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS,
            STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE,
        );
    };
}
export function staffMembershipApplicationsGetList(page, pageSize, sorted, filtered, keyword, filterBy) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application`, {
            action: 'searchallapplicationlist',
        });
        const body = {
            keyword: keyword || '',
            itemsPerPage: pageSize,
            page: page + 1,
            filterBy: filterBy,
            sortBy: sorted === undefined || sorted.length == 0 ? '' : sorted[0].id,
            sortDesc: sorted === undefined || sorted.length == 0 ? '' : sorted[0].desc,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST,
            STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS,
            STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE,
        );
    };
}
export function staffMembershipApplicationsSetFilterStatus(filterBtnId, filterBtnName) {
    return dispatch => {
        dispatch({
            type: STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS,
            filterBtnId,
            filterBtnName,
        });
        return Promise.resolve();
    };
}
