import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Row, Col, Button } from 'reactstrap';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import { updatePayment } from 'actions/staff/membership/applications/staffMembershipApplicationsDetailsAction';

import { setReducerModalState, defaultModalKeys } from '../../../../../../js/modal';
import { validateFromValidationObject as validateValidationObject } from 'js/validation';
import cyderlib from 'js/cyderlib';

class BankTransferPayment extends React.Component {
    constructor(props) {
        super(props);
        this.onDateOfPaymentChange = this.onDateOfPaymentChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            field: {
                paymentAmount: '',
                dateOfPayment: moment().format('DD/MM/YYYY'),
                bankDetails: '',
                referenceNumber: '',
            },
            validationObj: {},
            feedbackValidationObj: {},
            loading: false,
            ...defaultModalKeys,
        };
    }
    setLoading(loading) {
        this.setState({ loading });
    }
    determineSavable() {
        // VALIDATE INVALID VALUE
        for (let key in this.state.field) if (!this.state.field[key]) return false;
        const isValid = this.determineValidationObj();
        return isValid;
    }
    determineValidationObj() {
        // VALIDATE VALIDATION OBJECT
        const requiredObj = {
            paymentAmount: true,
            bankDetails: true,
            referenceNumber: true,
        };
        let isValid = validateValidationObject(this.state.validationObj, requiredObj);
        return isValid;
    }
    getValidationObject(key, value) {
        const state = Object.assign(this.state);

        // Get validation result for validation object
        let validationObj = state.validationObj;
        validationObj[key] = cyderlib.validate(key, value);

        // Get validation result for feedback validation object
        let feedbackValidationObj = state.feedbackValidationObj;
        feedbackValidationObj[key] = cyderlib.validate(key, value);

        return {
            validationObj,
            feedbackValidationObj,
        };
    }
    constructPayloadBody() {
        const { paymentAmount, dateOfPayment, bankDetails, referenceNumber } = this.state.field;
        const body = {
            amountReceived: paymentAmount,
            receivedTimestamp: dateOfPayment,
            modeOfPaymentId: this.props.paymentMode,
            datePaymentReceived: dateOfPayment,
            referenceNumber,
            bank: bankDetails,
            remarks: '',
            id: this.props.applicationInfo.paymentId,
        };
        return body;
    }
    async submit() {
        try {
            this.setLoading(true);
            const body = this.constructPayloadBody();
            const { data } = await this.props.updatePayment(body);
            if (data) {
                this.toggleDialofModal(
                    'Confirmation',
                    'Payment Information successfully updated.',
                    `/member/certification/${this.props.type}/applications/all`,
                );
                return;
            }
            this.toggleDialofModal('Error', 'We are sorry that this bank transfer payment has failed');
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }
    onDateOfPaymentChange(e) {
        let field = this.state.field;
        field.dateOfPayment = moment(e).format('DD/MM/YYYY');
        this.setState({
            field,
        });
    }
    onTextChange(value, key) {
        // GET VALIDATION OBJECT
        const validateObj = this.getValidationObject(key, value);
        // SET STATE
        let field = this.state.field;
        field[key] = value;
        this.setState({ field, ...validateObj });
    }
    toggleDialofModal(header, body, action) {
        const data = {
            modalAction: action,
            modalBody: body,
            modalHeader: header,
            modalOnlyOneButton: true,
        };
        this.props.dialogModalToggle();
        const state = setReducerModalState(setReducerModalState, data);
        this.setState(state);
    }
    render() {
        const { paymentAmount, dateOfPayment, bankDetails, referenceNumber } = this.state.field;
        const { feedbackValidationObj } = this.state;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.state.modalAction}
                    modalHeader={this.state.modalHeader}
                    modalBody={this.state.modalBody}
                    modalOnlyOneButton={this.state.modalOnlyOneButton}
                    loading={this.state.loading}
                    buttonDisabler={this.state.modalLoading}
                />
                {this.state.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <div className="col-5 col-lg-5 p-0">
                        <Row>
                            <Col xs={6}>
                                <PaymentInput
                                    inputStyle={{ width: '150px' }}
                                    label="Payment Amount (SGD)"
                                    description="Please enter amount of payment"
                                    placeholder=""
                                    value={paymentAmount}
                                    onChange={e => this.onTextChange(e.target.value, 'paymentAmount')}
                                    valueKey="paymentAmount"
                                    validationObj={feedbackValidationObj}
                                />
                            </Col>
                            <Col xs={6}>
                                <div className="form-group">
                                    <label className="field-header">
                                        Date of Payment <MandatoryStar />
                                    </label>
                                    <CyderDatePicker
                                        placeholder=""
                                        dateFormat="DD/MM/YYYY"
                                        closeOnSelect={true}
                                        value={dateOfPayment}
                                        onChange={e => this.onDateOfPaymentChange(e, 'dateOfPayment')}
                                    />
                                    <small className="form-text text-muted">Please select date of payment</small>
                                </div>
                            </Col>
                        </Row>
                        <PaymentInput
                            label="Name of Bank"
                            placeholder=""
                            value={bankDetails}
                            onChange={e => this.onTextChange(e.target.value, 'bankDetails')}
                            valueKey="bankDetails"
                            validationObj={feedbackValidationObj}
                        />
                        <PaymentInput
                            label="Transaction No. / Cheque No."
                            description="Please enter number of transaction or cheque"
                            placeholder=""
                            value={referenceNumber}
                            onChange={e => this.onTextChange(e.target.value, 'referenceNumber')}
                            valueKey="referenceNumber"
                            validationObj={feedbackValidationObj}
                        />
                        <br />
                        <Row>
                            <Col className="d-flex flex-row p-0" xs={6}>
                                <Button className="primary-btn-style-outline" onClick={this.props.back}>
                                    <strong>Back</strong>
                                </Button>
                            </Col>
                            <Col className="d-flex flex-row-reverse p-0" xs={6}>
                                <Button className="primary-btn-style" disabled={!this.determineSavable()} onClick={this.submit}>
                                    <strong>Submit</strong>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updatePayment: data => {
            return dispatch(updatePayment(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BankTransferPayment);
