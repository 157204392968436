/**
 * Member Certification Overview page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Container } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import Certifications from './components/Certifications';

import { memberAllCertListGetInfo } from 'actions/member/certification/memberCertificationListAction';

class MemberCertificationListPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            validcertifications: [],
            expiredcertifications: [],
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            loading: false,
            validTotalPages: 0,
            validTotalItems: 0,
            expiredTotalPages: 0,
            expiredTotalItems: 0,
        };
    }
    // componentDidMount() {
    //     this.showLoading(true);
    //     this.getRelevantData(this.props.mode);
    // }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidUpdate(prevProps) {
        if (this.props.mode !== prevProps.mode || prevProps.match.url !== this.props.match.url) {
            this.getDataValid();
            this.getDataExpired();
        }
    }
    showLoading(show) {
        if (!this._isMounted) return;
        this.setState({
            loading: show,
        });
    }
    determinedMode() {
        const mode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
        return mode;
    }

    getDataValid = async (page = 0, pageSize = 20, sorted = null, filtered = null) => {
        console.log('sorted: ', sorted);
        let { data, totalNoOfItems, totalNoOfPages } = await this.props.memberCertListGetInfo(
            'valid',
            this.props.mode,
            page,
            pageSize,
            sorted,
            filtered,
        );
        if (data) {
            this.setState({
                validcertifications: data,
                validTotalPages: totalNoOfPages,
                validTotalItems: totalNoOfItems,
            });
        } else {
            this.setState({
                validcertifications: [],
            });
        }
    };

    getDataExpired = async (page = 0, pageSize = 20, sorted = null, filtered = null) => {
        console.log(page, pageSize, sorted, filtered);
        let { data, totalNoOfItems, totalNoOfPages } = await this.props.memberCertListGetInfo(
            'expired',
            this.props.mode,
            page,
            pageSize,
            sorted,
            filtered,
        );
        if (data) {
            console.log('Set expired', data);
            this.setState({
                expiredcertifications: data,
                expiredTotalPages: totalNoOfPages,
                expiredTotalItems: totalNoOfItems,
            });
        } else {
            this.setState({
                expiredcertifications: [],
            });
        }
    };

    render() {
        const { tablePages, loading } = this.props;
        const {
            validcertifications,
            expiredcertifications,
            validTotalPages,
            validTotalItems,
            expiredTotalPages,
            expiredTotalItems,
        } = this.state;
        const determinedMode = window.location.pathname.toLowerCase().includes('product') ? 'product' : 'service';
        const mode = this.props.mode || determinedMode;
        return (
            <Container>
                {this.state.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <Certifications
                        mode={mode}
                        validcertifications={validcertifications}
                        expiredcertifications={expiredcertifications}
                        validTotalItems={validTotalItems}
                        validTotalPages={validTotalPages}
                        expiredTotalPages={expiredTotalPages}
                        expiredTotalItems={expiredTotalItems}
                        loading={loading}
                        getDataValid={this.getDataValid}
                        getDataExpired={this.getDataExpired}
                    />
                )}
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberCertificationsListReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        memberCertListGetInfo: (status, type, page, pageSize, sorted, filtered) => {
            return dispatch(memberAllCertListGetInfo(status, type, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberCertificationListPage));
