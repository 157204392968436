import {
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST,
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS,
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE,
    STAFF_CERT_APPL_LIST_FORCE_LOADING,
    STAFF_CERT_LIST_GET_INFO_REQUEST,
    STAFF_CERT_LIST_GET_INFO_SUCCESS,
    STAFF_CERT_LIST_GET_INFO_FAILURE,
    FETCH_XERO_INVENTORY_CODES,
    FETCH_APPLICATION_LIST,
    FETCH_NUMBER_OF_TICKS,
    FETCH_CERTIFICATION_RENEWALS,
    LOADING_CERTIFICATION,
    FETCH_RENEWAL_DETAILS,
    FETCH_RENEWAL_STATUS,
    UPDATE_RENEWAL_STATUS,
    FETCH_TERMINATION_REASON,
    ATTACH_PREVIOUS_TEMPLATE,
} from '../../../actions/constants/actionTypesStaffCertificationApplications';
const defaultState = {
    review: [],
    certs: [],
    stats: {
        applSubmitted: 0,
        applPayment: 0,
        applIssuance: 0,
        applMyCase: 0,
        issuedThisYear: 0,
        issuedThisMonth: 0,
        renewedPercentageLastYear: 0,
        renewedPercentageThisYear: 0,
    },
    tablePages: -1,
    loading: false,
    forceLoading: false,
    selectedTemplate: {},
    usePreviousAssessmentTemplate: { label: 'No', value: false },
};

export default function staffCertificationApplicationReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case ATTACH_PREVIOUS_TEMPLATE:
            newState.usePreviousAssessmentTemplate = action.usePreviousAssessmentTemplate;
            return newState;
        case STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS:
            const dt = action.response.data;
            newState.stats.issuedThisYear = dt.issuedThisYear;
            newState.stats.issuedThisMonth = dt.issuedThisMonth;
            newState.stats.renewedPercentageLastYear = dt.renewedPercentageLastYear;
            newState.stats.renewedPercentageThisYear = dt.renewedPercentageThisYear;
            newState.loading = false;
            return newState;
        case STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case 'STAFF_CERT_APPLICATION_STORE_SELECTED_TEMPLATE':
            newState.selectedTemplate = action.template;
            return newState;
        case 'STAFF_CERT_APPLICATION_USE_PREV_TEMPLATE':
            newState.usePreviousAssessmentTemplate = { label: 'Yes', value: true };
            return newState;
        case STAFF_CERT_LIST_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_CERT_LIST_GET_INFO_SUCCESS:
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.certs = action.response.data;
            newState.loading = false;
            return newState;
        case STAFF_CERT_LIST_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_CERT_APPL_LIST_FORCE_LOADING:
            newState.forceLoading = action.loadingState;
            return newState;
        case FETCH_XERO_INVENTORY_CODES:
            var tempPayload = action.payload;
            return { ...state, xeroCodes: tempPayload };
        case FETCH_APPLICATION_LIST:
            return { ...state, applications: action.payload, totalNoOfItems: action.totalNoOfItems, totalNoOfPages: action.totalNoOfPages };
        case FETCH_NUMBER_OF_TICKS:
            return { ...state, ticks: action.payload };
        case LOADING_CERTIFICATION:
            return { ...state, loading: true };
        case FETCH_CERTIFICATION_RENEWALS:
            return { ...state, renewals: action.payload, pages: action.pages, length: action.length, loading: false };
        case FETCH_RENEWAL_DETAILS:
            return { ...state, renewal: action.payload, loading: false };
        case FETCH_RENEWAL_STATUS:
            return { ...state, status: action.payload, loading: false };
        case UPDATE_RENEWAL_STATUS:
            return { ...state, loading: false };
        case FETCH_TERMINATION_REASON:
            return { ...state, terminationReason: action.payload, loading: false };
        case FETCH_TERMINATION_REASON:
        default:
            return state;
    }
}
