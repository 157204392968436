import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import Select from 'react-select';
import DialogModal from 'cyder/modals/DialogModal';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';

import { save, update, remove, load } from 'actions/staff/events/event-expenditure/action.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.amountChange = this.amountChange.bind(this);
        this.transactionTypeChange = this.transactionTypeChange.bind(this);
        this.transactionDateChange = this.transactionDateChange.bind(this);
        this.back = this.back.bind(this);

        this.eventIdChange = this.eventIdChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            transactionTypes: [{ value: 'Expenditure', label: 'Expenditure' }, { value: 'Income', label: 'Income' }],
            selectedTransactionType: {},
            data: {
                description: '',
                amount: '',
                transactionType: '',
                transactionDate: '',
                eventId: '',
                id: 0,
            },
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        this.props.setMode('list', 0);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    this.showLoading(false);
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        var transactionDate = moment(res.data.transactionDate).format('DD/MM/YYYY');
                        var formattedData = Object.assign({}, res.data);
                        formattedData.transactionDate = transactionDate;
                        this.setState({
                            data: formattedData,
                        });

                        this.state.transactionTypes.forEach(option => {
                            if (option.value === res.data.transactionType) {
                                this.setState({
                                    selectedTransactionType: option,
                                });
                            }
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    description: '',
                    amount: '',
                    transactionType: '',
                    transactionDate: '',
                    eventId: '',
                    id: '',
                },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.eventId = this.props.event.eventid;
        newData.transactionDate = moment(newData.transactionDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        this.props.setMode('list', 0);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        this.props.setMode('list', 0);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                this.props.setMode('list', 0);
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    descriptionChange(e) {
        var data = this.state.data;
        data.description = e.target.value;
        this.setState({ data });
    }
    amountChange(e) {
        var data = this.state.data;
        data.amount = e.target.value;
        this.setState({ data });
    }
    transactionTypeChange(e) {
        var data = this.state.data;
        data.transactionType = e.value;
        this.setState({
            data,
        });
        this.setState({
            selectedTransactionType: e,
        });
    }
    transactionDateChange(e) {
        var data = this.state.data;
        data.transactionDate = e.format('DD/MM/YYYY');
        this.setState({
            data,
        });
    }
    eventIdChange(e) {
        var data = this.state.data;
        data.eventId = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col className="d-flex " xs={12}>
                            <Button className="text-bold primary-btn-style-outline" onClick={this.back}>
                                Back
                            </Button>
                        </Col>
                        {/* <Col xs={12} className="d-flex">
                            <i onClick={this.back} style={{ cursor: 'pointer' }} className={`fa fa-2x fa-angle-left`} />
                            <div
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                }}
                                onClick={this.back}
                            >
                                back
                            </div>
                        </Col> */}
                    </Row>
                    <hr />
                    {/* <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="id">ID</Label>
                                <Input type="text" name="id" id="id" style={{width: '100px'}} disabled value={this.state.data.id} placeholder="id" />{' '}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="eventId">eventId</Label>
                                <Input
                                    type="text"
                                    name="eventId"
                                    id="eventId"
                                    value={this.state.data.eventId}
                                    placeholder="eventId"
                                    onChange={this.eventIdChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="transactionDate">Transaction Date</Label>
                                <CyderDatePicker
                                    value={this.state.data.transactionDate}
                                    onChange={this.transactionDateChange}
                                    closeOnSelect={true}
                                    datetimeClassName="mr-2"
                                    dateFormat="DD/MM/YYYY"
                                />
                                {/* <Input
                                    type="text"
                                    name="transactionDate"
                                    id="transactionDate"
                                    value={this.state.data.transactionDate}
                                    placeholder=""
                                    onChange={this.transactionDateChange}
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={2}>
                            <FormGroup>
                                <Label for="transactionType">Transaction Type</Label>
                                <Select
                                    autosize={false}
                                    options={this.state.transactionTypes}
                                    value={this.state.selectedTransactionType}
                                    clearable={this.state.select.clearable}
                                    onChange={this.transactionTypeChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />
                                {/* <Input
                                    type="text"
                                    name="transactionType"
                                    id="transactionType"
                                    value={this.state.data.transactionType}
                                    placeholder=""
                                    onChange={this.transactionTypeChange}
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={2}>
                            <FormGroup>
                                <Label for="amount">Amount</Label>
                                <Input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    value={this.state.data.amount}
                                    placeholder=""
                                    onChange={this.amountChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={4}>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Input
                                    type="textarea"
                                    rows={3}
                                    name="description"
                                    id="description"
                                    value={this.state.data.description}
                                    placeholder=""
                                    onChange={this.descriptionChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2}>
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        ) : (
                            <div />
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
