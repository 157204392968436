import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import { debounce } from 'lodash';

import { loginPageGetCompaniesAction } from '../../actions/security/cyderSecurityAction';

import { Async, components } from 'react-select';

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <i className="material-icons p-2">search</i>
            </components.DropdownIndicator>
        )
    );
};
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.getCompanies = this.getCompanies.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.state = {
            companies: [],
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onCompanyChange(e) {
        if (e.value) this.props.search(e.value, this.props.refresh);
    }
    async getCompanies(input) {
        try {
            const inputLength = input.length;
            const companiesRes = await this.props.getCompanies(input);
            const companies = companiesRes
                .map(company => {
                    const { companyname, company_id } = company;
                    if (companyname.substring(0, inputLength).toLowerCase() === input.toLowerCase()) {
                        return {
                            label: companyname,
                            value: company_id,
                        };
                    }
                })
                .filter(x => x);
            if (this._isMounted) {
                this.setState({
                    companies,
                });
            }
            return companies;
        } catch (error) {
            throw error;
        }
    }
    render() {
        return (
            <div className="navbar-search-form">
                <div className="form-group">
                    <Async
                        cacheOptions
                        defaultOptions
                        loadOptions={this.getCompanies}
                        onChange={this.onCompanyChange}
                        placeholder="Search Company"
                        name="company"
                        components={{ DropdownIndicator }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        search: (companyId, refresh) => {
            console.log(': ', refresh);
            if (refresh) {
                window.location.href = '/staff/company/' + companyId;
            } else {
                history.replace('/staff/company/' + companyId);
            }
        },
        getCompanies: input => {
            return dispatch(loginPageGetCompaniesAction(input));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Search);
