import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { Row, Col, Button, Card, CardBody, CardTitle, Badge, Label } from 'reactstrap';
import moment from 'moment';
import MembershipTierHistoryPanel from 'pages/shared/MembershipTierHistoryPanel';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';

// Subcategory component
const SubCategoryComponent = props => {
    if (props.data.length === 0) {
        return <CardBody>None</CardBody>;
    }
    // Reform subCategory data structure
    const subCategory = {};
    props.data.forEach(object => {
        if (!subCategory[object.mainCategoryName]) {
            subCategory[object.mainCategoryName] = [];
        }
        subCategory[object.mainCategoryName].push(object.subCategoryName);
    });
    // Generate component
    return Object.keys(subCategory).map((mainCategoryName, i) => {
        return (
            <CardBody key={i}>
                <u>{mainCategoryName}</u>
                <ul>
                    {subCategory[mainCategoryName].map((subCategoryName, i) => {
                        return <li key={i}>{subCategoryName}</li>;
                    })}
                </ul>
            </CardBody>
        );
    });
};
// Overview component
const CompanyDetailsPageOverviewPanel = ({
    count,
    companyInfo,
    memberInfo,
    companyId,
    subCategory,
    businessActivities,
    mainCategories,
    getBusinessActivityName,
    getMainCategoryName,
    deleteAcccount,
    updateMembershipPeriod,
}) => {
    let expired = false;
    try {
        let currentMm = moment();
        let expiryMm = moment(memberInfo.expiryDate, 'YYYY-MM-DD');
        if (expiryMm.isBefore(currentMm)) {
            expired = true;
        }
    } catch (e) {}
    return (
        <Row>
            <Col md={7}>
                <Card>
                    <CardTitle className="p-3 d-flex">
                        <span>
                            {' '}
                            <i className="fa fa-info-circle fa-fw" /> Corporate Information
                        </span>
                        {memberInfo != null && new Boolean(memberInfo.isSME).valueOf() ? (
                            <span className="ml-auto">
                                <Badge className="badge-sm" color="success">
                                    SME
                                </Badge>
                            </span>
                        ) : null}
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                {companyInfo.logoLocation != null && companyInfo.logoLocation != 'Not specified' && (
                                    <Image src={SGBC_S3_ROOT + companyInfo.logoLocation} size="small" circular />
                                )}
                                <br />
                                <h3>{companyInfo.name}</h3>
                                <h6 className="text-success">Organization ID: {companyInfo.id}</h6>
                                <hr />
                                <br />
                                <h6>
                                    <i className="fa fa-globe fa-fw" />
                                    &nbsp;
                                    <span>{companyInfo.countryName}</span>
                                </h6>
                                <h6>
                                    <i className="fa fa-key fa-fw" />
                                    &nbsp;
                                    <span>{companyInfo.registrationNumber}</span>
                                </h6>
                                <h6>
                                    <i className="fa fa-chrome fa-fw" />
                                    &nbsp;
                                    {companyInfo.website === 'Website' || companyInfo.website === 'Not specified' ? (
                                        <span>{companyInfo.website}</span>
                                    ) : (
                                        <a href={'https://' + companyInfo.website}>{companyInfo.website}</a>
                                    )}
                                </h6>
                                <h6>
                                    <i className="fa fa-phone fa-fw" />
                                    &nbsp;
                                    <span>{companyInfo.mainPhoneNumber}</span>
                                </h6>
                                <h6>
                                    <i className="fa fa-envelope-o fa-fw" />
                                    &nbsp;
                                    {companyInfo.generalEmail === 'Email' || companyInfo.generalEmail === 'Not specified' ? (
                                        <span>{companyInfo.generalEmail}</span>
                                    ) : (
                                        <a href={'mailto:' + companyInfo.generalEmail}>{companyInfo.generalEmail}</a>
                                    )}
                                </h6>
                                <h6>
                                    <Row>
                                        <i className="pl-3 fa fa-map-marker fa-fw" />
                                        <Col>
                                            <span>{companyInfo.businessAddressLine1}</span>
                                            <span>
                                                {companyInfo.businessAddressLine2 == null ||
                                                companyInfo.businessAddressLine2 == '' ||
                                                companyInfo.businessAddressLine2 == 'Not specified' ? (
                                                    ''
                                                ) : (
                                                    <React.Fragment>
                                                        <br />
                                                        {companyInfo.businessAddressLine2}
                                                    </React.Fragment>
                                                )}
                                            </span>
                                            <span>
                                                {companyInfo.businessAddressLine3 == null ||
                                                companyInfo.businessAddressLine3 == '' ||
                                                companyInfo.businessAddressLine3 == 'Not specified' ? (
                                                    ''
                                                ) : (
                                                    <React.Fragment>
                                                        <br />
                                                        {companyInfo.businessAddressLine3}
                                                    </React.Fragment>
                                                )}
                                            </span>
                                            <span>
                                                {companyInfo.businessPostalCode == null ||
                                                companyInfo.businessPostalCode == '' ||
                                                companyInfo.businessPostalCode == 'Not specified' ? (
                                                    ''
                                                ) : (
                                                    <React.Fragment>
                                                        <br />
                                                        {companyInfo.businessPostalCode}
                                                    </React.Fragment>
                                                )}
                                            </span>
                                        </Col>
                                    </Row>
                                </h6>
                                <br />
                                <hr />
                                <br />
                                <h6>
                                    <strong>Organisation Primary Business Activity</strong>
                                </h6>
                                <CardBody>{getMainCategoryName(mainCategories, companyInfo.organisationMainCategoryId)}</CardBody>
                                <br />
                                <br />
                                <h6>
                                    <strong>Services offered by organisation</strong>
                                </h6>
                                <SubCategoryComponent data={subCategory} />
                                <br />
                                <br />
                                <i className="fa fa-quote-left fa-2x fa-fw faded-less" />
                                &nbsp;&nbsp;
                                <Label>{companyInfo.greenBuildingMovementInvolvement}</Label>
                                <hr />
                                <Button className="primary-btn-style" onClick={deleteAcccount}>
                                    <i className="fa fa-trash fa-fw" /> Delete Account
                                </Button>{' '}
                                &nbsp;&nbsp;
                                <Link to={'/staff/company/companyprofile/' + companyId}>
                                    <Button className="primary-btn-style">
                                        <i className="fa fa-pencil fa-fw" /> Edit Profile
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col md={5}>
                <Card className="mb-2">
                    <CardTitle className="p-3">
                        <i className="fa fa-leaf" /> Membership Information
                    </CardTitle>
                    {memberInfo.dateJoined != null && (memberInfo.dateJoined > 0 || memberInfo.dateJoined != '') ? (
                        !expired ? (
                            <CardBody style={{ paddingRight: '5px' }}>
                                <h4>
                                    {memberInfo != null && memberInfo.membershipType}{' '}
                                    {memberInfo != null &&
                                        memberInfo.membershipTier != null &&
                                        memberInfo.membershipTier != '' &&
                                        memberInfo.membershipTier != 'Standard' && <span>({memberInfo.membershipTier})</span>}
                                    <span style={{ fontSize: '12px' }}>
                                        &nbsp;
                                        <MembershipTierHistoryPanel membershipId={memberInfo.id} />
                                    </span>
                                </h4>
                                <Badge color="success" className="mr-1">
                                    Since {memberInfo != null && moment(memberInfo.dateJoined).format('YYYY')}
                                </Badge>
                                <Badge color="warning">
                                    Expires {memberInfo != null && moment(memberInfo.expiryDate).format('DD MMMM YYYY')}
                                </Badge>
                                <br />
                                <div className="mt-2">
                                    Expires in{' '}
                                    {memberInfo != null &&
                                        moment()
                                            .diff(moment(memberInfo.expiryDate).format('D MMMM YYYY'), 'days')
                                            .toString()
                                            .substring(1)}{' '}
                                    days
                                </div>
                            </CardBody>
                        ) : (
                            <CardBody style={{ paddingRight: '5px' }}>
                                No active membership at the moment.
                                <br />
                                <div className="mt-2">
                                    Previously valid from {moment(memberInfo.dateJoined).format('YYYY')} till{' '}
                                    {moment(memberInfo.expiryDate).format('YYYY')}
                                </div>
                            </CardBody>
                        )
                    ) : (
                        <div style={{ marginLeft: '40px' }}>
                            <i>No active membership at the moment</i>
                        </div>
                    )}
                    {memberInfo != null && memberInfo.dateJoined != null && memberInfo.dateJoined != 0 && (
                        <div style={{ marginLeft: '15px', marginBottom: '10px' }}>
                            <Button className="primary-btn-style mr-1" onClick={updateMembershipPeriod}>
                                <i className="fa fa-edit" /> Update Membership
                            </Button>
                            <br />
                            <i style={{ fontSize: '10px' }}>*) you need to regenerate certificate after updating the membership</i>
                        </div>
                    )}
                </Card>
                <Card className="mb-2">
                    <CardTitle className="p-3">
                        <i className="fa fa-certificate" /> Certifications
                    </CardTitle>
                    <CardBody>
                        <h4>
                            <strong className="text-info">{count.certification}</strong> <small>Active Product Certificates</small>
                        </h4>
                    </CardBody>
                </Card>
                <Card className="mb-2">
                    <CardTitle className="p-3">
                        <i className="fa fa-glass" /> Events
                    </CardTitle>
                    <CardBody>
                        <h4>
                            <strong className="text-info">{count.event}</strong> <small>event attended</small>
                        </h4>
                        <small>in the last 12 months</small>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default CompanyDetailsPageOverviewPanel;
