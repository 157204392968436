import React from 'react';
import { connect } from 'react-redux';

import { Container, Col, Row } from 'reactstrap';
import TopNavigationLayout from '../../../layouts/TopNavigationLayout';
import DashboardWidget from '../../../cyder/widget/DashboardWidget';
import Resources from './components/resources/Resources';
import Certificates from './components/certificates/Certificates';
import DashboardUpcomingEvents from './components/DashboardUpcomingEvents';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import MembershipDashboardWidget from './components/MembershipDashboardWidget';

import {
    memberHomePanelCertGetInfo,
    memberHomePanelMembershipApplicationGetInfo,
    memberHomePanelEventGetInfo,
    memberHomePanelResourcesGetInfo,
    memberHomeSetLoadingMembershipApplicationInfoState,
    memberHomeSetLoadingCertificateState,
    memberHomeSetLoadingResourceLibraryState,
    memberHomeSetLoadingEventState,
} from 'actions/member/home/memberHomeAction';
import { staffCompanyDetailsPrintCertByCompanyId } from 'actions/staff/company/staffCompanyDetailsMembershipAction';
import { personalProfileGetProfileInfo } from 'actions/profile/personalProfileAction';

import { b64toBlob } from 'js/util';
class MemberHomeOld extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.showLoadingPrintCert = this.showLoadingPrintCert.bind(this);
        this.printCert = this.printCert.bind(this);
        this.state = {
            isOpenCertificates: true,
            isOpenResourceLibrary: true,
            isOpenUpcomingEvents: true,
            loadingPrintCert: false,
            loadingCertification: false,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getRelevantData();
        this.props.getProfileInfo();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    toggleCollapse(id) {
        let obj = {};
        obj[id] = !this.state[id];
        this.setState(obj);
    }
    shouldApplyButtonDisabled() {
        const { organisationMainCategoryId, officialManagementRepresentativeId, businessAddressLine1 } = this.state.companyInfo;
        if (!organisationMainCategoryId || !officialManagementRepresentativeId || !businessAddressLine1) return true;
        return false;
    }
    showLoadingPrintCert(loadingPrintCert) {
        this.setState({
            loadingPrintCert,
        });
    }
    printCert() {
        this.showLoadingPrintCert(true);
        this.props
            .printCertByCompanyId(this.props.match.params.companyid)
            .then(res => {
                var blob = b64toBlob(res.data, 'application/pdf');
                var blobUrl = URL.createObjectURL(blob);
                this.showLoadingPrintCert(false);
                window.open(blobUrl);
            })
            .catch(error => {
                this.showLoadingPrintCert(false);
                throw error;
            });
    }
    render() {
        const { isOpenCertificates, isOpenResourceLibrary, isOpenUpcomingEvents } = this.state;
        const {
            memberHomeCerts,
            memberHomeEvents,
            memberHomeResources,
            memberHomeMembershipInfo,
            loadingCertification,
            loadingEvent,
            loadingResourceLibrary,
        } = this.props;
        return (
            <Container key="container">
                <Row className="mt-2">
                    <Col xs={12} lg={6}>
                        <MembershipDashboardWidget />
                        <DashboardWidget
                            title="UPCOMING EVENTS"
                            // iconImagePath="/assets/icons/icon_mbr_home_event.png"
                            isOpen={isOpenUpcomingEvents}
                            toggleCollapse={() => this.toggleCollapse('isOpenUpcomingEvents')}
                        >
                            {loadingEvent ? <LoadingSpinner /> : <DashboardUpcomingEvents events={memberHomeEvents} />}
                        </DashboardWidget>
                    </Col>
                    <Col xs={12} lg={6}>
                        <DashboardWidget
                            isOpen={isOpenCertificates}
                            title="CERTIFICATION"
                            // iconImagePath="/assets/icons/icon_mbr_home_certification.png"
                            toggleCollapse={() => this.toggleCollapse('isOpenCertificates')}
                        >
                            {/* {loadingCertification ? <LoadingSpinner /> : <Certificates memberHomeCerts={memberHomeCerts} />} */}
                            <Certificates memberHomeCerts={memberHomeCerts} />
                        </DashboardWidget>
                        <DashboardWidget
                            isOpen={isOpenResourceLibrary}
                            title="RESOURCE LIBRARY"
                            // iconImagePath="/assets/icons/icon_mbr_resource_library.png"
                            toggleCollapse={() => this.toggleCollapse('isOpenResourceLibrary')}
                        >
                            {loadingResourceLibrary ? (
                                <LoadingSpinner />
                            ) : (
                                <Resources membershipInfo={memberHomeMembershipInfo} memberHomeResources={memberHomeResources} />
                            )}
                        </DashboardWidget>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberHomeReducer,
    };
};
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        // // Certificates
        // dispatch(memberHomeSetLoadingCertificateState(true));
        // dispatch(memberHomePanelCertGetInfo()).then(res => {
        //     dispatch(memberHomeSetLoadingCertificateState(false));
        // });
        // Application Info
        dispatch(memberHomeSetLoadingMembershipApplicationInfoState(true));
        dispatch(memberHomePanelMembershipApplicationGetInfo()).then(res => {
            dispatch(memberHomeSetLoadingMembershipApplicationInfoState(false));
        });
        // Event Info
        dispatch(memberHomeSetLoadingEventState(true));
        dispatch(memberHomePanelEventGetInfo()).then(res => {
            dispatch(memberHomeSetLoadingEventState(false));
        });
        // Resource Library
        dispatch(memberHomeSetLoadingResourceLibraryState(true));
        dispatch(memberHomePanelResourcesGetInfo()).then(res => {
            dispatch(memberHomeSetLoadingResourceLibraryState(false));
        });
    },
    /**
     * GET FROM (ACTION: getAccountProfile) API INSTEAD OF (ACTION: login) API
     *
     * Reason being that when update profile in PersonalProfilePage
     * (URL: /member/myaccount/personalprofile), the updated info doesn't get
     * updated on table used by login API. This profile info is being used by
     * top header panel on member site.
     */
    getProfileInfo: () => {
        return dispatch(personalProfileGetProfileInfo());
    },
    printCertByCompanyId: companyId => {
        return dispatch(staffCompanyDetailsPrintCertByCompanyId(companyId));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberHomeOld));
