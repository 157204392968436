import React from 'react';
import { Col, Row } from 'reactstrap';

const iconStyle = {
    fontSize: '40px',
};

const PreviewPanel1 = ({ textColor, membersExpiring }) => {
    const year = new Date().getFullYear();
    return (
        <PreviewPanelWrapper textColor={textColor} icon="fa-users">
            <Col sm={7}>
                <h4 className="pt-3">
                    Members <br />
                    Expiring in {year}
                </h4>
            </Col>
            <Col sm={5} className="text-right">
                <h1 className="display-4 text-bold">{membersExpiring}</h1>
            </Col>
        </PreviewPanelWrapper>
    );
};

export class PreviewPanelWrapper extends React.Component {
    render() {
        const { children, textColor, icon } = this.props;
        return (
            <div style={{ color: textColor }} className="panel-wrapper">
                <Row className="d-flex" style={{ minHeight: '110px' }}>
                    {children}
                </Row>
                <Row className="pt-4 d-flex justify-content-between">
                    <Col xs="12" sm="auto" className="d-flex align-items-end">
                        <i className={`fas ${icon}`} style={iconStyle} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default PreviewPanel1;
