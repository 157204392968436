// Staff membership overview page
export const STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST = 'STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_REQUEST';
export const STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS = 'STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_SUCCESS';
export const STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE = 'STAFF_MEMBERSHIP_OVERVIEW_GET_INFO_FAILURE';

// Staff membership applications page
export const STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST = 'STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_REQUEST';
export const STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS = 'STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_SUCCESS';
export const STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE = 'STAFF_MEMBERSHIP_APPLICATIONS_GET_LIST_FAILURE';

export const STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST = 'STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_REQUEST';
export const STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS = 'STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_SUCCESS';
export const STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE = 'STAFF_MEMBERSHIP_APPLICATIONS_COUNT_GET_FAILURE';

export const STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS = 'STAFF_MEMBERSHIP_APPLICATIONS_SET_FILTER_STATUS';
