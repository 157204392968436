import React from 'react';
import { HorizontalBar, Chart, Bar } from 'react-chartjs-2';

class CyderVerticalBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            data: {},
        };
    }
    componentDidMount() {
        let chartData = {
            labels: this.props.labels,
            total: this.props.total,
            datasets: [
                {
                    label: this.props.title,
                    data: this.props.data,
                    backgroundColor: 'rgba(120,190,32,0.2)',
                    borderColor: 'rgba(33, 192, 64,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(33, 192, 64,0.4)',
                    hoverBorderColor: 'rgba(33, 192, 64,1)',
                    showBarValue: true,
                },
            ],
        };
        const options = {
            legend: {
                display: false,
            },
        };
        this.setState({
            data: chartData,
            options,
        });

        Chart.pluginService.register({
            afterDraw: () => {
                if (this.refs != null && this.refs.chart != null && this.refs.chart.chartInstance != null) {
                    let ctx = this.refs.chart.chartInstance.chart.ctx;
                    ctx.font = '12px Verdana';
                    ctx.fillStyle = 'gray';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    let total = this.refs.chart.chartInstance.chart.config.data.total;
                    this.refs.chart.chartInstance.chart.config.data.datasets.forEach(function(dataset) {
                        if (dataset.showBarValue) {
                            const dataArray = dataset.data;
                            let metaKey = 0;
                            for (var k in dataset._meta) {
                                metaKey = k;
                            }
                            var meta = dataset._meta[metaKey];
                            meta != null &&
                                meta.data.forEach(function(bar, index) {
                                    let percentage = Math.round((dataArray[index] / total) * 100);
                                    let minTreshold = 20;
                                    let maxTreshold = 90;
                                    let yPos = percentage < minTreshold ? -20 : percentage > maxTreshold ? 20 : 20;
                                    let xPos = 0;
                                    ctx.fillText(dataArray[index] + ' (' + percentage + '%)', bar._view.x + xPos, bar._view.y + yPos);
                                });
                        }
                    });
                }
            },
        });
    }

    render() {
        return <Bar height={this.props.height ? this.props.height : 100} option={this.state.options} ref="chart" data={this.state.data} />;
    }
}
export default CyderVerticalBar;
