// Get - event MATERIALs
export const EVENT_GET_EVENT_MATERIALS_REQUEST = 'EVENT_GET_EVENT_MATERIALS_REQUEST';
export const EVENT_GET_EVENT_MATERIALS_SUCCESS = 'EVENT_GET_EVENT_MATERIALS_SUCCESS';
export const EVENT_GET_EVENT_MATERIALS_FAILURE = 'EVENT_GET_EVENT_MATERIALS_FAILURE';

// Save - event MATERIALs
export const EVENT_SAVE_MATERIAL_REQUEST = 'EVENT_SAVE_MATERIAL_REQUEST';
export const EVENT_SAVE_MATERIAL_SUCCESS = 'EVENT_SAVE_MATERIAL_SUCCESS';
export const EVENT_SAVE_MATERIAL_FAILURE = 'EVENT_SAVE_MATERIAL_FAILURE';

// Update - event MATERIALs
export const EVENT_UPDATE_MATERIAL_REQUEST = 'EVENT_UPDATE_MATERIAL_REQUEST';
export const EVENT_UPDATE_MATERIAL_SUCCESS = 'EVENT_UPDATEE_MATERIAL_SUCCESS';
export const EVENT_UPDATE_MATERIAL_FAILURE = 'EVENT_UPDATE_MATERIAL_FAILURE';

// Add, save, and delete - Single MATERIALs
export const EVENT_SAVE_NEW_EVENT_MATERIAL = 'EVENT_ADD_NEW_EVENT_MATERIAL';
export const EVENT_SAVE_SINGLE_EVENT_MATERIAL = 'EVENT_SAVE_SINGLE_EVENT_MATERIAL';
export const EVENT_DELETE_SINGLE_EVENT_MATERIAL = 'EVENT_DELETE_SINGLE_EVENT_MATERIAL';

// Set - event Id
export const EVENT_MATERIAL_SET_EVENT_ID = 'EVENT_MATERIAL_SET_EVENT_ID';

// Input fields action
export const EVENT_MATERIAL_CHANGE_FIELD_VALUE = 'EVENT_MATERIAL_CHANGE_FIELD_VALUE';

// Reset - all fields
export const EVENT_MATERIAL_RESET_FIELDS = 'EVENT_MATERIAL_RESET_FIELDS';

// Modal
export const EVENT_MATERIAL_SET_MODAL_DATA = 'EVENT_MATERIAL_SET_MODAL_DATA';
