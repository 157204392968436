import React from 'react';

import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import StoredLayout from '../../../../layouts/StoredLayout';

import PIEChart from 'cyder/chart/PIEChart';
import TableWidgetOrgPrimaryBusinessActivity from 'cyder/table/TableWidgetOrgPrimaryBusinessActivity';
import CertificationCrossAnalysis from './components/CertificationCrossAnalysis';
import DashboardWidget from 'cyder/widget/DashboardWidget';

import StatsSelector from './components/StatsSelector';

import { staffMembershipOverviewGetInfo } from 'actions/staff/membership/staffMembershipOverviewAction';
import Dashboard3NumbersWidget from 'cyder/widget/Dashboard3NumbersWidget';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

class StaffMembershipOverviewPage extends React.Component {
    constructor() {
        super();
        this.state = {
            currentYear: new Date().getFullYear(),
            lastYear: new Date().getFullYear() - 1,
            nextYear: new Date().getFullYear() + 1,
        };
    }
    componentDidMount() {
        this.props.staffMembershipOverviewGetInfo();
    }
    render() {
        const activeSGBCMembersStats = [
            {
                value: this.props.statistics.expiredLastYear,
                label: 'Expired in ' + this.state.lastYear,
            },
            {
                value: this.props.statistics.expiringCurrentYear,
                label: 'Expiring in ' + this.state.currentYear,
            },
            {
                value: this.props.statistics.expiringNextYear,
                label: 'Expiring in ' + this.state.nextYear,
            },
        ];
        const newMembershipsStats = [
            {
                value: this.props.statistics.newMembersCount,
                label: 'New Members ' + this.state.currentYear,
            },
            {
                value: this.props.statistics.renewedForLastYear,
                label: 'Renewed for Membership Expired in ' + this.state.lastYear,
            },
            {
                value: this.props.statistics.renewedThisYear,
                label: 'Renewed for Membership Expired in ' + this.state.currentYear,
            },
        ];
        const newMemberApplicationsStats = [
            {
                value: this.props.statistics.membershipSubmittedCount,
                label: 'Submitted',
            },
            {
                value: this.props.statistics.membershipProcessing,
                label: 'Processing',
            },
            {
                value: this.props.statistics.membershipPendingPayment,
                label: 'Pending Payment',
            },
        ];
        return this.props.loading ? (
            <LoadingSpinner />
        ) : (
            <div className="page-widget">
                {/* <StatsSelector /> */}
                <Row>
                    <Col xs={12} md={3}>
                        <Dashboard3NumbersWidget title="Expiring Members" items={activeSGBCMembersStats} />
                    </Col>
                    <Col xs={12} md={3}>
                        <Dashboard3NumbersWidget title="New Members" items={newMembershipsStats} />
                    </Col>
                    <Col xs={12} md={3}>
                        <Dashboard3NumbersWidget title="New Applications" items={newMemberApplicationsStats} />
                    </Col>
                    <Col xs={12} md={3}>
                        <CertificationCrossAnalysis />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <DashboardWidget title="Organisation Primary Business Activity" description="">
                            <TableWidgetOrgPrimaryBusinessActivity items={this.props.businessdata} />
                        </DashboardWidget>
                    </Col>
                    <Col xs={12} md={6}>
                        <PIEChart items={this.props.posmedata} title="Proportion of SMEs" />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // TODO: cleanup and remove next 2 items
        posmedata: state.staffMembershipStatistics.dashboard.proportionOfSME,
        businessdata: state.staffMembershipStatistics.dashboard.primaryBusinessActivity,
        ...state.staffMembershipOverviewReducer,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        staffMembershipOverviewGetInfo: () => {
            dispatch(staffMembershipOverviewGetInfo());
        },
        addProduct: () => {},
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipOverviewPage));
