// Panel info
export const MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST = 'MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST';
export const MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS = 'MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS';
export const MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE = 'MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE';

export const MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST = 'MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST';
export const MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS = 'MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS';
export const MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE = 'MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE';

export const MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST = 'MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST';
export const MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS = 'MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS';
export const MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE = 'MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE';

export const MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST = 'MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST';
export const MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS = 'MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS';
export const MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE = 'MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE';
export const MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST = 'MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST';
export const MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS = 'MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS';
export const MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE = 'MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE';

export const MEMBER_HOME_SET_LOADING_STATE = 'MEMBER_HOME_SET_LOADING_STATE';
export const MEMBER_HOME_SET_LOADING_EVENT_STATE = 'MEMBER_HOME_SET_LOADING_EVENT_STATE';
export const MEMBER_HOME_SET_LOADING_NEWS_STATE = 'MEMBER_HOME_SET_LOADING_NEWS_STATE';
export const MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE = 'MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE';
export const MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE = 'MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE';
export const MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE = 'MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE';
export const MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE = 'MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE';
