import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib.js';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config.js';

export function getAllTickets(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-ticket-type`, {
            action: 'get-by-eventid',
            id: eventId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function getXeroInventoryItems() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/xero/inventory-item`, {
            action: 'get-all-active',
            entity: 'SGBCGreenCouncil',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function saveTickets(eventId, tickets) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-ticket-type`, {
            action: 'save-ticket-types',
        });
        const body = {
            eventId,
            tickets,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
