import React from 'react';
import { connect } from 'react-redux';
import { validateFromValidationObjectPromise as validateObjects } from '../../js/validation';
import history from '../../history';

import { Form, Alert, Button, Row, Col, Input, Container, FormGroup, Label } from 'reactstrap';
import Select, { Async } from 'react-select';
import InputComponent from 'reactstrap-input';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
import TopNavigation2 from '../../layouts/TopNavigation2';
import DialogModal from 'cyder/modals/DialogModal';
import { SGBC_S3_ROOT } from 'config';

import {
    createAccountCorporatePreview,
    createAccountCorporate,
    onChangeField,
    createAccountCorporateSetDefaultCountry,
    createAccountCorporateResetFields,
    prefillCompanyInfo,
    createAccountSetModalData,
    createAccountSetPreviewOkay,
} from '../../actions/account/createAccountAction';
import { getCountriesGeneric } from '../../actions/misc/countryAction';
import { dialogModalToggle } from '../../actions/modal/dialogModalAction';
import { loginPageGetCompaniesAction } from '../../actions/security/cyderSecurityAction';
import { getValidationObj } from '../../actions/account/createAccountAction.js';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';
import { cyderLoginAction, getCompanies } from '../../actions/security/cyderSecurityAction';

import { loginPanelStyle3, asyncSelectStyle, InputComponentWrapper } from '../../pages/public/PublicLogin';
import { actionExecutor } from 'js/util';

const divStyle = {
    paddingBottom: '20px',
};
const loadingSpinnerStyle = {
    color: '#76777b',
    fontWeight: 'bold',
    margin: '20px 0px',
};

class CreateAccountComponent extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.submitLogin = this.submitLogin.bind(this);
        this.downloadTermAndConditionsDocument = this.downloadTermAndConditionsDocument.bind(this);
        this.state = {
            agreed: false,
            loading: false,
            noCompany: false,
            buttonClicked: false,
            isCompanySelected: false,
            companysubmitted: false,
            companyList: [],
            countries: [],
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            companySelectedValue: {
                companyname: '',
                label: '',
            },
            selectedCountry: {
                label: 'Singapore',
                value: '200',
            },
        };
    }
    async downloadTermAndConditionsDocument() {
        const { findByCategoryAndName } = this.props;
        try {
            let name = 'Registration Terms and Conditions';
            const res = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + res.data.resource;
            window.open(url);
        } catch (error) {
            // this.showModal('Error', 'Term and Conditions is not available', null, false, true);
            throw error;
        }
    }
    // LIFECYCLE
    componentDidMount() {
        window.scrollTo(0, 80);
        if (this._isMounted) this.getRelevantStuff();

        /**
         * If user navigate to public login page but is intended to create new account;
         * then set loginFrom back to default 'member' instead of indentify through which
         * public menu is clicked (untrackable)
         *
         * loginFrom is set to empty if action is login as they require to go through
         * read mode on company profile page.
         */
        const loginFrom = localStorage.getItem('loginFrom');
        if (!loginFrom) localStorage.setItem('loginFrom', 'member');
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getRelevantStuff = async () => {
        try {
            const { data } = await this.props.getCountriesGeneric();
            const countries =
                data &&
                data.map(({ name, id }) => {
                    return {
                        label: name,
                        value: id,
                    };
                });
            if (this._isMounted)
                this.setState({
                    countries,
                });
        } catch (error) {
            throw error;
        }
    };
    showLoading = loading => {
        if (this._isMounted) this.setState({ loading });
    };
    changeButtonClickedState = () => {
        this.setState({
            buttonClicked: !this.state.buttonClicked,
        });
    };
    countryChange = e => {
        this.props.setCountry(e.value);
        this.setState({
            selectedCountry: e,
        });
    };
    resetForm = () => {
        // RESET REDUCER STATE
        this.props.createAccountCorporateResetFields();
        // RESET LOCAL STATE
        this.setState({
            companysubmitted: false,
            agreed: false,
            noCompany: false,
            buttonClicked: false,
            isCompanySelected: false,
            companysubmitted: false,
        });
    };
    noCompanyFound = () => {
        this.setState({
            noCompany: true,
            companysubmitted: !this.state.companysubmitted,
        });
    };
    toggleCheckbox = () => {
        this.setState({
            agreed: !this.state.agreed,
        });
    };
    getOptionsCompanies = async input => {
        try {
            const companyList = await this.props.getCompanyList(input);
            this.setState({
                companyList,
            });
            return companyList;
        } catch (error) {
            throw error;
        }
    };
    changeCompanySelectedState = val => {
        if (!val) return;
        // PREFILL COMPANY INFO
        const isCompanySelected = typeof val === 'object';
        const companySelectedValue = isCompanySelected
            ? val
            : {
                  companyname: val,
                  label: val,
              };
        this.setState({
            companyList: [],
            isCompanySelected,
            companySelectedValue,
        });
        this.props.prefillCompanyInfo(val);
    };
    changeCompanySubmitted = () => {
        const { companysubmitted, countries } = this.state;
        const { setCountry } = this.props;
        const countryId = this.props.fields.country_id;
        // GET AND SET COUNTRY LIST
        setCountry(countryId);
        const selectedCountry = countries.find(x => x.value === countryId);
        this.setState({
            noCompany: false,
            selectedCountry,
            companysubmitted: !companysubmitted, // IS NEW COMPANY SUBMITTED / OR NOT
        });
    };
    createAccountCorporate = async noCompany => {
        try {
            this.showLoading(true);
            const res = await this.props.createAccountCorporate(noCompany);

            if (res.data !== undefined && res.data !== null) {
                const { errormessage, errcode } = res.data;
                const modalBody =
                    !noCompany && !errcode
                        ? 'Thank you for registering with SGBC Online. You will receive notification upon verification of your account.'
                        : 'Your Corporate Profile has been created.';
                const modalData = !errcode
                    ? {
                          modalBody,
                          modalOnlyOneButton: true,
                          modalAction: () => {
                              this.props.dialogModalToggle();
                              this.props.createAccountCorporateResetFields();
                              this.props.setPreviewOkay(false);
                              // Updated by Fernando on 20 Feb 2019, from '' to createAccount, coz we do not have chooseCompany anymore
                              this.props.setCreateAccountStatus('createAccount');
                              this.setState({ companySubmitted: false });
                              if (noCompany) this.submitLogin();
                              if (!noCompany) this.props.changePanel(3);
                          },
                      }
                    : {
                          modalHeader: 'Error',
                          modalBody: errormessage,
                          modalOnlyOneButton: true,
                          modalAction: () => {
                              // RESET STATE
                              this.props.setPreviewOkay(false);
                              // Updated by Fernando on 20 Feb 2019, from chooseCompany to createAccount, coz we do not have chooseCompany anymore
                              this.props.setCreateAccountStatus('createAccount');
                              this.setState({
                                  companysubmitted: false,
                                  agreed: false,
                                  noCompany: false,
                                  buttonClicked: false,
                                  isCompanySelected: false,
                                  companysubmitted: false,
                              });
                              return 'forceUpdate';
                          },
                      };
                this.props.dialogModalToggle();
                this.props.setEventModalData(modalData);
            } else {
                if (res.error) {
                    throw res.message;
                }
            }
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            // HANDLER FOR CONDITION WHERE USER CLICKED OUTSIDE OF DIALOG MODAL
            this.setState({
                agreed: false,
            });
        }
    };
    validateBeforePreview = () => {
        const requiredObj = {
            email: true,
            password: true,
            confirmpassword: true,
        };
        return this.props.getValidationObj().then(async () => {
            const { validation, createAccountCorporatePreview } = this.props;
            let { companysubmitted, noCompany } = this.state;
            const res = await validateObjects(validation, requiredObj);
            // updated by Fernando on 20 Feb 2019, to show create account page, instead of showing the company dropdown, so companysubmitted is forced to be TRUE
            companysubmitted = true;
            // updated by Fernando on 20 Feb 2019, to show always force to create company account
            noCompany = true;
            if (res && companysubmitted) createAccountCorporatePreview(noCompany);
            return res;
        });
    };
    areValuesFilled = fields => {
        // CHECK IF ALL FIELDS ARE FILLED
        for (let key in fields) {
            if (!(key === 'companyId' || key === 'country_id' || key === 'company_id')) {
                if (!fields[key]) return true;
            }
        }
        return false;
    };
    getCompanyId(input) {
        const callback = async () => {
            const { data } = await this.props.getCompany(input);
            return data.length > 0 ? data[0].id : null;
        };
        return actionExecutor(this.showLoading, callback);
    }
    async submitLogin() {
        const { fields, submitLogin, setLayout } = this.props;
        const data = {
            username: fields.email,
            password: fields.password,
            organization: await this.getCompanyId(fields.companyname),
        };
        const callback = async () => {
            const res = await submitLogin(data);
            if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                var navigate = '/changepassword';
                history.push(navigate);
                return;
            }
            setLayout('top-navigation-2');
            history.push('/member/myaccount/companyprofile');
        };
        actionExecutor(this.showLoading, callback);
    }
    render() {
        const notFilled = this.areValuesFilled(this.props.fields);
        let { noCompany } = this.state;
        const additionalInputProps = {
            handleChange: this.props.handleChange,
            validationObj: this.props.validation,
            saveButtonPressed: true,
        };

        // Updated by Fernando on 20 Feb 2019, to enable all, coz there is no personal account registration now
        // If previewOk then disabled else determine through noCompany
        // const shouldDisabled = this.props.previewOk || !noCompany;
        const shouldDisabled = false;

        // Updated by Fernando on 20 Feb 2019 to force to TRUE, as there is no personal account registration anymore right now
        // original value; this.state.companysubmitted
        const companysubmitted = true;

        // Updated by Fernando on 20 Feb 2019 to force to create only corporate account
        noCompany = true;

        return (
            <div>
                <DialogModal
                    modalAction={this.props.modalAction}
                    modalHeader={this.props.modalHeader}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    boundForceUpdate={this.componentDidMount}
                />
                {companysubmitted ? (
                    <Container style={divStyle} className="create-account">
                        <Row>
                            <CustomInputComp label="Country ">
                                <div>
                                    <label>Country</label>
                                    <Select
                                        autosize={false}
                                        name="selected-state"
                                        onChange={this.countryChange}
                                        options={this.state.countries}
                                        value={this.state.selectedCountry}
                                        isDisabled={shouldDisabled}
                                        styles={asyncSelectStyle}
                                        className="input-transparent"
                                        clearable={this.state.select.clearable}
                                        searchable={this.state.select.searchable}
                                    />
                                </div>
                            </CustomInputComp>
                            <CustomInputComp
                                label="Company/Organisation Name"
                                valueKey={'companyname'}
                                value={this.props.fields.companyname}
                                disabled={shouldDisabled}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                        </Row>
                        <Row>
                            <CustomInputComp
                                label="Organisation UEN or Registration No."
                                valueKey={'uen_regno'}
                                value={this.props.fields.uen_regno}
                                disabled={shouldDisabled}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                            <CustomInputComp
                                type="email"
                                label="Email"
                                valueKey={'email'}
                                value={this.props.fields.email}
                                disabled={this.props.previewOk}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                        </Row>
                        <Row>
                            <CustomInputComp
                                label="Applicant Contact First Name"
                                valueKey="firstname"
                                value={this.props.fields.firstname}
                                disabled={this.props.previewOk}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                            <CustomInputComp
                                label="Applicant Contact Last Name"
                                valueKey="lastname"
                                value={this.props.fields.lastname}
                                disabled={this.props.previewOk}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                        </Row>
                        <Row>
                            <CustomInputComp
                                type="password"
                                label="Password"
                                valueKey={'password'}
                                value={this.props.fields.password}
                                disabled={this.props.previewOk}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                            <CustomInputComp
                                type="password"
                                label="Confirm Password"
                                valueKey={'confirmpassword'}
                                value={this.props.fields.confirmpassword}
                                disabled={this.props.previewOk}
                                className="input-transparent"
                                additionalInputProps={additionalInputProps}
                            />
                        </Row>
                        <Row>
                            <AlertComponent disabled={!this.props.errorMessage} label={this.props.errorMessage} />
                            {this.state.loading ? (
                                <LoadingSpinner style={loadingSpinnerStyle} />
                            ) : !this.props.previewOk ? (
                                <Col sm={12} className="mt-4">
                                    <ButtonComponent
                                        label="Next"
                                        disabled={notFilled}
                                        className="primary-green"
                                        onClick={() => {
                                            this.validateBeforePreview().then(res => {
                                                // CHANGE STATE ON MEMBRSHIP PANEL
                                                // IF ALL INPUT FIELDS PASSED VALIDATION
                                                if (res) this.props.setCreateAccountStatus('previewAccount');
                                            });
                                        }}
                                    />
                                    <br />
                                    <div style={{ fontWeight: 'bold' }}>
                                        If you already have a Corporate Profile, click{' '}
                                        <a href="/public/login/member">
                                            <u>
                                                <span
                                                    style={{
                                                        color: '#78be20',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#78be20',
                                                    }}
                                                >
                                                    here
                                                </span>
                                            </u>
                                        </a>{' '}
                                        to login.
                                        <br /> To add a new user, please contact{' '}
                                        <a href="mailto:enquiry@sgbc.sg">
                                            <u>
                                                <span
                                                    style={{
                                                        color: '#78be20',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#78be20',
                                                    }}
                                                >
                                                    enquiry@sgbc.sg
                                                </span>
                                            </u>
                                        </a>
                                        .
                                    </div>
                                </Col>
                            ) : (
                                <Col sm={12}>
                                    <Container
                                        style={{ background: 'rgba(0, 0, 0, .1)', padding: '1em', borderRadius: 5, marginBottom: '1.5em' }}
                                    >
                                        <IAgreePanel
                                            checkedValue={this.state.agreed}
                                            onChange={this.toggleCheckbox}
                                            downloadTermAndConditionsDocument={this.downloadTermAndConditionsDocument}
                                        />
                                    </Container>
                                    <ButtonComponent
                                        label="Back"
                                        className="primary-dark"
                                        onClick={() => {
                                            this.props.setCreateAccountStatus('createAccount');
                                            this.props.changePanel(4);
                                            this.props.createAccountCorporateResetFields();
                                        }}
                                    />
                                    <ButtonComponent
                                        label="Proceed"
                                        disabled={!this.state.agreed}
                                        className="primary-green"
                                        onClick={e => this.createAccountCorporate(noCompany)}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Container>
                ) : (
                    <CompanySelection
                        companyList={this.state.companyList}
                        changePanel={this.props.changePanel}
                        changeButtonClickedState={this.changeButtonClickedState}
                        changeCompanySelectedState={this.changeCompanySelectedState}
                        setCreateAccountStatus={this.props.setCreateAccountStatus}
                        resetPage={this.resetForm}
                        changeCompanySubmitted={this.changeCompanySubmitted}
                        noCompany={this.state.noCompany}
                        noCompanyFound={this.noCompanyFound}
                        companySelectedValue={this.state.companySelectedValue}
                        isCompanySelected={this.state.isCompanySelected}
                        companySubmitted={this.state.companySubmitted}
                        getOptionsCompanies={this.getOptionsCompanies}
                        {...this.props}
                    />
                )}
            </div>
        );
    }
}

const CompanySelection = props => {
    return (
        <Container className="company-selection" style={{ marginTop: '-40px' }}>
            <Row className="text-left mt-4 mb-4">
                <Col>
                    <div className="welcome">
                        <span>Sign</span> Up
                    </div>
                </Col>
            </Row>
            <InputComponentWrapper label="Your company">
                <Async
                    cacheOptions
                    defaultOptions
                    name="company"
                    placeholder="Search company..."
                    styles={asyncSelectStyle}
                    className="input-transparent"
                    onSelectResetsInput={true}
                    escapeClearsValue={true}
                    loadOptions={props.getOptionsCompanies}
                    menuIsOpen={props.companyList.length > 0 ? true : false}
                    onChange={props.changeCompanySelectedState}
                    onInputChange={props.changeCompanySelectedState}
                    value={{
                        value: props.companySelectedValue.companyname,
                        label: props.companySelectedValue.companyname,
                    }}
                />
            </InputComponentWrapper>
            <div className="d-flex justify-content-center mt-4">
                <ButtonComponent
                    className="primary-dark"
                    label="Back"
                    onClick={() => {
                        props.changePanel(3);
                        props.changeButtonClickedState();
                    }}
                />
                <ButtonComponent
                    className="primary-green"
                    label="Next"
                    disabled={props.requesting}
                    onClick={() => {
                        props.setCreateAccountStatus('createAccount');
                        props.isCompanySelected ? props.changeCompanySubmitted() : props.noCompanyFound();
                    }}
                />
            </div>
        </Container>
    );
};

const IAgreePanel = props => {
    const { checkedValue, onChange, downloadTermAndConditionsDocument } = props;
    const fontStyle = {
        textAlign: 'left',
    };
    return (
        <div style={fontStyle}>
            <FormGroup check style={{ marginTop: '.5em' }}>
                <Form inline>
                    <FormGroup>
                        <Label>
                            <Input type="checkbox" value={checkedValue} onChange={onChange} /> &nbsp; I Accept the
                            <a href="#" onClick={() => downloadTermAndConditionsDocument()}>
                                &nbsp;<u>Terms and Conditions</u>
                            </a>
                        </Label>
                    </FormGroup>
                </Form>
            </FormGroup>
        </div>
    );
};

const AlertComponent = ({ disabled, label }) => {
    if (disabled) return null;
    return (
        <Col sm={12}>
            <Alert className="text-bold" color="danger">
                {label}
            </Alert>
        </Col>
    );
};

const CustomInputComp = props => {
    const { label, type, value, valueKey, disabled, inputStyle, children, className, additionalInputProps } = props;

    const styles = {
        background: 'transparent',
        borderRadius: 0,
        border: 'none',
        borderBottom: '1px solid #999999',
        padding: 0,
    };

    return (
        <Col sm={6}>
            <InputComponentWrapper>
                {children || (
                    <InputComponent
                        type={type || 'text'}
                        colWidth={12}
                        valueKey={valueKey}
                        className="input-transparent p-0"
                        inputProps={className}
                        inputStyle={{ ...inputStyle, ...styles }}
                        value={value}
                        label={label + ' *'}
                        inputProps={{
                            disabled,
                            maxLength: '128',
                        }}
                        {...additionalInputProps}
                    />
                )}
            </InputComponentWrapper>
        </Col>
    );
};

const ButtonComponent = props => {
    const { className, onClick, label, disabled } = props;
    return (
        <Button className={'m-2 ' + className} onClick={onClick} disabled={disabled} style={{ padding: '10px 15px' }}>
            <strong>{label}</strong>
        </Button>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.createAccountReducer,
        countries: state.countryReducer.countries,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // GET
        getCountriesGeneric: () => {
            return dispatch(getCountriesGeneric());
        },
        getCompanyList: input => {
            return dispatch(loginPageGetCompaniesAction(input));
        },
        getValidationObj: () => {
            return dispatch(getValidationObj());
        },
        getCompany: keyword => {
            return dispatch(getCompanies(keyword));
        },
        // SUBMIT
        createAccountCorporatePreview: noCompany => {
            dispatch(createAccountCorporatePreview(noCompany));
        },
        createAccountCorporate: noCompany => {
            return dispatch(createAccountCorporate(noCompany));
        },
        submitLogin: data => {
            return dispatch(cyderLoginAction(data));
        },
        // UTIL
        setLayout: layout => {
            dispatch({
                type: 'SET_CONFIG',
                config: {
                    layout: layout,
                },
            });
        },
        setCountry: countryId => {
            dispatch(createAccountCorporateSetDefaultCountry(countryId));
        },
        createAccountCorporateResetFields: () => {
            dispatch(createAccountCorporateResetFields());
        },
        prefillCompanyInfo: val => {
            dispatch(prefillCompanyInfo(val));
        },
        setPreviewOkay: bool => {
            dispatch(createAccountSetPreviewOkay(bool));
        },
        handleChange: e => {
            const key = e.target.id;
            const value = e.target.value;
            dispatch(onChangeField(key, value));
        },
        // DIALOG MODAL
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        setEventModalData: data => {
            dispatch(createAccountSetModalData(data));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation2(CreateAccountComponent));
