import withQuery from 'with-query';
import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';

export function getMyCompanyMembershipInfo() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/resource`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMyCompanyMembershipApplications() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/resource`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getMembershipTierUpgradeHistory(membershipId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/memberships_v1/membership-application-tier-response`, {
            action: 'get-tier-upgrade-history',
            membershipId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
