import React from 'react';
import history from '../../../../../history';
import { Row, Col, Input, FormGroup, Label, Button } from 'reactstrap';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import moment from 'moment';

import CyderFileUpload from 'cyder/forms/CyderFileUpload';

export const selectStyle = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};

const StaffCertificationApplicationProductCertDetails = props => {
    return props.state ? (
        <Formik
            initialValues={props.state.certDetails || {}}
            enableReinitialize={true}
            onSubmit={values => {
                props.onCertDetailsSave(values);
            }}
            render={args => {
                return <CertDetailsForm {...args} {...props} {...props.state} />;
            }}
        />
    ) : null;
};

const CertDetailsForm = ({
    mode,
    handleSubmit,
    isSubmitting,
    onDateChange,
    onServiceCategoryChange,
    onProductTypeChange,
    certDetails,
    onTextChange,
    onRatingChange,
    onCheckboxChange,
    ratingOptions,
    productTypeOptions,
    serviceCategoryOptions,
    printCert,
    handleRegenerate,
    viewUserAgreement,
    haveMua,
    setProductCatalogue,
    setProductPicture,
    isAdmin,
    handleDelete,
}) => {
    const {
        validFrom,
        validTo,
        brand,
        ratingId,
        productType,
        certificateNumber,
        mainContactNo,
        mainContactEmail,
        mainContactPerson,
        certificateRemarks,
        name,
        withAppendix,
        serviceCategory,
        serviceCategoryValue,
        certificateApplicationId,
        catalogueFileName,
        catalogueResourceId,
        pictureFileName,
        pictureResourceId,
    } = certDetails;
    const noCertAppId = certificateApplicationId ? false : true;
    const { ratingName } = ratingOptions.find(x => x.ratingId === parseInt(ratingId)) || '';
    const { id } = productTypeOptions.find(x => x.type === productType) || '';

    const processedRatingOptions = ratingOptions.map(x => {
        return { value: x.ratingId, label: x.ratingName };
    });

    return (
        <Row className="page-widget">
            <Col className="p-4">
                <Row className="pb-4" style={{ color: '#317446' }}>
                    <h3 className="text-bold">{name}</h3>
                </Row>
                <Form>
                    <Row>
                        <Col xs="6">
                            <FieldFormGroup xs={8} name="certificateNumber" label="Certification Number">
                                <Input
                                    type="text"
                                    id="certificateNumber"
                                    name="certificateNumber"
                                    value={certificateNumber || ''}
                                    onChange={e => onTextChange(e, 'certificateNumber')}
                                />
                            </FieldFormGroup>
                            <FieldFormGroup
                                xs={8}
                                label="Issued On"
                                description="E.g. DD-MM-YYYY *Note: If Issued on date is today or later, 
                                    certificate is considered Valid and will appear on the Certificate Directory 
                                    until it expires."
                            >
                                <CyderDatePicker
                                    id="validFrom"
                                    type="date"
                                    dateFormat="DD/MM/YYYY"
                                    closeOnSelect={true}
                                    onChange={value => onDateChange(value, 'validFrom')}
                                    value={moment(validFrom).format('DD/MM/YYYY')}
                                />
                            </FieldFormGroup>
                            <FieldFormGroup xs={8} label="Expiry Date">
                                <CyderDatePicker
                                    id="validTo"
                                    dateFormat="DD/MM/YYYY"
                                    closeOnSelect={true}
                                    onChange={value => onDateChange(value, 'validTo')}
                                    value={moment(validTo).format('DD/MM/YYYY')}
                                />
                            </FieldFormGroup>
                            <FieldFormGroup xs={8} name="rating" label="Rating">
                                <Select
                                    autofocus
                                    name="ratingId"
                                    value={{ value: ratingId, label: ratingName }}
                                    onChange={val => onRatingChange(val, 'ratingId')}
                                    options={processedRatingOptions}
                                    styles={selectStyle}
                                />
                            </FieldFormGroup>
                        </Col>

                        {mode === 'service' && (
                            <Col xs="6">
                                <FieldFormGroup
                                    xs={8}
                                    name="mainContactPerson"
                                    label="Main Contact Person"
                                    description="This will be shown on the Public Services Directory"
                                >
                                    <Input
                                        type="text"
                                        id="mainContactPerson"
                                        name="mainContactPerson"
                                        value={mainContactPerson || ''}
                                        onChange={e => onTextChange(e, 'mainContactPerson')}
                                    />
                                </FieldFormGroup>

                                <FieldFormGroup
                                    xs={8}
                                    name="mainContactNo"
                                    label="Main Contact Person No"
                                    description="This will be shown on the Public Services Directory"
                                >
                                    <Input
                                        type="text"
                                        id="mainContactNo"
                                        name="mainContactNo"
                                        value={mainContactNo || ''}
                                        onChange={e => onTextChange(e, 'mainContactNo')}
                                    />
                                </FieldFormGroup>

                                <FieldFormGroup
                                    xs={8}
                                    name="mainContactEmail"
                                    label="Main Contact Person Email"
                                    description="This will be shown on the Public Services Directory"
                                >
                                    <Input
                                        type="text"
                                        id="mainContactEmail"
                                        name="mainContactEmail"
                                        value={mainContactEmail || ''}
                                        onChange={e => onTextChange(e, 'mainContactEmail')}
                                    />
                                </FieldFormGroup>
                            </Col>
                        )}
                    </Row>

                    {mode === 'product' && (
                        <FieldFormGroup xs={4} name="productType" label="Product Type">
                            <Select
                                autofocus
                                name="productType"
                                isDisabled={!isAdmin}
                                options={productTypeOptions}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.type}
                                onChange={val =>
                                    onProductTypeChange(
                                        val,
                                        'productType',
                                        'productTypeId',
                                        'productCategoryId',
                                        'productTypeSubCat',
                                        'productSubCategoryId',
                                    )
                                }
                                value={{ id, type: productType }}
                                styles={selectStyle}
                            />
                        </FieldFormGroup>
                    )}
                    {mode === 'service' && (
                        <FieldFormGroup xs={4} name="serviceCategory" label="Services Category">
                            <Select
                                autofocus
                                name="serviceCategory"
                                isDisabled={true}
                                options={serviceCategoryOptions}
                                getOptionValue={option => option.id}
                                getOptionLabel={option => option.category}
                                onChange={val => onServiceCategoryChange(val, 'serviceCategory', 'serviceCategoryValue')}
                                value={{ id: serviceCategory, category: serviceCategoryValue }}
                                styles={selectStyle}
                            />
                        </FieldFormGroup>
                    )}
                    <FieldFormGroup xs={6} name="remarks" label="Remarks">
                        <Input
                            type="textarea"
                            id="remarks"
                            name="remarks"
                            disabled={false}
                            value={certificateRemarks}
                            onChange={e => onTextChange(e, 'certificateRemarks')}
                        />
                    </FieldFormGroup>

                    {mode === 'product' && (
                        <FieldFormGroup xs={6} name="catalogueFileName" label="Product Catalog">
                            <CyderFileUpload
                                attachmentId={catalogueResourceId}
                                filename={catalogueFileName}
                                onUploadSuccess={file => {
                                    setProductCatalogue(file.id, file.filename);
                                }}
                                onUploadFail={e => {
                                    throw e;
                                }}
                            />
                        </FieldFormGroup>
                    )}
                    {mode === 'product' && (
                        <FieldFormGroup xs={6} name="pictureFileName" label="Product Picture">
                            <CyderFileUpload
                                attachmentId={pictureResourceId}
                                filename={pictureFileName}
                                onUploadSuccess={file => {
                                    setProductPicture(file.id, file.filename);
                                }}
                                onUploadFail={e => {
                                    throw e;
                                }}
                            />
                        </FieldFormGroup>
                    )}
                    <FieldFormGroup>
                        <FormGroup check>
                            <Input
                                id="withAppendix"
                                type="checkbox"
                                checked={new Boolean(parseInt(withAppendix)).valueOf()}
                                onChange={e => onCheckboxChange(e, 'withAppendix')}
                            />
                            Print Certificate with Appendix
                        </FormGroup>
                    </FieldFormGroup>
                </Form>
                <Col>
                    <Row>
                        <Col style={{ paddingLeft: '0px' }}>
                            <Button
                                className="primary-btn-style"
                                disabled={isSubmitting || noCertAppId || !haveMua}
                                onClick={viewUserAgreement}
                                style={{ marginRight: '10px' }}
                            >
                                View User Agreement
                            </Button>
                            {certDetails.renewedFromCertificateId && (
                                <Button
                                    className="primary-btn-style"
                                    disabled={isSubmitting}
                                    onClick={e => {
                                        window.location.href = `/staff/certification/certificates/${mode}/certdetails/${certDetails.renewedFromCertificateId}`;
                                    }}
                                >
                                    View Old Certificate
                                </Button>
                            )}
                        </Col>
                        <Col className="justify-content-end" style={{ display: 'flex' }}>
                            <Button
                                className="primary-btn-style"
                                disabled={isSubmitting}
                                onClick={handleRegenerate}
                                style={{ marginLeft: '10px' }}
                            >
                                Re-Generate Certificate
                            </Button>
                            <Button
                                className="primary-btn-style"
                                disabled={isSubmitting}
                                onClick={printCert}
                                style={{ marginLeft: '10px' }}
                            >
                                Download Certificate
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="justify-content-end" style={{ paddingRight: '10px' }}>
                        <Button
                            disabled={!isAdmin}
                            className="text-bold primary-btn-style-outline"
                            onClick={handleDelete}
                            style={{ marginRight: '10px' }}
                        >
                            Delete Certificate
                        </Button>
                        <Button type="submit" className="primary-btn-style" disabled={isSubmitting} onClick={handleSubmit}>
                            Save Certificate
                        </Button>
                    </Row>
                </Col>
            </Col>
        </Row>
    );
};

export const FieldFormGroup = ({ xs, label, description, children, className, mandatoryStar, labelBold }) => {
    return (
        <Row>
            <Col xs={xs}>
                <FormGroup className={`pt-1 pb-1 ${className}`}>
                    {label && (
                        <Label className={labelBold && 'text-bold'}>
                            {label}
                            &nbsp;
                            {mandatoryStar && <MandatoryStar />}
                        </Label>
                    )}
                    {children}
                    {description && <small>{description}</small>}
                </FormGroup>
            </Col>
        </Row>
    );
};

export default StaffCertificationApplicationProductCertDetails;
