import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DiscontinueReasonDetailsList from 'pages/analytics/components/membership-renewal/DiscontinueReasonDetailsList';

class DiscontinueReasonDetailsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.state = {
            modal: false,
        };
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    show(title, reasonId) {
        this.setState({
            title,
            reasonId,
            modal: true,
        });
    }
    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle} style={{ color: '#317446' }}>
                    <div style={{ fontSize: '22px' }}>
                        <b>{this.state.title}</b>
                    </div>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <DiscontinueReasonDetailsList reasonId={this.state.reasonId} />
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn-style" onClick={this.toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default DiscontinueReasonDetailsPopup;
