import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

const style = {
    paddingLeft: '0.6rem',
    paddingRight: '0.6rem',
    borderWidth: '0px',
};

const IconWidget0 = ({ color, icon, topText, numberText, darkText, onClick, iconStyle, numberTextStyle, topTextStyle, className }) => {
    numberTextStyle = numberTextStyle || {
        fontSize: '70px',
        fontWeight: 'bold',
        marginTop: '-20px',
    };
    iconStyle = iconStyle || { fontSize: '40px', marginTop: '20px' };
    return (
        <Card style={{ backgroundColor: color, height: '100%' }} className="border-0 rounded-0 mb-2 btn">
            <CardBody
                style={style}
                className={`${className || 'p-4 '}
                    ${darkText ? '' : 'text-light'}`}
                onClick={onClick}
            >
                <Row className="py-1 px-3 d-flex justify-content-between">
                    <Col xs="12" sm="auto" className="p-0 text-left">
                        <h3 className="text-bold" style={topTextStyle}>
                            {topText}
                        </h3>
                        <i style={iconStyle} className={icon + ' justify-content-start mb-2'} />
                    </Col>
                    <Col xs="12" sm="auto" className="p-0 text-right" style={numberTextStyle}>
                        {numberText}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default IconWidget0;
