import React from 'react';
import { Grid, Header, Segment, Card, Form, Input } from 'semantic-ui-react';
import Box from '../components/Box';
import Loading from '../components/Loading';
import { mobile, tablet } from '../dummy';

class Service extends React.Component {
    render() {
        const { serviceCategories, onFetchServices, ratingId, onChange, onSearchSubmit, loading } = this.props;
        if (loading) return <Loading />;

        return (
            <Grid stackable centered className="category">
                <Grid.Column width={12}>
                    <Segment basic>
                        <Card.Group itemsPerRow={mobile ? 1 : tablet ? 2 : 4}>
                            {serviceCategories &&
                                serviceCategories.map(card => (
                                    <Box
                                        type="service"
                                        key={card.id}
                                        src={`/assets/icons/${card.filename}`}
                                        icon={card.icon}
                                        iconSize="small"
                                        header={card.category}
                                        onClick={() => onFetchServices(card.id, ratingId, card.category)}
                                    />
                                ))}
                        </Card.Group>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Service;
