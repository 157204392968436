import React from 'react';
import { Col, Row, CardBody } from 'reactstrap';
// import moment from 'moment';
// import { connect } from 'react-redux';
// import ReactTable from 'react-table';
// import CyderReactTable from 'cyder/table/CyderReactTable';
// import { Link } from 'react-router-dom';
// import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';

export const AnalyticsBigText = ({ text, textColor, alignCenter, onClick, headingSize }) => {
    switch (headingSize) {
        case '3':
        case 3:
            return (
                <h3 onClick={onClick} style={alignCenter ? { color: textColor, textAlign: 'center' } : { color: textColor }}>
                    {text}
                </h3>
            );
        case '4':
        case 4:
            return (
                <h4 onClick={onClick} style={alignCenter ? { color: textColor, textAlign: 'center' } : { color: textColor }}>
                    {text}
                </h4>
            );
        default:
            return (
                <h2 onClick={onClick} style={alignCenter ? { color: textColor, textAlign: 'center' } : { color: textColor }}>
                    {text}
                </h2>
            );
    }
};

export const AnalyticsNormalText = ({ text, textColor }) => <div style={{ color: textColor }}>{text}</div>;

export const AnalyticsCardBigText = ({ text, textColor }) => (
    <div style={{ color: textColor }}>
        {' '}
        <span style={{ fontWeight: '600', fontSize: '16px' }}> {text} </span>
    </div>
);

export const AnalyticsBigTextWithNormalText = ({
    customBigText,
    bigText,
    bigxs,
    customNormalText,
    normalText,
    normalxs,
    bigTextColor,
    normalTextColor,
}) => (
    <Col className="mb-2">
        <Row className="d-flex">
            <Col xs={bigxs} className="pl-0">
                {customBigText || <h3 style={{ color: bigTextColor }}>{bigText}</h3>}
            </Col>
            <Col xs={normalxs} className="pl-0">
                {customNormalText || <span style={{ color: normalTextColor, paddingTop: '7px' }}>{normalText}</span>}
            </Col>
        </Row>
    </Col>
);
