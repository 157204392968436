import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import { SGBC_S3_ROOT } from 'config';
import SecuringWrapper from 'cyder/common/SecuringWrapper';
import { Container, CardBody, Button, Row, Col } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import {
    getMembershipApplicationInfo,
    getMembershipApplicationPackage,
    getMembershipApplicationTierResponse,
    getMembershipApplicationTierDocs,
    withdraw,
    printCert,
} from 'actions/member/membership/application/membershipApplicationAction';

import moment from 'moment';
const DetailsPanelButton = ({ onClick, style, icon, label, className, children }) => (
    <React.Fragment>
        <Button
            className={'primary-btn-style ' + className}
            onClick={onClick}
            style={{
                ...style,
                fontSize: '15px',
            }}
        >
            <i className="mr-1 material-icons align-text-bottom ">{icon}</i>
            {label}
        </Button>
        {children}
        <br />
    </React.Fragment>
);
const TierResponsePanel = ({ tierResponseInfo, tierResponseProcessor, loading }) => {
    if (loading) {
        return <LoadingSpinner />;
    } else {
        const newResInfo = tierResponseProcessor(tierResponseInfo);
        return tierResponseInfo.length === 0 ? (
            ``
        ) : (
            <React.Fragment>
                <Row className="p-2">
                    <Col xs={12} sm={12}>
                        <h4>Responses for Membership Tier Advancement submitted.</h4>
                    </Col>
                </Row>
                <br />
                {Object.keys(newResInfo).map((question, index) => (
                    <div key={index}>
                        <div className="field-header">
                            {index + 1 + '. ' + question}
                            <br />
                        </div>
                        <ul>
                            {newResInfo[question].map((option, index) => (
                                <li className="field-label" key={index}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </React.Fragment>
        );
    }
};
const PackageDetailsPanel = ({ applicationInfo, loading }) => {
    if (loading) {
        return <LoadingSpinner />;
    } else {
        return (
            <div>
                <div className="field-header">
                    {' '}
                    Corporate Membership Package Selected:
                    <br />
                </div>
                <div className="field-label">
                    {applicationInfo.package_name} - ${applicationInfo.price.toFixed(2)} (exclusive of GST)
                </div>
            </div>
        );
    }
};
const ApplicationDetailsPanel = ({ applicationInfo, loading, loadingPrintCert, withdraw, printCert, paynow }) => {
    if (loading) {
        return <LoadingSpinner />;
    } else {
        return (
            <div>
                <FlexRow between="xs">
                    <FlexCol xs={8} md={4}>
                        <div className="field-header">
                            Membership Application
                            <br />
                        </div>
                    </FlexCol>
                    <FlexCol xs={2}>
                        {applicationInfo.membership_application_status_id < 4 && (
                            <Button className="primary-btn-style" onClick={withdraw}>
                                Withdraw
                            </Button>
                        )}
                    </FlexCol>
                    {/* {loadingPrintCert ? (
                        <LoadingSpinner />
                    ) : (
                        <FlexCol xs={2}>
                            {applicationInfo.membership_application_status_id == 5 && (
                                <Button className="primary-btn-style" onClick={printCert}>
                                    Download Certificate
                                </Button>
                            )}
                        </FlexCol>
                    )} */}
                </FlexRow>
                <Row>
                    <Col md={4} lg={2}>
                        Application Ref No:
                    </Col>
                    <Col>{applicationInfo.id}</Col>
                </Row>
                <Row>
                    <Col md={4} lg={2}>
                        Status:
                    </Col>
                    <Col>{applicationInfo.status}</Col>
                </Row>
                <Row>
                    <Col md={4} lg={2}>
                        Submitted On:
                    </Col>
                    <Col>{moment(applicationInfo.application_first_submitted_timestamp).format('DD MMMM YYYY')}</Col>
                    <Col style={{ marginTop: '-20px' }}>
                        {applicationInfo.membership_application_status_id == 4 && (
                            <DetailsPanelButton
                                label="Make Payment"
                                icon="attach_money"
                                className="primary-btn-md"
                                onClick={paynow}
                                style={{
                                    marginBottom: '4px',
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
};
class MemberMembershipViewApplicationPage extends React.Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.showLoadingPrintCert = this.showLoadingPrintCert.bind(this);
        this.showLoadingOthers = this.showLoadingOthers.bind(this);
        this.withdraw = this.withdraw.bind(this);
        this.withdrawConfirm = this.withdrawConfirm.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.printCert = this.printCert.bind(this);
        this.paynowLink = this.paynowLink.bind(this);
        this.back = this.back.bind(this);
        this.edit = this.edit.bind(this);
        this.state = {
            loading: true,
            loadingPrintCert: true,
            loadingOthers: true,
            application: {},
            questions: [],
            othersState: {},
            answersState: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getRelevantStuff();
    }
    back() {
        history.goBack();
    }
    async getRelevantStuff() {
        try {
            this.showLoading(true);
            this.showLoadingPrintCert(false);
            const id = this.props.match.params.id;
            const tierRes = await this.props.getMembershipAppllicationTierResponse(id);
            const docs = await this.props.getMembershipAppllicationTierDocs(id);
            const res = await this.props.getMembershipApplicationInfo(id);
            if (res.error || res.data == null) {
                this.showModal('Error', res.message, 'load', true, true);
            } else {
                this.setState({
                    application: res.data[0][0],
                    questions: tierRes.data,
                    docs: docs.data,
                });
                this.showLoading(false);
            }
        } catch (error) {
            this.showLoading(false);
            this.showModal('Error', error.message, 'load', true, true);
            throw error;
        }
    }
    tierResponsePostProcessor(tierResponseInfo) {
        const newResponse = {};
        tierResponseInfo.forEach(response => {
            if (!newResponse[response.question]) {
                newResponse[response.question] = [];
            }
            newResponse[response.question].push(response.option);
        });
        return newResponse;
    }
    modalAction() {
        if (this.state.modal.type === 'withdrawConfirm') {
            this.withdraw();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    withdrawConfirm() {
        this.showModal('Confirmation', 'Click OK to delete application.', 'withdrawConfirm', false, false);
    }
    printCert() {
        this.showLoadingPrintCert(true);
        this.props
            .printCert(this.state.application.id)
            .then(res => {
                var blob = b64toBlob(res.data, 'application/pdf');
                var blobUrl = URL.createObjectURL(blob);
                this.showLoadingPrintCert(false);
                window.open(blobUrl);
            })
            .catch(error => {
                this.showLoadingPrintCert(false);
                throw error;
            });
    }
    withdraw() {
        this.props
            .withdraw(this.state.application.id)
            .then(res => {
                if (res.error) {
                    this.showModal('Error', res.message);
                } else {
                    history.replace('/member/membership/overview');
                }
            })
            .catch(error => {
                this.showModal('Error', error.message);
                throw error;
            });
    }
    edit() {
        const { id, application_type } = this.state.application;
        let link = `/member/membership/${application_type === 'Renew' ? 'renewapplication' : 'newapplication'}/2/` + id;
        history.push(link);
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    showLoadingPrintCert(show) {
        this.setState({
            loadingPrintCert: show,
        });
    }
    showLoadingOthers(show) {
        this.setState({
            loadingOthers: show,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    paynowLink() {
        const { id } = this.props.match.params;
        history.push(`/member/membership/application/${id}/paynow`);
    }

    render() {
        console.log(this.state.application);
        let docNumber = 1;
        return (
            <Container>
                <br />
                <div className="page-widget">
                    <CardBody>
                        <DialogModal
                            modalAction={this.modalAction}
                            negativeButtonAction={this.negativeModalAction}
                            actionToggleExternal={this.modalToggle}
                            modalOpen={this.state.modal.showModal}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                        />
                        <Row className="mb-2">
                            <Col xs={12} className="d-flex">
                                <Button className="primary-btn-style-outline" onClick={this.back}>
                                    <i className="material-icons align-text-bottom">chevron_left</i>
                                    Back
                                </Button>
                            </Col>
                        </Row>
                        <br />
                        <ApplicationDetailsPanel
                            withdraw={this.withdrawConfirm}
                            printCert={this.printCert}
                            loading={this.state.loading}
                            loadingPrintCert={this.state.loadingPrintCert}
                            applicationInfo={this.state.application}
                            paynow={this.paynowLink}
                        />
                    </CardBody>
                    <br />
                    <CardBody>
                        <PackageDetailsPanel loading={this.state.loading} applicationInfo={this.state.application} />
                    </CardBody>
                    {this.state.application.status == 'Draft' ? (
                        <CardBody>
                            <Row xs={4} className="mb-2">
                                <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
                                    <Col xs={1} className="d-flex">
                                        <Button className="primary-btn-style" onClick={this.edit}>
                                            Edit
                                        </Button>
                                    </Col>
                                </SecuringWrapper>
                                <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>
                                    <Col xs={1} className="d-flex">
                                        <Button className="primary-btn-style" onClick={this.withdrawConfirm}>
                                            Withdraw
                                        </Button>
                                    </Col>
                                </SecuringWrapper>
                                {/* <SecuringWrapper isCorporateAdmin isSecondarymr isOmr>  
                                <Col xs={1} className="d-flex">
                                    <Button className="primary-btn-style" onClick={this.submit}>
                                        Submit
                                    </Button>
                                </Col>
                            </SecuringWrapper>       */}
                            </Row>
                        </CardBody>
                    ) : null}
                    <br />
                </div>
                {this.state.docs != null && Object.keys(this.tierResponsePostProcessor(this.state.questions)).length > 0 && (
                    <div className="page-widget">
                        <CardBody>
                            <TierResponsePanel
                                loading={this.state.loading}
                                tierResponseInfo={this.state.questions}
                                tierResponseProcessor={this.tierResponsePostProcessor}
                            />
                            {this.state.docs != null && this.state.docs.length >= 1 && (
                                <span className="field-header">
                                    These are the documents that you have uploaded:
                                    <br />
                                </span>
                            )}
                            {(this.state.docs == null || this.state.docs.length <= 0) && <b>You have not uploaded any document</b>}

                            {this.state.docs != null && this.state.docs.length >= 1 && this.state.docs[0].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[0].docId}`}>
                                    <u>{docNumber++}. Internal Corporate Sustainability Policies & Practices</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 2 && this.state.docs[1].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[1].docId}`}>
                                    <u>{docNumber++}. Internal Statement of Commitment on Sustainable Supplier Engagement Practices</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 3 && this.state.docs[2].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[2].docId}`}>
                                    <u>{docNumber++}. Published Sustainability Report</u>
                                    <br />
                                </a>
                            ) : null}
                            {this.state.docs != null && this.state.docs.length >= 4 && this.state.docs[3].docId != null ? (
                                <a style={{ color: 'dark grey' }} target="blank" href={`${SGBC_S3_ROOT}${this.state.docs[3].docId}`}>
                                    <u>{docNumber++}. 3rd Party Certified Environmental Management Systems</u>
                                    <br />
                                </a>
                            ) : null}
                        </CardBody>
                    </div>
                )}
            </Container>
        );
    }
}
export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberNewMembershipApplicationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipAppllicationTierResponse: id => {
            return dispatch(getMembershipApplicationTierResponse(id));
        },
        getMembershipAppllicationTierDocs: id => {
            return dispatch(getMembershipApplicationTierDocs(id));
        },
        getMembershipApplicationInfo: id => {
            return dispatch(getMembershipApplicationInfo(id));
        },
        getMembershipApplicationPackage: id => {
            return dispatch(getMembershipApplicationPackage(id));
        },
        withdraw: id => {
            return dispatch(withdraw(id));
        },
        printCert: id => {
            return dispatch(printCert(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberMembershipViewApplicationPage);
