import {
    EVENT_ALL_GET_EVENTS_REQUEST,
    EVENT_ALL_GET_EVENTS_SUCCESS,
    EVENT_ALL_GET_EVENTS_FAILURE,
    EVENT_ALL_CHANGE_INPUT,
    EVENT_ALL_SEARCH_KEYWORD,
} from '../../../constants/events/actionTypesStaffEvents';
import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';

export function eventsAllGetEventsAction(page, pageSize, keyword, sorted, filtered, status) {
    return (dispatch, getState) => {
        const url = withQuery(SGBC_API_CORS_ROOT + '/events_v1/cn/event', {
            action: 'getAllEventList',
        });
        const params = {
            keyword,
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            sorted,
            status,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            params,
            EVENT_ALL_GET_EVENTS_REQUEST,
            EVENT_ALL_GET_EVENTS_SUCCESS,
            EVENT_ALL_GET_EVENTS_FAILURE,
        );
    };
}
export function eventsAllChangeInput(input, changetype) {
    return {
        type: EVENT_ALL_CHANGE_INPUT,
        changetype,
        input,
    };
}
