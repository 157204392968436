import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getTotalShowedUpIndividualAnalytic, getIncomeCountExpAnalytic } from 'actions/analytics/eventsAnalyticsAction';
import { type } from 'os';

class TotallShowedUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            // console.log('result', res);
            if (res.data[0][0].totalIncome == null) {
                res.data[0][0].totalIncome = 0;
            }
            if (res.data[1][0].totalExpenditure == null) {
                res.data[1][0].totalExpenditure = 0;
            }
            if (res.data[0][0].totalIncome > res.data[1][0].totalExpenditure) {
                res.data[3] = 0;
                res.data[2] = res.data[0][0].totalIncome - res.data[1][0].totalExpenditure; //surplus
            } else {
                res.data[2] = 0;
                res.data[3] = res.data[1][0].totalExpenditure - res.data[0][0].totalIncome; //deficit
                console.log('Deficit', res.data[3]);
            }
            // console.log('result after', res);
            this.setState({
                data: res.data,
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                // console.log('result', res);
                if (res.data[0][0].totalIncome == null) {
                    res.data[0][0].totalIncome = 0;
                }
                if (res.data[1][0].totalExpenditure == null) {
                    res.data[1][0].totalExpenditure = 0;
                }
                if (res.data[0][0].totalIncome > res.data[1][0].totalExpenditure) {
                    res.data[3] = 0;
                    res.data[2] = res.data[0][0].totalIncome - res.data[1][0].totalExpenditure; //surplus
                } else {
                    res.data[2] = 0;
                    res.data[3] = res.data[1][0].totalExpenditure - res.data[0][0].totalIncome; //deficit
                    console.log('Deficit in update', res.data[3]);
                }
                // console.log('result after', res);
                this.setState({
                    data: res.data,
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }
    formatAmount(amount) {
        return new Intl.NumberFormat('en-SG', {
            style: 'currency',
            currency: 'SGD',
            minimumFractionDigits: 2,
        }).format(amount);
    }
    render() {
        const xs = {
            bigxs: 5,
            normalxs: 7,
        };
        return (
            <AnalyticsCard title={`Income and Expenditure`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        {/* <AnalyticsBigText text={this.state.data.totalIndividualShowedUp} textColor={`#78BE20`} /> */}
                        <AnalyticsBigTextWithNormalText
                            normalText={`Total Income`}
                            normalTextColor={`unset`}
                            bigText={this.formatAmount(this.state.data[0][0].totalIncome)}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Total Expenditure`}
                            normalTextColor={`unset`}
                            bigText={this.formatAmount(this.state.data[1][0].totalExpenditure)}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />
                        {this.state.data[0][0].totalIncome >= this.state.data[1][0].totalExpenditure ? (
                            <AnalyticsBigTextWithNormalText
                                normalText={`Surplus`}
                                normalTextColor={`unset`}
                                bigText={this.formatAmount(Math.floor(this.state.data[2] * 100) / 100)}
                                bigTextColor={`#78BE20`}
                                {...xs}
                            />
                        ) : (
                            <AnalyticsBigTextWithNormalText
                                normalText={`Deficit`}
                                normalTextColor={`unset`}
                                bigText={this.formatAmount(Math.floor(this.state.data[3] * 100) / 100)}
                                bigTextColor={`#ed5565`}
                                {...xs}
                            />
                        )}
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getIncomeCountExpAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(TotallShowedUp));
