/**
 * Member Service renew action
 *
 * Author: Fernando Karnagi
 */
import withQuery from 'with-query';

export function setBrandEditable(editable) {
    return {
        type: 'MEMBERSHIP_SERVICE_CERTIFICATION_RENEW_SET_BRAND_EDITABLE',
        editable,
    };
}

export function setUpdateAssessment(update) {
    return {
        type: 'MEMBERSHIP_SERVICE_CERTIFICATION_RENEW_SET_ASSESSMENT_UPDATE',
        update,
    };
}
