import React from 'react';
import { SGBC_API_ROOT, SGBC_API_KEY, SGBC_CRYPTO_KEY } from 'config';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { Input, Row, Col, Button, Card } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';

import DialogModal from 'cyder/modals/DialogModal';
import MandatoryStar from 'cyder/forms/MandatoryStar';
// import history from '../../../../history';
import withQuery from 'with-query';

import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';
import { v4 as uuidv4 } from 'uuid';
// import { charge } from 'actions/member/events/payment/action.js';
var CryptoJS = require('crypto-js');

const StripeInputWrapper = props => {
    return (
        <div className="form-group">
            <div className="field-header">
                {props.label} <MandatoryStar />
                {props.wrap ? <Card className="p-2 col-12">{props.children}</Card> : props.children}
            </div>
            <small className="form-text text-muted">{props.description}</small>
        </div>
    );
};

class CreditCardPayment extends React.Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setButtonLoading = this.setButtonLoading.bind(this);
        this.onCardHolderNameChanged = this.onCardHolderNameChanged.bind(this);
        this.state = {
            cardHolderName: '',
            loading: false,
            buttonLoading: false,
            ...defaultModalKeys,
        };
    }
    onCardHolderNameChanged(e) {
        this.setState({
            cardHolderName: e.target.value,
        });
    }
    setLoading(show) {
        this.setState({ loading: show });
    }
    setButtonLoading(show) {
        this.setState({ buttonLoading: show });
    }
    toggleDialofModal(header, body, action) {
        const data = {
            modalAction: action,
            modalBody: body,
            modalHeader: header,
            modalOnlyOneButton: true,
        };
        this.props.dialogModalToggle();
        const state = setReducerModalState(setReducerModalState, data);
        this.setState(state);
    }

    async submit(ev) {
        console.log('Process Payment...');
        ev.preventDefault();
        this.setButtonLoading(true);

        let { token } = await this.props.stripe.createToken({
            name: this.state.cardHolderName,
        });

        this.setLoading(true);
        // User clicked submit

        if (token == null) {
            this.setLoading(false);
            this.setButtonLoading(false);
            this.toggleDialofModal('Error', 'Your credit card is not valid');
            return;
        }

        // Total price in cents and truncate all fraction - Ken
        const totalPrice = Math.trunc(this.props.registration.totalPrice * 100);

        const cBody = localStorage.getItem('cBody');

        const now = new Date();
        const uuid = uuidv4();

        var cipherBody = {
            amount: totalPrice,
            source: token.id,
            description: 'SGBC Event Payment',
            currency: 'sgd',
            receipt_email: this.props.registration.applicantEmail,
            date: now,
            uuid: uuid,
        };

        if (cBody !== undefined && cBody !== null) {
            var bytes = CryptoJS.AES.decrypt(cBody, SGBC_CRYPTO_KEY);
            var originalJsonDec = bytes.toString(CryptoJS.enc.Utf8);
            var originalJson = JSON.parse(originalJsonDec);
            if (originalJson.date !== undefined && originalJson.date !== null) {
                var current = new Date().getTime();
                var ten_minutes_from_now = new Date(originalJson.date).getTime() + 60000;
                if (current >= ten_minutes_from_now) {
                    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(cipherBody), SGBC_CRYPTO_KEY).toString();
                    localStorage.setItem('cBody', ciphertext);
                } else {
                    this.setLoading(false);
                    this.setButtonLoading(false);
                    this.toggleDialofModal('Error', 'We are sorry that this credit card payment is not successful, please try again...');
                    return;
                }
            }
        } else {
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(cipherBody), SGBC_CRYPTO_KEY).toString();
            localStorage.setItem('cBody', ciphertext);
        }

        const url = withQuery(SGBC_API_ROOT + '/finances_v1/stripe', {
            action: 'charge',
        });
        var body = {
            amount: totalPrice,
            source: token.id,
            description: 'SGBC Event Payment',
            currency: 'sgd',
            receipt_email: this.props.registration.applicantEmail,
            // idempotency_key: uuid
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    const updatePaymentUrl = withQuery(SGBC_API_ROOT + '/finances_v1/cn/payment', {
                        action: 'update-payment-creditcard',
                    });

                    var updatedPaymentBody = {
                        amountReceived: this.props.registration.totalPrice,
                        referenceNumber: `${token.card.object} ${token.card.brand} ...${token.card.last4}`,
                        remarks: `ClientIP ${token.client_ip}, CreatedTS ${token.created}, TokenID ${token.id}`,
                        id: this.props.registration.paymentId,
                        registrationId: this.props.registration.id,
                        eventId: this.props.registration.eventId,
                        template: 'event',
                    };

                    const paymentOption = {
                        body: JSON.stringify(updatedPaymentBody),
                        method: 'POST',
                        headers: {
                            'x-api-key': SGBC_API_KEY,
                            'Content-Type': 'application/json',
                            Authorization: 'allow',
                        },
                    };

                    var redirectUrl = '/member/events/event';
                    var path = window.location.pathname;
                    if (path.toLowerCase().indexOf('/public/') >= 0) {
                        redirectUrl = '/';
                    }
                    fetch(updatePaymentUrl, paymentOption)
                        .then(responsePayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialofModal('Confirmation', 'Your credit payment was successful.', redirectUrl);
                        })
                        .catch(errorPayment => {
                            this.setLoading(false);
                            this.setButtonLoading(false);
                            this.toggleDialofModal('Error', 'We are sorry that this credit card payment is not successful');
                            throw errorPayment;
                        });
                } else {
                    alert('We are sorry that this credit card payment is not successful');
                }
            })
            .catch(error => {
                this.setLoading(false);
                this.setButtonLoading(false);
                this.toggleDialofModal('Error', 'We are sorry that this credit card payment is not successful');
                throw error;
            });
    }
    render() {
        const inputStyle = {
            base: {
                fontSize: '18px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
        };
        if (this.state.loading) return <LoadingSpinner />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.state.modalAction}
                    modalHeader={this.state.modalHeader}
                    modalBody={this.state.modalBody}
                    modalOnlyOneButton={this.state.modalOnlyOneButton}
                    loading={this.state.loading}
                    buttonDisabler={this.state.modalLoading}
                />
                <div>
                    <Row>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="Credit Card Number" description="Please enter credit card number">
                                <CardNumberElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                        <Col xs="4">
                            <StripeInputWrapper label="Card Holder Name" description="Please enter card holder name">
                                <Input
                                    style={{ borderRadius: 5 }}
                                    value={this.state.cardHolderName}
                                    onChange={this.onCardHolderNameChanged}
                                />
                            </StripeInputWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="Credit Card Expiry" description="Please enter card expiry date">
                                <CardExpiryElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                        <Col xs="4">
                            <StripeInputWrapper wrap label="CCV" description="Please enter CCV">
                                <CardCVCElement style={inputStyle} />
                            </StripeInputWrapper>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={4}>
                            <Button style={{ marginRight: '10px' }} className="primary-btn-style-outline" onClick={this.props.back}>
                                <strong>Back</strong>
                            </Button>
                        </Col>
                        <Col xs={4} className="d-flex justify-content-end">
                            {this.state.buttonLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <Button className="primary-btn-style" onClick={this.submit}>
                                    <strong>Submit</strong>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex flex-row pt-4 pb-4" xs={6}>
                            <img src="/assets/sgbc/SGBC_credit_cards_stripe.png" width={300} height={65} />
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default injectStripe(CreditCardPayment);
