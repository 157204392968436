/**
 * List of URLs for Staff
 *
 * Author: Fernando
 */

export const urls = [
    {
        title: '',
        show: true,
        items: [
            {
                url: '/staff/home',
                icon: 'home',
                title: 'Home',
                show: true,
                items: [],
            },
            {
                url: '/staff/accounts',
                icon: 'people',
                title: 'Accounts',
                show: true,
                items: [],
            },
            {
                url: '',
                icon: 'card_membership',
                title: 'Membership',
                show: true,
                items: [
                    {
                        url: '/staff/membership/overview',
                        icon: '',
                        title: 'Overview',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/membership/applications',
                        icon: '',
                        title: 'Applications',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/membership/membership-renewal',
                        icon: '',
                        title: 'Renewals',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/membership/members',
                        icon: '',
                        title: 'Directory',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/membership/export',
                        icon: '',
                        title: 'Export',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'stars',
                title: 'Certification',
                show: true,
                items: [
                    {
                        url: '/staff/certification/overview',
                        icon: '',
                        title: 'Overview',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/mycases',
                        icon: '',
                        title: 'My Cases',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/myreview',
                        icon: '',
                        title: 'My Review',
                        show: true,
                        /*badge: {
                            className: 'badge badge-danger badge-sm badge-rounded badge-outline',
                            title: '0',
                        },*/
                        items: [],
                    },
                    {
                        url: '/staff/certification/applications',
                        icon: '',
                        title: 'Applications',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/renewal',
                        icon: '',
                        title: 'Renewal',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/certificates/product',
                        icon: '',
                        title: 'Product Certificates',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/certificates/service',
                        icon: '',
                        title: 'Services Certificates',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/assessment/scoresheet-criteria/',
                        icon: '',
                        title: 'Criteria',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/assessment/requirement',
                        icon: '',
                        title: 'Requirement',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/certification/export',
                        icon: '',
                        title: 'Export',
                        show: true,
                        items: [],
                    },
                ],
            },
            {
                url: '',
                icon: 'event',
                title: 'Events',
                show: true,
                items: [
                    {
                        url: '/staff/events/all',
                        icon: '',
                        title: 'SGBC Event',
                        show: true,
                        items: [],
                    },
                    /*{
                        url: '/staff/events/create',
                        icon: '',
                        title: 'Add New Event',
                        show: true,
                        items: [],
                    },*/
                    {
                        url: '/staff/galadinner/all',
                        icon: '',
                        title: 'Gala Dinner',
                        show: true,
                        items: [],
                    },
                    // {
                    //     url: '/staff/events/promocodes',
                    //     icon: '',
                    //     title: 'Promo Codes',
                    //     show: true,
                    //     items: [],
                    // },
                ],
            },
            {
                url: '/staff/elections/all',
                icon: 'adjust',
                title: 'Elections',
                show: true,
                items: [],
            },
            {
                url: '/staff/surveys',
                icon: 'question_answer',
                title: 'Surveys',
                show: true,
                items: [],
            },
            {
                url: '',
                icon: 'insert_chart',
                title: 'Analytics',
                show: true,
                items: [
                    {
                        url: '/staff/analytics/membership',
                        icon: '',
                        title: 'Membership',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/analytics/certification',
                        icon: '',
                        title: 'Certification',
                        show: true,
                        items: [],
                    },
                    {
                        url: '/staff/analytics/event',
                        icon: '',
                        title: 'Event',
                        show: true,
                        items: [],
                    },
                ],
            },
            // {
            //     url: '/help',
            //     icon: 'help',
            //     title: 'Help',
            //     show: true,
            //     items: [],
            // },
        ],
    },
];
export function navigationStaff(state = Array.from(urls), action) {
    switch (action.type) {
        case 'SET_STAFFNAVIGATION':
            return Object.assign({}, state);
        case 'GET_REVIEW_LIST_COUNT':
            let newState = state.slice();
            const reviewCount = action.response.data.reviewCount;
            newState[0].items[3].items[4].badge.title = reviewCount;
            return newState;
        default:
            return state;
    }
}
