import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { certificateNumberIssuedAnalytics } from 'actions/analytics/certificateAnalyticsAction';
import { HorizontalBar, Chart } from 'react-chartjs-2';

class CertificationIssuedList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.openCert = this.openCert.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    openCert(certId, type) {
        if (type == 'Service') {
            history.push(`/staff/certification/certificates/service/certdetails/${certId}`);
        } else {
            history.push(`/staff/certification/certificates/product/certdetails/${certId}`);
        }
    }

    load() {
        this.props.load().then(res => {
            if (this.props.certification.interval == 'yearly') {
                let listData = [];
                let listData2 = [];
                let startYear = moment(this.props.certification.startDate, 'DD/MM/YYYY').format('YYYY');
                let movingYear = startYear;
                let endYear = moment(this.props.certification.endDate, 'DD/MM/YYYY').format('YYYY');
                while (movingYear <= endYear) {
                    let yearRecord = {
                        year: movingYear,
                        certs: [],
                    };
                    let yearRecord2 = {
                        year: movingYear,
                        certs: [],
                    };
                    listData.push(yearRecord);
                    listData2.push(yearRecord2);
                    movingYear++;
                }
                res.data.forEach((element, elementIndex) => {
                    if (element.certNumber != null && element.certNumber != '') {
                        let certIssuedYear = moment(element.issuedOn, 'YYYY-MM-DD').format('YYYY');
                        let yearArrayIndex = certIssuedYear - startYear;

                        if (element.applicationType == 'Renewal') {
                            listData2[yearArrayIndex].certs.push({
                                certNumber: element.certNumber,
                                certificateId: element.certificateId,
                                type: element.type,
                            });
                        } else {
                            listData[yearArrayIndex].certs.push({
                                certNumber: element.certNumber,
                                certificateId: element.certificateId,
                                type: element.type,
                            });
                        }
                    }
                });

                listData = listData.map(element => {
                    let certs = [];
                    element.certs.forEach((cert, i) => {
                        let certLink = (
                            <span
                                key={i}
                                style={{
                                    paddingRight: '10px',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'undeline',
                                }}
                                onClick={e => {
                                    this.openCert(cert.certificateId, cert.type);
                                }}
                            >
                                <u>{cert.certNumber}</u>
                            </span>
                        );
                        certs.push(certLink);
                    });
                    return {
                        year: element.year,
                        certs: (
                            <div style={{ paddingRight: '5px', maxWidth: this.props.certification.chart == 'all' ? '750px' : '325px' }}>
                                {certs}
                            </div>
                        ),
                    };
                });
                listData2 = listData2.map(element => {
                    let certs = [];
                    element.certs.forEach((cert, i) => {
                        let certLink = (
                            <span
                                key={i}
                                style={{
                                    paddingRight: '10px',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'undeline',
                                }}
                                onClick={e => {
                                    this.openCert(cert.certificateId, cert.type);
                                }}
                            >
                                <u>{cert.certNumber}</u>
                            </span>
                        );
                        certs.push(certLink);
                    });
                    return {
                        year: element.year,
                        certs: (
                            <div style={{ paddingRight: '5px', maxWidth: this.props.certification.chart == 'all' ? '750px' : '325px' }}>
                                {certs}
                            </div>
                        ),
                    };
                });

                this.setState({
                    data: listData,
                    data2: listData2,
                });
            } else {
                let listData = [];
                let listData2 = [];
                let startDate = moment(this.props.certification.startDate, 'DD/MM/YYYY').startOf('month');
                let movingMonth = startDate.clone();
                let endDate = moment(this.props.certification.endDate, 'DD/MM/YYYY').startOf('month');
                while (movingMonth.isSameOrBefore(endDate)) {
                    let monthRecord = {
                        month: movingMonth.clone(),
                        certs: [],
                    };
                    listData.push(monthRecord);

                    let monthRecord2 = {
                        month: movingMonth.clone(),
                        certs: [],
                    };
                    listData2.push(monthRecord2);

                    movingMonth.add(1, 'month');
                }
                res.data.forEach((element, elementIndex) => {
                    if (element.certNumber != null && element.certNumber != '') {
                        let certIssuedMonth = moment(element.issuedOn, 'YYYY-MM-DD').format('YYYYMM');

                        //  FAKE the data for internal testing
                        // if (elementIndex % 2 == 0) {
                        //     element.applicationType = 'Renewal'
                        // } else {
                        //     element.applicationType = 'New'
                        // }

                        if (element.applicationType == 'Renewal') {
                            listData2.forEach(monthElement => {
                                if (monthElement.month.format('YYYYMM') == certIssuedMonth) {
                                    monthElement.certs.push({
                                        certNumber: element.certNumber,
                                        certificateId: element.certificateId,
                                        type: element.type,
                                    });
                                }
                            });
                        } else {
                            listData.forEach(monthElement => {
                                if (monthElement.month.format('YYYYMM') == certIssuedMonth) {
                                    monthElement.certs.push({
                                        certNumber: element.certNumber,
                                        certificateId: element.certificateId,
                                        type: element.type,
                                    });
                                }
                            });
                        }
                    }
                });
                listData = listData.map(element => {
                    let certs = [];
                    element.certs.forEach((cert, i) => {
                        let certLink = (
                            <span
                                key={i}
                                style={{
                                    paddingRight: '10px',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'undeline',
                                }}
                                onClick={e => {
                                    this.openCert(cert.certificateId, cert.type);
                                }}
                            >
                                <u>{cert.certNumber}</u>
                            </span>
                        );
                        certs.push(certLink);
                    });
                    return {
                        month: element.month,
                        certs: <div style={{ paddingRight: '5px', maxWidth: '750px' }}>{certs}</div>,
                    };
                });

                listData2 = listData2.map(element => {
                    let certs = [];
                    element.certs.forEach((cert, i) => {
                        let certLink = (
                            <span
                                key={i}
                                style={{
                                    paddingRight: '10px',
                                    fontWeight: 'normal',
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'undeline',
                                }}
                                onClick={e => {
                                    this.openCert(cert.certificateId, cert.type);
                                }}
                            >
                                <u>{cert.certNumber}</u>
                            </span>
                        );
                        certs.push(certLink);
                    });
                    return {
                        month: element.month,
                        certs: <div style={{ paddingRight: '5px', maxWidth: '750px' }}>{certs}</div>,
                    };
                });

                this.setState({
                    data: listData,
                    data2: listData2,
                });
            }
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.certification.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.certification.refresh,
            });
        }
    }

    componentDidMount() {
        this.load();
    }

    render() {
        console.log('Data', this.state.data);
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Certificates Issued within ${moment(this.props.certification.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.certification.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{this.props.certification.interval == 'yearly' ? `Year` : 'Month'}</th>
                                        {this.props.certification.chart == 'all' ? <th>Certificates</th> : <th>New</th>}
                                        {this.props.certification.chart != 'all' && <th>Renewed</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ width: '20%' }}>
                                                {this.props.certification.interval == 'yearly'
                                                    ? item.year
                                                    : item.month != null && item.month.format('MMM YYYY')}
                                            </td>
                                            <td style={this.props.certification.chart == 'all' ? { width: '80%' } : { width: '40%' }}>
                                                {item.certs}
                                            </td>
                                            {this.props.certification.chart != 'all' && (
                                                <td style={{ width: '40%' }}>{this.state.data2[i].certs}</td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        certification: state.certificationIssuanceAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(certificateNumberIssuedAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CertificationIssuedList));
