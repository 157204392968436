import React from 'react';

import { Row, Col, FormGroup, Button, Input } from 'reactstrap';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import Select from 'react-select';

import { getOptionLabelProductTypes } from 'js/reactSelectLib';

const selectStyle = {
    control: styles => ({
        ...styles,
        borderRadius: '0px',
        background: 'white',
    }),
    indicatorSeparator: styles => ({
        ...styles,
        display: 'none',
    }),
};
const DetailsPanelInput = ({ mandatory, header, value, children, type = 'text', editable = false, onChange = () => {} }) => {
    return (
        <Col>
            <label className="field-header">
                {header}&nbsp;
                {mandatory && editable && <MandatoryStar />}
            </label>
            {editable ? (
                <React.Fragment>
                    {children || <Input type={type} disabled={!editable} className="form-control" value={value} onChange={onChange} />}
                </React.Fragment>
            ) : (
                <div>{!value ? 'Not specified' : value}</div>
            )}
        </Col>
    );
};
const PreviewField = ({ header, label, editable, mandatory }) => {
    return (
        <Col xs="4">
            <FormGroup>
                <label className="field-header">
                    {header} {mandatory && editable && <MandatoryStar />}
                </label>
                &nbsp;
                <div className="field-label">{label}</div>
            </FormGroup>
        </Col>
    );
};
const DetailsPanelButton = ({ onClick, style, icon, label, className, children }) => (
    <React.Fragment>
        <Button
            className={'primary-btn-style ' + className}
            onClick={onClick}
            style={{
                ...style,
                width: '180px',
                height: '60px',
                fontSize: '15px',
            }}
        >
            <i className="mr-1 material-icons align-text-bottom ">{icon}</i>
            {label}
        </Button>
        {children}
        <br />
    </React.Fragment>
);
const ProductDetailsPanel = ({
    editable,
    loading,
    status,
    title,
    visible,
    productTypes,
    viewAssessment,
    handleInputCountryChange,
    countryList,
    manufacturerDetails,
    manufacturerNameOfCompany,
    manufacturerNameOfCountry,
    manufacturerIsoCode,
    manufacturerFactoryAddress,
    manufacturerContactPersonName,
    manufacturerTelephone,
    manufacturerCountryId,
    onYesNoOptionChange,
    onProductTypeChange,
    selectedYesNoOption,
    selectedProductType,
    selectedProductCategory,
    selectedProductSubcategory,
    downloadInvoice,
    onManufacturerDetailsChange,
    onManufacturerFactoryAddressChange,
    onManufacturerContactPersonNameChange,
    onManufacturerTelephoneChange,
    onManufacturerIsoCodeChange,
    onManufacturerCountryIdchange,
    onManufacturerNameOfCompanyChange,
    feedbackSurvey,
    activeSurveys,
    companyInfo,
    paynow,
    muaLocked,
}) => {
    const { name, mainPhoneNumber, businessAddressLine1, businessAddressLine2, businessAddressLine3, businessPostalCode } = companyInfo;
    return (
        visible && (
            <NoShadowPanel flat title={title}>
                {status !== 'Draft' && (
                    <Row>
                        <PreviewField header="Status" editable={editable} label={status} />
                        <Col>
                            <FormGroup>
                                {(status === 'Assessment' || status === 'Accepted') && (
                                    <DetailsPanelButton label="Upload Document" icon="cloud_upload" onClick={viewAssessment} />
                                )}
                                {(status === 'Invoice' || status === 'Payment') && (
                                    <DetailsPanelButton
                                        label="Download Invoice"
                                        icon="get_app"
                                        onClick={downloadInvoice}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                )}
                                {status === 'Invoice' && (
                                    <DetailsPanelButton
                                        label="Make Payment"
                                        icon="get_app"
                                        onClick={paynow}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    />
                                )}
                                {status === 'Finalisation' && (
                                    <DetailsPanelButton
                                        label="Submit Mark User Agreement"
                                        icon="finalisation"
                                        onClick={feedbackSurvey}
                                        style={{
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <small className="form-text text-muted">
                                            (*) This is the final step to get your certified product
                                        </small>
                                    </DetailsPanelButton>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                <hr />
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Company Name" editable={false} value={name} />
                    <DetailsPanelInput header="Company Phone Number" editable={false} value={mainPhoneNumber} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Address 1" editable={false} value={businessAddressLine1} />
                    <DetailsPanelInput header="Address 2" editable={false} value={businessAddressLine2} />
                    <DetailsPanelInput header="Address 3" editable={false} value={businessAddressLine3} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput header="Postal Code" editable={false} value={businessPostalCode} />
                </Row>
                <Row className="pb-4 pt-4">
                    <DetailsPanelInput
                        mandatory
                        header="Main Contact Person"
                        editable={editable}
                        value={manufacturerContactPersonName}
                        onChange={onManufacturerContactPersonNameChange}
                    />
                    <DetailsPanelInput
                        mandatory
                        header="Main Contact Phone"
                        editable={editable}
                        value={manufacturerTelephone}
                        onChange={onManufacturerTelephoneChange}
                    />
                </Row>
                <Row className="pb-4 pt-4">
                    <Col>
                        {editable && (
                            <FormGroup check>
                                <label className="field-header">
                                    <Input
                                        disabled={!editable}
                                        type="checkbox"
                                        id="isManufacturer"
                                        className="mt-2"
                                        checked={selectedYesNoOption}
                                        onChange={onYesNoOptionChange}
                                    />
                                    Are you a Distributor/Reseller/Agent ?
                                </label>
                            </FormGroup>
                        )}
                        {!editable && (
                            <React.Fragment>
                                <PreviewField
                                    mandatory
                                    editable={editable}
                                    header="Are you a Distributor/Reseller/Agent?"
                                    label={selectedYesNoOption ? 'Yes' : 'No'}
                                />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
                {/* always show  */}
                <Row>
                    <Col>
                        <FormGroup>
                            <Row className="pb-4 pt-4">
                                <DetailsPanelInput
                                    mandatory
                                    header="Name Of Manufacturing Plant"
                                    editable={editable}
                                    value={manufacturerNameOfCompany}
                                    onChange={onManufacturerNameOfCompanyChange}
                                />
                                {/* <DetailsPanelInput
                                    mandatory
                                    header="ISO"
                                    editable={editable}
                                    value={manufacturerIsoCode}
                                    onChange={onManufacturerIsoCodeChange}
                                /> */}
                            </Row>
                            <Row className="pb-4 pt-4">
                                <DetailsPanelInput
                                    mandatory
                                    header="Address"
                                    editable={editable}
                                    value={manufacturerFactoryAddress}
                                    onChange={onManufacturerFactoryAddressChange}
                                />
                                <DetailsPanelInput mandatory header="Country Of Origin" editable={editable}>
                                    <Select
                                        autofocus
                                        name="countries"
                                        options={countryList}
                                        onChange={handleInputCountryChange}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option => option.name}
                                        styles={selectStyle}
                                        value={{
                                            id: manufacturerCountryId,
                                            name: manufacturerNameOfCountry,
                                        }}
                                    />
                                </DetailsPanelInput>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row className="pb-4 pt-4">
                    {editable ? (
                        <Col className="p-0">
                            <FormGroup>
                                <DetailsPanelInput mandatory header="Product Type" editable={editable}>
                                    <Select
                                        name="selected-state"
                                        autofocus
                                        isLoading={loading}
                                        options={productTypes}
                                        value={selectedProductType}
                                        onChange={onProductTypeChange}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={getOptionLabelProductTypes}
                                    />
                                    <small className="form-text text-muted">Please select product type</small>
                                </DetailsPanelInput>
                            </FormGroup>
                        </Col>
                    ) : (
                        <React.Fragment>
                            <PreviewField
                                header="Product Type"
                                editable={editable}
                                label={selectedProductType == null ? 'Others' : selectedProductType.type}
                            />
                            <PreviewField header="Product Category" editable={editable} label={selectedProductCategory} />
                            <PreviewField header="Product Subcategory" editable={editable} label={selectedProductSubcategory} />
                        </React.Fragment>
                    )}
                </Row>
            </NoShadowPanel>
        )
    );
};

export { PreviewField, DetailsPanelInput, DetailsPanelButton };
export default ProductDetailsPanel;
