import {
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST,
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS,
    STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE,
    STAFF_CERT_ASSESS_CRITERIA_LIST_SET_KEYWORD,
    STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE,
} from 'actions/constants/actionTypesStaffCertificationAssessmentCriteria';

const defaultState = {
    loading: false,
    ac: [],
    tablePages: -1,
    keyword: '',
    listType: 'product',
};

export default function staffCertificationAssessmentCriteriaListReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS:
            newState.ac = action.response.data;
            newState.tablePages = action.response.totalNoOfPages;
            newState.totalNum = action.response.totalNoOfItems;
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_LIST_SET_KEYWORD:
            newState.keyword = action.keyword;
            return newState;
        case STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE:
            newState.listType = action.listType;
            return newState;
        default:
            return newState;
    }
}
