import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Pie } from 'react-chartjs-2';
import { getTotalProductsCertifiedAnalytic } from 'actions/analytics/certificateAnalyticsAction';
import CyderDoughnut from 'pages/analytics/components/charts/CyderDoughnut';
var randomColor = require('randomcolor');
var tinygradient = require('tinygradient');

var gradient = tinygradient('#36a2eb', '#ff8686');

var generateGradient = step => {
    var colorsRgb = gradient.rgb(step);
    return colorsRgb;
};

var randomHexColor = () =>
    randomColor({
        luminosity: 'dark',
        hue: 'green',
        format: 'rgba',
        alpha: 0.6,
    });

class TotalProductsCertifiedBySGBP extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legendconfig: {
                legend: {
                    onClick: null,
                },
            },
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            var col = generateGradient(3);
            let labels = [];
            let total = 0;
            let data = [];
            let backgroundColor = [];
            let hoverBackgroundColor = [];
            res.data.forEach((element, i) => {
                if (element.status != null) {
                    total += element.productCount;
                    labels.push(element.status);
                    data.push(element.productCount);
                    backgroundColor.push(col[i].toHexString());
                }
            });
            this.setState({
                data,
                backgroundColor,
                hoverBackgroundColor,
                labels,
                total,
                centerText: total,
            });
        });
    }

    render() {
        return (
            <AnalyticsCard title={`Total SGBP Certificates`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CyderDoughnut {...this.state} />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getTotalProductsCertifiedAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(TotalProductsCertifiedBySGBP));
