// Group Search
export const APPLICATION_SEARCH_REQUEST = 'APPLICATION_SEARCH_REQUEST';
export const APPLICATION_SEARCH_SUCCESS = 'APPLICATION_SEARCH_SUCCESS';
export const APPLICATION_SEARCH_FAILURE = 'APPLICATION_SEARCH_FAILURE';

export const APPLICATION_KEYWORD_SEARCH_REQUEST = 'APPLICATION_KEYWORD_SEARCH_REQUEST';
export const APPLICATION_KEYWORD_SEARCH_SUCCESS = 'APPLICATION_KEYWORD_SEARCH_SUCCESS';
export const APPLICATION_KEYWORD_SEARCH_FAILURE = 'APPLICATION_KEYWORD_SEARCH_FAILURE';

export const APPLICATION_ADD_GET_DETAIL_REQUEST = 'APPLICATION_ADD_GET_DETAIL_REQUEST';
export const APPLICATION_ADD_GET_DETAIL_SUCCESS = 'APPLICATION_ADD_GET_DETAIL_SUCCESS';
export const APPLICATION_ADD_GET_DETAIL_FAILURE = 'APPLICATION_ADD_GET_DETAIL_FAILURE';

export const APPLICATION_ADD_SAVE_APPLICATION_REQUEST = 'APPLICATION_ADD_SAVE_APPLICATION_REQUEST';
export const APPLICATION_ADD_SAVE_APPLICATION_SUCCESS = 'APPLICATION_ADD_SAVE_APPLICATION_SUCCESS';
export const APPLICATION_ADD_SAVE_APPLICATION_FAILURE = 'APPLICATION_ADD_SAVE_APPLICATION_FAILURE';

export const APPLICATION_ADD_DELETE_APPLICATION_REQUEST = 'APPLICATION_ADD_DELETE_APPLICATION_REQUEST';
export const APPLICATION_ADD_DELETE_APPLICATION_SUCCESS = 'APPLICATION_ADD_DELETE_APPLICATION_SUCCESS';
export const APPLICATION_ADD_DELETE_APPLICATION_FAILURE = 'APPLICATION_ADD_DELETE_APPLICATION_FAILURE';

export const APPLICATION_CHANGE_INPUT_KEYWORD_VALUE = 'APPLICATION_CHANGE_INPUT_KEYWORD_VALUE ';
export const APPLICATION_ADD_CHANGE_FIELD_VALUE = 'APPLICATION_ADD_CHANGE_FIELD_VALUE ';
export const APPLICATION_ADD_SET_FIELDS = 'APPLICATION_ADD_SET_FIELDS';

export const APPLICATION_ADD_MODAL_TOGGLE = 'APPLICATION_ADD_MODAL_TOGGLE';
// StaffMembershipApplicationDetails
export const MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_GET_APPLICATION_MAIN_DETAILS_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_PROCESS_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_REJECT_PROCESS_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_COMPLETE_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_RESUBMIT_QUESTIONAIRE_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_APPROVE_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_GENERATE_INVOICE_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_INVOICE_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_MEMBERSHIP_RECEIPT_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_GET_MODE_OF_PAYMENTS_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_MARK_AS_PAID_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST = 'MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_REQUEST';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_GET_PAYMENT_INFO_FAILURE';

export const MEMBERSHIP_APPLICATION_PAYMENT_REQUEST = 'MEMBERSHIP_APPLICATION_PAYMENT_REQUEST';
export const MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS = 'MEMBERSHIP_APPLICATION_PAYMENT_SUCCESS';
export const MEMBERSHIP_APPLICATION_PAYMENT_FAILURE = 'MEMBERSHIP_APPLICATION_PAYMENT_FAILURE';

export const MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING = 'MEMBERSHIP_APPLICATION_DETAIL_FORCE_LOADING';
export const MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE = 'MEMBERSHIP_APPLICATION_DETAIL_RESET_STATE';
export const MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE = 'MEMBERSHIP_APPLICATION_DETAIL_FORCE_VALIDATE';
export const MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA = 'MEMBERSHIP_APPLICATION_DETAIL_SET_MODAL_DATA';
export const MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD = 'MEMBERSHIP_APPLICATION_DETAIL_CHANGE_PAYMENT_FIELD';
export const MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION = 'MEMBERSHIP_APPLICATION_DETAIL_SET_CALCULATE_MEMBERSHIP_PERIOD_FUNCTION';

export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_SUCCESS';
export const MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE = 'MEMBERSHIP_APPLICATION_DETAIL_STAFF_SEND_RECEIPT_EMAIL_FAILURE';

export const MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_FAILURE = 'MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_FAILURE';
export const MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_SUCCESS = 'MEMBERSHIP_APPLICATION_CREATE_STAFF_MEMBERSHIP_RECEIPT_SUCCESS';
export const MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST = 'MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_REQUEST';
export const MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS = 'MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_SUCCESS';
export const MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE = 'MEMBERSHIP_APPLICATION_SUBMIT_BEHALF_FAILURE';

export const MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS = 'MEMBERSHIP_APPLICATION_REJECT_EMAIL_SUCCESS';
export const MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE = 'MEMBERSHIP_APPLICATION_REJECT_EMAIL_FAILURE';
