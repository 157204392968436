import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

import { createTeamMemberViaArgs } from 'actions/shared/teamManagementAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';

import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';
import { actionExecutor } from 'js/util';

const defaultFields = {
    firstName: '',
    lastName: '',
    email: '',
    designation: '',
    companyId: 0,
    companyName: '',
    telephoneDid: '',
};

class OMRPanel extends Component {
    _isMounted = true;
    state = {
        loading: false,
        fields: defaultFields,
        modal: {
            showModal: false,
            modalMessage: '',
            modalHeader: '',
            positiveButtonHide: true,
            negativeButtonHide: true,
            type: 'save',
        },
    };
    componentDidMount() {
        this.getCompanyInfo();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getCompanyInfo() {
        const callback = async () => {
            const { data } = await this.props.getCompanyInfo();
            let fields = Object.assign({}, this.state.fields);
            fields.companyId = data.id || fields.companyId;
            fields.companyName = data.name || fields.companyName;
            if (this._isMounted) this.setState({ fields });
        };
        actionExecutor(this.showLoading, callback);
    }
    showLoading = loading => {
        if (!this._isMounted) return;
        this.setState({ loading });
    };
    onTextChange = e => {
        const { value, id } = e.target;
        let fields = Object.assign({}, this.state.fields);
        fields[id] = value;
        this.setState({ fields });
    };

    submit = () => {
        const { firstName, lastName, email, designation, companyId, companyName, telephoneDid } = this.state.fields;

        if (firstName !== '' && lastName !== '' && email !== '' && designation !== '' && telephoneDid !== '') {
            const callback = async () => {
                const { data } = await this.props.createTeamMemberViaArgs(
                    companyId,
                    companyName,
                    firstName,
                    lastName,
                    designation,
                    email,
                    telephoneDid,
                );
                if (data) {
                    if (this.props.embedded) {
                        {
                            /* Embedded is True when this OMRPanel is used from MembershipDashboardWidget. Added by Fernando 5 July 2019 */
                        }
                        this.showModal(
                            'Confirmation',
                            'Official Management Representative has been successfully added.',
                            'embedded',
                            null,
                            true,
                        );
                        window.location.href = '/member/membership/newapplication/1';
                        this.setState({ fields: defaultFields });
                        return;
                    } else {
                        this.showModal(
                            'Confirmation',
                            'Official Management Representative has been successfully added.',
                            'changeStep',
                            null,
                            true,
                        );
                        this.setState({ fields: defaultFields });
                        return;
                    }
                }
                this.showModalIf('Error', 'Failed to submit OMR info.', true);
            };
            actionExecutor(this.showLoading, callback);
        } else {
            this.showModal('Error', 'Please fill in all the fields.', null, null, true);
        }
    };
    modalAction = () => {
        const { type } = this.state.modal;
        if (type === 'changeStep') this.props.changeStep(1);
        this.modalToggle();
    };
    modalToggle = () => {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({ modal });
    };
    showModalIf = (header, message, evaluation) => {
        if (evaluation) this.showModal(header, message, '', null, true);
        return evaluation;
    };
    showModal = (header, message, type, positiveButtonHide, negativeButtonHide) => {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({ modal });
    };
    render() {
        const { loading, submitDisable } = this.state;
        const { firstName, lastName, email, designation, telephoneDid } = this.state.fields;
        if (loading) return <LoadingSpinner center />;
        return (
            <React.Fragment>
                <DialogModal
                    modalAction={this.modalAction}
                    actionToggleExternal={this.modalToggle}
                    negativeButtonAction={this.modalToggle}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalHeader={this.state.modal.modalHeader}
                    modalBody={this.state.modal.modalMessage}
                    modalOpen={this.state.modal.showModal}
                />
                <Card>
                    <CardBody>
                        {/* Embedded is True when this OMRPanel is used from MembershipDashboardWidget. Added by Fernando 5 July 2019 */}
                        {!this.props.embedded && (
                            <React.Fragment>
                                <NoShadowPanel
                                    flat
                                    textLowerCase
                                    description="Please provide information of the Official Management Representative (OMR) for your organization."
                                    descriptionSecondLine="The OMR has a unique role of voting on behalf of the organization for SGBC Board elections and standing for SGBC Board elections."
                                    title="Official Management Representative"
                                    panelStyle={{ textAlign: 'justify' }}
                                />

                                <hr />
                            </React.Fragment>
                        )}
                        <Row>
                            <Col xs={this.props.embedded ? 12 : 4}>
                                <InputField
                                    label="First Name"
                                    inputProps={{
                                        type: 'text',
                                        id: 'firstName',
                                        name: 'firstName',
                                        placeholder: 'First Name',
                                        value: firstName,
                                        onChange: this.onTextChange,
                                    }}
                                />
                            </Col>
                            <Col xs={this.props.embedded ? 12 : 4}>
                                <InputField
                                    label="Last Name"
                                    inputProps={{
                                        type: 'text',
                                        id: 'lastName',
                                        name: 'lastName',
                                        placeholder: 'Last Name',
                                        value: lastName,
                                        onChange: this.onTextChange,
                                    }}
                                />
                            </Col>
                            <Col xs={this.props.embedded ? 12 : 4}>
                                <InputField
                                    label="Designation"
                                    inputProps={{
                                        type: 'text',
                                        id: 'designation',
                                        name: 'designation',
                                        placeholder: 'Designation',
                                        value: designation,
                                        onChange: this.onTextChange,
                                    }}
                                />
                            </Col>
                            <Col xs={this.props.embedded ? 12 : 4}>
                                <InputField
                                    label="Email"
                                    inputProps={{
                                        type: 'text',
                                        id: 'email',
                                        name: 'email',
                                        placeholder: 'Email',
                                        value: email,
                                        onChange: this.onTextChange,
                                    }}
                                />
                            </Col>
                            <Col xs={this.props.embedded ? 12 : 4}>
                                <InputField
                                    label="Telephone DID"
                                    inputProps={{
                                        type: 'text',
                                        id: 'telephoneDid',
                                        name: 'telephoneDid',
                                        placeholder: 'Telephone DID',
                                        value: telephoneDid,
                                        onChange: this.onTextChange,
                                    }}
                                />
                            </Col>
                        </Row>
                        <FlexRow between="xs" style={{ marginBottom: '20px' }}>
                            <FlexCol xs={12} className={'d-flex justify-content-end'}>
                                <Button
                                    className="primary-btn-style d-flex justify-content-end"
                                    onClick={this.submit}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) this.submit();
                                    }}
                                >
                                    Submit
                                </Button>
                            </FlexCol>
                        </FlexRow>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getCompanyInfo: () => {
            return dispatch(memberMembershipApplicationGetCompanyInfo());
        },
        createTeamMemberViaArgs: (companyId, companyName, firstName, lastName, designation, email, telephoneDid) => {
            return dispatch(createTeamMemberViaArgs(companyId, companyName, firstName, lastName, designation, email, telephoneDid, true));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(OMRPanel));
