import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Row, Col, FormGroup, Card, CardBody, Button } from 'reactstrap';
import ButtonIcon from 'cyder/button/ButtonIcon';
import MandatoryStar from 'cyder/forms/MandatoryStar';

import { removeArrayItem } from 'js/util';

// EXPECTED OUTPUT: [{name: "cklajdcad", models: [{name: "clajdncadc"}, {name: "caldjncad"}]}]
// const defaultProductBrands = [{ name: '', models: [{ name: '' }] }];

class ProductsListTable extends React.Component {
    _isMounted = true;
    static contextTypes = {
        children: PropTypes.func,
        productBrands: PropTypes.array,
        brandInfoDisplaySize: PropTypes.number,
        brandTableContentSize: PropTypes.number,
        editable: PropTypes.bool,
        status: PropTypes.string,
        printCertificate: PropTypes.func,
    };
    state = {
        brandInfoDisplaySize: 3,
        brandTableContentSize: 5,
        currentBrandToAddModel: 0,
        addModelFormVisible: false,
        addBrandFormVisible: true,
        brandNameField: '',
        modelNameField: '',
        productBrands: [],
    };
    componentDidMount() {
        const { status, editable, productBrands, brandInfoDisplaySize, brandTableContentSize } = this.props;
        if (this._isMounted) {
            this.setState({
                productBrands: productBrands && productBrands.length !== 0 ? productBrands : this.state.productBrands,
                brandInfoDisplaySize: brandInfoDisplaySize || this.state.brandInfoDisplaySize,
                brandTableContentSize: brandTableContentSize || this.state.brandTableContentSize,
                addBrandFormVisible: productBrands.length === 0 || !productBrands,
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    onTextChange = e => {
        const { id, value } = e.target;
        const newState = Object.assign({}, this.state);
        console.log('ID', id);
        if (id === 'modelNameField') newState[id] = value.replace(/(\\n|\\r\\n|\\r)/gm, '');
        else newState[id] = value;
        this.setState(newState);
        this.passFirstProductBrandsWithoutSave();
    };
    passFirstProductBrandsWithoutSave() {
        const { brandNameField, modelNameField, productBrands } = this.state;
        const { children } = this.props;
        const isNewProductBrand = productBrands.length === 0;
        if (isNewProductBrand && children) {
            const firstProductBrands = [{ name: brandNameField, models: [{ name: modelNameField }] }];
            this.props.children(firstProductBrands);
        }
    }
    addModel = (brandIndex = 0) => {
        const { brandNameField, modelNameField, productBrands } = this.state;
        let brandData = productBrands[brandIndex];

        // Add brand
        if (!brandData) brandData = { name: brandNameField };

        // Add model
        let models = brandData.models || [];
        models.push({
            name: modelNameField,
        });

        // Add model info
        const newBrandInfo = Object.assign(brandData, {
            models,
        });

        // Update new brand info
        productBrands[brandIndex] = newBrandInfo;

        // Set state
        this.setState({
            productBrands,
            modelNameField: '',
            brandNameField: '',
            addModelFormVisible: false, // True => to open addModelForm when new item is saved
            addBrandFormVisible: false,
        });
        this.props.children && this.props.children(productBrands);
    };
    toggleAddModelFormVisible = () => {
        this.setState({
            addModelFormVisible: !this.state.addModelFormVisible,
        });
    };
    openNewBrandForm = () => {
        const { productBrands } = this.state;
        const hasData = productBrands.length > 0 && productBrands[0].name; // if data exist
        this.setState({
            productBrands: hasData ? productBrands : [],
            addBrandFormVisible: true,
        });
    };
    removeBrand = brandIndex => {
        const { productBrands } = this.state;
        let updatedProductBrands = removeArrayItem(productBrands, brandIndex);
        updatedProductBrands = updatedProductBrands.length === 0 ? [] : updatedProductBrands;

        // SET STATE
        this.setState({
            productBrands: updatedProductBrands,
            addModelFormVisible: false,
            addBrandFormVisible: true,
        });
        this.props.children && this.props.children([]);
    };
    removeModel = (brandIndex, modelIndex) => {
        let { productBrands } = this.state;
        let brand = productBrands[brandIndex];
        brand.models = removeArrayItem(brand.models, modelIndex);
        productBrands[brandIndex] = brand;

        // SET STATE
        this.setState({ productBrands });
        this.props.children && this.props.children(productBrands);
    };
    render() {
        const {
            brandInfoDisplaySize,
            brandTableContentSize,
            currentBrandToAddModel,
            addModelFormVisible,
            addBrandFormVisible,
            brandNameField,
            modelNameField,
            productBrands,
        } = this.state;
        const { printCertificate, editable = true, status = '' } = this.props;
        const isNewBrand = !productBrands[0] || !productBrands[0].name;
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        {/* TABLE CONTENT */}
                        <BrandTableContent
                            // DATA
                            status={status}
                            modelValue={modelNameField}
                            productBrands={productBrands}
                            editable={editable}
                            isNewBrand={isNewBrand}
                            addModelFormVisible={addModelFormVisible}
                            currentBrandToAddModel={currentBrandToAddModel}
                            // CONFIG
                            brandInfoDisplaySize={brandInfoDisplaySize}
                            brandTableContentSize={brandTableContentSize}
                            // ONCHANGE
                            onTextChange={this.onTextChange}
                            //ACTION
                            openNewBrandForm={this.openNewBrandForm}
                            onAddModelClick={this.addModel}
                            toggleAddModelFormVisible={this.toggleAddModelFormVisible}
                            removeBrand={this.removeBrand}
                            removeModel={this.removeModel}
                            printCertificate={printCertificate}
                        />
                        {/* BRAND INPUT PANEL */}
                        {editable && addBrandFormVisible && (
                            <BrandAndModelInput
                                isNewBrand={isNewBrand}
                                brandInfoDisplaySize={brandInfoDisplaySize}
                                brandTableContentSize={brandTableContentSize}
                                brandValue={brandNameField}
                                modelValue={modelNameField}
                                onTextChange={this.onTextChange}
                                onAddButtonClick={this.addModel}
                            />
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const BrandTableContent = ({
    productBrands,
    editable,
    addModelFormVisible,
    openNewBrandForm,
    onAddModelClick,
    toggleAddModelFormVisible,
    currentBrandToAddModel,
    removeBrand,
    removeModel,
    onTextChange,
    modelValue,
    printCertificate,
    status,
    brandInfoDisplaySize,
    brandTableContentSize,
    isNewBrand,
}) => {
    if (!productBrands) return null;
    // If is new brand, open new brand form; else if add model form visible, use add model function; else if not visible open add model form
    const addModelFunc = addModelFormVisible ? onAddModelClick : toggleAddModelFormVisible;
    return productBrands.map((item, index) => {
        const { name, models } = item;
        return (
            <Row key={index}>
                {/* BRAND DISPLAY PANEL */}
                <BrandInfoDisplay
                    item={item}
                    editable={editable}
                    brandInfoDisplaySize={brandInfoDisplaySize}
                    removeBrand={() => removeBrand(index)}
                />
                {/* MODEL DISPLAY PANEL */}
                <Col className="ticker" xs={brandTableContentSize}>
                    {models &&
                        models.map((model, j) => (
                            <ModelInfoDisplay
                                key={j}
                                model={model}
                                status={status}
                                editable={editable}
                                printCertificate={printCertificate}
                                removeModel={() => removeModel(index, j)}
                            />
                        ))}
                    {/* MODEL INPUT PANEL */}
                    {editable && addModelFormVisible && currentBrandToAddModel === index && (
                        <ModelInput onTextChange={onTextChange} modelValue={modelValue} brandTableContentSize="12">
                            <ButtonIcon label="Save" disabled={modelValue ? false : true} onClick={() => onAddModelClick(index)} />
                            <ButtonIcon className="ml-2" label="Cancel" onClick={toggleAddModelFormVisible} />
                        </ModelInput>
                    )}
                    {editable && !addModelFormVisible && <AddModelButton brandIndex={index} onClick={addModelFunc} label={'Add Model'} />}
                </Col>
            </Row>
        );
    });
};

const BrandInfoDisplay = ({ brandInfoDisplaySize, removeBrand, editable, item }) => {
    const { name } = item;
    return (
        <Col xs={brandInfoDisplaySize || 3}>
            <Card className="p-2">
                <CardBody>
                    {name && (
                        <Fragment>
                            <div className="d-flex">
                                <b>Brand</b>
                                <div className="ml-auto">{editable && name && <DeleteIcon onClick={removeBrand} />}</div>
                            </div>
                            <div>{name}</div>
                        </Fragment>
                    )}
                </CardBody>
            </Card>
        </Col>
    );
};

const ModelInfoDisplay = ({ editable, removeModel, model, printCertificate, status }) => {
    const { certificationNumber, name } = model;
    return (
        <React.Fragment>
            <Card className="mb-4">
                <CardBody>
                    <Row className="p-2">
                        {/* CERTIFCATION NUMBER & DOWNLOAD */}
                        {certificationNumber && status === 'Issued' && (
                            <Col className="ticker">
                                <b>Certificate Number</b>
                                <br />
                                {certificationNumber}
                                <br />
                                {printCertificate && <DownloadCertButton onClick={e => printCertificate(certificationNumber, 'product')} />}
                            </Col>
                        )}
                        {/* MODEL */}
                        {name && (
                            <Col className="ticker">
                                <div className="d-flex">
                                    <b>Model</b>
                                    <div className="ml-auto">{editable && name && <DeleteIcon onClick={removeModel} />}</div>
                                </div>
                                {name}
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

// BRAND & MODEL INPUT
const BrandAndModelInput = ({
    isNewBrand,
    brandInfoDisplaySize,
    brandTableContentSize,
    onTextChange,
    brandValue,
    modelValue,
    onAddButtonClick,
}) => (
    <Row>
        <Col className="ticker" xs={brandInfoDisplaySize || 3}>
            <Card className="p-2">
                <CardBody>
                    <FormGroup>
                        <b>Brand {isNewBrand && <MandatoryStar />}</b>
                        <input
                            type="text"
                            id="brandNameField"
                            className="form-control input-productlist"
                            value={brandValue}
                            onChange={onTextChange}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <small className="form-text text-muted">Please enter Brand Name</small>
        </Col>
        <ModelInput mandatory brandTableContentSize={brandTableContentSize} modelValue={modelValue} onTextChange={onTextChange}>
            <AddModelButton disabled={!brandValue || !modelValue} onClick={onAddButtonClick} label="Save" />
        </ModelInput>
    </Row>
);

// MODEL INPUT
const ModelInput = ({ onTextChange, modelValue, children, brandTableContentSize = 5, mandatory }) => {
    return (
        <Col className="pl-0 pr-0 ticker" xs={brandTableContentSize}>
            <Card className="p-2">
                <CardBody>
                    <FormGroup>
                        <b>Model {mandatory && <MandatoryStar />}</b>
                        <input
                            type="text"
                            id="modelNameField"
                            className="form-control input-productlist"
                            value={modelValue}
                            onChange={onTextChange}
                        />
                    </FormGroup>
                </CardBody>
            </Card>
            <small className="form-text text-muted">Please enter Model Name</small>
            {children}
        </Col>
    );
};

const AddModelButton = ({ onClick, brandIndex, label, disabled }) => (
    <FormGroup>
        <ButtonIcon disabled={disabled} onClick={() => onClick(brandIndex)} flat label={label || 'Add Model'} />
    </FormGroup>
);

const DeleteIcon = ({ onClick }) => (
    <Link onClick={onClick} to="#">
        <i className="red-icons material-icons material-icons-1x">delete</i>
    </Link>
);

const DownloadCertButton = ({ printCertificate }) => (
    <Button
        onClick={printCertificate}
        className="text-bold text-capitalize primary-btn-style"
        style={{
            cursor: 'pointer',
            color: 'white',
        }}
    >
        <i
            className="mr-1 material-icons align-text-bottom"
            style={{
                marginLeft: '0px 5px',
            }}
        >
            cloud_download
        </i>
        Download Certificate
    </Button>
);

export default ProductsListTable;
