import React from 'react';
import { PreviewPanelWrapper } from './PreviewPanel1';
import { Col } from 'reactstrap';

const PreviewPanel5 = ({ textColor, onGoingProductAssesment }) => (
    <PreviewPanelWrapper textColor={textColor} icon="fa-calendar-check">
        <Col>
            <h4 className="pt-3">
                On-going <br />
                Product Asessment
            </h4>
        </Col>
        <Col className="text-right">
            <h1 className="display-4 text-bold">{onGoingProductAssesment}</h1>
        </Col>
    </PreviewPanelWrapper>
);

export default PreviewPanel5;
