import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import { SGBC_WEB_HOST } from 'config';
import { Row, Col, Button, FormGroup } from 'reactstrap';

import { eventGetEventDetails } from 'actions/staff/events/events/eventsCreationAction';

class EventsHeaderComponent extends React.Component {
    _isMounted = true;
    state = {
        name: '',
    };
    componentDidMount() {
        this.getEventName();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getEventName = async () => {
        try {
            const { getEventDetails, eventId } = this.props;
            const { data } = await getEventDetails(eventId);
            if (!this._isMounted) return;
            this.setState({
                name: data.name,
            });
        } catch (error) {
            throw error;
        }
    };
    render() {
        const { registration, attendee, mode, eventId, companyId } = this.props;
        return (
            <Row className="mb-2">
                <Col sm={12} className="d-flex">
                    <h4>
                        <strong>{this.state.name}</strong>
                    </h4>
                    <div className="ml-auto">
                        <div className="d-flex mb-1">
                            <div className="ml-auto">
                                {registration && (
                                    <Link
                                        to={
                                            mode === 'staff'
                                                ? '/staff/events/' + eventId + '/attendees'
                                                : '/member/events/event/' + eventId + '/attendees/' + companyId
                                        }
                                    >
                                        <Button
                                            className="primary-btn-style mr-1"
                                            onClick={() => {
                                                if (attendee) document.location.reload();
                                            }}
                                        >
                                            <i className="material-icons align-text-bottom">people</i> Attendees
                                        </Button>
                                    </Link>
                                )}
                                {attendee && (
                                    <Link
                                        to={
                                            mode === 'staff'
                                                ? '/staff/events/' + eventId + '/registrations'
                                                : '/member/events/event/' + eventId + '/registrations/' + companyId
                                        }
                                    >
                                        <Button
                                            className="primary-btn-style"
                                            onClick={() => {
                                                if (registration) document.location.reload();
                                            }}
                                        >
                                            <i className="material-icons align-text-bottom">bookmark</i> Registrations
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        create: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getEventDetails: eventid => {
            return dispatch(eventGetEventDetails(eventid));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsHeaderComponent));
