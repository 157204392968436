import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';
import history from '../../../../history';

import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import { Row, Col, Form, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import InputField from 'pages/admin/companies/shared/InputField';

import { save, update, remove, load } from 'actions/admin/events/food-preference/action.js';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.descriptionChange = this.descriptionChange.bind(this);
        this.optionChange = this.optionChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            data: {
                description: '',
                option: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/events/food-preference');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    this.showLoading(false);
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    description: '',

                    option: '',
                },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/events/food-preference');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/events/food-preference', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/events/food-preference');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    descriptionChange(e) {
        var data = this.state.data;
        data.description = e.target.value;
        this.setState({ data });
    }
    optionChange(e) {
        var data = this.state.data;
        data.option = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <InputField
                        label="Description"
                        inputProps={{
                            type: 'text',
                            id: 'description',
                            value: this.state.data.description,
                            onChange: this.descriptionChange,
                        }}
                    />
                    <InputField
                        label="Option"
                        instruction="Requires alphanumeric"
                        inputProps={{
                            type: 'text',
                            id: 'option',
                            value: this.state.data.option,
                            onChange: this.optionChange,
                            style: { width: '600px' },
                        }}
                    />
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2} className="d-flex justify-content-end">
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                                <AuditListPopup contentTypeId={65} objectId={this.state.data.id} />
                            </FlexCol>
                        ) : null}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
