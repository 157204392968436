/**
 * Modal message used for staff certification
 *
 * ApplicationViewPage.js
 * Service && Product
 */

const modalMessageDict = {
    getPaymentInfoFailure: 'Failed to get payment info.',
    savePaymentInfoFailure: 'Failed to update payment info',
    printCertificateFailure: 'Failed to print certificate.',
    downloadInvoiceFailure: 'Failed to generate invoice.',
    previewTemplateValid: 'Please select an assessment template to preview.',
    handleRegenerateConfirm: 'Certificate(s) successfully generated.',
    downloadReceiptFailure: 'Failed to download receipt.',
    downloadReceiptFailureNoPaymentDone: 'Unable to download receipt as payment information has not been updated.',

    // Send Receipt
    createAndSendReceiptConfirm: 'Click OK to send receipt.',
    createAndSendReceiptFailure: 'Failed to send receipt.',
    createAndSendReceiptSuccess: 'Receipt has been successfully sent.',

    // AUTHORIZE INVOICE
    authorizeInvoiceConfirm: 'Click OK to authorise the invoice.',
    authorizeInvoiceFailure: 'Failed to authorise invoice.',
    authorizeInvoiceSuccess: 'Invoice successfully authorised.',

    // MARK AS PAID
    markAsPaidConfirmation: 'Click OK to mark application as paid.',
    markAsPaidFailure: 'Failed to mark application as paid.',
    markAsPaidSuccess: 'Application Payment successfully completed.',

    // START finalisation
    startFeedbackConfirm: 'Click OK to start Finalisation for this application.',
    startFeedbackFailure: 'Failed to start finalisation.',
    startFeedbackSuccess: 'Finalisation successfully started.',

    // COMPLETED APPLICATION
    completeApplicationFailure: 'Failed to complete application.',
    completeApplicationSuccess: 'Click Ok to proceed with certificate(s) generation.',

    // UPDATE REMARKS
    addRemarksFailure: 'Failed to update remarks.',
    addRemarksSuccess: 'Remarks successfully updated.',

    // SEND TO CASE OFFICER
    sendToCaseOfficerValid: 'Please select a case officer before proceeding.',
    sendToCaseOfficerFailure: 'Failed to send to assigned case officer.',
    sendToCaseOfficerConfirm: 'Application successfully sent to case officer.',

    // SEND FOR APPROVAL
    sendForApprovalFailure: 'Failed to send application for approval.',
    sendForApprovalSuccess: 'Application successfully sent for approval.',

    // TAKE UP CASE
    takeUpCaseValid: 'Please select an assessment template before proceeding.',
    takeUpConfirmFailure: 'Failed to accept application.',

    // DISCONTINUE
    discontinueDialogConfirm: 'Click OK to discontinue with the application.',
    discontinueApplicationFailure: 'Failed to discontinue application.',

    // APPROVE
    approveApplicationFailure: 'Failed to approve application.',
    approveApplicationSuccess: 'Application successfully approved.',

    // REJECT
    rejectApplicationFailure: 'Failed to reject application.',
    rejectApplicationSuccess: 'Application successfully rejected.',

    // CLOSE CASE
    closeCaseFailure: 'Failed to close case.',
    closeCaseConfimation: 'Assessment completed. Please proceed to SGBC Directory to ensure listing is updated.',

    // SEND WITHDRAWAL EMAIL
    sendWithdrawalEmailFailure: 'Failed to send withdrawal email.',
    sendWithdrawalSuccess: 'Email sent successfully.',

    // CERT NUMBER DUPLICATION
    certNumberDuplication: 'Certificate Number has been reserved or used. Please change it and try again.',

    // CERT NUMBER DOES NOT EXIST
    certNumberEmpty: 'Certificate Number must be filled.',

    // UPDATE STATUS
    updateStatusFailure: 'Failed to update status.',
    updateStatusSuccess: 'Status successfully updated. Please reload the page to get the updated status.',

    // COMPLETE ASSESSMENT
    missingCertApplicationFields: 'Missing certificate number, item code or rating. Please fill them up and try again.',
};
export default modalMessageDict;
