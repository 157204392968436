import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Grid, Segment, Header, Form, Input, Image, Button, Icon } from 'semantic-ui-react';
import { useLocation, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Actions from '../../../actions/public';
import { mobile, tablet } from '../dummy';
import TopNavigation2 from '../../../layouts/TopNavigation2';
import Sidebar from '../components/Sidebar';
import Product from './Product';
import ProductTypes from './ProductTypes';
import ProductList from './ProductList';
import SearchResult from './SearchResult';
import CompanyInfo from './CompanyInfo';
import CertificateInfo from './CertificateInfo';
import ScrollToTop from '../components/ScrollToTop';
import AffiliatesPanel from '../components/AffiliatesPanel';
import { Nav, NavItem, NavLink } from 'reactstrap';

const {
    fetchLowVocProductTypes,
    fetchProductCategory,
    fetchProductSubcategory,
    fetchSingleSubcategory,
    fetchSpecificProductTypes,
    fetchAllFilterOptions,
    fetchAllProductTypes,
    fetchProducts,
    fetchAllProducts,
    fetchFilteringLabels,
    searchProducts,
    fetchRating,
    loadingPublic,
    unloadingPublic,
    fetchCertificateInfo,
    loadingPublicProductCategory,
    loadingPublicProductSubcategory,
    loadingSingleSubCategory,
    loadingAllProductTypes,
    loadingSpecificProductTypes,
    loadingLowVocProductTypes,
} = Actions;

class ProductContainer extends Component {
    state = {
        lowvoc: 'all',
        greenAttributes: [],
        greenCredentials: [],
        bca: [],
        activeIndex: 0,
        productTypeLabels: this.props.productTypeLabelsId,
        category: 'all',
        subcategory: 'all',
        sortRating: 'asc',
        ratingId: 4,
        productTypeId: 'all',
    };

    async componentDidMount() {
        this.props.loadingPublicProductCategory();
        this.props.loadingPublicProductSubcategory();
        // this.props.loadingSingleSubCategory();
        this.props.loadingAllProductTypes();
        // this.props.loadingSpecificProductTypes();

        await this.props.fetchProductCategory();
        await this.props.fetchProductSubcategory();
        await this.props.fetchAllProductTypes();
        await this.props.fetchRating();
        this.props.fetchAllFilterOptions();
    }

    redirect = isSubCategory => {
        let subCategory = this.props.subcategories.filter(e => {
            return e.productSubcategoryId == this.state.subcategory;
        });
        const { location, history } = this.props;
        const { productTypeId } = this.state;
        const { productCategoryId, productSubcategoryId } = subCategory[0];

        if (subCategory.length > 0) {
            if (location.pathname.includes('/certificate')) {
                isSubCategory
                    ? history.push({
                          pathname: `/public/product/${productCategoryId}/${productSubcategoryId}/products`,
                      })
                    : history.push({
                          pathname: `/public/product/${productCategoryId}/${productSubcategoryId}/${productTypeId}/products`,
                      });
            }
            //else {
            //    isSubCategory
            //        ? window.location.replace(
            //              `${window.location.protocol}//${window.location.host}/public/product/${productCategoryId}/${productSubcategoryId}/products`,
            //          )
            //        : window.location.replace(
            //              `${window.location.protocol}//${window.location.host}/public/product/${productCategoryId}/${productSubcategoryId}/${productTypeId}/products`,
            //          );
            //}
        }
    };

    loadBreadCrumb = async (category, subcategory, productTypeId) => {
        this.props.loadingPublicProductCategory();
        this.props.loadingPublicProductSubcategory();
        this.props.loadingAllProductTypes();
        await this.props.fetchProductCategory();
        await this.props.fetchProductSubcategory();
        await this.props.fetchAllProductTypes();
        this.setState({
            subcategory,
            productTypeId,
        });
        this.props.unloadingPublic();
    };

    handleChangeBca = (e, { value }) => {
        const { keyword, subcategory, ratingId, bca, greenAttributes, greenCredentials, lowvoc, productTypeId } = this.state;
        const newData = {
            keyword,
            subCategoryId: subcategory === 'all' ? '' : subcategory,
            ratingId: ratingId || 'all',
            productTypeId,
            sortRating: 'asc',
            greenAttributeId: greenAttributes || [],
            greenCredentialId: greenCredentials || [],
            bcaId: bca,
            lowvoc,
        };
        if (this.state.bca.indexOf(value) < 0) {
            this.props.fetchProducts({ ...newData, bcaId: [...this.state.bca, value] });
            this.setState({ bca: [...this.state.bca, value] });
        } else {
            const bcaId = this.state.bca.filter(item => item !== value);
            this.props.fetchProducts({ ...newData, bcaId });
            this.setState({ bca: bcaId });
        }
    };

    handleChangeGreenCredentials = (e, { value }) => {
        const { keyword, subcategory, ratingId, bca, greenAttributes, greenCredentials, lowvoc, productTypeId } = this.state;
        const newData = {
            keyword,
            subCategoryId: subcategory === 'all' ? '' : subcategory,
            ratingId: ratingId || 'all',
            productTypeId,
            sortRating: 'asc',
            greenAttributeId: greenAttributes || [],
            greenCredentialId: greenCredentials || [],
            bcaId: bca || [],
            lowvoc,
        };
        if (greenCredentials.indexOf(value) < 0) {
            this.props.fetchProducts({ ...newData, greenCredentialId: [...this.state.greenCredentials, value] });
            this.setState({ greenCredentials: [...greenCredentials, value] });
        } else {
            const greenCredentialId = this.state.greenCredentials.filter(item => item !== value);
            this.props.fetchProducts({ ...newData, greenCredentialId });
            this.setState({ greenCredentials: greenCredentialId });
        }
    };

    handleChangeGreenAttributes = (e, { value }) => {
        const { keyword, subcategory, ratingId, bca, greenAttributes, greenCredentials, lowvoc, productTypeId } = this.state;
        const newData = {
            keyword,
            subCategoryId: subcategory === 'all' ? '' : subcategory,
            ratingId: ratingId || 'all',
            productTypeId,
            sortRating: 'asc',
            greenAttributeId: greenAttributes || [],
            greenCredentialId: greenCredentials || [],
            bcaId: bca || [],
            lowvoc,
        };
        if (this.state.greenAttributes.indexOf(value) < 0) {
            this.props.fetchProducts({ ...newData, greenAttributeId: [...this.state.greenAttributes, value] });
            this.setState({ greenAttributes: [...this.state.greenAttributes, value] });
        } else {
            const greenAttributeId = this.state.greenAttributes.filter(item => item !== value);
            this.props.fetchProducts({ ...newData, greenAttributeId });
            this.setState({ greenAttributes: greenAttributeId });
        }
    };

    handleChangeLowVoc = e => {
        const { keyword, subcategory, ratingId, bca, greenAttributes, greenCredentials, productTypeId } = this.state;
        const newData = {
            keyword,
            subCategoryId: subcategory === 'all' ? '' : subcategory,
            ratingId: ratingId || 'all',
            productTypeId,
            sortRating: 'asc',
            greenAttributeId: greenAttributes || [],
            greenCredentialId: greenCredentials || [],
            bcaId: bca || [],
            lowvoc: e.target.value,
        };
        this.props.fetchProducts({ ...newData });
        this.setState({ lowvoc: e.target.value });
    };

    handleFetchProducts = async (catId, subCategoryId, productId = null) => {
        this.props.loadingPublic();
        this.handleReset();
        this.setState({
            ratingId: 'all',
            subcategory: subCategoryId,
            keyword: '',
            search: false,
            productTypeId: productId ? parseInt(productId) : 'all',
        });
        await this.props.fetchSpecificProductTypes(subCategoryId);
        const newData = {
            keyword: '',
            subCategoryId,
            ratingId: 'all',
            productTypeId: productId ? productId : 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: 'all',
        };

        const data = { ...newData, ratingId: 'all' };

        await this.props.fetchFilteringLabels(subCategoryId);
        await this.props.fetchAllProducts(data);
        await this.props.fetchProducts(newData).then(() => {
            // if (subCategoryId === 'lowvoc') {
            //     this.props.history.push(`/public/product/all/lowvoc/products`);
            // }
            !productId && this.props.history.push(`/public/product/${catId}/${subCategoryId}/products`);
        });
        this.props.unloadingPublic();
    };

    handleFetchLowVoc = async () => {
        this.props.loadingPublic();
        this.handleReset();
        this.setState({ productType: 'all', ratingId: 'all', keyword: '', search: false, subcategory: 'lowvoc', category: 'lowvoc' });
        await this.props.fetchLowVocProductTypes();
        const newData = {
            keyword: '',
            subCategoryId: 'lowvoc',
            ratingId: 'all',
            productTypeId: 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: 'all',
        };

        this.props.fetchAllProducts(newData);
        this.props.fetchProducts(newData).then(() => {
            this.props.history.push(`/public/product/all/lowvoc/products`);
        });
        this.props.unloadingPublic();
    };

    handleChangeProductType = async (e, data) => {
        this.props.loadingPublic();
        const { keyword, subcategory, ratingId, lowvoc } = this.state;
        this.setState({ productTypeId: data.value });
        this.props.fetchRating();
        const newData = {
            keyword,
            subCategoryId: subcategory,
            ratingId: ratingId || 'all',
            productTypeId: data.value,
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: lowvoc,
        };
        this.props.fetchFilteringLabels(subcategory);
        await this.props.fetchProducts(newData);
    };

    handleFilterRating = (e, { value }) => {
        const { subcategory, productTypeId, keyword, lowvoc } = this.state;
        this.setState({ ratingId: value });
        const newData = {
            keyword: keyword && keyword !== '' ? keyword : '',
            subCategoryId: subcategory || '',
            ratingId: value,
            productTypeId: productTypeId || 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc,
        };
        this.props.fetchRating();
        this.props.fetchFilteringLabels(subcategory);
        this.props.fetchProducts(newData);
    };

    handleSearchChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    handleSearchSubmit = () => {
        const { keyword } = this.state;

        const newData = {
            keyword,
            subCategoryId: '',
            ratingId: 'all',
            productTypeId: 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: 'all',
        };

        if (keyword && keyword.length > 1) {
            this.setState({ keyword, subcategory: '', search: true, ratingId: 'all' });
            this.props.fetchProducts(newData);
            this.props.history.push('/public/product/search');
            this.setState({ keyword: '' });
        }
    };

    handleSearchCurrentResult = () => {
        const { keyword, lowvoc } = this.state;

        const newData = {
            keyword,
            subCategoryId: this.state.subcategory ? this.state.subcategory : '',
            ratingId: 'all',
            productTypeId: 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc,
        };

        this.setState({ keyword, subcategory: this.state.subcategory ? this.state.subcategory : '', ratingId: 'all' });
        this.props.fetchProducts(newData);
    };

    handleSortRating = id => {
        const { subcategory, productType, ratingId, keyword, lowvoc } = this.state;

        this.setState({ sortRating: id });
        const newData = {
            ratingId: ratingId || 4,
            keyword: keyword && keyword !== '' ? keyword : '',
            subCategoryId: subcategory === 'all' ? '' : subcategory,
            productTypeId: productType || 'all',
            sortRating: id,
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc,
        };
        this.props.fetchProducts(newData);
    };

    handleReset = () => {
        this.setState({
            keyword: '',
            ratingId: '',
            productTypeId: 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: 'all',
        });

        const newData = {
            ratingId: '',
            keyword: '',
            subCategoryId: this.state.subcategory,
            productTypeId: 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
            lowvoc: 'all',
        };
        this.props.fetchProducts(newData);
    };

    handleFetchProductDetails = (certificateId, companyId, productTypeId, productSubcategoryId, productCategoryId) => {
        this.setState({ companyId, productTypeId, subcategory: productSubcategoryId, category: productCategoryId });
        this.props.fetchCertificateInfo(certificateId, companyId);
        this.props.history.push(
            `/public/product/certificate/${productCategoryId}/${productSubcategoryId}/${productTypeId}/${certificateId}/${companyId}`,
        );
    };

    render() {
        //prettier-ignore
        const { categories, subcategories, categoryOptions, subcategoryOptions, checkboxes, location,
            loading, match, productTypeOptions, productTypeFromSearch, productType, filteringLabels, ratings, noSidebar, ratingOptions, productsLength, products, isProductListPage, isProductDetailPage, isProductSearchPage, isLowVoc, history } = this.props;
        const { url } = match;
        const go =
            isProductSearchPage || isProductDetailPage || isProductListPage
                ? () => {
                      this.setState({
                          search: false,
                          keyword: '',
                      });
                      this.props.history.push('/public/product');
                  }
                : () => {
                      this.props.history.push('/public/directory');
                  };
        // console.log('isProductListPage', isProductListPage);
        // console.log('isProductDetailPage', isProductDetailPage);
        // console.log('isProductSearchPage', isProductSearchPage);

        const options = {
            categoryOptions,
            subcategoryOptions,
            productTypeOptions: this.state.search ? productTypeFromSearch : productTypeOptions,
            subcategories,
            ratingOptions,
        };

        const handleChanges = {
            onChangeBca: this.handleChangeBca,
            onChangeGreenCredentials: this.handleChangeGreenCredentials,
            onChangeGreenAttributes: this.handleChangeGreenAttributes,
            onChangeLowVoc: this.handleChangeLowVoc,
        };

        const handleProductList = {
            onSortRating: this.handleSortRating,
            onFilterRating: this.handleFilterRating,
            onFetchProductDetails: this.handleFetchProductDetails,
            onSearchChange: this.handleSearchChange,
            onSearchCurrentResult: this.handleSearchCurrentResult,
        };

        const ptype = this.props.productTypes
            ? this.props.productTypes.filter(e => {
                  return e.productTypeId == this.state.productTypeId;
              })
            : null;
        const subCategory = this.props.subcategories.filter(e => {
            return e.productSubcategoryId == this.state.subcategory;
        });
        const category = this.props.categories.filter(e => {
            if (subCategory && subCategory.length > 0) {
                return e.id == subCategory[0].productCategoryId;
            } else {
                return false;
            }
        });

        const category_breadcrumb = `${isLowVoc ? 'Low Voc' : category && category.length > 0 ? category[0].category : ''}`;
        const subcategory_breadcrumb = `${subCategory && subCategory.length > 0 ? subCategory[0].subcategory : ''}`;
        const type_breadcrumb = `${ptype && ptype.length > 0 ? ptype[0].type : ''}`;

        return (
            <Fragment>
                <Grid stackable centered padded className="product-directory">
                    {/* Search Bar */}
                    <Grid.Row centered>
                        <Grid.Column width={16} style={{ paddingLeft: 0 }}>
                            <Grid stackable centered verticalAlign="middle">
                                <Grid.Column textAlign={mobile ? 'center' : undefined} width={16} style={{ paddingTop: 0 }}>
                                    <Segment
                                        placeholder
                                        className="hero"
                                        style={{ border: '0px', paddingBottom: '5px', borderColor: '#f5f5f5' }}
                                    >
                                        <Grid centered>
                                            <Grid.Column width={mobile || tablet ? 14 : 12}>
                                                <Header as="h2">
                                                    SGBC <br /> <span>Product Directory </span>
                                                </Header>
                                                <Form onSubmit={this.handleSearchSubmit}>
                                                    <Input
                                                        // icon={{ name: 'arrow right', circular: true, link: true }}
                                                        icon={
                                                            <Icon
                                                                name="arrow right"
                                                                circular={true}
                                                                link
                                                                onClick={this.handleSearchSubmit}
                                                            />
                                                        }
                                                        size="large"
                                                        name="keyword"
                                                        value={this.state.keyword}
                                                        onChange={this.handleSearchChange}
                                                        placeholder="Search"
                                                    />
                                                </Form>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <i style={{ color: '#949699', marginLeft: '8px', fontSize: '12px' }}>
                                                            Search by Company Name, Certificate Number, Product Type, Brand Name, Model
                                                        </i>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    {/* Search and Print Button */}
                    <Grid.Row centered={false} className="noprint">
                        <Grid.Column textAlign="left" width={6}>
                            <Button
                                basic
                                icon="chevron left"
                                content={
                                    isProductListPage || isProductSearchPage || isProductDetailPage
                                        ? 'Back to SGBP Directory'
                                        : 'Back to Directories'
                                }
                                onClick={go}
                            />
                        </Grid.Column>
                        <Grid.Column width={6} textAlign="right">
                            <Button icon="print" content="Print" className="btn print primary-green" onClick={() => window.print()} />
                        </Grid.Column>
                    </Grid.Row>
                    {!isProductSearchPage && (isProductDetailPage || isProductListPage) && (
                        <Grid.Row centered={false} className="noprint">
                            <Grid.Column width={12} textAlign="left" verticalAlign="middle">
                                <span>
                                    <Header
                                        as="h4"
                                        className="mx-2 my-1"
                                        style={{
                                            color: '#555',
                                            fontSize: 20,
                                        }}
                                    >
                                        <span style={{ color: '#78be20' }}>{category_breadcrumb}</span>
                                        {subcategory_breadcrumb !== '' && ' / '}
                                        <BreadcrumbItem
                                            canRedirect={isProductDetailPage || isProductListPage}
                                            redirect={() =>
                                                isProductDetailPage
                                                    ? this.redirect(true)
                                                    : isProductListPage
                                                    ? this.handleChangeProductType(null, {
                                                          key: 'all',
                                                          text: 'All',
                                                          value: 'all',
                                                          subcategoryId: 'all',
                                                      })
                                                    : null
                                            }
                                            text={subcategory_breadcrumb}
                                        />
                                        {type_breadcrumb !== '' && ' / '}
                                        <BreadcrumbItem
                                            canRedirect={isProductDetailPage}
                                            redirect={() => {
                                                this.redirect(false);
                                            }}
                                            text={type_breadcrumb}
                                        />
                                    </Header>
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    {/* Bread crumb */}
                    {/*isProductListPage && !this.state.search && !noSidebar && (
                        <Grid.Row centered={false}>
                            <Grid.Column width={12} style={{ paddingLeft: '5px' }}>
                                <Header
                                    as="h4"
                                    className="mx-2 my-1"
                                    style={{ color: '#78be20', fontSize: 28 }}
                                    content={`${category && category.length > 0 ? category[0].category : ''}`}
                                />
                                <Header
                                    as="h5"
                                    className="mx-2 my-1"
                                    style={{
                                        color: '#555',
                                        fontSize: 20,
                                    }}
                                    content={`${subCategory && subCategory.length > 0 ? subCategory[0].subcategory : ''}`}
                                />
                            </Grid.Column>
                        </Grid.Row>
                                )*/}

                    <Grid.Row>
                        {!noSidebar && !this.state.search && (
                            <Grid.Column width={3} className="main-sidebar" style={{ paddingTop: '0px' }}>
                                <Sidebar
                                    category={category}
                                    subCategory={subCategory}
                                    isProductListPage={isProductListPage}
                                    isProductDetailPage={isProductDetailPage}
                                    isProductSearchPage={isProductSearchPage}
                                    onChangeProductType={this.handleChangeProductType}
                                    onReset={this.handleReset}
                                    handleChanges={handleChanges}
                                    checkboxes={checkboxes}
                                    options={options}
                                    states={this.state}
                                    loading={loading}
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column width={this.state.search ? 14 : !noSidebar ? 9 : 16} style={{ paddingTop: '35px' }}>
                            <Switch location={location}>
                                <ScrollToTop>
                                    <Route
                                        exact
                                        path={`${url}/search`}
                                        render={() => (
                                            <SearchResult
                                                products={this.props.products}
                                                productTypes={productTypeOptions}
                                                productType={productType}
                                                filteringLabels={filteringLabels}
                                                loading={loading}
                                                states={this.state}
                                                ratingOptions={ratingOptions}
                                                search={this.state.search}
                                                handlers={{
                                                    onSearchCurrentResult: this.handleSearchCurrentResult,
                                                    onFetchProductDetails: this.handleFetchProductDetails,
                                                    onFilterRating: this.handleFilterRating,
                                                    onSortRating: this.handleSortRating,
                                                    onSearchChange: this.handleSearchChange,
                                                }}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={`${url}`}
                                        render={() => (
                                            <Product
                                                onFetchProducts={this.handleFetchProducts}
                                                onSearchChange={this.handleSearchChange}
                                                onSearchSubmit={this.handleSearchSubmit}
                                                onFetchLowVoc={this.handleFetchLowVoc}
                                                history={this.props.history}
                                                loading={loading}
                                                categories={categories}
                                                subcategories={subcategories}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={`${url}/company/:companyId`}
                                        render={({ match }) => <CompanyInfo match={match} loading={loading} />}
                                    />
                                    <Route
                                        exact
                                        path={`${url}/certificate/:categoryId/:subcategoryId/:typeId/:certificateId/:companyId`}
                                        render={({ match }) => (
                                            <CertificateInfo
                                                loadBreadCrumb={this.loadBreadCrumb}
                                                onFetchProductDetails={this.handleFetchProductDetails}
                                                match={match}
                                                companyId={this.state.companyId}
                                                loading={loading}
                                                search={this.state.search}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={`${url}/:categoryId/:subcategoryId`}
                                        render={({ match }) => (
                                            <ProductTypes
                                                handleFetchProducts={this.handleFetchProducts}
                                                match={match}
                                                subcategories={subcategories}
                                                productTypes={productTypeOptions}
                                                productType={productType}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={`${url}/:categoryId/:subcategoryId/products`}
                                        render={({ match }) => (
                                            <ProductList
                                                handleFetchProducts={this.handleFetchProducts}
                                                match={match}
                                                productTypes={productTypeOptions}
                                                products={products}
                                                productsLength={productsLength}
                                                loading={loading}
                                                states={this.state}
                                                filteringLabels={filteringLabels}
                                                handleChanges={handleProductList}
                                                productType={productType}
                                                ratings={ratings}
                                                ratingOptions={ratingOptions}
                                                ratingLabels={this.props.ratingLabels}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={`${url}/:categoryId/:subcategoryId/:productId/products`}
                                        render={({ match }) => (
                                            <ProductList
                                                handleFetchProducts={this.handleFetchProducts}
                                                match={match}
                                                productTypes={productTypeOptions}
                                                products={products}
                                                productsLength={productsLength}
                                                loading={loading}
                                                states={this.state}
                                                filteringLabels={filteringLabels}
                                                handleChanges={handleProductList}
                                                productType={productType}
                                                ratings={ratings}
                                                ratingOptions={ratingOptions}
                                                ratingLabels={this.props.ratingLabels}
                                            />
                                        )}
                                    />
                                </ScrollToTop>
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <AffiliatesPanel />
            </Fragment>
        );
    }
}

function mouseHover(e) {
    e.target.style.color = '#78be20';
}

function mouseLeave(e) {
    e.target.style.color = '#555';
}

const BreadcrumbItem = props => {
    const { canRedirect, text, redirect } = props;

    if (canRedirect)
        return (
            <a onMouseOver={mouseHover} onMouseLeave={mouseLeave} onClick={redirect} href="#">
                {text}
            </a>
        );
    else return text;
};

const mapState = ({ public: publicReducer }, ownProps) => {
    const location = ownProps.location.pathname;
    const params = ownProps.match.params;
    const productTypes = publicReducer.productTypes && publicReducer.productTypes.filter(item => item.showLabel === 1);
    const subcategoryId = productType =>
        productTypes && productTypes.filter(item => item.productTypeId === productType).map(item => item.productSubcategoryId)[0];
    const home = '/public/product';
    const certificateInfo = '/public/product/certificate/';
    const ratings = publicReducer.ratings;
    const ratingOptions =
        ratings &&
        ratings.map(item => {
            const checkmark = <Image src="/assets/icons/tick.png" alt="tick" />;

            let text = '';

            if (item.ratingId === 1)
                text = (
                    <span>
                        {item.ratingName} &nbsp; {checkmark}
                    </span>
                );
            else if (item.ratingId === 2)
                text = (
                    <span>
                        {item.ratingName} &nbsp; {checkmark}
                        {checkmark}
                    </span>
                );
            else if (item.ratingId === 3)
                text = (
                    <span>
                        {item.ratingName} &nbsp; {checkmark}
                        {checkmark}
                        {checkmark}
                    </span>
                );
            else if (item.ratingId === 4)
                text = (
                    <span>
                        {item.ratingName} &nbsp; {checkmark}
                        {checkmark}
                        {checkmark}
                        {checkmark}
                    </span>
                );

            return { key: item.ratingId, text, value: item.ratingId };
        });

    const categoryOptions =
        publicReducer.categories &&
        publicReducer.categories.map(cat => ({
            key: cat.id,
            text: cat.category,
            value: cat.id,
        }));

    const subcategoryOptions =
        publicReducer.subcategory &&
        publicReducer.subcategory
            .filter(item => item.showLabel === 1)
            .map(cat => ({
                key: cat.productSubcategoryId,
                text: cat.subcategory,
                value: cat.productSubcategoryId,
                categoryId: cat.productCategoryId,
            }));

    const productTypeOptions =
        publicReducer.productType &&
        publicReducer.productType
            .filter(item => item.showLabel === 1)
            .map(item => ({
                key: item.productTypeId,
                text: item.type,
                value: item.productTypeId,
                subcategoryId: item.productSubcategoryId,
            }));

    const productTypeId = publicReducer.products && publicReducer.products.map(item => item.productTypeId);
    const filtered = [...new Set(productTypeId)];
    const productTypeFromSearch =
        filtered &&
        filtered.map(item => {
            const products = publicReducer.products;
            const text = products && products.filter(prod => prod.productTypeId === item).map(hore => hore.productType)[0];
            return { key: item, text, value: item };
        });

    const subcat = subcategoryOptions && subcategoryOptions !== null && subcategoryOptions !== undefined;
    let categories = publicReducer.categories && [
        ...publicReducer.categories,
        // { id: 'lowvoc', category: 'Low VOC', active: 1, icon: 'snowflake' }
    ];

    categories = categories.filter(x => x.category !== 'Low VOC');

    const products =
        publicReducer.products &&
        publicReducer.products.map(x => {
            const formatDate = date => moment(date).format('DD/MM/YYYY');
            return {
                ...x,
                validTill: formatDate(x.validTill),
                validFrom: formatDate(x.validFrom),
                issuedOn: formatDate(x.issuedOn),
            };
        });

    return {
        subcategories: publicReducer.subcategories && publicReducer.subcategories.filter(item => item.showLabel === 1),
        categoryOptions: [{ key: 'all', text: 'All product category', value: 'all' }, ...categoryOptions],
        subcategoryOptions: subcat && [
            { key: 'all', text: 'All product subcategory', categoryId: 'all', value: 'all' },
            ...subcategoryOptions,
        ],
        subcategory: publicReducer.subcategory && publicReducer.subcategory.filter(item => item.showLabel === 1),
        checkboxes: publicReducer.options,
        productTypeOptions: productTypeOptions && [...productTypeOptions],
        productTypeFromSearch: productTypeFromSearch && [
            { key: 'all', text: 'All product types', categoryId: 'all', value: 'all' },
            ...productTypeFromSearch,
        ],
        productType: publicReducer.productType,
        productsLength: publicReducer.allProducts && publicReducer.allProducts.length,
        loading: publicReducer.loading,
        filteringLabels: publicReducer.filteringLabels && publicReducer.filteringLabels,
        ratingLabels: publicReducer.filteringLabels && publicReducer.filteringLabels.ratings,
        productTypeLabels: publicReducer.filteringLabels && publicReducer.filteringLabels.productTypes,
        productTypeLabelsId: publicReducer.filteringLabels && publicReducer.filteringLabels.productTypes.map(item => item.productTypeId),
        noSidebar: location === home || location === '/public/product/' || location.includes(certificateInfo),
        ratingOptions: ratingOptions && [{ key: 'all', text: 'All', value: 'all' }, ...ratingOptions],
        isLowVoc: location.includes('/lowvoc'),
        isProductListPage: location.includes('/products'),
        isProductSearchPage: location.includes('/search'),
        isProductDetailPage: location.includes('/certificate'),
        products,
        categories,
        productTypes,
        subcategoryId,
    };
};

const actionList = {
    fetchLowVocProductTypes,
    fetchProductCategory,
    fetchProductSubcategory,
    fetchSingleSubcategory,
    fetchAllFilterOptions,
    fetchSpecificProductTypes,
    fetchAllProductTypes,
    fetchProducts,
    fetchAllProducts,
    fetchFilteringLabels,
    searchProducts,
    fetchRating,
    loadingPublic,
    unloadingPublic,
    fetchCertificateInfo,
    loadingPublicProductCategory,
    loadingPublicProductSubcategory,
    loadingSingleSubCategory,
    loadingAllProductTypes,
    loadingSpecificProductTypes,
    loadingLowVocProductTypes,
};

// prettier-ignore
export default connect(mapState, actionList)(TopNavigation2(withRouter(ProductContainer)))
