/**
 * Member Certification Overview page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import history from '../../../../../history';
import StoredLayout from 'layouts/StoredLayout';

import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AllApplicationsSummary from './AllApplicationsSummary';
import AllCertificationsSummary from './AllCertificationsSummary';

import {
    getApplicationOverviewStats,
    getCertificationOverviewStats,
} from 'actions/staff/certification/staffCertificationApplicationAction';

class StaffCertificationOverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.getApplicationData = this.getApplicationData.bind(this);
        this.getProductCertificationData = this.getProductCertificationData.bind(this);
        this.getServiceCertificationData = this.getServiceCertificationData.bind(this);
        this.state = {
            applicationList: {},
            productCertificationList: {},
            serviceCertificationList: {},
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.getApplicationData();
        this.getProductCertificationData();
        this.getServiceCertificationData();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getApplicationData() {
        this.showLoading(true);
        this.props
            .retrieveApplicationOverviewStats()
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        applicationList: res.data,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    getProductCertificationData() {
        this.showLoading(true);
        this.props
            .retrieveCertificateOverviewStats('product')
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        productCertificationList: res.data,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    getServiceCertificationData() {
        this.showLoading(true);
        this.props
            .retrieveCertificateOverviewStats('service')
            .then(res => {
                if (this._isMounted) {
                    this.setState({
                        serviceCertificationList: res.data,
                        loading: false,
                    });
                }
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    showLoading(loading) {
        if (this._isMounted)
            this.setState({
                loading,
            });
    }
    render() {
        if (this.props.loading) return <LoadingSpinner center />;
        return (
            <div className="page-widget">
                <Row>
                    <Col xs={12}>
                        <AllApplicationsSummary
                            applNew={this.state.applicationList.total_new}
                            applPayment={this.state.applicationList.total_payment}
                            applInvoice={this.state.applicationList.total_invoice}
                            applPaidAndFinalisation={this.state.applicationList.total_paid_and_finalisation}
                            applIssued={this.state.applicationList.total_issued}
                            applAssessment={this.state.applicationList.total_assessment}
                            applTotal={this.state.applicationList.total_applications}
                            title="Ongoing Cases"
                            link={() => history.push('/staff/certification/applications')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <AllCertificationsSummary
                            product
                            certIssuedThisYear={this.state.productCertificationList.issuedThisYear}
                            certIssuedThisMonth={this.state.productCertificationList.issuedThisMonth}
                            certRenewedLastYear={this.state.productCertificationList.renewedPercentageLastYear}
                            certRenewedThisYear={this.state.productCertificationList.renewedPercentageThisYear}
                            title="Product Certification Status"
                            linkText={'/staff/certification/certificates/product'}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <AllCertificationsSummary
                            service
                            certIssuedThisYear={this.state.serviceCertificationList.issuedThisYear}
                            certIssuedThisMonth={this.state.serviceCertificationList.issuedThisMonth}
                            certRenewedLastYear={this.state.serviceCertificationList.renewedPercentageLastYear}
                            certRenewedThisYear={this.state.serviceCertificationList.renewedPercentageThisYear}
                            title="Services Certification Status"
                            linkText={'/staff/certification/certificates/service'}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberProductApplication,
        certifications: state.memberCertifications,
        ...state.staffCertificationApplicationReducer,
        loading: state.staffCertificationApplicationReducer.manualLoading,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        retrieveApplicationOverviewStats: () => dispatch(getApplicationOverviewStats()),
        retrieveCertificateOverviewStats: type => dispatch(getCertificationOverviewStats(type)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffCertificationOverviewPage));
