import React from 'react';
import { Link } from 'react-router-dom';

import '../../css/elements/dropdown-grid.css';

const ModulesDropdown = ({ items }) => (
    <div className="navbar-dropdown dropdown-grid">
        <div className="dropdown-inner">
            <div className="dropdown-title">Applications</div>
            {items.map((item, i) => (
                <Link key={i} to="/" className="grid-item">
                    <i className="material-icons material-icons-3x">
                        {item.icon}
                    </i>
                    <div className="title">{item.title}</div>
                </Link>
            ))}
        </div>
    </div>
);

let items = [
    { icon: 'people', color: 'dark', title: 'Membership' },
    { icon: 'stars', color: 'dark', title: 'Certification' },
    { icon: 'event_available', color: 'dark', title: 'Events' },
    { icon: 'settings_voice', color: 'dark', title: 'Election' },
    { icon: 'library_books', color: 'dark', title: 'Resources' },
    { icon: 'assignment', color: 'dark', title: 'Cases' },
];

const SGBCModulesDropdown = () => <ModulesDropdown items={items} />;

export default SGBCModulesDropdown;
