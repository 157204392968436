import React from 'react';

import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
import AffiliatesPanel from 'pages/public/components/AffiliatesPanel';
import MembershipPanel from './components/MembershipPanel';

class MemberLoginPage extends React.Component {
    state = {
        panelToStart: 3,
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps) {
        /**
         * Implemented for the purpose when user on crate account page and login at topNavigation2 is clicked;
         * reset the panel to publicLogin page (given now the flow is run on single component, MembershipPanel).
         */
        if (prevProps.match.url !== this.props.match.url) {
            // NOTE: MembershipPanel prioritizes local storage first then panelToStart prop
            localStorage.setItem('panel', 3); // set local storage to 3 so membership page can be detected
            this.setState({
                panelToStart: 4, // change state to whatever num for MembershipPanel to be reloaded
            });
        }
    }
    render() {
        const MembershipCarouselItems = [
            {
                customComponent: <MembershipPanel panelToStart={this.state.panelToStart} location={this.props.match} />,
            },
        ];
        if (this.props.loading) return <LoadingSpinner />;
        return (
            <div>
                <MembershipPanel panelToStart={this.state.panelToStart} location={this.props.match} />
                <AffiliatesPanel />
            </div>
        );
    }
}
export default MemberLoginPage;
