import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Input, Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import { InputFieldWrapper } from '../event-checklist/AddPage';

import { save, update, remove, load } from 'actions/staff/events/event-expenditure/action.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        // Onchange
        this.descriptionChange = this.descriptionChange.bind(this);
        this.amountChange = this.amountChange.bind(this);
        this.transactionTypeChange = this.transactionTypeChange.bind(this);
        this.transactionDateChange = this.transactionDateChange.bind(this);
        this.eventIdChange = this.eventIdChange.bind(this);
        // Action
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.back = this.back.bind(this);
        // Modal
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        // State
        this.state = {
            loading: false,
            transactionTypes: [{ value: 'Expenditure', label: 'Expenditure' }, { value: 'Income', label: 'Income' }],
            selectedTransactionType: { value: '', label: '' },
            data: {
                description: '',
                amount: '',
                transactionType: '',
                transactionDate: '',
                eventId: '',
                id: 0,
            },
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        this.props.setMode('list', 0);
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuffs() {
        this.showLoading(true);
        const mapDate = date => moment(date).format('DD/MM/YYYY');
        try {
            const id = this.props.id;
            if (id !== null && id > 0) {
                const res = await this.props.load(id);
                if (res.error || res.data === null || res.data.id === null) {
                    this.showModal('Error', res.message, 'load', true, true);
                } else {
                    // Format transaction date
                    var formattedData = Object.assign({}, res.data);
                    formattedData.transactionDate = mapDate(res.data.transactionDate);
                    // Selected transaction type
                    let selectedTransactionType;
                    this.state.transactionTypes.forEach(option => {
                        if (option.value === res.data.transactionType) {
                            selectedTransactionType = option;
                        }
                    });
                    // Set state
                    if (this._isMounted) {
                        this.setState(
                            {
                                data: formattedData,
                                selectedTransactionType,
                            },
                            () => {},
                        );
                    }
                }
            } else {
                if (this._isMounted) {
                    this.setState({
                        data: {
                            transactionType: '',
                            transactionDate: '',
                            description: '',
                            eventId: '',
                            amount: '',
                            id: '',
                        },
                    });
                }
            }
        } catch (error) {
            this.showModal('Error', error.message, 'load', true, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.eventId = this.props.event.eventid;
        newData.transactionDate = moment(newData.transactionDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        return newData;
    }
    async save() {
        const data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        let res;
        try {
            if (data.id === null || data.id === 0 || data.id === '') {
                res = await this.props.save(data);
                res.error ? this.showModal('Error', res.message, 'save', true, true) : this.props.setMode('list', 0);
            } else {
                res = await this.props.update(data);
                res.error ? this.showModal('Error', res.message) : this.props.setMode('list', 0);
            }
        } catch (error) {
            this.showModal('Error', error.message, 'save', true, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    async remove() {
        var data = this.state.data;
        try {
            this.props.remove(data.id);
            this.props.setMode('list', 0);
        } catch (error) {
            this.showModal('Error', error.message, 'delete', true, true);
            throw error;
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    descriptionChange(e) {
        var data = this.state.data;
        data.description = e.target.value;
        this.setState({ data });
    }
    amountChange(e) {
        var data = this.state.data;
        data.amount = e.target.value;
        this.setState({ data });
    }
    transactionTypeChange(e) {
        var data = this.state.data;
        data.transactionType = e.value;
        this.setState({
            selectedTransactionType: e,
            data,
        });
    }
    transactionDateChange(e) {
        try {
            var data = this.state.data;
            data.transactionDate = e.format('DD/MM/YYYY');
            this.setState({
                data,
            });
        } catch (error) {
            this.showModal('Error', 'Please choose a valid date', 'delete', true, true);
        }
    }
    eventIdChange(e) {
        var data = this.state.data;
        data.eventId = e.target.value;
        this.setState({ data });
    }
    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                &nbsp; Back
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    {/* <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="id">ID</Label>
                                <Input type="text" name="id" id="id" style={{width: '100px'}} disabled value={this.state.data.id} placeholder="id" />{' '}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col xs={12}>
                            <FormGroup>
                                <Label for="eventId">eventId</Label>
                                <Input
                                    type="text"
                                    name="eventId"
                                    id="eventId"
                                    value={this.state.data.eventId}
                                    placeholder="eventId"
                                    onChange={this.eventIdChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={4}>
                            <InputFieldWrapper label="Transaction Type" labelFor="transactionType">
                                <Select
                                    autosize={false}
                                    options={this.state.transactionTypes}
                                    value={this.state.selectedTransactionType}
                                    clearable={this.state.select.clearable}
                                    onChange={this.transactionTypeChange}
                                    name="selected-state"
                                    disabled={this.state.select.disabled}
                                    searchable={this.state.select.searchable}
                                />

                                {/* <Input
                                    type="text"
                                    name="transactionType"
                                    id="transactionType"
                                    value={this.state.data.transactionType}
                                    placeholder=""
                                    onChange={this.transactionTypeChange}
                                /> */}
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <InputFieldWrapper label="Transaction Date" labelFor="transactionDate">
                                <CyderDatePicker
                                    value={this.state.data.transactionDate}
                                    onChange={this.transactionDateChange}
                                    closeOnSelect={true}
                                    datetimeClassName="mr-2"
                                    dateFormat="DD/MM/YYYY"
                                />
                                {/* <Input
                                    type="text"
                                    name="transactionDate"
                                    id="transactionDate"
                                    value={this.state.data.transactionDate}
                                    placeholder=""
                                    onChange={this.transactionDateChange}
                                /> */}
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <InputFieldWrapper label="Amount" labelFor="amount">
                                <Input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    value={this.state.data.amount}
                                    placeholder=""
                                    onChange={this.amountChange}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <InputFieldWrapper label="Description" labelFor="description">
                                <Input
                                    type="textarea"
                                    row={4}
                                    name="description"
                                    id="description"
                                    value={this.state.data.description}
                                    placeholder=""
                                    onChange={this.descriptionChange}
                                />
                            </InputFieldWrapper>
                        </Col>
                    </Row>
                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button className="primary-btn-style" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2}>
                                <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        ) : (
                            <div />
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        event: state.eventsCreationReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
