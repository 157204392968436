import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';

const DetailsPageRowCardHOC = ({ link, linkText = 'Edit', children }) => (
    <Row className="mb-2">
        <Col xs={12}>
            <Card>
                <CardBody>
                    {children}
                    <br />
                    {link && (
                        <Link to={link}>
                            <Button className="primary-btn-style">{linkText}</Button>
                        </Link>
                    )}
                </CardBody>
            </Card>
        </Col>
    </Row>
);
export default DetailsPageRowCardHOC;
