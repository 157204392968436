import React from 'react';
import { connect } from 'react-redux';

import TableWidgetCertificateApplicationsWidget from '../../table/TableWidgetCertificateApplicationsWidget';

class CertificateApplications extends React.Component {
    render() {
        return (
            <TableWidgetCertificateApplicationsWidget
                items={this.props.applications}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        applications: state.staff.dashboardInfo.certificates.applications,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CertificateApplications);
