import {
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST,
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS,
    MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE,
    MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY,
} from '../../../actions/constants/actionTypesMemberCertification';

const defaultState = {
    loading: false,
    filterBy: '',
    // Product Application
    prodApplAppl: 0,
    prodApplIssued: 0,
    prodApplPayment: 0,
    prodApplTotal: 0,
    // Product Cretification
    prodCertExpired: 0,
    prodCertExpiring: 0,
    prodCertValid: 0,
    // Services Application
    servApplAppl: 0,
    servApplIssued: 0,
    servApplPayment: 0,
    servApplTotal: 0,
    // Services Cretification
    servCertExpired: 0,
    servCertExpiring: 0,
    servCertValid: 0,
};

export default function memberCertificationsOverviewReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_REQUEST:
            newState.loading = true;
            return newState;
        case MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_SUCCESS:
            const dt = action.response.data;
            // Product Application
            newState.prodApplAppl = dt.products.applications.application;
            newState.prodApplIssued = dt.products.applications.issued;
            newState.prodApplPayment = dt.products.applications.payment;
            newState.prodApplTotal = newState.prodApplAppl + newState.prodApplIssued + newState.prodApplPayment;
            // Product Cretification
            newState.prodCertExpired = dt.products.certifications.expired;
            newState.prodCertExpiring = dt.products.certifications.expiring;
            newState.prodCertValid = dt.products.certifications.valid;
            // Services Application
            newState.servApplAppl = dt.services.applications.application;
            newState.servApplIssued = dt.services.applications.issued;
            newState.servApplPayment = dt.services.applications.payment;
            newState.servApplTotal = newState.servApplAppl + newState.servApplIssued + newState.servApplPayment;
            // Services Cretification
            newState.servCertExpired = dt.services.certifications.expired;
            newState.servCertExpiring = dt.services.certifications.expiring;
            newState.servCertValid = dt.services.certifications.valid;
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_OVERVIEW_GET_INFO_FAILURE:
            newState.loading = false;
            return newState;
        case MEMBER_CERTIFICATION_OVERVIEW_SET_FILTERED_BY:
            newState.filterBy = action.status;
            return newState;
        default:
            return newState;
    }
}
