import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../history';

import { Alert, Button, Container, Row, Col } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import TopNavigation2 from '../../layouts/TopNavigation2';
// import { InputComponentWrapper } from '../../pages/public/PublicLogin';

import { cyderForgotPasswordPageSetMessageAction, cyderForgotPasswordAction } from '../../actions/security/cyderSecurityAction';
// import CyderForm from '../../cyder/forms/CyderForm';
import CyderInputGroup from '../../cyder/forms/CyderInputGroup';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.showLoading = this.showLoading.bind(this);
        this.state = {
            loading: false,
        };
    }
    componentWillMount() {
        window.scrollTo(0, 0);
        this.props.clearErrorMessage();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    render() {
        const style = this.props.message ? null : { display: 'none' };
        const fontStyle = {
            lineHeight: '18px',
            fontSize: '18px',
        };
        return (
            <div className="forgetpassword-panel" style={{ paddingTop: '20px' }}>
                <Row className="text-left">
                    <Col>
                        <div className="forgotpassword">
                            <span>Forgot</span> your Password?
                        </div>
                    </Col>
                </Row>
                <Container className="form-group">
                    <Row className="justify-content-center">
                        <Col
                            sm={12}
                            md={{
                                size: 5,
                            }}
                        >
                            {this.props.message ? (
                                <div className="field-header" style={{ color: '#76777b' }}>
                                    {this.props.message}
                                </div>
                            ) : null}
                            {!this.props.success && (
                                <Fragment>
                                    <div className="field-header" style={{ color: '#76777b' }}>
                                        Please enter your registered email address.
                                        <br />
                                        <CyderInputGroup
                                            field={this.props.fields[0]}
                                            onKeyDown={e => {
                                                if (e.keyCode == 13) this.validateThenSubmit();
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            {this.state.loading ? (
                                <LoadingSpinner style={fontStyle} />
                            ) : !this.props.success ? (
                                <Row className="justify-content-center">
                                    <Button
                                        className="m-2 primary-green"
                                        disabled={this.props.requesting}
                                        onClick={() => this.validateThenSubmit()}
                                        style={{ padding: '10px 15px' }}
                                    >
                                        Submit
                                    </Button>
                                    {/* <Button
                                    className="m-2 primary-green"
                                    style={{ padding: '10px 15px' }}
                                    onClick={() => {
                                        if (this.props.changePanel !== null) {
                                            this.props.changePanel(3);
                                        } else {
                                            history.push(`/public/login/staff`);
                                        }
                                    }}
                                >
                                    Log in
                                </Button> */}
                                </Row>
                            ) : (
                                <Link to="/">
                                    <Button className="m-2 primary-btn-style" style={{ padding: '10px 15px' }}>
                                        Back to Home
                                    </Button>
                                </Link>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
    validateThenSubmit() {
        this.props.clearErrorMessage();
        const email = document.getElementById(this.props.fields[0].id).value;
        if (email === '') {
            this.props.setMessage('Please enter your email address');
            return false;
        }

        this.showLoading(true);
        this.props
            .changePasswordSubmit(email)
            .then(res => {
                this.showLoading(false);
                if (!this.props.success) {
                    this.props.setMessage('You have entered an invalid email address. Please enter a valid email address.');
                } else {
                    this.props.setMessage(
                        'You have successfully reset your password. You will receive an email with instruction to change your password',
                    );
                }
            })
            .catch(error => {
                this.showLoading(false);
                this.props.setMessage('You have entered an invalid email address. Please enter a valid email address.');
                throw error;
            });
    }
}

const mapStateToProps = state => {
    return {
        ...state.forgotPasswordReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => {
            dispatch(cyderForgotPasswordPageSetMessageAction(''));
        },
        setMessage: message => {
            dispatch(cyderForgotPasswordPageSetMessageAction(message));
        },
        changePasswordSubmit: email => {
            var navigateHome = () => {
                var navigate = '/home';
                history.replace(navigate);
            };
            var navigateLogin = () => {
                var navigate = '/';
                history.replace(navigate);
            };
            return dispatch(cyderForgotPasswordAction(email, navigateHome, navigateLogin));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(ForgotPassword));
