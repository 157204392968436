import {
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE,
    GET_COMPANY_REP_INFO_REQUEST,
    GET_COMPANY_REP_INFO_SUCCESS,
    GET_COMPANY_REP_INFO_FAILURE,
    SET_MODAL_ACTION,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS,
    TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE,
    TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION,
} from '../../actions/constants/actionTypesTeamManagement';

import { setReducerModalState, defaultModalKeys } from 'js/modal';
import validationlib from 'js/validation';
import { sanitizeObjectNullsToEmptyStrings } from 'js/util';

const defaultState = {
    fields: {
        firstName: '',
        lastName: '',
        email: '',
        designation: '',
        telephoneDid: '',
        mobileNumber: '',
        department: '',
        salutationId: '',
        salutation: {
            value: '',
            label: '',
        },
    },
    validation: {},
    salutationTypes: [],
    companyRepInfo: {
        officialManagementRep: {},
        secondaryManagementRep: {},
    },
    ...defaultModalKeys,
};

export default function teamMembersDetailsReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    let salutation = {};
    switch (action.type) {
        // Get
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_REQUEST:
            newState.loading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_SUCCESS:
            newState.loading = false;
            let salutationTypes = action.response.data.map(each => {
                return {
                    label: each.salutation,
                    value: each.id,
                };
            });
            newState.salutationTypes = salutationTypes;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_SALUTATION_FAILURE:
            newState.loading = false;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_REQUEST:
            newState.loading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_SUCCESS:
            newState.loading = false;
            newState.fields = Object.assign(
                {},
                sanitizeObjectNullsToEmptyStrings(action.response.data, {
                    firstName: true,
                    lastName: true,
                    telephoneDid: true,
                    mobileNumber: true,
                    designation: true,
                    department: true,
                }),
            );
            salutation = {
                value: action.response.data.salutationId,
                label: action.response.data.salutationName ? action.response.data.salutationName : action.response.data.salutation,
            };
            newState.fields.salutation = salutation;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_GET_MEMBER_DETAILS_FAILURE:
            newState.loading = false;
            return newState;
        // Suspend
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SUSPEND_MEMBER_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = null;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Verify
        case TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_VERIFY_MEMBER_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalHeader = 'Error';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;

        // Transfer
        case TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            // newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_TRANSER_MEMBER_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalHeader = 'Error';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;

        case TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_PASSWORD_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalHeader = 'Error';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Remove
        case TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Subscriber successfully removed.';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_REMOVE_MEMBER_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = 'Failed to remove member.';
            newState.modalAction = null;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Save
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_REQUEST:
            newState.modalLoading = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = 'Team Member details successfully updated.';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SAVE_MEMBER_DETAILS_FAILURE:
            if (action.error.data.data != undefined && action.error.data.data == 0) {
                newState.modalBody = 'Please contact SGBC staff to modify any existing roles that has been assigned to your team members.';
            } else {
                newState.modalBody = 'Failed to save member';
            }
            newState.modalLoading = false;
            newState.modalHeader = 'Error';
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        // Others
        case TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_FIELD:
            newState.loading = false;
            newState.fields = Object.assign({}, state.fields);
            newState.fields[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            newState.validation[action.key] = validationlib.validate(action.key, action.value);
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_CHANGE_SALUTATION:
            newState.loading = false;
            newState.fields = Object.assign({}, state.fields);
            salutation = {
                value: action.value,
                label: action.label,
            };
            newState.fields.salutation = salutation;
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_RESET_STATE:
            newState = Object.assign({}, defaultState);
            return newState;
        case TEAM_MANAGEMENT_MEMBER_DETAILS_FORCE_VALIDATE:
            const fkeys = ['firstName', 'lastName', 'email', 'telephoneDID', 'mobileNumber', 'designation', 'department'];
            newState.validation = Object.assign({}, state.validation);
            for (let i = 0; i < fkeys.length; i += 1) {
                newState.validation[fkeys[i]] = validationlib.validate(fkeys[i], state.fields[fkeys[i]]);
            }
            return newState;
        case SET_MODAL_ACTION:
            newState.modalAction = action.modalAction;
            return newState;
        // Get - management representative info
        case GET_COMPANY_REP_INFO_REQUEST:
            return newState;
        case GET_COMPANY_REP_INFO_SUCCESS:
            const data = action.response.data;
            newState.companyRepInfo.officialManagementRep = sanitizeObjectNullsToEmptyStrings(data.officialManagementRep);
            newState.companyRepInfo.secondaryManagementRep = sanitizeObjectNullsToEmptyStrings(data.secondaryManagementRep);
            return newState;
        case GET_COMPANY_REP_INFO_FAILURE:
            return newState;
        default:
            return newState;
    }
}
