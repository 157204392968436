import withQuery from 'with-query';
import {
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
    STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS,
    MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE,
    MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANGE,
    MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS,
    MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE,
    MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT,
    MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES,
    MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING,
    MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE,
    MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM,
    MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS,
} from 'actions/constants/actionTypesMemberCompany';

import fetchlib from 'js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';

// Use for Staff - Company profile
export function staffCompanyDetailsGetCompanyInfo(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getcompanyinfo',
            companyId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE,
        );
    };
}
export function staffCompanyDetailsUpdateCompanyProfile() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().memberCompanyReducer.companyInfo);
        // Post process subCategory
        body.subCategory = Object.keys(body.subCategory)
            .map(key => {
                if (body.subCategory[key].checked) {
                    return parseInt(key);
                }
            })
            .filter(x => x !== undefined);
        // Post process organisationMainCategoryId
        if (body.organisationMainCategoryId === 0) {
            body.organisationMainCategoryId = null;
            body.organisationName = null;
        }
        // Post process annualTurnover
        body.annualTurnover = parseInt(body.annualTurnover);
        body.countryId = parseInt(body.countryId);
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'updateCompanyProfileByStaff',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
            STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
            STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
        );
    };
}
// Use for Member - Company profile
export function memberMembershipApplicationGetCompanyInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'getcompanyinfobycompany',
            companyId: id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE,
        );
    };
}
export function memberMembershipApplicationGetCountries() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/misc_v1/cn/country`, {
            action: 'findall',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE,
        );
    };
}
export function memeberMembershipApplicationGetBusinessActivity() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/orgcategories`, {
            action: 'findall',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE,
        );
    };
}
export function memberMembershipApplicationGetCategories() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/orgmaincategories`, {
            action: 'getallmainandsubcategory',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE,
        );
    };
}
export function memberMembershipApplicationUpdateCompanyProfile() {
    return (dispatch, getState) => {
        let body = Object.assign({}, getState().memberCompanyReducer.companyInfo);
        // Post process subCategory
        body.subCategory = Object.keys(body.subCategory)
            .map(key => {
                if (body.subCategory[key].checked) {
                    return parseInt(key);
                }
            })
            .filter(x => x !== undefined);
        // Post process organisationMainCategoryId
        if (body.organisationMainCategoryId === 0) {
            body.organisationMainCategoryId = null;
            body.organisationName = null;
        }
        body.annualTurnover = parseInt(body.annualTurnover);
        body.countryId = parseInt(body.countryId);
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'updateCompanyProfileByUser',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE,
        );
    };
}
export function memberMembershipApplicationUploadAttachment(contenttype, filename, attachment) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/attachment`, {
            action: 'upload',
        });
        const body = {
            contenttype,
            filename,
            attachment,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE,
        );
    };
}
export function memberMembershipApplicationDownloadAttachment() {
    return (dispatch, getState) => {
        // console.log("getState().memberCompanyReducer.companyInfo.logo: ", getState().memberCompanyReducer.companyInfo.logoLocation);
        const url = withQuery(`${SGBC_API_ROOT}/resources_v1/attachment`, {
            action: 'download',
            id: getState().memberCompanyReducer.companyInfo.logoLocation,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST,
            MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS,
            MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE,
        );
    };
}
export function memberMembershipApplicationRemoveAttachment() {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT,
    };
}
export function memberMembershipApplicationResetFields() {
    return (dispatch, getState) => {
        dispatch({ type: MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS });
        return Promise.resolve();
    };
}
export function memeberMembershipApplicationSetModalData(data) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE,
        data,
    };
}
export function memberMembershipApplicationSetLoading(load) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING,
        load,
    };
}
export function memeberMembershipApplicationCopyAddresses() {
    return { type: MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES };
}
export function memberMembershipApplicationHandleInputChange(event, subId, mainId, mainName) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANGE,
        event,
        subId,
        mainId,
        mainName,
    };
}
export function memberMembershipApplicationSetMemberProfileMode(isMember, companyId) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE,
        isMember,
        companyId,
    };
}
export function memberMembershipApplicationSetLoginFrom(loginFrom) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM,
        loginFrom,
    };
}

export function memberMembershipApplicationSetMembershipStatus(status) {
    return {
        type: MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS,
        status,
    };
}
