import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Image, Segment, Header, List, Divider, Icon, Button, Tab, Item } from 'semantic-ui-react';

import { fetchCertificateInfo, fetchCertificateInfoTagging, fetchServiceCompanyInfo } from '../../../actions/public';
import { ServiceCertListPanel } from '../service/ServiceDetails';
import { mobile } from '../dummy';

import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';

export const ProductCertListPanel = props => {
    const { item, onFetchProductDetails, ratingTicks } = props;

    return (
        <Item
            as="a"
            key={item.certificateId}
            onClick={() =>
                onFetchProductDetails(
                    item.certificateId,
                    item.companyId,
                    item.productTypeId,
                    item.productSubcategoryId,
                    item.productCategoryId,
                )
            }
        >
            <Item.Content>
                <Segment basic className="border-bottom">
                    <Header className="company">{item.companyName}</Header>
                </Segment>
                <Item.Description className="description-list">
                    <Grid stackable>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <div>Brand:</div> &nbsp;
                                        <div>
                                            <strong>{item.brandName}</strong>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div>Model:</div> &nbsp;
                                        <div>
                                            <strong>{item.models}</strong>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div>Product type:</div> &nbsp;
                                        <div>
                                            <strong>{item.productType}</strong>
                                        </div>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <div>Certification number:</div> &nbsp;
                                        <div>
                                            <strong>{item.certNo}</strong>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div>Issued on:</div> &nbsp;
                                        <div>
                                            <strong>
                                                {item.validFrom ? moment(item.validFrom, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                                            </strong>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div>Valid until:</div> &nbsp;
                                        <div>
                                            <strong>
                                                {item.validTill ? moment(item.validTill, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'}
                                            </strong>
                                        </div>
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} style={{ marginLeft: '10px' }}>
                            <Grid.Column>
                                <br />
                                <Header as="h5" className="ticks">
                                    {item.rating} &nbsp; {ratingTicks(item)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Item.Description>
            </Item.Content>
        </Item>
    );
};

class CertificateInfo extends Component {
    state = { activeIndex: 0 };

    componentDidMount() {
        const { loadBreadCrumb, match } = this.props;
        const { categoryId, subcategoryId, typeId } = match.params;
        loadBreadCrumb(categoryId, subcategoryId, typeId);

        this.props.fetchCertificateInfo(this.props.certificateId, this.props.companyId);
        this.props.fetchCertificateInfoTagging(this.props.certificateId);

        // Retrieve service cert list
        this.props.fetchServiceCompanyInfo({ companyId: this.props.companyId, serviceId: -1 });
    }

    handleFetchServiceDetails = (companyId, serviceId) => {
        this.props.fetchServiceCompanyInfo({ companyId, serviceId });
        this.props.history.push(`/public/service/details/${companyId}/${serviceId}`);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.certificateId !== this.props.certificateId) {
            this.setState({ activeIndex: 1 });
        }
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

    render() {
        const {
            certificateInfo,
            ratingTicks,
            ratingTicksService,
            tagging,
            loading,
            data,
            history,
            onFetchProductDetails,
            showMoreDetails,
        } = this.props;
        const { activeIndex } = this.state;
        const productCatalogue = certificateInfo && certificateInfo.certificateDetail && certificateInfo.certificateDetail.productCatalogue;
        const { cert, company, certList } = data;

        const panes = [
            {
                menuItem: { key: 'company', icon: 'building outline', content: 'Company' },
                render: () => (
                    <Tab.Pane className="company-details">
                        {company && company.logo && (
                            <Segment basic>
                                <Grid columns={mobile ? 1 : 3}>
                                    <Grid.Column>
                                        <img src={SGBC_S3_ROOT + company.logo} width={'100%'} />
                                    </Grid.Column>
                                </Grid>
                            </Segment>
                        )}
                        <Segment basic>
                            <Header as="h4" content="Company Details" />
                        </Segment>
                        <Grid columns={3}>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Company Name</List.Header>
                                        {company && company.name}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Business Address</List.Header>
                                        {company && company.businessAddressLine1}
                                        {company && company.businessAddressLine2}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Country</List.Header>
                                        {company && company.countryName ? company.countryName : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid columns={3}>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Phone Number</List.Header>
                                        {company && company.mainPhoneNumber ? company.mainPhoneNumber : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>Website</List.Header>
                                        {company && company.website ? (
                                            <a href={'//' + company.website} target="_blank">
                                                {company.website}
                                            </a>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List>
                                    <List.Item>
                                        <List.Header>General Email</List.Header>
                                        {company && company.generalEmail ? company.generalEmail : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        {company && (company.corporateMember === 1 || company.foundingMember === 1) && (
                            <React.Fragment>
                                <Divider />
                                <Grid columns={1}>
                                    <Grid.Column>
                                        <List>
                                            <List.Item>
                                                <List.Header>Organisation Primary Business Activity</List.Header>
                                                <List.List>
                                                    {company && company.organisationMainCategory ? company.organisationMainCategory : '-'}
                                                </List.List>
                                            </List.Item>
                                        </List>
                                        <List>
                                            <List.Item>
                                                <List.Header>Services Offered by Organisation</List.Header>
                                                {company && company.organisationSubCategory ? (
                                                    <List.List>
                                                        {company.organisationSubCategory.split(',').map(item => (
                                                            <List.Item key={item}>{item}</List.Item>
                                                        ))}
                                                    </List.List>
                                                ) : (
                                                    '-'
                                                )}
                                            </List.Item>
                                        </List>
                                        <List>
                                            <i className="fa fa-quote-left fa-3x faded-less" />
                                            &nbsp;&nbsp;
                                            {company && company.greenBuildingMovementInvolvement
                                                ? company.greenBuildingMovementInvolvement
                                                : '-'}
                                        </List>
                                    </Grid.Column>
                                </Grid>
                                <Divider />
                                <Grid columns={1}>
                                    <Grid.Column>
                                        <List>
                                            <List.Item>
                                                <List.Header>Singapore Green Building Council Membership</List.Header>
                                                {company && company.corporateMember === 1 ? (
                                                    <List>
                                                        <h2>Corporate Member</h2>
                                                    </List>
                                                ) : (
                                                    ''
                                                )}
                                                {company && company.foundingMember === 1 ? (
                                                    <List>
                                                        <h2>Founding Member</h2>
                                                    </List>
                                                ) : (
                                                    ''
                                                )}
                                                {company && company.memberSince ? (
                                                    <List>
                                                        <h4
                                                            className="badge badge-primary"
                                                            style={{ fontSize: '1em', backgroundColor: '#78BE20' }}
                                                        >
                                                            Since {company.memberSince}
                                                        </h4>
                                                    </List>
                                                ) : (
                                                    ''
                                                )}
                                            </List.Item>
                                        </List>
                                    </Grid.Column>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Tab.Pane>
                ),
            },

            {
                menuItem: { key: 'certificate', icon: 'certificate', content: 'Certificate' },
                render: () => (
                    <Tab.Pane>
                        <Segment clearing basic>
                            {!mobile && productCatalogue && (
                                <Header as="h4" floated="right">
                                    <Button
                                        size="tiny"
                                        icon
                                        basic
                                        circular
                                        labelPosition="right"
                                        className="btn-sgbc"
                                        onClick={() => {
                                            window.open(SGBC_S3_ROOT + productCatalogue);
                                        }}
                                    >
                                        <Icon name="file pdf outline" /> &nbsp; Download product brochure
                                    </Button>
                                </Header>
                            )}
                            <Header as="h4" content="Certificate details" floated="left" className="certificate-details" />
                        </Segment>
                        <Segment basic>
                            {mobile && productCatalogue && (
                                <Header as="h4" style={{ fontSize: '1em', padding: '.5em', marginBottom: '2em' }}>
                                    <Button
                                        size="tiny"
                                        icon
                                        basic
                                        circular
                                        labelPosition="right"
                                        className="btn-sgbc"
                                        onClick={() => {
                                            window.open(SGBC_S3_ROOT + productCatalogue);
                                        }}
                                    >
                                        <Icon name="file pdf outline" /> &nbsp; Download product brochure
                                    </Button>
                                </Header>
                            )}
                            <List relaxed>
                                <List.Item>
                                    <List.Header content="Brand name" />
                                    {(cert && cert.brandName) || '-'}
                                </List.Item>{' '}
                                <br />
                                <List.Item>
                                    <List.Header content="Description" />
                                    {cert && cert.description && cert.description !== '' ? cert.description : '-'} <br />
                                </List.Item>
                            </List>
                        </Segment>
                        <Grid columns={3}>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Certificate number</List.Header>
                                        {cert && cert.certNo}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Valid from</List.Header>
                                        {cert && cert.validFrom && moment(cert.validFrom, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Valid until</List.Header>
                                        {cert && cert.validTill && moment(cert.validTill, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid columns={3}>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Product type</List.Header>
                                        {cert && cert.productType ? cert.productType : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Models</List.Header>
                                        {cert && cert.models ? cert.models : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed>
                                    <List.Item>
                                        <List.Header>Rating</List.Header>
                                        {cert && cert.rating ? cert.rating : '-'}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        <Grid columns={3}>
                            <Grid.Column>
                                <List relaxed className="attributes">
                                    <List.Item>
                                        <List.Header>Functional System</List.Header>
                                        {tagging && tagging.bcaId.length > 0 ? (
                                            <List.List>
                                                {tagging.bcaId.map(item => (
                                                    <List.Item key={item}>{item}</List.Item>
                                                ))}
                                            </List.List>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed className="attributes">
                                    <List.Item>
                                        <List.Header>Green attributes</List.Header>
                                        {tagging && tagging.greenAttributeId.length > 0 ? (
                                            <List.List>
                                                {tagging.greenAttributeId.map(item => (
                                                    <List.Item key={item}>{item}</List.Item>
                                                ))}
                                            </List.List>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column>
                                <List relaxed className="attributes">
                                    <List.Item>
                                        <List.Header>Green credentials</List.Header>
                                        {tagging && tagging.greenCredentialsId.length > 0 ? (
                                            <List.List>
                                                {tagging.greenCredentialsId.map(item => (
                                                    <List.Item key={item}>{item}</List.Item>
                                                ))}
                                            </List.List>
                                        ) : (
                                            '-'
                                        )}
                                    </List.Item>
                                </List>
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane>
                ),
            },

            {
                menuItem: { key: 'list', icon: 'tasks', content: 'List of Certificate' },
                render: () => (
                    <Tab.Pane>
                        <Segment basic>
                            <Header as="h4" content="List of Certificate" />
                        </Segment>
                        <Item.Group>
                            {certList &&
                                certList
                                    .sort((a, b) => b.ratingId - a.ratingId)
                                    .map(item => (
                                        <ProductCertListPanel
                                            key={item.productId}
                                            item={item}
                                            onFetchProductDetails={onFetchProductDetails}
                                            ratingTicks={ratingTicks}
                                        />
                                    ))}

                            {showMoreDetails &&
                                showMoreDetails.length > 0 &&
                                showMoreDetails.map(item => (
                                    <ServiceCertListPanel
                                        key={item.serviceId}
                                        item={item}
                                        onFetchServiceDetails={this.handleFetchServiceDetails}
                                        ratingTicks={ratingTicksService}
                                    />
                                ))}
                        </Item.Group>
                    </Tab.Pane>
                ),
            },
        ];

        return (
            <Grid stackable centered className={loading ? 'certificate-info-page loading' : 'certificate-info-page'}>
                <Grid.Column width={mobile ? 16 : this.props.search ? 14 : 12}>
                    <Segment basic className="details">
                        <Header>{cert && cert.companyName}</Header>
                        <Tab
                            menu={{ fluid: true, vertical: !mobile, secondary: true }}
                            activeIndex={activeIndex}
                            onTabChange={this.handleTabChange}
                            panes={panes}
                        />
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = ({ public: publicReducer }, ownProps) => {
    const certificateId = Number(ownProps.match.params.certificateId);
    const companyId = Number(ownProps.match.params.companyId);
    const cert = publicReducer.certificateInfo && publicReducer.certificateInfo[0].certificateDetail;
    const company = publicReducer.certificateInfo && publicReducer.certificateInfo[0] && publicReducer.certificateInfo[0].companyDetail;
    const certList = publicReducer.certificateInfo && publicReducer.certificateInfo[0].allCertificateDetail;

    const showMoreDetails = publicReducer.companyInfo && publicReducer.companyInfo.showMoreDetails;

    const ratingTicks = item => {
        const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" />;

        let text = '';

        if (item.ratingId === 1) text = <span>{checkmark}</span>;
        else if (item.ratingId === 2)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 3)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 4)
            text = (
                <span>
                    {checkmark}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );

        return text;
    };

    const ratingTicksService = item => {
        let text = '';

        let css = {
            color: '#78be20',
        };

        if (item.ratingId === 5 || item.ratingId === 1) text = <span style={css}> L1</span>;
        else if (item.ratingId === 6 || item.ratingId === 2) text = <span style={css}> L2</span>;
        else if (item.ratingId === 7 || item.ratingId === 3) text = <span style={css}> L3</span>;
        else if (item.ratingId === 8 || item.ratingId === 4) text = <span style={css}> L4</span>;

        return text;
    };

    return {
        certificateInfo: publicReducer.certificateInfo && publicReducer.certificateInfo[0],
        tagging: publicReducer.tagging && publicReducer.tagging,
        loading: publicReducer.loading,
        data: { company, cert, certList },
        certificateId,
        showMoreDetails,
        companyId,
        ratingTicks,
        ratingTicksService,
    };
};

// prettier-ignore
export default connect(mapState, { fetchCertificateInfo, fetchServiceCompanyInfo, fetchCertificateInfoTagging })(withRouter(CertificateInfo));
