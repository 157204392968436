import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

// import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getOrganisationalCategoryAttendedAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class AttendeeList extends React.Component {
    _isMounted = true;
    state = {
        data: [],
    };
    componentDidMount() {
        this.props.load(this.props.eventId).then(res => {
            if (this._isMounted)
                this.setState({
                    data: res.data,
                });
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <AnalyticsCard title={`Attended by Organisational Categories`}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Quantity</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item.category}</td>
                                            <td>{item.count}</td>
                                            <td>{item.percentage}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: eventId => {
            return dispatch(getOrganisationalCategoryAttendedAnalytic(eventId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AttendeeList));
