import React from 'react';
import { Input } from 'reactstrap';

class Dropdown extends React.Component {
    constructor() {
        super();
    }

    render() {
        let tables = this.props.options;
        let selected = this.props.selectedTableNo;
        let registrationId = this.props.registrationId;

        return (
            <div>
                <Input type="select" onChange={e => this.props.assignTable(e.target.value, registrationId)} value={selected}>
                    <option value="0">Not Assigned</option>
                    {tables.map((table, i) => (
                        <option key={i} value={table.value}>
                            {table.label}
                        </option>
                    ))}
                </Input>
            </div>
        );
    }
}

export default Dropdown;
