// Get - event tracks
export const EVENT_GET_EVENT_TRACKS_REQUEST = 'EVENT_GET_EVENT_TRACKS_REQUEST';
export const EVENT_GET_EVENT_TRACKS_SUCCESS = 'EVENT_GET_EVENT_TRACKS_SUCCESS';
export const EVENT_GET_EVENT_TRACKS_FAILURE = 'EVENT_GET_EVENT_TRACKS_FAILURE';

// Save - event tracks
export const EVENT_SAVE_TRACK_REQUEST = 'EVENT_SAVE_TRACK_REQUEST';
export const EVENT_SAVE_TRACK_SUCCESS = 'EVENT_SAVE_TRACK_SUCCESS';
export const EVENT_SAVE_TRACK_FAILURE = 'EVENT_SAVE_TRACK_FAILURE';

// Update - event tracks
export const EVENT_UPDATE_TRACK_REQUEST = 'EVENT_UPDATE_TRACK_REQUEST';
export const EVENT_UPDATE_TRACK_SUCCESS = 'EVENT_UPDATEE_TRACK_SUCCESS';
export const EVENT_UPDATE_TRACK_FAILURE = 'EVENT_UPDATE_TRACK_FAILURE';

// Add, save, and delete - Single tracks
export const EVENT_SAVE_NEW_EVENT_TRACK = 'EVENT_ADD_NEW_EVENT_TRACK';
export const EVENT_SAVE_SINGLE_EVENT_TRACK = 'EVENT_SAVE_SINGLE_EVENT_TRACK';
export const EVENT_DELETE_SINGLE_EVENT_TRACK = 'EVENT_DELETE_SINGLE_EVENT_TRACK';

// Set - event Id
export const EVENT_TRACK_SET_EVENT_ID = 'EVENT_TRACK_SET_EVENT_ID';

// Input fields action
export const EVENT_TRACK_CHANGE_FIELD_VALUE = 'EVENT_TRACK_CHANGE_FIELD_VALUE';

// Reset - all fields
export const EVENT_TRACK_RESET_FIELDS = 'EVENT_TRACK_RESET_FIELDS';

// Modal
export const EVENT_TRACK_SET_MODAL_DATA = 'EVENT_TRACK_SET_MODAL_DATA';

export const EVENT_ALL_GET_EVENTS_SUCCESS = 'EVENT_ALL_GET_EVENTS_SUCCESS';
