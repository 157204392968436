import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../history';
import moment from 'moment';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import EventInfoPanel from './EventInfoPanel';
import EventTicketPanel from './EventTicketPanel';
import EventAttendeesPanel from './EventAttendeesPanel';
import EventConfirmationPanel from './EventConfirmationPanel';

import {
    loadEventInfo,
    loadEventMultipleDatesInfo,
    loadEventDatesInfo,
    loadEventTickets,
    loadEventTracks,
    submitRegistration,
    loadRegistrations,
    loadEventMaterials,
    validatePromoCode,
    loadAllEventTicketsForStaff,
} from 'actions/shared/eventRsvpAction.js';
import { getCompaniesAsActiveMembers } from 'actions/security/cyderSecurityAction';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';
import { memberHomePanelMembershipGetInfo } from 'actions/member/home/memberHomeAction';
import { personalProfileGetProfileInfo } from 'actions/profile/personalProfileAction';
import { staffMembershipMemberGetOrganisationCategory } from 'actions/staff/membership/members/staffMembershipMembersAction.js';

import cyderlib from '../../js/cyderlib';
import { validateFromValidationObjectPromise } from '../../js/validation';
import { sanitizeStringAndObjectNullsToEmptyStrings } from '../../js/util';

import { SGBC_S3_ROOT } from 'config';

class EventsRsvpPage extends React.Component {
    constructor(props) {
        super(props);
        this.clickButton = this.clickButton.bind(this);
        this.changeTixNum = this.changeTixNum.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.showLoadingDates = this.showLoadingDates.bind(this);
        this.showLoadingTickets = this.showLoadingTickets.bind(this);
        this.showLoadingTracks = this.showLoadingTracks.bind(this);
        this.showLoadingValidatePromoCode = this.showLoadingValidatePromoCode.bind(this);
        this.showLoadingSubmit = this.showLoadingSubmit.bind(this);
        this.ticketSelectionChange = this.ticketSelectionChange.bind(this);
        this.fillUpAttendees = this.fillUpAttendees.bind(this);
        this.onTrackChange = this.onTrackChange.bind(this);
        this.onAttendeeTextChange = this.onAttendeeTextChange.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onAttendeeChangeIsGmp = this.onAttendeeChangeIsGmp.bind(this);
        this.completeRegistration = this.completeRegistration.bind(this);
        this.setRegistrationResult = this.setRegistrationResult.bind(this);
        this.proceedPayment = this.proceedPayment.bind(this);
        this.onPromoCodeChange = this.onPromoCodeChange.bind(this);
        this.validatePromoCode = this.validatePromoCode.bind(this);
        this.validateIsMemberBeforeRedirect = this.validateIsMemberBeforeRedirect.bind(this);
        this.validationBeforeSumbmission = this.validationBeforeSumbmission.bind(this);
        this.onCompanyChange = this.onCompanyChange.bind(this);
        this.onAgreedTermsChange = this.onAgreedTermsChange.bind(this);
        this.downloadTermAndConditionsDocument = this.downloadTermAndConditionsDocument.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            selectedOrganisationCategory: null,
            organisationCategoryMap: [],
            mode: 'public',
            buttonClicked: false,
            tixnum: '0',
            activeTab: 'main',
            loading: false,
            loadingDates: false,
            loadingRegistrations: false,
            loadingTickets: false,
            loadingValidatePromoCode: false,
            loadingTracks: false,
            successfulRegistrationId: 0,
            loadingSubmit: false,
            agreeTerms: false,
            memberProfile: {},
            event: {},
            eventDate: {},
            eventDates: [],
            tickets: [],
            attendees: [],
            materials: [],
            tracks: [],
            registrations: [],
            validationObjs: [],
            feedbackValidationObjs: [],
            organisationCategory: [],
            isRegistrationConfirmed: true,
            isRegistrationInWaitingList: false,
            promoCode: '',
            promoCodeValid: false,
            selectedCompany: {
                label: '',
                value: '',
            },
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                modalAction: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'alert',
            },
            determineStaffMode: false,
        };
    }
    validatePromoCode() {
        this.showLoadingValidatePromoCode(true);
        this.props
            .validatePromoCode(this.state.promoCode, this.props.match.params.eventid)
            .then(res => {
                this.showLoadingValidatePromoCode(false);
                if (res.data.errmessage) {
                    this.setState({
                        promoCodeValid: false,
                    });
                    this.showModal('Error', res.data.errmessage, 'alert', false, true);
                } else {
                    this.showModal('Confirmation', `Promo code has been successfully applied`, 'alert', false, true);
                    this.setState({
                        promoCodeValid: true,
                    });
                }
            })
            .catch(error => {
                this.showLoadingValidatePromoCode(false);
                this.showModal('Error', `Promo code is not valid`, 'alert', false, true);
                this.setState({
                    promoCodeValid: false,
                });
                throw error;
            });
    }
    proceedPayment() {
        history.push(`/member/event/registration/${this.state.successfulRegistrationId}/payment`);
    }
    onPromoCodeChange(e) {
        this.setState({
            promoCode: e.target.value,
        });
    }
    onCompanyChange(e) {
        this.setState({
            selectedCompany: e || {},
        });
    }
    setRegistrationResult(isRegistrationConfirmed, isRegistrationInWaitingList, successfulRegistrationId) {
        this.setState({
            isRegistrationConfirmed,
            isRegistrationInWaitingList,
            successfulRegistrationId,
        });
        return Promise.resolve();
    }
    fillUpAttendees() {
        // Generate all attendees
        const { mode, tickets } = this.state;
        const choosenTicket = tickets.find(x => x.quantity > 0);

        // Member
        var attendeesToBeAdded = [];
        if (mode === 'member') {
            const user = this.state.memberProfile;
            attendeesToBeAdded.push({
                id: 0,
                type: `Main Registrant`,
                attendeeName: user.firstName + ' ' + user.lastName,
                designation: user.designation,
                company: user.userCompanyName, //should be the one who logged in and is non editable jira SGBCCRM-45
                track: '',
                email: user.email,
                phone: user.mobileNumber,
            });
            for (let i = 1; i < choosenTicket.quantity; i++) {
                attendeesToBeAdded.push({
                    id: i,
                    type: `Additional Attendee ${i}`,
                    attendeeName: '',
                    designation: '',
                    company: user.userCompanyName, //should be same as main attendee jira SGBCCRM-45
                    track: '',
                    email: '',
                    phone: '',
                });
            }
        }

        // Public
        if (mode === 'public' || mode === 'staff') {
            for (let i = 0; i < choosenTicket.quantity; i++) {
                attendeesToBeAdded.push({
                    id: i,
                    type: i == 0 ? `Main Registrant` : `Additional Attendee ${i}`,
                    attendeeName: '',
                    designation: '',
                    company: '',
                    track: '',
                    email: '',
                    phone: '',
                });
            }
        }

        this.setState({
            choosenTicket,
            attendees: attendeesToBeAdded,
        });
        window.scrollTo(0, 0);
        history.push('./form');
    }
    onCategoryChange(category, company) {
        let selected = category;
        if (company) {
            const array = this.state.organisationCategoryMap.filter(x => {
                return x.company_id === company.value;
            });
            if (array.length > 0) {
                // Company filled up primary business activity
                const found = this.state.organisationCategory.filter(x => {
                    return x.value === array[0].category_id;
                });
                selected = found[0];
            } else {
                // Company did not filled up primary business activity
                selected = null;
            }
        }
        this.setState({
            selectedOrganisationCategory: selected,
        });
    }
    onAttendeeChangeIsGmp(e, fieldName, attendeeId) {
        const { checked } = e.target;
        const newState = Object.assign({}, this.state);
        let attendees = newState.attendees;

        attendees.forEach((attendee, i) => {
            if (attendee.id === attendeeId) {
                attendee[fieldName] = checked;
                if (!checked) attendee.gmpNumber = null;
            }
        });

        this.setState({ attendees });
    }
    onAttendeeTextChange(e, fieldName, attendeeId) {
        const key = e.target.id;
        const value = e.target.value;

        const newState = Object.assign({}, this.state);
        let attendees = newState.attendees;
        let validationObjs = newState.validationObjs.slice();
        let feedbackValidationObjs = newState.feedbackValidationObjs.slice();
        attendees.forEach((attendee, i) => {
            if (attendee.id === attendeeId) {
                attendee[fieldName] = value;

                // !IMPORTANT: Get validation result for component feedback
                const feedbackValidation = Object.assign({}, feedbackValidationObjs[i]);
                feedbackValidation[key] = cyderlib.validate(key, value);
                feedbackValidationObjs[i] = feedbackValidation;

                // !IMPORTANT: Get validation result for froce validation
                const validation = Object.assign({}, validationObjs[i]);
                validation[fieldName] = cyderlib.validate(key, value);
                validationObjs[i] = validation;
            }
        });
        this.setState({ attendees, validationObjs, feedbackValidationObjs });
    }
    /**
     * This function is built with purpose to obtain validation results of an attendee object.
     * Unlike the one in onAttendeeTextChange which get validation result of a single input field,
     * this function obtains validation results for all input fields in a synchronous run.
     *
     * NOTE: keys use in feedbackValidationObj are different from validationObj
     *
     * @param {*} attendee Required; attendee object that contain key and value acquired from input fields
     * @param {*} index Required; index of the attendee object; starting with 0
     */
    cyderlibValidate(attendee, index) {
        const newState = Object.assign({}, this.state);
        const validationObjs = newState.validationObjs.slice();
        const feedbackValidationObjs = newState.feedbackValidationObjs.slice();
        let validationObj = Object.assign({}, validationObjs[index]);
        let feedbackValidationObj = Object.assign({}, feedbackValidationObjs[index]);

        const fieldNameToId = {
            attendeeName: 'fullname',
            company: 'companyname',
            email: 'email',
            phone: 'mobilenumber',
            designation: 'designation',
        };

        // Get validation results for feedback Validation
        Object.keys(fieldNameToId).forEach(fieldName => {
            const inputId = fieldNameToId[fieldName];
            feedbackValidationObj[inputId] = cyderlib.validate(inputId, attendee[fieldName]);
        });

        // Get validation results for validation obj
        Object.keys(fieldNameToId).forEach(fieldName => {
            const inputId = fieldNameToId[fieldName];
            validationObj[fieldName] = cyderlib.validate(inputId, attendee[fieldName]);
        });

        // Assign & Set state
        feedbackValidationObjs[index] = feedbackValidationObj;
        validationObjs[index] = validationObj;
        this.setState({
            validationObjs,
            feedbackValidationObjs,
        });
        return Promise.resolve();
    }
    onTrackChange(e, attendeeId) {
        let new_state = Object.assign({}, this.state);
        let attendees = new_state.attendees;
        attendees.forEach(attendee => {
            if (attendee.id === attendeeId) {
                attendee.track = e;
                attendee.trackId = e.value;
            }
        });
        this.setState({ attendees });
    }
    changeTixNum(e, ticketId) {
        let new_state = Object.assign({}, this.state);
        let tickets = new_state.tickets;
        let totalTicket = 0;
        tickets.forEach(ticket => {
            if (ticket.id == ticketId) {
                ticket.quantity = e.target.value;
                totalTicket = ticket.quantity;
            }
        });
        let attendees = new_state.attendees;
        attendees = [];

        const array = new Array(parseInt(e.target.value));
        this.setState({
            tickets,
            attendees,
            validationObjs: array.fill({}, 0),
            feedbackValidationObjs: array.fill({}, 0),
            tixnum: totalTicket,
        });
    }
    ticketSelectionChange(e, ticketId) {
        let new_state = Object.assign({}, this.state);
        let tickets = new_state.tickets;

        tickets.forEach(ticket => {
            if (ticket.id === ticketId) {
                ticket.selected = e.target.checked;
            } else {
                ticket.selected = false;
                ticket.quantity = 0;
            }
        });
        this.setState({ tixnum: 0 });
        this.setState({ tickets });
    }
    onAgreedTermsChange(e) {
        const { checked } = e.target;
        this.setState({
            agreeTerms: checked,
        });
    }
    showLoadingSubmit(show) {
        this.setState({
            loadingSubmit: show,
        });
    }
    showLoadingValidatePromoCode(show) {
        this.setState({
            loadingValidatePromoCode: show,
        });
    }
    showLoadingTracks(show) {
        this.setState({
            loadingTracks: show,
        });
    }
    showLoadingTickets(show) {
        this.setState({
            loadingTickets: show,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    showLoadingDates(show) {
        this.setState({
            loadingDates: show,
        });
    }
    determineMode() {
        const url = this.props.match.url.toLowerCase();
        const mode = url.includes('public') ? 'public' : url.includes('staff') ? 'staff' : 'member';
        if (this._isMounted) this.setState({ mode });
        return mode;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        const eventid = this.props.match.params.eventid;
        const mode = this.determineMode();
        this.getRelevantStuffs(eventid || null, mode);

        var path = window.location.pathname;
        if (path.toLowerCase().indexOf('/staff/') >= 0) {
            this.setState({
                staffMode: true,
            });
        } else {
            this.setState({
                staffMode: false,
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    toggleTab(tab) {
        if (this.state.activeTab === tab) return;
        this.setState({
            activeTab: tab,
        });
    }
    async validateIsMemberBeforeRedirect() {
        const { url, params } = this.props.match;
        const userContextUrl = url.split('/')[1];
        try {
            const membershipInfoRes = await this.props.getUserMemberInfo();
            switch (this.state.event.eventTypeId) {
                case 2:
                case 3:
                    this.showModalIf(
                        'Sorry',
                        'This event is available for member only. Please register as a member before you proceed.',
                        membershipInfoRes === {},
                    );
                    break;
            }
            history.push(`/${userContextUrl}/event/rsvp/${params.eventid}/tix`);
        } catch (error) {
            throw error;
        }
    }
    async getRelevantStuffs(eventId, mode) {
        let event = {};
        let eventDate = {};
        let memberProfile = {};
        let tickets = [];
        let tracks = [];
        let materials = [];
        let registrations = [];
        let organisationCategory = [];

        const mapDateFunc1 = date => moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
        const mapDateFunc2 = date => moment(date, 'YYYY-MM-DD');
        try {
            if (this._isMounted) {
                this.showLoading(true);
                this.showLoadingDates(true);
                this.showLoadingTickets(true);
                this.showLoadingTracks(true);
            }

            organisationCategory = await this.props.getOrganisationCategory();
            organisationCategory = organisationCategory.data.map(x => ({ label: x.name, value: x.id }));

            // Load event info
            event = await this.props.loadEventInfo(eventId, mode);
            event = sanitizeStringAndObjectNullsToEmptyStrings(event.data || {});

            // Load event dates info
            eventDate = await this.props.loadEventMultipleDatesInfo(eventId, mode);
            // if (mode != 'member') {
            //     eventDate = eventDate.data;
            // }
            eventDate = eventDate.data;

            let ticketRes = '';
            // Load event tickets info

            if (mode === 'staff') {
                ticketRes = await this.props.loadEventTicketsForStaff(eventId, mode);
            } else {
                ticketRes = await this.props.loadEventTickets(eventId, mode);
            }

            const { ticketType, ticketQuantity } = ticketRes.data;
            tickets = ticketType
                .map(ticket => {
                    // No limitations for Staff , can see all tickets. also max ticket default is always 10
                    if (mode === 'staff') {
                        ticket.maxAllowedPerRegistration = 100;
                        ticket.disabled = false;
                        ticket.registrationPeriod = mapDateFunc1(ticket.salesStartDate) + ' - ' + mapDateFunc1(ticket.salesEndDate);
                        ticket.selected = false;
                        ticket.quantity = 0;
                    } else {
                        const ticketsValidToSell = moment().isSameOrBefore(moment(ticket.salesEndDate, 'YYYY-MM-DD'), 'day');
                        ticket.disabled = ticketsValidToSell ? this.overridePurchase(ticketQuantity, ticket) : true;
                        ticket.registrationPeriod = mapDateFunc1(ticket.salesStartDate) + ' - ' + mapDateFunc1(ticket.salesEndDate);
                        ticket.selected = false;
                        ticket.quantity = 0;
                    }
                    return ticket;
                })
                .filter(x => x);
            // Load event tracks
            tracks = await this.props.loadEventTracks(eventId, mode);
            tracks = mode === 'public' || mode === 'staff' ? tracks.data : tracks;
            if (tracks && tracks.length > 0) {
                tracks = tracks.map(track => {
                    return {
                        label: track.name,
                        value: track.id,
                    };
                });
            }

            // Load event materials
            var tempmaterials = await this.props.loadEventMaterials(eventId);
            materials = tempmaterials.data;

            // Load personal profile info
            const memberProfileRes = await this.props.loadPersonalProfile();
            memberProfile = memberProfileRes[0];

            if (mode != 'public') {
                this.onCompanyChange({
                    label: memberProfile.companyName,
                    value: memberProfile.companyId,
                });
            }

            // Load registration for members
            if (this.props.match.url.toLowerCase().includes('member')) {
                const registrationsRes = await this.props.loadRegistrations(eventId);
                registrations = registrationsRes.data;
            }
        } catch (error) {
            throw error;
        } finally {
            if (!this._isMounted) return;
            this.showLoading(false);
            this.showLoadingTickets(false);
            this.showLoadingDates(false);
            this.showLoadingTracks(false);
            this.setState({
                event,
                eventDate,
                tickets,
                tracks,
                registrations,
                memberProfile,
                materials,
                organisationCategory,
            });
        }
    }
    //if true returned then ticketd disabled
    overridePurchase = (ticketQuanity, ticket) => {
        let ticket_selected = ticketQuanity.filter(x => x.id === ticket.id);
        let totalSold = 0;
        // Workaroud fix by Fernando on 22 Aug 2019, to prevent array out of index Error
        const ticketSold =
            ticket_selected && ticket_selected.length > 0 && ticket_selected[0].ticketSold ? ticket_selected[0].ticketSold : 0;
        const attendeeLimit =
            ticket_selected && ticket_selected.length > 0 && ticket_selected[0].attendeeLimit ? ticket_selected[0].attendeeLimit : 0;
        const ticketLimit =
            ticket_selected && ticket_selected.length > 0 && ticket_selected[0].quantityAvailableForSale
                ? ticket_selected[0].quantityAvailableForSale
                : 0;
        ticketQuanity.forEach(x => {
            totalSold += x.ticketSold;
        });
        let override = false;

        var maxallowed = ticket_selected[0].maxAllowedPerRegistration;
        if (ticket_selected[0].quantityAvailableForSale < ticket_selected[0].maxAllowedPerRegistration) {
            maxallowed = ticket_selected[0].quantityAvailableForSale;
        }
        if (ticket_selected[0].quantityAvailableForSale - ticketSold < maxallowed) {
            maxallowed = ticket_selected[0].quantityAvailableForSale - ticketSold;
        }

        if (attendeeLimit - totalSold < maxallowed) {
            maxallowed = attendeeLimit - totalSold;
        }
        //changed Lakshmi - CRMSD-222
        //if attendee limit - total sold  is even less than max allowed then max allowed is tis
        //maxAllowedPerRegistration Being reset here before being passed to EventTicketPanel
        ticket.maxAllowedPerRegistration = maxallowed;
        override = totalSold < attendeeLimit ? true : false;
        //changed Lakshmi - CRMSD-222
        if (ticketSold < ticketLimit && override) {
            return false;
        } else {
            return true;
        }
    };

    getCompanies = async input => {
        try {
            const companiesObj = await this.props.getCompanies(input);
            const companies = companiesObj.data.map(company => ({
                label: company.name,
                value: company.id,
            }));
            const organisationCategoryMap = companiesObj.data.map(company => ({
                company_id: company.id,
                category_id: company.organisation_main_category_id,
            }));
            this.setState({
                organisationCategoryMap,
            });
            return companies;
        } catch (error) {
            throw error;
        }
    };
    getCompleteInputName(key) {
        switch (key) {
            case 'attendeeName':
                return 'name';
            case 'company':
                return 'company name';
            case 'email':
                return 'email address';
            case 'phone':
                return 'mobile number';
            default:
                return key;
        }
    }
    validateIfEmpty(validationObj, requiredObj, attendeeInfo, attendeeIndex) {
        // Determine modal info
        const url = this.props.match.url.toLowerCase();
        const isMember = url.includes('member');
        const designationEval = attendeeIndex === 0 && isMember;
        const designation = designationEval ? 'main' : '';
        const desination2 = designationEval ? '' : isMember ? attendeeIndex : attendeeIndex + 1;
        // Filter out object key which has the value 'false'; not required to be validated
        const requiredObjKeys = Object.keys(requiredObj).filter(x => requiredObj[x] !== false);
        // Check if value exists
        for (let i = 0; i < requiredObjKeys.length; i++) {
            const key = requiredObjKeys[i];
            const hasInvalidVal = !validationObj[key] || !validationObj[key].approved || !attendeeInfo[key];
            const hasError = this.showModalIf(
                'Error',
                `Found invalid value on ${designation} attendant ${desination2} ${this.getCompleteInputName(key)}`,
                hasInvalidVal,
            );
            if (hasError) return true;
        }

        return false;
    }
    validationBeforeSumbmission() {
        const requiredObj = {
            attendeeName: true,
            email: true,
            phone: true,
            company: false,
            designation: false,
        };
        const attendees = this.state.attendees.slice(0);
        //as company is by default same for all attendee which are getting registered in one form

        let dataComplete = true;
        console.log('Category', this.state.selectedOrganisationCategory);
        this.state.attendees.forEach(element => {
            if (
                element.attendeeName == null ||
                element.attendeeName == '' ||
                element.designation == null ||
                element.designation == '' ||
                element.email == null ||
                element.email == '' ||
                element.phone == null ||
                element.phone == ''
            ) {
                dataComplete = false;
            }
            if (this.state.attendees.slice(0)[0].company != undefined) {
                element.company = this.state.attendees.slice(0)[0].company;
            }
            if ((element.company == null || element.company == '') && (element.company == null || element.company == '')) {
                dataComplete = false;
            }
            if (element.isGmp != null && element.isGmp && element.gmpNumber == null) {
                dataComplete = false;
            }
        });

        if (!this.state.selectedOrganisationCategory) {
            dataComplete = false;
        }

        if (!dataComplete) {
            this.showModalIf('Error', 'Please fill in all required information', true);
        } else {
            this.cyderlibValidate(attendees[0], 0) // get validationObj of first attendeesObj
                .then(() => {
                    // SPECIAL CASE (Company is only require to be validated on validateIfEmpty)
                    let newRequiredObj = Object.assign({}, requiredObj);
                    newRequiredObj.company = false; //this is by default provided from the UI Needs to be check
                    //this isue https://cydersg.atlassian.net/browse/SGBCCRM-998
                    const validationObjects = this.state.validationObjs.slice(0); // complete validationObjects
                    var error = false;
                    for (let i = 0; i < validationObjects.length; i++) {
                        // Check if validation has empty
                        const hasEmpty = this.validateIfEmpty(
                            Object.assign({}, this.state.validationObjs[i]),
                            newRequiredObj,
                            Object.assign({}, attendees[i]),
                            i,
                        );
                        if (hasEmpty) {
                            error = true;
                            break;
                        }
                        // Evaluate 'approved' property (within validationObjects) from each input field
                        validateFromValidationObjectPromise(validationObjects[i], requiredObj).then(res => (error = !res ? true : false));
                        this.showModalIf('Error', 'Found invalid information. Please provide valid information', error);
                        if (error) break;
                    }
                    return Promise.resolve(error);
                })
                .then(error => {
                    if (!error) {
                        this.completeRegistration();
                        // this.showModal(
                        //     'Confirmation',
                        //     'Form details has been successfully saved. Press OK to complete your registration',
                        //     'completeApplication',
                        //     false,
                        //     true,
                        // );
                    }
                });
        }
    }
    async completeRegistration() {
        this.showLoading(true);
        var ticketToChoose = {};
        this.state.tickets.forEach(ticket => {
            if (ticket.quantity > 0) {
                ticketToChoose = ticket;
            }
        });

        // Set attendee data
        const mode = this.state.mode;
        const attendees = this.state.attendees.map(attendee => {
            const data = {
                name: attendee.attendeeName,
                email: attendee.email,
                designation: attendee.designation,
                mobileNumber: attendee.phone,
                trackId: attendee.trackId,
                otherCompany: attendee.company,
                company: this.state.selectedCompany.label,
                companyId: this.state.selectedCompany.value,
                gmp: attendee.gmpNumber ? attendee.gmpNumber : null,
            };
            return data;
        });

        // Set fetch body
        const submissionData = {
            eventId: this.state.event.id,
            ticketQuantity: ticketToChoose.quantity,
            ticketTypeId: ticketToChoose.id,
            attendee: attendees,
            promoCode: this.state.promoCodeValid ? this.state.promoCode : '',
            organisationCategory: this.state.selectedOrganisationCategory.value,
        };

        try {
            const { data } = await this.props.submitRegistration(submissionData, mode);
            // Error handling
            if (!data || Object.keys(data).length === 0 || typeof data === 'string' || !data[0][0]) {
                this.showModalIf('Error', 'Failed to submit registration', true);
                history.push(`/${this.props.match.url.toLowerCase().split('/')[1]}/event/rsvp/` + this.props.match.params.eventid);
                return;
            }

            let isRegistrationConfirmed = false;
            let isRegistrationInWaitingList = false;
            let successfulRegistrationId = false;
            if (mode === 'public') {
                isRegistrationConfirmed = true;
            } else {
                isRegistrationConfirmed = data[0][0].is_confirmed === 0 ? false : true;
                isRegistrationInWaitingList = data[0][0].is_waiting_list === 0 ? false : true;
                successfulRegistrationId = data[0][0].registration_id || 0;
            }

            this.setRegistrationResult(isRegistrationConfirmed, isRegistrationInWaitingList, successfulRegistrationId);
            //this.showModal('Success', 'Successfully registered event', 'success', null, true);
            history.push('./confirmation');
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
            window.scrollTo(0, 0);
        }
    }
    clickButton() {
        this.setState({ buttonClicked: true });
    }
    // MODAL
    modalAction() {
        this.modalToggle();
        const type = this.state.modal.type;
        if (type === 'completeApplication') return this.completeRegistration();
        if (type === 'success') history.push('./confirmation');
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    async downloadTermAndConditionsDocument() {
        const { findByCategoryAndName } = this.props;
        try {
            let name = 'Event Terms and Conditions';
            const res = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + res.data.resource;
            window.open(url);
        } catch (error) {
            // this.showModal('Error', 'Term and Conditions is not available', null, false, true);
            throw error;
        }
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, null, null, true);
        return evaluation;
    }
    render() {
        var urlsArr = this.props.match.url.toLowerCase().split('/');
        const userContextUrl = urlsArr[1];
        const isTixUrl = this.props.match.url.toLowerCase().substring(this.props.match.url.length - 3) === 'tix';
        const isFormUrl = this.props.match.url.toLowerCase().substring(this.props.match.url.length - 4) === 'form';
        const isCompletedUrl = this.props.match.url.toLowerCase().substring(this.props.match.url.length - 12) === 'confirmation';
        try {
            var email = this.state.attendees[0].email || '';
        } catch (e) {}
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Row>
                <DialogModal
                    modalAction={this.modalAction}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                    modalOpen={this.state.modal.showModal}
                />
                <Col sm={12} style={{ padding: '0px' }} className={isTixUrl || isFormUrl || isCompletedUrl ? 'd-none' : null}>
                    <EventInfoPanel
                        staff={this.props.staff}
                        eventState={this.state}
                        isTixUrl={isTixUrl}
                        isFormUrl={isFormUrl}
                        isCompletedUrl={isCompletedUrl}
                        userContextUrl={userContextUrl}
                        validateIsMemberBeforeRedirect={this.validateIsMemberBeforeRedirect}
                    />
                </Col>
                <Container className={isTixUrl ? null : 'd-none'}>
                    <Card className="mx-1 m-4">
                        <CardBody>
                            <EventTicketPanel
                                staff={this.props.staff}
                                isTixUrl={isTixUrl}
                                isFormUrl={isFormUrl}
                                eventState={this.state}
                                changeTixNum={this.changeTixNum}
                                fillUpAttendees={this.fillUpAttendees}
                                ticketSelectionChange={this.ticketSelectionChange}
                                onPromoCodeChange={this.onPromoCodeChange}
                                validatePromoCode={this.validatePromoCode}
                                loadingValidatePromoCode={this.state.loadingValidatePromoCode}
                                userContextUrl={userContextUrl}
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Container className={isFormUrl ? null : 'd-none'}>
                    <Card className="mt-2 mx-1">
                        <CardBody>
                            <EventAttendeesPanel
                                staff={this.props.staff}
                                memberProfile={this.state.memberProfile}
                                eventState={this.state}
                                allowSubmission={this.state.allowSubmission}
                                onCategoryChange={this.onCategoryChange}
                                onAttendeeChangeIsGmp={this.onAttendeeChangeIsGmp}
                                onAttendeeTextChange={this.onAttendeeTextChange}
                                feedbackValidationObjs={this.state.feedbackValidationObjs}
                                onTrackChange={this.onTrackChange}
                                validationBeforeSumbmission={this.validationBeforeSumbmission}
                                getCompanies={this.getCompanies}
                                onCompanyChange={this.onCompanyChange}
                                mode={this.state.mode}
                                staffMode={this.state.staffMode}
                                onAgreedTermsChanged={this.onAgreedTermsChange}
                                downloadTermAndConditionsDocument={this.downloadTermAndConditionsDocument}
                                organisationCategory={this.state.organisationCategory}
                                selectedOrganisationCategory={this.state.selectedOrganisationCategory}
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Container className={isCompletedUrl ? null : 'd-none'}>
                    <Card className="mt-2 mx-1">
                        <CardBody>
                            <EventConfirmationPanel staff={this.props.staff} eventState={this.state} proceedPayment={this.proceedPayment} />
                        </CardBody>
                    </Card>
                </Container>
            </Row>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAllReducer,
        user: state.profile.user,
        membershipInfo: state.memberHomeReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventInfo: (id, mode) => {
            return dispatch(loadEventInfo(id, mode));
        },
        loadEventDatesInfo: (id, mode) => {
            return dispatch(loadEventDatesInfo(id, mode));
        },
        loadEventMultipleDatesInfo: (id, mode) => {
            return dispatch(loadEventMultipleDatesInfo(id, mode));
        },
        loadEventTickets: (id, mode) => {
            return dispatch(loadEventTickets(id, mode));
        },
        loadEventTicketsForStaff: (id, mode) => {
            return dispatch(loadAllEventTicketsForStaff(id, mode));
        },
        loadEventTracks: (id, mode) => {
            return dispatch(loadEventTracks(id, mode));
        },
        submitRegistration: (data, mode) => {
            return dispatch(submitRegistration(data, mode));
        },
        loadEventMaterials: id => {
            return dispatch(loadEventMaterials(id));
        },
        loadRegistrations: id => {
            return dispatch(loadRegistrations(id));
        },
        loadPersonalProfile: () => {
            return dispatch(personalProfileGetProfileInfo());
        },
        validatePromoCode: (code, eventId) => {
            return dispatch(validatePromoCode(code, eventId));
        },
        getUserMemberInfo: () => {
            return dispatch(memberHomePanelMembershipGetInfo());
        },
        getCompanies: keyword => {
            return dispatch(getCompaniesAsActiveMembers(keyword));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
        getOrganisationCategory: () => {
            return dispatch(staffMembershipMemberGetOrganisationCategory());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsRsvpPage));
