import React, { Fragment } from 'react';
import moment from 'moment';
import Select, { Async } from 'react-select';

import InputFieldWithValidation from 'reactstrap-input';
import { Row, Col, Button, Label, Form, FormGroup } from 'reactstrap';
import { InputField } from 'pages/staff/certification/applications/product/edit-on-behalf/AddPage';
import MandatoryStar from 'cyder/forms/MandatoryStar';

import history from '../../history';

export const InputComponentWrapper = ({ style, label, children, asterisk }) => {
    return (
        <FormGroup
            style={
                style || {
                    color: '63666a',
                    textAlign: 'left',
                    marginTop: '20px',
                }
            }
        >
            {label != null && label != '' && (
                <Label>
                    {label}
                    &nbsp;
                    {asterisk && <span className="asteriskField">*</span>}
                </Label>
            )}

            {children}
        </FormGroup>
    );
};

const getLabel = title => {
    return (
        <Fragment>
            {title} <MandatoryStar bold />
        </Fragment>
    );
};
const EventAttendeesPanel = props => {
    const {
        eventState,
        onCategoryChange,
        onAttendeeTextChange,
        onAttendeeChangeIsGmp,
        validationBeforeSumbmission,
        feedbackValidationObjs,
        getCompanies,
        onCompanyChange,
        mode,
        staffMode,
        downloadTermAndConditionsDocument,
        onAgreedTermsChanged,
        selectedOrganisationCategory,
        organisationCategory,
    } = props;
    const { choosenTicket, eventDate, event, attendees, tixnum, selectedCompany } = eventState;

    const convertTime = time => {
        try {
            return moment(time, 'HH:mm:SS').format('hh:mm a');
        } catch (e) {}
    };

    return (
        <div>
            {eventState.attendees !== null && (
                <div>
                    <Row>
                        <Col xs={12}>
                            <h2 className="text-center text-bold" style={{ marginTop: '15px' }}>
                                Please enter attendee(s) details.
                            </h2>
                        </Col>
                    </Row>
                    <hr />
                    {choosenTicket && (
                        <Row>
                            <Col xs={12}>
                                <h4 className="text-center text-bold" style={{ marginTop: '15px' }}>
                                    {event ? event.name : ''}
                                </h4>
                                <h5 className="text-center" style={{ marginTop: '15px' }}>
                                    {eventDate &&
                                        eventDate.length > 0 &&
                                        eventDate.map((dateRecord, i) => (
                                            <span key={i}>
                                                {moment(dateRecord.date, 'YYYY-MM-DD').format('dddd, Do MMMM YYYY')}
                                                {dateRecord.startTimeForThisDay && dateRecord.endTimeForThisDay && (
                                                    <Fragment>
                                                        <br />
                                                        {convertTime(dateRecord.startTimeForThisDay)} -{' '}
                                                        {convertTime(dateRecord.endTimeForThisDay)}
                                                        <br />
                                                    </Fragment>
                                                )}
                                            </span>
                                        ))}
                                </h5>
                                {parseInt(eventState.tixnum) !== 0 && (
                                    <div className="text-center">
                                        <Label className="field-header">
                                            {attendees ? attendees.length : 0} x {choosenTicket.name}
                                        </Label>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={12}>
                            {parseInt(tixnum) !== 0 ? (
                                <small className="pull-right" style={{ color: 'red' }}>
                                    <MandatoryStar bold /> Required
                                </small>
                            ) : (
                                <p className="justify-content-center d-flex">
                                    <br /> Your registration has been reset. Please re-register again.
                                </p>
                            )}
                        </Col>
                        <br />
                        <br />
                        <Col xs={12}>
                            <Form>
                                {attendees.map((attendee, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {i !== 0 && <hr />}
                                            <div key={i}>
                                                <Label className="field-header">{attendee.type}</Label>
                                                <Row className="pb-4">
                                                    <Col xs={12} md={6}>
                                                        <InputFieldWithValidation
                                                            type="text"
                                                            valueKey="fullname"
                                                            label={getLabel('Name')}
                                                            defaultValuevalue={attendee.attendeeName}
                                                            validationObj={feedbackValidationObjs[i]}
                                                            handleChange={e => {
                                                                onAttendeeTextChange(e, `attendeeName`, attendee.id);
                                                            }}
                                                            saveButtonPressed={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <InputFieldWithValidation
                                                            type="text"
                                                            style={{ width: '50px' }}
                                                            defaultValue={attendee.designation}
                                                            label={getLabel('Designation')}
                                                            valueKey="designation"
                                                            validationObj={feedbackValidationObjs[i]}
                                                            handleChange={e => {
                                                                onAttendeeTextChange(e, `designation`, attendee.id);
                                                            }}
                                                            saveButtonPressed={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="pb-4">
                                                    <Col xs={12} md={6}>
                                                        <InputFieldWithValidation
                                                            type="text"
                                                            label={getLabel('Email')}
                                                            valueKey="email"
                                                            defaultValue={attendee.email}
                                                            validationObj={feedbackValidationObjs[i]}
                                                            handleChange={e => {
                                                                onAttendeeTextChange(e, `email`, attendee.id);
                                                            }}
                                                            saveButtonPressed={true}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <InputFieldWithValidation
                                                            type="text"
                                                            label={getLabel('Handphone/DID')}
                                                            valueKey="mobilenumber"
                                                            defaultValue={attendee.phone}
                                                            validationObj={feedbackValidationObjs[i]}
                                                            handleChange={e => {
                                                                onAttendeeTextChange(e, `phone`, attendee.id);
                                                            }}
                                                            saveButtonPressed={true}
                                                        />
                                                    </Col>
                                                </Row>

                                                {eventState.event.isGmpEvent === 1 && (
                                                    <Row>
                                                        <Col xs={12} md={6}>
                                                            <div style={{ marginLeft: '5px' }}>
                                                                <InputField
                                                                    labelClassName="field-header"
                                                                    customLabelComponent={
                                                                        <Label className="">Are you a Green Mark AP/AAP?</Label>
                                                                    }
                                                                    inputProps={{
                                                                        type: 'checkbox',
                                                                        id: 'isGmp',
                                                                        checked: attendee.isGmp,
                                                                        onChange: e => onAttendeeChangeIsGmp(e, `isGmp`, attendee.id),
                                                                    }}
                                                                />
                                                            </div>
                                                            {attendee.isGmp && (
                                                                <div style={{ marginTop: '1.5rem' }}>
                                                                    <Row>
                                                                        <Col xs="11" style={{ marginTop: '-15px' }}>
                                                                            <InputFieldWithValidation
                                                                                label={getLabel('GMAP Number (e.g. SG9999)')}
                                                                                type="text"
                                                                                valueKey="gmpNumber"
                                                                                saveButtonPressed
                                                                                defaultValue={attendee.gmpNumber}
                                                                                validationObj={feedbackValidationObjs[i]}
                                                                                handleChange={e =>
                                                                                    onAttendeeTextChange(e, `gmpNumber`, attendee.id)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                )}

                                                <Row className="pb-4">
                                                    {/* this is only for public user to choose during registration */}
                                                    {i === 0 && (
                                                        <Col xs={12} md={6}>
                                                            <React.Fragment>
                                                                <InputComponentWrapper
                                                                    label={mode === 'public' ? getLabel('SGBC Member Organisation') : ''}
                                                                >
                                                                    <Async
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        name="company"
                                                                        placeholder="Search organisation..."
                                                                        label={getLabel('Full Organisation Name')}
                                                                        loadOptions={getCompanies}
                                                                        isDisabled={staffMode || mode === 'public' ? false : true}
                                                                        value={choosenTicket.name !== 'Non-Member' ? selectedCompany : {}}
                                                                        onChange={e => {
                                                                            onCategoryChange(null, e);

                                                                            if (mode === 'public') {
                                                                                onAttendeeTextChange(
                                                                                    {
                                                                                        target: {
                                                                                            id: 'company',
                                                                                            value: '',
                                                                                        },
                                                                                    },
                                                                                    `company`,
                                                                                    attendee.id,
                                                                                );
                                                                                document.getElementById('company').value = '';
                                                                            }
                                                                            onCompanyChange(e);
                                                                            // Required to be updated using on onAttendeeTextChange function for validation purposes
                                                                            onAttendeeTextChange(
                                                                                {
                                                                                    target: {
                                                                                        id: 'company',
                                                                                        value: e.label,
                                                                                    },
                                                                                },
                                                                                `company`,
                                                                                attendee.id,
                                                                            );
                                                                        }}
                                                                    />
                                                                </InputComponentWrapper>
                                                                <InputFieldWithValidation
                                                                    type="text"
                                                                    valueKey="company"
                                                                    label="For non-SGBC Members, enter your organisation name below AND select your organisation's Primary Business Activity to complete registration. Please indicate 'Not Applicable' if you are self-employed or a student."
                                                                    saveButtonPressed
                                                                    defaultValue={!selectedCompany ? attendee.company : ''}
                                                                    validationObj={feedbackValidationObjs[i]}
                                                                    handleChange={e => {
                                                                        onAttendeeTextChange(e, `company`, attendee.id);
                                                                        onCompanyChange({
                                                                            label: '',
                                                                            value: 0,
                                                                        });
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        </Col>
                                                    )}
                                                    {i === 0 && (
                                                        <Col xs={12} md={6}>
                                                            <InputComponentWrapper label={getLabel('Primary Business Activity')}>
                                                                <Select
                                                                    placeholder=""
                                                                    options={organisationCategory}
                                                                    value={selectedOrganisationCategory}
                                                                    onChange={e => onCategoryChange(e, null)}
                                                                />
                                                            </InputComponentWrapper>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </Form>
                            {eventState.attendees.length > 0 ? (
                                <Row className="d-flex ml-1">
                                    <div style={{ marginLeft: '5px' }}>
                                        <InputField
                                            customLabelComponent={
                                                <Label className="text-bold">
                                                    I agree to the
                                                    <a href="#" onClick={downloadTermAndConditionsDocument}>
                                                        &nbsp;<u>Terms and Conditions</u>.
                                                    </a>
                                                </Label>
                                            }
                                            labelClassName="field-header"
                                            inputProps={{
                                                type: 'checkbox',
                                                id: 'termsAndConditions',
                                                checked: eventState.agreeTerms,
                                                onChange: onAgreedTermsChanged,
                                            }}
                                        />
                                    </div>
                                </Row>
                            ) : null}
                            <hr />
                            <Row>
                                <Col className="d-flex flex-row" xs={6}>
                                    <Button className="primary-btn-style-outline" onClick={e => history.goBack()}>
                                        Back
                                    </Button>
                                </Col>
                                <Col className="d-flex flex-row-reverse" xs={6}>
                                    <Button
                                        className="primary-btn-style"
                                        disabled={parseInt(eventState.tixnum) === 0 || !eventState.agreeTerms}
                                        onClick={validationBeforeSumbmission}
                                        onKeyDown={e => {
                                            if (e.keyCode == 13) this.submit();
                                        }}
                                    >
                                        Complete Registration
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default EventAttendeesPanel;
