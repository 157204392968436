import validation from './validation';
import moment from 'moment';

function FormValidationException(input) {
    this.message = 'The validation "' + input + '" is invalid';
    this.name = 'UserException';
}
export const certificationApplicationStepDict = ['Commence Application', 'Preview Application', 'Submit Application'];
export const membershipApplicationStepDict = ['Package', 'Feedback', 'Preview', 'Completed'];
const iconDict = {
    all: {
        color: '#ed8a8a',
        text: 'All',
    },
    draft: {
        icon: 'inbox',
        color: '#4e8084',
        text: 'Draft',
    },
    submitted: {
        icon: 'flight_takeoff',
        color: '#4e8084',
        text: 'Submitted',
    },
    processing: {
        icon: 'hourglass_full',
        color: '#4e8084',
        text: 'Processing',
    },
    'approved & pending payment': {
        icon: 'attach_money',
        color: '#4e8084',
        text: 'Approved & Pending Payment',
    },
    complete: {
        icon: 'check',
        color: '#4e8084',
        text: 'Complete',
    },
    withdrawn: {
        icon: 'fast_rewind',
        color: '#4e8084',
        text: 'Withdrawn',
    },
    notapproved: {
        icon: 'close',
        color: '#4e8084',
        text: 'Not Approved',
    },
    'not approved': {
        icon: 'close',
        color: 'success',
        text: 'Not Approved',
    },
    discontinued: {
        icon: 'highlight_off',
        color: '#4e8084',
        text: 'Discontinued',
    },
    assessment: {
        icon: 'hourglass_full',
        color: 'warning',
        text: 'Assessment',
    },
    approvedInvoiceNotGenerated: {
        icon: 'receipt',
        color: 'dark',
        text: 'Approved, Invoice Not Yet Generated',
    },
    approvedPendingPayment: {
        icon: 'attach_money',
        color: 'info',
        text: 'Approved & Pending Payment',
    },
    preparation: {
        icon: 'attach_money',
        color: 'info',
        text: 'Payment Received (Cert Preparation)',
    },
    'payment received (cert preparation)': {
        icon: 'how_to_reg',
        color: 'info',
        text: 'Payment Received (Cert Preparation)',
    },
    completed: {
        icon: 'check',
        color: 'success',
        text: 'Completed',
    },
    rejected: {
        icon: 'close',
        color: 'default',
        text: 'Not Approved',
    },
    certificate: {
        icon: 'work',
        color: 'default',
        text: 'Processing Certificate',
    },
    noResponse: {
        icon: 'chat',
        color: 'danger',
        text: 'No response',
    },
    unsure: {
        icon: 'hourglass_full',
        color: 'info',
        text: 'Unsure if want to renew',
    },
    pending_payment: {
        icon: 'attach_money',
        color: 'warning',
        text: 'Pending Payment',
    },
    noRenew: {
        icon: 'close',
        color: 'default',
        text: 'Do not wish to renew',
    },
};
const prodCertIconDict = {
    approvedPendingPayment: {
        icon: 'inbox',
        color: 'danger',
        text: 'Approved Pending Payment',
    },
    draft: {
        icon: 'inbox',
        color: 'danger',
        text: 'Draft',
    },
    submitted: {
        icon: 'flight_takeoff',
        color: 'danger',
        text: 'Submitted',
    },
    accepted: {
        icon: 'assignment_turned_in',
        color: 'danger',
        text: 'Accepted',
    },
    assessment: {
        icon: 'hourglass_full',
        color: 'danger',
        text: 'Assessment',
    },
    processing: {
        icon: 'hourglass_full',
        color: 'danger',
        text: 'Processing',
    },
    approval: {
        icon: 'playlist_add_check',
        color: 'warning',
        text: 'Approval',
    },
    invoice: {
        icon: 'receipt',
        color: 'warning',
        text: 'Invoice',
    },
    payment: {
        icon: 'attach_money',
        color: 'warning',
        text: 'Paid',
    },
    finalisation: {
        icon: 'hearing',
        color: 'success',
        text: 'Finalisation',
    },
    feedback: {
        icon: 'hearing',
        color: 'success',
        text: 'Feedback',
    },
    preparation: {
        icon: 'verified_user',
        color: 'success',
        text: 'Preparation',
    },
    issued: {
        icon: 'check',
        color: 'success',
        text: 'Issued',
    },
    notApproved: {
        icon: 'close',
        color: 'default',
        text: 'Not Approved',
    },
    withdrawn: {
        icon: 'fast_rewind',
        color: 'default',
        text: 'Withdrawn',
    },
    discontinued: {
        icon: 'fast_rewind',
        color: 'default',
        text: 'Discontinued',
    },
    approved_invoice_not_generated: {
        icon: 'fast_rewind',
        color: 'default',
        text: 'Approved, invoice not generated',
    },
    complete: {
        icon: 'check',
        color: 'success',
        text: 'Complete',
    },
    completed: {
        icon: 'check',
        color: 'success',
        text: 'Completed',
    },
};

export default {
    getProdCertApplicationStatusAttr(status, attr) {
        return prodCertIconDict[status] ? prodCertIconDict[status][attr] : '';
    },
    getApplicationStatusAttr(status, attr) {
        return iconDict[status] ? iconDict[status][attr] : '';
    },
    // ABSTRACT VALIDATION TO ANOTHER FILE FOR REUSABILITY
    validate: validation.validate,
    validateOld: function(string, type) {
        try {
            if (type.toLowerCase() === 'alphanumeric') {
                var regex = new RegExp(/[^\w\d]+/);
                return !regex.test(string);
            }
            throw new FormValidationException(type);
        } catch (error) {
            // DEFAULT RETURN TRUE IF TYPE IS UNKNOWN
            return true;
        }
    },
    tableSortDate: (a, b, desc) => {
        const momentA = moment(a, 'DD/MM/YYYY').unix();
        const momentB = moment(b, 'DD/MM/YYYY').unix();
        if (momentA > momentB) {
            return -1;
        }
        if (momentA < momentB) {
            return 1;
        }
        return 0;
    },
    normalizeReceiptFields: receiptFields => {
        let normalizedReceiptFields = Object.assign({}, receiptFields);
        const amount = (receiptFields.amount / 100).toFixed(2);
        normalizedReceiptFields.amount = amount;
        return normalizedReceiptFields;
    },
    // FAKE DATA
    fakeCompanyData: [
        {
            id: '1279',
            organizationId: 'fits',
            organizationName: 'FITS Tech',
            country: 'Singapore',
            uenNo: '12345',
            organizationCategory: 'Product Companies',
            organizationPrimaryBusinessActivity: 'None',
            annualTurnover: null,
            numberOfEmployees: 12,
            min30percentLocalShareholding: false,
            isSME: null,
            keyMembershipAccount: false,
            membershipType: 'Corporate Member',
            joinDate: moment('01072017', 'DDMMYYYY'),
            expiryDate: moment('31122018', 'DDMMYYYY'),
        },
        {
            id: '1278',
            organizationId: 'veldon',
            organizationName: 'Veldon Corporation (Singapore) Pte Ltd',
            country: 'Singapore',
            uenNo: '201736927N',
            organizationCategory: 'Professional Building and Environmental Consultancies',
            organizationPrimaryBusinessActivity: 'Others',
            annualTurnover: 1500000,
            numberOfEmployees: 7,
            min30percentLocalShareholding: true,
            isSME: true,
            keyMembershipAccount: false,
            membershipType: 'Founding Member',
            joinDate: moment('01012009', 'DDMMYYYY'),
            expiryDate: moment('31122019', 'DDMMYYYY'),
        },
    ],
    fakeOrganizationMainCategories: [
        {
            id: '11',
            name: 'Others',
            orderNumber: '11',
            active: true,
        },
        {
            id: '10',
            name: 'Testing Laborataries',
            orderNumber: '10',
            active: true,
        },
        {
            id: '9',
            name: 'Energy Services Specialists',
            orderNumber: '9',
            active: true,
        },
        {
            id: '8',
            name: 'Government Agencies',
            orderNumber: '8',
            active: true,
        },
        {
            id: '7',
            name: 'Research and Tertiary Institutions',
            orderNumber: '7',
            active: true,
        },
        {
            id: '6',
            name: 'Professional Bodies, Trade Associations and Chambers',
            orderNumber: '6',
            active: true,
        },
        {
            id: '5',
            name: 'Property/Facility Management and Real Estate Services',
            orderNumber: '5',
            active: true,
        },
        {
            id: '4',
            name: 'Real Estate Owners and Developers',
            orderNumber: '4',
            active: true,
        },
        {
            id: '3',
            name: 'Builders and Contractors',
            orderNumber: '3',
            active: true,
        },
        {
            id: '2',
            name: 'Professional Building and Environmental Consultancies',
            orderNumber: '2',
            active: true,
        },
        {
            id: '1',
            name: 'Product Companies',
            orderNumber: '1',
            active: true,
        },
    ],
    fakeOrganizationSubCategories: [
        {
            id: '37',
            name: 'Energy Modelling',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '36',
            name: 'IT infrastructure network',
            mainCategory: 'Builders and Contractors',
            active: true,
        },
        {
            id: '35',
            name: 'Energy Retailer',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '34',
            name: 'Others',
            mainCategory: 'Others',
            active: true,
        },
        {
            id: '33',
            name: 'Testing Laboratory',
            mainCategory: 'Testing Laborataries',
            active: true,
        },
        {
            id: '32',
            name: 'Renewable Energy Integrator',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '31',
            name: 'Energy Efficient Solution Provider',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '30',
            name: 'NEA Accredited Energy Service Company (ESCO)',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '29',
            name: 'SGBC Certified Energy Performance Contracting (EPC)',
            mainCategory: 'Energy Services Specialists',
            active: true,
        },
        {
            id: '28',
            name: 'Government Agency',
            mainCategory: 'Government Agencies',
            active: true,
        },
        {
            id: '27',
            name: '	Research & Tertiary Institution',
            mainCategory: 'Research and Tertiary Institutions',
            active: true,
        },
    ],
    fakeUserManualCategories: [
        {
            id: '5',
            name: 'Communication',
        },
        {
            id: '4',
            name: 'Events',
        },
        {
            id: '3',
            name: 'Certification',
        },
        {
            id: '2',
            name: 'Membership',
        },
        {
            id: '1',
            name: 'Overview',
        },
    ],
    fakeRenewals: [
        {
            id: '1',
            name: 'Avengers',
            membershipId: '618',
            expiryDate: moment('15062018', 'DDMMYYYY'),
            status: 'noResponse',
            renewalId: '1307',
            officer: 'None',
            rnExists: true,
        },
        {
            id: '1',
            name: 'GMK Development & Marketing Pte Ltd',
            membershipId: '219',
            expiryDate: moment('01022018', 'DDMMYYYY'),
            status: null,
            renewalId: '1090',
            officer: 'None',
            rnExists: false,
        },
    ],
};
