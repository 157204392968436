import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigTextWithNormalText, AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getAverageAttendence } from 'actions/analytics/eventsAnalyticsAction';

class PercentageAverage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.events.refresh) {
            this.props.load().then(res => {
                this.setState({
                    data: res.data[0],
                    refreshState: this.props.events.refresh,
                });
            });
        }
    }

    render() {
        const xs = {
            bigxs: 3,
            normalxs: 9,
        };
        return (
            <AnalyticsCard title={`Attendance `} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigTextWithNormalText
                            normalText={`Total Attendees (Cumulative) `}
                            normalTextColor={`unset`}
                            bigText={this.state.data.totalAttended}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />
                        <AnalyticsBigTextWithNormalText
                            normalText={`Average Attendance %`}
                            normalTextColor={`unset`}
                            bigText={this.state.data.attendence}
                            bigTextColor={`#ed5565`}
                            {...xs}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        events: state.eventsAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getAverageAttendence());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(PercentageAverage));
