import React from 'react';
import StoredLayout from 'layouts/StoredLayout';
import { connect } from 'react-redux';

import { Row, Col } from 'reactstrap';
import IconWidgetSurvey from 'icon-widgets/IconWidget6';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

import { setSurveyCategory, getDashboard } from 'actions/staff/survey/surveyAction';

import 'css/forms/default-forms.css';
import 'css/cyder/widget/page-widget.css';

class SurveyDashboard extends React.Component {
    componentDidMount() {
        this.props.getDashboard();
        console.log('Dashboard', this.props.survey.control);
    }
    render() {
        return (
            <div>
                <NoShadowPanel title={`Survey Dashboard`} borderSize={2} flat>
                    <Row>
                        <Col>
                            <Row style={{ paddingBottom: '10px' }}>
                                <Col>
                                    <IconWidgetSurvey
                                        {...this.props.survey.control.dashboard[4]}
                                        link={() => {
                                            this.props.openAllSurvey(this.props.survey.control.dashboard[4]);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <IconWidgetSurvey
                                        {...this.props.survey.control.dashboard[0]}
                                        link={() => {
                                            this.props.openAllSurvey(this.props.survey.control.dashboard[0]);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <IconWidgetSurvey
                                        {...this.props.survey.control.dashboard[1]}
                                        link={() => {
                                            this.props.openAllSurvey(this.props.survey.control.dashboard[1]);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <IconWidgetSurvey
                                        {...this.props.survey.control.dashboard[2]}
                                        link={() => {
                                            this.props.openAllSurvey(this.props.survey.control.dashboard[2]);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <IconWidgetSurvey
                                        {...this.props.survey.control.dashboard[3]}
                                        link={() => {
                                            this.props.openAllSurvey(this.props.survey.control.dashboard[3]);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </NoShadowPanel>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        survey: state.staffSurvey,
        ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        openAllSurvey: surveyCategory => {
            dispatch(setSurveyCategory(surveyCategory));
            dispatch({
                type: 'STAFF_SURVEY_SET_STATUS',
                status: {
                    value: 'Current',
                    label: 'Current',
                },
            });
            ownProps.reloadSurvey();
        },
        getDashboard: () => {
            dispatch(getDashboard());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SurveyDashboard));
