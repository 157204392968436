import React from 'react';
import history from '../../history';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import YesNoIcon from 'cyder/media/YesNoIcon';
import { SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_ROOT, SGBC_S3_HOST } from 'config';
import moment from 'moment';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import ReactTable from 'react-table';

import { loadRegistrations, updatePaymentStatus, printReceipt } from 'actions/shared/eventRsvpAction.js';

const EventRegistrationPanel = ({ eventState, columns }) => (
    <div>
        <Row>
            <Col xs={12}>
                <h1 className="text-center">
                    <strong>Event {eventState.mode == 'registration' ? 'Registration' : 'Attendance'} </strong>
                </h1>
            </Col>
        </Row>
        <br />
        <Row>
            <Col className="d-flex flex-row" xs={6}>
                <Button
                    size="sm"
                    className="btn btn-default"
                    onClick={e => {
                        window.location.href = '/member/events/event';
                    }}
                >
                    <i className="material-icons align-text-bottom">chevron_left</i> <strong>Back</strong>
                </Button>
            </Col>
        </Row>
        <br />
        <p style={{ fontWeight: 'bolder' }}>
            {eventState.mode == 'attendance' && (
                <React.Fragment>
                    Total attended: {eventState.totalAttended} /{' '}
                    {eventState.originalRegistrations != null ? eventState.originalRegistrations.length : 0}
                </React.Fragment>
            )}
            {eventState.mode == 'registration' && (
                <React.Fragment>
                    Total registered: {eventState.originalRegistrations != null ? eventState.originalRegistrations.length : 0}
                </React.Fragment>
            )}
        </p>
        <hr />
        <ReactTable
            className="-highlight mb-2"
            data={eventState.mode == 'registration' ? eventState.registrations : eventState.originalRegistrations}
            loading={eventState.loading}
            columns={columns}
            minRows={0}
            filterable={false}
            manual
            showPagination={false}
        />
    </div>
);

class EventRegistrationPage extends React.Component {
    constructor(props) {
        super(props);
        this.showLoading = this.showLoading.bind(this);
        this.updatePaymentStatus = this.updatePaymentStatus.bind(this);
        this.printReceipt = this.printReceipt.bind(this);

        this.state = {
            loading: false,
            registrations: [],
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
        };
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    async printReceipt(paymentId) {
        try {
            this.setState({
                loadPrinting: true,
            });
            const res = await this.props.printReceipt(paymentId);
            window.open(SGBC_S3_HOST + '/temp/' + res.data.file);
        } catch (error) {
            //this.showModal('Error', 'Receipt cannot be printed', null, false, true);
            throw error;
        } finally {
            this.setState({
                loadPrinting: false,
            });
        }
    }
    updatePaymentStatus(registrationId) {
        this.showLoading(true);
        this.props
            .updatePaymentStatus(registrationId)
            .then(res => {
                this.showLoading(false);
                this.componentDidMount();
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        var eventId = null;
        try {
            eventId = this.props.match.params.eventid;
        } catch (e) {}

        // Load registration for members
        var urlsArr = this.props.match.url.toLowerCase().split('/');
        const userContextUrl = urlsArr[1];
        if (userContextUrl == 'member') {
            this.showLoading(true);
            this.props
                .loadRegistrations(eventId)
                .then(registrations => {
                    var mergedRegistration = [];
                    var currentRegistration = {};
                    var totalAttended = 0;
                    registrations.data.forEach(registration => {
                        if (registration.attended == 1) {
                            totalAttended++;
                        }
                        if (registration.id != currentRegistration.id) {
                            currentRegistration = Object.assign({}, registration);
                            currentRegistration.count = 1;
                            mergedRegistration.push(currentRegistration);
                        } else {
                            currentRegistration.name = currentRegistration.name + ', ' + registration.name;
                            currentRegistration.count++;
                        }
                    });
                    this.setState({
                        registrations: mergedRegistration,
                        originalRegistrations: registrations.data,
                        totalAttended,
                        mode: this.props.mode != null ? this.props.mode : 'registration',
                    });
                    this.showLoading(false);
                })
                .catch(error => {
                    this.showLoading(false);
                    throw error;
                });
        }
    }
    render() {
        let registrationColumns = [
            {
                Header: 'Name of Attendee(s)',
                accessor: 'name',
                maxWidth: 300,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Ticket',
                maxWidth: 300,
                sortable: false,
                accessor: 'ticketType',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <span>
                            {props.value} <br />(
                            <b>
                                {props.original.totalWithGst === null
                                    ? '-'
                                    : `$${props.original.totalPrice} for ${props.original.count} pax`}
                            </b>
                            )
                        </span>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: 'isRegistrationConfirmed',
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.original.totalWithGst <= 0) {
                        return (
                            <div>
                                <p>Registration is confirmed.</p>
                            </div>
                        );
                    }
                    if (props.original.paymentStatus == 1) {
                        return (
                            <div>
                                <p>Registration is confirmed and fully paid.</p>
                                <p>
                                    Click{' '}
                                    <a
                                        style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                                        href="#"
                                        onClick={e => {
                                            this.printReceipt(props.original.paymentId);
                                        }}
                                    >
                                        here
                                    </a>
                                    {` `}to download payment receipt.
                                </p>
                                <p>
                                    Click{' '}
                                    <a
                                        style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                                        href="#"
                                        onClick={e => {
                                            window.location.href =
                                                `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=` +
                                                props.original.invoiceNumber;
                                        }}
                                    >
                                        here
                                    </a>
                                    {` `}to download invoice ({props.original.invoiceNumber}).
                                </p>
                            </div>
                        );
                    } else {
                        if (props.original.isRegistrationConfirmed == 1) {
                            return (
                                <div>
                                    <p>Registration is confirmed.</p>
                                    <p>
                                        Please download your invoice ({props.original.invoiceNumber}){' '}
                                        <a
                                            style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                                            href="#"
                                            onClick={e => {
                                                window.location.href =
                                                    `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=` +
                                                    props.original.invoiceNumber;
                                            }}
                                        >
                                            here
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        Click{' '}
                                        <Link
                                            style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                                            to={`/member/event/registration/${props.original.id}/paynow`}
                                        >
                                            here
                                        </Link>{' '}
                                        to proceed with payment.
                                    </p>
                                </div>
                            );
                        } else {
                            if (props.original.isWaitingList == 1) {
                                return `Registration is on waiting list`;
                            } else {
                                return `Registration is pending SGBC confirmation`;
                            }
                        }
                    }
                },
            },
        ];

        let attendanceColumns = [
            {
                Header: 'Designation',
                accessor: 'designation',
                maxWidth: 100,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Name',
                accessor: 'name',
                maxWidth: 300,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Email',
                accessor: 'email',
                maxWidth: 300,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Contact Number',
                accessor: 'handphone',
                maxWidth: 200,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Attended',
                accessor: 'attended',
                maxWidth: 75,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <YesNoIcon negativeIcon="cancel" val={props.value} />;
                },
            },
            {
                Header: 'Check-in Time',
                accessor: 'checkinTimestamp',
                maxWidth: 150,
                sortable: false,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return <span>{props.value != null ? moment(props.value).format('DD/MM/YYYY HH:mm') : '-'}</span>;
                },
            },
        ];

        let columns = this.state.mode == 'registration' ? registrationColumns : attendanceColumns;

        return (
            <Container>
                <Card className="mx-1 m-4">
                    <CardBody>
                        <EventRegistrationPanel columns={columns} eventState={this.state} />
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadRegistrations: id => {
            return dispatch(loadRegistrations(id));
        },
        updatePaymentStatus: id => {
            return dispatch(updatePaymentStatus(id));
        },
        printReceipt: id => {
            return dispatch(printReceipt(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventRegistrationPage));
