import {
    GROUP_ADD_GET_DETAIL_REQUEST,
    GROUP_ADD_GET_DETAIL_SUCCESS,
    GROUP_ADD_GET_DETAIL_FAILURE,
    GROUP_ADD_RESET_FIELDS,
    GROUP_ADD_CHANGE_FIELD_VALUE,
    GROUP_ADD_SAVE_GROUP_REQUEST,
    GROUP_ADD_SAVE_GROUP_SUCCESS,
    GROUP_ADD_SAVE_GROUP_FAILURE,
    GROUP_ADD_GET_PERMISSIONS_REQUEST,
    GROUP_ADD_GET_PERMISSIONS_SUCCESS,
    GROUP_ADD_GET_PERMISSIONS_FAILURE,
    GROUP_ADD_DELETE_GROUP_REQUEST,
    GROUP_ADD_DELETE_GROUP_SUCCESS,
    GROUP_ADD_DELETE_GROUP_FAILURE,
    GROUP_ADD_FORCE_VALIDATE,
    GROUP_ADD_CHANGE_PERMISSIONS_VALUE,
    GROUP_ADD_REMOVE_PERMISSION_AT,
} from '../../../actions/constants/actionTypesAuth';

import cyderlib from '../../../js/cyderlib';

// defaults
const defaultState = {
    fields: {
        created_on: null,
        id: 5,
        name: '',
        updated_by_id: null,
        updated_on: null,
        groupname: '',
        permissions: [],
    },
    permissionChoices: [],
    validation: {},
};

export function groupAddReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case GROUP_ADD_GET_PERMISSIONS_REQUEST:
            newState.loading = true;
            return newState;
        case GROUP_ADD_GET_PERMISSIONS_SUCCESS:
            newState.permissionChoices = action.permissionChoices;
            newState.loading = false;
            return newState;
        case GROUP_ADD_GET_PERMISSIONS_FAILURE:
            newState.loading = false;
            return newState;
        case GROUP_ADD_RESET_FIELDS:
            newState.fields = Object.assign({}, defaultState.fields);
            return newState;
        case GROUP_ADD_GET_DETAIL_REQUEST:
            newState.loading = true;
            return newState;
        case GROUP_ADD_GET_DETAIL_SUCCESS:
            newState.fields = Object.assign({}, action.fields);
            newState.loading = false;
            return newState;
        case GROUP_ADD_GET_DETAIL_FAILURE:
            return newState;
        case GROUP_ADD_CHANGE_FIELD_VALUE:
            newState.fields = Object.assign({}, state.fields);
            newState.fields[action.key] = action.value;
            return newState;
        case GROUP_ADD_CHANGE_PERMISSIONS_VALUE:
            newState.fields = Object.assign({}, state.fields);
            newState.fields.permissions = action.value;
            return newState;
        case GROUP_ADD_REMOVE_PERMISSION_AT:
            newState.fields = Object.assign({}, state.fields);
            newState.fields.permissions = state.fields.permissions
                .slice(0, action.index)
                .concat(
                    state.fields.permissions.slice(
                        action.index + 1,
                        state.fields.permissions.length,
                    ),
                );
            return newState;
        case GROUP_ADD_SAVE_GROUP_REQUEST:
            newState.loading = true;
            return newState;
        case GROUP_ADD_SAVE_GROUP_SUCCESS:
            newState.loading = false;
            newState.saved = true;
            return newState;
        case GROUP_ADD_SAVE_GROUP_FAILURE:
            newState.loading = false;
            return newState;
        case GROUP_ADD_DELETE_GROUP_REQUEST:
            newState.loading = true;
            return newState;
        case GROUP_ADD_DELETE_GROUP_SUCCESS:
            newState.loading = false;
            newState.saved = true;
            return newState;
        case GROUP_ADD_DELETE_GROUP_FAILURE:
            newState.loading = false;
            newState.saved = true;
            return newState;
        case GROUP_ADD_FORCE_VALIDATE:
            let pw = null;
            newState.fields = Object.assign({}, state.fields);
            newState.validation = Object.assign({}, state.validation);
            newState.validation.groupname = cyderlib.validate(
                'groupname',
                state.fields.groupname,
                pw,
            );
            newState.saveButtonPressed = true;
            return newState;
        default:
            return state;
    }
}
