/**
 * Staff membership statistics reducer
 *
 * Author: Fernando
 */

import MEMBERSHIP_STATISTICS_DASHBOARD from '../../../json/sgbc-temp-staff-membership-stat-dashboard.json';
import MEMBERSHIP_STATISTICS_ISSUED_ANALYSIS from '../../../json/sgbc-temp-staff-membership-stat-issued-analysis.json';
import MEMBERSHIP_STATISTICS_EXPIRY_MANAGEMENT_FORECASTING from '../../../json/sgbc-temp-staff-membership-stat-expiry-management-forecasting.json';

var staffMembershipStatisticsDefaultState = {
    dashboard: MEMBERSHIP_STATISTICS_DASHBOARD,
    issuedAnalysis: MEMBERSHIP_STATISTICS_ISSUED_ANALYSIS,
    expiryManagementForecasting: MEMBERSHIP_STATISTICS_EXPIRY_MANAGEMENT_FORECASTING,
    selectedStat: 'Membership Dashboard',
};

export function staffMembershipStatisticsReducer(
    state = staffMembershipStatisticsDefaultState,
    action,
) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'SGBC_STAFF_MEMBERSHIP_STATISTICS_SET_STAT':
            newState.selectedStat = action.selectedStat;
            return newState;
        default:
            return state;
    }
}
