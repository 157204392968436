import moment from 'moment';
import {
    STAFF_HOME_PANEL_GET_INFO_REQUEST,
    STAFF_HOME_PANEL_GET_INFO_SUCCESS,
    STAFF_HOME_PANEL_GET_INFO_FAILURE,
    FETCH_STAFF_DATA_DASHBOARD,
    FETCH_STAFF_DATA_REVIEW_LIST_COUNT,
} from '../../actions/constants/actionTypesStaffHome';

const defaultState = {
    staffHomedata: {},
};

export default function memberHomeReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case FETCH_STAFF_DATA_REVIEW_LIST_COUNT:
            return {
                ...state,
                reviewList:
                    action.response && action.response.data && action.response.data != null ? action.response.data.reviewCount : null,
            };
        case FETCH_STAFF_DATA_DASHBOARD:
            return { ...state, dashboard: action.payload };
        case STAFF_HOME_PANEL_GET_INFO_REQUEST:
            return newState;
        case STAFF_HOME_PANEL_GET_INFO_SUCCESS:
            newState.staffHomedata = action.response.data[0];
            return newState;
        case STAFF_HOME_PANEL_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        default:
            return state;
    }
}
