import React from 'react';
import moment from 'moment';
import { Grid, Form, Image, Segment, Header, Input, Button, Icon } from 'semantic-ui-react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Service from './Service';
import Sidebar from './Sidebar';
import * as Action from '../../../actions/public';
import { mobile, tablet } from '../dummy';
import ServiceList from './ServiceList';
import ServiceDetails from './ServiceDetails';
import AffiliatesPanel from '../components/AffiliatesPanel';

const { fetchServiceCategories, filterServices, fetchRatingService, searchServices, fetchServiceCompanyInfo } = Action;

class ServiceContainer extends React.Component {
    state = { serviceCategoryId: '', ratingId: 'all', keyword: '' };

    componentDidMount() {
        this.props.fetchServiceCategories();
    }

    changeServiceCategoryId = serviceCategoryId => {
        this.setState({ serviceCategoryId });
    };

    handleSearchSubmit = () => {
        const { keyword } = this.state;
        if (keyword) {
            this.props
                .searchServices({ serviceCategoryId: '', keyword, ratingId: 'all' })
                .then(() => this.props.fetchRatingService(this.state.serviceCategoryId))
                .then(() => this.props.history.push('/public/service/search'));
            this.setState({ keyword: '' });
        }
    };

    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    };

    handleFilter = () => {
        const data = {
            serviceCategoryId: this.state.category,
            ratingId: 'all',
        };

        if (this.state.category) {
            this.props.filterServices(data);
            this.props.fetchRating();
            this.props.history.push(`/public/service/list/${this.state.category}`);
        }
    };

    //redirect = () => {
    //    this.props.history.push({ pathname: `/public/service/list/`, state: { testing: 'hello' } });
    //};

    handleFilterRating = (e, { value }) => {
        const { subcategory, productType, keyword } = this.state;
        this.setState({ ratingId: value });
        const newData = {
            keyword: keyword && keyword !== '' ? keyword : '',
            subCategoryId: subcategory || '',
            ratingId: value,
            productTypeId: productType || 'all',
            sortRating: 'asc',
            bcaId: [],
            greenAttributeId: [],
            greenCredentialId: [],
        };
        this.props.fetchRating();
        this.props.fetchFilteringLabels(subcategory);
        this.props.fetchProducts(newData);
    };

    handleFetchServices = (serviceCategoryId, ratingId) => {
        this.setState({ serviceCategoryId, ratingId });

        const data = {
            ratingId,
            serviceCategoryId,
        };

        if (serviceCategoryId) {
            this.props.filterServices(data);
            this.props.fetchRatingService(serviceCategoryId);
            this.props.history.push(`/public/service/list/${serviceCategoryId}`);
        }
    };

    handleFetchServiceDetails = (companyId, serviceId, serviceCategoryId) => {
        console.log(companyId, serviceId, serviceCategoryId);
        this.setState({ serviceCategoryId });
        this.props.fetchServiceCompanyInfo({ companyId, serviceId, serviceCategoryId });
        this.props.history.push(`/public/service/details/${companyId}/${serviceCategoryId}/${serviceId}`);
    };

    render() {
        const { keyword, serviceCategoryId } = this.state;
        console.log('serviceCategoryId', serviceCategoryId);
        const {
            history,
            isSearch,
            match: { url },
            serviceCategories,
            categoryOptions,
            services,
            ratings,
            loading,
            companyInfo,
            isHome,
            serviceDetails,
            ratingTicks,
            zcpRatingOption,
            isServiceListPage,
        } = this.props;

        let serviceCategoryName = null;
        if (serviceCategoryId && categoryOptions) {
            const category = this.props.categoryOptions.filter(e => {
                return e.value == this.state.serviceCategoryId;
            });
            serviceCategoryName = category[0].text;
        }

        return (
            <React.Fragment>
                <Grid stackable centered className="service-directory">
                    <Grid.Row centered>
                        <Grid.Column width={16} style={{ paddingLeft: 0 }}>
                            <Grid stackable centered verticalAlign="middle">
                                <Grid.Column textAlign={mobile ? 'center' : undefined} width={16} style={{ paddingTop: 0 }}>
                                    <Segment
                                        placeholder
                                        className="hero"
                                        style={{ border: '0px', paddingBottom: '5px', borderColor: '#f5f5f5' }}
                                    >
                                        <Grid centered>
                                            <Grid.Column width={12}>
                                                <Grid stackable columns={2} verticalAlign="middle">
                                                    <Grid.Column>
                                                        <Header as="h2">
                                                            SGBC <br /> <span>Services Directory</span>
                                                        </Header>
                                                        <Form onSubmit={this.handleSearchSubmit}>
                                                            <Input
                                                                icon={
                                                                    <Icon
                                                                        name="arrow right"
                                                                        circular={true}
                                                                        link
                                                                        onClick={this.handleSearchSubmit}
                                                                    />
                                                                }
                                                                // icon={{ name: 'arrow right', circular: true, link: true }}
                                                                size="large"
                                                                fluid
                                                                name="keyword"
                                                                value={keyword}
                                                                onChange={this.handleChange}
                                                                placeholder="Search"
                                                            />
                                                        </Form>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <i style={{ color: '#949699', marginLeft: '8px', fontSize: '12px' }}>
                                                                    Search by Company Name, Services Category, Certificate Number
                                                                </i>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid.Column>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>

                    {/* Back and Print buttons */}
                    <Grid.Row centered={false} className="noprint">
                        <Grid.Column width={isServiceListPage ? 6 : 12} style={{ paddingLeft: 0 }}>
                            <Segment basic className="navigator">
                                <Button
                                    basic
                                    icon="chevron left"
                                    content={!isHome ? 'Back to SGBS Directory' : 'Back to Directories'}
                                    onClick={
                                        isServiceListPage || serviceDetails || isSearch
                                            ? () => this.props.history.push('/public/service')
                                            : () => this.props.history.push('/public/directory')
                                    }
                                />
                            </Segment>
                        </Grid.Column>
                        {isServiceListPage && !isHome && (
                            <Grid.Column width={6} textAlign="right">
                                <Button icon="print" content="Print" className="btn print primary-green" onClick={() => window.print()} />
                            </Grid.Column>
                        )}
                    </Grid.Row>

                    {/* Bread crumb */}
                    {(isServiceListPage || serviceDetails) && !isSearch && (
                        <Grid.Row centered={false} className="noprint">
                            <Grid.Column width={12} style={{ paddingLeft: '5px' }}>
                                <Header
                                    as="h4"
                                    className="mx-2 my-1"
                                    style={{
                                        color: '#78be20',
                                        fontSize: 28,
                                    }}
                                >
                                    {/*<span style={{ color: '#78be20' }}>{serviceCategoryName}</span>*/}
                                    <BreadcrumbItem
                                        canRedirect={serviceDetails}
                                        redirect={() => {
                                            history.push({ pathname: `/public/service/list/${serviceCategoryId}` });
                                        }}
                                        text={serviceCategoryName}
                                    />
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    )}

                    <Grid.Row centered>
                        {/* Sidebar */}
                        {!isHome && !serviceDetails && !isSearch && (
                            <Grid.Column width={3} className="main-sidebar">
                                <Sidebar
                                    options={categoryOptions}
                                    categories={serviceCategories}
                                    handleChange={this.handleChange}
                                    onFilter={this.handleFilter}
                                    states={this.state}
                                    onFetchServices={this.handleFetchServices}
                                />
                            </Grid.Column>
                        )}
                        <Grid.Column
                            width={isSearch ? 12 : !isHome && !serviceDetails ? 9 : 16}
                            style={mobile ? { top: '50px' } : { paddingLeft: '0px' }}
                        >
                            <Switch>
                                <Route
                                    exact
                                    path={url}
                                    render={() => (
                                        <Service
                                            ratingId={this.state.ratingId}
                                            loading={loading}
                                            onFetchServices={this.handleFetchServices}
                                            serviceCategories={serviceCategories}
                                            onChange={this.handleChange}
                                            onSearchSubmit={this.handleSearchSubmit}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${url}/list/:serviceCategoryId`}
                                    render={() => (
                                        <ServiceList
                                            handlers={{
                                                onFetchServices: this.handleFetchServices,
                                                onFetchServiceDetails: this.handleFetchServiceDetails,
                                                onHandleChange: this.handleChange,
                                                onSearchSubmit: this.handleSearchSubmit,
                                                onFilterRating: this.handleFilterRating,
                                            }}
                                            ratings={ratings}
                                            zcpRatingOption={zcpRatingOption}
                                            ratingTicks={ratingTicks}
                                            services={services}
                                            states={this.state}
                                            loading={loading}
                                            options={categoryOptions}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${url}/search`}
                                    render={() => (
                                        <ServiceList
                                            handlers={{
                                                onFetchServices: this.handleFetchServices,
                                                onFetchServiceDetails: this.handleFetchServiceDetails,
                                                onHandleChange: this.handleChange,
                                                onSearchSubmit: this.handleSearchSubmit,
                                                onFilterRating: this.handleFilterRating,
                                            }}
                                            ratings={ratings}
                                            zcpRatingOption={zcpRatingOption}
                                            ratingTicks={ratingTicks}
                                            services={services}
                                            states={this.state}
                                            loading={loading}
                                        />
                                    )}
                                />
                                <Route
                                    path={`${url}/details/:companyId/:serviceCategoryId/:serviceId`}
                                    render={() => (
                                        <ServiceDetails
                                            onFetchServiceDetails={this.handleFetchServiceDetails}
                                            companyInfo={companyInfo}
                                            services={services}
                                            loading={loading}
                                            changeServiceCategoryId={this.changeServiceCategoryId}
                                        />
                                    )}
                                />
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <AffiliatesPanel />
            </React.Fragment>
        );
    }
}

function mouseHover(e) {
    e.target.style.color = '#78be20';
}

function mouseLeave(e) {
    e.target.style.color = '#555';
}

const BreadcrumbItem = props => {
    const { canRedirect, text, redirect } = props;

    if (canRedirect)
        return (
            <a onMouseOver={mouseHover} onMouseLeave={mouseLeave} onClick={redirect} href="#">
                {text}
            </a>
        );
    else return text;
};

const mapState = ({ public: publicReducer }, ownProps) => {
    const companyInfo = publicReducer.companyInfo;
    const serviceDetails = '/public/service/details';
    const location = ownProps.location.pathname;
    const categoryOptions =
        publicReducer.serviceCategories &&
        publicReducer.serviceCategories
            // .filter(item => item.showLabel === 1)
            .map(item => ({ key: item.id, text: item.category, value: item.id }));

    let ratingOptionStyle = {
        color: '#78be20',
        marginBottom: '0px',
    };
    const ratingOptions =
        publicReducer.ratings &&
        publicReducer.ratings.map(item => {
            // const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" />

            let text = (
                <span>
                    Rating <label style={ratingOptionStyle}>{item.ratingName}</label>
                </span>
            );

            // if (item.ratingId === 5 || item.ratingId === 1) text = <span>Rating <label style={css}>{item.ratingName}</label></span>
            // else if (item.ratingId === 6 || item.ratingId === 2) text = <span>Rating {item.ratingName}</span>
            // else if (item.ratingId === 7 || item.ratingId === 3) text = <span>Rating {item.ratingName}</span>
            // else if (item.ratingId === 8 || item.ratingId === 4) text = <span>Rating {item.ratingName}</span>

            return { key: item.ratingId, text, value: item.ratingId };
        });

    const zcpRatingOption = {
        key: 99,
        text: (
            <span>
                <label style={ratingOptionStyle}>ZCP</label>
            </span>
        ),
        value: 99,
    };

    const ratingTicks = item => {
        let text = '';

        let css = {
            color: '#78be20',
            fontWeight: 'bolder',
        };

        if (item.ratingId === 5 || item.ratingId === 1) text = <span style={css}>L1</span>;
        else if (item.ratingId === 6 || item.ratingId === 2) text = <span style={css}>L2</span>;
        else if (item.ratingId === 7 || item.ratingId === 3) text = <span style={css}>L3</span>;
        else if (item.ratingId === 8 || item.ratingId === 4) text = <span style={css}>L4</span>;

        return text;
    };

    const ratingSorted = ratingOptions && ratingOptions.sort((a, b) => b.key - a.key);

    const services =
        publicReducer.services &&
        publicReducer.services.map(x => {
            const formatDate = date => moment(date).format('DD/MM/YYYY');
            return {
                ...x,
                validTill: formatDate(x.validTill),
                validFrom: formatDate(x.validFrom),
                issuedOn: formatDate(x.issuedOn),
            };
        });

    return {
        ratings: ratingSorted && [{ key: 'all', text: 'All', value: 'all' }, ...ratingSorted],
        zcpRatingOption,
        loading: publicReducer.loading,
        isHome: location === '/public/service' || location === '/public/service/',
        serviceDetails: location.includes(serviceDetails),
        serviceCategories: publicReducer.serviceCategories,
        isServiceListPage: location.includes('/list'),
        isSearch: location.includes('/search'),
        categoryOptions,
        companyInfo,
        ratingTicks,
        services,
    };
};

const actionList = { fetchServiceCategories, filterServices, fetchRatingService, searchServices, fetchServiceCompanyInfo };

// prettier-ignore
export default connect(mapState, actionList)(withRouter(ServiceContainer));
