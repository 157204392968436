import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';

import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';

import { getMembershipApplicationAnalytic } from 'actions/analytics/memberAnalyticsAction';

class ActiveSGBPCertifiedProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data[0],
            });
        });
    }

    render() {
        const xs = {
            bigxs: 4,
            normalxs: 8,
        };
        return (
            <AnalyticsCard title={`New Membership Applications`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigTextWithNormalText
                            normalText={`Draft`}
                            normalTextColor={`unset`}
                            bigText={this.state.data.membershipDraftCount}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Submitted`}
                            normalTextColor={`unset`}
                            bigText={this.state.data.membershipSubmittedCount}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Processing`}
                            normalTextColor={`unset`}
                            bigText={this.state.data.membershipProcessing}
                            bigTextColor={`#f8ac59`}
                            {...xs}
                        />

                        <AnalyticsBigTextWithNormalText
                            normalText={`Pending Payment`}
                            normalTextColor={`unset`}
                            bigText={this.state.data.membershipPendingPayment}
                            bigTextColor={`#78BE20`}
                            {...xs}
                        />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getMembershipApplicationAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ActiveSGBPCertifiedProducts));
