import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col } from 'reactstrap';
import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';

import { getMemberAttendeesCountAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class MemberAttendees extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.state = {
            data: [],
        };
    }
    componentDidMount() {
        this.props.load(this.props.eventId).then(res => {
            if (this._isMounted)
                this.setState({
                    data: res.data[0],
                });
        });
    }
    componentWilllUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <AnalyticsCard title="SGBC Member Registrants" height="85">
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col xs="auto">
                                <AnalyticsBigText text={`${this.state.data.memberAttendeesAttended}`} textColor={`#f8ac59`} />
                            </Col>
                            {/* <Col xs="auto">
                                <AnalyticsNormalText text={`or`} textColor={`#f8ac59`} />
                            </Col> */}
                            <Col xs="auto">
                                <AnalyticsBigText
                                    text={`(${
                                        Number.isNaN(this.state.data.percentage) || this.state.data.percentage == 'NaN'
                                            ? 0
                                            : Math.round(this.state.data.percentage)
                                    }%)`}
                                    textColor={`#f8ac59`}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: eventId => {
            return dispatch(getMemberAttendeesCountAnalytic(eventId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MemberAttendees));
