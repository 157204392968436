import React from 'react';
import { Grid, Item, List, Header, Image, Input, Segment, Label, Form, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchProducts } from '../../../actions/public';
import NoData from '../components/NoData';
import { mobile } from '../dummy';

class SearchResult extends React.Component {
    state = { productTypeLabels: 'all', ratings: 'all' };

    render() {
        const { history, products, loading, ratingTicks, states, handlers, ratingOptions } = this.props;
        const { sortRating, ratingId } = states;
        const { onSearchCurrentResult, onFetchProductDetails, onFilterRating, onSearchChange } = handlers;

        return (
            <Grid stackable centered className={loading ? 'loading search-page' : 'search-page'}>
                <Grid.Column width={mobile ? 16 : 14}>
                    <Segment basic className="navigator">
                        <Grid className="found">
                            <Grid.Column width={4}>
                                <Header as="h3" style={{ paddingTop: 10 }}>
                                    {(products && products.length < 1) || !products
                                        ? 'No results found'
                                        : `Found ${products && products.length} ${products && products.length > 1 ? 'results' : 'result'}`}
                                </Header>
                            </Grid.Column>
                            {products && !this.props.search && (
                                <Grid.Column width={12}>
                                    <Form onSubmit={onSearchCurrentResult}>
                                        <Input
                                            fluid
                                            // icon={<Icon name='arrow right' circular={true} link onClick={onSearchCurrentResult}/>}
                                            // icon={{ name: 'arrow right', circular: true, link: true }}
                                            size="large"
                                            name="keyword"
                                            onChange={onSearchChange}
                                            placeholder="Search from current result"
                                        />
                                    </Form>
                                </Grid.Column>
                            )}
                        </Grid>
                    </Segment>
                    <Segment basic className="filtering">
                        <Header as="h4" content="Filter by Rating" />
                        <Label.Group circular size="medium">
                            {ratingOptions &&
                                ratingOptions
                                    .sort((a, b) => b.key - a.key)
                                    .map(item => (
                                        <Label
                                            as="a"
                                            key={item.key}
                                            content={item.text}
                                            value={item.value}
                                            onClick={onFilterRating}
                                            className={item.value === ratingId ? 'active ticks' : 'ticks'}
                                        />
                                    ))}
                        </Label.Group>
                    </Segment>
                    <Item.Group link>
                        {products && products.length > 0 ? (
                            products
                                .sort((a, b) => b.ratingId - a.ratingId)
                                .map(item => (
                                    <Item
                                        key={item.productId}
                                        onClick={
                                            () =>
                                                onFetchProductDetails(
                                                    item.certificateId,
                                                    item.companyId,
                                                    item.productTypeId,
                                                    item.productSubcategoryId,
                                                    item.productCategoryId,
                                                )
                                            //onFetchProductDetails(item.certificateId, item.companyId, this.props.search)
                                        }
                                    >
                                        <Item.Content>
                                            <Segment basic>
                                                <Header className="company">{item.companyName}</Header>
                                            </Segment>
                                            <Item.Description className="description-list">
                                                <Grid columns={2}>
                                                    <Grid.Column>
                                                        <List>
                                                            <List.Item>
                                                                <div>Brand:</div> &nbsp;
                                                                <div>
                                                                    <strong>{item.brandName}</strong>
                                                                </div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div>Model:</div> &nbsp;
                                                                <div>
                                                                    <strong style={{ 'white-space': 'unset' }}>{item.models}</strong>
                                                                </div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div>Product type:</div> &nbsp;
                                                                <div>
                                                                    <strong>{item.productType}</strong>
                                                                </div>
                                                            </List.Item>
                                                        </List>
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <List>
                                                            <List.Item>
                                                                <div>Certification number:</div> &nbsp;{' '}
                                                                <div>
                                                                    <strong>{item.certNo}</strong>
                                                                </div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div>Issued on:</div> &nbsp;{' '}
                                                                <div>
                                                                    <strong>{item.validFrom}</strong>
                                                                </div>
                                                            </List.Item>
                                                            <List.Item>
                                                                <div>Valid until:</div> &nbsp;{' '}
                                                                <div>
                                                                    <strong>{item.validTill}</strong>
                                                                </div>
                                                            </List.Item>
                                                        </List>
                                                    </Grid.Column>
                                                    <Grid.Row columns={1}>
                                                        <Grid.Column>
                                                            <Header as="h5" className="ticks">
                                                                {item.rating} &nbsp; {ratingTicks(item)}
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Item.Description>
                                        </Item.Content>
                                    </Item>
                                ))
                        ) : (
                            <NoData header="No results found. Please search using more generic keywords." />
                        )}
                    </Item.Group>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapState = (state, ownProps) => {
    const ratings = ownProps.filteringLabels && ownProps.filteringLabels.ratings;
    const ratingsId = ownProps.filteringLabels && ownProps.filteringLabels.ratings.map(item => item.id);
    const filteringLabels = ownProps.productType && ownProps.productType.filter(item => item.showLabel === 1);
    const filteringLabelsId = ownProps.productType && ownProps.productType.map(item => item.productTypeId);
    const ratingOptions = ownProps.ratingOptions && ownProps.ratingOptions.sort((a, b) => b.value - a.value);

    const ratingTicks = item => {
        const checkmark = <Image src="/assets/icons/tick.png" alt="Tick" />;

        let text = '';

        if (item.ratingId === 1) text = <span> {checkmark}</span>;
        else if (item.ratingId === 2)
            text = (
                <span>
                    {' '}
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 3)
            text = (
                <span>
                    {' '}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );
        else if (item.ratingId === 4)
            text = (
                <span>
                    {' '}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                    {checkmark}
                </span>
            );

        return text;
    };

    const ratingAll = {
        active: 1,
        id: 'all',
        productAssessmentRating: 1,
        ratingName: 'All',
        ratingNumber: 5,
        updatedById: 1,
    };

    const productTypeAll = {
        productTypeId: 'all',
        showLabel: 1,
        type: 'All',
    };

    return {
        ratings: ratings && [...ratings, ratingAll],
        filteringLabels: filteringLabels && [...filteringLabels, productTypeAll],
        ratingsId,
        filteringLabelsId,
        ratingTicks,
        ratingOptions,
    };
};

// prettier-ignore
export default connect(mapState, { fetchProducts }, )(withRouter(SearchResult));
