import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import moment from 'moment';

import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import TopNavigationLayout from '../../../../layouts/TopNavigationLayout';
import ButtonIcon from 'cyder/button/ButtonIcon';
import BigForm from 'cyder/forms/BigForm';
import OMRPanel from './components/OMRPanel';
import MembershipPlansCard from './components/MembershipPlansCard';
import QuestionaireComponent from './components/QuestionaireComponent';
import PreviewApplication from '../preview/PreviewApplication';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import TermsAndConditionsPanel from 'pages/member/membership/new/components/TermsAndConditionsPanel';

import {
    memberMembershipApplicationNewGetPackageInfo,
    memberMembershipApplicationNewGetQuestions,
    forceLoadingState,
    submitApplication,
    saveApplication,
    setModalData,
} from 'actions/member/membership/application/memberNewMembershipApplicationAction';
import { getMembershipApplicationInfo } from 'actions/member/membership/application/membershipApplicationAction';

import { memberHomePanelMembershipGetInfo } from 'actions/member/home/memberHomeAction';
import { memberMembershipApplicationGetCompanyInfo } from 'actions/member/company/memberCompanyAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';

import { actionExecutor, hasElevatedRoles } from 'js/util';

const learnSGBCQuestionId = 1;
const reasonsJoiningSGBCId = 2;
class MemberMembershipNewApplicationPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.showLoading = this.showLoading.bind(this);
        this.selectPackage = this.selectPackage.bind(this);
        this.goPreview = this.goPreview.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.onAgreeTermsChange = this.onAgreeTermsChange.bind(this);
        this.othersTextareaOnChange = this.othersTextareaOnChange.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.toggleModalSubmitOrSaveApplication = this.toggleModalSubmitOrSaveApplication.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.state = {
            step: 0, // Default step
            membershipPackageId: null,
            isNewMember: false,
            loading: false,
            othersState: {},
            answersState: {},
            currentAnswersState: {},
            membershipInfo: {},
            modal: false,
            agreeTerm: false,
            hasSaved: false,
            infoMessage: 'Changes not saved',
        };
    }
    componentDidMount() {
        const { hasElevatedRoles, memberHomeMembershipInfo } = this.props;
        window.scrollTo(0, 0);
        const step = this.determineStepToStart();
        if (!hasElevatedRoles) return;
        const callback = async () => {
            // Get relevant data (including questions)
            await this.getRelevantData(this.props.type);
            // Set initial AnswersState based on available questions
            this.setInitialAnswersState();
            // Saved session begins straight from step 2
            if (step === 2) this.getMembershipApplicationInfo();
            // Determine if user is existing or new member
            const isNewMember = !memberHomeMembershipInfo.membershipEndDate && !memberHomeMembershipInfo.membershipStartDate ? true : false;
            if (this._isMounted) this.setState({ isNewMember });
        };
        actionExecutor(this.showLoading, callback);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    determineStepToStart() {
        const step = parseInt(this.props.match.params.step) || this.state.step;
        this.setState({
            step,
        });
        return step;
    }
    showLoading(loading) {
        if (this._isMounted) this.setState({ loading });
    }
    changeStep(int = 1) {
        this.setState({ step: this.state.step + int });
    }
    setInitialAnswersState() {
        const { questions } = this.props;
        //Setting initial answersState based on number of answers available for the question
        let answersState = {};
        questions.forEach(q => {
            const array = new Array(q.answer.length);
            answersState[q.question.id] = array.fill(false);
        });
        if (this._isMounted) this.setState({ answersState });
    }
    parseSavedAnswerToAnswerState(savedAnswer) {
        var { answersState, othersState } = Object.assign({}, this.state);
        if (Object.keys(answersState).length === 0) return;
        const { questions } = this.props;

        savedAnswer.forEach(answer => {
            const { optionSelected, otherAnswer, question_id } = answer;
            if (!questions[question_id - 1]) return;
            // Get all answers of the question by questions_id
            const answersOfTheQuestion = questions[question_id - 1].answer;
            // Get index of the answer in answersOfTheQuestion
            const index = answersOfTheQuestion.map((x, i) => (x.id === optionSelected ? i : '')).filter(x => x);
            // Set value to true within answerState
            answersState[question_id].splice(index, 1, true);
            // Set other state
            othersState[question_id] = otherAnswer;
        });
        this.setState({ answersState });
    }
    getMembershipApplicationInfo() {
        const { id } = this.props.match.params;
        if (!id) return;
        const callback = async () => {
            this.setInitialAnswersState();
            // GET APPLICATION INFO AND SAVED ANSWERS
            const { data } = await this.props.getMembershipApplicationInfo(id);
            // ERROR HANDLING
            if (!data || data.length === 0) {
                this.setState({ modal: true });
                return;
            }
            const [membershipInfo, savedAnswer] = data;
            // PARSE SAVED ANSWERS
            this.parseSavedAnswerToAnswerState(savedAnswer);
            // SET STATE
            const { membership_package_id } = membershipInfo[0];
            this.setState({ membershipPackageId: membership_package_id, infoMessage: 'Changes saved', hasSaved: true });
        };
        actionExecutor(this.showLoading, callback);
    }
    getRelevantData() {
        const callback = async () => {
            const res = await this.props.getRelevantData(this.props.type);
            // ERROR HANDLING
            if (!res[2]) {
                this.setState({ modal: true }); // Open modal for error modal message
                return;
            }
        };
        return actionExecutor(() => {}, callback);
    }
    onAgreeTermsChange(checked) {
        this.setState({ agreeTerm: checked });
    }
    handleChangeCheckbox(e) {
        const qid = e.target.dataset.questionId;
        const aid = e.target.dataset.answerId;
        if (!qid || !aid) return;
        const checked = e.target.checked;
        const currentAnswersState = this.state.answersState;
        currentAnswersState[qid][aid - 1] = checked;
        this.setState({ currentAnswersState });
    }
    othersTextareaOnChange(e) {
        const qid = e.target.dataset.qid;
        const val = e.target.value;
        const currentOthersState = this.state.othersState;
        currentOthersState[qid] = val;
        this.setState({
            ...this.state,
            currentOthersState,
        });
    }
    processOther(qid) {
        return this.state.othersState[qid] || '';
    }
    shouldPreviewButtonDisabled() {
        const { answersState } = this.state;
        // Validate if quesstions are answered
        const qids = Object.keys(answersState);
        if (qids.length === 0) return true;
        for (let i = 0; i < qids.length; i += 1) {
            const key = qids[i];
            if (this.processQA(key).length === 0) return true;
        }
        return false;
    }
    processQA(qid) {
        const answersState = this.state.answersState;
        if (!answersState[qid]) return [];
        const result = Object.keys(answersState[qid]).map(item => {
            if (answersState[qid][item] === true) {
                return this.props.questions[qid - 1].answer[item].id;
            }
        });
        return result.filter(item => item);
    }
    goPreview() {
        this.changeStep();
    }
    selectPackage(id) {
        this.changeStep();
        this.setState({ membershipPackageId: id });
    }
    toggleModalSubmitOrSaveApplication(isSave) {
        var appId = this.props.match.params.id !== undefined ? parseInt(this.props.match.params.id) : null;
        const body = {
            renewalId: this.props.memberInfo.renewalId,
            membershipApplicationId: appId,
            membershipPackageId: parseInt(this.state.membershipPackageId),
            learnSGBCquestion: this.processQA(learnSGBCQuestionId).map(x => parseInt(x)), //No increment needed
            learnSGBCquestionOther: this.processOther(learnSGBCQuestionId),
            reasonsJoiningSGBC: this.processQA(reasonsJoiningSGBCId).map(x => parseInt(x)), //No increment needed
            reasonsJoiningSGBCOther: this.processOther(reasonsJoiningSGBCId),
            type: this.props.type,
        };
        this.setState({ modal: true });
        this.props.setModalData({
            modalAction: async () => {
                const { submitApplication, saveApplication } = this.props;
                const { data } = await (isSave ? saveApplication(body) : submitApplication(body));
                if (data) this.setState({ infoMessage: 'Changes saved' });
                // IMPORTANT: Change url
                if (data && this.state.step === 2) {
                    var applicationtype = this.props.type === 'New' ? `newapplication` : `renewapplication`;
                    history.push(`/member/membership/${applicationtype}/${this.state.step}/${data}`);
                    this.goPreview();
                }
            },
            modalBody: `Click OK to ${isSave ? 'save' : 'submit'} Membership Application.`,
        });
    }
    companyHasRep() {
        const omrId = this.props.companyInfo.officialManagementRepresentativeId;
        const smrId = this.props.companyInfo.secondaryRepId;
        return (omrId !== undefined && omrId !== null) || (smrId !== undefined && smrId !== null);
    }
    handleToggleModal() {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }
    handleCloseModal() {
        this.setState({ modal: false });
    }
    render() {
        if (!this.props.hasElevatedRoles) return <UnauthorizedComponent />;
        if (!this.companyHasRep()) return <RedirectToTeamManagementComponent />;
        if (this.state.loading) return <LoadingSpinner center />;
        const { isNewMember, step } = this.state;
        const { memberInfo } = this.props;
        var expiredBeforeSixMonths = true;
        if (memberInfo) {
            expiredBeforeSixMonths = moment().isSameOrBefore(moment(memberInfo.expiryDate).add(6, 'months'));
        }

        return (
            <Container>
                <DialogModal
                    forceModalAction={this.props.forceModalAction}
                    modalAction={this.props.modalAction}
                    modalHeader={this.props.modalHeader}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                    modalOpen={this.state.modal}
                    toggler={this.handleToggleModal}
                    closeModal={this.handleCloseModal}
                />
                {step === 0 && <OMRPanel changeStep={this.changeStep} />}
                <br />
                {step > 0 && (
                    <Fragment>
                        <BigForm title={`${expiredBeforeSixMonths ? this.props.type : 'NEW'} MEMBERSHIP APPLICATION`} />
                        <DescriptionPanel step={step} />
                        <br />
                        <Row>
                            <Col xs={12}>
                                {step === 1 && (
                                    <MembershipPlansCard
                                        mode={this.props.mode}
                                        view={this.props.view}
                                        packages={this.props.packages}
                                        selectPackage={this.selectPackage}
                                        loading={this.props.forceLoading}
                                    />
                                )}
                                {step === 2 && isNewMember && (
                                    <QuestionaireComponent
                                        mode={this.props.mode}
                                        view={this.props.view}
                                        questions={this.props.questions}
                                        answersState={this.state.answersState}
                                        othersState={this.state.othersState}
                                        othersTextareaOnChange={this.othersTextareaOnChange}
                                        handleChangeCheckbox={this.handleChangeCheckbox}
                                    />
                                )}
                                {(step === 2 && !isNewMember) || (step === 3 && isNewMember) ? (
                                    <React.Fragment>
                                        <PreviewApplication
                                            changeStep={this.changeStep}
                                            isNewMember={isNewMember}
                                            questions={this.props.questions}
                                            othersState={this.state.othersState}
                                            recommendedBy={this.state.recommendedBy}
                                            selectedPackage={this.props.packages.find(p => p.id === this.state.membershipPackageId)}
                                            answersState={this.state.answersState}
                                        />
                                        <Row>
                                            <Col className="mt-4">
                                                <TermsAndConditionsPanel onChange={this.onAgreeTermsChange} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 mt-4">
                                            <Col xs={6}>
                                                <ButtonIcon
                                                    label="Back"
                                                    className="primary-btn-style-outline mr-2"
                                                    icon="chevron_left"
                                                    onClick={() => this.changeStep(-1)}
                                                />
                                            </Col>
                                            <Col xs={6} className="d-flex justify-content-end">
                                                <ButtonIcon
                                                    icon="send"
                                                    label="Submit"
                                                    disabled={!this.state.agreeTerm}
                                                    onClick={() => this.toggleModalSubmitOrSaveApplication(false)}
                                                />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ) : null}
                            </Col>
                        </Row>
                        <br />
                        {step === 2 && isNewMember && (
                            <Fragment>
                                <Row className="mb-2">
                                    <Col xs={12} className="d-flex justify-content-end">
                                        <ButtonIcon
                                            disabled={this.shouldPreviewButtonDisabled()}
                                            onClick={() => this.toggleModalSubmitOrSaveApplication(true)}
                                            icon="exit_to_app"
                                            label="Save Changes & Preview"
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xs={12} className="d-flex justify-content-end">
                                        <strong className={`align-middle text-muted`}>{this.state.infoMessage}</strong>
                                    </Col>
                                </Row>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Container>
        );
    }
}

const RedirectToTeamManagementComponent = () => (
    <Container className="mt-4">
        <Card>
            <CardBody className="field-header p-4">
                <h1 className="text-bold color-red">Oops!</h1>
                <br />
                <p>
                    It appears your company has not specified to us on both Official Management Representative and Secondary Management
                    Representative. Both are required to apply for membership.
                </p>
                <br />
                <p>
                    Please click <Link to="/member/myaccount/teammanagement">here</Link> to set up your company data.
                </p>
            </CardBody>
        </Card>
    </Container>
);

const UnauthorizedComponent = () => (
    <Container className="mt-4">
        <Card>
            <CardBody className="field-header p-4">
                <h1 className="text-bold color-red">Oops!</h1>
                <br />
                <p>
                    You have no permissions to apply for membership currently. Please contact your company's representative(s) for
                    assistance in applying for your SGBC membership.
                </p>
                <br />
                <p>
                    You can see the list of members <Link to="/member/myaccount/teammanagement">here</Link>.
                </p>
            </CardBody>
        </Card>
    </Container>
);

const DescriptionPanel = ({ step }) => {
    if (step === 1) {
        return (
            <Card>
                <CardBody>
                    <Col className="description p-4" style={{ textAlign: 'justify' }}>
                        <p>SGBC membership is extremely rewarding. SGBC members enjoy several benefits including:</p>
                        <br />
                        <p>
                            <b>Recognition and Stature</b> - Be part of an exclusive group of green advocates who come from distinguished
                            backgrounds, leading corporations reputed for their green initiatives, academic and research institutions known
                            for outstanding thought leaders in green technologies as well as prominent non-profit and government agencies.
                        </p>
                        <br />
                        <p>
                            <b>Knowledge & Skills Enhancement</b> - Share and learn from some of the best in the industry. Learn about new
                            trends and assimilate the best green building practices. Enhance your knowledge and sharpen your skills to add
                            more value to the businesses and the communities that you serve. To know more on industry research and trends,
                            knowledge and skills development, members can access reports, studies and research papers from the members'
                            portal.
                        </p>
                        <br />
                        <p>
                            <b>Market Opportunities</b> - SGBC Membership allows access to extensive networking opportunities and a global
                            marketplace to showcase green building expertise, products, services and innovative urban solutions that enhance
                            economic value whilst addressing environmental challenges.
                        </p>
                        <br />
                        <p>
                            <b>Members Directory</b> - SGBC connects like-minded people working to green their businesses through an online
                            directory profile. Click here to go to the SGBC Member Directory.
                        </p>
                        <br />
                        <div>
                            <b>Privileges</b> - As a member of SGBC, you will get to enjoy:
                            <ul>
                                <li>
                                    Use of the SGBC Member's logo: SGBC allows members to use its corporate logo on business cards and
                                    corporate stationery (subject to prior approval).
                                </li>
                                <li>
                                    Preferential Rates for SGBC certification services, seminars, workshops and conferences - adding even
                                    more value to your membership!
                                </li>
                            </ul>
                        </div>
                    </Col>
                </CardBody>
            </Card>
        );
    }
    if (step === 2) {
        return (
            <Col>
                <p>
                    All membership applications are subject to the approval of the SGBC Board and we reserve the right to grant or refuse
                    Membership Applications at our sole and absolute discretion. Upon Application, the outcome will be made known in about 1
                    to 2 weeks.
                </p>
                <p>All fields marked with an asterisk (*) are required.</p>
            </Col>
        );
    }
    return '';
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        ...state.memberHomeReducer,
        ...state.memberNewMembershipApplicationReducer,
        companyInfo: state.memberCompanyReducer.companyInfo,
        hasElevatedRoles: hasElevatedRoles(state.profile.user),
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantData: type => {
            var isRenew = 0;
            if (type == 'Renew') isRenew = 1;
            const array = [
                dispatch(memberMembershipApplicationNewGetPackageInfo(type)), // All Packages availabel in season
                dispatch(memberMembershipApplicationGetCompanyInfo()), // Company Info
                dispatch(memberMembershipApplicationNewGetQuestions()), // Questions
                dispatch(memberHomePanelMembershipGetInfo()),
            ];
            return dispatch(forceLoadingState(true))
                .then(() => Promise.all(array))
                .then(res => {
                    dispatch(forceLoadingState(false));
                    return res;
                });
        },
        getMembershipApplicationInfo: id => {
            return dispatch(getMembershipApplicationInfo(id));
        },
        setModalData: data => {
            dispatch(setModalData(data));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        submitApplication: body => {
            return dispatch(submitApplication(body));
        },
        saveApplication: body => {
            return dispatch(saveApplication(body));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MemberMembershipNewApplicationPage));
