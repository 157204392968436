import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import {
    GROUP_ADD_GET_DETAIL_REQUEST,
    GROUP_ADD_GET_DETAIL_SUCCESS,
    GROUP_ADD_GET_DETAIL_FAILURE,
    GROUP_ADD_SAVE_GROUP_REQUEST,
    GROUP_ADD_SAVE_GROUP_SUCCESS,
    GROUP_ADD_SAVE_GROUP_FAILURE,
    GROUP_ADD_GET_PERMISSIONS_REQUEST,
    GROUP_ADD_GET_PERMISSIONS_SUCCESS,
    GROUP_ADD_GET_PERMISSIONS_FAILURE,
    GROUP_ADD_DELETE_GROUP_REQUEST,
    GROUP_ADD_DELETE_GROUP_SUCCESS,
    GROUP_ADD_DELETE_GROUP_FAILURE,
    GROUP_ADD_RESET_FIELDS,
    GROUP_ADD_CHANGE_FIELD_VALUE,
    GROUP_ADD_FORCE_VALIDATE,
    GROUP_ADD_CHANGE_PERMISSIONS_VALUE,
    GROUP_ADD_REMOVE_PERMISSION_AT,
} from '../../../actions/constants/actionTypesAuth';
import withQuery from 'with-query';
const normalizeGroupFields = fields => {
    let newFields = {};
    newFields.groupname = fields.name;
    newFields.id = fields.id;
    newFields.permissions = normalizePermissions(fields.permissions);
    return newFields;
};

const normalizePermissions = permissions => {
    let parsedArray = [];
    permissions.forEach(item => {
        const label = `${item.app_label} | ${item.content_model} | ${item.permission_name}`;
        parsedArray.push({
            label,
            value: item.permission_id,
        });
    });
    return parsedArray;
};

{
    /* const a = [{id: 999}, {id:123}]; */
}
{
    /* var id = 999; */
}
{
    /* a.find((group) => { */
}
{
    /*     return group.id === id; */
}
{
    /* }) */
}

export function groupAddSetFields(id) {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_ADD_GET_DETAIL_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/groups', {
            action: 'findbyid',
            id,
        });
        const options = {
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: GROUP_ADD_GET_DETAIL_FAILURE,
                            response,
                        });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        const dataWithPermissions = response.data;
                        dataWithPermissions.permissions = response.permissions.data;
                        dispatch({
                            type: GROUP_ADD_GET_DETAIL_SUCCESS,
                            fields: normalizeGroupFields(response.data),
                        });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_ADD_GET_DETAIL_FAILURE, error });
                return error;
            });
    };
}

export function groupAddResetFields() {
    return dispatch => {
        dispatch({ type: GROUP_ADD_RESET_FIELDS });
        return Promise.resolve();
    };
}
export function groupAddGetPermissions() {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_ADD_GET_PERMISSIONS_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/permission', {
            // appid: getState().cyderProfileReducer.profile.appId, // Undefined profile
            action: 'findall',
        });
        const options = {
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: GROUP_ADD_GET_PERMISSIONS_FAILURE,
                            error,
                        });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        const parsedArray = [];
                        response.data.forEach(item => {
                            const label = `${item.app_label} | ${item.content_model} | ${item.permission_name}`;
                            parsedArray.push({
                                label,
                                value: item.permission_id,
                            });
                        });
                        dispatch({
                            type: GROUP_ADD_GET_PERMISSIONS_SUCCESS,
                            permissionChoices: parsedArray,
                        });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_ADD_GET_PERMISSIONS_FAILURE, error });
                return error;
            });
    };
}
export function groupAddAddGroup(update, id) {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_ADD_SAVE_GROUP_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/groups', {
            // appid: getState().cyderProfileReducer.profile.appId, // Undefined profile
            action: update ? 'update' : 'add',
        });
        const selectedPermissionCodes = getState().groupAddReducer.fields.permissions;
        let grp_permission_ids = [];
        selectedPermissionCodes.forEach(item => {
            grp_permission_ids.push(item.value);
        });
        const body = {
            name: getState().groupAddReducer.fields.groupname,
            grp_permission_ids,
        };
        if (update) {
            body.id = id;
        }
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: GROUP_ADD_SAVE_GROUP_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: GROUP_ADD_SAVE_GROUP_SUCCESS,
                            response,
                        });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_ADD_SAVE_GROUP_FAILURE, error });
                return error;
            });
    };
}
export function groupAddDeleteGroup() {
    return (dispatch, getState) => {
        dispatch({ type: GROUP_ADD_DELETE_GROUP_REQUEST });
        const url = withQuery(SGBC_API_ROOT + '/auth_v1/groups', {
            action: 'delete',
        });
        const data = {
            id: getState().groupAddReducer.fields.id,
        };
        const options = {
            body: JSON.stringify(data),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: GROUP_ADD_DELETE_GROUP_FAILURE,
                            error,
                        });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: GROUP_ADD_DELETE_GROUP_SUCCESS,
                            response,
                        });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: GROUP_ADD_DELETE_GROUP_FAILURE, error });
                return error;
            });
    };
}

export function changeFieldValueAction(key, value) {
    return {
        type: GROUP_ADD_CHANGE_FIELD_VALUE,
        key,
        value,
    };
}
export function changePermissionValueAction(value) {
    return {
        type: GROUP_ADD_CHANGE_PERMISSIONS_VALUE,
        value,
    };
}

export function removePermissionValueAt(index) {
    return {
        type: GROUP_ADD_REMOVE_PERMISSION_AT,
        index,
    };
}

export function forceValidate() {
    return dispatch => {
        dispatch({ type: GROUP_ADD_FORCE_VALIDATE });
        return Promise.resolve();
    };
}
