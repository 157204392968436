import React from 'react';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Input, Row, Col, Button, Label, Alert } from 'reactstrap';

export const FootNotesComponent = ({ ratingRule, onFieldChange, sectionC, readonly }) => (
    <React.Fragment>
        <Row>
            <Col xs={2}>
                <u>Rating</u>
            </Col>
            <Col xs={3}>
                <u>Number of items</u>
            </Col>
        </Row>

        <Row>
            <Col xs={2}>Good</Col>
            <Col xs={3}>
                <React.Fragment>
                    <Input
                        style={{ width: '100px', float: 'left' }}
                        value={ratingRule.good.supplementary}
                        disabled={readonly}
                        onChange={e => {
                            onFieldChange('good', 'supplementary', e.target.value);
                        }}
                    />
                    &nbsp;/ {sectionC.length}
                </React.Fragment>
            </Col>
        </Row>

        <Row>
            <Col xs={2}>Very Good</Col>
            <Col xs={3}>
                <React.Fragment>
                    <Input
                        style={{ width: '100px', float: 'left' }}
                        value={ratingRule.veryGood.supplementary}
                        disabled={readonly}
                        onChange={e => {
                            onFieldChange('veryGood', 'supplementary', e.target.value);
                        }}
                    />
                    &nbsp;/ {sectionC.length}
                </React.Fragment>
            </Col>
        </Row>

        <Row>
            <Col xs={2}>Excellent</Col>

            <Col xs={3}>
                <React.Fragment>
                    <Input
                        style={{ width: '100px', float: 'left' }}
                        value={ratingRule.excellent.supplementary}
                        disabled={readonly}
                        onChange={e => {
                            onFieldChange('excellent', 'supplementary', e.target.value);
                        }}
                    />
                    &nbsp;/ {sectionC.length}
                </React.Fragment>
            </Col>
        </Row>

        <Row>
            <Col xs={2}>Leader</Col>

            <Col xs={3}>
                <React.Fragment>
                    <Input
                        style={{ width: '100px', float: 'left', paddingRight: '20px' }}
                        value={ratingRule.leader.supplementary}
                        disabled={readonly}
                        onChange={e => {
                            onFieldChange('leader', 'supplementary', e.target.value);
                        }}
                    />
                    &nbsp;/ {sectionC.length}
                </React.Fragment>
            </Col>
        </Row>
    </React.Fragment>
);

export default FootNotesComponent;
