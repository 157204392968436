import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import history from '../../../../history';
import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import MainComponent from './EventsCreatePageMainComponent';
import DatesComponent from './EventsCreatePageDatesComponent';
import TicketsComponent from './EventsCreatePageTicketsComponent';
import ChecklistListPage from '../event-checklist/ListPage';
import ChecklistAddPage from '../event-checklist/AddPage';
import ExpendituresListPage from '../event-expenditure/ListPage';
import ExpendituresAddPage from '../event-expenditure/AddPage';
import IndividualEventAnalytics from 'pages/analytics/IndividualEventAnalytics';
import { SGBC_WEB_HOST } from 'config';

import {
    eventResetEventFields,
    eventGetEventDetails,
    eventSaveEventDetails,
    eventRemoveEventDetails,
    eventUpdateEventDetails,
    eventChangeEventInput,
    eventHandleImageInput,
    // eventGetfoodRestriction,
    eventGetPaymentMode,
    eventSetEventId,
    eventSetModalData,
    eventSaveDocumentFile,
    eventRemoveDocumentFile,
    eventDownloadDocumentFile,
} from '../../../../actions/staff/events/events/eventsCreationAction';
import {
    eventDatesGetEventDate,
    eventMultipleDatesGetEventDate,
    eventDatesSaveEventDate,
    eventDatesSaveOrUpdateEventDate,
    eventDatesUpdateEventDate,
    eventDatesChangeDateInput,
    eventDatesResetFields,
    eventDatesSetModalData,
    eventDatesSetEventId,
    eventDatesAddDate,
    eventDatesDeleteDate,
} from '../../../../actions/staff/events/events/eventsDateAction';
import {
    eventTrackSetEventId,
    eventGetEventTracks,
    eventSaveAllEventTracks,
    eventUpdateAllEventTracks,
    eventSaveNewEventTrack,
    eventSaveSingleEventTrack,
    eventRemoveSingleEventTrack,
    eventChangeTrackInput,
    eventResetTrackFields,
    eventTracksSetModalData,
} from '../../../../actions/staff/events/events/eventsTrackAction';
import { saveNewChecklist } from '../../../../actions/staff/events/event-checklist/action';
import { eventsAttendeeGetAttendeeList } from '../../../../actions/staff/events/events/eventsAttendeeAction';
import { dialogModalToggle } from '../../../../actions/modal/dialogModalAction';
import { blobDownload, parseContentType } from '../../../../js/util';

const navs = [
    {
        key: 'main',
        text: 'Main Details',
    },
    {
        key: 'dates',
        text: 'Date & Time',
    },
    // {
    //     key: 'tracks',
    //     text: 'Tracks',
    // },
    {
        key: 'tickets',
        text: 'Tickets',
    },
    // {
    //     key: 'materials',
    //     text: 'Materials',
    // },
    {
        key: 'checklist',
        text: 'Checklist',
    },
    {
        key: 'expenditures',
        text: 'Income & Expenditure',
    },
    {
        key: 'analytics',
        text: 'Analytics',
    },
];
class EventsAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.setInitialActiveTab = this.setInitialActiveTab.bind(this);
        //Event - Main Details
        this.saveEventDetails = this.saveEventDetails.bind(this);
        this.deleteEventDetails = this.deleteEventDetails.bind(this);
        this.updateEventDetails = this.updateEventDetails.bind(this);
        this.handleEventFileInput = this.handleEventFileInput.bind(this);
        this.handleEventImageInput = this.handleEventImageInput.bind(this);
        this.downloadDocumentFile = this.downloadDocumentFile.bind(this);
        // New File Upload
        this.onEDMUploadSuccess = this.onEDMUploadSuccess.bind(this);
        this.onEDMUploadFail = this.onEDMUploadFail.bind(this);
        this.onEventBannerUploadFail = this.onEventBannerUploadFail.bind(this);
        this.onEventBannerUploadSuccess = this.onEventBannerUploadSuccess.bind(this);
        this.onRegistrationPageImageUploadSuccess = this.onRegistrationPageImageUploadSuccess.bind(this);
        this.onRegistrationPageImageUploadFail = this.onRegistrationPageImageUploadFail.bind(this);
        // Event - Dates
        this.saveEventDates = this.saveEventDates.bind(this);
        this.updateEventDates = this.updateEventDates.bind(this);
        this.addEventDate = this.addEventDate.bind(this);
        this.deleteEventDate = this.deleteEventDate.bind(this);
        //Event - Track
        this.saveEventTracks = this.saveEventTracks.bind(this);
        this.updateEventTracks = this.updateEventTracks.bind(this);
        this.saveSingleEventTrack = this.saveSingleEventTrack.bind(this);
        this.deleteSingleEventTrack = this.deleteSingleEventTrack.bind(this);
        this.renderNavItem = this.renderNavItem.bind(this);
        this.handleChangeEventDescription = this.handleChangeEventDescription.bind(this);
        this.handleChangeEventSummary = this.handleChangeEventSummary.bind(this);
        this.handleChangeEventVenue = this.handleChangeEventVenue.bind(this);
        // Event - Checklist
        this.changeChecklistMode = this.changeChecklistMode.bind(this);
        // Event - Expenditure
        this.changeExpenditureMode = this.changeExpenditureMode.bind(this);
        this.saveNewChecklist = this.saveNewChecklist.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.state = {
            activeTab: 'main',
            checklist: {
                mode: 'list',
                id: 0,
            },
            expenditure: {
                mode: 'list',
                id: 0,
            },
            tracksArray: [],
            checklistArray: [],
            edmFileId: '',
            edmFileName: '',
            registrationPageImageFileId: '',
            registrationPageImageFileName: '',
            eventBannerFileId: '',
            eventBannerFileName: '',
            eventDescription: this.props.main.fields.description ? this.props.main.fields.description : '',
            eventSummary: this.props.main.fields.summary ? this.props.main.fields.summary : '',
            loading: false,
            file: '',
        };
    }
    onEventBannerUploadSuccess(file) {
        this.setState({
            eventBannerFileId: file.id,
        });
    }
    onEventBannerUploadFail(error) {}
    onRegistrationPageImageUploadSuccess(file) {
        this.setState({
            registrationPageImageFileId: file.id,
        });
    }
    onRegistrationPageImageUploadFail(error) {}
    onEDMUploadSuccess(file) {
        this.setState({
            edmFileId: file.id,
        });
    }
    onEDMUploadFail(error) {}
    showLoading(loading) {
        if (this._isMounted)
            this.setState({
                loading,
            });
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    componentDidMount() {
        this._isMounted = true;
        const eventid = this.props.match.params.eventid;
        this.setInitialActiveTab(navs);
        this.showLoading(true);
        this.props
            .getRelevantStuffs()
            .then(() => this.getEventDetails(eventid, this.state.activeTab))
            .then(() => this.showLoading(false));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) this.componentDidMount();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChangeEventDescription = event => {
        this.setState({ eventDescription: event });
    };

    handleChangeEventSummary = event => {
        this.setState({ eventSummary: event });
    };
    handleChangeEventVenue = value => {
        var target = {
            id: 'venue',
            value: value,
            name: 'venue',
        };
        var event = {
            target: target,
        };
        this.props.changeEventInput(event);
    };
    toggleTab(tab) {
        if (this.state.activeTab !== tab) history.push('./' + tab);
    }
    changeExpenditureMode(type, id) {
        let expenditure = Object.assign({}, this.state.expenditure);
        expenditure.mode = type;
        expenditure.id = id;
        this.setState({
            expenditure,
        });
    }
    changeChecklistMode(type, id) {
        let checklist = Object.assign({}, this.state.checklist);
        checklist.mode = type;
        checklist.id = id;
        this.setState({
            checklist,
        });
    }
    async getEventDetails(eventid, activeTab) {
        try {
            if (eventid) {
                switch (activeTab) {
                    case 'main':
                        //Event - Main
                        await this.props.eventResetEventFields();
                        await this.props.getEventDetails(eventid);
                        this.setState({
                            edmFileName: `Download EDM PDF`,
                            edmFileId: this.props.main.fields.edmPdf,
                            eventBannerFileName: `Download Banner Image`,
                            eventBannerFileId: this.props.main.fields.bannerImage,
                            registrationPageImageFileName: `Download Registration Page Image`,
                            registrationPageImageFileId: this.props.main.fields.registrationPageImage,
                            eventDescription: this.props.main.fields.description,
                            eventSummary: this.props.main.fields.summary,
                            confirmRegRequired: this.props.main.fields.confirmRegRequired,
                        });
                        this.props.eventSetEventId(eventid);
                        break;
                    case 'dates':
                        //Event - Dates
                        await this.props.getEventDetails(eventid); // event details is also required, to show the header
                        await this.props.eventSetEventId(eventid);
                        await this.props.eventResetDatesFields();
                        await this.props.getEventDates(eventid);
                        await this.props.eventTrackSetEventId(eventid);
                        break;
                    case 'tracks':
                        //Event - Tracks
                        await this.props.getEventDetails(eventid); // event details is also required, to show the header
                        await this.props.eventSetEventId(eventid);
                        await this.props.eventResetTrackFields();
                        await this.props.eventGetAttendeeList(eventid);
                        await this.props.getEventTracks(eventid);
                        await this.props.eventTrackSetEventId(eventid);
                        break;
                    default:
                        await this.props.getEventDetails(eventid); // event details is also required, to show the header
                        this.props.eventSetEventId(eventid);
                        break;
                }
            } else {
                this.props.eventResetEventFields();
                this.setState({
                    eventDescription: '',
                    eventSummary: '',
                    confirmRegRequired: true,
                });
            }
        } catch (error) {
            throw error;
        } finally {
            return Promise.resolve();
        }
    }
    //Event - Main Details
    saveEventDetails() {
        const {
            SGBCCPD_visible,
            PEBCPD_visible,
            SCECPD_visible,
            BCACPD_visible,
            BOACPD_visible,
            SILCPD_visible,
            CPD1_visible,
            CPD2_visible,
            CPD3_visible,
            CPD4_visible,
        } = this.props.main.fields;
        const cpd = {
            SGBCCPD_visible: SGBCCPD_visible ? 1 : 0,
            PEBCPD_visible: PEBCPD_visible ? 1 : 0,
            SCECPD_visible: SCECPD_visible ? 1 : 0,
            BCACPD_visible: BCACPD_visible ? 1 : 0,
            BOACPD_visible: BOACPD_visible ? 1 : 0,
            SILCPD_visible: SILCPD_visible ? 1 : 0,
            CPD1_visible: CPD1_visible ? 1 : 0,
            CPD2_visible: CPD2_visible ? 1 : 0,
            CPD3_visible: CPD3_visible ? 1 : 0,
            CPD4_visible: CPD4_visible ? 1 : 0,
        };

        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => {
                this.props.saveEventDetails(
                    this.state.edmFileId,
                    this.state.registrationPageImageFileId,
                    this.state.eventBannerFileId,
                    this.state.eventDescription,
                    this.state.eventSummary,
                    cpd,
                );
            },
            modalBody: 'Click OK to create the event.',
        });
    }
    removeFile(fileType) {
        if (fileType === 'edm') {
            this.setState({
                edmFileId: '',
            });
        } else if (fileType === 'banner') {
            this.setState({
                eventBannerFileId: '',
            });
        } else if (fileType === 'registration') {
            this.setState({
                registrationPageImageFileId: '',
            });
        }
    }
    deleteEventDetails() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () => this.props.deleteEventDetails(this.props.match.params.eventid),
            modalBody: 'Click OK to delete the event',
        });
    }
    updateEventDetails() {
        this.props.dialogModalToggle();
        this.props.setEventModalData({
            modalAction: () =>
                this.props.updateEventDetails(
                    this.props.match.params.eventid,
                    this.state.edmFileId,
                    this.state.registrationPageImageFileId,
                    this.state.eventBannerFileId,
                    this.state.eventDescription,
                    this.state.eventSummary,
                ),
            modalBody: 'Click OK to update the event.',
        });
    }
    handleEventImageInput(event) {
        const supportType = ['image/png', 'image/jpeg'];
        const reader = new FileReader();
        const file = event.target.files[0];
        const id = event.target.id;
        if (supportType.includes(file.type)) {
            reader.onload = () => {
                const b64string = reader.result.substring(reader.result.indexOf(',') + 1);
                this.props.saveDocumentFile(file.type, file.name, b64string, id);
            };
            // Handle cancel behaviour
            if (file.length !== 0) {
                reader.readAsDataURL(file);
            }
        } else {
            this.props.dialogModalToggle();
            this.props.setEventModalData({
                modalAction: './' + this.props.match.params.eventid,
                modalBody: 'File type not supported',
                modalHeader: 'Error',
                modalOnlyOneButton: true,
            });
        }
    }
    handleEventFileInput(event) {
        const reader = new FileReader();
        const file = event.target.files[0];
        const id = event.target.id;
        reader.onload = () => {
            const b64string = reader.result.substring(reader.result.indexOf(',') + 1);
            this.props.saveDocumentFile(file.type, file.name, b64string, id);
        };
        if (file.length !== 0) {
            reader.readAsDataURL(file);
        }
    }
    downloadDocumentFile(inputId) {
        this.props.downloadDocumentFile(inputId).then(res => {
            const contentType = parseContentType(res.data.contenttype);
            const str = res.data.attachment;
            blobDownload(res.data.filename, contentType, str);
        });
    }
    //Event - Dates
    saveEventDates() {
        this.props.dialogModalToggle();
        this.props.setEventDatesModalData({
            modalAction: () => this.props.saveOrUpdateEventDates(),
            modalBody: 'Click OK to save the event date.',
        });
    }
    addEventDate() {
        this.props.eventDatesAddDate();
    }
    deleteEventDate(id) {
        this.props.eventDatesDeleteDate(id);
    }
    updateEventDates() {
        this.props.dialogModalToggle();
        this.props.setEventDatesModalData({
            modalAction: () => this.props.updateEventDates(),
            modalBody: 'Click OK to save the event date.',
        });
    }
    //Event - Tracks
    saveEventTracks() {
        this.props.dialogModalToggle();
        this.props.setEventTracksModalData({
            modalAction: () => this.props.saveEventTracks(),
            modalBody: 'Click OK to save the event tracks.',
        });
    }
    updateEventTracks() {
        this.props.dialogModalToggle();
        this.props.setEventTracksModalData({
            modalAction: () => this.props.updateEventTracks(this.props.match.params.eventid),
            modalBody: 'Click OK to save the event tracks.',
        });
    }
    saveSingleEventTrack(inputid) {
        this.props.saveSingleEventTrack(inputid);
    }
    deleteSingleEventTrack(inputid) {
        this.props.deleteSingleEventTrack(inputid);
    }
    // NAVIGATION RENDER
    renderNav(navs) {
        const navItems = this.props.match.params.eventid ? navs.map(this.renderNavItem) : navs.slice(0, 1).map(this.renderNavItem);
        return navItems;
    }
    renderNavItem(navItem) {
        return (
            <NavItem key={navItem.key}>
                <NavLink
                    className={this.state.activeTab === navItem.key ? 'active' : null}
                    onClick={() => {
                        this.toggleTab(navItem.key);
                    }}
                >
                    {navItem.text}
                </NavLink>
            </NavItem>
        );
    }
    setInitialActiveTab(navs) {
        const urlArray = this.props.match.url.toLowerCase().split('/');
        const urlContext = urlArray[urlArray.length - 1];
        for (let index in navs) {
            const nav = navs[index];
            if (nav.key === urlContext && this._isMounted) {
                this.setState({
                    activeTab: nav.key,
                });
                return;
            } else if (!this.props.match.params.eventid) {
                this.setState({
                    activeTab: 'main',
                });
                return;
            }
        }
    }

    saveNewChecklist(data) {
        this.props.saveNewChecklist(data).then(() => {
            this.changeChecklistMode('list');
        });
    }

    render() {
        const eventid = this.props.match.params.eventid;

        // Note: public rsvp url has to go through staff routes for consistent page layout
        const staffUrl = `/staff/event/rsvp/${eventid || 999}`;

        const { checklist, checklistItems } = this.props;

        return (
            <div className="page-widget">
                <React.Fragment>
                    <DialogModal
                        boundForceUpdate={this.boundForceUpdate}
                        loading={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalLoading : false}
                        modalBody={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalBody : ``}
                        modalOnlyOneButton={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalOnlyOneButton : false}
                        buttonDisabler={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalLoading : true}
                        modalAction={this.props[this.state.activeTab] ? this.props[this.state.activeTab].modalAction : null}
                        modalHeader={
                            this.props[this.state.activeTab] && this.props[this.state.activeTab].modalHeader
                                ? this.props[this.state.activeTab].modalHeader
                                : 'Confirmation'
                        }
                    />
                    <React.Fragment>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <Link to="/staff/events/all">
                                    <ButtonComponent className="primary-btn-style-outline" icon="chevron_left" label="Back" />
                                </Link>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-2">
                            <Col xs={12} className="d-flex">
                                <h4>
                                    <strong>{this.props.main.fields.name}</strong>
                                </h4>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12} className="d-flex">
                                <Link to="/staff/events/all">
                                    <ButtonComponent className="primary-btn-style mr-1" icon="dashboard" label="Event Dashboard" />
                                </Link>
                                {eventid && (
                                    <React.Fragment>
                                        <ButtonComponent
                                            className="primary-btn-style mr-1"
                                            icon="edit"
                                            label="Registration"
                                            onClick={() => {
                                                history.push(`/staff/events/${eventid}/registrations`);
                                            }}
                                        />
                                        <ButtonComponent
                                            className="primary-btn-style mr-1"
                                            icon="supervisor_account"
                                            label="Attendees"
                                            onClick={() => {
                                                history.push(`/staff/events/${eventid}/attendees`);
                                            }}
                                        />
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Row>
                                    <div className="ml-auto d-flex flex-row">
                                        <span className="align-self-center">
                                            {' '}
                                            <Link to={staffUrl}>Staff RSVP Link</Link>{' '}
                                        </span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="ml-auto d-flex flex-row">
                                        <span
                                            style={{ cursor: 'pointer', fontWeight: '400' }}
                                            onClick={e => {
                                                window.open(`/public/event/rsvp/${eventid}`);
                                            }}
                                        >
                                            Public RSVP Link
                                        </span>
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mb-2">
                            <Col xs={12}>
                                <Nav tabs>{this.renderNav(navs)}</Nav>
                            </Col>
                        </Row>
                    </React.Fragment>
                    {this.state.loading || this.props.tickets.loading ? (
                        <LoadingSpinner center />
                    ) : (
                        <div className="page-widget">
                            <Row>
                                <Col xs={12}>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="main">
                                            <MainComponent
                                                data={this.props.main.fields}
                                                state={this.state}
                                                selection={this.props.main.selection}
                                                validation={this.props.main.validation}
                                                eventid={this.props.match.params.eventid} // Input props
                                                addEvent={this.saveEventDetails}
                                                deleteEvent={this.deleteEventDetails}
                                                updateEvent={this.updateEventDetails}
                                                handleEventInputChange={this.props.changeEventInput}
                                                eventDescription={this.state.eventDescription}
                                                eventSummary={this.state.eventSummary} // File input props
                                                file={this.props.main.file}
                                                onChangeEventDescription={this.handleChangeEventDescription}
                                                onChangeEventSummary={this.handleChangeEventSummary}
                                                onChangeEventVenue={this.handleChangeEventVenue}
                                                handleEventFileInput={this.handleEventFileInput}
                                                handleEventImageInput={this.handleEventImageInput}
                                                removeDocumentFileAction={this.props.removeDocumentFile}
                                                downloadDocumentFileAction={this.downloadDocumentFile}
                                                onEDMUploadSuccess={this.onEDMUploadSuccess}
                                                onEDMUploadFail={this.onEDMUploadFail}
                                                onEventBannerUploadSuccess={this.onEventBannerUploadSuccess}
                                                onEventBannerUploadFail={this.onEventBannerUploadFail}
                                                onRegistrationPageImageUploadSuccess={this.onRegistrationPageImageUploadSuccess}
                                                onRegistrationPageImageUploadFail={this.onRegistrationPageImageUploadFail}
                                                edmFileId={this.state.edmFileId}
                                                registrationPageImageFileId={this.state.registrationPageImageFileId}
                                                eventBannerFileId={this.state.eventBannerFileId}
                                                edmFileName={this.state.edmFileName}
                                                registrationPageImageFileName={this.state.registrationPageImageFileName}
                                                eventBannerFileName={this.state.eventBannerFileName}
                                                setActiveTab={this.setInitialActiveTab}
                                                removeFile={this.removeFile}
                                            />
                                        </TabPane>
                                        <TabPane tabId="dates">
                                            {this.props.dates.dates &&
                                                this.props.dates.dates.map((date, i) => (
                                                    <DatesComponent
                                                        key={i}
                                                        // mode={this.props.dates.mode}
                                                        // data={this.props.dates.date}
                                                        fields={date}
                                                        // saveEventDates={this.saveEventDates}
                                                        // updateEventDates={this.updateEventDates}
                                                        handleEventInputChange={this.props.changeDateInput}
                                                        deleteEventDate={this.deleteEventDate}
                                                        deletable={i >= 1}
                                                    />
                                                ))}
                                            {/* <DatesComponent
                                                    mode={this.props.dates.mode}
                                                    data={this.props.dates.date}
                                                    fields={this.props.dates.fields}
                                                    saveEventDates={this.saveEventDates}
                                                    updateEventDates={this.updateEventDates}
                                                    handleEventInputChange={this.props.changeDateInput}
                                                    deleteEventDate={this.deleteEventDate}
                                                    deletable={false}
                                                /> */}
                                            {/* <DatesComponent
                                                    mode={this.props.dates.mode}
                                                    data={this.props.dates.date}
                                                    fields={this.props.dates.fields}
                                                    saveEventDates={this.saveEventDates}
                                                    updateEventDates={this.updateEventDates}
                                                    handleEventInputChange={this.props.changeDateInput}
                                                    deleteEventDate={this.deleteEventDate}
                                                    deletable={true}
                                                /> */}
                                            <Button className="primary-btn-style" onClick={this.addEventDate}>
                                                Add More Date
                                            </Button>
                                            {` `}
                                            <Button className="primary-btn-style" onClick={this.saveEventDates}>
                                                Save Event Date
                                            </Button>
                                        </TabPane>
                                        {/* On 7 Feb 2019, Eddy & Jaq requested to hide tracks */}
                                        {/* <TabPane tabId="tracks">
                                            <TracksComponent
                                                mode={this.props.tracks.mode}
                                                data={this.props.tracks.tracks}
                                                fields={this.props.tracks.fields}
                                                tempTracks={this.props.tracks.tempTracks}
                                                attendees={this.props.attendees.attendees}
                                                saveEventTracks={this.saveEventTracks}
                                                updateEventTracks={this.updateEventTracks}
                                                saveNewEventTrack={this.props.saveNewEventTrack}
                                                saveSingleEventTrack={this.saveSingleEventTrack}
                                                deleteSingleEventTrack={this.deleteSingleEventTrack}
                                                handleTrackInputChange={this.props.changeTrackInput}
                                            />
                                        </TabPane> */}
                                        <TabPane tabId="tickets">
                                            <TicketsComponent dates={this.props.dates.dates} date={this.props.dates.fields} />
                                        </TabPane>
                                        {/* On 7 Feb 2019, Eddy & Jaq requested to hide material */}
                                        {/* <TabPane tabId="materials">
                                            <MaterialsComponent />
                                        </TabPane> */}
                                        <TabPane tabId="checklist">
                                            {this.state.checklist.mode === 'list' && (
                                                <ChecklistListPage
                                                    id={this.state.checklist.id}
                                                    setMode={this.changeChecklistMode}
                                                    checklist={checklist}
                                                    checklistItems={checklistItems}
                                                />
                                            )}
                                            {this.state.checklist.mode === 'add' && (
                                                <ChecklistAddPage
                                                    eventId={this.props.eventId}
                                                    onSaveNewChecklist={this.saveNewChecklist}
                                                    id={this.state.checklist.id}
                                                    setMode={this.changeChecklistMode}
                                                />
                                            )}
                                        </TabPane>
                                        <TabPane tabId="expenditures">
                                            {this.state.expenditure.mode === 'list' && (
                                                <ExpendituresListPage id={this.state.expenditure.id} setMode={this.changeExpenditureMode} />
                                            )}
                                            {this.state.expenditure.mode === 'add' && (
                                                <ExpendituresAddPage id={this.state.expenditure.id} setMode={this.changeExpenditureMode} />
                                            )}
                                        </TabPane>
                                        <TabPane tabId="analytics">
                                            {this._isMounted ? (
                                                <IndividualEventAnalytics eventId={this.props.match.params.eventid} />
                                            ) : null}
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </div>
                    )}
                </React.Fragment>
            </div>
        );
    }
}

const ButtonComponent = props => {
    const { color, style, label, icon, className, onClick } = props;
    return (
        <Button color={color} className={className} style={style} onClick={onClick}>
            <i className="material-icons align-text-bottom">{icon}</i>
            &nbsp; {label}
        </Button>
    );
};

const mapStateToProps = (state, ownProps) => {
    const eventId = ownProps.match.params.eventid;
    return {
        ...state.eventsAllReducer,
        main: state.eventsCreationReducer,
        tracks: state.eventsTrackReducer,
        dates: state.eventsDateReducer,
        tickets: state.eventsTicketReducer,
        materials: state.eventsMaterialReducer,
        checklist: state.eventsChecklistReducer.checklist,
        checklistItems: state.eventsChecklistReducer.checklistItems,
        expenditures: state.eventsExpenditureReducer,
        attendees: state.eventsAttendeeReducer,
        eventId,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getRelevantStuffs: () => {
            return dispatch(eventGetPaymentMode());
        },
        eventDatesAddDate: () => {
            return dispatch(eventDatesAddDate());
        },
        eventDatesDeleteDate: id => {
            return dispatch(eventDatesDeleteDate(id));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        // Events - Main Details
        getEventDetails: eventid => {
            return dispatch(eventGetEventDetails(eventid));
        },
        saveEventDetails: (edmFileId, registrationPageImageFileId, eventBannerFileId, description, summary, cpd) => {
            return dispatch(eventSaveEventDetails(edmFileId, registrationPageImageFileId, eventBannerFileId, description, summary, cpd));
        },
        deleteEventDetails: eventid => {
            dispatch(eventRemoveEventDetails(eventid));
        },
        updateEventDetails: (eventid, edmFileId, registrationPageImageFileId, eventBannerFileId, description, summary) => {
            dispatch(eventUpdateEventDetails(eventid, edmFileId, registrationPageImageFileId, eventBannerFileId, description, summary));
        },
        eventSetEventId: eventid => {
            dispatch(eventSetEventId(eventid));
        },
        changeEventInput: event => {
            const { id, checked, value, name } = event.target;
            const evaluation = id === 'foodRestrict' || id === 'paymentMode' || id === 'confirmRegRequired' || id.includes('visible');
            dispatch(eventChangeEventInput(evaluation ? checked : value, id, name));
        },
        handleEventSize: (height, width, inputId) => {
            dispatch(eventHandleImageInput(height, width, inputId));
        },
        eventResetEventFields: () => {
            return dispatch(eventResetEventFields());
        },
        setEventModalData: data => {
            dispatch(eventSetModalData(data));
        },
        saveDocumentFile: (contenttype, filename, attachment, inputId) => {
            dispatch(eventSaveDocumentFile(contenttype, filename, attachment, inputId));
        },
        removeDocumentFile: inputId => {
            dispatch(eventRemoveDocumentFile(inputId));
        },
        downloadDocumentFile: inputId => {
            return dispatch(eventDownloadDocumentFile(inputId));
        },
        // Event - Dates
        getEventDates: eventid => {
            dispatch(eventMultipleDatesGetEventDate(eventid));
        },
        saveEventDates: () => {
            dispatch(eventDatesSaveEventDate());
        },
        saveOrUpdateEventDates: () => {
            dispatch(eventDatesSaveOrUpdateEventDate());
        },
        updateEventDates: () => {
            dispatch(eventDatesUpdateEventDate());
        },
        eventResetDatesFields: () => {
            return dispatch(eventDatesResetFields());
        },
        eventDatesSetEventId: eventid => {
            dispatch(eventDatesSetEventId(eventid));
        },
        changeDateInput: (moment, inputid, eventDateId) => {
            dispatch(eventDatesChangeDateInput(moment, inputid, eventDateId));
        },
        setEventDatesModalData: data => {
            dispatch(eventDatesSetModalData(data));
        },
        // Event - Tracks
        eventGetAttendeeList: eventid => {
            return dispatch(eventsAttendeeGetAttendeeList(eventid));
        },
        getEventTracks: eventid => {
            return dispatch(eventGetEventTracks(eventid));
        },
        saveEventTracks: () => {
            return dispatch(eventSaveAllEventTracks());
        },
        updateEventTracks: eventid => {
            return dispatch(eventUpdateAllEventTracks(eventid));
        },
        saveNewEventTrack: () => {
            dispatch(eventSaveNewEventTrack());
        },
        saveSingleEventTrack: inputid => {
            dispatch(eventSaveSingleEventTrack(inputid));
        },
        deleteSingleEventTrack: inputid => {
            dispatch(eventRemoveSingleEventTrack(inputid));
        },
        eventTrackSetEventId: eventid => {
            dispatch(eventTrackSetEventId(eventid));
        },
        changeTrackInput: (event, inputid) => {
            dispatch(eventChangeTrackInput(event.target.value, event.target.id, inputid));
        },
        eventResetTrackFields: () => {
            return dispatch(eventResetTrackFields());
        },
        setEventTracksModalData: data => {
            dispatch(eventTracksSetModalData(data));
        },
        saveNewChecklist: data => dispatch(saveNewChecklist(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(StoredLayout(EventsAllPage)));
