/**
 * Template Edit panel
 *
 * Author: Fernando
 */
import { dialogModalToggle } from 'actions/modal/dialogModalAction';
import {
    getActiveCriteriaSections,
    getAssessmentScoresheet,
    getCategories,
    getCriteriaBySection,
    getSavedItem,
    getSubCategories,
    printCriteria,
    saveAssessment,
    setModalData,
    submitAssessment,
    updateAssessment,
} from 'actions/staff/certification/staffCertAssessmentAction';
import {
    sendResubmitEmail,
    fetchNumberOfTicksBySubIdWithWait,
    getApplicationInfo,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { SGBC_S3_HOST, SGBC_S3_ROOT } from 'config';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, FormGroup, Input, Row } from 'reactstrap';
import CriteriaViewPanel from './CriteriaViewPanel';
import FootNotesComponent from './FootNotesComponent';
// import scrollToComponent from 'react-scroll-to-component';

const uuidv4 = require('uuid/v4');
const generateSubCatLabel = item => `${item.category} > ${item.subcategory} > ${item.type}`;
const generateSubCatLabelForPrinting = item => `${item.category} : ${item.subcategory} : ${item.type}`;

const RatingPanel = props => {
    let ratingValue = 'Not Applicable';
    let ratings = [];
    if (props.type == 'product') {
        if (props.finalRating && props.finalRating.length > 0) {
            for (var i = 0; i < props.finalRating.length; i++) {
                ratings.push(props.finalRating[i].id + ' : ' + props.finalRating[i].finalRating + ' tick(s)');
            }
        }
    } else {
        if (props.finalRating && props.finalRating.length > 0 && props.finalRating[0].finalRating && props.finalRating[0].finalRating > 4) {
            ratings.push('L' + parseInt(props.finalRating[0].finalRating - 4));
        }
    }
    return (
        <div>
            <span>
                <b style={{ fontSize: '20px', color: '#317446' }}>Rating:</b> <br />
                {ratings &&
                    ratings.length > 0 &&
                    ratings.map((e, i) => (
                        <React.Fragment key={i}>
                            {' '}
                            <span key={i}>{e}</span>
                            <br />
                        </React.Fragment>
                    ))}
                {!ratings || (ratings.length <= 0 && <span>Not Applicable</span>)}
            </span>
            <br />
            <i>Rating Calculation might take a while. Please refresh your browser if you do not see the updated rating.</i>
            <br />
            <br />
        </div>
    );
};

class ScoresheetView extends React.PureComponent {
    constructor(props) {
        super(props);
        this.toggleModalAlert = this.toggleModalAlert.bind(this);
        this.toggleModalSave = this.toggleModalSave.bind(this);
        this.toggleModalSubmit = this.toggleModalSubmit.bind(this);
        this.saveAssessment = this.saveAssessment.bind(this);
        this.submitAssessment = this.submitAssessment.bind(this);
        this.handleChangeCodeTitle = this.handleChangeCodeTitle.bind(this);
        this.initialLoadGetSubCategories = this.initialLoadGetSubCategories.bind(this);
        this.clearModalState = this.clearModalState.bind(this);
        this.getCriteriaBySection = this.getCriteriaBySection.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getActiveCriteriaSections = this.getActiveCriteriaSections.bind(this);
        this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this);
        this.handleChangeCategory = this.handleChangeCategory.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.updatedCriteriaItemStatus = this.updatedCriteriaItemStatus.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.save = this.save.bind(this);
        this.submit = this.submit.bind(this);
        this.updateApproveReject = this.updateApproveReject.bind(this);
        this.printCriteria = this.printCriteria.bind(this);
        this.toggleModalSubmitDoc = this.toggleModalSubmitDoc.bind(this);
        this.productSectionAChange = this.productSectionAChange.bind(this);
        this.productSectionBChange = this.productSectionBChange.bind(this);
        this.productSectionCChange = this.productSectionCChange.bind(this);
        this.serviceSectionAChange = this.serviceSectionAChange.bind(this);
        this.serviceSectionBChange = this.serviceSectionBChange.bind(this);
        this.serviceSectionCChange = this.serviceSectionCChange.bind(this);
        this.onFootNotesFieldChange = this.onFootNotesFieldChange.bind(this);

        this.onRemarksChange = this.onRemarksChange.bind(this);
        this.onAddDoc = this.onAddDoc.bind(this);
        this.onDeleteDoc = this.onDeleteDoc.bind(this);
        this.onDocChange = this.onDocChange.bind(this);

        this.updateRecordRemarks = this.updateRecordRemarks.bind(this);
        this.onRecordRemarksChanged = this.onRecordRemarksChanged.bind(this);
        this.reassess = this.reassess.bind(this);

        this.state = {
            id: null,
            finalRating: 1,
            templateId: null,
            loading: false,
            loadPrinting: false,
            categories: [],
            subCategories: [],
            selectedCategory: null,
            selectedSubCategory: null,
            modalShow: false,
            existingSectionA: [],
            savedSectionA: [],
            existingSectionB: [],
            savedSectionB: [],
            existingSectionC: [],
            savedSectionC: [],
            existingSectionAService: [],
            savedSectionAService: [],
            existingSectionBService: [],
            savedSectionBService: [],
            existingSectionCService: [],
            savedSectionCService: [],

            updatedRequirementId: null, // Added by Fernando on 25 Sep 2019, to support auto-scroll to the requirement upon saved

            ratingRule: {
                good: {
                    critical: 1,
                    supplementary: 1,
                },
                veryGood: {
                    critical: 2,
                    supplementary: 3,
                },
                excellent: {
                    critical: 3,
                    supplementary: 5,
                },
                leader: {
                    critical: 4,
                    supplementary: 7,
                },
            },
        };
    }
    getRequirementObj(requirementId) {
        var requirementObj = null;
        var stateFieldName = null;
        var sectionObj = null;

        let savedSectionA = Object.assign([], this.state.savedSectionA);
        let savedSectionB = Object.assign([], this.state.savedSectionB);
        let savedSectionC = Object.assign([], this.state.savedSectionC);
        let savedSectionAService = Object.assign([], this.state.savedSectionAService);
        let savedSectionBService = Object.assign([], this.state.savedSectionBService);
        let savedSectionCService = Object.assign([], this.state.savedSectionCService);
        let savedSectionCServiceMinimumCriteria = Object.assign({}, this.state.savedSectionCServiceMinimumCriteria);

        var requirementAObj = savedSectionA.find(requirement => requirement.id == requirementId);
        var requirementBObj = savedSectionB.find(requirement => requirement.id == requirementId);
        var requirementCObj = savedSectionC.find(requirement => requirement.id == requirementId);
        var requirementAServiceObj = savedSectionAService.find(requirement => requirement.id == requirementId);
        var requirementBServiceObj = savedSectionBService.find(requirement => requirement.id == requirementId);
        var requirementCServiceObj = savedSectionCService.find(requirement => requirement.id == requirementId);

        if (requirementAObj != null) {
            requirementObj = requirementAObj;
            stateFieldName = 'savedSectionA';
            sectionObj = savedSectionA;
        } else if (requirementBObj != null) {
            requirementObj = requirementBObj;
            stateFieldName = 'savedSectionB';
            sectionObj = savedSectionB;
        } else if (requirementCObj != null) {
            requirementObj = requirementCObj;
            stateFieldName = 'savedSectionC';
            sectionObj = savedSectionC;
        }
        if (requirementAServiceObj != null) {
            requirementObj = requirementAServiceObj;
            stateFieldName = 'savedSectionAService';
            sectionObj = savedSectionAService;
        } else if (requirementBServiceObj != null) {
            requirementObj = requirementBServiceObj;
            stateFieldName = 'savedSectionBService';
            sectionObj = savedSectionBService;
        } else if (requirementCServiceObj != null) {
            requirementObj = requirementCServiceObj;
            stateFieldName = 'savedSectionCService';
            sectionObj = savedSectionCService;
        }
        return {
            requirementObj,
            stateFieldName,
            sectionObj,
        };
    }
    printCriteria() {
        this.setState({
            loadPrinting: true,
        });
        this.props
            .printCriteria(this.props.templateId, this.props.type, this.props.type == 'product' ? '' : this.state.selectedCategory.label)
            .then(res => {
                this.setState({
                    loadPrinting: false,
                });
                window.open(SGBC_S3_HOST + '/temp/' + res.file);
            });
    }
    updateAuditTrail(message, trail) {
        if (trail == null) {
            trail = [];
        }
        if (message == null) {
            message = '';
        }

        trail.unshift({
            timestamp: moment().format('DD/MM/YYYY HH:mm'),
            actor: this.props.profile.user.email,
            message: message,
        });
        return trail;
    }
    onRemarksChange(requirementId, value) {
        let requirementWrapper = this.getRequirementObj(requirementId);
        requirementWrapper.requirementObj.remarks = value;
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var sectionObj = requirementWrapper.sectionObj;
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
    }
    onRecordRemarksChanged(requirementId, value) {
        let requirementWrapper = this.getRequirementObj(requirementId);
        requirementWrapper.requirementObj.internalNotes = value;
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var sectionObj = requirementWrapper.sectionObj;
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
    }
    onAddDoc(requirementId) {
        let requirementWrapper = this.getRequirementObj(requirementId);

        if (requirementWrapper.requirementObj.documents == null) {
            requirementWrapper.requirementObj.documents = [];
        }
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var id = uuidv4();
        requirementWrapper.requirementObj.documents.push({
            id,
            resourceId: null,
            filename: null,
        });
        var sectionObj = requirementWrapper.sectionObj;
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
    }
    onDeleteDoc(requirementId, docId) {
        let requirementWrapper = this.getRequirementObj(requirementId);

        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var newDocs = requirementWrapper.requirementObj.documents.filter((value, index, arr) => {
            return value.id != docId;
        });
        requirementWrapper.requirementObj.documents = newDocs;
        var sectionObj = requirementWrapper.sectionObj;
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
    }
    onDocChange(requirementId, docId, resourceId, filename) {
        let requirementWrapper = this.getRequirementObj(requirementId);
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var docToUpdate = requirementWrapper.requirementObj.documents.find(document => {
            return document.id == docId;
        });
        docToUpdate.resourceId = resourceId;
        docToUpdate.filename = filename;
        var sectionObj = requirementWrapper.sectionObj;
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
    }
    save(requirementId) {
        let { requirementObj, sectionObj, stateFieldName } = this.getRequirementObj(requirementId);
        requirementObj.status = 'Saved';

        // This is required, to force re-render the children components
        requirementObj.render = new Date().getTime();
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });

        this.saveOrSubmit(requirementObj.status);
    }
    updateRecordRemarks(requirementId) {
        let requirementWrapper = this.getRequirementObj(requirementId);
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        // var sectionObj = requirementWrapper.sectionObj;

        // if (requirementWrapper.requirementObj.internalNtes != null && requirementWrapper.requirementObj.remarks != '') {
        //     action = action + '\nRemarks: ' + requirementWrapper.requirementObj.remarks;
        // }
        // requirementWrapper.requirementObj.trail = this.updateAuditTrail(action, requirementWrapper.requirementObj.trail);
        // this.setState({
        //     sectionObj,
        // });
        this.saveOrSubmit(requirementWrapper.requirementObj.status);
    }
    submit(requirementId) {
        let { requirementObj, sectionObj, stateFieldName } = this.getRequirementObj(requirementId);
        requirementObj.status = 'Submitted';

        // This is required, to force re-render the children components
        requirementObj.render = new Date().getTime();
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });

        let action = 'Submit document';
        if (requirementObj.remarks) action += '\nRemarks: ' + requirementObj.remarks;
        requirementObj.trail = this.updateAuditTrail(action, requirementObj.trail);
        this.saveOrSubmit(requirementObj.status);
    }
    // submit(requirementId) {
    //     this.submitDoc(requirementId);
    // }
    approve() {
        const { requirementId } = this.state;
        let requirementWrapper = this.getRequirementObj(requirementId);
        requirementWrapper.requirementObj.status = 'Accepted';
        // This is required, to force re-render the children components
        requirementWrapper.requirementObj.render = new Date().getTime();
        var sectionObj = requirementWrapper.sectionObj;
        var ratingObj = document.getElementsByName(`rating-${requirementId}`);
        let ratingVal = [];
        if (ratingObj && ratingObj.length > 0) {
            for (var i = 0; i < ratingObj.length; i++) {
                let e = ratingObj[i];
                let val = e.value;
                let id = e.id.replace(e.name + '-', '');
                ratingVal.push({ id, val });
            }
            requirementWrapper.requirementObj.rating = ratingVal;
        }

        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });

        requirementWrapper.requirementObj.trail = this.updateAuditTrail('Accept document', requirementWrapper.requirementObj.trail);
        this.updateApproveReject(requirementWrapper.requirementObj.status);
    }
    reject() {
        const { requirementId, rejectionRemark, rejectRequirementId, rejectSectionNumber } = this.state;
        // if (!rejectionRemark) {
        //     this.toggleModalAlert('Warning', 'Please provide your rejection remark');
        //     return;
        // }

        let { requirementObj, sectionObj, stateFieldName } = this.getRequirementObj(requirementId);
        requirementObj.status = 'Rejected';
        requirementObj.reason = rejectionRemark;
        // This is required, to force re-render the children components
        requirementObj.render = new Date().getTime();
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
        requirementObj.trail = this.updateAuditTrail('Resubmit document\nRemarks: ' + rejectionRemark, requirementObj.trail);
        this.updateApproveReject(requirementObj.status);
    }
    reassess(requirementId) {
        let { requirementObj, sectionObj, stateFieldName } = this.getRequirementObj(requirementId);
        requirementObj.status = 'Submitted';
        // This is required, to force re-render the children components
        requirementObj.render = new Date().getTime();
        this.setState({
            sectionObj,
            updatedRequirementId: requirementId,
        });
        // requirementObj.trail = this.updateAuditTrail('Resubmit document\nRemarks: ' + rejectionRemark, requirementObj.trail);
        this.updateApproveReject(requirementObj.status);
    }
    onFootNotesFieldChange(field, subField, value) {
        var ratingRule = Object.assign({}, this.state.ratingRule);
        var fieldObj = Object.assign({}, ratingRule[field]);
        fieldObj[subField] = value;
        ratingRule[field] = fieldObj;
        this.setState({ ratingRule });
    }
    productSectionAChange(selectedItems) {
        this.setState({ savedSectionA: selectedItems });
    }
    productSectionBChange(selectedItems) {
        this.setState({ savedSectionB: selectedItems });
    }
    productSectionCChange(selectedItems) {
        this.setState({ savedSectionC: selectedItems });
    }
    serviceSectionAChange(selectedItems) {
        this.setState({ savedSectionAService: selectedItems });
    }
    serviceSectionBChange(selectedItems) {
        this.setState({ savedSectionBService: selectedItems });
    }
    serviceSectionCChange(selectedItems) {
        this.setState({ savedSectionCService: selectedItems });
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (this.props.scoresheetId == 0) {
            this.getInitialRelevantData();
        } else {
            this.loadAssessmentScoresheet();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    loadAssessmentScoresheet() {
        this.showLoading(true);
        Promise.all([
            this.getCategories(),
            this.props.getAssessmentScoresheet(this.props.scoresheetId),
            this.props.fetchNumberOfTicksBySubId(this.props.applicationId),
            this.props.getApplicationInfo(this.props.applicationId),
        ]).then(res => {
            const savedItem = res[1].data;
            const finalRating = res[2].data;
            const applicationInfo = res[3].data;
            let criteria = JSON.parse(savedItem.criteria);
            let selectedCategory = null;
            if (savedItem != null && savedItem.type == 'product') {
                // product
                const existingCategory = res[0].data.find(item => item.id == savedItem.productCategoryId);
                if (existingCategory) {
                    selectedCategory = {
                        value: existingCategory.id,
                        label: existingCategory.category,
                    };
                    this.initialLoadGetSubCategories(selectedCategory, existingCategory.id, savedItem);
                } else {
                    if (this._isMounted) {
                        this.setState({
                            loading: false,
                            title: savedItem.title,
                            selectedCategory,
                        });
                    }
                }
                // Set sections and rules
                if (this._isMounted) {
                    this.setState({
                        templateId: parseInt(this.props.templateId),
                        id: parseInt(this.props.scoresheetId),
                        ratingRule: criteria.ratingRule,
                        status: savedItem.status,
                        savedSectionA: criteria.savedSectionA,
                        savedSectionB: criteria.savedSectionB,
                        savedSectionC: criteria.savedSectionC,
                        attachment: savedItem.attachment,
                        updatedOn: savedItem.updatedOn,
                        finalRating,
                        applicationInfo,
                    });
                }
            } else {
                // service
                const existingCategory = res[0].data.find(item => item.id == savedItem.serviceCategoryId);
                if (existingCategory) {
                    selectedCategory = {
                        value: existingCategory.id,
                        label: existingCategory.category,
                    };
                }
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        templateId: parseInt(this.props.templateId),
                        id: parseInt(this.props.scoresheetId),
                        title: savedItem.title,
                        status: savedItem.status,
                        selectedCategory,
                        savedSectionAService: criteria.savedSectionAService,
                        savedSectionBService: criteria.savedSectionBService,
                        savedSectionCService: criteria.savedSectionCService,
                        savedSectionCServiceMinimumCriteria: criteria.savedSectionCServiceMinimumCriteria || 5,
                        attachment: savedItem.attachment,
                        finalRating,
                        updatedOn: savedItem.updatedOn,
                    });
                }
            }
        });
    }
    getInitialRelevantData() {
        this.showLoading(true);
        Promise.all([
            this.getCategories(),
            this.getActiveCriteriaSections(this.props.type),
            this.getSavedItem(),
            this.props.getCriteriaBySection(1),
            this.props.getCriteriaBySection(2),
            this.props.getCriteriaBySection(3),
            this.props.getCriteriaBySection(4),
            this.props.getCriteriaBySection(5),
            this.props.getCriteriaBySection(6),
        ]).then(res => {
            let productSectionACriterias = res[3].data;
            let productSectionBCriterias = res[4].data;
            let productSectionCCriterias = res[5].data;
            let serviceSectionACriterias = res[6].data;
            let serviceSectionBCriterias = res[7].data;
            let serviceSectionCCriterias = res[8].data;
            if (this._isMounted) {
                this.setState({
                    existingSectionA: productSectionACriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                    existingSectionB: productSectionBCriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                    existingSectionC: productSectionCCriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                    existingSectionAService: serviceSectionACriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                    existingSectionBService: serviceSectionBCriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                    existingSectionCService: serviceSectionCCriterias.map(criteria => {
                        return {
                            id: criteria.criteriaCode,
                            label: criteria.criteria,
                        };
                    }),
                });
            }

            if (res[2]) {
                const savedItem = res[2].data;
                let selectedCategory = null;
                if (savedItem.type == 'product') {
                    // product
                    const existingCategory = res[0].data.find(item => item.id == savedItem.productCategoryId);
                    if (existingCategory) {
                        selectedCategory = {
                            value: existingCategory.id,
                            label: existingCategory.category,
                        };
                        this.initialLoadGetSubCategories(selectedCategory, existingCategory.id, savedItem);
                    } else {
                        if (this._isMounted) {
                            this.setState({
                                loading: false,
                                title: savedItem.title,
                                selectedCategory,
                            });
                        }
                    }
                    // Set sections and rules
                    if (this._isMounted) {
                        this.setState({
                            templateId: parseInt(this.props.templateId),
                            id: parseInt(this.props.scoresheetId),
                            ratingRule: savedItem.criteria.ratingRule,
                            savedSectionA: savedItem.criteria.savedSectionA,
                            savedSectionB: savedItem.criteria.savedSectionB,
                            savedSectionC: savedItem.criteria.savedSectionC,
                            attachment: savedItem.attachment,
                        });
                    }
                } else {
                    // service
                    const existingCategory = res[0].data.find(item => item.id == savedItem.serviceCategoryId);
                    if (existingCategory) {
                        selectedCategory = {
                            value: existingCategory.id,
                            label: existingCategory.category,
                        };
                    }
                    if (this._isMounted) {
                        this.setState({
                            loading: false,
                            templateId: parseInt(this.props.templateId),
                            id: parseInt(this.props.scoresheetId),
                            title: savedItem.title,
                            selectedCategory,
                            savedSectionAService: savedItem.criteria.savedSectionAService,
                            savedSectionBService: savedItem.criteria.savedSectionBService,
                            savedSectionCService: savedItem.criteria.savedSectionCService,
                            savedSectionCServiceMinimumCriteria: savedItem.criteria.savedSectionCServiceMinimumCriteria || 5,
                            attachment: savedItem.attachment,
                        });
                    }
                }
            } else {
                this.showLoading(false);
            }
        });
    }
    initialLoadGetSubCategories(selectedCategory, catId, currentData) {
        /**
         * this only happens for product as services do not have subcats?
         * props get passed here so setState only needs to happen once
         */
        this.getSubCategories(catId).then(res => {
            const ssc = res.data.find(item => item.id == currentData.productTypeId) || null;
            if (this._isMounted) {
                this.setState({
                    selectedCategory,
                    selectedSubCategory: ssc && {
                        value: ssc.id,
                        label: generateSubCatLabelForPrinting(ssc),
                    },
                    loading: false,
                    title: currentData.title,
                });
            }
        });
    }
    getSavedItem() {
        if (this.props.templateId) return this.props.getSavedItem(this.props.templateId);
        return Promise.resolve();
    }
    getActiveCriteriaSections(type) {
        return this.props.getActiveCriteriaSections(type).then(data => {
            if (this.state.modalSelectedSection == null) {
                this.getCriteriaBySection(data.data[0].id);
            }
            if (this._isMounted) {
                this.setState({
                    modalSections: data.data,
                    modalSelectedSection: data.data[0],
                });
            }
            return data;
        });
    }
    getCategories() {
        return this.props.getCategories(this.props.type).then(data => {
            const cats = data.data.map(item => ({
                value: item.id,
                label: item.category,
            }));
            if (this._isMounted) {
                this.setState({
                    categories: cats,
                });
            }
            return data;
        });
    }
    getSubCategories(categoryid) {
        return this.props.getSubCategories(categoryid).then(data => {
            const subcats = data.data.map(item => {
                const st = generateSubCatLabel(item);
                return {
                    value: item.id,
                    label: st,
                };
            });
            this.setState({
                subCategories: subcats,
            });
            return data;
        });
    }
    // because code == title
    handleChangeCodeTitle(e) {
        this.setState({ title: e.target.value });
    }
    handleChangeCategory(e) {
        // dont need to do anything if same option is selected
        if (this.state.selectedCategory !== null && e.value == this.state.selectedCategory.value) return;
        const isService = this.props.type == 'service';
        if (!isService) {
            // only get if type == product
            this.getSubCategories(e.value);
        }

        const { currentData } = this.state;
        const catIdObj = isService ? { serviceCategoryId: e.value } : { productCategoryId: e.value };

        const newCurrentData = Object.assign({}, currentData, catIdObj);
        this.setState({
            currentData: newCurrentData,
            selectedCategory: e,
            selectedSubCategory: null,
        });
    }
    getCriteriaBySection(sectionid) {
        this.props.getCriteriaBySection(sectionid).then(res => {
            if (this._isMounted) {
                this.setState({
                    modalCriteria: res.data ? res.data : [],
                    modalSelectedCriteria: res.data ? res.data[0] : null,
                });
            }
        });
    }
    handleChangeSection(e) {
        this.setState({
            modalSelectedSection: e,
            modalSelectedCriteria: null,
        });
        this.getCriteriaBySection(e.id);
    }
    handleChangeSubCategory(e) {
        const { currentData } = this.state;
        this.setState({
            selectedSubCategory: e,
            currentData: Object.assign({}, currentData, {
                productTypeId: e.value,
            }),
        });
    }
    clearModalState() {
        this.setState({
            modalSelectedSection: null,
            modalSelectedCriteria: null,
            modalCriteria: [],
            modalRequiredDocuments: '',
            modalDocumentsToSubmit: '',
        });
    }
    toggleModal(modalType, modalRatings, criteriaIndex) {
        if (this.state.modalShow == true) this.clearModalState();
        this.setState({
            modalRatingArray: modalRatings || [],
            modalShow: !this.state.modalShow,
            criteriaIndex,
        });
        // to prevent flash
        setTimeout(() => {
            this.setState({ modalType: modalType || 'criteria' });
        }, 400);
    }
    updatedCriteriaItemStatus(section, status) {
        // console.log(section)
        section.forEach(item => {
            if (item.status == null || item.status == 'Saved' || item.status == 'Rejected') {
                if (item.documents != null && item.documents.length > 0) {
                    item.status = status;
                }
            }
        });
        return section;
    }
    async updateApproveReject(status) {
        // this.props.dialogModalToggle();
        const { rejectRequirementId, rejectSectionNumber } = this.state;
        const { type } = this.props;
        const postBody = {
            id: this.state.id,
            templateId: this.state.templateId,
            productCategoryId: this.state.selectedCategory != null ? this.state.selectedCategory.value : null,
            productTypeId: this.state.selectedSubCategory != null ? this.state.selectedSubCategory.value : null,
            title: this.state.title,
            updatedOn: this.state.updatedOn,
            criteria: {
                savedSectionA: this.state.savedSectionA,
                savedSectionB: this.state.savedSectionB,
                savedSectionC: this.state.savedSectionC,
                savedSectionAService: this.state.savedSectionAService,
                savedSectionBService: this.state.savedSectionBService,
                savedSectionCService: this.state.savedSectionCService,
                savedSectionCServiceMinimumCriteria: this.state.savedSectionCServiceMinimumCriteria,
                ratingRule: this.state.ratingRule,
            },
            type,
        };
        if (status == 'Accepted' || status == 'Submitted') {
            let res = await this.props.updateAssessment(postBody, type);
            if (res.error) {
                if (res.error.sqlState && res.error.sqlState == '45000') {
                    this.toggleModalAlert('Error', 'There is an update of this assessment. The assessment content has been refreshed.');
                } else {
                    this.toggleModalAlert('Error', 'Assessment update has failed');
                }
            }
        } else {
            let res = await this.props.saveAssessment(postBody, type, true, parseInt(this.props.applicationId));
            if (res.error) {
                if (res.error.sqlState && res.error.sqlState == '45000') {
                    this.toggleModalAlert('Error', 'There is an update of this assessment. The assessment content has been refreshed.');
                } else {
                    this.toggleModalAlert('Error', 'Assessment update has failed');
                }
            } else {
                // Disable send email for now by Fernando 2 Sep 2019, as SGBC did not want to send to applicant for time being
                // let res1 = await this.props.sendResubmitEmail(rejectRequirementId, rejectSectionNumber, parseInt(this.props.applicationId));
            }
        }
        // this.toggleModalAlert('Confirmation', 'Scoresheet has been successfully saved');
        // Force to re-render
        this.componentDidMount();
    }

    async saveOrSubmit(status) {
        const { type } = this.props;
        const postBody = {
            id: this.state.id,
            templateId: this.state.templateId,
            productCategoryId: this.state.selectedCategory != null ? this.state.selectedCategory.value : null,
            productTypeId: this.state.selectedSubCategory != null ? this.state.selectedSubCategory.value : null,
            title: this.state.title,
            updatedOn: this.state.updatedOn,
            criteria: {
                savedSectionA: this.state.savedSectionA,
                savedSectionB: this.state.savedSectionB,
                savedSectionC: this.state.savedSectionC,
                savedSectionAService: this.state.savedSectionAService,
                savedSectionBService: this.state.savedSectionBService,
                savedSectionCService: this.state.savedSectionCService,
                savedSectionCServiceMinimumCriteria: this.state.savedSectionCServiceMinimumCriteria,
                ratingRule: this.state.ratingRule,
            },
            type,
        };
        let res = await this.props.saveAssessment(postBody, type);
        if (res.error) {
            if (res.error.sqlState && res.error.sqlState == '45000') {
                this.toggleModalAlert('Error', 'There is an update of this assessment. The assessment content has been refreshed.');
            } else {
                this.toggleModalAlert('Error', 'Assessment update has failed');
            }
        } else {
            this.toggleModalAlert('Confirmation', 'Scoresheet has been successfully saved');
        }
        this.componentDidMount();
    }

    async saveAssessment() {
        this.props.dialogModalToggle();
        const { type } = this.props;
        const postBody = {
            id: this.state.id,
            templateId: this.state.templateId,
            productCategoryId: this.state.selectedCategory != null ? this.state.selectedCategory.value : null,
            productTypeId: this.state.selectedSubCategory != null ? this.state.selectedSubCategory.value : null,
            title: this.state.title,
            updatedOn: this.state.updatedOn,
            criteria: {
                savedSectionA: this.updatedCriteriaItemStatus(this.state.savedSectionA, 'Saved'),
                savedSectionB: this.updatedCriteriaItemStatus(this.state.savedSectionB, 'Saved'),
                savedSectionC: this.updatedCriteriaItemStatus(this.state.savedSectionC, 'Saved'),
                savedSectionAService: this.updatedCriteriaItemStatus(this.state.savedSectionAService, 'Saved'),
                savedSectionBService: this.updatedCriteriaItemStatus(this.state.savedSectionBService, 'Saved'),
                savedSectionCService: this.updatedCriteriaItemStatus(this.state.savedSectionCService, 'Saved'),
                savedSectionCServiceMinimumCriteria: this.state.savedSectionCServiceMinimumCriteria,
                ratingRule: this.state.ratingRule,
            },
            type,
        };
        let res = await this.props.saveAssessment(postBody, type);
        // console.log("res: ", res)
        if (res.error) {
            if (res.error.sqlState && res.error.sqlState == '45000') {
                this.toggleModalAlert('Error', 'There is an update of this assessment. The assessment content has been refreshed.');
            } else {
                this.toggleModalAlert('Error', 'Assessment update has failed');
            }
        } else {
            this.toggleModalAlert('Confirmation', 'Scoresheet has been successfully saved');
        }
        this.componentDidMount();
    }
    async submitAssessment() {
        this.props.dialogModalToggle();
        const { type } = this.props;
        const postBody = {
            id: this.state.id,
            templateId: this.state.templateId,
            productCategoryId: this.state.selectedCategory != null ? this.state.selectedCategory.value : null,
            productTypeId: this.state.selectedSubCategory != null ? this.state.selectedSubCategory.value : null,
            title: this.state.title,
            updatedOn: this.state.updatedOn,
            criteria: {
                savedSectionA: this.updatedCriteriaItemStatus(this.state.savedSectionA, 'Submitted'),
                savedSectionB: this.updatedCriteriaItemStatus(this.state.savedSectionB, 'Submitted'),
                savedSectionC: this.updatedCriteriaItemStatus(this.state.savedSectionC, 'Submitted'),
                savedSectionAService: this.updatedCriteriaItemStatus(this.state.savedSectionAService, 'Submitted'),
                savedSectionBService: this.updatedCriteriaItemStatus(this.state.savedSectionBService, 'Submitted'),
                savedSectionCService: this.updatedCriteriaItemStatus(this.state.savedSectionCService, 'Submitted'),
                savedSectionCServiceMinimumCriteria: this.state.savedSectionCServiceMinimumCriteria,
                ratingRule: this.state.ratingRule,
            },
            type,
        };
        let res = await this.props.submitAssessment(postBody, type);

        if (res.error) {
            if (res.error.sqlState && res.error.sqlState == '45000') {
                this.toggleModalAlert('Error', 'There is an update of this assessment. The assessment content has been refreshed.');
            } else {
                this.toggleModalAlert('Error', 'Assessment update has failed');
            }
        } else {
            this.toggleModalAlert('Confirmation', 'Scoresheet has been successfully submitted');
        }
        this.componentDidMount();
    }
    approveRejectConfirm = (isApprove, action) => {
        const verb = isApprove ? 'accept' : 'reject';
        const message = isApprove ? `Click OK to ${verb} the submitted document.` : `To resubmit document, click OK.`;
        this.toggleModalConfirm('Confirmation', message, action);
    };
    promptRejectionRemark = () => {
        const RemarkComponent = (
            <Row>
                <Col>
                    <p>Please specify reasons for rejection:</p>
                    <Input type="textarea" onChange={e => this.setState({ rejectionRemark: e.target.value })} />
                </Col>
            </Row>
        );
        this.toggleModalConfirm('Request', RemarkComponent, this.reject);
    };
    toggleModalSave() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.saveAssessment(),
            modalBody: 'Are you sure you want to save this assessment?',
        });
    }
    toggleModalConfirm = (header, body, action) => {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => {
                action();
                this.props.dialogModalToggle(); // Turn off dialog modal confirm
            },
            modalHeader: header,
            modalBody: body,
        });
    };
    toggleModalAlert(header, message) {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalOnlyOneButton: true,
            modalHeader: header,
            modalBody: message,
        });
    }
    toggleModalSubmit() {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => this.submitAssessment(),
            modalBody: 'Are you sure you want to submit this assessment?',
        });
    }
    toggleModalSubmitDoc(requirementId) {
        this.props.dialogModalToggle();
        this.props.setModalData({
            modalAction: () => {
                this.props.dialogModalToggle();
                this.submit(requirementId);
            },
            modalBody: 'Are you sure you want submit your document?',
        });
    }
    getSectionBInstruction() {
        const { categoryId } = this.props;
        switch (categoryId) {
            case 2: // EPC Chiller Plan Retroit
            case 7: // Green Facilities Management
            case 8: // EPC Air-Conditioning System Maintenance
            case 10: // EPC Lighting Retrofit
                return 'The final SGBS Rating will be derived from all submissions.';
            case 1: // Architecture
            case 3: // Environment Sustainability Design
            case 4: // Mechanical & Electrical
            case 5: // Quantity Surveyor
            default:
                return 'A minimum of 5 criteria under this category must be submitted for evaluation.';
        }
    }
    render() {
        let sectionNumber = 0;
        let sectionCount = 0;
        if (this.props.type == 'service') {
            const { savedSectionAService, savedSectionBService, savedSectionCService } = this.state;
            if (savedSectionAService.length > 0) sectionCount++;
            if (savedSectionBService.length > 0) sectionCount++;
            if (savedSectionCService.length > 0) sectionCount++;
        } else {
            const { savedSectionA, savedSectionB, savedSectionC } = this.state;
            if (savedSectionA.length > 0) sectionCount++;
            if (savedSectionB.length > 0) sectionCount++;
            if (savedSectionC.length > 0) sectionCount++;
        }

        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <div>
                <DialogModal
                    forceModalAction={this.props.forceModalAction}
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                {!this.props.hidePrint && (
                    <Row className="mb-2">
                        <Col xs={12} className="text-right">
                            {this.state.loadPrinting ? (
                                <div style={{ width: '20px', float: 'right', marginRight: '50px' }}>
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <Button
                                    className="ml-auto mr-2 primary-btn-style"
                                    onClick={() => this.printCriteria()}
                                    style={{ position: 'relative', top: this.props.adjustPrintButton ? '-33px' : '0px' }}
                                >
                                    <i className="mr-1 material-icons align-text-bottom ">print</i>
                                    &nbsp;Print
                                </Button>
                            )}
                        </Col>
                    </Row>
                )}
                <WriteUpPanel
                    showInstruction={this.props.showInstruction}
                    sectionBInstruction={this.getSectionBInstruction()}
                    attachment={this.state.attachment}
                    selectedSubCategory={this.state.selectedSubCategory}
                    type={this.props.type}
                    savedSectionBService={this.state.savedSectionBService}
                    savedSectionCServiceMinimumCriteria={this.state.savedSectionCServiceMinimumCriteria}
                />
                {this.props.staff && !this.props.preview && <RatingPanel {...this.state} type={this.props.type} />}
                {this.props.type == 'service' && (
                    <Fragment>
                        {this.state.savedSectionAService.length > 0 && (
                            <Row
                                style={{
                                    paddingTop: '10px',
                                    paddingRight: '20px',
                                    borderTop: '2px solid',
                                    borderTopColor: '#aaa',
                                    borderBottom: '2px solid',
                                    borderBottomColor: '#aaa',
                                }}
                            >
                                <Col xs={12}>
                                    {sectionCount > 1 && (
                                        <label>
                                            <b>Section {++sectionNumber}</b>
                                        </label>
                                    )}
                                    <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                    {this.state.savedSectionAService.length > 0 &&
                                        this.state.savedSectionAService.map((requirement, id) => {
                                            return (
                                                <CriteriaViewPanel
                                                    key={`requirement-${requirement.id}`}
                                                    render={requirement.render}
                                                    section={'A'}
                                                    requirementId={requirement.id}
                                                    updatedRequirementId={this.state.updatedRequirementId}
                                                    requirement={requirement}
                                                    id={id}
                                                    onRemarksChange={this.onRemarksChange}
                                                    onAddDoc={this.onAddDoc}
                                                    onDeleteDoc={this.onDeleteDoc}
                                                    onDocChange={this.onDocChange}
                                                    approve={requirementId => {
                                                        this.setState({ requirementId }, () =>
                                                            this.approveRejectConfirm(true, this.approve),
                                                        );
                                                    }}
                                                    reject={requirementId => {
                                                        this.setState(
                                                            {
                                                                requirementId,
                                                                rejectRequirementId: id + 1,
                                                                rejectSectionNumber: sectionNumber,
                                                            },
                                                            () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                        );
                                                    }}
                                                    status={this.state.status}
                                                    staff={this.props.staff}
                                                    closed={this.props.closed}
                                                    save={this.save}
                                                    submit={this.toggleModalSubmitDoc}
                                                    section={'SectionA'}
                                                    toggleModalAlert={this.toggleModalAlert}
                                                    preview={this.props.preview}
                                                    reviewMode={this.props.reviewMode}
                                                    onRecordRemarksChanged={this.onRecordRemarksChanged}
                                                    updateRecordRemarks={this.updateRecordRemarks}
                                                    reassess={this.reassess}
                                                />
                                            );
                                        })}
                                </Col>
                            </Row>
                        )}

                        {this.state.savedSectionBService.length > 0 && (
                            <Row
                                style={{
                                    paddingRight: '20px',
                                    paddingTop: '10px',
                                }}
                            >
                                <Col xs={12}>
                                    {sectionCount > 1 && (
                                        <label>
                                            <b>Section {++sectionNumber}</b>
                                        </label>
                                    )}
                                    {this.state.savedSectionBService.length > 0 && (
                                        <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                    )}
                                    {this.state.savedSectionBService.length > 0 &&
                                        this.state.savedSectionBService.map((requirement, id) => (
                                            <CriteriaViewPanel
                                                key={`requirement-${requirement.id}`}
                                                render={requirement.render}
                                                section={'B'}
                                                requirement={requirement}
                                                id={id}
                                                requirementId={requirement.id}
                                                updatedRequirementId={this.state.updatedRequirementId}
                                                onRemarksChange={this.onRemarksChange}
                                                onAddDoc={this.onAddDoc}
                                                onDeleteDoc={this.onDeleteDoc}
                                                onDocChange={this.onDocChange}
                                                approve={requirementId => {
                                                    this.setState({ requirementId }, () => this.approveRejectConfirm(true, this.approve));
                                                }}
                                                reject={requirementId => {
                                                    this.setState(
                                                        { requirementId, rejectRequirementId: id + 1, rejectSectionNumber: sectionNumber },
                                                        () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                    );
                                                }}
                                                status={this.state.status}
                                                closed={this.props.closed}
                                                staff={this.props.staff}
                                                save={this.save}
                                                submit={this.toggleModalSubmitDoc}
                                                withTick
                                                section={'SectionB'}
                                                preview={this.props.preview}
                                                reviewMode={this.props.reviewMode}
                                                onRecordRemarksChanged={this.onRecordRemarksChanged}
                                                updateRecordRemarks={this.updateRecordRemarks}
                                                reassess={this.reassess}
                                            />
                                        ))}
                                </Col>
                            </Row>
                        )}

                        {this.state.savedSectionCService.length > 0 && (
                            <Row
                                style={{
                                    paddingRight: '20px',
                                    paddingTop: '10px',
                                }}
                            >
                                <Col xs={12}>
                                    {sectionCount > 1 && (
                                        <label>
                                            <b>Section {++sectionNumber}</b>
                                        </label>
                                    )}
                                    <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                    {this.state.savedSectionCService.length > 0 &&
                                        this.state.savedSectionCService.map((requirement, id) => (
                                            <CriteriaViewPanel
                                                key={`requirement-${requirement.id}`}
                                                render={requirement.render}
                                                section={'C'}
                                                requirement={requirement}
                                                requirementId={requirement.id}
                                                updatedRequirementId={this.state.updatedRequirementId}
                                                id={id}
                                                onRemarksChange={this.onRemarksChange}
                                                onAddDoc={this.onAddDoc}
                                                onDeleteDoc={this.onDeleteDoc}
                                                onDocChange={this.onDocChange}
                                                approve={requirementId => {
                                                    this.setState({ requirementId }, () => this.approveRejectConfirm(true, this.approve));
                                                }}
                                                reject={requirementId => {
                                                    this.setState(
                                                        { requirementId, rejectRequirementId: id + 1, rejectSectionNumber: sectionNumber },
                                                        () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                    );
                                                }}
                                                status={this.state.status}
                                                staff={this.props.staff}
                                                closed={this.props.closed}
                                                save={this.save}
                                                submit={this.toggleModalSubmitDoc}
                                                section={'SectionC'}
                                                preview={this.props.preview}
                                                reviewMode={this.props.reviewMode}
                                                onRecordRemarksChanged={this.onRecordRemarksChanged}
                                                updateRecordRemarks={this.updateRecordRemarks}
                                                reassess={this.reassess}
                                            />
                                        ))}
                                </Col>
                            </Row>
                        )}
                    </Fragment>
                )}
                {this.props.type == 'product' && (
                    <Fragment>
                        <Row
                            style={{
                                paddingRight: '20px',
                                borderTop: '2px solid',
                                borderTopColor: '#aaa',
                                paddingTop: '10px',
                            }}
                        >
                            <Col xs={12}>
                                <label>
                                    <b>Section A</b>
                                </label>
                                <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                {this.state.savedSectionA.length <= 0 && (
                                    <Row>
                                        <Col xs={1}>-</Col>
                                        <Col xs={this.props.preview ? 8 : !this.props.staff ? 6 : 4}>-</Col>
                                        <Col xs={3}>-</Col>
                                        {this.props.staff && <Col xs={2}>-</Col>}
                                        {!this.props.preview && <Col xs={2}>-</Col>}
                                    </Row>
                                )}
                                {this.state.savedSectionA.length > 0 &&
                                    this.state.savedSectionA.map((requirement, id) => {
                                        return (
                                            <CriteriaViewPanel
                                                key={`requirement-${requirement.id}`}
                                                requirement={requirement}
                                                section={'A'}
                                                requirementId={requirement.id}
                                                updatedRequirementId={this.state.updatedRequirementId}
                                                id={id}
                                                render={requirement.render}
                                                onRemarksChange={this.onRemarksChange}
                                                onAddDoc={this.onAddDoc}
                                                onDeleteDoc={this.onDeleteDoc}
                                                onDocChange={this.onDocChange}
                                                approve={requirementId => {
                                                    this.setState({ requirementId }, () => this.approveRejectConfirm(true, this.approve));
                                                }}
                                                reject={requirementId => {
                                                    this.setState(
                                                        { requirementId, rejectRequirementId: id + 1, rejectSectionNumber: 'A' },
                                                        () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                    );
                                                }}
                                                status={this.state.status}
                                                staff={this.props.staff}
                                                closed={this.props.closed}
                                                save={this.save}
                                                submit={this.toggleModalSubmitDoc}
                                                section={'SectionA'}
                                                preview={this.props.preview}
                                                reviewMode={this.props.reviewMode}
                                                onRecordRemarksChanged={this.onRecordRemarksChanged}
                                                updateRecordRemarks={this.updateRecordRemarks}
                                                reassess={this.reassess}
                                            />
                                        );
                                    })}
                            </Col>
                        </Row>

                        <Row
                            style={{
                                paddingRight: '20px',
                                borderTop: '2px solid',
                                borderTopColor: '#aaa',
                                paddingTop: '10px',
                            }}
                        >
                            <Col xs={12}>
                                <label>
                                    <b>Section B</b>
                                </label>
                                <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                {this.state.savedSectionB.length <= 0 && (
                                    <Row>
                                        <Col xs={1}>-</Col>
                                        <Col xs={this.props.preview ? 8 : !this.props.staff ? 6 : 4}>-</Col>
                                        <Col xs={3}>-</Col>
                                        {this.props.staff && <Col xs={2}>-</Col>}
                                        {!this.props.preview && <Col xs={2}>-</Col>}
                                    </Row>
                                )}
                                {this.state.savedSectionB.length > 0 &&
                                    this.state.savedSectionB.map((requirement, id) => (
                                        <CriteriaViewPanel
                                            key={`requirement-${requirement.id}`}
                                            requirement={requirement}
                                            section={'B'}
                                            id={id}
                                            requirementId={requirement.id}
                                            updatedRequirementId={this.state.updatedRequirementId}
                                            render={requirement.render}
                                            onRemarksChange={this.onRemarksChange}
                                            onAddDoc={this.onAddDoc}
                                            onDeleteDoc={this.onDeleteDoc}
                                            onDocChange={this.onDocChange}
                                            approve={requirementId => {
                                                this.setState({ requirementId }, () => this.approveRejectConfirm(true, this.approve));
                                            }}
                                            reject={requirementId => {
                                                this.setState(
                                                    { requirementId, rejectRequirementId: id + 1, rejectSectionNumber: 'B' },
                                                    () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                );
                                            }}
                                            status={this.state.status}
                                            staff={this.props.staff}
                                            closed={this.props.closed}
                                            save={this.save}
                                            submit={this.toggleModalSubmitDoc}
                                            withTick
                                            section={'SectionB'}
                                            preview={this.props.preview}
                                            reviewMode={this.props.reviewMode}
                                            onRecordRemarksChanged={this.onRecordRemarksChanged}
                                            updateRecordRemarks={this.updateRecordRemarks}
                                            reassess={this.reassess}
                                            applicationInfo={this.state.applicationInfo}
                                        />
                                    ))}
                            </Col>
                        </Row>

                        <Row
                            style={{
                                paddingRight: '20px',
                                borderTop: '2px solid',
                                borderTopColor: '#aaa',
                                borderBottom: '2px solid',
                                borderBottomColor: '#aaa',
                                paddingTop: '10px',
                            }}
                        >
                            <Col xs={12}>
                                <label>
                                    <b>Section C</b>
                                </label>
                                <TableHeader previe={this.props.preview} staff={this.props.staff} />
                                {this.state.savedSectionC.length <= 0 && (
                                    <Row>
                                        <Col xs={1}>-</Col>
                                        <Col xs={this.props.preview ? 8 : !this.props.staff ? 6 : 4}>-</Col>
                                        <Col xs={3}>-</Col>
                                        {this.props.staff && <Col xs={2}>-</Col>}
                                        {!this.props.preview && <Col xs={2}>-</Col>}
                                    </Row>
                                )}
                                {this.state.savedSectionC.length > 0 &&
                                    this.state.savedSectionC.map((requirement, id) => (
                                        <CriteriaViewPanel
                                            requirementId={requirement.id}
                                            updatedRequirementId={this.state.updatedRequirementId}
                                            key={`requirement-${requirement.id}`}
                                            requirement={requirement}
                                            section={'C'}
                                            id={id}
                                            render={requirement.render}
                                            onRemarksChange={this.onRemarksChange}
                                            onAddDoc={this.onAddDoc}
                                            onDeleteDoc={this.onDeleteDoc}
                                            onDocChange={this.onDocChange}
                                            approve={requirementId =>
                                                this.setState({ requirementId }, () => this.approveRejectConfirm(true, this.approve))
                                            }
                                            reject={requirementId => {
                                                this.setState(
                                                    { requirementId, rejectRequirementId: id + 1, rejectSectionNumber: 'C' },
                                                    () => this.approveRejectConfirm(false, this.promptRejectionRemark),
                                                );
                                            }}
                                            status={this.state.status}
                                            save={this.save}
                                            submit={this.toggleModalSubmitDoc}
                                            staff={this.props.staff}
                                            closed={this.props.closed}
                                            section={'SectionC'}
                                            preview={this.props.preview}
                                            reviewMode={this.props.reviewMode}
                                            onRecordRemarksChanged={this.onRecordRemarksChanged}
                                            updateRecordRemarks={this.updateRecordRemarks}
                                            reassess={this.reassess}
                                        />
                                    ))}
                            </Col>
                        </Row>

                        {this.props.type === 'product' && (this.props.staff || this.props.preview) && (
                            <Row
                                style={{
                                    paddingRight: '20px',
                                    borderTop: '2px solid',
                                    borderTopColor: '#aaa',
                                    borderBottom: '2px solid',
                                    borderBottomColor: '#aaa',
                                    paddingTop: '10px',
                                }}
                            >
                                <Col xs={12}>
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col xs={12}>
                                                <label>
                                                    <b>Minimum Supplementary Requirement</b>
                                                </label>
                                                <FootNotesComponent
                                                    readonly
                                                    ratingRule={this.state.ratingRule}
                                                    onFieldChange={() => { }}
                                                    sectionC={this.state.savedSectionC}
                                                />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                </Col>
                            </Row>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

const WriteUpPanel = props => {
    const { showInstruction, sectionBInstruction, attachment, selectedSubCategory, type, savedSectionBService, savedSectionCServiceMinimumCriteria } = props;
    return (
        <Fragment>
            {showInstruction && (
                <Fragment>
                    <Row className="mb-2">
                        <Col xs={12}>
                            {type == 'product' && (
                                <React.Fragment>
                                    <p>
                                        Assessment will take place progressively as documents are submitted; this can be tracked via the
                                        Submission Status for each individual Requirement.
                                    </p>
                                    <p>
                                        Click on <b>Add Document</b> followed by <b>Save/Submit</b> to upload the necessary document(s).
                                        Submitted documents will be locked for processing and cannot be modified. Where necessary, SGBC will
                                        request for additional details, information and/or documentation pertaining to the Application.
                                    </p>
                                    <strong>Please note the following:</strong>
                                    <ul>
                                        <br />
                                        <div className="mb-2">
                                            <li className="text-bold">Section A:</li>
                                            <Col>All requirements are mandatory.</Col>
                                        </div>
                                        <div className="mb-2">
                                            <li className="text-bold">Section B:</li>
                                            <Col>All requirements are mandatory.</Col>
                                        </div>
                                        <div className="mb-2">
                                            <li className="text-bold">Section C:</li>
                                            <Col>A minimum of 2 criteria under this section must be submitted for evaluation.</Col>
                                        </div>
                                    </ul>
                                    <p>The final SGBP Rating will be derived from submissions in Sections B and C.</p>
                                </React.Fragment>
                            )}

                            {type == 'service' && savedSectionBService != null && savedSectionBService.length > 0 && (
                                <React.Fragment>
                                    <p>
                                        Assessment will take place progressively as documents are submitted; this can be tracked via the
                                        Submission Status for each individual Requirement.
                                    </p>
                                    <p>
                                        Click on <b>Add Document</b> followed by <b>Save/Submit</b> to upload the necessary document(s).
                                        Submitted documents will be locked for processing and cannot be modified. Where necessary, SGBC will
                                        request for additional details, information and/or documentation pertaining to the Application.
                                    </p>
                                    <strong>Please note the following:</strong>
                                    <p>
                                        To obtain the respective L1-L4 rating, you must fulfil all the requirements for that required level.
                                        Failure to do so will result in the lowest grade level being awarded for your application.
                                    </p>
                                </React.Fragment>
                            )}

                            {type == 'service' && (savedSectionBService == null || savedSectionBService.length <= 0) && (
                                <React.Fragment>
                                    <p>
                                        Assessment will take place progressively as documents are submitted; this can be tracked via the
                                        Submission Status for each individual Requirement.
                                    </p>
                                    <p>
                                        Click on <b>Add Document</b> followed by <b>Save/Submit</b> to upload the necessary document(s).
                                        Submitted documents will be locked for processing and cannot be modified. Where necessary, SGBC will
                                        request for additional details, information and/or documentation pertaining to the Application.
                                    </p>
                                    <strong>Please note the following:</strong>
                                    <ul>
                                        <br />
                                        <div className="mb-2">
                                            <li className="text-bold">Section 1:</li>
                                            <Col>
                                                Please note that all requirements under this section must be submitted along with the
                                                application for evaluation.
                                            </Col>
                                        </div>
                                        <div className="mb-2">
                                            <li className="text-bold">Section 2:</li>
                                            <Col>A minimum of {savedSectionCServiceMinimumCriteria} criteria under this section must be submitted for evaluation.</Col>
                                        </div>
                                    </ul>
                                </React.Fragment>
                            )}

                            {attachment ? (
                                <span>
                                    <br />
                                    Click here to download reference table(s) as indicated in the Criteria:
                                    <a target="blank" href={`${SGBC_S3_ROOT}${attachment}`}>
                                        &nbsp;<u>Attachment.pdf</u>
                                    </a>
                                    <br />
                                    <br />
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                    <hr />
                </Fragment>
            )}
            <Row>
                <Col xs={12}>
                    {type && selectedSubCategory && (
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <label className="field-header">{type === 'product' ? 'Product' : 'Services'}</label>
                            <br />
                            {selectedSubCategory.label}
                        </FormGroup>
                    )}
                    {showInstruction && (
                        <React.Fragment>
                            {' '}
                            <span>Note: Please upload a zip file if multiple files are to be uploaded</span>
                            <br />
                        </React.Fragment>
                    )}
                </Col>
            </Row>
            <hr />
        </Fragment>
    );
};

const TableHeader = ({ preview, staff }) => {
    return (
        <Row>
            <Col xs={1}>
                <u>No.</u>
            </Col>
            <Col xs={preview ? 8 : !staff ? 6 : 4}>
                <u>Requirement</u>
            </Col>
            <Col xs={3}>
                <u>Documents to Submit</u>
            </Col>
            {staff && (
                <Col xs={2}>
                    <u>Your Action</u>
                </Col>
            )}
            {!preview && (
                <Col xs={2} className="text-center">
                    <u>Submission Status</u>
                </Col>
            )}
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffCertificationSTemplateReducer,
        ownProps,
        profile: state.profile,
        application: state.staffCertificationApplicationReducer,
        getCriteriaInfoLoading: state.staffCertificationAssessmentCriteriaAddReducer.loading,
    };
};

const mapDispatchToProps = dispatch => ({
    getSubCategories: categoryid => dispatch(getSubCategories(categoryid)),
    getActiveCriteriaSections: type => dispatch(getActiveCriteriaSections(type)),
    getSavedItem: id => dispatch(getSavedItem(id)),
    fetchNumberOfTicksBySubId: (id, subId) => {
        return dispatch(fetchNumberOfTicksBySubIdWithWait(id, subId));
    },
    saveAssessment: (body, type, reject, applicationId) => dispatch(saveAssessment(body, type, reject, applicationId)),
    sendResubmitEmail: (requirementId, sectionNumber, applicationId) =>
        dispatch(sendResubmitEmail(requirementId, sectionNumber, applicationId)),
    updateAssessment: (body, type) => dispatch(updateAssessment(body, type)),
    submitAssessment: (body, type) => dispatch(submitAssessment(body, type)),
    getCategories: type => dispatch(getCategories(type)),
    dialogModalToggle: () => dispatch(dialogModalToggle()),
    setModalData: data => dispatch(setModalData(data)),
    getCriteriaBySection: section => {
        return dispatch(getCriteriaBySection(section));
    },
    printCriteria: (id, type, category) => dispatch(printCriteria(id, type, category)),
    getAssessmentScoresheet: id => dispatch(getAssessmentScoresheet(id)),
    getApplicationInfo: id => {
        return dispatch(getApplicationInfo(id));
    },
});

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ScoresheetView),
);
