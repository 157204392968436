import React from 'react';
import { connect } from 'react-redux';
import { b64toBlob } from '../../../js/util';
import Select from 'react-select';
import history from '../../../history';
import moment from 'moment';
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, Card, CardBody, CardTitle, Badge, InputForm, FormGroup, Label } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import OverviewPanel from './CompanyDetailsPageOverviewPanel';
import MembershipPanel from './CompanyDetailsPageMembershipPanel';
import CertificatePanel from './CompanyDetailsPageCertificate';
import EventPanel from './CompanyDetailsPageEventPanel';
import TeamManagementComponent from '../../shared/TeamManagementComponent';
import LoggingActivityPanel from './CompanyDetailsPageLoggingActivityPanel';
import DialogModal from 'cyder/modals/DialogModal';
import StaffCompanySearch from 'elements/navbar-staff/Search';
import Cleave from 'cleave.js/react';

// Overview
import {
    staffCompanyDetailsGetMemberInfo,
    staffCompanyDetailsGetMemberInfoIncludingExpiry,
    staffCompanyDetailsGetCompanyInfo,
    staffCompanyDetailsGetCertificationInfo,
    staffCompanyDetailsGetEventInfo,
    staffCompanyDetailsOverviewHandleInputChange,
    staffCompanyDetailsOverviewResetToDefault,
} from 'actions/staff/company/staffCompanyDetailsOverviewAction';
// Membership
import {
    staffCompanyDetailsGetCertificationPDF,
    staffCompanyDetailsGetMembershipLetter,
    staffCompanyDetailsGetMemberApplication,
    staffCompanyDetailsUpdateMembershipTier,
    staffCompanyDetailsPrintCertByCompanyId,
    staffCompanyDetailsMembershipShowLoading,
    staffCompanyDetailsMembershipResetToDefault,
    staffCompanyDetailsMembershipSetEventModalToggle,
    staffCompanyDetailsOverviewSetModalToggle,
    staffCompanyDetailsResubmitQuestionnaires,
    staffCompanyDetailsRegenerateCertificate,
    updateMembershipPeriod,
    downloadCertificate,
} from 'actions/staff/company/staffCompanyDetailsMembershipAction';
// Certificate
import {
    staffCertListing,
    staffCertApplOverviewGetInfo,
    staffCertListPageSetLoadingState,
} from 'actions/staff/certification/staffCertificationApplicationAction';
import { fetchCertificateList, deleteAccount } from '../../../actions/staff/company/staffCompanyDetails';
// Events
import {
    staffCompanyDetailsGetEventAttended,
    staffCompanyDetailsHandleInputChange,
} from 'actions/staff/company/staffCompanyDetailsEventsAction';
//Team Management
import { staffCompanyDetailsGetTeamMemberList } from 'actions/staff/company/staffCompanyDetailsTeamManagementAction';
// Logging activities
import {
    staffCompanyDetailsLoggingResetToDefault,
    staffCompanyDetailsLoggingGetLoggingActivities,
} from '../../../actions/staff/company/staffCompanyDetailsLoggingActivityAction';
// Others
import {
    getMembershipApplicationTierResponse,
    getMembershipApplicationTierDocs,
} from 'actions/member/membership/application/membershipApplicationAction';
import {
    memeberMembershipApplicationGetBusinessActivity,
    memberMembershipApplicationGetCategories,
} from 'actions/member/company/memberCompanyAction';
import { dialogModalToggle } from '../../../actions/modal/dialogModalAction';

import TierResponsePopup from './TierResponsePopup';

const MEMBERSHIPTIERS = [
    {
        label: `Standard`,
        value: `Standard`,
    },
    {
        label: `Partner`,
        value: `Partner`,
    },
    {
        label: `Advocate`,
        value: `Advocate`,
    },
];

const MEMBERSHIPFOUNDINGMEMBERS = [
    {
        label: `No`,
        value: 0,
    },
    {
        label: `Yes`,
        value: 1,
    },
];

const MEMBERSHIPTYPES = [
    {
        label: `Corporate`,
        value: 1,
    },
    {
        label: `Established`,
        value: 2,
    },
    {
        label: `Founding`,
        value: 0,
    },
];

const UpgradeTierPanel = ({ selectedMembershipTier, membershipTierOnChange, updateMembershipTier, resubmitQuestionnaires }) => (
    <Row>
        <FormGroup style={{ margin: '10px' }}>
            <Label>
                <b>Membership Tier</b>
            </Label>
            <div style={{ width: '180px' }}>
                <Select
                    options={MEMBERSHIPTIERS}
                    value={selectedMembershipTier}
                    clearable={true}
                    onChange={membershipTierOnChange}
                    name="selected-state"
                    disabled={false}
                    searchable={true}
                />
            </div>
        </FormGroup>
        <FormGroup style={{ margin: '10px', display: 'inherit' }}>
            <Label>&nbsp;</Label>
            <div style={{ width: '180px', marginLeft: '5px', marginTop: '30px' }}>
                <Button className="primary-btn-style mr-1" onClick={updateMembershipTier}>
                    <i className="fa fa-save" />
                    &nbsp; Update Membership Tier
                </Button>
            </div>
            <div style={{ width: '180px', marginLeft: '5px', marginTop: '30px' }}>
                <Button className="primary-btn-style mr-2" onClick={resubmitQuestionnaires} style={{ position: 'relative' }}>
                    <i className="fa fa-reply" />
                    &nbsp;Resubmit
                </Button>
            </div>
        </FormGroup>
    </Row>
);

class StaffCompanyDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        this.boundForceUpdate = this.boundForceUpdate.bind(this);
        this.membershipTierOnChange = this.membershipTierOnChange.bind(this);
        this.membershipFoundingMemberOnChange = this.membershipFoundingMemberOnChange.bind(this);
        this.updateMembershipTier = this.updateMembershipTier.bind(this);
        this.printCert = this.printCert.bind(this);
        this.getInfo = this.getInfo.bind(this);
        this.persistTab = this.persistTab.bind(this);
        this.viewMembershipTier = this.viewMembershipTier.bind(this);
        this.resubmitMembershipTier = this.resubmitMembershipTier.bind(this);
        this.regenerateCertificate = this.regenerateCertificate.bind(this);
        this.deleteAcccount = this.deleteAcccount.bind(this);
        this.tierResponsePostProcessor = this.tierResponsePostProcessor.bind(this);
        this.updateMembershipPeriod = this.updateMembershipPeriod.bind(this);
        this.toggleUpdateMembershipPeriod = this.toggleUpdateMembershipPeriod.bind(this);
        this.handleChangeDateTime = this.handleChangeDateTime.bind(this);

        this.state = {
            activeTab: 'overview',
            companyId: 0,
            status: {
                value: 'all',
                label: 'All',
            },
            type: 'product',
            keyword: null,
            page: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            loadingGenerateCert: false,
            loadingUpdateMembershipPeriod: false,
            membershipStartDate: null,
            membershipExpiryDate: null,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ companyId: this.props.match.params.companyid, activeTab: this.props.activeTab });
        this.getRelevantStuffs(this.props.match.params.companyid);
    }
    showLoading(loading, type) {
        let state = this.state;
        state[type || 'loading'] = loading;
        this.setState(state);
    }
    handleChangeDateTime(e, n) {
        // console.log(e.target.value);
        // console.log(n);
        let state = this.state;
        state[n] = e.target.value;
        this.setState(state);
    }
    tierResponsePostProcessor(tierResponseInfo) {
        const newResponse = {};
        if (tierResponseInfo != null) {
            tierResponseInfo.forEach(({ question, option }) => {
                if (!newResponse[question]) newResponse[question] = [];
                newResponse[question].push(option);
            });
        }

        return newResponse;
    }
    async viewMembershipTier() {
        this.popup.show();
    }
    regenerateCertificate() {
        this.showLoading(true, 'loadingGenerateCert');
        this.props.membershipRegenerateCertificate(this.props.match.params.companyid).then(() => this.props.dialogModalToggle());
        this.showLoading(false, 'loadingGenerateCert');
    }
    async updateMembershipPeriod() {
        this.setState({
            loadingUpdateMembershipPeriod: true,
        });
        const res = await this.props.updateMembershipPeriod(
            this.props.match.params.companyid,
            moment(this.state.membershipStartDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            moment(this.state.membershipExpiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            this.state.membershipFoundingMember.value,
        );
        this.setState({
            loadingUpdateMembershipPeriod: false,
        });
        this.toggleUpdateMembershipPeriod();
        this.componentDidMount();
    }
    resubmitMembershipTier() {
        const { memberApplication } = this.props.membership;
        if (!memberApplication || memberApplication.length === 0) return;
        const applicationId = memberApplication[0].id;
        this.props.dialogModalToggle();
        this.props.membershipSetEventModalData({
            modalAction: () => {
                this.props.membershipResubmitQuestionnaires(applicationId);
                this.popup.hide();
                this.setState({
                    time: new Date().getTime(),
                });
            },
            modalBody: 'Are you sure you want to resubmit the membership tier documents?',
        });
    }
    boundForceUpdate() {
        this.componentDidMount();
    }
    getRelevantStuffs(companyId) {
        const data = {
            page: 1,
            itemsPerPage: 20,
            keyword: null,
            status: { value: 'all', label: 'All' },
            type: 'product',
        };
        switch (this.state.activeTab) {
            case 'overview':
                this.props.overviewResetToDefault().then(() => {
                    this.props.overviewGetBusinessActivities();
                    this.props.overviewGetOrganisationMainCategory();
                    this.props.overviewGetMemberInfo(companyId);
                    this.props.overviewGetCertificationInfo(companyId);
                    this.props.overviewGetEventInfo(companyId);
                    this.props.overviewGetCompanyInfo(companyId);
                });
                break;
            case 'certification':
                this.getInfo(this.state.page, 20, this.state.keyword, this.state.status, this.state.type);
                //this.props.fetchCertificateList(data);
                break;
            case 'membership':
                this.props.membershipResetToDefault().then(() => {
                    this.props.membershipGetMemberApplication(companyId).then(async res => {
                        if (res != null && res.data != null && res.data.length > 0) {
                            let tierRes = await this.props.getMembershipAppllicationTierResponse(res.data[0].id);
                            let docs = await this.props.getMembershipAppllicationTierDocs(res.data[0].id);
                            this.setState({
                                tierQuestions: tierRes.data,
                                docs: docs.data,
                            });
                        }
                    });
                    this.props.membershipGetCertificatonPDF(companyId);
                    this.props.membershipGetMembershipLetter(companyId);
                });
            case 'logging':
                this.props.loggingResetToDefault().then(() => this.props.loggingGetLoggingActivitiesList(companyId));
                break;
        }
    }
    getInfo(page = 0, pageSize = 20, keyword = null, status = null) {
        this.props.staffCertListing(page, pageSize, null, null, keyword, status, this.props.type, this.state.companyId);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState(
                {
                    activeTab: tab,
                },
                () => this.getRelevantStuffs(this.state.companyId),
            );
        }
    }
    persistTab(activeTab) {
        this.setState({ activeTab });
    }
    renderCompanyInfo() {
        return (
            <div>
                <h3>{this.props.overview.companyInfo.name}</h3>
                <h6 className="text-success">Organization ID: {this.props.overview.companyInfo.id}</h6>
            </div>
        );
    }
    renderNavItem(navItems) {
        return navItems.map(navItem => (
            <NavItem key={navItem.key}>
                <NavLink
                    className={this.state.activeTab === navItem.key ? 'active' : null}
                    onClick={() => {
                        this.toggleTab(navItem.key);
                    }}
                >
                    <i className={'fa fa-fw ' + navItem.icon} />
                    {' ' + navItem.text}
                </NavLink>
            </NavItem>
        ));
    }
    // Overview Tab
    getBusinessActivityName(businessActivities, mainCategoryId) {
        let businessActivity = '';
        businessActivities.forEach(activity => {
            if (activity.id === mainCategoryId) businessActivity = activity.name;
        });
        return businessActivity;
    }
    getMainCategoryName(categories, mainCategoryId) {
        let mainCategory = '';
        categories.forEach(category => {
            if (category.mainCategory.id === mainCategoryId) {
                mainCategory = category.mainCategory.name;
            }
        });
        return mainCategory;
    }
    deleteAcccount() {
        this.props.dialogModalToggle();
        this.props.overviewSetModalData({
            modalAction: async () => {
                this.props.overviewSetModalData({
                    modalLoading: true,
                });
                let res = await this.props.deleteAccount(this.props.match.params.companyid);
                // console.log(res);
                if (res.error) {
                    this.props.overviewSetModalData({
                        modalBody: 'This account cannot be deleted',
                        modalHeader: 'Error',
                        modalOnlyOneButton: true,
                        modalAction: () => {
                            this.props.dialogModalToggle();
                        },
                    });
                } else {
                    this.props.overviewSetModalData({
                        modalBody: 'This account has been successfully deleted',
                        modalOnlyOneButton: true,
                        modalAction: () => {
                            this.props.dialogModalToggle();
                            history.push('/staff/accounts');
                        },
                    });
                }
            },
            modalBody: 'Are you sure you want to delete this account?',
        });
    }
    // Membership
    updateMembershipTier() {
        this.props.dialogModalToggle();
        this.props.membershipSetEventModalData({
            modalAction: () => {
                this.props.membershipUpdateMembershipTier(
                    this.props.match.params.companyid,
                    this.props.overview.selectedMembershipTier.value,
                );
                this.popup.hide();
            },
            modalBody: 'Are you sure you want to update the membership tier',
        });
    }
    async printCert() {
        this.props.downloadCertificate(this.props.match.params.companyid);
    }
    membershipTierOnChange(e) {
        this.props.overviewHandleInputChange(e);
    }
    membershipFoundingMemberOnChange(e) {
        this.setState({
            membershipFoundingMember: e,
        });
    }
    toggleUpdateMembershipPeriod() {
        this.setState({
            membershipFoundingMember: {
                label: this.props.overview.memberInfo.foundingMember == 0 ? 'Corporate' : this.props.overview.memberInfo.foundingMember == 1 ? 'Founding' : 'Established',
                value: this.props.overview.memberInfo.foundingMember,
            },
            membershipStartDate: moment(this.props.overview.memberInfo.dateJoined, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            membershipExpiryDate: moment(this.props.overview.memberInfo.expiryDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            modalUpdateMembershipPeriod: !this.state.modalUpdateMembershipPeriod,
        });
    }
    render() {
        const navs = [
            {
                key: 'overview',
                text: 'Overview',
                icon: 'fa-eye',
            },
            {
                key: 'membership',
                text: 'Membership',
                icon: 'fa-pencil-square-o',
            },
            {
                key: 'certification',
                text: 'Certifications',
                icon: 'fa-exclamation-circle',
            },
            {
                key: 'events',
                text: 'Events',
                icon: 'fa-check-square-o',
            },
            {
                key: 'team',
                text: 'Team Management',
                icon: 'fa-users',
            },
            {
                key: 'logging',
                text: 'Logging Activities',
                icon: 'fa-sign-in',
            },
        ];
        const activeTab = this.state.activeTab;
        const { overview, membership } = this.props;

        return (
            <div className="page-widget">
                <Modal size="sm" isOpen={this.state.modalUpdateMembershipPeriod} toggle={this.toggleUpdateMembershipPeriod}>
                    <ModalHeader toggle={this.toggleUpdateMembershipPeriod}>
                        <b>Update Membership</b>
                    </ModalHeader>
                    <ModalBody>
                        <Row style={{ marginBottom: '5px' }}>
                            <Col xs="3">Start</Col>
                            <Col xs="6">
                                <Cleave
                                    placeholder="DD/MM/YYYY"
                                    style={{
                                        marginRight: '10px',
                                        padding: '0.3rem 0.75rem',
                                        width: '150px',
                                        border: '1px solid rgba(0, 0, 0, 0.15)',
                                    }}
                                    options={{
                                        date: true,
                                        delimiter: '/',
                                        datePattern: ['d', 'm', 'Y'],
                                    }}
                                    value={this.state.membershipStartDate}
                                    onChange={e => this.handleChangeDateTime(e, 'membershipStartDate')}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '5px' }}>
                            <Col xs="3">Expiry</Col>
                            <Col xs="6">
                                <Cleave
                                    placeholder="DD/MM/YYYY"
                                    style={{
                                        marginRight: '10px',
                                        padding: '0.3rem 0.75rem',
                                        width: '150px',
                                        border: '1px solid rgba(0, 0, 0, 0.15)',
                                    }}
                                    options={{
                                        date: true,
                                        delimiter: '/',
                                        datePattern: ['d', 'm', 'Y'],
                                    }}
                                    value={this.state.membershipExpiryDate}
                                    onChange={e => this.handleChangeDateTime(e, 'membershipExpiryDate')}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '5px' }}>
                            <Col xs="3">Type</Col>
                            <Col xs="6">
                                <div style={{ width: '150px' }}>
                                    <Select
                                        options={MEMBERSHIPTYPES}
                                        value={this.state.membershipFoundingMember}
                                        clearable={true}
                                        onChange={this.membershipFoundingMemberOnChange}
                                        name="selected-state"
                                        disabled={false}
                                        searchable={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.loadingUpdateMembershipPeriod ? (
                            <LoadingSpinner />
                        ) : (
                            <Button className="primary-btn-style" onClick={this.updateMembershipPeriod}>
                                Save
                            </Button>
                        )}
                        <Button className="primary-btn-style" onClick={this.toggleUpdateMembershipPeriod}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <DialogModal
                    modalAction={this.props[activeTab].modalAction}
                    modalHeader={this.props[activeTab].modalHeader}
                    modalBody={this.props[activeTab].modalBody}
                    loading={this.props[activeTab].modalLoading}
                    buttonDisabler={this.props[activeTab].modalLoading}
                    boundForceUpdate={this.boundForceUpdate}
                    modalOnlyOneButton={this.props[activeTab].modalOnlyOneButton}
                />
                <TierResponsePopup
                    className="modal-lg"
                    ref={instance => (this.popup = instance)}
                    tierQuestions={this.state.tierQuestions}
                    docs={this.state.docs}
                    tierResponsePostProcessor={this.tierResponsePostProcessor}
                    memberApplication={this.props.membership.memberApplication}
                    orgCategory={this.props.overview.memberInfo.orgCategory}
                    upgradeTierPanel={
                        <UpgradeTierPanel
                            selectedMembershipTier={this.props.overview.selectedMembershipTier}
                            resubmitQuestionnaires={this.resubmitMembershipTier}
                            membershipTierOnChange={this.membershipTierOnChange}
                            updateMembershipTier={this.updateMembershipTier}
                        />
                    }
                />
                {overview.loading || membership.loading ? (
                    <LoadingSpinner center />
                ) : (
                    <React.Fragment>
                        <Row className="mb-2">
                            <Col xs={9}>
                                <Nav tabs>{this.renderCompanyInfo()}</Nav>
                            </Col>
                            <Col xs={3}>
                                <StaffCompanySearch refresh />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={12}>
                                <Nav tabs>{this.renderNavItem(navs)}</Nav>
                            </Col>
                        </Row>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="overview">
                                <OverviewPanel
                                    count={this.props.overview.count}
                                    companyId={this.state.companyId}
                                    memberInfo={this.props.overview.memberInfo}
                                    companyInfo={this.props.overview.companyInfo}
                                    subCategory={this.props.overview.subCategory}
                                    businessActivities={this.props.member.form.businessActivities}
                                    mainCategories={this.props.member.form.categories}
                                    getBusinessActivityName={this.getBusinessActivityName}
                                    getMainCategoryName={this.getMainCategoryName}
                                    deleteAcccount={this.deleteAcccount}
                                    updateMembershipPeriod={this.toggleUpdateMembershipPeriod}
                                />
                            </TabPane>
                            <TabPane tabId="membership">
                                <MembershipPanel
                                    memberApplication={this.props.membership.memberApplication}
                                    selectedMembershipTier={this.props.overview.selectedMembershipTier}
                                    membershipTierOnChange={this.membershipTierOnChange}
                                    updateMembershipTier={this.updateMembershipTier}
                                    printCert={this.printCert}
                                    memberInfo={this.props.overview.memberInfo}
                                    viewMembershipTier={this.viewMembershipTier}
                                    resubmitMembershipTier={this.resubmitMembershipTier}
                                    regenerateCertificate={this.regenerateCertificate}
                                    loadingGenerateCert={this.state.loadingGenerateCert}
                                />
                            </TabPane>
                            <TabPane tabId="certification">
                                <CertificatePanel
                                    certs={this.props.certs}
                                    getInfo={this.getInfo}
                                    staffCertListing={(page, pageSize, sorted, filtered, keyword, status, type) => {
                                        this.props.staffCertListing(
                                            page,
                                            pageSize,
                                            sorted,
                                            filtered,
                                            keyword,
                                            status,
                                            type,
                                            this.state.companyId,
                                        );
                                    }}
                                />
                            </TabPane>
                            <TabPane tabId="events">
                                <EventPanel
                                    keyword={this.props.events.keyword}
                                    loading={this.props.events.loading}
                                    totalNum={this.props.events.totalNum}
                                    eventsList={this.props.events.eventsList}
                                    handleInputChangeAction={event => this.props.eventsHandleInputChange(event, this.state.companyId)}
                                    getEventAttendedAction={(page, pageSize, sorted, filtered) =>
                                        this.props.eventsGetEventAttended(
                                            this.state.companyId,
                                            this.props.events.keyword,
                                            page,
                                            pageSize,
                                            sorted,
                                            filtered,
                                        )
                                    }
                                />
                            </TabPane>
                            <TabPane tabId="team">
                                {this.state.activeTab === 'team' ? (
                                    <React.Fragment>
                                        <div className="page-widget">
                                            <TeamManagementComponent
                                                mode="staff"
                                                persistTab={this.persistTab}
                                                companyId={this.state.companyId}
                                                tableData={this.props.team.tableData}
                                                companyInfo={this.props.overview.companyInfo}
                                                getMemberListAction={this.props.teamGetMemberList}
                                            />
                                            <br />
                                        </div>

                                        <div className="page-widget">
                                            <TeamManagementComponent
                                                mode="staff"
                                                subscriber
                                                persistTab={this.persistTab}
                                                companyId={this.state.companyId}
                                                tableData={this.props.team.tableData}
                                                companyInfo={this.props.overview.companyInfo}
                                                getMemberListAction={this.props.teamGetMemberList}
                                            />
                                            <br />
                                        </div>
                                    </React.Fragment>
                                ) : null}
                            </TabPane>
                            <TabPane tabId="logging">
                                <LoggingActivityPanel
                                    companyId={this.state.companyId}
                                    loggingActivitiesList={this.props.logging.loggingActivitiesInfo}
                                    getLoggingActivitiesListAction={this.props.loggingGetLoggingActivitiesList}
                                />
                            </TabPane>
                        </TabContent>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        member: state.memberCompanyReducer,
        overview: state.staffCompanyDetailsOverviewReducer,
        membership: state.staffCompanyDetailsMembershipReducer,
        certification: {},
        certs: state.staffCertificationApplicationReducer.certs,
        events: state.staffCompanyDetailsEventsReducer,
        team: state.staffCompanyDetailsTeamManagementReducer,
        logging: state.staffCompanyDetailsLoggingActivityReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // Overview
        overviewGetCompanyInfo: companyId => {
            dispatch(staffCompanyDetailsGetCompanyInfo(companyId));
        },
        overviewGetBusinessActivities: () => {
            dispatch(memeberMembershipApplicationGetBusinessActivity());
        },
        overviewGetOrganisationMainCategory: () => {
            dispatch(memberMembershipApplicationGetCategories());
        },
        overviewGetMemberInfo: companyId => {
            return dispatch(staffCompanyDetailsGetMemberInfoIncludingExpiry(companyId));
        },
        overviewGetCertificationInfo: companyId => {
            dispatch(staffCompanyDetailsGetCertificationInfo(companyId));
        },
        overviewGetEventInfo: companyId => {
            dispatch(staffCompanyDetailsGetEventInfo(companyId));
        },
        overviewHandleInputChange: event => {
            dispatch(staffCompanyDetailsOverviewHandleInputChange(event));
        },
        overviewResetToDefault: () => {
            return dispatch(staffCompanyDetailsOverviewResetToDefault());
        },
        // Membership
        membershipShowLoading: show => {
            dispatch(staffCompanyDetailsMembershipShowLoading(show));
        },
        membershipGetMemberApplication: companyId => {
            return dispatch(staffCompanyDetailsGetMemberApplication(companyId));
        },
        membershipGetCertificatonPDF: companyId => {
            dispatch(staffCompanyDetailsGetCertificationPDF(companyId));
        },
        membershipGetMembershipLetter: companyId => {
            dispatch(staffCompanyDetailsGetMembershipLetter(companyId));
        },
        memebershipPrintCertByCompanyId: companyId => {
            return dispatch(staffCompanyDetailsPrintCertByCompanyId(companyId));
        },
        dialogModalToggle: () => {
            dispatch(dialogModalToggle());
        },
        membershipSetEventModalData: data => {
            dispatch(staffCompanyDetailsMembershipSetEventModalToggle(data));
        },
        overviewSetModalData: data => {
            dispatch(staffCompanyDetailsOverviewSetModalToggle(data));
        },
        membershipUpdateMembershipTier: (companyId, membershipTier) => {
            return dispatch(staffCompanyDetailsUpdateMembershipTier(companyId, membershipTier));
        },
        membershipResetToDefault: () => {
            return dispatch(staffCompanyDetailsMembershipResetToDefault());
        },
        membershipResubmitQuestionnaires: applicationId => {
            return dispatch(staffCompanyDetailsResubmitQuestionnaires(applicationId));
        },
        membershipRegenerateCertificate: companyId => {
            return dispatch(staffCompanyDetailsRegenerateCertificate(companyId));
        },
        deleteAccount: companyId => {
            return dispatch(deleteAccount(companyId));
        },
        // Certificate
        staffCertListing: (page, pageSize, sorted, filtered, keyword, status, type, companyId) => {
            dispatch(staffCertListing(page, pageSize, sorted, filtered, keyword, status, type, companyId));
        },
        fetchCertificateList: data => dispatch(fetchCertificateList(data)),
        // Event
        eventsGetEventAttended: (companyId, keyword, page, pageSize, sorted, filtered) => {
            dispatch(staffCompanyDetailsGetEventAttended(companyId, keyword, page, pageSize, sorted, filtered));
        },
        eventsHandleInputChange: (event, companyId) => {
            const value = event.target.value;
            dispatch(staffCompanyDetailsHandleInputChange(value));
        },
        // Team Management
        teamGetMemberList: (a, b, c, d, companyId) => {
            dispatch(staffCompanyDetailsGetTeamMemberList(a, b, c, d, companyId));
        },
        // Logging Activities
        loggingResetToDefault: () => {
            return dispatch(staffCompanyDetailsLoggingResetToDefault());
        },
        loggingGetLoggingActivitiesList: (companyId, sorted, filtered) => {
            dispatch(staffCompanyDetailsLoggingGetLoggingActivities(companyId, sorted, filtered));
        },
        getMembershipAppllicationTierResponse: id => {
            return dispatch(getMembershipApplicationTierResponse(id));
        },
        getMembershipAppllicationTierDocs: id => {
            return dispatch(getMembershipApplicationTierDocs(id));
        },
        downloadCertificate: companyId => {
            return dispatch(downloadCertificate(companyId));
        },
        updateMembershipPeriod: (companyId, startDate, expiryDate, foundingMember) => {
            return dispatch(updateMembershipPeriod(companyId, startDate, expiryDate, foundingMember));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffCompanyDetailsPage));
