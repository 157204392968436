import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Label, FormGroup, Input, Button } from 'reactstrap';
import { Card, CardBody, CardText } from 'reactstrap';
import { getAllMembershipPackages, updateMembershipPackage } from 'actions/admin/memberships/membership-application-status/action';
import Select from 'react-select';

class ChangeMembershipPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            loading: false,
            membershipPackage: null,
            loadingSave: false,
            membershipPackages: [],
        };
        // this.getCourseProviders = this.getCourseProviders.bind(this);
    }

    async componentDidMount() {
        this.setState({
            loading: true,
            success: false,
        });
        let { data } = await this.props.getMembershipPackages();
        let membershipPackages = data.map(pkg => {
            return {
                value: pkg.id,
                label: pkg.packageName,
            };
        });
        let selectedPackageArr = membershipPackages.filter(pkg => {
            return pkg.value == this.props.selectedPackage.id;
        });
        let membershipPackage = {};
        if (selectedPackageArr != null && selectedPackageArr.length > 0) {
            membershipPackage = selectedPackageArr[0];
        }
        this.setState({
            membershipPackages,
            loading: false,
            membershipPackage,
        });
    }
    render() {
        if (this.state.loading) return '';
        if (this.state.showForm) {
            return (
                <div>
                    <div style={{ width: '400px', marginLeft: '10px', marginBottom: '10px' }}>
                        <Select
                            autosize={false}
                            options={this.state.membershipPackages}
                            value={this.state.membershipPackage}
                            clearable={true}
                            onChange={e => {
                                this.setState({
                                    membershipPackage: e,
                                });
                            }}
                            isMulti={false}
                            name="selected-state"
                            disabled={false}
                            searchable={true}
                        />
                    </div>
                    <Button
                        className="primary-btn-style mr-2"
                        onClick={async () => {
                            this.setState({
                                loadingSave: true,
                            });
                            await this.props.updateMembershipPackage(this.props.applicationId, this.state.membershipPackage.value);
                            this.setState({
                                loadingSave: false,
                                showForm: false,
                                success: true,
                            });
                        }}
                        style={{ position: 'relative', marginLeft: '10px' }}
                    >
                        Save
                    </Button>
                    {` `}
                    <Button
                        className="primary-btn-style mr-2"
                        onClick={() => {
                            this.setState({
                                showForm: false,
                            });
                        }}
                        style={{ position: 'relative', marginLeft: '10px' }}
                    >
                        Cancel
                    </Button>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    {this.state.success && (
                        <b>Membership package has been successfully updated. Please refresh the page to reload new package.</b>
                    )}
                    {!this.state.success && (
                        <Button
                            className="primary-btn-style mr-2"
                            onClick={() => {
                                this.setState({
                                    showForm: true,
                                });
                            }}
                            style={{ position: 'relative', marginLeft: '10px' }}
                        >
                            Change Membership Package
                        </Button>
                    )}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {
        getMembershipPackages: () => {
            return dispatch(getAllMembershipPackages());
        },
        updateMembershipPackage: (id, packageId) => {
            return dispatch(updateMembershipPackage(id, packageId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChangeMembershipPackage);
