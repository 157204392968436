import React from 'react';
import history from '../../../../../history';
import { SGBC_S3_HOST } from 'config.js';
import { Button, Card, CardBody } from 'reactstrap';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';

const TermsAndConditions = ({ changeStep, mode, agreeTcValue, isRenew }) => {
    const backUrl = isRenew ? `/member/certification/${mode}/certificates` : `/member/certification/${mode}/applications`;
    return (
        <Card>
            <CardBody>
                <NoShadowPanel flat description="" title="Begin Application" panelStyle={{ textAlign: 'justify' }}>
                    <p>
                        Assessment will take place progressively and will take approximately 3 weeks to complete, depending on the validity
                        and quality of the submitted documents.
                    </p>
                    <p>
                        Do read through the
                        <a target="blank" href={SGBC_S3_HOST + `/pdf/SGBP_and_SGBS_How_to_Apply_Guide.pdf`}>
                            &nbsp;<u>How to apply</u>&nbsp;
                        </a>
                        guide before you begin the application process.
                    </p>
                    <br />
                    <div className="d-flex">
                        <div className="ml-auto d-inline-flex mt-3">
                            <Button className="primary-btn-style-outline-tc primary-btn-md mr-2" onClick={e => history.push(backUrl)}>
                                BACK
                            </Button>
                            <Button
                                style={agreeTcValue === false ? { cursor: 'unset' } : { cursor: 'pointer' }}
                                className="primary-btn-style-tc primary-btn-md"
                                onClick={changeStep}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </NoShadowPanel>
            </CardBody>
        </Card>
    );
};
export default TermsAndConditions;
