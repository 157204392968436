import {
    TEMPLATE_EDIT_SET_MODAL_DATA,
    SAVE_SERVICE_REQUEST,
    SAVE_SERVICE_SUCCESS,
    SAVE_SERVICE_FAILURE,
    SAVE_PRODUCT_REQUEST,
    SAVE_PRODUCT_SUCCESS,
    SAVE_PRODUCT_FAILURE,
} from '../../../../actions/constants/actionTypesStaffCertificationAssessmentTemplate';
import { setReducerModalState, defaultModalKeys } from 'js/modal';

// defaults
const defaultState = {
    ...defaultModalKeys,
};

export default function staffCertificationSTemplateReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case TEMPLATE_EDIT_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        case SAVE_SERVICE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case SAVE_SERVICE_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = '/staff/certification/assessment/scoresheet-criteria';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case SAVE_SERVICE_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        case SAVE_PRODUCT_REQUEST:
            newState.modalLoading = true;
            return newState;
        case SAVE_PRODUCT_SUCCESS:
            newState.modalLoading = false;
            newState.modalBody = action.response.message;
            newState.modalAction = '/staff/certification/assessment/scoresheet-criteria/';
            newState.modalOnlyOneButton = true;
            newState.forceModalAction = true;
            return newState;
        case SAVE_PRODUCT_FAILURE:
            newState.modalLoading = false;
            newState.modalBody = action.error.message;
            newState.modalOnlyOneButton = true;
            newState.modalAction = null;
            return newState;
        default:
            return newState;
    }
}
