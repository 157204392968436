import { instance } from '../../../config';
import { FETCH_CERTIFICATE_LIST } from '../../constants/actionTypesStaffCompanyDetails';

import withQuery from 'with-query';
import fetchlib from '../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, SGBC_S3_HOST } from '../../../config';

export const fetchCertificateList = data => dispatch => {
    instance
        .post(`/certificates_v1/cn/certificate?action=certificateList`, data)
        .then(response => {
            dispatch({
                type: FETCH_CERTIFICATE_LIST,
                payload: response.data.data,
            });
        })
        .catch(err => {
            throw err;
        });
};

export function deleteAccount(companyId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'delete',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { id: companyId }, null, null, null);
    };
}
