// Get - event EXPENDITUREs
export const EVENT_GET_EVENT_EXPENDITURES_REQUEST = 'EVENT_GET_EVENT_EXPENDITURES_REQUEST';
export const EVENT_GET_EVENT_EXPENDITURES_SUCCESS = 'EVENT_GET_EVENT_EXPENDITURES_SUCCESS';
export const EVENT_GET_EVENT_EXPENDITURES_FAILURE = 'EVENT_GET_EVENT_EXPENDITURES_FAILURE';

// Save - event EXPENDITUREs
export const EVENT_SAVE_EXPENDITURE_REQUEST = 'EVENT_SAVE_EXPENDITURE_REQUEST';
export const EVENT_SAVE_EXPENDITURE_SUCCESS = 'EVENT_SAVE_EXPENDITURE_SUCCESS';
export const EVENT_SAVE_EXPENDITURE_FAILURE = 'EVENT_SAVE_EXPENDITURE_FAILURE';

// Update - event EXPENDITUREs
export const EVENT_UPDATE_EXPENDITURE_REQUEST = 'EVENT_UPDATE_EXPENDITURE_REQUEST';
export const EVENT_UPDATE_EXPENDITURE_SUCCESS = 'EVENT_UPDATEE_EXPENDITURE_SUCCESS';
export const EVENT_UPDATE_EXPENDITURE_FAILURE = 'EVENT_UPDATE_EXPENDITURE_FAILURE';

// Add, save, and delete - Single EXPENDITUREs
export const EVENT_SAVE_NEW_EVENT_EXPENDITURE = 'EVENT_ADD_NEW_EVENT_EXPENDITURE';
export const EVENT_SAVE_SINGLE_EVENT_EXPENDITURE = 'EVENT_SAVE_SINGLE_EVENT_EXPENDITURE';
export const EVENT_DELETE_SINGLE_EVENT_EXPENDITURE = 'EVENT_DELETE_SINGLE_EVENT_EXPENDITURE';

// Set - event Id
export const EVENT_EXPENDITURE_SET_EVENT_ID = 'EVENT_EXPENDITURE_SET_EVENT_ID';

// Input fields action
export const EVENT_EXPENDITURE_CHANGE_FIELD_VALUE = 'EVENT_EXPENDITURE_CHANGE_FIELD_VALUE';

// Reset - all fields
export const EVENT_EXPENDITURE_RESET_FIELDS = 'EVENT_EXPENDITURE_RESET_FIELDS';

// Modal
export const EVENT_EXPENDITURE_SET_MODAL_DATA = 'EVENT_EXPENDITURE_SET_MODAL_DATA';

// Generate
export const EVENT_EXPENDITURE_GENERATE_REQUEST = 'EVENT_EXPENDITURE_GENERATE_REQUEST';
export const EVENT_EXPENDITURE_GENERATE_SUCCESS = 'EVENT_EXPENDITURE_GENERATE_SUCCESS';
export const EVENT_EXPENDITURE_GENERATE_FAILURE = 'EVENT_EXPENDITURE_GENERATE_FAILURE';
