import {
    USER_ADD_SAVE_USER_REQUEST,
    USER_ADD_SAVE_USER_SUCCESS,
    USER_ADD_SAVE_USER_FAILURE,
    USER_ADD_CHANGE_FIELD_VALUE,
    USER_ADD_FORCE_VALIDATE,
    USER_ADD_REMOVE_REDIRECT_ID,
} from '../../../actions/constants/actionTypesAuth';

import cyderlib from '../../../js/cyderlib';

// defaults
var defaultState = {
    user: {
        username: '',
        password: '',
        confirmpassword: '',
    },
    validation: {},
    loading: false,
    errorMessage: null,
    saveButtonPressed: false,
    redirectId: null,
};

export function userAddReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case USER_ADD_REMOVE_REDIRECT_ID:
            newState.redirectId = null;
            return newState;
        case USER_ADD_SAVE_USER_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case USER_ADD_SAVE_USER_SUCCESS:
            newState.loading = false;
            newState.redirectId = action.response.id;
            return newState;
        case USER_ADD_SAVE_USER_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            newState.user = [];
            return newState;
        case USER_ADD_CHANGE_FIELD_VALUE:
            newState.user = Object.assign({}, state.user);
            newState.user[action.key] = action.value;
            newState.validation = Object.assign({}, state.validation);
            let pw = null;
            if (action.key === 'confirmpassword') {
                pw = state.user['password'];
            }
            newState.validation[action.key] = cyderlib.validate(
                action.key,
                action.value,
                pw,
            );
            return newState;
        case USER_ADD_FORCE_VALIDATE:
            newState.user = Object.assign({}, state.user);
            newState.validation = Object.assign({}, state.validation);
            for (var k in state.user) {
                let pw = null;
                if (k === 'confirmpassword') {
                    pw = state.user['password'];
                }
                newState.validation[k] = cyderlib.validate(
                    k,
                    state.user[k],
                    pw,
                );
            }
            newState.saveButtonPressed = true;
            return newState;
        default:
            return state;
    }
}
