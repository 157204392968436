import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Row, Col, Form, Button, Input, Container } from 'reactstrap';
import { SGBC_S3_ROOT } from 'config';
import { search, searchForMember, getAllCategories } from 'actions/member/resources/action.js';
import EditButton from 'cyder/media/EditButton';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import moment from 'moment';

const buttonStyle = {
    backgroundColor: '#6ba17d',
    borderColor: '#6ba17d',
    margin: '2px',
    fontWeight: 'bold',
    color: '#fff',
};

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onYearChange = this.onYearChange.bind(this);
        this.onKeywordChange = this.onKeywordChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            keyword: '',
            pageCount: 0,
            categories: [],
            category: {
                label: `All`,
                value: 0,
            },
            years: [],
            year: {
                label: `All`,
                value: 0,
            },
        };
    }
    onKeywordChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    componentDidMount() {
        let years = [];
        years.push({
            label: `All`,
            value: 0,
        });
        for (var i = parseInt(moment().format('YYYY')); i >= 2010; i--) {
            years.push({
                label: i,
                value: i,
            });
        }

        this.props
            .getAllCategories()
            .then(categories => {
                var categoryToAdd = [];
                categoryToAdd.push({
                    value: 0,
                    label: `All`,
                });
                categories.data.forEach(category => {
                    categoryToAdd.push({
                        value: category.id,
                        label: category.category,
                    });
                });
                categoryToAdd = categoryToAdd.filter(category => category.value !== 5 && category.value !== 6);
                this.setState({
                    categories: categoryToAdd,
                    years,
                });
            })
            .catch(error => {});
    }
    onCategoryChange(e) {
        this.setState({
            category: e,
        });
    }
    onYearChange(e) {
        this.setState({
            year: e,
        });
    }
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search(this.state.keyword, this.state.category.value, this.state.year.value, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'Category',
                accessor: 'category',
                minWidth: 100,
                maxWidth: 200,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Year',
                accessor: 'year',
                maxWidth: 70,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 200,
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Button
                            size="sm"
                            style={buttonStyle}
                            onClick={e => {
                                if (props.original.resource.indexOf(`resources/`) >= 0) {
                                    window.open(`https://sgbc.online/media/${props.original.resource}`);
                                } else {
                                    window.open(`${SGBC_S3_ROOT}${props.original.resource}`);
                                }
                            }}
                        >
                            <i className="mr-1 material-icons align-text-bottom ">file_download</i>
                        </Button>
                    );
                },
            },
        ];
        return (
            <Container>
                <br />
                <Row>
                    <Col xs={12} className="d-flex">
                        <h3>
                            <img src="/assets/icons/icon_mbr_resource_library.png" style={{ marginBottom: '5px' }} width="48" height="48" />
                            &nbsp;
                            <strong>RESOURCES</strong>
                        </h3>
                    </Col>
                </Row>
                <br />
                <br />
                <Row className="mb-2">
                    <br />
                    <br />
                    <Col xs={4}>
                        <div className="form-inline">
                            Select by category
                            <div style={{ width: '100%' }}>
                                <Select
                                    style={{ width: '100%' }}
                                    autosize={false}
                                    options={this.state.categories}
                                    value={this.state.category}
                                    clearable={true}
                                    onChange={this.onCategoryChange}
                                    isMulti={false}
                                    name="selected-state"
                                    disabled={false}
                                    searchable={true}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className="form-inline">
                            Select by year
                            <div style={{ width: '100%' }}>
                                <Select
                                    style={{ width: '100%' }}
                                    autosize={false}
                                    options={this.state.years}
                                    value={this.state.year}
                                    clearable={true}
                                    onChange={this.onYearChange}
                                    isMulti={false}
                                    name="selected-state"
                                    disabled={false}
                                    searchable={true}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className="form-inline">
                            Search by name
                            <div style={{ width: '100%' }}>
                                <Input
                                    type="text"
                                    onChange={this.onKeywordChange}
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    value={this.state.keyword}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col xs={2}>
                        <div className="form-inline">
                            <div style={{ width: '100%', paddingTop: '20px' }}>
                                <Button style={buttonStyle} onClick={() => this.table.fireFetchData()}>
                                    <i className="material-icons align-text-bottom">search</i>
                                    &nbsp;Search
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, categoryId, year, page, pageSize, sorted, filtered) => {
            return dispatch(searchForMember(keyword, categoryId, year, page, pageSize, sorted, filtered));
        },
        getAllCategories: () => {
            return dispatch(getAllCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
