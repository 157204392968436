import withQuery from 'with-query';
import {
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST,
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS,
    STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE,
    STAFF_CERT_APPL_LIST_FORCE_LOADING,
    STAFF_CERT_LIST_GET_INFO_REQUEST,
    STAFF_CERT_LIST_GET_INFO_SUCCESS,
    STAFF_CERT_LIST_GET_INFO_FAILURE,
    FETCH_XERO_INVENTORY_CODES,
    FETCH_APPLICATION_LIST,
    FETCH_NUMBER_OF_TICKS,
} from '../../constants/actionTypesStaffCertificationApplications';
import { SGBC_API_ROOT, SGBC_API_KEY, SGBC_PRINT_ROOT, SGBC_API_CORS_ROOT, instance } from '../../../config';
import fetchlib from '../../../js/fetchlib';
import Axios from 'axios';

export const getAllCertificateList = (type, offset = 0, limit = 500) => (dispatch, getState) => {
    const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
        action: 'getUsedCertificateNoList',
        type: type,
        offset: offset,
        limit: limit,
    });
    return fetchlib.fetchV2(dispatch, getState, 'POST', url);
};

export const fetchReviewList = data => (dispatch, getState) => {
    const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
        action: 'reviewList',
    });
    var postData = Object.assign({}, data);
    return fetchlib.fetchV2(dispatch, getState, 'POST', url, postData);
};

export function fetchReviewIdByStaff() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'getReviewListByStaff',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export const fetchApplicationList = data => (dispatch, getState) => {
    let securedInstance = Axios.create({
        baseURL: SGBC_API_ROOT,
        headers: {
            Authorization: getState().profile.authorizationToken != null ? getState().profile.authorizationToken : 'allow',
            'content-type': 'application/json',
            'x-api-key': SGBC_API_KEY,
        },
    });

    return securedInstance
        .post('/certificates_v1/cn/certificate-application?action=applicationList', data)
        .then(response => {
            dispatch({
                type: FETCH_APPLICATION_LIST,
                payload: response.data.data,
                totalNoOfItems: response.data.totalNoOfItems,
                totalNoOfPages: response.data.totalNoOfPages,
            });
        })
        .catch(err => {
            throw err;
        });
};

export function printCertificationApplication(id, type, muaLocked) {
    var action = muaLocked ? 'print-certificate-mark-user-agreement' : 'print-certification';
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action,
            id,
            type,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export const fetchNumberOfTicks = applicationId => dispatch => {
    instance
        .post(`/certificates_v1/cn/certificate-application?action=get-final-rating&id=${applicationId}`)
        .then(response => {
            dispatch({
                type: FETCH_NUMBER_OF_TICKS,
                payload: response.data.data,
            });
        })
        .catch(err => {
            throw err;
        });
};

export const fetchNumberOfTicksBySubId = applicationId => dispatch => {
    if (applicationId) {
        instance
            .post(`/certificates_v1/cn/certificate-application?action=get-final-rating-per-sub-id&id=${applicationId}`)
            .then(response => {
                dispatch({
                    type: FETCH_NUMBER_OF_TICKS,
                    payload: response.data.data,
                });
            })
            .catch(err => {
                throw err;
            });
    }
};

export function fetchNumberOfTicksBySubIdWithWait(applicationId) {
    if (applicationId) {
        return (dispatch, getState) => {
            const url = withQuery(
                `${SGBC_API_ROOT}/certificates_v1/cn/certificate-application?action=get-final-rating-per-sub-id&id=${applicationId}`,
            );
            return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
        };
    } else {
        return new Promise();
    }
}
export function getCertificationStats(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getCertificationStats',
            type,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            STAFF_CERT_APPL_OVERVIEW_GET_INFO_REQUEST,
            STAFF_CERT_APPL_OVERVIEW_GET_INFO_SUCCESS,
            STAFF_CERT_APPL_OVERVIEW_GET_INFO_FAILURE,
        );
    };
}

// This is Certification Product/Service Listing
export function staffCertListing(page, pageSize, sorted, filtered, keyword, status, type, companyId, issuedFrom, issuedTo, filterType) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'certificateList',
        });
        const body = {
            page: page + 1,
            itemsPerPage: pageSize,
            keyword,
            status,
            type,
            companyId,
            sorted,
            issuedFrom,
            issuedTo,
            filterType,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            STAFF_CERT_LIST_GET_INFO_REQUEST,
            STAFF_CERT_LIST_GET_INFO_SUCCESS,
            STAFF_CERT_LIST_GET_INFO_FAILURE,
        );
    };
}

// This is Certification Application Listing
export function staffCertListPageSetLoadingState(loadingState) {
    return dispatch => {
        dispatch({
            type: STAFF_CERT_APPL_LIST_FORCE_LOADING,
            loadingState,
        });
        return Promise.resolve();
    };
}

export function getApplicationInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCompanyInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getApplicationStatusInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application-status`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getProductTypeInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product-type`, {
            action: 'find-by-id-with-parent-info',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getXeroInvoiceInfo(invoiceNumber) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/invoice`, {
            action: 'get_cert_invoice_info',
        });
        var body = {
            invoiceNumber,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function closeCase(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'close-case',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function findApproverInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function checkCertNumberDuplication(certNumber) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'certNumberChecking',
            certNumber,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function returnMua(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'return-mua',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function removeInvoice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-application-non-invoice',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getServiceCategoryInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/service_v1/cn/category`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getProductAssessmentTemplatesByProductType(productTypeId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'get-templates-by-product-type-v2',
            productTypeId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getProductAssessmentTemplatesByProductCategory(productTypeId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'get-templates-by-product-category-with-others',
            productTypeId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getServiceAssessmentTemplatesByServiceCategory(serviceCategoryId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/assessment_v1/cn/assessment-template`, {
            action: 'get-templates-by-service-category',
            serviceCategoryId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function acceptApplication(id, templateId, copyPrevAssessment) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'take-up-case',
            id,
            templateId,
            copyPrevAssessment,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function takeOverCase(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'take-over-case',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function discontinueApplication(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'discontinue',
            id,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function startAssessment(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'start-assessment',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function completeAssessment(id, selectedOfficerId, brands, service) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'complete-assessment',
            id,
            selectedOfficerId,
        });
        var body = {
            brands,
            service,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function unlinkInvoice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'unlink-invoice',
            id,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function sendForApproval(id, selectedPeerId, selectedManagerId, brands, service) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'send-for-approval',
            id,
            selectedPeerId,
            selectedManagerId,
        });
        var body = {
            brands,
            service,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function updateRemarks(id, remarks) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-remarks',
            id,
        });
        var data = {
            remarks,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function updateStatus(id, statusId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-status',
            id,
            statusId,
        });
        var data = {};
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function updateGreenRemarks(id, remarks) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-green-remarks',
            id,
        });
        var data = {
            remarks,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function getApplicationOverviewStats(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getApplicationStats',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCertificatesByApplicationId(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'get-certificates-by-application-id',
            certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getCertificationOverviewStats(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getCertificationStats',
            type,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllSuperAdmin() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'get-all-superadmin',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllStaffs() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'get-all-staffs',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getAllCertManagers() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/auth_v1/users`, {
            action: 'get-all-cert-managers',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function approveApplication(id, approvallevel) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'approve-application',
            id,
            approvallevel,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function rejectApplication(id, approvallevel, remarks) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'reject-application',
            id,
            approvallevel,
        });
        var data = {
            remarks,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, data, null, null, null);
    };
}

export function startFeedback(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'start-feedback',
            id,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function startPreparation(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'start-preparation',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getInvoiceInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/invoice`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function saveEditOnBehalf(id, brands) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'save-edit-on-behalf',
            id,
        });
        var body = {
            brands,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function updateBrands(id, brands) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-brands',
            id,
        });
        var body = {
            brands,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function updateBrandsByMember(id, brands) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-brands-by-member',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, brands, null, null, null);
    };
}

export function completeApplication(id, brands, type, service) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'complete-application',
            id,
        });
        var body = {
            brands,
            type,
            service,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function updateApplicationFeedback(id, brands, type, service) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-application-feedback',
            id,
        });
        var body = {
            brands,
            type,
            service,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
export function markAsPaid(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'mark-as-paid',
            id,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function authorizingInvoice(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'authorizeInvoice',
        });
        const body = {
            certificateApplicationId: id,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getBCAOptions() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/bca-data-option`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getOtherOptions() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/other-data-option`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function updateTagging(id, tagging) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'update-tagging',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, tagging, null, null, null);
    };
}

export const fetchXeroInventoryCodes = prefix => dispatch => {
    instance
        .post(`/finances_v1/cn/invoice?action=getXeroInventoryCodesByPrefix&prefix=${prefix}`)
        .then(response => {
            dispatch({
                type: FETCH_XERO_INVENTORY_CODES,
                payload: response.data.data,
            });
        })
        .catch(err => {
            throw err;
        });
};

export function printCert(certNumber) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'getMembershipCertByCompanyId',
            certNumber,
        });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function generateCertNumber(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'get-cert-number',
            type,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function loadPaymentMode() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment-mode`, {
            action: 'getFinancePaymentMode',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function savePaymentInfo(paymentInfo, id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'update-payment-info',
        });
        paymentInfo.applicationId = id;
        return fetchlib.fetch(dispatch, getState, 'POST', url, paymentInfo, null, null, null);
    };
}

export function getPaymentDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'get-payment-info',
            paymentId: id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function getPaymentInfo(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/finances_v1/cn/payment`, {
            action: 'find-by-id',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function staffCertListGetStatus() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application-status`, {
            action: 'get-all-active',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function sendWithdrawalEmail(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'sendWithdrawalEmail',
            id,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function sendReceipt(certificatelink, id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'send-certificate-receipt',
            id,
        });
        var data = {};
        data.certificatelink = certificatelink;
        data.id = id;
        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
export function createReceiptInS3ForCertificate(paymentId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_ROOT}/pdfs_v1/print`, {
            action: 'print-certificate-receipt',
            id: paymentId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function sendResubmitEmail(requirementId, sectionNumber, applicationId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'sendResubmitEmail',
        });
        let postBody = {
            requirementId,
            sectionNumber,
            applicationId,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, postBody, null, null, null);
    };
}

export function updateNumberSequence(value, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'updateNumberSequence',
        });
        let postBody = {
            value,
            type,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, postBody, null, null, null);
    };
}
