/**
 * Promo code reducer
 *
 * Author: Fernando
 */

// defaults
var defaultState = {
    control: {},
    data: {},
};

export function promoCodesReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case 'PROMOCODE_XXX':
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        default:
            return state;
    }
}
