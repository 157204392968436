import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Col, Row, Form, Container } from 'reactstrap';

import TableWidgetUpcomingEvent from 'cyder/table/TableWidgetUpcomingEvent';

class DashboardUpcomingEvents extends React.Component {
    render() {
        const { events } = this.props;
        if (events && events.length === 0)
            return (
                <Container>
                    <Row>
                        <Col className="p-3">Stay tuned with us for more upcoming events</Col>
                    </Row>
                </Container>
            );
        if (this.props.new) return <TableWidgetUpcomingEvent items={events} />;
        return <EventListComponent items={events} />;
    }
}

const EventListComponent = ({ items }) => {
    return (
        <div style={{ height: '285px', overflow: 'auto', marginTop: '15px' }}>
            {items &&
                items.map((item, i) => {
                    const { eventId, eventDescription, eventDate, eventSummary } = item;

                    const formattedDate = moment(eventDate).format('DD/MM/YYYY');
                    const linkTo = `/member/event/rsvp/${eventId}`;
                    return (
                        <Col key={i}>
                            {i > 0 ? <hr /> : null}
                            <Row className="sgbc-newtheme mt-2 mb-2">
                                <Form inline>
                                    <div className="text-bold mr-2">
                                        {item.eventDates &&
                                            item.eventDates.split(',').map((dateRecord, i) => (
                                                <React.Fragment key={i}>
                                                    {moment(dateRecord.trim()).format('DD/MM/YYYY')}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                    </div>
                                </Form>
                                <Link to={linkTo}>
                                    <div>{eventDescription}</div>
                                </Link>
                            </Row>
                            <Row>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: eventSummary,
                                    }}
                                />
                            </Row>
                        </Col>
                    );
                })}
        </div>
    );
};

export default DashboardUpcomingEvents;
