import React from 'react';

import { CardBody, Button } from 'reactstrap';
import { MealTypePanel } from '../../pages/staff/dinner/dashboard/DinnerEventsDashboardPage';

const NoShadowPanel = ({
    allocationNumber,
    title,
    icon,
    flat,
    iconSize,
    children,
    titleClass,
    borderSize,
    description,
    descriptionSecondLine,
    panelStyle,
    descriptionStyle,
    titleStyle,
    hasNolineBreak,
    textLowerCase,
    print,
    printText,
    galaTitleColor,
    mealType,
    fontSize,
}) => {
    let style = {
        padding: '30px 40px',
        backgroundColor: '#fff',
        ...panelStyle,
    };
    if (borderSize) {
        style.padding = `${borderSize}px ${borderSize}px`;
    }
    if (flat) {
        style.boxShadow = '0px 0px 0px 0px';
        style.padding = `2px 2px`;
    }

    const printPage = () => window.print();

    return (
        <CardBody style={style}>
            <br />
            <h3 className={`text-uppercase color-${titleClass}`} style={{ fontSize: fontSize || '2.25em', ...titleStyle }}>
                {icon && <i className={`mb-1 align-middle material-icons material-icons-${iconSize}x`}>{icon}</i>}
                {icon && ' '}
                {print && title === '2. Preview' ? (
                    <div className="print">
                        <strong>{title}</strong>{' '}
                        <Button color="info" onClick={printPage}>
                            {printText || 'Print this page'}
                        </Button>
                    </div>
                ) : (
                    <strong style={galaTitleColor}>{title}</strong>
                )}
            </h3>
            {!hasNolineBreak && <br />}
            <div>
                <div className={textLowerCase ? '' : 'text-uppercase'} style={descriptionStyle || null}>
                    {description}
                    {descriptionSecondLine ? (
                        <React.Fragment>
                            <br />
                            {descriptionSecondLine}
                        </React.Fragment>
                    ) : null}
                </div>
                {children}
            </div>
        </CardBody>
    );
};
export default NoShadowPanel;
