import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import { Grid, Segment, Header, Form, Input, Dropdown } from 'semantic-ui-react';
import { Container, FormGroup, Row, Col, Badge, Nav, NavItem, NavLink, CardText } from 'reactstrap';
import { Button } from 'semantic-ui-react';
import Select from 'react-select';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { mobile } from '../../../public/dummy';
import {
    staffMembershipMemberGetOrganisationCategory,
    staffMembershipMemberGetPublicMemberDirectory,
} from 'actions/staff/membership/members/staffMembershipMembersAction.js';

import { defaultKeywordSearchKeys, searchKeywordByAlphabeticalLetter } from 'js/keywordSearch';

const FilterNavComponentWrapper = props => {
    return (
        <Col xs={12} {...props}>
            <Grid.Row className="mb-2">
                <Grid.Column width={12}>
                    <Nav tabs>{props.children}</Nav>
                </Grid.Column>
            </Grid.Row>
        </Col>
    );
};
const FilterNavTitle = props => {
    return (
        <CardText className="p-2">
            <strong>{props.label + ': '}</strong>
            <br />
        </CardText>
    );
};
class MembershipDirectoryPage extends React.Component {
    _isMounted = true;
    state = {
        activeTab: {
            type: 'All',
            alphabetical: 'All',
        },
        selectedOrgCategory: '',
        directoryList: [],
        loading: false,
        pagination: true,
        pageSize: 10,
        pageCount: 0,
        ...defaultKeywordSearchKeys,
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getOrganisationCategory();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    showLoading = loading => {
        this.setState({
            loading,
        });
    };
    handleInputChange = event => {
        this.setState({
            keyword: event.target.value,
        });
    };
    onSelectChange = (e, { value }) => {
        this.setState({
            selectedOrgCategory: value,
        });
    };
    search = async (page, pageSize = 20, sorted, category) => {
        try {
            const { selectedOrgCategory } = this.state;
            this.setState({ loading: true });
            const selectedCategory = selectedCategory !== '' ? selectedOrgCategory : '';
            const { data, totalNoOfPages, totalNoOfItems } = await this.props.getMemberDirectory(
                this.state.activeTab.type,
                this.state.keyword,
                category ? category : selectedCategory,
                this.state.activeTab.alphabetical,
                page,
                pageSize,
                sorted,
            );
            // const newData = await this.searchByAlphabet(data);
            this.setState({
                directoryList: data,
                loading: false,
                pageCount: totalNoOfPages,
                totalNum: totalNoOfItems,
            });
        } catch (error) {
            throw error;
        }
    };
    searchByAlphabet(data) {
        const { alphabetical } = this.state.activeTab;
        var searchResult = data;
        // search by alphabet
        if (alphabetical !== 'All') {
            searchResult = searchKeywordByAlphabeticalLetter(data, alphabetical, 'name');
        }
        return searchResult;
    }
    getAlphebeticalNavObject() {
        let alphabets = ['All'];
        let i = 'A'.charCodeAt(0);
        let j = 'Z'.charCodeAt(0);
        for (; i <= j; ++i) {
            alphabets.push(String.fromCharCode(i));
        }
        return alphabets.map(letter => {
            return {
                key: letter.toLowerCase(),
                text: letter,
            };
        });
    }
    toggleTab(tab, id) {
        let activeTab = this.state.activeTab;
        activeTab[id] = tab;
        this.setState({
            activeTab,
            loading: true,
        });
        return Promise.resolve();
    }
    renderNav(navItems, id) {
        return navItems.map(item => {
            const isActive = this.state.activeTab[id] === item.text;
            return (
                <NavItem key={item.key} style={{ backgroundColor: 'unset' }}>
                    <NavLink
                        style={{
                            zIndex: 0,
                            fontWeight: isActive ? 'bold' : '',
                            backgroundColor: 'unset',
                        }}
                        className={isActive ? 'active p-2' : 'p-2'}
                        onClick={() => {
                            this.toggleTab(item.text, id).then(() => this.table.fireFetchData());
                        }}
                    >
                        {item.text}
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        const { history, categoryOptions } = this.props;
        var typeNavs = [
            {
                key: 'all',
                text: 'All',
            },
            {
                key: 'foundingMembers',
                text: 'Founding Member',
            },
            {
                key: 'corporateMembers',
                text: 'Corporate Member',
            },
            {
                key: 'establishedMembers',
                text: 'Established Member',
            },
            
        ];
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                minWidth: 300,
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Link className="link" to={'./memberdirectory/' + props.original.companyId}>
                            {props.value}
                        </Link>
                    );
                },
            },
            {
                Header: 'Type',
                accessor: 'membershipType',
                minWidth: 125,
                Cell: props => {
                    return <Badge className="membership-type">{props.value}</Badge>;
                },
            },
            // Requested by Wenyi on 22 August 2019, to hide this column
            // https://cydersg.atlassian.net/browse/SGBCCRM-1572
            // {
            //     Header: 'Tier',
            //     accessor: 'membershipTierCalculated',
            //     minWidth: 125,
            //     Cell: props => {
            //         if (props.value === 'N/A') return;
            //         return props.value;
            //     },
            // },
            {
                Header: 'Website',
                accessor: 'website',
                minWidth: 125,
                Cell: props => {
                    if (props.value == null || props.value == '' || props.value == 'null') {
                        return `-`;
                    } else {
                        if (props.value.indexOf('http') < 0) {
                            var url = `http://${props.value}`;
                            return (
                                <a className="link" target="_blank" href={url}>
                                    {props.value}
                                </a>
                            );
                        } else {
                            return (
                                <a className="link" target="_blank" href={props.value}>
                                    {props.value}
                                </a>
                            );
                        }
                    }
                },
            },
            {
                Header: 'Category',
                accessor: 'orgCategory',
                style: { whiteSpace: 'unset' },
                minWidth: 200,
                Cell: props => {
                    if (props.value == null || props.value == '' || props.value == 'null') {
                        return `-`;
                    } else {
                        return <span>{props.value}</span>;
                    }
                },
            },
        ];
        const alphaticallNavs = this.getAlphebeticalNavObject();
        return (
            <Grid className="members">
                <Grid.Row centered>
                    <Grid.Column width={16} style={{ paddingLeft: 0 }}>
                        <Grid stackable centered verticalAlign="middle">
                            <Grid.Column width={16}>
                                <Segment
                                    placeholder
                                    className="hero"
                                    style={{ border: '0px', paddingBottom: '5px', borderColor: '#f5f5f5' }}
                                >
                                    <Grid centered>
                                        <Grid.Column width={12}>
                                            <Header as="h2">
                                                SGBC <br /> <span>Member Directory </span>
                                            </Header>
                                            <Form>
                                                <Input
                                                    // icon={{ name: 'arrow right', circular: true, link: true }}
                                                    size="large"
                                                    name="keyword"
                                                    onKeyUp={e => {
                                                        // if (e.keyCode == 13) {
                                                        this.table.fireFetchData();
                                                        // }
                                                    }}
                                                    onChange={this.handleInputChange}
                                                    placeholder="Search by company name"
                                                />
                                                <Dropdown
                                                    options={categoryOptions}
                                                    onChange={(e, data) => {
                                                        this.onSelectChange(e, data);
                                                        this.search(0, 20, null, data.value);
                                                    }}
                                                    // onChange={this.onSelectChange}
                                                    placeholder={mobile ? 'Organisation category' : 'Select organisation category'}
                                                    search
                                                    selection
                                                />
                                                {/* <Button
                                                    className="btn btn-secondary primary-green"
                                                    style={{ display: 'inline-block' }}
                                                    onClick={() => this.table.fireFetchData()}
                                                >
                                                    Search
                                                </Button> */}
                                            </Form>
                                        </Grid.Column>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className="noprint">
                    <Grid.Column width={12}>
                        <Button
                            basic
                            icon="chevron left"
                            content={'Back to Directories'}
                            onClick={() => history.push('/public/directory')}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={12} style={{ padding: 0 }}>
                        <FilterNavComponentWrapper>
                            <FilterNavTitle label="Type" />
                            {this.renderNav(typeNavs, 'type')}
                        </FilterNavComponentWrapper>
                        <FilterNavComponentWrapper className="alphabetical">
                            <FilterNavTitle label="Alphabetical" />
                            {this.renderNav(alphaticallNavs, 'alphabetical')}
                        </FilterNavComponentWrapper>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                    <Grid.Column width={12} style={{ padding: 0 }}>
                        <CyderReactTable
                            manual
                            minRows={0}
                            filterable={false}
                            hideTotalNum={true}
                            columns={columns}
                            className="-highlight mb-2"
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            data={this.state.directoryList}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={state => {
                                this.search(state.page, state.pageSize, state.sorted, this.state.selectedOrgCategory);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

const RowColWrapper = props => {
    const { children, rowStyle, colStyle, colClassName } = props;
    return (
        <Row style={rowStyle}>
            <Col xs={12} className={colClassName} style={colStyle}>
                {children}
            </Col>
        </Row>
    );
};

const mapStateToProps = (state, ownProps) => {
    const categories = state.staffMembershipMembersReducer.orgCategories;
    const categoryOptions = categories && categories.map(item => ({ key: item.value, value: item.value, text: item.label }));

    return {
        ...state.staffMembershipMembersReducer,
        categoryOptions,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getMemberDirectory: (type, keyword, category, alphabetical, page, pageSize, sorted, filtered) => {
            let tier = null;
            return dispatch(
                staffMembershipMemberGetPublicMemberDirectory(
                    type,
                    keyword,
                    category,
                    alphabetical,
                    tier,
                    page,
                    pageSize,
                    sorted,
                    filtered,
                ),
            );
        },
        getOrganisationCategory: () => {
            return dispatch(staffMembershipMemberGetOrganisationCategory());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembershipDirectoryPage));
