import { instance } from '../../../config';
import * as Types from 'actions/constants/actionTypesStaffCertificationApplications';

import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_S3_HOST, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';
export const loadingCertification = () => ({ type: Types.LOADING_CERTIFICATION });

export const fetchCertificationRenewals = data => dispatch => {
    dispatch(loadingCertification());
    // console.log("data: ", data)
    if (data.pageSize && data.pageSize > 0) {
        data.itemsPerPage = data.pageSize;
    }
    instance
        .post('/certificates_v1/cn/certificate?action=certificateRenewalList', data)
        .then(response => {
            dispatch({
                type: Types.FETCH_CERTIFICATION_RENEWALS,
                payload: response.data.data,
                pages: response.data.totalNoOfPages,
                length: response.data.totalNoOfItems,
            });
        })
        .catch(err => console.error(err.response));
};

export const fetchRenewalDetails = certificateId => dispatch => {
    dispatch(loadingCertification());
    instance
        .get(`/certificates_v1/cn/certificate?action=certificateRenewalDetail&id=${certificateId}`)
        .then(response => {
            dispatch({ type: Types.FETCH_RENEWAL_DETAILS, payload: response.data.data[0] });
        })
        .catch(err => console.error(err.response));
};

export function fetchRenewalDetailsV2(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: `certificateRenewalDetail`,
            id: certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export const fetchRenewalStatus = () => dispatch => {
    instance
        .get('/certificates_v1/cn/certificate-renewal-status?action=getAllStatus')
        .then(response => {
            dispatch({ type: Types.FETCH_RENEWAL_STATUS, payload: response.data.data });
        })
        .catch(err => console.error(err.response));
};

export const fetchTerminationReasons = () => dispatch => {
    instance
        .get('/certificates_v1/cn/termination-reason?action=get-all')
        .then(response => {
            dispatch({ type: Types.FETCH_TERMINATION_REASON, payload: response.data.data });
        })
        .catch(err => console.error(err.response));
};

export const updateRenewalStatus = data => dispatch => {
    dispatch(loadingCertification());
    return instance
        .post('/certificates_v1/cn/certificate?action=updateCertificateRenewalStatus', data)
        .then(response => {
            dispatch({ type: Types.UPDATE_RENEWAL_STATUS });
        })
        .catch(err => console.error(err.response));
};
