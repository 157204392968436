// Country Search
export const USER_MANUAL_SEARCH_REQUEST = 'USER_MANUAL_SEARCH_REQUEST';
export const USER_MANUAL_SEARCH_SUCCESS = 'USER_MANUAL_SEARCH_SUCCESS';
export const USER_MANUAL_SEARCH_FAILURE = 'USER_MANUAL_SEARCH_FAILURE';
export const USER_MANUAL_KEYWORD_SEARCH_REQUEST = 'USER_MANUAL_KEYWORD_SEARCH_REQUEST';
export const USER_MANUAL_KEYWORD_SEARCH_SUCCESS = 'USER_MANUAL_KEYWORD_SEARCH_SUCCESS';
export const USER_MANUAL_KEYWORD_SEARCH_FAILURE = 'USER_MANUAL_KEYWORD_SEARCH_FAILURE';
export const USER_MANUAL_TOGGLE_CHECKBOX = 'USER_MANUAL_TOGGLE_CHECKBOX';
export const USER_MANUAL_TOGGLE_CHECKBOX_ALL = 'USER_MANUAL_TOGGLE_CHECKBOX_ALL';

export const USER_MANUAL_CHANGE_INPUT_KEYWORD_VALUE = 'USER_MANUAL_CHANGE_INPUT_KEYWORD_VALUE ';
export const USER_MANUAL_ADD_SET_MODAL_DATA = 'USER_MANUAL_ADD_SET_MODAL_DATA';

// Country Details / Country Add
export const USER_MANUAL_ADD_GET_DETAIL_REQUEST = 'USER_MANUAL_ADD_GET_DETAIL_REQUEST';
export const USER_MANUAL_ADD_GET_DETAIL_SUCCESS = 'USER_MANUAL_ADD_GET_DETAIL_SUCCESS';
export const USER_MANUAL_ADD_GET_DETAIL_FAILURE = 'USER_MANUAL_ADD_GET_DETAIL_FAILURE';

export const USER_MANUAL_ADD_SAVE_USER_MANUAL_REQUEST = 'USER_MANUAL_ADD_SAVE_USER_MANUAL_REQUEST';
export const USER_MANUAL_ADD_SAVE_USER_MANUAL_SUCCESS = 'USER_MANUAL_ADD_SAVE_USER_MANUAL_SUCCESS';
export const USER_MANUAL_ADD_SAVE_USER_MANUAL_FAILURE = 'USER_MANUAL_ADD_SAVE_USER_MANUAL_FAILURE';

export const USER_MANUAL_ADD_DELETE_USER_MANUAL_REQUEST = 'USER_MANUAL_ADD_DELETE_USER_MANUAL_REQUEST';
export const USER_MANUAL_ADD_DELETE_USER_MANUAL_SUCCESS = 'USER_MANUAL_ADD_DELETE_USER_MANUAL_SUCCESS';
export const USER_MANUAL_ADD_DELETE_USER_MANUAL_FAILURE = 'USER_MANUAL_ADD_DELETE_USER_MANUAL_FAILURE';

export const USER_MANUAL_ADD_SET_FIELDS = 'USER_MANUAL_ADD_SET_FIELDS';

export const USER_MANUAL_ADD_CHANGE_FIELD_VALUE = 'USER_MANUAL_ADD_CHANGE_FIELD_VALUE ';
