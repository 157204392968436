import StoredLayout from 'layouts/StoredLayout';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import DialogModal from 'cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import { Input, Row, Col, Form, Button, FormGroup, Label } from 'reactstrap';

import { save, update, remove, load } from 'actions/admin/misc/user-manual/action.js';

import 'css/forms/default-forms.css';

class AddPage extends React.Component {
    constructor(props) {
        super(props);

        this.categoryIdChange = this.categoryIdChange.bind(this);

        this.manualPdfChange = this.manualPdfChange.bind(this);

        this.orderNumberChange = this.orderNumberChange.bind(this);

        this.nameChange = this.nameChange.bind(this);

        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            data: {
                categoryId: '',

                manualPdf: '',

                orderNumber: '',

                name: '',
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    back() {
        history.replace('/admin/misc/user-manual');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        this.showLoading(true);
        var id = null;
        try {
            id = this.props.match.params.id;
        } catch (e) {}
        if (id != null && id > 0) {
            this.props
                .load(id)
                .then(res => {
                    this.showLoading(false);
                    if (res.error || res.data == null || res.data.id == null) {
                        this.showModal('Error', res.message, 'load', true, true);
                    } else {
                        this.setState({
                            data: res.data,
                        });
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'load', true, true);
                });
        } else {
            this.setState({
                data: {
                    categoryId: '',

                    manualPdf: '',

                    orderNumber: '',

                    name: '',
                },
            });
            this.showLoading(false);
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        return newData;
    }
    save() {
        var data = this.prepareDataBeforeSave(this.state.data);
        this.showLoading(true);
        if (data.id == null || data.id === 0 || data.id === '') {
            this.props
                .save(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message, 'save', true, true);
                    } else {
                        history.replace('/admin/misc/user-manual');
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'save', true, true);
                });
        } else {
            this.props
                .update(data)
                .then(res => {
                    this.showLoading(false);
                    if (res.error) {
                        this.showModal('Error', res.message);
                    } else {
                        history.replace('/admin/misc/user-manual', 'update', true, true);
                    }
                })
                .catch(error => {
                    this.showLoading(false);
                    this.showModal('Error', error.message, 'update', true, true);
                });
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/misc/user-manual');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
            });
    }
    categoryIdChange(e) {
        var data = this.state.data;
        data.categoryId = e.target.value;
        this.setState({ data });
    }

    manualPdfChange(e) {
        var data = this.state.data;
        data.manualPdf = e.target.value;
        this.setState({ data });
    }

    orderNumberChange(e) {
        var data = this.state.data;
        data.orderNumber = e.target.value;
        this.setState({ data });
    }

    nameChange(e) {
        var data = this.state.data;
        data.name = e.target.value;
        this.setState({ data });
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <div>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <i onClick={this.back} style={{ cursor: 'pointer' }} className={`fa fa-2x fa-angle-left`} />
                            <div
                                style={{
                                    fontSize: '20px',
                                    marginLeft: '20px',
                                    cursor: 'pointer',
                                }}
                                onClick={this.back}
                            >
                                back
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mb-2">
                        {' '}
                        <Col xs={12}>
                            {' '}
                            <FormGroup>
                                {' '}
                                <Label for="categoryId">categoryId</Label>{' '}
                                <Input
                                    type="text"
                                    name="categoryId"
                                    id="categoryId"
                                    value={this.state.data.categoryId}
                                    placeholder="categoryId"
                                    onChange={this.categoryIdChange}
                                />{' '}
                            </FormGroup>{' '}
                        </Col>{' '}
                    </Row>
                    <Row className="mb-2">
                        {' '}
                        <Col xs={12}>
                            {' '}
                            <FormGroup>
                                {' '}
                                <Label for="manualPdf">manualPdf</Label>{' '}
                                <Input
                                    type="text"
                                    name="manualPdf"
                                    id="manualPdf"
                                    value={this.state.data.manualPdf}
                                    placeholder="manualPdf"
                                    onChange={this.manualPdfChange}
                                />{' '}
                            </FormGroup>{' '}
                        </Col>{' '}
                    </Row>
                    <Row className="mb-2">
                        {' '}
                        <Col xs={12}>
                            {' '}
                            <FormGroup>
                                {' '}
                                <Label for="orderNumber">orderNumber</Label>{' '}
                                <Input
                                    type="text"
                                    name="orderNumber"
                                    id="orderNumber"
                                    value={this.state.data.orderNumber}
                                    placeholder="orderorderNumberMumber"
                                    onChange={this.orderNumberChange}
                                />{' '}
                            </FormGroup>{' '}
                        </Col>{' '}
                    </Row>
                    <Row className="mb-2">
                        {' '}
                        <Col xs={12}>
                            {' '}
                            <FormGroup>
                                {' '}
                                <Label for="name">name</Label>{' '}
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={this.state.data.name}
                                    placeholder="name"
                                    onChange={this.nameChange}
                                />{' '}
                            </FormGroup>{' '}
                        </Col>{' '}
                    </Row>

                    <hr />
                    <FlexRow between="xs">
                        <FlexCol xs={2}>
                            <Button color="success" onClick={this.save}>
                                Save
                            </Button>
                        </FlexCol>
                        {this.state.data.id > 0 ? (
                            <FlexCol xs={2}>
                                <Button color="danger" onClick={this.removeConfirm}>
                                    Delete
                                </Button>
                            </FlexCol>
                        ) : (
                            <div />
                        )}
                    </FlexRow>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
