import React from 'react';
import { connect } from 'react-redux';
import history from '../../../history';
import StoredLayout from 'layouts/StoredLayout';

import { Card, CardBody, Row, Col, Form, Button } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import InputFieldWithValidation from 'reactstrap-input';
import DialogModal from 'cyder/modals/DialogModal';
import moment from 'moment';

import { findById, getVoterInfoByElectionId } from 'actions/staff/election/action';
import { login } from 'actions/staff/election/scrutineer/action';

class ElectionsScrutineerLoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.back = this.back.bind(this);
        this.login = this.login.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.onScrutineer1IdChange = this.onScrutineer1IdChange.bind(this);
        this.onScrutineer2IdChange = this.onScrutineer2IdChange.bind(this);
        this.onScrutineer1KeyChange = this.onScrutineer1KeyChange.bind(this);
        this.onScrutineer2KeyChange = this.onScrutineer2KeyChange.bind(this);

        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);

        this.state = {
            loading: false,
            loadingLogin: false,
            scrutineer1Id: '',
            scrutineer1Key: '',
            scrutineer2Id: '',
            scrutineer2Key: '',
            data: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
            voterInfo: {},
        };
    }
    onScrutineer1IdChange(e) {
        this.setState({
            scrutineer1Id: e.target.value,
        });
    }
    onScrutineer2IdChange(e) {
        this.setState({
            scrutineer2Id: e.target.value,
        });
    }
    onScrutineer1KeyChange(e) {
        this.setState({
            scrutineer1Key: e.target.value,
        });
    }
    onScrutineer2KeyChange(e) {
        this.setState({
            scrutineer2Key: e.target.value,
        });
    }

    modalAction() {
        if (this.state.modal.type === 'alert') {
            this.modalToggle();
        }
    }
    negativeModalAction() {
        this.modalToggle();
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    showLoadingLogin(show) {
        this.setState({
            loadingLogin: show,
        });
    }
    login() {
        this.showLoadingLogin(true);
        this.props
            .login(
                this.state.scrutineer1Id,
                this.state.scrutineer1Key,
                this.state.scrutineer2Id,
                this.state.scrutineer2Key,
                this.props.match.params.electionid,
            )
            .then(res => {
                this.showLoadingLogin(false);
                if (res == null || res.data == null || res.data.length < 2) {
                    this.showModal('Error', 'Scrutineer is not valid.', 'alert', false, true);
                } else {
                    history.push(`/staff/elections/view/${this.props.match.params.electionid}/result`);
                }
            })
            .catch(error => {
                this.showLoadingLogin(false);
                this.showModal('Error', 'Scrutineer is not valid.', 'alert', false, true);
                throw error;
            });
    }
    back() {
        history.push(`/staff/elections/view/${this.props.match.params.electionid}`);
    }
    componentDidMount() {
        this.showLoading(true);
        this.props
            .findById(this.props.match.params.electionid)
            .then(res => {
                this.setState({
                    data: res.data,
                });
                this.showLoading(false);
            })
            .catch(error => {
                this.showLoading(false);
                throw error;
            });
    }
    render() {
        const ended = this.props.match.params.electionid === '1';
        return (
            <div>
                {this.state.loading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        <DialogModal
                            modalAction={this.modalAction}
                            modalOpen={this.state.modal.showModal}
                            actionToggleExternal={this.modalToggle}
                            modalBody={this.state.modal.modalMessage}
                            modalHeader={this.state.modal.modalHeader}
                            negativeButtonAction={this.negativeModalAction}
                            positiveButtonHide={this.state.modal.positiveButtonHide}
                            negativeButtonHide={this.state.modal.negativeButtonHide}
                        />

                        <Row className="mb-2" id="root">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3">
                                            <Col xs={2} className="justify-content-center">
                                                <Button className="my-1 primary-btn-style" block onClick={this.back}>
                                                    <i className="material-icons align-text-bottom">chevron_left</i> Back
                                                </Button>
                                            </Col>
                                            <Col xs={{ size: 8, offset: 0 }} className="text-center">
                                                <h1 style={{ marginBottom: '1em' }}>
                                                    <strong>Result Vault: {this.state.data.name}</strong>
                                                </h1>
                                                <div
                                                    style={{ marginBottom: '1em' }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.data.summary }}
                                                />
                                                <div dangerouslySetInnerHTML={{ __html: this.state.data.description }} />
                                                <br />
                                                <div>
                                                    <strong>Start:</strong> {moment(this.state.data.startDate).format('DD/MM/YYYY')}
                                                    &nbsp;&nbsp;&nbsp;
                                                    <strong>End:</strong> {moment(this.state.data.endDate).format('DD/MM/YYYY')}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col xs={6}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h4>
                                                <strong>
                                                    <i className="material-icons align-text-center">lock</i> First Scrutineer
                                                </strong>
                                            </h4>
                                            <InputFieldWithValidation
                                                type="text"
                                                label="Scrutineer ID *"
                                                validationObj={{}}
                                                value={this.state.scrutineer1Id}
                                                handleChange={this.onScrutineer1IdChange}
                                            />
                                            <InputFieldWithValidation
                                                type="password"
                                                label="Secret Key *"
                                                validationObj={{}}
                                                value={this.state.scrutineer1Key}
                                                handleChange={this.onScrutineer1KeyChange}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={6}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h4>
                                                <strong>
                                                    <i className="material-icons align-text-center">lock</i> Second Scrutineer
                                                </strong>
                                            </h4>
                                            <InputFieldWithValidation
                                                type="text"
                                                label="Scrutineer ID *"
                                                validationObj={{}}
                                                value={this.state.scrutineer2Id}
                                                handleChange={this.onScrutineer2IdChange}
                                            />
                                            <InputFieldWithValidation
                                                type="password"
                                                label="Secret Key *"
                                                validationObj={{}}
                                                value={this.state.scrutineer2Key}
                                                handleChange={this.onScrutineer2KeyChange}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.state.loadingLogin ? (
                                    <LoadingSpinner />
                                ) : (
                                    <Button className="my-1 primary-btn-style" onClick={this.login}>
                                        <i className="material-icons align-text-bottom">lock_open</i> Unlock
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        findById: id => {
            return dispatch(findById(id));
        },
        login: (scrutineer1Id, scrutineer1Key, scrutineer2Id, scrutineer2Key, electionId) => {
            return dispatch(login(scrutineer1Id, scrutineer1Key, scrutineer2Id, scrutineer2Key, electionId));
        },
        getVoterInfoByElectionId: id => {
            return dispatch(getVoterInfoByElectionId(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ElectionsScrutineerLoginPage));
