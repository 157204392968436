import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from '../../layouts/StoredLayout';
import history from '../../history';

import { Card, CardBody, FormGroup, Label, Input, Row, Col, Form, Button, Container } from 'reactstrap';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import { setJumbotronTitle } from '../../actions/pagedata';

import { setSurveyValue, submitAnswer } from 'actions/staff/survey/surveyAction';

class SurveysDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.submitAnswer = this.submitAnswer.bind(this);
        this.submitAnswerNoConfirmation = this.submitAnswerNoConfirmation.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.getSurveyData = this.getSurveyData.bind(this);
        this.state = {
            loading: true,
            showModal: false,
            modalMessage: '',
            modalHeader: '',
            modalAction: '',
        };
    }
    componentDidMount() {
        const { onRef, surveyid, setSurveyValue, staffSurvey } = this.props;
        if (onRef) onRef(this);
        if (surveyid || this.props.match.params.surveyid) {
            const surveyId = surveyid || this.props.match.params.surveyid;
            setSurveyValue(surveyId).then(res => {
                if (this._isMounted) {
                    this.setState({
                        survey: res.data,
                    });
                }
            });
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    getSurveyData() {
        return this.state.survey;
    }
    modalAction() {
        let postSurveyUrl = this.props.survey.postSurveyUrl;
        if (!postSurveyUrl) postSurveyUrl = this.props.postSurveyUrl;
        this.modalToggle();
        if (postSurveyUrl) history.push(postSurveyUrl);
    }
    modalToggle() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }
    showModal(header, message, action) {
        this.setState({
            showModal: true,
            modalMessage: message,
            modalHeader: header,
            modalAction: action,
        });
    }
    submitAnswerNoConfirmation(applicationId) {
        this.props.submitAnswerWithApplicationId(this.state.survey, applicationId).then(res => {});
    }
    submitAnswer() {
        let applicationId = this.props.applicationId;
        this.props
            .submitAnswer(this.state.survey, applicationId)
            .then(res => {
                console.log('res: ', res);
                if (res.error) {
                    this.showModal('Error', res.message);
                } else {
                    var postSurveyMessage = this.props.postSurveyMessage;
                    if (postSurveyMessage == null || postSurveyMessage == '') {
                        postSurveyMessage = 'Thank you for your participation, your responses are invaluable to us.';
                    }
                    console.log('postSurveyMessage: ', postSurveyMessage);
                    this.showModal('Confirmation', postSurveyMessage, () => {
                        history.push('/member/myaccount/dashboard');
                    });
                }
            })
            .catch(error => {
                this.showModal('Error', 'Failed to submit survey.');
                throw error;
            });
    }
    onInputChange(questionId, value) {
        var survey = { ...this.state.survey };
        survey.questions.forEach(question => {
            if (question.id === questionId) {
                question.answer = value;
            }
        });
        this.setState(survey);
    }
    render() {
        if (!this.state.survey) return <LoadingSpinner center />;
        return (
            <Container className="mt-4">
                <DialogModal
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.showModal}
                    positiveButtonHide={false}
                    negativeButtonHide={true}
                    modalAction={this.modalAction}
                    modalBody={this.state.modalMessage}
                    modalHeader={this.state.modalHeader}
                    modalAction={this.state.modalAction}
                />
                <RowColWrapper xs={12}>
                    <Card>
                        <CardBody>
                            <Form className="mb-12">
                                <RowColWrapper xs={12}>
                                    <h3 className="text-bold">{this.state.survey.surveyName}</h3>
                                    {/* <Col xs={6}>
                                            <span>
                                                <h3>Start</h3>
                                                {this.state.survey.startedOn}
                                            </span>
                                        </Col> */}
                                </RowColWrapper>
                                <br />
                                <RowColWrapper xs={12}>
                                    <div className="text-justify">{this.state.survey.surveyDescription}</div>
                                    {/*
                                    <div className="text-justify">
                                        We welcome and encourage your feedback to continually enhance the certification process. All
                                        feedback and comments received will be carefully reviewed and kept strictly confidential.
                                    </div>
                                    <Col xs={6}>
                                            <h3>End</h3>
                                            <span>
                                                {this.state.survey.finishedOn}
                                            </span>
                                        </Col> */}
                                </RowColWrapper>
                            </Form>
                            <hr />
                            <br />
                            <RowColWrapper xs={12}>
                                <QuestioniareComponent questionsArray={this.state.survey.questions} onChange={this.onInputChange} />
                            </RowColWrapper>
                            <RowColWrapper xs={12} className="mt-2">
                                <Button className="primary-btn-style" onClick={this.submitAnswer}>
                                    Submit
                                </Button>
                            </RowColWrapper>
                        </CardBody>
                    </Card>
                </RowColWrapper>
            </Container>
        );
    }
}
export const RowColWrapper = ({ xs, children, className }) => {
    return (
        <Row className={className}>
            <Col xs={xs}>{children}</Col>
        </Row>
    );
};
const QuestioniareComponent = ({ questionsArray, onChange }) => {
    return questionsArray.map((qn, i) => {
        const { questionText, questionTypeId, id, questionOptions } = qn;
        return (
            <Row
                key={i}
                style={{
                    marginBottom: '20px',
                }}
            >
                <Col xs={5}>
                    <h5 className="field-header">{questionText}</h5>
                    {questionTypeId === 2 ? (
                        <FormGroup tag="fieldset">
                            <Input
                                type="select"
                                id="exampleSelect"
                                onChange={e => {
                                    onChange(id, e.target.value);
                                }}
                            >
                                <option />
                                {questionOptions.map((option, idx) => {
                                    const { text } = option;
                                    return <option key={idx}>{text}</option>;
                                })}
                            </Input>
                        </FormGroup>
                    ) : (
                        <Input
                            rows={5}
                            type="textarea"
                            onChange={e => {
                                onChange(qn.id, e.target.value);
                            }}
                        />
                    )}
                </Col>
            </Row>
        );
    });
};

const mapStateToProps = (state, ownProps) => {
    return {
        staffSurvey: state.staffSurvey,
        survey: state.survey,
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
        setSurveyValue: surveyId => {
            return dispatch(setSurveyValue(surveyId));
        },
        submitAnswerWithApplicationId: (survey, applicationId) => {
            return dispatch(submitAnswer(survey, applicationId));
        },
        submitAnswer: (survey, applicationId) => {
            return dispatch(submitAnswer(survey, applicationId));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(SurveysDetailPage));
