import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Segment, Card, Input, Form, Divider } from 'semantic-ui-react';
import Box from '../components/Box';
import { mobile, tablet } from '../dummy';
import Loading from '../components/Loading';

const lowBox = { height: 100 }

class Product extends React.Component {
    state = { openPanel: false, selected: null }

    render() {
        const { categories, loading, onFetchProducts, onFetchLowVoc, history, subcategories } = this.props;
        const { loadingProductCategory, loadingAllProductTypes, loadingProductSubcategory } = this.props;
        const { openPanel, selected } = this.state

        if (loading || loadingProductCategory || loadingProductSubcategory || loadingAllProductTypes) return <Loading />
        let overallLoading = loadingProductCategory || loadingProductSubcategory || loadingAllProductTypes;

        const panel = (
        <Grid.Column width={12} style={{ paddingLeft: 0 }}>
            <Segment basic>
                <Card.Group itemsPerRow={mobile ? 1 : tablet ? 2 : 4} className={overallLoading ? "loading" : ""}>
                    {categories &&
                        categories.map(card => {
                            return (
                                <Box
                                    key={card.id}
                                    id={card.id}
                                    icon={card.icon}
                                    src={`/assets/icons/${card.filename}`}
                                    iconSize="small"
                                    header={card.category}
                                    history={history}
                                    // loading={loading}
                                    loadingProductCategory={loadingProductCategory}
                                    loadingAllProductTypes={loadingAllProductTypes}
                                    loadingProductSubcategory={loadingProductSubcategory}
                                    subcategories={subcategories}
                                    selected={selected}
                                    onFetchLowVoc={onFetchLowVoc}
                                    onFetchProducts={onFetchProducts}
                                />
                            );
                        })}
                </Card.Group>
            </Segment>
        </Grid.Column>)

        return (
            <Grid stackable centered className="category">
                {mobile || tablet ? <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                    {panel}
                </div> : panel}
            </Grid>
        );
    }
}

const mapState = ({ public: publicReducer }, ownProps) => {
    return {
        loadingProductCategory: publicReducer.loadingProductCategory,
        loadingProductSubcategory: publicReducer.loadingProductSubcategory,
        loadingAllProductTypes: publicReducer.loadingAllProductTypes, 
        ownProps,
    };
};

const actionList = {};

// prettier-ignore
export default connect(mapState, actionList)(Product)

// export default Product;
