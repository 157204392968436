// Overview Tab
export const STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST = 'STAFF_DIRECTORY_OVERVIEW_GET_COMPANY_INFO_REQUEST';
export const STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS = 'STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_SUCCESS';
export const STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE = 'STAFF_DIRECOTRY_OVERVIEW_GET_COMPANY_INFO_FAILURE';

export const STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS = 'STAFF_DIRECTORY_OVERVIEW_GET_MEMBER_INFO_SUCCESS';
export const STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS = 'STAFF_DIRECTORY_OVERVIEW_GET_CERTIFICATION_INFO_SUCCESS';
export const STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS = 'STAFF_DIRECTORY_OVERVIEW_GET_EVENT_INFO_SUCCESS';

export const STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE = 'STAFF_DIRECTORY_OVERVIEW_HANDLE_INPUT_CHANGE';
export const STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA = 'STAFF_DIRECTORY_OVERVIEW_SET_MODAL_DATA';
export const STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT = 'STAFF_DIRECTORY_OVERVIEW_RESET_TO_DEFAULT';

// Membership
export const STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_GET_CERTIFICATION_DETAILS_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_GET_CERTIFICATION_DETAILS_FAILURE';

export const STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_GET_MEMBERSHIP_LETTER_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBERSHIP_LETTER_FAILURE';

export const STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_TIER_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_TIER_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_TIER_FAILURE';

export const STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_PRINT_CERTIFICATION_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_PRINT_CERTIFICATION_FAILURE';

export const STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_RESUBMIT_QUESTIONAIRE_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESTIONAIRE_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_RESUBMIT_QUESSTIONAIRE_FAILURE';

export const STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_REGENERATE_CERTIFICATE_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_REGENERATE_CERTIFICATE_FAILURE';

export const STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_GET_MEMBER_APPLICATION_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA = 'STAFF_DIRECTORY_MEMBERSHIP_SET_EVENT_MODAL_DATA';

export const STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING = 'STAFF_DIRECTORY_MEMBERSHIP_SHOW_LOADING';

export const STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT = 'STAFF_DIRECTORY_MEMBERSHIP_RESET_TO_DEFAULT';

// Event
export const STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST = 'STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_REQUEST';
export const STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS = 'STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_SUCCESS';
export const STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE = 'STAFF_DIRECTORY_EVENTS_GET_EVENT_ATTENDED_FAILURE';

export const STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE = 'STAFF_DIRECTORY_EVENTS_HANDLE_INPUT_CHANGE';

// Team Management
export const STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST = 'STAFF_DIRECTORY_TEAM_GET_MEMBER_LIST_REQUEST';
export const STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS = 'STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_SUCCESS';
export const STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE = 'STAFF_DIRECTORY_EVENTS_GET_MEMBER_LIST_FAILURE';
export const STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST = 'STAFF_DIRECTORY_TEAM_GET_SUBSCRIBER_LIST_REQUEST';
export const STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS = 'STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_SUCCESS';
export const STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE = 'STAFF_DIRECTORY_EVENTS_GET_SUBSCRIBER_LIST_FAILURE';

// Login Activities
export const STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST = 'STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_REQUEST';
export const STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS = 'STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_SUCCESS';
export const STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE = 'STAFF_DIRECTORY_LOGGING_GET_LOGGING_ACTIVITY_FAILURE';

export const STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT = 'STAFF_DIRECTORY_LOGGING_RESET_TO_DEFAULT';

// Certification
export const FETCH_CERTIFICATE_LIST = 'FETCH_CERTIFICATE_LIST';

export const STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_PERIOD_REQUEST = 'STAFF_DIRECTORY_MEMBERHISP_UPDATE_MEMEBRSHIP_PERIOD_REQUEST';
export const STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_PERIOD_SUCCESS = 'STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMBERSHIP_PERIOD_SUCCESS';
export const STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_PERIOD_FAILURE = 'STAFF_DIRECTORY_MEMBERSHIP_UPDATE_MEMEBRSHIP_PERIOD_FAILURE';
