import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import React from 'react';
import moment from 'moment';
import { Label, Row, Col, Form, FormGroup, Button, Card, CardBody } from 'reactstrap';

const DatesComponent = props => {
    const style = {
        paddingLeft: '1px',
        paddingRight: '1px',
        paddingTop: '1px',
        paddingBottom: '1px',
    };
    return (
        <div>
            <h4>Event Date</h4>
            <br />
            <Row>
                <Col xs={12}>
                    <Form inline>
                        <FormGroup>
                            <Label>New Date</Label>
                            <CyderDatePicker
                                id="date"
                                value={moment(props.fields.date, 'DD/MM/YYYY')}
                                onChange={moment => props.handleEventInputChange(moment, 'date')}
                                dateFormat="DD/MM/YYYY"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Start Time for this day (optional)</Label>
                            <CyderDatePicker
                                id="startTimeForThisDay"
                                value={moment(props.fields.startTimeForThisDay, 'HH:mm')}
                                dateFormat={null}
                                timeFormat="HH:mm"
                                onChange={moment => props.handleEventInputChange(moment, 'startTimeForThisDay')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>End Time for this day (optional)</Label>
                            <CyderDatePicker
                                id="endTimeForThisDay"
                                value={moment(props.fields.endTimeForThisDay, 'HH:mm')}
                                dateFormat={null}
                                timeFormat="HH:mm"
                                onChange={moment => props.handleEventInputChange(moment, 'endTimeForThisDay')}
                            />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            <br />
            <Row className="mt-2">
                <Col>
                    <Button className="primary-btn-style" onClick={props.mode === 'update' ? props.updateEventDates : props.saveEventDates}>
                        Save Date
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default DatesComponent;
