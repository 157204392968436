import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from '../../../../layouts/StoredLayout';
import DialogModal from 'cyder/modals/DialogModal';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Col, Button, Card, CardBody, CardTitle, Badge, Label } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import StaffMembershipPredetermineExport from 'pages/staff/membership/export/StaffMembershipPredetermineExport';
import StaffMembershipDynamicExport from 'pages/staff/membership/export/StaffMembershipDynamicExport';
import { getMaxMinYear, getStaticExport } from '../../../../actions/staff/membership/export/staffMembershipExportAction';

import moment from 'moment';
import { exportCSVFile } from 'js/jsonToCsvExport';

export const CardWrapper = props => {
    return (
        <CardBody xs={12} sm={12} md={12}>
            {props.cardTitle ? (
                <h6 className="p-1">
                    <strong>{props.cardTitle}</strong>
                </h6>
            ) : null}
            <Row>
                <Col xs={12}>{props.children}</Col>
            </Row>
        </CardBody>
    );
};
export const ButtonComponent = props => {
    return (
        <CardWrapper>
            <Button block size="lg" className="primary-btn-style" onClick={props.onClick}>
                <Label sm={6}>{props.label}</Label>
            </Button>
        </CardWrapper>
    );
};
class StaffMembershipExportPage extends React.Component {
    constructor(props) {
        super(props);
        this.setActiveExport = this.setActiveExport.bind(this);
        this.exportData = this.exportData.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.showModalIf = this.showModalIf.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.state = {
            loading: false,
            activeExport: 'static',
            years: [],
            selectedStatus: {},
            selectedYear: {},
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.getRelevantStuffs();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuffs() {
        let years = [];
        try {
            const year = await this.props.getMaxMinYear();
            var min = year.data[0].minYear;
            var max = year.data[0].maxYear;

            for (max; max >= min; max--) {
                var obj = {};
                obj.label = max;
                obj.value = max;
                years.push(obj);
            }
            this.setState({
                years,
            });
        } catch (error) {
            throw error;
        }
    }

    async exportData() {
        var year = this.state.selectedYear.value;
        var type = this.state.selectedStatus.value;
        try {
            this.showLoading(true);
            const { data } = await this.props.getStaticExport(year, type);
            const hasError = this.showModalIf('Error', `Failed to export ${type} category file. Please try again later.`, !data);
            if (hasError) return;
            exportCSVFile(data, `${year}-membership-${type}.csv`);
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }

    showLoading(loading) {
        this.setState({ loading });
    }
    setActiveExport(activeExport) {
        this.setState({
            activeExport,
        });
    }

    // MODAL
    modalAction() {
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    onSelectChange(e, type) {
        var state = this.state;
        if (type === 'year') {
            state.selectedYear.label = e.label;
            state.selectedYear.value = e.value;
        } else if (type === 'status') {
            state.selectedStatus.label = e.label;
            state.selectedStatus.value = e.value;
        }
    }

    render() {
        const { modal, loading, activeExport } = this.state;
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    positiveButtonHide={modal.positiveButtonHide}
                    negativeButtonHide={modal.negativeButtonHide}
                    modalBody={modal.modalMessage}
                    modalHeader={modal.modalHeader}
                    modalOpen={modal.showModal}
                />
                <HeaderPanel activeExportType={activeExport} setActiveExport={this.setActiveExport} />
                {loading ? (
                    <LoadingSpinner center />
                ) : (
                    <React.Fragment>
                        {activeExport === 'static' && (
                            <StaffMembershipPredetermineExport
                                exportAction={this.exportData}
                                years={this.state.years}
                                onSelectChange={this.onSelectChange}
                            />
                        )}
                        {activeExport === 'dynamic' && (
                            <StaffMembershipDynamicExport showModalIf={this.showModalIf} years={this.state.years} />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const HeaderPanel = ({ activeExportType, setActiveExport }) => {
    const isStatic = activeExportType === 'static';
    const getClassName = evaluation => (evaluation ? 'primary-btn-style-selected' : 'primary-btn-style') + ' mr-2';
    return (
        <Row className="text-right">
            <Col>
                <ButtonIcon
                    className={getClassName(isStatic)}
                    onClick={() => setActiveExport('static')}
                    label="Standard Export"
                    icon="assignment"
                />
                <ButtonIcon
                    className={getClassName(!isStatic)}
                    onClick={() => setActiveExport('dynamic')}
                    label="Dynamic Export"
                    icon="autorenew"
                />
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMaxMinYear: () => {
            return dispatch(getMaxMinYear());
        },
        getStaticExport: (year, type) => {
            return dispatch(getStaticExport(year, type));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffMembershipExportPage));
