import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import fetchlib from 'js/fetchlib';
import withQuery from 'with-query';

export function staffCertificationExport(type, tick) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, { action: 'exportCompanies' });
        const body = {
            type,
            tick,
        };
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}

export function getDataList(body) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, { action: 'exportDynamicCompanies' });
        return fetchlib.fetchV2(dispatch, getState, 'POST', url, body, null, null, null);
    };
}
