import React from 'react';
import { Row, Col } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const ManagementRepresentativeComp = ({ linkText, link, section, type, rep }) => {
    return (
        <RowCardHOC link={link} linkText={linkText}>
            <h5>
                Section {section}: {type} Management Representative
            </h5>
            <br />
            <Row>
                <Col xs={6}>
                    <LabelItem label="First name" content={rep.firstName} />
                    <br />
                    <LabelItem label="Last name or Surname" content={rep.lastName} />
                    <br />
                    <LabelItem label="Department" content={rep.department} />
                    <br />
                    <LabelItem label="Designation" content={rep.designation} />
                    <br />
                    <LabelItem label="Mobile number" content={rep.mobileNumber} />
                </Col>
                <Col xs={6}>
                    <LabelItem label="Salutation" content={rep.salutation} />
                    <br />
                    <LabelItem label="Other salutation" content={rep.otherSalutation} />
                    <br />
                    <LabelItem label="Telephone DID" content={rep.telephoneDid} />
                    <br />
                    <LabelItem label="Email" content={rep.email} />
                </Col>
            </Row>
        </RowCardHOC>
    );
};
export default ManagementRepresentativeComp;
