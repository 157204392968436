import {
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST,
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS,
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS,
    EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE,
    EVENT_ATTENDEE_RESET_TO_DEFAULT,
    EVENT_ATTENDEE_HANDLE_INPUT_CHANGE,
    EVENT_ATTENDEE_SET_MODAL_DATA,
    EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST,
} from '../../../../actions/constants/events/actionTypesStaffEventsAttendee';

import { setReducerModalState, defaultModalKeys } from '../../../../js/modal';
import moment from 'moment';

// defaults
const defaultState = {
    mode: 'member',
    tablePages: -1,
    loading: false,
    attendees: [],
    keyword: '',
    data: {
        total: 0,
        attended: 0,
        yetToAttend: 0,
        percentAttended: 0,
    },
    ...defaultModalKeys,
};

export function eventsAttendeeReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST:
            let x = action.response.data;
            // Data calculation
            let attended = 0;
            x.forEach(attendee => {
                attendee.attended === 1 ? attended++ : (attended = attended);
            });
            newState.data = {
                attended,
                total: x.length,
                yetToAttend: x.length - attended,
                percentAttended: ((attended / x.length) * 100).toFixed(2),
            };
            newState.loading = false;
            return newState;
        case EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST:
            newState.loading = true;
            return newState;
        case EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS:
            const response = action.response.data;
            newState.attendees = response;
            // Map date to display format
            const mapDate = date => moment(date).format('MMM D, YYYY HH:mm');
            newState.attendees = response.map(attendee => {
                attendee.checkinTimestamp = attendee.checkinTimestamp ? mapDate(attendee.checkinTimestamp) : '-';
                // get attendee with active value equals 1
                if (new Boolean(attendee.active).valueOf) {
                    return attendee;
                }
            });
            // Data calculation
            // let attended = 0;
            // response.forEach(attendee => {
            //     attendee.attended === 1 ? attended++ : (attended = attended);
            // });
            // newState.data = {
            //     attended,
            //     total: response.length,
            //     yetToAttend: response.length - attended,
            //     percentAttended: ((attended / response.length) * 100).toFixed(2),
            // };
            newState.loading = false;
            return newState;
        case EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE:
            newState.loading = false;
            return newState;
        case EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST:
            newState.modalLoading = true;
            return newState;
        case EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalBody = 'Attendee has been successfully checked in';
            return newState;
        case EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE:
            // Set modal state
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalOnlyOneButton = true;
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to check in attendee. Please try again.';
            return newState;
        case EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalBody = 'Attendee waiting status has been successfully updated';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE:
            newState.modalLoading = false;
            newState.modalAction = 'forceUpdate';
            newState.modalHeader = 'Error';
            newState.modalBody = 'Failed to update attendee waiting status. Please try again.';
            newState.modalOnlyOneButton = true;
            return newState;
        case EVENT_ATTENDEE_HANDLE_INPUT_CHANGE:
            newState.keyword = action.value;
            return newState;
        case EVENT_ATTENDEE_RESET_TO_DEFAULT:
            newState = Object.assign({}, defaultState);
            return newState;
        case EVENT_ATTENDEE_SET_MODAL_DATA:
            newState = setReducerModalState(newState, action.data);
            return newState;
        default:
            return state;
    }
}
