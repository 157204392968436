import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../history';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col, Form, Button } from 'reactstrap';
import { Row as FlexRow, Col as FlexCol } from 'react-flexbox-grid';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import DialogModal from 'cyder/modals/DialogModal';
import Select from 'react-select';
import InputField, { ButtonComponent } from 'pages/admin/companies/shared/InputField';

import { save, update, remove, load, getAllProductSubCategories } from 'actions/admin/product/product-type/action.js';
import { getProductCategories } from 'actions/admin/product/product-sub-category/action.js';

import { sanitizeStringAndObjectNullsToEmptyStrings } from 'js/util';
import AuditListPopup from 'pages/staff/audit/AuditListPopup';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = true;
        this.assessorIdChange = this.assessorIdChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onProdCategoriesChange = this.onProdCategoriesChange.bind(this);
        this.onProdSubCategoriesChange = this.onProdSubCategoriesChange.bind(this);
        this.assessmentScoresheetTemplateIdChange = this.assessmentScoresheetTemplateIdChange.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.save = this.save.bind(this);
        this.remove = this.remove.bind(this);
        this.removeConfirm = this.removeConfirm.bind(this);
        this.modalToggle = this.modalToggle.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.modalAction = this.modalAction.bind(this);
        this.negativeModalAction = this.negativeModalAction.bind(this);
        this.state = {
            loading: false,
            prodCategories: [],
            prodSubCategories: [],
            statuses: [{ label: 'Active', value: 1 }, { label: 'InActive', value: 0 }],
            data: {
                subcategoryId: '',
                categoryId: '',
                assessorId: '',
                assessmentScoresheetTemplateId: '',
                type: '',
                status: 1,
                id: 0,
            },
            modal: {
                showModal: false,
                modalMessage: '',
                modalHeader: '',
                positiveButtonHide: true,
                negativeButtonHide: true,
                type: 'save',
            },
        };
    }
    showLoading(loading) {
        this.setState({
            loading,
        });
    }
    back() {
        history.replace('/admin/product/product-type');
    }
    modalAction() {
        if (this.state.modal.type === 'deleteConfirm') {
            this.remove();
            return;
        }
        this.modalToggle();
    }
    negativeModalAction() {
        this.modalToggle();
    }
    showModalIf(header, message, evaluation) {
        if (evaluation) this.showModal(header, message, 'Alert', null, true);
        return evaluation;
    }
    modalToggle() {
        var modal = this.state.modal;
        modal.showModal = !modal.showModal;
        this.setState({
            modal,
        });
    }
    showModal(header, message, type, positiveButtonHide, negativeButtonHide) {
        var modal = this.state.modal;
        modal.showModal = true;
        modal.modalHeader = header;
        modal.type = type;
        modal.positiveButtonHide = positiveButtonHide;
        modal.negativeButtonHide = negativeButtonHide;
        modal.modalMessage = message;
        this.setState({
            modal,
        });
    }
    componentDidMount() {
        const tasks = [this.getProductCategories(), this.getProductSubCategories(), this.getRelevantStuff()];
        this.showLoading(true);
        Promise.all(tasks).then(() => this.showLoading(false));
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getRelevantStuff() {
        try {
            const { id } = this.props.match.params;
            if (!id || id === '0') return;
            const { data, error, message } = await this.props.load(id);
            const evaluation = error || !data || !data.id;
            const hasError = this.showModalIf('Error', message, evaluation);
            if (hasError) return;
            if (this._isMounted) {
                data.status = data.active;
                this.setState({
                    data: sanitizeStringAndObjectNullsToEmptyStrings(data),
                });
            }
        } catch (error) {
            throw error;
        }
    }
    async getProductCategories() {
        try {
            const { data } = await this.props.getProductCategories();
            if (this._isMounted) {
                this.setState({
                    prodCategories: data,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    async getProductSubCategories() {
        try {
            const { data } = await this.props.getAllProductSubCategories();
            if (this._isMounted) {
                this.setState({
                    prodSubCategories: data,
                });
            }
        } catch (error) {
            throw error;
        }
    }
    prepareDataBeforeSave(data) {
        var newData = data;
        newData.active = data.status;
        return newData;
    }
    async save() {
        try {
            this.showLoading(true);
            const { save, update } = this.props;
            const data = this.prepareDataBeforeSave(this.state.data);
            const isSave = !data.id || data.id === 0;
            const { error, message } = isSave ? await save(data) : await update(data);
            const hasError = this.showModalIf('Error', message, error);
            if (hasError) return;
            history.replace('/admin/product/product-type');
        } catch (error) {
            this.showModalIf('Error', error.message, true);
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    removeConfirm() {
        this.showModal('Confirmation', 'Are you sure you want to delete this record?', 'deleteConfirm', false, false);
    }
    remove() {
        var data = this.state.data;
        this.props
            .remove(data.id)
            .then(res => {
                history.replace('/admin/product/product-type');
            })
            .catch(error => {
                this.showModal('Error', error.message, 'delete', true, true);
                throw error;
            });
    }
    assessorIdChange(e) {
        var data = this.state.data;
        data.assessorId = e.target.value;
        this.setState({ data });
    }
    assessmentScoresheetTemplateIdChange(e) {
        var data = this.state.data;
        data.assessmentScoresheetTemplateId = e.target.value;
        this.setState({ data });
    }
    typeChange(e) {
        var data = this.state.data;
        data.type = e.target.value;
        this.setState({ data });
    }
    onProdCategoriesChange(value) {
        const data = {
            ...this.state.data,
            categoryId: value.id,
        };
        this.setState({ data });
    }
    onProdSubCategoriesChange(value) {
        const data = {
            ...this.state.data,
            subcategoryId: value.id,
        };
        this.setState({ data });
    }
    onStatusChange(value) {
        const data = {
            ...this.state.data,
            status: value.value,
        };
        this.setState({ data });
    }
    render() {
        if (this.state.loading) return <LoadingSpinner center />;
        return (
            <Col>
                <DialogModal
                    modalAction={this.modalAction}
                    negativeButtonAction={this.negativeModalAction}
                    actionToggleExternal={this.modalToggle}
                    modalOpen={this.state.modal.showModal}
                    positiveButtonHide={this.state.modal.positiveButtonHide}
                    negativeButtonHide={this.state.modal.negativeButtonHide}
                    modalBody={this.state.modal.modalMessage}
                    modalHeader={this.state.modal.modalHeader}
                />
                <Form>
                    <Row className="mb-2">
                        <Col xs={12} className="d-flex">
                            <Button className="primary-btn-style-outline" onClick={this.back}>
                                <i className="material-icons align-text-bottom">chevron_left</i>
                                Back
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <hr />
                <InputField
                    label="ID"
                    inputProps={{
                        type: 'text',
                        id: 'id',
                        style: { width: '100px' },
                        disabled: true,
                        value: this.state.data.id,
                    }}
                />
                <InputField
                    label="Type"
                    instruction="Requires alphanumeric"
                    inputProps={{
                        type: 'text',
                        id: 'type',
                        style: { width: '600px' },
                        value: this.state.data.type,
                        onChange: this.typeChange,
                    }}
                />
                {/* <InputField
                    label="Criteria ID"
                    instruction="Requires numeric"
                    inputProps={{
                        type: 'text',
                        id: 'assessmentScoresheetTemplateId',
                        style: { width: '100px' },
                        onChange: this.assessmentScoresheetTemplateIdChange,
                        value: this.state.data.assessmentScoresheetTemplateId,
                    }}
                />
                <InputField
                    label="Assessor ID"
                    instruction="Requires numeric"
                    inputProps={{
                        type: 'text',
                        style: { width: '100px' },
                        id: 'assessorId',
                        value: this.state.data.assessorId,
                        onChange: this.assessorIdChange,
                    }}
                /> */}
                <InputField label="Product Category" instruction="Select product category">
                    <div style={{ width: '600px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            options={this.state.prodCategories}
                            onChange={this.onProdCategoriesChange}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.category}
                            value={this.state.prodCategories.find(x => x.id === this.state.data.categoryId)}
                        />
                    </div>
                </InputField>
                <InputField label="Product Sub Category" instruction="Select product sub category">
                    <div style={{ width: '600px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            options={this.state.prodSubCategories}
                            onChange={this.onProdSubCategoriesChange}
                            getOptionValue={option => option.id}
                            getOptionLabel={option => option.subcategory}
                            value={this.state.prodSubCategories.find(x => x.id === this.state.data.subcategoryId)}
                        />
                    </div>
                </InputField>

                <InputField label="Status">
                    <div style={{ width: '120px' }}>
                        <Select
                            autofocus
                            isLoading={this.state.loading}
                            options={this.state.statuses}
                            onChange={this.onStatusChange}
                            getOptionValue={option => option.value}
                            getOptionLabel={option => option.label}
                            value={this.state.statuses.find(x => x.value === this.state.data.status)}
                        />
                    </div>
                </InputField>
                <hr />
                <FlexRow between="xs">
                    <ButtonComponent label="Save" className="primary-btn-style" onClick={this.save} />
                    {this.state.data.id > 0 ? (
                        <FlexCol xs={2} className="d-flex justify-content-end">
                            <Button className="primary-btn-style-outline" onClick={this.removeConfirm}>
                                Delete
                            </Button>
                            <AuditListPopup contentTypeId={33} objectId={this.state.data.id} />
                        </FlexCol>
                    ) : null}
                </FlexRow>
            </Col>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        save: data => {
            return dispatch(save(data));
        },
        update: data => {
            return dispatch(update(data));
        },
        remove: id => {
            return dispatch(remove(id));
        },
        load: id => {
            return dispatch(load(id));
        },
        getProductCategories: () => {
            return dispatch(getProductCategories());
        },
        getAllProductSubCategories: () => {
            return dispatch(getAllProductSubCategories());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AddPage));
