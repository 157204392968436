import React from 'react';
import { Button } from 'reactstrap';

const EditButton = ({ onClick, text }) => (
    <Button className={text ? `primary-btn-style primary-btn-xs` : 'primary-btn-style'} onClick={onClick}>
        <i className="material-icons align-text-bottom ">edit</i>
        {text && (
            <React.Fragment>
                {` `}
                {text}
            </React.Fragment>
        )}
    </Button>
);

export default EditButton;
