import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';
import { Row, Col, Button, Card, CardBody, CardTitle, Badge, Label } from 'reactstrap';
import { SGBC_S3_ROOT, SGBC_INVOICE_DOWNLOAD_ROOT, SGBC_S3_HOST, SGBC_S3_CERT_LOGO_ROOT } from 'config';

// Subcategory component
const SubCategoryComponent = props => {
    if (props.data.length === 0) {
        return <CardBody>None</CardBody>;
    }
    // Reform subCategory data structure
    const subCategory = {};
    props.data.forEach(object => {
        if (!subCategory[object.mainCategoryName]) {
            subCategory[object.mainCategoryName] = [];
        }
        subCategory[object.mainCategoryName].push(object.subCategoryName);
    });
    // Generate component
    return Object.keys(subCategory).map((mainCategoryName, i) => {
        return (
            <CardBody key={i}>
                <u>{mainCategoryName}</u>
                <ul>
                    {subCategory[mainCategoryName].map((subCategoryName, i) => {
                        return <li key={i}>{subCategoryName}</li>;
                    })}
                </ul>
            </CardBody>
        );
    });
};

let established_logo = `/assets/sgbc/SGBC Established Member Logo.png`;
let founding_logo = `/assets/sgbc/SGBC Founding Member Logo.png`;
let member_logo = `/assets/sgbc/SGBC Member Logo.png`;

// Overview companent
const MemberOverviewPanel = ({
    count,
    companyInfo,
    memberInfo,
    companyId,
    subCategory,
    businessActivities,
    getBusinessActivityName,
    mainCategories,
    getMainCategoryName,
}) => {
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardTitle className="p-3 d-flex">
                        <span>
                            {' '}
                            <i className="fa fa-info-circle fa-fw" /> Corporate Information
                        </span>
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col xs={12} sm={12} md={12}>
                                {companyInfo.logoLocation != null && companyInfo.logoLocation != 'Not specified' && (
                                    <Image src={SGBC_S3_ROOT + companyInfo.logoLocation} size="small" circular />
                                )}
                                <br />
                                <div>
                                    <Row>
                                        <Col>
                                            <div style={{ paddingTop: '20%' }}>
                                                <h3>{companyInfo.name}</h3>
                                            </div>
                                        </Col>
                                        <Col className="ml-auto d-inline-flex">
                                            <div className="ml-auto mr-4 d-inline-flex">
                                                {memberInfo.membershipType === 'Founding Member' ? (
                                                    <Image src={founding_logo} size="small" />
                                                ) : memberInfo.membershipType === 'Established Member' ? (
                                                    <Image src={established_logo} size="small" />
                                                ) : (
                                                    <Image src={member_logo} size="small" />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <hr />
                                <br />
                                <h6>
                                    <i className="fa fa-chrome fa-fw" />
                                    &nbsp;
                                    {companyInfo.website === 'Website' || companyInfo.website === 'Not specified' ? (
                                        <span>{companyInfo.website}</span>
                                    ) : (
                                        <a href={'https://' + companyInfo.website}>{companyInfo.website}</a>
                                    )}
                                </h6>
                                <h6>
                                    <i className="fa fa-phone fa-fw" />
                                    &nbsp;
                                    <span>{companyInfo.mainPhoneNumber}</span>
                                </h6>
                                <h6>
                                    <i className="fa fa-envelope-o fa-fw" />
                                    &nbsp;
                                    {companyInfo.generalEmail === 'Email' || companyInfo.generalEmail === 'Not specified' ? (
                                        <span>{companyInfo.generalEmail}</span>
                                    ) : (
                                        <a href={'mailto:' + companyInfo.generalEmail}>{companyInfo.generalEmail}</a>
                                    )}
                                </h6>
                                <h6>
                                    <i className="fa fa-map-marker fa-fw" />
                                    &nbsp;
                                    <span>
                                        {companyInfo.businessAddressLine1 != 'Not specified' && (
                                            <React.Fragment>
                                                {companyInfo.businessAddressLine1}
                                                <br />
                                            </React.Fragment>
                                        )}
                                        {companyInfo.businessAddressLine2 != 'Not specified' && (
                                            <React.Fragment>
                                                {companyInfo.businessAddressLine2}
                                                <br />
                                            </React.Fragment>
                                        )}
                                        {companyInfo.businessAddressLine3 != 'Not specified' && (
                                            <React.Fragment>
                                                {companyInfo.businessAddressLine3}
                                                <br />
                                            </React.Fragment>
                                        )}
                                        {companyInfo.businessPostalCode != 'Not specified' && (
                                            <React.Fragment>
                                                {companyInfo.businessPostalCode}
                                                <br />
                                            </React.Fragment>
                                        )}
                                    </span>
                                </h6>
                                <br />
                                <hr />
                                <br />
                                <h6>
                                    <strong>Organisation Primary Business Activity</strong>
                                </h6>
                                <CardBody>{getMainCategoryName(mainCategories, companyInfo.organisationMainCategoryId)}</CardBody>
                                <br />
                                <br />
                                <h6>
                                    <strong>Services offered by organisation</strong>
                                </h6>
                                <SubCategoryComponent data={subCategory} />
                                <br />
                                <br />
                                <i className="fa fa-quote-left fa-2x fa-fw faded-less" />
                                &nbsp;&nbsp;
                                <Label>{companyInfo.greenBuildingMovementInvolvement}</Label>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default MemberOverviewPanel;
