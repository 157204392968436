import { SGBC_API_KEY, SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config';
import fetchlib from 'js/fetchlib';
import {
    COMPANY_SEARCH_REQUEST,
    COMPANY_SEARCH_SUCCESS,
    COMPANY_SEARCH_FAILURE,
    COMPANY_KEYWORD_SEARCH_REQUEST,
    COMPANY_KEYWORD_SEARCH_SUCCESS,
    COMPANY_KEYWORD_SEARCH_FAILURE,
    COMPANY_TOGGLE_CHECKBOX,
    COMPANY_TOGGLE_CHECKBOX_ALL,
    COMPANY_CHANGE_INPUT_KEYWORD_VALUE,
} from '../../constants/actionTypesCompanies';
import withQuery from 'with-query';

export function companySearchAction() {
    return (dispatch, getState) => {
        dispatch({ type: COMPANY_SEARCH_REQUEST });

        const url = withQuery(SGBC_API_ROOT + '/misc_v1/company', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const test = { name: 'TEST1', iso: 'T1', userid: 1 };
        const options = {
            body: JSON.stringify(test),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: COMPANY_SEARCH_FAILURE, error });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: COMPANY_SEARCH_SUCCESS, response });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: COMPANY_SEARCH_FAILURE, error });
            });
    };
}
export function companyKeywordSearchAction(inputKeywordValue) {
    return (dispatch, getState) => {
        dispatch({ type: COMPANY_KEYWORD_SEARCH_REQUEST, inputKeywordValue });

        const url = withQuery(SGBC_API_ROOT + '/misc_v1/company', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'search',
        });
        const body = {
            keyword: inputKeywordValue,
        };
        const options = {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'x-api-key': SGBC_API_KEY,
                'Content-Type': 'application/json',
                Authorization: 'allow',
            },
        };
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({
                            type: COMPANY_KEYWORD_SEARCH_FAILURE,
                            error,
                        });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({
                            type: COMPANY_KEYWORD_SEARCH_SUCCESS,
                            response,
                        });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: COMPANY_KEYWORD_SEARCH_FAILURE, error });
            });
    };
}
export function toggleCheckboxes(id) {
    return {
        type: COMPANY_TOGGLE_CHECKBOX,
        id,
    };
}
export function toggleCheckboxesAll() {
    return {
        type: COMPANY_TOGGLE_CHECKBOX_ALL,
    };
}
export function changeInputKeywordValue(inputKeywordValue) {
    return {
        type: COMPANY_CHANGE_INPUT_KEYWORD_VALUE,
        inputKeywordValue,
    };
}

export function load(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'find-by-id',
            id,
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function updateUenName(data) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/companies_v1/cn/companies`, {
            action: 'update-uen-name',
        });

        return fetchlib.fetch(dispatch, getState, 'POST', url, data, null, null, null);
    };
}
