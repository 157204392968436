import React from 'react';
import { connect } from 'react-redux';

import '../../css/cyder/search/search-panel.css';

class SearchPanel extends React.Component {
    render() {
        return (
            <div className="search-panel">
                <br />
                {this.props.items &&
                    this.props.items.map((item, i) => (
                        <p key={i}>
                            <a href="#">
                                <span className="title">{item.title}</span>
                            </a>
                            <br />
                            <span className="description">
                                {item.description}
                            </span>
                            <hr />
                        </p>
                    ))}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchPanel);
