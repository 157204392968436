import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const debugMode = true;

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
};

const PrivateRoute = ({ component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            // Render component
            if (debugMode || props.loggedIn) return renderMergedProps(component, props, rest);
            // Redirect to error page
            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            );
        }}
    />
);

const mapStateToProps = state => ({
    loggedIn: state.cyderLoginReducer.loggedIn,
});
export default connect(
    mapStateToProps,
    {},
)(PrivateRoute);
