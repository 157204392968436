import StoredLayout from '../../layouts/StoredLayout';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../cyder/modals/DialogModal';
import LegendIcon from '../../cyder/media/LegendIcon';
import CyderHeaderPanel from '../../cyder/panels/CyderHeaderPanel';
import CyderDatePicker from '../../cyder/forms/CyderDatePicker';
import PublicCompanyDetailsPagePanel from './PublicCompanyDetailsPagePanel';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../history';
import withQuery from 'with-query';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    CardTitle,
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    FormText,
    Button,
    Card,
    CardBody,
    CardText,
    CardHeader,
    Badge,
    Collapse,
    Table,
} from 'reactstrap';

import '../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import moment from 'moment';

class StaffCompanyDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
        };
    }
    componentDidMount() {
        moment.relativeTimeThreshold('d', 999);
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }
    render() {
        const ed = this.props.renewal.expiry_date;
        const expireText =
            moment().diff(ed) * -1 < 0 ? 'Expired' : 'Expires' + ' ' + moment.duration(moment().diff(ed) * -1).humanize(true);
        const TabItem = ({ id, icon, text }) => {
            return (
                <NavItem>
                    <NavLink
                        className={this.state.activeTab === id ? 'active' : null}
                        onClick={() => {
                            this.toggle(id);
                        }}
                    >
                        <i className={'fa ' + icon} /> {text}
                    </NavLink>
                </NavItem>
            );
        };
        return <PublicCompanyDetailsPagePanel />;
    }
}

const RenewalTableRow = ({ title, email }) => {
    return (
        <tr>
            <td className="align-middle">{email ? <Link to="#">{title}</Link> : <span>{title}</span>}</td>
            <td>
                <Button style={{ padding: '0.20rem' }} color="success" size="sm" id="generateRenewalLetterKey">
                    {email ? 'Send Now' : 'Generate'}
                </Button>
            </td>
        </tr>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.staffCompanyDetailsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleCheckbox: id => {
            // dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            // dispatch(toggleCheckboxesAll());
        },
        getGroups: (page, pageSize, sorted, filtered) => {
            // dispatch(
            //     staffCompanySearchAction(page, pageSize, sorted, filtered),
            // );
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(StaffCompanyDetailsPage));
