import React from 'react';
import { connect } from 'react-redux';

import TableWidgetMembershipApplicationsWidget from '../../table/TableWidgetMembershipApplicationsWidget';

class MembershipApplications extends React.Component {
    render() {
        return (
            <TableWidgetMembershipApplicationsWidget
                items={this.props.applications}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        applications: state.staff.dashboardInfo.memberships.applications,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MembershipApplications);
