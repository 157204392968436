import StoredLayout from 'layouts/StoredLayout';
import React from 'react';
import { Container } from 'reactstrap';

// SGBC custom components
import PayNowPanel from './components/PayNowPanel';

class CertificatesPayNowPage extends React.Component {
    render() {
        return (
            <Container>
                <PayNowPanel {...this.props} />
            </Container>
        );
    }
}

export default StoredLayout(CertificatesPayNowPage);
