import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { AnalyticsBigText, AnalyticsNormalText, AnalyticsBigTextWithNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';

import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';

import { getNewMembershipApplicationAnalytic } from 'actions/analytics/memberAnalyticsAction';

class NewMembersAnalytic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.load().then(res => {
            this.setState({
                data: res.data,
            });
        });
    }

    render() {
        const xs = {
            bigxs: 4,
            normalxs: 8,
        };
        const getCustomBigText = (text, color) => {
            return <h4 style={{ color }}>{text}</h4>;
        };
        const getCustomNormalText = (text, color) => {
            return <span style={{ color, paddingTop: '7px' }}>{text}</span>;
        };
        return (
            <AnalyticsCard title={`New Members Analytics`} height={100}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <Row>
                            <Col xs={12}>
                                <AnalyticsNormalText text={`New Members in ${this.state.data[0][0].thisYear}`} />
                                <Row>
                                    <Col xs={6}>
                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('0.5-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[0][0].count, '#f8ac59')}
                                            {...xs}
                                        />

                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('1.5-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[0][1].count, '#78BE20')}
                                            {...xs}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('1-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[0][2].count, '#f8ac59')}
                                            {...xs}
                                        />

                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('2-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[0][3].count, '#78BE20')}
                                            {...xs}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12}>
                                <AnalyticsNormalText text={`New Members in ${this.state.data[1][0].lastYear}`} />
                                <Row>
                                    <Col>
                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('0.5-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[1][0].count, '#f8ac59')}
                                            {...xs}
                                        />

                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('1.5-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[1][1].count, '#78BE20')}
                                            {...xs}
                                        />
                                    </Col>
                                    <Col>
                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('1-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[1][2].count, '#f8ac59')}
                                            {...xs}
                                        />

                                        <AnalyticsBigTextWithNormalText
                                            customNormalText={getCustomNormalText('2-Year', 'unset')}
                                            customBigText={getCustomBigText(this.state.data[1][3].count, '#78BE20')}
                                            {...xs}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getNewMembershipApplicationAnalytic());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(NewMembersAnalytic));
