import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Form, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { validateEncryption, changeEncryptionPassword } from '../../actions/public/index';
import LoadingSpinner from '../../cyder/loadingSpinner/LoadingSpinner';
class ResetPassword extends React.Component {
    handleSubmitResetPassword = values => {
        const url = this.props.location.pathname;
        const encrypted = url.split('/')[3];
        var obj = {
            password: values.confirmPassword,
            encrypted: encrypted,
        };
        this.props.changeEncryptionPassword(obj);
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            validate: false,
        };
    }

    componentDidMount() {
        this.validateEncryption();
    }

    async validateEncryption() {
        const url = this.props.location.pathname;
        const encrypted = url.split('/')[3];
        var bodyJson = { encrypted };
        var result = await this.props.validateEncryption(bodyJson);
        var loading = false;
        var validate = result.data;
        this.setState({
            loading,
            validate,
        });
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : this.state.validate ? (
            <Grid centered columns={3} className="reset-password">
                <Grid.Column>
                    <Header content="Reset Password" />
                    <Formik
                        initialValues={{ password: '', confirmPassword: '' }}
                        onSubmit={values => this.handleSubmitResetPassword(values)}
                        validate={validate}
                        render={({ values, handleSubmit, errors, touched, handleChange, handleBlur }) => (
                            <Form onSubmit={handleSubmit}>
                                <Form.Field>
                                    <Form.Input
                                        value={values.password}
                                        name="password"
                                        type="password"
                                        placeholder="Your new password"
                                        label="Password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.password && touched.password && <span className="help-text">{errors.password}</span>}
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        value={values.confirmPassword}
                                        name="confirmPassword"
                                        type="password"
                                        placeholder="Confirm your new password"
                                        label="Confirm password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {errors.confirmPassword && touched.confirmPassword && (
                                        <span className="help-text">{errors.confirmPassword}</span>
                                    )}
                                </Form.Field>
                                <Button fluid type="submit" content="Submit" className="btn primary-btn-style" icon="plus" />
                            </Form>
                        )}
                    />
                </Grid.Column>
            </Grid>
        ) : (
            <Grid centered columns={3} style={{ marginTop: '3em' }} className="reset-password">
                <Grid.Column>
                    <Header content="Reset Password" subheader="This link has either expired or used." />
                </Grid.Column>
            </Grid>
        );
    }
}
const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    validateEncryption: data => {
        return dispatch(validateEncryption(data));
    },
    changeEncryptionPassword: data => {
        return dispatch(changeEncryptionPassword(data));
    },
});

const validate = values => {
    let errors = {};

    if (!values.password) errors.password = 'This is required';
    else if (values.password.length < 6) errors.password = 'This should be at least 6 characters';
    if (!values.confirmPassword) errors.confirmPassword = 'This is also required';
    else if (values.confirmPassword !== values.password) errors.confirmPassword = 'Should be equal to the above value';

    return errors;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResetPassword);
