import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';
import moment from 'moment';

import { Container, Row, Col, FormGroup, Button, Input, Nav, NavItem, NavLink } from 'reactstrap';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { memberGetMemberEventList, memberGetMemberUpcomingEventList } from 'actions/member/events/event/action.js';
import { personalProfileGetProfileInfo } from 'actions/profile/personalProfileAction';

const FilterNavComponentWrapper = props => {
    return (
        <div>
            <Nav pills>{props.children}</Nav>
        </div>
    );
};
class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.getMemberEventList = this.getMemberEventList.bind(this);
        this.changeInputKeywordValue = this.changeInputKeywordValue.bind(this);
        this.setEventType = this.setEventType.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            keyword: '',
            activeTab: 'myevent',
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this._isMounted = true;
        this.props.getPersonalProfile();
    }
    setEventType(type) {
        this.setState(
            {
                activeTab: type,
            },
            () => this.table.fireFetchData(),
        );
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    changeInputKeywordValue(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    getMemberEventList(page, pageSize, sorted, filtered) {
        this.showLoading(true);
        let fn = this.props.getMemberEventList;
        if (this.state.activeTab == 'upcomingevent') {
            fn = this.props.getMemberUpcomingEventList;
        }

        fn(this.state.keyword, page, pageSize, sorted, filtered)
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    showLoading(show) {
        this.setState({ loading: show });
    }
    toggleTab(tab) {
        this.setState({
            activeTab: tab,
            loading: true,
        });
        return Promise.resolve();
    }
    renderNav(navItems) {
        return navItems.map(item => {
            return (
                <NavItem key={item.key} className="mr-2">
                    <NavLink
                        className={this.state.activeTab === item.key ? 'active p-2' : 'p-2'}
                        onClick={() => {
                            this.toggleTab(item.key).then(() => {
                                this.setEventType(item.key);
                            });
                        }}
                    >
                        <strong className="field-header">{item.text}</strong>
                    </NavLink>
                </NavItem>
            );
        });
    }
    render() {
        var typeNavs = [
            {
                key: 'myevent',
                text: 'Registered Events',
            },
            {
                key: 'upcomingevent',
                text: 'SGBC Upcoming Events',
            },
        ];
        let columns = [
            {
                Header: 'Date',
                accessor: 'eventDates',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value == null || props.value == '0000-00-00') {
                        return '-';
                    } else {
                        // return moment(props.value, 'YYYY-MM-DD').format('DD MMM YYYY');
                        return props.value.split(',').map((dateRecord, i) => (
                            <React.Fragment>
                                {moment(dateRecord.trim()).format('DD MMM YYYY')}
                                <br />
                            </React.Fragment>
                        ));
                    }
                },
                maxWidth: 200,
            },
            {
                Header: 'Name',
                accessor: 'name',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    return (
                        <Link style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }} to={'/member/event/rsvp/' + props.original.id}>
                            {props.value}
                        </Link>
                    );
                },
            },
            {
                Header: 'Total Attended',
                accessor: 'attended',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 150,
                Cell: props => (
                    <Link
                        style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                        to={'/member/event/' + props.original.id + '/attendance/'}
                    >
                        {props.value}
                    </Link>
                ),
            },
            {
                Header: 'Total Registered',
                accessor: 'totalCount',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                maxWidth: 150,
                Cell: props => (
                    <Link
                        style={{ fontWeight: 'bolder', color: 'rgb(49, 116, 70)' }}
                        to={'/member/event/' + props.original.id + '/registration/'}
                    >
                        {props.value}
                    </Link>
                ),
            },
            // {
            //     Header: '',
            //     accessor: 'id',
            //     maxWidth: 50,
            //     sortable: false,
            //     Cell: props => {
            //         return (
            //             <Link to={'/member/event/rsvp/' + props.value}>
            //                 <Button size="sm" color="info" className="primary-btn-style">
            //                     <i className="fa fa-1x fa-info-circle" />
            //                 </Button>
            //             </Link>
            //         );
            //     },
            // },
        ];
        if (this.state.activeTab === 'upcomingevent') columns.splice(2, 3);
        return (
            <Container>
                <br />
                <Row className="mb-2">
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} className="d-flex">
                                <h2>
                                    <strong>EVENTS</strong>
                                </h2>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <div className="form-inline d-flex">
                            <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    style={{ width: '200px' }}
                                    id="search-keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    placeholder="Keyword"
                                    value={this.state.keyword}
                                    onChange={this.changeInputKeywordValue}
                                />
                            </FormGroup>
                            <Button className="primary-btn-style" onClick={() => this.table.fireFetchData()}>
                                <i className="fa fa-fw fa-search" />
                            </Button>
                        </div>
                    </Col>
                </Row>
                <br />
                <FilterNavComponentWrapper>{this.renderNav(typeNavs)}</FilterNavComponentWrapper>
                <React.Fragment>
                    <div
                        style={{
                            width: '100%',
                            height: '10px',
                            backgroundColor: '#4b4b4b',
                            borderTopRightRadius: '5px',
                        }}
                    />
                    <div
                        className="page-widget"
                        style={{
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                        }}
                    >
                        <Col xs={12}>
                            <CyderReactTable
                                manual
                                className="-highlight mb-2"
                                minRows={0}
                                filterable={false}
                                data={this.state.list}
                                defaultPageSize={this.state.pageSize}
                                columns={columns}
                                pages={this.state.pageCount}
                                totalNum={this.state.totalNum}
                                showPagination={this.state.pagination}
                                ref={instance => {
                                    if (instance) this.table = instance.table;
                                }}
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {
                                    this.getMemberEventList(state.page, state.pageSize, state.sorted, state.filtered);
                                }}
                            />
                        </Col>
                    </div>
                </React.Fragment>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        profile: state.personalProfileReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getMemberEventList: (keyword, page, pageSize, sorted, filtered) => {
            return dispatch(memberGetMemberEventList(keyword, page, pageSize, sorted, filtered));
        },
        getMemberUpcomingEventList: (keyword, page, pageSize, sorted, filtered) => {
            return dispatch(memberGetMemberUpcomingEventList(keyword, page, pageSize, sorted, filtered));
        },
        getPersonalProfile: () => {
            dispatch(personalProfileGetProfileInfo());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
