/**
 * Service Application view page
 *
 * Author: Fernando Karnagi
 */

import TopNavigationLayout from 'layouts/TopNavigationLayout';
import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import ButtonIcon from 'cyder/button/ButtonIcon';
import BigForm from 'cyder/forms/BigForm';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import 'css/ui-elements/buttons.css';
import 'css/icons/material-design-icons.css';
import 'css/cyder/form/cyder-icons.css';
import 'css/cyder/form/common-form.css';
import 'css/cyder/tooltip/menucool.css';
import 'css/ui-elements/buttons.css';
import 'css/cyder/table/dashboard-table.css';
import CyderApplicationStatusPanel from 'cyder/panels/CyderApplicationStatusPanel';
import { AssessmentPanel } from './components/AssessmentPanel';
import ServiceDetails from './components/ServiceDetailsPanel';

const ApplicationStatus = ({ visible, status, step }) => {
    if (visible) {
        return (
            <div>
                <Row>
                    <Col>
                        <CyderApplicationStatusPanel status={status} step={step} />
                    </Col>
                </Row>
            </div>
        );
    } else {
        return '';
    }
};

class ApplicationViewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
        };
    }

    render() {
        return (
            <div>
                <BigForm title="Services Certification Application" description="">
                    <div>
                        <ApplicationStatus
                            visible={true}
                            status={this.props.application.view.status}
                            step={this.props.application.view.step}
                        />
                        <ServiceDetails
                            visible={true}
                            finalRating={this.props.application.view.finalRating}
                            startAssessment={this.props.startAssessment}
                            viewAssessment={this.props.viewAssessment}
                            updatePayment={this.props.updatePayment}
                            selectState={this.state}
                            status={this.props.application.view.status}
                            submittedOn={this.props.application.view.submittedOn}
                            certificate={this.props.certifications.serviceCertificate}
                            title={`Application ID : ${this.props.application.view.applicationNo}`}
                        />
                    </div>
                </BigForm>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        application: state.memberServiceApplication,
        assessment: state.memberServiceAssessment,
        certifications: state.memberCertifications,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        startAssessment: () => {
            history.replace('/member/certification/service/newassessment');
        },
        viewAssessment: () => {
            history.replace('/member/certification/service/viewassessment');
        },
        updatePayment: () => {
            history.replace('/member/certification/service/updatepayment');
        },
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ApplicationViewPage),
);
