import React from 'react';
import { connect } from 'react-redux';

import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';
import NoShadowPanel from 'cyder/panels/NoShadowPanel';
import MandatoryStar from 'cyder/forms/MandatoryStar';
import CyderDatePicker from 'cyder/forms/CyderDatePicker';
import { PaymentInput } from './CashPayment';

class ChequePayment extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 col-lg-12">
                    <PaymentInput
                        label="Payment Amount"
                        description="Please enter amount of payment"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'amountReceived');
                        }}
                        value={this.props.paymentInfo.amountReceived}
                    />
                    <div className="form-group">
                        <label className="field-header">
                            Date of Payment <MandatoryStar />
                        </label>
                        <CyderDatePicker
                            placeholder=""
                            dateFormat="DD/MM/YYYY"
                            closeOnSelect={true}
                            onChange={e => {
                                this.props.onDateChanged(e, 'datePaymentReceived', 'datePaymentReceivedCtrl');
                            }}
                            value={this.props.paymentInfo.datePaymentReceivedCtrl}
                        />
                        {/* <Input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={e => {
                                this.props.onTextChanged(e.target.value, 'datePaymentReceived');
                            }}
                            value={this.props.paymentInfo.datePaymentReceived}
                        /> */}
                        <small className="form-text text-muted">Please select date of payment</small>
                    </div>
                    <PaymentInput
                        label="Bank"
                        description="Please enter bank details"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'bank');
                        }}
                        value={this.props.paymentInfo.bank}
                    />
                    <PaymentInput
                        label="Cheque Number"
                        description="Please enter cheque number"
                        placeholder=""
                        onChange={e => {
                            this.props.onTextChanged(e.target.value, 'referenceNumber');
                        }}
                        value={this.props.paymentInfo.referenceNumber}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChequePayment);
