import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import StoredLayout from 'layouts/StoredLayout';

import { Row, Col, Form, Button, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { search } from 'actions/staff/dinner/registration/action.js';

class ListPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.search = this.search.bind(this);
        this.tableTypeChange = this.tableTypeChange.bind(this);
        this.nonStandardMealChange = this.nonStandardMealChange.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
            keyword: '',
            tableTypes: [
                { value: 'All', label: 'All' },
                { value: 'VIP', label: 'VIP' },
                { value: 'Platinum', label: 'Platinum' },
                { value: 'Gold', label: 'Gold' },
                { value: 'Standard', label: 'Standard' },
            ],
            nonStandardMeals: [
                { value: 'All', label: 'All' },
                { value: 'Halal', label: 'Halal' },
                { value: 'Vegetarian', label: 'Vegetarian' },
            ],
            tableType: { value: 'All', label: 'All' },
            nonStandardMeal: { value: 'All', label: 'All' },
        };
    }
    handleInputChange(e) {
        this.setState({
            keyword: e.target.value,
        });
    }
    componentDidMount() {
        if (this.props.location.state !== undefined) {
            let value = this.props.location.state.nonStandardMeal.value;
            let label = this.props.location.state.nonStandardMeal.label;
            var nonStandardMeal = {
                value,
                label,
            };
            this.nonStandardMealChange(nonStandardMeal);
        }
        setTimeout(() => {
            this.table.fireFetchData();
        }, 150);
    }
    search(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .search(
                this.state.keyword,
                this.state.tableType.value,
                this.state.nonStandardMeal.value,
                this.props.match.params.eventid,
                page,
                pageSize,
                sorted,
                filtered,
            )
            .then(res => {
                this.setState({
                    list: res.data,
                    loading: false,
                    pageCount: res.totalNoOfPages,
                    totalNum: res.totalNoOfItems,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    tableTypeChange(e) {
        this.setState({
            tableType: e,
        });
    }
    nonStandardMealChange(e) {
        this.setState({
            nonStandardMeal: e,
        });
    }
    render() {
        const columns = [
            { Header: 'Contact Name', accessor: 'contactName', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Contact Phone', accessor: 'contactPhone', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Contact Email', accessor: 'contactEmail', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Company Name', accessor: 'companyName', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            //          { Header: 'Company Address', accessor: 'companyAddress', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            { Header: 'Table', accessor: 'tableType', style: { whiteSpace: 'unset' }, headerStyle: { whiteSpace: 'unset' } },
            {
                Header: '',
                accessor: 'id',
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={`/staff/galadinner/${this.props.match.params.eventid}/registration/` + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom ">edit</i>
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <Link to={`/staff/galadinner/${this.props.match.params.eventid}/info`}>
                            <Button className="primary-btn-style-outline">
                                <i className="material-icons align-text-bottom">chevron_left</i> Back
                            </Button>
                            &nbsp;
                        </Link>
                    </Col>
                </Row>
                <br />
                <Row className="mb-2">
                    <Col>
                        <Form inline className="float-left">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    value={this.state.keyword}
                                    onChange={this.handleInputChange}
                                />
                                <div style={{ width: '200px', marginLeft: '5px' }}>
                                    <Select
                                        styles={{ width: '200px' }}
                                        autosize={false}
                                        options={this.state.tableTypes}
                                        value={this.state.tableType}
                                        clearable={true}
                                        onChange={this.tableTypeChange}
                                        name="selected-state"
                                        disabled={false}
                                        searchable={true}
                                    />
                                </div>
                                <div style={{ width: '200px', marginLeft: '5px' }}>
                                    <Select
                                        styles={{ width: '200px' }}
                                        autosize={false}
                                        options={this.state.nonStandardMeals}
                                        value={this.state.nonStandardMeal}
                                        clearable={true}
                                        onChange={this.nonStandardMealChange}
                                        name="selected-state"
                                        disabled={false}
                                        searchable={true}
                                    />
                                </div>
                            </FormGroup>
                            <Button
                                className="primary-btn-style"
                                onClick={e => {
                                    this.table.fireFetchData();
                                }}
                            >
                                Search
                            </Button>
                        </Form>
                        <Form inline>
                            <div className="ml-auto">
                                <Link to={`/staff/galadinner/${this.props.match.params.eventid}/registration/0`}>
                                    <Button className="ml-1 mr-1 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i>
                                        &nbsp;Add
                                    </Button>
                                </Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.list}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={this.state.pagination}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.totalNum}
                            pages={this.state.pageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.search(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        search: (keyword, tableType, nonStandardMealType, eventId, page, pageSize, sorted, filtered) => {
            return dispatch(search(keyword, tableType, nonStandardMealType, eventId, page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(ListPage));
