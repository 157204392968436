import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { AnalyticsBigText, AnalyticsNormalText } from 'pages/analytics/components/AnalyticsTextComponents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { getAllAttendeesCountAnalytic } from 'actions/analytics/eventsAnalyticsAction';

class RegisteredAttendees extends React.Component {
    _isMounted = true;
    state = {
        data: [],
    };
    componentDidMount() {
        this.props.load(this.props.eventId).then(res => {
            if (this._isMounted)
                this.setState({
                    data: res.data[0],
                });
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    render() {
        return (
            <AnalyticsCard title="Total Registrants" height="85">
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <AnalyticsBigText text={this.state.data.allAttendees} textColor="#78BE20" />
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: eventId => {
            return dispatch(getAllAttendeesCountAnalytic(eventId));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(RegisteredAttendees));
