/**
 * Certification Issuance Analytics Reducer
 *
 * Author: Fernando
 *
 */
import moment from 'moment';

const defaultState = {
    startDate: '01/01/' + moment().format('YYYY'),
    endDate: '31/12/' + moment().format('YYYY'),
    year: null,
    type: 'product',
    interval: 'monthly',
    refresh: null,
};

/**
 * Login reducer
 *
 * @param {*} state
 * @param {*} action
 */
export default function certificationRenewalAnalyticsReducer(state = defaultState, action) {
    switch (action.type) {
        case 'CERTIFICATION_RENEWAL_ANALYTICS_SET_START_DATE':
            var newState = Object.assign({}, state);
            newState.startDate = action.start;
            return newState;

        case 'CERTIFICATION_RENEWAL_ANALYTICS_SET_TYPE':
            var newState = Object.assign({}, state);
            newState.type = action.chart;
            return newState;

        case 'CERTIFICATION_RENEWAL_ANALYTICS_SET_END_DATE':
            var newState = Object.assign({}, state);
            newState.endDate = action.end;
            return newState;

        case 'CERTIFICATION_RENEWAL_ANALYTICS_REFRESH':
            var newState = Object.assign({}, state);
            newState.refresh = new Date().getTime();
            return newState;

        default:
            return state;
    }
}
