import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import history from '../../history';

import { Button, Container, Col, Row, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import TopNavigation2 from 'layouts/TopNavigation2';
import DashboardUpcomingEvents from 'pages/member/home/components/DashboardUpcomingEvents';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AffiliatesPanel from './components/AffiliatesPanel';
// import CarouselPanel from './components/CarouselPanel';
import { SGBC_S3_THEMES_ROOT, SGBC_DISABLED_CERT, SGBC_DISABLED_COMPANIES } from 'config';

import {
    publicHomePanelEventGetInfo,
    memberHomeSetLoadingEventState,
    // memberHomeSetLoadingState,
    // memberHomeSetLoadingNewsState,
} from 'actions/member/home/memberHomeAction';

class PublicHome extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getRelevantData();
    }
    render() {
        return (
            <div className="home-panel">
                <WhatWeDoPanel loadingEvent={this.props.loadingEvent} events={this.props.memberHomeEvents} />
                <AffiliatesPanel />
            </div>
        );
    }
}

const WhatWeDoPanel = ({ loadingEvent, events }) => {
    const cards = [
        {
            title: 'Membership',
            imageUrl: SGBC_S3_THEMES_ROOT + 'home-membership.jpeg',
            iconImagePath: '/assets/icons/icon-membership.png',
            link: `/public/membership`,
            button: {
                text: 'Join now',
                url: '/public/membership',
            },
            temporaryDisable: false,

            caption: (
                <div className="card-caption">
                    <h4>
                        From <span>$1500</span> / year
                    </h4>
                    Demonstrate sustainability leadership and be part of the Green Building community
                </div>
            ),
        },
        {
            title: 'Certification',
            imageUrl: SGBC_S3_THEMES_ROOT + 'home-certification.jpeg',
            link: `/public/certifications`,
            iconImagePath: '/assets/icons/icon-certification.png',
            button: {
                text: 'Get certified now',
                url: '/public/certifications',
            },
            // temporaryDisable: true,
            caption: (
                <div className="card-caption">
                    <h4>
                        From <span>$1200</span> / 2 year
                    </h4>
                    Gain industry and BCA Green Mark recognition for green building products/services
                </div>
            ),
        },
        {
            title: 'News & Events',
            imageUrl: SGBC_S3_THEMES_ROOT + 'home-event.jpeg',
            link: `/public/eventdirectory`,
            iconImagePath: '/assets/icons/icon-news-event.png',
            button: {
                text: 'Sign Up Now',
                url: '/public/eventdirectory',
            },
            temporaryDisable: false,

            caption: (
                <div className="card-caption">
                    <h4>
                        <span>Free</span>
                    </h4>
                    Stay updated with the latest Green Building news and events
                </div>
            ),
        },
    ];
    return (
        <Container className="sgbc-newtheme homepanel">
            <Row>
                <Col>
                    <div className="welcome">
                        Welcome to <br /> <span>Singapore Green Building Council</span>
                    </div>
                </Col>
            </Row>
            <Row>
                {cards.map((card, index) => {
                    return (
                        <Col key={index} xs={12} sm={4} className="p-3">
                            <CardComponent
                                link={card.link}
                                imageUrl={card.imageUrl}
                                iconImagePath={card.iconImagePath}
                                buttonObj={card.button}
                                title={card.title}
                                caption={card.caption}
                                temporaryDisable={card.temporaryDisable}
                            />
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
};

const AboutUsPanel = ({ events, loadingEvent }) => (
    <Container className="sgbc-newtheme homepanel">
        <Row>
            <Col className="p-1" xs={12} sm={6}>
                <Col
                    className="fadeInLeft animated p-4"
                    style={{
                        textAlign: 'justify',
                        visibility: 'visible',
                        animationName: 'fadeInLeft',
                    }}
                >
                    <h2>
                        <img src="/assets/icons/icon_mbr_home_aboutsgbconline.png" style={{ marginBottom: '5px' }} width="48" height="48" />
                        &nbsp; <strong>About SGBC</strong>
                    </h2>
                    <br />
                    <p>
                        Singapore Green Building Council (SGBC) forges public-private partnerships to foster innovative industry solutions
                        across the entire building and construction value chain. Through its green building product and green building
                        services labelling programmes, SGBC sets high standards and benchmarks for green building solutions both locally and
                        regionally to help build more sustainable cities for better living. As Singapore’s representative on the World Green
                        Building Council, SGBC actively contributes to the global green building movement by sharing expertise and knowledge
                        during international conferences and events.
                    </p>
                    <br />
                    <p>
                        Officially launched on 28 October 2009 as the only non-profit organisation with a concerted private-public sector
                        partnership to achieve a world-class and sustainable built-environment in Singapore, our key role is to advocate
                        green building design, practices and technologies and drive environmental sustainability in the building and
                        construction industry.
                    </p>
                </Col>
            </Col>
            <Col className="p-1" xs={12} sm={6}>
                <Col className="p-4">
                    <h2>
                        <img src="/assets/icons/icon_mbr_home_event.png" style={{ marginBottom: '5px' }} width="48" height="48" />
                        &nbsp; <strong>Events</strong>
                    </h2>

                    <br />
                    {loadingEvent ? (
                        <LoadingSpinner text={`Please wait, we are loading SGBC events`} />
                    ) : (
                        <DashboardUpcomingEvents new events={events} />
                    )}
                    <a
                        style={{ fontSize: '15px', float: 'right' }}
                        onClick={e => {
                            history.push(`/public/eventdirectory`);
                        }}
                    >
                        (<i>view all events</i>)
                    </a>
                </Col>
            </Col>
        </Row>
    </Container>
);

const CardComponent = props => {
    const { link, imageUrl, iconImagePath, buttonObj, title, caption, temporaryDisable } = props;
    const cardBodyStyle = {
        height: '400px',
        overflowY: title.includes('Events') ? 'hidden' : 'auto',
    };
    const imageStyle = {
        maxHeight: '210px',
        maxWidth: '100%',
    };
    return (
        <Card body className="card-public" onClick={() => (!temporaryDisable && link ? history.push(link) : {})}>
            <CardTitle>
                <div className="wrapper">
                    <img src={iconImagePath} alt={title} width="50" />
                </div>
                {title}
            </CardTitle>
            <div className="divider">. . . . .</div>
            {caption}

            {!temporaryDisable && buttonObj.url ? (
                <Link to={buttonObj.url}>
                    <Button className="btn-text">{buttonObj.text}</Button>
                </Link>
            ) : (
                <Button className="btn-text">{buttonObj.text}</Button>
            )}
            {temporaryDisable ? (
                <span className="btn-text" style={{ color: '#78be20', fontWeight: 'bold' }}>
                    For Certification, please contact SGBC Certification at certification@sgbc.sg
                </span>
            ) : (
                ''
            )}
        </Card>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ownProps,
    ...state.memberHomeReducer,
});
const mapDispatchToProps = dispatch => ({
    getRelevantData: () => {
        const getData = [dispatch(publicHomePanelEventGetInfo())];
        dispatch(memberHomeSetLoadingEventState(true)).then(() => {
            Promise.all(getData).then(() => {
                dispatch(memberHomeSetLoadingEventState(false));
            });
        });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigation2(PublicHome));
