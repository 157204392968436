// Get - event CHECKLISTs
export const EVENT_GET_EVENT_CHECKLISTS_REQUEST = 'EVENT_GET_EVENT_CHECKLISTS_REQUEST';
export const EVENT_GET_EVENT_CHECKLISTS_SUCCESS = 'EVENT_GET_EVENT_CHECKLISTS_SUCCESS';
export const EVENT_GET_EVENT_CHECKLISTS_FAILURE = 'EVENT_GET_EVENT_CHECKLISTS_FAILURE';

// Save - event CHECKLISTs
export const EVENT_SAVE_CHECKLIST_REQUEST = 'EVENT_SAVE_CHECKLIST_REQUEST';
export const EVENT_SAVE_CHECKLIST_SUCCESS = 'EVENT_SAVE_CHECKLIST_SUCCESS';
export const EVENT_SAVE_CHECKLIST_FAILURE = 'EVENT_SAVE_CHECKLIST_FAILURE';

// Update - event CHECKLISTs
export const EVENT_UPDATE_CHECKLIST_REQUEST = 'EVENT_UPDATE_CHECKLIST_REQUEST';
export const EVENT_UPDATE_CHECKLIST_SUCCESS = 'EVENT_UPDATEE_CHECKLIST_SUCCESS';
export const EVENT_UPDATE_CHECKLIST_FAILURE = 'EVENT_UPDATE_CHECKLIST_FAILURE';

// Add, save, and delete - Single CHECKLISTs
export const EVENT_SAVE_NEW_EVENT_CHECKLIST = 'EVENT_ADD_NEW_EVENT_CHECKLIST';
export const EVENT_SAVE_SINGLE_EVENT_CHECKLIST = 'EVENT_SAVE_SINGLE_EVENT_CHECKLIST';
export const EVENT_DELETE_SINGLE_EVENT_CHECKLIST = 'EVENT_DELETE_SINGLE_EVENT_CHECKLIST';

// Set - event Id
export const EVENT_CHECKLIST_SET_EVENT_ID = 'EVENT_CHECKLIST_SET_EVENT_ID';

// Input fields action
export const EVENT_CHECKLIST_CHANGE_FIELD_VALUE = 'EVENT_CHECKLIST_CHANGE_FIELD_VALUE';

// Reset - all fields
export const EVENT_CHECKLIST_RESET_FIELDS = 'EVENT_CHECKLIST_RESET_FIELDS';

// Modal
export const EVENT_CHECKLIST_SET_MODAL_DATA = 'EVENT_CHECKLIST_SET_MODAL_DATA';

export const FETCH_EVENT_CHECKLIST_SUCCESS = 'FETCH_EVENT_CHECKLIST_SUCCESS';

export const FETCH_EVENT_CHECKLIST_BY_ID = 'FETCH_EVENT_CHECKLIST_BY_ID';

export const SAVE_NEW_CHECKLIST = 'SAVE_NEW_CHECKLIST';

// Generate Records

export const EVENT_CHECKLIST_GENERATE_REQUEST = 'EVENT_CHECKLIST_GENERATE_REQUEST';

export const EVENT_CHECKLIST_GENERATE_SUCCESS = 'EVENT_CHECKLIST_GENERATE_SUCCESS';

export const EVENT_CHECKLIST_GENERATE_FAILURE = 'EVENT_CHECKLIST_GENERATE_FAILURE';
