import React from 'react';

import { Row, Col, Input, FormGroup, Button, Form } from 'reactstrap';

import { FieldFormGroup } from '../product/StaffCertificationApplicationProductCertDetails';

const StaffCertificationApplicationFunctionalSystem = props => {
    const { checkboxOptions, selectedCheckboxOptions } = props.state;
    const { onBCAOptionChange, onBCAFunctionalSystemSave } = props;
    return (
        <Row className="page-widget">
            <Col className="p-4">
                <Form>
                    <h4>BCA Functional System & Singular Products Data</h4>
                    <FieldFormGroup name="bcaFunctional" label="Please select below">
                        {checkboxOptions.map(option => (
                            <CheckboxComponent
                                key={option.id}
                                id={option.id}
                                label={option.bcaDataOption}
                                checked={selectedCheckboxOptions[option.id] || false}
                                onChange={e => onBCAOptionChange(e, option.id)}
                            />
                        ))}
                    </FieldFormGroup>
                </Form>
                <Col className="text-right mt-4">
                    <Button type="submit" className="primary-btn-style" onClick={onBCAFunctionalSystemSave}>
                        Save Changes
                    </Button>
                </Col>
            </Col>
        </Row>
    );
};

const CheckboxComponent = ({ id, checked, onChange, label }) => (
    <FormGroup check>
        <Input id={id} type="checkbox" checked={checked} onChange={onChange} />
        {label}
    </FormGroup>
);

export default StaffCertificationApplicationFunctionalSystem;
