import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function staffCertApplProductGetProductInfo(certificateId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product`, {
            action: 'find-by-certificate-id',
            certificateId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}

export function staffCertUpdateProductInfo(productId, productInfo) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/product_v1/cn/product`, {
            action: 'update-from-certificate',
            productId,
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, productInfo, null, null, null);
    };
}
