/**
 * Navigation action creators
 *
 * Author: Fernando
 */

import {
    SET_NAVIGATION,
    SET_MEMBERNAVIGATION,
    SET_STAFFNAVIGATION,
    SET_ADMIN_STAFFNAVIGATION,
    SET_NONMEMBERNAVIGATION,
} from '../constants/actionTypes';
import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from 'config.js';
import fetchlib from 'js/fetchlib';

export function cyderNavigationAction(url) {
    return {
        type: SET_NAVIGATION,
        url,
    };
}
export function cyderStaffNavigationAction(url) {
    return {
        type: SET_STAFFNAVIGATION,
        url,
    };
}
export function cyderStaffAdminNavigationAction(url) {
    return {
        type: SET_ADMIN_STAFFNAVIGATION,
        url,
    };
}
export function cyderMemberNavigationAction(url) {
    return {
        type: SET_MEMBERNAVIGATION,
        url,
    };
}
export function cyderNonMemberNavigationAction(url) {
    return {
        type: SET_NONMEMBERNAVIGATION,
        url,
    };
}
export function cyderStaffNavigationCertGetReviewListCount() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'reviewListCount',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, 'GET_REVIEW_LIST_COUNT', null);
    };
}
