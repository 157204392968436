import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Container, Row, Col, Form, Button } from 'reactstrap';
import TopNavigationLayout from 'layouts/TopNavigationLayout';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

import { loadMyCompanyMembershipApplications } from 'actions/member/membership/overview/action.js';

import '../../../../scss/custom.scss';

class MembershipApplicationsList extends React.Component {
    constructor(props) {
        super(props);
        this.loadMyCompanyMembershipApplications = this.loadMyCompanyMembershipApplications.bind(this);
        this.state = {
            list: [],
            loading: false,
            pagination: true,
            pageSize: 10,
            pageCount: 0,
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    loadMyCompanyMembershipApplications(page, pageSize, sorted, filtered) {
        this.setState({
            loading: true,
        });
        this.props
            .loadMyCompanyMembershipApplications(page, pageSize, sorted, filtered)
            .then(res => {
                const onGoing = res.data.filter((element, i) => {
                    return element.membershipApplicationStatusId <= 4 || element.membershipApplicationStatusId == 9;
                });
                const past = res.data.filter((element, i) => {
                    return element.membershipApplicationStatusId >= 5 && element.membershipApplicationStatusId <= 8;
                });
                this.setState({
                    onGoing,
                    past,
                    loading: false,
                    onGoingPageCount: 1,
                    onGoingTotalNum: onGoing.length,
                    pastPageCount: 1,
                    pastTotalNum: past.length,
                });
            })
            .catch(res => {
                this.setState({
                    loading: false,
                });
            });
    }
    render() {
        const columns = [
            {
                Header: 'Ref No',
                accessor: 'id',
                maxWidth: 100,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Type',
                accessor: 'applicationType',
                maxWidth: 150,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Package',
                accessor: 'packageName',
                maxWidth: 150,
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: 'Valid Form',
                accessor: 'membershipStartDate',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value != null) {
                        return moment(props.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    } else {
                        return `-`;
                    }
                },
            },
            {
                Header: 'Valid Until',
                accessor: 'membershipExpiryDate',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => {
                    if (props.value != null) {
                        return moment(props.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    } else {
                        return `-`;
                    }
                },
            },
            {
                Header: 'Submitted On',
                accessor: 'applicationFirstSubmittedTimestamp',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                Cell: props => moment(props.value).format('DD/MM/YYYY'),
            },
            {
                Header: 'Status',
                accessor: 'status',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
            },
            {
                Header: '',
                accessor: 'id',
                style: { whiteSpace: 'unset' },
                sortable: false,
                maxWidth: 50,
                Cell: props => {
                    return (
                        <Link to={'/member/membership/viewapplication/' + props.value}>
                            <i className="mr-1 material-icons align-text-bottom " style={{ color: '#6ba17d' }}>
                                folder_open
                            </i>
                        </Link>
                    );
                },
            },
        ];
        return (
            <Container>
                <br />
                <Row>
                    <Col xs={12} className="d-flex">
                        <h3>
                            <strong>ONGOING MEMBERSHIP APPLICATIONS</strong>
                        </h3>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.onGoing}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            hideTotalNum
                            manual
                            showPagination={false}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.onGoingTotalNum}
                            pages={this.state.onGoingPageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.loadMyCompanyMembershipApplications(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col xs={12} className="d-flex">
                        <h3>
                            <strong>PAST MEMBERSHIP APPLICATIONS</strong>
                        </h3>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.state.past}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={false}
                            defaultPageSize={this.state.pageSize}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            totalNum={this.state.pastTotalNum}
                            pages={this.state.pastPageCount}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.loadMyCompanyMembershipApplications(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadMyCompanyMembershipApplications: (page, pageSize, sorted, filtered) => {
            return dispatch(loadMyCompanyMembershipApplications(page, pageSize, sorted, filtered));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavigationLayout(MembershipApplicationsList));
