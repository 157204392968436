import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FormGroup, Input, Row, Col, Form } from 'reactstrap';

class AnalyticsToolbar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card style={{ borderRadius: '0px', marginBottom: '20px' }}>
                <CardBody>
                    <CardTitle style={{ fontWeight: '600', fontSize: '16px' }}>{this.props.title}</CardTitle>
                    {this.props.children}
                </CardBody>
            </Card>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(AnalyticsToolbar));
