import {
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST,
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE,
    EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS,
    EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE,
    EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE,
    EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT,
    EVENT_ATTENDEE_DETAILS_SET_MODA_DATA,
} from '../../../../actions/constants/events/actionTypesStaffEventsAttendee';
import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../../config';
import fetchlib from '../../../../js/fetchlib';

export function eventAttendeeDetailsGetAttendeeDetails(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'findbyid',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_REQUEST,
            EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_SUCCESS,
            EVENT_ATTENDEE_DETAILS_GET_ATTENDEE_DETAILS_FAILURE,
        );
    };
}
export function eventAttendeeDetailsGetProfessionList() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/persons_v1/cn/professions`, {
            action: 'getAllProfessions',
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            null,
            EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_SUCCESS,
            EVENT_ATTENDEE_DETAILS_GET_PROFESSION_LIST_FAILURE,
        );
    };
}
export function eventAttendeeDetailsGetTicketTypesList(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/event-ticket-type`, {
            action: 'getTicketTypeByEventId',
            eventId,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            {},
            null,
            EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_SUCCESS,
            EVENT_ATTENDEE_DETAILS_GET_TICKET_TYPES_LIST_FAILURE,
        );
    };
}
export function eventAttendeeDetailsCheckInAttendee(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'checkIn',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_REQUEST,
            EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_SUCCESS,
            EVENT_ATTENDEE_DETAILS_CHECK_IN_ATTENDEE_FAILURE,
        );
    };
}
export function eventAttendeeDetailsDeleteAttendee(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'deleteByActive',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_REQUEST,
            EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_SUCCESS,
            EVENT_ATTENDEE_DETAILS_DELETE_ATTENDEE_FAILURE,
        );
    };
}
export function eventAttendeeDetailsUpdateAttendee(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'update',
        });
        const data = getState().eventsAttendeeDetailsReducer.attendeeInfo;
        const body = {
            id,
            nric: data.nric,
            name: data.name,
            email: data.email,
            trackId: data.trackId,
            professionId: data.attendeeProfessionId,
            companyId: data.companyId,
            companyName: data.companyName,
            ticketTypeId: data.ticketTypeId,
            designation: data.designation,
            handphone: data.handphone,
            gmp: data.gmp,
            professionalNumber: data.professionalNumber,
            foodPreferenceId: data.foodPreferenceId,
        };

        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_REQUEST,
            EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_SUCCESS,
            EVENT_ATTENDEE_DETAILS_UPDATE_ATTENDEE_FAILURE,
        );
    };
}
export function eventAttendeeDetailsResetToDefault() {
    return (dispatch, getState) => {
        dispatch({
            type: EVENT_ATTENDEE_DETAILS_RESET_TO_DEFAULT,
        });
        return Promise.resolve();
    };
}
export function eventAttendeeDetailsHandleEventInputChange(event) {
    return {
        type: EVENT_ATTENDEE_DETAILS_HANDLE_INPUT_CHNAGE,
        event,
    };
}
export function eventAttendeeDetailsSetModalData(data) {
    return {
        type: EVENT_ATTENDEE_DETAILS_SET_MODA_DATA,
        data,
    };
}
