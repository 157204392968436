import React from 'react';
import { Row, Col } from 'reactstrap';
import LabelItem from './DetailsPageLabelItem';
import RowCardHOC from './DetailsPageRowCardHOC';

const DetailsPageFormSection3 = ({ company }) => (
    <RowCardHOC>
        <h5>Section E: Corporate Remark</h5>
        <br />
        <div>
            <Row>
                <Col xs={6} className="col-lg-6 col-md-6 space-top">
                    <LabelItem label="General email" content={company.generalEmail} />
                    <br />
                    <LabelItem label="Annual turnover (SGD)" content={company.annualTurnover} />
                    <br />
                    <LabelItem label="Number of employees" content={company.numberOfEmployees} />
                </Col>
                <Col xs={6}>
                    <LabelItem label="Type of Products or Services" content={company.productsServicesDescription} />
                    <br />
                    <LabelItem
                        label="Describe the ways in which your organisation is or will be involved in the green building movement"
                        content={company.greenBuildingMovementInvolvement}
                    />
                </Col>
            </Row>
        </div>
    </RowCardHOC>
);
export default DetailsPageFormSection3;
