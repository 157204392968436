import React from 'react';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AuditListPanel from './AuditListPanel';
import ButtonIcon from 'cyder/button/ButtonIcon';

class AuditListPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);

        this.state = {
            modal: false,
        };
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    componentDidMount() {
        // if (this.props.ref) {
        //     this.props.ref(this.show);
        // }
    }

    show() {
        this.setState({
            modal: true,
        });
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                    <ModalHeader toggle={this.toggle}>Audit Trail</ModalHeader>
                    <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <AuditListPanel contentTypeId={this.props.contentTypeId} objectId={this.props.objectId} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={this.toggle}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                {this.props.type == 'link' ? (
                    <a
                        href="#"
                        onClick={e => {
                            this.setState({
                                modal: true,
                            });
                        }}
                        style={{
                            color: '#317446',
                            // display: 'flex',
                            // justifyContent: 'flex-end'
                        }}
                    >
                        <u>Audit Trail</u>
                    </a>
                ) : this.props.withIcon ? (
                    <ButtonIcon
                        borderStyle={{ marginRight: '10px', marginBottom: '10px' }}
                        width={'auto'}
                        icon={`notes`}
                        label={`Audit Trail`}
                        onClick={e => {
                            this.setState({
                                modal: true,
                            });
                        }}
                        // buttonColor={color}
                        // disabled={disabled}
                        // className={className + ' text-capitalize text-center'}
                        fontStyle={{ justifyContent: 'center' }}
                        // {...btnProps}
                    />
                ) : (
                    <Button
                        style={{ marginRight: 5 }}
                        className="primary-btn-style"
                        onClick={e => {
                            this.setState({
                                modal: true,
                            });
                        }}
                    >
                        Audit Trail
                    </Button>
                )}
                {` `}
            </React.Fragment>
        );
    }
}
export default AuditListPopup;
