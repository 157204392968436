import React from 'react';
import moment from 'moment';
import history from '../../../../history';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import ButtonIcon from 'cyder/button/ButtonIcon';
import { Row, Col, Form, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import MembersEventDetailList from './MembersEventDetailList';
import { getMembersEventProfile } from 'actions/analytics/memberAnalyticsAction';

class CertificationRenewalDetailsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);

        this.state = {
            modal: false,
        };
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    componentDidMount() {}

    show(title, category) {
        this.setState({
            title,
            category,
            modal: true,
        });
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader style={{ color: 'rgb(49,116,70)' }} toggle={this.toggle}>
                    <div style={{ fontSize: '22px' }}>
                        <b>{`MEMBERS WHO HAVE ATTENDED ${this.state.title} EVENTS`}</b>
                    </div>
                </ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <MembersEventDetailList category={this.state.category} />
                </ModalBody>
                <ModalFooter>
                    <ButtonIcon onClick={this.toggle} label={`Close`} />
                </ModalFooter>
            </Modal>
        );
    }
}

class MembersEventList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);

        this.state = {
            refreshState: new Date().getTime(),
        };
    }

    load() {
        this.props.load().then(res => {
            let total = 0;
            res.data.forEach(item => {
                total += item.categoryCount;
            });
            this.setState({
                data: res.data,
                total,
            });
        });
    }

    componentDidMount() {
        this.load();
    }

    render() {
        return (
            <AnalyticsCard title={'No. of SGBC Members Who Have Attended Our Events'}>
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <CertificationRenewalDetailsPopup
                            ref={instance => {
                                this.popup = instance;
                            }}
                            className={`modal-lg`}
                        />
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Number of Attended Events</th>
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => (
                                        <tr key={i}>
                                            <td style={{ width: '50%' }}>{item.eventCategoryText}</td>
                                            <td style={{ width: '20%' }}>{item.categoryCount}</td>
                                            <td style={{ width: '20%' }}>{Math.round((item.categoryCount * 100) / this.state.total)}</td>
                                            <td style={{ width: '10%' }}>
                                                <ButtonIcon
                                                    icon={`remove_red_eye`}
                                                    onClick={e => {
                                                        this.popup.show(item.eventCategoryText, item.eventCategory);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(getMembersEventProfile());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MembersEventList));
