export const productCards = [
    {
        id: 1,
        header: 'Architectural',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/random',
    },
    {
        id: 2,
        header: 'Civil & Structural',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/300x300',
    },
    {
        id: 3,
        header: 'Mechanical',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/305x305',
    },
    {
        id: 4,
        header: 'Electrical',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/306x306',
    },
    {
        id: 5,
        header: 'Smart',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/307x307',
    },
    {
        id: 6,
        header: 'Architectural',
        description: "A long long description to show because it's nice to have one, like so.",
        src: 'http://source.unsplash.com/308x308',
    },
];

export const productCategory = [
    {
        id: 1,
        name: 'Architectural',
        description: "A long long description to show because it's nice to have one, like so.",
    },
    {
        id: 2,
        name: 'Civil & Structural',
        description: "A long long description to show because it's nice to have one, like so.",
    },
    {
        id: 3,
        name: 'Mechanical',
        description: "A long long description to show because it's nice to have one, like so.",
    },
    {
        id: 4,
        name: 'Electrical',
        description: "A long long description to show because it's nice to have one, like so.",
    },
    {
        id: 5,
        name: 'Smart',
        description: "A long long description to show because it's nice to have one, like so.",
    },
    {
        id: 6,
        name: 'Landscaping',
        description: "A long long description to show because it's nice to have one, like so.",
    },
];

export const projectTypes = [
    {
        id: 1,
        name: 'Office & Retail',
    },
    {
        id: 2,
        name: 'Industrial',
    },
    {
        id: 3,
        name: 'Institutional',
    },
    {
        id: 4,
        name: 'Childcare',
    },
    {
        id: 5,
        name: 'Healthcare',
    },
    {
        id: 6,
        name: 'Hospitality',
    },
];

export const greenAttributes = [
    {
        id: 1,
        name: 'Low VOC',
    },
    {
        id: 2,
        name: 'Low Formal',
    },
    {
        id: 3,
        name: 'IAQ Solutions',
    },
    {
        id: 4,
        name: 'Energy Efficiency',
    },
];

export const mobile = window.innerWidth < 450;
export const tablet = window.innerWidth > 450 && window.innerWidth < 769;
export const membermobile = window.innerWidth < 880;
