import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';

import { Col, Row, Form, FormGroup, Input, Button, Label } from 'reactstrap';
import DialogModal from 'cyder/modals/DialogModal';
import EditButton from 'cyder/media/EditButton';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import {
    getCriteriaSectionList,
    serRequirementListType,
} from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaListAction';

// reuse modal stuff from AddAction
import { deleteCriteria, setModalData } from 'actions/staff/certification/assessment/staffCertificationAssessmentCriteriaAddAction';
import { dialogModalToggle } from 'actions/modal/dialogModalAction';
import 'css/forms/default-forms.css';

const typeOptions = [
    { key: 'product', label: 'Product', value: 'product' },
    { key: 'service', label: 'Services', value: 'service' },
    // { key: 'all', label: 'All', value: 'all' },
];

class StaffCertificationAssessmentCriteriaListPage extends React.Component {
    constructor(props) {
        super(props);
        this.getDataFunc = this.getDataFunc.bind(this);
        this.showLoading = this.showLoading.bind(this);
        this.changeType = this.changeType.bind(this);
        this.keywordChange = this.keywordChange.bind(this);
        this.state = {
            tooltipOpen: false,
            disabled: false,
            searchable: true,
            selectValue: '',
            clearable: true,
            type: 'product',
            idForDeletion: null,
            csvPayload: null,
            loading: true,
            keyword: '',
        };
    }
    showLoading(loading) {
        this.setState({ loading });
    }
    async getDataFunc(page, pageSize, sorted, filtered) {
        this.showLoading(true);
        await this.props.getCriteriaSectionList(this.state.keyword, this.state.type, page, pageSize, sorted, filtered);
        this.showLoading(false);
    }
    keywordChange(keyword) {
        this.setState({ keyword });
    }
    async changeType(type) {
        this.showLoading(true);
        await this.props.getCriteriaSectionList('', type, 1, 10);
        this.setState({ type, keyword: '' });
        this.showLoading(false);
    }
    render() {
        const tableCols = [
            {
                Header: 'Code',
                accessor: 'criteriaCode',
                style: { whiteSpace: 'unset' },
                minWidth: 50,
                maxWidth: 150,
            },
            {
                Header: 'Section',
                style: { whiteSpace: 'unset' },
                accessor: 'section',
                minWidth: 150,
            },
            {
                Header: 'Requirement',
                accessor: 'criteria',
                style: { whiteSpace: 'unset' },
                minWidth: 200,

                Cell: props => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.value.props.dangerouslySetInnerHTML.__html,
                        }}
                    />
                ),
            },
            {
                Header: 'Documents to Submit',
                style: { whiteSpace: 'unset' },
                accessor: 'documentsToSubmit',
                minWidth: 200,
                sortable: false,
                Cell: props => (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: props.value.props.dangerouslySetInnerHTML.__html,
                        }}
                    />
                ),
            },
            {
                Header: '',
                sortable: false,
                accessor: 'id',
                maxWidth: 50,
                Cell: props => (
                    <div>
                        <Link to={`/staff/certification/assessment/requirement/${props.value}`}>
                            <EditButton />
                        </Link>
                    </div>
                ),
            },
        ];
        return (
            <div className="page-widget">
                <DialogModal
                    modalAction={this.props.modalAction}
                    modalBody={this.props.modalBody}
                    modalOnlyOneButton={this.props.modalOnlyOneButton}
                    loading={this.props.modalLoading}
                    buttonDisabler={this.props.modalLoading}
                />
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline">
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Input
                                    type="text"
                                    name="text"
                                    id="search-keyword"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.table.fireFetchData();
                                        }
                                    }}
                                    value={this.state.keyword}
                                    onChange={e => {
                                        this.keywordChange(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                {typeOptions.map((item, i) => (
                                    <Label key={i}>
                                        <Input
                                            key={item.key}
                                            name="type"
                                            type="radio"
                                            value={item.value}
                                            checked={item.value === this.state.type}
                                            onChange={() => this.changeType(item.value)}
                                        />
                                        {item.label}
                                    </Label>
                                ))}
                            </FormGroup>
                            <Button
                                className="primary-btn-style"
                                onClick={() => {
                                    this.table.fireFetchData();
                                }}
                            >
                                <i className="mr-1 material-icons align-text-bottom ">search</i>
                                Search
                            </Button>
                            <div>
                                {this.state.type === 'product' && (
                                    <Link to="/staff/certification/assessment/requirement/new/product">
                                        <Button className="primary-btn-style" style={{ marginLeft: '5px' }}>
                                            <i className="mr-1 material-icons align-text-bottom ">add</i>
                                            Add Product Requirement
                                        </Button>
                                    </Link>
                                )}
                                {this.state.type === 'service' && (
                                    <Link to="/staff/certification/assessment/requirement/new/service">
                                        <Button className="primary-btn-style" style={{ marginLeft: '5px' }}>
                                            <i className="mr-1 material-icons align-text-bottom ">add</i>
                                            Add Services Requirement
                                        </Button>
                                    </Link>
                                )}
                                &nbsp; &nbsp;
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            className="-highlight mb-2"
                            data={this.props.assessmentCriteria}
                            columns={tableCols}
                            minRows={0}
                            filterable={false}
                            manual
                            showPagination={true}
                            defaultPageSize={10}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            pages={this.props.tablePages}
                            totalNum={this.props.totalNum}
                            loading={this.state.loading}
                            onFetchData={(state, instance) => {
                                this.getDataFunc(state.page, state.pageSize, state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const addReducer = 'staffCertificationAssessmentCriteriaAddReducer';
    const ac = state.staffCertificationAssessmentCriteriaListReducer && state.staffCertificationAssessmentCriteriaListReducer.ac;
    const assessmentCriteria =
        ac &&
        ac.map(item => {
            if (item) {
                const documentsToSubmit = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: item.documentsToSubmit,
                        }}
                    />
                );
                const criteria = <div dangerouslySetInnerHTML={{ __html: item.criteria }} />;
                return { ...item, documentsToSubmit, criteria };
            }
        });
    return {
        ...state.staffCertificationAssessmentCriteriaListReducer,
        modalAction: state[addReducer].modalAction,
        modalBody: state[addReducer].modalBody,
        modalOnlyOneButton: state[addReducer].modalOnlyOneButton,
        modalLoading: state[addReducer].modalLoading,
        buttonDisabler: state[addReducer].modalLoading,
        assessmentCriteria,
        ...ownProps,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCriteriaSectionList: (keyword, type, a, b, c, d) => dispatch(getCriteriaSectionList(keyword, type, a, b, c, d)),
        deleteCriteria: id => {
            return dispatch(deleteCriteria(id));
        },
        onKeywordChange: e => {
            dispatch({
                type: 'STAFF_CERT_ASSESS_CRITERIA_LIST_SET_KEYWORD',
                keyword: e.target.value,
            });
        },
        dialogModalToggle: () => dispatch(dialogModalToggle()),
        setModalData: data => dispatch(setModalData(data)),
        setType: type => dispatch(serRequirementListType(type)),
    };
};

export default StoredLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StaffCertificationAssessmentCriteriaListPage),
);
