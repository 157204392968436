import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const uuidv4 = require('uuid/v4');

const chartPrintCenterPlugin = {
    beforeDraw: chart => {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#000';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
            //Start with a base font of 30px
            ctx.font = '30px ' + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;

            // Pick a new font size so it will not be larger than the height of label.
            // var fontSizeToUse = Math.min(newFontSize, elementHeight);
            var fontSizeToUse = 12;
            var fontSizeToUseInCenter = 25;

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUseInCenter + 'px ' + fontStyle;
            ctx.fillStyle = '#000';

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
            ctx.font = fontSizeToUse + 'px ' + fontStyle;
        }
    },
};

const chartPrintLabelOutsidePlugin = {
    afterDraw: chart => {
        let fontSizeToUse = 12;
        let ctx = chart.chart.ctx;
        let metaKey = 0;
        for (var k in chart.data.datasets[0]._meta) {
            metaKey = k;
        }
        let meta = chart.data.datasets[0]._meta[metaKey];
        meta != null &&
            meta.data.forEach(function(element, index) {
                // console.log(element);
                let view = element._view;
                let centreAngle = view.startAngle + (view.endAngle - view.startAngle) / 2;
                let innerRadius = view.outerRadius / 2;
                let rangeFromCentreTextPos = view.outerRadius + 0.75 * innerRadius;
                let textPos = {
                    x: view.x + Math.cos(centreAngle) * rangeFromCentreTextPos,
                    y: view.y + Math.sin(centreAngle) * rangeFromCentreTextPos,
                };
                let rangeFromCentreLineStart = view.outerRadius - 0.5 * innerRadius;
                let lineStartPos = {
                    x: view.x + Math.cos(centreAngle) * rangeFromCentreLineStart,
                    y: view.y + Math.sin(centreAngle) * rangeFromCentreLineStart,
                };
                let rangeFromCentreLineEnd = view.outerRadius + 0.25 * innerRadius;
                let lineEndPos = {
                    x: view.x + Math.cos(centreAngle) * rangeFromCentreLineEnd,
                    y: view.y + Math.sin(centreAngle) * rangeFromCentreLineEnd,
                };
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = fontSizeToUse + 'px ';
                ctx.fillStyle = '#000';
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#777';
                ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
                // console.log(renderInfo);
                ctx.fillText(chart.data.labels[index], textPos.x, textPos.y);

                ctx.beginPath();
                ctx.moveTo(lineStartPos.x, lineStartPos.y);
                ctx.lineTo(lineEndPos.x, lineEndPos.y);
                ctx.stroke();
            });
    },
};

class CyderDoughnut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            refreshState: new Date().getTime(),
            data: {},
        };
    }

    componentDidUpdate() {
        if (this.state.refreshState != this.props.refreshState) {
            this.componentDidMount();
            this.setState({
                refreshState: this.props.refreshState,
            });
        }
    }

    componentDidMount() {
        let chartId = uuidv4();
        var labels = [];
        var data = [];
        this.props.data.forEach((element, i) => {
            if (element > 0) {
                let label = this.props.labels[i];
                let percentage = Math.round((element * 100) / this.props.total);
                label = label + ', ' + element + ' (' + percentage + '%)';
                data.push(element);
                labels.push(label);
            }
        });
        let chartData = {
            labels,
            datasets: [
                {
                    data,
                    backgroundColor: this.props.backgroundColor,
                    hoverBackgroundColor: this.props.hoverBackgroundColor,
                },
            ],
            chartId,
        };

        const options = {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    top: 50,
                    bottom: 50,
                },
            },
            elements: {
                center: {
                    text: this.props.centerText,
                    color: '#36A2EB', //Default black
                    fontStyle: 'Helvetica', //Default Arial
                    sidePadding: 15, //Default 20 (as a percentage)
                },
            },
        };
        this.setState({
            data: chartData,
            options,
        });
    }

    render() {
        return (
            <Doughnut
                height={200}
                options={this.state.options}
                ref={ref => (this.chartInstance = ref && ref.chartInstance)}
                data={this.state.data}
                plugins={[chartPrintCenterPlugin, chartPrintLabelOutsidePlugin]}
            />
        );
    }
}
export default CyderDoughnut;
