import withQuery from 'with-query';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT } from '../../../config';
import fetchlib from '../../../js/fetchlib';

export function memberHomeCertificatesGetApplications(type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'getMemberCertificateApplicationListByType',
            type, // PRODUCT OR SERVICE
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, null, null, null, null);
    };
}
export function memberHomeCertificatesGetExpiringCertificates() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'memberExpiringCertificateList',
        });
        const params = {
            itemsPerPage: getState().config.defaultTablePageSize,
            page: 1,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null, null);
    };
}
export function memberHomeCertificatesGetCertificates(page, pageSize, type) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate`, {
            action: 'memberCertificateListByTypeDashboard',
        });
        const params = {
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
            type,
            active: 1,
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null, null);
    };
}
export function memberHomeCertificatesGetCertificateApplication() {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/certificates_v1/cn/certificate-application`, {
            action: 'getMemberHome',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url);
    };
}
