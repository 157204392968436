import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StoredLayout from 'layouts/StoredLayout';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import AnalyticsCard from 'pages/analytics/components/AnalyticsCard';
import { membershipDiscontinueReasonAnalytics } from 'actions/analytics/memberAnalyticsAction';
import DiscontinueReasonDetailsPopup from 'pages/analytics/components/membership-renewal/DiscontinueReasonDetailsPopup';

class DiscontinueReasonList extends React.Component {
    constructor(props) {
        super(props);
        this.load = this.load.bind(this);
        this.state = {
            refreshState: new Date().getTime(),
        };
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate() {
        if (this.state.refreshState != this.props.membership.refresh) {
            this.load();
            this.setState({
                refreshState: this.props.membership.refresh,
            });
        }
    }
    load() {
        this.props.load().then(res => {
            let total = 0;
            res.data.forEach(item => {
                total += item.total;
            });
            this.setState({
                data: res.data,
                total,
            });
        });
    }
    render() {
        return (
            <AnalyticsCard
                title={`${
                    this.state.data != null
                        ? `Memberships Expiring/Expired within ${moment(this.props.membership.startDate, 'DD/MM/YYYY').format(
                              'DD MMMM YYYY',
                          )} - ${moment(this.props.membership.endDate, 'DD/MM/YYYY').format('DD MMMM YYYY')}`
                        : `...`
                }`}
            >
                {this.state.data == null ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <DiscontinueReasonDetailsPopup ref={instance => (this.popup = instance)} className={`modal-lg`} />
                        <h2 style={{ color: '#ed5565' }}>Distribution of Reasons for Discontinuation</h2>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th />
                                        <th>Quantity</th>
                                        <th>Percentage</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td style={{ width: '60%' }}>{item.reason}</td>
                                                <td style={{ width: '20%' }}>{item.total}</td>
                                                <td style={{ width: '20%' }}>{Math.round((item.total * 100) / this.state.total)}</td>
                                                <td style={{ width: '10%' }}>
                                                    <Button
                                                        className="primary-btn-style"
                                                        onClick={e => this.popup.show(item.reason, item.reasonId)}
                                                    >
                                                        <i className="mr-1 material-icons align-text-bottom ">remove_red_eye</i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                )}
            </AnalyticsCard>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        membership: state.membershipRenewalAnalyticsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        load: () => {
            return dispatch(membershipDiscontinueReasonAnalytics());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(DiscontinueReasonList));
