import React from 'react';
import moment from 'moment';

import { CardBody, Row, Col, Button } from 'reactstrap';

import { anchorElementDownload } from 'js/util';

import { SGBC_S3_ROOT } from 'config';

const EventInfoContent = ({ iconSrc, label, children }) =>
    !children ? null : (
        <Col>
            <Row>
                <h4 style={{ color: '#4e8084' }}>
                    <img src={iconSrc} className="mb-1" width="36" height="36" />
                    &nbsp;&nbsp;
                    <strong>{label.toUpperCase()}</strong>
                </h4>
            </Row>
            <h5 className="ml-4 pl-3" style={{ lineHeight: '30px', paddingBottom: '30px' }}>
                {children}
            </h5>
        </Col>
    );

const EventInfoContentHTML = ({ iconSrc, label, children }) =>
    !children ? null : (
        <Col>
            <Row>
                <h4 style={{ color: '#4e8084' }}>
                    <img src={iconSrc} className="mb-1" width="36" height="36" />
                    &nbsp;&nbsp;
                    <strong>{label.toUpperCase()}</strong>
                </h4>
            </Row>
            <h5 className="ml-4 pl-3" style={{ lineHeight: '30px', paddingBottom: '30px' }}>
                {children}
            </h5>
        </Col>
    );

const EventInfoButton = ({ id, className, onClick, children, style, disabled }) => (
    <Button id={id} disabled={disabled} style={{ ...style }} className={'flex-fill primary-btn-style ' + className || ''} onClick={onClick}>
        {children}
    </Button>
);

const EventInfoButtonNormal = ({ id, className, onClick, children, style, disabled }) => (
    <Button id={id} disabled={disabled} style={{ ...style }} className={'flex-fill' + className || ''} onClick={onClick}>
        {children}
    </Button>
);

const EventInfoHeader = ({ eventState }) => (
    <Row className="info-header" style={{ marginBottom: '3em' }}>
        <Col xs={9} style={{ textAlign: 'center', color: '#4e8084', paddingTop: '40px', margin: '0 auto' }}>
            <h2 style={{ marginBottom: '1em' }}>
                <strong>{eventState.event.name}</strong>
            </h2>
            <p>
                <strong
                    dangerouslySetInnerHTML={{
                        __html: eventState.event.summary || '',
                    }}
                />
            </p>
        </Col>
    </Row>
);

const EventInfoPanel1 = ({ staff, eventState }) => {
    const eventInfo = eventState.event;
    return (
        <Col sm={12} md={6}>
            <CardBody>
                <img
                    style={{
                        objectFit: 'contain',
                        width: '100%',
                        height: '400px',
                    }}
                    src={eventInfo.bannerImage ? SGBC_S3_ROOT + eventInfo.bannerImage : '/assets/sgbc/sgbc-logo-transparent.png'}
                />
                <div className="m-4" style={{ lineHeight: '30px', textAlign: 'justify' }}>
                    <CardBody>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventInfo.description || '* No description provided',
                            }}
                        />
                    </CardBody>
                </div>
                <Col
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    <br />
                </Col>
            </CardBody>
        </Col>
    );
};
const EventInfoPanel2 = ({ staff, eventState, validateIsMemberBeforeRedirect, isTixUrl, isFormUrl, isCompletedUrl }) => {
    const eventInfo = eventState.event;
    const tickets = eventState.tickets;

    // Check if all tickets are sold out
    var ticketsSoldOut = true;
    for (var i in tickets) {
        if (tickets[i].disabled === false) {
            ticketsSoldOut = false;
            break;
        }
    }

    // Check if registration date has passed
    const { eventDate } = eventState;
    const date = eventDate.length > 0 ? eventDate[0].date : null;
    const canRegister = moment().isSameOrBefore(moment(date, 'YYYY-MM-DD'), 'day');

    return (
        <Col sm={12} md={5}>
            {(staff || (canRegister && tickets.length > 0 && !ticketsSoldOut)) && (
                <React.Fragment>
                    <Row className="d-flex mr-3 ml-3 h-70">
                        <EventInfoButtonNormal
                            onClick={validateIsMemberBeforeRedirect}
                            className={
                                'm-1 flex-fill primary-btn-style-noncaps ' + (isTixUrl || isFormUrl || isCompletedUrl ? ' d-none' : '')
                            }
                        >
                            <h3>
                                <b>Click Here to Register</b>
                            </h3>

                            {eventInfo.confirmRegRequired === 1 && tickets.length !== 0 && canRegister && (
                                <h6>(*) Confirmation of Registration subject to availability</h6>
                            )}
                        </EventInfoButtonNormal>
                    </Row>
                </React.Fragment>
            )}
            <CardBody>
                <EventInfoDetails eventState={eventState} />
            </CardBody>
            {/* 
                {registrations.length !== 0 && (
                    <React.Fragment>
                        <EventInfoButton
                            id="proceedToPayButton"
                            style={{
                                backgroundColor: '#FF6978',
                                borderColor: '#FF6978',
                            }}
                            onClick={() => {
                                registrations.length > 1
                                    ? history.push(`/${userContextUrl}/event/${eventInfo.id}/registration`)
                                    : history.push(`/${userContextUrl}/event/registration/${eventInfo.id}/paynow`);
                            }}
                        >
                            Proceed To Pay
                        </EventInfoButton>
                        <EventInfoButton
                            id="viewInvoiceButton"
                            onClick={() => {
                                if (registrations.length > 1) {
                                    history.push(`/${userContextUrl}/event/${eventInfo.id}/registration`);
                                } else if (registrations[0]) {
                                    window.location.href =
                                        `${SGBC_INVOICE_DOWNLOAD_ROOT}generate?entity=sgbc_green_council&invoiceno=` +
                                        registrations[0].invoiceNumber;
                                }
                            }}
                        >
                            View Invoice
                        </EventInfoButton>
                    </React.Fragment>
                )}
                {!eventHasPassed && registrations.length === 0 && eventTicktes.length !== 0 ? (
                    <EventInfoButton id="registerButton" onClick={validateIsMemberBeforeRedirect}>
                        Register
                    </EventInfoButton>
                ) : null}
                {registrations.length !== 0 && (
                    <EventInfoButton
                        id="attendeesButton"
                        onClick={() => {
                            history.push(`/${userContextUrl}/event/registration/${eventInfo.id}/attendant`);
                        }}
                    >
                        Attendees
                    </EventInfoButton>
                )}
                */}
        </Col>
    );
};
const EventInfoDetails = ({ eventState }) => {
    const eventInfo = eventState.event;
    const eventDate = eventState.eventDate;
    const eventMaterials = eventState.materials;
    var showCpd = true;
    if (
        !eventInfo.bcacpdVisible &&
        !eventInfo.boacpdVisible &&
        !eventInfo.cpd1Visible &&
        !eventInfo.cpd2Visible &&
        !eventInfo.cpd3Visible &&
        !eventInfo.cpd4Visible &&
        !eventInfo.pebcpdVisible &&
        !eventInfo.scecpdVisible &&
        !eventInfo.sgbccpdVisible &&
        !eventInfo.silcpdVisible
    ) {
        showCpd = false;
    }

    const convertTime = time => {
        try {
            return moment(time, 'HH:mm:SS').format('hh:mm a');
        } catch (e) {}
    };

    return (
        <Col className="panel panel-success panel-body page-widget" style={{ minHeight: '800px' }}>
            <CardBody>
                <EventInfoContentHTML label="Venue" iconSrc="/assets/icons/icon_event_venue.png">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: eventInfo.venue || 'Not specified',
                        }}
                    />
                </EventInfoContentHTML>
                <EventInfoContent label="Date & Time" iconSrc="/assets/icons/icon_event_date_time.png">
                    {eventDate != null &&
                        eventDate.length > 0 &&
                        eventDate.map((dateRecord, i) => (
                            <span key={i}>
                                {moment(dateRecord.date, 'YYYY-MM-DD').format('dddd, Do MMMM YYYY')}
                                {dateRecord.startTimeForThisDay != null &&
                                    dateRecord.startTimeForThisDay != '' &&
                                    dateRecord.endTimeForThisDay != null &&
                                    dateRecord.endTimeForThisDay != '' && (
                                        <React.Fragment>
                                            {' '}
                                            <br />
                                            {convertTime(dateRecord.startTimeForThisDay)} - {convertTime(dateRecord.endTimeForThisDay)}
                                            <br />
                                        </React.Fragment>
                                    )}
                            </span>
                        ))}
                </EventInfoContent>
                {showCpd && (
                    <EventInfoContent label="CPD Credits" iconSrc="/assets/icons/icon_event_CPDcredits.png">
                        {eventInfo.sgbccpdVisible === 1 && (
                            <span>
                                {'SGBC-GMAP CPD: '}
                                {eventInfo.sgbccpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.pebcpdVisible === 1 && (
                            <span>
                                {'PEB PDU: '}
                                {eventInfo.pebcpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.scecpdVisible === 1 && (
                            <span>
                                {'SCEM PDU: '}
                                {eventInfo.scecpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.bcacpdVisible === 1 && (
                            <span>
                                {'BCA-Registered Energy Auditor CPD: '}
                                {eventInfo.bcacpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.boacpdVisible === 1 && (
                            <span>
                                {'BOA-SIA CPD: '}
                                {eventInfo.boacpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.silcpdVisible === 1 && (
                            <span>
                                {'SILA CPD: '}
                                {eventInfo.silcpd || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.cpd1Visible === 1 && (
                            <span>
                                {'CPD 1: '}
                                {eventInfo.cpd1 || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.cpd2Visible === 1 && (
                            <span>
                                {'CPD 2: '}
                                {eventInfo.cpd2 || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.cpd3Visible === 1 && (
                            <span>
                                {'CPD 3:  '}
                                {eventInfo.cpd3 || 'Pending'}
                                <br />
                            </span>
                        )}
                        {eventInfo.cpd4Visible === 1 && (
                            <span>
                                {'CPD 4:'}
                                {eventInfo.cpd4 || 'Pending'}
                                <br />
                            </span>
                        )}
                    </EventInfoContent>
                )}
                <EventInfoContent label="Organizer's Info" iconSrc="/assets/icons/icon_event_info.png">
                    Singapore Green Building Council
                    <br />
                    Telephone: 6732 5518
                    <br />
                    Email: events@sgbc.sg
                </EventInfoContent>
                {eventInfo.eventMaterials ? (
                    <EventInfoContent label="Event Materials" iconSrc="/assets/icons/icon_mbr_resource_library.png">
                        {eventMaterials.map((material, index) => (
                            <div key={index}>
                                {!material.eventMaterialFile && material.eventMaterialFile !== 'null' && (
                                    <div onClick={() => anchorElementDownload(`${SGBC_S3_ROOT}${material.eventMaterialFile}`)}>
                                        {material.eventMaterialName}
                                        <br />
                                    </div>
                                )}
                            </div>
                        ))}
                    </EventInfoContent>
                ) : null}
                {!eventInfo.edmPdf || eventInfo.edmPdf === 'null' ? null : (
                    <Button
                        className="m-1 flex-fill primary-btn-style"
                        onClick={() => anchorElementDownload(`${SGBC_S3_ROOT}${eventInfo.edmPdf}`)}
                    >
                        <strong>DOWNLOAD EDM PDF</strong>
                    </Button>
                )}
            </CardBody>
        </Col>
    );
};
const EventInfoMainPanel = ({ staff, eventState, userContextUrl, isTixUrl, isFormUrl, isCompletedUrl, validateIsMemberBeforeRedirect }) => {
    // There could be multiple dates, the first index is the latest date of the event
    return (
        <Row>
            <Col xs={12}>
                <EventInfoHeader eventState={eventState} />
                <Row className="p-4 justify-content-center">
                    <EventInfoPanel1
                        eventState={eventState}
                        staff={staff}
                        isTixUrl={isTixUrl}
                        isFormUrl={isFormUrl}
                        isCompletedUrl={isCompletedUrl}
                        validateIsMemberBeforeRedirect={validateIsMemberBeforeRedirect}
                    />
                    <EventInfoPanel2
                        eventState={eventState}
                        staff={staff}
                        isTixUrl={isTixUrl}
                        isFormUrl={isFormUrl}
                        isCompletedUrl={isCompletedUrl}
                        userContextUrl={userContextUrl}
                        validateIsMemberBeforeRedirect={validateIsMemberBeforeRedirect}
                    />
                </Row>
            </Col>
        </Row>
    );
};

export default EventInfoMainPanel;
