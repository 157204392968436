import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import history from '../../../../history';
import StoredLayout from 'layouts/StoredLayout';
import { FormGroup, Input, Row, Col, Form, Button } from 'reactstrap';

import { eventsDinnerAllGetEventsAction, eventsAllChangeInputKeywordValue } from 'actions/staff/dinner/events/eventsAllAction';
import CyderReactTable from 'cyder/table/CyderReactTable';

class EventsAllPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        this.props.handleInputChange(event);
    }
    render() {
        const columns = [
            {
                Header: 'Guest of Honor',
                accessor: 'guestOfHonour',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 150,
            },
            {
                Header: 'Venue',
                accessor: 'eventVenue',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                minWidth: 150,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: 'Date',
                accessor: 'eventDates',
                style: { whiteSpace: 'unset' },
                headerStyle: { whiteSpace: 'unset' },
                Cell: props => {
                    return <div dangerouslySetInnerHTML={{ __html: props.value }} />;
                },
            },
            {
                Header: 'VIP',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'vip',
                maxWidth: 70,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: 'Platinum',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'platinum',
                maxWidth: 70,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: 'Gold',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'gold',
                maxWidth: 70,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: 'Standard',
                headerStyle: { whiteSpace: 'unset' },
                style: { whiteSpace: 'unset' },
                accessor: 'standard',
                maxWidth: 70,
                Cell: props => {
                    return props.value;
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: 'eventId',
                maxWidth: 90,
                Cell: props => {
                    return (
                        <Link to={'./' + props.value}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom">edit</i> Edit
                            </Button>
                        </Link>
                    );
                },
            },
            {
                Header: '',
                sortable: false,
                accessor: 'eventId',
                maxWidth: 90,
                Cell: props => {
                    return (
                        <Link to={`./${props.value}/info`}>
                            <Button className="primary-btn-style">
                                <i className="mr-1 material-icons align-text-bottom">folder_open</i> Open
                            </Button>
                        </Link>
                    );
                },
            },
        ];
        return (
            <div className="page-widget">
                <Row className="mb-2">
                    <Col xs={12}>
                        <div className="form-inline d-flex">
                            <FormGroup className="mb-4 mr-sm-4 mb-sm-0">
                                <Input
                                    type="text"
                                    id="keywordSearch"
                                    placeholder="Keyword"
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            this.props.getAllEvents(null, null, this.props.keyword);
                                        }
                                    }}
                                    value={this.props.keyword}
                                    onChange={this.handleInputChange}
                                />
                            </FormGroup>
                            <Button
                                className="ml-2 align-middle primary-btn-style"
                                onClick={() => this.props.getAllEvents(null, null, this.props.keyword)}
                            >
                                <i className="material-icons align-text-bottom">search</i>
                                &nbsp;Search
                            </Button>
                            <div className="ml-auto">
                                <Link to="./create">
                                    <Button className="ml-2 align-middle primary-btn-style">
                                        <i className="material-icons align-text-bottom">add</i> Create New Event
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={12}>
                        <CyderReactTable
                            manual
                            className="-highlight mb-2"
                            data={this.props.applications}
                            defaultPageSize={10}
                            columns={columns}
                            minRows={0}
                            filterable={false}
                            pages={this.props.tablePages}
                            totalNum={this.props.totalNum}
                            loading={this.props.loading}
                            ref={instance => {
                                if (instance != null) {
                                    this.table = instance.table;
                                }
                            }}
                            onFetchData={(state, instance) => {
                                this.props.getAllEvents(state.page, state.pageSize, '', state.sorted, state.filtered);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsDinnerAllReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        handleInputChange: event => {
            dispatch(eventsAllChangeInputKeywordValue(event.target.value));
        },
        getAllEvents: (page, pageSize, keyword, sorted, filtered) => {
            dispatch(eventsDinnerAllGetEventsAction(page, pageSize, keyword, sorted, filtered));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventsAllPage));
