import StoredLayout from 'layouts/StoredLayout';
import history from '../../history';
import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';
import { Container, Input, Row, Col, Button, Card, CardBody, Table, Label, Form, FormGroup, FormText, Alert } from 'reactstrap';
import { loadAttendants } from 'actions/shared/eventRsvpAction.js';
import { REGISTERED_ATTENDANTS_COLUMNS } from './EventsRsvpConstants';

const EventRegisteredAttendantPanel = ({ eventState }) => (
    <div>
        <Row>
            <Col xs={12}>
                <h1 className="text-center">Registered Attendants</h1>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex flex-row" xs={6}>
                <Button
                    color="warning"
                    onClick={e => {
                        history.goBack();
                    }}
                    size="sm"
                >
                    Back
                </Button>
            </Col>
        </Row>
        <hr />
        <ReactTable
            className="-highlight mb-2"
            data={eventState.registeredAttendants}
            columns={REGISTERED_ATTENDANTS_COLUMNS}
            minRows={0}
            loading={eventState.loading}
            filterable={false}
            manual
            showPagination={false}
        />
    </div>
);

class EventRegistrationPage extends React.Component {
    constructor(props) {
        super(props);
        this.showLoading = this.showLoading.bind(this);

        this.state = {
            loading: false,
            registeredAttendants: [],
            select: {
                disabled: false,
                searchable: true,
                clearable: true,
            },
        };
    }
    showLoading(show) {
        this.setState({
            loading: show,
        });
    }
    componentDidMount() {
        var registrationId = null;
        try {
            registrationId = this.props.match.params.registrationid;
        } catch (e) {}

        // Load attendants for registration
        this.showLoading(true);
        this.props
            .loadAttendants(registrationId)
            .then(res => {
                this.setState({
                    registeredAttendants: res.data,
                });
                this.showLoading(false);
            })
            .catch(error => {
                this.showLoading(false);
            });
    }

    render() {
        return (
            <Container>
                <Card className="mx-1">
                    <CardBody>
                        <EventRegisteredAttendantPanel eventState={this.state} />
                    </CardBody>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.eventsAllReducer,
        user: state.profile.user,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadAttendants: id => {
            return dispatch(loadAttendants(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(EventRegistrationPage));
