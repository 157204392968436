export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_REQUEST';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_SUCCESS';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_DETAILS_FAILURE';

export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_REQUEST';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_SUCCESS';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE = 'STAFF_CERT_ASSESS_CRITERIA_ADD_GET_SECTIONS_FAILURE';

export const STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST = 'STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_REQUEST';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS = 'STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_SUCCESS';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE = 'STAFF_CERT_ASSESS_CRITERIA_ADD_CREATE_UPDATE_FAILURE';

export const STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST = 'STAFF_CERT_ASSESS_CRITERIA_DELETE_REQUEST';
export const STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS = 'STAFF_CERT_ASSESS_CRITERIA_DELETE_SUCCESS';
export const STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE = 'STAFF_CERT_ASSESS_CRITERIA_DELETE_FAILURE';

export const STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD = 'STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_FIELD';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING = 'STAFF_CERT_ASSESS_CRITERIA_ADD_CHANGE_RATING';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING = 'STAFF_CERT_ASSESS_CRITERIA_ADD_REMOVE_RATING';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING = 'STAFF_CERT_ASSESS_CRITERIA_ADD_ADD_RATING';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS = 'STAFF_CERT_ASSESS_CRITERIA_ADD_RESET_FIELDS';

export const STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA = 'STAFF_CERT_ASSESS_CRITERIA_ADD_SET_MODAL_DATA';

export const STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST = 'STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_REQUEST';
export const STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS = 'STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_SUCCESS';
export const STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE = 'STAFF_CERT_ASSESS_CRITERIA_LIST_GET_CRITERIA_LIST_FAILURE';
export const STAFF_CERT_ASSESS_CRITERIA_LIST_SET_KEYWORD = 'STAFF_CERT_ASSESS_CRITERIA_LIST_SET_KEYWORD';
export const STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE = 'STAFF_CERT_ASSESS_CRITERIA_ADD_FORCE_VALIDATE';

export const STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE = 'STAFF_CERT_ASSESS_CRITERIA_LIST_SET_TYPE';
