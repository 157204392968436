// Get company Info
export const MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COMPANY_INFO_FAILURE';

// Get business activity
export const MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_GET_BUSINESS_ACTIVITY_FAILURE';

export const MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_GET_COUNTRIES_FAILURE';

// Get categories and sub categories
export const MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_GET_CATEGORIES_FAILURE';

// Update company info
export const MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE';

export const STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST = 'STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_REQUEST';
export const STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS = 'STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_SUCCESS';
export const STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE = 'STAFF_MEMBERSHIP_APPLICATION_UPDATE_COMPANY_INFO_FAILURE';

// Upload attchment
export const MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_SAVE_ATTACHMENT_FAILURE';

// Download attachment
export const MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST = 'MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_REQUEST';
export const MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS = 'MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_SUCCESS';
export const MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE = 'MEMBER_MEMBERSHIP_APPLICATION_DOWNLOAD_ATTACHMENT_FAILURE';

// Remove attachment
export const MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT = 'MEMBER_MEMBERSHIP_APPLICATION_REMOVE_ATTACHMENT';

// Input action
export const MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANGE = 'MEMBER_MEMBERSHIP_APPLICATION_HANDLE_INPUT_CHANG';
export const MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS = 'MEMBER_MEMBERSHIP_APPLICATION_RESET_FIELDS';

// Modal
export const MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE = 'MEMBER_MEMBERSHIP_APPLICATION_MODAL_DIALOG_TOGGLE';

// Others
export const MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES = 'MEMBER_MEMBERSHIP_APPLICATION_COPY_ADDRESSES';
export const MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING = 'MEMBER_MEMBERSHIP_APPLICATION_SET_LOADING';
export const MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE = 'MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBER_PROFILE_MODE';
export const MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM = 'MEMBER_MEMBERSHIP_APPLICATION_SET_LOGIN_FROM';
export const MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS = 'MEMBER_MEMBERSHIP_APPLICATION_SET_MEMBERSHIP_STATUS';
