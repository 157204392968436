import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyder/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyder/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import {
    Container,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    Form,
    Button,
    Card,
    CardTitle,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';

import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';
import CyderReactTable from 'cyder/table/CyderReactTable';

class CompanyPage extends React.Component {
    componentDidMount() {
        this.props.setJumbotronTitle('Singapore Settings');
    }
    render() {
        return (
            <div>
                <Row>
                    <Col xs={10}>
                        <h2>Key Info</h2>
                        <Card className="mb-2">
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <Form>
                                            <FormGroup row>
                                                <Label className="text-right" xs={4}>
                                                    Current gst: <span className="text-muted">*</span>
                                                </Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={4}>
                                                    Sme current worker limit: <span className="text-muted">*</span>
                                                </Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label className="text-right" xs={4}>
                                                    Sme current annual turnover limit: <span className="text-muted">*</span>
                                                </Label>
                                                <Col xs={8}>
                                                    <Input type="text" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" xs={4}>
                                                    Created on:
                                                </Col>
                                                <Col xs={8}>
                                                    <div>{moment().format('MMMM D YYYY, HH:mm a')}</div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" xs={4}>
                                                    Updated on:
                                                </Col>
                                                <Col xs={8}>
                                                    <div>{moment().format('MMMM D YYYY, HH:mm a')}</div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" xs={4}>
                                                    Updated by:
                                                </Col>
                                                <Col xs={8}>
                                                    <div>fkarnagi</div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col className="text-right" xs={4}>
                                                    ID:
                                                </Col>
                                                <Col xs={8}>
                                                    <div>(None)</div>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={2}>
                        <h2 style={{ visibility: 'hidden' }}>placeholder</h2>
                        <div className="sticky-top">
                            <Card>
                                <CardBody>
                                    <Button color="info" block>
                                        Save
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and continue editing</span>
                                    </Button>
                                    <Button color="default" block>
                                        <span style={{ whiteSpace: 'normal' }}>Save and add another</span>
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderExpenseMyReceiptsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(CompanyPage));
