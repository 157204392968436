/**
 * Service certificate renew page
 *
 * Author: Fernando Karnagi
 */

import React from 'react';
import { connect } from 'react-redux';
import history from '../../../../../history';

// COMPONENTS
import { Container } from 'reactstrap';
import LoadingSpinner from 'cyder/loadingSpinner/LoadingSpinner';
import BigForm from '../../../../../cyder/forms/BigForm';
import TopNavigationLayout from '../../../../../layouts/TopNavigationLayout';
import TermsAndConditions from '../shared/TermsAndConditions';

// ACTIONS
import { saveRenewCertApplication, getCertificateInfoById } from 'actions/member/certification/renew/memberProductRenewAction';
import { setUpdateAssessment } from '../../../../../actions/member/certification/renew/memberServiceRenewAction';
import { getRenewCertApplFromCertID } from 'actions/member/certification/applications/memberCertNewApplAction';
import { findByCategoryAndName } from 'actions/admin/misc/resources/action';

import { SGBC_S3_ROOT } from 'config';

class ServiceRenewPage extends React.Component {
    constructor(props) {
        super(props);
        this.showLoading = this.showLoading.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.getRelevantStuffs = this.getRelevantStuffs.bind(this);

        this.agreeTc = this.agreeTc.bind(this);
        this.constructBody = this.constructBody.bind(this);
        this.downloadTermAndConditionsDocument = this.downloadTermAndConditionsDocument.bind(this);
        this.changeStep = this.changeStep.bind(this);

        this.state = {
            agreeTc: false,
            loading: false,
        };
    }
    componentDidMount() {
        this._isMounted = true;
    }
    showLoading(show) {
        if (this._isMounted) {
            this.setState({
                loading: show,
            });
        }
    }
    getRelevantStuffs() {
        return new Promise(async (resolve, reject) => {
            try {
                this.showLoading(true);
                const certId = this.props.match.params.certId;
                const certificateInfo = await this.props.getCertificateInfoById(certId);
                let res = await this.props.getRenewData(certId);
                if (this._isMounted) {
                    this.setState({
                        certificateInfo: certificateInfo.data,
                    });
                }
                resolve(certificateInfo);
            } catch (error) {
                reject(error);
            } finally {
                this.showLoading(false);
            }
        });
    }
    constructBody(data) {
        return {
            isSelfFinancingCapable: this.props.renew.isSelfFinancingCapable,
            productCatalogueFilename: this.props.renew.productCatalogueFilename,
            service: this.props.renew.service,
            manufacturerContactPersonName: this.props.renew.manufacturerContactPersonName
                ? this.props.renew.manufacturerContactPersonName
                : data.mainContactPerson
                ? data.mainContactPerson
                : '',
            manufacturerTelephone: this.props.renew.manufacturerTelephone
                ? this.props.renew.manufacturerTelephone
                : data.mainContactNo
                ? data.mainContactNo
                : '',
            serviceCategoryId: this.props.renew.serviceCategoryId
                ? this.props.renew.serviceCategoryId
                : data.serviceCategory
                ? data.serviceCategory
                : '',
            productCatalogue: this.props.renew.productCatalogue,
            certificateNumberToRenewOrAmend: this.props.renew.certificateNumber,
            productPicture: this.props.renew.productPicture,
            productPictureFilename: this.props.renew.productPictureFilename,
            certId: this.props.match.params.certId,
            templateId: this.props.renew.templateId,
            assessmentId: this.props.renew.assessmentId,
            manufacturerCountryId: '',
            manufacturerCountryName: '',
            manufacturerFactoryAddress: '',
            manufacturerNameOfCompany: '',
            manufacturerPostalCode: '',
            remarks: '',
            greenRemarks: this.props.renew.greenRemarks,
        };
    }
    async changeStep(e, amount = 1) {
        const idObj = {};

        this.showLoading(true);

        let { data } = await this.getRelevantStuffs();

        const body = {
            ...idObj,
            ...this.constructBody(data),
        };
        try {
            const res = await this.props.saveRenewCertApplication('service', body);
            if (res.data.id) {
                history.push(`/member/certification/service/renew/application/` + res.data.id);
                return;
            }
            this.showModal('error', 'Failed to start certification renewal', '', false, true);
            throw this.props.error;
        } catch (error) {
            throw error;
        } finally {
            this.showLoading(false);
        }
    }
    agreeTc() {
        var state = this.state;
        state.agreeTc = !state.agreeTc;
        this.setState({
            state,
        });
    }
    async downloadTermAndConditionsDocument(type) {
        const { findByCategoryAndName, forceSetLoading } = this.props;
        try {
            forceSetLoading(true);
            let name = 'Certification Terms and Conditions';
            const res = await findByCategoryAndName(5, name);
            var url = SGBC_S3_ROOT + res.data.resource;
            window.open(url);
        } catch (error) {
            this.showModal('Error', 'Term and Conditions is not available', null, false, true);
            throw error;
        } finally {
            forceSetLoading(false);
        }
    }

    render() {
        return this.state.loading ? (
            <LoadingSpinner />
        ) : (
            <React.Fragment>
                <Container>
                    <br />
                    <BigForm title={'Services Certificate Renewal'} className="ml-2">
                        <TermsAndConditions
                            mode={`service`}
                            changeStep={this.changeStep}
                            downloadTermAndConditionsDocument={this.downloadTermAndConditionsDocument}
                            agreeTc={this.agreeTc}
                            agreeTcValue={this.state.agreeTc}
                            isRenew={true}
                        />
                    </BigForm>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps,
        assessment: state.memberServiceAssessment,
        application: state.memberServiceApplication,
        review: state.memberServiceReview,
        certifications: state.memberCertifications,
        renew: state.memberProductRenew,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getCertificateInfoById: certId => {
            return dispatch(getCertificateInfoById(certId));
        },
        getRenewData: certId => {
            return dispatch(getRenewCertApplFromCertID(certId));
        },
        saveRenewCertApplication: (type, body) => {
            return dispatch(saveRenewCertApplication(type, body, false));
        },
        findByCategoryAndName: (categoryId, name) => {
            return dispatch(findByCategoryAndName(categoryId, name));
        },
        updateAssessment: () => {
            dispatch(setUpdateAssessment(true));
        },
        submitAssessment: () => {
            dispatch(setUpdateAssessment(false));
        },
        submitRenewal: () => {},
    };
};

export default TopNavigationLayout(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ServiceRenewPage),
);
