import {
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST,
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS,
    EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS,
    EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE,
    EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS,
    EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE,
    EVENT_ATTENDEE_RESET_TO_DEFAULT,
    EVENT_ATTENDEE_HANDLE_INPUT_CHANGE,
    EVENT_ATTENDEE_SET_MODAL_DATA,
    EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST,
} from '../../../../actions/constants/events/actionTypesStaffEventsAttendee';

import withQuery from 'with-query';
import fetchlib from '../../../../js/fetchlib';
import { SGBC_API_ROOT, SGBC_API_CORS_ROOT, instance } from '../../../../config';

// Used both in member and staff attendee

export function exportAttendeeList(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'getAllAttendeesExport',
        });
        const params = {
            eventId: parseInt(eventId),
        };
        return fetchlib.fetch(dispatch, getState, 'POST', url, params, null, null, null);
    };
}

export function eventsAttendeeGetAttendeeList(eventId, keyword, page, pageSize) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'getAllAttendees',
        });
        const body = {
            eventId: parseInt(eventId),
            keyword: keyword || '',
            itemsPerPage: pageSize || getState().config.defaultTablePageSize,
            page: page + 1 || 1,
        };
        return fetchlib.fetchV2(
            dispatch,
            getState,
            'POST',
            url,
            body,
            EVENT_ATTENDEE_GET_ATTENDEE_LIST_REQUEST,
            EVENT_ATTENDEE_GET_ATTENDEE_LIST_SUCCESS,
            EVENT_ATTENDEE_GET_ATTENDEE_LIST_FAILURE,
        );
    };
}
export function eventsAttendeeGetAllAttendeeList(eventId) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'getAllAttendeesDetail',
        });
        return fetchlib.fetch(dispatch, getState, 'POST', url, { eventId }, null, EVENT_ATTENDEE_GET_ALL_ATTENDEE_LIST, null);
    };
}

export function eventsAttendeeCheckInAttendee(id) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'checkIn',
            id,
        });
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            null,
            EVENT_ATTENDEE_CHECK_IN_ATTENDEE_REQUEST,
            EVENT_ATTENDEE_CHECK_IN_ATTENDEE_SUCCESS,
            EVENT_ATTENDEE_CHECK_IN_ATTENDEE_FAILURE,
        );
    };
}

export function eventsAttendeeAddCPDPoints(id) {
    return (dispatch, getState) => {
        return instance
            .post('/gbp_v1/events?action=update-attendee-cpd-points', { id })
            .then(response => {
                return response.data.data;
            })
            .catch(err => {
                return { error: err };
            });
    };
}

export function eventsAttendeeUpdatWaitingStatus(id, status) {
    return (dispatch, getState) => {
        const url = withQuery(`${SGBC_API_CORS_ROOT}/events_v1/cn/attendee`, {
            action: 'updateWaitingList',
        });
        const body = {
            id,
            waitingList: status,
        };
        return fetchlib.fetch(
            dispatch,
            getState,
            'POST',
            url,
            body,
            null,
            EVENT_ATTENDEE_UPDATE_WAITING_LIST_SUCCESS,
            EVENT_ATTENDEE_UPDATE_WAITING_LIST_FAILURE,
        );
    };
}
export function eventAttendeeResetToDefault() {
    return (dispatch, getState) => {
        dispatch({ type: EVENT_ATTENDEE_RESET_TO_DEFAULT });
        return Promise.resolve();
    };
}
export function eventsAttendeeHandleInputChange(value) {
    return {
        type: EVENT_ATTENDEE_HANDLE_INPUT_CHANGE,
        value,
    };
}
export function eventsAttendeeSetModalData(data) {
    return {
        type: EVENT_ATTENDEE_SET_MODAL_DATA,
        data,
    };
}
