import { connect } from 'react-redux';
import { compose, branch, renderComponent, renderNothing } from 'recompose';
import TopNavigation2 from './TopNavigation2';

const layouts = ['top-navigation-2'];

const Component = compose(
    connect(state => {
        return {
            navigation: state.profile.user.id
                ? state.navigationMember
                : state.navigationPublic,
            layout: state.config.layout,
            user: state.profile.user,
        };
    }),
    branch(
        ({ layout }) => layouts.includes(layout),
        renderComponent(TopNavigation2),
        renderNothing,
    ),
)(TopNavigation2);

export default Component;
