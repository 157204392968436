import React from 'react';
import { isIE } from 'react-device-detect';
import { Row, Col, Button } from 'reactstrap';

const determineShow = (saving, step, error) => {
    if (saving) return false;
    if (step === 2) return true;
    if (step === 3) if (error) return true;
    return false;
};
const ButtonComponent = ({ className, disabled, onClick, icon, label }) => (
    <Button disabled={disabled} className={className} onClick={onClick}>
        <i className="mr-1 material-icons align-text-bottom ">{icon}</i>
        {label}
    </Button>
);
const ButtonPanel = ({
    showPreviousButton, //1 yes other than 1 than dont show
    agreeTcValue,
    step,
    changeStep,
    setFileLength,
    saveApplicationChanges,
    savingApplChanges,
    savedThisSession,
    error,
    errorMessage,
    validationBeforeSubmission,
    canProceedToPreview,
}) => {
    return (
        <Row className={!isIE ? `text-right` : ``} style={{ marginTop: '20px' }}>
            <Col className="m-0">
                {step >= 2 && step <= 3 && showPreviousButton == 1 && (
                    <ButtonComponent
                        className="primary-btn-style-outline mr-1"
                        icon="navigate_before"
                        label="Previous"
                        onClick={e => {
                            if (step === 3) setFileLength(0);
                            changeStep(e, -1);
                        }}
                    />
                )}
                {step === 2 && savedThisSession && (
                    <Row>
                        <Col className="d-flex flex-row-left">
                            <ButtonComponent
                                className="primary-btn-style"
                                icon="navigate_next"
                                label="Save Changes"
                                onClick={() => saveApplicationChanges(false, false)}
                            />
                        </Col>
                        <Col>
                            <ButtonComponent
                                className="primary-btn-style"
                                icon="navigate_next"
                                label="Preview Application"
                                disabled={!canProceedToPreview}
                                onClick={async () => {
                                    saveApplicationChanges(false, true);
                                    // Validation here is used to determine whether to change to preview step
                                    const successfullyValidated = await validationBeforeSubmission(false);
                                    if (!successfullyValidated) return;
                                    changeStep();
                                }}
                            />
                        </Col>
                    </Row>
                )}
                {step === 2 && !savedThisSession && (
                    <ButtonComponent
                        icon="save"
                        className="primary-btn-style"
                        label={savingApplChanges ? 'Saving Changes' : 'Save Changes'}
                        onClick={() => saveApplicationChanges(false, false)}
                    />
                )}
                {step === 3 && (
                    <ButtonComponent
                        icon="send"
                        label="Submit"
                        className="primary-btn-style"
                        disabled={savingApplChanges || !agreeTcValue}
                        onClick={() => saveApplicationChanges(true, true)}
                    />
                )}
                {!savedThisSession || error ? <br /> : null}
                {determineShow(savingApplChanges, step, error) && (
                    <strong className={`align-middle text-muted ${errorMessage && 'color-danger'}`}>
                        {error ? `An error occured: ${errorMessage}` : `Changes ${!savedThisSession ? 'not' : ''} saved`}
                    </strong>
                )}
            </Col>
        </Row>
    );
};

export default ButtonPanel;
