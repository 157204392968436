export const SAVE_SERVICE_REQUEST = 'SAVE_SERVICE_REQUEST';
export const SAVE_SERVICE_SUCCESS = 'SAVE_SERVICE_SUCCESS';
export const SAVE_SERVICE_FAILURE = 'SAVE_SERVICE_FAILURE';

export const SAVE_PRODUCT_REQUEST = 'SAVE_PRODUCT_REQUEST';
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_FAILURE = 'SAVE_PRODUCT_FAILURE';

export const TEMPLATE_EDIT_SET_MODAL_DATA = 'TEMPLATE_EDIT_SET_MODAL_DATA';

export const STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE = 'STAFF_CERT_ASSESSMENT_SET_FILTER_TYPE';
export const STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS = 'STAFF_CERT_ASSESSMENT_SET_SELECTED_STATUS';
export const STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY = 'STAFF_CERT_ASSESSMENT_SET_SELECTED_CATEGORY';
