import moment from 'moment';
import {
    MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS,
    MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE,
    MEMBER_HOME_SET_LOADING_STATE,
    MEMBER_HOME_SET_LOADING_EVENT_STATE,
    MEMBER_HOME_SET_LOADING_NEWS_STATE,
    MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE,
    MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE,
    MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE,
    MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE,
} from '../../actions/constants/actionTypesMemberHome';

const defaultState = {
    memberHomeCerts: [],
    memberHomeEvents: [],
    memberHomeMembershipApplicationInfo: [],
    memberHomeMembershipInfo: {
        membershipEndDate: null,
        membershipStartDate: null,
    },
    memberInfo: {},
    memberHomeResources: {
        downloads: [],
        partnerEvents: [],
        pastEvents: [],
        reports: [],
    },
};

export default function memberHomeReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case MEMBER_HOME_PANEL_CERT_GET_INFO_REQUEST:
            return newState;
        case MEMBER_HOME_PANEL_CERT_GET_INFO_SUCCESS:
            newState.memberHomeCerts = action.response.data;
            newState.memberHomeCerts.forEach(cert => {
                cert.expired = moment(cert.expired, 'YYYY-MM-DD').format('DD/MM/YYYY');
                cert.issued = moment(cert.issued, 'YYYY-MM-DD').format('DD/MM/YYYY');
            });
            return newState;
        case MEMBER_HOME_PANEL_CERT_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_REQUEST:
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_SUCCESS:
            newState.memberHomeMembershipInfo = action.response.data;
            newState.memberInfo = action.response.data;
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_REQUEST:
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_SUCCESS:
            newState.memberHomeMembershipApplicationInfo = action.response.data;
            return newState;
        case MEMBER_HOME_PANEL_MEMBERSHIP_APPLICATION_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_HOME_PANEL_EVENTS_GET_INFO_REQUEST:
            return newState;
        case MEMBER_HOME_PANEL_EVENTS_GET_INFO_SUCCESS:
            newState.memberHomeEvents = action.response.data;
            return newState;
        case MEMBER_HOME_PANEL_EVENTS_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_HOME_PANEL_RESOURCES_GET_INFO_REQUEST:
            return newState;
        case MEMBER_HOME_PANEL_RESOURCES_GET_INFO_SUCCESS:
            newState.memberHomeResources = action.response.data;
            return newState;
        case MEMBER_HOME_PANEL_RESOURCES_GET_INFO_FAILURE:
            newState.errorMessage = action.error.message;
            return newState;
        case MEMBER_HOME_SET_LOADING_STATE:
            newState.loading = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_MEMBERSHIPINFO_STATE:
            newState.loadingMemberhipInfo = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_MEMBERSHIPAPPLICATIONINFO_STATE:
            newState.loadingMemberhipApplicationInfo = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_CERTIFICATION_STATE:
            newState.loadingCertification = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_RESOURCELIBRARY_STATE:
            newState.loadingResourceLibrary = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_EVENT_STATE:
            newState.loadingEvent = action.loadingState;
            return newState;
        case MEMBER_HOME_SET_LOADING_NEWS_STATE:
            newState.loadingNews = action.loadingState;
            return newState;
        default:
            return state;
    }
}
