/**
 * Cyder Input Group
 *
 * Author: Fernando
 */

import React from 'react';
import { connect } from 'react-redux';

const CyderInputGroup = ({ field, onKeyDown }) => {
    if (!field) {
        return <div />;
    }
    let hasErrors = field.errors && field.errors.length > 0 ? true : false;
    return (
        <div className={hasErrors ? 'form-group text-danger' : 'form-group'}>
            <label className="form-control-label">{field.rules.title}</label>
            <div className="input-group-prepend">
                <span className="input-group-text rounded-left" style={{ border: '0px', borderRadius: '0px' }}>
                    <i className={hasErrors ? 'material-icons text-danger' : 'material-icons'}>{field.icon}</i>
                </span>
                <input
                    id={field.id}
                    placeholder={field.placeholder || ''}
                    type={field.type}
                    onKeyDown={onKeyDown}
                    className={hasErrors ? 'form-control is-invalid rounded-right' : 'form-control rounded-right'}
                    name={field.name}
                />
            </div>
            {field.errors.map((error, i) => (
                <div key={i} className="form-text text-danger">
                    {error}
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CyderInputGroup);
